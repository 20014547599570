import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'fibra-opportunity-cambio',
  templateUrl: './opportunity-cambio.component.html',
  styleUrls: ['./opportunity-cambio.component.scss'],
})
export class OpportunityCambioComponent implements OnInit {
  
  @ViewChild('interested', {static: false}) interested: ElementRef; 
  benefities = [
    {
      icon: '/assets/opportunity/svg/opportunity-benefits-wallet.svg',
      description: 'Agilidade na contratação: nosso time está focado para garantir um processo mais rápido.',
      alt: 'icon-wallet'
    },
    {
      icon: '/assets/opportunity/svg/opportunity-benefits-wallet-chart.svg',
      description: 'Taxas mais atrativas: o FIbra assegura a melhor cotação para suas operações.',
      alt: 'icon-chart'
    },
    {
      icon: '/assets/opportunity/svg/opportunity-benefits-coin.svg',
      description: 'Tarifas diferenciadas: valores flexíveis de acordo com a sua necessidade.',
      alt: 'icon-coin'
    },
    {
      icon: '/assets/opportunity/svg/opportunity-presention-chart.svg',
      description: 'Suporte de uma equipe com elevado conhecimento técnico para estruturar soluções para atender sua empresa.',
      alt: 'icon-chart'
    }
  ]
  constructor() {}

  ngOnInit() {}

  isInterested() {
    const targetElement = this.interested.nativeElement
    targetElement.scrollIntoView({behavior: "smooth"})
  }
     
}
