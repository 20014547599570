<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page"
  *fibraHasRole="'
  COB.GER_SEG_BOL,
  QUALQUER_CONTA.COB.GER_SEG_BOL'"
>
  <fibra-breadcrumb
    bottom="-20"
    infoAccount="Cobrança / Segunda via de boleto"
    roule="Segunda Via de Boleto"
  ></fibra-breadcrumb>
  <div class="duplicate-content">
    <div class="duplicate-list">
      <fibra-filter-cobranca 
        [default]="true" 
        [abaSelected]="'bill-list'" 
        [inputFilter]="true" 
        [approvalTransaction]="true" 
        (emitFilter)="sendFilter($event)">
      </fibra-filter-cobranca>
      <fibra-list params="{{ params }}" [typeList]="'bill-list'"></fibra-list>
    </div>
  </div>
</div>

<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
