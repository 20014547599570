import { Subscription } from 'rxjs';
import { ApprovalStatusLimitesUseCases } from './../../../../core/usecases/approval-limits/approval-limits.usecase';
import { Component, EventEmitter, OnInit, Output, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { ApprovalandRefuseRequest, } from '@src/core/domain/transfer-approval/approval.model';
import * as Util from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import * as $ from 'jquery';
import jwt_decode from "jwt-decode";
import { ApprovalandRefuseLimitRequest } from '@src/core/domain/limits/limits.model';
import { ApprovalLimitesUseCases } from '@src/core/usecases/approval-limits/approval-limits.usecase';

class EmailToken {
  UserId: string;
}
@Component({
  selector: 'fibra-limit-approval',
  templateUrl: './limit-approval.component.html',
  styleUrls: ['./limit-approval.component.scss']
})
export class LimitApprovalComponent implements OnInit {

  @Input() public identificationPage: string;
  public inputData: any;

public tabMenu: string = 'all';
public elementCheckAll: any = '';
public showIndividualConfirmation: boolean;
public selected = [];
public approvalOrRefuseList = [];
public loading: boolean = false;
public shownAll: boolean;
public util = Util;
public emailStorage: string;
public sameEmailApproval;
public showIndividualRecusa: boolean = false;
public showIndividualAceite: boolean = false;
public disableCheckBox: boolean = false;
public hideCheCheckBoxAll: boolean = true;
public typToastColor;
public REDCOLOR = '#ED6C6C';
public GREENCOLOR = '#73B599';
public AMBARCOLOR = '#D69D10';
public isPendent: boolean;
public callBack: number;
public actionConfirmed: boolean = false;
public indexList: number;
public actionAproveOrRefuse = false;
public objRequest: ApprovalandRefuseRequest;
public loadingDetail: boolean = false;
public dataDetail;
public getDataShowHideBarAprovarRecusar = [];
public actionRefuseOrApproval: string = '';
public statusNumber: number = 0;
public indentificationType: string ='';
public isError: boolean;
public pendingLenghtMaster = [];
public varSubscriptTransaction: Subscription;
public prepareObject: ApprovalandRefuseLimitRequest = {
    tipoTransacao: '',
    tipoAlteracao: '',
    id: 0,
    idAprovador: 0,
    idUsuario: 0,
    idAprovacao: ''
  };

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private approvalLimitesUseCases: ApprovalLimitesUseCases,
    private approvalStatusLimitesUseCases: ApprovalStatusLimitesUseCases
  ) {

  }

  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  public userEmail;

  ngOnInit(): void {
    this.userEmail = this.jwtDecode();
    this.getDataTransactionLimitOrPixLimit();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getDataTransactionLimitOrPixLimit() {
    switch (this.identificationPage) {
      case 'CHANGE-TRANSACTION':
        this.indentificationType = 'outro';
        this.getData(this.indentificationType);
        return;
      case 'PIX-LIMITE':
        this.indentificationType = 'pix';
        this.getData(this.indentificationType);
        return;
    }
  }

  getData(type: string) {
    this.loading = true;
    this.isError = false;
    let params = {
      status: this.statusNumber,
      type: type
    };
    this.unsubscribeVariable(this.varSubscriptTransaction);
    if(this.statusNumber === 0) {
      this.varSubscriptTransaction = this.approvalLimitesUseCases.execute(type)
        .subscribe(
          this.successDetail,
          this.errorDetail
        );
      return;
    }

    if(this.statusNumber !== 0) {
      this.varSubscriptTransaction = this.approvalStatusLimitesUseCases.execute(params)
        .subscribe(
          this.successDetail,
          this.errorDetail
        );
      return;
    }
  }

  private requestDetail(id: number) {
    this.loadingDetail = true;
    this.dataDetail = this.inputData.find(res => res.id === id);
  }

  public toogleDetail = (index, id) => {
    this.requestDetail(id);
    if (this.indexList !== index) {
      this.indexList = index;
      $('.collapse').removeClass('show');
    }
  };

  checked(idx, data?) {
    this.elementCheckAll = this.getElementCheckAll();
    if (idx === 'all') {
      this.checkedAll();
    }

    if (idx !== 'all') {
      this.checkedIndividual(idx, data);
    }
  }

  private checkedAll() {
    this.inputData.map((item, index) => {
      let elem = document.getElementById(index.toString()) as HTMLInputElement;
      if(!this.elementCheckAll.checked && typeof(elem.checked) !== 'undefined') {
        this.approvalOrRefuseList = [];
        this.getDataShowHideBarAprovarRecusar = [];
        elem.checked = false;
        return;
      }

      if(this.elementCheckAll.checked && typeof(elem.checked) !== 'undefined') {
        this.getDataShowHideBarAprovarRecusar.push(item);
        this.approvalOrRefuseList.push(this.getPrepareObject(item));
        
        this.getDataShowHideBarAprovarRecusar = this.getDataShowHideBarAprovarRecusar
          .filter((result, i) => this.getDataShowHideBarAprovarRecusar.indexOf(result) === i);
        
        this.approvalOrRefuseList = this.approvalOrRefuseList
        .filter((result, i) => this.approvalOrRefuseList.indexOf(result) === i);
        elem.checked = true;
      }
    });
  }

  checkedIndividual(idx: number, data: any) {
    let element = document.getElementById(idx.toString()) as HTMLInputElement;
    this.getPrepareObject(data);
    if(element.checked) {
      this.getDataShowHideBarAprovarRecusar.push(data);
      this.approvalOrRefuseList.push(this.prepareObject);

      element.checked = true;
      return;
    }

    if(!element.checked) {
      this.approvalOrRefuseList.splice(this.approvalOrRefuseList.indexOf(this.prepareObject), 1);
      this.listApprovalLength(this.approvalOrRefuseList);

      this.getDataShowHideBarAprovarRecusar.splice(this.getDataShowHideBarAprovarRecusar.indexOf(data), 1);
      this.listApprovalLength(this.getDataShowHideBarAprovarRecusar);

      element.checked = false;
    }
  }

  getPrepareObject(data) {
    return  this.prepareObject =  {
      tipoTransacao: this.indentificationType,
      tipoAlteracao: data.tipoAlteracao,
      id: data.id,
      idAprovador: this.selectedIdAprovacaoLimite(data).id,
      idUsuario: this.selectedIdAprovacaoLimite(data).idPessoa,
      idAprovacao: this.selectedIdAprovacaoLimite(data).identificadorAprovacao
    };
  }

  selectedIdAprovacaoLimite(data) {
    let isApprovalAction;
    data.aprovadores.map((result) => {
     if(result.emailAprovador == this.userEmail) {
      isApprovalAction = result;
     }
    })
    return isApprovalAction;
  }

  cancelConfirmation() {
    this.clearCheckBox();
    this.resetAction();
  }

  getElementCheckAll() {
    return document.getElementById('checkAll') as HTMLInputElement;
  }

  listApprovalLength(object: any) {
    const elementCheckAll = this.getElementCheckAll();
    if (object.length < 2) {
      elementCheckAll.checked = false;
    }
  }

  selectedMenu(menu: string) {
    this.tabMenu = menu;
    this.getStatus(menu);
  }

  getStatus(status) {
    switch(status) {
      case 'all':
          this.statusNumber = 0;
        break;
      case 'pendente':
          this.statusNumber = 1;
        break;
      case 'aprovado':
          this.statusNumber = 2;
        break;
      case 'recusado':
          this.statusNumber = 3;
        break;
    }
    this.getData(this.indentificationType);
  }

  compareApprovals(approvals: any) {
    let isOtherMaster = false;
    approvals.map(data => {
      if (this.userEmail === data.emailAprovador && data.status === 'Aprovado') {
        isOtherMaster = true;
      }
    });
    return isOtherMaster;
  }

  isApprovalPermission(approvals: any) {
    let isApproval = false;
    approvals.map(data => {
      if (this.userEmail === data.emailAprovador) {
        isApproval = true;
      }
    });
    return isApproval;
  }

  podeAprovar(approvals: any) {
    let isApproval = false;
    approvals.map(data => {
      if (this.userEmail === data.emailAprovador && data.podeAprovar) {
        isApproval = true;
      }
    });
    return isApproval;
  }

  showPending(approvals: any) {
    let pendingMaster = false;
    this.pendingLenghtMaster = approvals;
    this.lenghtPendingMaster();
    approvals.map(data => {
      if (this.userEmail === data.emailAprovador && data.idStatusLimiteTransacional === 1) {
        pendingMaster = true;
      }
    });
    return pendingMaster;
  }

  lenghtPendingMaster() {
    let pendingLenght;
    pendingLenght = this.pendingLenghtMaster
      .filter((result) => result.idStatusLimiteTransacional === 1);
    return pendingLenght.length;
  }

  getSession() {
    let token = sessionStorage.getItem('x-ibpj-session');
    return token;
  }

  jwtDecode() {
    const decoded: EmailToken = jwt_decode(this.getSession());
    return decoded.UserId;
  }

  handleTypeConfirm() {
    let justRefuse = false
    this.getDataShowHideBarAprovarRecusar.map(data => {
      for (const item of data.aprovadores ) {
        if(item.status === "Aprovado" && this.userEmail === item.emailAprovador) {
          justRefuse = true;
        }
      }
    })
    return justRefuse;
  }

  public showPendingOperatorToolTip(obj: any) {
    return obj.some((e) => e.idTipoAcesso === 1 && e.status === 'Pendente');
  }

  public showRefundOperatorToolTip(obj: any) {
    return obj.some((e) => e.idTipoAcesso === 1 && e.status === 'Recusado');
  }

  public showPendingMasterToolTip(obj: any) {
    return obj.some((e) => e.idTipoAcesso === 2 && (e.status === 'Pendente'));
  }

  public showRefundMasterToolTip(obj: any) {
    return obj.some((e) => e.idTipoAcesso === 2 && (e.status === 'Recusado'));
  }
  
  public approvalOrRefuseIndividual(action, item?) {
    this.approvalOrRefuseList = [];
    this.approvalOrRefuseList.push(this.getPrepareObject(item));

    if(action === 'REFUSE') {
      this.showIndividualRecusa = true;
      this.showIndividualAceite = false;
      this.disableCheckBox = true;
      this.clearCheckBox();
      return;
    }

    if(action === 'APPROVAL') {
      this.showIndividualAceite = true;
      this.showIndividualRecusa = false;
      this.disableCheckBox = true;
      this.clearCheckBox();
    }
  } 

  getModalToken(action) {
    if(action === 'REFUSE') {
      this.actionConfirmed = true;
      this.callBack = 128;
    }

    if(action === 'APPROVAL') {
      this.actionConfirmed = true;
      this.callBack = 127;
    }
  }

  public resetAction() {
    this.approvalOrRefuseList = [];
    this.showIndividualRecusa = false;
    this.disableCheckBox = false;
    this.showIndividualAceite = false;
    this.actionRefuseOrApproval = '';
    this.getDataShowHideBarAprovarRecusar = [];
    this.loading = false;
    this.prepareObject = {
        tipoTransacao: '',
        tipoAlteracao: '',
        id: 0,
        idAprovador: 0,
        idUsuario: 0
      };
    this.statusNumber = 0;
  }

  public clearCheckBox() {
    this.elementCheckAll = this.getElementCheckAll();
    this.inputData.map((_item, index) => {
      let elements = document.getElementById(index.toString()) as HTMLInputElement;
      if(typeof(elements.checked) !== 'undefined') {
        this.elementCheckAll.checked = false;
        elements.checked = false;
      }
    });
  }

  public changeTypeApprove(status) {
   if(status == 9) {
    this.actionRefuseOrApproval = 'APPROVAL';
    this.callBack = 127;
    return;
   }

   if(status == 10) {
    this.actionRefuseOrApproval = 'REFUSE';
    this.callBack = 128;
   }
  }
  
  public confirmAction() {
    this.getModalToken(this.actionRefuseOrApproval);
    console.log(this.approvalOrRefuseList);
  }

  public securityResponse(event) {
    let message;
    if(this.callBack === 128 && event.code === 200) {
      message = event.data;
      this.showMessageResponse(1, message);
      this.statusNumber = 3;
      this.tabMenu = 'recusado';
    }

    if (this.callBack === 127 && event.error && event.error.code && event.error.code == 400 && event.error.message && event.error.message.length) {
      this.showMessageResponse(1, event.error.message[0]);
    }

    if(this.callBack === 127 && event.code === 200) {
      if(event.data.result) {
        message = event.data.result;
        this.showMessageResponse(2, message);
        this.statusNumber = 2;
        this.tabMenu = 'aprovado'
      }

      if(!event.data.result) {
        message = event.data;
        this.showMessageResponse(2, message);
        this.statusNumber = 3;
        this.tabMenu = 'recusado'
      }

    }

    if(event.code === 500 || event.status === 500) {
      this.showMessageResponse(3, 'Ocorreu um erro! Tente novamente.');
      this.statusNumber = 0;
      this.tabMenu = 'all';
    }

    this.getData(this.indentificationType);
  }

  public securityRequest = () => {
    return this.approvalOrRefuseList;
  };

  getNameApproval() {
    let user = JSON.parse(sessionStorage.getItem('defaultCompany'));
    return user.name;
  }

  public showMessageResponse(action: number, message: string) {
    if(action === 1) {
      this.typToastColor = this.REDCOLOR;
    }

    if(action === 2) {
      this.typToastColor = this.GREENCOLOR;
      if(message.indexOf('Alteração de limite pendente') !== -1 ||
        message.indexOf('Alteração de horário pendente') !== -1 ||
        message.indexOf('Alteração pendente') !== -1) {
        this.typToastColor = this.AMBARCOLOR;
      }

      if(message.indexOf('Uma das solicitações foi recusada') !== -1 ||
        message.indexOf('Solicitação recusada') !== -1
      ) {
        this.typToastColor = this.REDCOLOR;
      }
    }

    if(action === 3) {
      this.typToastColor = this.REDCOLOR;
    }

    this.callToast(message);
  }


  public closeModal() {
    this.clearCheckBox();
    this.actionConfirmed = false;
    this.resetAction();
  }

  private successDetail = (value) => {
      this.inputData = value.data;
      this.findStatusPendente(value.data);
      this.isError = false;
     this.loading = false;
  };

  private errorDetail = (err) => {
    this.loading = false;
    if(err.status === 500) {
      let message = 'Ocorreu um erro! Tente novamente.';
      this.typToastColor = this.REDCOLOR;
      this.callToast(message);
      this.isError = true;
      return;
    }
    if(err.status === 404) {
      this.isError = true;
      return;
    }
  };

  callToast(message) {
    this.toast.callModalMessage(null, `${message}`, '', null, true);
  }

  unsubscribeVariable(variable) {
    if(variable) {
      variable.unsubscribe();
    }
    return;
  }

  findStatusPendente(data) {
    this.hideCheCheckBoxAll = true;
    data.map((result) => {
      if(result.idStatus === 1) {
       this.hideCheCheckBoxAll = false;
       return;
      }
    });
  }
}