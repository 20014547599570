import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { optionsDocDownload } from './optionsDocDownload';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'fibra-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})


export class BreadcrumbComponent implements OnInit {
  @Input() infoAccount;
  @Input() roule;
  @Input() routeBack?;
  @Input() downloadDoc;
  @Input() onlyExcel;
  @Input() infoText;
  @Input() arrow;
  @Input() height = 200; // can be variable to adapt on layout
  @Input() bottom = -75; // need use negative values
  @Input() exportButtonPlaceholder = 'Baixar informações da tela';
  @Input() batchDetails;
  @Input() linkExternoUrl: string = null;
  @Input() linkExternoTitulo: string = null;
  @Input() showBtnLimitPix?: boolean = false;
  
  @Output() infoReport: EventEmitter<any>;

  constructor(
      private route: Router,
      private location: Location,
  ) {
    this.infoReport = new EventEmitter();
  }

  options: optionsDocDownload[];

  ngOnInit() {
    if (this.onlyExcel === true){ 
      this.options = [
        { name: 'Excel', value: 2 }
      ];
    }else{
      this.options = [
        { name: 'PDF', value: 1 },
        { name: 'Excel', value: 2 }
      ];
    }
  }

  public sendInfo(value) {
    this.infoReport.emit(value);
  }

  public goBack = (): void => {
    if (this.routeBack) {
      this.route.navigate([`${this.routeBack}`]);
      return;
    }
    this.location.back();
  }

  redirectPixLimits() {
    localStorage.setItem('redirectPixLimits', 'redirectPixLimits');
    this.route.navigate(['/pix']);
  }
}
