<div class="card-body">
  <div>
      <div *ngFor="let operation of totalOperations"
          class="for-total-opera">
          <div class="total-operation-item-content">
              <img class="total-opration-content-item-single"
                  alt="image-credito-contratado" src="/assets/svg/contratado.svg">
              <div>
                  <div class="item-content-card">
                      Contratado
                  </div>
                  <div class="font-size-18">
                      {{ operation.valor_contratado | currency: 'BRL' }}
                  </div>
              </div>
          </div>
          <div class="total-operation-item-content">
              <img class="total-opration-content-item-single"
                  alt="image-credito-contratado" src="/assets/svg/parcelas-vence-hoje.svg">
              <div>
                  <div class="item-content-card">
                      Parcelas vencendo hoje
                  </div>
                  <div class="font-size-18">
                      {{ operation.valor_vencendo | currency: 'BRL' }}
                  </div>
              </div>
          </div>
          <div class="total-operation-item-content">
              <img class="total-opration-content-item-single"
                  alt="image-parcelas-vencidas" src="/assets/svg/parcelas-vencidas.svg">
              <div>
                  <div class="item-content-card">
                      Parcelas vencidas
                  </div>
                  <div class="font-size-18">
                      {{ operation.valor_vencido | currency: 'BRL' }}
                  </div>
              </div>
          </div>
          <div class="total-operation-item-content">
              <img class="total-opration-content-item-single"
                  alt="image-proximos-vencimentos" src="/assets/svg/proximos-vencimentos.svg">

              <div>
                  <div class="item-content-card">
                      Próximos vencimentos
                  </div>
                  <div class="font-size-18">
                      {{ operation.valor_a_vencer | currency: 'BRL' }}
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>