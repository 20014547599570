import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { LimitModel } from '@src/core/domain/limits/limits.model';
import { TransactionLimitRepository } from '@src/core/repositories/transactional-limits/transactional-limits.repository';

@Injectable({
  providedIn: 'root'
})

export class GetTransactionalLimitAccount implements UseCase<any, LimitModel> {

  constructor(private getLimitAccount: TransactionLimitRepository) { }

  execute(param: any): Observable<LimitModel> {
    return this.getLimitAccount.getLimitAccount(param);
  }
}
