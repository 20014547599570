import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fibra-modal-increase-limit-pix',
  templateUrl: './modal-increase-limit-pix.component.html',
  styleUrls: ['./modal-increase-limit-pix.component.scss'],
})
export class ModalIncreaseLimitPixComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', { static: true }) content: HTMLElement;
  
  constructor(
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    //this.openModal(this.content);
  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal(value: any): void {
    this.closeEvent.emit(value);
    this.modalService.dismissAll();
  }
}
