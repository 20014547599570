<div class="col-sm-11 stepper">
  <div class="abas parcela-tab dados cards-contract d-block">
    <!-- CONTEUDO DO PAINEL -->
    <div class="card">
      <div class="header">
        <div class="row p-0 m-0">
          <div
            *ngFor="let step of stepObj"
            class="col-6 step"
            [ngClass]="{
              step1: step.step === 1,
              step2: step.step === 2,
              step3: step.step === 3
            }"
          >
            <div class="d-inline-flex number">{{ step.step }}</div>
            <p class="d-inline">{{ step.name }}</p>
          </div>
        </div>
      </div>
      <div class="card-content step-1 d-block">
        <ng-container *ngTemplateOutlet="firstStep"></ng-container>
      </div>
      <div class="card-content step-2 d-none">
        <ng-container *ngTemplateOutlet="secondStep"></ng-container>
      </div>
      <div class="card-content step-2 d-none" *ngIf="thirdStep">
        <ng-container *ngTemplateOutlet="thirdStep"></ng-container>
      </div>
    </div>
  </div>
</div>
