import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    Output,
    EventEmitter,
    Input,
} from '@angular/core';
import {ToastErrorMessageComponent} from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsalService } from '@azure/msal-angular';
import { NavigationStart, Router } from '@angular/router';
import { SendLoginUsecase } from '@src/core/usecases/login/send-login-usecases';
import { PostOtpUsecase } from '@src/core/usecases/otp/post-otp.usecase';
import { finalize } from 'rxjs/operators';

/**
 * @description - api_callback é o code de verificação de api e define para qual o payload é enviado
 * @example Valor numérico para cada api chamada
 * PagamentoSimples = 1,
 * PagamentoMutiplo = 2,
 * Transferencia = 3,
 * MatrizAcessosGravar = 4,
 * MatrizAcessosAtualizarRegra = 5,
 * MatrizAcessosDelete = 6,
 * MatrizAcessosStatus = 8,
 */

@Component({
    selector: 'fibra-modal-validation',
    templateUrl: './modal-validation.component.html',
    styleUrls: ['./modal-validation.component.scss'],
})
export class ModalValidationComponent implements OnInit, AfterViewInit {
    public deactivate: boolean;
    public sending: boolean;
    public iconPassword = true;
    public msgPasswordError: boolean;
    public msgPasswordBlocked: boolean;
    public msgApiError: boolean;
    public countPass = 4;
    public loading = false;
    public password: any = '';
    public tokenConfirmation: any = '';
    public REDCOLOR = '#ED6C6C';
    public typeColorToast: string;

    @Input() apiCallback: number;
    @Input() apiPayload: any;
    @Input() requestData;
    @Input() TitleButton = 'Confirmar';
    @Input() handleInternalError = true;

    @Output() closeEvent: EventEmitter<any>;
    @Output() responseValidation: EventEmitter<any>;
    @ViewChild('content', { static: true }) content: HTMLElement;

    constructor(
        private router: Router,
        private postLogin: SendLoginUsecase,
        private postOtp: PostOtpUsecase,
        private modalService: NgbModal,
        private authService: MsalService
    ) {
        this.closeEvent = new EventEmitter();
        this.responseValidation = new EventEmitter();
    }

    
    @ViewChild(ToastErrorMessageComponent, null)
    toast: ToastErrorMessageComponent;

    ngAfterViewInit(): void {
        this.afterInit();
    }

    ngOnInit(): void {
        this.openModal(this.content);
        this.typeColorToast = this.REDCOLOR;
    }

    private afterInit() {
        const elem = document.getElementById('password');
        if (elem) {
            elem.focus();
        }
        this.deactivate = true;
    }

    public getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    public openModal(content) {
        let modal =this.modalService.open(content, {
            centered: true,
            size: 'lg',
            windowClass: 'modal-xxl modal-holder',
            backdrop: 'static',
        });
        modal.result.then(()=>{

        }).catch(()=>{
            this.closeEvent.emit(false);
            this.modalService.dismissAll();
        });
        
    }

    public closeModal() {
        this.closeEvent.emit(false);
        this.modalService.dismissAll();
    }

    public closeError() {
        this.msgPasswordError = false;
        this.msgApiError = false;
    }

    public onChangePassword(password: string) {
        this.password = password;
        this.deactivate =
            this.password.length <= 5 || this.tokenConfirmation.length <= 5;
    }

    public onChangeToken(token: string) {
        this.tokenConfirmation = token;
        this.deactivate =
            this.password.length <= 5 || this.tokenConfirmation.length <= 5;
    }

    public showPassword() {
        this.iconPassword = !this.iconPassword;
        const typePassword = document.getElementById(
            'password'
        ) as HTMLInputElement;
        typePassword.type === 'text'
            ? (typePassword.type = 'password')
            : (typePassword.type = 'text');
    }

    public verifyPasswordOld() {
        this.sending = true;
        this.loading = true;
        this.TitleButton = 'Validando dados...';

        const userData = {
            username: localStorage.getItem('useremail'),
            password: this.password,
            otp: this.tokenConfirmation,
        };

        if (this.msgPasswordBlocked === true) {
            this.logout();
            return;
        }

        this.postLogin
            .execute(userData)
            .pipe(finalize(() => this.sending = false))
            .subscribe(this.requestSuccess, this.requestError);
    }

    verifyPassword() {
        this.sending = true;
        this.loading = true;
        this.TitleButton = 'Validando dados...';

        const params = {
            password: this.password,
            otp: this.tokenConfirmation,
            api_callback: Number(this.apiCallback),
            payload: this.apiPayload
        };

        if (this.msgPasswordBlocked === true) {
            this.logout();
            return;
        }
        
        this.postOtp
            .execute(params)
            .pipe(finalize(() => this.sending = false))
            .subscribe(this.requestSuccess, this.requestError);
    }

    private requestSuccess = (value) => {
        this.msgPasswordError = true;
        this.closeModalSuccess(value);
    }

    public requestBlocked() {
        this.countPass--;
        this.msgPasswordError = false;
        this.msgPasswordBlocked = true;
        this.loading = false;
        this.TitleButton = 'Sair do sistema';
    }

    public closeModalSuccess = (value) => {
        this.responseValidation.emit(value);
        this.modalService.dismissAll();
    }

    private requestError = (err) => {
        if (err.error && err.error.code === 500 && this.handleInternalError) {
            this.errorApi(err);
        } else if (err.error && err.error.code === 406){
            this.errorPassword(err);
        } else {
            this.businessError(err);
        }        
    }

    private businessError = err => {
        this.closeModalSuccess(err);
    }

    private errorApi = err => {
        this.closeModal();
        this.loading = false;
        this.TitleButton = 'Confirmar'; 
        this.responseValidation.emit(err);
    }

    private errorPassword = err => {
        this.countPass--;
        this.msgPasswordError = true;
        this.loading = false;
        this.TitleButton = 'Confirmar';
        if (this.countPass === 0) {
            this.requestBlocked();
        }
    }

    public logoutOff() {
        this.authService.logout();
        localStorage.clear();
        this.closeModal();
        this.router.navigate(['/']);
    }

    public logout() {
        this.authService.logout();
        localStorage.clear();
    }
}
