import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
import { FilterComponent } from "../../filter/filter.component";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";
import * as Util from "@src/shared/util-common";
import { DdaHistoryModel } from "@src/core/domain/dda/history.model";
import { DdaHistoryImplementationRepository } from "@src/data/repository/dda/history/dda-history.implementation.repository";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "fibra-historico",
  templateUrl: "./historico.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
})
export class DdaHistoricoComponent implements OnInit, AfterContentChecked  {
  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  @ViewChild(FilterComponent, null) filterComp: FilterComponent;

  empresa: any = null;
  historicos: DdaHistoryModel[] = [];

  isLoading: boolean = false;
  eventTabs: string = "Vencido";

  util = Util;
  selected: [] = [];

  constructor(public historyRepository: DdaHistoryImplementationRepository, private changeDetector : ChangeDetectorRef) {}
  ngOnInit(): void {
    this.obterDadosEmpresa();
  }

  ngAfterContentChecked() : void {
    this.changeDetector.detectChanges();
  }

  obterDadosEmpresa(): void {
    this.empresa = JSON.parse(sessionStorage.getItem("defaultCompany"))[0];
  }

  getDates(): any {
    let datas = [
      ...new Set(
        this.historicos.map((item) =>
          moment(item.data_hora).format("YYYY-MM-DD")
        )
      ),
    ];
    datas.filter((n, i) => datas.indexOf(n) == i);
    return datas;
  }

  getTransactionByDate(dt: string): any {
    return this.historicos.filter(
      (d) => moment(d.data_hora).format("YYYY-MM-DD") === dt
    );
  }

  setFilter(event: { from: NgbDate, to: NgbDate }): void {
    this.isLoading = true;

    this.historyRepository
      .get({
        nu_cnpj: this.empresa.cpfCnpj,
        data_inicial: `${event.from.year}-${event.from.month.toString().padStart(2, "0")}-${event.from.day.toString().padStart(2, "0")}T00:00:00`,
        data_final: `${event.to.year}-${event.to.month.toString().padStart(2, "0")}-${event.to.day.toString().padStart(2, "0")}T23:59:59`,
        pagina_atual: 1,
        quantidade_registros: 50,
      })
      .subscribe(
        (result: any) => {
          this.fetchDataSource(result);
        },
        (error: any) => {
          this.fetchDataSourceError(error);
        }
      );
  }

  fetchDataSource(result: Array<DdaHistoryModel>): void {
    this.historicos = result;
    this.isLoading = false;
  }

  fetchDataSourceError(error: any): void {
    this.historicos = [];
    this.isLoading = false;
  }

  verifyCalendar(event: any): void {}

  verificaSelected(i: any) {
    return this.selected.find((x) => x == i) != null;
  }
}
