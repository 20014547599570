import {
  TransactionDetailModel,
  TransactionDetailRequest,
  TransacaoAprovadorResponse
} from './../../domain/transaction/transaction.model';
import { Observable } from 'rxjs';

export abstract class TransactionDetailRepository {
  abstract getDetail(
    params: TransactionDetailRequest
  ): Observable<TransactionDetailModel>;

  abstract getAprovadoresPendentesDda(transacaoId: number): Observable<TransactionDetailModel<TransacaoAprovadorResponse>>;
}
