<div class="main-selector">
    <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" id="content-body body-content" id="body-scroll" *fibraHasRole="'APROV_TRANS.CONS_TRANS,APROV_TRANS.APROV_REPROV_TRANS'">
    <fibra-breadcrumb infoAccount="Open Finance" [roule]="title" arrow="false" [linkExternoTitulo]="'Acessar Portal do Cidadão'" [linkExternoUrl]="'https://openbankingbrasil.org.br/'"></fibra-breadcrumb>
    <div *ngIf="loading; else presentation" class="loading">
        <div class="d-flex justify-content-center position loading-container loadingView">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <ng-template #presentation>
        <div [ngSwitch]="currentPresentation">
            <div class="home" *ngSwitchCase="HOME">
                <fibra-openbanking-home [onBoardingDone]="onBoardingDone" [termsOfUseAccepted]="termsOfUseAccepted" (onReverOnboarding)="onReverOnboarding()" (onReverTermoDeUso)="onReverTermoDeUso()"></fibra-openbanking-home>
            </div>
            <div *ngSwitchCase="ONBOARDING">
                <div class="box">
                    <img class="termsofuse-arrow" src="/assets/svg/openbankin-termsofuse-backarrow.svg" (click)="backToHome()">
                    <span style="margin-left: 5px;">Voltar</span>
                </div>
                <div class="onboarding">
                    <fibra-openbanking-onboarding [readonly]="onBoardingDone" [canCancel]="false" (onOk)="okOnboarding()"></fibra-openbanking-onboarding>
                </div>            
            </div>
            <div *ngSwitchCase="TERMSOFUSE">
                <div class="box">
                    <img class="termsofuse-arrow" src="/assets/svg/openbankin-termsofuse-backarrow.svg" (click)="backToHome()">
                    <span style="margin-left: 5px;">Voltar</span>
                </div>
                <div class="termsofuse" >
                    <fibra-accept-doc class="termsofuseviewer" [doc]="termsOfUse" (confirm)="acceptTermsOfUse()"></fibra-accept-doc>
                </div>            
            </div>
        </div>
    </ng-template>        
</div>