import { Component, EventEmitter, OnInit, Output, ViewChild, OnDestroy, Input } from '@angular/core';
import { map, finalize, catchError } from 'rxjs/operators';
import { Observable, Subscription, forkJoin, throwError, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PixGetKeysDataRepository } from '@src/data/repository/pix/keys/pix-get-keys-repository';
import { PixCreateKeyDataRepository } from '@src/data/repository/pix/keys/pix-create-keys-repository';
import { PixGetAccountsDataRepository } from '@src/data/repository/pix/accounts/pix-get-accounts-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-pix-keys-approvals',
  templateUrl: './pix-keys-approvals.component.html',
  styleUrls: ['./pix-keys-approvals.component.scss']
})
export class PixKeysApprovalsComponent implements OnInit, OnDestroy {  

  public keySolicitation;

  @ViewChild('content', { static: true }) content: HTMLElement;
  @ViewChild('contentSms', { static: true }) contentSms: HTMLElement;
  @ViewChild('contentEmail', { static: true }) contentEmail: HTMLElement;
  @ViewChild('contentReprove', { static: true }) contentReprove: HTMLElement;
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @Output() onTemPendenciasChanged: EventEmitter<any> = new EventEmitter();

  @Input() public temPendenciasChave = false;
  @Input() public temPendenciasDoacao = false;
  @Input() public temPendenciasRequisicao = false;
  @Input() public subMenu: string = null;

  public get temPendencias(): boolean {
    return this.temPendenciasChave || this.temPendenciasDoacao || this.temPendenciasRequisicao;
  }

  public enable = false;

  public selectedNavBar: string = 'keys';
  public seletedFilter: string = 'pending';

  public apiCallback: any;
  public showModalValidation: boolean = false;;
  public payload: any;
  public loading = false
  public userEmail;

  public keyapproved = [];
  public keysRefused = [];
  public keyPending = [];
  public keys;
  public selectedKEy;
  public showTerms = false;
  public selectedAccount;

  public warnTitle;
  public warnSubtitle;

  public warnSucces = false;
  public warnPending = false;
  public warnRefused = false
  public showKeys = true;

  public responseKey;

  public minutes = 1;
  public interval;
  public counter = 0;
  public timeoutToken = false;
  public codValidacao: any;
  public isCodInvalid = false;
  public email: any;
  public numCelular: any;
  public token = '';
  public tokenMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  public keyapprovedReceivedRequest = [];
  public keysRefusedReceivedRequest = [];
  public keyPendingReceivedRequest = [];

  public keyapprovedSolicitationSend = [];
  public keysRefusedSolicitationSend = [];
  public keyPendingSolicitationSend = [];

  isRefused = false;

  public textBntPrimary;
  public texBntSecondary;
  public showBtnSecondary;

  public isApproved: boolean;

  public msgError = "Ocorreu um erro no envio";
  public subTextError = "Tente novamente!"

  public isConfirmationSendSolitationKey = false;

  private _subGetKeysApproved: Subscription;
  private _subGetSolicitaionsSend: Subscription;
  private _subGetRequestReceived: Subscription;

  constructor(
    private modalService: NgbModal,
    public getKeys: PixGetKeysDataRepository,
    public createKeyService: PixCreateKeyDataRepository,
    public accountService: PixGetAccountsDataRepository,
  ) { }

  ngOnInit() {
    this.userEmail = localStorage.getItem('useremail');    

    if (this.subMenu == 'solicitacoesenviadas') {
      this.handleSelecterdNav('sendSolicitation'); 
      this.getSolicitaionsSend();
    } else if (this.subMenu == 'solicitacoesrecebidas') {
      this.handleSelecterdNav('receiveRequest');
      this.getRequestReceived();
    } else {
      this.handleSelecterdNav('keys')
      this.getKeysApproved();
    }    
    
    //iniciando recusa ou aprovação de solicitação pelo modal de aviso 
    
    this.keySolicitation = JSON.parse(window.sessionStorage.getItem('key'));

    if (this.keySolicitation) {
      this.handleSelecterdNav('receiveRequest');

      if (this.keySolicitation.isApprovals) {
        this.approveKeys(this.keySolicitation.key);
        this.isApproved = true
      } else {
        if (this.keySolicitation.key.fl_posse) {
          this.sendToken(this.keySolicitation.key);
          this.isApproved = false
        } else {
          this.selectedKEy(this.keySolicitation.key);
          this.isApproved = false;
        }
      }

      sessionStorage.removeItem('key')
    }    
  }

  ngOnDestroy(): void {
    if (this._subGetKeysApproved) {
      this._subGetKeysApproved.unsubscribe();
    }

    if (this._subGetSolicitaionsSend) {
      this._subGetSolicitaionsSend.unsubscribe();
    }

    if (this._subGetRequestReceived) {
      this._subGetRequestReceived.unsubscribe();
    }    
  }

  goToKeys(account) {
    this.accountService.changeSelectedAccount(account).subscribe((res) => {
      localStorage.setItem('conta-selecionada', account);
      localStorage.setItem('view-selecionada', 'minhaschaves-chaves');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
      this.loading = false;


    }, (error) => {
      this.loading = false;

    })
  }

  goToTransf() {
    localStorage.setItem('view-selecionada', 'transferir-transferir-forcechangetab');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  handleSelecterdNav(type: any) {
    this.selectedNavBar = type;
  }

  handleFilter(type: any) {
    this.seletedFilter = type;
  }

  openTerm() {
    this.showTerms = true;
    this.selectedAccount = { num_conta: this.selectedKEy.num_conta, desc_conta: this.selectedKEy.conta.desc_conta, isApprovals: 'refusedKEy', operation: this.selectedKEy.cod_operacao_chave };
    this.closeModal("");

  }

  approveKeys(key) {
    this.selectedKEy = key;
    this.payload = { id_operacao: key.cod_operacao_chave, token_validacao: this.token, cod_validacao: this.codValidacao }
    this.apiCallback = 89;
    this.showModalValidation = true;
  }

  getAprovadores(lista) {
    for (let index = 0; index < lista.length; index++) {
      if(lista[index].status=="REPROVADO")
      {
        return lista[index].pessoa.nome_pessoa        
      }
    }
    
    return "integrando";    
  }

  private resolveError(err: any): Observable<any[]> {
    if (err.error.code == 404 && err.error.status == 'success') {
      return of([]);
    }
    
    return throwError(err);
  }

  private doGetKeysApproved(): Observable<any> {
    this.keyapproved = [];
    this.keysRefused = [];
    this.keyPending = [];
    
    return this.getKeys.getKeysApproved().pipe(
      map(data => {
        this.keys = data['data'];

        this.keys.map((key: any) => {
          if (key.ds_status_consolidado_resumido === 'APROVADO') {
            this.keyapproved.push(key)
          }
          if (key.ds_status_consolidado_resumido === 'PENDENTE') {
            this.keyPending.push(key)
          }
          if (key.ds_status_consolidado_resumido === 'RECUSADO') {
            this.keysRefused.push(key)
          }
        });
      }),
      catchError(this.resolveError));
  }

  public getKeysApproved(): void {
    this.loading = true;

    if (this._subGetKeysApproved) {
      this._subGetKeysApproved.unsubscribe();
    }

    this._subGetKeysApproved = this.doGetKeysApproved()
      .pipe(
        finalize(() => this.loading = false),
        catchError(err => {
          console.log(err);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.temPendenciasChave = false;

        this.keyPending.map(rs => {
          let aprov = new Array(rs)

          if (aprov.filter(ap=> ap.status === 'PENDENTE').length > 0) {
            this.temPendenciasChave = true;            
          }
        });

        this.onTemPendenciasChanged.emit(this.temPendencias);
      });
  }

  private doGetSolicitaionsSend(): Observable<any> {
    this.keyapprovedSolicitationSend = [];
    this.keysRefusedSolicitationSend = [];
    this.keyPendingSolicitationSend = [];

    const obs1 = this.getKeys.getSolicitationSendApproved().pipe(
      map((data: any) => {
        this.keyapprovedSolicitationSend = data['data'];
      }),
      catchError(this.resolveError));

    const obs2 = this.getKeys.getSolicitationSendPending().pipe(
      map((data: any) => {
        this.keyPendingSolicitationSend = data['data'];
      }),
      catchError(this.resolveError));

    const obs3 = this.getKeys.getSolicitationSendRefused().pipe(
      map((data: any) => {
        this.keysRefusedSolicitationSend = data['data'];
      }),
      catchError(this.resolveError));

    return forkJoin([obs1, obs2, obs3]);
  }

  public getSolicitaionsSend(): void {
    this.loading = true;

    if (this._subGetSolicitaionsSend) {
      this._subGetSolicitaionsSend.unsubscribe();
    }

    this._subGetSolicitaionsSend = this.doGetSolicitaionsSend()
      .pipe(
        finalize(() => this.loading = false),
        catchError(err => {
          console.log(err);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.temPendenciasRequisicao = this.keyPendingSolicitationSend.length > 0;
        this.onTemPendenciasChanged.emit(this.temPendencias);
      });
  }

  private doGetRequestReceived(): Observable<any> {
    this.keyapprovedReceivedRequest = [];
    this.keysRefusedReceivedRequest = [];
    this.keyPendingReceivedRequest = [];

    const obs1 = this.getKeys.getRequestReceivedApproved().pipe(
      map((data: any) => {
        this.keyapprovedReceivedRequest = data['data'];
      }),
      catchError(this.resolveError));

    const obs2 = this.getKeys.getRequestReceivedPending().pipe(
      map((data: any) => {
        this.keyPendingReceivedRequest = data['data'];
      }),
      catchError(this.resolveError));

    const obs3 = this.getKeys.getRequestReceivedRefused().pipe(
      map((data: any) => {
        this.keysRefusedReceivedRequest = data['data'];
      }),
      catchError(this.resolveError));

    return forkJoin([obs1, obs2, obs3]);
  }

  public getRequestReceived(): void {
    this.loading = true;

    if (this._subGetRequestReceived) {
      this._subGetRequestReceived.unsubscribe();
    }

    this._subGetRequestReceived = this.doGetRequestReceived()
      .pipe(
        finalize(() => this.loading = false),
        catchError(err => {
          console.log(err);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.temPendenciasDoacao = this.keyPendingReceivedRequest.length > 0;
        this.onTemPendenciasChanged.emit(this.temPendencias);
      });
  }
  
  //show modal de validação token/senha - iniciado apicalback 89 - confirmações 
  openModal(content, key): void {

    this.selectedKEy = key;
    this.payload = key.cod_operacao_chave;
    this.apiCallback = 89;
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }
  isConfirmationSendSolitation() {
    this.isConfirmationSendSolitationKey = true;
  }

  //metodo para cancelamento de cadastro, exclusão, cancelamento de portabilidade/revindicação recebidas
  refusedKey() {
    this.payload = { id_operacao: this.selectedKEy.cod_operacao_chave, token_validacao: this.token, cod_validacao: this.codValidacao }
    this.closeModal('');
    this.apiCallback = 90;
    this.showModalValidation = true;
  }

  refuseKeyPort(key) {
    this.selectedKEy = key;
    this.payload = { id_operacao: key.cod_operacao_chave, token_validacao: this.token, cod_validacao: this.codValidacao }
    this.apiCallback = 90;
    this.showModalValidation = true;
  }

  //metodo de cancelamento de envio de revindicação ou portabilidade chaves - cancelamento avulso
  cancelSolictionKey(key) {
    this.selectedKEy = key;
    this.payload = key.cod_operacao_chave;
    this.closeModal('');
    this.apiCallback = key.fl_posse && key.fl_processado_bacen ? 93 : key.fl_portabilidade && key.fl_processado_bacen? 94 : 0  ;
    this.showModalValidation = true;
  }
  //respota do componente de validação de senha e token
  responseApiModal($event) {

    this.showModalValidation = false;

    if (this.apiCallback === 89 && $event.error && $event.error.code && $event.error.code === 400 && $event.message && $event.message.length) {
      this.toast.callModalMessage(null, $event.error.message[0], null, false, true);
      this.handleSelecterdNav('keys');
      this.getKeysApproved();
      return ;
    }

    this.responseKey = $event['data'];
    this.showKeys = false;

    //cancelamento avulso de revindicação
    if (this.apiCallback === 93 || this.apiCallback === 94) {
      //cancelamento realizado com sucesso
      if ($event.code === 200) {
        this.warnRefused = true;
        this.showKeys = false;
        this.warnTitle = `Solicitação cancelada`
        this.warnSubtitle = `A solicitação enviada da chave ${this.maskKeys(this.selectedKEy)} foi recusada por você.`
        //Ocorreu algum tipo de erro no bacen
      } else {
        this.loading = false;
        this.showKeys = true;
        this.msgError = "Ocorreu um erro no envio";
        const message = document.getElementById('message');
        message.classList.add('show');
      }

      //status revindicação
    } else if ($event['data'].fl_posse) {
      this.textBntPrimary = 'Fechar';
      this.showBtnSecondary = false;
      if ($event['data'].ds_status_consolidado === 'IB_AGENDADO') {
        this.warnPending = true;
        this.warnTitle = "Reivindicação de chave pendente "
        this.textBntPrimary = 'Fechar';
        this.warnSubtitle = `Sua efetivação ocorrerá dentro do horário do fucionamento do BACEN.`
        //cancelamento de revindicação visão do portado da chave
      } else if (this.apiCallback === 90) {
        //cancelamento de revindicação interno
        if ($event['data'].ds_status_consolidado === 'IB_APROVACAO_REPROVADA') {
          this.warnRefused = true;
          this.showKeys = false;
          this.warnTitle = `Reivindicação recusada`
          this.warnSubtitle = `A solicitação de reivindicação da chave ${this.maskKeys(this.selectedKEy)} foi recusada por você.`
          //Cancelamento de solicitação reprovado pelo doar
        } else if ($event['data'].status === 'REPROVADO') {
          this.warnSucces = true;
          this.showKeys = false;
          this.warnTitle = `Validamos sua posse de chave de ${this.stringsKeys(this.selectedKEy)}`
          this.warnSubtitle = `A solicitação de reivindicação de posse por terceiro da chave  ${this.maskKeys(this.selectedKEy)} foi recusada por você`
          //cancelamento de solicitação pendente de aprovação de outro master
        } else if ($event['data'].status === 'PENDENTE') {
          this.warnPending = true;
          this.showKeys = false;
          this.warnTitle = `Validamos sua posse de chave de ${this.stringsKeys(this.selectedKEy)}`
          this.warnSubtitle = `A solicitação de posse da chave  ${this.maskKeys(this.selectedKEy)}
          foi recusada por você, mas o(s) outro(s) master(s) da sua empresa precisa(m) aprovar.`
        }
        //confirmação de envio de solicitação, feito depois da aprovação interna dos masters, antes enviar a solictação
      } else if (this.isConfirmationSendSolitationKey && $event['data'].status === 'APROVADO') {
        this.warnSucces = true;
        this.showKeys = false;
        this.warnTitle = `Solicitação de reivindicação enviada`
        this.warnSubtitle = `A reivindicação da chave  ${this.maskKeys(this.selectedKEy)} foi feita com sucesso. 
        Lembrando que o usuário que está de posse da chave terá 7 dias para responder a solicitação.`
        //reprovação interna de envio de solicitação, um master reprovou o envio da solicitção
      } else if (this.isConfirmationSendSolitationKey && $event['data'].status === 'REPROVADO') {
        this.warnRefused = true;
        this.showKeys = false;
        this.warnTitle = `Reivindicação recusada`
        this.warnSubtitle = `A solicitação de reivindicação da chave
      ${this.maskKeys(this.selectedKEy)}
      foi recusada por você.`
        //solicitação de envio aguardando aprovação de outros masters
      } else if (this.isConfirmationSendSolitationKey && $event['data'].status === 'PENDENTE') {
        this.warnPending = true;
        this.showKeys = false;
        this.warnTitle = `Reivindicação pendente`
        this.warnSubtitle = `A solicitação de reivindicação da chave ${this.maskKeys(this.selectedKEy)} está pendente de aprovação de outro(s) master(s) da sua empresa.`
      } else if ($event['data'].status === 'APROVADO') {
        this.warnSucces = true;
        this.showKeys = false;
        this.warnTitle = `Reivindicação aceita`
        this.warnSubtitle = `A solicitação de reivindicação da chave
      ${this.maskKeys(this.selectedKEy)}
      foi aceita por você.`
      }
    } else if ($event['data'].fl_portabilidade) {
      this.textBntPrimary = 'Fechar';
      this.showBtnSecondary = false;
      if ($event['data'].ds_status_consolidado === 'IB_AGENDADO') {
        this.warnPending = true;
        this.warnTitle = "Portabilidade de chave pendente "
        this.textBntPrimary = 'Fechar';
        this.warnSubtitle = `Sua efetivação ocorrerá dentro do horário do fucionamento do BACEN.`

      } else if (this.apiCallback === 90 || this.apiCallback === 89) {
        if (
            $event['data'].ds_status_consolidado === 'IB_APROVACAO_REPROVADA' ||
            $event['data'].ds_status_consolidado === "BACEN_REIVINDICPORTAB_REVOGADA_DOADOR"
        ) {
          this.warnRefused = true;
          this.showKeys = false;
          this.warnTitle = `Portabilidade recusada`
          this.warnSubtitle = `A solicitação de portabilidade da chave ${this.maskKeys(this.selectedKEy)} 
          foi recusada por você.`
        } else if ($event['data'].status === 'PENDENTE') {
          this.warnPending = true;
          this.showKeys = false;
          this.warnTitle = `Portabilidade pendente`
          this.warnSubtitle = `A solicitação de portabilidade da chave  ${this.maskKeys(this.selectedKEy)}
          foi aprovada por você, mas o(s) outro(s) master(s) da sua empresa precisa(m) aprovar.`
        } else if ($event['data'].status === 'APROVADO') {
          this.warnSucces = true;
          this.showKeys = false;
          this.warnTitle = `Portabilidade aceita`
          this.warnSubtitle = `A solicitação de portabilidade da chave ${this.maskKeys(this.selectedKEy)}
          foi aceita por você.`
        } else if ($event['data'].status === 'Chave pendente de cadastro no DICT') {
          this.warnSucces = true;
          this.showKeys = false;
          this.warnTitle = `Solicitação de cadastro enviada`
          this.warnSubtitle = `Chave pendente de cadastro no BACEN`
        } else if ($event['data'].status === 'PENDENTE') {
          this.warnPending = true;
          this.showKeys = false;
          this.warnTitle = `Portabilidade pendente`
          this.warnSubtitle = `A solicitação de portabilidade da chave ${this.maskKeys(this.selectedKEy)}
          está pendente de aprovação de outro(s) master(s) da sua empresa.`
        }
      }
      //Aprovação de cadastro de chaves 'lisas'
    } else if ($event.code === 200 && $event['data'].tp_operacao === 'CRIAR') {
      this.textBntPrimary = 'Voltar';
      this.texBntSecondary = ' Acompanhar status';
      this.showBtnSecondary = true;
      //Aprovação de cadastro de chaves
      if ($event['data'].ds_status_consolidado === 'IB_AGENDADO') {
        this.warnPending = true;
        this.warnTitle = "Cadastro de chave pendente "
        this.textBntPrimary = 'Fechar';
        this.warnSubtitle = `Sua efetivação ocorrerá dentro do horário do fucionamento do BACEN.`
      } else if ($event['data'].status === 'APROVADO') {
        this.texBntSecondary = 'Fazer uma transferência';
        this.warnTitle = "Chave Pix cadastrada"
        this.warnSubtitle = `Compartilhe a chave ${$event.data.chave ? $event.data.chave : 'Aleátoria'} sempre que quiser receber uma transferência.`
        this.warnSucces = true;
      }
      //aprovação de cadastro de chaves pendente de aprovação de outro master
      if ($event['data'].status === 'PENDENTE') {
        this.textBntPrimary = 'Voltar';
        this.texBntSecondary = ' Acompanhar status';
        this.showBtnSecondary = true;
        this.warnPending = true;
        this.warnTitle = "Cadastro de chave pendente "
        this.warnSubtitle = `Solicitação enviada para aprovação do(s) master(s) da sua empresa.`
      }
      //reprovação de cadastro feito por algum master
      if ($event['data'].status === 'REPROVADO') {
        this.warnRefused = true;
        this.showKeys = false;
        this.warnTitle = "Cadastro de chave recusada"
        this.warnSubtitle = `Você recusou a chave  ${this.maskKeys(this.selectedKEy)}
        para a conta ${this.selectedKEy.num_conta} - ${this.selectedKEy.conta.desc_conta}`
      }
      //exclusão de chaves 
    } else if ($event.code === 200 && $event['data'].tp_operacao === 'EXCLUIR') {
      if ($event['data'].ds_status_consolidado === 'IB_AGENDADO') {
        this.warnPending = true;
        this.warnTitle = "Exclusão de chave pendente "
        this.textBntPrimary = 'Fechar';
        this.warnSubtitle = `Sua efetivação ocorrerá dentro do horário do fucionamento do BACEN.`
        //exclusão aprovada
      } else if ($event['data'].status === 'APROVADO') {
        this.warnSucces = true;
        this.showKeys = false;
        this.textBntPrimary = 'Voltar'
        this.warnTitle = "Exclusão de chave aprovada"
        this.warnSubtitle = `Você aprovou a exclusão da chave ${this.maskKeys(this.selectedKEy)} para a conta ${this.selectedKEy.num_conta} - ${this.selectedKEy.conta.desc_conta}`

      }
      //exclusão pendente de aprovação de outro master
      if ($event['data'].status === 'PENDENTE') {
        this.warnPending = true;
        this.warnTitle = "Cadastro de chave pendente "
        this.warnSubtitle = `Você aprovou a exclusão da chave  ${this.maskKeys(this.selectedKEy)} para a conta ${this.selectedKEy.num_conta} - ${this.selectedKEy.conta.desc_conta}, mas o(s) outro(s) master(s) precisa(m) aprovar.`
      }
      //exclusão cancelada, foi reprovado por um master
      if ($event['data'].status === 'REPROVADO') {
        this.warnRefused = true;
        this.showKeys = false;
        this.textBntPrimary = 'Voltar';
        this.warnTitle = "Exclusão de chave recusada"
        this.warnSubtitle = `Você recusou a chave  ${this.maskKeys(this.selectedKEy)}
        para a conta ${this.selectedKEy.num_conta} - ${this.selectedKEy.conta.desc_conta}`
      }
    }
  }

  goBack() {
    if (this.selectedNavBar === 'keys') {
      this.getKeysApproved();
    } else if (this.selectedNavBar === 'sendSolicitation') {
      this.getSolicitaionsSend();
    } else if (this.selectedNavBar === 'receiveRequest') {
      this.getRequestReceived();
    } 
    
    this.warnSucces = false;
    this.warnPending = false;
    this.warnRefused = false
    this.showKeys = true;
  }

  closeModalApi($event) {
    this.showModalValidation = false;
  }

  public closeModal($event): void {
    this.showModalValidation = false;
    this.modalService.dismissAll();
  }
  //verifica se existe algum tipo de aprovação de chave
  isPeddingotherMaster(approveds) {

    let isOtherMaster = false;
    approveds.map(data => {
      if (data.status === 'PENDENTE' && data.pode_aprovar && this.userEmail === data.pessoa.email) {
        isOtherMaster = true
      }
    })
    return isOtherMaster

  }

  responseApiModalTerms($event) {
    this.showModalValidation = false;
    this.showKeys = false;
    this.textBntPrimary = 'Voltar';
    this.texBntSecondary = ' Acompanhar status';
    this.showBtnSecondary = true;
    if ($event.code === 200 && $event['data'].tp_operacao === 'CRIAR') {
      if ($event['data'].status === 'APROVADO') {
        this.warnTitle = "Chave Pix cadastrada"
        this.warnSubtitle = `Compartilhe a chave ${this.maskKeys(this.selectedKEy)} sempre que quiser receber uma transferência.`
        this.warnSucces = true;
        this.texBntSecondary = ' Acompanhar status';

      }
      if ($event['data'].status === 'PENDENTE') {
        this.warnPending = true;
        this.warnTitle = "Cadastro de chave pendente "
        this.warnSubtitle = `Solicitação enviada para aprovação do(s) master(s) da sua empresa.`
      }

    }

  }

  changeTerm($event) {
    this.showTerms = false
  }

  //contador de segundos para validação positiva
  startTimer() {
    clearInterval(this.interval);
    this.timeoutToken = false;
    this.counter = 59;
    this.interval = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else {
        clearInterval(this.interval);
        if (this.minutes > 0) {
          this.minutes--;
          this.callTimer();
        } else {
          this.timeoutToken = true;
          clearInterval(this.interval);
          this.minutes = 0;
        }
      }
    }, 1000);
  }

  callTimer() {
    this.startTimer();
  }
  //serviço para receber token de validação, email, phone
  public sendToken(key: any) {
    this.token = "";
    this.selectedKEy = key;
    this.modalService.dismissAll();
    this.isCodInvalid = false;
    this.loading = true;
    key.tp_chave === 'EMAIL' ? this.email = key.chave : this.numCelular = key.chave
    if (key.tp_chave === 'EMAIL') {
      this.createKeyService.sendEmailToken(this.email).subscribe((res) => {
        this.loading = false;
        this.openModalValidation(this.contentEmail);
        this.codValidacao = res.data;
        this.minutes = 5;
        this.startTimer();
      });
    } else if (key.tp_chave === 'PHONE') {
      this.createKeyService.sendSmsToken(this.numCelular.substring(3, 14)).subscribe((res) => {
        this.loading = false;
        this.openModalValidation(this.contentSms);
        this.codValidacao = res.data;
        this.minutes = 5;
        this.startTimer();
      }, error => {

        this.loading = false;
        this.msgError = "Ocorreu um erro no envio";
        const message = document.getElementById('message');
        message.classList.add('show');

      });
    }
  }

  public openModalValidation(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }
  //metodo auxiliar para exibição de mascaras de chaves
  maskKeys(key) {
    switch (key.tp_chave) {
      case ('EMAIL'):
        return key.chave;
      case ('PHONE'):
        return `${key.chave.substring(0, 3)} ${key.chave.substring(3, 5)} ${key.chave.substring(5, 10)}-${key.chave.substring(10, 14)}`;
      case ('CPF'):
        return `${key.chave.substring(0, 3)}.${key.chave.substring(3, 6)}.${key.chave.substring(6, 9)}-${key.chave.substring(9, 12)}`;
      case ('CNPJ'):
        return `${key.chave.substring(0, 2)}.${key.chave.substring(2, 5)}.${key.chave.substring(5, 8)}/${key.chave.substring(8, 12)}-${key.chave.substring(12, 14)}`;
      case ('EVP'):
        return key.chave ? key.chave : 'aletória';
      default:
        break;
    }
  }

  stringsKeys(key) {
    switch (key.tp_chave) {
      case ('EMAIL'):
        return 'email';
      case ('PHONE'):
        return 'celular';
      case ('CPF'):
        return `CPF`;
      case ('CNPJ'):
        return `CNPJ`;
      default:
        break;
    }
  }

  public closeError() {
    this.msgError = "Ocorreu um erro no envio";
    const message = document.getElementById('message');
    message.classList.remove('show');
  }

  //metodo para validar se a data de agendamento de chave é hoje
  isToday(date): boolean {
    return moment().isSame(moment(date), 'day')
  }
 
}


