import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RolesService } from '@src/shared/services/roles.service';

@Component({
  selector: 'fibra-listing-tab',
  templateUrl: './listing-tab.component.html',
  styleUrls: ['./listing-tab.component.scss'],
})
export class ListingTabComponent implements OnInit {
  optionList = [];
  toggle = 1;
  @Output() selected: EventEmitter<any>;
  @Output() emitBack: EventEmitter<any>;

  constructor(private rolesService: RolesService) {
    
    this.selected = new EventEmitter();
    this.emitBack = new EventEmitter();
  }

  ngOnInit() {
    let roles = this.rolesService.get();

    if (roles) {

      if (roles.includes('COB.CONS_ARQ_TRANS') || roles.includes('QUALQUER_CONTA.COB.CONS_ARQ_TRANS')) {
        this.optionList.push( { option: 'Arquivos em trânsito', value: 1 } );
      }

      if (roles.includes('COB.CONS_POS_CART') || roles.includes('QUALQUER_CONTA.COB.CONS_POS_CART')) {
        this.optionList.push( { option: 'Posição de carteira', value: 2 } );
      }

      if (roles.includes('COB.CONS_INST_ADIC') || roles.includes('QUALQUER_CONTA.COB.CONS_INST_ADIC')) {
        this.optionList.push( { option: 'Instruções adicionadas', value: 3 } );
      }

      if (roles.includes('COB.CONS_FRANC') || roles.includes('QUALQUER_CONTA.COB.CONS_FRANC')) {
        this.optionList.push( { option: 'Francesinha', value: 4 } );
      }
    }
  }

  public tabSelected(option) {
    this.emitBack.emit(true);
    this.toggle = option.value;
    this.selected.emit(option);
  }
}