<div class="content" style="width: 100%;">
  <div class="div">
    <form class="flex" [formGroup]="filterForm">
      <div class="form-check form-check-inline" style="margin-right: 30px;justify-content: space-between;">
        <div class="dropdown-periodic">
          <span class="span"> Operação </span>
          <fibra-dropdown [style.width.px]="156" customClass="period" [options]="periods" class="margin"
            (change)="switchFilter($event)" textColor="#8f969d"></fibra-dropdown>
        </div>
        <div class="div" style="display: flex;align-items: center;margin-right: 20px;">
          <input type="radio" class="form-check-input" formControlName="filter" mdbInput
            (click)="changeFilterType('ser')" value="ser" />
          <div>
            <label class="form-check-label" for="digitableLine">Operações em ser</label>
          </div>
        </div>
        <div class="div" style="display: flex; flex-direction: row; align-items: center;">
          <input type="radio" class="form-check-input" formControlName="filter" mdbInput
            (click)="changeFilterType('contract')" value="contract" />
          <label class="form-check-label" for="opticalReader">Referência banco</label>
        </div>


        <input type="text" maxlength="10" placeholder="0000000000" class="line-code-bar" name="dtEmSer"
          formControlName="serDate" disabled *ngIf="filterBySer">
        <input type="text" maxlength="10" placeholder="0000000000" onlyNumber class="line-code-bar" name="contract"
          formControlName="contractNum" [(ngModel)]="contractNum" *ngIf="filterByContract">
        <div class="button-receipt">
          <button type="button" class="button-continue" (click)="sendWarrantyData()">
            Consultar
          </button>
        </div>
        <div>
          <br><br><br><br>
          <span class="field-required" *ngIf="(contractNum === '' || !contractNum) && fieldRequired">Campo obrigatório</span>      
        </div>
      </div>

    </form>
  </div>

  <div class="datatable" *ngIf="!loading && !noData && isRec && !hasError">
    <div class="table-title">
      <div class="table-item">
        <span class="item-font">Data de recepção</span>
      </div>
      <div class="table-item">
        <span class="item-font">Data de vencimento</span>
      </div>
      <div class="table-item">
        <span class="item-font">Moeda</span>
      </div>
      <div class="table-item">
        <span class="item-font">Valor</span>
      </div>

    </div>
    <div class="table-content" *ngFor="let received of receivedArray">
      <div class="type">
        <span class="type-font"> Ref.banco: {{received.num_contrato}} </span>
      </div>
      <div class="table-title">
        <div class="table-item">
          <span class="item-font">{{received.dt_op}}</span>
        </div>
        <div class="table-item">
          <span class="item-font">{{received.dt_vencto}}</span>
        </div>
        <div class="table-item">
          <span class="item-font">{{received.moeda}}</span>
        </div>
        <div class="table-item">
          <span class="item-font">{{received.valorFormatado}}</span>
        </div>

      </div>
    </div>
  </div>

  <div class="datatable" *ngIf="!loading && !noData && isEmi && !hasError">
    <div class="table-title">
      <div class="table-item-emitidas item-refbanco">
        <span class="item-font">Ref.banco</span>
      </div>
      <div class="table-item-emitidas item-dataemissao">
        <span class="item-font">Data de emissão</span>
      </div>
      <div class="table-item-emitidas item-datavencimento">
        <span class="item-font">Data de vencimento</span>
      </div>
      <div class="table-item-emitidas item-tipo">
        <span class="item-font">Tipo</span>
      </div>
      <div class="table-item-emitidas item-bancoavisador">
        <span class="item-font">Banco avisador</span>
      </div>
      <div class="table-item-emitidas item-moeda">
        <span class="item-font">Moeda</span>
      </div>
      <div class="table-item-emitidas item-valor">
        <span class="item-font">Valor</span>
      </div>

    </div>
    <div class="table-content" *ngFor="let issued of issuedArray">
      <div class="type">
        <span class="type-font"> Beneficiário: {{issued.nome_beneficiario}} </span>
      </div>
      <div class="table-title">
        <div class="table-item-emitidas item-refbanco">
          <span class="item-font">{{issued.num_contrato}}</span>
        </div>
        <div class="table-item-emitidas item-dataemissao">
          <span class="item-font">{{issued.dt_op}}</span>
        </div>
        <div class="table-item-emitidas item-datavencimento">
          <span class="item-font">{{issued.dt_vencto}}</span>
        </div>
        <div class="table-item-emitidas item-tipo">
          <span class="item-font">{{issued.modalidade | titlecase}}</span>
        </div>
        <div class="table-item-emitidas item-bancoavisador">
          <span class="item-font">{{issued.nome_banco_avisador}}</span>
        </div>
        <div class="table-item-emitidas item-moeda">
          <span class="item-font">{{issued.swift}}</span>
        </div>
        <div class="table-item-emitidas item-valor">
          <span class="item-font labelRight">{{issued.valorFormatado}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="datatable warning-display" *ngIf="loading">
    <div class="d-flex justify-content-center position loading-container">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <div class="datatable warning-display" *ngIf="noData && !loading">
    <div class="empty-info">
      <fibra-generic-info type="warn" title="Nenhum resultado encontrado"
        message="Tente uma nova pesquisa inserindo novos parâmetros.">
      </fibra-generic-info>
    </div>
  </div>

  <div class="datatable warning-display" *ngIf="hasError && !loading">
    <div class="empty-info">

      <fibra-generic-info type="error" title="Ocorreu um erro inesperado"
        message="Não foi possível encontrar dados de movimentação!">
      </fibra-generic-info>
    </div>
  </div>

</div>
