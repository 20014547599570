import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isArray } from 'util';
import { toTitleCase } from 'codelyzer/util/utils';
import { BalanceDataShare } from '@src/core/usecases/data-share/balance-data-share.service';
import { AccountDataShare } from '@src/core/usecases/data-share/account-data-share.service';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';
import { GetAccountsByFunctionalityUsecase } from '@src/core/usecases/account/get-accounts-by-functionality.usecase';
import { DropdownList } from '@src/core/domain/business-models';
import { GetNewTokenUsecase } from '@src/core/usecases/token/get-new-token-usecases';
import { TokenTypeModel, NewTokenRequest } from '@src/core/domain/token/tokenType.model';
import { Observable } from 'rxjs';
import { AccountTypeModel } from '@src/core/domain/account/accountType.model';
import { ToastErrorMessageComponent } from '../toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnInit {
  public optionsProfile: DropdownList;
  public selected: any;
  private defaultAccount: Array<any>;
  public loading: boolean;
  private isResponsive: boolean;

  @Input() public contasPermitidas: string[] = [];
  @Input() public funcionalidade: string = null;
  @Input() public filtrarFuncionalidade: boolean = false;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  public canSelect = conta => {
    if (this.funcionalidade != null) {
      if (!conta.tem_acesso) {
        this.toast.callModalMessage(null, "Para acessar o Extrato, entre em contato com o seu gerente de relacionamento.");
      }

      return conta.tem_acesso;
    } else {
      return true;
    }
  }

  constructor(
    private router: Router,
    private getNewToken: GetNewTokenUsecase,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private getAccountsByFuncionality: GetAccountsByFunctionalityUsecase,
    private shareAccount: AccountDataShare,
    private shareBalance: BalanceDataShare,
  ) {
    this.optionsProfile = [];
  }

  ngOnInit(): void {
    this.isResponsive = window.innerWidth <= 1081;
    this.requestAccount();
  }

  public requestAccount(): void {
    this.loading = true;

    let getAccounts: Observable<AccountTypeModel>;

    if (this.funcionalidade == null) {
      getAccounts = this.getAllAccountTypes.execute();
    } else {
      getAccounts = this.getAccountsByFuncionality.execute({ functionality: this.funcionalidade, filter: this.filtrarFuncionalidade });
    }

    getAccounts.subscribe(this.requestSuccess, this.requestError, this.requestComplete);
  }

  private requestError = (err) => {
    if (err && err.status === 401 || err && err.status === 0) {
      this.router.navigate(['/']);
    }
  }

  private formatValue(value, desc): string {
      return this.isResponsive ? value : `${value} - ${desc}` ;
  }

  public showBlockedBalanace(account): boolean {
    return (account.toUpperCase() === 'CHEQUE EMPRESA');
  }

  private requestSuccess = (value) => {
    this.optionsProfile = [];
    value.data.map((e, i) => {
      // se esta na lista de contas permitidas ou se podem aparecer todas
      if( (this.contasPermitidas.length  > 0 && this.contasPermitidas.includes(e.desc_conta)) || this.contasPermitidas.length === 0 ) {
        this.optionsProfile.push({
          indice: e.num_indice,
          name: this.formatValue(e.num_conta, e.exibicao),
          desc_conta: e.desc_conta,
          value: e.num_conta,
          balance: e.saldo,
          modality_account: e.cod_modalidade,
          selected: e.selecionado,
          tem_acesso: e.tem_acesso,
          exibicao: e.exibicao
        });
      }
    });

    // se nao estiver selecionado, seleciona a primeira
    if(this.optionsProfile.find(x => x.selected) == undefined){
      this.reloadSession(this.optionsProfile[0].indice);
    }
    else {
      const selectedAccount = this.accountSelecter(value.data);
      const selectedBalance = selectedAccount.saldo;
      if (selectedBalance) {
        this.shareBalance.setValue({ ...selectedBalance, exibicao: selectedAccount.exibicao });
      }
      this.defaultAccount = this.accountSelecter(this.optionsProfile);
      this.selected = this.defaultAccount[0].name;
      const accountBalance = {
        ...this.defaultAccount[0].balance,
        exibicao: this.defaultAccount[0].exibicao
      };
      const descAccount = toTitleCase(this.defaultAccount[0].desc_conta);
      this.shareAccount.setAccount(descAccount);
      this.shareBalance.setValue(accountBalance);
      this.shareAccount.setValue(value.data);
    }
  }

  private requestComplete = () => {
    const timeout = 400;
    setTimeout(() => {
      this.loading = false;
    }, timeout);
  }

  private accountSelecter = arr => {
    let isSelected: boolean;
    if (isArray(arr)) {
      isSelected = arr.some((e) => e.selected);
    }
    if (isSelected) {
      return arr.filter((e) => e.selected);
    }
    return arr[0];
  }

  private reloadSessionComplete = () => {
    location.reload();
    setTimeout(() => this.loading = false, 300);
  }

  reloadSession(indice: number) {
    if (indice) {      
      this.loading = true;
      this.getNewToken.execute({ indice }).subscribe(
        (response: TokenTypeModel) => {
        sessionStorage.setItem('x-ibpj-session', response.data.sessionToken);
      },
      null,
      this.reloadSessionComplete
      );
    }
  }
}
