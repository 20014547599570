import { Injectable } from '@angular/core';
import { AddressTypeModelRepository } from '@src/core/repositories/charge/address-type.repository';
import {
  AddressTypeModel,
  ChargeRequest,
} from '@src/core/domain/charge/address.model';
import { Observable } from 'rxjs';
import { AddressRepositoryMapper } from '@src/data/repository/charge/address/address-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { AddressEntity } from '@src/data/repository/charge/address/address.entity';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressDataRepository extends AddressTypeModelRepository {
  mapper = new AddressRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAddress(obj: ChargeRequest): Observable<AddressTypeModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<AddressEntity>(
        `${environment.URLBase}v1/correios/enderecos/cep/${obj.cep}`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
