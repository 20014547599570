import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { PendencyRepository } from '@src/core/repositories/dashboard/pendency.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetTemFibraFacilUseCase implements UseCase<void, boolean> {
  constructor(private pendencyRepository: PendencyRepository) {}

  execute(): Observable<boolean> {
    return this.pendencyRepository.getTemFibraFacil();
  }
}
