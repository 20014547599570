import { PendencyLimitsDataShare } from './../../../../core/usecases/data-share/pendency-limits-data-share.service';
import { Router } from '@angular/router';
import { TabListDataShare } from '@src/core/usecases/data-share/tab-list-data-share.service';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AccountDataShare, CompanySelectDataShare } from '@src/data/repository/data-share-repository';
import { MarkDashAsReadUsecase } from '@src/core/usecases/alteracoeslimites/markdashasread.usecase'
import { RolesService } from '@src/shared/services/roles.service';
import { DDAPendencyDataShare } from '@src/core/usecases/data-share/dda-pendency-data-share.service';
@Component({
  selector: 'fibra-card-pendency',
  templateUrl: './card-pendency.component.html',
  styleUrls: ['./card-pendency.component.scss'],
})
export class CardPendencyComponent implements OnInit  {
  public selected: string;
  public index: number;
  public account: any;
  public loading = true;
  public optionChangeOverdraft: string
  public labelCloseOverdraftLimit = "Mais informações"
  public exibicaoAccount: string;

  @ViewChild('cardContent', null) cardContent: ElementRef;

  @Input() dataPendency;
  @Input() dataPendenciesOpenBanking;
  @Input() parcelaAvencer:boolean;
  @Input() parcelaVencida:boolean;
  @Input() parcelaVencendo:boolean;
  @Input() showData: boolean;
  @Input() derivativePendency: boolean;
  @Input() hasNotificationPix: boolean;
  @Input() pendencyLimitsPix: boolean;
  @Input() pendencyLimitsTransacional: boolean;
  @Input() pendencyHours: boolean;
  @Input() hasLimitsMax: boolean;
  @Input() hasLimitsMaxPix: boolean;
  @Input() scrollOffSet: HTMLElement;
  @Input() hasOverdraftChange: boolean;
  @Input() ddaPendencies: number = 0;

  public showButton: boolean = false;
  constructor(
    private setTab: TabListDataShare, 
    private router: Router, 
    private pendencyLimitsDataShare: PendencyLimitsDataShare,
    private sharedAccount: AccountDataShare,
    private _markDashAsReadUsecase: MarkDashAsReadUsecase,
    private rolesService: RolesService,
    private pendencyDdaDataShare: DDAPendencyDataShare,
  ) {}

  ngOnInit(): void { 
      this.validateOverdraftChange();
  }

  public validateOverdraftChange(): void {

    if (this.hasOverdraftChange){
      
      this.sharedAccount.dataShared.subscribe(data => {
        if (data) {
          this.account = data.find(a => a.alteracaolimite && a.alteracaolimite.pendentereconhecimentocard);
  
          if (this.account) {
            this.account.alteracaolimite.acao = this.account.alteracaolimite.tipo == "Aumento" ? "aumentou" : this.account.alteracaolimite.tipo == "Redução" ? "foi reduzido" : "foi cancelado";
            this.exibicaoAccount = this.account.exibicao;
          }
  
          this.loading = false;
          
          switch (this.account.alteracaolimite.tipo) {
            case "Redução":
              this.optionChangeOverdraft = "reduziu";
              break;
            case "Aumento":
              this.optionChangeOverdraft = "aumentou";
              break;
            case "Cancelamento": 
              this.optionChangeOverdraft = "foi cancelado";
              this.labelCloseOverdraftLimit = "Fechar"
              this.exibicaoAccount = ""
            default:
              break;
          }
  
        }      
      });
      } 

  }

  public markCardAsRead(): void {
    this._markDashAsReadUsecase.execute(this.account.alteracaolimite.id).subscribe(
      () => {
        sessionStorage.setItem('cardOverdraftLimitChange', 'true');
        this.hasOverdraftChange = false;
        if(this.optionChangeOverdraft !== "foi cancelado"){
          this.router.navigate(['/loans'], { queryParams: { chequeempresa: true }});
        }
      },
      err => {
        this.hasOverdraftChange = false;
        if(this.optionChangeOverdraft !== "foi cancelado"){
          this.router.navigate(['/loans'], { queryParams: { chequeempresa: true }});
        }
      }
    );

    
  }

  scrollBy(side) {
    if (side === 'right') {
      document.querySelector('#test').scrollBy({
        left: 410,
        behavior: 'smooth',
      });
    }
    if (side === 'left') {
      document.querySelector('#test').scrollBy({
        left: -410,
        behavior: 'smooth',
      });
    }
  }

  linkRedirect() {
    this.setTab.setValue('PENDENTE');
    this.router.navigate(['/transaction-approval']);
  }

  doRedirectToBoletos() {
    this.pendencyDdaDataShare.setSelectedTab('boletos');
  }

  redirectLimitsApproval(type) {
    switch(type) {
      case 'hours':
        this.pendencyLimitsDataShare.setValue('pix');
        this.router.navigate(['/pix']);
        break;
      case 'transactional':
        this.pendencyLimitsDataShare.setValue('transactional');
        this.router.navigate(['/change-transactional-limits']);
        break;
      case 'pix':
        this.pendencyLimitsDataShare.setValue('pix');
        this.router.navigate(['/pix']);
        break;
      case 'limitMaxPix':
        this.pendencyLimitsDataShare.setValue('limitMaxPix');
        this.router.navigate(['/pix']);
        break;
    }
  }
}
