<ng-template #content let-modal>
  <div class="cheque-empresa-container">
    <div class="modal-header">
      <div class="generic-info">
        <h1 [style.color]="'#D69D10'">Atenção</h1>
        <div class="generic-info-message">Para concluir a transação, será utilizado</div>
        <div class="generic-info-message">
          <p class="text-message">R$ {{valor || 0 | number:"1.2-2"}} do {{conta}}</p></div>
      </div>
    </div>
    <div class="step-buttons-cancelar">
      <button
        type="button"
        class="button-cancel"
        (click)="closeModal('cancelar')"
      >
        Cancelar
      </button>
    </div>
    <div class="step-buttons-confirmar">
      <button
        type="button"
        class="button-yes"
        (click)="closeModal('sim')"
      >
        Confirmar
      </button>
    </div>
  </div>
</ng-template>
