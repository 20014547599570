import { DisclaimerModel } from '@src/core/domain/disclaimer/disclaimer.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { LimitsDisclaimerRepositories } from '@src/core/repositories/limits-disclaimer/limits-disclaimer.repository';
import { ReturnTypeDisclaimerLimits } from '@src/core/domain/limit-disclaimer/limits-diclaimer.model';

@Injectable({
  providedIn: 'root',
})
export class LimitsDisclaimerUsecase implements UseCase<{}, ReturnTypeDisclaimerLimits> {
  constructor(private limitDisclaimer: LimitsDisclaimerRepositories) { }

  execute(params: {}): Observable<ReturnTypeDisclaimerLimits> {
    return this.limitDisclaimer.getDisclaimers(params);
  }
}
