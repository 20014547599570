import {
  Component,
  OnInit,
  Input,
  forwardRef,
  TemplateRef,
  AfterContentInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownList, DropdownItem } from '@src/core/domain/business-models';
import { CompanySelectDataShare } from '@src/data/repository/data-share-repository';
import { RolesService } from '@src/shared/services/roles.service';

@Component({
  selector: 'fibra-dropdown-cobranca',
  templateUrl: './dropdown-cobranca.component.html',
  styleUrls: ['./dropdown-cobranca.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponentCobranca),
      multi: true,
    },
  ],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class DropdownComponentCobranca
  implements OnInit, ControlValueAccessor, AfterContentInit {
  /**
   * Set initial label
   */
  @Input() public placeholder: string;
  @Input() public numCpfCnpj: string;
  @Input() public options: DropdownList;
  @Input() public value: any;
  @Input() public isDisabled: boolean;
  @Input() public isClear: boolean;
  @Output() public callback?: EventEmitter<number> = new EventEmitter();
  @Output() public selected?: EventEmitter<any> = new EventEmitter();
  /**
   * Name of icon included in path '/assets/svg/'
   */
  @Input() public iconName: string;

  /**
   * Prefix of dropdown
   *
   * use example:
   * ```html
   * <fibra-dropdown [prefix]='myPrefix'> </fibra-dropdown>
   * <ng-template #myPrefix> <img src='myicon.svg'> </ng-template>
   * ```
   */
  @Input() public prefix: TemplateRef<HTMLElement>;
  @Input() public backgroundColor: string;
  @Input() public textColor: string;
  @Input() public removeShadown: boolean;
  @Input() public customClass: string;
  @Input() public prefixText: string;
  @Input() public disabledChange: boolean;
  @Input() public isCobranca: boolean
  // tslint:disable-next-line: no-output-native
  @Output() public change: EventEmitter<any> = new EventEmitter();
  @Output() public emitChange: EventEmitter<any> = new EventEmitter();
  public label: string;
  public hasOpened: boolean;
  constructor(
    private shareSelect: CompanySelectDataShare
    ) { }

  ngAfterContentInit(): void {
    if (!this.placeholder && this.options) {
      const selected = this.options.find((item) => item.selected === true);
      if (this.options[0]) {
        this.select(this.options[0]);
      }
      if (selected) {
        this.select(selected);
      }
    }
    this.selected.emit(this.options);
  }

  ngOnInit() {
    if(this.isCobranca){
      this.label = "Carteira"
    }
   }

  public select = (item?: DropdownItem) => {

    if (!this.disabledChange) {
      this.label = item.name;
      this.value = item.value;
      if(item.cpfCnpj){
        this.numCpfCnpj = item.cpfCnpj;
      }
      if (item.indice !== undefined) {
        this.shareSelect.setValue({ selected: false, changed: true });
      } else {
        this.shareSelect.setValue({ selected: false, changed: false });
      }
    }
    this.onChange(item.value);
  
    this.hasOpened && this.change.emit(item.value);

    this.emitChange.emit(item);
    // tslint:disable-next-line: no-unused-expression
    this.callback && this.hasOpened && this.callback.emit(item.indice);

    if(this.isClear){
      this.label = 'Carteira';
      this.value = null;
    }
  }

  public toggle() {
    this.hasOpened = !this.hasOpened;
  }

  onClick(event) {
    const clickInDropdown = event.target.classList.contains('dropdown');

    if (!clickInDropdown && this.hasOpened) {
      this.hasOpened = !this.hasOpened;
    }
  }

  /*
    FormControl methods
  */
  public writeValue(value: any): void {
    if (value) {
      this.selectByValue(value);
    }
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void { }
  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /*
    Private methods
  */

  private selectByValue(value: any) {
    const item = this.options.find((e) => e.value === value);
    this.select(item);
  }

  private onChange = (value: any) => { };
}
