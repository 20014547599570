<div *ngIf="!userDetail; else main">
  <div class="d-flex justify-content-center position loading-container loadingView">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<ng-template #main>
  <div class="card-body">
    <div class="account_permission_content"
      *ngIf="
        (userDetail && userDetail.data && userDetail.data.contas && userDetail.data.contas.length > 0) ||
        (userDetail && userDetail.data && userDetail.data.permissoes_nao_atreladas && userDetail.data.permissoes_nao_atreladas.length > 0)
      "
    >
      <div class="account_permission-info">
        <span>Contas e permissões</span>
      </div>
      <div *ngIf="userDetail && userDetail.data && userDetail.data.contas && userDetail.data.contas.length > 0">
        <div *ngFor="let item of userDetail.data.contas">
          <div class="account-box">
            <img src="assets/svg/tooltip.svg" alt="icon" />
            <span>{{item.numero_conta || item.conta}} - {{item.exibicao || item.descricao_conta || item.desc_conta}}</span>
          </div>
          <div class="permission-box" *ngIf="item.funcionalidades && item.funcionalidades.length > 0; else space">
            <div class="permission-info">
              <span>Permissões:</span>
            </div>
            <div class="permission-transf-box" *ngFor="let permission of item.funcionalidades">
              <div class="transf-info">
                <img src="assets/svg/tooltip.svg" alt="icon" />
                <span>{{permission.desc_funcionalidade || ''}}</span>
              </div>
              <div *ngFor="let operation of permission.operacao">
                <div class="transf-item">
                  <span>{{operation.desc_operacao}}</span>
                </div>
              </div>
            </div>
          </div>
          <ng-template #space>
            <div class="space"></div>
          </ng-template>
        </div>
      </div>

      <div *ngIf="userDetail && userDetail.data && userDetail.data.permissoes_nao_atreladas && userDetail.data.permissoes_nao_atreladas.length > 0">
        <div>
          <div class="account-box">
            <img src="assets/svg/tooltip.svg" alt="icon" />
            <span>Permissões não atreladas a contas</span>
          </div>
          <div class="permission-box">
            <div class="permission-transf-box" *ngFor="let item of userDetail.data.permissoes_nao_atreladas">
              <div class="transf-info">
                <img src="assets/svg/tooltip.svg" alt="icon" />
                <span>{{item.desc_funcionalidade || ''}}</span>
              </div>
              <div *ngFor="let operation of item.operacao">
                <div class="transf-item">
                  <span>{{operation.desc_operacao}}</span>
                </div>
              </div>
            </div>
          </div>
          <ng-template #space>
            <div class="space"></div>
          </ng-template>
        </div>
      </div>
    </div>
  
    <div class="group_part_content" *ngIf="userDetail && userDetail.data && userDetail.data.grupos && userDetail.data.grupos.length > 0">
      <div class="group_part-info">
        <span>Grupos que faz parte</span>
      </div>
      <div *ngFor="let item of userDetail.data.grupos">
        <div class="group-item">
          <img src="/assets/svg/tooltip.svg" alt="icon" />
          <span>{{item.desc_grupo}}</span>
        </div>
        <div class="space"></div>
      </div>
    </div>

    <div class="group_part_content" *ngIf="userDetail && userDetail.data && userDetail.data.pessoas_relacionadas && userDetail.data.pessoas_relacionadas.length > 0">
      <div class="group_part-info">
        <span>Usuários que fazem parte do grupo</span>
      </div>
      <div *ngFor="let item of userDetail.data.pessoas_relacionadas">
        <div class="group-item">
          <img src="/assets/svg/tooltip.svg" alt="icon" />
          <span>{{item.nome_pessoa}}</span>
        </div>
        <div class="space"></div>
      </div>
    </div>
  </div>
</ng-template>
