<div class="comprovante" id="comprovante" *ngIf="readyToLoad">
    <div class="close-div" id="fechar">
        <img src="/assets/svg/icon-fechar-black.svg" alt="fechar" id="ico-fechar" (click)="goBack()" style="display:none">
    </div>
    <div class="header">
        <div class="header-content">
            <img src="/assets/svg/logo_fibra.svg" alt="logo" style="width: 165px;height: 49px;">
            <span class="header-text"> Internet Banking - Banco Fibra - Empresa: {{company[0].name}} - CNPJ {{company[0].modality_account}} - {{day}} {{hours}}</span>
        </div>
        <span class="title-report">Derivativos</span>
    </div>

    <!-- SubTítulo derivativos -->
    <div class="div" style="margin-bottom: 5px;margin-left: 80px; margin-top:18px" *ngIf="informations.name=='derivativos'">
        <span class="report-subtitle" style="color:#8F969D"> Consultar pela data {{ informations.dt_ref | date: 'dd/MM/yyyy' }} </span> <span class="report-subtitle-value"> </span>
    </div>

    <div class="empty-info" *ngIf="hide">
        <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
          message="Tente uma nova pesquisa inserindo novos parâmetros.">
        </fibra-generic-info>
    </div>

    <!-- Derivativos -->
    <div class="div" *ngIf="informations.name=='derivativos' && !hide">
        <div class="lado-a-lado">
            <div class="datatable">
                <div class="table-title">
                    <div class="table-item">
                        <span class="item-font">Data de Vencimento</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">Contrato</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">Notional</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">Valor Líquido</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">Tipo de Movimentação</span>
                    </div>

                </div>
                <div class="table-body">
                    <div class="table-content" *ngFor="let item of derivative; let i = index">
                        <div class="table-row">
                            <div class="table-item">
                                <span class="item-font">{{item.dt_vencto | date: 'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">{{item.num_contrato}}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">{{item.moeda}} {{ item.valor_notionalME | number: '1.2-2' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font">R$ {{ item.valor_liquido | number: '1.2-2' }}</span>
                            </div>
                            <div class="table-item">
                                <span class="item-font" *ngIf="item.tipo == 'credito'">Crédito</span>
                                <span class="item-font" *ngIf="item.tipo == 'debito'">Débito</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-content">
        <div class="card">
            <div class="html2pdf__page-break"></div>
            <br /><br /><br />
                <div style="display: table; padding: 25px 0px 10px 20px; width:100%;">
                    <p>Se precisar de ajuda, entre em contato com a gente.</p>
                </div> 
            <br />
            <div class="card-body" #atendimento>
                <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">Canais exclusivos</div>
                    <div class="desc">Dúvidas e apoio</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    <b>Corporate, Agro e PMEs</b>
                    (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
                </p>
                <p>
                    <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body" #sac>
                <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">SAC</div>
                    <div class="desc">Sugestões, reclamações e elogios</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    <b>Canal de Atendimento ao Cliente</b>
                </p>
                <p>
                    0800 727 0132
                </p>
                <p>
                    <b>Canal exclusivo para deficientes auditivos ou de fala</b>
                </p>
                <p>
                    0800 200 6921
                </p>
            </div>
        </div>
        <div class="card">
            <div class="card-body" #ouvidoria>
                <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
                <div class="text">
                    <div class="title">Ouvidoria</div>
                    <div class="desc">Demandas não solucionadas</div>
                </div>
            </div>
            <div class="card-bottom">
                <p>
                    0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.<br><br>
                </p>
            </div>
        </div>
    </div>
</div>