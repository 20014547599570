import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DdaAccountModel } from "@src/core/domain/dda/account.model";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";
import { DdaCallBackEnum } from "@src/core/domain/dda/dda-callback.enum";
import { StatusApprovalEnum } from "@src/core/domain/dda/dda-type-approval.enum";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { TermTypeEnum } from "@src/core/domain/dda/term-types-enum";
import {
  DdaTermCancelPayloadModel,
  DdaTermPayloadModel,
} from "@src/core/domain/dda/term.model";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { DdaTermApprovalEntity } from "@src/data/repository/dda/entity/term/dda-term-approval.entity";
import { TermApprovalMapper } from "@src/data/repository/dda/term/dda-company-approval.mapper";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";
import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";
import * as moment from "moment";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";

@Component({
  selector: "fibra-criar-cancelamento-adesao",
  templateUrl: "./criar-cancelamento-adesao.component.html",
  styleUrls: ["../../../pages/dda/dda.scss","./criar-cancelamento-adesao.component.scss"],
  providers: [CpfCnpjPipe, TermImplementationRepository],
})
export class CriarCancelamentoAdesaoComponent implements OnInit {
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  callBackType = DdaCallBackEnum;

  empresa: any;

  enableAction: boolean = false;
  totalMasters: number = 0;


/*  apiCallback: number = DdaCallBackEnum.AprovarTermo;

  statusApproval = StatusApprovalEnum;

  exibirAutorizacao: boolean = false;
  exibirConclusao: boolean = false;
  apiPayload: DdaTermApprovalEntity = {
    id: 0,
    id_tipo: TermTypeEnum.Cancelamento,
    termo_aceite_id: 0,
    ds_status: StatusApprovalEnum.Approved,
    dt_aprovacao: moment().format("YYYY-MM-DDTHH:mm:ss"),
    aprovacao_substituida: "",
  }; */

  apiCallback: number = DdaCallBackEnum.AderirTermo;

  statusApproval = StatusApprovalEnum;

  exibirAutorizacao: boolean = false;
  exibirConclusao: boolean = false;

  typeColorToast = "#ED6C6C";

  statusAprovacao: string = "";
  exibirDadosAprovacao: boolean = false;

  descricaoAprovacao: string = "";

  criadoPor: string = "";
  dataCriacao: string = "";
  horaCriacao: string = "";

  @Input() apiPayload: DdaTermPayloadModel = {
    id_tipo: TermTypeEnum.Cancelamento,
    termo_aceite_id: 0,
    nu_conta: "",
    nu_cnpj: "",
    filiais: [],
  };

  constructor(
    private termRepository: TermImplementationRepository,
    public ddaTermDataShare: DdaTermDataShare,
    private router: Router
  ) {}

  ngOnInit() {   
    this.obterDadosEmpresa();
  }

  goStep(): void {
    this.exibirAutorizacao = true;
  }

  redirect() {
    this.router.navigate(["dda"]);
  }

  obterDadosEmpresa(): void {
    this.empresa = JSON.parse(sessionStorage.getItem("defaultCompany"))[0];
  }
  reprovarTermo(): void {
    this.apiCallback = this.callBackType.AprovarTermo;
    this.exibirAutorizacao = true;
  }

  getInscricao(filial: DdaBranchModel): string {
    return `${filial.cnpjInscricao}${filial.identificacaoFilial}`;
  }

  enable(): void {
    this.enableAction = !this.enableAction;
  }

  exibirMensagem(err: any): void {
    if (err.code !== 400) {
      this.toast.callModalMessage(null, err.message[0], null, null, null);
    } else {
      this.toast.callModalMessage(null, err, null, null, null);
    }
  }

  fecharModal(): void {
    this.exibirAutorizacao = false;
  }

  fecharModalConclusao(): void {
    this.exibirConclusao = false;
  }

  respostaApi(value: any): void {
    this.exibirAutorizacao = false;
    if (value.status === "success") {
      this.exibirAutorizacao = false;
      let mapper = new TermApprovalMapper();
      this.ddaTermDataShare.setStatusTermCancel(mapper.mapTo(value.data[0]));
      this.totalMasters = value.data[0].aprovacoes.length;
      this.exibirConclusao = true;
    } else {
      this.exibirConclusao = false;
      this.exibirMensagem(value.error);
    }
  }
}
