import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { DdaAccountModel } from "@src/core/domain/dda/account.model";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";
import { DdaBatchCompanyModel } from "@src/core/domain/dda/dda-batch-company.model";
import { DdaCallBackEnum } from "@src/core/domain/dda/dda-callback.enum";
import { DdaStatusProcesso, DdaStatusProcessoFilial, StatusApprovalChangeEnum, StatusApprovalEnum } from "@src/core/domain/dda/dda-type-approval.enum";
import { DdaTermCompanyApprovalModel, PersonModel } from "@src/core/domain/dda/term-company-approval.model";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { DdaTermApprovalEntity } from "@src/data/repository/dda/entity/term/dda-term-approval.entity";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";
import { CnpjValidator } from "@src/shared";
import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";
import { toNumber } from "@src/shared/util-common";
import * as moment from "moment";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";
import { Router } from '@angular/router';
import { DdaApprovalsModel } from "@src/core/domain/dda/dda-approvals.model.";
import { TermTypeEnum } from "@src/core/domain/dda/term-types-enum";
import { EmpresaFilialModel } from "@src/core/domain/dda/empresa-filial.model";
import { environment } from "@src/environments/environment";

@Component({
  selector: "fibra-dda-filiais",
  templateUrl: "./dda-filiais.component.html",
  styleUrls: ["../../../pages/dda/dda.scss", "./dda-filiais.component.scss"],
  providers: [CpfCnpjPipe],
})
export class DdaFiliaisComponent implements OnInit, OnChanges {
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  @Input() waitApproval: boolean = false; //ADESAO STATUS PENDENTE
  @Input() waitApprovalCancel: boolean = false; //CANCELAMENTO ADESAO STATUS (GERAL)
  @Input() cancelInProgress: boolean = false; //CANCELAMENTO ADESAO PENDENTE DE APROVAÇÃO DE MASTER (USUÁRIO LOGADO)
  @Input() showUndoCancel: boolean = false; //HABILITAR OPÇÃO DE DESFAZER (MANTER) PARA USUÁRIO LOGADO
  @Input() showMainData: boolean = false;
  waitCancelInCipProgress: boolean = false;

  empresa: any;
  filiais: Array<DdaBranchModel> = [];
  callbackEnum = DdaCallBackEnum;

  desativarBotaoSalvarAlteracoes : boolean = true;
  isDesfazerCancelamento: boolean = false;
  showDetail = true;

  cnpjMask = [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/];
  typeColorToast = "#ED6C6C";
  apiCallback: number;
  apiPayload: any;

  apiPayloadManter: DdaTermApprovalEntity = {
    id: 0,
    termo_aceite_id: 0,
    ds_status: StatusApprovalEnum.Approved,
    dt_aprovacao: "",
    aprovacao_substituida: "",
    filiais: []
  };

  apiPayloadFilias: DdaBatchCompanyModel = {
    termo_aceite_id: 0,
    pessoa_id_requisitante: 0,
    id_tipo: 0,
    filiais: [],
  };

  exibirAutorizacao: boolean = false;
  exibirConclusao: boolean = false;
  exibirConclusaoManter: boolean = false;
  totalMasters: number = 0;
  inclusaoFilial: boolean = false;
  contemFilial: boolean = false;

  isLoading: boolean = true;

  showDisclaimer: boolean = false;
  showModalCancel: boolean = false;
  modalUndo: boolean = false;
  termoAceiteIdUndo: number = 0;
  statusTermCancel: DdaTermCompanyApprovalModel = null;
  statusTerm: DdaTermCompanyApprovalModel = null;

  num_conta: string = null;

  imgStatus: string = '';
  descStatus: string = '';

  adhesionInLegado: boolean = false;

  titleCancel: string;
  messageCancel: string;

  empresaFilial: EmpresaFilialModel = {
    termo_aceite_id: 0,
    cnpj: "",
    filiais: [],
  };

  aprovadores: PersonModel[];
  aprovadoresCancelamento: PersonModel[];

  adesaoConcluida: boolean = false;
  adesaoPendente: boolean = false;
  adesaoEmProcesso: boolean = false;
  cancelamentoPendente: boolean = false;
  cancelamentEmProcesso: boolean = false;

  statusAdesao : DdaStatusProcesso = DdaStatusProcesso.Aderido;
  statusAdesaoFilial : DdaStatusProcessoFilial = DdaStatusProcessoFilial.EmProcessoAdesao;

  isDebug : boolean = environment.production;

  constructor(
    private cpfCnpjPipe: CpfCnpjPipe,
    private termRepository: TermImplementationRepository,
    public ddaTermDataShare: DdaTermDataShare,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {

  }

  obterConta(): void {
    this.termRepository.getAccount().subscribe((result: any) => {
      this.num_conta = (
        result.data.find((x) => x.selecionado === true) as DdaAccountModel
      ).num_conta;
    });
  }

  obterDadosTermo(): void {
    //PENDENTE DE APROVAÇÃO
    this.ddaTermDataShare.getStatusTerm().subscribe((result: DdaTermCompanyApprovalModel) => {
      if (result !== null && result != undefined) {
        this.statusTerm = result;
        this.waitApproval = result.status === StatusApprovalEnum.Pendant;        
        this.aprovadores = result.aprovacoes.filter(x => x.status === StatusApprovalEnum.Pendant).map(aprovador => aprovador.pessoa[0]);
        this.getLegacyMembership(false);
      } else {
        this.router.navigate(["dda"]);
      }
    });

    //PENDENTE DE APROVAÇÃO DO CANCELAMENTO
    this.ddaTermDataShare.getStatusTermCancel().subscribe((result: DdaTermCompanyApprovalModel) => {
      if (result !== null && result!=undefined) {
        this.getLegacyMembership(false);
        this.waitApprovalCancel = result.status === StatusApprovalEnum.Pendant;

        //PENDENTE DE APROVAÇÃO vetor Aprovadores é vazio
        //PREENCHIDO VETOR DE PERMISSÃO PARA CANCELAMENTO
        this.aprovadoresCancelamento = result.aprovacoes.filter(x => x.status === StatusApprovalEnum.Pendant).map(aprovador => aprovador.pessoa[0]);
        
        //CANCELAMENTO EM PROGRESSO = CANCELAMENTO APROVADO + STATUS ADESÃO PENDENTE + TODOS OS APROVADORES DA ADESÃO COM STATUS APROVADO
        this.waitCancelInCipProgress = (result.status === StatusApprovalEnum.Approved && (this.waitApproval && this.aprovadores.length == 0));

        if(this.waitApprovalCancel) {
          this.termoAceiteIdUndo = result.id;
          this.empresaFilial.termo_aceite_id = result.id;
        }
      }});          
  }

  /**
   * [Adesão pendente]
   * this.waitApproval = result.status === StatusApprovalEnum.Pendant; = TRUE (2 PENDENTE)
   * this.waitCancelInCipProgress = (result.status === StatusApprovalEnum.Approved) = TRUE (3 APROVADO)
   * 
   * [Cancelamento em progresso]
   * this.waitApproval = result.status === StatusApprovalEnum.Pendant; = TRUE (2 PENDENTE)
   * this.waitCancelInCipProgress = (result.status === StatusApprovalEnum.Approved) = TRUE (3 APROVADO)
   * this.adhesionInLegado = TRUE
   * 
   * [Cancelamento pendente]
   * this.waitApproval = result.status === StatusApprovalEnum.Approved; = TRUE (2 APROVADO)
   * this.waitApprovalCancel = result.status === StatusApprovalEnum.Pendant; TRUE (3 PENDENTE)
   * this.adhesionInLegado = TRUE
   * 
   * [Aderido]
   * 2 - APROVADO
   * 3 - CANCELADO
   * 
   * [Cancelamento realizado]
   * 2 - CANCELADO
   * 3 - APROVADO
   * 
   */
  checkStatusAdesao() {
    this.adesaoPendente = !this.adhesionInLegado && this.waitApproval && this.aprovadores!=null && this.aprovadores.length > 0 && !this.waitCancelInCipProgress && !this.waitApprovalCancel;
    this.adesaoEmProcesso = !this.adhesionInLegado && !this.waitApproval && this.aprovadores!=null && this.aprovadores.length == 0 && !this.waitCancelInCipProgress && !this.waitApprovalCancel;
    this.adesaoConcluida = this.adhesionInLegado && !this.waitApproval && !this.waitCancelInCipProgress && !this.waitApprovalCancel;
    this.cancelamentoPendente = this.adhesionInLegado && !this.waitApproval && !this.waitCancelInCipProgress && this.waitApprovalCancel && this.aprovadoresCancelamento!=null && this.aprovadoresCancelamento.length > 0;
    this.cancelamentEmProcesso = this.adhesionInLegado && !this.waitApprovalCancel && this.waitCancelInCipProgress && this.waitApproval && this.aprovadoresCancelamento!=null && this.aprovadoresCancelamento.length == 0;    

    //ADESAO PENDENTE
    if(this.adesaoPendente){
      this.imgStatus = 'bullet_yellow';
      this.descStatus = 'Adesão pendente';
      this.statusAdesao = DdaStatusProcesso.AdesaoPendente;
    }

        //ADESAO EM PROCESSAMENTO
    if(this.adesaoEmProcesso){
      this.imgStatus = 'bullet_yellow';
      this.descStatus = 'Adesão pendente';
      this.statusAdesao = DdaStatusProcesso.AdesaoEmProcesso;
    }

    //Aderido - 2 APROVADO | 3 CANCELADO
    if(this.adesaoConcluida){
      this.imgStatus = 'bullet_green';
      this.descStatus = 'Aderido';
      this.adesaoConcluida = true;
      this.statusAdesao = DdaStatusProcesso.Aderido;
    }

    //Cancelamento pendente     - 2 APROVADO | 3 PENDENTE = master > 1
    //Cancelamento em progresso - 2 PENDENTE | 3 APROVADO | adhesionInLegado = true
    if(this.cancelamentoPendente){
      this.imgStatus = 'bullet_yellow';
      this.descStatus = 'Cancelamento pendente';
      this.isDesfazerCancelamento = true;
      this.statusAdesao = DdaStatusProcesso.CancelamentoPendente;
    }

    //Cancelamento me progresso
    if(this.cancelamentEmProcesso){
      this.imgStatus = 'bullet_yellow';
      this.descStatus = 'Cancelamento pendente';
      this.isDesfazerCancelamento = true;
      this.statusAdesao = DdaStatusProcesso.CancelamentoEmProcesso;
    }
  }

  ngOnInit() {
    this.obterConta();
    this.obterDadosTermo();
    this.obterDadosEmpresa();
    this.obterDadosFiliais();
  }

  ngOnChanges(){
  }

  ngAfterContentChecked() : void {
    //this.changeDetector.detectChanges();
    this.checkStatusAdesao();
  }

  obterDadosEmpresa(): void {
    this.empresa = JSON.parse(sessionStorage.getItem("defaultCompany"))[0];
    this.empresaFilial.cnpj = this.empresa.cpfCnpj;
  }

  closeModalUndo(event: boolean): void {
    this.modalUndo = false;

    if(event === true)
      console.log(this.empresa.cpfCnpj);
      this.undoCancellation();
  }

  showModalUndo(): void {
    this.modalUndo = true;
  }

  undoCancellation(): void {
    if(this.statusTerm != null && this.termoAceiteIdUndo > 0){
      this.termRepository
      .undoCancellation(this.empresaFilial).subscribe(
        (result: any) => {
          if(result.data){
            this.router.navigate(["dda"]);
          }
        },
        (error: any) => {
          this.exibirMensagem(error);
        }
      );
    }
  }
  
  obterDadosFiliais(): void {
    this.isLoading = true;
    if(this.statusTerm != null){
      this.termRepository.getBranch(this.statusTerm.id).subscribe(
        (result: any) => {
          this.filiais = result;
          this.empresaFilial.filiais = this.filiais.map(f=>`${f.cnpjInscricao}${f.identificacaoFilial}`);
          this.isLoading = false;
        },
        (error: any) => {
          this.isLoading = false;
        }
      );      
    }
    else{
      this.isLoading = false;
    }
  }

  checkedAll(): void {
    this.filiais.forEach((filial: DdaBranchModel) => {
      filial.selecionado = !filial.selecionado && filial.statusId !== 1;
    });
  }

  showCancelFiliais(): void {
    this.prepararPayloadFiliais();

    if(this.excluindoFiliais()){
      this.showCancel(false);
    } else {
      this.exibirAutorizacao = true;
    }
  }

  showCancel(matriz: boolean): void {
    if(matriz) {
      this.titleCancel = 'Após o cancelamento da adesão os pagamentos agendados não serão mais liquidados.';      
    } else {      
      this.titleCancel = 'Após a exclusão da filial os pagamentos agendados não serão mais liquidados.';      
    }

    this.prepararPayloadFiliais();
    this.apiPayloadFilias.filiais = this.filiais.map(f=>`${f.cnpjInscricao}${f.identificacaoFilial}`);
    this.empresaFilial.filiais = this.filiais.map(f=>`${f.cnpjInscricao}${f.identificacaoFilial}`);

    this.messageCancel = 'Deseja continuar o processo de cancelamento?';
    this.showModalCancel = true;
  }

  closeModalConf(event: boolean): void {
    if (event) {
      if(this.excluindoFiliais()) {
        this.prepararPayloadFiliais();
        this.exibirAutorizacao = true;
      } else {
        this.apiPayloadFilias.filiais = this.filiais.map(f=>`${f.cnpjInscricao}${f.identificacaoFilial}`);
        this.empresaFilial.filiais = this.filiais.map(f=>`${f.cnpjInscricao}${f.identificacaoFilial}`);
        this.showDisclaimer = event;
        this.showModalCancel = false;
      }
    }
    else {
      this.showModalCancel = false;
    }

  }

  excluirFilial(index: number): void {
    if (this.filiais && this.filiais[index].status === "") {
      this.filiais.splice(index,1);
      if(this.filiais.length==0){
        this.desativarBotaoSalvarAlteracoes = false;
      }
    } else {
      this.filiais[index].excluido = true;
    }

    this.processarValidacoes();
  }

  desfazerExclusao(filial: DdaBranchModel): void {
    try {
      filial.excluido = false;
      this.desativarBotaoSalvarAlteracoes = this.existeFilialParaValidacao();
    } catch (err) {
      this.exibirMensagem("Ocorreu um erro no envio! Tente novamente.");
    }
  }

  verificarDadosEnvio(): void {
    let verificarAlteracaoDados = this.filiais.length > 0  ? this.filiais.some((d: DdaBranchModel) =>
      (d.cnpjValidado && d.identificacaoFilial !== '' && (d.excluido || d.statusId==0))) : false;
    
      let duplicadosEncontrados = this.filiais.filter((objeto, index, self) =>  
    self.findIndex(o => o.identificacaoFilial === objeto.identificacaoFilial) !== index );

    this.desativarBotaoSalvarAlteracoes = verificarAlteracaoDados && (duplicadosEncontrados.length>1);

  }

  excluindoFiliais(): boolean {
    return (this.filiais.length > 0 ? this.filiais.some((d: DdaBranchModel) => d.excluido) : false);
  }

  getFiliaisExclusao(): DdaBranchModel[] {

    return this.filiais.length > 0 ? 
    this.filiais.filter((d: DdaBranchModel) => (d.cnpjValidado && d.identificacaoFilial !== '' && d.excluido)) : [];
  }

  adicionarFilial(): void {

    let filial = {
      cnpjInscricao: this.obterInscricao(),
      identificacaoFilial: "",
      razaoSocial: "",
      cnpjValidado: false,
      validado: false,
      msgValidacao: "Campo obrigatório.",
      status: "",
      statusId: 0,
      excluido: false,
    };
    this.filiais.push(filial);

    this.processarValidacoes();
  }

  processarValidacoes(){

    if(this.filiais.length>0){
      this.filiais.forEach(f=>{
        this.validarCnpj(f);
      });
    }
    else{
      this.desativarBotaoSalvarAlteracoes = true;
    }


  }

  existeFilialParaValidacao(): boolean{
    let count = 0;
    if(this.filiais.length>0){
      count = this.filiais.filter(
        f=>f.validado === false
         && f.msgValidacao != "").length;
    }
    return count>0;
  }  

  validarCnpj(filial: DdaBranchModel): void {
    filial.validado = true;

    if (filial.identificacaoFilial === "") {
      filial.validado = false;
      filial.msgValidacao = "Campo obrigatório.";
    }
    else{
      if (!this.verificaFilialMatriz(filial) && filial.validado) {
        filial.cnpjValidado = CnpjValidator.validCnpj(
          toNumber(filial.cnpjInscricao + filial.identificacaoFilial)
        );
        filial.msgValidacao = filial.cnpjValidado ? null : "Número de CNPJ inválido.";
        filial.validado = filial.cnpjValidado;
      }

      if(filial.validado){
        this.verificarDuplicados(filial);
      }
    }

    this.desativarBotaoSalvarAlteracoes = this.existeFilialParaValidacao();
  }

  verificaFilialMatriz(filial): boolean {
    if (
      this.empresa.cpfCnpj ===
      toNumber(filial.cnpjInscricao + filial.identificacaoFilial)
    ) {
      filial.validado = false;
      filial.msgValidacao = "CNPJ da Matriz não pode ser adicionado na Filial.";
    }
    return (
      this.empresa.cpfCnpj ===
      toNumber(filial.cnpjInscricao + filial.identificacaoFilial)
    );
  }

  verificarDuplicados(filial: DdaBranchModel): void {
    let duplicado: DdaBranchModel[] = this.filiais.filter(
      (x) =>
        toNumber(x.identificacaoFilial) === toNumber(filial.identificacaoFilial)
    );

    let encontrado: boolean = duplicado.length > 1;

    filial.validado = !encontrado;

    if(filial.identificacaoFilial===""){
      filial.msgValidacao = "Campo obrigatório";
    }else{
      filial.msgValidacao = encontrado ? "Filial já adicionada." : null;
    }
  }

  obterInscricao(): string {
    let inscricao = this.cpfCnpjPipe.transform(this.empresa.cpfCnpj);
    return inscricao.substring(0, inscricao.indexOf("/") + 1);
  }

  exibirMensagem(err: any): void {
    if (err.status !== undefined) {
      this.toast.callModalMessage(null, err.message[1], null, null, null);
    } else {
      this.toast.callModalMessage(null, err, null, null, null);
    }
  }

  fecharModal(): void {
    this.exibirAutorizacao = false;
  }

  fecharModalConclusao(): void {
    this.exibirConclusao = false;
    //this.router.navigate(["dda"]);
  }

  getInscricao(filial: DdaBranchModel): string {
    return `${toNumber(filial.cnpjInscricao)}${toNumber(
      filial.identificacaoFilial
    )}`;
  }

  respostaApi(value: any): void {
    if (value.status === "success") {
      this.exibirAutorizacao = false;

      if (this.apiPayloadManter.ds_status === StatusApprovalEnum.Reproved) {
        this.exibirConclusaoManter = true;
        this.showUndoCancel = true;
        this.ddaTermDataShare.setStatusTermCancel(null);
      } else {
        //T
        //verificar se é exclusao de filial (apresentar modal de filial)
        this.exibirConclusao = true;
        if(Array.isArray(value.data)){
          this.totalMasters = value.data[0].total_masters == undefined ? value.data[0].aprovacoes.length : value.data[0].total_masters;
        }
        else{
          this.inclusaoFilial = true;
          this.contemFilial = value.data.cnpjs != null;
          this.totalMasters = value.data.total_masters;
        }
      }
    } else {
      this.exibirConclusaoManter = false;
      this.exibirConclusao = false;
      this.exibirAutorizacao = false;
      this.exibirMensagem(value.error);
    }
  }

  enableRowUser(filial: DdaApprovalsModel, status: number): boolean {
    return filial.aprovadores.find(x => x.status_id === status) !== undefined;
  }  

  manter(): void {
    this.ddaTermDataShare
      .getStatusTermCancel()
      .subscribe((result: DdaTermCompanyApprovalModel) => {
        this.apiPayloadManter.id = result.id;
        this.apiCallback = DdaCallBackEnum.AprovarTermo;
        this.apiPayloadManter.dt_aprovacao = moment().format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        this.exibirAutorizacao = true;
        this.apiPayload = this.apiPayloadManter;
      });
  }

  prepararPayloadFiliais(): void {
    this.apiPayloadFilias.termo_aceite_id = this.statusTerm.id;
    this.apiPayloadFilias.pessoa_id_requisitante = 0;
    this.apiPayloadFilias.id_tipo = TermTypeEnum.Cancelamento;
    this.apiPayloadFilias.filiais = this.getCnpjs();
    this.apiCallback = this.apiPayloadFilias.filiais.length > 0 ? DdaCallBackEnum.AceiteAlterarFiliais : DdaCallBackEnum.AderirTermo;
    this.apiPayload = this.apiPayloadFilias;    
  }

  getCnpjs(): Array<string> {
    return this.filiais
      .filter(
        (d: DdaBranchModel) =>
          (d.cnpjValidado && d.identificacaoFilial !== '' && (d.excluido || d.statusId==0))
      )
      .map((d: DdaBranchModel) => {
        return (
          this.getInscricao(d) +
          "|" +
          (d.excluido!=undefined && d.excluido ? StatusApprovalChangeEnum.PendantExclude : StatusApprovalChangeEnum.PendantInclude) + 
          "|" + StatusApprovalChangeEnum.Approved /**A operação sempre será 2  - APROVAÇÃO */
        );
      });
  }

  getLegacyMembership(filiais: boolean): void {
    this.termRepository
      .getLegacyMembership(filiais).subscribe(
        (result: any) => {
          if(result.data){
            this.adhesionInLegado = result.data.success;            
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  exibeAguardandoAprovacaoFilial(filial: DdaBranchModel): boolean{
    return (filial.statusId == 9 || filial.statusId == 10 && filial.aprovadores != null && filial.aprovadores.length > 0 && filial.aprovadores.filter(x => x.status_id == 9 || x.status_id == 10).length > 0);
  }

  aguardandoAprovacao(): boolean {
    return this.waitApprovalCancel || this.waitApproval;
  }

  aguardandoAprovacaoCancelamento(): boolean {
    return this.adhesionInLegado && this.cancelInProgress || this.waitApprovalCancel;
  }

  toogleElement(): void{
    this.showDetail = !this.showDetail;
  }
}
