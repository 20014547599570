import { Router } from '@angular/router';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ApprovalReceiptComponent } from '../../components/approval-receipt/approval-receipt.component';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';
import { ToastService } from './demo.service';
import * as Util from '@src/shared/util-common';

@Component({
  selector: 'fibra-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {
  constructor(public toastService: ToastService, private router: Router) {}
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  @ViewChild(ApprovalReceiptComponent, null) receipt: ApprovalReceiptComponent;
  public generatePDF = (value?) => Util.generatePDF(value);

  navigateToResource(): void {
    localStorage.setItem('receipt', 'dsadasd');
    window.open('/approval-receipt');
  }

  ngOnInit() {}
}
