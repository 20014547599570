<ng-template #content let-modal style="top: 100px;">
    <div  [ngStyle]="showMesAno ? searchType === 'periodo' ? { 'height': '460px' } : { 'height': '220px' } : { 'height': '400px' }" class="period-datepicker-container">

        <div class="modal-header">
            <div style="width: 100%;" class="generic-info">
                <fibra-form-control-group  *ngIf="showMesAno" >
                    <div class="fibra-radio-outline" *ngIf="searchType !== undefined">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <label class="btn btn-outline-primary" [ngClass]="{'active': searchType === 'periodo'}">
                            <input
                                type="radio"
                                name="options"
                                id="option1"
                                autocomplete="off"
                                value="periodo"
                                name="periodo"
                                (click)="setSearchType($event.target.value)"
                                [checked]="searchType === 'periodo'"
                            />
                            Período
                            </label>
                            <label class="btn btn-outline-primary" [ngClass]="{'active': searchType === 'mes'}">
                            <input #garantorValue
                                type="radio"
                                name="options"
                                id="option2"
                                autocomplete="off"
                                value="mes"
                                name="mes"
                                (click)="setSearchType($event.target.value)"
                                [checked]="searchType === 'mes'"
                            />
                            Mês
                            </label>
                            <label class="btn btn-outline-primary" [ngClass]="{'active': searchType === 'ano'}">
                                <input #garantorValue
                                type="radio"
                                name="options"
                                id="option3"
                                autocomplete="off"
                                value="ano"
                                name="ano"
                                (click)="setSearchType($event.target.value)"
                                [checked]="searchType === 'ano'"
                                />
                                Ano
                            </label>
                        </div>
                    </div>
                </fibra-form-control-group>
            </div>
        </div>

        <div class="modal-header" *ngIf="searchType === 'mes'">
            <div style="width: 100%;" class="generic-info">
                <fibra-form-control-group>
                    <fibra-form-control style="margin-right: 20px !important">
                        <fibra-dropdown (change)="changeSelectMonth($event)" [options]="meses" [clearField]="clearField" class="dropdown-mes-list" customClass="select-option modalperiodmes" placeholder="Mês" removeShadown="true">
                        </fibra-dropdown>
                    </fibra-form-control>
                    <fibra-form-control  style="margin-right: 0 !important">
                        <fibra-dropdown id="ano" class="dropdown-ano-list" (change)="changeSelectYear($event)" [options]="ano" [clearField]="clearField" customClass="select-option modalperiodano" placeholder="Ano" removeShadown="true">
                        </fibra-dropdown>
                    </fibra-form-control>
                </fibra-form-control-group>
            </div>
        </div>

        <div class="modal-header" *ngIf="searchType === 'ano'">
            <div style="width: 100%;" class="generic-info">
                <fibra-form-control-group>
                    <fibra-form-control style="margin-right: 0 !important">
                        <fibra-dropdown id="ano" class="dropdown-ano-list" (change)="changeSelectYear($event)" [clearField]="clearField" [options]="ano" customClass="select-option modalperiodanoum" placeholder="Ano" removeShadown="true">
                        </fibra-dropdown>
                    </fibra-form-control>
                </fibra-form-control-group>
            </div>
        </div>
        

        <div class="modal-header" *ngIf="searchType === 'periodo'">
            <div class="generic-info">
                <div class="input-group">
                    <div style="width:30%" class="dropdown-input">
                        <span>&nbsp;&nbsp;De</span>
                        <input #dpFromDate
                               class="form-control" placeholder="dd/mm/aaaa"
                               name="dpFromDate"
                               [textMask]="addMask(dateMask)"
                               [(ngModel)]="dataI"
                               (input)="dataChanged($event)"
                               (change)='todate($event.target.value)'
                               (blur)="onBlur($event.target.value)"
                               [style.width.px]="180">
                    </div>
                    <div style="width:35%"></div>
                    <div style="width:30%" class="dropdown-input">
                        <span style="text-align: left">&nbsp;&nbsp;Até</span>
                        <input #dpToDate
                               class="form-control" placeholder="dd/mm/aaaa"
                               name="dpToDate"
                               [textMask]="addMask(dateMask)"
                               [(ngModel)]="dataF"
                               (input)="dataChanged($event)"
                               (change)='todate($event.target.value)'
                               (blur)="onBlurDataF($event.target.value)"
                               [style.width.px]="180">
                    </div>
                </div>
            </div>
        </div>
        <br>
        <ng-container *ngIf="searchType === 'periodo'">
            <ngb-datepicker #dp (dateSelect)="onDateSelection($event)"
                            [displayMonths]="2"
                            [dayTemplate]="t"
                            outsideDays="hidden"
                            navigation="1"
                            [maxDate]="maxDate"
                            [minDate]="minDate"
                            style="position: relative; border: 0px;">
            </ngb-datepicker>
            <ng-template #t let-date let-focused="focused">
                <span *ngIf="abaSelected === undefined" class="custom-day"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    [class.init]="isDateInit(date)"
                    [class.end]="isDateEnd(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null"
                    [class.past-date]="isPastDate(date)"
                >
                    {{ date.day }}
                </span>
                <span *ngIf="abaSelected !== undefined"  class="custom-day"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    [class.init]="isDateInit(date)"
                    [class.end]="isDateEnd(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null"
                    [class.past-date]="isPastDatePosicaoCarteira(date)"
                >
                    {{ date.day }}
                </span>
            </ng-template>
        </ng-container>
        <div class="period-center">
            <button
                    type="button"
                    class="button-cancel"
                    (click)="closeModal('limpar')">
                Limpar
            </button>&nbsp;
            <button
                    type="button"
                    class="button-yes"
                    (click)="closeModal('buscar')">
                Buscar
            </button>
        </div>
    </div>
</ng-template>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
