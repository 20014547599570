import { Component, OnInit, ViewChild } from "@angular/core";
import { DdaAccountModel } from "@src/core/domain/dda/account.model";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";

import { DdaTermPayloadModel } from "@src/core/domain/dda/term.model";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";

import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";
import { toNumber } from "@src/shared/util-common";
import { CnpjValidator } from "@src/shared/validators/cnpj.validator";
import * as Util from "@src/shared/util-common";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { TermApprovalMapper } from "@src/data/repository/dda/term/dda-company-approval.mapper";
import { TermApprovalEntity } from "@src/data/repository/dda/entity/term/dda-company-approval.entity";
import { DdaCallBackEnum } from "@src/core/domain/dda/dda-callback.enum";
import { TermTypeEnum } from "@src/core/domain/dda/term-types-enum";

@Component({
  selector: "fibra-dda-contrate",
  templateUrl: "./contrate.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
  providers: [CpfCnpjPipe, TermImplementationRepository],
})
export class DdaContrateComponent implements OnInit {
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  empresa: any;
  filiais: DdaBranchModel[] = [];
  util = Util;
  params: any;

  desativarBotaoSalvarAlteracoes : boolean = false;

  cnpjMask = [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/];
  typeColorToast = "#ED6C6C";
  apiCallback: number = DdaCallBackEnum.AderirTermo;
  apiPayload: DdaTermPayloadModel = {
    id_tipo: TermTypeEnum.Adesao,
    termo_aceite_id: 1,
    nu_conta: "",
    nu_cnpj: "",
    filiais: [],
  };
  exibirAutorizacao: boolean = false;
  exibirConclusao: boolean = false;
  totalMasters: number = 1;
  constructor(
    private cpfCnpjPipe: CpfCnpjPipe,
    private termRepository: TermImplementationRepository,
    public shareTermData: DdaTermDataShare
  ) {}

  ngOnInit() {
    this.obterDadosEmpresa();
    this.prepararPayload();
  }

  prepararPayload(): void {
    this.apiPayload.nu_cnpj = this.empresa.cpfCnpj;

    this.termRepository.getAccount().subscribe((result: any) => {
      this.apiPayload.nu_conta = (
        result.data.find((x) => x.selecionado === true) as DdaAccountModel
      ).num_conta;
    });
  }

  obterDadosEmpresa(): void {
    this.empresa = JSON.parse(sessionStorage.getItem("defaultCompany"))[0];
  }

  excluirFilial(index: number): void {
    this.filiais.splice(index,1);
    if(this.filiais.length==0){
      this.desativarBotaoSalvarAlteracoes = false;
    }

    this.processarValidacoes();
  }

  verificarDadosEnvio(): void {
    let verificarAlteracaoDados = this.filiais.length > 0  ? this.filiais.some((d: DdaBranchModel) =>
      (d.cnpjValidado && d.identificacaoFilial !== '' && (d.validado || d.statusId==0))) : false;
    
    let duplicadosEncontrados = this.filiais.filter((objeto, index, self) =>  
    self.findIndex(o => o.identificacaoFilial === objeto.identificacaoFilial) !== index );

    this.desativarBotaoSalvarAlteracoes = !verificarAlteracaoDados && (duplicadosEncontrados.length>1);

  }

  adicionarFilial(): void {

    let filial = {
      cnpjInscricao: this.obterInscricao(),
      identificacaoFilial: "",
      razaoSocial: "",
      cnpjValidado: false,
      validado: false,
      msgValidacao: "Campo obrigatório.",
      status: "",
      statusId: 0,
      excluido: false,
    };
    this.filiais.push(filial);

    this.processarValidacoes();
  }

  processarValidacoes(){
    this.filiais.forEach(f=>{
      this.validarCnpj(f);
    });
  }

  existeFilialParaValidacao(): boolean{
    let count = 0;
    if(this.filiais.length>0){
      count = this.filiais.filter(
        f=>f.validado === false
         && f.msgValidacao != "").length;
    }
    return count>0;
  }

  validarCnpj(filial: DdaBranchModel): void {
    filial.validado = true;

    if (filial.identificacaoFilial === "") {
      filial.validado = false;
      filial.msgValidacao = "Campo obrigatório.";
    }
    else{
      if (!this.verificaFilialMatriz(filial) && filial.validado) {
        filial.cnpjValidado = CnpjValidator.validCnpj(
          toNumber(filial.cnpjInscricao + filial.identificacaoFilial)
        );
        filial.msgValidacao = filial.cnpjValidado ? null : "Número de CNPJ inválido.";
        filial.validado = filial.cnpjValidado;
      }

      if(filial.validado){
        this.verificarDuplicados(filial);
      }
    }

    this.desativarBotaoSalvarAlteracoes = this.existeFilialParaValidacao();
  }

  

  verificaFilialMatriz(filial): boolean {
    if (
      this.empresa.cpfCnpj ===
      toNumber(filial.cnpjInscricao + filial.identificacaoFilial)
    ) {
      filial.validado = false;
      filial.msgValidacao = "CNPJ da Matriz não pode ser adicionado na Filial.";
    }
    return (
      this.empresa.cpfCnpj ===
      toNumber(filial.cnpjInscricao + filial.identificacaoFilial)
    );
  }

  verificarDuplicados(filial: DdaBranchModel): void {
    let duplicado: DdaBranchModel[] = this.filiais.filter(
      (x) =>
        toNumber(x.identificacaoFilial) === toNumber(filial.identificacaoFilial)
    );

    let encontrado: boolean = duplicado.length > 1;

    filial.validado = !encontrado;

    if(filial.identificacaoFilial===""){
      filial.msgValidacao = "Campo obrigatório";
    }else{
      filial.msgValidacao = encontrado ? "Filial já adicionada." : null;
    }
  }

  obterInscricao(): string {
    let inscricao = this.cpfCnpjPipe.transform(this.empresa.cpfCnpj);
    return inscricao.substring(0, inscricao.indexOf("/") + 1);
  }

  exibirMensagem(err: any): void {
    if (err.code === 400) {
      this.toast.callModalMessage(null, err.message[0], null, null, null);
    } else {
      this.toast.callModalMessage(null, err, null, null, null);
    }
  }

  fecharModal(): void {
    this.exibirAutorizacao = false;
  }

  fecharModalConclusao(): void {
    this.exibirConclusao = false;
  }

  respostaApi(value: any): void {
    if (value.status === "success") {
      this.exibirAutorizacao = false;
      let mapper = new TermApprovalMapper();
      this.shareTermData.setStatusTerm(mapper.mapTo(value.data[0]));

      this.totalMasters = value.data[0].aprovacoes.length;

      this.exibirConclusao = true;
    } else {
      this.exibirAutorizacao = false;
      this.exibirMensagem(value.error);
    }
  }

  processar(): void {
    this.verificarDadosEnvio();
    this.prepararFiliais();
  }

  prepararFiliais(): void {
    let data = this.filiais
      .filter((x) => x.validado === true)
      .map((item: any) => {
        return {
          cnpj: toNumber(item.cnpjInscricao + item.identificacaoFilial),
        };
      });

    data.forEach((item: any) => {
      this.apiPayload.filiais.push(item.cnpj.trim());
    });

    this.exibirAutorizacao = true;
  }
}
