<div class="fibra-card" >
  <div class="body col-12" style="display: flex;">
    <div class="fibra-card-title col-4">
      Valor total 
    </div>
    <div class="col-1">
      <hr>
    </div>
   
      <div class="fibra-card-value col-6">
        <div class="text col-4">
          <small>Valor Aplicado</small>
          <span>R$ {{valorTotal.valorAplicado | number: '1.2-2' }}</span>
        </div>
    
        <div class="text col-4">
          <small>Rendimento Bruto</small>
          <span>R$ {{valorTotal.rendBruto | number: '1.2-2' }}</span>
      
        </div>
    
        <div class="text col-4">
          <small>Valor Líquido</small>
          <span>R$ {{valorTotal.valorLiq | number: '1.2-2' }}</span>
      
        </div>
      </div>
  </div>

  


</div>
