import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fibra-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
})
export class FormControlComponent implements OnInit {
  @Input() public label: string;

  constructor() {}

  ngOnInit() {}
}

