export interface KeysEntity {
    chave: string;
    chave_tipo: string;
    id: string;
    identificacao_psp: string;
    titular_conta: {
        cpf_cnpj: string;
        cpf_cnpj_mascara: string;
        nome: string;
        nome_comercial: string;
        tipo_titular: string;
    };
    status: string;
    informacao_adicional_conta: {
        agencia: string;
        conta: string;
        tipo_conta: string;
        data_abertura: string;
        agencia_mascara: string;
        conta_mascara: string

    };
    data_criacao: string;
    dataHoraFormatada: string;
    mesma_titularidade: boolean;
}

export interface KeysDataEntity{
    keys: KeysEntity[]
}

export interface KeysResponse {
    code: number;
    status: string;
    data?: KeysDataEntity;
}

export interface KeysSearchResponse {
    code: number;
    status: string;
    data?: KeysEntity;
}

export enum KeyTypes {
    ALEATORIO =  '1',
    CPF = '2',
    CNPJ =  '3',
    EMAIL = '4',
    CELULAR = '5'
}

export interface TemPendenciaResponse {
    code: number;
    status: string;
    data?: { temPendenciasChave: boolean, temPendenciasDoacao: boolean, temPendenciasRequisicao: boolean };
}