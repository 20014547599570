import { BankDataShare } from '@src/core/usecases/data-share/bank-data-share.service';
import {
  Component,
  Output,
  EventEmitter,
  PipeTransform,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import {
  SelectorDataShare,
  SelectBeneficiaryDataShare,
} from '@src/data/repository/data-share-repository';
import {
  BeneficiariesRequest,
  FavoritesEnum,
  RemoveAndAddRequest,
} from '@src/core/domain/transfer/transfer.model';
import { GetFavoritesUsecase } from '@src/core/usecases/transfer/beneficiaries/get-favorites-usecases';
import { DeleteFavoritesUsecase } from '@src/core/usecases/transfer/beneficiaries/delete-favorites-usecases';
import * as Util from '@src/shared/util-common';
import { DeleteFavoritesAccountUsecase } from '@src/core/usecases/transfer/beneficiaries/delete-favorites-account-usecases';
import { RolesService } from '@src/shared/services/roles.service';

@Component({
  selector: 'fibra-list-beneficities-transfer',
  templateUrl: './beneficities-list.component.html',
  styleUrls: ['./beneficities-list.component.scss'],
})
export class BeneficitiesTransferListComponent implements OnInit {
  public count;
  public data;
  public loading;
  @Output() public emitBeneficiaries: EventEmitter<any> = new EventEmitter();
  @Output() public emitDeleteAccount: EventEmitter<any> = new EventEmitter();
  @Output()
  public emitDeleteBeneficiary: EventEmitter<any> = new EventEmitter();
  @Output() public emitToast: EventEmitter<any> = new EventEmitter();
  public hide;
  public search;
  public genericTitle = 'Nenhum beneficiário salvo';
  public genericMessage =
    'Você ainda não possui um beneficiário salvo em sua lista. Toque no botão Novo beneficiário para fazer uma transferência.';
  public typeColorToast;
  public REDCOLOR = '#ED6C6C';

  removeMask = (value) => Util.removeMask(value);

  constructor(
    private router: Router,
    private shareBeneficiary: SelectBeneficiaryDataShare,
    private shareBank: BankDataShare,
    private getBeneficiaries: GetFavoritesUsecase,
    private deleteBeneficiary: DeleteFavoritesUsecase,
    private deleteAccountbenefitiary: DeleteFavoritesAccountUsecase,
    private roles: RolesService
  ) {}

  ngOnInit(): void {}

  searchBeneficiaries(text: string, pipe: PipeTransform): FavoritesEnum[] {
    return this.data.filter((res) => {
      const term = text.toLowerCase();
      return (
        res.nome_beneficiario.toLowerCase().includes(term) ||
        pipe.transform(res.num_cpf_cnpj).includes(term)
      );
    });
  }

  requestBeneficiaries = () => {
    this.loading = true;
    this.hide = false;
    const params = {
      nome_beneficiario: '',
      num_cpf_cnpj: '',
      num_conta: '',
    };
    this.getBeneficiaries
      .execute(params)
      .subscribe(this.requestSuccess, this.requesteError, this.requestComplete);
  };

  requestSearch(value) {
    this.loading = true;
    this.hide = false;
    const regex = /[0-9]/;

    if (regex.test(value)) {
      const params: BeneficiariesRequest = {
        nome_beneficiario: '',
        num_cpf_cnpj: value,
        num_conta: '',
      };
      this.getBeneficiaries
        .execute(params)
        .subscribe(
          this.requestSuccess,
          this.requesteSearchError,
          this.requestComplete
        );
    } else {
      const params: BeneficiariesRequest = {
        nome_beneficiario: value,
        num_cpf_cnpj: '',
        num_conta: '',
      };

      this.getBeneficiaries
        .execute(params)
        .subscribe(
          this.requestSuccess,
          this.requesteSearchError,
          this.requestComplete
        );
    }
  }

  private requestSuccess = (value) => {
    this.data = Util.verifyArray(value.data).sort((a, b) => {
      if (
        a.nome_beneficiario.toLowerCase() > b.nome_beneficiario.toLowerCase()
      ) {
        return 1;
      }
      if (a.nome_beneficiario < b.nome_beneficiario) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    setTimeout(() => {
      this.setBackgroundColor();
    }, 50);
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.data);
    this.loading = false;
  };

  private requesteError = () => {
    this.data = [];
    this.hide = true;
    this.loading = false;
    this.genericTitle = 'Nenhum beneficiário salvo';
    this.genericMessage =
      'Você ainda não possui um beneficiário salvo em sua lista. Toque no botão Novo beneficiário para fazer uma transferência.';
  };

  private requesteSearchError = () => {
    this.data = [];
    this.hide = true;
    this.loading = false;
    this.genericTitle = 'Você não possui itens';
    this.genericMessage = 'Dados inexistentes';
  };

  deleteBeneficiaryRequest(cod) {
    const params: RemoveAndAddRequest = {
      cod_beneficiario: cod.cod_beneficiario,
    };

    this.deleteBeneficiary
      .execute(params)
      .subscribe(this.deleteSuccess, this.deleteError);
  }

  private deleteSuccess = (_value) => {
    this.requestBeneficiaries();
  };

  private deleteError = (_err) => {
  };

  deleteAccountBeneficiaryRequest(cod: number) {
    const params: RemoveAndAddRequest = {
      cod_beneficiario_conta: cod,
    };

    this.deleteAccountbenefitiary
      .execute(params)
      .subscribe(this.deleteSuccess, this.deleteError);
  }

  newBeneficiary(beneficiary) {
    this.shareBeneficiary.setValue({
      beneficiary: beneficiary,
      quote: beneficiary.contas[0],
    });
    this.shareBank.setValue('new');
    this.router.navigate(['/bank']);
  }

  initialLetters(name) {
    name = name.replace(/\s(de|da|dos|das)\s/g, ' ');
    const initials = name.match(/\b(\w)/gi);
    return (
      initials[0] + (initials[1] !== undefined ? initials[1] : initials[0])
    ).toUpperCase();
  }

  setBackgroundColor() {
    const arrayColor = [
      '#CC092F',
      '#082A4D',
      '#FEF200',
      '#0070AF',
      '#003399',
      '#FF984E',
      '#CE4343',
      '#8A05BE',
      '#F00003',
      '#C4C4C4',
    ];
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        const color = arrayColor[Math.floor(Math.random() * arrayColor.length)];
        const elem = document.getElementById(`background${i}`);
        elem.setAttribute('style', `background: ${color}`);
      }
    }
  }

  selectBeneficiary(value, value2) {
    this.typeColorToast = this.REDCOLOR;
    var permissoes = this.roles.get();
    if(permissoes.includes('TRANSF.TRANSF_OTR_TIT_BC') && value2.cod_banco != 224 && value.flag_titularidade == 1){
      
      this.shareBeneficiary.setValue({ beneficiary: value, quote: value2 });

      this.router.navigate(['/same-ownership']);
    
    }else if(permissoes.includes('TRANSF.TRANSF_MSM_TIT_OTR_BC') && value2.cod_banco != 224 && value.flag_titularidade == 0){

      this.shareBeneficiary.setValue({ beneficiary: value, quote: value2 });

      this.router.navigate(['/same-ownership']);

    }else if(permissoes.includes('TRANSF.TRANSF_OTR_TITU') && value2.cod_banco == 224 && value.flag_titularidade == 1){
      
      this.shareBeneficiary.setValue({ beneficiary: value, quote: value2 });

      this.router.navigate(['/same-ownership']);

    }else if(permissoes.includes('TRANSF.TRANSF_MSM_TITU') && value2.cod_banco == 224 && value.flag_titularidade == 0){

      this.shareBeneficiary.setValue({ beneficiary: value, quote: value2 });

      this.router.navigate(['/same-ownership']);

    }else{
      this.emitToast.emit("Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.");
    }
  }

  setBeneficiary(item) {
    this.shareBeneficiary.setValue(item);

    this.router.navigate(['/same-ownership']);
  }

  pad(num:number, size:number): string {
      let s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
  }

  openDetails() {}

  emitToastDetails(text){
    this.emitToast.emit(text)
  }
}
