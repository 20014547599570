import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import * as Util from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { PixTransferDataShare } from '@src/core/usecases/data-share/pix-transfer-data-share.service';
import { PixComprovanteDataShare } from '@src/core/usecases/data-share/pix-comprovante-data-share.service';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';

@Component({
  selector: 'fibra-pix-confirm-devolution',
  templateUrl: './pix-confirm-devolution.component.html',
  styleUrls: ['./pix-confirm-devolution.component.scss'],
})

export class PixConfirmDevolutionComponent implements OnInit {
  @Output() loadConfirm: EventEmitter<any> = new EventEmitter();
  refundData: any = [];

  constructor(
    private route: Router,
    public transferShare: PixTransferDataShare,
    public transferComprovante: PixComprovanteDataShare,
    public getBeneficiariesDataRepository: PixGetBeneficiariesDataRepository,
  ) {
    this.textBtn = 'Devolver';
    this.textDate = 'Data';
  }
  public beneficiaryData;
  public bank;
  public requestDataApi;
  public showModalValidation = false;
  public isPending;
  public dateCompare = false;
  public companyData;
  public textBtn;
  public textDate;
  public infoComprovantes;
  public loading: boolean = false;

  public params;
  public apiCallBack;
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit(): void {
    this.refundData = this.transferShare.getValue();
    console.log(this.refundData);
  }

  private changeDateCompare(compare?) {
    if (compare) {
      this.textBtn = 'Confirmar agendamento';
      this.textDate = 'Agendada para';
    }
  }


  htmlShowCpf(val) {
    let format = Util.formatCpfCnpj(val);
    return Util.protectShowCpf(format);
  }

  private dateFormat(date) {
    const jsdate = new Date(
      date.split('/')[2],
      date.split('/')[1] - 1,
      date.split('/')[0]
    );
    return jsdate.toISOString().slice(0, 19);
  }

   private successTransfer = (value) => {
    this.transferComprovante.setValue(value)
    localStorage.setItem('view-selecionada', 'confirmRefundPix-pixRefundView');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  } 

  private errorTransfer = (err) => {
    this.showModalValidation = false;
    var erroTimeout = false;
    if (err.error && err.error.message && err.error.message instanceof Array && err.error.message.length > 0) {
      this.toast.callModalMessage(null, `${err.error.message[0]}`);
    } else if(err.error && err.error.message && err.error.message.length > 0){
      this.toast.callModalMessage(null, 'Transação indisponivel. Tente novamente mais tarde.');
      erroTimeout = true
    }else{
      this.toast.callModalMessage(null, 'Ocorreu um erro ao realizar a operação.');
      erroTimeout = true
    }

    //Remover quando nao quiser redirecionar para tela de processamento
    if(erroTimeout){
      this.infoComprovantes = {
        data: null,
        destinoBanco: this.beneficiaryData.beneficiario,
        origemBanco: this.beneficiaryData.contaDebito,
        erroTransfer: erroTimeout
      }
      this.transferComprovante.setValue(this.infoComprovantes)
      localStorage.setItem('view-selecionada', 'transferir-pixTransferComprovante');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }
    
  } 

  public securityResponse = value => {
    if (value.status === 'success') {
      this.successTransfer(value);
    } else {
      this.errorTransfer(value);
    }
  } 

  public closeModal = () => (this.showModalValidation = false);

  public goNext(){
    this.apiCallBack = 124;
    this.showModalValidation = true;
  }

  public securityRequest = () => {
    return this.refundData.payload;
  }
  
  navigateBack() {
    localStorage.setItem('view-selecionada', 'refund-transfer');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  returnAgencyFormat(account) {
    return Util.returnAgency(account);
  }

  returAccountFormat(account) {
    return Util.formatValueAccount(account);
  }
}
