import { PixGetTermsDataRepository } from '@src/data/repository/pix/terms/pix-get-terms-repository';
import { GetPendencyLimitsApprovalUsecase } from './../../../../core/usecases/dashboard/get-pendency-limits-approval.usecase';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef,OnDestroy, Component, Input, OnInit, Output, ViewChild, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { DatePipe } from '@angular/common';
import { LimitChangeTimeComponent } from '../../components/limit-change-time/limit-change-time.component';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { PixAccountLimitesUseCases } from '@src/core/usecases/pix/pix-limites-account.usecase';
import jwt_decode from "jwt-decode";
import { PostOtpUsecase } from '@src/core/usecases/otp/post-otp.usecase';
import { GetPendencyLimitReducePixUsecase } from '@src/core/usecases/dashboard/get-pendency-limit-reduce-pix.usecase';
import { ModalIncreaseLimitPixComponent } from '@src/presentation/web/components/modal-increase-limit-pix/modal-increase-limit-pix.component';

@Component({
  selector: 'fibra-pix-limites',
  templateUrl: './pix-limites.component.html',
  styleUrls: ['./pix-limites.component.scss']
})

export class PixLimitesComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(LimitChangeTimeComponent, null) limitChange: LimitChangeTimeComponent;
  @ViewChild('content', { static: true }) content: HTMLElement;
  @ViewChild('contentReprove', { static: true }) contentReprove: HTMLElement;
  @Input() accountNum: [{}];
  @Output() redirectingTerm = new EventEmitter();
  @Input() receivedPendencyLimit: boolean;

  // New implementations
  accounts = [];
  editCall: boolean = false;
  editTimeCall: boolean = false;
  timeNight: number; // = 2000;
  timeDay: number = 1959;
  public seletedTypeView: string = 'contas';
  public showModalValidation: boolean = false;
  formChanged: boolean = false;
  ValueFormOnSave: any;
  onSaveData: boolean = false;
  statusForm: boolean = false;
  allowLimit: number;
  loadingLimit: boolean = false;
  loading: boolean = true;
  maxLimiteAlert: boolean = false;
  successControlChangeForm: boolean = false;
  globalValueForm;

  public seletedFilter: string = 'pending';
  public typeColorToast: string;
  public BLUECOLOR = '#6CBED9';
  public REDCOLOR = '#ED6C6C';
  public showTerm = false;
  showComponent = false;
  public isCadastro = false;
  public notif: boolean = false;
  public getTermSubscription: Subscription;
  dataLimits: Observable<{}>;
  _unSubscribe: Subscription;
  pendencySubscription: Subscription;
  numberAccountSelected: string;
  apiCallback: number;
  showModalOtpChangeHourLimit: boolean;
  receiveDayTime: number;
  receiveNightTime: number;
  controlToastBack: boolean;
  objectPendency = [];

  checkErrorMaxPix = [];
  checkErrorMinPix = [];
  checkErrorMaxPixTransaction = [];

  receivErrorMax: boolean;
  receivErrorMin: boolean;
  receivErrorMaxTransaction: boolean;

  temporaryDataLimit = [];

  formAllErrorsMax: BehaviorSubject<{}>;
  formAllErrorsMaxTransaction: BehaviorSubject<{}>;
  formAllErrorsMin: BehaviorSubject<{}>;
  valuesToPayload: BehaviorSubject<{}>;

  showModalAlertLimitChange: boolean = false;
  showToastHour: boolean = false;
  valueChanged = 0;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    public getBeneficiariesService: PixGetBeneficiariesDataRepository,
    private modalService: NgbModal,
    private router: ActivatedRoute,
    private getAccountLimitPIX: PixAccountLimitesUseCases,
    private getPendencyLimitsApprovalUsecase: GetPendencyLimitsApprovalUsecase,
    private render: Renderer2,
    private serviceTerms: PixGetTermsDataRepository,
    private observeChangeLimit: GetPendencyLimitReducePixUsecase
  ) {

  }
  ngOnDestroy(): void {
    //this.toast.closeModal();
    this.notif = false;
    this.pendencySubscription.unsubscribe();
    this.getTermSubscription.unsubscribe();
  }

  ngOnInit() {
    this.getTermAceite();

    this.formatArrayAccounts(this.router.snapshot.data['resolve'].data);
    //this.dataLimits = this.mountArray(this.newArrayMock.data[0]);

    //Controla a exibição dos erros de formulário
    this.controlShowErros();
    this.mountPayload();

    if(this.accountNum){
      this.accountSelected(this.accountNum)
    }
  }

  ngAfterViewInit() {
    this.getPendencyDashboard();
    if (this.verifyLocalStorage()) {
      this.seletedTypeView = 'horario';
      this.removeLocalStorage();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  mountArray(arrReq) {

    const maxLimit = this.allowLimit;
    const diurno = this.conditionFilter(arrReq.valores, 'DIURNO', 'MESMA_TITULARIDADE', 'OUTRA_TITULARIDADE_PF', 'OUTRA_TITULARIDADE_PJ');
    const noturno = this.conditionFilter(arrReq.valores, 'NOTURNO', 'MESMA_TITULARIDADE', 'OUTRA_TITULARIDADE_PF', 'OUTRA_TITULARIDADE_PJ');

    let data = [{
      maxLimiteValue: maxLimit,
      title: 'PIX',
      diurnoMesma: 0,
      diurnoOutraPJ: 0,
      diurnoOutraPF: 0,
      noturnoMesma: 0,
      noturnoOutraPJ: 0,
      noturnoOutraPF: 0,
      transacaoDiurnaMesma: 0,
      transacaoDiurnaOutraPJ: 0,
      transacaoDiurnaOutraPF: 0,
      transacaoNoturnaMesma: 0,
      transacaoNoturnaOutraPJ: 0,
      transacaoNoturnaOutraPF: 0
    }];

    data.forEach((el, i) => {
      el.diurnoMesma = diurno[0].valorMaximoTotal;
      el.diurnoOutraPJ = diurno[1].valorMaximoTotal;
      el.diurnoOutraPF = diurno[2].valorMaximoTotal;

      el.noturnoMesma = noturno[0].valorMaximoTotal;
      el.noturnoOutraPJ = noturno[1].valorMaximoTotal;
      el.noturnoOutraPF = noturno[2].valorMaximoTotal;

      el.transacaoDiurnaMesma = diurno[0].valorMaximoTransacao;
      el.transacaoDiurnaOutraPJ = diurno[1].valorMaximoTransacao;
      el.transacaoDiurnaOutraPF = diurno[2].valorMaximoTransacao;

      el.transacaoNoturnaMesma = noturno[0].valorMaximoTransacao;
      el.transacaoNoturnaOutraPJ = noturno[1].valorMaximoTransacao;
      el.transacaoNoturnaOutraPF = noturno[2].valorMaximoTransacao;
    });

    return data;
  }

  conditionFilter(data: any, perido: string, titularidade1: string, titularidade2: string, titularidade3: string) {
    return data.filter(a => a.tipoPeriodo === perido && a.tipoTransacao === 'PIX' && (a.tipoTitularidade === titularidade1 || a.tipoTitularidade === titularidade2 || a.tipoTitularidade === titularidade3));
  }

  verifyLocalStorage(): boolean {
    return (localStorage.getItem('redirectPixLimitsTab') !== null && localStorage.getItem('redirectPixLimitsTab') === 'redirectPixLimitsHorario');
  }

  removeLocalStorage(): void {
    localStorage.removeItem('redirectPixLimits');
    localStorage.removeItem('redirectPixLimitsTab');
  }

  public closeModal(_$event): void {
    this.showModalValidation = false;
    this.modalService.dismissAll();
  }

  public closeModalApi(_$event): void {
    this.showModalValidation = false;
    this.showModalOtpChangeHourLimit = false;
    this.modalService.dismissAll();
  }

  public titleCaseWord(word: string) {
    var splitStr = word.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  public confirmeCancelChange() {
    if(this.editTimeCall) this.editTimeCall = false;
    this.editCall = false;

  }

  handleFilter(type: any) {
    this.seletedFilter = type;
  }

  public securityResponse = value => {
    this.requestSeccess(value);
    this.requestError(value);
  }

  private requestError = (err) => {

    if(err.error && err.error.code === 500){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }

    if(err.error && err.error.code === 404 && err.error.status === 'error'){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }
  }

  private requestSeccess = (sc) => {

    if(sc.code === 200 && sc.data.indexOf('alterado com sucesso') !== -1){
      this.globalValueForm = this.ValueFormOnSave;
      this.dataLimits = new Observable<{}>((obs) => {
        obs.next([this.ValueFormOnSave]);
      });    
      
      this.successControlChangeForm = true;
      this.showModalValidation = false;
      
      setTimeout(() => {
        this.typeColorToast = '#73B599';
        this.toast.callModalMessage(null, sc.data, null, false, true);     
      });
      this.editTimeCall = false;  
      return;   
    }

    if(sc.code === 200 && sc.data.indexOf('solicitada') !== -1){
      this.globalValueForm = this.ValueFormOnSave;
      this.dataLimits = new Observable<{}>((obs) => {
        obs.next([this.ValueFormOnSave]);
      });    

      this.successControlChangeForm = true;
      this.showModalValidation = false;
      
      setTimeout(() => {
        this.typeColorToast = '#73B599';
        this.toast.callModalMessage(null, sc.data, null, false, true);     
      });
      this.editTimeCall = false;
      return;   
    }

    if(sc.code === 200 && sc.data.indexOf('pendente') !== -1){
      this.globalValueForm = this.ValueFormOnSave;
      this.dataLimits = new Observable<{}>((obs) => {
        obs.next([this.ValueFormOnSave]);
      });    

      this.successControlChangeForm = true;
      this.showModalValidation = false;
      
      setTimeout(() => {
        this.typeColorToast = '#D69D10';
        this.toast.callModalMessage(null, sc.data, null, false, true);     
      });
      this.editTimeCall = false;      
      return;   
    }

  }

  public securityResponseChangeHourLimit = value => {
    this.requestSeccessChangeHourLimit(value);
    this.requestErrorChangeHourLimit(value);
  }

  private requestErrorChangeHourLimit = (err) => {

    if(err.error && err.error.code === 500){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }

    if(err.error && err.error.code === 404 && err.error.status === 'error'){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar alterar o limite.', ' Tente novamente.', false, true);
      return;
    }
  }

  private requestSeccessChangeHourLimit = (sc) => {
    if(sc.code === 200 && sc.data.indexOf('solicitada') !== -1){
      this.typeColorToast = '#73B599';
      this.toast.callModalMessage(null, sc.data, null, false, true);     
      this.editTimeCall = false;
      return;   
    }

    if(sc.code === 200 && sc.data.indexOf('pendente') !== -1){
      this.typeColorToast = '#D69D10';
      this.toast.callModalMessage(null, sc.data, null, false, true);     
      this.editTimeCall = false;
      return;   
    }
  }

  public securityRequest = () => {
    let token = sessionStorage.getItem('accessToken');
    const decoded: any = jwt_decode(token);
    const tipoAcesso = localStorage.getItem('acessoMaster') === 'true' ? 1 : 2;

    return {
      nrConta: this.numberAccountSelected,
      codEmpresa: 0,
      emailOperador: decoded.email,
      empresaId: 0,
      nomeSolicitante: decoded.name,
      pix: true,
      limiteDiario: this.allowLimit,
      valores: [
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.ValueFormOnSave.diurnoMesma,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoDiurnaMesma,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.ValueFormOnSave.diurnoOutraPJ,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoDiurnaOutraPJ,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 1,
          tipoPeriodo: "DIURNO",
          valorMaximoTotal: this.ValueFormOnSave.diurnoOutraPF,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoDiurnaOutraPF,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 1,
          tipoTitularidade: "MESMA_TITULARIDADE",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.ValueFormOnSave.noturnoMesma,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoNoturnaMesma,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 2,
          tipoTitularidade: "OUTRA_TITULARIDADE_PJ",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.ValueFormOnSave.noturnoOutraPJ,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoNoturnaOutraPJ,
        },
        {
          idTipoTransacao: 1,
          tipoTransacao: "PIX",
          idTipoTitularidade: 3,
          tipoTitularidade: "OUTRA_TITULARIDADE_PF",
          idTipoPeriodo: 2,
          tipoPeriodo: "NOTURNO",
          valorMaximoTotal: this.ValueFormOnSave.noturnoOutraPF,
          valorMaximoTransacao: this.ValueFormOnSave.transacaoNoturnaOutraPF,
        }
      ],
      aprovadores: [
        {
          idPessoa: 0,
          nomeAprovador: decoded.name,
          idTipoAcesso: tipoAcesso,
        }
      ]
    }
  }

  public securityRequestToLimitHour = () => {
    let token = sessionStorage.getItem('accessToken');
    const decoded: any = jwt_decode(token);
    const tipoAcesso = localStorage.getItem('acessoMaster') === 'true' ? 1 : 2;

    return {
      nrConta: this.numberAccountSelected,
      horarioDiurnoInicio: "06:01:00",
      horarioDiurnoFim: this.receiveDayTime+":00",
      horarioNoturnoInicio: this.receiveNightTime+":00",
      horarioNoturnoFim: "06:00:59",
    };
  }

  goBack(): void {
    // Content code
  }

  responseApiModal(_$event): void {
    this.showModalValidation = false;
  }

  backRefused(): void {
    // Content code
  }

  formatArrayAccounts(acc): Promise<void> {

    if(typeof acc === 'undefined') {
      this.serviceTerms.getPixTerms().subscribe((rs:any) => {
        let data = rs.data;
        let checkIfExistApproveAccount = data.map((f) => f.status === 'APROVADO');

        if(checkIfExistApproveAccount.includes(true)){
          this.showComponent = true;

          rs.data.forEach(cnt => {
            this.accounts.push({
              indice: cnt.conta.num_indice,
              name: `${cnt.conta.numero} - ${this.titleCaseWord(cnt.conta.exibicao)}`,
              value: cnt.conta.numero,
              modality_account: cnt.conta.exibicao,
              selected: false
            })
          });

          return
        }

        this.showTerm = true;   
      });
      return
    }

    if(!this.showTerm) {
      this.showComponent = true;
      acc.forEach(cnt => {
        this.accounts.push({
          indice: cnt.conta.num_indice,
          name: `${cnt.conta.numero} - ${this.titleCaseWord(cnt.conta.exibicao)}`,
          value: cnt.conta.numero,
          modality_account: cnt.conta.exibicao,
          selected: false
        })
      })
    }
  }

  accountSelected(evt): void {
    this.numberAccountSelected = evt;
    this.loadingLimit = true;

    if (this._unSubscribe) {
      this._unSubscribe.unsubscribe();
    }

    
    const ObserveChangeLimit = this.observeChangeLimit.execute();
    const getAccountLimitPix = this.getAccountLimitPIX.execute(this.numberAccountSelected);

    this._unSubscribe = forkJoin(ObserveChangeLimit, getAccountLimitPix).subscribe((rs: any) =>  {
      let pendency = rs[0].data.pendenciaPix;
      this.maxLimiteAlert = rs[0].data.pendenciaPix;

      if(pendency){
        this.valueChanged = rs[1].data.find(v => v.tipoAlteracao === 'LIMITE' && v.pix && v.status === 'Aprovado').limiteDiario;        
        this.showModalAlertLimitChange = true;
      }

      const limitReady: any = rs[1].data.find((e: any) => e.nrConta === evt && e.tipoAlteracao === 'LIMITE' && e.pix);
      const hourLimit: any = rs[1].data.find((e: any) => e.nrConta === evt && e.tipoAlteracao === 'HORARIO');

      if(hourLimit.horarioNoturnoInicio === '20:00:00') this.timeNight = 2000;
      if(hourLimit.horarioNoturnoInicio === '22:00:00') this.timeNight = 2200;

      if(hourLimit.horarioDiurnoFim === '21:59:00') this.timeDay = 2159;
      if(hourLimit.horarioDiurnoFim === '19:59:00') this.timeDay = 1959;

      this.loadingLimit = false;

      this.allowLimit = limitReady.limiteDiario;

      this.dataLimits = new Observable<{}>((obs) => {
        obs.next(this.mountArray(limitReady))
      })

    }, error => {
      console.warn(error)
    });

    // REQUEST LIMITES PIX    
    /* this._unSubscribe = this.getAccountLimitPIX.execute(this.numberAccountSelected).subscribe(rs => {
    
      const limitReady: any = rs.data.find((e: any) => e.nrConta === evt && e.tipoAlteracao === 'LIMITE' && e.pix);
      const hourLimit: any = rs.data.find((e: any) => e.nrConta === evt && e.tipoAlteracao === 'HORARIO');

      if(hourLimit.horarioNoturnoInicio === '20:00:00') this.timeNight = 2000;
      if(hourLimit.horarioNoturnoInicio === '22:00:00') this.timeNight = 2200;
      
      this.loadingLimit = false;

      this.allowLimit = limitReady.limiteDiario;

      this.dataLimits = new Observable<{}>((obs) => {
        obs.next(this.mountArray(limitReady))
      })

    }, (error:any) =>  {
      console.log(error)
    }); */
  }

  async getAllowLimit() {
    return this.allowLimit
  }

  editar(): void {
    this.editCall = true;
  }

  cancelEdit(): void {
    this.editCall = false;
    this.controlToastBack = false;
    this.globalValueForm = undefined;
  }

  editCancel(): void {

    if(this.controlToastBack){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ao cancelar você perderá tudo que foi preenchido. ', 'Deseja mesmo cancelar?', true, false);
      return;
    }    

    this.editCall = false; 
    this.formChanged = false
  }

  saveTimeLimit(): void {
    this.apiCallback = 129;
    this.showModalOtpChangeHourLimit = true;
  }

  editTimeLimit(): void {
    this.editTimeCall = true;
  }

  cancelarTime(): void {
    if(this.showToastHour){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'Ao cancelar você perderá tudo que foi preenchido. ', 'Deseja mesmo cancelar?', true, false);
      return;
    }

    if(this.editTimeCall) this.editTimeCall = false;
    this.editCall = false;
  }

  selectedTab(tab: string): void {
    if(tab !== 'contas'){
      this.showTerm = false;
      this.editCall = false;
    }

    this.seletedTypeView = tab;
    this.toast.closeModal();
  }

  callBackIfChangeForm(evt: any): void {
    let data = evt.value;

    if(typeof this.globalValueForm !== 'undefined' && this.globalValueForm !== null){
      let control = Object.keys(data).some(key => data[key] !== this.globalValueForm[key]);
      if(control){
        this.controlToastBack = true;  
        this.formChanged = true;
        this.globalValueForm = undefined;
      }
      return
    }

    this.controlToastBack = evt.flg;  
    this.formChanged = evt.flg    
  }

  getValuesFormOnChange(evt): void {

    let addKeyLimitMax = Object.assign(evt, { maxLimiteValue: this.allowLimit });
    this.ValueFormOnSave = addKeyLimitMax;

    this.apiCallback = 125;
    this.securityRequest();
    this.showModalValidation = true;

    this.temporaryDataLimit.push(addKeyLimitMax);
    this.valuesToPayload.next(this.temporaryDataLimit);

    setTimeout(() => {
      this.editCall = false;
      this.onSaveData = false;
      this.maxLimiteAlert = false;
    }, 1000);
  }

  saveChangeLimit(): void {
    if(this.receivErrorMax || this.receivErrorMaxTransaction){
      this.typeColorToast = '#ED6C6C';
      this.toast.callModalMessage(null, 'O valor não pode ser superior ao limite máximo diário', '', false, true);
      return
    }
    this.onSaveData = true;
  }

  forStatusObserve(evt): void {

    let max = evt.max;
    if (max) this.statusForm = true;
    if (!max) this.statusForm = false;

    let err = [evt];

    err.forEach((key) => {
      if (key.max) this.checkErrorMaxPix.push(key.max);
      if (!key.max) this.checkErrorMaxPix = [false];

      if (key.tr) this.checkErrorMaxPixTransaction.push(key.tr);
      if (!key.tr) this.checkErrorMaxPixTransaction = [false];

    })

    this.formAllErrorsMax.next([...this.checkErrorMaxPix]);
    this.formAllErrorsMaxTransaction.next([...this.checkErrorMaxPixTransaction]);
  }

  forStatusObserveErrorMin(evt): void {
    let errForm = []
    let err = [evt];
    let obj = err[0].anyErrorMin[0];

    Object.keys(obj).forEach(key => {
      if(obj[key]){
        errForm.push(true);
        return
      }
      errForm.push(false);
    })

    this.formAllErrorsMin.next([...errForm])
  }

  readExitComponent(evt: boolean): void {
    this.editTimeCall = evt;
  }

  getValueErrorMax() {
    return this.formAllErrorsMax.asObservable();
  }

  getValueErrorMaxTransaction() {
    return this.formAllErrorsMaxTransaction.asObservable();
  }

  getValueErrorMin() {
    return this.formAllErrorsMin.asObservable();
  }

  controlShowErros() {
    this.formAllErrorsMax = new BehaviorSubject<[]>([]);
    this.formAllErrorsMin = new BehaviorSubject<[]>([]);
    this.formAllErrorsMaxTransaction = new BehaviorSubject<[]>([]);

    //Observa erro max quando houver transação maior que o periodo
    this.getValueErrorMaxTransaction().subscribe((rs: [{}]) => {
      this.receivErrorMaxTransaction = rs.includes(true) ? true : false;
    });

    // Observa os erros do tipo MAX 
    this.getValueErrorMax().subscribe((rs: [{}]) => {
      this.receivErrorMax = rs.includes(true) ? true : false;
    });

    // Observa os erros do tipo Min 
    this.getValueErrorMin().subscribe((rs: [{}]) => {
      this.receivErrorMin = rs.includes(true) ? true : false;
      this.typeColorToast = '#ED6C6C';
      if (this.receivErrorMin) this.toast.callModalMessage(null, 'Valor inválido. Informe um valor maior que zero.', null, null, true);
      if (!this.receivErrorMin) this.toast.closeModal();
    });
  }

  mountPayload() {
    this.valuesToPayload = new BehaviorSubject<[]>([]);
  }

  getValueToPayload() {
    return this.valuesToPayload.asObservable();
  }

  receiveTimeChange(evt){
    let composeHour = evt.tNight.split(':')[0] +''+ evt.tNight.split(':')[1];
    this.showToastHour = (Number(composeHour) === this.timeNight) ? false : true;
    this.receiveDayTime = evt.tDay;
    this.receiveNightTime = evt.tNight;
  }

  public redirectTerm() {
    this.redirectingTerm.emit('termos');
  }

  getPendencyDashboard() {
    if(this.receivedPendencyLimit) {
      this.seletedTypeView = 'aprovacoes';
    }
    this.getNotification();
    
  }

  getNotification() {
    this.unsubscribeVariable(this.pendencySubscription);
     this.pendencySubscription = this.getPendencyLimitsApprovalUsecase.execute().subscribe((result) => {
      this.validationNotification(result);
   });
  }

  validationNotification(result) {
    this.objectPendency.push(result.data);
      this.objectPendency.map(data => {
        if(data.pendenciaLimitePix === true || data.pendenciaHorario === true) {
          this.notif = true;
        }

        if(data.pendenciaLimitePix === false && data.pendenciaHorario === false) {
          this.notif = false;
        }
        return;
      });
  }

  unsubscribeVariable(variable) {
    if(variable) {
      variable.unsubscribe();
    }
    return;
  }

  getTermAceite() {
    this.unsubscribeVariable(this.getTermSubscription);
    this.getTermSubscription = this.serviceTerms.getPixTerms()
      .subscribe(this.successTerm, this.errorTerm);
  }

 private successTerm = (value) => {
    if(value.status === 'success') {
      this.showTerm = false;
      this.loading = false;
    }
  }

  private errorTerm = (err) => {
    if(err.status === 500) {
      console.log('ocorreu um erro');
      this.showTerm = true;
      this.loading = false;
    }
    if(err.status === 404) {
      this.showTerm = true;
      this.loading = false;
      return;
    }
  }

  closeAlertModal(modal: HTMLElement){
    this.render.removeClass(modal, "animate__fadeIn");
    this.render.addClass(modal, "animate__fadeOut");
    
    setTimeout(() => {
      this.showModalAlertLimitChange = false;
    }, 500);
  }

  openIncreaseLimitModal(){
    this.modalService.open(ModalIncreaseLimitPixComponent, {
      centered: true,
      size: '400px',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

}
