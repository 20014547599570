import { GetAmazonArchiveDownloadUsecase } from '@src/core/usecases/charge/get-amazon-archiveDownload.usecase';
import { GetUrlAssignedUsecase } from '@src/core/usecases/charge/get-urlAssigned.usecase';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { DownloadArchiveDataShare } from '@src/data/repository/data-share-repository';
import { ErrorTypeModel } from '@src/core/domain/error/errorType.model';

import { GetArchiveDownloadUsecase } from '@src/core/usecases/charge/get-archiveDownload.usecase';
import { ArchiveEntity } from '@src/data/repository/charge/archive-repository/archive.entity';
import {
  ArchiveRequest,
  ArchiveModel,
} from '@src/core/domain/charge/archive.model';
import { PutArchiveUploadUsecase } from '@src/core/usecases/charge/put-archiveUpload.usecase';
import { UploadDataShare } from '@src/core/usecases/data-share/upload-data-share.service';
import { handleFile } from '@src/shared/util-common';

import { optionWallet } from '@src/data/repository/data-share-repository';
import { PaymentBatchRepository } from '@src/data/repository/payment-batch/payment-batch-repository';
import { error } from '@angular/compiler/src/util';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AccessDataRepository } from '@src/data/repository/access-repository/access.repository';

@Component({
  selector: 'fibra-upload-payment',
  templateUrl: './download-batch-payment.component.html',
  styleUrls: ['./download-batch-payment.component.scss'],
})
export class DownloadBatchPaymentComponent implements OnInit {
  fileToUpload: Set<File>;
  file: FormData;
  files: any[] = [];
  newFiles: any[] = [];
  messagem: any[] = [];
  removeMessage: any;
  exibirMessage: string;
  currentFile: any[] = [];
  loading: boolean;
  success: boolean;
  isMaster: boolean;
  fileToUploadV2: File;
  isUploadSucessV2 = false
  errorMessages = []
  horarios;
  uploadMessage: string;
  isUploadInProgress = false;
  showSecurityModal: boolean = false;
  filename: string;

  uploadAutomaticoAtivado: boolean = false;

  // Steps
  step1: boolean;
  step2: boolean;
  step3: boolean;
  step4: boolean;
  step5: boolean;
  step6: boolean;

  optionWallet = optionWallet;

  @Output() uploadFiles: EventEmitter<any>;
  @Output() refreshAPIData = new EventEmitter();
  nameFile;
  @Input() disabledDiv;
  @Input() activeTab;
  @ViewChild("fileDropRef", {
    static: false
  }) fileDropRef: ElementRef<HTMLInputElement>;
  accountsWithPix: any;
  loadingView: boolean = false;

  constructor(
    private dndshare: DownloadArchiveDataShare,
    private getUrlAssigned: GetUrlAssignedUsecase,
    private accesService: AccessDataRepository,
    private putArchiveUpload: PutArchiveUploadUsecase,
    private getArchiveDocumentacao: GetArchiveDownloadUsecase,
    private getAmazonArchiveDownload: GetAmazonArchiveDownloadUsecase,
    private uploadDataShare: UploadDataShare,
    private termsService: PaymentBatchRepository,
    private router: Router
  ) {
    this.uploadFiles = new EventEmitter();
    this.file = new FormData();
    this.loading = false;
    this.success = true;

    // Step
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
    this.step6 = false;
  }

  ngOnInit() {
    this.accesService.getAccess().subscribe((({ data }) => this.isMaster = data.acessoMaster))
    this.termsService.verificarAprovacaoAutomatica().subscribe(({ data }) => {
      this.uploadAutomaticoAtivado = data.aprovacaoAutomatica

      this.uploadMessage = this.uploadAutomaticoAtivado ? "Enviado Com Sucesso" : "Arquivo Enviado"
    })
    this.termsService.validarHorariosDePagamento().subscribe(({ data }) => {
      this.horarios = data

      this.horarios.valorlimite = Number(this.horarios.valorlimite.replace(",", "."))

    })

    const uploadBotTimming = localStorage.getItem("upload-batch-success")

    if (uploadBotTimming) {
      const estimatedTime = new Date(uploadBotTimming).toISOString()
      const currentTime = new Date().toISOString()

      if (currentTime >= estimatedTime) {
        localStorage.removeItem("upload-batch-success")
      }

      this.success = true
      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
      this.step4 = false;
      this.step5 = false;
      this.step6 = true
    }
    this.getUrlUpload();
  }

  private successDownload = (value: ArchiveEntity) => {
    if (value.status === 'success') {

      if (["CNAB240", "CNAB400", "Excel", "LaminaPagamentoEmLote"].includes(this.nameFile)) {
        let fileUrl = value.data.split("u0026").join("&");
        window.open(fileUrl, "_blank");
      }

      this.getAmazonArchiveDownload
        .execute(value.data)
        .subscribe((res: any) => {
          const name = this.nameFile;
          handleFile(res, name);
          this.nameFile = null;
        }, this.errorDownload);
    }
  }

  private errorUpload = (err: ErrorTypeModel) => {
    //console.log(err);
    this.loading = false;
  }

  /*private requestGetUrlAssigned = (obj: File) => {
     this.file.append(obj.name, obj, obj.name);
     const param: any = {
       arquivo: obj.name,
     };
 
     this.getUrlAssigned
       .execute(param)
       .subscribe(this.successUrlAssigned, this.errorUpload);
   }*/

  private verifysuccess = (boll: boolean) => {
    this.success = boll;
    this.uploadDataShare.setValue(boll);
  }

  public errorDownload(err: ErrorTypeModel) {
  }

  public downloadItem(doc, nameItem) {
    this.setName(nameItem);
    const param: ArchiveRequest = {
      documentacao: doc,
    };
    this.getArchiveDocumentacao
      .execute(param)
      .subscribe(this.successDownload, this.errorDownload);
  }

  private setName(nameItem) {
    this.nameFile = nameItem;
  }

  nextStep(step: number, files?) {
    switch (step) {
      case 2:
        // Steps
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;

        // Array Files
        this.fileBrowseHandlerArray(files);

        // Clear Files
        this.currentFile = [];

        // Current File
        this.currentFile = files;

        break;
      case 3:
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;

        // Array Files
        this.fileBrowseHandler(this.currentFile);
        //console.log(this.currentFile)

        break;

      case 4:
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;

        break;

      default:
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        break;
    }
  }

  public getUrlUpload() {
    this.termsService.getURL().subscribe((data: any) => {
      this.accountsWithPix = data['data'];
      if(localStorage.getItem("getArquivo")) {
        localStorage.removeItem("getArquivo");
      }
      localStorage.setItem('getArquivo', JSON.stringify(this.accountsWithPix))
      this.step6 = localStorage.getItem("payment-batch-pending-approval") ? true : false;
    })
  }

  openModalSystemFileUpload(){
    document.getElementById('fileDropRef').click();
  }

  onUpload(accountsWithPix: any) {
    this.isUploadInProgress = true
    this.loadingView = true;
    const url = accountsWithPix.url;
    const { nome_arquivo } = JSON.parse(localStorage.getItem('getArquivo'))
    localStorage.setItem("file-upload-cnab240", JSON.stringify({
      url,
      nome_arquivo,
      file: { ...this.fileToUploadV2 }
    }))

    this.termsService.Upload(nome_arquivo, url, this.fileToUploadV2).subscribe(data => {
      // this.termsService.Upload(nome_arquivo.nome_arquivo, url, this.fileToUploadV2).subscribe(data => {
      if (this.uploadAutomaticoAtivado) {
        this.validarToken();
        this.termsService.validacaoURL(nome_arquivo).subscribe(() => {},
          ({ error }) => {
            if (error.code === 400) {
              this.step1 = false;
              this.step2 = false;
              this.step3 = false;
              this.step4 = false;
              this.step5 = true;
              this.errorMessages = error.message;
              this.loadingView = false;
            } else if (error.code === 404 && error.data.length === 0) {
              if (this.uploadAutomaticoAtivado) {
                this.isUploadInProgress = false;
                this.getUrlUpload();
                this.refreshAPIData.emit()
              }
              this.isUploadSucessV2 = true
              this.loadingView = false;
            } else {
              this.isUploadSucessV2 = false
              this.loadingView = false;
            }
          }, () => {
            this.isUploadInProgress = false;
            this.loadingView = false;
          })
      } else {
        this.loadingView = false;
        this.termsService.detalharArquivosCNAB(nome_arquivo).subscribe(() => {
          this.router.navigateByUrl(`payment-batch/cnab240-review/${nome_arquivo}`)
        }, ({ error }) => {
          this.step1 = false;
          this.step2 = false;
          this.step3 = false;
          this.step4 = false;
          this.step5 = true;
          this.errorMessages = error.message;
        }), () => localStorage.removeItem('getArquivo')

      }
    })
  }

  ValidacaoUrl() {
    const nome_arquivo = JSON.parse(localStorage.getItem('getArquivo'))

    this.termsService.validacaoURL(nome_arquivo.nome_arquivo).subscribe(data => {
    }, error => {
      //console.log('Erro Chamada validação: ' + error)
      if (error.error.code == 400) {
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step5 = true;
      } else if (error.error.code === 404 && error.error.data.length === 0) {
        this.refreshAPIData.emit()
        this.isUploadSucessV2 = true
      } else {
        this.isUploadSucessV2 = false
      }
      this.messagem = error.error.message;
      this.removeMessage = this.messagem.slice(0, 1);
      if (this.removeMessage) {
        this.exibirMessage = this.removeMessage.toString();
      }

    }
    );
  }

  reload() {
    location.reload();
    return false;
  }

  verifyFile(event) {
    const { nome_arquivo: expectedFileName } = JSON.parse(localStorage.getItem('getArquivo'))
    this.newFiles = event;
    this.currentFile = [];
    this.isUploadSucessV2 = false

    this.currentFile = event

    this.fileToUploadV2 = event[0]
    //console.log(event[0].name)
    //console.log("Esperado", expectedFileName)
    //console.log("Nome", this.fileToUploadV2)

    if (this.fileToUploadV2.name !== expectedFileName) {

      this.step1 = false;
      this.step2 = false;
      this.step3 = false;
      this.step4 = false;
      this.step5 = true;

      this.errorMessages = ['Nome de arquivo inválido', `Nome do arquivo esperado: ${expectedFileName}`]
      return;
    }

    if (
      event[0].name > 0
    ) {
      this.nextStep(2, event);
    } else {
      this.nextStep(3, event);
    }
  }

  mudarCarteira(carteira) {
    if (carteira !== 'todas') {
      this.nextStep(3);
    }
  }

  handleDragFile(e: any) {
    if (!localStorage.getItem('payment-batch-pending-approval')) {
      this.getUrlUpload()
      this.fileDropRef.nativeElement.files = e

      setTimeout(() => this.verifyFile(e), 1000)
    }
  }

  onFileDropped($event) {
    //console.log($event)
    // this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  fileBrowseHandlerArray(files) {
    this.prepareFilesListArray(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.clearFiles();
    this.nextStep(1);
  }

  clearFiles() {
    this.files = [];
    this.currentFile = [];
  }

  private successUpload = (value: ArchiveModel) => {
    const params: ArchiveRequest = {
      data: value.data,
      arquivo: this.files,
    };
    this.putArchiveUpload.execute(params).subscribe(
      (archive) => {
        this.verifysuccess(true);
        this.loading = false;
        this.uploadFiles.emit(true);
      },
      (err) => {
        this.verifysuccess(false);
      }
    );
  }

  private successUrlAssigned = (value: ArchiveModel) => {
    if (value.status === 'success') {
      this.successUpload(value);
    }
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files.length) {
            this.loading = true
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.success = true;
              this.loading = false
              this.uploadFilesSimulator(index + 1);
              this.clearFiles();
              this.nextStep(4);
            } else {
              this.files[index].progress += 5;
            }
          } else {
            // this.clearFiles();
            clearInterval(progressInterval);
          }
        }, 200);
      }
    }, 1000);
  }

  prepareFilesList(files: Array<any>) {
    //console.debug(files)
    for (const item of files) {
      //console.log(item)
      item.progress = 0;
      this.files.push(item);
      //this.requestGetUrlAssigned(item);
    }
    this.uploadFilesSimulator(0);
  }

  prepareFilesListArray(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadNewFile() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
    this.step6 = false;
    localStorage.removeItem("payment-batch-pending-approval");
  }

  validarToken(){
    this.showSecurityModal = true;
    const { nome_arquivo } = JSON.parse(localStorage.getItem('getArquivo'));
    this.filename = nome_arquivo;
  }

  closeSecurityModal() {
    this.showSecurityModal = false
  }

  finishUpload(){
    this.showSecurityModal = false
  }
}

