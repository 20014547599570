import { AbstractControl } from '@angular/forms';

export class CnpjValidator {
  /**
   * Método estático responsável pela validação dos dados.
   *
   * @param AbstractControl control
   * @return object ou null caso válido
   */
  static validate(control: AbstractControl): { [key: string]: boolean } {
    if (this.validCnpj(control.value)) {
      return null;
    }
    return { cnpj: true };
  }
  /**
   * Valida um CNPJ.
   *
   * @param cnpj valor do cnpj a ser validado.
   * @return boolean informando se o cnpj é válido ou não.
   */
  static validCnpj(cnpj: any): boolean {
    if (
      !cnpj ||
      cnpj.length !== 14 ||
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false;
    }
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    // tslint:disable-next-line: triple-equals
    if (resultado != digitos.charAt(0)) {
      return false;
    }
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    // tslint:disable-next-line: triple-equals
    if (resultado != digitos.charAt(1)) {
      return false;
    }
    return true;
  }
}
