import { Mapper } from '@src/core/base/mapper';
import { WarrantyModel } from '@src/core/domain/dashboard/dashboard.model';
import { WarrantyEntity } from '@src/data/repository/dashboard/warranty/warranty.entity';

export class WarrantyRepositoryMapper extends Mapper<
  WarrantyEntity,
  WarrantyModel
> {
  mapFrom(param: WarrantyEntity): WarrantyModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: WarrantyModel): WarrantyEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
