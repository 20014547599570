import { Component, OnInit, Input } from '@angular/core';
import { ShowValuesDashboardDataShare } from '@src/core/usecases/data-share/show-values-dashboard-data-share.service';

@Component({
  selector: 'fibra-card-value-cheque',
  templateUrl: './card-value.component.html',
  styleUrls: ['./card-value.component.scss'],
})
export class CardValueChequeComponent implements OnInit {
  @Input() public backgroundColor = 'white';
  @Input() public color: string;
  @Input() public valueType: 'number' | 'text' = 'text';
  @Input() public valueCheque;
  @Input() public value;
  @Input() public titleCard: string;
  @Input() public hasToggleVisible: boolean;
  @Input() public tooltipTitle: HTMLElement;
  @Input() public exibicao: string = null;

  public isVisibleValue = true;
  showData = false;

  constructor(private shareShowValues: ShowValuesDashboardDataShare) {}

  ngOnInit() {
    this.showValuesShare();
  }

  public toggleVisibleValue() {
    this.isVisibleValue = !this.isVisibleValue;
  }

  showValuesShare(){
    this.shareShowValues.dataShared.subscribe( res => {
      this.showData = res &&  res.iconEyesOpen
    });
  }

}
