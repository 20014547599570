import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { AlteracoesLimitesRepository } from '@src/core/repositories/alteracoeslimites/alteracoeslimites.repository';
import { Response } from '@src/core/domain/response/response';

@Injectable({
  providedIn: 'root'
})
export class AlteracoesDataLimitesRepository extends AlteracoesLimitesRepository {

  constructor(private http: HttpClient) {
    super();
  }

  public markModalAsRead(idAlteracao: number): Observable<Response<any>> {
    return this.http
      .post<Response<any>>(`${environment.URLBase}v1/alteracoeslimites/markmodalasread`, { idAlteracao }, { headers: getHeader() })
  }

  public markDashAsRead(idAlteracao: number): Observable<Response<any>> {
    return this.http
      .post<Response<any>>(`${environment.URLBase}v1/alteracoeslimites/markdashasread`, { idAlteracao }, { headers: getHeader() })
  }
}