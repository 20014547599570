<div class="box-drop">
  <ng-template #account>
    <label>Conta</label>
    <label *ngIf="!loading" class="account-selected" #account>{{
      selected
    }}</label>
  </ng-template>

  <ng-container *ngIf="optionsProfile.length > 1; else account">
    <label class="selecione-conta">Selecione a conta</label>
    <fibra-header-dropdown
      *ngIf="!loading"
      class="dropdown-company-list"
      placeholder="{{ selected | titlecase }}"
      iconName="dropdown_pj"
      [options]="optionsProfile"
      (callback)="reloadSession($event)"
      removeShadown="true"
      customClass="dropdown-account-list"
      textColor="#4D86BF"
      id="selecioneConta"
      [canSelect]="canSelect"
    ></fibra-header-dropdown>
  </ng-container>
  <div class="d-flex justify-content-center position" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>