import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'fibra-opportunity-products-list',
  templateUrl: './opportunity-products-list.component.html',
  styleUrls: ['./opportunity-products-list.component.scss'],
})
export class OpportunityProductsListComponent implements OnInit {

  public pageProduct: boolean = false;
  public params: any = {};
  public product = [
    {
      title: 'Capital de Giro',
      img: '/assets/opportunity/svg/opportunity-capital-giro.svg',
      description: 'Crédito para atender as necessidades de giro e equilibrar o fluxo de caixa da sua empresa',
      link: '/capital-giro'
    },
    {
      title: 'Câmbio',
      img: '/assets/opportunity/svg/opportunity-cambio.svg',
      description: 'Solução para seus pagamentos e recebimentos do exterior com taxas competitivas',
      link: '/cambio'
    },
    {
      title: 'Seguros',
      img: '/assets/opportunity/svg/icon-seguros.svg',
      description: 'Soluções em seguros com atendimento ágil, humanizado e transparente para proteger o seu negócio',
      link: '/seguros'
    },
    {
      title: 'Fiança Bancária',
      img: '/assets/opportunity/svg/icon-bank.svg',
      description: 'Garantia para operações tais como licitações, fornecimento de produtos, B3, dentre outras',
      link: '/fianca-bancaria'
    }
  ]

  constructor(private router: Router, public analytics: AngularFireAnalytics) {}

  ngOnInit() {
    this.isPageProduct();
    this.notViewSameProductInPage();
  }

  isPageProduct() {
    this.router.url === '/opportunity' ? this.pageProduct = true : this.pageProduct = false;
  }

  notViewSameProductInPage() {
    switch (this.router.url) {
      case '/capital-giro':
        this.product.splice(0, 1);
        this.getInformationAccount('click_oportunidade_capitalgiro');
        break;
      case '/cambio':
        this.getInformationAccount('click_oportunidade_cambio');
        this.product.splice(1, 1);
        break;
      case '/seguros':
        this.getInformationAccount('click_oportunidade_seguros');
        this.product.splice(2, 1);
        break;
      case '/fianca-bancaria':
        this.getInformationAccount('click_oportunidade_fianca_bancaria');
        this.product.splice(3, 1);
        break;
      default:
        break;
    }
  }

  async getInformationAccount(param: string) {
    this.params = {
      id: await this.getCompanyLocalStorage().value,
      name: await this.getCompanyLocalStorage().name,
      type: await this.getCompanyLocalStorage().tipo_pessoa,
      num_cpf_cnpj: await this.getCompanyLocalStorage().cpfCnpj,
    }
    this.analytics.logEvent(param, this.params);
  }

  getCompanyLocalStorage() {
    return JSON.parse(localStorage.getItem('batch-company-name'));
  }
}
