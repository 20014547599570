import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { OpenBankingTransactionStatusEnum } from "@src/core/domain/openbanking/openbanking.transaction.status.enum";
import { Observable } from "rxjs";
import { OpenbankingPaymentRequestComponent } from "./openbanking-paymentrequest.component";

@Injectable()
export class OpenbankingPaymentRequestCanDeactivate implements CanDeactivate<OpenbankingPaymentRequestComponent> {
    canDeactivate(component: OpenbankingPaymentRequestComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean>  {
        if (!component) {
            return true;
        }

        if (component.status === OpenBankingTransactionStatusEnum.InProgress) {
            alert('Não é possível navegar pelo sistema enquando a transação não for concluída.');
            return false;
        }

        return true;
    }

}