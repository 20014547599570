import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { PixLimitesRepository } from '@src/core/repositories/pix/pix-limites.repository';
import { PixDataModel, ReturnTypeModel } from '@src/core/domain/pix/return-type.model';

@Injectable({
  providedIn: 'root',
})
export class PixLimiteDataRepository extends PixLimitesRepository {
  
  constructor(private http: HttpClient) {
    super();
  }

  getLimitesPix(obj: void): Observable<ReturnTypeModel> {
    const httpOptions = {
      headers: getHeader(),
    };

   return this.http.get<ReturnTypeModel>(`${environment.URLBase}/v1/limitetransacional/pix`, httpOptions);
  }


  getAccountLimitesPix(params): Observable<PixDataModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    /* const postParams = {
        NumeroConta: String(param),
        NumeroEmpresa: String(0),
        IdEmpresa: 0,
        Pix: true,
    } */

    

    //return this.http.post<LimitModel>(`${environment.URLBase}v1/limiteTransacional/disclaimer`, postParams, httpOptions);
    //return this.http.get<LimitModel>(`${environment.URLBase}v1/LimiteTransacional/Consultar/PIX`, httpOptions);
    return this.http.get<PixDataModel>(`${environment.URLBase}v1/LimiteTransacional/ativos/${params}`, httpOptions);
  }

  
}
