import { ProductListEntity } from './product.entity';
import { ProductListModel } from '@src/core/domain/product/product.model';
import { Mapper } from '@src/core/base/mapper';

export class ProductRepositoryMapper extends Mapper <ProductListEntity, ProductListModel> {
  mapFrom(param: ProductListEntity): ProductListModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: ProductListModel): ProductListEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
