import { CompanyDefaultModel } from '@src/core/domain/company/companyType.model';
import { Mapper } from '@src/core/base/mapper';
import { CompanyDefaultType } from '@src/data/repository/company/companyType.entity';

export class CompanyDefaultRepositoryMapper extends Mapper<
  CompanyDefaultType,
  CompanyDefaultModel
> {
  mapFrom(param: CompanyDefaultType): CompanyDefaultModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }

  mapTo(param: CompanyDefaultModel): CompanyDefaultType {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
