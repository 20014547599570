import { ComprovanteEntity } from './product.entity';
import { ComprovanteModel } from '@src/core/domain/product/product.model';
import { Mapper } from '@src/core/base/mapper';

export class ComprovanteRepositoryMapper extends Mapper <ComprovanteEntity, ComprovanteModel> {
  mapFrom(param: ComprovanteEntity): ComprovanteModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: ComprovanteModel): ComprovanteEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
