import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { CETFibraFacil } from '@src/core/domain/loans/loan-model';
import { LoansRepository } from '@src/core/repositories/loans/loans-repository';

@Injectable({
  providedIn: 'root'
})
export class GetCETFibraFacilUsecase implements UseCase<string, CETFibraFacil> {

  constructor(private loanRepository: LoansRepository) { }

  execute(params: string): Observable<CETFibraFacil> {
    return this.loanRepository.getCETFibraFacil(params);
  }
}
