import { Component, OnInit, OnDestroy } from '@angular/core';
import { GetTotalOperationsUsecase } from '@src/core/usecases/loans/get-total-operations';
import { LoansRequest } from '@src/core/domain/loans/loan-model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fibra-total-operation',
  templateUrl: './total-operation.component.html',
  styleUrls: ['./total-operation.component.scss'],
})
export class TotalOperationComponent implements OnInit,OnDestroy {

  public totalOperations = [];
  public totalSubscription : Subscription;
  
  constructor(
    private totalOperationUseCase: GetTotalOperationsUsecase    
  ) {
   
  }
  ngOnInit(): void {

    const params : LoansRequest = {}
    this.totalSubscription= this.totalOperationUseCase.execute(params).subscribe((data) => {
      this.totalOperations = data.data
    },(err) => {
    })
   
  }

  ngOnDestroy()
  {
    this.totalSubscription.unsubscribe()
  }

  
}
