import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { ArchivesListRequest } from '@src/core/domain/charge/archive.model';
import * as moment from 'moment';
import { ParamsPayment } from './payment-batch-entity';
import { catchError, retry } from 'rxjs/operators';
import { error } from 'console';
import { codeBarMask } from '@src/shared/util-common';

@Injectable({
  providedIn: 'root',
})
export class PaymentBatchRepository {

  constructor(private http: HttpClient) { }

  public getURL(): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<any>(`${environment.URLBase}v1/Transacoes/url/pagamentolote/cnab240`, httpOptions);
  }

  Upload(nome_arquivo, url: string, arquivoCNAB: any) {
    const params = {
      "arquivo": arquivoCNAB,
      "nome_arquivo": nome_arquivo,
    }

    const fileData = new FormData()

    console.log("arquivo")
    console.debug(arquivoCNAB)

    fileData.append("arquivo_cnab", arquivoCNAB)

    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.put(`${url}`, arquivoCNAB);
  }

  validacaoURL(params: any): Observable<any> {
    const nm_arquivo = params
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<any>(`${environment.URLBase}v1/Transacoes/arquivo/${nm_arquivo}/validacao/pagamentolote/cnab240`, httpOptions);
  }

  public detalheHistorico(Id): Observable<any> {
    const arquivoId = Id
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<any>(`${environment.URLBase}v1/Transacoes/${arquivoId}/detalheHistoricoRetorno/cnab240`, httpOptions);
  }

  public detalheHistoricoLiquidado(Id): Observable<any> {
    const arquivoId = Id
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<any>(`${environment.URLBase}v1/Transacoes/${arquivoId}/detalheHistoricoLiquidado/cnab240`, httpOptions);
  }

  public pagamentoLoteDownload(list): Observable<any> {
    const arquivo = list
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<any>(`${environment.URLBase}v1/Transacoes/DownloadCnab240/${arquivo}`, httpOptions);
  }

  public listarHistoricoRemessa(body): Observable<any> {
    const params = {
      "dt_inicial": body.dt_inicial,
      "dt_final": body.dt_final
    };
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.post<any>(`${environment.URLBase}v1/Transacoes/listarHistoricoRemessa`, params, httpOptions);
  }

  public listarHistoricoRetorno(body): Observable<any> {
    const params = {
      "dt_inicial": body.dt_inicial,
      "dt_final": body.dt_final
    };

    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.post<any>(`${environment.URLBase}v1/Transacoes/listarHistoricoRetorno`, params, httpOptions);
  }

  public listarHistoricoLiquidacao(body): Observable<any> {
    const params = {
      "dt_inicial": body.dt_inicial,
      "dt_final": body.dt_final
    };

    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.post<any>(`${environment.URLBase}v1/Transacoes/listarHistoricoLiquidado`, params, httpOptions);
  }


  public detalharArquivosCNAB(nomeArquivo) {
    const httpOptions = {
      headers: getHeader(),
    };
    
    return this.http.get<any>(`${environment.URLBase}v1/Transacoes/arquivo/${nomeArquivo}/revisao/pagamentolote/cnab240`, httpOptions);
  }

  public verificarAprovacaoAutomatica() {
    const httpOptions = {
      headers: getHeader(),
    };
    
    return this.http.get<any>(`${environment.URLBase}v1/Transacoes/aprovacaoautomatica/pagamentolote/cnab240`, httpOptions);
  }

  public validarHorariosDePagamento() {
    const httpOptions = {
      headers: getHeader(),
    };
    
    return this.http.get<any>(`${environment.URLBase}/v1/Transacoes/pagamentoLote/horarioLimites`, httpOptions);
  }
}
