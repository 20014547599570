import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { TermTypeEnum } from "@src/core/domain/dda/term-types-enum";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";
import { finalize } from "rxjs/operators";
import { RolesService } from "@src/shared/services/roles.service";
import {
  StatusApprovalChangeEnum,
  StatusApprovalEnum,
} from "@src/core/domain/dda/dda-type-approval.enum";
import { DdaApprovalsModel } from "@src/core/domain/dda/dda-approvals.model.";
@Component({
  selector: "fibra-dda-card-dashboard",
  templateUrl: "./dda-card-dashboard.component.html",
  styleUrls: ["./dda-card-dashboard.component.scss"],
})
export class DdaCardDashboardComponent implements OnInit {
  statusTerm: DdaTermCompanyApprovalModel = null;
  statusTermCancel: DdaTermCompanyApprovalModel = null;
  toApprovalTerm: boolean = false;
  toCancelTerm: boolean = false;
  toApprovalExclude: boolean = false;
  toApprovalInclude: boolean = false;
  empresa: any;
  constructor(
    private termRepository: TermImplementationRepository,
    public ddaTermShareData: DdaTermDataShare,
    private router: Router,
    private rolesService: RolesService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.checkCreatedTerm();
      this.checkCreatedCancelTerm();
    }, 1000);
  }

  getHasRole(): boolean {
    return (
      this.rolesService.getRoles().find((x) => x.Name === "MASTER") !==
      undefined
    );
  }

  checkCreatedTerm(): void {
    if (this.getHasRole())
      this.termRepository
        .get(TermTypeEnum.Adesao)
        .pipe(
          finalize(() => {
            if (this.statusTerm !== null) {
              this.getBranches(this.statusTerm.id);
            }
          })
        )
        .subscribe((result: any) => {
          if(result !== null && result!=undefined){
            this.statusTerm = result;        
            this.toApprovalTerm =
            this.statusTerm.aprovacoes.find(
              (x) =>
                x.pessoa[0].email === localStorage.getItem("useremail") &&
                x.status === StatusApprovalEnum.Pendant
            ) !== undefined;
          }
          else{
            this.toApprovalTerm = false;
          }
        });
  }

  checkCreatedCancelTerm(): void {
    if (this.getHasRole())
      this.termRepository
        .get(TermTypeEnum.Cancelamento)
        .pipe(finalize(() => {}))
        .subscribe((result: any) => {

          if(result !== null && result!=undefined){

          this.statusTermCancel = result;
          this.toCancelTerm =
            this.statusTermCancel.aprovacoes.find(
              (x) =>
                x.pessoa[0].email === localStorage.getItem("useremail") &&
                x.status === StatusApprovalEnum.Pendant
            ) !== undefined;
            }
            else {
              this.toCancelTerm = false;
            }

        });
  }

  getBranches(termoAceiteid: number): void {
    this.termRepository
      .getApprovals(termoAceiteid)
      .subscribe((data: Array<DdaApprovalsModel>) => {
        let branches: Array<DdaApprovalsModel> = data.filter(
          (x) => x.status_id === StatusApprovalChangeEnum.PendantExclude
        );

        branches.forEach((item) => {
          this.toApprovalExclude = item.aprovadores!=null ?
            item.aprovadores.find(
              (x) =>
                x.email === localStorage.getItem("useremail") &&
                x.status_id === StatusApprovalChangeEnum.PendantExclude
            ) !== undefined: false;
        });

        branches = data.filter(
          (x) => x.status_id === StatusApprovalChangeEnum.PendantInclude
        );
        branches.forEach((item) => {
          this.toApprovalInclude = item.aprovadores!=null ?
            item.aprovadores.find(
              (x) =>
                x.email === localStorage.getItem("useremail") &&
                x.status_id === StatusApprovalChangeEnum.PendantInclude
            ) !== undefined: false;
        });
      });
  }

  redirectTo(): void {
    this.router.navigate(["/dda"]);
  }
}
