import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DDAPendencyDataShare {
    private showSelectedTabSubject = new BehaviorSubject('');

    showSelectedTab = this.showSelectedTabSubject.asObservable();

    setSelectedTab(value: string) {
        this.showSelectedTabSubject.next(value);
    }

    getSelectedTab() {
        return this.showSelectedTab;
    }

    clearSelectedTab() {
        this.showSelectedTabSubject = new BehaviorSubject('');
        this.showSelectedTab = this.showSelectedTabSubject.asObservable();
    }
}