import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fibra-openbanking-home',
    templateUrl: './openbanking-home.component.html',
    styleUrls: ['./openbanking-home.component.scss']
})
export class OpenbankingHomeComponent {

    @Input() onBoardingDone: boolean;
    @Input() termsOfUseAccepted: boolean;
    
    @Output() onReverOnboarding: EventEmitter<any> = new EventEmitter();
    @Output() onReverTermoDeUso: EventEmitter<any> = new EventEmitter();
        
    reverOnboarding() {
        this.onReverOnboarding.emit();
        this.onReverOnboarding.complete();
    }

    termoDeUso() {
        this.onReverTermoDeUso.emit();
        this.onReverTermoDeUso.complete();
    }
}