<table class="table fib-info">
  <thead class="table-header">
    <tr>
      <th scope="col" style="width: 100px; min-width: 100px">
        <input type="checkbox" class="checkbox" *ngIf="showCheckbox && showCheckboxInstruction && transit.length > 0" (change)="checked('all', '')" [disabled]="_readonlyInput"/>
        <span  (click)="sort('emissao')">
          Data da emissão<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/>
          <!-- <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
                fill="#082A4D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
                fill="#082A4D"
              />
            </g>
          </svg> -->
        </span>
      </th>
      <th style="width: 105px; min-width: 105px" scope="col" (click)="sort('movimento')">
        Data de movimento<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/>
        <!-- <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.6">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
              fill="#082A4D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
              fill="#082A4D"
            />
          </g>
        </svg> -->
      </th>
      <th style="width: 110px; min-width: 110px" scope="col" (click)="sort('vencimento')">
        Data de vencimento<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/>
        <!-- <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.6">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
              fill="#082A4D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
              fill="#082A4D"
            />
          </g>
        </svg> -->
      </th>
      <th style="width: 115px; min-width: 115px" scope="col" (click)="sort('seu_numero')">Seu número<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 135px; min-width: 135px" scope="col" (click)="sort('nosso_numero')">Nosso Número<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 110px; min-width: 110px" scope="col" (click)="sort('nome_sacado')">
        Pagador<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/>
        <!-- <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.6">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
              fill="#082A4D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
              fill="#082A4D"
            />
          </g>
        </svg> -->
      </th>
      <th style="width: 110px; min-width: 110px" scope="col" (click)="sort('valor_titulo')">
        Valor título<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/>
        <!-- <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.6">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
              fill="#082A4D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
              fill="#082A4D"
            />
          </g>
        </svg> -->
      </th> 
      <th style="width: 110px; min-width: 110px" scope="col" (click)="sort('status')">Status<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 50px; min-width: 50px" class="status-titulo" scope="col">Ver título</th>
    </tr>
  </thead>

  <div #tablebody class="bodyTable" *ngIf="!hide && !loading">
    <tbody *ngFor="let item of transit; let i = index">
      <tr>
        <td style="width: 100px; min-width: 100px" scope="row" >
          <input type="checkbox" class="checkbox" [checked]="shown[i]" [disabled]="_readonlyInput" *ngIf="showCheckbox && showCheckboxInstruction && !['BAIXADO','PAGO'].includes(item.status)" [id]="i" (change)="checked(i, item)"/>
          {{ item.dt_emissao | date: 'dd/MM/yyyy' }}
        </td>
        <td style="width: 105px; min-width: 105px" scope="row">{{ item.dt_movimento | date: 'dd/MM/yyyy' }}</td>
        <td style="width: 110px; min-width: 110px" scope="row">{{ item.dt_vencimento | date: 'dd/MM/yyyy' }}</td>
        <td style="width: 115px; min-width: 115px" scope="row">{{ item.seu_numero }}</td>
        <td style="width: 135px; min-width: 135px" scope="row">{{ item.nosso_numero === '' ? '-' : item.nosso_numero  }}</td>
        <td style="width: 110px; min-width: 110px" data-hj-suppress>
          <ngb-highlight
            [result]="item.nome_pagador"
            [term]="filter.value"
          ></ngb-highlight>
        </td>
        <td style="width: 110px; min-width: 110px" >
          <ngb-highlight
            [result]="item.vlr_titulo | currency: 'BRL'"
            [term]="filter.value"
          ></ngb-highlight>
        </td>
        <td style="width: 110px; min-width: 110px" class="util">
          <div [hidden] = "!isVencido"
          [ngClass]="{
            drop: ['BAIXADO'].includes(item.status),
            authorized: ['PAGO'].includes(item.status),
            refused: ['PROTESTADO','EM CARTÓRIO', 'EM ABERTO'].includes(item.status)
          }"
        ></div>
        <div [hidden] = "isVencido"
          [ngClass]="{
             pending: ['EM ABERTO'].includes(item.status),
              drop: ['BAIXADO'].includes(item.status),
              authorized: ['PAGO'].includes(item.status),
              refused: ['PROTESTADO','EM CARTÓRIO', 'VENCIDO'].includes(item.status)
          }"
        ></div>
          <ngb-highlight
            [result]="getStatusFormatedCarteira(item.status, item.dt_vencimento)"
            [term]="filter.value"
          ></ngb-highlight>
        </td>
        <td style="width: 50px; min-width: 50px">
          <svg
            (click)="sendItemList(item)"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M12.9999 12.3967H14.1987M5.79932 3.99609H10.5993H5.79932ZM5.79932 9.99609H10.5993H5.79932ZM5.79932 6.39549H9.39932H5.79932ZM5.79932 12.3967H9.39932H5.79932ZM12.9999 3.99609H14.1987H12.9999ZM12.9999 9.99609H14.1987H12.9999ZM12.9999 6.39549H14.1987H12.9999Z"
                stroke="#082A4D"
                stroke-width="0.8"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1H19M2.8081 1V17.7928L4.6018 16.597L6.421 17.7964L8.2141 16.597L10.0141 17.7928L11.8075 16.597L13.6069 17.7928L15.4012 16.597L17.2 17.7928V1H2.8081ZM13.6069 17.7964V17.7928V17.7964ZM10.0141 17.7964V17.7928V17.7964Z"
                stroke="#082A4D"
                stroke-width="0.8"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </td>
      </tr>
      <tr class="details" *ngIf="shown[i] && (dataForDetails.inputServiceMoney || dataForDetails.inputServiceNumber || dataForDetails.calendarService || _isBatchChoose || _isBatchAbatimentoChoose || _isBatchAltVencimentoChoose)" [id]="'details' + i">
        <td colspan="6">
          <fibra-query-transit-details-list
            [data]="dataForDetails"
            (valueChoice)="receiveValueChoice($event)"
            [index]="i"
            [item]="item"
            [limparInput]="cleanInput == true"
            (isDateValid)="verifyDatePosicaoCarteira($event)"
            (isExpirationDateInvalid)="verifyExpirationDate($event)"
            [valueChoiceQuery]="valueChoiceVenc"
            [valueChoiceVenc]="valueChoiceVenc"
            [isBatchChoose]="_isBatchChoose == true"
            [isBatchAbatimentoChoose]="_isBatchAbatimentoChoose == true"
            [isBatchAltVencimentoChoose]="_isBatchAltVencimentoChoose == true"
            [readonlyInput]="_readonlyInput == true"
          ></fibra-query-transit-details-list>
        </td>
      </tr>
    </tbody>
    <div class="d-flex justify-content-center" *ngIf="loadingMore">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Carregando...</span>
      </div>
    </div>
  </div>  
  </table>

  <div class="d-flex justify-content-center position" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="empty-info" *ngIf="hide">
    <fibra-generic-info
      type="warn"
      title="Nenhum resultado encontrado"
      message="Tente uma nova pesquisa inserindo novos parâmetros."
    >
    </fibra-generic-info>
  </div>

  <fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
