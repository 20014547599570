import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CompanyDataShare {
    public data: any;
    public index: number;
    public bool: boolean;
    public dataToShare = new BehaviorSubject(this.data);
    public indexToShare = new BehaviorSubject(this.index);
    public companySelectShare = new BehaviorSubject(this.bool);
    dataShared = this.dataToShare.asObservable();
    indexShare = this.indexToShare.asObservable();
    selectShared = this.companySelectShare.asObservable();

    constructor() {}
    setSelect(bool: boolean) {
        this.companySelectShare.next(bool);
    }

    setIndex(index: number) {
        this.indexToShare.next(index);
    }

    setValue(data: any) {
        if (data.companyList) {
            data.companyList.sort(compare);
        }
        function compare(a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }

        if (data && data.companyList && Array.isArray(data.companyList)) {
            data.companyList.map((e) => {
                const words = e.name.toLowerCase().split(' ');
                for (let i = 0; i < words.length; i++) {
                    const word = words[i];
                    if(word){
                        words[i] = word[0].toUpperCase() + word.slice(1);
                    }
                }
                e.name = words.join(' ');
            });
        }
        this.dataToShare.next(data);
    }

    clear() {
      this.dataToShare = new BehaviorSubject([]);
      this.dataShared = this.dataToShare.asObservable();
    }
}
