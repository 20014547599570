import {
  TransactionDetailRequest,
  TransactionDetailModel,
} from '../../domain/transaction/transaction.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { TransactionDetailRepository } from '@src/core/repositories/transaction/transaction.repository';

@Injectable({
  providedIn: 'root',
})
export class GetTransactionDetailUsecase
  implements UseCase<any, TransactionDetailModel> {
  constructor(private transactionRepository: TransactionDetailRepository) {}

  execute(
    params: TransactionDetailRequest
  ): Observable<TransactionDetailModel> {
    return this.transactionRepository.getDetail(params);
  }
}
