import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { ReturnTypeModelLimits } from '@src/core/domain/approval-limits/return-approval-limits.model';
import { ApprovalLimitsRepository } from '@src/core/repositories/approval-limits/approval-limits.repository';

@Injectable({
  providedIn: 'root'
})
export class ApprovalLimitesUseCases implements UseCase<string, ReturnTypeModelLimits> {
  constructor(private approvalLimitsRepository: ApprovalLimitsRepository) { }

  execute(params: string): Observable<ReturnTypeModelLimits> {
    return this.approvalLimitsRepository.getApprovalAllLimites(params)
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApprovalStatusLimitesUseCases implements UseCase<{}, ReturnTypeModelLimits> {
  constructor(private approvalLimitsRepository: ApprovalLimitsRepository) { }
  execute(params: {}): Observable<ReturnTypeModelLimits> {
    return this.approvalLimitsRepository.getApprovalStatusLimites(params)
  }
}