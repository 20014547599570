<div class="list-value">
  <!-- Loading -->
  <div
    class="d-flex justify-content-center position loading-container"
    *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
    </div>
  </div>
  <div class="empty-info" *ngIf="hide && !loading">
    <fibra-generic-info
      type="error"
      title="Ocorreu um erro"
      message="Não foi possível encontrar suas contas. Tente novamente mais tarde."
    >
    </fibra-generic-info>
  </div>
  <table class="table" *ngIf="!hide && !loading">
    <thead>
      <tr class="header-list">
        <th scope="col">
          <div class="text" (click)="sortList('Conta')">
            Conta
            <span class="icon">
              <img src="/assets/svg/sort-list.svg" alt="sort-list" />
            </span>
          </div>
        </th>
        <th scope="col">
          <div class="text" (click)="sortList('Dados da conta')">
            Dados da conta
            <span class="icon">
              <img src="/assets/svg/sort-list.svg" alt="sort-list" />
            </span>
          </div>
        </th>
        <th scope="col">
          <div class="text">Saldo da conta</div>
        </th>
      </tr>
    </thead>
    <tbody style="display: flex; flex-direction: column">
      <tr *ngFor="let item of profileData; index as i">
        <div class="panel-group" id="accordion">
          <div
            class="panel-list"
            data-toggle="collapse"
            data-parent="#accordion"
            [attr.href]="'#collapse' + i"
            (click)="toogleIconArrow(i)"
          >
            <td scope="row">
              <label class="label-list">
                {{ item.exibicao | titlecase }}</label
              >
            </td>
            <td class="item-data">
              <label class="label-list">
                AG: {{ item.cod_agencia }} | CC: {{ item.num_conta | account }}</label
              >
            </td>
            <td>
              <label class="label-list"
                >R$
                {{
                  !showData
                    ? "*****"
                    : (item.saldo.vlr_saldo + item.saldo.vlr_bloqueado || 0
                      | number: "1.2-2")
                }}
              </label>
              <span class="item-icon">
                <img
                  id="{{ i }}"
                  src="/assets/svg/dropdown.svg"
                  width="16"
                  class=""
                  alt="Seta para dropdown"
                />
              </span>
            </td>
          </div>
          <div [id]="'collapse' + i" class="panel-collapse collapse in">
            <div class="panel-body">
              <fibra-balance-datail
                [balanceData]="item.saldo"
                [indice]="item.num_indice"
                [showData]="showData"
                [exibicao]="item.exibicao"
              ></fibra-balance-datail>
            </div>
          </div>
        </div>
      </tr>
    </tbody>
  </table>
</div>
