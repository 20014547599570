<div class="protest-content">
  <div class="content-text">
    <span>Dias para protestar</span>
  </div>
  <div class="inputForm">
    <label for="dateProtest"></label>
    <input
      type="number"
      class="form-control"
      placeholder="Ex.: 30 dias"
      [(ngModel)]="inputValue"
      (input)="inputValue = $event.target.value; resolveProtest(inputValue)"
      id="inputDay"
      maxlength="10"
    />
  </div>
<!--   <div class="inputForm noHover" >
    <label for="dateProtest">Data de início do protesto</label>
    <input
      type="text"
      class="form-control"
      name="dateProtest"
      id="dateProtest"
      [(ngModel)]="dateProtest"
      disabled
    />
  </div> -->
</div>
