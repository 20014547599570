import { Injectable } from '@angular/core';
import { LoginRepository } from '@src/core/repositories/login/login.repository';
import { Observable } from 'rxjs';
import {LoginRepositoryMapper} from '@src/data/repository/login-repository/login-repository.mapper';
import { HttpClient } from '@angular/common/http';
import {LoginEntity, VerifyMasterEntity} from '@src/data/repository/login-repository/login.entity';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';
import { environment } from '@src/environments/environment';

import {
  LoginMobileTypeModel,
  LoginMobileRequest,
} from '@src/core/domain/login/loginMobileType.model';

// header
import { getInitialHeader } from '@src/core/base/header';


@Injectable({
  providedIn: 'root',
})
export class LoginDataRepository extends LoginRepository {
  mapper = new LoginRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  sendLogin(data: LoginMobileRequest): Observable<LoginMobileTypeModel> {
    const httpOptions = {
      headers: getInitialHeader(),
      method: 'POST',
    };
    const body = {
      user: data.username,
      password: data.password
    };
    return this.http
      .post<LoginEntity>(
        `${environment.URLBase}v1/login`,
        body,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
