import {
  AddAccountRequest,
  AddBeneficiariesRequest,
  BeneficiariesModel,
  BeneficiariesRequest,
  BusinessDayRequest,
  RemoveAndAddRequest,
  RequestAccountFibra,
} from '@src/core/domain/transfer/transfer.model';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BeneficiariesEntity, BankTypeEnum } from './beneficiaries.entity';
import { BeneficiariesRepositoryMapper } from './beneficiaries-repository-mapper';
import { FavoritesRepository } from '@src/core/repositories/transfer/favorites.repository';

// environment

@Injectable({
  providedIn: 'root',
})
export class BeneficiariesDataRepository extends FavoritesRepository {
  mapper = new BeneficiariesRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getFavorites(obj: BeneficiariesRequest): Observable<BeneficiariesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };
    return this.http
      .post<BeneficiariesEntity>(
        `${environment.URLBase}v1/beneficiarios/favoritos/todos`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getBusinessDay(obj: BusinessDayRequest): Observable<BeneficiariesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;
    return this.http
      .get<BeneficiariesEntity>(
        `${environment.URLBase}v1/transferencias/diautil/${params.diautil}`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  addBankFavorites(obj: BankTypeEnum): Observable<BeneficiariesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };

    return this.http
      .post<BeneficiariesEntity>(
        `${environment.URLBase}v1/beneficiarios/Conta`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getAccountFibra(obj: RequestAccountFibra): Observable<BeneficiariesModel> {

    const __handleError = (error: HttpErrorResponse) => {
      if (error.status == 429) {
        return throwError(error);
      } else {
        return handleError(error);
      }
    }

    return this.http
      .post<BeneficiariesEntity>(`${environment.URLBase}v1/beneficiarios/conta`, { ...obj }, { headers: getHeader() })
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(__handleError));
  }

  addAccountBeneficiary(
    obj: AddAccountRequest
  ): Observable<BeneficiariesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };
    console.log(obj);
    return this.http
      .post<BeneficiariesEntity>(
        `${environment.URLBase}v1/beneficiarios/favoritos/conta`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  addFavorites(obj: AddBeneficiariesRequest): Observable<BeneficiariesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };

    return this.http
      .post<BeneficiariesEntity>(
        `${environment.URLBase}v1/beneficiarios/favoritos`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  deleteAccountFavorites(
    obj: RemoveAndAddRequest
  ): Observable<BeneficiariesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };

    return this.http
      .post<BeneficiariesEntity>(
        `${environment.URLBase}v1/beneficiarios/excluir/conta`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  deleteFavorites(obj: RemoveAndAddRequest): Observable<BeneficiariesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };

    return this.http
      .post<BeneficiariesEntity>(
        `${environment.URLBase}v1/beneficiarios/excluir`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
