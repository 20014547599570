<table class="table">
  <thead class="table-header">
    <tr>
      <th scope="col">
        <input
          type="checkbox"
          class="checkbox"
          *ngIf="showCheckbox"
          (change)="checked('all')"
        />
        <span (click)="ordered('date')">
          Data da emissão
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
                fill="#082A4D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
                fill="#082A4D"
              />
            </g>
          </svg>
        </span>
      </th>
      <th scope="col" (click)="ordered('date')">
        Data de vencimento
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.6">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
              fill="#082A4D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
              fill="#082A4D"
            />
          </g>
        </svg>
      </th>
      <th scope="col" (click)="ordered('payer')">
        Pagador
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.6">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
              fill="#082A4D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
              fill="#082A4D"
            />
          </g>
        </svg>
      </th>
      <th scope="col" (click)="ordered('value')">
        Valor do título
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.6">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 0L8.22788 5.02282H0L4.11394 0Z"
              fill="#082A4D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.11394 13.0596L8.22788 8.03675H0L4.11394 13.0596Z"
              fill="#082A4D"
            />
          </g>
        </svg>
      </th>
      <th scope="col">Status</th>
      <th scope="col">Ver título</th>
    </tr>
  </thead>

  <tbody *ngFor="let item of transit; let i = index">
    <tr>
      <td scope="row">
        <input
          type="checkbox"
          class="checkbox"
          *ngIf="showCheckbox"
          [id]="i"
          (change)="checked(i)"
        />
        {{ item.dt_emissao | date: 'dd/MM/yyyy' }}
      </td>
      <td scope="row">{{ item.dt_vencimento | date: 'dd/MM/yyyy' }}</td>
      <td>
        <ngb-highlight
          [result]="item.pagador"
          [term]="filter.value"
        ></ngb-highlight>
      </td>
      <td>
        <ngb-highlight
          [result]="item.vlr_titulo | currency: 'BRL'"
          [term]="filter.value"
        ></ngb-highlight>
      </td>
      <td class="util">
        <div
          [ngClass]="{
            pending: item.desc_status == 'Em análise',
            authorized: item.desc_status == 'AC',
            refused: item.desc_status == 'Recusado'
          }"
        ></div>
        <ngb-highlight
          [result]="item.desc_status"
          [term]="filter.value"
        ></ngb-highlight>
      </td>
      <td>
        <svg
          (click)="sendItemList(item)"
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <path
              d="M12.9999 12.3967H14.1987M5.79932 3.99609H10.5993H5.79932ZM5.79932 9.99609H10.5993H5.79932ZM5.79932 6.39549H9.39932H5.79932ZM5.79932 12.3967H9.39932H5.79932ZM12.9999 3.99609H14.1987H12.9999ZM12.9999 9.99609H14.1987H12.9999ZM12.9999 6.39549H14.1987H12.9999Z"
              stroke="#082A4D"
              stroke-width="0.8"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 1H19M2.8081 1V17.7928L4.6018 16.597L6.421 17.7964L8.2141 16.597L10.0141 17.7928L11.8075 16.597L13.6069 17.7928L15.4012 16.597L17.2 17.7928V1H2.8081ZM13.6069 17.7964V17.7928V17.7964ZM10.0141 17.7964V17.7928V17.7964Z"
              stroke="#082A4D"
              stroke-width="0.8"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </td>
    </tr>
    <tr class="details" *ngIf="shown[i]" [id]="'details' + i">
      <td colspan="6">
        <fibra-query-transit-details-list
          [data]="dataForDetails"
          (valueChoice)="receiveValueChoice($event)"
          [index]="i"
        ></fibra-query-transit-details-list>
      </td>
    </tr>
  </tbody>
</table>
<div class="empty-info" *ngIf="hide">
  <fibra-generic-info
    type="warn"
    title="Você não possui itens"
    message="Não foi possível encontrar dados de garantia!"
  >
  </fibra-generic-info>
</div>
