import { BankDataShare } from '@src/core/usecases/data-share/bank-data-share.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  PipeTransform,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { BankEntity } from '@src/data/repository/transfer/bank/bank.entity';
import { GetBankUsecase } from '@src/core/usecases/transfer/get-all-bank-usecases';
import {ActivatedRoute, Router} from '@angular/router';
import * as Util from '@src/shared/util-common';
import { RolesService } from '@src/shared/services/roles.service';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { PixBankDataShare } from '@src/core/usecases/data-share/pix-banco-data-share';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import { PixInsituticoesResponse, PixInstituicoesEntity } from '@src/data/repository/pix/beneficiaries/pix-beneficiaries.entity';

interface Banks {
  value: string;
  label: string;
  nome_banco: string;
  ispb: number;
  cod_banco: number;
  cod_ispb: number;
  css: string;
}

@Component({
  selector: 'fibra-pix-agenciaconta-bancos-list',
  templateUrl: './pix-agenciaconta-bancos-list.component.html',
  styleUrls: ['./pix-agenciaconta-bancos-list.component.scss'],
  providers: [DecimalPipe],
})
export class PixListBankComponent implements OnInit {
  @Input() data;
  @Input() params;
  @Output() emitBank: EventEmitter<any> = new EventEmitter();
  public filter = new FormControl('');
  public getOrderedDate;
  public getOrderedPayer;
  public getOrderedValue;
  public hide = false;
  public loading: boolean = true;
  public option;
  public newAccount;
  public banks: Observable<Banks[]>;
  public fibraFirst: any;
  public noFibra = true;
  public urlTree;
  public typeColorToast;
  public REDCOLOR = '#ED6C6C';
  public buscaBanco;

  listDataTemp = [];
  listDataFirstTemp = [];

  constructor(
    public pipe: DecimalPipe,
    private shareBank: BankDataShare,
    private roles: RolesService,
    public pixBankShare: PixBankDataShare,
    public getPixInstituicoes: PixGetBeneficiariesDataRepository
  ) {
    this.loading = true;

    this.banks = this.filter.valueChanges.pipe(
      startWith(''),
      map((text) => this.search(text, this.pipe))
    );
  }
  
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit() {
    this.typeColorToast = this.REDCOLOR;
    this.requestBank();
    this.configList();
    this.shareBank.dataShared.subscribe((res) => {
      if (res === 'new') {
        this.newAccount = true;
      }
    });
  }

  public configList() {
    this.filter.valueChanges.subscribe((res) => {
      this.banks.subscribe((res) => (this.hide = Util.isEmpty(res)));
      return;
    });
  }

  search(text: string, pipe: PipeTransform): Banks[] {
    return this.data.filter((banks) => {
      const term = text.toLowerCase();

      let result =
        banks.nome_banco.toLowerCase().includes(term) ||
        this.mountCodBank(pipe.transform(banks.cod_banco)).includes(term);
      return result;
    });
  }

  initialLetters(name: string) {
    name = name.replace(/\s(de|da|dos|das)\s/g, ' ');
    const initials = name.match(/\b(\w)/gi);

    if (initials.length > 1) {
      return (initials[0] + initials[1]).toUpperCase();
    } else {
      return name.substr(0, 2);
    }
  }

  getRandColor() {
    let iconColor = (Math.random() * (10 - 1) + 1).toFixed(0);

    return 'bg-' + iconColor;
  }

  selectBank(bank) {
    var permissoes = this.roles.get();
    if((permissoes.includes('PIX.TRANSF_PIX_OTR_TIT_BC') || permissoes.includes('PIX.TRANSF_PIX_MSM_TIT_BC')) && bank.cod_ispb != 58616418){
      this.pixBankShare.setValue(bank)
      localStorage.setItem('view-selecionada', 'transferir-transferOutroBanco');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }else if((permissoes.includes('PIX.TRANS_PIX_OTR_TIT') || permissoes.includes('PIX.TRANSF_PIX_MSM_TIT')) && bank.cod_ispb == 58616418){
      this.pixBankShare.setValue(bank)
      localStorage.setItem('view-selecionada', 'transferir-transferFibra');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }else{
      this.toast.callModalMessage(null, "Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.");
    }
  }

  private requestBank = (nome?: any) => {
    this.loading = true;
    //this.getAllBank.execute().subscribe(this.requestSuccess, this.requestError);
    this.getPixInstituicoes.getPixInsituticoes(nome).subscribe((res: any) => {
      this.requestSuccess(res);
    },(err) => {
      this.requestError(err);
    })
  };

  private requestSuccess = (value: PixInsituticoesResponse) => {
    let bankList: any = [];
    this.listDataFirstTemp = []
    this.listDataTemp = []

    if (value.code === 200) {
      bankList = value.data;
      bankList.forEach((item) => {
        this.listDataTemp.push({
          cod_banco: item.cod_banco,
          nome_banco: item.nome_banco,
          cod_ispb : item.cod_ispb,
          css: this.getRandColor(),
        })
        // if (
        //   item.cod_ispb === 58616418 ||
        //   item.cod_ispb === 60746948 ||
        //   item.cod_ispb === 0 ||
        //   item.cod_ispb === 90400888  ||
        //   item.cod_ispb === 360305 ||
        //   item.cod_ispb === 60701190 
        // ) {
        //   let i = 0;
        //   switch (item.cod_ispb) {
        //     //fibra
        //     case 58616418:
        //       i = 0;
        //       break;
        //     //bradesco
        //     case 60746948:
        //       i = 1;
        //       break;
        //     //banco do brasil
        //     case 0:
        //       i = 2;
        //       break;
        //     //santander
        //     case 90400888 :
        //       i = 3;
        //       break;
        //     //Caixa Econômica Federal
        //     case 360305:
        //       i = 4;
        //       break;
        //     //Itaú Unibanco S.A
        //     case 60701190 :
        //       i = 5;
        //       break;

        //     default:
        //       break;
        //   }

        //   this.listDataFirstTemp[i] = {
        //     cod_banco: item.cod_ispb,
        //     nome_banco: item.nome_banco,
        //     css: this.getRandColor(),
        //   };
        // } else {
        //   this.listDataTemp.push({
        //     cod_banco: item.cod_banco,
        //     nome_banco: item.nome_banco,
        //     cod_ispb : item.cod_ispb,
        //     css: this.getRandColor(),
        //   })
        // }
      });
    }
    this.data = this.listDataFirstTemp.concat(this.listDataTemp);
    console.log(this.data)
    this.loading = false;
  };

  mountCodBank(cod: number) {
    return ('000' + cod.toString()).slice(-3);
  }

  public formatBankCode = (code) => {
    const newCode = String(code);
    if (newCode.length === 1) {
      return '00' + newCode;
    }
    if (newCode.length === 2) {
      return '0' + newCode;
    }
    return code;
  };

  private requestError = (err) => {
    this.loading = false;
    this.hide = Util.isEmpty(this.data);
    return console.log(err);
  };

  public goBack() {
    localStorage.setItem('view-selecionada', 'transferir-newtransfer');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public getBancos(nome?: any){
    this.filter = this.buscaBanco;
    this.requestBank(this.buscaBanco)
  }
}
