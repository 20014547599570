import { BankModel } from './../../../../core/domain/transfer/transfer.model';
import { Mapper } from '@src/core/base/mapper';
import { BankEntity } from './bank.entity';

export class BankRepositoryMapper extends Mapper<BankModel, BankEntity> {
  mapFrom(param: BankEntity): BankModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: BankModel): BankEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
