import { Mapper } from '@src/core/base/mapper';
import {  posicaoCarteiraListQueryModel } from '@src/core/domain/query/query.model';
import { posicaoCarteiraListEntity } from './posicao-carteira.entity';

export class posicaoCarteiraListRepositoryMapper extends Mapper<
posicaoCarteiraListEntity,
  posicaoCarteiraListQueryModel
> {
  mapFrom(param: posicaoCarteiraListEntity): posicaoCarteiraListQueryModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: posicaoCarteiraListQueryModel): posicaoCarteiraListEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
