import { Subscription } from 'rxjs';
import { AcceptTermUsecase } from './../../../../core/usecases/accept-term/accept-term.usecase';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { GetCompanyTypesUsecases } from '@src/core/usecases/company/get-companyTypes.usecase';
import { setCompanyIndexUsecases } from '@src/core/usecases/company/set-companyIndex.usecase';
import { DropdownList } from '@src/core/domain/business-models';
import { CompanyRequest } from '@src/core/domain/company/companyType.model';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { formatString, versionTerm } from '@src/shared/util-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ProfileAccessProvider } from '@src/data/repository/perfilamento/profile.repository';
import { UserService } from '@src/shared/services/user.service';
import { RolesService } from '@src/shared/services/roles.service';
import { CompanyTypeDataRepository } from '@src/data/repository/company/companyType.repository';
import { PixGetTermsDataRepository } from '@src/data/repository/pix/terms/pix-get-terms-repository';
import { Router } from '@angular/router';

@Component({
    selector: 'fibra-company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit {
    public optionsCompany: DropdownList;
    public selected: any;
    public selectedCpfCnpj: any;
    public companyGreaterThanOne = false;
    public loading;
    public showModalDefaultCompany = false;
    public companyData: Array<any>;
    public askCompanyDefault = false;
    public noAgreed: boolean = false;
    @ViewChild('content', { static: true }) content: HTMLElement;

    private selectedCompany: Array<any>;
    public index = null;
    private isResponsive: boolean;
    public showTerm = false; // faz o modal aparecer
    public varSubscriptTerm: Subscription;
    public idTermo: string;
    public closeModalTerm: boolean = false;
    public isAdesao: boolean = false;
    public showOverdraftLimitModal: boolean = false;

    @Input() readonly: boolean = false;

    constructor(
        private authService: MsalService,
        private getCompanyTypes: GetCompanyTypesUsecases,
        private setCompany: setCompanyIndexUsecases,
        private companyDataShare: CompanyDataShare,
        private analytics: AngularFireAnalytics,
        private accessProvider: ProfileAccessProvider,
        private userService: UserService,
        private companyTypeRepository: CompanyTypeDataRepository,
        private acceptTermUsecase: AcceptTermUsecase,
        private pixGetTermsDataRepository: PixGetTermsDataRepository,
        private rolesService: RolesService,
        private router: Router
    ) {
        this.accessProvider.getAccessUser();
        this.optionsCompany = [];
        this.loading = false;
    }
    ngOnInit(): void {
        this.isResponsive = window.innerWidth <= 1081;
        this.showOverdraftLimitModal = this.showOverdraftLimiteChangeModal;
        this.reqCompanies();
        this.validAdesaoPix();
        this.noAgreed = this.showResolucao6;
    }


    private ConditionOpenModal() {
        this.companyTypeRepository.getAskCompanyDefault().subscribe(x => {
            this.askCompanyDefault = x.data.pergunta_empresa_padrao
            if (!this.askCompanyDefault && this.companyGreaterThanOne && !this.showOverdraftLimiteChangeModal) {
                this.companyDataShare.selectShared.subscribe(
                    (open) => {
                        if (open !== undefined && open) {
                            this.openModal();
                        }else {
                           this.isAdesao ? this.getTerm() : '';
                        }
                    }
                );
            } else {
                this.isAdesao ? this.getTerm() : '';
            }
        })
    }

    private openModal() {
        $(document).ready(() => {
            $('#open').click();
        });
    }

    closeModalOverdraft($event){
        // exibe o modal de empresa padrão, somente após o fechamento do modal de mudança de limite(caso seja exibido)
        this.reqCompanies();
    }

    private logout = () => {
        this.authService.logout();
        localStorage.clear();
    }

    // Call companies
    private reqCompanies = () => {
        this.loading = true;
        this.companyDataShare.dataShared.subscribe((res: { companyList: any }) => {
            if (res && res.companyList) {
                this.optionsCompany = res.companyList;
                this.companyGreaterThanOne = res.companyList.length > 1;
                this.selectedCompany = res.companyList.filter((e) => e.selected);
                this.updateContaRole();
                this.ConditionOpenModal();
                this.loading = false;
            } else {
                this.getCompanyTypes
                    .execute()
                    .subscribe(this.requestSuccess, this.requestError);
                this.closeModalTerm = true;  
            }
        });
    }

    private requestSuccess = (value) => {
        this.companyData = value.data;
        this.formatList(value);
        this.loading = false;
        const selecionada = this.optionsCompany.filter((e) => e.selected)[0];
        this.userService.setType(selecionada.tipo_pessoa);
    }

    private requestError = (err) => {
        if (err.status === 403 || err.status === 0) {
            this.logout();
        }
        this.loading = false;
    }

    private requestComplete() {
        location.reload();
        this.loading = true;
    }

    public reloadSession = (indice: number): void => {
        const selecionada = this.optionsCompany.filter((e) => e.indice === indice)[0];
        this.userService.setType(selecionada.tipo_pessoa);
        const allowRequest = indice !== this.selectedCompany[0].indice;
        if (allowRequest) {
            this.loading = true;
            const param: CompanyRequest = {
                index: indice,
                persistir: false,
            };
            this.setCompany
                .execute(param)
                .subscribe(
                    this.setCompanySuccess,
                    this.setCompanyError,
                    this.requestComplete
                );
        }
    }

    private setCompanySuccess = res => {
        if (res && res.data) {
            sessionStorage.setItem('x-ibpj-session', res.data.token_sessao);
            this.analytics.logEvent('change_company', {
                cod_cli: this.selectedCompany[0].value,
            });
        }
    }

    private updateContaRole() {
        let company = this.selectedCompany[0];
        localStorage.setItem("batch-company-name", JSON.stringify(this.selectedCompany[0]))
    }

    get indexCompany() {
        return this.index;
    }

    public getCompany(index): void {
        this.index = index;
        this.companyDataShare.setIndex(index);
    }

    private setCompanyError = (err) => {
        console.log('Company Set Error:', err);
    }
    public companyModal() {
        this.showModalDefaultCompany = !this.showModalDefaultCompany;
    }

    public closeModal(): void {
        this.reloadSession(this.index);
        this.companyDataShare.setSelect(false);
        this.showModalDefaultCompany = false;
    }

    private formatValue(value): string {
        return this.isResponsive ? formatString(value) : value;
    }

    private formatList = (value) => {
        this.optionsCompany = [];
        value.data.map((e, i) => {
            e.nome_cli_cad_int = formatString(e.nome_cli_cad_int, 27);
            this.optionsCompany.push({
                indice: e.indice,
                name: this.formatValue(e.nome_cli_cad_int),
                unformated_name: e.nome_cli_cad_int,
                value: e.cod_cli_cad_int,
                modality_account: e.num_cnpj_cpf_cli,
                ind_pauta_vencida: e.ind_pauta_vencida,
                ind_cliente_saida_risco: e.ind_cliente_saida_risco,
                ind_problema_credito: e.ind_problema_credito,
                ind_restricao_cred: e.ind_restricao_cred,
                ind_possui_conta: e.ind_possui_conta,
                selected: e.selecionado,
                cpfCnpj: e.num_cnpj_cpf_cli,
                tipo_pessoa: e.tipo_pessoa,
            });
        });
        this.selectedCompany = this.optionsCompany.filter((e) => e.selected);
        this.updateContaRole();
        this.companyDataShare.setValue({ companyList: this.optionsCompany });
        sessionStorage.removeItem("defaultCompany");
        sessionStorage.setItem(
            'defaultCompany',
            JSON.stringify(this.selectedCompany)
        );
        this.selected = this.formatValue(this.selectedCompany[0].name);
        this.selectedCpfCnpj = this.formatValue(this.selectedCompany[0].cpfCnpj);
        this.analytics.setUserProperties({
            cod_cli: this.selectedCompany[0].value,
        });
    }

    unsubscribeVariable(variable) {
        if(variable) {
          variable.unsubscribe();
        }
        return;
    }

    getTerm() {
        this.unsubscribeVariable(this.varSubscriptTerm);
        let params = {
            idTermo: versionTerm.VERSAO // SEMPRE QUE ALTERAR O TERMO TEM QUE MUDAR ESSE NÚMERO DE VERSIONAMENTO
        };

        this.idTermo = params.idTermo;

        this.varSubscriptTerm = this.acceptTermUsecase.execute(params)
            .subscribe(
                this.sucessTerm,
                this.errorTerm
            );
    }

    sucessTerm = (value) => {
       this.showTerm = false;
       console.log('success');
    }

    errorTerm = (err) => {
        this.showTerm = true;
        console.log('error');
    }

    closeEvent($event) {
        if($event != undefined || $event) {
            this.isAdesao ? this.getTerm() : '';
        }
    }
    
    validAdesaoPix() {
        let roles = false;
        roles = this.rolesService.getRoles().some((res: any) => res.Name.indexOf('MASTER') > -1 ? true : false); 
        if(roles === true ) {
            this.pixGetTermsDataRepository.getPixTerms()
            .subscribe((res) => {
                if(res.data[0].status === 'APROVADO'){
                    this.isAdesao = true
                }

                if(res.data[0].status !== 'APROVADO'){
                    this.isAdesao = false;
                } 
            }, (err) => {
                this.isAdesao = false;
            });
        }
    }

    public get showOverdraftLimiteChangeModal(): boolean {
        const roles = this.rolesService.get();
        
        let modalOverdraft = sessionStorage.getItem('modalOverdraftLimitChange');
        
        return (this.router.url === '/dashboard') && (roles.indexOf('ALTERACAO_LIMITE_MODAL') > -1 && modalOverdraft != 'true');
    }

    public get showResolucao6(): boolean {
        const roles = this.rolesService.get();
        let agreedModalShareData = sessionStorage.getItem('agreedModalShareData');
        return (this.router.url === '/dashboard') && (roles.indexOf('RESOLUCAO_6') > -1 && agreedModalShareData !== 'true');
    }
}
