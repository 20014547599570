import { ListQueryEntity } from '@src/data/repository/query/query-list/query.entity';
import { ListQueryModel } from './../../../../core/domain/query/query.model';
import { Mapper } from '@src/core/base/mapper';

export class QueryRepositoryMapper extends Mapper<
  ListQueryModel,
  ListQueryEntity
> {
  mapFrom(param: ListQueryEntity): ListQueryModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: ListQueryModel): ListQueryEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
