import { AddressEntity } from '@src/data/repository/charge/address/address.entity';
import { AddressTypeModel } from '@src/core/domain/charge/address.model';
import { Mapper } from '@src/core/base/mapper';

export class AddressRepositoryMapper extends Mapper <AddressEntity, AddressTypeModel> {
  mapFrom(param: AddressEntity): AddressTypeModel {
    return {
      code: param.code,
      status: param.status,
      data: {
        cep: param.data.Cep,
        street: param.data.Logradouro,
        complement: param.data.Complemento,
        neighborhood: param.data.Bairro,
        city: param.data.Localidade,
        uf: param.data.Uf
      }
    };
  }

  mapTo(param: AddressTypeModel): AddressEntity {
    return {
      code: param.code,
      status: param.status,
      data: {
        Cep: param.data.cep,
        Logradouro: param.data.street,
        Complemento: param.data.complement,
        Bairro: param.data.neighborhood,
        Localidade: param.data.city,
        Uf: param.data.uf
      },
    };
  }
}
