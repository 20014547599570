<div  *fibraHasRole="'MASTER'" class="container-flex">
  <div *ngIf="!showDisclaimer; else disclaimer">
    <div class="row">
      <div class="col-12">
        <h1>Como funciona o DDA?</h1>
      </div>
      <div class="col-12 mt-3">
        <span class="label"
          >O Débito Direto Autorizado permite que sua empresa receba e visualize
          eletronicamente os boletos de cobrança em que consta como devedora
          registrados pelas empresas cedentes, excluindo a necessidade de
          recebê-los em papel.</span
        >
      </div>
    </div>

    <hr class="mt-3" />
    <div class="row">
      <div class="col-12">
        <h1>Vantagens do DDA</h1>
      </div>
      <div class="col-12 mt-3">
        <span class="label">
          • Certeza do recebimento dos boletos eletronicamente;<br />
          • Conveniência para visualizar e pagar os boletos de cobrança no
          Internet Banking;<br />
          • Maior segurança e controle das contas a pagar;<br />
          • Redução do consumo de papel, com a inibição do boleto impresso.<br />
        </span>
      </div>
    </div>
    <hr />
    <div class="row justify-content-end">
      <button
        type="button"
        class="button-blue mr-2"
        (click)="showDisclaimer = true"
      >
        Ver Termo de Adesão
      </button>
    </div>
  </div>
  <ng-template #disclaimer>
      <fibra-disclaimer></fibra-disclaimer>
    <div class="row mt-5 justify-content-between">
      <div class="check-div">
        <input
          id="agree"
          type="checkbox"
          class="checkbox"
          (click)="enable()"
        />
        <label for="agree" class="check-font"
          >Li e concordo com o termo de adesão do débito autorizado.
        </label>
      </div>
      <button
        type="button"
        class="col-1 text-center button-blue mr-2"
        [disabled]="!enableNext"
        (click)="goStep()"
      >
        Avançar
      </button>
    </div>
  </ng-template>
</div>
