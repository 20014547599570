<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page">
  <fibra-breadcrumb
    height="16"
    bottom="-20"
    infoAccount="Mais"
    roule="Administração de usuários"
  ></fibra-breadcrumb>

  <div class="admin-content">
    <div class="rules-list">
      <fibra-list-admin-user></fibra-list-admin-user>
    </div>
  </div>
</div>
