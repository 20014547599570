<div class="box-component">
  <div class="title">
    <label class="position">{{ label }}</label>
  </div>

  <div class="selecionar_conta overflowingElements" id="box" *ngIf="mapArrows()" (window:resize)="hideIfOverflowing('.overflowingElements', '.hiddenArrows')">
    <div class="card_conta" *ngFor="let item of data; index as i">
      <div *ngIf="item.num_indice !== desable || ownership === 'other'">
        <div class="card-body card_conta-body" *ngIf="(showCard(item.desc_conta, item.num_conta | account) === true)">
          <div class="selecionar_conta-dados">
            <label>Conta</label>
            <span>{{ item.exibicao | titlecase }}</span>
            <!-- <span>{{showCard(item.desc_conta, item.num_conta | account)}}</span> -->
          </div>

          <div class="selecionar_conta-dados agency">
            <label>Dados da conta</label><span>AG: {{ item.cod_agencia }} | CC: {{ item.num_conta | account }}</span>
          </div>

          <div class="selecionar_conta-saldos">
            <div class="saldo-text">
              <span>Saldo</span>
            </div>

            <div class="cheque_empresa" *ngIf="item.saldo.vlr_saldo_cheque && item.saldo.vlr_limite">
              <div class="cheque-empresa-opcao">
                <label>Sem {{ item.exibicao | titlecase }}</label>
                <span>R$ {{ item.saldo.vlr_saldo | number: '1.2-2' }}</span>
              </div>

              <div class="cheque-empresa-opcao">
                <label for="">Com {{ item.exibicao | titlecase }}</label>
                <span>R$ {{ item.saldo.vlr_saldo_cheque | number: '1.2-2' }}</span>
              </div>
            </div>

            <div class="cheque_empresa" *ngIf="!item.saldo.vlr_saldo_cheque || !item.saldo.vlr_limite">
              <div class="cheque-empresa-opcao">
                <label>Saldo</label>
                <span>R$ {{ item.saldo.vlr_saldo | number: '1.2-2' }}</span>
              </div>
            </div>
          </div>
          <!-- [disabled]="i === desable || disableAccount(item.desc_conta, item.num_conta | account)" -->
          <div class="btn-conta">
            <button *ngIf="showButton" (click)="selectAccount(item, i)" [class.active]="active === item.num_indice">
              {{ active === item.num_indice ? 'Conta selecionada' : 'Selecionar conta' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-info" *ngIf="hide">
      <fibra-generic-info type="warn" title="Você não possui itens" message="Não foi possível encontrar nenhuma conta!">
      </fibra-generic-info>
    </div>
  </div>
  <div class="arrow hiddenArrows">
    <div>
      <img (click)="scrollBy('left', $event.target)" class="margin-icon" src="/assets/svg/arrow-left.svg" alt="" />
    </div>
    <div>
      <img (click)="scrollBy('right', $event.target)" src="/assets/svg/arrow-rigth.svg" alt="" />
    </div>
  </div>
</div>