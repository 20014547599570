<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>

<div class="content-page">
  <fibra-breadcrumb
          bottom="-120px"
          infoAccount="Transações"
          roule="Transferências"
          arrow="false"
          downloadDoc="false"
  ></fibra-breadcrumb>
  <div class="body-content">
    <div id="transfer-status">
      <div class="status-container">
        <div class="icon-status">
          <img src="/assets/png/iconCheck.png"/>
        </div>
        <div class="text-status">
          <div class="text-status-title">{{ typeMessage }}</div>
          <div class="text-status-message" *ngIf="agend">
            {{ dataConfirm.mensagem }}
          </div>
        </div>
      </div>
    </div>

    <div id="transfer-receipt">
      <div id="transfer-receipt-content">
        <div class="container">
          <div class="row row-line">
            <div class="col-2">
              <img src="/assets/png/logo-receipt.png" id="logo-receipt" />
            </div>
            <div class="col-10 text-right">
              <h1 style="text-align: center;">
                {{ textReceipt }}
              </h1>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p>Valor</p>
              <p class="bold">
                {{
                beneficiaryData.transferInformation.get('ammount').value
                | currency: 'BRL'
                }}
              </p>
            </div>
            <div class="col">
              <p>
                {{ textDate }}
              </p>
              <p class="bold" *ngIf="textReceipt === 'Comprovante de transferência'">
                {{
                beneficiaryData.transferInformation.get('dateTrasfer').value
                }}
                {{currentDate | date:'HH:mm'}}
              </p>
              <p class="bold" *ngIf="textReceipt === 'Comprovante de transferência agendada'">
                {{
                beneficiaryData.transferInformation.get('dateTrasfer').value
                }}
              </p>
            </div>
            <div class="col" *ngIf="!agend">
              <p>Tipo da transferência</p>
              <p class="bold">
                {{
                beneficiaryData.transferInformation.get('optionTransfer')
                .value
                }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <h2 class="bold">Beneficiário</h2>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p>Nome</p>
              <p class="bold" data-hj-suppress>
                {{ beneficiaryData.beneficiaryData.beneficiary.nome_beneficiario }}
              </p>
            </div>
            <div class="col">
              <p>CPF/CNPJ</p>
              <p class="bold">
                {{ beneficiaryData.beneficiaryData.beneficiary.num_cpf_cnpj }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p>Banco</p>
              <p class="bold">
                {{ beneficiaryData.beneficiaryData.quote.banco.cod_banco }} -
                {{ beneficiaryData.beneficiaryData.quote.banco.nome_banco }}
              </p>
            </div>
            <div class="col">
              <p>Agência</p>
              <p class="bold">
                {{ beneficiaryData.beneficiaryData.quote.num_agencia }}
              </p>
            </div>
            <div class="col">
              <p>Conta</p>
              <p class="bold">
                {{ beneficiaryData.beneficiaryData.quote.num_conta | account }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <h2 class="bold">Debitado de</h2>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p>Nome</p>
              <p class="bold" data-hj-suppress>
                {{ beneficiaryData?.transferInformation.get('nome_beneficiario').value }}
              </p>
            </div>
            <div class="col">
              <p>CPF/CNPJ</p>
              <p class="bold">
                {{
                removeMask(
                beneficiaryData.transferInformation.get('num_cpfcnpj').value
                ) | cpfcnpj
                }}
              </p>
            </div>
          </div>

          <div class="row row-line">
            <div class="col">
              <p>Banco</p>
              <p class="bold">224 - Banco Fibra</p>
            </div>
            <div class="col">
              <p>Agência</p>
              <p class="bold">{{ bank.cod_agencia }}</p>
            </div>
            <div class="col">
              <p>Conta</p>
              <p class="bold">{{ bank.num_conta | account }}</p>
            </div>
          </div>

          <div class="row row-line">
            <div class="col-5">
              <p>Número do compromisso</p>
              <p class="bold">
                {{ dataConfirm.numeroCompromisso || ' ' }}
              </p>
            </div>
            <div class="col-7" [hidden]="true">
              <p>Código de autenticação</p>
              <p class="bold">5fgh4ydh-1087-7689-8675-2b63ksgdh2</p>
            </div>
          </div>

          <div class="row" *ngIf="agend">
            <div class="col">
              <p>
                A transferência será efetivada mediante disponibilidade de saldo
                em conta.
              </p>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="card">
            <div class="html2pdf__page-break"></div>
            <br>

            <div class="row">
              <div class="col">
                <p>Se precisar de ajuda, entre em contato com a gente.</p>
              </div>
            </div>

            <br>
            <div class="card-body" #atendimento>
              <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
              <div class="text">
                <div class="title">Canais exclusivos</div>
                <div class="desc">Dúvidas e apoio</div>
              </div>
            </div>
            <div class="card-bottom">
              <p>
                <b>Corporate, Agro e PMEs</b>
                (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
              </p>
              <p>
                <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body" #sac>
              <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
              <div class="text">
                <div class="title">SAC</div>
                <div class="desc">Sugestões, reclamações e elogios</div>
              </div>
            </div>
            <div class="card-bottom">
              <p>
                <b>Canal de Atendimento ao Cliente</b>
              </p>
              <p>
                0800 727 0132
              </p>
              <p>
                <b>Canal exclusivo para deficientes auditivos ou de fala</b>
              </p>
              <p>
                0800 200 6921
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body" #ouvidoria>
              <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
              <div class="text">
                <div class="title">Ouvidoria</div>
                <div class="desc">Demandas não solucionadas</div>
              </div>
            </div>
            <div class="card-bottom">
              <p>
                0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-8 align-left">
            <button
                    type="button"
                    class="leaked-button preview"
                    routerLink="/transfer"
            >
              Nova transferência
            </button>
            <button
                    type="button"
                    class="leaked-button preview margin-left"
                    (click)="dowloadFile(dataConfirm.numeroCompromisso)"
            >
              Baixar comprovante
            </button>
          </div>
          <div class="col-4 align-right">
            <button
                    type="button"
                    class="secondary-button next"
                    routerLink="/dashboard"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-content></ng-content>

