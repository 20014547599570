import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import {
  francesinhaListModel,
  ListQueryRequest,
} from '@src/core/domain/query/query.model';
import { FrancesinhaRepository } from '@src/core/repositories/query/francesinha.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class getAllQueryFrancesinhaUsecase
  implements UseCase<ListQueryRequest, francesinhaListModel> {
  constructor(private instructionRepository: FrancesinhaRepository) {}

  execute(params: ListQueryRequest): Observable<francesinhaListModel> {
    return this.instructionRepository.getAllQueryFrancesinha(params);
  }
}

