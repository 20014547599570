import { Observable } from 'rxjs';
import {
  ExtractModel,
  ExtractRequest,
} from '@src/core/domain/extract/extract.model';

export abstract class ExtractRepository {
  abstract getAllExtracts(obj: ExtractRequest): Observable<ExtractModel>;
  abstract getEntriesExtracts(obj: ExtractRequest): Observable<ExtractModel>;
  abstract getExitExtracts(obj: ExtractRequest): Observable<ExtractModel>;
  abstract getSchedulingExtracts(obj: ExtractRequest): Observable<ExtractModel>;
  abstract getBlockedExtracts(obj: ExtractRequest): Observable<ExtractModel>;
}
