import { Injectable } from '@angular/core';
import { ExtractRepository } from '@src/core/repositories/extract/extract.repository';
import { UseCase } from '@src/core/base/use-case';
import {
  ExtractModel,
  ExtractRequest,
} from '@src/core/domain/extract/extract.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetSchedulingExtractsUsecase
  implements UseCase<ExtractRequest, ExtractModel> {
  constructor(private extractRepository: ExtractRepository) {}

  execute(params: ExtractRequest): Observable<ExtractModel> {
    return this.extractRepository.getSchedulingExtracts(params);
  }
}
