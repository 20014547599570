import { ArchiveEntity } from '@src/data/repository/charge/archive-repository/archive.entity';
import { ArchiveModel } from '@src/core/domain/charge/archive.model';
import { Mapper } from '@src/core/base/mapper';

export class DownloadArchiveRepositoryMapper extends Mapper<
  ArchiveEntity,
  ArchiveModel
> {
  mapFrom(param: ArchiveEntity): ArchiveModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }

  mapTo(param: ArchiveModel): ArchiveEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }
}
