import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { PixDeviceResponse } from './pix-device-entity';
import { getHeader } from '@src/core/base/header';

@Injectable({
  providedIn: 'root',
})
export class PixGetDeviceRegisterDataRepository {

  constructor(private http: HttpClient) {}

  public getPermissionPixTransfer(account: number) :Observable<PixDeviceResponse> {

    const httpOptions = {
        headers: getHeader(),
    };
   
    return this.http.get<PixDeviceResponse>(`${environment.URLBase}v1/pix/device/account/${account}`, httpOptions)
  }

  
}