<div class="approval-list" id="body-content">
  <header class="approval-list__header">
    <img class="logo" src="/assets/svg/logo_fibra.svg" alt="Logo Fibra" />
    <p class="tagline">
      Operações de crédito - Internet Banking - Banco Fibra -
      {{ this.date }} - Auto atendimento IB - {{ this.hour }}.
    </p>
    <h3 class="headline">Contratos</h3>
  </header>
  <main class="approval-list__content">
    <!-- <ul class="approval-list__content__tabs">
      <li class="item">{{ tabsType | titlecase }}</li>
      <li class="item">
        Conta
        <span *ngIf="accountType === ''" class="item__selected">{{
          accountType || 'Todas'
        }}</span>
        <span *ngIf="accountType !== ''" class="item__selected">{{
          accountType | account
        }}</span>
      </li>
      <li class="item">Tipo <span class="item__selected">Todos</span></li>
      <li class="item">
        Período <span class="item__selected">{{ diffDateDays }} dias</span>
      </li>
    </ul> -->

    <div class="approval-list__content__table">

      
      <table class="approval-table">
        <tr>
          <th class="tbl-col-1">Contrato</th>
          <th class="tbl-col-2">Produto</th>
          <th class="tbl-col-3">Valor do empréstimo</th>
          <th class="tbl-col-4">Prazo</th>
          <th class="tbl-col-5">Vencimento do contrato</th>
          <th class="tbl-col-6">Situação</th>
          <th class="tbl-col-6">Informações do contrato</th>
        </tr>

        <div class="pt-15">
          <span class="dateRef">Data de referência: {{ dateReferenceToShow | date: 'dd/MM/yyyy' }}</span>
        </div> 

        <tr *ngFor="let item of loansList">
          <td>{{ item.num_contrato }}</td>
          <td>{{ item.nome_produto }}</td>
          <td>{{ item.valor_principal | currency: 'BRL' }}</td>
          <td>{{ item.num_parcelas }} meses</td> 
          <td>{{ item.dt_fim | date: 'dd/MM/yyyy' }}</td>
          <td>
            <div class="progress">
              <div class="progress-bar custom-progress-bar" role="progressbar"
                  
                  [ngStyle]="{'width': (item.qt_parcela_paga/item.num_parcelas)*100  +'%'}"
                  aria-valuenow="5" aria-valuemin="0"
                  aria-valuemax="{{item.num_parcelas}}">
              </div>
          </div>
          <p class="custom-td-info">{{ item.qt_parcela_paga }} / {{
              item.num_parcelas }}</p>           
          </td>
          <td>
            <img src="/assets/png/icon_doc.png">
        </td>
        </tr>
      </table>
    </div>
  </main>
</div>



<!-- <div *ngIf="dailyPosition" class="approval-list" id="body-content">
  <header class="approval-list__header">
    <img class="logo" src="/assets/svg/logo_fibra.svg" alt="Logo Fibra" />
    <p class="tagline">
      Operações de crédito - Internet Banking - Banco Fibra -
      {{ this.date }} - Auto atendimento IB - {{ this.hour }}.
    </p>
    <h3 class="headline">Contratos</h3>
  </header>
  <main class="approval-list__content">
     <ul class="approval-list__content__tabs">
      <li class="item">{{ tabsType | titlecase }}</li>
      <li class="item">
        Conta
        <span *ngIf="accountType === ''" class="item__selected">{{
          accountType || 'Todas'
        }}</span>
        <span *ngIf="accountType !== ''" class="item__selected">{{
          accountType | account
        }}</span>
      </li>
      <li class="item">Tipo <span class="item__selected">Todos</span></li>
      <li class="item">
        Período <span class="item__selected">{{ diffDateDays }} dias</span>
      </li>
    </ul>

    <div class="approval-list__content__table">
      <table class="approval-table">
        <tr>
          <th class="tbl-col-1">
            Contrato xpto
          </th>
          <th class="tbl-col-2">Tipo de transação xpto</th>
          <th class="tbl-col-3">Produto xpto</th>
          <th class="tbl-col-4">Valor do empréstimo xpto</th>
          <th class="tbl-col-5">Vencimento do contrato xpto </th>
          <th class="tbl-col-6">Situação xpto</th>
        </tr>

        <tr *ngFor="let item of loansList">
          <td>{{ item.num_contrato }}</td>
          <td>
            <p class="tipo-transacao">{{ item.dt_fim | date: 'dd/MM/yyyy' }}</p>
            <p class="nome_favorecido">
              {{ item.nome_produto }}
            </p>
          </td>
          <td>{{ item.valor_emprestimo | currency: 'BRL' }}</td>
          <td>{{ item.num_parcelas }} meses</td>
          <td>{{ item.dt_fim | date: 'dd/MM/yyyy' }}</td>
          <td>
            <div class="progress">
              <div class="progress-bar custom-progress-bar" role="progressbar"
                  
                  [ngStyle]="{'width': (item.qt_parcela_paga/item.num_parcelas)*100  +'%'}"
                  aria-valuenow="5" aria-valuemin="0"
                  aria-valuemax="{{item.num_parcelas}}">
              </div>
          </div>
          <p class="custom-td-info">{{ item.qt_parcela_paga }} / {{
              item.num_parcelas }}</p>           
          </td>
        </tr>
      </table>
    </div>
  </main>
</div> -->