<div id="transfer-receipt">
  <div id="transfer-receipt-content">
    <div class="container">
      <div class="row row-line">
        <div class="col-2">
          <img src="/assets/png/logo-receipt.png" id="logo-receipt" />
        </div>
        <div class="col-10 text-right">
          <h1>{{ textReceipt }}</h1>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p>Valor</p>
          <p class="bold">{{ data?.vlr_transacao | currency: 'BRL' }}</p>
        </div>
        <div class="col">
          <p>{{ textDate }}</p>
          <p class="bold" *ngIf="agend">{{ data?.dt_agendada | date: 'dd/MM/yyyy' }}</p>
          <p class="bold" *ngIf="!agend">{{ data?.dt_execucao | date: 'dd/MM/yyyy HH:mm' }}</p>
        </div>
        <div class="col">
          <p>Tipo da transferência</p>
          <p class="bold">
            {{ data?.tipo_transacao || '--' }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h2 class="bold">Beneficiário</h2>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p>Nome</p>
          <p class="bold">
            {{ data?.info_beneficiario.nome_beneficiario | titlecase }}
          </p>
        </div>
        <div class="col">
          <p>CPF/CNPJ</p>
          <p class="bold">
            {{ removeMask(data?.info_beneficiario.num_cpf_cnpj) | account }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p>Banco</p>
          <p class="bold">
            {{ data?.info_beneficiario.cod_banco }} -
            {{ data?.info_beneficiario.nome_banco }}
          </p>
        </div>
        <div class="col">
          <p>Agência</p>
          <p class="bold">
            {{ resolveAgency(data?.info_beneficiario.num_agencia_conta) }}
          </p>
        </div>
        <div class="col">
          <p>Conta</p>
          <p class="bold">
            {{
              resolveAccount(data?.info_beneficiario.num_agencia_conta)
                | account
            }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h2 class="bold">Debitado de</h2>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p>Nome</p>
          {{ data?.info_conta.nome_conta | titlecase }}
        </div>
        <div class="col">
          <p>CPF/CNPJ</p>
          <p class="bold">
            {{ data?.info_beneficiario.num_cpf_cnpj }}
          </p>
        </div>
      </div>

      <div class="row row-line">
        <div class="col">
          <p>Banco</p>
          <p class="bold">224 - Banco Fibra</p>
        </div>
        <div class="col">
          <p>Agência</p>
          <p class="bold">
            {{ resolveAgency(data?.info_conta.num_agencia_conta) }}
          </p>
        </div>
        <div class="col">
          <p>Conta</p>
          <p class="bold">
            {{ resolveAccount(data?.info_conta.num_agencia_conta) | account }}
          </p>
        </div>
      </div>

      <div class="row row-line">
        <div class="col-5">
          <p>Número do compromisso</p>
          <p class="bold">{{ data?.cod_transacao }}</p>
        </div>
      </div>

      <div class="row" *ngIf="agend">
        <div class="col">
          <p>
            A transferência será efetivada mediante disponibilidade de saldo em
            conta.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
