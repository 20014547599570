import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { componentDeclarations } from '@src/shared/directives/tooltip/tooltip.common';

@NgModule({
  declarations: [...componentDeclarations],
  exports: [...componentDeclarations],
  imports: [CommonModule],
})

export class TooltipModule {}
