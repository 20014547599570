import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { StatusApprovalEnum } from "@src/core/domain/dda/dda-type-approval.enum";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { TermTypeEnum } from "@src/core/domain/dda/term-types-enum";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";
import { environment } from "@src/environments/environment";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TransacionalResolver implements Resolve<DdaTermCompanyApprovalModel[]> {

  constructor(
    private termRepository: TermImplementationRepository,
    public ddaTermShareData: DdaTermDataShare,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): DdaTermCompanyApprovalModel[] | Observable<DdaTermCompanyApprovalModel[]> | Promise<DdaTermCompanyApprovalModel[]> {
    return forkJoin(
      [ this.termRepository.get(TermTypeEnum.Adesao), this.termRepository.get(TermTypeEnum.Cancelamento)]
    ).pipe(
      map((result: DdaTermCompanyApprovalModel[]) =>{
        if(result!=null){
          return result;
        }
        else{
          if(!environment.production){
            console.log('Registros não encontrado para adesão e cancelamento');
          }
          return of(null);
        }
      }),
      catchError((a) =>{
        if(!environment.production){
          console.log('Erro ao consultar registros de adesão e cancelamento');
        }
        return of(null);
        ;
      })
    );
  }
}