import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { RegistratoResponse, RegistratoRequest } from './registrato.entity';
import { handleError } from '@src/shared/util-common';

@Injectable({
  providedIn: 'root',
})
export class RegistratoDataRepository {

  constructor(private http: HttpClient) {}

  get(): Observable<RegistratoResponse> {
        const httpOptions = {
          headers: getHeader(),
        };
          try {
            return this.http
            .get<RegistratoResponse>(
              `${environment.URLBase}v1/registrato`,
              httpOptions
            )
         }
         catch(error) {
             handleError(error);
         }
  }

  post(params: RegistratoRequest): Observable<RegistratoResponse> {    
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<RegistratoResponse>(
        `${environment.URLBase}v1/Token/ValidarOTP`, params,
        httpOptions
        );
  }

}
