<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="main-selector">
    <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" id="content-body body-content" id="body-scroll" *fibraHasRole="'
    APROV_TRANS.CONS_TRANS,
    APROV_TRANS.APROV_REPROV_TRANS'">


    <div class="body-content">
        <!-- <fibra-breadcrumb infoAccount="Transações" roule="Voltar" arrow="true" [downloadDoc]="true"
        (infoReport)="exportScreen($event)"></fibra-breadcrumb> -->
        <div class="body-header">
            <div>
                <img src="../../../assets/svg/arrow_page.svg" alt="arrow-page" (click)="goBack()" />
                <p style="color: #082a4d">Voltar</p>
            </div>
            <h1 style="color: #082a4d">Revisão de: {{ filename }}</h1>
        </div>

        <div class="wraper-list-value">
            <header class="wraper-list-header">
                <!-- <div class="check-all">
                    <input *ngIf="showCheckbox" type="checkbox" class="checkbox" (change)="checked('all')"
                        [checked]="shownAll === true" />
                </div> -->
                <div class="items">
                    <div class="col-date">
                        <span>Data pagamento</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('dt_transacao', data)" />
                    </div>
                    <div class="col-transaction">
                        <span>Tipo de transação</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('tipo_transacao', data)" />
                    </div>
                    <div class="col-account">
                        <span>Conta</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('num_conta', data)" />
                    </div>
                    <div class="col-cnpj">
                        <span>CNPJ / CPF</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('num_cpf_cnpj', data)" />
                    </div>
                    <div class="col-value">
                        <span>Valor</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('vlr_transacao', data)" />
                    </div>


                </div>
            </header>
            <div class="list-value">
                <div class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)"
                    *ngIf="loading">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="empty-info" *ngIf="hide">
                    <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
                        message="Tente uma nova pesquisa inserindo novos parâmetros.">
                    </fibra-generic-info>
                </div>

                <div *ngIf="!loading">
                    <div class="panel-group" id="accordion">
                        <div class="list-content" *ngFor="let item of detalheArquivoRemessa; index as i">
                            <div class="panel-list">

                                <div class="col-date">
                                    <span>
                                        {{ item?.dataPagamento}}
                                    </span>
                                </div>
                                <div class="col-transaction">
                                    <span class="title-desc">{{
                                        item?.tipoTransacao === 'PAGAMENTO'
                                        ? 'Pagamento de boleto'
                                        : item?.tipoTransacao
                                        }}</span>
                                    <span class="text-desc">{{
                                        item?.favorecido | titlecase
                                        }}</span>
                                </div>
                                <div class="col-account">
                                    <span>
                                        {{ item?.conta }}
                                    </span>
                                </div>
                                <div class="col-cnpj">
                                    <span>
                                        {{ item?.tipoTransacao !== 'Pagamento em Lote' ? item.CpjCnpj : '-' }}
                                    </span>
                                </div>
                                <div class="col-value">
                                    <span>
                                        {{ item?.valor | currency: 'BRL' }}
                                    </span>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </div>




        </div>

        <div class="send-cnab-file">
            <button (click)="openSecurityModal()">Enviar</button>
        </div>
        <fibra-modal-validation *ngIf="showSecurityModal" (closeEvent)="closeSecurityModal()"
            [apiCallback]=121 [apiPayload]="filename" (responseValidation)="finishUpload()"></fibra-modal-validation>

        
    </div>
</div>