import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageInterface } from "../modal-conclusao-adesao/interfaces/image-interfaces";

@Component({
  selector: "fibra-modal-sem-adesao",
  templateUrl: "./modal-sem-adesao.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
})
export class ModalSemAdesaoComponent implements OnInit {
  @ViewChild("content", { static: true }) content: HTMLElement;
  @Input() title: string = "";
  @Input() message: string = "";
  @Input() typeMessage: string = "";

  imgTypes: ImageInterface[] = [
    {
      type: "error",
      src: "assets/png/icon-error.png",
      forecolor: "fg-red",
    },
    {
      type: "success",
      src: "assets/png/icon-accreddited.png",
      forecolor: "fg-green",
    },
    {
      type: "warning",
      src: "assets/png/icon-pending.png",
      forecolor: "fg-warn",
    },
  ];

  imgSrc: string = "";
  //commit

  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit() {
    this.openModal(this.content);
    this.setModalData();
  }

  setModalData(): void {
    this.imgSrc = this.imgTypes.find((x) => x.type === this.typeMessage).src;
  }

  openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "modal-xxl",
      backdrop: "static",
    });
  }

  close(): void {
    this.router.navigate(["dashboard"]);
    this.modalService.dismissAll();
  }
}
