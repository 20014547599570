import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '@src/core/base/use-case';
import { AccountTypeRepository } from '@src/core/repositories/account/account-type.repository';
import { AccountTypeModel } from '@src/core/domain/account/accountType.model';

@Injectable({
  providedIn: 'root'
})
export class GetAccountsByFunctionalityUsecase implements UseCase<{ functionality: string, filter: boolean }, AccountTypeModel> {

  constructor(private accountTypeRepository: AccountTypeRepository) { }

  execute(params: { functionality: string, filter: boolean }): Observable<AccountTypeModel> {
    return this.accountTypeRepository.getAllAccountsByFuncionality(params.functionality, params.filter);
  }
}
