<div class="approval-list" id="body-content">
  <header class="approval-list__header">
    <img class="logo" src="/assets/svg/logo_fibra.svg" alt="Logo Fibra" />
    <p class="tagline">
      Consulta de derivativos - Internet Banking - Banco Fibra -
      {{ this.date }} - Auto atendimento IB - {{ this.hour }}.
    </p>
    <h3 class="headline">Produtos / Derivativos</h3>
  </header>
  <main class="approval-list__content">

    <div class="approval-list__content__table">
      <table class="approval-table">
        <tr>
          <th class="tbl-col-1">
            Data de Vencimento
          </th>
          <th class="tbl-col-2">Contrato</th>
          <th class="tbl-col-3">Notional</th>
          <th class="tbl-col-4">Valor Líquido</th>
          <th class="tbl-col-5">Tipo de Movimentação</th>
          
        </tr>

        <tr *ngFor="let item of approvalList">
          <td>{{ item.dt_vencto | date: 'dd/MM/yyyy' }}</td>
          <td>{{ item.num_contrato }}</td>
          <td>R$ {{ item.notional | number: '1.2-2' }}</td>
          <td>R$ {{ item.valor_liq | number: '1.2-2' }}</td>
          <td>{{item.tipo}}</td>
        </tr>
      </table>
    </div>
  </main>
</div>
