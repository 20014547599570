<div class="fibra-card" [ngClass]="'bk-' + backgroundColor">
  <div class="fibra-card-title">
    {{ titleCard }}
  </div>
  <div class="fibra-card-value">
    <div class="text">
      <small>{{ subtitle1Card }}</small>
      <p *ngIf="showData">R$ {{ value1 | number: '1.2-2' }}</p>
      <p *ngIf="!showData">*****</p>
    </div>

    <div class="text">
      <small>{{ subtitle2Card }}</small>
      <p *ngIf="showData">R$ {{ value2 | number: '1.2-2' }}</p>
      <p *ngIf="!showData">*****</p>
    </div>
  </div>
</div>
