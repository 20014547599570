<div class="mainRevision">
  <div class="panel-body" *ngIf="item.paymentReturn.desc_etapa_pagamento !== 'AprovadoSucesso' && item.paymentReturn.desc_etapa_pagamento !== 'AgendadamentoPrevio'">
    <div class="row">
      <div class="panel-column">
        <span class="panel-title">Valor do boleto</span>
        <span class="panel-text">R$ {{item && item.vlr_titulo | number: '1.2-2'}}</span>
      </div>
      <div class="panel-column">
        <span class="panel-title">Data de vencimento</span>
        <span class="panel-text">{{item && item.dt_vencimento}}</span>
      </div>
      <div class="panel-column">
        <span class="panel-title">Data do pagamento</span>
        <span class="panel-text">{{item && formatDate(item.dt_pagamento)}}</span>
      </div>
    </div>
    <div class="row">
      <div class="panel-column">
        <span class="panel-title">Nome do beneficiário:</span>
        <span class="panel-text">{{item && item.nome_beneficiario | titlecase}}</span>
      </div>
      <div class="panel-column">
        <span class="panel-title">CNPJ / CPF</span>
        <span class="panel-text">{{item && item.num_cpf_cnpj | cpfcnpj}}</span>
      </div>
      <div class="panel-column">
        <span class="panel-title">Banco</span>
        <span class="panel-text">{{item && item.num_banco + ' - ' + item.desc_banco}}</span>
      </div>
    </div>
    <div class="row">
      <div class="panel-column">
        <span class="panel-title">Descrição</span>
        <span class="panel-text">{{item && item.desc_transacao}}</span>
      </div>
    </div>
    <div class="row">
      <div class="panel-column">
        <span class="panel-title">Juros</span>
        <span class="panel-text">R$ {{item && item.vlr_juros | number: '1.2-2'}}</span>
      </div>
      <div class="panel-column">
        <span class="panel-title">Multas</span>
        <span class="panel-text">R$ {{item && item.vlr_multa | number: '1.2-2'}}</span>
      </div>
      <div class="panel-column">
        <span class="panel-title">Mora</span>
        <span class="panel-text">R$ {{item && item.vlr_mora | number: '1.2-2'}}</span>
      </div>
    </div>
  </div>
  <div class="absoluteButton" *ngIf="item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso' || item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio'">
    <div class="panel-header-button" (click)="generatePDF()">
      <span>Baixar PDF</span>
    </div>
  </div>
  <div class="panel-body" *ngIf="item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso' || item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio'" id="payment-receipt-content">
    <div class="row panel-header">
      <div class="panel-header-start">
        <img class="receiptLogo" src="/assets/png/logo-receipt.png" />
        <span class="panel-header-text">{{ item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio' ? 'Comprovante de agendamento' : 'Comprovante' }}</span>
      </div>
    </div>
    <div class="panel-line"></div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Valor</span>
        <span class="panel-text-second">R$ {{item && item.vlr_titulo | number: '1.2-2'}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">Forma de pagamento</span>
        <span class="panel-text-second">{{item && item.payment_method}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">Canal</span>
        <span class="panel-text-second">{{item && item.channel}}</span>
      </div>
    </div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Data de pagamento</span>
        <span class="panel-text-second">{{item && formatDate(item.dt_pagamento)}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">Data de vencimento</span>
        <span class="panel-text-second">{{item && item.dt_vencimento}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">Hora da transação</span>
        <span class="panel-text-second">{{item && item.paymentReturn.hr_transacao}}</span>
      </div>
    </div>
    <div class="row">
      <span class="receiptTitles">Beneficiário</span>
    </div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Nome</span>
        <span class="panel-text-second">{{item && item.nome_beneficiario | titlecase}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">CPF/CNPJ</span>
        <span class="panel-text-second">{{item && item.num_cpf_cnpj | cpfcnpj}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">Banco</span>
        <span class="panel-text-second">{{item && item.num_banco + ' - ' + item.desc_banco}}</span>
      </div>
    </div>
    <div class="row">
      <span class="receiptTitles">Encargos</span>
    </div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Juros</span>
        <span class="panel-text-second">R$ {{item && item.vlr_juros | number: '1.2-2'}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">Multas</span>
        <span class="panel-text-second">R$ {{item && item.vlr_multa | number: '1.2-2'}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">Mora</span>
        <span class="panel-text-second">R$ {{item && item.vlr_mora | number: '1.2-2'}}</span>
      </div>
    </div>
    <div class="row">
      <span class="receiptTitles">Conta debitada</span>
    </div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Nome</span>
        <span class="panel-text-second">{{item && item.debitCompany}}</span>
      </div>
      <div class="panel-column-second">
        <span class="panel-title-second">CPF/CNPJ</span>
        <span class="panel-text-second">{{item && item.debitDocument | cpfcnpj}}</span>
      </div>
    </div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Dados da conta</span>
        <span class="panel-text-second">{{item && item.debitAccountName | titlecase}}</span>
        <span class="panel-text-second-account">AG: {{item && item.debitAgency}} | CC: {{item && item.debitAccountCod | account}}</span>
      </div>
    </div>
    <div class="panel-line"></div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Número do compromisso</span>
        <span class="panel-text-second">{{item && item.paymentReturn.num_compromisso}}</span>
      </div>
    </div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Código de barras</span>
        <span class="panel-text-second">{{item && item.num_cod_barras | barcode}}</span>
      </div>
    </div>
    <div class="row">
      <div class="panel-column-second">
        <span class="panel-title-second">Pagamento efetuado com bases nas informações do código de barras.</span>
      </div>
    </div>
    <div class="panel-line" *ngIf="(!item.isPayment && item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso') || item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio'"></div>
    <div class="row" *ngIf="(!item.isPayment && item.paymentReturn.desc_etapa_pagamento === 'AprovadoSucesso') || item.paymentReturn.desc_etapa_pagamento === 'AgendadamentoPrevio'">
      <div class="panel-column-second">
        <span class="panel-title-second">O pagamento será efetivado mediante disponibilidade de saldo em conta.</span>
      </div>
    </div>
    <div class="card-content">
      <div class="card">
        <div class="html2pdf__page-break"></div>
        <br><br>
        <div class="card-body" #atendimento>
          <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
          <div class="text">
            <div class="title">Canais exclusivos</div>
            <div class="desc">Dúvidas e apoio</div>
          </div>
        </div>
        <div class="card-bottom">
          <p>
            <b>Corporate, Agro e PMEs</b>
            (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
          </p>
          <p>
            <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body" #sac>
          <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
          <div class="text">
            <div class="title">SAC</div>
            <div class="desc">Sugestões, reclamações e elogios</div>
          </div>
        </div>
        <div class="card-bottom">
          <p>
            <b>Canal de Atendimento ao Cliente</b>
          </p>
          <p>
            0800 727 0132
          </p>
          <p>
            <b>Canal exclusivo para deficientes auditivos ou de fala</b>
          </p>
          <p>
            0800 200 6921
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body" #ouvidoria>
          <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
          <div class="text">
            <div class="title">Ouvidoria</div>
            <div class="desc">Demandas não solucionadas</div>
          </div>
        </div>
        <div class="card-bottom">
          <p>
            0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
