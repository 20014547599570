import { Injectable } from '@angular/core';
import { OtpRepository } from '@src/core/repositories/otp/otp.repository';
import { UseCase } from '@src/core/base/use-case';
import { OtpModel, OtpRequest } from '@src/core/domain/otp/otp.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostOtpUsecase implements UseCase< any, OtpModel > {

  constructor(private otpTypeRepository: OtpRepository) { }

  execute(params: OtpRequest): Observable< OtpModel > {
    return this.otpTypeRepository.postOtp(params);
  }
}
