<div class="form-row title-information">
  <form [formGroup]="formStep2">
    <div class="responsiveContent">

    <fibra-section-content description="Informações do Título" style="margin-top: -10px; margin-bottom: -15px">
      <fibra-form-control-group formGroupName="titleInformation">
        <fibra-form-control [style.max-width.px]="328" label="Carteira">
          <fibra-dropdown formControlName="carteira" [style.width.%]="100" removeShadown="true" customClass="select-option" [options]="optionWallet"
            (change)="alterAccount($event)"></fibra-dropdown>
        </fibra-form-control>
        <fibra-form-control [style.max-width.px]="328" label="Conta header">
          <input disabled formControlName="conta" [style.width.%]="100" placeholder="000000" />
        </fibra-form-control>
        <fibra-form-control [style.max-width.px]="328" label="Seu número">
          <div class="control-data">
            <input formControlName="yourNumber" [style.width.%]="100" placeholder="0000000000" [textMask]="{
                mask: yourNumber,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }" />

            <ng-container *ngIf="titleInformationControlIsInvalid('yourNumber')">
              <small class="text-danger" *ngIf="titleInformationControlErrors('yourNumber').required">
                Não foi informado.
              </small>
              <small class="text-danger" *ngIf="titleInformationControlErrors('yourNumber').minlength">
                Mínimo de 10 dígitos.
              </small>
            </ng-container>
          </div>
        </fibra-form-control>
      </fibra-form-control-group>

      <div class="mt-3">
        <fibra-form-control-group formGroupName="titleInformation">
          <fibra-form-control [style.max-width.px]="328" label="Valor do título">
            <div class="control-data">
              <input formControlName="titleValue" [style.width.%]="100" currencyMask maxlength="17" [options]="
                titleInformationControl('coin').value === 1
                  ? {
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ',',
                      align: 'left',
                      allowNegative: false
                    }
                  : {
                      prefix: '$ ',
                      thousands: ',',
                      decimal: '.',
                      align: 'left',
                      allowNegative: false
                    }
              " />
              <ng-container *ngIf="titleInformationControlIsInvalid('titleValue')">
                <small class="text-danger" *ngIf="titleInformationControlErrors('titleValue').required">
                  Não foi informado.
                </small>
              </ng-container>
              <small class="text-danger" *ngIf="isTitleValueZero">
                Tem que ser maior que zero.
              </small>
            </div>
          </fibra-form-control>
          <fibra-form-control [style.max-width.px]="328" label="Chave de acesso nota fiscal">
            <div class="control-data">
              <input formControlName="fiscalKey" [style.width.%]="100" placeholder="000000" (change)="formatFiscalKey($event.target.value)"
                maxlength="54" [textMask]="{
                mask: fiscalKey,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }" />
              <ng-container *ngIf="titleInformationControlIsInvalid('fiscalKey')">
                <small class="text-danger" *ngIf="
                  titleInformationControlErrors('fiscalKey').required ||
                  titleInformationControlErrors('fiscalKey').minlength
                ">
                  Mínimo de 44 dígitos.
                </small>
              </ng-container>
            </div>
          </fibra-form-control>
          <fibra-form-control [style.max-width.px]="328" label="Moeda">
            <fibra-dropdown [style.width.%]="100" removeShadown="true" customClass="select-option" formControlName="coin" [options]="currencyOption"></fibra-dropdown>
          </fibra-form-control>
        </fibra-form-control-group>
      </div>
      <div class="mt-3">
        <fibra-form-control-group formGroupName="titleInformation">
          <fibra-form-control [style.max-width.px]="328" label="Valor desconto">
            <div class="control-data">
              <input formControlName="discount" [style.width.%]="100" maxlength="17" currencyMask [options]="
                titleInformationControl('coin').value === 1
                  ? {
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ',',
                      align: 'left',
                      allowNegative: false
                    }
                  : {
                      prefix: '$ ',
                      thousands: ',',
                      decimal: '.',
                      align: 'left',
                      allowNegative: false
                    }
              " />
              <ng-container *ngIf="titleInformationControlIsInvalid('discount')">
                <small class="text-danger" *ngIf="titleInformationControl('discount').value >= titleInformationControl('titleValue').value  ">
                  O valor do desconto não pode ser maior ou igual ao valor do título.
                </small>
                <small class="text-danger" *ngIf="!titleInformationControl('discount').value">
                  Não foi informado.
                </small>
              </ng-container>
            </div>
          </fibra-form-control>
          <fibra-form-control [style.max-width.px]="328" label="Mora (aplicado por dia)">
            <div class="control-data">
              <input formControlName="mora" maxlength="17" [style.width.%]="100" currencyMask [options]="
                titleInformationControl('coin').value === 1
                  ? {
                      prefix: 'R$ ',
                      thousands: '.',
                      decimal: ',',
                      align: 'left',
                      allowNegative: false
                    }
                  : {
                      prefix: '$ ',
                      thousands: ',',
                      decimal: '.',
                      align: 'left',
                      allowNegative: false
                    }
              " />
              <ng-container *ngIf="titleInformationControlIsInvalid('mora')">
                <small class="text-danger" *ngIf="titleInformationControlErrors('mora').required">
                  Não foi informado.
                </small>
              </ng-container>
            </div>
          </fibra-form-control>
          <fibra-form-control [style.max-width.px]="328" label="Espécie">
            <fibra-dropdown [style.width.%]="100" removeShadown="true" customClass="select-option" formControlName="especie" [options]="especiesOptions"
            (change)="changeEspecie($event)"></fibra-dropdown>
          </fibra-form-control>
        </fibra-form-control-group>
      </div>

      <div class="mt-3">
        <fibra-form-control-group formGroupName="titleInformation">
          <fibra-form-control [style.max-width.px]="145" label="Data de emissão">
            <div class="control-data">
              <fibra-datepicker 
                formControlName="createAt" 
                id="createAt" 
                [date]="createDate" 
                (dateSelected)="selectDate('createAt', $event)"
                [minDate]="createDefaultDate" 
                placement="top"
                [maxDate]="maxIssueDate"
              ></fibra-datepicker>
              <ng-container *ngIf="titleInformationControlIsInvalid('createAt')">
                <small class="text-danger" *ngIf="titleInformationControlErrors('createAt').dateGreaterToday ">
                  Data inválida. Informe uma data igual ou menor que a data atual.
                </small>
                <small class="text-danger" *ngIf="dateControlFirstError('createAt').required ">
                  Data inválida. Informe uma data igual ou menor que a data atual.
                </small>
              </ng-container>
            </div>
          </fibra-form-control>

          <fibra-form-control [style.max-width.px]="145" label="Data de vencimento">
            <div class="control-data">
              <fibra-datepicker formControlName="dueDate" [date]="dueDate" [minDate]="createDefaultDueDate" (dateSelected)="selectDate('dueDate', $event)"
                placement="top"></fibra-datepicker>
              <ng-container *ngIf="titleInformationControlIsInvalid('dueDate') && titleInformationControl('carteira').value !== 'CBONLINE'">
                <small class="text-danger" *ngIf="titleInformationControlErrors('dueDate').dateLessTodayFiveDays">
                  Data inválida. Informe uma data de no mínimo 5 dias superior a data atual.
                </small>
                <small class="text-danger" *ngIf="titleInformationControlErrors('dueDate').required  ">
                  Data inválida. Informe uma data.
                </small>
              </ng-container>

              <ng-container *ngIf="titleInformationControlIsInvalid('dueDate') && titleInformationControl('carteira').value === 'CBONLINE'">
                <small class="text-danger" *ngIf="titleInformationControlErrors('dueDate').dateLessToday">
                  Data inválida. Informe uma data igual ou maior que a data atual.
                </small>
                <small class="text-danger" *ngIf="titleInformationControlErrors('dueDate').required">
                  Data inválida. Informe uma data igual ou maior que a data atual.
                </small>
              </ng-container>
            </div>
          </fibra-form-control>

          <fibra-form-control [style.max-width.px]="145" label="Data limite para desconto">
            <div class="control-data">
              <fibra-datepicker formControlName="limitDiscountDate" [date]="limitDiscountDate" [minDate]="createDefaultDate" (dateSelected)="selectDate('limitDiscountDate', $event)"
                placement="top"></fibra-datepicker>
              <ng-container *ngIf="titleInformationControlIsInvalid('limitDiscountDate')">
                <small class="text-danger" *ngIf="titleInformationControlErrors('limitDiscountDate').dateLessToday">
                  Data inválida. Informe uma data igual ou maior que a data atual.
                </small>
                <small class="text-danger" *ngIf="titleInformationControlErrors('limitDiscountDate').mustSmallerDate">
                  Data inválida. Informe uma data igual ou menor que a data de vencimento.
                </small>
                <small class="text-danger" *ngIf="titleInformationControlErrors('limitDiscountDate').required">
                  Data inválida. Informe uma data igual ou maior que a data atual.
                </small>
              </ng-container>
            </div>
          </fibra-form-control>

          <fibra-form-control [style.max-width.px]="518" [style.height.px]="108" label="Mensagens">
              <div class="control-data">
                  <input
                  type='text'
                  id="message1"
                  formControlName="message1"
                  [style.width.%]="100"
                  maxlength="69"
                />
                <!-- <ng-container *ngIf="titleInformationControlIsInvalid('message1')">
                    <small class="text-danger" *ngIf="titleInformationControlErrors('message1').required">
                      Não foi informado.
                    </small>
                  </ng-container> -->
                <input
                  type='text'
                  id="message2"
                  formControlName="message2"
                  [style.width.%]="100"
                  maxlength="69"
                />
                <input
                  type='text'
                  id="message3"
                  formControlName="message3"
                  [style.width.%]="100"
                  maxlength="69"
                />
                <input
                  type='text'
                  id="message4"
                  formControlName="message4"
                  [style.width.%]="100"
                  maxlength="69"
                />
                <input
                  type='text'
                  id="message5"
                  formControlName="message5"
                  [style.width.%]="100"
                  maxlength="69"
                />
              </div>
            </fibra-form-control>

        </fibra-form-control-group>
      </div>

      <div style="margin-top: -10px;">
        <fibra-form-control-group formGroupName="titleInformation">
          <fibra-form-control-group [style.max-width.px]="300">
            <div class="control-data">

              <fibra-section-content>
                <fibra-form-control-group>
                  <fibra-radiobutton label="Multa" (selected)="showTax($event)" [option]="multaOption" [check]="taxCheck"></fibra-radiobutton>
                </fibra-form-control-group>
              </fibra-section-content>
              
              <div class="tax-input" *ngIf="checkTax">
                <fibra-input 
                  formControlName="tax" 
                  [style.width.%]="100" 
                  maxlength="17"  
                  [currency]="checkTax === 'percent' ? false : true"
                  [percentage]="checkTax === 'percent' ? true : false" 
                  [typeCurrency]="titleInformationControl('coin').value"
                  (keyup)="checkValueTax($event)"
                ></fibra-input>
                  
                <ng-container *ngIf="titleInformationControlIsInvalid('tax')">
                  <small class="text-danger" *ngIf="titleInformationControlErrors('tax').required">
                    Não foi informado.
                  </small>
                </ng-container>
                <ng-container *ngIf="hasErrorPercent">
                  <small class="text-danger">
                    Percentual não permitido. Informe um percentual maior que 0, menor ou igual a 2%.
                  </small>
                </ng-container>
              </div>
            </div>
          </fibra-form-control-group>
        </fibra-form-control-group>
      </div>

    </fibra-section-content>

    <div class="divisor" style="margin-bottom: 25px;"></div>

  </div>

    <fibra-section-content description="Informações de protesto">
      <fibra-form-control-group formGroupName="titleInformation">
        <fibra-radiobutton 
          label="Deseja protestar?" 
          (selected)="showProtest($event)" 
          [option]="protestOption" 
          [check]="titleInformationControl('protest').value"
        ></fibra-radiobutton>
      </fibra-form-control-group>

      <fibra-form-control class="info-protest" *ngIf="checkRadio">
        <fibra-protest #myInput id="inputDay" inputValue="{{inputValue}}" dateProtest="{{dateProtest}}" date="{{protest}}" (protest)="Protest($event)" onkeypress="return (!(event.charCode >= 33 && event.charCode <= 44) && !(event.charCode == 46) && !(event.charCode >= 58 && event.charCode <= 64) && !(event.charCode == 91) && !(event.charCode >= 93 && event.charCode <= 96) && !(event.charCode >= 123 && event.charCode <= 126) && !(event.charCode >= 128 && event.charCode <= 187))"></fibra-protest>
      </fibra-form-control>
    </fibra-section-content>

    <div class="col-12 buttons" *ngIf="submit">
      <div class="description no-border">
        <div class="step-buttons-container offset-9">
          <button type="button" class="leaked-button preview">Voltar</button>
          <button type="button" class="secondary-button next" (click)="goNext()" [disabled]="!titleInformationForm.valid || verifyValue === true || hasErrorPercent">
            Avançar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>