import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fibra-modal-cheque-empresa-pix',
  templateUrl: './modal-cheque-empresa-pix.component.html',
  styleUrls: ['./modal-cheque-empresa-pix.component.scss'],
})
export class ModalChequeEmpresaPixComponent implements OnInit {
  @Input() valor: number;
  @Input() conta: string = 'Cheque Empresa';
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', { static: true }) content: HTMLElement;
  
  constructor(
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.conta = this.conta === 'CHEQUE EMPRESA' ? 'CHEQUE EMPRESA' : this.conta;
    this.openModal(this.content);
  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal(value: any): void {
    this.closeEvent.emit(value);
    this.modalService.dismissAll();
  }
}
