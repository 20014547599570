import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[mascaraCPFCNPJ]'
})
export class MascaraCPFCNPJDirective {

    constructor(private ngControl: NgControl) {}

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event: any) {
        this.alteraInput(event, false);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
      this.alteraInput(event.target.value, true);
    }

    alteraInput(event, backspace: boolean) {
        let input = event.replace(/\D/g, '');
        if (input.length === 0) {
            input = '';
        } else if (input.length <= 3) {
            input = input.replace(/^(\d{0,3})/, '$1');
        } else if (input.length <= 6) {
            input = input.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
        } else if (input.length <= 9) {
            input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
        } else if (input.length <= 11) {
            input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
        } else if (input.length === 11) {
            input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
        } else if (input.length <= 12) {
            input = input.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3/$4');
        } else {
            input = input.substring(0, 14);
            input = input.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
        }
        this.ngControl.valueAccessor.writeValue(input);
    }
}
