import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { PixTermResponse } from './pix-terms.entity';

@Injectable({
  providedIn: 'root',
})
export class PixGetTermsDataRepository {

  constructor(private http: HttpClient) { }

  public getPixTerms(): Observable<PixTermResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<PixTermResponse>(`${environment.URLBase}v1/TermoAceite/empresa/1`, httpOptions);
  }

  getTerms() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<any>(`${environment.URLBase}v1/TermoAceite/contas-sem-termo/1`, httpOptions);
  }


  public approvePixTerm(id: string): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.put<any>(`${environment.URLBase}v1/termoaceite/aprovar/termo/${id}`, {}, httpOptions);
  }

  public reprovePixTerm(id: string): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.put<any>(`${environment.URLBase}v1/termoaceite/reprovar/termo/${id}`, {}, httpOptions);
  }

  public getRedInfomationPix(type: string): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get(`${environment.URLBase}v1/pix/carrossel?tipo=${type}`, httpOptions);
  }
  public alterReadInformartionPix(type: string) {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.post<any>(`${environment.URLBase}v1/pix/carrossel?tipo=${type}`, {}, httpOptions);
  }
}
