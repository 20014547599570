import { DisclaimerModel } from '@src/core/domain/disclaimer/disclaimer.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { AcceptTermModel } from '@src/core/domain/accept-term/accept-term.model';
import { AcceptTermRepositories } from '@src/core/repositories/accept-term/accept-term.repository';

@Injectable({
  providedIn: 'root',
})
export class AcceptTermUsecase implements UseCase<{}, AcceptTermModel> {
  constructor(private acceptTermRepositories: AcceptTermRepositories) { }

  execute(params: {}): Observable<AcceptTermModel> {
    return this.acceptTermRepositories.getAcceptTerm(params);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PostAcceptTermUsecase implements UseCase<{}, AcceptTermModel> {
  constructor(private acceptTermRepositories: AcceptTermRepositories) { }

  execute(params: {}): Observable<AcceptTermModel> {
    return this.acceptTermRepositories.postAcceptTerm(params);
  }
}
