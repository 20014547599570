import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fibra-pix-transfer-proof',
  templateUrl: './pix-transfer-proof.component.html',
  styleUrls: ['./pix-transfer-proof.component.scss']
})
export class PixTransferProofComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
