<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>
<div class="content-page"
  *fibraHasRole="'CAP_AGIL.SIM_SOL_CONTR,CAP_AGIL.ACOMP_SOL'"
>
  <div class="div">
    <fibra-breadcrumb
      bottom="0"
      infoAccount="Produtos"
      roule="Capital Ágil"
      arrow="false"
      downloadDoc=""
    ></fibra-breadcrumb>
    <div class="navbar-teste">
      <div class="item-unselected" (click)="changeNavbarClass('produtos-simulacao')" id="produtos-simulacao-item"
        *fibraHasRole="'CAP_AGIL.SIM_SOL_CONTR'"
      >
        <span class="item-font-unselected" id="produtos-simulacao-font"> Produtos e Simulação </span>
      </div>
      <div *fibraHasRole="'CAP_AGIL.ACOMP_SOL'">
        <div class="item-unselected" id="acompanhar-item" (click)="changeNavbarClass('acompanhar-solicitacao')" id="acompanhar-solicitacao-item" *ngIf="temSolicitacao">
          <span class="item-font-unselected" id="acompanhar-solicitacao-font"> Acompanhar Solicitação </span>
        </div>
      </div>
    </div>
  </div>
  <section class="body-content">
   <fibra-produtos-simulacao *ngIf="produtos" 
   (callAcompanhar)="updateAcompanhar()"> </fibra-produtos-simulacao>
   <fibra-acompanhar-solicitacao *ngIf="solicitacao"></fibra-acompanhar-solicitacao>
  </section>
</div>
