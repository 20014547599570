import { Injectable } from '@angular/core';
import { QueryRepository } from '@src/core/repositories/query/query.repository';
import { UseCase } from '@src/core/base/use-case';
import {
  ListQueryModel,
  ListQueryRequest,
} from '@src/core/domain/query/query.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GetAllQueryUsecase implements UseCase<ListQueryRequest, ListQueryModel> {

  constructor(private queryRepository: QueryRepository) { }

  execute(params: ListQueryRequest): Observable<ListQueryModel> {
    return this.queryRepository.getAllQuery(params);
  }
}
