import { ListArchivesModel } from '@src/core/domain/charge/archive.model';
import { ListArchivesEntity } from '@src/data/repository/charge/archive-repository/archive.entity';
import { Mapper } from '@src/core/base/mapper';

export class ArchiveListRepositoryMapper extends Mapper<
  ListArchivesEntity,
  ListArchivesModel
> {
  mapFrom(param: ListArchivesEntity): ListArchivesModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: ListArchivesModel): ListArchivesEntity {
    return {
      code: param.code,
      status: param.status,
      data: {
        dt_envio: param.data.send_date,
        arquivos: param.data.archives,
      },
    };
  }
}
