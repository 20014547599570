<div class="main-selector">
    <fibra-company-list ></fibra-company-list>
  </div>
  <div class="content-page" *fibraHasRole="'MASTER'">
    <fibra-debug-dda></fibra-debug-dda>
    <fibra-breadcrumb
      bottom="-20"
      infoAccount="Transações"
      roule="Débito Direto Autorizado (DDA)"
      arrow="false"
      infoText='DDA é o Débito Direto Autorizado, uma modalidade de cobrança na qual o boleto é disponibilizado ao devedor em meio eletrônico, sem a emissão de papel ou envio de correspondência.'
    ></fibra-breadcrumb>
  
    <ng-template #firstStep>
      <fibra-aceite-termo (goNextStep)="goNextStep($event)"></fibra-aceite-termo>
    </ng-template>
    <ng-template #secondStep>
      <fibra-dda-contrate></fibra-dda-contrate>
    </ng-template>
  
    <div class="row">
      <div class="col mt-5">
        <fibra-steps
        [stepObj]="step"
        [firstStep]="firstStep"
        [secondStep]="secondStep"
        [currentStep]="currentStep"
        [titleSans]="true"
      ></fibra-steps>
      </div>
    </div>
  
  </div>
  
