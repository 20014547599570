import { Component, OnInit } from "@angular/core";

@Component({
  selector: "fibra-disclaimer-cancel",
  templateUrl: "./disclaimer-cancel.component.html",
  styleUrls: ["../../../pages/dda/dda.scss","./disclaimer-cancel.component.scss"],
})
export class DisclaimerCancelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
