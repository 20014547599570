<div *fibraHasRole="'MASTER'">
  <div *ngIf="!showDisclaimer" class="card mt-3 noborder">
    <div class="card-body" *ngIf="showMainData">
      <div class="row" *ngIf="!isDebug" [hidden]="showDetail">
        <h1><img id="pendentes" alt="logo" src="assets/svg/help-circle.svg" style="margin-right: 12px !important;" />Status Processo - Filiais</h1>
          <table class="table">
            <thead>
                <tr>
                    <th scope="col">Aderido <img id="pendentes" alt="logo" src="assets/svg/bullet_green.svg" style="margin-right: 12px !important;" /></th>
                    <th scope="col">Ades&atilde;o pendente <img id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" /></th>
                    <th scope="col">Ades&atilde;o em processo <img id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" /></th>
                    <th scope="col">Cancelamento pendente <img id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" /></th>
                    <th scope="col">Cancelamento em processo <img id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" /></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                  <!--ADESAO-->
                    <td [ngClass]="adesaoConcluida ? 'table-cell': ''">
                      <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'green'} : {'color': 'red'}">{{adhesionInLegado.toString().toUpperCase()}}</b> , esperado <b style="color: green;">TRUE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'red'} : {'color': 'green'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ?  {'color': 'red'} : {'color': 'green'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ?  {'color': 'red'} : {'color': 'green'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                    </td>
                    <!--ADESAO PENDENTE-->
                    <td [ngClass]="adesaoPendente ? 'table-cell': ''">
                      <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'red'} : {'color': 'green'}">{{adhesionInLegado.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'green'} : {'color': 'red'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ? {'color': 'red'} : {'color': 'green'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ? {'color': 'red'} : {'color': 'green'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                    </td>
                    <!--ADESAO EM PROCESSO-->
                    <td [ngClass]="adesaoEmProcesso ? 'table-cell': ''">
                      <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'red'} : {'color': 'green'}">{{adhesionInLegado.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'red'} : {'color': 'green'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ? {'color': 'red'} : {'color': 'green'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ? {'color': 'red'} : {'color': 'green'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                    </td>                      
                    <!--CANCELAMENTO PENDENTE-->
                    <td [ngClass]="cancelamentoPendente ? 'table-cell': ''">
                      <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'green'} : {'color': 'red'}">{{adhesionInLegado.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'red'} : {'color': 'green'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ? {'color': 'red'} : {'color': 'green'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ? {'color': 'green'} : {'color': 'red'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                    </td>
                    <!--CANCELAMENTO EM PROCESSO-->
                    <td [ngClass]="cancelamentEmProcesso ? 'table-cell': ''">
                      <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'green'} : {'color': 'red'}">{{adhesionInLegado.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'red'} : {'color': 'green'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ? {'color': 'green'} : {'color': 'red'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                      <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ? {'color': 'red'} : {'color': 'green'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                    </td>                      
                </tr>
            </tbody>
        </table>
      </div>      
      <div>
        <div class="row">
          <div class="col-10">
            <h1 (click)="toogleElement()">Dados da Empresa</h1>
          </div>
          <div class="col-2" *fibraHasRole="'MASTER'">
            <button
              type="button"
              class="leaked-button"
              *ngIf="showUndoCancel && !isDesfazerCancelamento"
              (click)="manter()"
            >
              Manter Adesão
            </button>
            <button
              type="button"
              class="leaked-button"
              *ngIf="showUndoCancel && isDesfazerCancelamento"
              (click)="showModalUndo()"
            >
              Manter Adesão
            </button>            
            <button
              type="button"
              [hidden]="adesaoEmProcesso || cancelamentEmProcesso"
              [className]="adhesionInLegado ? 'leaked-button' : 'text-center button-blue'"
              *ngIf="!waitApproval && !waitApprovalCancel && !showUndoCancel"
              [disabled]="!adhesionInLegado"
              (click)="!(adesaoEmProcesso || cancelamentEmProcesso) && showCancel(true)"
            >
              Cancelar Adesão
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-3">
            <span class="table-header">CNPJ</span>
          </div>
          <div class="col-3">
            <span class="table-header">Razão Social</span>
          </div>
          <div class="col-6">
            <span class="table-header">Status</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <span class="label-medium">{{ empresa.cpfCnpj | cpfcnpj }}</span>
          </div>
          <div class="col-3">
            <span class="label-medium">{{ empresa.name }}</span>
          </div>
          <div class="col-6">
            <div [ngSwitch]="statusAdesao" class="label-medium">
              <div *ngSwitchCase="'ADERIDO'">
                <span class="label-medium">
                  <img alt="" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                </span>
              </div>
              <div *ngSwitchCase="'ADESAO_PENDENTE'">
                <span class="label-medium">
                  <span [appTooltip]="tooltipAll" class="icon-info notification">
                    <img id="pendentes" alt="logo" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                    <div #tooltipAll class="tooltip-custom">
                      <p style="font-size: 14px;">Aguardando aprovação</p>
                      <div class="masters" style="text-align: left;">
                        <div class="masters-title">
                          <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                          <span class="master-font"> Masters </span>
                        </div>
                        <span *ngFor="let pessoa of aprovadores">
                          <span class="master-sub">{{pessoa.nome_pessoa}}</span>
                        </span>
                      </div>
                      <div class="arrow"></div>
                    </div>
                  </span>
                </span>                            
              </div>
              <div *ngSwitchCase="'ADESAO_EM_PROCESSAMENTO'">
                <span class="label-medium">
                  <span [appTooltip]="tooltipAll" class="icon-info notification">
                    <img id="pendentes" alt="logo" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                    <div #tooltipAll class="tooltip-validacao-em-processo">
                      <div class="masters" style="text-align: left">
                        <div class="masters-title">
                          <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                          <span class="master-font">Em processamento de adesão</span>
                        </div>
                      </div>
                      <div class="arrow"></div>
                    </div>
                  </span>
                </span>                            
              </div>            
              <div *ngSwitchCase="'CANCELAMENTO_PENDENTE'">
                <span class="label-medium">
                  <span [appTooltip]="tooltipAll" class="icon-info notification">
                    <img id="pendentes" alt="logo" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                    <div #tooltipAll class="tooltip-custom">
                      <p style="font-size: 14px;">Aguardando aprovação</p>
                      <div class="masters" style="text-align: left;">
                        <div class="masters-title">
                          <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                          <span class="master-font"> Masters </span>
                        </div>
                        <span *ngFor="let pessoa of aprovadoresCancelamento">
                          <span class="master-sub">{{pessoa.nome_pessoa}}</span>
                        </span>
                      </div>
                      <div class="arrow"></div>
                    </div>
                  </span>
                </span>              
              </div>
              <div *ngSwitchCase="'CANCELAMENTO_EM_PROCESSAMENTO'">
                <span class="label-medium">
                  <span [appTooltip]="tooltipAll" class="icon-info notification">
                    <img id="pendentes" alt="logo" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                    <div #tooltipAll class="tooltip-validacao-em-processo">
                      <div class="masters" style="text-align: left">
                        <div class="masters-title">
                          <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                          <span class="master-font">Em processo de cancelamento</span>
                        </div>
                      </div>
                      <div class="arrow"></div>
                    </div>
                  </span>
                </span>              
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row mt-4">
          <div class="col-12">
            <h1>Dados da Filial</h1>
          </div>
          <div class="col-12">
            <p class="mt-2 label-medium">
              A filial precisa ter o CNPJ raiz igual da matriz.
            </p>
          </div>
        </div>

        <div class="list-value">
          <div class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)" *ngIf="isLoading">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
          </div>

        <div *ngIf="filiais.length > 0  && !isLoading; else notfound">
          <div class="row mt-4 align-items-center">
            <div class="col-1" *fibraHasRole="'MASTER'">
              <input *ngIf="!isDesfazerCancelamento" type="checkbox" class="checkbox" (change)="checkedAll()" />
            </div>
            <div class="col-3">
              <span class="table-header">CNPJ</span>
            </div>
            <div class="col-3">
              <span class="table-header">Razão Social</span>
            </div>
            <div class="col-3">
              <span class="table-header">Status</span>
            </div>
            <div class="col-1 pl-1">
              <span class="table-header">Ação</span>
            </div>
          </div>
          <div class="div" *ngFor="let filial of filiais; index as i">
            <div class="row mt-2 align-items-center">
              <div class="col-1" *fibraHasRole="'MASTER'" [ngClass]="{ 'pb-3': filial.status === '' }">
                <input
                  *ngIf="!(filial.statusId===5 || filial.statusId===9 || filial.statusId===10)"
                  id="{{filial.identificacaoFilial}}"
                  [disabled]="filial.statusId===1"
                  type="checkbox"
                  class="checkbox"
                  [checked]="filial.selecionado"
                  (change)="filial.selecionado = !filial.selecionado"
                />
              </div>
              <div *ngIf="filial.statusId!==0 ; else newBranch" class="col-3">
                <span class="label-medium">{{
                  getInscricao(filial) | cpfcnpj
                }}</span>
              </div>
              <ng-template #newBranch>
                <div class="col-3">
                  <div class="form-group row">
                    <span class="ml-3 label-medium col-form-label">{{
                      filial.cnpjInscricao
                    }}</span>
                    <div class="col pl-1">
                      <input
                        type="text"
                        class="form-control text-left label-medium"
                        maxlength="7"
                        #ctrl="ngModel"
                        [(ngModel)]="filial.identificacaoFilial"
                        required
                        value="{{ filial.identificacaoFilial }}"
                        [textMask]="{
                          mask: cnpjMask,
                          placeholderChar: '_',
                          guide: false,
                          modelClean: true
                        }"
                        (blur)="validarCnpj(filial);processarValidacoes()"
                      />
                    </div>
                  </div>
                </div>
              </ng-template>
              <div class="col-3">
                <span class="label-medium">{{ filial.razaoSocial }}</span>
              </div>
              <div class="col-3 pl-2 pb-3">
                <span *ngIf="filial.statusId == 0 ; else showToolTip" class="label-medium">
                  <img alt="" src="assets/svg/bullet_yellow.svg" />&nbsp;&nbsp;
                </span>
                <ng-template #showToolTip>
                  <span *ngIf="filial.statusId === 2 && !aguardandoAprovacao()" class="label-medium">
                    <img alt="" src="assets/svg/bullet_green.svg" />&nbsp;&nbsp;
                  </span>
                  <span *ngIf="filial.statusId !== 2 || showUndoCancel || cancelInProgress || aguardandoAprovacao()">
                    <span [appTooltip]="tooltipAll" class="icon-info notification">
                      <span *ngIf="filial.statusId == 10 ; else showToolTipPendente" class="label-medium">
                        <img id="exclusao" alt="logo" src="assets/svg/bullet-red.svg" style="margin-right: 12px !important;" />
                      </span>
                      <ng-template #showToolTipPendente>
                        <img *ngIf="filial.statusId !== 10 " id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" />
                      </ng-template>
                      <div #tooltipAll class="label-medium" [ngClass]="!exibeAguardandoAprovacaoFilial(filial) && !showUndoCancel && !aguardandoAprovacao() || filial.statusId == 5 ? 'tooltip-validacao': 'tooltip-custom'">
                        <div *ngIf="exibeAguardandoAprovacaoFilial(filial) && !this.adhesionInLegado || this.waitApproval">
                          <div class="masters" style="text-align: left">
                            <div class="masters-title"> 
                              <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                              <span class="master-font">Em processo de ades&atilde;o</span>
                            </div>
                          </div>
                          <div class="arrow"></div>
                        </div>                      
                        <div *ngIf="filial.statusId == 5">
                          <div class="masters" style="text-align: left">
                            <div class="masters-title">
                              <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                              <span class="master-font"> CNPJ em valida&ccedil;&atilde;o </span>
                            </div>
                          </div>
                          <div class="arrow"></div>
                        </div>                        
                        <div *ngIf="exibeAguardandoAprovacaoFilial(filial) && filial.aprovadores!=null && filial.aprovadores.length > 0">
                          <p style="font-size: 14px;">Aguardando aprovação filial</p>
                          <div class="masters" style="text-align: left;">
                            <div class="masters-title">
                              <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                              <span class="master-font"> Masters </span>
                            </div>
                            <span *ngFor="let item of filial.aprovadores">
                              <span class="master-sub" *ngIf="item.status_id === 9 || item.status_id === 10">{{item.nome}}</span>
                            </span>
                          </div>
                          <div class="arrow"></div>
                        </div>
                        <div *ngIf="filial.statusId !== 13 && filial.statusId !== 2 && showUndoCancel || aguardandoAprovacao() && aprovadores!=null && aprovadores.length > 0">
                          <p style="font-size: 14px;">Aguardando aprovação {{descStatus}}</p>
                          <div class="masters" style="text-align: left;">
                            <div class="masters-title">
                              <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                              <span class="master-font"> Masters </span>
                            </div>
                            <span *ngFor="let pessoa of aprovadores">
                              <span class="master-sub">{{pessoa.nome_pessoa}}</span>
                            </span>
                            <span *ngFor="let pessoa of aprovadoresCancelamento">
                              <span class="master-sub">{{pessoa.nome_pessoa}}</span>
                            </span>                                                    
                          </div>
                          <div class="arrow"></div>
                        </div>
                        <div *ngIf="filial.statusId !== 5 && !exibeAguardandoAprovacaoFilial(filial) && !showUndoCancel && !aguardandoAprovacao()">
                          <div class="masters" style="text-align: left">
                            <div class="masters-title">
                              <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                              <span class="master-font">Em processamento</span>
                            </div>
                          </div>
                          <div class="arrow"></div>
                        </div>
                          <div *ngIf="filial.statusId == 2 && !exibeAguardandoAprovacaoFilial(filial)">
                          <div class="masters" style="text-align: left">
                            <div class="masters-title">
                              <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                              <span class="master-font">Em processamento</span>
                            </div>
                          </div>
                          <div class="arrow"></div>
                        </div>
                      </div>
                    </span>
                  </span>
                </ng-template>
                <span *ngIf="showUndoCancel || aguardandoAprovacaoCancelamento()" class="label-medium">
                    Cancelamento pendente
                </span>
                <span *ngIf="!showUndoCancel && !aguardandoAprovacao()"  [ngClass]="filial.statusId == 10 ? 'label-medium-red' : 'label-medium'">
                  {{filial.statusId | ddaStatus}}
                </span>
              </div>

              <label for="{{filial.identificacaoFilial}}">
              <div
                *ngIf="
                  (filial.statusId === 0 || filial.statusId === 2) &&
                  !filial.excluido
                "
                class="col-1 pl-2 pb-3"
              >
                <img *ngIf="!isDesfazerCancelamento"
                  src="/assets/svg/trash.svg"
                  alt="Excluir filial"
                  (click)="excluirFilial(i)"
                />
              </div>
              <div
                *ngIf="filial.statusId === 2 && filial.excluido"
                class="col-1 pb-3 pl-2"
              >
                <img
                  src="/assets/svg/undo.svg"
                  alt="Desfazer exclusão"
                  (click)="desfazerExclusao(filial)"
                />
              </div>
              </label>
            </div>
            <div
              class="row align-items-center"
              *ngIf="!filial.validado && filial.status === ''"
            >
              <div class="col-1"></div>
              <img alt="" class="pl-3" src="assets/svg/info-error.svg" />
              <small class="label-small text-danger"
                >&nbsp;
                {{ filial.msgValidacao }}
              </small>
            </div>
          </div>
        </div>
        <ng-template #notfound>
          <div class="row">
            <div class="col">
              <span class="label-xmedium info-warning"
                >Não existem filiais cadastradas para essa adesão.</span
              >
            </div>
          </div>
        </ng-template>

        <div class="row mt-5" *fibraHasRole="'MASTER'">
          <button
            type="button"
            [hidden]="adesaoEmProcesso || cancelamentEmProcesso"
            *ngIf="!isDesfazerCancelamento"
            [className]="adhesionInLegado ? 'col-2 leaked-button ml-2' : 'text-center button-blue ml-2'"
            (click)="!(adesaoEmProcesso || cancelamentEmProcesso) && adicionarFilial()"
            [disabled]="!adhesionInLegado"
          >
            Adicionar Filial
          </button>
        </div>
        <hr />
        <div class="row mt-2 justify-content-end" *fibraHasRole="'MASTER'">
          <button
            type="button"
            [hidden]="adesaoEmProcesso || cancelamentEmProcesso"
            *ngIf="!isDesfazerCancelamento"
            [disabled]="desativarBotaoSalvarAlteracoes && !isDesfazerCancelamento"
            class="text-center button-blue mr-2"
            (click)="!(adesaoEmProcesso || cancelamentEmProcesso) && showCancelFiliais()"
          >
            Salvar Alterações
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<fibra-modal-confirmation
  #content
  [title]="titleCancel"
  [message]="messageCancel"  
  (closeEvent)="closeModalConf($event)"
  *ngIf="showModalCancel"
>
</fibra-modal-confirmation>

<fibra-modal-confirmation
  #content
  [title]="'Sua solicitação de cancelamento será interrompida'"
  [message]="'Deseja continuar?'"
  (closeEvent)="closeModalUndo($event)"
  *ngIf="modalUndo"
>
</fibra-modal-confirmation>

<fibra-modal-validation
  *ngIf="exibirAutorizacao"
  [apiCallback]="apiCallback"
  (closeEvent)="fecharModal()"
  [apiPayload]="apiPayload"
  (responseValidation)="respostaApi($event)"
>
</fibra-modal-validation>
<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>

<fibra-modal-alteracao-adesao
  *ngIf="exibirConclusao"
  [contemFilial]="contemFilial"
  [totalMasters]="totalMasters"
  [inclusaoFilial]="inclusaoFilial"
  [exclusao]="excluindoFiliais()"
  [filiais]="getFiliaisExclusao()"
  (closeEvent)="fecharModalConclusao()"
>
</fibra-modal-alteracao-adesao>

<fibra-criar-cancelamento-adesao
  *ngIf="showDisclaimer && statusTermCancel === null"
  [apiPayload]="apiPayload"
></fibra-criar-cancelamento-adesao>

<fibra-modal-conclusao-cancelamento
  *ngIf="exibirConclusaoManter"
  [manter]="true"
  [recusar]="false"
  [totalMasters]="0"
  [totalFiliais]="0"
  (closeEvent)="fecharModalConclusao()"
>
</fibra-modal-conclusao-cancelamento>
