export enum DdaCallBackEnum {
  AderirTermo = 130,
  AprovarTermo = 131,
  AprovacaoAlteracaoFiliais = 132,
  AceiteAlterarFiliais = 133,
  AprovarPagamentoBoleto = 134,
  ReprovarPagamentoBoleto = 135,
  AprovacaoExclusaoFiliaisEmLote = 136

}
