import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { PixDataModel, ReturnTypeModel } from '@src/core/domain/pix/return-type.model';
import { PixLimitesRepository } from '@src/core/repositories/pix/pix-limites.repository';

@Injectable({
  providedIn: 'root'
})
export class PixAccountLimitesUseCases implements UseCase<string, PixDataModel> {

  constructor(private pixLimiteRepository: PixLimitesRepository) { }

  execute(params: any): Observable<PixDataModel> {
    return this.pixLimiteRepository.getAccountLimitesPix(params);
  }  
}
