import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {AccountDataShare} from '@src/data/repository/data-share-repository';
import * as Util from '@src/shared/util-common';
import {GetAllAccountTypeUsecase} from '@src/core/usecases/account/get-all-accountTypes.usecase';
import {ApprovalMatrixModel, PeopleList} from '@src/core/domain/approval-matrix/approval-matrix.model';
import {GetPeopleUsecase} from '@src/core/usecases/approval-matrix/get-people.usecase';
import {GetAllPeopleUsecase} from '@src/core/usecases/approval-matrix/get-all-people.usercase';
import {ToastErrorMessageComponent} from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import {isObject, isString} from 'util';
import {toTitleCase} from 'codelyzer/util/utils';

@Component({
  selector: 'fibra-new-rule-matrix',
  templateUrl: './new-rule-matrix.component.html',
  styleUrls: ['./new-rule-matrix.component.scss'],
})
export class NewRuleMatrixComponent implements OnInit {
  public indexSelected: any;
  public countList = [];
  public addOperators = [];
  public accountGroup = [];
  public checkAccounts = [];
  public optionsCounter = [];
  public checkOperators = [];
  public countOptionsRule = [];
  public optionsCountSelected = [];
  public searchOperator: any;
  public hide: boolean;
  public loading: boolean;
  public isMaster: boolean;
  public errorOperator = true;
  private noOperator: boolean;
  public flagAlreadyDelete: boolean;
  public flagAlreadyExistOperator: boolean;
  public flagAccount;
  public masters = [];
  public selectedAccount;
  public randHack: string;
  public formAddRule: FormGroup;
  public operators: Array<PeopleList>;
  public rules: Array<ApprovalMatrixModel> = [];
  public people: Array<PeopleList> = [];
  public countTxtRegra = 30;
  public showAction: boolean;
  public selectedBottomFunction: string;
  public textAlertRule: string;
  public securityAcction: boolean;
  public apiCallback = 4;
  public model: any;
  public nomeRegra: string;
  public vlrPermitido: any;
  public newValue: any;
  public typeColorToast: string;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuild: FormBuilder,
    private shareAccount: AccountDataShare,
    private getAllPeople: GetAllPeopleUsecase,
    private getPeople: GetPeopleUsecase,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
  ) {
    this.formAddRule = this.formBuild.group({
      nome_regra: ['', [Validators.required]],
      vlr_permitido: ['', [Validators.required]],
      operator: ['', [Validators.required]],
      master: ['', [Validators.required]],
      count: ['', [Validators.required]],
    });
  }

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  ngOnInit(): void {
    this.initCreateRule();
  }

  private initCreateRule = () => {
    this.randHack = Math.floor(Math.random() * 9999999999999).toString();
    this.requestAllOperators();
    this.requestAccount();
    this.requestMasterOperator();
  }

  public cancelAll = () => {
    this.resetView();
    this.initCreateRule();
  }

  public changeNomeRegra(value) {
    this.countTxtRegra = 30 - value.length;
    this.nomeRegra = value;
  }

  public search = (text$: Observable<string>) => {
    const text = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => {
        this.errorOperator = term === '' || term === null || term === ' ';
        if(term === '' || term === null || term === ' '){
          return[];
        }
        else{
          var operadores= this.operators
            .filter(
              (v) => {
                const arr = v.email.split('@');
                const userEmail = arr[0];
                const userEmailDomain = arr[1];
                return v.nome_pessoa.toLowerCase().indexOf(term.toLowerCase()) > -1
                || userEmail.toLowerCase().indexOf(term.toLowerCase()) > -1
                || userEmailDomain.toLowerCase().indexOf(term.toLowerCase()) > -1
                || v.email.toLowerCase().indexOf(term.toLowerCase()) > -1;
              }
          );

          operadores = operadores.slice(0, 10);
          operadores = operadores.sort((a: any , b: any) => {
              if (a.nome_pessoa < b.nome_pessoa) {
                return -1;
              }
              else if(a.nome_pessoa > b.nome_pessoa){
                return 1;
              }
              return 0;
            } );

            return operadores;
          }
      })
    );
    return text;
  }

  public formatter = (x: { nome_pessoa: string, email: string }) => {
    this.searchOperator = x;
    return `${x.nome_pessoa} - ${x.email}`;
  }

  public verifyValue = () => {
    if (this.vlrPermitido === 0 || this.vlrPermitido == null || this.vlrPermitido === '' || this.vlrPermitido === undefined ) {
      this.toast.callModalMessage(null, 'O campo Valor Até R$ deve ser preenchido com valor superior a zero.');
      return true;
    }
    if(this.nomeRegra == '' || this.nomeRegra == undefined){
      this.toast.callModalMessage(null, 'O campo Nome da regra deve ser preenchido.');
      return true;
    }
    return false;
  }

  public addAccount() {
    let flagAlreadyExist = false;
    const empty = (this.countList && this.countList.length === 0);
    this.optionsCountSelected.map(e => {
      if (String(e) === String(this.countList)) {
        flagAlreadyExist = true;
      }
    });

    if (flagAlreadyExist && !empty) {
      this.toast.callModalMessage(null, 'Esta conta já faz parte da regra.');
      return;
    }
    if (empty) {
      this.toast.callModalMessage(null, 'Favor selecionar ao menos uma conta.');
      return;
    }
    this.optionsCountSelected.push(this.countList);
    this.formAddRule.controls.count.setValue(this.optionsCountSelected);
  }

  public deleteMessageOperator(bottomFunctions = "operator") {
    this.showAction = true;
    this.selectedBottomFunction = bottomFunctions;
    this.textAlertRule = this.checkOperators.length > 1 ?
        'Deseja realmente excluir os usuários selecionados?' :
        'Deseja realmente excluir o usuário selecionado?';

    return this.textAlertRule;
  }

  public deleteMessageAccount(bottomFunctions = "account") {
    this.showAction = true;
    this.selectedBottomFunction = bottomFunctions;
    this.textAlertRule = this.checkAccounts.length > 1 ?
        'Deseja realmente excluir as contas selecionadas?' :
        'Deseja realmente excluir a conta selecionada?';

    return this.textAlertRule;
  }

  public bottomFunctions(func): void {
    switch (func) {
      case 'account':
        this.deleteCheckAccounts();
        break;
      case 'operator':
        this.deleteCheckOperators();
        break;
      default:
        console.error('This function ' + func + ', is not configured yet');
        break;
    }
  }

  public cancelDeleteRule() {
    return (this.showAction = false);
  }

  public deleteCheckOperators() {
    this.showAction = false;
    if(this.indexSelected != null){
      const index: number = this.addOperators.indexOf(this.indexSelected);
      if (index !== -1) {
        this.addOperators.splice(index, 1);
        this.toast.callModalMessage(null, `Usuário excluído`);
      }
      const index2: number = this.people.indexOf(this.indexSelected);
      if (index2 !== -1) {
        this.people.splice(index2, 1);
      }
      if (this.addOperators.length === 0) {
        this.checkOperators = [];
      }
      this.indexSelected = null;
    }
    else{
      this.checkOperators.map(e => {
        const index: number = this.addOperators.indexOf(e);
        if (index !== -1) {
          this.addOperators.splice(index, 1);
          if(this.checkOperators.length > 1){
          this.toast.callModalMessage(null, `Usuários excluídos`);
          }
          else{
            this.toast.callModalMessage(null, `Usuário excluído`);
          }
        }
      });
      this.checkOperators = [];
    }
    
  }

  public deleteAccount(account) {
    this.indexSelected = account;
    this.deleteMessageAccount();
   
  }

  public changeCounter(event) {
    this.countList = [];
    if (this.countList.length === 0) {
      this.countList.push(event.name);
    }
  }

  private requestMasterOperator = () => {
    this.getAllPeople
      .execute({ id: 2 })
      .subscribe(
        this.requestMasterOperatorSuccess,
        this.requestMasterOperatorError
      );
  }

  private requestMasterOperatorSuccess = (res) => {
    this.masters = res.data;
  }

  private requestMasterOperatorError = (err) => {
    // TODO add error
  }

  public addOperatorRule() {
    let operatorName: string;
    const isOperator = this.operators.some(e => {
          if (this.searchOperator !== undefined) {
            return e.cod_pessoa === this.searchOperator.cod_pessoa;
          }
          return false;
        });
    this.flagAlreadyExistOperator = false;
    this.noOperator = this.operators.some(e => {
      if (isString(this.model)) {
        return e.nome_pessoa.toUpperCase() === this.model.toUpperCase()
            || e.email.toUpperCase() === this.model.toUpperCase();
      }
      if (isObject(this.model)) {
        return e.nome_pessoa.toUpperCase() === this.model.nome_pessoa.toUpperCase()
            || e.email.toUpperCase() === this.model.email.toUpperCase();
      }
    });
    if (isOperator && this.noOperator) {
      this.addOperators.map(e => {
        if (e.email === this.searchOperator.email) {
          this.flagAlreadyExistOperator = true;
        }
      });
      if (!this.flagAlreadyExistOperator) {
        this.addOperators.push(this.searchOperator);
        this.model = null;
      }
      this.searchOperator = [];
    }
    if (!isOperator && this.noOperator || this.flagAlreadyExistOperator) {
      operatorName = isObject(this.model) ? this.model.nome_pessoa : this.model;
      this.toast.callModalMessage(null, `O operador ${operatorName} já está relacionado a esta regra`);
      this.model = null;
    }
    if ((isOperator && !this.noOperator) || (!isOperator && !this.noOperator) ) {
      this.toast.callModalMessage(null, 'Nenhum registro encontrado.');
    }
  }

  public removeOperator(i) {
    this.indexSelected = i;
    this.deleteMessageOperator();
  }

  public onClick(event, value) {
    event.preventDefault();
    this.newValue = value;
    this.vlrPermitido = '';
    this.vlrPermitido = this.formatVlrPermitido(this.newValue);
  }

  public formatVlrPermitido = value => {
    if (value.length >= 12) {
    // tslint:disable-next-line:radix
      return parseInt(value.split(' ')[1]
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.'));
    }
    // tslint:disable-next-line:radix
    return parseInt(value.split(' ')[1]
        .replace('.', '')
        .replace(',', '.'));
  }

  public addMaster(e) {
    this.isMaster = e.target.checked;
  }

  public formatVlr(vlr) {
    if (isNaN(vlr)) {
      return vlr.replace('.', '').replace(',', '');
    } else {
      return vlr;
    }
  }

  public addNewRule() {
    if (this.verifyValue()) {
      return;
    }
    this.securityAcction = true;
  }

  public checkOperator(value) {
    this.indexSelected = null;
    let flagOperator = {
      bool: false,
      value: 0
    };
    this.checkOperators.map((e, i) => {
      if (e === value) {
        flagOperator = {
          bool: true,
          value: i,
        };
      }
    });
    if (!flagOperator.bool) {
      this.checkOperators.push(value);
    } else {
      this.checkOperators.splice(flagOperator.value, 1);
    }
  }

  public securityResponse = value => {
    if (value.status === 'success') {
      this.requestSuccess(value);
      return;
    }
    this.requestError(value);
  }

  public securityRequest = () => {
    this.loading = true;
    const accounts = [];
    const people = [];

    this.optionsCountSelected.map(e => {
      const cc = String(e).split(' - ');
      accounts.push({
        conta: {
          numero: cc[0],
          desc_conta: cc[1]
        }
      });
    });

    this.addOperators.map(e => {
      people.push({
        pessoa_id: e.cod_pessoa
      });
    });

    const params = {
      master: this.isMaster,
      nome_regra: this.nomeRegra,
      vlr_permitido: this.formatVlr(this.vlrPermitido),
      regras_contas: accounts,
      regras_operadores: people,
    };
    return params;
  }

  private requestSuccess = value => {
    this.loading = false;
    this.optionsCountSelected = [];
    this.addOperators = [];
    this.nomeRegra = null;
    this.vlrPermitido = null;
    this.isMaster = false;
    this.loading = false;
    this.securityAcction = false;
    this.typeColorToast = this.GREENCOLOR;
    this.model = null;
    this.onSuccess.emit();
  }

  private requestError = (err) => {
    this.loading = false;
    this.securityAcction = false;
    this.typeColorToast = this.REDCOLOR;
    if (err.error.message && err.error.message.length > 0) {
      this.toast.callModalMessage(null, `${err.error.message[0]}`);
    } else {
      this.toast.callModalMessage(null, 'Ocorreu um erro ao criar a regra. Tente novamente mais tarde.');
    }
  }

  public checkAccount(value) {
    this.indexSelected = null;
    this.flagAccount = {
      bool: false,
      value: 0,
    };
    this.checkAccounts.map((e, i) => {
      if (e.conta_id === String(value)) {
        this.flagAccount = {
          bool: true,
          value: i,
        };
      }
    });
    if (!this.flagAccount.bool) {
      this.checkAccounts.push({ conta_id: value[0] });
    } else {
      this.checkAccounts.splice(this.flagAccount.value, 1);
    }
  }

  public deleteCheckAccounts() {
    this.showAction = false;
    this.flagAlreadyDelete = false;
    if(this.indexSelected != null)
    {
      const index: number = this.optionsCountSelected.indexOf(this.indexSelected);
      if (index !== -1) {
        this.optionsCountSelected.splice(index, 1);
        this.toast.callModalMessage(null, `Conta excluída`);
      }
      this.indexSelected = null;
    }
    else{
      this.checkAccounts.map(e => {
        const index: number = this.accountGroup.indexOf(e);
        if (index !== -1) {
          this.accountGroup.splice(index, 1);
          if(this.checkAccounts.length > 1){
            this.toast.callModalMessage(null, `Contas excluídas`);
          }
          else{
            this.toast.callModalMessage(null, `Conta excluída`);
          }
        }
        this.optionsCountSelected.map((f, i) => {
          if (String(f) === e.conta_id) {
            this.optionsCountSelected.splice(i, 1);
            if(this.checkAccounts.length > 1){
              this.toast.callModalMessage(null, `Contas excluídas`);
            }
            else{
              this.toast.callModalMessage(null, `Conta excluída`);
            }
          }
        });
      });
      this.checkAccounts = [];
    }
  }

  private requestAccount(): void {
    this.loading = true;
    this.getAllAccountTypes
      .execute(null)
      .subscribe(
        this.requestAccountSuccess,
        this.requestAccountError,
        this.requestComplete
      );
  }

  private requestAccountError = (err) => {
    this.hide = true;
    this.loading = false;
  }

  private requestAccountSuccess = (value) => {
    this.countOptionsRule = [];

    value.data.map((e) => {
      this.countOptionsRule.push({
        name: `${e.num_conta} - ${toTitleCase(e.desc_conta)}`,
        value: e.num_conta,
      });
    });
    this.selectedAccount = this.countOptionsRule[0].value;
  }

  private requestAllOperators = () => {
    this.getAllPeople
      .executeApproval({ id: 1 })
      .subscribe(
        this.requestAllOperatorsSuccess,
        this.requestAllOperatorsError
      );
  }

  public requestAllOperatorsSuccess = (res) => {
    this.operators = res.data;
  }

  public requestAllOperatorsError = (err) => {
    this.operators = [];
  }

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.rules);
    this.loading = false;
  }

  private resetView = () => {
    this.countList = [];
    this.addOperators = [];
    this.accountGroup = [];
    this.checkAccounts = [];
    this.optionsCounter = [];
    this.checkOperators = [];
    this.countOptionsRule = [];
    this.optionsCountSelected = [];
    this.searchOperator = null;
    this.hide = false ;
    this.loading = false;
    this.isMaster = false;
    this.errorOperator = true;
    this.noOperator = false;
    this.flagAlreadyDelete = false;
    this.flagAlreadyExistOperator = false;
    this.flagAccount = null;
    this.masters = [];
    this.selectedAccount = null;
    this.randHack = null;
    this.operators = [];
    this.rules = [];
    this.people = [];
    this.showAction = false;
    this.selectedBottomFunction = null;
    this.textAlertRule = null;
    this.securityAcction = false;
    this.model = null;
    this.nomeRegra = null;
    this.vlrPermitido = null;
    this.newValue = null;
  }

  public closeModal = () => {
    this.securityAcction = false;
    this.loading = false;
  }

  public checkLengthOperators = () => {
    return this.checkOperators.length <= 1 || this.addOperators.length < 2;
  }

  public checkDeleteOperators = () => {
    return (this.checkOperators.length > 1 && this.addOperators.length > 1);
  }
}
