import { AccountTypeModel } from '@src/core/domain/account/accountType.model';
import { Mapper } from '@src/core/base/mapper';
import { AccountTypeEntity } from '@src/data/repository/account/accountType.entity';

export class AccountTypeRepositoryMapper extends Mapper<
  AccountTypeEntity,
  AccountTypeModel
> {
  mapFrom(param: AccountTypeEntity): AccountTypeModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }

  mapTo(param: AccountTypeModel): AccountTypeEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
