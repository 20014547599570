import { HttpHeaders } from '@angular/common/http';
import { environment } from '@src/environments/environment';

function setInitialHeader(ssotoken?: string) {
  let head: HttpHeaders;
  head = new HttpHeaders()
    .append('accept', 'application/json')
    .append('Access-Control-Allow-Origin', '*')
    .append('content-type', 'application/json')
    .append('authorization', 'Bearer ' + ssotoken);

  return head;
}

const setHeader = (token?: string, autorization?: string) => {
  let headToken: HttpHeaders;
  headToken = new HttpHeaders()
    .append('accept', 'application/json')
    .append('Access-Control-Allow-Origin', '*')
    .append('accept', 'text/plain')
    .append('x-ibpj-session', token)
    .append('authorization', 'Bearer ' + autorization);

  return headToken;
};


export const getInitialHeader = () => {
  const token = sessionStorage.getItem('accessToken');
  return setInitialHeader(token);
};

export const getHeader = () => {
  const authorizationToken = sessionStorage.getItem('accessToken');
  const sessionToken = sessionStorage.getItem('fibra-session');  
  return setHeader(sessionToken, authorizationToken);
};
