<div class="quick-go">

  <header class="quick-go-header">
    <h1>Acompanhamento</h1>
  </header>
  <main class="quick-go-content">
    <section *ngIf="!hideAccompaniment" class="first-part">
      <h2 class="sub-title">Cobrança</h2>
      <!-- <div *ngFor="let item of dataAccompaniment">
        <div class="text">
          {{ item.qtd_itens }} Título{{ item.qtd_itens > 1 ? 's' : '' }}
          <span *ngIf="item.tipo_itens === 'Vencer'">à</span>
          <span *ngIf="item.tipo_itens === 'Analise'">em</span>
          {{ item.tipo_itens | lowercase }}
        </div>
      </div> -->

      <div>
        <div class="text">
          {{dataAccompaniment.qtdTotalRecusados}} Título{{dataAccompaniment.qtdTotalRecusados > 1 ? 's' : '' }} 
          recusado{{dataAccompaniment.qtdTotalRecusados > 1 ? 's' : '' }}
        </div>
        <div class="text">
          {{dataAccompaniment.qtdTotalAprovados}} Título{{dataAccompaniment.qtdTotalAprovados > 1 ? 's' : '' }} 
          aprovado{{dataAccompaniment.qtdTotalAprovados > 1 ? 's' : '' }}
        </div>    <div class="text">
          {{dataAccompaniment.qtdTotalEmAnalise}} Título{{dataAccompaniment.qtdTotalEmAnalise > 1 ? 's' : '' }} 
          em análise{{dataAccompaniment.qtdTotalEmAnalise > 1 ? 's' : '' }}
        </div>
      </div>

      <div class="button-receipt" *ngIf="dataAccompaniment">
        <button type="button" class="secondary-button button" (click)="moreInfo('charge')">
          Mais informações
        </button>
      </div>

      <div class="empty-info" *ngIf="hideAccompaniment">
        <fibra-generic-info type="warn" title="Você não possui itens"
          message="Não foi possível encontrar dados de acompanhamento!">
        </fibra-generic-info>
      </div>
    </section>







    <section>
      <fibra-warranty-map showMoreInfo="true" (eventClick)="moreInfo('Guarantee')"></fibra-warranty-map>

      <!-- <fibra-warranty-map></fibra-warranty-map> -->
      <!-- <h2 class="sub-title">Garantia</h2>
      <div class="text" *ngFor="let item of dataWarranty">
        <span *ngIf="item.tipo_garantia === 'TotalSuperveniencia'"
          >Superveniência/Insuficiência:</span
        >
        <span *ngIf="item.tipo_garantia === 'TotalExigivel'"
          >Total Exigível:</span
        >
        <span *ngIf="item.tipo_garantia === 'TotalGarantias'"
          >Total Garantias:</span
        >
        <div>
          R$ {{ !showData ? "*****" : (item.vlr_garantia | number: "1.2-2") }}
        </div>
      </div>
      <div class="button-receipt" *ngIf="dataWarranty">
        <button
          type="button"
          class="secondary-button button"
          (click)="moreInfo('Guarantee')"
        >
          Mais informações
        </button>
      </div>
      <div class="empty-info" *ngIf="hideWarranty">
        <fibra-generic-info
          type="warn"
          title="Você não possui itens"
          message="Não foi possível encontrar dados de garantia!"
        >
        </fibra-generic-info>
      </div> 
    </section> -->
      <!-- <section
      class="empty-info-default"
      *ngIf="hideWarranty && hideAccompaniment"
    >
      <fibra-generic-info
        type="warn"
        title="Você não possui itens"
        message="Não foi possível encontrar dados de cobrança ou garantia!"
      >
      </fibra-generic-info>  -->
    </section>

  </main>
  <fibra-toast-functionality-access></fibra-toast-functionality-access>
</div>