import {
  Component, Output, EventEmitter
} from '@angular/core';

@Component({
  selector: 'fibra-dropdown-dda',
  templateUrl: './dropdown-dda.component.html',
  styleUrls: ['./dropdown-dda.component.scss'],
})
export class DropdownDdaComponent {
  @Output() public change: EventEmitter<any> = new EventEmitter();

  public selected(item: string) {
    this.change.emit(item);
  }
}
