import { Observable } from 'rxjs';
import {
  ListQueryModel,
  ListQueryRequest,
  ListQueryInfoTitleRequest,
  ExcelExportListQueryModel,
  PdfExportListQueryModel
} from '@src/core/domain/query/query.model';

export abstract class QueryRepository {
  abstract getAllQuery(obj: ListQueryRequest): Observable<ListQueryModel>;
  abstract getAuthorizedQuery(
    obj: ListQueryRequest
  ): Observable<ListQueryModel>;
  abstract getInfoTitleQuery(obj: ListQueryInfoTitleRequest): Observable<ListQueryModel>;
  abstract getInfoTransitTitleQuery(obj: ListQueryInfoTitleRequest): Observable<ListQueryModel>;
  abstract getPendingQuery(obj: ListQueryRequest): Observable<ListQueryModel>;
  abstract getRefusedQuery(obj: ListQueryRequest): Observable<ListQueryModel>;
  abstract getAllQueryExcelExport(obj: ListQueryRequest): Observable<ExcelExportListQueryModel>;
}
