<div class="fibra-card">
  <div class="fibra-card-title">
    {{ titleCard }}
  </div>
  <div class="fibra-card-value">
    <ng-container [ngSwitch]="valueType">
      <ng-container *ngSwitchCase="'number'">
        <p>
          <small>R$</small>
          <span *ngIf="showData" class="value"> {{ value | number: '1.2-2' }} </span>
          <span *ngIf="!showData" class="value"> ***** </span>
        </p>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ value }}
      </ng-container>
    </ng-container>
  </div>
  <p *ngIf="accountNumber">Conta: {{accountNumber}} - {{ accountName | titlecase }}</p>
</div>
