<div>
    <fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
    <div class="main-selector">
        <fibra-company-list></fibra-company-list>
    </div>
    <div class="content-page-d" id="content-body body-content" id="body-scroll">
        <div class="body-content">
            <div class="filter-list">
                <fibra-filter 
                    [approval]="false" 
                    [approvalTransaction]="approvalTransaction"
                    (emitFilter)="setFilter($event)" 
                    (emitFilterToast)="verifyCalendar($event)" 
                    [inputFilter]="false"
                    [default]="false"
                    [isSchedules]="isSchedules"
                    [minDayCalendar]="90">
                </fibra-filter>
            </div>

            <div class="wraper-list-value">
                <header class="wraper-list-header">
                    <div class="items">
                        <div class="col-pagador">
                            <span class="title-list">Nome Pagador</span>
                            <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('nomePagador', listArray)" />
                        </div>
                        <div class="col-date">
                            <span class="title-list">Data pagamento</span>
                            <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('dataPagamento', listArray)" />
                        </div>
                        <div class="col-transaction">
                            <span class="title-list">CNPJ/CPF</span>
                            <img src="/assets/svg/duo_arrow.svg" alt=""
                                (click)="util.sortList('cpfcnpjPagador', listArray)" />
                        </div>
                        <div class="col-account">
                            <span class="title-list">Instituição</span>
                            <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('bancoPagador', listArray)" />
                        </div>
                        <div class="col-cnpj">
                            <span class="title-list">Dados da Conta</span>
                            <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('numeroContaPagador', listArray)" />
                        </div>

                        <div class="col-status">
                            <span class="title-list">Valor</span>
                            <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('valorTransferencia', listArray)" />
                        </div>
                        
                    </div>
                </header>
                <div class="list-value">
                    <div class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)"
                        *ngIf="loading || filterComp?.loading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="empty-info paddingTopGeneric" *ngIf="hide">
                        <fibra-generic-info type="warn" title="{{ messageNinetyDay  ? 'Você não possui transações' : 'Nenhum resultado encontrado.' }}" title2="{{ messageNinetyDay ? 'disponíveis para devolução.' : ''}}">
                        </fibra-generic-info>
                    </div>
                    <div>
                        <div class="panel-group" id="accordion">
                            <div class="list-content" *ngFor="let pixDev of listArray; let i = index">
                                <div class="panel-list">
                                    <div class="col-pagador">
                                        <span>
                                            {{pixDev.nomePagador }}
                                        </span>
                                    </div>
                                    
                                    <div class="col-date">
                                        <span>
                                            {{pixDev.dataPagamento | date: 'dd/MM/yyyy'}}
                                        </span>
                                    </div>
                                    <div class="col-transaction">
                                        <span class="title-desc">{{ pixDev.cpfcnpjPagadorMascarado }}</span>
                                    </div>
                                    <div class="col-account">
                                        <span>
                                            {{pixDev.bancoPagador || 'Banco Fibra'}}
                                        </span>
                                    </div>
                                    <div class="col-cnpj">
                                        <span>
                                            AG: {{  pixDev.numeroAgenciaPagadorMascarado }} | CC: {{ pixDev.numeroContaPagadorMascarado | account}}
                                        </span>
                                    </div>

                                    <div class="col-status valueAndAction">
                                        <div *ngIf="pixDev.bloqueioCautelar">
                                            <span class="icon-info notification" [appTooltip]="tooltipAll">
                                                <span class="color-precautionary-block">
                                                    <img class="scheduledIcon" src="../../../../assets/png/icon-pending.png" alt="aviso" />
                                                     {{pixDev.valorTransferencia | currency : 'R$'}}
                                                </span>
                                              <div #tooltipAll [ngClass]="
                                                  i <= 2
                                                    ? 'tooltip-refused-down'
                                                    : 'tooltip-refused'
                                                ">
                                                <p>Transação bloqueada</p>
                                                <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                                              </div>
                                            </span>
                                          </div>

                                        <span *ngIf="!pixDev.bloqueioCautelar" class="color-no-precautionary-block">
                                            {{pixDev.valorTransferencia | currency : 'R$'}}
                                        </span>
                                        <div class="item-action">
                                            <img class="refund" src="assets/svg/arrow-curverd-left.svg" alt="Devolver" (click)="nextScreenRefund(listArray[i])" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div #anchor><!-- QUANDO ESTE ELEMENTO ENTRA EM EXIBIÇÃO NA VIEWPORT O SCROLL É CHAMADO --></div>

                            <!-- CARREGANDO DADOS -->
                            <div class="list-content" *ngIf="loadDataGrid">
                                <div class="panel-list">  
                                    <div class="load-date">
                                        <span>
                                            Carregando dados...
                                        </span>
                                    </div>
                                </div>
                            </div>                         

                            <!-- FIM DOS RESULTADODS -->
                            <div class="list-content" *ngIf="noDataShow">
                                <div class="panel-list">  
                                    <div class="load-date">
                                        <span>
                                            Não existem mais dados a serem exibidos.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>