import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PfGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.userService.isPf()){
        return true;
      }
      else {
        this.router.navigate(['/dashboard']);
        return false;
      }
      
      // return new Observable<boolean>(obs => {
      //   this.company.dataShared.subscribe((res) => {
      //     if (res !== undefined && isArray(res.companyList)) {
      //       let e = res.companyList.filter((e) => e.selected)[0];
      //       console.log("GuardPf:", e);
      //       if(e.tipo_pessoa === 'PF'){
      //         console.log("OK");
      //         obs.next(true);
      //       }
      //       else{
      //         console.log("NOK");
      //         obs.next(false);
      //         this.router.navigate(['/dashboard']);
      //       }
      //     }
      //   });
      // });
  }
  
}
