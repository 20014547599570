export enum StatusApprovalEnum {
  Pendant = "PENDENTE",
  Approved = "APROVADO",
  Reproved = "REPROVADO",
  Canceled = "CANCELADO"
}

export enum StatusTipoValidacaoDdaEnum {
  Adesao = 1,
  Cancelamento = 2,
  InclusaoFilial = 3,
  ExclusaoFilial = 4,
}

export enum StatusApprovalChangeEnum {
  Pendant = 1,
  Approved = 2,
  Reproved = 3,
  Canceled = 4,
  InValidationRFB = 5,
  RFBReturn = 6,
  FailRFB = 7,
  Adhesion = 8,
  PendantInclude = 9,
  PendantExclude = 10,
  PendentAdhered = 11,
  Adhered = 12,
  PendentCancel = 13,
  ErrorRFB = 14
}

export enum DdaPermissoes {
  
  ConsultarBoletos = "DDA.DDA_CONS_BOL",
  RealizarPagamento = "DDA.DDA_REALIZA_PAG_BOL",
  GerarArquivoCNBA = "DDA.DDA_GER_ARQ_CNAB",
  ConsultarHistorico = "DDA.DDA_CONS_HIST"
}

export enum DdaStatusProcesso {
  NaoAderido = "NAO_ADERIDO",
  Aderido = "ADERIDO",
  AdesaoPendente = "ADESAO_PENDENTE",
  AdesaoEmProcesso = "ADESAO_EM_PROCESSAMENTO",
  CancelamentoPendente = "CANCELAMENTO_PENDENTE",
  CancelamentoEmProcesso = "CANCELAMENTO_EM_PROCESSAMENTO"
}

export enum DdaStatusProcessoFilial {
  EmProcessoAdesao = "PROCESSO_ADESAO",
  CNPJemValidacao = "CNPJ_EM_VALIDACAO",
  AguardandoAprovacaoMaster = "AGUARDANDO_APROVACAO_MASTER",
  AguardandoAprovacaoCancelamento = "AGUARDANDO_PROCESSO_CANCELAMENTO",
  EmProcessamento = "EM_PROCESSAMENTO"
}