import { BeneficiariesModel } from './../../../domain/transfer/transfer.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { PurposesRepository } from '@src/core/repositories/transfer/purposes.repository';

@Injectable({
  providedIn: 'root',
})
export class GetPurposesUsecase implements UseCase<any, BeneficiariesModel> {
  constructor(private purposesRepository: PurposesRepository) {}

  execute(): Observable<BeneficiariesModel> {
    return this.purposesRepository.getAllPurposes();
  }
}
