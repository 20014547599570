import { Mapper } from "@src/core/base/mapper";
import { EspecieModel } from "@src/core/domain/charge/especie.model";
import { EspecieEntity } from "./especie.entity";

export class EspecieRepositoryMapper extends Mapper<
  EspecieEntity,
  EspecieModel
> {
  mapFrom(param: EspecieEntity): EspecieModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: EspecieModel): EspecieEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
