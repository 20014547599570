import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as Util from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { ToastFunctionalityAccessComponent } from '@src/presentation/web/components/toast-functionality-access/toast-functionality-access.component';
import { RolesService } from '@src/shared/services/roles.service';
import { Router } from '@angular/router';

@Component({
  selector: 'fibra-quick-access-go-along',
  templateUrl: './quick-access-go-along.component.html',
  styleUrls: ['./quick-access-go-along.component.scss'],
})
export class QuickAccessGoAlongComponent implements OnChanges, OnInit {
  @Input() dataAccompaniment;
  @Input() showData;
  public hideCard: boolean;
  public hideAccompaniment: boolean;
  public hideWarranty: boolean;
  public showCard: boolean;

  constructor(
    private rolesService: RolesService,
    private router: Router
  ) { }
  @ViewChild(ToastFunctionalityAccessComponent, null)
  toast: ToastFunctionalityAccessComponent;

  ngOnInit(): void {
    this.verifyData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.verifyData();
  }

  public verifyData() {
    this.hideAccompaniment = !this.dataAccompaniment;

  }

  moreInfo(type) {
    if (type === 'charge') {
      if(this.rolesService.get().includes('COB.CONS_POS_CART') ||
        this.rolesService.get().includes('COB.CONS_ARQ_TRANS') ||
        this.rolesService.get().includes('COB.CONS_INST_ADIC') ||
        this.rolesService.get().includes('COB.CONS_FRANC') ||
        this.rolesService.get().includes('COB.CONS_ARQ_REM') ||
        this.rolesService.get().includes(' COB.CONS_ARQ_RET')
      ) {
        this.router.navigate(['/query'])
      } else {
        this.toast.callModalMessage(null, 'Para ter acesso as funcionalidades, solicite acesso ao(s) usuário(s) master(s) da sua empresa');
      }

    }
    if (type === 'Guarantee') {
      if (this.rolesService.get().includes('OP_CRED.CONS_OP_CRED')) {
        this.router.navigate(['/loans'])
      } else {
        this.toast.callModalMessage(null, 'Para ter acesso as funcionalidades, solicite acesso ao(s) usuário(s) master(s) da sua empresa', false, true);
      }

    }
  }

}


