import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'fibra-toast-error-message',
  templateUrl: './toast-error-message.component.html',
  styleUrls: ['./toast-error-message.component.scss'],
})
export class ToastErrorMessageComponent implements OnInit {


  @Input() color;
  @ViewChild('rootdiv', null) rootDiv: ElementRef;
  @Output() optionYes: EventEmitter<any> = new EventEmitter();
  @Output() optionNo: EventEmitter<any> = new EventEmitter();

  public dataMsgTransaction: any;

  constructor() {
    this.dataMsgTransaction = {
      title: null,
      msg: null,
      showButtonClose: null,
      showButtonOptions: null,
    };
  }

  ngOnInit() {
    this.color = !this.color ? '#ed6c6c' : this.color;
  }

  public callModalMessage(
    value,
    title?: string,
    msg?: string,
    buttonOption?: boolean,
    buttonClose?: boolean
  ) {
    if (!value) {
      this.dataMsgTransaction.showButtonClose = true;
      this.dataMsgTransaction.title = title;
      this.dataMsgTransaction.msg = msg;
      this.dataMsgTransaction.showButtonOptions = buttonOption;
      this.dataMsgTransaction.showButtonClose = buttonClose;
    }
    switch (value) {
      case 'arquivo-cnab-invalido':
        this.dataMsgTransaction.title = 'Formato inválido';
        this.dataMsgTransaction.msg = 'Selecione algum arquivo no formato: TXT, CNAB, REM, XLS, XLSX.';
        this.dataMsgTransaction.showButtonClose = true;
        this.dataMsgTransaction.showButtonOptions = false;
        break;
      case 'saldo':
        this.dataMsgTransaction.title = 'Saldo insuficiente';
        this.dataMsgTransaction.msg = 'Selecione outra conta.';
        this.dataMsgTransaction.showButtonClose = true;
        this.dataMsgTransaction.showButtonOptions = false;
        break;
      case 'retorno':
        this.dataMsgTransaction.title =
          'Ao voltar você perderá tudo o que foi preenchido.';
        this.dataMsgTransaction.msg = 'Deseja mesmo voltar?';
        this.dataMsgTransaction.showButtonClose = false;
        this.dataMsgTransaction.showButtonOptions = true;
        break;
      case 'cancelarLimitePix':
        this.dataMsgTransaction.title =
          'Ao cancelar você perderá tudo que foi preenchido.';
        this.dataMsgTransaction.msg = 'Deseja mesmo voltar?';
        this.dataMsgTransaction.showButtonClose = false;
        this.dataMsgTransaction.showButtonOptions = true;
        break;
      case 'quantidade':
        this.dataMsgTransaction.title =
          'Limite de transação por CPF/CNPJ excedido.';
        this.dataMsgTransaction.msg =
          'Selecione outra empresa no topo da página.';
        this.dataMsgTransaction.showButtonClose = true;
        this.dataMsgTransaction.showButtonOptions = false;
        break;
      case 'transacaoAp':
        this.dataMsgTransaction.title =
          'Ao cancelar você perderá tudo que foi preenchido.';
        this.dataMsgTransaction.msg = 'Deseja mesmo voltar?';
        this.dataMsgTransaction.showButtonClose = false;
        this.dataMsgTransaction.showButtonOptions = true;
        break;
    }

    this.rootDiv.nativeElement.classList.add('show');
    if (!this.dataMsgTransaction.showButtonOptions && !buttonClose) {
      setTimeout(() => {
        this.closeModal();
      }, 6000);
    }
  }
  public closeModal() {
    this.dataMsgTransaction.title = null;
    this.dataMsgTransaction.msg = null;
    this.dataMsgTransaction.showButtonClose = null;
    this.dataMsgTransaction.showButtonOptions = null;

    this.rootDiv.nativeElement.classList.remove('show');
  }

  public dismissToast = () => {
    this.rootDiv.nativeElement.classList.remove('show');
  }

  public clickYes() {
    this.optionYes.emit(true);
    this.dismissToast();
    this.closeModal();
  }

  public clickNo() {
    this.optionNo.emit(true);
    this.dismissToast();
    this.closeModal();
  }
}
