import { DdaBoletoModel } from "@src/core/domain/dda/dda-boleto.model";
import { DdaBoletoConsultaModel } from "@src/core/domain/dda/dda-consulta-boleto.model";
import { DdaSinglePaymentModel } from "@src/core/domain/dda/dda-single-payment.model";
import { Observable } from "rxjs";
import { PaymentModel } from '@src/core/domain/payment/payment.model';

export abstract class DdaBoletoRepository {
  abstract get(
    param: DdaBoletoConsultaModel
  ): Observable<Array<DdaBoletoModel>>;
  abstract batchPayment(): void;
  abstract singlePayment(param: DdaSinglePaymentModel): void;
  abstract export(param: string[]): void;
  abstract exportarXlsx(request: string[]): Observable<PaymentModel<string>>;
  abstract exportarCNAB(request: string[]): Observable<PaymentModel<string>>;
}
