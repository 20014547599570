import { BalancesEntity } from '@src/data/repository/balances-repository/balances-entity';
import { BalancesModel } from '@src/core/domain/balance/balances.model';
import { Mapper } from '@src/core/base/mapper';

export class BalancesRepositoryMapper extends Mapper <BalancesEntity, BalancesModel> {
  mapFrom(param: BalancesEntity): BalancesModel {
    return {
     code: param.code,
      status: param.status,
      data: {
        cheque_value : param.data.vlr_saldo_cheque,
        balance_value : param.data.vlr_saldo,
        limit_value : param.data.vlr_limite,
        block_value : param.data.vlr_bloqueado,
        cheque_used : param.data.vlr_cheque_utilizado,
        cheque_available : param.data.vlr_cheque_disponivel,
      },
    };
  }

  mapTo(param: BalancesModel): BalancesEntity {
    return {
      code: param.code,
      status: param.status,
      data: {
        vlr_saldo_cheque: param.data.cheque_value,
        vlr_saldo: param.data.balance_value,
        vlr_limite: param.data.limit_value,
        vlr_bloqueado: param.data.block_value,
        vlr_cheque_utilizado: param.data.cheque_used,
        vlr_cheque_disponivel: param.data.cheque_available,
      },
    };
  }
}
