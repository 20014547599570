import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RolesService } from "@src/shared/services/roles.service";

const roleSegundaViaBoleto: string[] = [
  "COB.GER_SEG_BOL",
  "QUALQUER_CONTA.COB.GER_SEG_BOL",
];

@Component({
  selector: "fibra-modal-conclusao-titulo",
  templateUrl: "./modal-conclusao-titulo.component.html",
  styleUrls: ["./modal-conclusao-titulo.component.scss"],
})
export class ModalConclusaoTitulo implements OnInit {
  @ViewChild("content", { static: true }) content: HTMLElement;
  @Output() closeEvent: EventEmitter<any>;
  @Output() showMsgError: EventEmitter<any>;
  @Input() showDownload: boolean = false;
  @Input() base64Pdf: string = null;

  hasRoleSegViaBoleto: boolean = false;

  countError: number = 0;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private roleService: RolesService
  ) {
    this.closeEvent = new EventEmitter();
    this.showMsgError = new EventEmitter();
  }

  ngOnInit() {
    this.checkPermission2via();
    this.openModal(this.content);
  }

  checkPermission2via(): void {
    this.roleService.getRoles().forEach((item) => {
      if (roleSegundaViaBoleto.find((x: string) => x === item.Name))
        this.hasRoleSegViaBoleto = true;
    });
  }

  openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "modal-xxl",
      backdrop: "static",
    });
  }

  close(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/dashboard"]);
  }

  download(): void {
    this.openBase64NewTab();
  }

  redirect(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/duplicate-bill"]);
  }

  newCharge(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/charge"]);
  }

  openBase64NewTab(): void {
    try {
      const byteArray = new Uint8Array(
        atob(this.base64Pdf)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    } catch {
      this.countError++;
      this.showMsgError.emit();
    }
  }

  base64toBlob(base64Data: string) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }
}
