import { Component, OnInit } from '@angular/core';
import * as Util from '@src/shared/util-common';

@Component({
  selector: 'fibra-download-derivative-list',
  templateUrl: './download-derivative-list.component.html',
  styleUrls: ['./download-derivative-list.component.scss'],
})
export class DownloadDerivativeListComponent implements OnInit {
  removeMask = (value) => Util.removeMask(value);
  public generatePDF = (value?) => Util.generateDerivativePDF(value);
  public data;
  public approvalList;
  public accountType;
  public tabsType;
  public date;
  public hour;
  public diffDateDays;

  constructor() {}

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('derivative-export-data'));
    this.approvalList = this.data.data;
    this.getDate();
  }

  ngAfterViewInit() {
    this.generatePDF('body-content');
    localStorage.removeItem('derivative-export-data');
  }

  getDate() {
    const auxDate = new Date();
    this.date = Intl.DateTimeFormat('pt-BR').format(auxDate);
    this.hour = auxDate.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
  }
}
