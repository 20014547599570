import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";
import { DdaCallBackEnum } from "@src/core/domain/dda/dda-callback.enum";
import { DdaStatusProcesso, StatusApprovalEnum } from "@src/core/domain/dda/dda-type-approval.enum";
import { DdaTermCompanyApprovalModel, PersonModel } from "@src/core/domain/dda/term-company-approval.model";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { DdaTermApprovalEntity } from "@src/data/repository/dda/entity/term/dda-term-approval.entity";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";
import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";
import * as moment from "moment";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";
import { environment } from "@src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "fibra-aprovacao-termo",
  templateUrl: "./aprovacao-termo.component.html",
  styleUrls: ["../../../pages/dda/dda.scss", "./aprovacao-termo.component.scss"],
  providers: [CpfCnpjPipe, TermImplementationRepository],
})
export class AprovacaoTermoComponent implements OnInit {

  @Input() waitApproval: boolean = false; //ADESAO STATUS PENDENTE
  @Input() waitApprovalCancel: boolean = false; //CANCELAMENTO ADESAO STATUS (GERAL)
  @Input() cancelInProgress: boolean = false; //CANCELAMENTO ADESAO PENDENTE DE APROVAÇÃO DE MASTER (USUÁRIO LOGADO)
  @Input() showUndoCancel: boolean = false; //HABILITAR OPÇÃO DE DESFAZER (MANTER) PARA USUÁRIO LOGADO
  @Input() showMainData: boolean = false;
  waitCancelInCipProgress: boolean = false;

  isDesfazerCancelamento: boolean = false;
  showDetail = true;

  imgStatus: string = '';
  descStatus: string = '';

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  callBackType = DdaCallBackEnum;
  statusApproval = StatusApprovalEnum;

  empresa: any;
  filiais: DdaBranchModel[] = [];
  enableAction: boolean = false;
  showDisclaimer: boolean = false;
  totalMasters: number = 0;
  adhesionInLegado: boolean = false;

  apiCallback: number = DdaCallBackEnum.AprovarTermo;
  exibirAutorizacao: boolean = false;
  exibirConclusao: boolean = false;
  apiPayload: DdaTermApprovalEntity = {
    id: 0,
    termo_aceite_id: 0,
    ds_status: StatusApprovalEnum.Approved,
    dt_aprovacao: moment().format("YYYY-MM-DDTHH:mm:ss"),
    aprovacao_substituida: "",
    filiais: []
  };

  typeColorToast = "#ED6C6C";

  statusAprovacao: string = "";
  exibirDadosAprovacao: boolean = false;

  descricaoAprovacao: string = "";

  criadoPor: string = "";
  dataCriacao: string = "";
  horaCriacao: string = "";
  termoAceiteId: number = 0;


  aprovadores: PersonModel[];
  aprovadoresCancelamento: PersonModel[];

  adesaoConcluida: boolean = false;
  adesaoPendente: boolean = false;
  adesaoEmProcesso: boolean = false;
  cancelamentoPendente: boolean = false;
  cancelamentEmProcesso: boolean = false;

  statusAdesao : DdaStatusProcesso = DdaStatusProcesso.Aderido;

  isDebug : boolean = environment.production;


  constructor(
    private termRepository: TermImplementationRepository,
    public ddaTermDataShare: DdaTermDataShare,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.obterDadosEmpresa();
    this.prepararPayload();
    this.obterDadosFiliais();
    this.checkStatusAdesao();
    this.changeDetector.detectChanges();
  }
  
  ngAfterContentChecked() : void {
    //this.changeDetector.detectChanges();
    this.checkStatusAdesao();
  }


  /**
   * [Adesão pendente]
   * this.waitApproval = result.status === StatusApprovalEnum.Pendant; = TRUE (2 PENDENTE)
   * this.waitCancelInCipProgress = (result.status === StatusApprovalEnum.Approved) = TRUE (3 APROVADO)
   * 
   * [Cancelamento em progresso]
   * this.waitApproval = result.status === StatusApprovalEnum.Pendant; = TRUE (2 PENDENTE)
   * this.waitCancelInCipProgress = (result.status === StatusApprovalEnum.Approved) = TRUE (3 APROVADO)
   * this.adhesionInLegado = TRUE
   * 
   * [Cancelamento pendente]
   * this.waitApproval = result.status === StatusApprovalEnum.Approved; = TRUE (2 APROVADO)
   * this.waitApprovalCancel = result.status === StatusApprovalEnum.Pendant; TRUE (3 PENDENTE)
   * this.adhesionInLegado = TRUE
   * 
   * [Aderido]
   * 2 - APROVADO
   * 3 - CANCELADO
   * 
   * [Cancelamento realizado]
   * 2 - CANCELADO
   * 3 - APROVADO
   * 
   */
  checkStatusAdesao() {

    this.adesaoPendente = !this.adhesionInLegado && this.waitApproval && this.aprovadores!=null && this.aprovadores.length > 0 && !this.waitCancelInCipProgress && !this.waitApprovalCancel;
    this.adesaoEmProcesso = !this.adhesionInLegado && !this.waitApproval && this.aprovadores!=null && this.aprovadores.length == 0 && !this.waitCancelInCipProgress && !this.waitApprovalCancel;
    this.adesaoConcluida = this.adhesionInLegado && !this.waitApproval && !this.waitCancelInCipProgress && !this.waitApprovalCancel;
    this.cancelamentoPendente = this.adhesionInLegado && !this.waitApproval && !this.waitCancelInCipProgress && this.waitApprovalCancel && this.aprovadoresCancelamento!=null && this.aprovadoresCancelamento.length > 0;
    this.cancelamentEmProcesso = this.adhesionInLegado && !this.waitApprovalCancel && this.waitCancelInCipProgress && this.waitApproval && this.aprovadoresCancelamento!=null && this.aprovadoresCancelamento.length == 0;

    //ADESAO PENDENTE
    if(this.adesaoPendente){
      this.imgStatus = 'bullet_yellow';
      this.descStatus = 'Adesão pendente';
      this.statusAdesao = DdaStatusProcesso.AdesaoPendente;
    }

        //ADESAO EM PROCESSAMENTO
    if(this.adesaoEmProcesso){
      this.imgStatus = 'bullet_yellow';
      this.descStatus = 'Adesão pendente';
      this.statusAdesao = DdaStatusProcesso.AdesaoEmProcesso;
    }

    //Aderido - 2 APROVADO | 3 CANCELADO
    if(this.adesaoConcluida){
      this.imgStatus = 'bullet_green';
      this.descStatus = 'Aderido';
      this.adesaoConcluida = true;
      this.statusAdesao = DdaStatusProcesso.Aderido;
    }

    //Cancelamento pendente     - 2 APROVADO | 3 PENDENTE = master > 1
    //Cancelamento em progresso - 2 PENDENTE | 3 APROVADO | adhesionInLegado = true
    if(this.cancelamentoPendente){
      this.imgStatus = 'bullet_yellow';
      this.descStatus = 'Cancelamento pendente';
      this.isDesfazerCancelamento = true;
      this.statusAdesao = DdaStatusProcesso.CancelamentoPendente;
    }

    //Cancelamento me progresso
    if(this.cancelamentEmProcesso){
      this.imgStatus = 'bullet_yellow';
      this.descStatus = 'Cancelamento pendente';
      this.isDesfazerCancelamento = true;
      this.statusAdesao = DdaStatusProcesso.CancelamentoEmProcesso;
    }
  }

  prepararPayload() {
    this.ddaTermDataShare
      .getStatusTerm()
      .subscribe((result: DdaTermCompanyApprovalModel) => {
        if (result != null && result!=undefined) {
          this.termoAceiteId = result.id;
          this.apiPayload.termo_aceite_id = this.termoAceiteId;

          this.waitApproval = result.status === StatusApprovalEnum.Pendant;
          this.getLegacyMembership(false);

          this.aprovadores = result.aprovacoes.filter(x => x.status === StatusApprovalEnum.Pendant).map(aprovador => aprovador.pessoa[0]);

          try {
            this.apiPayload.id = result.aprovacoes.find(x =>
                x.pessoa[0].email === localStorage.getItem("useremail") &&
                x.status === StatusApprovalEnum.Pendant
            ).id;
          } catch (err) {}

          this.apiPayload.aprovacao_substituida = null;

          this.apiPayload.dt_aprovacao = moment().format("YYYY-MM-DDTHH:mm:ss");
          this.statusAprovacao = result.status;

          this.criadoPor = result.aprovacoes.find(
            (x) =>
              moment(x.dt_criacao).format("YYYY-MM-DDTHH:mm") ===
                moment(result.dt_criacao).format("YYYY-MM-DDTHH:mm") &&
              x.status === StatusApprovalEnum.Approved
          ).pessoa[0].nome_pessoa;

          let notApproved = result.aprovacoes.filter(
            (x) =>
              moment(x.dt_criacao).format("YYYY-MM-DDTHH:mm") ===
                moment(result.dt_criacao).format("YYYY-MM-DDTHH:mm") &&
              x.status === StatusApprovalEnum.Pendant
          );

          this.totalMasters =
            notApproved === undefined ? 0 : notApproved.length;

          this.exibirDadosAprovacao =
            result.aprovacoes.find(
              (x) =>
                x.pessoa[0].email === localStorage.getItem("useremail") &&
                x.status === StatusApprovalEnum.Pendant
            ) !== undefined;

          this.dataCriacao = result.dt_criacao;
          this.horaCriacao = moment(result.dt_criacao).format("HH:mm");
        }
      });

    //PENDENTE DE APROVAÇÃO DO CANCELAMENTO
    this.ddaTermDataShare.getStatusTermCancel().subscribe((result: DdaTermCompanyApprovalModel) => {
      if (result !== null && result!=undefined) {
        this.getLegacyMembership(false);
        this.aprovadoresCancelamento = result.aprovacoes.filter(x => x.status === StatusApprovalEnum.Pendant).map(aprovador => aprovador.pessoa[0]);
        this.waitApprovalCancel = result.status === StatusApprovalEnum.Pendant;

        //CANCELAMENTO EM PROGRESSO = CANCELAMENTO APROVADO + STATUS ADESÃO PENDENTE + TODOS OS APROVADORES DA ADESÃO COM STATUS APROVADO
        this.waitCancelInCipProgress = (result.status === StatusApprovalEnum.Approved && (this.waitApproval && this.aprovadores.length == 0));
      }});
  }

  obterDadosFiliais(): void {
    this.termRepository
      .getBranch(this.termoAceiteId)
      .subscribe((result: any) => {
        this.filiais = result;
      });
  }

  obterDadosEmpresa(): void {
    this.empresa = JSON.parse(sessionStorage.getItem("defaultCompany"))[0];
  }

  reprovarTermo(): void {
    this.apiPayload.ds_status = StatusApprovalEnum.Reproved;
    this.exibirAutorizacao = true;
  }

  getInscricao(filial: DdaBranchModel): string {
    return `${filial.cnpjInscricao}${filial.identificacaoFilial}`;
  }

  enable(): void {
    this.enableAction = !this.enableAction;
  }

  exibirMensagem(err: any): void {
    if (err.code !== 400) {
      this.toast.callModalMessage(null, err.message[0], null, null, null);
    } else {
      this.toast.callModalMessage(null, err, null, null, null);
    }
  }

  fecharModal(): void {
    this.exibirAutorizacao = false;
  }

  fecharModalConclusao(): void {
    this.exibirConclusao = false;
    //this.router.navigate(["dda-transacional"]);
  }

  respostaApi(value: any): void {
    this.exibirAutorizacao = false;
    if (value.status === "success") {
      this.exibirConclusao = true;
    } else {
      this.exibirConclusao = false;
      this.exibirMensagem(value.error);
    }
  }

  getLegacyMembership(filiais: boolean): void {
    this.termRepository
      .getLegacyMembership(filiais).subscribe(
        (result: any) => {
          if(result.data){
            this.adhesionInLegado = result.data.success;            
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  toogleElement(): void{
    this.showDetail = !this.showDetail;
  }
}
