import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

  // tslint:disable-next-line: max-line-length
  transform(value: number, currencyCode: string = 'BRL', symbolDisplay: boolean = true, digits?: string, showSimble: boolean= false): string {
    if (!value) {
      return '';
    }
    const currencyPipe: CurrencyPipe = new CurrencyPipe('pt-BR');
    let newValue: string = currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
    if (!showSimble) { newValue = newValue.substring(3); }
    return newValue;
  }
}
