import { Component, EventEmitter, Input, Output, OnChanges, OnDestroy, SimpleChanges, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getAllQueryFrancesinhaUsecase } from '@src/core/usecases/query/francesinha-list/get-all-query.usecase';
import { getAllQueryFrancesinhaResumoUsecase } from '@src/core/usecases/query/francesinha-list/get-all-query-resume.usecase';
import { FilterQueryDataShare, TabsDataShare } from '@src/data/repository/data-share-repository';
import { getOrderedDate, getOrderedPayer, getOrderedValue, isNull, verifyArray } from '@src/shared/util-common';
import * as Util from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fibra-query-francesinha-list',
  templateUrl: './francesinha-list.component.html',
  styleUrls: ['./francesinha-list.component.scss'],
})
export class FrancesinhaListComponent implements OnInit, OnDestroy {
  transit;
  filter = new FormControl('');
  getOrderedDate;
  getOrderedPayer;
  getOrderedValue;
  theaderOptions;
  @Input() params;
  show;
  loading;
  option;
  tabprevious = '';
  paramsAux:any;
  util = Util;
  @Output() queryDataExport = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  private subscriptions: Subscription[] = [];

  public resume: any;

  private ShowToast(value: any): void {
    const isMessageServer: boolean = !!(value && value.code && value.message && value.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, value.message[0], null, null, null);
  }

  constructor(
    private getAllFrancesinhaList: getAllQueryFrancesinhaUsecase,
    private getAllFrancesinhaResumoList: getAllQueryFrancesinhaResumoUsecase,
    private sharedFilter: FilterQueryDataShare,
    private shareTab: TabsDataShare
  ) {
    this.transit = [];
    this.loading = false;
    this.show = true;

    this.option = [
      { idOption: 1, option: 'Seu número', referenciaCampo: 'seu_numero' },
      { idOption: 2, option: 'Nosso número' , referenciaCampo: 'nosso_numero' },
      { idOption: 3, option: 'Pagador', referenciaCampo: 'nome_pagador' },
      { idOption: 4, option: 'Vencimento', referenciaCampo: 'dt_vencimento' },
      { idOption: 5, option: 'Referência', referenciaCampo: 'dt_referencia' },
      { idOption: 6, option: 'Lançado', referenciaCampo: 'dt_lancamento' },
      { idOption: 7, option: 'Valor do título', referenciaCampo: 'vlr_titulo' },
      { idOption: 8, option: 'Histórico', referenciaCampo: 'historico' },
      { idOption: 9, option: 'Desc/Abto', referenciaCampo: 'vlr_abatimento_desconto' },
      { idOption: 10, option: 'Encargos', referenciaCampo: 'vlr_encargos' },
      { idOption: 11, option: 'I.O.F', referenciaCampo: 'vlr_iof' },
      { idOption: 12, option: 'Tarifa', referenciaCampo: 'vlr_tarifa' },
      { idOption: 13, option: 'Valor líquido', referenciaCampo: 'vlr_liquido' },
    ]
  }

  ngOnInit(): void {
    this.initList('francesinha-list-all');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  private initList(tab) {
    this.subscriptions.push(this.sharedFilter.dataShared.subscribe((res) => {
      this.transit = [];
      this.show = false;
      this.loading = true;
      setTimeout(() => {
        this.switList(tab, res);
        // this.loading = false;
      }, 300);
    }));
  }

  private switList(list, param?) {
    if (list && list === 'francesinha-list-all' && (this.tabprevious !== list || this.paramsAux != param)) {

        if (!param.dt_cadastro) {
          param.dt_cadastro = new Date();
        }

        this.requestQueryListAll(param),
        // this.requestQueryListAllResumo(param)
        this.paramsAux = param;
        this.tabprevious = list; 
    }
  }

  private requestQueryListAll(param?) {
    if (param) {
      this.loading = true;
      this.subscriptions.push(this.getAllFrancesinhaList
        .execute(param)
        .subscribe(value => {
          this.transit = verifyArray(value.data);
          this.queryDataExport.emit(this.transit);
          if (this.transit && this.transit.length > 0) {
            this.requestQueryListAllResumo(param);
            this.show = false;
          }
          else {
            this.show = true;
            this.resume = null;
          }
          this.loading = false;

        },

          this.requestError,
        ));
    }
  }

  private requestQueryListAllResumo(param?) {  
    this.resume = null    
    if (param) {
      this.getAllFrancesinhaResumoList
        .execute(param)
        .subscribe(value => {
          
          this.resume = value.data;
          let transitExport: any = {tableData: this.transit, resume: this.resume }
          this.queryDataExport.emit(transitExport);
        },
          this.requestError,
        );
    }
  }

  // private requestSuccess = (value) => {
  //   this.transit = Util.verifyArray(value.data);
  //   if (this.transit && this.transit.length > 0) {
  //     this.show = false;
  //   }
  //   else {
  //     this.show = true;
  //   }
  //   this.loading = false;
  // };

  private requestError = (err) => {
    this.queryDataExport.emit(this.transit);
    this.ShowToast(err);
    this.transit = [];
    this.show = true;
    this.loading = false;
    this.resume = null;
  };

  isNull = (value) => {
    return Util.isNull(value);
  };

  ordered(value) {
    // switch (value) {
    //   case 'date':
    //     return (this.getOrderedDate = getOrderedDate(this.transit));
    //   case 'payer':
    //     return (this.getOrderedPayer = getOrderedPayer(this.transit));
    //   case 'value':
    //     return (this.getOrderedValue = getOrderedValue(this.transit));
    // }
  }

  public ordenar(coluna: string) {
    Util.sortList(coluna, this.transit);
  }
}