import { BankDataShare } from './../../../../core/usecases/data-share/bank-data-share.service';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  PipeTransform,
  OnInit,
  ViewChild
} from '@angular/core';
import { PixBeneficiariesEntity, ChavesBeneficiarioEntity } from '@src/data/repository/pix/beneficiaries/pix-beneficiaries.entity';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import * as Util from '@src/shared/util-common';
import { PixBeneficiarioFavoritoDataShare } from '@src/core/usecases/data-share/pix-beneficiario-favorito-data-share.service';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { RolesService } from '@src/shared/services/roles.service';

@Component({
  selector: 'fibra-pix-beneficiaries-list',
  templateUrl: './pix-beneficiaries-list.component.html',
  styleUrls: ['./pix-beneficiaries-list.component.scss'],
})
export class PixBeneficiariesListComponent implements OnInit {
  private _filter: string;
  @Input()
  set filter(value: string) {
    this._filter = value;
    if (value != undefined) {
      // call your stuff
      this.BuscaBeneficiario(value);
    }
  }
  get filter() {
    return this._filter;
  }
  public count;
  public enable = false;
  public serviceData: PixBeneficiariesEntity[];
  public data;
  public dataBenef;
  public chavesData: ChavesBeneficiarioEntity[] = [];
  public contasData: ChavesBeneficiarioEntity[] = [];
  public loading: boolean = true;
  public hide: boolean = false;
  public benefVazio: boolean = false;
  public benefErro: boolean = false;
  public numContas = 0;
  public numChaves = 0;
  public search;
  public idBenefDelete;
  public idBenefChaveDelete;
  public flagDeleteChaves = false;
  public buscaBeneficiario: boolean = false;

  removeMask = (value) => Util.removeMask(value);

  constructor(
    public getBeneficiariesService: PixGetBeneficiariesDataRepository,
    public getBeneficiariesDataRepository: PixGetBeneficiariesDataRepository,
    public beneficiarioShare: PixBeneficiarioFavoritoDataShare,
    private roles: RolesService
    ){}
  
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit(): void {
    this.beneficiarioShare.deleteValue()
    this.getBeneficiariesAndFormat();
  }

  


  initialLetters(name) {
    name = name.replace(/\s(de|da|dos|das)\s/g, ' ');
    const initials = name.match(/\b(\w)/gi);
    return (
      initials[0] + (initials[1] !== undefined ? initials[1] : initials[0])
    ).toUpperCase();
  }

  public getBeneficiariesAndFormat() {
    this.loading = true;
    this.hide = false;
    this.data = new Array();
    this.dataBenef =  new Array();
    //CHAMADA DA API
    this.getBeneficiariesDataRepository.getPixBeneficiariosTodos().subscribe((benef) => {
      this.loading = false;
      this.serviceData = benef.data;
      this.serviceData.forEach((res) => {
        this.chavesData = [];
        this.contasData = [];
        //res.keyAndAccNum = res.chaves.length + res.contas.length;
        if (res.beneficiarioChaveBanco && res.beneficiarioChaveBanco.length === 0) {
          const dataShow = {
            data: res,
            outrasChaves: this.chavesData,
            contas: this.contasData
          }
          this.dataBenef.push(dataShow);
          this.data.push(dataShow);
        } else if (res.beneficiarioChaveBanco && res.beneficiarioChaveBanco.length > 0) {
          res.beneficiarioChaveBanco.forEach((element) =>{
            if(element.tipo_chave != 'AGENCIACONTA'){
              this.chavesData.push(element)
              this.numChaves++;
            }else{
              this.contasData.push(element)
              this.numContas++;
            }
          })
          const dataShow = {
            data: res,
            outrasChaves: this.chavesData,
            contas: this.contasData,
            numContas: this.numContas,
            numChaves: this.numChaves
          }
          this.dataBenef.push(dataShow);
          this.data.push(dataShow);
        }else{
          this.hide = true;
          this.benefVazio = true;
        }
        this.numContas = 0;
        this.numChaves = 0;
      })
    },(err) => {
      this.loading = false;
      if(err && err.error && err.error.status == 'success'){
        this.benefVazio = true;
        this.benefErro = false;
      }else{
        this.benefVazio = false;
        this.benefErro = true;
      }
    })
  }

  public returnType(key:number) {
    switch (key) {
      case 1:
        return 'Aleatorio';
      case 2:
        return 'CPF';
      case 3:
        return 'CNPJ';
      case 4:
        return 'E-MAIL';
      case 5:
        return 'Telefone';  
        
    }
  }

  public newBeneficiary(benef){
    const beneficiario = {
      beneficiarioInfo: benef.beneficiarioPix,
      chaveInfo: benef.beneficiarioChaveBanco[0],
      favorito: false,
      novaChave: true,
      outraTitularidade: benef.beneficiarioChaveBanco[0].cod_banco_beneficiario == 224 ? false : true
    }
    this.beneficiarioShare.setValue(beneficiario)
    localStorage.setItem('view-selecionada', 'transferir-newtransfer');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public openDetails(){

  }

  public selectBeneficiary(benef){
    const beneficiario = {
      beneficiarioInfo: benef.beneficiarioPix,
      chaveInfo: benef.beneficiarioChaveBanco[0],
      favorito: true,
      novaChave: false,
      outraTitularidade: benef.beneficiarioChaveBanco[0].cod_banco_beneficiario == 224 ? false : true
    }
    this.beneficiarioShare.setValue(beneficiario)
    if(benef.beneficiarioChaveBanco[0].tipo_chave != 'AGENCIACONTA'){
      localStorage.setItem('view-selecionada', 'transferir-newtransfer');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }else{
      //TODO ADICIONAR NO RETORNO DO BENEFICIARIO SE É OUTRA OU MESMA TITULARIDADE E VERIFICAR SE EH FIBRA OU OUTRO BANCO
      if(benef.beneficiarioChaveBanco[0].cod_banco_beneficiario == 224){
        localStorage.setItem('view-selecionada', 'transferir-transferFibra');
        window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
      }else{
        localStorage.setItem('view-selecionada', 'transferir-transferOutroBanco');
        window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
      }
    }
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  public DeleteBeneficiary(benef){
    this.idBenefDelete = benef.beneficiarioPix.id_beneficiario
    //this.toast.callModalMessage(null, benef.beneficiarioChaveBanco[0].chave == '' ? "Deseja excluir esse beneficiário?" : "Deseja excluir essa chave?" , null, true);
    this.toast.callModalMessage(null,"Deseja excluir esse beneficiário?", null, true);
  }

  public selectToasterFunction(){
    if(this.flagDeleteChaves){
      this.getBeneficiariesDataRepository.deletePixBeneficiariosChave(this.idBenefChaveDelete, this.idBenefDelete).subscribe((res) => {
        this.getBeneficiariesAndFormat();
      },(errDelete)=>{
        this.toast.callModalMessage(null, "Ocorreu um erro ao excluir o beneficiário. Tente novamente mais tarde.");
      })
      this.flagDeleteChaves = false;
    }else{
      this.getBeneficiariesDataRepository.deletePixBeneficiarios(this.idBenefDelete).subscribe((res) => {
        this.getBeneficiariesAndFormat();
      },(errDelete)=>{
        this.toast.callModalMessage(null, "Ocorreu um erro ao excluir o beneficiário. Tente novamente mais tarde.");
      })
    }
  }

  public optionNo = () => {};
  
  public DeleteBeneficiaryChave(benef){
    this.idBenefDelete = benef.id_beneficiario_pix;
    this.idBenefChaveDelete = benef.id_chave_banco;
    this.flagDeleteChaves = true;
    this.toast.callModalMessage(null, benef.chave == '' ? "Deseja excluir essa conta?" : "Deseja excluir essa chave?" , null, true);
  }

  public selectBeneficiaryChave(benef){
    var permissoes = this.roles.get();
    const beneficiario = {
      beneficiarioInfo:benef.benefInfo,
      chaveInfo: benef.chaveInfo,
      favorito: true,
      outraTitularidade: benef.chaveInfo.fl_outra_titularidade == 0 ? false : true
    }
    this.beneficiarioShare.setValue(beneficiario)
    if(benef.chaveInfo.tipo_chave != 'AGENCIACONTA'){
      localStorage.setItem('view-selecionada', 'transferir-newtransfer');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }else{
      //TODO ADICIONAR VERIFICAO DE PERMISSIONAMENTO
      if(benef.chaveInfo.cod_banco_beneficiario == 224){
        if(permissoes.includes('PIX.TRANSF_PIX_MSM_TIT') && benef.chaveInfo.fl_outra_titularidade == 0){
          localStorage.setItem('view-selecionada', 'transferir-transferFibra');
          window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
        }else if(permissoes.includes('PIX.TRANS_PIX_OTR_TIT') && benef.chaveInfo.fl_outra_titularidade == 1){
          localStorage.setItem('view-selecionada', 'transferir-transferFibra');
          window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
        }else{
          this.toast.callModalMessage(null, "Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.");
        }
      }else{
        if(permissoes.includes('PIX.TRANSF_PIX_MSM_TIT_BC') && benef.chaveInfo.fl_outra_titularidade == 0){
          localStorage.setItem('view-selecionada', 'transferir-transferOutroBanco');
          window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
        }else if(permissoes.includes('PIX.TRANSF_PIX_OTR_TIT_BC') && benef.chaveInfo.fl_outra_titularidade == 1){
          localStorage.setItem('view-selecionada', 'transferir-transferOutroBanco');
          window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
        }else{
          this.toast.callModalMessage(null, "Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.");
        }
      }
    }
  }
  public BuscaBeneficiario(value){
    if(this.dataBenef){
      this.data = this.dataBenef;
      if(value && value != ''){
        var filtrado = this.data.filter((benef) => {
          let result =
          benef.data.beneficiarioPix.nome_beneficiario.toLowerCase().includes(value.toLowerCase()) || 
          benef.data.beneficiarioChaveBanco.some(element => element.chave.includes(value.toLowerCase()))
          return result;
        })
        this.data = filtrado;
      }
      if(this.data && this.data.length == 0){
        this.buscaBeneficiario = true;
      }else{
        this.buscaBeneficiario = false;
      }
    }
  }
}
