<fibra-toast-error-message [color]="typToastColor"></fibra-toast-error-message>
<div class="main-selector">
    <fibra-company-list></fibra-company-list>
    <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div class="content-page" *fibraHasRole="'DER.CONS_DER'">
  <div class="div">
    <fibra-breadcrumb bottom="0" infoAccount="Produtos / Derivativos" roule="Derivativos" arrow="false" [downloadDoc]="true" [exportButtonPlaceholder]="'Baixar informações da tela'" (infoReport)="exportScreen($event)"></fibra-breadcrumb>


    <div class="content">
      <div class="group-items" [formGroup]="derivativeFormGroup" #derivativeForm="ngForm">
        <div class="period">
          <div class="label-period">Consultar pela data</div>
          <div class="group-period">
              <fibra-datepicker formControlName="derivativeDate" [minDate]="minDate" [maxDate]="maxDate" (dateSelected)="selectDate($event)" (dateInvalid)="dateInvalid($event)" placement="bottom"></fibra-datepicker>
              <div class="button-receipt">
                <button type="button" class="button-continue" (click)="consultar()" [disabled]="disableButton">
                  Consultar
                </button>
              </div>
          </div>
          <button
            type="button"
            class="button-produto-nota"
            (click)="downloadFile(notasPdf)"
            *ngIf="loadingNotas"
          >
            Baixar Notas
          </button>
          <span class="icon-alert" *ngIf="loadingNotas">
            <span
              class="icon-info"
              [appTooltip]="tooltipAll"
            >
              <img class="info-filter"src="/assets/png/icon-pending.png"/>
              <div #tooltipAll class="tooltip-custom">
                <p>As notas disponíveis para download são referente à data consultada.</p>
                <div></div>
              </div>
            </span>
          </span>
        </div>
      </div>

      <div class="icon-invalid-date" *ngIf="dataInvalida">
        <img class="info-invalid-date" src="/assets/png/iconErro.png" />
        <span class="invalid-date-text">
          Data inválida
        </span>
      </div>

      <div class="lado-a-lado">
        <div class="datatable" *ngIf="!loading && !hasError">
          <div class="table-title">
            <div class="table-item-vencimento">
                <span class="item-font">Data de Vencimento</span>
            </div>
            <div class="table-item">
                <span class="item-font">Contrato</span>
            </div>
            <div class="table-item">
                <span class="item-font-notional">Notional</span>
            </div>
            <div class="table-item">
                <span class="item-font-liquido">Valor Líquido</span>
            </div>
            <div class="table-item">
                <span class="item-font">Tipo de Movimentação</span>
            </div>
          </div>
          <div class="table-body" *ngIf="!noData">
            <div class="table-content" *ngFor="let item of derivative; let i = index">
              <div class="table-row">
                <div class="table-item-vencimento">
                    <span class="item-font">{{item.dt_vencto | date: 'dd/MM/yyyy'}}</span>
                </div>
                <div class="table-item-contrato">
                    <span class="item-font">{{item.num_contrato}}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-notional" *ngIf="item.moeda">{{item.moeda}} {{ item.valor_notionalME | number: '1.2-2' }}</span>
                </div>
                <div class="table-item">
                    <span class="item-font-liquido">R$ {{ item.valor_liquido | number: '1.2-2' }}</span>
                </div>
                <div class="table-item">
                    <span class="item-font" *ngIf="item.tipo == 'credito'">Crédito</span>
                    <span class="item-font" *ngIf="item.tipo == 'debito'">Débito</span>
                </div>
              </div>
            </div>
          </div>
          <div class="table-body" *ngIf="noData">
            <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Não há informações para serem apresentadas.">
            </fibra-generic-info>
          </div>
        </div>

        <div class="datatable warning-display" *ngIf="loading">
            <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div class="datatable warning-display" *ngIf="hasError && !loading">
          <div class="empty-info">

              <fibra-generic-info type="error" title="Ocorreu um erro inesperado" message="Não foi possível encontrar dados de movimentação!">
              </fibra-generic-info>
          </div>
        </div>

        <fibra-side-card-derivative *ngIf="!loading && !hasError"
          [options]="total" 
          [loading]="loading">
        </fibra-side-card-derivative>
      </div>
      <!-- <div class="download-notas" *ngIf="loadingNotas">
        <button
          type="button"
          class="button-produto-comod"
          (click)="downloadFileComod(notasPdfComod)"
          *ngIf="notasPdfComod && notasPdfComod.length > 0"
        >
          Baixar Nota NDF COMOD
        </button>
        <div *ngFor="let notas of notasPdf; let i = index">
          <button
            type="button"
            class="button-produto-nota"
            (click)="downloadFile(notas)"
            *ngIf="notas.produto != 'NDF COMOD'"
          >
            Baixar Nota {{notas.produto}}
          </button>
        </div>
      </div>
      <div class="footer-notas"  *ngIf="loadingNotas">
        *As notas disponíveis para download são referente à data consultada.
      </div> -->
    </div>
  </div>
</div>

<section class="body-content report" id="body-report" *ngIf="report" style="position: absolute;" >
    <fibra-derivative-report id="report" (closeReport)="changeReportView($event)" *fibraHasRole="'DER.CONS_DER'"></fibra-derivative-report>
</section>