import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ProductRepository } from "@src/core/repositories/product/product.repository";
import {
  ProductListModel,
  ProductListEntity,
  ProductListRequest,
  ContractRequest,
  ComprovanteModel,
  ComprovanteEntity,
} from "@src/core/domain/product/product.model";
import { ProductRepositoryMapper } from "@src/data/repository/product/product-repository.mapper";
import { ComprovanteRepositoryMapper } from "@src/data/repository/product/comprovante-repository.mapper";

// header
import { getHeader } from "@src/core/base/header";

// environment
import { environment } from "@src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductDataRepository extends ProductRepository {
  mapper = new ProductRepositoryMapper();
  mapperComprovante = new ComprovanteRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllProduct(): Observable<ProductListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<ProductListEntity>(
        `${environment.URLBase}v1/creditopreaprovado/produto`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  postSimulation(params: ProductListRequest): Observable<ProductListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<ProductListEntity>(
        `${environment.URLBase}v1/creditopreaprovado/simulacao`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  getParcelas(cod_solicitacao: number): Observable<ProductListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<ProductListEntity>(
        `${environment.URLBase}v1/creditopreaprovado/simulacao/${cod_solicitacao}/parcelas`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  getSolicitation(): Observable<ProductListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<ProductListEntity>(
        `${environment.URLBase}v1/creditopreaprovado/solicitacao`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  getComprovante(cod_solicitacao: number): Observable<ComprovanteModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<ComprovanteEntity>(
        `${environment.URLBase}v1/creditopreaprovado/solicitacao/comprovante/${cod_solicitacao}`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapperComprovante.mapFrom));
  }

  putContratacao(params: ContractRequest): Observable<ProductListModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<any>(
        `${environment.URLBase}/v1/Token/ValidarOTP`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}
