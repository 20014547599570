import { Observable } from 'rxjs';
import {
  francesinhaListModel,
  ListQueryRequest,
} from '@src/core/domain/query/query.model';

export abstract class FrancesinhaRepository {
  abstract getAllQueryFrancesinha(
    obj: ListQueryRequest
  ): Observable<francesinhaListModel>;
}

export abstract class FrancesinhaRepositoryResumo {
  abstract getAllQueryFrancesinhaResumo(
    obj: ListQueryRequest
  ): Observable<francesinhaListModel>;
}
