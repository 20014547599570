import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NoPermissionComponent } from './no-permission.component';

@NgModule({
    declarations: [
        NoPermissionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: '',
                component: NoPermissionComponent
            }
        ]),

    ],
    exports: [NoPermissionComponent],
})
export class NoPermissionModule { }