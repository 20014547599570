import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { setCompanyIndexUsecases } from '@src/core/usecases/company/set-companyIndex.usecase';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CompanySelectDataShare } from '@src/data/repository/data-share-repository';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { CompanyRequest } from '@src/core/domain/company/companyType.model';
import { CompanyTypeDataRepository } from '@src/data/repository/company/companyType.repository';

@Component({
  selector: "fibra-modal-default-company",
  templateUrl: "./modal-default-company.component.html",
  styleUrls: ["./modal-default-company.component.scss"],
})
export class ModalDefaultCompanyComponent implements OnInit {
  public selected: string;
  public showAskCompanyDefault: boolean;
  public index: number;
  public activeButton: boolean;
  public askCompanyDefault: boolean = false;
  public modal: NgbModalRef

  @Input() TitleButton = "Confirmar";
  @Input() deactivate = true;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Input() companySelector: TemplateRef<any>;
  @ViewChild("content", { static: true }) content: HTMLElement;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private shareSelect: CompanySelectDataShare,
    private sharedCompany: CompanyDataShare,
    private setCompany: setCompanyIndexUsecases,
    private companyTypeRepository: CompanyTypeDataRepository
  ) {}

  ngOnInit(): void {
    this.shareSelect.setValue({ selected: true, changed: false });
    this.sharedCompany.indexShare.subscribe((index) => (this.index = index));
    this.openModal(this.content);
    this.selected = sessionStorage.getItem("defaultCompany");
  }

  public isDisabled = () => {
    this.shareSelect.dataShared.subscribe((res) => {
      this.deactivate = res.selected;
      this.activeButton = res.changed;
    });
    return this.deactivate;
  };

  public openModal(content): void {
    this.modal = this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "modal-xxl",
      backdrop: "static",
    });
  }

  public reloadSession = (indice): void => {
    const param: CompanyRequest = {
      index: indice,
      persistir: this.showAskCompanyDefault,
    };
    this.setCompany
      .execute(param)
      .subscribe(
        this.setCompanySuccess,
        this.setCompanyError,
        this.setCompanyComplete
      );
  };

  private setCompanySuccess = (res) => {
    if (res && res.data) {
      sessionStorage.setItem("x-ibpj-session", res.data.token_sessao);
    }
  };
  private setCompanyError = (err) => {
    console.log("Company Set Error:", err);
  };

  private setCompanyComplete() {
    location.reload();
  }

  public actionModal(): void {
    console.log(this.index);
    this.reloadSession(this.index);
    sessionStorage.setItem(
      "showAskCompanyDefault",
      JSON.stringify(this.showAskCompanyDefault)
    );
    this.modal.dismiss();
  }
  public closeModal(): void {
    sessionStorage.setItem(
      "showAskCompanyDefault",
      JSON.stringify(this.showAskCompanyDefault)
    );
    this.sharedCompany.setSelect(false);
    this.modal.dismiss();
  }

  public askAgain(e): void {
    this.showAskCompanyDefault = e.target.checked;
    if (!this.activeButton) {
      this.shareSelect.setValue({
        selected: !e.target.checked,
        changed: false,
      });
    }
  }
}
