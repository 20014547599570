import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { componentDeclarations } from '@src/shared/components/custom-forms/custom-forms.common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@src/shared/shared.module';

@NgModule({
  declarations: [...componentDeclarations],
  imports: [CommonModule, FormsModule, SharedModule],
  exports: [...componentDeclarations],
})
export class CustomFormsModule {}
