import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, flatMap, map} from 'rxjs/operators';
import { RulesRepository } from '@src/core/repositories/approval-matrix/rules.repository';
import { ApprovalMatrixRepositoryMapper } from '@src/data/repository/approval-matrix/approval-matrix.repository-mapper';
import { ApprovalMatrixEntity  } from '@src/data/repository/approval-matrix/approval-matrix.entity';
import {
  ApprovalMatrixModel,
  ApproveRuleRequest,
  NewRuleMatrixModel,
  NewRuleMatrixRequest } from '@src/core/domain/approval-matrix/approval-matrix.model';
import { NewRuleMatrixEntity  } from '@src/data/repository/approval-matrix/approval-matrix.entity';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import {handleError} from '@src/shared/util-common';

@Injectable({
  providedIn: 'root'
})


export class RulesDataRepository extends RulesRepository {
  mapper = new ApprovalMatrixRepositoryMapper();

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getAllRules(id?): Observable<ApprovalMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<ApprovalMatrixEntity>(
        `${environment.URLBase}v1/matrizacessos/regras`,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getRulesId(id?): Observable<ApprovalMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<ApprovalMatrixEntity>(
            `${environment.URLBase}v1/matrizacessos/regras/${id}`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  setNewRuleMatrix(params: NewRuleMatrixRequest): Observable<NewRuleMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .post<NewRuleMatrixEntity>(
            `${environment.URLBase}v1/matrizacessos/regras`, params,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.mapper.mapFrom));
  }

  getAllPeople(param?): Observable<ApprovalMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<ApprovalMatrixEntity>(
        `${environment.URLBase}v1/matrizacessos/pessoas/acesso/${param.id}`,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getAllPeopleApproval(param?): Observable<ApprovalMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<ApprovalMatrixEntity>(
        `${environment.URLBase}v1/matrizacessos/pessoas/acessoAprovacao/${param.id}`,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  deleteRule(param): Observable<ApprovalMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .delete<ApprovalMatrixEntity>(
        `${environment.URLBase}v1/matrizacessos/regras/${param}`,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getPeople(param): Observable<ApprovalMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<ApprovalMatrixEntity>(
        `${environment.URLBase}v1/matrizacessos/pessoas/regra/${param.idRule}/acesso/${param.idPeopleType}`,
        {},
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
  editRule(param): Observable<ApprovalMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const cod_regra = param.cod_regra;
    delete param.cod_regra;
    return this.http
      .put<ApprovalMatrixEntity>(
        `${environment.URLBase}v1/matrizacessos/regras/${cod_regra}`,
        param,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  postApproveRule(param: ApproveRuleRequest): Observable<ApprovalMatrixModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .post<ApprovalMatrixEntity>(
            `${environment.URLBase}v1/matrizacessos/regrasaprovacao`,
            param,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
