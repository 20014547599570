import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GetAllQueryUsecase } from '@src/core/usecases/query/get-all-query.usecase';
import {
  InstructionDataShare,
  DetailsListShare,
  FilterQueryDataShare,
} from '@src/data/repository/data-share-repository';
import {
  getOrderedDate,
  getOrderedPayer,
  getOrderedValue,
} from '@src/shared/util-common';
import * as Util from '@src/shared/util-common';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'fibra-portfolio-all-list',
  templateUrl: './query-transit.component.html',
  styleUrls: ['./query-transit.component.scss'],
})
export class PorfolioTransitAllListComponent implements OnInit {
  transit: Array<any>; // transit.pagador[index] e transit.vlr_titulo[index]. pegar o index do checked(idx)?
  filter = new FormControl('');
  getOrderedDate;
  getOrderedPayer;
  getOrderedValue;
  @Input() params;
  showCheckbox = false;
  valueChoice;
  shown = [];
  shownAll = false;
  date = new Date();
  @Output() emitItem: EventEmitter<any>;
  private subscriptions: Subscription[] = [];

  dataForDetails = {
    numberItem: null, // Item a ser enviado para a Api de Instruções (sem detalhes)?
    originalValue: null, // Item a ser enviado para a Api de Instruções?
    labelService: '',
    inputServiceMoney: null,
    inputServiceNumber: null,
    calendarService: null,
    postInput: '',
  };

  dataSetDetailsBar = {
    instruction: '', // Item a ser enviado para a Api de Instruções?
    optionInBatch: null,
    screen: '',
  };
  hide;

  constructor(
    private getAllQueryList: GetAllQueryUsecase,
    private sharedFilter: FilterQueryDataShare,
    private resetShareValueChoice: DetailsListShare
  ) {
    this.emitItem = new EventEmitter();
    this.transit = [];
    this.dataForDetails.numberItem = [];
    this.dataForDetails.originalValue = [];
    this.sendDataToDetails('init');
  }

  ngOnInit() {
  
    this.subscriptions.push(this.sharedFilter.dataShared.subscribe(
      (res: { account: string; dt_Final: string; dt_Inicio: string, param_carteira: any }) => {
        this.params = {
          num_conta: res.account = undefined? "":res.account,
          dt_inicial: res.dt_Inicio,
          dt_final: res.dt_Final,
          param_carteira: res.param_carteira
        };
        this.hide = false;
        setTimeout(() => {
          this.requestQueryList();
        }, 300);
      }
    ));
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  private requestQueryList() {
    this.getAllQueryList.execute(this.params).subscribe((res) => {
      this.transit = Util.verifyArray(res.data);
      this.hide = Util.isEmpty(this.transit);
      this.dataForDetails.numberItem.push(res.numberItem);
      this.dataForDetails.originalValue.push(res.originalValue);
    });
  }

  checked(idx) {
    if (idx !== 'all') {
      this.shown[idx] = !this.shown[idx];
    } else {
      this.shownAll = !this.shownAll;
      this.transit.map((item, index) => {
        const elem = document.getElementById(
          index.toString()
        ) as HTMLInputElement;
        elem.checked = this.shownAll;
        this.shown[index] = this.shownAll;
      });
    }
  }

  ordered(value) {
    switch (value) {
      case 'date':
        return (this.getOrderedDate = getOrderedDate(this.transit));
      case 'payer':
        return (this.getOrderedPayer = getOrderedPayer(this.transit));
      case 'value':
        return (this.getOrderedValue = getOrderedValue(this.transit));
    }
  }

  resetData() {
    (this.dataForDetails.labelService = ''),
      (this.dataForDetails.inputServiceMoney = false),
      (this.dataForDetails.inputServiceNumber = false),
      (this.dataForDetails.calendarService = false),
      (this.dataForDetails.postInput = '');
  }

  sendDataToDetails(event) {
    this.resetData();
    const items = ['abatimento', 'alteracao-vencimento', 'protesto'];
    const includes = items.includes(this.dataSetDetailsBar.instruction);

    if (this.dataSetDetailsBar.optionInBatch === false && includes) {
      this.showCheckbox = true;
    }
    if (
      this.dataSetDetailsBar.optionInBatch ||
      this.dataSetDetailsBar.optionInBatch === null ||
      !includes
    ) {
      this.showCheckbox = true;
    }

    if (!this.dataSetDetailsBar.optionInBatch) {
      if (this.dataSetDetailsBar.instruction === 'abatimento') {
        if (this.dataSetDetailsBar.screen == '0') {
          (this.dataForDetails.labelService = 'Conceder abatimento de'),
            (this.dataForDetails.inputServiceMoney = true);
        }
        if (this.dataSetDetailsBar.screen == '1') {
          this.dataForDetails.labelService = 'Conceder abatimento de';
        }
        if (this.dataSetDetailsBar.screen == '2') {
          this.dataForDetails.labelService = 'Abatimento concedido de';
        }
      }

      if (this.dataSetDetailsBar.instruction === 'alteracao-vencimento') {
        if (this.dataSetDetailsBar.screen == '0') {
          (this.dataForDetails.labelService = 'Alteração de vencimento'),
            (this.dataForDetails.calendarService = true);
        }
        if (this.dataSetDetailsBar.screen == '1') {
          this.dataForDetails.labelService = 'Alteração de vencimento';
        }
        if (this.dataSetDetailsBar.screen == '2') {
          this.dataForDetails.labelService = 'Concedido novo vencimento';
        }
      }

      if (this.dataSetDetailsBar.instruction === 'protesto') {
        if (this.dataSetDetailsBar.screen == '0') {
          (this.dataForDetails.labelService = 'Conceder protesto de'),
            (this.dataForDetails.inputServiceNumber = true),
            (this.dataForDetails.postInput = 'dias');
        }
        if (this.dataSetDetailsBar.screen == '1') {
          (this.dataForDetails.labelService = 'Conceder protesto de'),
            (this.dataForDetails.postInput = 'dias');
        }
        if (this.dataSetDetailsBar.screen == '2') {
          (this.dataForDetails.labelService = 'Concedido protesto de'),
            (this.dataForDetails.postInput = 'dias');
        }
      }
    }
  }

  receiveValueChoice(valueChoice) {
    this.valueChoice = valueChoice;
  }

  sendItemList(item) {
    this.emitItem.emit(item);
    this.shown[item];
  }
}


