<div class="content-beneficiaries">
    <div class="modal">
        <ng-template id="content" #content let-modal>
            <div class="conteudo-modal">
                <div class="terms">
                    <div class="header">
                        <div class="title-div">
                            <span class="title-font"> Aviso importante!</span>
                            <a href="#ancor"></a>
                        </div>
                    </div>
                    <div class="term-content">
                        <div class="term-text">
                            <p class="font" id="ancor">A partir do dia 01/11/2023 entram em vigor as novas disposições para atendimento à Resolução Conjunta BCB nº 6/2023 do Banco Central do Brasil e do Conselho Monetário Nacional, que dispõem sobre requisitos para compartilhamento de dados e informações sobre indícios de fraudes.
                            </p>
                            <br>
                            <p class="font" id="ancor">Assim, AUTORIZO o BANCO FIBRA a REGISTRAR e COMPARTILHAR com outras instituições autorizadas a funcionar pelo Banco Central do Brasil, por meio de sistema eletrônico utilizado especificamente para esse fim, dados e informações sobre indícios de ocorrência ou de tentativas de fraudes identificadas pelo BANCO FIBRA em suas atividades, com a finalidade de subsidiar procedimentos e controles das instituições financeiras, instituições de pagamento e demais instituições autorizadas a funcionar pelo Banco Central do Brasil para a prevenção de fraudes no Sistema Financeiro Nacional.
                            </p>
                            <br>
                            <p class="font" id="ancor">Estou ciente de que o registro dos dados e das informações contemplarão: a) a identificação de quem, segundo os indícios disponíveis, teria executado ou tentado executar a fraude, quando aplicável; b) a descrição dos indícios da ocorrência ou da tentativa de fraude; c) a identificação da instituição responsável pelo registro dos dados e informações; e d) a identificação dos dados da conta destinatária e de seu titular, em caso de transferência de recursos.
                            </p>
                        </div>
                    </div>

                    <div class="actions-div">
                        <div class="row">
                            <div class="col-12 ml-4 d-flex align-itens-center">
                                <input class="ml-1 mr-2" type="checkbox" [(ngModel)]="checkAgreed"/>
                                <span class="color-agreed">Li e estou de acordo</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mt-4">
                                <div class="buttons">
                                <button type="button" class="button-primary" [disabled]="!checkAgreed" (click)="closeModalTerm()">
                                    Fechar
                                </button>
                            </div>
                            </div>
                            <div class="col-12 d-flex mt-4 justify-content-center">
                                <p>Ficou com dúvida? Acesse as <a href="https://www.bcb.gov.br/meubc/faqs/s/compartilhamento-entre-instituicoes-de-dados-e-informacoes-sobre-indicios-de-fraudes" target="_blank">Perguntas e Respostas</a> disponibilizadas pelo Banco Central do Brasil.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </ng-template>
    </div>

   
  </div>