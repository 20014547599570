import { BalanceDataShare } from '@src/core/usecases/data-share/balance-data-share.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BalancesEnum } from '@src/core/domain/balance/balances.model';
import { toTitleCase } from 'codelyzer/util/utils';

@Component({
  selector: 'fibra-balance-list',
  templateUrl: './balance-list.component.html',
  styleUrls: ['./balance-list.component.scss'],
})
export class BalanceListComponent implements OnInit {
  public balances: BalancesEnum;
  public widhtDiv;
  public qtdCards: number;
  public toTitleCase = toTitleCase;
  public today = Date.now();

  @Output() dataBalance: EventEmitter<BalancesEnum>;
  
  constructor(private shareBalance: BalanceDataShare) {
    this.dataBalance = new EventEmitter();
  }

  ngOnInit() {
    this.getBalances();
  }

  public getBalances = () => {
    this.shareBalance.dataShared.subscribe((res) => {
      if (res !== undefined) {
        this.balances = {
          balance_value: res.vlr_saldo,
          block_value: res.vlr_bloqueado,
          cheque_available: res.vlr_cheque_disponivel,
          cheque_used: res.vlr_cheque_utilizado,
          cheque_value: res.vlr_saldo_cheque,
          cheque_empresa_encargos: res.vlr_cheque_encargos,
          limit_value: res.vlr_limite,
          exibicao: res.exibicao,
          garantida_limite: res.vlr_limite_garantida,
          garantida_disponivel: res.vlr_garantida_disponivel,
          garantida_utilizado: res.vlr_garantida_utilizado,
          garantida_encargos: res.vlr_garantida_encargos
        };

        this.countCards();
      }
    });

  };

  public countCards() {

    this.qtdCards = 0;


    if (this.balances.balance_value) {
      this.qtdCards++;
    }

    if (this.balances.block_value) {
      this.qtdCards++;
    }

    if ((this.balances.cheque_available) || (this.balances.cheque_used)) {
      this.qtdCards++;
    }

    if (this.balances.cheque_value) {
      this.qtdCards++;
    }

  }

  public scrollBy(side) {
    if (side === 'right') {
      document.querySelector('#box').scrollBy({
        left: 350,
        behavior: 'smooth',
      });
    }
    if (side === 'left') {
      document.querySelector('#box').scrollBy({
        left: -350,
        behavior: 'smooth',
      });
    }
  }
}
