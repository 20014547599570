import {
  AdminUserListModel,
  AdminUserListRequest,
  AdminDetailRequest,
} from './../../domain/admin-user/admin-user.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { AdminUserRepository } from '@src/core/repositories/admin-user/admin-user.repository';

@Injectable({
  providedIn: 'root',
})
export class GetUserDetailsUseCase
  implements UseCase<AdminDetailRequest, AdminUserListModel> {
  constructor(private adminUserRepository: AdminUserRepository) {}

  execute(params: AdminDetailRequest): Observable<AdminUserListModel> {
    return this.adminUserRepository.getUserDetails(params);
  }
}