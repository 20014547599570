import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { DropdownList } from '@src/core/domain/business-models';

import { isArray } from 'util';
import { GetPurposesUsecase } from '@src/core/usecases/transfer/purposes/get-favorites-usecases';

@Component({
  selector: 'fibra-purposes-list',
  templateUrl: './purposes-list.component.html',
  styleUrls: ['./purposes-list.component.scss'],
})
export class PurposesListComponent implements OnInit {
  public optionsPurposes: DropdownList;
  public selected: any;
  private defaultAccount: Array<any>;
  public loading: boolean;
  @Output() selectedPurpose: EventEmitter<any> = new EventEmitter();

  constructor(private getPurposes: GetPurposesUsecase) {
    this.optionsPurposes = [];
  }
  ngOnInit(): void {
    this.requestPurposes();
  }

  private requestPurposes(): void {
    this.loading = true;
    this.getPurposes
      .execute()
      .subscribe(this.requestSuccess, this.requestError, this.requestComplete);
  }

  private requestError = (err) => {
    console.log(err);
  };

  selectPurpose(value) {
    this.selectedPurpose.emit(value);
  }

  private requestSuccess = (value) => {
    if (isArray(value.data)) {
      value.data.map((e, i) => {
        this.optionsPurposes.push({
          name: `${e.desc_finalidade}`,
          value: e.cod_finalidade,
        });
      });
    } else {
      this.optionsPurposes.push({
        name: `${value.data.desc_finalidade}`,
        value: value.data.cod_finalidade,
      });

      this.defaultAccount = value.data;
    }
    this.defaultAccount = this.optionsPurposes;
    this.selected = this.defaultAccount[0].name;
  };

  private requestComplete = () => {};
}
