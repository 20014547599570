import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit
} from '@angular/core';
export type TAlertComponent = 'warning' | 'success' | 'danger';

export interface IAlertComponent {
  type: TAlertComponent;
  title: string;
  text: string;
  iconPNG?: string;
  okButtonTitle?: string;
  cancelButtonTitle?: string;
};

@Component({
  selector: 'fibra-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  public selected: string;
  public showAskCompanyDefault: boolean;
  public index: number;
  public activeButton: boolean;
  public askCompanyDefault: boolean = false;
  public _open = false;
  public alertClass = {};
  public busy = false;

  @Input() data: IAlertComponent;
  @Output() confirm?: EventEmitter<void> = new EventEmitter();
  @Output() cancel?: EventEmitter<void> = new EventEmitter();

  @Input() set open(value: boolean) {
    this._open = value;
  };

  @Input() public showIcon = true;

  ngOnInit(): void {
    this.alertClass = {
      'danger': this.data.type === 'danger',
      'warning': this.data.type === 'warning',
      'success': this.data.type === 'success'
    };
    if (this.data.type === 'danger' && !this.data.iconPNG) {
      this.data.iconPNG = 'alert';
    }
    if (this.data.type === 'warning' && !this.data.iconPNG) {
      this.data.iconPNG = 'icon-pending';
    } 
    if (this.data.type === 'success' && !this.data.iconPNG) {
      this.data.iconPNG = 'iconConfirm';
    }
  }

  confirmAction() {
    if (!this.busy) {
      this.confirm.emit();
    }    
  }

  cancelAction() {
    if (!this.busy) {
      this.cancel.emit();
    }
  }
}
