import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  ParcelasEnum } from '@src/core/domain/product/product.model';

@Component({
  selector: 'fibra-modal-generic-confirmation',
  templateUrl: './modal-generic-confirmation.component.html',
  styleUrls: ['./modal-generic-confirmation.component.scss'],
})
export class ModalGenericConfirmationComponent implements OnInit {
  @Input() retornoParc: Array<ParcelasEnum> = [];
  @Input() title: string;
  @Input() message: string;
  @Input() message2: string;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', { static: true }) content: HTMLElement;
  
  constructor(
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.openModal(this.content);
  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal(value: any): void {
    this.closeEvent.emit(value);
    this.modalService.dismissAll();
  }
}
