import { Injectable } from '@angular/core';
import { PredictedRepository } from '@src/core/repositories/predicted/predicted.repository';
import { UseCase } from '@src/core/base/use-case';
import { PredictedModel, PredictedRequest } from '@src/core/domain/predicted/predicted.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetPredictedUsecase implements UseCase<PredictedRequest, PredictedModel> {

  constructor(private predictedRepository: PredictedRepository) { }

  execute(params: PredictedRequest): Observable<PredictedModel> {
    return this.predictedRepository.getPredictedMovements(params);
  }
}
