<div class="approval-list" id="body-content">
    <header class="approval-list__header">
      <img class="logo" src="/assets/svg/logo_fibra.svg" alt="Logo Fibra" />
      <p class="tagline">
        Consulta de transações - Internet Banking - Banco Fibra -
        {{ this.date }} - Auto atendimento IB - {{ this.hour }}.
      </p>
      <h3 class="headline">Aprovação de transações</h3>
    </header>
    <main class="approval-list__content">
      <ul class="approval-list__content__tabs">
        <li class="item">{{ tabsType | titlecase }}</li>
        <li class="item">
          Conta
          <span *ngIf="accountType === ''" class="item__selected">{{
            accountType || 'Todas'
          }}</span>
          <span *ngIf="accountType !== ''" class="item__selected">{{
            accountType | account
          }}</span>
        </li>
        <li class="item">Tipo <span class="item__selected">Todos</span></li>
        <li class="item">
          Período <span class="item__selected">{{ diffDateDays }} dias</span>
        </li>
      </ul>
  
      <div class="empty-info" *ngIf="hide">
        <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
          message="Tente uma nova pesquisa inserindo novos parâmetros.">
        </fibra-generic-info>
      </div>
  
      <div class="approval-list__content__table" *ngIf="!hide">
        <table class="approval-table">
          <tr>
            <th class="tbl-col-1">
              Data pagamento / <br />
              transferência
            </th>
            <th class="tbl-col-2">Tipo de transação</th>
            <th class="tbl-col-3">Conta</th>
            <th class="tbl-col-4">CNPJ / CPF</th>
            <th class="tbl-col-5">Valor</th>
            <th class="tbl-col-6">Status</th>
          </tr>
  
          <tr *ngFor="let item of approvalList">
            <td>{{ item.dt_transacao | date: 'dd/MM/yyyy' }}</td>
            <td>
              <p class="tipo-transacao">{{ item.tipo_transacao }}</p>
              <p class="nome_favorecido">
                {{ item.info_transacao.nome_favorecido }}
              </p>
            </td>
            <td>{{ item.num_conta | account }}</td>
            <td>{{ item.num_cpf_cnpj }}</td>
            <td>{{ item.vlr_transacao | currency: 'BRL' }}</td>
            <td>
  
              <div *ngIf="(item?.desc_status === 'INCONSISTENTE' || item?.desc_status === 'PENDENTE BANCO FIBRA' || item?.desc_status === 'AGUARDANDO SALDO DISPONÍVEL') && item.flag_robot_aprovacao === false">
                <p class="desc_status">
                  <span class="icon-info">
                    <div class="pending-icon"></div>
                  </span>

                  Pendente
                </p>
              </div>
  
              <div *ngIf="item?.desc_status === 'EM PROCESSAMENTO' && item.flag_robot_aprovacao === true">
                  <p class="desc_status">
                    <span class="icon-info">
                      <div class="pending-icon"></div>
                    </span>
    
                    Em processamento
                  </p>
              </div>
  
              <div *ngIf="item.desc_status !== 'RECUSADO LIMITE' && item.desc_status !== 'PENDENTE BANCO FIBRA' && item.desc_status !== 'AGUARDANDO SALDO DISPONÍVEL' && item.desc_status !== 'EM PROCESSAMENTO' && item.desc_status !== 'INCONSISTENTE'">
                <p class="desc_status">
                  <span class="icon-info">
                    <div [ngClass]="(item.desc_status === 'LIQUIDADO' || item.desc_status === 'APROVADO') ? 'authorized-icon' : ((item.desc_status === 'AGENDADO' || item.desc_status === 'PENDENTE' || item.desc_status === 'VENCIDO') ? 'pending-icon' : 'refused-icon')"></div>
                  </span>

                  {{ item.desc_status === 'REPROVADO' || item.desc_status === 'CANCELADO SALDO INSUFICIENTE' || item.desc_status === 'CANCELADO BANCO FIBRA' || item.desc_status === 'TRANSAÇÃO ESTORNADA' ? 'Recusado' : (item.desc_status | titlecase) }}
                </p>
              </div>
  
              <div *ngIf="item.desc_status == 'EM PROCESSAMENTO' && item.flag_robot_aprovacao === false">
                <p class="desc_status">
                  <span class="icon-info">
                    <div class="pending-icon"></div>
                  </span>

                  Processando
                </p>
              </div>
  
              <div *ngIf="item.desc_status === 'RECUSADO LIMITE' && item.flag_robot_aprovacao === false">
                <p class="desc_status">
                  <span class="icon-info">
                    <div class="refused-icon"></div>
                  </span>
                  Recusado
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </main>
    <div class="card-content">
      <div class="card">
          <div class="html2pdf__page-break"></div>
          <br />
              <div style="display: table; padding: 25px 0px 10px 20px; width:100%;">
                  <p>Se precisar de ajuda, entre em contato com a gente.</p>
              </div> 
          <br />
        <div class="card-body" #atendimento>
          <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
          <div class="text">
            <div class="title">Canais exclusivos</div>
            <div class="desc">Dúvidas e apoio</div>
          </div>
        </div>
        <div class="card-bottom">
          <p>
            <b>Corporate, Agro e PMEs</b>
            (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
          </p>
          <p>
            <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body" #sac>
          <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
          <div class="text">
            <div class="title">SAC</div>
            <div class="desc">Sugestões, reclamações e elogios</div>
          </div>
        </div>
        <div class="card-bottom">
          <p>
            <b>Canal de Atendimento ao Cliente</b>
          </p>
          <p>
            0800 727 0132
          </p>
          <p>
            <b>Canal exclusivo para deficientes auditivos ou de fala</b>
          </p>
          <p>
            0800 200 6921
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body" #ouvidoria>
          <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
          <div class="text">
            <div class="title">Ouvidoria</div>
            <div class="desc">Demandas não solucionadas</div>
          </div>
        </div>
        <div class="card-bottom">
          <p>
            0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
          </p>
        </div>
      </div>
    </div>
  </div>
  