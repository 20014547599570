import {
  ComprovanteModel
} from '@src/core/domain/product/product.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { ProductRepository } from '@src/core/repositories/product/product.repository';

@Injectable({
  providedIn: 'root',
})
export class GetComprovanteUseCase
  implements UseCase<number, ComprovanteModel> {
  constructor(private productRepository: ProductRepository) {}

  execute(cod_solicitacao: number): Observable<ComprovanteModel> {
    return this.productRepository.getComprovante(cod_solicitacao);
  }
}
