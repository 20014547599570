import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PaymentDataShare } from '@src/core/usecases/data-share/payment-data-share.service';

@Component({
  selector: 'fibra-page-payment-revision',
  templateUrl: './payment-revision.component.html',
  styleUrls: ['./payment-revision.component.scss'],
})
export class PaymentRevisionComponent implements OnInit {
  public loading: boolean;
  public loadingDetail: boolean;
  public dataList: Array<any>;
  public sorted: boolean;
  public dataDetail: any;
  public error: any;
  public shared: any;
  public multipleList: Array<any>;

  constructor(
    private router: Router,
    private paymentDataShare: PaymentDataShare,
  ) {
    this.error = {
      active: false,
      type: "error",
      title: "Ocorreu um erro",
      message: "Não foi possível listar os status. Tente novamente mais tarde."
    };
  }

  ngOnInit() {
    this.loading = true;
    this.paymentDataShare.dataShared.subscribe(res => {
      if (!res || Object.keys(res).length === 0) {
        this.onNav('/dashboard');
      }else{
        this.shared = res;
        this.formatList();
      }
    });
  }

  formatList = () => {
    const multipleData = this.shared.paymentMultiple;

    multipleData.map((e, i) => {
      if (this.shared.multipleReturn) {
        if (this.shared.multipleReturn[i].aprovadores && this.shared.multipleReturn[i].aprovadores.length > 0) {
          this.shared.multipleReturn[i].masters = this.shared.multipleReturn[i].aprovadores.filter(a => a.master && !a.master_backup);
          this.shared.multipleReturn[i].operators = this.shared.multipleReturn[i].aprovadores.filter(a => !a.master);
        }

        e.paymentReturn = this.shared.multipleReturn[i];
      }
    });

    this.dataList = multipleData;
    this.loading = false;
  }

  sortList(value) {
    this.sorted = !this.sorted;
    this.dataList.sort((a, b) => {
      if (a[value] < b[value]) {
        if (this.sorted) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[value] > b[value]) {
        if (this.sorted) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
  }

  hoverTooltip(id, bool) {
    this.dataList.map((e, i) => {
      if (i === id) {
        e.showTooltip = bool;
      }
    });
  }

  onNav = (path) => {
    this.router.navigateByUrl(path);
  }
}
