import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, Input, Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { GetInfoTitleQueryUsecase } from '@src/core/usecases/query/get-info-title-query.usecase';
import * as models from '@src/data/repository/data-share-repository';
import { Subscription } from 'rxjs'
import { min } from 'rxjs/operators';

@Component({
  selector: 'fibra-query-portfolio-detail',
  templateUrl: './query-portfolio-detail.component.html',
  styleUrls: ['./query-portfolio-detail.component.scss'],
})
export class QueryPortfolioDetailComponent implements OnInit {
  @Input() titleData: any;

  loading;
  optionsTab;
  titleInfoList;
  instrucaoLista = [];
  ocorrenciaLista;
  maskCpfCnpj;
  isCurrencyBRL: string = 'R$';
  isCurrencyUSD: string = '$';

  info;
  @Output() emitBack: EventEmitter<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private getInfoTitle: GetInfoTitleQueryUsecase,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.emitBack = new EventEmitter();
    this.optionsTab = models.optionPortfolioDetail;
    this.info = 'sacado';
  }

  ngOnInit() {
    this.requestQueryInfoTitle(this.titleData);
  }

  private requestQueryInfoTitle(param) {

    this.subscriptions.push(this.getInfoTitle
      .execute(
        {
          numero_carteira: null,
          codigo_agencia: null,
          nosso_numero: param.nosso_numero,
          seu_numero: null,
          nosso_numero_correspondente: null,
          codigo_banco_correspondente: null,
          codigo_cliente: null
        }
      )
      .subscribe(
        this.requestSuccess,
        this.requestError,
        this.requestComplete
      ));
  }





  private requestSuccess = (value: any) => {
    if (value && value.data) {
      let result = value.data;

      this.titleInfoList = result[0];

      // this.instrucaoLista = result.filter(x => {
      //   if (x.tipoRegistro == 2)
      //     return x;
      // })

      let str_instrucao_instrucao = '';
      let dt_instrucao_data = null;
      let str_instrucao_situacao = '';

      let itens = [];

      for (let index = 0; index < result.length; index++) {
        let x = result[index];

        if (x.tipoRegistro == 2) {

            itens.push(x);
        }
      }

      let instrucaoListaTemp = [];
      itens.filter(function(item){
        var i = instrucaoListaTemp.findIndex(x => (x.instrucao_instrucao == item.instrucao_instrucao && x.instrucao_situacao == item.instrucao_situacao));
        if(i <= -1){
          instrucaoListaTemp.push(item);
        }
        return null;
      });

      this.instrucaoLista = instrucaoListaTemp;

      this.ocorrenciaLista = result.filter(x => {
        if (x.tipoRegistro == 3)
          return x;
      })
    }
    this.loading = false;
  };

  private requestError = (err) => {
    this.loading = false;
    return console.log(err);
  };

  private requestComplete = () => {
    this.loading = false;
  };

  public navigateBack() {
    this.emitBack.emit(true);
  }

  public selectTab(event) {
    this.info = event;
  }

  public cepMask(value) {
    if (value) {
      return (
        value.replace(/\D/g, '')
          .replace(/(\d{5})(\d)/, '$1-$2')
          .replace(/(-\d{3})\d/, '$1')
      )
    }
  }

  public cpfCnpjMask(value) {

    if (value && this.titleInfoList.tipoPessoa === 'F') {
      var valueNumberZero = value.substring(0, 4)
      var valueNumberSemZero = value.replace(valueNumberZero, '')
      return (
        valueNumberSemZero.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      )
    } else {
      var valueNumberZero = value.substring(0, 1)
      var valueNumberSemZero = value.replace(valueNumberZero, '')
      return (
        // value.replace(/^0/, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        valueNumberSemZero.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      )
    }
  }

  public cpfCnpjMaskAvalista(value) {

    if (value && this.titleInfoList.sacadorAvalistaTipoPessoa === 'F') {
      var valueNumberZero = value.substring(0, 4)
      var valueNumberSemZero = value.replace(valueNumberZero, '')
      return (
        valueNumberSemZero.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      )
    } else {
      var valueNumberZero = value.substring(0, 1)
      var valueNumberSemZero = value.replace(valueNumberZero, '')
      return (
        valueNumberSemZero.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      )
    }
  }

  public validateDate(value) {

    let formatedDate = formatDate(value, 'dd/MM/yyyy', this.locale);
    let minDate = formatDate(new Date(1900, 1, 1), 'dd/MM/yyy', this.locale);

    if (formatedDate && formatedDate > minDate)
      return formatedDate;

    return "";
  }
}
