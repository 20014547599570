import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, delay, flatMap, map } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { IOpenBankingTransaction } from '@src/core/domain/openbanking/openbanking.transaction.model';
import { AccountTypeModel } from '@src/core/domain/account/accountType.model';
import { AccountsEntity } from '../pix/accounts/pix-accounts-entity';
import { IOpenBankingConsent } from '@src/core/domain/openbanking/openbanking.consent.model';

@Injectable({
  providedIn: 'root',
})
export class OpenBankingRepository {
  
    constructor(private http: HttpClient) {
    
    }

    getAccounts(): Observable<AccountsEntity[]> {       
        return this.http.get<AccountTypeModel>(`${environment.URLBase}v1/contas`, { headers: getHeader() })
            .pipe(catchError(handleError))
            .pipe(map(result => <AccountsEntity[]>result.data));
    }

    public setUserDoneOnboardingAndTermsOfUse(): Observable<void> {
        return this.http.post<void>(`${environment.URLBase}v1/openfinance/onboarding_termodeuso`, {}, { headers: getHeader() })
            .pipe(catchError(handleError));
    }

    public setUserDoneOnboarding(): Observable<void> {
        return this.http.post<void>(`${environment.URLBase}v1/openfinance/onboarding`, {}, { headers: getHeader() })
            .pipe(catchError(handleError));
    }

    public setUserAcceptedTermsOfUse(): Observable<void> {
        return this.http.post<void>(`${environment.URLBase}v1/openfinance/termodeuso`, {}, { headers: getHeader() })
            .pipe(catchError(handleError));
    }

    public getUsuarioFezOnboardingTermoDeUso(): Observable<{ fezonboarding: boolean, aceitoutermodeuso: boolean }> {
        return this.http.get<{ code: number, status: string, data?: any }>(`${environment.URLBase}v1/openfinance/getusuariofezonboardingtermodeuso`, { headers: getHeader() })
            .pipe(catchError(handleError))
            .pipe(map(result => <{ fezonboarding: boolean, aceitoutermodeuso: boolean }>result.data));
    }

    public cancelTransaction(interactionId: string, consentId: string): Observable<{ url: string, errors: any }> {
        return this.http.post<{ code: number, status: string, data?: any }>(`${environment.URLBase}v1/openfinance/cancel/${interactionId}/${consentId}`, {}, { headers: getHeader() })
            .pipe(catchError(handleError))
            .pipe(map(result => <{ url: string, errors: string }>result.data));
    }

    public validateAccount(index: number, valor: number): Observable<{ pode_aprovar: boolean, unico_aprovador: boolean, possui_termo_aceite_pix: boolean }> {
        return this.http.get<{ data: { pode_aprovar: boolean, unico_aprovador: boolean, possui_termo_aceite_pix: boolean } }>(`${environment.URLBase}v1/pix/openbanking/validar-conta-selecionada-ob/${index}/${valor}`, { headers: getHeader() })
            .pipe(catchError(handleError))
            .pipe(map(result => result.data));
    }

    public validaEscalonamento(consentId: string): Observable<string> {
        return this.http.get<{ code: number, status: string, data?: any }>(`${environment.URLBase}v1/openfinance/escalonamento/${consentId}`, { headers: getHeader() })
            .pipe(catchError(handleError))
            .pipe(map(result => result ? <string>result.data : null));
    }
}