<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>

<div class="content-page">
  <fibra-breadcrumb
    bottom="-120px"
    infoAccount="Transações"
    roule="Transferências"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb>
  <div class="body-content">
    <div class="arrow" (click)="routerBack()">
      <img src="/assets/svg/seta-left.svg" alt="" />
      <label>Voltar</label>
    </div>
    <div class="row-title">
      <h1 class="title">Selecione um banco</h1>
    </div>
    <fibra-list-bank (emitBank)="selectBank($event)"></fibra-list-bank>
  </div>
</div>
