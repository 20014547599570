import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'fibra-admin-user-detail',
  templateUrl: './admin-user-detail.component.html',
  styleUrls: ['./admin-user-detail.component.scss'],
})
export class AdminUserDetailComponent implements OnChanges {
  @Input() data: any;
  @Input() id: number;

  public userDetail;
  constructor() {}

  ngOnChanges() {
    this.userDetail = null;
    if (this.data) {
      this.userDetail = this.data;
    }
  }
}
