import { Component, OnInit, ViewChild, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountDataShare } from '@src/data/repository/data-share-repository';
import { MarkModalAsReadUsecase } from '@src/core/usecases/alteracoeslimites/markmodalasread.usecase';

@Component({
  selector: 'fibra-modal-overdraft-limit-change',
  templateUrl: './modal-overdraft-limit-change.component.html',
  styleUrls: ['./modal-overdraft-limit-change.component.scss'],
})
export class ModalOverdraftLimiteChangeComponent implements OnInit {
  public loading = true;
  public enableButton = false;
  public activeButton: boolean;
  public account: any;
  public acknowledging: boolean;
  public modal: NgbModalRef;
  public cancelOverdraft: boolean = false;
  public exibicaoAccount: string = '';

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', { static: true }) content: HTMLElement;
  

  constructor(
    private modalService: NgbModal,
    private sharedAccount: AccountDataShare,
    private _markModalAsReadUsecase: MarkModalAsReadUsecase
  ) { }

  ngOnInit(): void {

    this.openModal(this.content);

    this.sharedAccount.dataShared.subscribe(data => {
      if (data) {
        this.account = data.find(a => a.alteracaolimite && a.alteracaolimite.pendentereconhecimentomodal);

        if (this.account) {
          this.account.alteracaolimite.acao = this.account.alteracaolimite.tipo == "Aumento" ? "aumentou" : this.account.alteracaolimite.tipo == "Redução" ? "reduziu" : "foi cancelado";
          this.exibicaoAccount = this.account.exibicao;
          if (this.account.alteracaolimite.acao == "foi cancelado" ){
            this.cancelOverdraft = true;
            this.exibicaoAccount = ""
          }
        }

        this.loading = false;
      }      
    });
  }

  public openModal(content): void {
    this.modal = this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
      keyboard: false
    });
  }

  public actionModal(): void {
    this.acknowledging = true;

    this._markModalAsReadUsecase.execute(this.account.alteracaolimite.id).subscribe(
      () => {
        sessionStorage.setItem('modalOverdraftLimitChange','true');
        this.closeModal()     
      },
      err => {
        console.log(err);
        this.closeModal();
      },
      () => this.acknowledging = false
    );
  }

  public closeModal(): void {
    this.modal.close();
    this.closeEvent.emit("close");
  }

  public toggleButtonEnabled(e): void {
    this.enableButton = e.target.checked;
  }
}