<div class="filter">
    <div class="filter-default">
        <div class="dropdown-periodic">
            <div class="group-items">
                <div class="period">
                    <div class="label-period">Período</div>
                    <div class="group-period">
                        <div *ngFor="let item of periods">
                            <div class="options-period" (click)="changePeriod(item)" [ngClass]="{ activeCalendar: selectedPeriod === item }">
                                {{ item }} dias
                            </div>
                        </div>
                        <div class="icon">
                            <fibra-range-datepicker [isSchedules]="isSchedules" id="datepicker" (change)="changePeriodDate($event)" [minDate]="minDate" [maxDate]="maxDate"></fibra-range-datepicker>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <section *ngIf="from && to">
        <span class="txt-interval">Período: {{ formatRange() }}</span>
    </section>
</div>

