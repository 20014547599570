import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { DerivativeResponse,DerivativeNotasResponse} from './derivative.entity';
import { handleError } from '@src/shared/util-common';
import { formatDate } from '@angular/common';
import { DateExcelRequest } from '../loans-repository/loans.entity';

@Injectable({
  providedIn: 'root',
})
export class DerivativeDataRepository {

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale?: string) { }

  getDerivative(dt: Date): Observable<DerivativeResponse> {
    const httpOptions = {
      headers: getHeader(),
      params: {
        dt: formatDate(dt, 'yyyy-MM-dd', this.locale)
      }
    };
    try {
      return this.http
        .get<DerivativeResponse>(
          `${environment.URLBase}v1/derivativos/contratos`,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }

  }


  postExcel(dt: Date): Observable<any> {

    const obj :DateExcelRequest = {
      dt: formatDate(dt, 'yyyy-MM-dd', this.locale)
    }

    const params = { ...obj };

    //const paramsReq = {  dt: formatDate(dt, 'yyyy-MM-dd', this.locale) };

    //const body = JSON.stringify(`{"dt": "${formatDate(dt, 'yyyy-MM-dd', this.locale)}"}`);
    
    try {
      return this.http
      .post<any>(
        `${environment.URLBase}v1/derivativos/Contratos/excel/${formatDate(dt, 'yyyy-MM-dd', this.locale)}`,
        {},
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
    }
    catch (error) {
      handleError(error);
    }

  }

  getNotas(dt: Date): Observable<DerivativeNotasResponse> {
    const httpOptions = {
      headers: getHeader(),
      params: {
        dt: formatDate(dt, 'yyyy-MM-dd', this.locale)
      }
    };
    try {
      return this.http
        .get<DerivativeNotasResponse>(
          `${environment.URLBase}v1/derivativos/pdf`,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }

  }

}
