import { Injectable } from '@angular/core';
import { RulesRepository } from '@src/core/repositories/approval-matrix/rules.repository';
import { UseCase } from '@src/core/base/use-case';
import { ApprovalMatrixModel, RuleRequest } from '@src/core/domain/approval-matrix/approval-matrix.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GetRulesIdUsecase implements UseCase<RuleRequest, ApprovalMatrixModel> {

  constructor(private ruleRepository: RulesRepository) { }

  execute(params): Observable<ApprovalMatrixModel> {
    return this.ruleRepository.getRulesId(params);
  }
}
