import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageInterface } from "../modal-conclusao-adesao/interfaces/image-interfaces";

@Component({
  selector: "fibra-modal-conclusao-aprovacao",
  templateUrl: "./modal-conclusao-aprovacao.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
})
export class ModalConclusaoAprovacaoComponent implements OnInit {
  @ViewChild("content", { static: true }) content: HTMLElement;
  @Input() totalMasters: number = 0;
  @Input() totalFiliais: number = 0;
  @Input() recusar: boolean = false;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  imgTypes: ImageInterface[] = [
    {
      type: "error",
      src: "assets/png/icon-error.png",
      forecolor: "fg-red",
    },
    {
      type: "success",
      src: "assets/png/icon-accreddited.png",
      forecolor: "fg-green",
    },
    {
      type: "warning",
      src: "assets/png/icon-pending.png",
      forecolor: "fg-warn",
    },
  ];

  title: string = "";
  message: string = "";
  imgSrc: string = "";
  typeMessage: string = "";

  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit() {
    this.openModal(this.content);
    this.setModalData();
  }

  setModalData(): void {
    if (!this.recusar) {
      if (this.totalMasters === 1 && this.totalFiliais === 0) {
        this.title = "Adesão ao DDA aprovada.";
        this.message = `Você aderiu ao DDA. Até o próximo
      dia útil, todos os boletos de cobrança registrados em seu nome serão
      disponibilizados eletronicamente.`;
        this.imgSrc = this.imgTypes.find((x) => x.type === "success").src;
        this.typeMessage = "success";
      }
      if (this.totalMasters === 1 && this.totalFiliais >= 1) {
        this.title = "Adesão ao DDA aprovada.";
        this.message = `CNPJ da filial está sendo validado.
        Após a validação, todos os boletos de cobrança registrados serão
        disponibilizados em até 1 dia útil.`;
        this.imgSrc = this.imgTypes.find((x) => x.type === "success").src;
        this.typeMessage = "success";
      }
      if (this.totalMasters > 1 && this.totalFiliais === 0) {
        this.title = "Adesão ao DDA pendente.";
        this.message = `Adesão ao DDA pendente. Necessário a aprovação dos demais masters da sua empresa.`;
        this.imgSrc = this.imgTypes.find((x) => x.type === "warning").src;
        this.typeMessage = "warn";
      }

      if (this.totalMasters > 1 && this.totalFiliais >= 1) {
        this.title = "Adesão ao DDA pendente.";
        this.message = `Adesão ao DDA pendente. Necessário a aprovação dos demais masters da sua empresa.`;
        this.imgSrc = this.imgTypes.find((x) => x.type === "warning").src;
        this.typeMessage = "warn";
      }
    } else {
      this.title = "Adesão ao DDA recusada.";
      this.message = "";
      this.imgSrc = this.imgTypes.find((x) => x.type === "error").src;
      this.typeMessage = "error";
    }
  }

  openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "modal-xxl",
      backdrop: "static",
    });
  }

  fechar(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/dda"]);
  }

  irParaHome(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/dashboard/"]);
  }
}
