
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoansRequestContractInfo } from '@src/core/domain/loans/loan-model';
import { LoansDateParameterDataShare } from '@src/core/usecases/data-share/loans-date-param';
import { GetParcelaUsecase } from '@src/core/usecases/loans/get-parcela';
import { Subscription } from 'rxjs';



@Component({
  selector: 'fibra-parcelas',
  templateUrl: './parcelas.component.html',
  styleUrls: ['./parcelas.component.scss'],
})
export class ParcelasComponent implements OnInit,OnDestroy {
  public loading: boolean;
  public getParcelaSub:Subscription;

  constructor(
    private getParcela: GetParcelaUsecase,
    public dateShare: LoansDateParameterDataShare,
  ) { }

  @Input() numContract: string;
  @Input() dateFilterParam: string;
  @Output() showPageContractInformation = new EventEmitter<any>();

  parcelaList = [];
  parcelaListAux = [];
  parcelaListData = [];
  numContrato: string;
  actualTab = "tudo";

  dateChose: Date;

  public dataInicio: string;
  public dataFim: string;

  hasData: boolean = false;
  totalPrincipal: Number;
  totalPrincipalCorrigido: Number;
  totalJuros: Number;
  totalJurosDeMora: Number;
  totalMultaMoratoria: Number;
  totalIof: Number;
  totalAPagar: Number;
  totalAPagarSeAbaVencida:Number;

  totalAPagarSeAbaVencida1:Number;
  hasError=false;

  dateReferenceToShow = null


  ngOnInit(): void {

    this.tabsSelect('tudo');
  }


  addStatusToList(status = null) {
    this.parcelaListData = this.parcelaList
    this.parcelaList = []
    this.parcelaListData.forEach(element => {
      let situation = this.checkCodSituation(element.cod_situacao)
      let parcela = element
      parcela.status = situation
      this.parcelaList.push(parcela)
    })
  }

  ListFillData() {

    this.totalPrincipal = 0;
    this.totalPrincipalCorrigido = 0;
    this.totalJuros = 0;
    this.totalJurosDeMora = 0;
    this.totalMultaMoratoria = 0;
    this.totalIof = 0;
    this.totalAPagar = 0;
    this.totalAPagarSeAbaVencida = 0;


    this.parcelaList.forEach(element => {
      this.totalPrincipal += element.valor_principal
      this.totalPrincipalCorrigido +=  element.valor_principal_cm
      this.totalJuros +=  element.valor_juros
      this.totalJurosDeMora +=  element.valor_mora
      this.totalMultaMoratoria +=  element.valor_multa
      this.totalIof += element.valor_iof
      this.totalAPagar += element.valor_principal + element.valor_juros + element.valor_iof + element.valor_mora + element.valor_multa + element.valor_principal_cm
      this.totalAPagarSeAbaVencida += element.valor_principal + element.valor_juros + element.valor_iof + element.valor_mora + element.valor_multa
    })

    //this.totalAPagarSeAbaVencida1 = Math.round(this.totalAPagarSeAbaVencida)

    sessionStorage.setItem('parcelas-total-values', `
    {"totalPrincipal":${this.totalPrincipal},
      "totalPrincipalCorrigido":${this.totalPrincipalCorrigido},
      "totalJuros":${this.totalJuros},
      "totalJurosDeMora":${this.totalJurosDeMora},
      "totalMultaMoratoria":${this.totalMultaMoratoria},
      "totalIof":${this.totalIof},
      "totalAPagar":${this.totalAPagar},
      "totalAPagarSeAbaVencida":${this.totalAPagarSeAbaVencida}}`)
  }



  checkHasData() {
    if (this.parcelaList.length > 0)
      this.hasData = true
    else
      this.hasData = false
  }

  checkCodSituation(codSituation: string) {
    if (codSituation == "À vencer") {
      return "avencer"
    } else if (codSituation == "Liquidado") {
      return "liquidada"
    }
    else {
      return "vencido"
    }

  }

  back() {
    this.showPageContractInformation.emit(false)
  }



  private requestSuccess = (value: any, tab:string) => {
    localStorage.setItem(
      'installments-export-data',
      JSON.stringify({
        data: value,
        type: null,
        date: null,
        tabs: tab
      })
    );
  };


  tabsSelect(tabs) {
    this.parcelaList = []
    this.hasData = false
    this.loading = true;
    setTimeout(() => {
      if (tabs === 'tudo') {
        this.parcelaList = []
        this.dateShare.data
        let dateParam = null
        let date = null

        this.dateShare.dataShared.subscribe((res) => (dateParam = res))


        //Export Excel
        let excelFilterParam = '';
        sessionStorage.setItem('excelFilterParam', excelFilterParam);
      
        //Seta data de referencia, se houver
        this.dateReferenceToShow = dateParam.dat_ref

        if(dateParam.userPassValue)
          date = dateParam.dat_ref

        const params: LoansRequestContractInfo = {
          numContrato: this.numContract,
          dt_ref: date
        }

        sessionStorage.setItem('excelFilterCodContract', this.numContract);

        this.getParcelaSub = this.getParcela.execute(params).subscribe((data) => {
          
          data.data.forEach(element => {
            if(element.cod_situacao ==="A vencer")
            {
              element.cod_situacao = "À vencer"
              this.parcelaList.push(element)
            }
            else
            {
              this.parcelaList.push(element)
            }
          });

          //PDF
          sessionStorage.setItem('parcelas_pdf', JSON.stringify(this.parcelaList) );
          sessionStorage.setItem('informations_pdf', '{"title":"Capital de giro", "filter":"Tudo", "report_name":"Parcelas", "name":"parcelas","dt_ref":"'+this.dateReferenceToShow+'","num_contract":"'+this.numContract+'"}')



          this.checkHasData()

          this.ListFillData()

          this.addStatusToList()

          this.requestSuccess(this.parcelaList, 'tudo')

          this.loading = false
        }, (err)=>
        {
          this.hasError=true;
        })


        this.actualTab = 'tudo'

      }
      else if (tabs === 'pagos') {

        this.parcelaList = []

        let dateParam = null
        let date=null

        this.dateShare.dataShared.subscribe((res) => (dateParam = res))


        //Export Excel
        let excelFilterParam = 'Liquidado';
        sessionStorage.setItem('excelFilterParam', excelFilterParam);

        //Seta data de referencia, se houver
        this.dateReferenceToShow = dateParam.dat_ref

        if(dateParam.userPassValue)
          date = dateParam.dat_ref

        const params: LoansRequestContractInfo = {
          numContrato: this.numContract,
          dt_ref: date
        }

        this.getParcelaSub = this.getParcela.execute(params).subscribe((data) => {
          let allList = data.data

          allList.forEach(element => {
            if (element.cod_situacao == "Liquidado")
              this.parcelaList.push(element)
          }, (err)=>
          {
            this.hasError=true;
          })

          //PDF
          sessionStorage.setItem('parcelas_pdf', JSON.stringify(this.parcelaList) );
          sessionStorage.setItem('informations_pdf', '{"title":"Capital de giro", "filter":"Liquidado", "report_name":"Parcelas", "name":"parcelas", "dt_ref":"'+this.dateReferenceToShow+'","num_contract":"'+this.numContract+'"}')
          


          this.checkHasData()

          this.ListFillData()
          this.addStatusToList()

          this.requestSuccess(this.parcelaList, 'pagos')

          this.loading = false
        })

        this.actualTab = 'pagos'

      }
      else if (tabs === 'avencer') {
        this.parcelaList = []
        let dateParam = null
        let date=null

        this.dateShare.dataShared.subscribe((res) => (dateParam = res))


        //Export Excel
        let excelFilterParam = 'A Vencer';
        sessionStorage.setItem('excelFilterParam', excelFilterParam);

        //Seta data de referencia, se houver
        this.dateReferenceToShow = dateParam.dat_ref

        if(dateParam.userPassValue)
          date = dateParam.dat_ref

        const params: LoansRequestContractInfo = {
          numContrato: this.numContract,
          dt_ref: date
        }

        this.getParcelaSub = this.getParcela.execute(params).subscribe((data) => {
          let allList = []

           data.data.forEach(element => {
            if(element.cod_situacao ==="A vencer")
            {
              element.cod_situacao = "À vencer"
              allList.push(element)
            }
            else
            {
              allList.push(element)
            }
          });

          allList.forEach(element => {
            if (element.cod_situacao == "À vencer")
              this.parcelaList.push(element)
          })

          //PDF
          sessionStorage.setItem('parcelas_pdf', JSON.stringify(this.parcelaList) );
          sessionStorage.setItem('informations_pdf', '{"title":"Capital de giro", "filter":"À vencer", "report_name":"Parcelas", "name":"parcelas", "dt_ref":"'+this.dateReferenceToShow+'","num_contract":"'+this.numContract+'"}')


          this.checkHasData()

          this.ListFillData()
          this.addStatusToList()

          this.requestSuccess(this.parcelaList, 'avencer')

          this.loading = false
        }, (err)=>
        {
          this.hasError=true;
        })

        this.actualTab = 'avencer'

      }
      else {
        this.parcelaList = []
        let dateParam = null
        let date = null
        this.dateShare.dataShared.subscribe((res) => (dateParam = res))


        //Export Excel
        let excelFilterParam = 'Vencidas';
        sessionStorage.setItem('excelFilterParam', excelFilterParam);

        //Seta data de referencia, se houver
        this.dateReferenceToShow = dateParam.dat_ref

        if(dateParam.userPassValue)
          date = dateParam.dat_ref

        const params: LoansRequestContractInfo = {
          numContrato: this.numContract,
          dt_ref: date
        }

        this.getParcelaSub = this.getParcela.execute(params).subscribe((data) => {
          let allList = data.data

          allList.forEach(element => {
            if (element.cod_situacao == "Vencidas")
              this.parcelaList.push(element)
          })


          //PDF
          sessionStorage.setItem('parcelas_pdf', JSON.stringify(this.parcelaList) );
          sessionStorage.setItem('informations_pdf', '{"title":"Capital de giro", "filter":"Vencidas", "report_name":"Parcelas", "name":"parcelas","dt_ref":"'+this.dateReferenceToShow+'","num_contract":"'+this.numContract+'"}')

          this.checkHasData()

          this.ListFillData()
          this.addStatusToList()

          this.requestSuccess(this.parcelaList, 'vencido')

          this.loading = false
        }, (err)=>
        {
          this.hasError=true;
        })

        this.actualTab = 'vencido'

      }

    }, 600);
  }


  ngOnDestroy()
  {
    this.getParcelaSub.unsubscribe();
    sessionStorage.removeItem('excelFilterParam');
    sessionStorage.removeItem('parcelas_pdf');
    sessionStorage.removeItem('informations_pdf');
    sessionStorage.removeItem('parcelas-total-values');
    sessionStorage.removeItem('excelFilterCodContract');
  }

}
