<div class="fibra-card">

  <!-- Fibra-Stepper -->
  <div class="fibra-stepper" *ngIf="showHeader">
    <div class="container">
      <div class="row">
        <div class="col reposicionamento" *ngFor="let step of stepObj">
          <span [ngClass]="
            currentStep === step.step && !titleSans
              ? 'fibra-stepper-circle-number-active fibra-stepper-circle-number d-inline-flex'
              : currentStep === step.step && titleSans
                ? 'fibra-stepper-circle-number-active fibra-stepper-title-sans fibra-stepper-circle-number d-inline-flex'
                : titleSans
                  ? 'fibra-stepper-title-sans fibra-stepper-circle-number d-inline-flex'
                  : 'fibra-stepper-circle-number d-inline-flex'
          ">{{ step.step }}</span>
          <p [ngClass]="
            currentStep === step.step && !titleSans
              ? 'fibra-stepper-title-active fibra-stepper-title d-inline'
              : currentStep === step.step && titleSans
                ? 'fibra-stepper-title-active fibra-stepper-title-sans fibra-stepper-title d-inline'
                : titleSans
                  ? 'fibra-stepper-title-sans fibra-stepper-title d-inline'
                  : 'fibra-stepper-title d-inline'
          ">{{ step.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Fibra-Stepper / End -->

  <!-- Content -->
  <div class="fibra-content">
    <div class="card-content" *ngIf="currentStep === 1">
      <ng-container *ngTemplateOutlet="firstStep"></ng-container>
    </div>
    <div class="card-content" *ngIf="currentStep === 2">
      <ng-container *ngTemplateOutlet="secondStep"></ng-container>
    </div>
    <div class="card-content" *ngIf="currentStep === 3">
      <ng-container *ngTemplateOutlet="thirdStep"></ng-container>
    </div>
  </div>
  <!-- Content / End -->

</div>