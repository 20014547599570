import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// header
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { TransactionLimitRepository } from '@src/core/repositories/transactional-limits/transactional-limits.repository';
import { LimitModel } from '@src/core/domain/limits/limits.model';

@Injectable({
    providedIn: 'root',
})

export class GetAccountLimitRepositoryData extends TransactionLimitRepository {

    constructor(private http: HttpClient) {
        super();
    }

    getLimitAccount(params: any): Observable<LimitModel> {
        const httpOptions = {
            headers: getHeader(),
        };

        //return this.http.post<LimitModel>(`${environment.URLBase}v1/limiteTransacional/disclaimer`, postParams, httpOptions);
        //return this.http.get<LimitModel>(`${environment.URLBase}v1/LimiteTransacional/Consultar/PIX`, httpOptions);
        return this.http.get<LimitModel>(`${environment.URLBase}v1/LimiteTransacional/ativos/${params}`, httpOptions);
    }
    
}
