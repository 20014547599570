import { Mapper } from '@src/core/base/mapper';
import {AdminUserEntity, AdminUserListEntity, AdminUserDadosEntity} from './admin-user.entity';
import { AdminGroupListEntity } from './admin-user.entity';
import {AdminUserListModel, AdminUserModel} from '@src/core/domain/admin-user/admin-user.model';
import { AdminGroupListModel } from '@src/core/domain/admin-user/admin-user.model';

export class AdminUserRepositoryMapper extends Mapper<
    AdminUserModel,
    AdminUserEntity
    > {
  mapFrom(param: AdminUserEntity): AdminUserModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: AdminUserModel): AdminUserEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}

export class AdminUserListRepositoryMapper extends Mapper<
  AdminUserListModel,
  AdminUserListEntity
> {
  mapFrom(param: AdminUserListEntity): AdminUserListModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: AdminUserListModel): AdminUserListEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}

export class AdminGroupRepositoryMapper extends Mapper<
    AdminGroupListModel,
    AdminGroupListEntity
    > {
  mapFrom(param: AdminGroupListEntity): AdminGroupListModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: AdminGroupListModel): AdminGroupListEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}

export class AdminUserDadosRepositoryMapper extends Mapper<
    AdminUserModel,
    AdminUserDadosEntity
    > {
  mapFrom(param: AdminUserDadosEntity): AdminUserModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: AdminUserModel): AdminUserDadosEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}