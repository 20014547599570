<div class="newtransaction">
    <div class="row">
        <div class="col-sm-12">
            <section  class="origin">
                <span style="color: #8F969D;">A {{ consent.organizationName }} precisa da sua autorização para realizar transferências inteligentes da sua conta, de acordo com as regras abaixo.</span>
            </section>

            <section>
                <h1 class="group-header">Regras da autorização</h1>
                <label class="value" style="font-weight: bold;">As transferências apenas serão autorizadas para contas do mesmo titular</label>
            </section>

            <section>
                <h1 class="group-header">Dados do recebedor</h1>

                <div *ngIf="isCreditorsArray">
                    <div class="caption">Razão Social</div>    
                    <label class="value">{{consent.creditors[0].name}}</label>

                    <div class="caption">Nome Fantasia</div>    
                    <label class="value">{{consent.creditors[0].name}}</label>
                    
                    <div class="caption">{{consent.creditors[0].personType == 'PESSOA_JURIDICA' ? 'CNPJ' : 'CPF'}}</div>    
                    <label class="value">{{cpfCnpjCreditor}}</label>   
                </div>

                <div *ngIf="!isCreditorsArray">
                    <div class="caption">Razão Social</div>    
                    <label class="value">{{consent.creditor.name}}</label>

                    <div class="caption">Nome Fantasia</div>    
                    <label class="value">{{consent.creditor.name}}</label>
                    
                    <div class="caption">{{consent.creditor.personType == 'PESSOA_JURIDICA' ? 'CNPJ' : 'CPF'}}</div>    
                    <label class="value">{{cpfCnpjCreditor}}</label>   
                </div>
                
            </section>

            <section >
                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="group-header">Conta para débito</h1>
                    </div>
                </div>
                <fibra-slide-account-selector-pix *ngIf="!loadingAccounts" [data]="accounts.items" [active]="active" [showButton]="showButton" (emitAccount)="selectAccount($event)"></fibra-slide-account-selector-pix>
                <div *ngIf="loadingAccounts"  class="d-flex justify-content-center position loading-container">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </section>

            <!--<section>
                <h1 class="group-header">Descrição da autorização</h1>
            </section>-->

            <!--<section>
                <h1 class="group-header">Solicitante</h1>
            </section>-->

            <section>
                <h1 class="group-header">Iniciador das movimentações</h1>
                <label class="value">{{consent.organizationName}}</label>
            </section>

            <section>
                <h1 class="group-header">Tipo de transação</h1>

                <div class="caption">Open Finance - Transferência Inteligente</div> 
                <label class="value">PIX</label>
            </section>

            <div *ngIf="consent.kind == 'AUTOMATIC_PAYMENT'">
                <div *ngIf="consent.recurringConfiguration.sweeping && (consent.recurringConfiguration.sweeping | json) != '{}'">
                    <section *ngIf="consent.recurringConfiguration.sweeping.transactionLimit">
                        <h1 class="group-header">Limite por transação</h1>
                        <label class="value">{{currencyStr}}  {{consent.recurringConfiguration.sweeping.transactionLimit}}</label>
                    </section>

                    <section *ngIf="consent.recurringConfiguration.sweeping.periodicLimits?.day">
                        <h1 class="group-header">Limite diário</h1>
                        <label class="value">{{currencyStr}}  {{consent.recurringConfiguration.sweeping.periodicLimits.day.transactionLimit | number: '1.2-2'}}</label>
                    </section>

                    <section *ngIf="consent.recurringConfiguration.sweeping.periodicLimits?.week">
                        <h1 class="group-header">Limite semanal</h1>
                        <label class="value">{{currencyStr}} {{consent.recurringConfiguration.sweeping.periodicLimits.week.transactionLimit | number: '1.2-2'}}</label>
                    </section>

                    <section *ngIf="consent.recurringConfiguration.sweeping.periodicLimits?.month">
                        <h1 class="group-header">Limite mensal</h1>
                        <label class="value">{{currencyStr}}  {{consent.recurringConfiguration.sweeping.periodicLimits.month.transactionLimit | number: '1.2-2'}}</label>
                    </section>
                </div>
            </div>

            <section *ngIf="consent.startDateTime">
                <h1 class="group-header">Data de início</h1>
                <label class="value">{{consent.startDateTime | date: 'dd/MM/yyyy'}}</label>
            </section>

            <section *ngIf="consent.expirationDateTime">
                <h1 class="group-header">Término</h1>
                <label class="value">{{consent.expirationDateTime | date: 'dd/MM/yyyy'}}</label>
            </section>

            <section *ngIf="interactionId">
                <h1 class="group-header">ID da autorização</h1>
                <label class="value">{{interactionId}}</label>
            </section>


            <section>
                <h1 class="group-header">Autorização</h1>
                <label class="value">Será necessária a aprovação de todos os usuários masters para</label>
                <label class="value">concluir a autorização de transferências inteligentes.</label>
            </section>
            
            <section class="importante">
                <span class="importante">Importante:</span>
                <span> ao confirmar essa autorização você será redirecionado para a instituição iniciadora de transferências inteligentes.</span>
            </section>
        </div>        
    </div>
    <div  class="row buttons">
        <div class="col-sm-12">
            <button type="button" class="continue" [class.busy]="busy" [disabled]="!(accounts?.items.length > 0) || selectedAccount == null" (click)="ok()">{{busy ? '' : 'Continuar com acesso'}}
                <div *ngIf="busy" class="spinner-border continue" role="status"></div>
            </button>
            <button type="button" class="cancel" (click)="cancel()">
                Cancelar operação
            </button>
        </div>
    </div>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
<fibra-modal-cheque-empresa-pix *ngIf="chequeEmpresaPix" (closeEvent)="closeModalPix($event)" [conta]="selectedAccount.desc_conta" [valor]="valorChequeEmpresa"></fibra-modal-cheque-empresa-pix>