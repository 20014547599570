import { Component, OnInit, ViewChild } from '@angular/core';
import { ScriptService } from '@src/core/base/script.service';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { ChatRepository } from '@src/data/repository/chat-repository/chat.repository';
import { RolesService } from '@src/shared/services/roles.service';
import { isArray } from 'util';
// import './chat.js'

@Component({
  selector: 'fibra-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']

})
export class ChatComponent implements OnInit {
  public company: any;
  public isLoading: boolean = true;
  public email: string;
  private window: any;

  constructor(private sharedCompany: CompanyDataShare, private script: ScriptService, private chatService: ChatRepository, private roleService: RolesService) { }

  private getWindow(): any {
    return window;
  }

  ngOnInit() {
    this.window = this.getWindow();
    this.sharedCompany.dataShared.subscribe((res) => {
      if (res !== undefined && isArray(res.companyList)) {
        this.company = res.companyList.filter((e) => e.selected)[0];
        this.email = localStorage.getItem('useremail');
        this.script.load('crypto-js', 'hmac-sha256', 'base64', 'ze-snippet').then(data => {
          this.isLoading = false;
          let tipoAcesso = this.roleService.get().includes('MASTER')? "MASTER": "OPERADOR";
          this.chatService.getToken().subscribe(c => {
            this.initChat(c.data.token_chat, this.window, this.company, tipoAcesso);
          });
        }).catch(error => console.log(error));

      }
    });
  }

  initChat(token: string, window: any, company: any, tipoAcesso: string) {
    this.window.zESettings = {
      webWidget: {
        position: { horizontal: 'right' }, 
        launcher: {
          label: {
            '*': 'Converse conosco'
          }
        },
       /* color: {
          theme: '#4D86BF',
          launcherText: '#FFFFFF',
          title: '#FFFFFF',
          titleText: '#FFFFFF',
        },*/
        authenticate: {
          chat: {
            jwtFn: function (callback) {
              //console.log('CHAT')
              //this.window.$zopim.livechat.addTags(this.company.name)
              //this.window.$zopim.livechat.addTags(this.company.cpfCnpj)
              
              callback(token);
            }
          }
        }
      }
    };

    this.window.zE('webWidget:on', 'open', function() {
      window.$zopim.livechat.addTags(company.name);
      window.$zopim.livechat.addTags(company.cpfCnpj);
      window.$zopim.livechat.addTags(tipoAcesso);
    });
    
    
  }
}

