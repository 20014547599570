import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fibra-approval-matrix',
  templateUrl: './approval-matrix.component.html',
  styleUrls: ['./approval-matrix.component.scss']
})
export class ApprovalMatrixComponent implements OnInit {
  params = [];
  constructor() { }

  ngOnInit() {
  }

}
