<section class="sideCard" *ngIf = "options && options.length > 0">
  <h1 class="title">Totais</h1>
  <div class="side-card-content">
    <div *ngFor = "let value of options">
      <div class="list" id="sideCardFirstRow">
        <div class="item">
          <p>
            <span>{{value.swift}}</span>{{ (value.total | number: '1.2-2' ) }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="loading-container d-flex justify-content-center align-items-center" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div> -->
</section>