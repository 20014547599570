import { Injectable } from '@angular/core';
import { QueryRepository } from '@src/core/repositories/query/query.repository';
import { from, Observable } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { InstructionRepository } from '@src/core/repositories/query/instruction.repository';
import { QueryRepositoryMapper } from './list-query-repository-mapper';
import {
  ListQueryModel,
  ListQueryRequest,
  ListQueryInfoTitleRequest,
  ListQueryRequestExcel,
  PdfExportListQueryModel
} from '@src/core/domain/query/query.model';
import { ListQueryEntity } from './query.entity';

// environment

@Injectable({
  providedIn: 'root',
})
export class QueryDataRepository extends QueryRepository {
  mapper = new QueryRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllQuery(obj: any): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

    return this.http
      .post(
        `${environment.URLBase}v1/arquivostransito`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getAuthorizedQuery(obj: any): Observable<ListQueryModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

    return this.http
      .post(
        `${environment.URLBase}v1/arquivostransito/aprovados`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getPendingQuery(obj: any): Observable<ListQueryModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

    return this.http
      .post(
        `${environment.URLBase}v1/arquivostransito/emanalise`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getRefusedQuery(obj: any): Observable<ListQueryModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

    return this.http
      .post(
        `${environment.URLBase}v1/arquivostransito/recusados`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getInfoTitleQuery(obj: any): Observable<ListQueryModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    
    const params = obj;

    return this.http
      .post(
        `${environment.URLBase}v1/Titulos/TituloDetalheCobranca`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }


  getInfoTransitTitleQuery(obj: any): Observable<ListQueryModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    
    const params = obj;

    return this.http
      .post(
        `${environment.URLBase}v1/ArquivosTransito/detalhes`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }


  getAllQueryExcelExport(obj: any): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
      responseType: 'blob' as 'json'
    };
    const params = obj;
    let paramUrl = obj.view;
    delete params.view;
    

    return this.http
      .post(
        `${environment.URLBase}v1/${paramUrl}`,
        params,
        httpOptions
      );

  }


}
