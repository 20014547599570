<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>

<div class="content-page" [formGroup]="transferData">
  <fibra-breadcrumb
    bottom="-120px"
    infoAccount="Transações"
    roule="Transferências"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb>
  <div class="body-content">
    <div class="arrow" (click)="toast.callModalMessage('retorno')">
      <img src="/assets/svg/seta-left.svg" alt="" />
      <label>Voltar</label>
    </div>
  </div>

  <fibra-transaction-information
    [typeTransfer]="typeTransfer"
    (errorCpfCnpj)="errorToast($event)"
    (errorValue)="errorToastValue($event)"
    (errorAccountFibra)="errorToastAccountFibra($event)"
    (optionYes)="navigateBack()"
  ></fibra-transaction-information>
  <fibra-toast-error-message
    (optionYes)="navigateBack()"
  ></fibra-toast-error-message>
</div>
