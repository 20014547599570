import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PixCreateKeyDataRepository } from '@src/data/repository/pix/keys/pix-create-keys-repository';
import { PixDeleteKeysDataRepository } from '@src/data/repository/pix/keys/pix-delete-keys-repository';
import { PixGetKeysDataRepository } from '@src/data/repository/pix/keys/pix-get-keys-repository';
import { FormControl } from '@angular/forms';
import { RolesService } from '@src/shared/services/roles.service';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-pix-chaves-cadastro',
  templateUrl: './pix-chaves-cadastro.component.html',
  styleUrls: ['./pix-chaves-cadastro.component.scss']
})
export class PixChavesCadastroComponent implements OnInit {
  @ViewChild('contentSms', { static: true }) contentSms: HTMLElement;
  @ViewChild('contentEmail', { static: true }) contentEmail: HTMLElement;
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  
  @Output() changeToKeys = new EventEmitter();

  private _isMaster: boolean;

  public codValidacao = '';
  public cadastro = false;
  public enable = false;
  public loading = false;
  public finalizado = false;
  public cadastroEmail = false;
  public enableCel = false;
  public numCelular = '';
  public email = '';
  public timeoutToken = false;
  public document = '';
  public tokenMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public validEmail = false;
  public token = '';
  public docType = '';
  public keyType = '';
  public cadastroCelular = false;
  public cadastroDocumento = false;
  public validDocument = false;
  public warnType = '';
  public minutes = 1;
  public interval;
  public counter = 0;
  public cadastroAleatorio = false;
  public arrayMessages = {
    chaveJaVinculada:
    {
      title: 'Chave vinculada a uma conta em outra instituição',
      subtitle: 'Solicite a portabilidade desta chave. Após solicitar a portabilidade, você terá 7 dias para confirmar a alteração na instituição em que ela está cadastrada.',
      button1: 'Cadastrar outra chave',
      button2: 'Solicitar Portabilidade',
      type: 'warn',
      key: this.numCelular
    },
    portabilidadePendente: {
      title: 'Portabilidade de chave pendente',
      subtitle: 'Solicitação enviada para aprovação do(s) master(s) da sua empresa.',
      button1: 'Cadastrar outra chave',
      button2: 'Ver status da solicitação',
      type: 'warn',
      key: this.numCelular
    },
    chaveJaCadastrada: {
      title: 'Esta chave foi cadastrada por outro usuário.',
      subtitle: 'Solicite a reivindicação da chave. Após solicitação de reivindicação, o usuário que está de posse da chave terá o prazo de 7 dias para resposta.',
      button1: 'Cadastrar outra chave',
      button2: 'Solicitar reivindicação',
      type: 'warn',
      key: this.numCelular
    },
    reivindicaçãoPendente: {
      title: 'Esta chave foi cadastrada por outro usuário.',
      subtitle: 'Solicite a reivindicação da chave. Após solicitação de reivindicação, o usuário que está de posse da chave terá o prazo de 7 dias para resposta.',
      button1: 'Cadastrar outra chave',
      button2: 'Solicitar reivindicação',
      type: 'warn',
      key: this.numCelular
    },
    cadastroChavePendente: {
      title: 'Cadastro de chave pendente',
      subtitle: 'Solicitação enviada para aprovação do(s) master(s) da sua empresa.',
      button1: 'Cadastrar outra chave',
      button2: 'Acompanhar status',
      type: 'warn',
      key: this.numCelular
    },
    aceiteTermosPixPendente:
    {
      title: 'Aceite ao Termo do Pix pendente',
      subtitle: 'Para cadastrar uma chave, o(s) master(s) da sua empresa precisa(m) aceitar o Termo de Adesão do Pix.',
      button1: 'Fechar',
      button2: 'Acompanhar status',
      type: 'warn',
      key: this.numCelular
    },
    chavePixCadastrada:
    {
      title: 'Chave Pix Cadastrada',
      subtitle: `Compartilhe a chave minhachave sempre que quiser receber uma transferência.`,
      button1: 'Fazer uma transferência',
      button2: 'Voltar',
      type: 'success',
      key: this.numCelular
    }, userOtherKey: {
      title: 'Esta chave pertence a outro usuário',
      subtitle: "Solicite a reivindicação desta chave. Após solicitar a reivindicação, o usuário que está de posse da chave terá 7 dias para responder a solicitação.",
      button1: 'Cadastrar outra chave',
      button2: 'Solicitar reivindicação',
      type: 'warn',
      key: this.numCelular
    }
  };

  public warnTitle = '';
  public warnSubtitle = '';
  public button1 = '';
  public button2 = '';
  public btnFunction1 = "";
  public btnFunction2 = ""

  public apiCallback = 88;
  public payload = 3;
  public showModalValidation = false;

  public getAccountInformationSession: any;
  public msgError = "Ocorreu um erro no envio";
  public subTextError = "Tente novamente!"

  public responseCreateKey;
  public limitsNumberKeys;
  public keysCreateStatus;

  isCodInvalid = false;

  public isMyDocuments = true;
  public isLimitKey = true;

  public isKeyCreated = false;

  public totalKeys: number;
  public typeUser: string;
  public phone = new FormControl();
  public isPortability = false;

  public isScheduling = false;

  public isNumConta: number;
  public permissoes;
  public semPermissaoMsg: boolean = true;
  public hideButton2 = false;

  constructor(public createKeyService: PixCreateKeyDataRepository,
    public deleteKeyService: PixDeleteKeysDataRepository,
    public getKeys: PixGetKeysDataRepository,
    public modalService: NgbModal,
    private roles: RolesService
  ) { 
    this._isMaster = Boolean(localStorage.getItem('acessoMaster'));
  }

  ngOnInit() {
    this.permissoes = this.roles.get();
    this.loading = true;
    this.getAccountInformationSession = JSON.parse(sessionStorage.getItem('defaultCompany'));
    this.typeUser = this.getAccountInformationSession[0].tipo_pessoa

    this.getKeysOnInit();


    if (localStorage.getItem('cadastro-atual') !== 'false') {
      if (localStorage.getItem('cadastro-atual') === 'celular') {
        this.cadastro = true;
        this.cadastroCelular = true;
        this.keyType = 'celular';
      } else if (localStorage.getItem('cadastro-atual') === 'email') {
        this.cadastro = true;
        this.cadastroEmail = true;
        this.keyType = 'email';
      } else if (localStorage.getItem('cadastro-atual') === 'document') {
        this.cadastro = true;
        this.cadastroDocumento = true;
        this.document = this.getAccountInformationSession[0].cpfCnpj;
        this.keyType = 'document';
      } else if (localStorage.getItem('cadastro-atual') === 'aleatorio') {
        this.cadastro = true;
        this.cadastroAleatorio = true;
        this.keyType = 'aleatorio';
      }
    }
  }

  public limitKeys() {
    if (this.typeUser === 'PF') {
      if (this.totalKeys === 5) {
        return false;
      } else {
        return true;

      }
    } else if (this.typeUser === 'PJ') {
      if (this.totalKeys === 20) {
        return false;
      } else {
        return true;

      }
    }
    // this.getKeys.getLimitKeys().subscribe((data: any) => {
    //   this.isLimitKey = data['data']
    //   this.loading = false;
    // }, error => {
    //   console.log(error)
    //   this.loading = false;
    // })

  }

  public getKeysOnInit() {
    this.loading = true;
    this.getKeys.getKeys().subscribe((keys: any) => {
      if (keys.data.keys.length > 0) {
        const data = keys.data;
        let object = data['keys']
        let emailKeys = new Array();
        let documentKeys = new Array();
        let randomKeys = new Array();
        let smartPhoneKeys = new Array();
        object.forEach((res) => {

          if (res.chave_tipo === 'EMAIL') {
            emailKeys.push(res);
          } else if (res.chave_tipo === 'CPF' || res.chave_tipo === 'CNPJ') {
            documentKeys.push(res);
          } else if (res.chave_tipo === 'EVP') {
            randomKeys.push(res);
          } else {
            smartPhoneKeys.push(res);
          }
        })
        this.totalKeys = emailKeys.length + documentKeys.length + randomKeys.length + smartPhoneKeys.length;
        this.loading = false;
      } else {
        this.loading = false;
      }

    }, (err) => {
      if (err['status'] === 404) {
        let object = [];
        let emailKeys = new Array();
        let documentKeys = new Array();
        let randomKeys = new Array();
        let smartPhoneKeys = new Array();
        object.forEach((res) => {
          if (res.chave_tipo === 'EMAIL') {
            emailKeys.push(res);
          } else if (res.chave_tipo === 'CPF' || res.chave_tipo === 'CNPJ') {
            documentKeys.push(res);
          } else if (res.chave_tipo === 'EVP') {
            randomKeys.push(res);
          } else {
            smartPhoneKeys.push(res);
          }
        })

        this.totalKeys = emailKeys.length + documentKeys.length + randomKeys.length + smartPhoneKeys.length;
        this.loading = false;

      } else {
        this.loading = false;
      }

    })

  }

  public chooseKey(key: string) {
    this.isKeyCreated = false;
    this.document = this.getAccountInformationSession[0].cpfCnpj;
    if (key === 'celular') {
      this.cadastroCelular = true;
      this.cadastroEmail = false;
      this.cadastroDocumento = false;
      this.cadastroAleatorio = false;
      this.keyType = 'celular';
      this.cadastro = !this.cadastro;
    } else if (key === 'email') {
      this.cadastroCelular = false;
      this.cadastroEmail = true;
      this.cadastroDocumento = false;
      this.cadastroAleatorio = false;
      this.keyType = 'email';
      this.cadastro = !this.cadastro;
    } else if (key === 'documento') {
      this.cadastroCelular = false;
      this.cadastroEmail = false;
      this.cadastroDocumento = true;
      this.cadastroAleatorio = false;
      this.keyType = 'documento';
      this.cadastro = !this.cadastro;
    } else if (key === 'aleatorio') {
      this.cadastroCelular = false;
      this.cadastroEmail = false;
      this.cadastroDocumento = false;
      this.cadastroAleatorio = true;
      this.keyType = 'aleatorio';
      this.cadastro = !this.cadastro;
    }
  }

  public goBackToKeys() {
    this.cadastro = !this.cadastro;
    this.isMyDocuments = true;
  }

  public goBack() {
    this.isMyDocuments = true;
    this.cadastroEmail = false;
    this.cadastroAleatorio = false;
    this.cadastroEmail = false;
    this.cadastroCelular = false;
    localStorage.setItem('view-selecionada', 'minhaschaves-chaves');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public goToApproval(subMenu: string) {
    localStorage.setItem('view-selecionada', `aprovacoes-${subMenu}`);
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public goToTranfer() {
    localStorage.setItem('view-selecionada', 'transferir-beneficiarios');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  mphone() {
    this.numCelular = this.phone.value;
    if (this.numCelular.length < 11) {
      this.enableCel = false;
    } else {
      this.enableCel = true;
    }
  }

  public cadastrarChave() {
    this.subTextError = "Tente novamente!"
    this.msgError = "Ocorreu um erro no envio";
    this.loading = true;
    this.isCodInvalid = false;
    this.apiCallback = 88;
    this.isKeyCreated = false;
    this.isPortability = false;
    this.isScheduling = false;
    this.isNumConta = 0;
    this.semPermissaoMsg = true;
    this.hideButton2 = false;

    if (!this.permissoes.includes('PIX.CAD_CHAVE')) {
      this.loading = false;
      this.msgError = "Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.";
      this.semPermissaoMsg = false;
      const message = document.getElementById('message');
      message.classList.add('show');
    } else {
      if (this.keyType === 'celular') {
        this.createKeyService.createKey('PHONE', this.codValidacao, this.token, `+55${this.numCelular.replace(/[^0-9\/]/g, '')}`).subscribe((data) => {
          this.responseCreateKey = data;
          this.modalService.dismissAll();
          if (data['data'].fl_posse) {
            this.modalService.dismissAll();
            this.keysCreateStatus = 'REIVINDICACAO';
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.warnType = this.arrayMessages.reivindicaçãoPendente.type;
            this.warnTitle = this.arrayMessages.reivindicaçãoPendente.title;
            this.warnSubtitle = this.arrayMessages.reivindicaçãoPendente.subtitle.replace('minhachave', this.numCelular);
            this.button1 = this.arrayMessages.reivindicaçãoPendente.button1;
            this.button2 = this.arrayMessages.reivindicaçãoPendente.button2;
            this.finalizado = true;
            this.loading = false;
            this.btnFunction1 = 'goback'
            this.btnFunction2 = 'solicitanionRequest';
          } else if (data['data'].fl_portabilidade) {
            this.modalService.dismissAll();
            this.keysCreateStatus = 'PORTABILIDADE';
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.warnType = this.arrayMessages.chaveJaVinculada.type;
            this.warnTitle = this.arrayMessages.chaveJaVinculada.title;
            this.warnSubtitle = this.arrayMessages.chaveJaVinculada.subtitle.replace('minhachave', this.numCelular);
            this.button1 = this.arrayMessages.chaveJaVinculada.button1;
            this.button2 = this.arrayMessages.chaveJaVinculada.button2;
            this.finalizado = true;
            this.loading = false;
            //this.isPortability  = true;
            this.btnFunction1 = 'goback'
            this.btnFunction2 = 'solicitanionRequest';
            this.isNumConta = data['data'].num_conta;
            // this.btnFunction1 = 'goback'
            // this.btnFunction2 = 'solicitanionRequest';

          } else if (data['data'].status === 'APROVADO') {
            this.keysCreateStatus = 'APROVADO';
            this.showModalValidation = true;
            this.payload = this.responseCreateKey['data'].cod_operacao_chave
          } else if (data['data'].status === 'PENDENTE') {

            this.keysCreateStatus = 'APROVADO';
            this.showModalValidation = true;
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.apiCallback = 92;


          }
        }, (err) => {
          if (err.status === 400 && err.error.message[0] === "Falha ao realizar a validação positiva.") {
            this.subTextError = "Falha ao realizar a validação positiva."
            this.isCodInvalid = true;
            this.loading = false;
          } else if (err.status === 400 && err.error.message[0] === "OPERACAO_PENDENTE_JACADASTRADA_CONTA") {
            this.modalService.dismissAll();
            this.loading = false;
            this.isKeyCreated = true;

          } else if (err.status === 400 && JSON.parse(err.error.message[0].replaceAll('u0022', '"')).cod_direcionamento === "CHAVE_JA_NA_CONTA") {
            this.modalService.dismissAll();
            this.loading = false;
            this.isKeyCreated = true;
          } else if (err.status === 400 && JSON.parse(err.error.message[0].replaceAll('u0022', '"')).cod_direcionamento === "PORTABILIDADE_INTRAPSP") {
            this.modalService.dismissAll();
            let conta = JSON.parse(err.error.message[0].replaceAll('u0022', '"'))
            this.isNumConta = conta.informacao_adicional_conta.conta;
            this.keysCreateStatus = 'PORTABILIDADE';
            this.finalizado = true;
            this.loading = false;
            //this.isPortability = true;
            this.warnType = this.arrayMessages.chaveJaVinculada.type;
            this.warnTitle = `Chave vinculada à conta ${conta['informacao_adicional_conta'].conta}`;
            this.warnSubtitle = 'Para utilizar a mesma chave, esta deverá ser excluída da outra conta.';
            this.button1 = this.arrayMessages.chaveJaVinculada.button1;
            this.button2 = this.arrayMessages.chaveJaVinculada.button2;
            this.finalizado = true;
            this.loading = false;
            this.btnFunction1 = 'goback'
            this.btnFunction2 = 'solicitanionRequest';
          } else if (err.status === 400 && err.error.message[0].includes('MIP_ADD_001')) {
            this.loading = false;
            const message = document.getElementById('message');
            this.msgError = "Não é possível efetuar o cadastro. Chave em processo de portabilidade ou reivindicação.";
            this.subTextError = null;
            message.classList.add('show');
          } else {
            this.loading = false;
            const message = document.getElementById('message');
            this.msgError = "Ocorreu um erro no envio";
            message.classList.add('show');
          }
        });
      } else if (this.keyType === 'email') {
        this.createKeyService.createKey('EMAIL', this.codValidacao, this.token, this.email).subscribe((data) => {
          this.responseCreateKey = data;
          this.isNumConta = data['data'].num_conta;
          this.modalService.dismissAll();
          if (data['data'].fl_posse) {
            this.modalService.dismissAll();
            this.keysCreateStatus = 'REIVINDICACAO';
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.warnType = this.arrayMessages.reivindicaçãoPendente.type;
            this.warnTitle = this.arrayMessages.reivindicaçãoPendente.title;
            this.warnSubtitle = this.arrayMessages.reivindicaçãoPendente.subtitle.replace('minhachave', this.numCelular);
            this.button1 = this.arrayMessages.reivindicaçãoPendente.button1;
            this.button2 = this.arrayMessages.reivindicaçãoPendente.button2;
            this.finalizado = true;
            this.loading = false;
            this.btnFunction1 = 'goback'
            this.btnFunction2 = 'solicitanionRequest';
          } else if (data['data'].fl_portabilidade) {
            this.modalService.dismissAll();
            this.keysCreateStatus = 'PORTABILIDADE';
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.warnType = this.arrayMessages.chaveJaVinculada.type;
            this.warnTitle = this.arrayMessages.chaveJaVinculada.title;
            this.warnSubtitle = this.arrayMessages.chaveJaVinculada.subtitle.replace('minhachave', this.numCelular);
            this.button1 = this.arrayMessages.chaveJaVinculada.button1;
            this.button2 = this.arrayMessages.chaveJaVinculada.button2;
            this.finalizado = true;
            this.loading = false;
            //this.isPortability = true;
            this.btnFunction1 = 'goback'
            this.btnFunction2 = 'solicitanionRequest';

          } else if (data['data'].status === 'APROVADO') {
            this.keysCreateStatus = 'APROVADO';
            this.showModalValidation = true;
            this.payload = this.responseCreateKey['data'].cod_operacao_chave
          } else if (data['data'].status === 'PENDENTE') {
            this.keysCreateStatus = 'APROVADO';
            this.showModalValidation = true;
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.apiCallback = 92;
          }
        }, (err) => {
          if (err.status === 400 && err.error.message[0] === "Falha ao realizar a validação positiva.") {
            this.subTextError = "Falha ao realizar a validação positiva."
            this.isCodInvalid = true;
            this.loading = false;
          } else if (err.status === 400 && err.error.message[0] === "OPERACAO_PENDENTE_JACADASTRADA_CONTA") {
            this.modalService.dismissAll();
            this.loading = false;
            this.isKeyCreated = true;
          } else if (err.status === 400 && JSON.parse(err.error.message[0].replaceAll('u0022', '"')).cod_direcionamento === "CHAVE_JA_NA_CONTA") {
            this.modalService.dismissAll();
            this.isKeyCreated = true;
            this.loading = false;
          } else if (err.status === 400 && JSON.parse(err.error.message[0].replaceAll('u0022', '"')).cod_direcionamento === "PORTABILIDADE_INTRAPSP") {
            this.modalService.dismissAll();
            let conta = JSON.parse(err.error.message[0].replaceAll('u0022', '"'))
            this.isNumConta = conta.informacao_adicional_conta.conta;
            this.keysCreateStatus = 'PORTABILIDADE';
            this.finalizado = true;
            this.loading = false;
            //this.isPortability = true;
            this.warnType = this.arrayMessages.chaveJaVinculada.type;
            this.warnTitle = `Chave vinculada à conta ${conta['informacao_adicional_conta'].conta}`;
            this.warnSubtitle = 'Para utilizar a mesma chave, esta deverá ser excluída da outra conta.';
            this.button1 = 'Fechar';
            this.finalizado = true;
            this.loading = false;
            this.btnFunction1 = 'goback'
            this.hideButton2 = true;
          } else if (err.status === 400 && err.error.message[0].includes('MIP_ADD_001')) {
            this.loading = false;
            const message = document.getElementById('message');
            this.msgError = "Não é possível efetuar o cadastro. Chave em processo de portabilidade ou reivindicação.";
            this.subTextError = null;
            message.classList.add('show');
          } else {
            this.loading = false;
            const message = document.getElementById('message');
            this.msgError = "Ocorreu um erro no envio";
            message.classList.add('show');
          }
        });
      } else if (this.keyType === 'aleatorio') {
        this.createKeyService.createKey('EVP').subscribe((data) => {
          this.responseCreateKey = data;

          if (data['data'].status === 'PENDENTE') {
            this.keysCreateStatus = 'APROVADO';
            this.showModalValidation = true;
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.apiCallback = 92;
          }

          if (data['data'].status === 'APROVADO') {
            this.keysCreateStatus = 'APROVADO';
            this.payload = this.responseCreateKey['data'].cod_operacao_chave
            this.showModalValidation = true;
          }
        }, (err) => {
          this.loading = false;
          const message = document.getElementById('message');
          this.msgError = "Ocorreu um erro no envio";
          message.classList.add('show');
        });
      } else {
        this.getAccountInformationSession[0].tipo_pessoa === 'PF' ? this.docType = 'CPF' : this.docType = 'CNPJ';
        this.createKeyService.createKey(this.docType, null, null, this.document.replace(/[^\d]+/g, '')).subscribe((data) => {
          this.responseCreateKey = data;
          if (data['data'].fl_posse) {
            this.modalService.dismissAll();
            this.keysCreateStatus = 'REIVINDICACAO';
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.warnType = this.arrayMessages.reivindicaçãoPendente.type;
            this.warnTitle = this.arrayMessages.reivindicaçãoPendente.title;
            this.warnSubtitle = this.arrayMessages.reivindicaçãoPendente.subtitle.replace('minhachave', this.numCelular);
            this.button1 = this.arrayMessages.reivindicaçãoPendente.button1;
            this.button2 = this.arrayMessages.reivindicaçãoPendente.button2;
            this.finalizado = true;
            this.loading = false;
            this.btnFunction1 = 'goback'
            this.btnFunction2 = 'solicitanionRequest';
          } else if (data['data'].fl_portabilidade) {
            this.modalService.dismissAll();
            this.keysCreateStatus = 'PORTABILIDADE';
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.warnType = this.arrayMessages.chaveJaVinculada.type;
            this.warnTitle = this.arrayMessages.chaveJaVinculada.title;
            this.warnSubtitle = this.arrayMessages.chaveJaVinculada.subtitle.replace('minhachave', this.numCelular);
            this.button1 = this.arrayMessages.chaveJaVinculada.button1;
            this.button2 = this.arrayMessages.chaveJaVinculada.button2;
            this.finalizado = true;
            this.loading = false;
            //this.isPortability = true;
            this.isNumConta = data['data'].num_conta;
            this.btnFunction1 = 'goback'
            this.btnFunction2 = 'solicitanionRequest';

          } else if (data['data'].status === 'APROVADO') {
            this.keysCreateStatus = 'APROVADO';
            this.showModalValidation = true;
            this.payload = this.responseCreateKey['data'].cod_operacao_chave
          } else if (data['data'].status === 'PENDENTE') {

            this.keysCreateStatus = 'APROVADO';
            this.showModalValidation = true;
            this.payload = this.responseCreateKey['data'].cod_operacao_chave;
            this.apiCallback = 92;


          }
        }, (err) => {
          if (err.status === 400 && err.error.message[0] === "OPERACAO_PENDENTE_JACADASTRADA_CONTA") {
            this.modalService.dismissAll();
            this.loading = false;
            this.isKeyCreated = true;
          } else if (err.status === 400 && JSON.parse(err.error.message[0].replaceAll('u0022', '"')).cod_direcionamento === "PORTABILIDADE_INTRAPSP") {
            this.modalService.dismissAll();
            let conta = JSON.parse(err.error.message[0].replaceAll('u0022', '"'))
            this.isNumConta = conta.informacao_adicional_conta.conta;
            this.keysCreateStatus = 'PORTABILIDADE';
            this.finalizado = true;
            this.loading = false;
            //this.isPortability = true;
            this.warnType = this.arrayMessages.chaveJaVinculada.type;
            this.warnTitle = `Chave vinculada à conta ${conta['informacao_adicional_conta'].conta}`;
            this.warnSubtitle = 'Para utilizar a mesma chave, esta deverá ser excluída da outra conta.';
            this.button1 = 'Fechar';
            this.finalizado = true;
            this.loading = false;
            this.btnFunction1 = 'goback'
            this.hideButton2 = true;
          } else if (err.status === 400 && JSON.parse(err.error.message[0].replaceAll('u0022', '"')).cod_direcionamento === "CHAVE_JA_NA_CONTA") {
            this.isMyDocuments = false;
            this.isKeyCreated = true;
            this.loading = false;
          } else if (err.status === 400 && err.error.message[0].includes('MIP_ADD_001')) {
            this.loading = false;
            const message = document.getElementById('message');
            this.msgError = "Não é possível efetuar o cadastro. Chave em processo de portabilidade ou reivindicação.";
            this.subTextError = null;
            message.classList.add('show');
          } else {
            this.loading = false;
            const message = document.getElementById('message');
            this.msgError = "Ocorreu um erro no envio";
            message.classList.add('show');
          }
        });
      }
    }


    localStorage.setItem('cadastro-atual', 'false');
    localStorage.setItem('chaves', 'tem-chave');
  }

  public sendToken(type: string) {
    this.token = "";
    this.modalService.dismissAll();
    this.isCodInvalid = false;
    this.isKeyCreated = false;
    this.loading = true;
    this.semPermissaoMsg = true;
    if (!this.permissoes.includes('PIX.CAD_CHAVE')) {
      this.loading = false;
      this.msgError = "Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.";
      this.semPermissaoMsg = false;
      const message = document.getElementById('message');
      message.classList.add('show');
    } else {
      if (type === 'email') {
        this.createKeyService.sendEmailToken(this.email).subscribe((res) => {
          this.loading = false;
          this.openModal(this.contentEmail);
          this.codValidacao = res.data;
          this.minutes = 5;
          this.startTimer();
        });
      } else if (type === 'phone') {
        this.createKeyService.sendSmsToken(this.numCelular.replace(/[^0-9\/]/g, '')).subscribe((res) => {
          this.loading = false;
          this.openModal(this.contentSms);
          this.codValidacao = res.data;
          this.minutes = 5;
          this.startTimer();
        }, error => {
          this.loading = false;
          this.msgError = "Ocorreu um erro no envio";
          const message = document.getElementById('message');
          message.classList.add('show');
        });
      }
    }
  }

  mascaraCpfCnpj(event) {
    let input = event.replace(/\D/g, '');
    if (input.length === 0) {
      input = '';
    } else if (input.length <= 3) {
      input = input.replace(/^(\d{0,3})/, '$1');
    } else if (input.length <= 6) {
      input = input.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
    } else if (input.length <= 9) {
      input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
    } else if (input.length <= 11) {
      input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
    } else if (input.length === 11) {
      input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
    } else if (input.length <= 12) {
      input = input.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3/$4');
    } else {
      input = input.substring(0, 14);
      input = input.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
    }
    this.document = input;
    if (this.document.length === 14 || this.document.length === 18) {
      this.validDocument = true;
    } else {
      this.validDocument = false;
    }
  }

  public closeError() {
    this.msgError = "Ocorreu um erro no envio";
    const message = document.getElementById('message');
    message.classList.remove('show');
  }

  validacaoEmail() {
    const usuario = this.email.substring(0, this.email.indexOf('@'));
    const dominio = this.email.substring(this.email.indexOf('@') + 1, this.email.length);
    if ((usuario.length >= 1) &&
      (dominio.length >= 3) &&
      (usuario.search('@') == -1) &&
      (dominio.search('@') == -1) &&
      (usuario.search(' ') == -1) &&
      (dominio.search(' ') == -1) &&
      (dominio.search('.') != -1) &&
      (dominio.indexOf('.') >= 1) &&
      (dominio.lastIndexOf('.') < dominio.length - 1)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  startTimer() {
    clearInterval(this.interval);
    this.timeoutToken = false;
    this.counter = 59;
    this.interval = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else {
        clearInterval(this.interval);
        if (this.minutes > 0) {
          this.minutes--;
          this.callTimer();
        } else {
          this.timeoutToken = true;
          clearInterval(this.interval);
          this.minutes = 0;
        }
      }
    }, 1000);
  }

  callTimer() {
    this.startTimer();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  responseApiModal($event) {
    this.showModalValidation = false;
    this.isScheduling = false;
    let ignorarErro = false;

    if (this.keysCreateStatus === 'APROVADO') {
      if ($event.code === 200 && $event['data'].ds_status_consolidado === 'IB_AGENDADO') {
        this.showModalValidation = false;
        this.warnType = this.arrayMessages.cadastroChavePendente.type;
        this.warnTitle = this.arrayMessages.cadastroChavePendente.title;
        this.warnSubtitle = 'Sua efetivação ocorrerá dentro do horário do fucionamento do BACEN.';
        this.button1 = "Fechar";
        this.isScheduling = true;
        this.button2 = "Acompanhar status";
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-chaves';
      }
      else if ($event.code === 200 && $event['data'].status === 'Chave ativa no sistema') {
        this.showModalValidation = false;
        this.warnType = this.arrayMessages.chavePixCadastrada.type;
        this.warnTitle = this.arrayMessages.chavePixCadastrada.title;
        this.warnSubtitle = this.arrayMessages.chavePixCadastrada.subtitle.replace('minhachave', $event['data'].chave);
        this.button1 = this.arrayMessages.chavePixCadastrada.button1;
        this.button2 = this.arrayMessages.chavePixCadastrada.button2;
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goTransf'
        this.btnFunction2 = 'goback';
      }
      else if ($event.code === 200 && $event['data'].status === 'Chave pendente de cadastro no DICT') {
        this.showModalValidation = false;
        this.warnType = this.arrayMessages.chavePixCadastrada.type;
        this.warnTitle = "Solicitação de cadastro enviada";
        this.warnSubtitle = "Chave pendente de cadastro no BACEN"
        this.button1 = "Fechar";
        this.button2 = "Acompanhar status";
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-chaves';
      }
      else if ($event.code === 200 && $event['data'].status === 'PENDENTE') {
        this.showModalValidation = false;
        this.warnType = this.arrayMessages.cadastroChavePendente.type;
        this.warnTitle = this.arrayMessages.cadastroChavePendente.title;
        this.warnSubtitle = this.arrayMessages.cadastroChavePendente.subtitle.replace('minhachave', this.numCelular);
        this.button1 = "Fechar";
        this.button2 = this.arrayMessages.cadastroChavePendente.button2;
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-chaves';
      }
    }
    else if (this.keysCreateStatus === 'PORTABILIDADE') {
      if ($event.code === 200 && $event['data'].ds_status_consolidado === 'IB_AGENDADO') {
        this.showModalValidation = false;
        this.isScheduling = true;
        this.warnType = this.arrayMessages.cadastroChavePendente.type;
        this.warnTitle = "Reivindicação de chave pendente";
        this.warnSubtitle = 'Sua efetivação ocorrerá dentro do horário do fucionamento do BACEN.';
        this.button1 = "Fechar";
        this.button2 = "Acompanhar status";
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-solicitacoesenviadas';
      }
      else if ($event.code === 200 && $event['data'].status === 'Chave ativa, sistema precisa enviar as atualizações da chave para o DICT ') {
        this.showModalValidation = false;
        this.warnType = this.arrayMessages.cadastroChavePendente.type;
        this.warnTitle = "Cadastro de chave pendente";
        this.warnSubtitle = "Chave pendente de cadastro no DICT"
        this.button1 = "Fechar";
        this.button2 = "Acompanhar status";
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-solicitacoesenviadas';
      }
      else if ($event.code === 200 && $event['data'].status === 'Chave pendente de cadastro no DICT') {
        this.showModalValidation = false;
        this.warnType = this.arrayMessages.chavePixCadastrada.type;
        this.warnTitle = "Solicitação de portabilidade enviada";
        this.warnSubtitle = `A solicitação de portabilidade da chave ${$event['data'].chave} foi enviada com sucesso. Lembrando que você terá 7 dias para confirmar a alteração na instituição em que a chave está cadastrada.`
        this.button1 = "Cadastrar outra chave";
        this.button2 = this._isMaster ? 'Ver status da solicitação' : 'Acompanhar status';
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-solicitacoesenviadas';
      }
      else if ($event.code === 200 && $event['data'].status === 'PENDENTE') {
        this.keysCreateStatus = 'PENDENTE';
        this.warnType = this.arrayMessages.cadastroChavePendente.type;
        this.warnTitle = "Portabilidade de chave pendente ";
        this.warnSubtitle = this.arrayMessages.cadastroChavePendente.subtitle.replace('minhachave', this.numCelular);
        this.button1 = this.arrayMessages.cadastroChavePendente.button1;
        this.button2 = this.arrayMessages.cadastroChavePendente.button2;
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-solicitacoesenviadas';

      }
      else if ($event.status === 400 && $event.error.message[0].includes('MIP_ADD_001')) {
        this.loading = false;
        const message = document.getElementById('message');
        this.msgError = "Não é possível efetuar o cadastro. Chave em processo de portabilidade ou reivindicação.";
        this.subTextError = null;
        message.classList.add('show');
        ignorarErro = true;
      }
    }
    else if (this.keysCreateStatus === 'REIVINDICACAO') {
      if ($event.code === 200 && $event['data'].ds_status_consolidado === 'IB_AGENDADO') {
        this.showModalValidation = false;
        this.isScheduling = true;
        this.warnType = this.arrayMessages.cadastroChavePendente.type;
        this.warnTitle = "Reivindicação de chave pendente";
        this.warnSubtitle = 'Sua efetivação ocorrerá dentro do horário do fucionamento do BACEN.';
        this.button1 = "Fechar";
        this.button2 = "Acompanhar status";
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-solicitacoesrecebidas';
      }
      else if ($event.code === 200 && $event['data'].status === 'PENDENTE') {
        this.warnType = this.arrayMessages.cadastroChavePendente.type;
        this.warnTitle = "Reivindicação de chave pendente";
        this.warnSubtitle = this.arrayMessages.cadastroChavePendente.subtitle.replace('minhachave', this.numCelular);
        this.button1 = this.arrayMessages.cadastroChavePendente.button1;
        this.button2 = this.arrayMessages.cadastroChavePendente.button2;
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-solicitacoesrecebidas';
      }
      else if ($event.code === 200 && $event['data'].status === 'Chave não registrada no DICT aguardando usuário confirmar se quer fazer o processo de reivindicação' || $event['data'].status === 'Chave pendente de cadastro no DICT') {
        this.warnType = this.arrayMessages.chavePixCadastrada.type;
        this.warnTitle = "Solicitação de reivindicação enviada"
        this.warnSubtitle = `A reivindicação da chave ${$event['data'].chave} foi feita com sucesso. Lembrando que o usuário que está de posse da chave terá 7 dias para responder a solicitação.`
        this.button1 = this.arrayMessages.cadastroChavePendente.button1;
        this.button2 = 'Acompanhar status';
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goback'
        this.btnFunction2 = 'goStatus-solicitacoesrecebidas';
      }
      else if ($event.status === 400 && $event.error.message[0].includes('MIP_ADD_001')) {
        this.loading = false;
        const message = document.getElementById('message');
        this.msgError = "Não é possível efetuar o cadastro. Chave em processo de portabilidade ou reivindicação.";
        this.subTextError = null;
        message.classList.add('show');
        ignorarErro = true;
      }
    }
    else if ($event.code !== 200 && !ignorarErro) {
      this.loading = false;
      this.msgError = "Ocorreu um erro no envio";
      const message = document.getElementById('message');
      message.classList.add('show');
    }

    if ($event.status >= 400 && $event.status <= 500) {
      this.toast.callModalMessage(null, null, null);
    }
  }

  closeModalApi($event) {
    this.showModalValidation = false;
    this.loading = false;

  }

  solicitaionResquest() {
    this.showModalValidation = true;
    this.loading = true;
    this.finalizado = true;
  }

  btnSeletedCArd(params: string) {

    const aux = params.split('-');

    switch (aux[0]) {

      case 'goback':
        this.limitKeys();
        this.cadastroCelular = false;
        this.cadastroEmail = false;
        this.cadastroDocumento = false;
        this.cadastroAleatorio = false;
        this.finalizado = false;
        this.keyType = '';
        this.cadastro = !this.cadastro;
        break;
      case 'goTransf':
        localStorage.setItem('view-selecionada', 'transferir-beneficiarios');
        window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
        break;
      case 'solicitanionRequest':
        this.solicitaionResquest();
        break;
      case 'goStatus':
        this.goBack();
        this.goToApproval(aux[1]);
        break;
      default:

        break;
    }
  }


  handleLimitKey() {
    this.loading = false;
    this.subTextError = "Você atingiu o limite máximo de chaves ativas permitidas para vínculo a esta conta."
    this.msgError = "";
    const message = document.getElementById('message');
    message.classList.add('show');

  }

  openChat() {
    document.getElementsByTagName('iframe')[1].contentWindow.document.getElementsByTagName('button')[0].click();
  }
}
