import { 
  Component, 
  OnInit, 
  ViewChild, 
  Output, 
  EventEmitter
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
  FormControl,
} from '@angular/forms';
import * as Util from '@src/shared/util-common';
import * as $ from 'jquery';
import { indexadorList } from '@src/data/repository/data-share-repository';
import { ProductListRequest, ProductListEnum, SimulationEnum, ParcelasEnum, ContratacaoEnum, ContractRequest} from '@src/core/domain/product/product.model';
import { GetProductListUseCase } from '@src/core/usecases/product/get-product.usecase';
import { GetParcelasUseCase } from '@src/core/usecases/product/get-parcelas.usecase';
import { PostSimulationUseCase } from '@src/core/usecases/product/post-product.usecase';
import { GetComprovanteUseCase } from '@src/core/usecases/product/get-comprovante.usecase';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';
import { DropdownList } from '@src/core/domain/business-models';


@Component({
  selector: 'fibra-produtos-simulacao',
  templateUrl: './produtos-simulacao.component.html',
  styleUrls: ['./produtos-simulacao.component.scss'],
})
export class ProdutoSimulacaoComponent implements OnInit {
  @Output() callAcompanhar: EventEmitter<any>;
  public formStep1: FormGroup;
  public list: Array<ProductListEnum> = [];
  public simulacaoRetorno: SimulationEnum;
  public parcelasList: Array<ParcelasEnum> = [];
  public parcelasIndexador:boolean = false;
  public contratacaoRetorno: ContratacaoEnum;
  public optionsProfile: DropdownList;
  public loading: boolean;
  public showModalValidation = false;
  public showModalParcelas = false;
  public showModalConfirmation = false;
  public showModalError = false;
  public prazoValidate = false;
  public valorValidate = true;
  public carenciaValidate = true;
  public disableSimulation = true;
  public indexList;
  public indexadorList = indexadorList;
  public teste = true;
  public requestDataApi;
  public disableCarencia = true;
  public cdSimula: number;
  public initOpen = false;
  public REDCOLOR = '#ED6C6C';
  public typeColorToast = this.REDCOLOR;
  public toasterTitle: string;
  public toasterMessage: string;
  public URLComprovante: string;
  public messageHorarioLimiteProduct:string;
  public messageHorarioLimiteSimulation:string;
  public selecionadoValor: any;
  public apiCallback: number = 7;

  checkboxValue: boolean;
  nomeSimulation: string;
  prazoTotal: any;
  carenciaPrincipal: any;
  selectedIndex: any;
  selectedAccount: any;
  indexador: number;
  meuValor: any;
  simulationId: number;
  productStep = 1;
  customClass:string;
  accountModel : FormGroup;
  hasDtaOne: boolean = false;
  hasRotateOne: boolean = false;
  firstRotate: boolean = false;

  public get simulacaoForm() {
    return this.formStep1.get('simulacao');
  }
 
  constructor(
    private formBuild: FormBuilder,
    private getProductListUseCase: GetProductListUseCase,
    private postSimulationUseCase: PostSimulationUseCase,
    private getParcelasUseCase: GetParcelasUseCase,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private getComprovanteUseCase: GetComprovanteUseCase,
  ) {
    this.callAcompanhar = new EventEmitter();
    this.toasterTitle = 'Ocorreu um erro no envio!';
    this.toasterMessage = 'Tente novamente.';
    this.formStep1 = this.formBuild.group({
      simulacao: this.formBuild.group({
        valor: [],
        prazoTotal:['', Validators.required],
        carencia:['', Validators.required],
        indexador:['']
      }),
    });
    this.accountModel = new FormGroup( {
      account : new FormControl('')
      }
    );
  }
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit() {
    this.loading = false;
    this.showModalValidation = false;
    this.showModalParcelas = false;
    this.requestAccount();
    this.requestProductList();
  }


  //CHAMADA DAS CONTAS
  public requestAccount(): void {
    this.getAllAccountTypes
      .execute(null)
      .subscribe(
        this.requestSuccess,
        this.requestError
        );
  }

  private requestError = (err) => {
    this.typeColorToast = this.REDCOLOR;
    this.showToast(err);
  }

  private requestSuccess = (value) => {
    this.optionsProfile = [];
    value.data.forEach(element => {
      if(element.desc_conta == 'CONTA CORRENTE' || element.desc_conta == 'CHEQUE EMPRESA'){
        this.optionsProfile.push(element)
      }
    });
  }

  //validacoes de campos
  validatePrazo(maxValue: any){
    var erro = false;
    var teste = this.simulacaoForm.get('prazoTotal').value;
    if(this.simulacaoForm.get('prazoTotal').value != "" && (this.simulacaoForm.get('prazoTotal').value > maxValue || this.simulacaoForm.get('prazoTotal').value == 0 || this.simulacaoForm.get('prazoTotal').value == null)){
      erro = true;
      this.prazoValidate = false;
    }else if(this.simulacaoForm.get('prazoTotal').value != ""){
      this.prazoValidate = true;
    }else{
      this.prazoValidate = false;
    }
    this.disableSimulationButton();
    return erro;
  }

  validateMeuValor(minMaxValue: any){
    var erro = false;
    var teste = this.simulacaoForm.get('valor').value;
    if(this.simulacaoForm.get('valor').value != "" && this.simulacaoForm.get('valor').value != null && (this.simulacaoForm.get('valor').value < minMaxValue.limite_min_oferta || this.simulacaoForm.get('valor').value > minMaxValue.valor_disponivel)){
      erro = true;
      this.valorValidate = false;
    }else if(this.simulacaoForm.get('valor').value == 0){
      erro = true;
      this.valorValidate = false;
    }else{
      this.valorValidate = true;
    }
    this.disableSimulationButton();
    return erro;
  }

  validateCarencia(maxValue: any){
    var erro = false;
    var teste = this.simulacaoForm.get('carencia').value;
    if(!this.disableCarencia && (this.simulacaoForm.get('carencia').value > maxValue || this.simulacaoForm.get('carencia').value == "" || this.simulacaoForm.get('carencia').value == 0 || this.simulacaoForm.get('carencia').value == null)){
      erro = true;
      this.carenciaValidate = false;
    }else{
      this.carenciaValidate = true;
    }
    this.disableSimulationButton();
    return erro;
  }
  
  // REQUEST PRODUTOS
  private requestProductList(): void {
    this.loading = false;
    this.getProductListUseCase
      .execute()
      .subscribe(this.requestProductSuccess, this.requestProductError);
  }
  private requestProductError = (err) => {
    this.typeColorToast = this.REDCOLOR;
    this.loading = true;
    var messageSplit = err.error.message.split(" ");
    if(err.error && err.error.message){
      if(messageSplit[0] == 'Disponível' && messageSplit[1] == 'para' && messageSplit[2] == 'simulação'){
        this.productStep = 4;
        this.messageHorarioLimiteProduct = err.error.message
        return console.log(err);
      }
    }else{
      this.showToast(err);
      return console.log(err);
    }
  };

  private requestProductSuccess = (value: any) => {
    this.loading = true;
    if(!value.data || (value && value.data.length <= 0)){
      this.productStep = 0
      this.list = [];
    }else{
      this.list = Util.verifyArray(value.data);
      if(this.list && this.list.length == 1){
        this.hasDtaOne = true;
        this.hasRotateOne = true;
        this.firstRotate = true;
        this.toogleIconArrow(0)
      }else{
        this.hasDtaOne = false;
      }
    }
  };

  
  //SETA VALORES
  setMeuValor(value: any){
    this.meuValor = value;
  } 

  setPrazo(value: any){
    this.prazoTotal = value;
  } 

  setCarencia(value: any){
    this.carenciaPrincipal = value;
  } 

  accountSelected() {
    this.selectedAccount = this.selecionadoValor;
}

  // REQUEST SIMULAÇAO DE EMPRESTIMO
  simulation(item: any){
    this.loading = false;
    this.selectedIndex = document.getElementById("selectIndexador");
    if(this.selectedIndex.value == 'Pré-fixado'){
      this.indexador = 1;
    }else{
      this.indexador = 2;
    }
    if(!this.selectedAccount){
      this.selectedAccount = this.optionsProfile[0]
    }
    this.nomeSimulation = item.desc_produto;
    var carencia = 0;
    var valorDisponivel = item.valor_disponivel;
    if(this.simulacaoForm.get('valor').value != "" && this.simulacaoForm.get('valor').value != null){
      valorDisponivel = +this.simulacaoForm.get('valor').value
    }
    
    if(this.simulacaoForm.get('carencia').value != "" && this.simulacaoForm.get('carencia').value != null){
      carencia = +this.simulacaoForm.get('carencia').value
    }

    const params: ProductListRequest = {
      codigo_produto: item.cod_produto,
      valor_solicitado: valorDisponivel,
      parcelas: +this.simulacaoForm.get('prazoTotal').value,
      carencia: carencia,
      indexador: this.indexador,
      num_conta: String(this.selectedAccount.num_indice),
    }
    this.postSimulationUseCase
    .execute(params)
    .subscribe(this.requestSimulationSuccess, this.requestSimulationError);
    
    //TODO: verificar se ja existe solicitação para a data atual, caso sim estourar erro: “Já existe uma solicitação de contratação em andamento para o dia de hoje.”
  }

  private requestSimulationError = (err) => {
    this.typeColorToast = this.REDCOLOR;
    this.loading = true;
    if(err.error && err.error.message){
      var messageSplit = err.error.message.split(" ");
      if(messageSplit[0] == 'Disponível' && messageSplit[1] == 'para' && messageSplit[2] == 'simulação'){
        this.productStep = 5;
        this.messageHorarioLimiteSimulation = err.error.message
      }else{
        this.showToast(err);
      }
      return console.log(err);
    }else{
      return console.log(err);
    }
  };

  private requestSimulationSuccess = (value: any) => {
    this.loading = true;
    this.simulacaoRetorno = value.data;
    this.productStep = 2;
    this.getParcelasUseCase
    .execute(value.data.cod_simulacao)
    .subscribe(this.requestParcelasSuccess, this.requestParcelasError);
  };

  //ABRE MODAL DE PARCELAS
  parcelas(){
    this.showModalParcelas = true;
  }

  public closeModalParc = (event) => {
    this.showModalParcelas = false;
  }

  //REQUEST PARCELAS
  private requestParcelasError = (err) => {
    return console.log(err);
  };

  private requestParcelasSuccess = (value: any) => {
    this.parcelasList = Util.verifyArray(value.data);
    if(this.simulacaoRetorno.indexador_id == 2){
      this.parcelasIndexador = true;
    }
  };

  disableSimulationButton(){
    if(this.prazoValidate && this.valorValidate && this.carenciaValidate)
      this.disableSimulation = false;
    else
      this.disableSimulation = true;
  }

  //CONTRATACAO 
  contract(){
    this.showModalConfirmation = true;
    this.apiCallback = 7;
    this.loading = false;
  }
  // private requestContratacaoError = (err) => {
  //   return console.log(err);
  // };

  // private requestContratacaoSuccess = (value: any) => {
  //   this.contratacaoRetorno = value.data;
  //   this.loading = true;
  // }
  errorTimeLimitContract(message){
    this.productStep = 5;
    this.messageHorarioLimiteSimulation = message
  }

  responseApi(validation) {
    if (validation) {
      this.contratacaoRetorno = validation
      this.showModalValidation = false;
      this.productStep = 3;
    }
    
    this.getParcelasUseCase
    .execute(validation.cod_simulacao)
    .subscribe(this.requestParcelasSuccess, this.requestParcelasError);
  }

  closeModal() {
    this.showModalValidation = false;
  }

  public securityRequest() {
    return this.simulacaoRetorno.cod_simulacao;
  }

  closeModalError(){
    this.showModalError = false;
  }

  public securityResponse(res): void {
    if (res.status === 'sucess') {
      this.requestContratacaoSuccess(res);
      return;
    } else {
      this.requestContratacaoError(res);
    }
  }

  private requestContratacaoError = (err) => {
    this.loading = true;
    if(err.error && err.error.message){
      var messageSplit = err.error.message.split(" ");
      if(messageSplit[0] == 'Disponível' && messageSplit[1] == 'para' && messageSplit[2] == 'simulação'){
        this.errorTimeLimitContract(err.error.message)
      }
    }
  }

  private requestContratacaoSuccess = (value: any) => {
    this.loading = true;
    if (value) {
      this.contratacaoRetorno = value.data
      this.showModalValidation = false;
      this.productStep = 3;
      
      this.getParcelasUseCase
      .execute(value.data.cod_simulacao)
      .subscribe(this.requestParcelasSuccess, this.requestParcelasError);
    }
  }

  public closeModalConf = (value: any) => {
    if(value == 'sim'){
      this.showModalConfirmation = false;
      this.showModalValidation = true;
    }else if(value == 'cancelar'){
      this.showModalConfirmation = false;
    }
  }

  //controle erro preenchimento dos valores para simular emprestimo
  public simulationControl(name: string) {
    return this.simulacaoForm.get(name);
  }
  public simulationControlIsInvalid(name: string) {
    const control = this.simulationControl(name);
    return control.invalid && (control.touched || control.dirty);
  }
  public simulationControlFirstError(name: string): any {
    const control = this.simulationControl(name);
    return this.getErrorsControl(control);
  }

  private getErrorsControl(control: AbstractControl) {
    const list =
      control.errors !== null ? Object.getOwnPropertyNames(control.errors) : [];
    if (list.length > 1) {
      const error = {};
      error[list[0]] = control.errors[list[0]];
      return error;
    }
    return control.errors || false;
  }


  //botao voltar
  voltar(){
    this.productStep = 1;
    this.simulacaoForm.reset();
  }

  //seta pra baixo quando clicado
  toogleIconArrow(index) {
    if (this.indexList !== index) {
      this.indexList = index;
      $('.collapse').removeClass('show');
      $(`.item-icon img`).removeClass('img-arrow');
    }
    if(this.list && this.list.length > 1){
      document.getElementById(index).classList.toggle('img-arrow');
    }
    if(this.list && this.list.length == 1 && this.hasRotateOne){
      if(this.firstRotate){
        this.firstRotate = false;
      }else{
        this.hasRotateOne = false;
      }
    }else if(this.list && this.list.length == 1){
      this.hasRotateOne = true;
    }
    $('input').val('');
    $('option').attr('selected', false);
  }

  //BOTAO GERADOR PDF
  public generatePDF(cdSimula: number) {
    this.requestComprovante(cdSimula);
  }

  //CHECKBOX
  carenciaRules(){
    const cb = document.getElementById('carenciaCheckBox');
    if(cb['checked']){
      this.disableCarencia = true;
      this.simulacaoForm.reset({
        valor: this.simulacaoForm.get('valor').value,
        prazoTotal:this.simulacaoForm.get('prazoTotal').value,
        carencia:"",
        indexador:this.simulacaoForm.get('indexador').value
      })
      this.carenciaValidate = true;
      this.disableSimulationButton();
    }else{
      this.disableCarencia = false;
      this.carenciaValidate = false;
      this.disableSimulationButton();
    }
  }

  //BOTAO FECHAR DO RESUMO
  closeResumo(){
    this.productStep = 1;
    this.callAcompanhar.emit();
  }


  //CALL TOAST ERROR
  private showToast = (err) => {
    if (err.error.message && err.error.message.length > 0) {
      let message: string;
      switch (err.error.message[0]) {
        case 'Cliente não passou pela validação padrão do Internet Banking':
          message = 'Ocorreu um erro no envio!';
          break;
        case 'Já existe uma solicitação de contratação em andamento para o dia de hoje.':
          message = 'Já existe uma solicitação de contratação em andamento para o dia de hoje.'
        default:
          message = err.error.message;
          break;
      }
      this.toast.callModalMessage(null, message);
    } else {
      this.toast.callModalMessage(null, this.toasterTitle, this.toasterMessage);
    }
  }

  

  //REQUEST GET COMPROVANTE
  private requestComprovante(cdSimulacao: number): void {
    this.getComprovanteUseCase
      .execute(cdSimulacao)
      .subscribe(this.requestComprovanteSuccess, this.requestComprovanteError);
  }

  private requestComprovanteError = (err) => {
    this.showToast(err);
    return console.log(err);
  };

  private requestComprovanteSuccess = (value: any) => {
    this.URLComprovante = value.data;
    this.downloadFile(this.URLComprovante);
  };

  //DOWNLOAD PDF
  downloadFile(url: any){
    fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "ComprovanteContratacao.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    })
  }
}
