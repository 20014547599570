import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fibra-pix-details-list-beneficiaries',
  templateUrl: './pix-details-list-beneficiaries.component.html',
  styleUrls: ['./pix-details-list-beneficiaries.component.scss'],
})
export class PixListBeneficiariesDetailsComponent implements OnInit {
  @Input() keyData;
  @Input() accountData;
  @Input() beneficiarioData;
  @Input() indice;
  @Output() emitBeneficiary: EventEmitter<any> = new EventEmitter();
  @Output() emitDelete: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.keyData;
    this.accountData;
  }

  selectBeneficiary(item, item2) {
    console.log(item);
    const beneficiario = {
      benefInfo: item.data.beneficiarioPix,
      chaveInfo: item2
    }
    this.emitBeneficiary.emit(beneficiario);
  }

  deleteBeneficiary(item) {
    console.log(item);
    this.emitDelete.emit(item);
  }

  public returnType(key: string) {
    switch (key) {
      case "EVP":
        return 'aleatória';
      case "CPF":
        return 'CNPJ/CPF';
      case "CNPJ":
        return 'CNPJ/CPF';
      case "EMAIL":
        return 'e-mail';
      case "PHONE":
        return 'telefone';  
        
    }
  }
}
