import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { AdminUserRepository } from '@src/core/repositories/admin-user/admin-user.repository';

@Injectable({
  providedIn: 'root',
})
export class GetUsersAssociatedWithActiveApprovalMatrixUseCase implements UseCase<Array<number>, Array<number>> {
  constructor(private _adminUserRepository: AdminUserRepository) {}

  execute(usuarios: Array<number>): Observable<Array<number>> {
    return this._adminUserRepository.getUsersAssociatedWithActiveApprovalMatrix(usuarios);
  }
}
