import { Injectable } from '@angular/core';
import {
  ListQueryRequest,
  francesinhaListModel,
} from '@src/core/domain/query/query.model';
import { from, Observable } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { francesinhaListEntity } from './francesinha.entity';
import { FrancesinhaRepository, FrancesinhaRepositoryResumo } from '@src/core/repositories/query/francesinha.repository';
import { FrancesinhaListRepositoryMapper } from './list-francesinha-repository-mapper';

// environment

@Injectable({
  providedIn: 'root',
})

export class FrancesinhaDataRepository extends FrancesinhaRepository {
  mapper = new FrancesinhaListRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllQueryFrancesinha(obj: ListQueryRequest): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

    return this.http
      .post<francesinhaListEntity>(
        `${environment.URLBase}v1/Francesinha`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}

export class FrancesinhaResumoDataRepository extends FrancesinhaRepositoryResumo {
  mapper = new FrancesinhaListRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllQueryFrancesinhaResumo(obj: ListQueryRequest): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = obj;

    return this.http
      .post<francesinhaListEntity>(
        `${environment.URLBase}v1/Francesinha/Resumo`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}