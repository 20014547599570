<ng-template #content let-modal>
  <div class="company-change-container">
    <div class="modal-header" *ngIf="!loading">
      <h4 class="modal-title">Ajuste de limite</h4>
    </div>
    <div class="modal-body" *ngIf="!loading">
      <form>
        <div class="txt-alert">
          <p>
            O seu limite {{this.exibicaoAccount | titlecase}} {{this.account?.alteracaolimite?.acao}}.
          </p>
        </div>
        <div class="selecionar_conta-dados">
          <span>Agência {{this.account?.cod_agencia}}</span>
          <span class="conta">Conta {{this.account?.num_conta}}</span>
        </div>
        <div class="row box-alert" *ngIf="!cancelOverdraft">
          <p class="col-md-12 txt-sub-alert">
            Você pode consultar o seu limite atual
            acessando a opção Operações de Crédito na aba Cheque Empresa
          </p>
        </div>
      </form>
    </div>
    <div class="modal-footer-company" *ngIf="!loading">
      <div class="modal-footer-dialog">
        <input type="checkbox" (change)="toggleButtonEnabled($event)" [disabled]="acknowledging"/>
        Estou ciente
      </div>
      <button class="btn btn-primary btn-confirm" [disabled]="!enableButton || acknowledging" (click)="actionModal()">
        <div class="d-flex justify-content-center position" *ngIf="acknowledging">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
        <span *ngIf="!acknowledging">Fechar</span>        
      </button>
      <p class="col-md-12 txt-sub-alert">
        Em caso de dúvidas sobre este ajuste, procure o seu gerente.
      </p>
    </div>
    <div class="d-flex justify-content-center position loading-container" *ngIf="loading">
      <div class="spinner-border text-primary" role="status">
    </div>
  </div>
  </div>
</ng-template>
