import { Injectable } from '@angular/core';
import { CompanyTypeRepository } from '@src/core/repositories/company/company-type.repository';
import { UseCase } from '@src/core/base/use-case';
import { CompanyRequest, CompanyDefaultModel } from '@src/core/domain/company/companyType.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetCompanyDefaultUsecases implements UseCase<CompanyRequest, CompanyDefaultModel> {

  constructor(private companyTypeRepository: CompanyTypeRepository) { }

  execute(): Observable<CompanyDefaultModel> {
    return this.companyTypeRepository.getCompanyDefault();
  }
}
