<div class="search-beneficiary">
  <input
    type="text"
    placeholder="Pesquisar por nome ou número do banco"
    [formControl]="filter"
  />
  <img src="assets/svg/icon_search.svg" />
</div>
<div class="wraper-list-value">
  <div class="list-value">
    <table class="table">
      <tbody class="p-15" *ngIf="!loading">
        <tr *ngFor="let item of banks | async; index as i">
          <td>
            <div class="items">
              <!-- Bank Fibra -->
              <div *ngIf="item.cod_banco === 224" class="icon-fibra"></div>

              <!-- Other Bank -->
              <div
                *ngIf="item.cod_banco !== 224"
                class="initial-letters"
                [ngClass]="item.css"
              >
                {{ initialLetters(item.nome_banco) }}
              </div>

              <div>
                {{
                  mountCodBank(item.cod_banco) + ' - ' + item.nome_banco
                    | titlecase
                }}
              </div>
            </div>
            <div class="select-button" (click)="selectBank(item)">
              Selecionar
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="empty-info" *ngIf="hide">
      <fibra-generic-info
        type="warn"
        title="Dados inexistentes"
        message="Não foi possível encontrar dados do banco!"
      >
      </fibra-generic-info>
    </div>
    <div class="d-flex justify-content-center position" *ngIf="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>

<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
