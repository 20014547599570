<div class="daily-content">

    <p class="parcelas-text">
        Parcelas</p>
    <div class="tabs-list" class="margin-bottom-20">
        <fibra-tabs-list [options]="[
            { idOption: 'vencendo', option: 'Vencendo hoje' },
            { idOption: 'avencer', option: 'À vencer' },
            { idOption: 'vencidas', option: 'Vencidas' }
          ]" (itemTab)="tabsSelect($event.idOption)"></fibra-tabs-list>
    </div>
    <div class="row">
        <div class="col-md-11">
            <div class="daily-content-body">
                <div *ngIf="loading" class="loading-content">
                    <div class="text-center loading">
                        <div class="spinner-border " role="status">
                            <span class="sr-only">Carregando dados...</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="hasData" class="daily-header">
                    <div [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-header padding-left-05 padding-right-10">Produto</div>
                    <div [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-header">Contrato</div>
                    <div [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-header">N° da Parcela</div>
                    <div *ngIf="showDtVencimento" [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-header"><div class="col-children">Data do Vencimento</div></div>
                    <div [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-header">Principal</div>
                    <div [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-header">Valor à pagar</div>
                    <div [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-header">Situação</div>
                </div>
                <div *ngIf="!loading && !hasData && !hasError" class="no-info-to-show">
                    <fibra-generic-info
                    type="warn"
                    title="Nenhum resultado encontrado"
                    message="Não há informações para serem exibidas"
                  ></fibra-generic-info>
                </div>
                <div class="empty-info no-info-to-show" *ngIf="hasError">
                    <fibra-generic-info
                    type="error"
                    title="Ocorreu um erro"
                    message="Não foi possível carregar suas operações. Tente novamente mais tarde."
                  ></fibra-generic-info>
                </div>
                <div class="content-table-display" *ngIf="hasData">
                    <table *ngIf="hasData" class="table width-100">
                        <tbody *ngFor="let item of diaryPositionList; let i = index">
                            <tr *ngIf="item.infoMonthYear != null">
                                <td class="month-line">{{ item.infoMonthYear }}</td>
                            </tr>
                            <tr data-toggle="collapse" attr.data-target="#collapse_{{i}}" class="accordion-toggle">
                                <td [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-value border-none padding-left-05 padding-right-10">{{ item.nome_produto }}</td>
                                <td [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-value">{{ item.num_contrato }}</td>
                                <td [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-value">{{ item.num_parcela }}</td>
                                <td *ngIf="showDtVencimento" [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-value">{{ item.dt_vencto | date : 'dd/MM/yyyy' }}</td>
                                <td [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-value padding-right-10"><span>{{ item.valor_principal | currency: 'BRL'}} </span></td>
                                <td [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-value">{{ item.valor_a_pagar | currency: 'BRL' }}</td>
                                <td [ngClass]="{'width-14': showDtVencimento, 'width-16' : !showDtVencimento }" class="col-value">
                                    <span class="margin-right"><img src="/assets/svg/status-{{status}}.svg" /> </span>
                                    {{ item.cod_situacao }}
                                    <span class="margin-left-5" data-toggle="collapse" data-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne"><img
                                            src="/assets/svg/arrow_down.svg" /> </span>
                                </td>
                            </tr>
                            <tr id="collapse_{{i}}" class="accordian-body collapse background-color-1">
                                <td colspan="7" class="accordion-daily-content">
                                    <div class="row accordion-flex-content">
                                        <div class="col-sm-2">
                                            <p class="accordion-content-item">Correção monetária</p>
                                            <p class="accordion-item">{{
                                                item.valor_principal_cm | currency: 'BRL' }}</p>
                                        </div>
                                        <div class="col-sm-2">
                                            <p class="accordion-content-item">Juros</p>
                                            <p class="accordion-item">{{ item.valor_juros | currency: 'BRL' }}</p>
                                        </div>
                                        <div class="col-sm-2">
                                            <p class="accordion-content-item">Juros de mora</p>
                                            <p class="accordion-item">{{
                                                item.valor_mora | currency: 'BRL'}}</p>
                                        </div>
                                        <div class="col-sm-2">
                                            <p class="accordion-content-item">Multa moratória</p>
                                            <p class="accordion-item">{{
                                                item.valor_multa | currency: 'BRL' }}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="hasData" class="barra-inferior">
                <span class="alinhamento-barra-inferior valores-totais">
                    Valores<br /> totais
                </span>
                <span class="alinhamento-barra-inferior alignment-daily-donw">
                    <span class="image-down-item"></span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Principal<br />
                    <span class="valor">{{ totalPrincipal | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Correção monetária <br />
                    <span class="valor">{{ totalPrincipalCorrigido | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Juros <br />
                    <span class="valor">{{ totalJuros | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Juros de mora <br />
                    <span class="valor">{{ totalJurosDeMora | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Multa moratória <br />
                    <span class="valor">{{ totalMultaMoratoria | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    IOF <br />
                    <span *ngIf="status=='vencido'" class="valor">{{ totalIof | currency:'BRL'}}</span>
                    <span *ngIf="status!=='vencido'" class="valor">-</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Total à pagar <br />
                    <span class="valor">{{ totalAPagar | currency:'BRL'}}</span>
                </span>
            </div>
        </div>
        <div *ngIf="hasData" class="texto-informativo">
            *Valores sujeitos a confirmação no momento da liquidação.
        </div>
    </div>
</div>
