<div class="container-main">
  <div class="title-header">{{title ? title : 'PIX'}}</div>

  <div class="container-list">
    <form [formGroup]="limitList">
      <div class="title flex">
        <div class="flex-item-1 title-cols">Limite</div>
        <div class="flex-item-1 title-cols">Mesma Titularidade</div>
        <div class="flex-item-1 title-cols">Outra Titularidade PJ</div>
        <div class="flex-item-1 title-cols">Outra Titularidade PF</div>
      </div>

      <div class="content-list">
        <div class="items flex">
          <div class="flex-item-1 title-row">Diurno</div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('diurnoMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('diurnoMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('diurnoMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="diurnoMesma" type="text" formControlName="diurnoMesma" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('diurnoOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('diurnoOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('diurnoOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="diurnoOutraPJ" type="text" formControlName="diurnoOutraPJ" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('diurnoOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('diurnoOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('diurnoOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="diurnoOutraPF" type="text" formControlName="diurnoOutraPF" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
        </div>

        <div class="items flex">
          <div class="flex-item-1 title-row">Noturno</div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('noturnoMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('noturnoMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('noturnoMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="noturnoMesma" type="text" formControlName="noturnoMesma" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('noturnoOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('noturnoOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('noturnoOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="noturnoOutraPJ" type="text" formControlName="noturnoOutraPJ" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('noturnoOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('noturnoOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('noturnoOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="noturnoOutraPF" type="text" formControlName="noturnoOutraPF" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
        </div>

        <div class="items flex" *ngIf="transacDayTime">
          <div class="flex-item-1 title-row">Por transação diurna</div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('transacaoDiurnaMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('transacaoDiurnaMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('transacaoDiurnaMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="transacaoDiurnaMesma" type="text" formControlName="transacaoDiurnaMesma" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('transacaoDiurnaOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('transacaoDiurnaOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('transacaoDiurnaOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="transacaoDiurnaOutraPJ" type="text" formControlName="transacaoDiurnaOutraPJ" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('transacaoDiurnaOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('transacaoDiurnaOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('transacaoDiurnaOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="transacaoDiurnaOutraPF" type="text" formControlName="transacaoDiurnaOutraPF" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
        </div>

        <div class="items flex" *ngIf="transNocturnal">
          <div class="flex-item-1 title-row">Por transação noturna</div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('transacaoNoturnaMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('transacaoNoturnaMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('transacaoNoturnaMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="transacaoNoturnaMesma" type="text" formControlName="transacaoNoturnaMesma" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('transacaoNoturnaOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('transacaoNoturnaOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('transacaoNoturnaOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="transacaoNoturnaOutraPJ" type="text" formControlName="transacaoNoturnaOutraPJ" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
          <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('transacaoNoturnaOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('transacaoNoturnaOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('transacaoNoturnaOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="transacaoNoturnaOutraPF" type="text" formControlName="transacaoNoturnaOutraPF" min="0.01" max="{{limiteData[0].maxLimiteValue}}"></div>
        </div>
      </div>
    </form>
    <div *ngIf="_controlEditForm">
      <span class="notice">*No caso de aumento do limite será necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para utilizá-lo.</span><br>
      <span class="notice">**Serão considerados os limites vigentes no momento da liquidação das transações.</span>
    </div>
  </div>
</div>
