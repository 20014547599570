<div class="content-transferir">
    <div class="transfer-check">
        <div class="transfer-header">
            <img src="/assets/svg/logo_fibra.svg" alt="logo"> 
            <span class="transfer-title"> Comprovante de transfêrencia Pix</span>
        </div>
        <div class="value-date">
            <div class="transfer-value">
                <span class="title">Valor</span>
                <span class="subtitle" style="margin-top: 8px;"> R$ 50.546,34</span>
            </div>
            <div class="date">
                <span class="title">Data e hora da transferência</span>
                <span class="subtitle" style="margin-top: 8px;"> 02/09/2020     15:00</span>
            </div>
        </div>
        <div class="beneficiario" style="margin-top: 30px;">
            <span class="title-font"> Beneficiário</span>
            <div class="div" style="display: flex;flex-direction: row;">
                <div class="nome">
                    <span class="title">Nome</span>
                    <span class="subtitle"> Empresa Alimenticia LTDA</span>
                </div>
                <div class="document">
                    <span class="title">CNPJ/CPF</span>
                    <span class="subtitle"> 427.463.208-35</span>
                </div>
                
            </div>
            <div class="div" style="display: flex;flex-direction: row;">
                <div class="bank">
                    <span class="title">Banco</span>
                    <span class="subtitle"> 033 - Banco Santander Brasil</span>
                </div>
                <div class="agency">
                    <span class="title">Agência</span>
                    <span class="subtitle"> 1314</span>
                </div>
                <div class="account">
                    <span class="title">Conta</span>
                    <span class="subtitle"> 01092192-4</span>
                </div>
            </div>
        </div>
        <div class="beneficiario">
            <span class="title-font"> Debitado de</span>
            <div class="div" style="display: flex;flex-direction: row;">
                <div class="nome">
                    <span class="title">Nome</span>
                    <span class="subtitle"> Empresa Alimenticia LTDA</span>
                </div>
                <div class="document">
                    <span class="title">CNPJ/CPF</span>
                    <span class="subtitle"> 427.463.208-35</span>
                </div>
                
            </div>
            <div class="div" style="display: flex;flex-direction: row;">
                <div class="bank">
                    <span class="title">Banco</span>
                    <span class="subtitle"> 033 - Banco Santander Brasil</span>
                </div>
                <div class="agency">
                    <span class="title">Agência</span>
                    <span class="subtitle"> 1314</span>
                </div>
                <div class="account">
                    <span class="title">Conta</span>
                    <span class="subtitle"> 01092192-4</span>
                </div>
            </div>
        </div>
        <div class="id-div">
            <span class="id-title"> ID da transação</span>
            <span class="id-value"> E5145584545112044554Afdaz15Pnl</span>
        </div>
        <div class="transfer-instructions">          
              <p>Se precisar de ajuda, entre em contato com a gente.</p>
        </div>
        <div class="transfer-channels">
            <div class="channel-div">
                <img src="/assets/svg/headset-icon.svg" alt="headset" style="margin-right: 10px;">
                <div class="channel-info">
                    <span class="info"> Canais exclusivos</span>
                    <span class="info"> Dúvidas e apoio</span>
                </div>
            </div>
            <div class="tel-div">
                <span class="tel-title"> Corporate, Agro e PMEs</span>
                <span class="tel-subtitle"> (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.</span>
            </div>
            <span class="transfer-email"> relacionamento@bancofibra.com.br</span>

        </div>
        <div class="buttons">
            <button
            type="button"
            class="button-secondary"
          >
            Baixar comprovante
          </button>
          <button
          type="button"
          class="button-primary"
        > Fechar </button>
        </div>
    </div>
</div>