import { Injectable } from '@angular/core';
import { InstructionRequest } from '@src/core/domain/query/query.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterQueryDataShare {
  public data: any;
  public currentAccount = undefined;

  public dataToShare = new BehaviorSubject(this.data);
  dataShared = this.dataToShare.asObservable();

  private _paramsInstrucoes = new BehaviorSubject<InstructionRequest>(null);
  public paramsInstrucoes = this._paramsInstrucoes.asObservable();

  constructor() {}

  setValue(data: any) {
    this.dataToShare.next(data);
  }

  setParamsInstrucoes(data: InstructionRequest) {
    this._paramsInstrucoes.next(data);
  }
}
