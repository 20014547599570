import { Mapper } from '@src/core/base/mapper';
import { TransferApprovalEntity } from './approval.entity';
import { ApprovalandRefuseEntity } from './approval.entity';
import { TransferApprovalModel } from '@src/core/domain/transfer-approval/approval.model';
import { ApprovalandRefuseModel } from '@src/core/domain/transfer-approval/approval.model';


export class TransferApprovalRepositoryMapper extends Mapper<
  TransferApprovalEntity,
  TransferApprovalModel
> {
  mapFrom(param: TransferApprovalEntity): TransferApprovalModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: TransferApprovalModel): TransferApprovalEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}

export class ApprovalAndRefusedRepositoryMapper extends Mapper<
    ApprovalandRefuseModel,
    ApprovalandRefuseEntity
    > {
  mapFrom(param: ApprovalandRefuseEntity): ApprovalandRefuseModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: ApprovalandRefuseModel): ApprovalandRefuseEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
