import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fibra-card-value-investments',
  templateUrl: './card-value-investments.component.html',
  styleUrls: ['./card-value-investments.component.scss']
})
export class CardValueInvestmentsComponent implements OnInit {
  @Input() valorTotal: {
    valorAplicado: number,
    rendBruto: number,
    valorLiq: number
  };
  constructor() { }

  ngOnInit() {
  }

}
