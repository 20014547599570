import { Mapper } from '@src/core/base/mapper';
import { francesinhaListModel } from '@src/core/domain/query/query.model';
import { francesinhaListEntity } from './francesinha.entity';

export class FrancesinhaListRepositoryMapper extends Mapper<
francesinhaListEntity,
  francesinhaListModel
> {
  mapFrom(param: francesinhaListEntity): francesinhaListModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: francesinhaListModel): francesinhaListEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
