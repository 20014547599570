<ng-template #content let-modal>
    <div class="dda-container">
      <div class="modal-header">
        <div class="generic-info">
          <img alt="" src="assets/png/icon-pending.png" class="img-medium mb-3" />
      <div class="row">
        <div class="col-12 text-left">
          <h5
          class="font-weight-bold fg-yellow"          
        > {{ title }}</h5>
        </div>
        <div class="col-12 mt-2 text-left">
          <h5  class="font-weight-bold fg-yellow">{{ message }}</h5>
        </div>
      </div>    
        </div>
      </div>
      <div class="mt-2 row text-center">
      <div class="col-6">
        <button
            type="button"
            [ngClass]="{ 'mb-3': recusar }"
            class="leaked-button mt-2"
            (click)="cancel()"
          >
            Cancelar
          </button>
      </div> 
      <div *ngIf="!recusar" class="col-6">
        <button
            type="button"
            (click)="confirm()"
            class="secondary-button mt-2 mb-3"
          >
            Continuar
          </button>
      </div> 
    </div>
  </div>
  </ng-template>