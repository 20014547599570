<div class="filter">
    <div class="filter-default">
        <fibra-form-control-group [formGroup]="filter" *ngIf="default">
            <fibra-form-control  *ngIf="abaSelected !== 'archive-list'" label="Carteira" [style.width.px]="155">
                <fibra-dropdown removeShadown="true" formGroupName="wallet" customClass="select-option" [options]="getOptionWallet()" (change)="changeWallet($event)"></fibra-dropdown>
            </fibra-form-control>

            <fibra-form-control  *ngIf="abaSelected !== 'archive-list'" label="Conta" [style.width.px]="200">
                <fibra-dropdown removeShadown="true" formGroupName="account" customClass="select-option" placeholder="Todas" [options]="optionsProfile"(change)="changeAccount($event)"></fibra-dropdown>
            </fibra-form-control>
                
            <fibra-form-control  *ngIf="!isTransitAprovados && abaSelected === 'query-transit'"  label="Data de" [style.width.px]="200">
                <fibra-dropdown  removeShadown="true"  [style.width.px]="200" formGroupName="tipoData" customClass="select-option" [options]="dataDe" class="margin" (change)="changeTipoData($event)"></fibra-dropdown>
            </fibra-form-control>

            <fibra-form-control  *ngIf="abaSelected === 'query-posicao-carteira'"  label="Data de" [style.width.px]="120">
                <fibra-dropdown  removeShadown="true"  [style.width.px]="120" formGroupName="tipoDataPosicao" customClass="select-option" [options]="dataDePosicao" class="margin" (change)="changeTipoDataPosicao($event)"></fibra-dropdown>
            </fibra-form-control>

            <fibra-form-control  *ngIf="abaSelected === 'query-instruction'"  label="Data de" [style.width.px]="120">
                <fibra-dropdown  removeShadown="true"  [style.width.px]="120" formGroupName="tipoDataInstrucoes" customClass="select-option" [options]="dataDeInstrucoes" class="margin" (change)="changeTipoDataInstrucoes($event)"></fibra-dropdown>
            </fibra-form-control>
        </fibra-form-control-group>
        <ng-container *ngIf="approval">
            <fibra-form-control-group [formGroup]="filter">
                <fibra-form-control [style.width.px]="333">
                    <div class="dropdown-input">
                        <span>Conta</span>
                        <fibra-dropdown [style.width.px]="333" removeShadown="true" formGroupName="account" customClass="select-option" placeholder="{{ selected }}"
                            [options]="optionsProfile" (change)="changeAccount($event)"></fibra-dropdown>
                    </div>
                </fibra-form-control>
                <fibra-form-control [style.width.px]="250">
                    <div class="dropdown-input">
                        <span>Tipo</span>
                        <fibra-dropdown [style.width.px]="250" removeShadown="true" formGroupName="type" customClass="select-option" [options]="optionTypeTransfer"
                            (change)="changeType($event)"></fibra-dropdown>
                    </div>
                </fibra-form-control>
            </fibra-form-control-group>
        </ng-container>
        <ng-container *ngIf="receipt">
            <fibra-form-control-group [formGroup]="filter">
                <fibra-form-control [style.width.px]="333">
                    <div class="dropdown-input">
                        <span>Conta</span>
                        <fibra-dropdown [style.width.px]="333" removeShadown="true" formGroupName="account" customClass="select-option" placeholder="{{ selected }}"
                            [options]="optionsProfile" (change)="changeAccount($event)"></fibra-dropdown>
                    </div>
                </fibra-form-control>
                <fibra-form-control [style.width.px]="250">
                    <div class="dropdown-input">
                        <span>Tipo</span>
                        <fibra-dropdown [style.width.px]="250" removeShadown="true" formGroupName="type" customClass="select-option" [options]="optionTypeReceipt"
                            (change)="changeType($event)"></fibra-dropdown>
                    </div>
                </fibra-form-control>
            </fibra-form-control-group>
        </ng-container>
        <div class="dropdown-periodic" style="display: block">
            <div class="group-items">
                <div class="period" [hidden]="isAdcInstrucao || isTransitAprovados" >
                    <div *ngIf="!isFrancesinha" class="label-period">Período</div>
                    <div class="group-period">
                        <div *ngFor="let item of optionPeriod" >
                            <div *ngIf="!isFrancesinha"  class="options-period" (click)="changePeriod(item)" [ngClass]="{ activeCalendar: dataRequest === item }">
                                {{ item }} dias
                            </div>
                        </div>
                        <div class="icon" *ngIf="!isFrancesinha">
                            <div *ngIf="abaSelected === 'bill-list' || abaSelected === 'archive-list'">
                                <fibra-range-datepicker
                                        [abaSelected]="abaSelected === 'bill-list' || abaSelected === 'archive-list'"
                                        *ngIf="!isSchedules"
                                        (change)="changePeriodDate($event)"
                                        [minDate]="minDay"
                                        [maxDate]="dayMax"
                                        [abaPosicaoCarteira]="abaSelectedPosicaoCarteira">
                                </fibra-range-datepicker>
                                <fibra-range-datepicker
                                        *ngIf="isSchedules"
                                        (change)="changePeriodDate($event)"
                                        [minDate]="today"
                                        [maxDate]="maxDay"
                                        [abaPosicaoCarteira]="abaSelectedPosicaoCarteira">
                                </fibra-range-datepicker>
                            </div>
                            <div *ngIf="abaSelected === 'query-transit'">
                                <button (click)="periodModal()" class="btn btn-outline calendar" type="button"><img id="calendar" src="/assets/svg/icon_calendar_gray.svg" alt="icon" ></button>
                                <fibra-modal-period-datepicker #content *ngIf="!isSchedules && showModalPeriodDatepicker"
                                                               [abaSelected]="abaSelected === 'query-transit'"
                                                               (change)="changePeriodDate($event)"
                                                               (closeEvent)="closeEvent($event)"
                                                               [minDate]="minDay"
                                                               [maxDate]="approvalTransaction ? maxDay : today"
                                                               [isSchedules]="isSchedules"
                                ></fibra-modal-period-datepicker>

                                <fibra-modal-period-datepicker #content *ngIf="isSchedules && showModalPeriodDatepicker"
                                                               (change)="changePeriodDate($event)"
                                                               (closeEvent)="closeEvent($event)"
                                                               [minDate]="today"
                                                               [maxDate]="maxDay"
                                                               [isSchedules]="isSchedules"
                                ></fibra-modal-period-datepicker>

                            </div>
                            <div *ngIf="abaSelected === 'query-posicao-carteira'">
                                <button (click)="periodModal()" class="btn btn-outline calendar" type="button"><img id="calendar" src="/assets/svg/icon_calendar_gray.svg" alt="icon" ></button>
                                <fibra-modal-period-datepicker #content *ngIf="!isSchedules && showModalPeriodDatepicker"
                                                               [abaSelected]="abaSelected === 'query-posicao-carteira'"
                                                               (change)="changePeriodDate($event)"
                                                               (closeEvent)="closeEvent($event)"
                                                               [minDate]="minDay"
                                                               [maxDate]="dayMax"
                                                               [abaPosicaoCarteira]="abaSelectedPosicaoCarteira"
                                                               [isSchedules]="true" 
                                                               ></fibra-modal-period-datepicker>
                                <fibra-modal-period-datepicker #content *ngIf="isSchedules && showModalPeriodDatepicker"
                                                               (change)="changePeriodDate($event)"
                                                               (closeEvent)="closeEvent($event)"
                                                               [minDate]="today"
                                                               [maxDate]="maxDay"
                                                               [abaPosicaoCarteira]="abaSelectedPosicaoCarteira"
                                                               [isSchedules]="true"
                                ></fibra-modal-period-datepicker>
                            </div>
                            <div *ngIf="abaSelected === 'query-instruction'">
                                <button (click)="periodModal()" class="btn btn-outline calendar" type="button"><img id="calendar" src="/assets/svg/icon_calendar_gray.svg" alt="icon" ></button>
                                <fibra-modal-period-datepicker #content *ngIf="showModalPeriodDatepicker"
                                                               [abaSelected]="abaSelected === 'query-instruction'"
                                                               (change)="changePeriodDate($event)"
                                                               (closeEvent)="closeEvent($event)"
                                                               maxRange="90"
                                                               [maxDate]="maxDayInstrucoes"></fibra-modal-period-datepicker>
                            </div>
                            <div *ngIf="abaSelected === undefined">
                                <fibra-range-datepicker
                                        *ngIf="!isSchedules"
                                        (change)="changePeriodDate($event)"
                                        [maxDate]="approvalTransaction ? maxDay : today"
                                        [minDate]="minDay"
                                        [isSchedules]="isSchedules">
                                </fibra-range-datepicker>
                                <fibra-range-datepicker
                                        *ngIf="isSchedules"
                                        (change)="changePeriodDate($event)"
                                        [minDate]="today"
                                        [maxDate]="maxDay"
                                        [isSchedules]="isSchedules">
                                </fibra-range-datepicker>
                            </div>
                        </div>

                        <div class="icon dataPickerContainer" *ngIf="isFrancesinha">
                            Data
                            <fibra-datepicker 
                                id="filterDate" 
                                class="dataPicker"
                                [date]="filterDate" 
                                (dateSelected)="selectDate($event)"
                                [minDate]="createDefaultDate" 
                                placement="top">
                            </fibra-datepicker>
                        </div>
                    </div><br>
                </div>
                <div *ngIf="isTransitAprovados">
                    <label class="label-data-transit"><b> Data:  </b> {{ this.todayDate }}</label>
                </div>
            </div>
            <div id="disclamervencimento" class="description-calendar" *ngIf="descriptionDateShow" [ngStyle]="{ 'margin-bottom': alinharDisclaimer ? '-25px' : '0' }">Para consultar boletos à vencer selecione o período no calendário.</div>
        </div>
    </div>

    <div class="filter-input mb-3" *ngIf="inputFilter">
        <fibra-form-control-group [formGroup]="filterInput">
            <div class="filter-position">
                <fibra-form-control *ngIf="abaSelected != 'query-posicao-carteira' && abaSelected != 'query-transit' && abaSelected != 'query-instruction'" label="Valor do título" [style.width.px]="200">
                    <fibra-dropdown removeShadown="true" formGroupName="titleValue" customClass="select-option" [options]="titleValues" (change)="setValueTitle($event)">
                    </fibra-dropdown>
                </fibra-form-control>

                <fibra-form-control style="width: 15%" label="Valor do título">
                    <div class="control-data">
                        <input id="valorTitulo" formControlName="valorTitulo" [style.width.%]="100" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left', allowNegative: false }"/>
                    </div>
                </fibra-form-control>

                <fibra-form-control *ngIf="abaSelected != 'query-transit'" [ngStyle]="{ width: abaSelected == 'query-posicao-carteira' ? '15%' : '200px' }" label="Nosso número">
                    <div class="control-data">
                        <input id="ourNumber" formControlName="ourNumber" [style.width.%]="100" (change)="setOurNumber($event.target.value)" />
                    </div>
                </fibra-form-control>

                <fibra-form-control class="your-number" [ngStyle]="{ width: abaSelected == 'query-posicao-carteira' ? '15%' : '200px' }" label="Seu número">
                    <div class="control-data">
                        <input id="yourNumber" formControlName="yourNumber" [style.width.%]="100" (change)="setYourNumber($event.target.value)" />
                    </div>
                </fibra-form-control>

                <fibra-form-control [ngStyle]="{ width: abaSelected == 'query-posicao-carteira' ? '40%' : '200px' }" label="Pagador" class="ml-2" [ngClass]="{ 'mr-0': abaSelected !== 'query-instruction' }">
                    <div class="control-data">
                        <input id="draweeName" formControlName="draweeName" [style.width.%]="100" placeholder="Nome do pagador" (change)="setDraweeName($event.target.value)"/>
                    </div>
                </fibra-form-control>

                <fibra-form-control *ngIf="abaSelected === 'query-instruction'" label="Instrução" [style.width.px]="200">
                    <fibra-dropdown removeShadown="true" formGroupName="instructionValue" customClass="select-option" [options]="instructionValues" (change)="setValueInstruction($event)">
                    </fibra-dropdown>
                </fibra-form-control>

                <button id="pesquisar" [ngStyle]="{ width: abaSelected == 'query-posicao-carteira' ? '15%' : '150px' }" type="button" style="margin-left: 35px;" class="secondary-button next mt-3" (click)="sendInfoFilter()">
                    Pesquisar
                </button>
            </div>
        </fibra-form-control-group>
    </div>
    <section *ngIf="periodCalendar != '' && periodCalendar != undefined && !isFrancesinha">
        <span class="txt-interval">Período: {{ dataIn }} à {{ dataFinal }}</span>
    </section>
</div>
<ng-content></ng-content>