<div class="modal">
    <ng-template #content let-modal>
        <div class="status">
            <div>
                <div>
                    <img src="../../../../../assets/png/iconConfirm.png" height="29" alt="Ícone de confirmação" />
                    <span class="fg-green">{{titulo}}</span>
                </div>
            </div>
        </div>
        <span *ngIf="(model.codStatusPagamento == 7 || model.codStatusPagamento == 8)" class="tooltipText">O pagamento será realizado na data agendada, mediante disponibilidade de saldo em conta.</span>
        <div class="content">
            <div class="header">
                <img src="../../../../../assets/png/logo-receipt.png" height="32" />
                <h1 class="payment-title-receipt text-center"><strong>{{mensagemTituloBoleto}}</strong></h1>
            </div>
            <div class="separator"></div>
            <div class="body">
                <div class="row">
                    <div class="column">
                        <span>Valor pago</span>
                        <strong>{{model.valorPago | currency:"BRL"}}</strong>
                    </div>
                    <div class="column">
                        <span>Forma de pagamento</span>
                        <strong>{{model.formaPagamento}}</strong>
                    </div>
                    <div class="column">
                        <span>Canal</span>
                        <strong>{{model.canal == 'InternetBanking' ? 'Internet Banking' : model.canal}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <span>Data de vencimento</span>
                        <strong>{{model.dataVencimento | date:"dd/MM/yyyy"}}</strong>
                    </div>
                    <div class="column" *ngIf="(model.codStatusPagamento == 7 || model.codStatusPagamento == 8)">
                        <span>Data agendada</span>
                        <strong>{{model.dataAgendada | date:"dd/MM/yyyy"}}</strong>
                    </div>
                    <div class="column">
                        <span>Data {{ statusPagamento }}</span>
                        <strong>{{model.dataAgendamentoPagamento | date:"dd/MM/yyyy"}}</strong>
                    </div>
                </div>
                <span class="sub-title">Beneficiário</span>
                <div class="row">
                    <div class="column">
                        <span>Nome</span>
                        <strong>{{model.beneficiario.nome}}</strong>
                    </div>
                    <div class="column">
                        <span>CPF/CNPJ</span>
                        <strong>{{model.beneficiario.cpfCnpj | cpfcnpj}}</strong>
                    </div>
                    <div class="column">
                        <span>Banco</span>
                        <strong>{{model.beneficiario.banco}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <span>Valor do boleto</span>
                        <strong>{{model.valorBoleto | currency:"BRL"}}</strong>
                    </div>
                    <div class="column">
                        <span>Desconto</span>
                        <strong>{{model.desconto | currency:"BRL"}}</strong>
                    </div>
                    <div class="column">
                        <span>Abatimento</span>
                        <strong>{{model.abatimento | currency:"BRL"}}</strong>
                    </div>
                </div>
                <span class="sub-title">Encargos</span>
                <div class="row">
                    <div class="column">
                        <span>Juros</span>
                        <strong>{{model.encargos.juros | currency:"BRL"}}</strong>
                    </div>
                    <div class="column">
                        <span>Multas</span>
                        <strong>{{model.encargos.multas | currency:"BRL"}}</strong>
                    </div>
                    <div class="column">
                        <span>Mora</span>
                        <strong>{{model.encargos.mora | currency:"BRL"}}</strong>
                    </div>
                </div>
                <span class="sub-title">Conta debitada</span>
                <div class="row">
                    <div class="column">
                        <span>Nome</span>
                        <strong>{{model.contaDebitada.nome | titlecase}}</strong>
                    </div>
                    <div class="column">
                        <span>CPF/CNPJ</span>
                        <strong>{{model.contaDebitada.cpfCnpj | cpfcnpj}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <span>Dados da conta</span>
                        <strong>{{model.contaDebitada.conta.descricao | titlecase}}</strong>
                        <strong>AG: {{model.contaDebitada.conta.agencia}} | CC:
                            {{model.contaDebitada.conta.numero | account}}</strong>
                    </div>
                </div>
            </div>
            <div class="separator"></div>
            <div class="footer">
                <div class="row">
                    <div class="column">
                        <span>Número do compromisso</span>
                        <strong>{{model.numeroCompromisso}}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <span>Código de barras</span>
                        <strong>{{model.codigoBarras}}</strong>
                    </div>
                </div>
                <div class="row">
                    <span>O pagamento será efetivado mediante disponibilidade de saldo na conta</span>
                </div>
            </div>
            <div class="separator"></div>
            <div class="actions">
                <button type="button" class="btn btn btn-outline-primary" (click)="closeModal();goToView('/dda','boletos')">Pagar outro boleto</button>
                <button type="button" class="btn btn btn-outline-primary" (click)="baixarPDF()">Baixar PDF</button>
                <button type="button" class="text-center button-blue mr-2" (click)="closeModal();goToView('/dashboard')">Fechar</button>
            </div>
        </div>
    </ng-template>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>