import {
  ApprovalRequest,
  ApprovalDetailRequest,
  TransferApprovalModel,
  ApprovalandRefuseRequest,
  ApprovalandRefuseModel,
} from './../../domain/transfer-approval/approval.model';
import { Observable } from 'rxjs';
import {  ReceiptRequest } from '@src/core/domain/transfer-receipt/receipt.model';

export abstract class TransferApprovalRepository {
  abstract getList(params: ApprovalRequest): Observable<TransferApprovalModel>;
  abstract getDetail(
    params: ApprovalDetailRequest
  ): Observable<TransferApprovalModel>;
  abstract postApproval(
    params: ApprovalandRefuseRequest
  ): Observable<ApprovalandRefuseModel>;
  abstract postRefused(
    params: ApprovalandRefuseRequest
  ): Observable<ApprovalandRefuseModel>;
  abstract postExportExcel(
    params: ApprovalRequest
  ): Observable<TransferApprovalModel>;
  abstract postReceiptExportExcel(
    params: ReceiptRequest
  ): Observable<TransferApprovalModel>;
}
