import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getHeader } from "@src/core/base/header";
import { DdaAccountModel } from "@src/core/domain/dda/account.model";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";
import { DdaApprovalsModel } from "@src/core/domain/dda/dda-approvals.model.";
import { DddaApproversMastersModel } from "@src/core/domain/dda/dda-approvers.model";
import { DdaBatchCompanyApprovalModel } from "@src/core/domain/dda/dda-batch-company-approval.model";
import { DdaBatchCompanyModel } from "@src/core/domain/dda/dda-batch-company.model";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { TermTypeEnum } from "@src/core/domain/dda/term-types-enum";
import { DdaTermModel } from "@src/core/domain/dda/term.model";
import { DdaTermRepository } from "@src/core/repositories/dda/dda-term.repository";
import { environment } from "@src/environments/environment";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { DdaApprovalsEntity } from "../entity/term/dda-approval.entity";
import { DdaApproversMasterEntity } from "../entity/term/dda-approvers.entity";
import { DdaBatchCompanyApprovalEntity } from "../entity/term/dda-batch-company.entity";
import { DdaBranchEntity } from "../entity/term/dda-branch.entity";
import { DdaCompanyApprovalEntity } from "../entity/term/dda-company-approval.entity";
import { DdaAccountMapper } from "./dda-account.mapper";
import { DdaApprovalsMapper } from "./dda-approvals.mapper";
import { DdaApproversMasterMapper } from "./dda-approvers-master.mapper";
import { DdaBranchMapper } from "./dda-branch.mapper";
import {
  DdaCompanyApprovalMapper,
  TermApprovalMapper,
} from "./dda-company-approval.mapper";
import { EmpresaFilialModel } from "@src/core/domain/dda/empresa-filial.model";

@Injectable({
  providedIn: "root",
})
export class TermImplementationRepository extends DdaTermRepository {
  accountMapper = new DdaAccountMapper();
  approvalCompanyMapper = new DdaCompanyApprovalMapper();
  termApprovalMapper = new TermApprovalMapper();
  branchMapper = new DdaBranchMapper();
  approversMapper = new DdaApproversMasterMapper();
  approvalsMapper = new DdaApprovalsMapper();

  private httpOptions = {
    headers: getHeader(),
  };
  constructor(private http: HttpClient) {
    super();
  }

  get(termType: TermTypeEnum): Observable<DdaTermCompanyApprovalModel> {
    return this.http
      .get<DdaCompanyApprovalEntity>(
        `${environment.URLBase}v1/Dda/recuperar-termo/${termType}`,
        this.httpOptions
      )
      .pipe(
        map(result=>{
          return this.approvalCompanyMapper.mapFrom(result);
        }),
        catchError((a) =>{
          if(!environment.production){
            console.log('Erro ao consultar termo');
            console.error(a);
          }
          return of(null);
        })
        );
  }

  create(): Observable<DdaTermModel> {
    throw new Error("Method not implemented.");
  }

  getAccount(): Observable<DdaAccountModel[]> {
    return this.http.get<DdaAccountModel[]>(
      `${environment.URLBase}v1/contas/`,
      this.httpOptions
    );
  }

  getBranch(id: number): Observable<Array<DdaBranchModel>> {
    return this.http
      .get<DdaBranchEntity>(
        `${environment.URLBase}v1/dda/recuperar-filiais/${id}`,
        this.httpOptions
      )
      .pipe(map(this.branchMapper.mapTo));
  }

  getApprovers(termId: number): Observable<Array<DddaApproversMastersModel>> {
    return this.http
      .get<DdaApproversMasterEntity>(
        `${environment.URLBase}v1/dda/listar-aprovadores/${termId}`,
        this.httpOptions
      )
      .pipe(map(this.approversMapper.mapTo));
  }

  getApprovals(termId: number): Observable<DdaApprovalsModel[]> {
    return this.http
      .get<DdaApprovalsEntity>(
        `${environment.URLBase}v1/dda/recuperar-empresas/${termId}`,
        this.httpOptions
      )
      .pipe(map(this.approvalsMapper.mapFrom));
  }

  addCompanyBatch(payload: DdaBatchCompanyModel): Observable<any> {
    return this.http.post<DdaBatchCompanyModel>(
      `${environment.URLBase}v1/dda/listar-aprovadores/`,
      payload,
      this.httpOptions
    );
  }

  takeApproval() {}

  approvalCompanyBatch(
    payload: DdaBatchCompanyApprovalEntity
  ): Observable<any> {
    return this.http.post<DdaBatchCompanyApprovalModel>(
      `${environment.URLBase}v1/dda/listar-aprovadores/`,
      payload,
      this.httpOptions
    );
  }

  undoCancellation(
    empresaFilial: EmpresaFilialModel
  ): Observable<any> {
    return this.http.put(
      `${environment.URLBase}v1/dda/termo-aceite/desfazer-cancelamento`,
      empresaFilial,
      this.httpOptions
    );
  }

  getLegacyMembership(filiais: boolean): Observable<any>{
    return this.http.get(
      `${environment.URLBase}v1/dda/existe-adesao-legado/${filiais}`,
      this.httpOptions
    );
  }

  getHaveTerm(): Observable<any>{
    return this.http.get(
      `${environment.URLBase}v1/dda/possui-termo`,
      this.httpOptions
    );
  }
}
