import { ModalShareDataRepositories } from '@src/core/repositories/modal-share-data/modal-share-data.respository';
import { Injectable } from "@angular/core";
import { UseCase } from "@src/core/base/use-case";
import { ModalShareDataModel } from "@src/core/domain/modal-share-data/modal-share-data.model";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ModalShareDataUsecase implements UseCase<{}, ModalShareDataModel> {
    constructor(private modalShareDataRepositories: ModalShareDataRepositories) {}

    execute(body: any): Observable<ModalShareDataModel> {
        return this.modalShareDataRepositories.postData(body);
    }
}