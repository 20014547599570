export class VerifyValidData {
  static validator(date: any) {

    if(date === null) return false

    let dtArray = date.split('/')

    let dtDay = parseInt(dtArray[2])
    let dtMonth = parseInt(dtArray[1])
    let dtYear = parseInt(dtArray[0])


    if (dtArray === null) return false

 /*    if (dtDay < 2 || dtMonth < 2) return false

    if (dtYear < 4) return false */

    if (dtYear < 1900) return false

    if (dtMonth < 1 || dtMonth > 12) return false
    else if (dtDay < 1 || dtDay > 31) return false
    else if ((dtMonth === 4 || dtMonth === 6 || dtMonth === 9 || dtMonth === 11) && dtDay === 31) return false
    else if (dtMonth === 2) {
      let isleap = dtYear % 4 === 0 && (dtYear % 100 !== 0 || dtYear % 400 === 0)

      if (dtDay > 29 || (dtDay === 29 && !isleap)) return false
    }

    return true
  }

}