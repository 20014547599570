import { TransactionDetailModel } from './../../../core/domain/transaction/transaction.model';
import { Mapper } from '@src/core/base/mapper';
import { TransactionDetailEntity } from './transaction.entity';

export class TransactionDetailRepositoryMapper extends Mapper<
  TransactionDetailModel,
  TransactionDetailEntity
> {
  mapFrom(param: TransactionDetailEntity): TransactionDetailModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: TransactionDetailModel): TransactionDetailEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
