import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { RolesService } from '@src/shared/services/roles.service';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[fibraHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  // the role the user must have
  @Input() fibraHasRole: string;
  @Input() fibraHasRoleElse?: TemplateRef<unknown>;

  stop$ = new Subject();

  isVisible = false;

  /**
   * @param {ViewContainerRef} viewContainerRef
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   *   -- the templateRef to be potentially rendered
   * @param {RolesService} rolesService
   *   -- will give us access to the roles a user has
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private rolesService: RolesService
  ) {}

  ngOnInit() {
    //  We subscribe to the roles$ to know the roles the user has
    let roles = this.rolesService.get();
    // If he doesn't have any roles, we clear the viewContainerRef
    if (!roles) {
      this.viewContainerRef.clear();
    }
    // If the user has the role needed to
    // render this component we can add it
    //if (roles.includes(this.appHasRole)) {
    // var fibraHasRoleArray = ;
    var all = this.fibraHasRole.split(',').map(x => x.trim());
    var allowed = all.filter(x => !x.startsWith('!'));
    var prohibited = all.filter(x => x.startsWith('!')).map(x => x.slice(1));
    if ((this.containsAny(roles, allowed) || allowed.length === 0 ) && (!this.containsAny(roles, prohibited) || prohibited.length === 0)) {
      // If it is already visible (which can happen if
      // his roles changed) we do not need to add it a second time
      if (!this.isVisible) {
        // We update the `isVisible` property and add the
        // templateRef to the view using the
        // 'createEmbeddedView' method of the viewContainerRef
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      // If the user does not have the role,
      // we update the `isVisible` property and clear
      // the contents of the viewContainerRef
      this.isVisible = false;
      this.viewContainerRef.clear();

      if (this.fibraHasRoleElse) {
        this.viewContainerRef.createEmbeddedView(this.fibraHasRoleElse);
      }
    }
  }

  containsAny(a: string[], b: string[]){
    if (!Array.isArray(b)){
      b = [b];
    }
    return b.some(x => a.includes(x))
  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next();
  }
}