import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DDAPendencyDataShare } from '@src/core/usecases/data-share/dda-pendency-data-share.service';
import { take, delay } from 'rxjs/operators';

function momentToNgbDate(date: moment.Moment): NgbDate {
  return new NgbDate(date.year(), date.month() + 1, date.date());
}

export type Period = { from: NgbDate, to: NgbDate };

@Component({
  selector: "fibra-dda-filter-date",
  templateUrl: "./dda-filter-date.component.html",
  styleUrls: ["./dda-filter-date.component.scss"],
})
export class DddaFilterDateComponent {
  public from?: moment.Moment;
  public to?: moment.Moment;

  private _eventTabs: string = "AVencer";

  public isSchedules: boolean = false;

  public get eventTabs(): string {
    return this._eventTabs;
  }

  @Input()
  public set eventTabs(value: string | undefined) {
    const now = moment();
    this.selectedPeriod = 7;

    value = value === undefined ? "AVencer" : value;

    this.pendencyDdaDataShare
      .getSelectedTab()
      .pipe(take(1),delay(2000))
      .subscribe(tab =>{
        if (tab === 'boletos' && value === 'AVencer') {
          this.selectedPeriod = 15;
          this.changePeriod(this.selectedPeriod);
          this.pendencyDdaDataShare.clearSelectedTab();
        }
      });

    this.isSchedules = value != "Vencido";
    
    if (value == "AVencer" || value == "Agendado") {
      this.minDate = momentToNgbDate(now.clone());
      this.maxDate = momentToNgbDate(now.clone().add(180, "days"))
    }
    else if(value == "Vencido") {
      this.minDate = momentToNgbDate(now.clone().add(-180, "days"));
      this.maxDate = momentToNgbDate(now.clone())
    } else { //Pendentes
      this.minDate = momentToNgbDate(now.clone().add(-180, "days"));
      this.maxDate = momentToNgbDate(now.clone().add(180, "days"))
    }

    this._eventTabs = value;

    this.changePeriod(this.selectedPeriod)
  }
 
  public minDate: NgbDate
  public maxDate: NgbDate

  public periods: number[] = [7, 15, 30, 90];
  public selectedPeriod: number = 7;

  @Output()
  public change: EventEmitter<Period> = new EventEmitter();

  public constructor(private readonly pendencyDdaDataShare: DDAPendencyDataShare) {
    const now = moment();

    this.minDate = momentToNgbDate(now.clone());
    this.maxDate = momentToNgbDate(now.clone().add(180, "days"))
  }

  public changePeriod(value: number) {
    this.selectedPeriod = value;
    
    const now = moment();    

    if(this.eventTabs == "Vencido") {
      this.to   = now.clone();
      this.from = now.clone().add(-value, "days");
    } else {
      this.from = now.clone();
      this.to   = now.clone().add(value, "days");
    }

    this.change.emit({ from: momentToNgbDate(this.from), to: momentToNgbDate(this.to) });
  }
  
  public changePeriodDate(period: Period) {
    moment.locale('pt-br');
    var newTo = moment(`${period.to.year}-${period.to.month}-${period.to.day}`);
    var newFrom = moment(`${period.from.year}-${period.from.month}-${period.from.day}`);
    this.to = newTo;
    this.from = newFrom;
    this.selectedPeriod = 180,
    this.change.emit(period);
  }

  public formatRange(): string {
    return `${this.from.format("DD/MM/YYYY")} à ${this.to.format("DD/MM/YYYY")}`
  }
}
