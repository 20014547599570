<div class="term-content" *fibraHasRole="'MASTER'">
  <div class="row mt-0">
    <div class="col-12 term-title">
      <span class="term-font">Termo de cancelamento</span>
    </div>
    <div class="col-12 mt-3">
      <p class="mt-3 term-text font">
        Pelo presente Termo de Exclusão e Rescisão do Serviço de Débito Direto
        Autorizado – DDA (“TERMO”), o CLIENTE:
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="mt-3 term-text font">
        1.&nbsp;Solicita ao BANCO FIBRA S.A. a exclusão de seus boletos de
        cobrança registrados do Débito Direto Autorizado – DDA, bem como a
        rescisão da prestação de serviços de recebimento de boletos de cobrança
        registrados emitidos contra o próprio CLIENTE pelas INSTITUIÇÕES
        FINANCEIRAS PARTICIPANTES, no prazo de 30 (trinta) dias contados da
        presente solicitação, nos termos das CONDIÇÕES GERAIS À FICHA CADASTRAL
        E PROPOSTA PESSOA JURÍDICA.
      </p>
    </div>
    <div class="col-12">
      <p class="mt-3 term-text font">
        2.&nbsp;O CLIENTE está ciente que, a partir desta data, o BANCO FIBRA
        tomará todas as providências internas necessárias para a exclusão do DDA
        do CLIENTE e de seus Pagadores Agregados, se cadastrados, bem como
        providenciará, no prazo de 1 (um) dia útil contados desta solicitação,
        relatório com informações sobre todos os boletos disponíveis no DDA até
        a data do encerramento dos serviços.
      </p>
    </div>
    <div class="col-12">
      <p class="mt-3">
        3.&nbsp;POR FIM, O CLIENTE TEM CONHECIMENTO E RECONHECE EXPRESSAMENTE
        QUE A EXCLUSÃO DEFINITIVA DO DDA SOMENTE OCORRERÁ APÓS O CLIENTE
        REQUERER A EXCLUSÃO JUNTO A TODOS OS BANCOS EM QUE TENHA ADERIDO AO DDA,
        OU QUANDO ENCERRAR A TOTALIDADE DAS CONTAS DE DEPÓSITO ELEGÍVEIS A
        PARTICIPAÇÃO DO DDA MANTIDAS NESSES BANCOS.
      </p>
    </div>
  </div>
</div>
