
import { FilterComponent } from '@src/presentation/web/components/filter/filter.component';

import {
  ApprovalandRefuseRequest,
  ApprovalList,

} from '@src/core/domain/transfer-approval/approval.model';
import {
  Component,
  EventEmitter,

  OnInit, OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as Util from '@src/shared/util-common';

import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PaymentBatchRepository } from '@src/data/repository/payment-batch/payment-batch-repository';

@Component({
  selector: 'fibra-payment-batch-cnab240-review',
  templateUrl: './payment-batch-cnab240-review.component.html',
  styleUrls: ['./payment-batch-cnab240-review.component.scss']
})
export class PaymentBatchCnab240ReviewComponent implements OnInit {
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private paymentBatchRepository: PaymentBatchRepository
  ) { }
  public data;
  public filter = new FormControl('');
  public loading: boolean;
  public hide: boolean;
  public shown = [];
  public shownAll: boolean;
  public dataAll: ApprovalList[];
  public actionConfirmed: boolean;
  public showConfirmation: boolean;
  public showConfirmationRadio: boolean;
  public checkedArrayIsNotEmpty: boolean;
  public checkedArrayIsExpiredTicket: boolean;
  public oneOrMorePendingChecked: boolean;
  public oneOrMorePendingExpiredChecked: boolean;
  public isPoolPenddingselected: boolean;
  public isExpiredTicketSelected: boolean;
  public showRefusedOrApproveAction: boolean;
  public showIndividualConfirmation: boolean;
  public showIndividualRecusa: boolean;
  public approvalList = [];
  public checkedList = [];
  public action: string;
  public total;
  public params;
  public type;
  public count = 0;
  public dataDetail;
  public loadingDetail: boolean;
  public util = Util;
  public selectedTab: string;
  public status: string;
  public options = [];
  public actionAproveOrRefuse = false;
  public channelEnum;
  public objRequest: ApprovalandRefuseRequest;
  public isSchedules;
  public typToastColor;
  public indexList: boolean;
  public isPendent: boolean;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public AMBARCOLOR = '#D69D10';


  public loadingEncargos: boolean = true;
  public cnab240_arquivo_id: number;


  public detalheArquivoRemessa = [];
  public precisaAprovacao: Boolean;
  public filename: string;

  public showSecurityModal: boolean = false;

  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  @ViewChild(FilterComponent, null)
  filterComp: FilterComponent;
  public generatePDF = (value?) => Util.generatePDF(value);
  public removeMask = (value?) => Util.removeMask(value);
  public userEmail;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const { filename } = params

      this.filename = filename
      this.loadAllCNABFromFile()
    })
    
  }



  ngOnDestroy() {

  }


  ngAfterViewInit() {
  }

  private loadAllCNABFromFile() {
    this.paymentBatchRepository.detalharArquivosCNAB(this.filename).subscribe((data) => this.loadSuccess(data))
  }

  public loadSuccess({ data }) {
    this.detalheArquivoRemessa = [...data.detalheArquivoRemessa];

    this.detalheArquivoRemessa = this.detalheArquivoRemessa.map(item => ({
      ...item,
      valor: Number(item.valor.replaceAll('.', '').replaceAll(',', '.'))
    }));

    this.precisaAprovacao = data.precisaAprovacao;
  }

  public goBack() {
    this.location.back()
  }

  openSecurityModal() {
    this.showSecurityModal = true
  }

  closeSecurityModal() {
    this.showSecurityModal = false
  }


  finishUpload() {
    const { file, nome_arquivo, url } = JSON.parse(localStorage.getItem("file-upload-cnab240"))
    this.paymentBatchRepository.validacaoURL(this.filename).subscribe((data) => {
      
     }, (err) => {
      const currentDate = new Date()

      currentDate.setMinutes(currentDate.getMinutes() + 5)
      localStorage.setItem("upload-batch-success", `${currentDate}`)
      localStorage.setItem("payment-batch-pending-approval", 'true');
      this.location.back()
     })
  

  }
}
