
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountDataShare, DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { AccessDataRepository } from '@src/data/repository/access-repository/access.repository';
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import jwt_decode from "jwt-decode";
import { ToastErrorMessageComponent } from "@src/presentation/web/components/toast-error-message/toast-error-message.component";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";
import { StatusApprovalEnum } from "@src/core/domain/dda/dda-type-approval.enum"; 
import { MasterBackupImplementationRepository } from '@src/data/repository/master-backup-repository/master-backup.implementation.repository';
import { VerificarMasterBackup } from '@src/core/domain/master-backup/master-backup.model';
import { DDAPendencyDataShare } from '@src/core/usecases/data-share/dda-pendency-data-share.service';
import { mergeMap, last } from 'rxjs/operators';
@Component({
  selector: "fibra-transacional",
  templateUrl: "./transacional.component.html",
  styleUrls: ["../dda.scss"],
})
export class DdaTransacionalComponent implements OnInit {
  
  isMaster: boolean = false;
  selectedNavBar: string = "";
  esconderDadosAdesaoPendente: boolean = false;
  esconderDadosCancelamentoPendente: boolean = false;

  @ViewChild(ToastErrorMessageComponent, null)
  public toast: ToastErrorMessageComponent;

  selectNavBarFromBoleto: string = "";

  contasPermitidas: Array<string> = ['CHEQUE EMPRESA', 'CONTA CORRENTE', 'FIBRA FACIL'];
  adhesionInLegado: boolean = false;
  acessoMasterBackup: boolean = false;

  constructor(
    private router: Router,
    private accessDataRepository: AccessDataRepository,
    private readonly shareAccount: AccountDataShare,
    private readonly pendencyDdaDataShare: DDAPendencyDataShare,
    public ddaTermDataShare: DdaTermDataShare,
    private readonly activeRoute :ActivatedRoute,
    private readonly route: Router,
    private termRepository: TermImplementationRepository,
    private masterBackupRepository: MasterBackupImplementationRepository
  ) {
    
  }

  ngOnInit() {    
    this.getLegacyMembership(false);

    this.activeRoute.params.subscribe(p=>this.selectNavBarFromBoleto = p['aba']);
    
    this.verificarAbaSelecionada();

    this.loadTerms();
    this.verificarStatusAprovacao();
    this.verificarMasterBackup();
  }

  private verificarMasterBackup(): void {
    this.masterBackupRepository.verificarMasterBackup().subscribe(
        (data: VerificarMasterBackup) => {
            this.acessoMasterBackup = data.data.acessoMasterBackup;

            if(this.acessoMasterBackup) {
              this.selectedNavBar = "boletos";
            }
        },
        (error: any) => {
            console.log(error);
        });
  }

  private verificarAbaSelecionada(): void {
    let roles = this.verificarRoles();
    let isConsultarBoleto = roles.filter(valor => valor.Name === 'DDA.DDA_CONS_BOL').length > 0;
    let isRealizarPagamento = roles.filter(valor => valor.Name === 'DDA.DDA_REALIZA_PAG_BOL').length > 0;
    let isGerarArquivoCNBA = roles.filter(valor => valor.Name === 'DDA.DDA_GER_ARQ_CNAB').length > 0;
    let isConsultaHistorico = roles.filter(valor => valor.Name === 'DDA.DDA_CONS_HIST').length > 0;
    
    this.activeRoute.params.subscribe(p=>this.selectNavBarFromBoleto = p['aba']);

    this.accessDataRepository.getAccess().pipe(
      last(), 
      mergeMap(data => {
        this.isMaster = data['data'].acessoMaster;
        this.selectedNavBar = this.isMaster ? this.selectNavBarFromBoleto!=null || this.selectNavBarFromBoleto!=undefined ? "boletos" : "termos-dados"  : isConsultarBoleto || isRealizarPagamento || isGerarArquivoCNBA ? "boletos" : isConsultaHistorico ? "historico" : "" ;   
        
        return this.pendencyDdaDataShare.getSelectedTab()
      }
    ))
    .subscribe(
      result => {
        if (result === 'boletos') {
          this.selectedNavBar = result;
        }
      }
    );
  }

  verificarRoles(): Array<UserRole> {
    const token = sessionStorage.getItem('fibra-session');
    const decodedRoleDDA: any = jwt_decode(token);
    return decodedRoleDDA.UserRoles.filter(r => r.Name.startsWith('DDA.'));
  }

  loadTerms(): void {
    let result = this.activeRoute.snapshot.data['termos'];
    this.ddaTermDataShare.setStatusTerm(result[0]);
    this.ddaTermDataShare.setStatusTermCancel(result[1]);
  }  

  verificarStatusAprovacao() {
    this.ddaTermDataShare
      .getStatusTerm()
      .subscribe((result: DdaTermCompanyApprovalModel) => {
        if (result != null && result!=undefined) {
          this.esconderDadosAdesaoPendente = result.status == StatusApprovalEnum.Pendant || result.aprovacoes.filter(x=>x.status == StatusApprovalEnum.Pendant).length > 0;            
        }
      });

    //PENDENTE DE APROVAÇÃO DO CANCELAMENTO
    this.ddaTermDataShare.getStatusTermCancel().subscribe((result: DdaTermCompanyApprovalModel) => {
      if (result !== null && result!=undefined) {
        this.esconderDadosCancelamentoPendente = result.status == StatusApprovalEnum.Pendant || result.aprovacoes.filter(x => x.status === StatusApprovalEnum.Pendant).length > 0;
      }});
  }      

  changeNavbarClass(id: string) {
    if(this.adhesionInLegado || id === 'termos-dados' || id === 'historico') {
      this.selectedNavBar = id;
    }
  }
 
  getLegacyMembership(filiais: boolean): void {
    this.termRepository
      .getLegacyMembership(filiais).subscribe(
        (result: any) => {
          if(result.data){
            this.adhesionInLegado = result.data.success;          
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
}

export class UserRole {
  Name: string;
  NumeroConta: string[];
}
