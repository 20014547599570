export interface PaymentModel<T = any> {
  code: number;
  status: string;
  data?: T;
}

export interface PaymentRequest {
  num_cod_barras: string;
  desc_pagamento: string;
  vlr_pagamento: number;
  dt_pagamento: string;
  num_conta_debito: string;
  email_operador?: string;
  num_canal_pagamento: number;
  num_forma_pagamento: number;
  tipo_pagamento?: number;
  cnpj_cpf_sacado?: string;
}

export interface AprovadorResponse {
  master: boolean;
  cod_pessoa: number;
  cod_pessoa_banco: number;
  nome_pessoa: string;
  email: string;
  criador: boolean;
  num_cpf_cnpj: string;
  master_backup: boolean;
}

export interface PaymentResponse {
  num_operacao: number;
  mensagem: string;
  sucesso: boolean;
  num_compromisso: number;
  desc_etapa_pagamento: string;
  desc_status: string;
  data_agendamento: string;
  data_que_foi_agendado: string;
  aprovadores: AprovadorResponse[];
  cod_febraban: string;
}

export interface PaymentAprovadorResponse {
  master: boolean;
  criador: boolean;
  nome_pessoa: string;
}

export interface PaymentDeleteRequest {
  num_operacao: number;
}

export interface PaymentMultipleRequest {
  lista_pagamentos_efetivar: { id: string, dt_pagamento: string }[];
}

export interface PaymentMultipleDdaRequest {
  lista_pagamentos_efetivar: { codigo_barras: string, sacado: string, cnpj_cpf_sacado: string, dt_vencimento: string, dt_pagamento: string, conta_debito: string, valor: number }[];
}

export interface PaymentBoletoRequest {
  dt_pagamento: string;
  codigoBoleto: string;
  isDda?: boolean
}

export interface PaymentDetalhesBoletoRequest {
  cnpj_cpf_sacado: string;
  codigo_barras: string;
  nosso_numero: string;
}

export interface PagamentoAprovarReprovarResponseViewResponse {
  mensagem: string;
  sucesso: boolean;
  desc_etapa_execucao: string;
  num_compromisso: number;
  num_operacao: number;
  status: number;
}

export interface PaymentDetalhesBoletoResponse {
  id: number;
  codigo_cip: string;
  valor_original: number;
  valor_pago: number;
  data_agendamento: string;
  data_pagamento: string;
  valor_juros: number;
  valor_multa: number;
  valor_mora: number;
  valor_abatimento: number;
  codigo_barras: string;
  nosso_numero: string;
  numero_documento_origem: string;
  aceita_pagamento_parcial: boolean;
  data_vencimento: string;
  valor_desconto: number;
  processando_aprovacao: boolean;
  status: string;
  status_id: number | null;
  cpf_cnpj_sacado: string;
  numero_compromisso: string;
  canal_de_pagamento: string;
  forma_de_pagamento: string;
  beneficiario: string;
  cpf_cnpj_beneficiario: string;
  numero_banco_beneficiario: string;
  nome_conta_pagador: string;
  cpf_cnpj_pagador: string;
  numero_agencia_pagador: string;
  numero_conta_pagador: string;
  tipo_conta_pagador: string;
  nome_banco_beneficiario: string;
  transacao_id: number;
  pagamento_id?: number;
}

export interface PaymentGetResponse {
  num_compromisso: number;
  cod_pagamento: number;
  cod_status_pagamento: number;
  status_pagamento: string;
  cod_canal_pagamento: number;
  canal_pagamento: string;
  cod_forma_pagamento: number;
  desc_transacao: string;
  forma_pagamento: string;
  aceita_parcial: boolean;
  vlr_pago: number;
  vlr_original: number;
  vlr_juros: number;
  vlr_multa: number;
  vlr_mora: number;
  vlr_abatimento: number;
  vlr_desconto: number;
  num_cod_barras: string;
  dt_agendamento: string;
  dt_execucao: string;
  dt_vencimento: string;
  desc_email_operador: string;
  desc_nome_operador: string;
  num_banco_beneficiario: number;
  desc_banco_beneficiario: string;
  num_cpfcnpj_beneficiario: string;
  desc_razao_social_beneficiario: string;
  tipo_pessoa_beneficiario: string;
  num_cpfcnpj_pagador: string;
  desc_razao_social_pagador: string;
  num_conta_pagador: string;
  tipo_pessoa_pagador: string;
  num_agencia_pagador: string;
  tipo_conta_pagador: number;
  desc_tipo_conta_pagador: string;
  num_banco_pagador: string;
  desc_banco_pagador: string;
}

interface MemoriaCalculoViewModel {
  perc_juros: number;
  perc_multa: number;
  perc_desconto: number;
  perc_abatimento: number;
  vlr_juros: number;
  vlr_multa: number;
  vlr_desconto: number;
  vlr_abatimento: number;
  vlr_total: number;
  vlr_mora: number;
  dt_validade: string;
  vlr_minimo: number;
  vlr_maximo: number;
  tipo_calculo: string;
};

export interface PaymentGetBoletoResponse {
  tipo_pessoa: string;
  num_cpf_cnpj: string;
  nome_beneficiario: string;
  nome_fantasia_beneficiario: string;
  cep_beneficiario: string;
  nome_pagador: string;
  num_cpf_cnpj_pagador: string;
  nome_fantasia_pagador: string;
  codigo_barras: string;
  num_linha_digitavel: string;
  dt_vencimento: string;
  vlr_titulo: number;
  status: number;
  desc_status: string;
  num_banco: string;
  desc_banco: string;
  dt_limite_pagamento: string;
  mem_calculo: MemoriaCalculoViewModel;
}

export interface PaymentGetBoletoDetalhadoResponse {
  permite_pagamento: boolean;
  boleto: PaymentGetBoletoResponse,
  pagamento?: {
    mensagem: string;
    numero_operacao: number;
    numero_compromisso: number;
    etapa_pagamento: string;
    status: string;
    data_agendamento: string;
    aprovadores?: AprovadorResponse[];
  }
}

export interface AprovarReprovarPagamentoBoletoDDA {
  pagamento_id: number;
  transacao_id: number;
}

export type OtpOptions = {  
  closeEvent: () => void,
} & (
  {
    apiCallback: 1
    apiPayload:  PaymentRequest,
    responseValidation: (response: PaymentModel<PaymentResponse>) => void,
  } |
  {
    apiCallback: 2
    apiPayload:  PaymentMultipleRequest
    responseValidation: (response: PaymentModel<PaymentResponse>) => void,
  } |
  {
    apiCallback: 137
    apiPayload:  PaymentMultipleDdaRequest
    responseValidation: (response: PaymentModel<PaymentResponse>) => void,
  } |  
  {
    apiCallback: 134 | 135
    apiPayload:  AprovarReprovarPagamentoBoletoDDA,
    responseValidation: (response: PaymentModel<PagamentoAprovarReprovarResponseViewResponse>) => void,
  }
)
