import { Injectable } from '@angular/core';
import { InstructionsTypeRepository } from '@src/core/repositories/instructions/instructions-type.repository';
import { InstructionsTypeModel, InstructionsRequest } from '@src/core/domain/instructions/instructionsType.model';
import { Observable } from 'rxjs';
import { InstructionsTypeRepositoryMapper } from '@src/data/repository/instructions/instructionsType-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { InstructionsTypeEntity  } from '@src/data/repository/instructions/instructionsType.entity';
import { flatMap, map } from 'rxjs/operators';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class InstructionsTypeDataRepository extends InstructionsTypeRepository {
  mapper = new InstructionsTypeRepositoryMapper();

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getInstructionsTypes(): Observable<InstructionsTypeModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<InstructionsTypeEntity>(
        `${environment.URLBase}v1/titulos/instrucao`,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  setInstructionsIndex(params: InstructionsRequest): Observable<InstructionsTypeModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<InstructionsTypeEntity>(
        `${environment.URLBase}v1/titulos/instrucao`,params,
        httpOptions
        )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}
