import { Injectable } from '@angular/core';
import { ArchiveModelRepository } from '@src/core/repositories/charge/archive.repository';
import { UseCase } from '@src/core/base/use-case';
import {
  ListArchivesModel,
  ListArchivesRequest,
} from '@src/core/domain/charge/archive.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetListArchiveUsecase implements UseCase<ListArchivesRequest, ListArchivesModel> {
  constructor(private archiveRepository: ArchiveModelRepository) {}

  execute(params: ListArchivesRequest): Observable<ListArchivesModel> {
    return this.archiveRepository.getArchiveList(params);
  }
}
