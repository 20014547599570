import { HostListener, Input, Directive } from '@angular/core';
import { OpenBankingTransactionStatusEnum } from '@src/core/domain/openbanking/openbanking.transaction.status.enum';
import { OpenbankingPaymentRequestComponent } from './openbanking-paymentrequest.component';
    
@Directive({
    selector: 'fibra-openbanking-paymentrequest-beforeunload'
})
export class OpenbankingPaymentRequestBeforeUnloadComponent {

    @Input() component: OpenbankingPaymentRequestComponent;

    @HostListener('window:beforeunload', ['$event'])
    checkModified($event) {
        if (this.component.status == OpenBankingTransactionStatusEnum.InProgress) {
            $event.returnValue = true;
        };
    }
}