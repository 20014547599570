import { Injectable } from '@angular/core';
import { PaymentRepository } from '@src/core/repositories/payment/payment.repository';
import { UseCase } from '@src/core/base/use-case';
import { PaymentModel, PaymentRequest, PaymentBoletoRequest } from '@src/core/domain/payment/payment.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetBoletoUsecases implements UseCase<PaymentBoletoRequest, PaymentModel> {

  constructor(private paymentRepository: PaymentRepository) { }

  execute(params: PaymentBoletoRequest): Observable<PaymentModel> {
    return this.paymentRepository.getBoleto(params);
  }  
}
