
  <div class="modal-header" style="border: none; padding:10px 10px ;">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" style="color: #082A4D;">&times;</span>
    </button>
  </div>
  <div *ngIf="getBacen" class="modal-body" style="padding: 0">
    <div class="row">
      <div class="col-12 d-flex justify-content-center my-4">
          <img class="image" src="/assets/svg/information.svg" alt="pix-image">
      </div>
      <div class="col-12 px-5 text-center text-information">
        <p>Antes de realizar uma reclamação no Banco Central do Brasil, tente resolver o problema junto à nossa área de atendimento.</p>
      </div>
      <div class="col-12 d-flex justify-content-center my-4">
        <button class="button-primary " (click)=" goToSiteBacen()"><span> Canal de Atendimento</span> <br><span> Banco Central do Brasil</span></button>
      </div>
    </div>
  </div>

  <div *ngIf="!getBacen" class="modal-body" style="padding: 0">
    <div class="row px-3 mb-4">
      <div class="col-12 d-flex justify-content-center mb-3">
          <img class="image-2" src="/assets/svg/information.svg" alt="pix-image">
      </div>
      <div class="col-6 block-information mb-2">
        <h2 class="mb-1">Canais Exclusivos</h2>
        <span class="span-infor">Corporate, Agro e PMEs:</span>
        <span class="span-infor is-bold">(11) 4130-7449</span>
        <span class="span-infor is-bold">relacionamento@bancofibra.com.br</span>
        <span class="span-infor">Das 9h às 18h, de segunda a <br> sexta-feira, exceto feriados</span>
        <div class="divider"></div>

        <h2 class="mb-1">Ouvidoria</h2>
        <span class="span-infor">Das 10h às 16h, de segunda a <br> sexta-feira, exceto feriados</span>
        <span class="span-infor is-bold">0800 727 0132</span>
      </div>

      <div class="col-6 block-information mb-2">
        <h2 class="mb-1">SAC</h2>
        <span class="span-infor">Canal de Atendimento ao Cliente</span>
        <span class="span-infor is-bold">0800 727 0132</span>
        <div class="divider"></div>

        <h2 class="mb-1">Acessibilidade</h2>
        <span class="span-infor">Canal exclusivo para deficientes auditivos ou de fala <div class="span-infor is-bold">0800 200 6921</div></span>
        <div class="divider"></div>
        <span class="span-infor">Ainda não resolveu o seu problema, <a (click)="callBacen()">clique aqui</a></span>
      </div>
    </div>
  </div>
