import { TransactionDetailModel, TransacaoAprovadorResponse } from './../../../core/domain/transaction/transaction.model';
import { TransactionDetailRepositoryMapper } from './transaction-repository.mapper';
import { TransactionDetailRepository } from '@src/core/repositories/transaction/transaction.repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';
// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { TransactionDetailRequest } from '@src/core/domain/transaction/transaction.model';
import { TransactionDetailEntity } from './transaction.entity';

@Injectable({
  providedIn: 'root',
})
export class TransactionDetailDataRepository extends TransactionDetailRepository {
  mapper = new TransactionDetailRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getDetail(obj: TransactionDetailRequest): Observable<TransactionDetailModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };

    return this.http
      .get<TransactionDetailEntity>(
        `${environment.URLBase}v1/transacoes/${params.idTransacao}/detalhes`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getAprovadoresPendentesDda(transacaoId: number): Observable<TransactionDetailModel<TransacaoAprovadorResponse>> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { transacaoId };

    return this.http
      .get<TransactionDetailEntity>(
        `${environment.URLBase}v1/TransacoesAprovadores/listar-pendentes/${params.transacaoId}`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
