import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BannerEmailDataRepository } from '@src/data/repository/dashboard/banner-email.repository';
import * as Util from '@src/shared/util-common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fibra-opportunity-form',
  templateUrl: './opportunity-form.component.html',
  styleUrls: ['./opportunity-form.component.scss'],
})
export class OpportunityFormComponent implements OnInit {

  @ViewChild('content', { static: true }) content: HTMLElement;

  public contactPreference = [{ name: "E-mail", value: 1 }, { name: "Celular", value: 2 }];
  public hasOpened: boolean;
  public itemSelected: string = 'Selecione um opção';
  public showMessaEmailIsInvalid: boolean = false;
  public juridico: string = '*Sujeito a análise, aprovação de crédito e demais condições dos produtos.';
  public typToastColor;
  public REDCOLOR: string = '#ED6C6C';
  public params = {
    product_name: '',
    name: '',
    num_cpf_cnpj: '',
    phone: '',
    email: '',
    contact_type: ''
  };

  public formInterested: FormGroup = this.fb.group({
    razaoSocial: ["", [Validators.required]],
    cnpj: ["",[Validators.required]],
    celular: ["",[Validators.required]],
    email: ["",[Validators.required, Validators.email]]
  });

  cpfcnpjMask = (value) => Util.cpfcnpjmask(value);
  public celMask = ['+', '55', '', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    public bannerEmailDataRepository: BannerEmailDataRepository,
    private toastr: ToastrService
    ) {}

  ngOnInit() {
    this.identifyProduct();
  }

  identifyProduct() {
    this.juridico = '*Sujeito a análise, aprovação de crédito e demais condições dos produtos.';
    switch (this.router.url) {
      case '/capital-giro':
        this.params.product_name = 'Capital de Giro';
        break;
      case '/cambio':
        this.params.product_name = 'Câmbio';
        break;
      case '/seguros':
        this.params.product_name = 'Fibra Corretora de Seguros';
        this.juridico = '*Sujeito a análise e aprovação da Seguradora.';
        break;
      case '/fianca-bancaria':
        this.params.product_name = 'Fiança Bancária';
        break;
    
      default:
        break;
    }
  }

  setFocusEmail() {
    this.showMessaEmailIsInvalid = true;
  }

  public toggle() {
    this.hasOpened = !this.hasOpened;
  }
  
  select(item) {
    this.itemSelected = item.name;
    this.params['contact_type'] = item.name;
  }

  sendForm() {
    this.params['name'] = this.formInterested.controls['razaoSocial'].value;
    this.params['num_cpf_cnpj'] = this.formInterested.controls['cnpj'].value;
    this.params['phone'] = this.formInterested.controls['celular'].value;
    this.params['email'] = this.formInterested.controls['email'].value;

    this.bannerEmailDataRepository.sendBannerEmail(this.params).subscribe((_res) => {
      this.openModal(this.content);
    }, (_err) => {
      this.typToastColor = this.REDCOLOR;
      this.toastr.error('Ocorreu um erro no envio.', null, { closeButton: true });
      this.closeModal();
    })
  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal(): void {
    this.clearForm();
    this.modalService.dismissAll();
  }

  clearForm() {
    this.formInterested.reset();
    this.itemSelected = 'Selecione um opção';
    this.params = {
      product_name: '',
      name: '',
      num_cpf_cnpj: '',
      phone: '',
      email: '',
      contact_type: ''
    };
    this.showMessaEmailIsInvalid = false;
  }
}
