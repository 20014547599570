import {
  TransferModel,
  TransferRequest,
} from '@src/core/domain/transfer/transfer.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { TransferRepository } from '@src/core/repositories/transfer/transfer.repository';

@Injectable({
  providedIn: 'root',
})
export class TransferUsecases
  implements UseCase<TransferRequest, TransferModel> {
  constructor(private transferRepository: TransferRepository) {}

  execute(params: TransferRequest): Observable<TransferModel> {
    return this.transferRepository.addTransfer(params);
  }
}
