import { Observable } from 'rxjs';
import {
  PaymentModel,
  PaymentRequest,
  PaymentBoletoRequest,
  PaymentDeleteRequest,
  PaymentMultipleRequest,
  PaymentDetalhesBoletoRequest,
  PaymentDetalhesBoletoResponse,
  PaymentAprovadorResponse,
  PaymentGetBoletoResponse,
  PaymentGetBoletoDetalhadoResponse,
  PaymentGetResponse
} from '@src/core/domain/payment/payment.model';
import { DdaBoletoConsultaModel } from "@src/core/domain/dda/dda-consulta-boleto.model";
import { DdaBoletoModel } from "@src/core/domain/dda/dda-boleto.model";
import { DdaBoletosStatusErroModel } from "../../domain/dda/dda-boletos-status-erro.js";
import { DdaBoletosStatusErroResponse } from "../../domain/dda/dda-boletos-status-erro-response.js";

export abstract class PaymentRepository {
  abstract getBoleto(obj: PaymentBoletoRequest): Observable<PaymentModel<PaymentGetBoletoResponse>>;
  abstract getBoletoDetalhado(request: PaymentBoletoRequest): Observable<PaymentModel<PaymentGetBoletoDetalhadoResponse>>;
  abstract postBoleto(obj: PaymentRequest): Observable<PaymentModel>;
  abstract saveBoletos(obj: PaymentRequest): Observable<PaymentModel>;
  abstract deleteBoletos(obj: PaymentDeleteRequest): Observable<PaymentModel>;
  abstract getDetalhes(id: number): Observable<PaymentModel<PaymentGetResponse>>;
  abstract postMultipleBoleto(obj: PaymentMultipleRequest): Observable<PaymentModel>;
  abstract getStatusHourPayment(x: number): Observable<PaymentModel<{ status: "ABERTO" | "FECHADO" }>>;
  abstract listarBoletosDda(param: DdaBoletoConsultaModel): Observable<PaymentModel<DdaBoletoModel[]>>  
  abstract getBoletosStatusErro(param: DdaBoletosStatusErroModel): Observable<PaymentModel<DdaBoletosStatusErroResponse>>  
  abstract listarAprovadores(pagamentoId: number): Observable<PaymentModel<PaymentAprovadorResponse[]>>;
}

