import { HttpClient } from '@angular/common/http';
import { Inject, Injectable,LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { handleError } from '@src/shared/util-common';
import { ReceivedPaymentEntity, ReceivedPaymentResponse } from './received-payment.entity';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ReceveidPaymentDataRepository {

  constructor(private http: HttpClient,@Inject(LOCALE_ID) private locale?: string) {}

  PostExcel(obj: any, url: string): Observable<any> {
    //const params = { ...obj };
    return this.http
      .post<any>(
        `${url}`,
        obj,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }
  

  postExportExcelOrdemRecebida(): Observable<any> {
    let json = JSON.parse(sessionStorage.getItem('excel_info'))

    let referencia = json.referencia || null;
    let dataInicio = null;
    let dataFinal = null;

    if(json.dt_fim!==undefined){
      dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
    }
    if(json.dt_ini!==undefined){
      dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
    }


    let obj = {
      dataInicial:dataInicio,
      dataFinal: dataFinal,
      referencia : referencia
    }
   
    return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/ordempagto/ordem-pagamento-recebida/excel`);
  }
  postExportExcelOrdemEmitida(): Observable<any> {
    let json = JSON.parse(sessionStorage.getItem('excel_info'))
    let referencia = json.referencia || null;
    let dataInicio = null;
    let dataFinal = null;

    if(json.dt_fim!==undefined){
      dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
    }
    if(json.dt_ini!==undefined){
      dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
    }

    let obj = {
      dataInicial:dataInicio,
      dataFinal: dataFinal,
      referencia : referencia
    }
    return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/ordempagto/ordem-pagamento-emitida/excel`);
  }
  

  getReceivedPaymentEmSer(endPeriod:string): Observable<ReceivedPaymentResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      try {
        return this.http
        .get<ReceivedPaymentResponse>(
          `${environment.URLBase}v1/comercioexterior/ordempagto/recebida?dt_ini=null&dt_fim=${endPeriod}`,
          httpOptions
        )
     }
     catch(error) {
         handleError(error);
     }
}

getReceivedPaymentContract(contractNum:string): Observable<ReceivedPaymentResponse> {
  const httpOptions = {
    headers: getHeader(),
  };
    try {
      return this.http
      .get<ReceivedPaymentResponse>(
        `${environment.URLBase}v1/comercioexterior/ordempagto/recebida/ref/${contractNum}`,
        httpOptions
      )
   }
   catch(error) {
       handleError(error);
   }
   
}
}
