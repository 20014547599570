import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StatusApprovalEnum } from "@src/core/domain/dda/dda-type-approval.enum";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";

@Component({
  selector: "fibra-dda-main",
  templateUrl: "./dda-main.component.html",
})
export class DdaMainComponent implements OnInit {
  isMaster: boolean = false;
  showModal: boolean = false;

  constructor(
    public readonly ddaTermDataShare: DdaTermDataShare,
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    this.isMaster = JSON.parse(localStorage.getItem("acessoMaster"));
  }

  ngOnInit() {
    this.loadTerms();
  }

  loadTerms(): void {
    let result: DdaTermCompanyApprovalModel[] = this.activeRoute.snapshot.data['termos'];
    this.ddaTermDataShare.setStatusTerm(result[0]);
    this.ddaTermDataShare.setStatusTermCancel(result[1]);
    if (result[0] === null && !this.isMaster) {
      this.showModal = true;
    }
    this.parseRedirect(result);
  }


  parseRedirect(result: DdaTermCompanyApprovalModel[]): void {
    let statusTerm = result[0];
    let statusTermCancel = result[1];

    if (statusTerm != null && statusTerm != undefined) {      
      if ((statusTerm.status === StatusApprovalEnum.Reproved || statusTerm.status === StatusApprovalEnum.Canceled ) && (statusTermCancel.status === null || statusTermCancel.status === StatusApprovalEnum.Approved) ) {
        this.router.navigate(["dda-aceite"]);
      } else {
        let nav: any;
        this.activeRoute.params.subscribe(param=> nav= param['aba']);
        if(nav!=null && nav!=undefined){
          this.router.navigate(["dda-transacional", {aba: nav}], { skipLocationChange: true, replaceUrl: true });
        }
        else {
          this.router.navigate(["dda-transacional"]);
        }
      }
    } else {
      if (!this.isMaster) {
        this.showModal = true;
      }
      this.router.navigate(["dda-aceite"]);
    }
  }
}