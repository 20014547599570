import { Mapper } from "@src/core/base/mapper";
import { DdaApprovalsModel } from "@src/core/domain/dda/dda-approvals.model.";
import {
  DdaApprovalsEntity,
  DdaApproverEntity,
} from "../entity/term/dda-approval.entity";

export class DdaApprovalsMapper
  implements Mapper<DdaApprovalsEntity, Array<DdaApprovalsModel>>
{
  mapFrom(param: DdaApprovalsEntity): Array<DdaApprovalsModel> {
    return param.data.map((item: DdaApproverEntity) => {
      return {
        empresa_id: item.empresa_id,
        termo_id: item.termo_id,
        cnpj: item.cnpj,
        nome: item.nome,
        aprovadores: item.aprovadores,
        status_id: item.status_id,
        descricao_status: item.descricao_status,
      };
    });
  }

  mapTo(param: Array<DdaApprovalsModel>): DdaApprovalsEntity {
    throw new Error("Method not implemented.");
  }
}
