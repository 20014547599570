<div class="container-main">
  <div class="layer-1 flex">
    <div class="item-1">
      <span>Horário diurno</span>
      <span>06:01 às {{formatHour(timeDay)}}</span>
    </div>
 
    <div class="item-2">
      <span class="format-span">Horário noturno</span>

      <div
        class="flex margin-top-14"
        [ngClass]="changeTime ? 'remove-margin-top' : ''"
      >
        <div class="gp-time">
          <span *ngIf="!changeTime" class="tm-start">{{formatHour(timeNight)}}</span>
          <fibra-dropdown
            *ngIf="changeTime"
            [options]="timesList"
            (change)="timeSelected($event)"
          ></fibra-dropdown>
        </div>

        <div class="gp-time">
          <span class="tm-end"> às 06:00</span>
        </div>
      </div>
    </div>

    <div class="item-3">
      <span>*Horário de Brasília</span>
    </div>
  </div>
</div>
