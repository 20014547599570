import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import * as $ from 'jquery';

// model
import { StepperModel } from '@src/core/domain/business-models';
@Component({
  selector: 'fibra-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() stepObj: StepperModel[];
  @Input() firstStep: TemplateRef<any>;
  @Input() secondStep: TemplateRef<any>;
  @Input() thirdStep: TemplateRef<any>;

  constructor() {}

  /**
   * @description
   * Add a Title to componente on view
   */
  ngOnInit() {
    $(document).ready(() => {
      function _desableStep1() {
        $('.step-1').addClass('d-none').removeClass('d-block');
        $('.step1').find('.number').addClass('disabled');
        $('.step1').find('p').addClass('disabled-text');
      }

      function _desableStep2() {
        $('.step-2').addClass('d-none').removeClass('d-block');
        $('.step2').find('.number').addClass('disabled');
        $('.step2').find('p').addClass('disabled-text');
      }

      function _enableStep1() {
        $('.step-1').removeClass('d-none');
        $('.step1').find('.number').removeClass('disabled');
        $('.step1').find('p').removeClass('disabled-text');
      }

      function _enableStep2() {
        $('.step-2').removeClass('d-none');
        $('.step2').find('.number').removeClass('disabled');
        $('.step2').find('p').removeClass('disabled-text');
      }

      _desableStep2();

      $('.next').on('click', (e) => {
        e.preventDefault();
        _desableStep1();
        _enableStep2();
      });

      $('.preview').on('click', (e) => {
        e.preventDefault();
        _desableStep2();
        _enableStep1();
      });
    });
  }
}
