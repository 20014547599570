<div class="content-approval">
    <div class="content-options">
        <!-- <div class="options-div">
            <span [ngClass]="selectedNavBar === 'keys' ? 'option-selected': 'option-unselected'" (click)="handleSelecterdNav('keys')"
                style="margin-right: 17px; cursor: pointer;"> Chaves</span>
            <div class="separator"></div>
            <span [ngClass]="selectedNavBar === 'sendSolicitation' ? 'option-selected': 'option-unselected'" (click)="handleSelecterdNav('sendSolicitation')"
                style="margin-right: 17px; margin-left: 16px; cursor: pointer;">
                Solicitações enviadas
            </span>
            <div class="separator"></div>
            <span [ngClass]="selectedNavBar === 'receiveRequest' ? 'option-selected': 'option-unselected'" (click)="handleSelecterdNav('receiveRequest')"
                style="margin-left: 16px; cursor: pointer;"> Solicitações recebidas
            </span>
        </div> -->
        <div class="sub-options-div">
            <span [ngClass]="seletedFilter === 'pending' ? 'option-selected': 'option-unselected'" (click)="handleFilter('pending')"
                style="margin-right: 17px; cursor: pointer;"> Pendentes</span>
            <div class="separator"></div>
            <span [ngClass]="seletedFilter === 'approved' ? 'option-selected': 'option-unselected'" (click)="handleFilter('approved')"
                style="margin-right: 17px; margin-left: 16px; cursor: pointer;">
                Aprovadas</span>
            <div class="separator"></div>
            <span [ngClass]="seletedFilter === 'refused' ? 'option-selected': 'option-unselected'" (click)="handleFilter('refused')"
                style="margin-left: 16px; cursor: pointer;"> Recusadas</span>
        </div>
    </div>
    <div class="keys-content" [ngSwitch]="seletedFilter" *ngIf="!loading">
        <div>
            <div class="cards approved" *ngSwitchCase="'approved'">
                <div class="card-item" *ngFor="let key of keyapproved">
                    <div class="status">
                        <span class="status-font"> Status</span>
                        <div class="circle-green"> </div>
                        <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 8px;" *ngIf="key.status.includes('pendente')">
                        <span class="status-message-green"> <span *ngIf="key.tp_operacao === 'CRIAR'">
                                Cadastro de chave aprovado</span><span *ngIf="key.tp_operacao === 'EXCLUIR'">
                                Exclusão de chave aprovada</span></span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aletória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '+00 00 0000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value">{{key.conta.num_conta}} - {{key.conta.desc_conta}}</span>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_criacao | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards refused" *ngSwitchCase="'refused'">
                <div class="card-item" *ngFor="let key of keysRefused">
                    <div class="status">
                        <span class="status-font"> Status</span>
                        <div class="circle-red" *ngIf="true"> </div>
                        <span *ngIf="false" class="icon-info notification" [appTooltip]="tooltipAll">
                            <img src="/assets/png/info-damage.png" style="margin-right: 4px !important;" alt="logo" />
                            <div #tooltipAll class="tooltip-custom d-flex align-items-center">
                                <div class="text d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-12 text-left">
                                            <p class="text"> A pessoa que cadastrou esta chave confirmou que ainda a usa.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>


                        </span>
                        <span class="status-message-red"> <span> <span *ngIf="key.tp_operacao === 'CRIAR'">
                                    Cadastro de chave recusada</span><span *ngIf="key.tp_operacao === 'EXCLUIR'">
                                    Exclusão de chave recusada</span></span></span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aletória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '++00 00 0000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value">{{key.conta.num_conta}} - {{key.conta.desc_conta}}</span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Recusado pelo(s) master(s)</span>
                            <div *ngFor="let item of key.aprovacoes">
                                <p class="acc-value" *ngIf="item.status === 'REPROVADO'">{{item.pessoa.nome_pessoa}}</p>
                            </div>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_criacao | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards pending" *ngSwitchCase="'pending'">
                <div class="card-item" *ngFor="let key of keyPending">
                    <div class="status">
                        <span class="status-font" *ngIf="true"> Status :</span>
                        <div class="circle-orange" *ngIf="isPeddingotherMaster(key.aprovacoes)"> </div>
                        <span class="icon-alert">
                            <span class="icon-info notification" [appTooltip]="tooltipAll" *ngIf="!isPeddingotherMaster(key.aprovacoes)">
                                <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;" alt="logo" />
                                <div #tooltipAll class="tooltip-custom-alert">
                                    <p style="font-size: 14px;" class="text-center">Aguardando aprovação</p>
                                    <div class="master" style="text-align: start;
                                    margin-left: 22px;">
                                        <div class="masters-title" style="font-size: 12px; margin-top: 5px;">
                                            <img src="/assets/svg/marcador-icon.svg">
                                            <span class="master-font"> Masters </span>
                                        </div>

                                        <span class="master-sub" style="color: #8F969D;
                                        font-size: 12px; margin-top: 5px;" *ngFor="let item of key.aprovacoes">
                                            <p *ngIf="item.pode_aprovar && item.obrigatoria" style="margin:0px">{{item.pessoa.nome_pessoa}}</p>
                                        </span>

                                    </div>
                                    <div class="arrow"></div>
                                </div>
                            </span>
                        </span>
                        <img src="/assets/svg/pending-watch.svg" alt="watch" style="margin-right: 8px;" *ngIf="false">
                        <span class="status-message-orange"> <span *ngIf="isPeddingotherMaster(key.aprovacoes)"><span *ngIf="key.tp_operacao === 'CRIAR'">
                                    Cadastro de chave pendente de aprovação</span><span *ngIf="key.tp_operacao === 'EXCLUIR'">
                                    Exclusão de chave pendente de aprovação</span></span><span *ngIf="!isPeddingotherMaster(key.aprovacoes)"><span
                                    *ngIf="key.tp_operacao === 'CRIAR'">
                                    Cadastro de chave pendente de aprovação</span><span *ngIf="key.tp_operacao === 'EXCLUIR'">
                                    Exclusão de chave pendente de aprovação</span></span></span>
                    </div>
                    <div class="card-content">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value">
                                <span *ngIf="key.tp_chave === 'EVP'">{{key.chave ? key.chave : 'Chave aletória'}}</span>
                                <span *ngIf="key.tp_chave === 'CPF' || key.tp_chave === 'CNPJ'">{{key.chave | cpfcnpj}}</span>
                                <span *ngIf="key.tp_chave === 'PHONE'">{{key.chave | mask: '++00 00 0000-0000' }}</span>
                                <span *ngIf="key.tp_chave === 'EMAIL'">{{key.chave}}</span>
                            </span>
                        </div>
                        <div class="account">
                            <span class="acc-title"> Conta</span>
                            <span class="acc-value">{{key.conta.num_conta}} - {{key.conta.desc_conta}}</span>
                        </div>
                        <div class="date">
                            <span class="date-title"> Data e hora</span>
                            <span class="date-time">{{key.data_criacao | date:'dd/MM/yyyy HH:mm' }}</span>
                        </div>
                        <div class="card-options" *ngIf="isPeddingotherMaster(key.aprovacoes)">
                            <img src="/assets/svg/fechar.svg" alt="fechar" style="margin-right: 32px; cursor: pointer;">
                            <img src="/assets/svg/aprovar.svg" alt="aprovar" style="cursor: pointer;">
                        </div>
                    </div>
                    <div class="card-content-two" *ngIf="enable">
                        <div class="key">
                            <span class="title"> Chave</span>
                            <span class="key-value"> email@empresa.com.br</span>
                        </div>
                        <div class="text-div">
                            <span class="text-font">
                                Recebemos pedido de reivindicação de posse da chave Pix nome@empresa.com.br. Isso significa que outro usuário registrou pedido
                                para que essa chave seja vinculada a outra conta. Para cancelar esse processo e para que
                                você possa continuar utilizando essa chave, é necessário que você faça nova validação de
                                posse até o dia 24/12/2020
                            </span>
                        </div>
                        <div class="buttons">
                            <button type="button" class="button-secondary">
                                Recusar
                            </button>
                            <button type="button" class="button-primary"> Aprovar </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="keys-content" style="height: 200px;justify-content: center;align-items: center;" *ngIf="!loading">
        <span class="no-content" *ngIf="false">
            Nenhuma solicitação de portabilidade/reivindicação de chave pendente ainda.
        </span>
        <span class="no-content" *ngIf="keyPending.length === 0 && seletedFilter === 'pending'">
            Nenhuma solicitação de cadastro de chave pendente ainda.
        </span>
        <span class="no-content" *ngIf="keyapproved.length === 0 && seletedFilter === 'approved'">
            Nenhuma solicitação de cadastro de chave aprovada ainda.
        </span>
        <span class="no-content" *ngIf="keysRefused.length === 0 && seletedFilter === 'refused'">
            Nenhuma solicitação de cadastro de chave recusada ainda.
        </span>
    </div>

    <div class="keys-content" *ngIf="loading" style="height: 200px;justify-content: center;align-items: center;">
        <div class="d-flex justify-content-center position loading-container">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>

</div>