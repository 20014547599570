<div *ngIf="simulacao && !loading" class="impersonate-bar fixed-top">
  <div *ngIf="selectedUser == 0 && !stopSession" class="simulating">
    <div>Você está simulando o usuário <span class="bold">{{user.tipo_acesso}} - {{user.nome_pessoa}}</span> </div>

    <select class="form-select form-select-sm select-user" aria-label=".form-select-sm example"
      [(ngModel)]='selectedUser' (ngModelChange)="onChange($event)">
      <option [ngValue]="0" disabled>Trocar usuário</option>
      <option *ngFor="let u of users" [value]="u.cod_pessoa">{{u.nome_pessoa}}</option>
    </select>


    <div class="button">
      <button type="button" class="button-primary" (click)="messageStopImpersonate()">
        Encerrar simulação
      </button>
    </div>

  </div>


  <div *ngIf="selectedUser != 0" class="change-simulation">

    <div>Você deseja trocar para o usuário <span class="bold">{{nextUser.tipo_acesso}} - {{nextUser.nome_pessoa}}?</span> </div>

    <div class="button">
      <button type="button" class="button-cancel" (click)="cancelChange()">
        Cancelar
      </button>
    </div>

    <div class="button">
      <button type="button" class="button-primary" (click)="impersonate()">
        Confirmar
      </button>
    </div>

  </div>

  <div *ngIf="stopSession" class="change-simulation">

    <div>Você realmente deseja encerrar essa simulação? </div>

    <div class="button">
      <button type="button" class="button-cancel" (click)="cancelStop()">
        Cancelar
      </button>
    </div>

    <div class="button">
      <button type="button" class="button-primary" (click)="stopImpersonate()">
        Confirmar
      </button>
    </div>

  </div>



</div>