import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as models from '@src/data/repository/data-share-repository';
import { InvestimentsDataRepository } from '@src/data/repository/investiments/investiments.repository';
import { InvestimentsEnum, InvestimentsProdutosEnum } from '@src/data/repository/investiments/investiments.entity';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { PostExportExcelUsecase } from '@src/core/usecases/transfer-approval/post-export-approval-usecase';
import { titulosList } from '@src/data/repository/data-share-repository';
import * as Util from '@src/shared/util-common';
import * as $ from 'jquery';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-investments-table',
  templateUrl: './investments-table.component.html',
  styleUrls: ['./investments-table.component.scss']
})
export class InvestmentsTableComponent implements OnInit, OnDestroy {
  public token = undefined;

  public options;
  public routAccont: string;
  public investimentFormGroup: FormGroup;
  public noData = false;
  public noDataProdutos = true;
  public indexList;
  public produtos: InvestimentsProdutosEnum[];
  public productSelect: string;
  public investimentsDetails;
  public dataInvalida = false;
  public dataMaior = false;


  loading = false;
  loadingDetails = false;
  hasError = false;
  selectedYear = 0;
  optionPeriod = [(new Date()).getFullYear(), (new Date()).getFullYear() - 1, (new Date()).getFullYear() - 2];
  maxDate: NgbDate;
  minDate: NgbDate;
  investimentDate: Date;
  investiments: InvestimentsEnum[];
  total: {
    valorAplicado: number,
    rendBruto: number,
    valorLiq: number
  };
  public typToastColor;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  constructor(
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private InvestimentsService: InvestimentsDataRepository
  ) {
    this.minDate = this.calendar.getPrev(this.calendar.getToday(), 'y', 1);
    this.maxDate = this.calendar.getToday();
  }

  ngOnInit() {
    this.getInvestimentsProdutos();
    this.productSelect = '';
    this.investimentFormGroup = this.formBuilder.group({
      investimentDate: new FormControl('', [])
    });
    var InitDate = new Date();
    sessionStorage.setItem('dt_op', InitDate.toString())
    this.investimentFormGroup.patchValue({
      investimentDate: InitDate,
    });
    this.getInvestiments(InitDate)
    if (this.investiments && this.investiments.length > 0) {
      this.noData = false;
    } else {
      this.noData = true;
    }
  }

  getInvestimentsProdutos() {
    this.InvestimentsService.getInvestimentsProdutos().subscribe((data) => {
      this.produtos = data.data;
      if (this.produtos && this.produtos.length > 0) {
        this.noDataProdutos = false;
        this.sortList();
      }
      console.log(this.produtos);
    }, () => {
      this.produtos = [];
    });
  }

  getInvestiments(dt: Date, prod?: string) {
    sessionStorage.setItem('dt_op', dt.toString())
    this.loading = false;
    if (!prod) {
      this.InvestimentsService.getInvestiments(dt, null, false).subscribe((data) => {
        this.investiments = data.data;
        //PDF
        // sessionStorage.setItem('investiments_posicao_list_pdf', JSON.stringify(this.investiments));
        // sessionStorage.setItem('informations_pdf', '{"title":"Posição Consolidada", "name":"investiments-posicao", "report_name":"investimentos-posição", "filter":"investiments-posicao", "dt_ref":"' + dt.toString() + '"}')
        // console.log(this.investiments);
        if (this.investiments && this.investiments['itens'] && this.investiments['itens'].length > 0) {
          this.noData = false;
          this.loading = true;
          this.hasError = false;
          this.getTotal();
        } else {
          this.noData = true;
          this.loading = true;
          this.hasError = false;
        }
      }, () => {
        this.hasError = true;
        this.noData = true;
        this.loading = true;
        this.investiments = [];
      });


      //PDF
      this.InvestimentsService.getInvestimentsDetailsList(dt,prod).subscribe((investimentList) => {        
        Util.consoleDev('investimentList: ',investimentList)
        
        sessionStorage.setItem('investimentsPosicao', JSON.stringify(this.total));
        sessionStorage.setItem('investiments_posicao_list_pdf', JSON.stringify(investimentList));
        sessionStorage.setItem('informations_pdf', '{"title":"Posição Consolidada", "name":"investiments-posicao", "report_name":"investimentos-posição", "filter":"investiments-posicao", "dt_ref":"' + dt.toString() + '"}')
        // this.typToastColor = this.GREENCOLOR;
        // this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
      })



    } else {
      sessionStorage.setItem('dt_op', dt.toString())
      this.InvestimentsService.getInvestiments(dt, prod, false).subscribe((data) => {
        this.investiments = data.data;
        console.log(this.investiments);
        if (this.investiments && this.investiments['itens'] && this.investiments['itens'].length > 0) {
          this.noData = false;
          this.loading = true;
          this.getTotal();
        } else {
          this.noData = true;
          this.loading = true;
        }
      }, () => {
        this.hasError = true;
        this.noData = true;
        this.loading = true;
        this.investiments = [];
      });
    }
  }

  getInvestimentsDetails(dt: Date, num_op: number) {
    this.investimentsDetails = []
    this.loadingDetails = false;
    this.InvestimentsService.getInvestimentsDetails(num_op, dt).subscribe((data) => {
      this.investimentsDetails = data.data;
      console.log(this.investiments);
      this.loadingDetails = true;
    }, () => {
      this.loadingDetails = true;
    });
  }

  selectDate(obj: NgbDate | string) {
    this.investimentsDetails = []
    var today = new Date();
    this.dataInvalida = false;

    if (typeof(obj) === 'string') {
      let aux = obj.split("/");
      obj = new NgbDate(Number(aux[2]), Number(aux[1]), Number(aux[0]));
    }

    const jsDate = new Date(obj.year, obj.month - 1, obj.day);
    if (jsDate > today) {
      this.dataMaior = true;
    } else {
      this.dataMaior = false;
      this.investimentFormGroup.patchValue({
        investimentDate: jsDate,
      });
      this.getInvestiments(this.investimentFormGroup.get('investimentDate').value)
    }
  }

  dateInvalid(obj: string) {
    console.log('Data invalida');
    this.dataInvalida = true;
    this.hasError = false;
    this.noData = true;
    this.loading = true;
    this.investiments = [];
  }

  getTotal() {
    let valorAplicado: number = 0;
    let rendBruto: number = 0;
    let valorLiq: number = 0;
    if (this.investiments) {
      valorAplicado = this.investiments['tot_aplic'];
      rendBruto = this.investiments['tot_bruto']
      valorLiq = this.investiments['tot_liquido']
    }
    this.total = {
      valorAplicado,
      rendBruto,
      valorLiq
    };
  }

  filterProduct(product: any) {
    this.productSelect = product;
    if (this.productSelect == 'Todos') {
      this.productSelect = null;
      this.getInvestiments(this.investimentFormGroup.get('investimentDate').value)
    } else {
      sessionStorage.setItem('produtoInvestimentoExcel', this.productSelect)
      this.getInvestiments(this.investimentFormGroup.get('investimentDate').value, this.productSelect)
    }
  }

  //seta pra baixo quando clicado
  toogleIconArrow(index, num_op: number) {
    if (this.indexList !== index) {
      this.getInvestimentsDetails(this.investimentFormGroup.get('investimentDate').value, num_op);
      this.indexList = index;
      $('.collapse').removeClass('show');
      $(`.item-icon img`).removeClass('img-arrow');
    }else{
      this.getInvestimentsDetails(this.investimentFormGroup.get('investimentDate').value, num_op);
    }

    document.getElementById(index).classList.toggle('img-arrow');
    $('option').attr('selected', false);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('produtoInvestimentoExcel')
    sessionStorage.removeItem('dt_op')
    sessionStorage.removeItem('investiments_posicao_list_pdf')
    sessionStorage.removeItem('informations_pdf') 
  }

  sortList() {
    this.produtos.sort((a, b) => {
      if (a['name'] < b['name']) {
        return -1;
      }
      if (a['name'] > b['name']) {
        return 1;
      }
      return 0;
    });
    this.produtos.splice(0, 0, { name: "Todos", value: "Todos" });
  }
}
