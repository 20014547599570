<div class="fibra-banner-container" *ngIf="isPj()">
    <ngb-carousel #carousel class="fibra-banner">

        <ng-template ngbSlide>
            <img (click)="openModal(1)" style="cursor: pointer;" src="/assets/banner/banner-email.png" alt="" class="img-banner">
        </ng-template>

        <ng-template ngbSlide>
            <img src="/assets/banner/banner-dda.jpg" alt="" class="img-banner">
        </ng-template>

        <ng-template ngbSlide>
            <img (click)="openModal(2)" style="cursor: pointer;" src="/assets/banner/banner-cambio.png" alt="" class="img-banner">
        </ng-template>

        <div *fibraHasRole="'CAMP.EXI_BANNER'">
            <ng-template ngbSlide *ngIf="campaign && campaign.nome === 'CHEQUE EMPRESA PAUTA APROVADA'">
                <img (click)="openModal(1)" style="cursor: pointer;" src="/assets/banner/{{campaign.caminho_banner}}" alt="" class="img-banner">
            </ng-template>

            <ng-template ngbSlide *ngIf="campaign && campaign.nome !== 'CHEQUE EMPRESA PAUTA APROVADA'">
                <img (click)="goToExtract()" style="cursor: pointer;" src="/assets/banner/{{campaign.caminho_banner}}" alt="" class="img-banner">
            </ng-template>
        </div>

       <!-- <ng-template ngbSlide *ngIf="showBannerChequeEmpresaNaoContratadoMiddle">
            <img src="/assets/banner/CENARIO1 - MIDDLE - CHEQUE EMPRESA.jpg" alt="Cheque Empresa Não Contratado" class="img-banner">
        </ng-template>

        <ng-template ngbSlide *ngIf="showBannerChequeEmpresaNaoUtilizado">
            <img src="/assets/banner/CENARIO2-CHEQUE-EMPRESA.jpg" alt="Cheque Empresa Não Utilizado" class="img-banner">
        </ng-template>

        <ng-template ngbSlide *ngIf="showBannerFibraFacilNaoUtilizado">
            <img src="/assets/banner/CENARIO2-FIBRA-FACIL.jpg" alt="Fibra Fácil Não Utilizado" class="img-banner">
        </ng-template>

        <ng-template ngbSlide *ngIf="showBannerFibraFacil">
            <a rel="noopener noreferrer" target="_blank" style="cursor: pointer" (click)="redirecionaParaChequeEmpresa($event)">
                <img src="/assets/banner/Banner-IB-Fibra-Fácil.jpg" alt="Limite do Fibra Fácil aumentou" class="img-banner">
            </a>
        </ng-template>

        <ng-template ngbSlide *ngIf="showBannerChequeEmpresa">
            <a rel="noopener noreferrer" target="_blank" style="cursor: pointer" (click)="redirecionaParaChequeEmpresa($event)">
                <img src="/assets/banner/Banner-IB-Cheque-Empresa.jpg" alt="Limite do Cheque Empresa aumentou" class="img-banner">
            </a>
        </ng-template>

        <ng-template ngbSlide *ngIf="empresaPEBoletoOnline">
            <img src="/assets/banner/Banner - Empresas PE.png" alt="Agora você pode emitir seu boleto online (avulto) aqui no nosso Internet Banking." class="img-banner">
        </ng-template>

        <ng-template ngbSlide *ngIf="!empresaPEBoletoOnline">
            <img src="/assets/banner/Banner - Demais empresas.png" alt="Agora você pode emitir seu boleto online (avulto) aqui no nosso Internet Banking." class="img-banner">
        </ng-template>

        <ng-template ngbSlide>
            <img src="/assets/banner/Banner-Incentivo-Chat-IB.jpg" alt="Chat Banco Fibra. Atendimento humanizado, fácil e rápido para a sua empresa." class="img-banner">
        </ng-template>

        <ng-template ngbSlide *ngIf="fibraFacil">
            <img src="/assets/banner/Fibra-Facil-v3.jpg" alt="Fibra fácil" class="img-banner">
        </ng-template>
        
        <ng-template ngbSlide *ngIf="!fibraFacil">
            <img src="/assets/banner/Banner0002-Novo-IB-v1.jpg" alt="Gestão facilitada! Consulte saldos e extratos a qualquer momento." class="img-banner">
        </ng-template>
        
        <ng-template ngbSlide>
            <a rel="noopener noreferrer" href="https://click.relacionamento-bancofibra.com.br/cadastroderegra" target="_blank">
                <img src="/assets/banner/banner-0003-Novo-IB-v1.png" alt="Cadastro de regras" class="img-banner">
            </a>
        </ng-template>

        <ng-template ngbSlide *ngIf="showBannerCapitalGiroEmpresa">
            <a rel="noopener noreferrer" target="_blank">
                <img src="/assets/banner/Banner-IB-Web-FGI-PEAC-Empresas-v1.png" alt="Capital de Giro FGI PEAC" class="img-banner">
            </a>
        </ng-template>

        <ng-template ngbSlide *ngIf="showBannerCapitalGiroPE">
            <a rel="noopener noreferrer" target="_blank">
                <img src="/assets/banner/Banner-IB-Web-FGI-PEAC-PE-v1.png" alt="Capital de Giro FGI PEAC" class="img-banner">
            </a>
        </ng-template>-->

    </ngb-carousel>
</div>

<fibra-modal-banner-email *ngIf="showModalBannerEmail" (closeEvent)="closeModalPix($event)" [bannerType]="bannerType"></fibra-modal-banner-email>