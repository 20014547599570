<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="main-selector">
    <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" id="content-body body-content" id="body-scroll" *fibraHasRole="'
    APROV_TRANS.CONS_TRANS,
    APROV_TRANS.APROV_REPROV_TRANS'">
    <fibra-breadcrumb infoAccount="Transações" roule="Pagamento em lote" arrow="true" [downloadDoc]="true"
        (infoReport)="exportScreen($event)" [batchDetails]="batchDetails"></fibra-breadcrumb>

    <div class="body-content">
        <div class="tabs-list">
            <fibra-tabs-list [options]="options" (itemTab)="tabsSelect($event.idOption)"></fibra-tabs-list>
        </div>
        <div class="scheduledView" *ngIf="selectedTab === 'Tudo'">
            <img src="../../../../assets/png/icon-pending.png" class="scheduledIcon" />
            <span class="scheduledSpan">Exceto agendamentos</span>
        </div>
        <div class="filter-list">
            <fibra-filter [approval]="true" [approvalTransaction]="true" (emitFilter)="setFilter($event)"
                (emitFilterToast)="verifyCalendar($event)" [isSchedules]="isSchedules"></fibra-filter>
        </div>
        <div class="wraper-list-value">
            <header class="wraper-list-header">
                <div class="check-all">
                    <input *ngIf="showCheckbox" type="checkbox" class="checkbox" (change)="checked('all')"
                        [checked]="shownAll === true" />
                </div>
                <div class="items">
                    <div class="col-date">
                        <span>Data pagamento / transferência</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('dt_transacao', data)" />
                    </div>
                    <div class="col-transaction">
                        <span>Tipo de transação</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('tipo_transacao', data)" />
                    </div>
                    <div class="col-account">
                        <span>Conta</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('num_conta', data)" />
                    </div>
                    <div class="col-cnpj">
                        <span>CNPJ / CPF</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('num_cpf_cnpj', data)" />
                    </div>
                    <div class="col-value">
                        <span>Valor</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('vlr_transacao', data)" />
                    </div>
                    <div class="col-status">
                        <span>Status</span>
                        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('desc_status', data)" />
                    </div>
                    <div class="col-action">
                        <span>Ações</span>
                    </div>
                </div>
            </header>
            <div class="list-value">
                <div class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)" *ngIf="loading || filterComp?.loading">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="empty-info" *ngIf="hide">
                    <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
                        message="Tente uma nova pesquisa inserindo novos parâmetros.">
                    </fibra-generic-info>
                </div>

                <div *ngIf="!loading">
                    <div class="panel-group" id="accordion">
                        <div class="list-content" *ngFor="let item of data; index as i"
                            [ngClass]="{'azul': verificaSelected(i)}">
                            <div class="panel-list">
                                <div class="check-all">
                                    <div class="checkbox" *ngIf=" item.desc_status === 'PENDENTE' && !emProcessamento(item.aprovadores) && isMaster(item.aprovadores)">
                                        <input type="checkbox" class="checkbox" [id]="i" (change)="checked(i, item)" />
                                    </div>
                                </div>
                                <div class="col-date">
                                    <span>
                                        {{ item?.dt_transacao | date: 'dd/MM/yyyy' }}
                                    </span>
                                </div>
                                <div class="col-transaction">
                                    <span class="title-desc">{{
                                        item?.tipo_transacao === 'PAGAMENTO'
                                        ? 'Pagamento de boleto'
                                        : item?.tipo_transacao
                                        }}</span>
                                    <span class="text-desc">{{
                                        item?.info_transacao?.nome_favorecido | titlecase
                                        }}</span>
                                </div>
                                <div class="col-account">
                                    <span>
                                        {{ item?.num_conta }}
                                    </span>
                                </div>
                                <div class="col-cnpj">
                                    <span>
                                        {{ item?.tipo_transacao !== 'Pagamento em Lote' ? item.num_cpf_cnpj : '-' }}
                                    </span>
                                </div>
                                <div class="col-value">
                                    <span>
                                        {{ item?.vlr_transacao | currency: 'BRL' }}
                                    </span>
                                </div>

                                <!-- ToolTip -->
                                <div class="col-status">
                                    <div class="item-status util"
                                        *ngIf="item.tipo_transacao !== 'Pagamento em Lote' else isBatchPayment ">
                                        <div *ngIf="item.desc_status === 'VENCIDO'">
                                            <span class="icon-info notification" [appTooltip]="tooltipAll">
                                                <img src="/assets/svg/tooltip-alert-orange.svg" alt="logo" />
                                                <div #tooltipAll [ngClass]="
                                                i <= 2
                                                  ? 'tooltip-approval-down'
                                                  : 'tooltip-approval'
                                              ">
                                                    <p>{{item.desc_status}}</p>
                                                    <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                                                </div>
                                            </span>
                                        </div>
                                        <div *ngIf="item.desc_status === 'PENDENTE BANCO FIBRA'">
                                            <span class="icon-info notification" [appTooltip]="tooltipAll">
                                                <img src="/assets/svg/tooltip-alert-orange.svg" alt="logo" />
                                                <div #tooltipAll [ngClass]="
                                                            i <= 2
                                                            ? 'tooltip-approval-down'
                                                            : 'tooltip-approval'
                                                        ">
                                                    <p>Aguardando confirmação Banco Fibra</p>
                                                    <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                                                </div>
                                            </span>
                                        </div>

                                        <div *ngIf="item?.desc_status === 'AGENDADO'">
                                            <span class="icon-info notification" [appTooltip]="tooltipAll">
                                                <div class="scheduled-icon"></div>
                                                <div #tooltipAll [ngClass]=" i <= 2 ? 'tooltip-custom-down' : 'tooltip-custom'">
                                                    <h3>Pagamento agendado</h3>
                                                    <p>
                                                        O pagamento será realizado na data agendada,
                                                        mediante disponibilidade de saldo em conta.
                                                    </p>
                                                    <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                                                </div>
                                            </span>
                                        </div>

                                        <div *ngIf="item?.desc_status === 'LIQUIDADO'">
                                            <span class="icon-info notification app-tooltip">
                                                <div class="authorized-icon"></div>
                                            </span>
                                        </div>
                                        <!-- <div *ngIf="(item.desc_status === 'PENDENTE' &&  isPedding(item.aprovadores) === true)">
                                        <span class="icon-info notification app-tooltip">
                                            <div class="pedding-icon"></div>
                                        </span>
                                        </div> -->

                                        <div *ngIf="(item.desc_status === 'PENDENTE')">
                                            <span class="icon-info notification" [appTooltip]="tooltipAll">
                                                <img width="10" height="10" src="/assets/svg/tooltip-alert-orange.svg"
                                                    alt="logo" />
                                                <div #tooltipAll [ngClass]="{
                                                    'tooltip-custom-down': i <= 2, 
                                                    'tooltip-custom': i > 2, 
                                                    'tooltip-align': emProcessamento(item.aprovadores)
                                                }">
                                                    <div *ngIf="!emProcessamento(item.aprovadores)">
                                                        <h3>Aguardando aprovação</h3>
                                                        <div class="info">
                                                            <ul class="info-list">
                                                                <li class="header"
                                                                    *ngIf="showApprovalMaster(item.aprovadores)">
                                                                    <img src="../../../../assets/img/icon-dot.svg" alt="" />
                                                                    Masters
                                                                </li>
                                                                <li class="item-mt" *ngFor="let master of item.aprovadores">
                                                                    <div *ngIf="master.tipo_acesso === 'Master'">
                                                                        <span
                                                                            *ngIf="master.desc_status_aprovador.indexOf('Aguardando Aprova') !== -1">{{
                                                                            master.nome_aprovador }}</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <ul *ngIf="showApprovalOperator(item.aprovadores)"
                                                                class="info-list">
                                                                <li class="header">
                                                                    <img src="../../../../assets/img/icon-dot.svg" alt="" />
                                                                    Operador
                                                                </li>
                                                                <li class="item-op"
                                                                    *ngFor="let operator of item.aprovadores">
                                                                    <div *ngIf="operator.tipo_acesso === 'Operador'">
                                                                        {{ operator.nome_aprovador }}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="emProcessamento(item.aprovadores)">
                                                        <h3>Em processamento</h3>
                                                    </div>
                                                    <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                                                </div>
                                            </span>
                                        </div>

                                        <div class="text" *ngIf="item.desc_status == 'DEVOLVIDA'">
                                            <span class="icon-info notification">
                                              <img width="10" height="10" src="/assets/png/alert.png" alt="logo" />
                                            </span>
                                          </div>
                                          
                                        <div *ngIf="item.desc_status === 'RECUSADO'">
                                            <span class="icon-info notification" [appTooltip]="tooltipAll">
                                                <img width="10" height="10" src="/assets/png/alert.png" alt="logo" />
                                                <div #tooltipAll [ngClass]="
                            i <= 2 ? 'tooltip-refused-down' : 'tooltip-refused'
                          ">
                                                    <h3>Recusado por:</h3>
                                                    <div class="info">
                                                        <ul class="info-list">
                                                            <li class="header"
                                                                *ngIf="showApprovalMaster(item.aprovadores)">
                                                                <img src="../../../../assets/img/icon-dot.svg" alt="" />
                                                                Masters
                                                            </li>
                                                            <li class="item" *ngFor="let master of item.aprovadores">
                                                                <div *ngIf="master.tipo_acesso === 'Master'">
                                                                    <span
                                                                        *ngIf="master.desc_status_aprovador === 'Recusado'">{{
                                                                        master.nome_aprovador
                                                                        }}</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul class="info-list">
                                                            <li class="header" *ngIf="checkOperator(item.aprovadores)">
                                                                <img src="../../../../assets/img/icon-dot.svg" alt="" />
                                                                Operador
                                                            </li>
                                                            <li class="item" *ngFor="let master of item.aprovadores">
                                                                <div *ngIf="master.tipo_acesso === 'Operador'">
                                                                    {{ master.nome_aprovador }}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="text" *ngIf="item.desc_status === 'PENDENTE BANCO FIBRA'">
                                            Pendente
                                        </div>
                                        <div *ngIf="item?.desc_status === 'AGUARDANDO SALDO DISPONÍVEL'">
                                            <span class="icon-info notification" [appTooltip]="tooltipAll">
                                                <img width="10" height="10" src="/assets/svg/tooltip-alert-orange.svg"
                                                    alt="logo" />
                                                <div #tooltipAll [ngClass]="
                            'tooltip-custom-down-balace'
                          ">
                                                    <h3>
                                                        Aguardando saldo disponível
                                                    </h3>
                                                    <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                                                </div>
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="item.desc_status === 'CANCELADO SALDO INSUFICIENTE' || item.desc_status === 'CANCELADO BANCO FIBRA'">
                                            <span class="icon-info notification" [appTooltip]="tooltipAll">
                                                <img width="10" height="10" src="/assets/png/alert.png" alt="logo" />
                                                <div #tooltipAll [ngClass]="'tooltip-custom-down-balace'">
                                                    <h3 [ngClass]="'rec'">
                                                        {{
                                                        item.desc_status === 'CANCELADO SALDO INSUFICIENTE'
                                                        ? (item.desc_status | titlecase)
                                                        : 'Não autorizado pelo Banco Fibra'
                                                        }}
                                                    </h3>
                                                    <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow-top'"></div>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="text"
                                            *ngIf="item.desc_status !== 'PENDENTE BANCO FIBRA' && item.desc_status !== 'AGUARDANDO SALDO DISPONÍVEL'">
                                            {{ item.desc_status === 'REPROVADO' || item.desc_status === 'CANCELADO SALDO
                                            INSUFICIENTE' || item.desc_status === 'CANCELADO BANCO FIBRA' ? 'Recusado' :
                                            (item.desc_status | titlecase) }}
                                        </div>
                                        <div class="text" *ngIf="item.desc_status == 'AGUARDANDO SALDO DISPONÍVEL'">
                                            Pendente
                                        </div>
                                    </div>

                                    <ng-template #isBatchPayment>-</ng-template>

                                </div>
                                <div class="">
                                    <div class="item-action">
                                        <div class="item-download" *ngIf="
                                            item.desc_status === 'LIQUIDADO' ||
                                            item.desc_status === 'AGENDADO'
                                            ">
                                            <img src="/assets/svg/download-accent-dark.svg" alt="download-accent"
                                                (click)="sendReceipt(item)" />
                                            </div>
                                            <div class="item-refused" [ngStyle]="{
                                                cursor:
                                                showConfirmation || this.checkedArrayIsNotEmpty
                                                    ? 'unset'
                                                    : 'pointer'
                                                }" *ngIf="!emProcessamento(item.aprovadores) &&
                                                    item.desc_status === 'PENDENTE' ||
                                                    (item.desc_status === 'VENCIDO' && item.tipo_transacao == 'PAGAMENTO')
                                                ">
                                                <span
                                                    *ngIf="(isMaster(item.aprovadores) === true) && item.tipo_transacao !== 'Pagamento em Lote'">
                                                    <img src="/assets/svg/close.svg" alt="download-accent" (click)="
                                                        !this.checkedArrayIsNotEmpty
                                                            ? approveOrRefuse('recusar', i, item)
                                                            : cancelConfirmation()
                                                        " />
                                            </span>
                                        </div>
                                        <div class="item-approval" [ngStyle]="{
                        cursor:
                          showConfirmation || this.checkedArrayIsNotEmpty
                            ? 'unset'
                            : 'pointer'
                      }" *ngIf="!emProcessamento(item.aprovadores) &&
                        item.desc_status === 'PENDENTE' ||
                        (item.desc_status === 'VENCIDO' && item.tipo_transacao == 'PAGAMENTO')
                      ">
                                            <span *ngIf="(isPedding(item.aprovadores) === true)">
                                                <img src="/assets/svg/approval.svg" alt="Approval Icon"
                                                    (click)="approveOrRefuse('aprovar', i, item)" />
                                            </span>

                                        </div>
                                        <div class="more-detail"></div>
                                        <span class="item-icon"
                                            *ngIf="item.tipo_transacao !== 'Pagamento em Lote' else batchPaymentArrow"
                                            (click)="toogleDetail(i, item.cod_transacao)" data-toggle="collapse"
                                            data-parent="#accordion" [attr.href]="'#collapse' + i">
                                            <img id="{{ i }}" src="/assets/svg/dropdown.svg" class=""
                                                alt="Seta para dropdown" />
                                        </span>
                                        <ng-template #batchPaymentArrow>
                                            <span class="item-icon">
                                                <img id="{{ i }}" src="/assets/svg/dropdown.svg" class="right-arrow"
                                                    alt="Seta para dropdown" />
                                            </span>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div [id]="'collapse' + i" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <fibra-transfer-approval-detail [index]="i" [data]="dataDetail"
                                        [loading]="loadingDetail">
                                    </fibra-transfer-approval-detail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-options" *ngIf="
          isExpiredTicketSelected ||
          checkedArrayIsNotEmpty ||
          showConfirmation ||
          checkedArrayIsExpiredTicket ||
          showConfirmationRadio ||
          isPoolPenddingselected ||
          showRefusedOrApproveAction ||
          oneOrMorePendingExpiredChecked ||
          showIndividualRecusa ||
          showIndividualConfirmation
        ">
                <div class="info" *ngIf="isExpiredTicketSelected && !showIndividualRecusa">
                    <div class="d-flex justify-content-center align-items-center" *ngIf="loadingEncargos">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <!-- <div *ngIf="loadingEncargos">
            carregando...
          </div> -->
                    <div class="expired-ticket" *ngIf="!loadingEncargos">
                        <h2 class="warn-info">Este boleto está vencido</h2>
                        <p class="desc-info">
                            Serão aplicados os seguintes encargos: <br />
                            <span>multa</span> {{vencidoMultas | currency: 'BRL' }} + <span>juros:</span> {{vencidoJuros
                            | currency: 'BRL' }} +
                            <span>mora:</span> {{vencidoMora | currency: 'BRL' }} = <span>{{totalEncargos | currency:
                                'BRL' }}</span>
                            <!-- = <span>R$ 0.000.000,00</span>  -->
                        </p>
                    </div>
                </div>
                <div class="info" *ngIf="isPoolPenddingselected">
                    <div class="expired-ticket" *ngIf="oneOrMorePendingExpiredChecked">
                        <h2 class="warn-info">Existem boletos vencidos selecionados</h2>
                        <p class="desc-info">Serão aplicados encargos.</p>
                    </div>
                    <div class="ticket-info">
                        <div class="tickets">
                            <img src="assets/svg/consultas_icon.svg" alt="Comprovante" />
                            <a href="javascript:;">{{ checkedList.length }}
                                {{ checkedList.length > 1 ? 'registros' : 'registro' }}</a>
                        </div>
                        <div class="value-info">
                            <p class="title">Valor total</p>
                            <span class="price">{{ total | currency: 'BRL' }}</span>
                        </div>
                    </div>

                </div>

                <div class="actions" *ngIf="showConfirmationRadio">
                    <div class="action-radios">
                        <div class="custom-control custom-radio custom-control-inline"
                            *ngIf="handleTypeConfirm() === true;else other_content">
                            <fibra-radiobutton [option]="[
                      { option: 'Recusar', value: 10 }
                    ]" (selected)="changeTypeApprove($event)"></fibra-radiobutton>
                        </div>
                        <ng-template #other_content>
                            <div class="custom-control custom-radio custom-control-inline">
                                <fibra-radiobutton [option]="[
                          { option: 'Aprovar', value: 9 },
                          { option: 'Recusar', value: 10 }
                        ]" (selected)="changeTypeApprove($event)"></fibra-radiobutton>
                            </div>
                        </ng-template>
                    </div>
                    <div class="buttons">
                        <button class="confirm" [disabled]="!actionAproveOrRefuse" (click)="confirmAction()">
                            Confirmar
                        </button>
                        <div class="cancel" (click)="cancelConfirmation()">
                            Remover seleções
                        </div>
                    </div>
                </div>
                <div class="actionsAprovarRecusar" *ngIf="showIndividualConfirmation">
                    Deseja aprovar esta transação?
                    <div class="buttons">
                        <div class="cancel" (click)="cancelConfirmation()">
                            Cancelar
                        </div>
                        <button class="confirm" (click)="aprovarAction()">
                            Aprovar
                        </button>
                    </div>
                </div>
                <div class="actionsAprovarRecusar" *ngIf="showIndividualRecusa">
                    Deseja recusar esta transação?
                    <div class="buttons">
                        <div class="cancel" (click)="cancelConfirmation()">
                            Cancelar
                        </div>
                        <button class="confirm" (click)="recusarAction()">
                            Recusar
                        </button>
                    </div>
                </div>

                <!-- <div class="single-action" *ngIf="showRefusedOrApproveAction">
          <div class="text">
            Deseja {{ this.action }}
            {{
            checkedList.length > 1 ? 'estas transações' : 'esta transação'
            }}?
          </div>
          <div class="buttons">
            <div class="cancel" (click)="cancelConfirmation()">Cancelar</div>
            <div class="approve" (click)="confirmAction()">
              {{ this.action || 'Aprovar' }}
            </div>
          </div>
        </div> -->
            </div>
        </div>
        <fibra-modal-validation *ngIf="actionConfirmed" [apiCallback]="channelEnum" (closeEvent)="closeModal()"
            [apiPayload]="securityRequest()" (responseValidation)="securityResponse($event)">
        </fibra-modal-validation>
    </div>
</div>