import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { environment } from '@src/environments/environment';
import { PendencyEntity } from '@src/data/repository/dashboard/pendency/pendency.entity';
import { PendencyModel, OpenBankingPendency, PendencyDda } from '@src/core/domain/dashboard/dashboard.model';
import { PendencyRepositoryMapper } from '@src/data/repository/dashboard/pendency/pendency-repository-mapper';
import { PendencyRepository } from '@src/core/repositories/dashboard/pendency.repository';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';

// environment

@Injectable({
  providedIn: 'root',
})
export class PendencyDataRepository extends PendencyRepository {
  mapper = new PendencyRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getPendency(): Observable<PendencyModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    let params;

    return this.http
      .post<PendencyEntity>(
        `${environment.URLBase}v1/dashboard/pendencias`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getOpenBankingPendencies(): Observable<OpenBankingPendency[]> {
    return this.http.get<{code: number, status: string, data?: any }>(`${environment.URLBase}v1/dashboard/pendenciasopenbanking`, { headers: getHeader() })
      .pipe(catchError(handleError))
      .pipe(map(result => {
        if (result.status == 'success') {
          return <OpenBankingPendency[]>result.data;
        } else {
          return [];
        }
      }));
  }

  getPendencyPix(): Observable<PendencyModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    let params;

    return this.http
      .get<any>(
        `${environment.URLBase}v1/pix/notificacaopendencia/listar`,
        httpOptions
      )

  }

  getTemFibraFacil(): Observable<boolean> {
    return this.http.get<{ code: number; status: string; data: { temFibraFacil: boolean }}>(`${environment.URLBase}v1/dashboard/fibrafacil`, { headers: getHeader() })
      .pipe(map(result => {
        if (result.status == 'success') {
          return result.data.temFibraFacil
        } else {
          return false;
        }
      }))
  }

  getPendencyDda(params): Observable<PendencyDda> {
    return this.http.post<any>(`${environment.URLBase}v1/dashboard/pendenciasdda`, params, { headers: getHeader() })
    .pipe(map(result => {
      if (result.status == 'success') {
        return result;
      } else {
        return false;
      }
    }))
  }
}
