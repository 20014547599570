import { BalanceDataShare } from '@src/core/usecases/data-share/balance-data-share.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { NewTokenRequest, TokenTypeModel } from '@src/core/domain/token/tokenType.model';
import { GetNewTokenUsecase } from '@src/core/usecases/token/get-new-token-usecases';
import { toTitleCase } from 'codelyzer/util/utils';

@Component({
  selector: 'fibra-balance-datail',
  templateUrl: './balance-datail.component.html',
  styleUrls: ['./balance-datail.component.scss'],
})
export class BalanceDatailComponent implements OnInit {
  @Input() balanceData;
  @Input() indice;
  @Input() showData;
  @Input() exibicao;
  token;
  public toTitleCase = toTitleCase;

  constructor(
    private getNewToken: GetNewTokenUsecase,
    private router: Router,
    private shareBalance: BalanceDataShare,
  ) {}

  ngOnInit() {}

  sendIndiceExtract(indice: number) {
    const params: NewTokenRequest = {
      indice,
    };
    this.getNewToken.execute(params).subscribe((response: TokenTypeModel) => {
      sessionStorage.setItem('x-ibpj-session', response.data.sessionToken);
      this.router.navigate(['/extract']);
    });
  }

  sendBalance(balance) {
    this.shareBalance.setValue({ ...balance, exibicao: this.exibicao });
  }
}
