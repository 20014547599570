import { ShowValuesDashboardDataShare } from '@src/core/usecases/data-share/show-values-dashboard-data-share.service';
import { Component, OnInit, AfterViewInit, Input, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import * as $ from 'jquery';
import { RemoveClassDataShare } from '@src/core/usecases/data-share/remove-class-data-share.service';
import { ToastErrorMessageComponent } from '../toast-error-message/toast-error-message.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { GetNotificationUseCases } from '@src/core/usecases/notification/get-notification.usecase';
import { PutNotificationPixUseCases } from '@src/core/usecases/notification/put-notification.usecase';
import { DeleteNotificationUseCases } from '@src/core/usecases/notification/delete-notification.usecase';
import { Subscription } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { PostNotificationUseCases } from '@src/core/usecases/notification/post-notification.usecase';
import { RolesService } from '@src/shared/services/roles.service';

@Component({
  selector: 'fibra-main-bank',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)', opacity: 0 }),
            animate('400ms', style({ transform: 'translateX(0)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [

            style({ transform: 'translateX(0)', opacity: 1 }),
            animate('400ms', style({ transform: 'translateX(100%)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
  
})

export class MainComponent implements OnInit, AfterViewInit {

  @ViewChild(ToastErrorMessageComponent, {static: false}) toast!: ToastErrorMessageComponent;
  private _viewdropdown: ElementRef = null;
  @ViewChild('circleAlert', { static: false }) circleAlert: HTMLElement;
  @ViewChild('viewdropdown', null) set viewdropdown(value: ElementRef) {
    if(value) {
      this._viewdropdown = value;

      this._viewdropdown.nativeElement.addEventListener("scroll", () => {
        if(!this.loadingMore && !this._eof && (this._viewdropdown.nativeElement.scrollTop + this._viewdropdown.nativeElement.clientHeight + 20 >= this._viewdropdown.nativeElement.scrollHeight)) {
          //this.loadMore()
        }
      });
    }
  }

  public name: string;
  public avatar: string;
  @Input() hideMenu?: boolean;
  @Input() hideMain?: boolean;
  @Input() hideProfile;
  @Input() hideCompany;
  public iconEyesOpen = true;
  public toogleShowIconEyes: boolean;
  public loading: boolean;
  public divisor;
  public showCtrlNtfy: boolean = false;
  public colorToast = '#6CBED9';
  public control = false;
  currentState: boolean;

  loadingMore: boolean = false;
  private _eof: boolean = false;
  public listArray: any[] = [];
  isReadFalse: boolean = false;
  content = '';
  public varSubscriptGetNotification: Subscription;
  deviceTypeAndToken = {
    sistema: 1,
    token: '',
    permite_push: false
  };
  public foundError: boolean = false;
  

  constructor(
    private router: Router,
    private authService: MsalService,
    private sharedShowValues: ShowValuesDashboardDataShare,
    private shareRemoveClass: RemoveClassDataShare,
    private getNotificationUseCases: GetNotificationUseCases,
    private putNotificationUseCases: PutNotificationPixUseCases,
    private deleteNotificationUseCases: DeleteNotificationUseCases,
    private cdr: ChangeDetectorRef,
    private angularFireMessaging: AngularFireMessaging,
    private saveTokenNotification: PostNotificationUseCases
  ) {
    window.addEventListener('ACCEPT_NOTIFY', () => {
      this.angularFireMessaging.requestToken.subscribe((token) => {
        this.deviceTypeAndToken.permite_push = true;
        this.deviceTypeAndToken.token = token;
        this.saveNotificationTokenInBack();
      },
        this.returnRequestError
      );  
    });
    this.showValues();
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
      if (event instanceof NavigationStart ) {
        this.toogleShowIconEyes = (event.url.indexOf('extract') !== -1 || event.url.indexOf('dashboard') !== -1)
      }
    });
    this.currentState = false;
  }

  ngOnInit() {
    this.loading = true;
    this.hideCompany = false;
    this.hideProfile = true;
    setTimeout(() => this.userName(), 3000);
    this.showValues();
    if(this.getBrowserName() !== 'safari' && this.getBrowserName() !== 'opera') {
      this.requestTokenFCM();
      this.listenNotificationFCM();
    }
  }

  clickBell(evt){
    this.currentState = !this.currentState;
  }
  

  ngAfterViewInit() {
    setTimeout(() => {
      this.userName();
      this.persistenceGetToken();
    }, 3000);
  }


  showValues() {
    this.iconEyesOpen = !this.iconEyesOpen;
    this.sharedShowValues.setValue({
      iconEyesOpen: this.iconEyesOpen
    });
  }

  userName = () => {
    this.name = localStorage.getItem('username');
    if (this.name) {
      this.name = this.name.split(' ')[0];
    }
    this.avatar = this.name && this.name.charAt(0).toUpperCase();
    this.loading = false;
  }
  

  logout() {
    sessionStorage.clear();
    this.authService.logout();
    localStorage.clear();
  }

  navMatrix() {
    this.router.navigate(['/approval-matrix']);
    this.shareRemoveClass.setValue(true);
  }

  navAdmin() {
    this.router.navigate(['/admin-user']);
  }

  openModal() {
    $(document).ready(() => {
      $('#open').click();
    });
    $(document).ready(() => {
      $('#open').click();
    });
  }

  callNotifyEvent(evt, i){
    this.showCtrlNtfy = evt;
  }

  markRead(id, mes){
    this.listArray.find((res)=> {
      if(typeof res.mes !== 'undefined' && res.mes === mes) {
        res.data.find((es) => {
          es.id === id ? es.visualizado = true : '';
        });
      }
    });
    this.checkNotificationWithRead(id);
  }

  removeNotify(ind, id, el: HTMLElement, mes, indexMes){
    el.classList.add('animate__animated');
    el.classList.add('animate__backOutLeft');

    setTimeout(() => {
      this.listArray.find((res) => {
        if(typeof res.mes !== 'undefined' && res.mes === mes) {
          res.data.splice(ind, 1);
        }

        if(res.data.length === 0) {
          this.listArray.splice(indexMes, 1);
        }
      });

      this.deleteNotification(id);
    }, 500);
  }


  listenNotificationFCM() {
    this.angularFireMessaging.messages.subscribe((res: any) => {
      this.isCompanySameNotificationFCM(res.data.id_empresa);
    },
      error => console.log(error)
    )
  }

  callNotification() {
    !this.getTokenNotification() && !this.getTokenNotification().token && Notification.permission === 'granted' ? window.dispatchEvent(new Event('ACCEPT_NOTIFY')) : '';
    this.loadingMore = true;
    this.foundError = false;
    this.listArray = [];
    this.requestNotification();
  }

  requestNotification () {
    const values = {
      token_id: this.getTokenNotification().token_id,
      companyId: this.getCompanyId()
    };
    this.unsubscribeVariable(this.varSubscriptGetNotification);
    this.varSubscriptGetNotification = this.getNotificationUseCases.execute(values)
      .subscribe(
        this.requestSuccess,
        this.requestNotificationError
      );
  }

  checkNotificationWithRead(id: number) {
    const param = {
      id_notificacao: id,
      id_token: this.getTokenNotification().token_id
    }
    this.putNotificationUseCases.execute(param).subscribe(
      success => this.isRead(),
      this.requestError
    );
  }

  deleteNotification(id: number) {
    const param = {
      id_notificacao: id,
      id_token: this.getTokenNotification().token_id
    }
    this.deleteNotificationUseCases.execute(param).subscribe(
      this.deleteSuccess,
      this.requestError
    );
  }

  deleteSuccess = () => {
    this.isRead()
    this.toast.callModalMessage(false, 'Notificação excluída', null, null, null);
  }

  requestSuccess = (value) => {
    this.listArray = value.data.sort((a:any, b:any) => b.mes - a.mes);
    this.isRead();
    this.loadingMore = false;
    this.foundError = false;
  }

  requestNotificationError = (error) => {
    error.status === 404 ? this.foundError = false : this.foundError = true;
    this.loadingMore = false;
  }

  requestError = (error) => {
    this.loadingMore = false
  }

  isRead() {
    this.isReadFalse = false;
    this.listArray.find((res) => {
      res.data.find(e => {
        if(e.visualizado === false) {
          this.isReadFalse = true;
          return;
        }
      });
    });
  }

  unsubscribeVariable(variable) {
    if(variable) {
      variable.unsubscribe();
    }
    return;
  }

  loadMore() {
    this.loadingMore = true;
    this.cdr.detectChanges();
    this._viewdropdown.nativeElement.scrollTop = this._viewdropdown.nativeElement.scrollHeight;
    //console.log('scroll funcionando')
  }
  
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  requestTokenFCM() {
    this.angularFireMessaging.requestToken.subscribe(
      this.userAcceptOrDeniedNotification,
      this.returnRequestError
    );
  }

  userAcceptOrDeniedNotification = (token) => {
   if(token !== null || Notification.permission === 'granted') {
      this.deviceTypeAndToken.permite_push = true;
      this.deviceTypeAndToken.token = token;
    }
  }

  saveNotificationTokenInBack() {
    this.saveTokenNotification.execute(this.deviceTypeAndToken)
      .subscribe((res) => {
        this.setTokenNotificationInSession(res)
      },
      this.returnError
    );
  }

  returnRequestError = (error) => {
    this.loadingMore = false
    this.foundError = true;
  }

  returnError = (error) => {
    this.loadingMore = false
    this.foundError = true;
  }

  setTokenNotificationInSession(value) {
    const dataToken = {
      token_id: value.data.token_id,
      token: value.data.token
    }
    sessionStorage.setItem('tokenNotification', JSON.stringify(dataToken));
    this.foundError = false;
  }

  getTokenNotification() {
    const token = JSON.parse(sessionStorage.getItem('tokenNotification'));
    return token;
  }

  getCompanyId() {
    const companyId = JSON.parse(sessionStorage.getItem('defaultCompany'));
    return companyId[0].value;
  }
  
  persistenceGetToken() {
    this.responseModalAcceptNotification();
    let toTry = 1;
    let stop = null;
    stop = setInterval(() => {
      if(this.getTokenNotification()) {
        clearInterval(stop);
        this.requestNotification(); 
      }
  
      if(toTry === 10 && !this.getTokenNotification()) {
        clearInterval(stop);
        this.foundError = true; 
      }
      this.saveNotificationTokenInBack();
      toTry += 1;
    },2000);
  }

  responseModalAcceptNotification() {
    Notification.requestPermission().then(function(getperm) 
    { 
      if(Notification.permission === 'granted') {
        window.dispatchEvent(new Event('ACCEPT_NOTIFY'));
      }
    });
  } 

  isCompanySameNotificationFCM(value) {
    const companyId = this.getCompanyId()
    if(companyId === JSON.parse(value)) {
      this.isReadFalse = true;
    }
  }
} 
