import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { PredictedRequest } from '@src/core/domain/predicted/predicted.model';
import { GetPredictedUsecase } from '@src/core/usecases/predicted/get-predicted-movements.usecase';
import { GetPredictedTotalizadoresUsecase } from '@src/core/usecases/predicted/get-predicted-movements-totalizadores.usecase';
import { ProfileAccessProvider } from '@src/data/repository/perfilamento/profile.repository';
import { FunctionalitiesId, PermissionId } from '@src/data/repository/perfilamento/profile.entity';
import { consoleDev } from '@src/shared/util-common';

@Component({
  selector: 'fibra-side-card',
  templateUrl: './side-card.component.html',
  styleUrls: ['./side-card.component.scss'],
})
export class SideCardComponent implements OnInit {
  public predicted: any;
  public periodDate: number;
  public dataInicio: string;
  public dataFim: string;
  public dateEnd: string;
  public loading: boolean;
  public allow: boolean;
  @Input() options;
  @Input() isCobranca: boolean;
  @Input() hide = false;
  @Input() valuesPeriod;
  @Input() isCarteira: boolean;

  constructor(
    private getPredictedUsecase: GetPredictedUsecase,
    private getPredictedTotalizadoresUsecase: GetPredictedTotalizadoresUsecase,
    private accessProvider: ProfileAccessProvider
  ) { }

  ngOnInit(): void {
    this.executeTotalizadores()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.executeTotalizadores()
  }

  executeTotalizadores() {
    this.formatDate(0);
    this.loading = false;
    const params: PredictedRequest = {
      dataInicio: this.dataInicio,
      dataFinal: this.dataFim,
    };

    this.loading = true;

    if (this.isCobranca && !this.isCarteira) {
      this.getTotalizadores()
    } else if (this.isCobranca && this.isCarteira) {
      this.predicted = this.valuesPeriod
      this.loading = false;
    } else {
      this.getPredictedUsecase
        .execute(params)
        .subscribe(this.requestSuccess, this.requestError);
    }
  }

  getTotalizadores() {
    this.getPredictedTotalizadoresUsecase
      .execute()
      .subscribe(this.requestSuccessTotalizadores, this.requestErrorTotalizadores)
  }

  private requestSuccessTotalizadores = (value) => {
    this.predicted = {
      expected_entries: value.data.totalAprovados,
      expected_exits: value.data.totalRecusados,
      expected_real_balance: value.data.totalEmAnalise
    };
    this.loading = false;

  }

  private requestErrorTotalizadores = (error) => {
    this.predicted = {
      expected_entries: 0,
      expected_exits: 0,
      expected_real_balance: 0
    };
    this.loading = false;
  }

  private requestSuccess = (value) => {
    this.predicted = value.data;
    this.loading = false;
  }

  private requestError = (error) => {
    this.predicted = {
      expected_exits: 0,
      expected_entries: 0,
      expected_balance: 0,
      expected_real_balance: 0
    };
    this.loading = false;
  }

  public formatDate(days: number): void {
    const date = new Date();
    this.dataInicio = date.toISOString().slice(0, 10);
    const auxDate = new Date(date);
    this.periodDate = auxDate.setDate(date.getDate() - days);
    const endDate = new Date(this.periodDate);
    this.dataFim = endDate.toISOString().slice(0, 10);
  }

  public isEmpty = (value) => {
    return value === undefined || value === 0 || value === null;
  }

  public getAccess = async () => {
    this.allow = await this.accessProvider.getPermissionAllowed(FunctionalitiesId.Extract, PermissionId.Bill_Payment);
    console.log(this.allow);
  }

}


