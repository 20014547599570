<div class="benefit-content">
  <div class="list-value">
    <!-- Lista de Beneficiários vazio -->
    <div class="empty-info" *ngIf="(benefVazio || benefErro || buscaBeneficiario) && !loading">
      <fibra-generic-info
        type="warn"
        title="Nenhum beneficiário salvo"
        message="Você ainda não possui um beneficiário salvo em sua lista.
        Toque no botão Novo beneficiário para fazer uma transferência Pix."
        *ngIf="benefVazio"
      >
      </fibra-generic-info>
      <fibra-generic-info
        type="error"
        title="Ocorreu um erro"
        message="Não foi possível encontrar seus beneficiários favoritos. Tente novamente mais tarde."
        *ngIf="benefErro"
      >
      </fibra-generic-info>
      <fibra-generic-info
        type="warn"
        title="Dados inexistentes."
        *ngIf="buscaBeneficiario"
      >
      </fibra-generic-info>
    </div>

    <!-- Loading -->
    <div class="d-flex justify-content-center loading" *ngIf="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <!-- Lista de Beneficiários -->
    <ul
      class="col-lg-12 list-group list-group-flush p-0"
      *ngIf="!loading"
    >
      <li *ngFor="let item of data; index as i" class="row-list">
        <div class="panel-group" id="accordion">
          <table>
            <tr>
              <td class="td-1">
                <div
                  class="initial-letters show-initial-letters"
                  [id]="'background' + i"
                >
                  {{ initialLetters(item.data.beneficiarioPix.nome_beneficiario) }}
                </div>
              </td>
              <td class="td-2">
                <div class="col-name">
                  <small>Nome</small>
                  <span data-hj-suppress>{{ item.data.beneficiarioPix.nome_beneficiario | titlecase }}</span>
                </div>
              </td>
              <td class="td-3">
                <div
                  class="col-cpf"
                >
                  <small>CNPJ/CPF</small>
                  <span>{{ item?.data.beneficiarioPix.num_cpf_cnpj_mascarado }}</span>
                </div>
              </td>
              <!-- CHAVE PIX -->
              <!-- <td class="td-4">
                <div class="col-bank-number" *ngIf="item.data.beneficiarioChaveBanco && item.data.beneficiarioChaveBanco.length === 1  && item.data.beneficiarioChaveBanco[0].tipo_chave !== 'AGENCIACONTA'" >
                  <small>Chave {{returnType(item.data.beneficiarioChaveBanco[0].tipo_chave)}}</small>
                  <span>{{ item.data.beneficiarioChaveBanco[0].chave_mascara }}</span>
                </div>
              </td> -->
              <!-- AGENCIA E CONTA -->
              <td class="td-4">
                <div class="col-bank-number" *ngIf="item.data.beneficiarioChaveBanco && item.data.beneficiarioChaveBanco.length === 1 && item.data.beneficiarioChaveBanco[0].tipo_chave === 'AGENCIACONTA'">
                  <small>Número da instituição</small>
                  <span> {{ pad(item.data.beneficiarioChaveBanco[0].cod_compe_beneficiario, 3) }} </span>
                </div>
              </td>
              <td class="td-5">
                <div class="col-fibra-bank">
                  <div class="col-bank-name" *ngIf="item.data.beneficiarioChaveBanco && item.data.beneficiarioChaveBanco.length === 1 && item.data.beneficiarioChaveBanco[0].tipo_chave === 'AGENCIACONTA'">
                    <small>Nome da instituição</small>
                    <span> {{ item.data.beneficiarioChaveBanco[0].nome_banco_beneficiario }} </span>
                  </div>
                </div>
              </td>
              <td class="td-6">

              </td>
              <td class="td-7">
                
              </td>
              <td class="td-8">
                <div class="col-select-btn">
                  <button
                    type="button"
                    class="button"
                    (click)="selectBeneficiary(item.data)"
                    *ngIf="item.data.beneficiarioChaveBanco && item.data.beneficiarioChaveBanco.length === 1"
                  >
                    Selecionar
                  </button>
                </div>
                
                <div
                  *ngIf="item.data.beneficiarioChaveBanco && item.data.beneficiarioChaveBanco.length > 1"
                  class="severalAccounts"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  [attr.href]="'#collapse' + i"
                >
                  {{item.numContas > 0 ? item.numContas : ''}} {{item.numContas > 1 ? 'contas' : item.numContas > 0 ? 'conta' : ''}} {{item.numChaves > 0 && item.numContas > 0 ? '|' : ''}} {{item.numChaves > 0 ? item.numChaves : ''}} {{item.numChaves > 1 ? 'chaves' : item.numChaves > 0 ? 'chave' : ''}}
                  <img
                    src="/assets/svg/arrow_down_circle.svg"
                    alt="Mostrar Detalhes"
                    (click)="openDetails()"
                  />
                </div>
              </td>
              
              <td class="td-9">
                <div class="col-new-account-btn">
                  <button
                    type="button"
                    class="button"
                    (click)="newBeneficiary(item.data)"
                  >
                    Nova Chave
                  </button>
                </div>
              </td>
              <td class="td-10">
                <div class="col-trash-btn item-icon" id="rotate" *ngIf="item.data.beneficiarioChaveBanco && item.data.beneficiarioChaveBanco.length === 1">
                  <div
                    style="width: 19px"
                  ></div>
                  <img
                    src="/assets/svg/trash.svg"
                    alt="Excluir Favorito"
                    (click)="DeleteBeneficiary(item.data)"
                  />
                </div>
              </td>
            </tr>
          </table>
          <div
            [id]="'collapse' + i"
            class="panel-collapse collapse in"
            *ngIf="item.data.beneficiarioChaveBanco && item.data.beneficiarioChaveBanco.length > 1"
          >
            <div class="panel-body" *ngIf="item.data.beneficiarioChaveBanco && item.data.beneficiarioChaveBanco.length > 1">
              <fibra-pix-details-list-beneficiaries
              [keyData]="item.outrasChaves" [accountData]="item.contas" [beneficiarioData]="item"(emitBeneficiary)="selectBeneficiaryChave($event)" (emitDelete)="DeleteBeneficiaryChave($event)"
              ></fibra-pix-details-list-beneficiaries>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <fibra-toast-error-message [color]="'#ED6C6C'" (optionYes)="selectToasterFunction()" (optionNo)="optionNo()"></fibra-toast-error-message>
</div>
