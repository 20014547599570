<div class="panel-body">
  <div class="panel-values">
    <div class="values" *ngIf="balanceData.vlr_cheque_disponivel && balanceData.vlr_limite" id="saldoDisponivelSemChequeEmpresa">
      <div class="value-title">Disponível</div>
      <div class="value-number">
        R$ {{!showData ? "*****" : (balanceData.vlr_saldo || 0 | number: "1.2-2")}}
      </div>
      <div class="value-subtitle">Sem {{ toTitleCase(exibicao) }}</div>
    </div>
    <div class="values" *ngIf="!balanceData.vlr_cheque_disponivel || !balanceData.vlr_limite">
      <div class="value-title">Saldo disponível</div>
      <div class="value-number">
        R$ {{!showData ? "*****" : (balanceData.vlr_saldo || 0 | number: "1.2-2")}}
      </div>
    </div>
    <div class="values" *ngIf="balanceData.vlr_cheque_disponivel && balanceData.vlr_limite" id="saldoDisponivelComChequeEmpresa">
      <div class="value-title">&nbsp;</div>
      <div class="value-number">
          R$ {{ !showData ? "*****" : ((balanceData.vlr_saldo_cheque || 0) | number: "1.2-2") }}
      </div>
      <div class="value-subtitle">Com {{ toTitleCase(exibicao) }}</div>
    </div>
    <div class="values" *ngIf="balanceData.vlr_bloqueado > 0">
      <div class="value-title">Bloqueado</div>
      <div class="value-number" [ngClass]="'block'">
        R$ {{ !showData ? "*****" : (balanceData.vlr_bloqueado || 0 | number: "1.2-2") }}
      </div>
    </div>
    <div class="values">
      <div class="value-title">Pendente</div>
      <div class="value-number" [ngClass]="'pending'">
          <span class="icon-info notification" [appTooltip]="tooltipAll">
            <img width="10" height="10" src="/assets/svg/tooltip-alert-orange.svg" alt="logo"/>
            <div #tooltipAll [ngClass]="'tooltip-custom'">
              <h3> 
                Movimentações previstas do dia
              </h3>
            </div>
          </span>
          R$ {{ !showData ? "*****" : (balanceData.vlr_pendente || 0 | number: "1.2-2") }}
      </div>
    </div>
  </div>
  <div class="panel-value-btn">
    <button class="panel-button" (click)="sendIndiceExtract(indice); sendBalance(balanceData)">
      Ver extrato completo
    </button>
  </div>
</div>
