<div class="content">
    <div class="div">
        <form class="flex" [formGroup]="filterForm">
            <div class="form-check form-check-inline" style="justify-content: space-between;">
                <div class="dropdown-periodic">
                    <span class="span"> Operação </span>
                    <fibra-dropdown class="period-drop" [style.width.px]="156" customClass="period" (change)="switchFilter($event)" [options]="periods" textColor="#8f969d"></fibra-dropdown>
                </div>
                <div class="radio-class" style="display: flex; align-items: center;">
                    <input type="radio" class="form-check-input" formControlName="filter" mdbInput (click)="changeFilterType('ser')" value="ser" />
                    <div>
                        <label class="form-check-label" for="digitableLine">Operações em ser</label
              >
              </div>
              </div>
       
       
        </div>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            class="form-check-input"
            formControlName="filter"
            mdbInput
            (click) = "changeFilterType('contract')"
            value="contract"
          />
          <label class="form-check-label" for="opticalReader" *ngIf="selectedFilter === 'accace'">Número do contrato</label>
                        <label class="form-check-label" for="opticalReader" *ngIf="selectedFilter === 'cob' || selectedFilter === 'carta'">Referência banco</label>
                        <input type="text" maxlength="10" placeholder="000000" class="line-code-bar" name="dtEmSer" formControlName="serDate" disabled *ngIf="filterBySer">
                        <input type="text" maxlength="10" onlyNumber placeholder="0000000000" class="line-code-bar" name="contract" formControlName="contractNum" [(ngModel)]="contractNum" *ngIf="filterByContract">
                        <div class="button-receipt">
                            <button type="button" class="button-continue" (click)="sendExportaData()">
                Consultar
              </button>
                        </div>
                        <div>
                            <br><br><br><br>
                            <span class="field-required" *ngIf="(contractNum === '' || !contractNum) && fieldRequired">Campo obrigatório</span>
                        </div>
                    </div>

        </form>
        </div>

        <div class="datatable" *ngIf="!loading && !noData && isAcc && !hasError">
            <div class="table-title">
                <div class="table-item">
                    <span class="item-font">Data de contratação</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Data de entrega</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Data de liquidação</span>
                </div>
                <div class="table-item" style="text-align: center;">
                    <span class="item-font">Moeda</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Valor do contrato</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Saldo LAE (ACC)</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Saldo LE (ACE)</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Liquidado</span>
                </div>

            </div>
            <div class="table-content" *ngFor="let acc of accAceArray">
                <div class="type">
                    <span class="type-font"> Contrato: {{acc.num_contrato}} </span>
                </div>
                <div class="table-title">
                    <div class="table-item">
                        <span class="item-font">{{acc.dt_op}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{acc.dt_vencto}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{acc.dt_vencto2}}</span>
                    </div>
                    <div class="table-item" style="text-align: center;">
                        <span class="item-font"> {{acc.swift}}</span>
                        <span class="item-font"> BRL</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font labelRight">{{acc.valorContratoUsd}}</span>
                        <span class="item-font labelRight">{{acc.valorContratoBrl}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font labelRight">{{acc.saldo_acc_moeda_estrangeira | number: "1.2-2"}}</span>
                        <span class="item-font labelRight">{{acc.saldo_acc_moeda_nacional | number: "1.2-2"}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font labelRight">{{acc.saldo_ace_moeda_estrangeira | number: "1.2-2"}}</span>
                        <span class="item-font labelRight">{{acc.saldo_ace_moeda_nacional | number: "1.2-2"}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font labelRight">{{acc.valor_liquidado_moeda_estrangeira | number: "1.2-2"}}</span>
                        <span class="item-font labelRight">{{acc.valor_liquidado_moeda_nacional | number: "1.2-2"}}</span>
                    </div>

                </div>
            </div>
        </div>

        <div class="datatable" *ngIf="!loading && !noData && isCob && !hasError">
            <div class="table-title">
                <div class="table-item" style="width: 10% !important;">
                    <span class="item-font">Ref.banco</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Contrato ACE</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Data de recepção</span>
                </div>
                <div class="table-item" style="margin-right: 20px;">
                    <span class="item-font">Data de vencimento</span>
                </div>
                <div class="table-item" style="width: 25% !important;">
                    <span class="item-font">Banco importador</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Moeda</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Saldo</span>
                </div>

            </div>
            <div class="table-content" *ngFor="let cob of cobrancaArray">
                <div class="type">
                    <span class="type-font"> Ref. cliente: {{cob.num_ref}} </span>
                </div>
                <div class="table-title">
                    <div class="table-item" style="width: 10% !important;">
                        <span class="item-font">{{cob.num_contrato}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{cob.vinculo}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{cob.dt_op}}</span>
                    </div>
                    <div class="table-item" style="margin-right: 20px;">
                        <span class="item-font"> {{cob.dt_vencto}}</span>
                    </div>
                    <div class="table-item" style="width: 25% !important;">
                        <span class="item-font">{{cob.nome_importador}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{cob.swift}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{cob.saldoFormatado}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="datatable" *ngIf="!loading && !noData && isCar && !hasError">
            <div class="table-title">
                <div class="table-item-carta" style="width: 6% !important; margin-right: 10px;">
                    <span class="item-font">Ref.banco</span>
                </div>
                <div class="table-item-carta" style="width: 10% !important;">
                    <span class="item-font">Situação</span>
                </div>
                <div class="table-item-carta" style="width: 15% !important; margin-right: 4px;">
                    <span class="item-font">Data de remessa docs</span>
                </div>
                <div class="table-item-carta" style="width: 10% !important;">
                    <span class="item-font">Data de vencimento</span>
                </div>
                <div class="table-item-carta" style="width: 30% !important;">
                    <span class="item-font">Banco emissor / Banco importador</span>
                </div>
                <div class="table-item-carta" style="width: 6% !important;">
                    <span class="item-font">Moeda</span>
                </div>
                <div class="table-item-carta">
                    <span class="item-font">Valor da carta de crédito negociada</span>
                </div>
                <!-- <div class="table-item-carta">
                    <span class="item-font">Saldo utilizado</span>
                </div>
                <div class="table-item-carta">
                    <span class="item-font">Saldo disponível</span>
                </div> -->

            </div>
            <div class="table-content" *ngFor="let credit of creditArray">
                <div class="type">
                    <span class="type-font"> Carta de crédito: {{credit.cod}} </span>
                </div>
                <div class="table-title">
                    <div class="table-item-carta" style="width: 6% !important; margin-right: 10px;">
                        <span class="item-font">{{credit.num_ref}}</span>
                    </div>
                    <div class="table-item-carta" style="width: 10% !important;">
                        <span class="item-font">{{credit.tipo_lc}}</span>
                    </div>
                    <div class="table-item-carta" style="width: 15% !important; margin-right: 4px;">
                        <span class="item-font">{{credit.dt_me}}</span>
                    </div>
                    <div class="table-item-carta" style="width: 10% !important;">
                        <span class="item-font"> {{credit.dt_vencto}}</span>
                    </div>
                    <div class="table-item-carta" style="width: 30% !important;;">
                        <span class="item-font">{{credit.nome_banco_emissor}} </span>
                        <span class="item-font" style="margin-top: 6px;"> {{credit.nome_importador}} </span>
                    </div>
                    <div class="table-item-carta" style="width: 6% !important;">
                        <span class="item-font">{{credit.swift}}</span>
                    </div>
                    <div class="table-item-carta">
                        <span class="item-font">{{(credit.saldo_a_vincular) | number:'1.2-2'}}</span>
                    </div>
                    <!-- <div class="table-item-carta">
                        <span class="item-font">{{credit.saldoUtilizado}}</span>
                    </div>
                    <div class="table-item-carta">
                        <span class="item-font">{{(credit.saldo_a_vincular - credit.saldo_le) | number:'1.2-2' }}</span>
                    </div> -->

                </div>
            </div>
        </div>

        <div class="datatable warning-display" *ngIf="loading">
            <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div class="datatable warning-display" *ngIf="noData && !loading && !hasError">
            <div class="empty-info">
                <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Tente uma nova pesquisa inserindo novos parâmetros.">
                </fibra-generic-info>
            </div>
        </div>

        <div class="datatable warning-display" *ngIf="hasError && !loading">
            <div class="empty-info">

                <fibra-generic-info type="error" title="Ocorreu um erro inesperado" message="Não foi possível encontrar dados de movimentação!">
                </fibra-generic-info>
            </div>
        </div>


        </div>