import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ComexReportDataShare } from '@src/core/usecases/data-share/comex-report-data-share.service';
import { AccAceEntity } from '@src/data/repository/export-repository/export.entity';
import { CobrancaEntity, CreditoEntity, FinimpEntity } from '@src/data/repository/import-repository/import.entity';
import { ChangeEntity } from '@src/data/repository/ready-change-repository/ready-change.entity';
import { ReceivedPaymentEntity } from '@src/data/repository/receveid-payment/received-payment.entity';
import { IssuedWarrantyEntity, ReceivedWarrantyEntity } from '@src/data/repository/warranties/warranties.entity';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as $ from 'jquery'; 

@Component({
  selector: 'fibra-foreign-trade-report',
  templateUrl: './foreign-trade-report.component.html',
  styleUrls: ['./foreign-trade-report.component.scss']
})
export class ForeignTradeReportComponent implements OnInit, OnDestroy{
  @Input() public changeArray: ChangeEntity[] ;
  @Input() public finimpArray: FinimpEntity[] ;
  @Input() public cobrancaArrayImport: CobrancaEntity[] ;
  @Input() public creditArrayImport: CreditoEntity[] ;
  @Input() public accAceArray: AccAceEntity[] ;
  @Input() public cobrancaArrayExport: CobrancaEntity[] ;
  @Input() public creditArrayExport: CreditoEntity[] ;
  @Input() public paymentArray: ReceivedPaymentEntity[] ;
  @Input() public receivedArray: ReceivedWarrantyEntity[] ;
  @Input() public issuedArray: IssuedWarrantyEntity[] ;
  @Output() public closeReport = new EventEmitter();
  public reportData;
  public date = new Date();
  public day;
  public hours;
  public company;
  public hide = false;
  public readyToLoad= false;
  public changeTotais;
  public hasReport: boolean = true;

  constructor(public comexReport: ComexReportDataShare) { }

  ngOnInit() {
    this.hide = false;
    this.day = this.date.toLocaleDateString();
    this.hours = this.date.toLocaleTimeString();

    this.changeTotais = JSON.parse(sessionStorage.getItem('ready-change-total'))
    this.company = JSON.parse(sessionStorage.getItem('defaultCompany'));
    if (this.company[0].modality_account.length === 11) {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
    }
    this.reportData = this.comexReport.getReportInfo();
    this.readyToLoad = true;

    if(localStorage.getItem('canLoadReport') === 'false') {
      this.hasReport = false;
      this.reportData = this.comexReport.deleteValue();
      this.changeArray = null;
      this.finimpArray = null;
      this.cobrancaArrayImport = null;
      this.creditArrayImport = null;
      this.accAceArray = null;
      this.cobrancaArrayExport = null;
      this.creditArrayExport = null;
      this.paymentArray = null;
      this.issuedArray = null;
      this.receivedArray = null;
    }

    if(localStorage.getItem('canLoadReport') === 'true') {
      this.hasReport = true;
    }

    setTimeout(() => {
    this.generatePDF()
    }, 2000); 
   
  }

  ngOnDestroy() {
  }


  public generatePDF() {
   
    const data = document.getElementById('comprovante');
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
    {
      html2canvas(data, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
        
        const image = { type: 'png', quality: 0.98 };
        const margin = [0.5, 0.5];
        
        var imgWidth = 8.5;
        var pageHeight = 11;

        //era imgHeight
        var innerPageWidth = imgWidth - margin[0] * 2;
        var innerPageHeight = pageHeight - margin[1] * 2;
       
        var pxFullHeight = canvas.height;
        var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        var nPages = Math.ceil(pxFullHeight / pxPageHeight);
  
        var pageHeight = innerPageHeight;
        
        var pageCanvas = document.createElement('canvas');
        var pageCtx = pageCanvas.getContext('2d');
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        var pdf = new jsPDF('p', 'in', [8.5, 11]);
        pdf.setFillColor(255,255,200,1);

        for (var page = 0; page < nPages; page++) {

          // Trim the final page to reduce file size.
  
          if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
  
            pageCanvas.height = pxFullHeight % pxPageHeight;
  
            pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
  
          }
          var w = pageCanvas.width;
          var h = pageCanvas.height;
          pageCtx.fillStyle = 'white';
          pageCtx.fillRect(0, 0, w, h);
          pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
  
          // Add the page to the PDF.
          if (page > 0) {
            pdf.addPage();
          }

          var imgData = pageCanvas.toDataURL('image' + image.type, image.quality);
          pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
  
        }
        pdf.save('fibra-comercio-exterior.pdf');
      });
    }
    else
    {
      html2canvas(data, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
        const image = { type: 'png', quality: 0.98 };
        
        const margin = [0.5, 0.5];
        var imgWidth = 8.5;
        var pageHeight = 11;

        // era imgHeight
        var innerPageWidth = imgWidth - margin[0] * 2;
        
        // era HeightLeft
        var innerPageHeight = pageHeight - margin[1] * 2;
        
        var pxFullHeight = canvas.height;
        var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        var nPages = Math.ceil(pxFullHeight / pxPageHeight);
        
        var pageHeight = innerPageHeight;
        var pageCanvas = document.createElement('canvas');
        
        var pageCtx = pageCanvas.getContext('2d');

        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;
        
        var pdf = new jsPDF('p', 'in', [8.5,11]);
        pdf.setFillColor(255, 255, 200, 1);
        
        for (var page = 0; page < nPages; page++) {
          if((page === nPages - 1) && (pxFullHeight % pxPageHeight !== 0)) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
            pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
          }

          var w = pageCanvas.width;
          var h = pageCanvas.height;
          pageCtx.fillStyle = 'white';
          pageCtx.fillRect(0, 0, w, h);
          pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

          if(page > 0) {
            pdf.addPage();
          }

          var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
          pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
          
        }
        pdf.save('fibra-comercio-exterior.pdf');
      });
    }
    $("#ico-fechar").css({ display: "block" });
    this.goBack();
 
  }

  public goBack() {
    this.closeReport.emit('true');
  }
}

