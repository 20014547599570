import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StepperModel } from "@src/core/domain/business-models/stepper.model";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";

@Component({
  selector: "fibra-adesao",
  templateUrl: "./adesao.component.html",
  styleUrls: ["../dda.scss"],
  providers: [CpfCnpjPipe],
})
export class DdaAdesaoComponent implements OnInit {
  currentStep: number = 1;

  step: StepperModel[] = [
    {
      step: 1,
      name: "Entenda",
    },
    {
      step: 2,
      name: "Contrate",
    },
  ];

  statusTerm: DdaTermCompanyApprovalModel = null;

  constructor(
    private readonly activeRoute :ActivatedRoute,
    public ddaTermDataShare: DdaTermDataShare) {}

  ngOnInit(): void {
    this.loadTerms();
  }

  loadTerms(): void {
/*    let result = this.activeRoute.snapshot.data['termos'];
    this.ddaTermDataShare.setStatusTerm(result[0]);
    this.ddaTermDataShare.setStatusTermCancel(result[1]);*/
  }

  goNextStep(stepNumber: number): void {
    this.currentStep = stepNumber;
  }
}
