import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { ListQueryRequest, posicaoCarteiraListQueryModel } from '@src/core/domain/query/query.model';
import { PosicaoCarteiraRepository } from '@src/core/repositories/query/posicao-carteira.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetPosicaoCarteiraUsecase
  implements UseCase<ListQueryRequest, posicaoCarteiraListQueryModel> {
  constructor(private repository: PosicaoCarteiraRepository) {}

  execute(params: ListQueryRequest): Observable<posicaoCarteiraListQueryModel> {
    return this.repository.getPosicaoCarteira(params);
  }
}
