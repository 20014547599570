import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

import { StepperModel } from "@src/core/domain/business-models";
import { CompanyDataShare } from "@src/core/usecases/data-share/company-data-share.service";
import { GetAllAccountTypeUsecase } from "@src/core/usecases/account/get-all-accountTypes.usecase";
import { GetCompanyTypesUsecases } from "@src/core/usecases/company/get-companyTypes.usecase";
import { RegistratoDataRepository } from "@src/data/repository/registrato-repository/registrato.repository";
import { RegistratoEntity, RegistratoRequest } from "@src/data/repository/registrato-repository/registrato.entity";
import { isArray } from "util";


@Component({
  selector: 'fibra-page-registrato',
  templateUrl: 'registrato.component.html',
  styleUrls: ['registrato.component.scss']
})
export class RegistratoComponent implements OnInit {

  public ids = ['register', 'read']
  public register = true;
  public read = false;
  public pj = false;

  public registrato: RegistratoEntity;
  public pessoa: any;


  public formStep1: FormGroup;
  public showModalValidation = false;
  public requestDataApi;
  public apiCallback = 84;
  public currentStep = 1;
  public showModalRegistratoError = false;
  public errorSend = false;

  public step: StepperModel[] = [
    {
      step: 1,
      name: 'Preenchimento',
    },
    {
      step: 2,
      name: 'Comprovante',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private repository: RegistratoDataRepository,
    private sharedCompany: CompanyDataShare,
    // ,
    // private sharedCompany: CompanyDataShare,
    // private getAllAccountTypeUsecase: GetAllAccountTypeUsecase,
    // private getCompanyTypes: GetCompanyTypesUsecases
  ) { }



  ngOnInit(): void {
    this.formSetup();
    this.showModalValidation = false;
    this.showModalRegistratoError = false;


    this.sharedCompany.dataShared.subscribe((res) => {
      if (res !== undefined && isArray(res.companyList)) {        
        this.pessoa = res.companyList.filter((e) => e.selected)[0];
        console.log(this.pessoa);
        this.getFrase();
      }
    });

    // Aguardando flag de pf ou pj
    // Victor irá disponibilizar
    // let data = this.sharedCompany.data;
    // this.sharedCompany.dataShared.subscribe((data) => {
    //   // console.log(data);
    // });

    // this.getAllAccountTypeUsecase.execute().subscribe(data => console.log(data));
    // this.getCompanyTypes.execute().subscribe(data => console.log(data));
  }

  public changeNavbarClass(id: string) {
    if (id === 'register') {
      $('#register-item').removeClass('item-unselected');
      $('#register-item').addClass('item-selected');
      $('#register-font').removeClass('item-font-unselected');
      $('#register-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'register') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.register = true;
      this.read = false;
    }
    if (id === 'read') {
      this.getFrase();
      $('#read-item').removeClass('item-unselected');
      $('#read-item').addClass('item-selected');
      $('#read-font').removeClass('item-font-unselected');
      $('#read-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'read') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.register = false;
      this.read = true;
    }


  }



  formSetup() {
    this.formStep1 = this.formBuilder.group({
      fraseDeSeguranca: [null, [Validators.required]]
    });
  }

  get campoFraseDeSeguranca() { return this.formStep1.get('fraseDeSeguranca'); }


  submitFormStep1() {
    this.showModalValidation = true;
  }

  closeModal($event) {
    this.showModalValidation = $event;
  }

  responseApi(validation) {
    if (validation) {
      if (this.errorSend) {
        this.showModalRegistratoError = true;
      } else {
        this.currentStep = 2;
        this.showSendSuccess();
      }
    }
    this.closeModal(false);
  }

  //ABRE MODAL DE ERROS
  openModalRegistratoError($evt: any) {
    if ($evt) $evt.preventDefault();
    this.showModalRegistratoError = true;
  }

  public closeModalRegistratoError = (event) => {
    this.showModalRegistratoError = event;
  }

  showSendSuccess() {
    this.toastr.success('Frase enviada com sucesso.', null, { closeButton: true });
  }

  getFrase(){
    this.repository.get().subscribe((res)=>{
      this.registrato = res.data;
      console.log("R:", this.registrato);
    })
  }

  postFrase(frase: string, otp:string, senha: string){
    let req :RegistratoRequest;
    req.api_callback = 84;
    req.otp = otp;
    req.password = senha;
    req.payload = frase
    this.repository.post(req).subscribe((res)=>{
      this.registrato = res.data;
      console.log("P:", this.registrato);
    })
  }

  getStatus(codigo: number){
    if (codigo === 1){
      return "Frase solicitada"
    }
    if (codigo === 2){
      return "Aguardando aprovação do Bacen"
    }
    if (codigo === 3){
      return "Frase aprovada"
    }
    if (codigo === 4){
      return "Frase recusada"
    }
    if (codigo === 5){
      return "Erro ao enviar a frase."
    }
  }

  getColor(codigo: number){
    if (codigo === 1){
      return "wait"
    }
    if (codigo === 2){
      return "wait"
    }
    if (codigo === 3){
      return "success"
    }
    if (codigo === 4){
      return "error"
    }
    if (codigo === 5){
      return "error"
    }
  }

}
