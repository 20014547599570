import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageInterface } from '../modal-conclusao-adesao/interfaces/image-interfaces';

@Component({
  selector: 'fibra-modal-pagamento-pendente',
  templateUrl: './modal-pagamento-pendente.component.html',
  styleUrls: ['../../../pages/dda/dda.scss','./modal-pagamento-pendente.component.scss']
})
export class ModalPagamentoPendenteComponent implements OnInit {
  @ViewChild("content", { static: true }) content: HTMLElement;

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Input() message: string = "";

  imgTypes: ImageInterface[] = [
    {
      type: "error",
      src: "assets/png/icon-error.png",
      forecolor: "fg-red",
    },
    {
      type: "success",
      src: "assets/png/icon-accreddited.png",
      forecolor: "fg-green",
    },
    {
      type: "warning",
      src: "assets/png/icon-pending.png",
      forecolor: "fg-warn",
    },
  ];

  title: string = "";  
  imgSrc: string = "";
  typeMessage: string = "";

  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit() {
    this.openModal(this.content);
    this.setModalData();
  }

  setModalData(): void {
    this.title = "Pagamento Pendente.";
    this.imgSrc = this.imgTypes.find((x) => x.type === "warning").src;
    this.typeMessage = "warning";
  }

  openModal(content) {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  fechar(): void {
    this.modalService.dismissAll();
    this.goToView('/dda','boletos')
  }

  irParaHome(): void {
    this.modalService.dismissAll();
    this.goToView('/dashboard/');
  }

  public goToView = (route, aba?: string) => { 
    const userRoute = {
      params: { aba: aba }
    };

    this.router.navigate([route, userRoute.params], { skipLocationChange: true });

  }

  public closeModal(): void {
    this.modalService.dismissAll();
  }
}