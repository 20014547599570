import { DdaHistoryModel } from "@src/core/domain/dda/history.model";
import { Observable } from "rxjs";

export abstract class DdaHistoryRepository {
  abstract get(param: {
    nu_cnpj: string;
    data_inicial: string;
    data_final: string;
    pagina_atual: number;
    quantidade_registros: number;
  }): Observable<Array<DdaHistoryModel>>;
}
