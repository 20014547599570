import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AcceptDocRepository {

  constructor(private http: HttpClient) { }

  getDoc($file: string): Observable<string> {
    return this.http.get<string>(`../assets/docs/${$file}.html`, { responseType: 'html' as any });
  }

}
