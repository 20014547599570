import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { FavoritesRepository } from '@src/core/repositories/transfer/favorites.repository';
import {
  AddAccountRequest,
  BeneficiariesModel,
} from '@src/core/domain/transfer/transfer.model';

@Injectable({
  providedIn: 'root',
})
export class AddAccountUsecase implements UseCase<any, BeneficiariesModel> {
  constructor(private favoritesRepository: FavoritesRepository) {}

  execute(params: AddAccountRequest): Observable<BeneficiariesModel> {
    return this.favoritesRepository.addAccountBeneficiary(params);
  }
}
