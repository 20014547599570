import { Injectable } from '@angular/core';
import { TokenRepository } from '@src/core/repositories/token/token.repository';
import { UseCase } from '@src/core/base/use-case';
import {
  TokenTypeModel,
  NewTokenRequest
} from '@src/core/domain/token/tokenType.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetNewTokenUsecase implements UseCase<NewTokenRequest, TokenTypeModel> {

  constructor(private tokenRepository: TokenRepository) { }

  execute(params: NewTokenRequest): Observable<TokenTypeModel> {
    return this.tokenRepository.getNewToken(params);
  }
}
