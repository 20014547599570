<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div class="content-page">
  <div class="section">
    <div  class="margin-40-right">
        <img src="/assets/opportunity/svg/opportunity-capital-giro.svg" alt="icon-capital-giro">
        <h1>Capital de Giro</h1>
        <p>Uma linha de produtos para atender você e seu negócio. São serviços e soluções para administrar e gerenciar com mais eficiência o seu fluxo financeiro.</p>
        <button (click)="isInterested()">Tenho interesse</button>
    </div>
    <div>
      <img src="/assets/opportunity/img/img-header-capital-giro.jpg" alt="img-header-capital-giro">
    </div>
  </div>  
  <div class="section margin-top-80">
    <div>
      <img src="/assets/opportunity/img/img-capital-giro-tablet.jpg" alt="img-capital-giro-tablet">
    </div>
    <div class="padding-40-left">
      <h3>Precisando de Recursos Financeiros para o seu dia a dia?</h3>
      <p class="font-16px">O Capital de Giro é uma linha de crédito simples que atende as suas necessidades. Com um atendimento personalizado, o Banco Fibra entende o seu giro de vendas e te ajuda no seu fluxo de caixa diário.</p>
      <button (click)="isInterested()">Saiba mais</button>
    </div>
  </div>  
  <div class="section-benefities margin-top-80">
      <h3>Vantagens do Capital de Giro</h3>
    <div class="warpper">
      <div #interested *ngFor="let item of benefities" class="warpper-single">
        <img [src]="item.icon" [alt]="item.alt">
        <p class="font-16px">{{ item.description }}</p>
      </div>
    </div>
  </div> 
  <fibra-opportunity-form></fibra-opportunity-form>
  <div class="line margin-top-80"></div> 
  <fibra-opportunity-products-list></fibra-opportunity-products-list>
</div>
