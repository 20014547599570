import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DdaBoletoModel, DdaBoletoSituacaoEnum, DdaBoletoStatusEnum } from "@src/core/domain/dda/dda-boleto.model";
import { DdaBoletoConsultaModel } from "@src/core/domain/dda/dda-consulta-boleto.model";
import { OtpOptions, PaymentModel, PaymentMultipleDdaRequest } from "@src/core/domain/payment/payment.model";
import { PaymentRepository } from "@src/core/repositories/payment/payment.repository";
import { AccountDataShare } from "@src/data/repository/data-share-repository";
import { AccountsEntity } from "@src/data/repository/pix/accounts/pix-accounts-entity";
import { FilterComponent } from "@src/presentation/web/components/filter/filter.component";
import { ToastErrorMessageComponent } from "@src/presentation/web/components/toast-error-message/toast-error-message.component";
import * as Util from "@src/shared/util-common";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TransactionDetailRepository } from '@src/core/repositories/transaction/transaction.repository';
import jwt_decode from "jwt-decode";
import { DdaBoletoImplementationRepository } from '@src/data/repository/dda/boleto/dda-boleto.implementation.repository';
import { FavoritesRepository } from '@src/core/repositories/transfer/favorites.repository';
import { DdaBoletosStatusErroResponse } from "../../../../../core/domain/dda/dda-boletos-status-erro-response.js";
import { OptionList } from "../../../../../core/domain/business-models/option-list.model.js";

export const REDCOLOR   = '#ED6C6C';
export const GREENCOLOR = '#73B599';

export function dateToNgbDate(date: Date | undefined): NgbDate | undefined {
  if (date) {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }
}

export function momentToNgbDate(date: moment.Moment): NgbDate | undefined {
  if (date) {
    return new NgbDate(date.year(), date.month() + 1, date.date());
  }
}

export function ngbDateToDate(date: NgbDate | undefined): Date | undefined {
  if (date) {
    return new Date(date.year, date.month - 1, date.day);
  }
}

const optionsMap: Record<keyof typeof DdaBoletoSituacaoEnum, OptionList> = {
  AVencer: { idOption: "AVencer", option: "À vencer" },
  Vencido: { idOption: "Vencido", option: "Vencidos" },
  PendenteAprovacao: { idOption: "PendenteAprovacao", option: "Pendentes" },
  Agendado: { idOption: "Agendado", option: "Agendados" },
}

@Component({
  selector: "fibra-boletos",
  templateUrl: "./boletos.component.html",
  styleUrls: ["../../../pages/dda/dda.scss", "./boletos.component.scss"],
})
export class DdaBoletosComponent implements OnInit {
  DdaBoletoSituacaoEnum = DdaBoletoSituacaoEnum;
  optionsMap = optionsMap;
  options: { idOption: keyof typeof DdaBoletoSituacaoEnum, option: string }[] = [
    optionsMap.AVencer,
    optionsMap.Vencido,
    optionsMap.PendenteAprovacao,
    optionsMap.Agendado,
  ];
  util = Util;

  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  @ViewChild(FilterComponent, null) filterComp: FilterComponent;

  tipoBatch?: "pagar" | "exportar" = 'pagar';

  situacaoEnum = DdaBoletoStatusEnum;

  exibirAutorizacao: boolean = false;
  exibirComprovanteProcessamento: boolean = false;

  eventTabs: string;
  tabsSelect: keyof typeof DdaBoletoSituacaoEnum = "AVencer";
  showBatch: boolean = false;
  exibirToolDataPagamento: boolean = false;
  toPay: boolean = false;
  isLoading: boolean = false;
  boletos: Array<DdaBoletoModel> = [];
  checkedAllBoletos: boolean = false;
  mockedNow?: string;

  boletosMultiplos: PaymentMultipleDdaRequest = {lista_pagamentos_efetivar: []};

  approval: boolean = false;
  dataInicial: string = null;
  dataFinal: string = null;
  pendingRequests: number = 0;

  public tipoExportacao: string;

  createDefaultDueDate: NgbDate = dateToNgbDate(new Date());
  dataPagamento: NgbDate = dateToNgbDate(new Date());
  agendarPagamentoParaDataVencimento: boolean = false;
  public typeColorToast = REDCOLOR;

  private _account: AccountsEntity;
  public alcadas: any;

  otpOptions: OtpOptions;  
  exibirPagamentoNoProximoDiaUtil: boolean = false;
  exibirPagamentoAposVencimento: boolean = false;

  public get account(): AccountsEntity {
    return this._account;
  }
  public set account(value: AccountsEntity) {
    this._account = value;
  }

  private readonly errorHandler = response =>
    this.showError(response.error && response.error.message ? response.error.message[0] : Array.isArray(response.message) ? response.message[0] : response.message)

  constructor(
    private readonly paymentsRepository: PaymentRepository,
    private readonly transactionDetailRepository: TransactionDetailRepository,
    private readonly shareAccount: AccountDataShare,
    private readonly ddaBoletoRepository: DdaBoletoImplementationRepository,
    private readonly favoritesRepository: FavoritesRepository
  ) {
  }

  isGerarArquivoCNBA: boolean = false;

  verificarRoles(): Array<UserRole> {
    const token = sessionStorage.getItem('fibra-session');
    const decodedRoleDDA: any = jwt_decode(token);
    return decodedRoleDDA.UserRoles.filter(r => r.Name.startsWith('DDA.'));
  }

  buscarConta(): void {
    if (this.shareAccount && this.shareAccount.dataShared) {
      this.shareAccount.dataShared.subscribe(
        (response: AccountsEntity[]) => {
          if (Array.isArray(response) && response.length > 0) {
            const filteredAccounts = response.filter(x => x.selecionado);
            if (filteredAccounts.length > 0) {
              this.account = filteredAccounts[0];
            } else {
              // Caso não haja nenhum objeto com `selecionado` igual a true
              // Lógica apropriada aqui...
            }
          } else {
            // Caso `response` não seja um array ou esteja vazio
            // Lógica apropriada aqui...
          }
        },
        this.errorHandler
      );
    } else {
      // Caso `this.shareAccount` ou `this.shareAccount.dataShared` sejam nulos ou indefinidos
      // Lógica apropriada aqui...
    }
  }

  ngOnInit(): void {
    this.buscarConta();
    let roles = this.verificarRoles();
    this.isGerarArquivoCNBA = roles.filter(valor => valor.Name === 'DDA.DDA_GER_ARQ_CNAB').length > 0;
  }

  private showError(message: string): void {
    this.typeColorToast = REDCOLOR;
    this.toast.callModalMessage(null, "Erro", message)
  }

  private showWarning(message: string): void {
    this.typeColorToast = REDCOLOR;
    this.toast.callModalMessage(null, "Aviso", message)
  }

  private showInfo(message: string): void {
    this.typeColorToast = GREENCOLOR;
    this.toast.callModalMessage(null, "Informação", message)
  }

  onDdaFilterDateChange(event: { from: NgbDate, to: NgbDate }): void {
    this.dataInicial = `${event.from.year}-${event.from.month.toString().padStart(2, '0')}-${event.from.day.toString().padStart(2, '0')}T00:00:00`;
    this.dataFinal   = `${event.to.year}-${event.to.month.toString().padStart(2, '0')}-${event.to.day.toString().padStart(2, '0')}T23:59:59`;

    this.search();
  }

  disablePendenteAgendado(){
    switch(this.tabsSelect){
      case "PendenteAprovacao":
      case "Agendado":
        return false;
      default:
        return true;
    }
  }

  search(): void {
    this.boletos = [];
    this.isLoading = true;

    let payload: DdaBoletoConsultaModel = {
      nu_cnpj: JSON.parse(localStorage.getItem('batch-company-name')).cpfCnpj,
      data_inicial: this.dataInicial,
      data_final: this.dataFinal,
      status_pagamento: this.tabsSelect,
      pagina_atual: 1,
      quantidade_registros: 50,
    };
    
    this.paymentsRepository.getBoletosStatusErro(payload).subscribe(
      x => {
          optionsMap.AVencer.badgeValue           = x.data.a_vencer           > 0 ? String(x.data.a_vencer)           : "";
          optionsMap.Agendado.badgeValue          = x.data.agendado           > 0 ? String(x.data.agendado)           : "";
          optionsMap.PendenteAprovacao.badgeValue = x.data.pendente_aprovacao > 0 ? String(x.data.pendente_aprovacao) : "";
          optionsMap.Vencido.badgeValue           = x.data.vencido            > 0 ? String(x.data.vencido)            : "";
        }
      )
    
    this.paymentsRepository.listarBoletosDda(payload).subscribe(
      (response) =>   this.fetchDataSource(response.data),
      (error: any) => this.fetchDataSourceError(error),
    );
  }
  
  agendarPagamentoParaDataVencimentoClick(): void {
    if (!this.agendarPagamentoParaDataVencimento) {
      for (const boleto of this.boletos) {
        boleto.data_pagamento = boleto.vencimento;
        this.dataPagamento =dateToNgbDate(new Date(boleto.vencimento));
      }
    }
    else {
      for (const boleto of this.boletos) {
        boleto.data_pagamento = undefined;
      }
    }
  }

  private startRequest = () => { 
    this.pendingRequests++;
  }

  private endRequest = () => { 
    this.pendingRequests--;
  }

  public async call<T>(observable: Observable<T>): Promise<T | null> {
    this.startRequest();

    try {
      return await observable.toPromise();
    } catch (error) {
      this.showError(error.error && error.error.message ? error.error.message[0] : Array.isArray(error.message) ? error.message[0] : error.message);

      throw error;
    }
    finally {
      this.endRequest();
    }
  }

  public get dataPagamentoAsDate(): Date | undefined {
    return ngbDateToDate(this.dataPagamento);
  }

  public get now(): Date {
    return this.mockedNow ? new Date(this.mockedNow) : new Date();
  }

  stringToNgbDate(dateString: string): NgbDate {
    const momentDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss');
  
    const dateStruct: NgbDateStruct = {
      year: momentDate.year(),
      month: momentDate.month() + 1,
      day: momentDate.date()
    };
  
    return NgbDate.from(dateStruct);
  }

  async onDataPagamentoSelected(value: NgbDate): Promise<void> {
    this.exibirToolDataPagamento = false;
    if (value.before(dateToNgbDate(new Date()))) {
      this.showWarning("Data inválida. Favor informar uma data maior ou igual a data atual.")
    }

    this.dataPagamento = value;
    this.agendarPagamentoParaDataVencimento = false;

    const businessDay = (await this.call(this.favoritesRepository.getBusinessDay({ diautil: this.dataPagamentoAsDate.toISOString() }))).data;    
    const proximoDiaUtil = dateToNgbDate(new Date(businessDay.diautil));
    const dataAtual = moment(moment(this.now).format('YYYY-MM-DDT00:00:00'));
    const outraData = moment.utc(businessDay.diautil);
    const diferenca = outraData.diff(dataAtual, 'days');

    const dtPagamentoDiaUtil = moment(`${proximoDiaUtil.year}-${proximoDiaUtil.month}-${proximoDiaUtil.day}`, 'YYYY-MM-DDT00:00:00');
    const dtPagamentoFromDatePicker = moment(`${this.dataPagamento.year}-${this.dataPagamento.month}-${this.dataPagamento.day}`, 'YYYY-MM-DDT00:00:00');

    const dtPagamento  = diferenca > 0 ? dtPagamentoDiaUtil : moment(this.dataPagamentoAsDate);    

    const minDtVecimento = this.boletos.filter(x => x.selecionado).sort((a, b) => moment(a.vencimento).diff(moment(b.vencimento), 'days') < 0 ? -1 : 1);
    const dtVencimento = moment(minDtVecimento[0].vencimento);
    
    const diff = moment(dtPagamento).diff(moment(dtVencimento), 'days');
    const pagamentoNoProximoDiaUtil = dtPagamentoDiaUtil.diff(dtPagamentoFromDatePicker, 'days') > 0;
    
    if (diff > 0 || pagamentoNoProximoDiaUtil) {
      this.dataPagamento = this.stringToNgbDate(dtPagamentoDiaUtil.format("YYYY-MM-DDT00:00:00"))      
      this.exibirToolDataPagamento = true;
    }

    this.exibirPagamentoNoProximoDiaUtil = pagamentoNoProximoDiaUtil;
    this.exibirPagamentoAposVencimento   = diff > 0;

    for (const boleto of this.boletos) {
      boleto.data_pagamento = ngbDateToDate(value).toDateString();
    }

  }

  listarAprovadores(pagamentoId: number): Observable<string[]> {
    return this.paymentsRepository
      .listarAprovadores(pagamentoId)
      .pipe(map(x => x.data.filter(x => !x.criador).map(x => x.nome_pessoa)));
  }

  listarAprovadoresPagamentosPendentes(transacaoId: number): Observable<any>{
    var data = this.transactionDetailRepository
      .getAprovadoresPendentesDda(transacaoId)
      .pipe(
        map(console.log),
      );

      return data;

  }

  tabsSelected(event: keyof typeof DdaBoletoSituacaoEnum): void {
    this.tabsSelect = event;
    this.showBatch = false;
    this.eventTabs = event;      
    
    setTimeout(() => {
      this.search();
    })
  }

  fetchDataSource(result: DdaBoletoModel[]): void {    
    this.isLoading = false;
    this.boletos = result;

    this.onDataPagamentoSelected(this.dataPagamento);

    // TODO Remover
    // this.boletos = this.boletos.map(x => ({ ...x, erros_lambda: { cdg_erro: "123", data_hora: "", msg_erro: "Something going wrong..." } }));

    if(this.boletos && Array.isArray(this.boletos)){
      this.boletos.forEach((item: DdaBoletoModel) => {
        if(item.status.startsWith("Aguardando saldo dispon")) {
          item.status = "Aguardando saldo disponível";
        }
      });
    }
  }

  fetchDataSourceError(error: any): void {
    this.isLoading = false;
    this.boletos = [];
  }  

  checkedAll() {
    this.tipoBatch = 'pagar';

    this.boletos.forEach((item: DdaBoletoModel) => {
      item.selecionado = !this.checkedAllBoletos;
    });

    this.checkedAllBoletos = !this.checkedAllBoletos;
    
    this.buscarConta();
    this.getChecked();
  }

  checked(item: DdaBoletoModel) {
    item.selecionado = !item.selecionado;
    this.getChecked();
  }

  getChecked() {
    let checked = this.boletos.filter((x) => x.selecionado === true);
    this.showBatch = checked === undefined ? false : checked.length > 0;
  }

  getTotalValue(): number {
    return this.boletos.reduce((total, numero) => total + numero.valor, 0);
  }

  Checked() {
    this.showBatch = this.SumBoletosSelecionados() > 1;
  }

  SumBoletosSelecionados() {
    return this.boletos.filter((x) => x.selecionado === true).length;
  }

  SumVlBoletosSelecionados() {
    return this.boletos.filter((x) => x.selecionado === true).reduce((total, numero) => total + numero.valor, 0);
  }

  OnViewBoleto(item: DdaBoletoModel, show: boolean) {
    this.boletos
      .filter(x => x.toggled == true)
      .forEach(x => x.toggled = false);

    this.boletos.filter(x => x == item)[0].toggled = show;    
  }

  onTipoBatchClick(event: Event): void {
    this.tipoBatch = event.target["value"];
  }

  onExportarChange(value: any): void {
    this.tipoExportacao = value;
    this.exportar();
  }

  exportar(): void {
    var boletos = this.boletos.filter(x => x.selecionado).map(x => x.id);

    this.ddaBoletoRepository[this.tipoExportacao == "EXCEL" ? "exportarXlsx" : "exportarCNAB"](boletos)
      .subscribe(
        x => {
          const byteArray = new Uint8Array(atob(x.data).split("").map((char) => char.charCodeAt(0)));
      
          const file    = new Blob([byteArray], { type: this.tipoExportacao == "EXCEL" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "text/plain" });
          
          const fileURL = URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);            
          a.href = fileURL;
          a.download = `Exportação.${this.tipoExportacao == "EXCEL" ? "xlsx" : "txt"}`;
          a.click();
          window.URL.revokeObjectURL(fileURL);
        }
      )
  }

  pagarMultiplos(): void {
    this.exibirComprovanteProcessamento = false;

    this.buscarConta()

    this.boletosMultiplos.lista_pagamentos_efetivar = this.boletos.filter(x => x.selecionado).map(x => ({ 
      codigo_barras: x.codigo_barras,
      sacado: x.sacado,
      cnpj_cpf_sacado: x.cnpj_cpf_sacado, 
      dt_vencimento: moment(x.vencimento).format("YYYY-MM-DDTHH:mm"), 
      dt_pagamento: moment(x.data_pagamento).format("YYYY-MM-DDTHH:mm"), 
      conta_debito: this.account.num_conta, 
      valor: x.valor}));

    this.otpOptions = {
      closeEvent: this.fecharModal.bind(this),
      apiCallback: 137,
      apiPayload: { lista_pagamentos_efetivar: this.boletosMultiplos.lista_pagamentos_efetivar },
      responseValidation: this.paymentMultipleResposta.bind(this),
    }

    this.exibirAutorizacao = true;
  }

  paymentMultipleResposta(response: PaymentModel): void {
    if (response.code == 200) {
      console.log(response.data.alcada);
      this.alcadas = response.data.alcada;
      this.exibirComprovanteProcessamento = true;
    }
    else {
      this.showWarning(response.data.message);
    }
  }

  fecharModal(): void {
    this.exibirAutorizacao = false;
  }

  obterSituacao(boleto: DdaBoletoModel): DdaBoletoSituacaoEnum {
    // A situacao deveria vir do boleto, mas a menos que criem uma opção para listar todos, será sempre igual ao tabsSelect
    return DdaBoletoSituacaoEnum[this.tabsSelect]
  }  

  cancelarExportacao() {
    if(this.boletos.length > 0) {
      this.boletos.forEach(x => {
        x.selecionado = false;  
      });
    }

    this.showBatch = false;
    this.tipoBatch = 'pagar';
  }
}


class UserRole {
  Name: string;
  Descricao: string;
  NumeroConta: string[];
}