import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { IncomeReportResponse } from './income-report.entity';
import { handleError } from '@src/shared/util-common';

@Injectable({
  providedIn: 'root',
})
export class IncomeReportDataRepository {

  constructor(private http: HttpClient) {}

  getIncomeReport(): Observable<IncomeReportResponse> {
        const httpOptions = {
          headers: getHeader(),
        };
          try {
            return this.http
            .get<IncomeReportResponse>(
              `${environment.URLBase}v1/informerendimentos`,
              httpOptions
            )
         }
         catch(error) {
             handleError(error);
         }

  }

}
