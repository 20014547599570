import { GetAuthorizedQueryUsecase } from '@src/core/usecases/query/get-authorized-query.usecase';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  getOrderedDate,
  getOrderedPayer,
  getOrderedValue,
} from '@src/shared/util-common';
import { ListQueryModel } from '@src/core/domain/query/query.model';
import * as Util from '@src/shared/util-common';

@Component({
  selector: 'fibra-portfolio-authorized-list',
  templateUrl: './query-transit.component.html',
  styleUrls: ['./query-transit.component.scss'],
})
export class PortfolioTransitAuthorizedListComponent implements OnInit {
  transit;
  filter = new FormControl('');
  getOrderedDate;
  getOrderedPayer;
  getOrderedValue;
  @Input() params;
  hide;

  constructor(private getAuthorizedQueryList: GetAuthorizedQueryUsecase) {
    this.transit = [];
    this.requestQueryList();
  }

  ngOnInit() {
    this.hide = Util.isEmpty(this.transit);
  }

  private requestQueryList() {
    this.getAuthorizedQueryList
      .execute(this.params)
      .subscribe(
        (res) => this.requestSuccess,
        this.requestError,
        this.requestComplete
      );
  }

  private requestSuccess = (value: ListQueryModel) => {
    this.transit = Util.verifyArray(value.data);
    this.hide = Util.isEmpty(this.transit);
  };

  private requestError = (err) => {
    return console.log(err);
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.transit);
  };

  isNull = (value) => {
    return Util.isNull(value);
  };

  ordered(value) {
    switch (value) {
      case 'date':
        return (this.getOrderedDate = getOrderedDate(this.transit));
      case 'payer':
        return (this.getOrderedPayer = getOrderedPayer(this.transit));
      case 'value':
        return (this.getOrderedValue = getOrderedValue(this.transit));
    }
  }
}

