import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ddaStatus",
})
export class DdaStatusPipe implements PipeTransform {
  transform(value: number): any {
    switch (value) {
      case 0:
        return "Inclusão de Filial";
      case 1:
        return "Aguardando Aprovação";
      case 2:
        return "Aprovado";
      case 3:
        return "Reprovado";
      case 4:
        return "Cancelamento";
      case 5:
        return "CNPJ em validação";
      case 6:
        return "Retorno RFB";
      case 7:
        return "Falha RFB";
      case 8:
        return "Adesão";
      case 9:
        return "Inclusão Pendente";
      case 10:
        return "Exclusão Pendente";
      case 11:
        return "Adesão Pendente";
      case 12:
        return "Aderido";
      case 13:
        return "Cancelamento pendente";
    }
  }
}
