import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TabsDataShare {
    public data: any;
    public currentAccount = undefined;

    public dataToShare = new BehaviorSubject(this.data);
    dataShared = this.dataToShare.asObservable();

    constructor() { }

    setValue(data: any) {
        this.dataToShare.next(data);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TabsOnChange {

    private _tabChange = new Subject<string>();
    public onTabChange = this._tabChange.asObservable();

    changeTab(tabName: string) {
        this._tabChange.next(tabName);
    }
}
