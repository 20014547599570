import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router, Event, NavigationEnd } from '@angular/router';
import { CompanyEntity, UserEntity } from '@src/data/repository/impersonate-repository/impersonate.entity';
import { ImpersonateRepository } from '@src/data/repository/impersonate-repository/impersonate.repository';

@Component({
  selector: 'fibra-impersonate-bar',
  templateUrl: './impersonate-bar.component.html',
  styleUrls: ['./impersonate-bar.component.scss']

})
export class ImpersonateBarComponent implements OnInit {
  simulacao: boolean;
  loading: boolean = true;

  company: CompanyEntity;
  user: UserEntity;
  nextUser: UserEntity;
  users: UserEntity[];
  stopSession: boolean = false;

  selectedUser: number = 0;

  constructor(private router: Router, private impersonateService: ImpersonateRepository) { }

  ngOnInit() {
    this.loading = true;
    this.selectedUser = 0;
    this.simulacao = sessionStorage.getItem("simulacao") === "true";
    if (this.simulacao) {
      this.loadUsers();
    }
    
    this.impersonateService.impersonating.subscribe(s => {
      this.simulacao = s;
      this.loadUsers();
    })
    
  }

  loadUsers(){
    this.user = JSON.parse(sessionStorage.getItem('simulacao.user'));
    this.users = JSON.parse(sessionStorage.getItem('simulacao.users'));
    this.company = JSON.parse(sessionStorage.getItem('simulacao.company'));
    this.loading = false;
  }


  stopImpersonate() {
    this.impersonateService.broadcastImpersonate(false);
    this.router.navigate(['/impersonate']);
  }

  onChange(codPessoa: number){
    this.nextUser = this.users.find(e => e.cod_pessoa == codPessoa);
  }

  cancelChange(){
    this.selectedUser = 0;
  }

  impersonate() {
    let u = this.users.find(e => e.cod_pessoa == this.selectedUser);
    sessionStorage.setItem('simulacao.user', JSON.stringify(u));
    this.impersonateService.getSession(this.company.cod_cli_cad_int, u.email, u.nome_pessoa).subscribe(data => {
      let token = data.data.token_sessao;
      sessionStorage.setItem("fibra-session", token);
      sessionStorage.setItem('simulacao', 'true');
      localStorage.setItem('loggedUser', 'true');
      this.impersonateService.broadcastImpersonate(true);
      this.simulacao = sessionStorage.getItem("simulacao") === "true";
      if (this.simulacao) {
        this.loadUsers();
      }
      location.reload();
    }, err => {
      console.error("Erro ao recuperar sessao", err);
    });
  }

  messageStopImpersonate(){
    this.stopSession = true;
  }

  cancelStop(){
    this.stopSession = false;
  }


}

