export class PixBeneficiariesEntity {
    beneficiarioPix: BeneficiarioEntity;
    beneficiarioChaveBanco: ChavesBeneficiarioEntity[];
}

export class BeneficiarioEntity {
    id_beneficiario: number;
    nome_beneficiario: string;
    num_cpf_cnpj: string;
    cod_empresa: string;
    nome_cliente: string;
}

export class ChavesBeneficiarioEntity {
    id_chave_banco: number;
    id_beneficiario_pix: number;
    tipo_chave: string;
    chave: string;
    cod_banco_beneficiario: number;
    cod_compe_beneficiario: number;
    nome_banco_beneficiario: string;
    agencia_beneficiario: string;
    num_conta_beneficiario: string;
    tipo_conta_beneficiario: string;
}

export class ChaveContaEntity{
    agencia: string;
    cod_banco: string;
    conta: string;
    conta_ativa: boolean;
    nome_banco: string;
    tipo_conta: string;
}
export class AccountsEntity {
    cod_beneficiario_conta: number;
    cod_beneficiario: number;
    cod_tipo_operacao: number;
    cod_tipo_conta: number;
    cod_banco: number;
    nome_banco: string
    num_conta: number;
    num_agencia: 1234;
    nome_chave_pix: string;
}

export interface PixBeneficiariesResponse {
    code: number,
    status: string,
    data: PixBeneficiariesEntity[]
}

export interface PixInsituticoesResponse {
    code: number,
    status: string,
    data: PixInstituicoesEntity[]
}

export class PixInstituicoesEntity {
    identification: number;
    name: string;
    type: string;
}


export interface PixBeneficiarioNovoResponse {
    code: number,
    status: string,
    data: PixBeneficiarioNovoEntity
}

export class PixBeneficiarioNovoEntity {
    id_beneficiario: number;
    num_cpf_cnpj: string;
    nome_beneficiario: string;
    cod_empresa: string;
    nome_cliente: string;
}

export class NovaChaveEntity {
    cod_beneficiario: number;
    cod_beneficiario_chave: number;
    chave_tipo: string;
    chave: string;
    tipoChave: string;
    chaveconta: NovaChaveContaEntity;
    chave_ativa: boolean;
}

export class NovaChaveContaEntity {
    cod_banco: number;
    nome_banco: string
    agencia: number;
    conta: number;
    tipo_conta: string;
    conta_ativa: boolean;
}


export interface PixPrePagamentoResponse {
    code: number,
    status: string,
    data: any
}

export class PixPrePagamentoEntity {
    cod_banco: number;
    nome_banco: string
    agencia: number;
    conta: number;
    tipo_conta: string;
    conta_ativa: boolean;
}

export interface PixConsultaContaResponse {
    code: number,
    status: string,
    data: PixConsultaContaEntity
}

export class PixConsultaContaEntity {
    cod_limite: number;
    data_criacao: Date
    vlr_permitido: number;
    ativo: boolean;
    status: string;
    conta_id: number;
    pessoa_id_solicitacao: number;
    pessoa_email_solicitacao: string;
    cancelado: boolean
}


export interface PixTermoOperadorResponse {
    code: number,
    status: string,
    data: any
}