import { Injectable } from '@angular/core';
import { TitleInformationModelRepository } from '@src/core/repositories/charge/title-information.repository';
import { Observable } from 'rxjs';
import { TitleInformationRepositoryMapper } from '@src/data/repository/charge/title-information/title-information-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { TitleInformationEntity } from '@src/data/repository/charge/title-information/title-information.entity';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TitleInformationRepository extends TitleInformationModelRepository {
  mapper = new TitleInformationRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  postTitleInformation(obj): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };

    return this.http
      .post<TitleInformationEntity>(
        `${environment.URLBase}v1/titulos`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}
