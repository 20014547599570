import { Component, OnInit, ViewChild } from '@angular/core';
import * as Util from '@src/shared/util-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-ownership',
  templateUrl: './ownership.component.html',
  styleUrls: ['./ownership.component.scss'],
})
export class OwnershipPageComponent implements OnInit {
  accountData;
  companyData;
  bankData;
  cnpjMask = Util.cnpjMask;
  transferData: FormGroup;
  typeTransfer = 'same';

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  constructor(private formBuild: FormBuilder, private router: Router) {
    this.transferData = this.formBuild.group({
      ammount: [''],
      dateTrasfer: [''],
      optionTransfer: [''],
      description: [''],
    });
  }

  ngOnInit() {}

  navigateBack() {
    this.router.navigate(['/transfer']);
  }

  errorToast(event) {
    if (event === 11) {
      this.toast.callModalMessage(null, 'CPF invalido', 'Digite novamente.');

      return;
    }
    this.toast.callModalMessage(null, 'CNPJ invalido', 'Digite novamente.');
  }

  errorToastValue(event) {
    if (event) {
      this.toast.callModalMessage(null, null, 'Digite um valor valido.');
    }
  }

  errorToastAccountFibra(event) {
    console.log(event);
    if (event) {
      this.toast.callModalMessage(
        null,
        'Nenhuma conta encontrada',
        'Digite novamente.'
      );
    }
  }
}
