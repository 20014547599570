<div *ngIf="loadingWarranty" style="display: flex; justify-content: center;">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="errWarranty && !loadingWarranty" class="no-show-information">
    <fibra-generic-info type="warn" title="" message="Erro. Tente novamente"> </fibra-generic-info>
</div>
<div *ngIf="!loadingWarranty && !errWarranty" class="card-warranty-for">
    <!--p class="garantia-recebiveis-title">Garantia de recebíveis</p>
    <p *ngIf="recebiveisSupervenienciaInsu" class="supervenience">*Superveniência</p>
    <p *ngIf="!recebiveisSupervenienciaInsu" class="insufuciencia-texto">*Insuficiência</p>
    <p *ngIf="!dataAccount[0].ind_restricao_cred" class="data-account-info" [ngStyle]="{'color':recebiveisSupervenienciaInsu ? 'green' : 'red'}">
        {{ recebiveisSupervenienciaInsuValue | currency:'BRL' }}
    </p>

    <p class="card-intern-title">Total Exigível</p>
    <p class="card-intern-value">
        {{ warrantyMap.total_exigivel_recebiveis | currency:'BRL' }}
    </p>
    <p class="card-intern-title">Fluxo mês Anterior</p>
    <p class="card-intern-value">
        {{ warrantyMap.fluxo_mes_anterior | currency:'BRL' }}
    </p>
    <p class="card-intern-title ">Fluxo mês atual</p>
    <p class="card-intern-value margin-bottom-20">
        {{ warrantyMap.fluxo_mes_atual | currency:'BRL' }}
    </p>
    <p *ngIf="dataAccount[0].ind_restricao_cred" class="msg-info">
        *Favor entrar em contato com seu gerente de relacionamento
    </p>
    <p *ngIf="!dataAccount[0].ind_restricao_cred && recebiveisSupervenienciaInsu" class="msg-info">
        *Valor sujeito a alteração no decorrer do dia, de acordo com a apuração diária das garantias e análise de crédito. Disponibilidade sujeita às obrigações contratuais. <br /> <br />*Saldo composto pela garantia + Conveniência aprovada
    </p>

    <div *ngIf="showMoreInfo" class="button-receipt">
        <button type="button" class="secondary-button button" (click)="onClick()">
          Mais informações
        </button>
    </div>

    <hr class="solid"> -->


    <p class="garantia-duplicata-title">Garantia de duplicatas</p>
    <p *ngIf="warrantyMap.total_garantias >= warrantyMap.total_exigivel_duplicatas" class="supervenience">*Superveniência</p>

    <p *ngIf="warrantyMap.total_garantias < warrantyMap.total_exigivel_duplicatas" class="insufuciencia-texto">*Insuficiência</p>   
    <!-- versao Completa -->
    <p *fibraHasRole="'!PROBLEMA_CREDITO, !PAUTA_VENCIDA'" class="margin-bottom-20" [ngStyle]="{'color':warrantyMap.total_garantias < warrantyMap.total_exigivel_duplicatas ? 'red' : 'green'}">
        {{ (warrantyMap.total_garantias - warrantyMap.total_exigivel_duplicatas) | currency:'BRL' }}
    </p>

    <p class="card-intern-title">Total Exigível</p>
    <p class="card-intern-value">
        {{ warrantyMap.total_exigivel_duplicatas | currency:'BRL' }}
    </p>

    <p class="card-intern-title">Total de garantias</p>
    <p class="card-intern-value margin-bottom-20">
        {{ warrantyMap.total_garantias | currency:'BRL' }}
    </p>
    <!-- versao Completa -->
    <p *fibraHasRole="'!PROBLEMA_CREDITO, !PAUTA_VENCIDA'" class="msg-info">
        *Valor sujeito a alteração no decorrer do dia, de acordo com a apuração diária das garantias e análise de crédito. Disponibilidade sujeita às obrigações contratuais. <br /> <br /> *Saldo Composto pela garantia + Conveniência aprovada
    </p>
    <!-- versao Simples -->
    <p *fibraHasRole="'PROBLEMA_CREDITO, PAUTA_VENCIDA'" class="msg-info">
        *Favor entrar em contato com seu gerente de relacionamento
    </p>


    <div *ngIf="showMoreInfo" class="button-receipt">
        <button type="button" class="secondary-button button" (click)="onClick()">
          Mais informações
        </button>
    </div>