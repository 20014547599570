import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as models from '@src/data/repository/data-share-repository';
import { IncomeReportDataRepository } from '@src/data/repository/income-report-repository/income-report.repository';
import { IncomeReportEntity } from '@src/data/repository/income-report-repository/income-report.entity';
import { FileService } from '@src/data/repository/file/file.service';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-page-income-report',
  templateUrl: './income-report.component.html',
  styleUrls: ['./income-report.component.scss'],
})

export class IncomeReportPageComponent implements OnInit {
  public token = undefined;
  public valueBlocked: boolean;
  public balance;
  public optionsSideCard;
  public options;
  public routAccont: string;
  public typToastColor;
  public incomeReportForm: FormGroup;
  selectedYear = 0;
  optionPeriod = [(new Date()).getFullYear(), (new Date()).getFullYear() - 1, (new Date()).getFullYear() - 2];
  public noData = true;

  incomeReport: IncomeReportEntity[];
  filteredIncomeReport: IncomeReportEntity[];
  loading = true;
  hasError = false;
  startYear = 2018;
  endYear = (new Date()).getFullYear();
  toastColor = '#FF0000';
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';


  constructor(
    private incomeReportService: IncomeReportDataRepository,
    private fileService: FileService,
    private formBuilder: FormBuilder
  ) {
    this.optionsSideCard = models.optionsSideCard[0];

  }
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit() {
    this.getIncomeReport();
    this.incomeReportForm = this.formBuilder.group({
      yearNum: new FormControl('', [
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        Validators.min(this.startYear),
        Validators.max(this.endYear)
      ]),
    });
  }


  public changePeriodItem(year: number) {
    this.incomeReportForm.reset();
    this.selectedYear = year;
    this.filterIncomeReport(year);
  }

  public changePeriodButton(year: number) {
    this.selectedYear = year;
    this.filterIncomeReport(year);
  }

  getIncomeReport() {
    this.loading = true;
    this.incomeReportService.getIncomeReport().subscribe((data) => {
      this.incomeReport = data.data;
      this.selectedYear = (new Date()).getFullYear();
      this.filterIncomeReport(this.selectedYear);
    }, () => {
      this.hasError = true;
      this.noData = false;
      this.loading = false;
      this.incomeReport = [];
    });
  }

  filterIncomeReport(year: number) {
    this.filteredIncomeReport = this.incomeReport.filter(x => x.ano == year);
    this.noData = this.filteredIncomeReport.length === 0;
    this.sortIncomeReport(this.filteredIncomeReport);
    this.loading = false;
  }

  getPeriodDescription(item: IncomeReportEntity) {
    if (item.tipo === 'anual') {
      return 'Anual';
    } else if (item.tipo === 'trimestral' && item.trimestre === 1) {
      return 'Primeiro Trimestre';
    } else if (item.tipo === 'trimestral' && item.trimestre === 2) {
      return 'Segundo Trimestre';
    } else if (item.tipo === 'trimestral' && item.trimestre === 3) {
      return 'Terceiro Trimestre';
    } else {
      return '';
    }
  }

  getFileSize(item: IncomeReportEntity) {
    const factor = 100;
    if (item.tamanho_arquivo >= 1024 * 1024) {
      return Math.round((item.tamanho_arquivo / 1024 * 1024) * factor) / factor + ' MB';
    } else {
      return Math.round((item.tamanho_arquivo / 1024) * factor) / factor + ' KB';
    }
  }


  sortIncomeReport(somethingToSort: IncomeReportEntity[]) {
    const ordering = {};
    const sortOrder = [1, 2, 3, 0];
    for (let i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }

    somethingToSort.sort((a, b) => {
      return (ordering[a.trimestre] - ordering[b.trimestre]);
    });
  }

  getMaxYear() {
    const years = this.incomeReport.map(item => item.ano).filter(val => !isNaN(val));
    return Math.max(...years);
  }

  getAllYears() {
    return Array.from(new Set(this.incomeReport.map(item => item.ano)));
  }

  downloadFile(item: IncomeReportEntity) {
    this.fileService.downloadFile(item.url).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = item.nome_arquivo;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this.toastColor = this.GREENCOLOR;
      this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
    }, (error: any) => {
      this.toastColor = this.REDCOLOR;
      this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o PDF.');
    });
  }

}

