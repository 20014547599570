import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TransferEntity, TransferTypeEnum } from './transfer.entity';
import { TransferRepositoryMapper } from './transfer-repository-mapper';
import { TransferRepository } from '@src/core/repositories/transfer/transfer.repository';
import {
  TransferRequest,
  TransferModel,
} from '@src/core/domain/transfer/transfer.model';

@Injectable({
  providedIn: 'root',
})
export class TransferDataRepository extends TransferRepository {
  mapper = new TransferRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  addTransfer(obj: TransferRequest): Observable<TransferModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };

    return this.http
      .post<TransferEntity>(
        `${environment.URLBase}v1/transferencias`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}
