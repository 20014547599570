<div *ngIf="schedulings != undefined && !loading">
  <ul *ngFor="let scheduling of schedulings" class="row-list">
    <li class="date-sale row">
      <span class="date col-lg-5">{{ formatDate(scheduling.data) }}</span>
      <span class="sale col-lg-7">
        Saldo previsto:
        <strong>
          R$
          {{
            isNull(scheduling.vlr_saldo_previsto)
              ? "0,0"
              : (scheduling.vlr_saldo_previsto | number: "1.2-2")
          }}
        </strong>
      </span>
    </li>
    <li class="list-group-item" *ngFor="let items of scheduling.lancamentos">
      <p class="date-list">
        <img
          [src]="iconPath(items.cod_deb_cred || 'C', items.desc_tipo_transacao)"
          alt=""
        />
        <!-- {{ items.desc_historico | titlecase }} -->
        
        <span class="details">
          <span style="font-weight: 500;">{{ items.desc_historico | titlecase }}</span>
          <span *ngIf="items.nome_favorecido" class="text-desc" data-hj-suppress>{{ items.nome_favorecido }}</span>
          <span *ngIf="items.num_cpf_cnpj" class="text-desc">{{ items.num_cpf_cnpj }}</span>
          <span *ngIf="items.desc_historico.toUpperCase().indexOf('PIX') === -1 && items.dados_conta?.cod_agencia" class="text-desc">{{ items.dados_conta?.cod_agencia }}</span>
          <span *ngIf="items.dados_conta?.nome_banco" class="text-desc">{{ items.dados_conta?.nome_banco }}</span>
        </span>

        <span
          class="value"
          [ngClass]="{
            green: items.cod_deb_cred === 'C',
            red: items.cod_deb_cred === 'D'
          }"
          >R$ 
          <span *ngIf="items.cod_deb_cred === 'D'" style="margin-left: 5px;">-</span>
          {{ items.vlr_lancamento | money }}
          <!-- {{items.desc_tipo_transacao}}
          <img
            src="assets/svg/comprovante.svg"
            alt="Comprovante"
            class="ckeck"
          /> -->
        </span>
        <span style="display:block;font-size:13px;margin-top: 5px;margin-left:2.0%;color: #8F969D; display: block">
          <span class="data-hj-suppress" *ngIf="temDescricao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)" class="text-desc">{{ items.compl_det.desc_nome }}</span>
          <br *ngIf="temDescricao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"/>
          <span class="text-desc" *ngIf="items.desc_historico.toUpperCase().indexOf('PAGTO TIT') !== -1">{{ items.compl_det.desc_finalidade
          }}
        </span>
        <br *ngIf="items.desc_historico.toUpperCase().indexOf('PAGTO TIT') !== -1" />
          <span class="text-desc" *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)">CPF/CNPJ: {{ formatCpfCnpj(items.num_cpf_cnpj) }}</span>
          <br *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"/>
          <span *ngIf="temInstituicao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)" class="text-desc">Instituição: {{ items.compl_det.dados_conta.nome_banco
            }}</span>
          <span class="text-desc" *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj,
          items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)">CC: {{ formatAccountNumber(items.compl_det.dados_conta.num_conta)
            }} | AG: {{ items.compl_det.dados_conta.cod_agencia?.padStart(4, '0') }}</span>
          <span *ngIf="temChave(items)" class="text-desc">Chave: {{ items.compl_det.dados_conta.chave }}</span>
        </span>
      </p>
    </li>
  </ul>
</div>

<!-- Loading -->
<div
  class="d-flex justify-content-center position loading-container"
  *ngIf="loading"
>
  <div class="spinner-border text-primary" role="status"></div>
</div>

<div class="empty-info" *ngIf="hide && !loading">
  <fibra-generic-info
    type="warn"
    title="Nenhum resultado encontrado"
    message="Tente uma nova pesquisa inserindo novos parâmetros."
  >
  </fibra-generic-info>
</div>
