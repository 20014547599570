<div class="content-component"> 
  <div class="row">
    <div class="col-6">
      <h3>Tenho Interesse</h3>
      <p>
        Este preenchimento é exclusivo para cotação de um ou mais produtos* do Banco Fibra. Para outros assuntos, acione o chat, ícone localizado na parte inferior direita da página.
      </p> 
      <p class="juridico">
        {{ juridico }}
      </p>
    </div>
  </div>
  <div class="col-6 p-0">
    <form [formGroup]="formInterested">
      <div class="mb-4 position-relativo">
        <label for="razaoSocial">Razão Social</label>
        <input type="text" formControlName="razaoSocial" class="form-control" placeholder="Escreva a razão social">
      </div>
      <div class="mb-4 position-relativo">
      <label for="cnpj">CNPJ</label>
      <input type="text" formControlName="cnpj" class="form-control" placeholder="00.000.000/000-00"
      [textMask]="{
        mask: cpfcnpjMask,
        placeholderChar: '_',
        guide: false,
        modelClean: true
      }"
      >
      </div>
      <div class="mb-4 position-relativo">
        <label for="celular">Telefone celular</label>
        <input type="text" formControlName="celular" class="form-control" placeholder="(00) 00000-0000"
        [textMask]="{
          mask: celMask,
          placeholderChar: '_',
          guide: false,
          modelClean: true
        }"
        >
      </div>
      <div class="mb-4 position-relativo" [ngClass]="showMessaEmailIsInvalid && !formInterested.controls['email'].valid ? ' invalid-email ' : ''">
        <label for="email">E-mail</label>
        <input (focus)="setFocusEmail()" type="text" formControlName="email" class="form-control" placeholder="Escreva seu e-mail">
      </div>
      <div *ngIf="showMessaEmailIsInvalid && !formInterested.controls['email'].valid" class="mb-4 invalid-email-label">
        <img src="/assets/opportunity/svg/email-invalid.svg" alt="e-mail-invalido"><span>E-mail inválido</span>
      </div>
      <div class="mb-4 position-relativo">
        <label for="contato">Preferência de contato</label>
        
        <div class="select-option content-drop dropdown" (click)="toggle()">
        <div
          class="inside-content dropdown">
          <span 
            class="dropdown place-holder">
            {{ itemSelected }}
          </span>
          <img
            [ngStyle]="
              hasOpened 
                ? { transform: 'rotate(180deg)' }
                : { transform: 'rotate(0deg)' }
            "
            style="margin-left: 5px"
            src="/assets/svg/arrow_down_blue.svg"
            alt="image-seta-baixo"
            class="dropdown"
          />
        </div>
      </div>

      <div
        *ngIf="hasOpened"
        class="items dropdown"
      >
        <div
          *ngFor="let item of contactPreference"
          class="item dropdown"
          (click)="select(item); this.toggle()"
        >
          <span class="dropdown">
            {{ item.name }} 
          </span>
        </div>
      </div>

      </div>
    </form> 
    <button (click)="sendForm()" [disabled]="(!formInterested.valid || itemSelected === 'Selecione um opção')" [ngClass]="!formInterested.valid || itemSelected === 'Selecione um opção' ? 'btn-send-disabled' : 'btn-send' ">Enviar</button>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-body">

    <div class="text">
      <img src="/assets/opportunity/svg/icon-modal.svg" alt="icon-modal">
    </div>

    <div class="msg">
      <h2>Formulário enviado!</h2>
      <p>Recebemos seus dados e em breve nosso time comercial entrará em contato.</p>
    </div>

    <button type="button" class="btn-close" (click)="closeModal()">Fechar</button>

  </div>
</ng-template>