<div class="home">
    <div class="row" style="height: calc(100% - 80px); padding: 0 20px;">
    </div>
    <div class="row" style="padding: 0 20px;">
        <div class="col-md-4">
            <a class="opcao" href="https://openbankingbrasil.org.br/perguntas-frequentes/" target="_blank" >
                <img style="margin-left: 20px;vertical-align: middle;" src="assets/svg/help-circle.svg">
                <span style="color: #4D86BF; margin-left: 20px;">Perguntas e respostas</span>
            </a>
        </div>
        <div class="col-md-4">
            <a class="opcao" (click)="reverOnboarding()">
                <img style="margin-left: 20px;vertical-align: middle;" src="assets/svg/icon-corner-up-left.svg">
                <span style="color: #4D86BF; margin-left: 20px;">{{(onBoardingDone ? 'Rever o' : 'O') + 'nboarding'}}</span>
            </a>
        </div>
        <div class="col-md-4">
            <a class="opcao" (click)="termoDeUso()">
                <img style="margin-left: 20px;vertical-align: middle;" src="assets/svg/revertermoob.svg">
                <span style="color: #4D86BF; margin-left: 20px;">{{(termsOfUseAccepted ? 'T' : 'Aceitar t') + 'ermo de uso'}}</span>
            </a>
        </div>
    </div>    
</div>