import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { WarrantyModel } from '@src/core/domain/dashboard/dashboard.model';
import { WarrantyRepository } from '@src/core/repositories/dashboard/warranty.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetWarrantyUsecases implements UseCase<void, WarrantyModel> {
  constructor(private warrantyRepository: WarrantyRepository) {}

  execute(): Observable<WarrantyModel> {
    return this.warrantyRepository.getWarranty();
  }
}
