import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { handleError } from '@src/shared/util-common';
import { ReceivedWarrantyResponse, IssuedWarrantyResponse, ReceivedWarrantyEntity, IssuedWarrantyEntity } from './warranties.entity';
import { formatDate } from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class WarrantiesDataRepository {

  constructor(private http: HttpClient,@Inject(LOCALE_ID) private locale?: string) { }

  getReceivedWarratyEmSer(endPeriod: string): Observable<ReceivedWarrantyResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    try {
      return this.http
        .get<ReceivedWarrantyResponse>(
          `${environment.URLBase}v1/comercioexterior/garantia/recebida?dt_ini=null&dt_fim=${endPeriod}`,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }
  }

  getIssuedWarratyEmSer(endPeriod: string): Observable<IssuedWarrantyResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    try {
      return this.http
        .get<IssuedWarrantyResponse>(
          `${environment.URLBase}v1/comercioexterior/garantia/emitida?dt_ini=null&dt_fim=${endPeriod}`,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }
  }

  getReceivedWarrantyContract(contractNum: string): Observable<ReceivedWarrantyResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    try {
      return this.http
        .get<ReceivedWarrantyResponse>(
          `${environment.URLBase}v1/comercioexterior/garantia/recebida/ref/${contractNum}`,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }

  }

  getIssuedWarrantyContract(contractNum: string): Observable<IssuedWarrantyResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    try {
      return this.http
        .get<IssuedWarrantyResponse>(
          `${environment.URLBase}v1/comercioexterior/garantia/emitida/ref/${contractNum}`,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }

  }

  PostExcel(obj: any, url: string): Observable<any> {
    return this.http
      .post<any>(
        `${url}`,
        obj,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }


  postExportExcelGarantiaEmitida(): Observable<any> {
    let json = JSON.parse(sessionStorage.getItem('excel_info'))
    let referencia = json.referencia || null;
    let dataInicio = null;
    let dataFinal = null;

    if(json.dt_fim!==undefined){
      dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
    }
    if(json.dt_ini!==undefined){
      dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
    }
    

    let obj = {
      dataInicial:dataInicio,
      dataFinal: dataFinal,
      referencia : referencia
    }
        
    return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/garantia/garantia-emitida/excel`);
  }
  postExportExcelGarantiaRecebida(): Observable<any> {
    let json = JSON.parse(sessionStorage.getItem('excel_info'))
    let referencia = json.referencia || null;
    let dataInicio = null;
    let dataFinal = null;

    if(json.dt_fim!==undefined){
      dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
    }
    if(json.dt_ini!==undefined){
      dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
    }
    
    let obj = {
      dataInicial:dataInicio,
      dataFinal: dataFinal,
      referencia : referencia
    }
    return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/garantia/garantia-recebida/excel`);
  }


}
