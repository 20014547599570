<ng-template #t let-date let-focused="focused">
   <span *ngIf="abaSelected === undefined" class="custom-day"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    [class.init]="isDateInit(date)"
    [class.end]="isDateEnd(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
    [class.past-date]="isPastDate(date)"
  >
    {{ date.day }}
  </span>
  <span *ngIf="abaSelected !== undefined" class="custom-day"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
    [class.past-date]="isPastDatePosicaoCarteira(date)"
  >
    {{ date.day }}
  </span>
</ng-template>

<form class="form-inline">
    <div class="form-group">
      <div class="input-group">
        <input name="datepicker"         
             class="form-control"
             ngbDatepicker
             #datepicker="ngbDatepicker"
             [autoClose]="'outside'"
             (dateSelect)="onDateSelection($event)"
             [maxDate]="maxDate"
             [minDate]="minDate"
             [displayMonths]="2"
             [dayTemplate]="t"
             outsideDays="hidden"
             [startDate]="fromDate!"
             navigation="1"
             #d="ngbDatepicker"
            [placement]="placement"
        >
        <div class="input-group-append">
          <button class="btn btn-outline calendar" (click)="d.toggle(); positionDatePopup()" type="button">
              <img id="calendar" src="/assets/svg/icon_calendar_gray.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </form>
