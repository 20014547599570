<div class="margin-top-25">



    <div *ngIf="loading" class="loading-content">

        <div class="text-center" class="loading">
            <div class="spinner-border" role="status">
                <span class="sr-only">Carregando dados...</span>
            </div>
        </div>
    </div>
    <div *ngFor="let item of loansList">
        <span (click)="back()"></span>
        <span><img src="/assets/png/seta-left.png" (click)="back()" /></span>
        <span class="voltar" (click)="back()">Voltar</span>
        <span class="capital-texto">Capital de giro</span>
        <span class="contrato">
            {{item.num_contrato}}
        </span>
        <div class="row">


            
            
            <div class="col-md-8 boarding">


                <div class="dateRefAlign">
                    <span class="dateRef">Data de referência: {{ dateReferenceToShow | date: 'dd/MM/yyyy' }}</span>
                </div> 


                <p class="dados-contrato">
                    Dados do contrato
                </p>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="dados-contratacao">Data da contratação</p>
                        <p class="dados-contratacao-valor">{{item.dt_contratacao | date :'dd/MM/yyyy'}}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="dados-contratacao">Valor do empréstimo</p>
                        <p class="valores">{{item.valor_emprestimo | currency: 'BRL' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="dados-contratacao">Principal</p>
                        <p class="valores">{{item.valor_principal | currency: 'BRL' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="dados-contratacao">Correção monetária</p>
                        <p class="valores">{{item.valor_principal_cm | currency: 'BRL' }}</p>
                    </div>
                </div>

                <div class="row padding-top-25">
                    <div class="col-sm-2">
                        <p class="dados-contratacao">Taxa mensal</p>
                        <p class="dados-contratacao-valor">{{item.taxa_juros_mes.toString().replace(".",",") }}%</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="dados-contratacao">Juros</p>
                        <!-- <p class="valores">{{item.taxa_juros.toString().replace(".",",") }}%</p> -->
                        <p class="valores">{{item.valor_juros | currency : 'BRL' }}</p>
                    </div>
                    <div class="col-sm-2 margin-right-5">
                        <p class="dados-contratacao">Juros de mora</p>
                        <p class="valores">{{item.valor_mora | currency: 'BRL' }}</p>
                    </div>
                    <div class="col-sm-2 margin-right-5">
                        <p class="dados-contratacao">multa moratória</p>
                        <p class="valores">{{item.valor_multa | currency: 'BRL' }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="dados-contratacao">IOF</p>
                        <p class="valores">{{item.valor_iof | currency: 'BRL' }}</p>
                    </div>
                </div>

                <div class="row padding-top-25" >
                    <!-- <div class="col-sm-3">
                        <p class="dados-contratacao">Valor à pagar</p>
                        <p class="valores">{{item.valor_a_pagar | currency: 'BRL' }}</p>
                    </div> -->
                </div>

                <p class="image-dados-contr">
                </p>



                <p class="dados-contrato padding-top-10" >
                    Pagamento
                </p>


                <div class="row padding-top-10">
                    <div class="col-sm-2">
                        <p class="dados-contratacao">Prazo</p>
                        <p class="dados-contratacao-valor">{{item.num_parcelas}}</p>
                    </div>
                    <div class="col-sm-2 margin-right-3">
                        <p class="dados-contratacao">Data fim</p>
                        <p class="valores">{{item.dt_fim | date :'dd/MM/yyyy'}}</p>
                    </div>
                    <div class="col-sm-2 margin-right-3">
                        <p class="dados-contratacao">Data próximo vencimento</p>
                        <p class="valores">{{item.dt_proximo_vencto | date :'dd/MM/yyyy'}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="dados-contratacao">Parcelas pagas</p>
                        <p class="valores">{{item.qt_parcela_paga}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="dados-contratacao">Valor à pagar</p>
                        <p class="valores">{{item.valor_saldo_devedor | currency: 'BRL' }}</p>
                    </div>
                </div>

                <div class="row dados-contratacao-content">
                    <div class="col-sm-3">
                        <p class="dados-contratacao">Valor pago</p>
                        <p class="valores">{{item.valor_pago | currency: 'BRL' }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 evolucao-pag">
                <div class="evolucao-texto">
                    Evolução do pagamento
                </div>



                <div class="info-content">

                    <div class="info-content-item">
                        <img class="image-info-content-item" alt="image-total-pago"
                            src="/assets/svg/parcelas-vence-hoje.svg">
                        <div>
                            <div class="info-cont">
                                Valor Pago
                            </div>
                            <div class="font-size-18">
                                {{ item.valor_pago | currency: 'BRL' }}
                            </div>
                        </div>
                    </div>


                    <span class="image-cont"></span>

                    <div class="flex-top">
                        <img class="image-info-content-item" alt="image-saldo-apagar"
                            src="/assets/svg/proximos-vencimentos.svg">
                        <div>
                            <div class="font-margin">
                                Valor à pagar
                            </div>
                            <div class="font-size-18">
                                {{ item.valor_saldo_devedor | currency: 'BRL' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    <div class="texto-informativo">
        *Valores sujeitos a confirmação no momento da liquidação.
    </div> 
    </div>
</div>
