<div class="box-tabs">
    <div class="group-options">
        <nav class="nav" *ngFor="let item of options">
          <a class="nav-link" [class.active]="typeList === item.idOption" (click)="changeType(item.idOption); emitTabs(item)">{{ item.option }}</a>
          
         <!--  <span class="icon-alert" 
          *ngIf="item.option == 'Tudo' && tabListConsultaPosicaoCarteira?.value == 2">
            <span
              class="icon-info"
              [appTooltip]="tooltipAll"
            >
              <img class="info-filter"src="/assets/png/icon-pending.png"/>
              <div #tooltipAll class="tooltip-custom">
                <p>Exceto títulos à vencer</p>
                <div></div>
              </div>
            </span>
          </span> -->
          <span *ngIf="showBadge && item.badgeValue !== ''" class="badge badge-primary">{{item.badgeValue}}</span>
          <span *ngIf="showTooltipPortfolioPosition" class="icon-alert">
        <span
          *ngIf="item.notification"
          class="icon-info notification"
          [appTooltip]="tooltipAll"
        >
          <img src="/assets/svg/tooltip-alert-orange.svg" alt="logo" />
          <div #tooltipAll class="tooltip-custom">
            <p>Exceto títulos à vencer</p>
            <div></div>
          </div>
        </span>
      </span>
    </nav>
  </div>
  <div class="group-items" *ngIf="filter">
    <div class="period">
      <div class="label-period">Período</div>
      <div class="group-period">
        <div *ngFor="let item of optionPeriod">
          <div
            class="options-period"
            (click)="changePeriod(item)"
            [ngClass]="{ activeCalendar: dataRequest == item }"
          >
            {{ item }} dias
          </div>
        </div>
        <div class="icon-button">
          <button (click)="periodModal()" class="btn btn-outline calendar" type="button"><img id="calendar" src="/assets/svg/icon_calendar_gray.svg" alt="icon" ></button>
          <!-- Tudo -->
          <fibra-modal-period-datepicker #content *ngIf="typeList === 'extract-list-all' && showModalPeriodDatepicker"
                    (change)="changePeriodDate($event)"
                    (closeEvent)="closeEvent($event)"
                    [maxDate]="today"
                    [isSchedules]="false"
                    [showMesAno]="true"
                    [abaPosicaoCarteira]="'extract'"
          ></fibra-modal-period-datepicker>
          
          <!-- Entradas / Saídas -->
          <fibra-modal-period-datepicker #content 
                    *ngIf="
                      typeList !== 'extract-list-scheduling' &&
                      typeList !== 'extract-list-all' &&
                      typeList !== 'AGENDADO' && 
                      showModalPeriodDatepicker
                    "
                    (change)="changePeriodDate($event)"
                    (closeEvent)="closeEvent($event)"
                    [maxDate]="today"
                    [isSchedules]="false"
                    [showMesAno]="true"
                    [abaPosicaoCarteira]="'extract'"
          ></fibra-modal-period-datepicker>

          <!-- Agendadas -->
          <fibra-modal-period-datepicker #content  *ngIf="typeList === 'extract-list-scheduling' && showModalPeriodDatepicker"
                    (change)="changePeriodDate($event)"
                    (closeEvent)="closeEvent($event)"
                    [minDate]="today"
                    [maxDate]="maxDay"
                    [isSchedules]="true"
                    [showMesAno]="true"
                    [abaPosicaoCarteira]="'extract'"
          ></fibra-modal-period-datepicker>
          <fibra-modal-period-datepicker #content  *ngIf="typeList === 'AGENDADO' && showModalPeriodDatepicker"
                    (change)="changePeriodDate($event)"
                    (closeEvent)="closeEvent($event)"
                    [minDate]="today"
                    [isSchedules]="true"
                    [showMesAno]="true"
                    [abaPosicaoCarteira]="'extract'" 
          ></fibra-modal-period-datepicker>
        </div>
      </div>
    </div>
  </div>
</div>
<fibra-toast-error-message color="#ED6C6C"></fibra-toast-error-message>