import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getHeader } from "@src/core/base/header";
import { ModalShareDataModel } from "@src/core/domain/modal-share-data/modal-share-data.model";
import { ModalShareDataRepositories } from "@src/core/repositories/modal-share-data/modal-share-data.respository";
import { environment } from "@src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ModalShareDataRepository extends ModalShareDataRepositories {

    constructor(private http: HttpClient) {
      super();
    }
    public postData(body: any): Observable<ModalShareDataModel> {
      const httpOptions = {
        headers: getHeader(),
      };

      return this.http.post<ModalShareDataModel>(`${environment.URLBase}v1/resolucao6`, body, httpOptions);
    }
  
  }