import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable, throwError, ObservableInput } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor(private authService: MsalService, private _router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(req)
            .pipe(
                catchError((err: any, caught: Observable<HttpEvent<any>>): ObservableInput<any> => {

                    if (req.url.indexOf('/usuarios/sessao') > 0 && err && (err.status !== 200)) {
                        sessionStorage.clear();

                        if (err.status === 401 || err.status === 403) {
                            location.href = '/no-permission';
                        }

                        else {
                            this.authService.logout();
                        }
                    }

                    // console.error("Erro na requisição:", req.url, environment.URLBase, err)
                    if (req.url.indexOf('/usuarios/empresas') > 0 && err && (err.status !== 200)) {
                        sessionStorage.clear();
                        this.authService.logout();
                    }

                        if (req.url.indexOf(environment.URLBase) >= 0 && err && (err.status === 401 || err.status === 403)) {

                            if (err.status === 403 && err.error == null) {
                                sessionStorage.setItem('erroDashboard', 'Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.');
                                this._router.navigate(['dashboard']);
                            } else {
                                console.info("Realizando logout...");
                                sessionStorage.clear();
                                this.authService.logout();
                            }
                        }
                        return throwError(err);
                    })
            );
    }
}
