import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageInterface } from '../modal-conclusao-adesao/interfaces/image-interfaces';
import { PaymentMultipleDdaRequest } from '@src/core/domain/payment/payment.model';
import * as Util from "@src/shared/util-common";

@Component({
  selector: 'fibra-modal-pagamento-em-processamento',
  templateUrl: './modal-pagamento-em-processamento.component.html',
  styleUrls: ['../../../pages/dda/dda.scss','./modal-pagamento-em-processamento.component.scss']
})
export class ModalPagamentoEmProcessamentoComponent implements OnInit {

    private _alcadas: any;

    @ViewChild("content", { static: true }) content: HTMLElement;
  
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  
    imgTypes: ImageInterface[] = [
      {
        type: "error",
        src: "assets/png/icon-error.png",
        forecolor: "fg-red",
      },
      {
        type: "success",
        src: "assets/png/icon-accreddited.png",
        forecolor: "fg-green",
      },
      {
        type: "warning",
        src: "assets/png/icon-pending.png",
        forecolor: "fg-warn",
      },
    ];
  
    title: string = "";
    message: string = "";
    imgSrc: string = "";
    typeMessage: string = "";

    boletos: PaymentMultipleDdaRequest;

    util = Util;

    @Input() set value (value: PaymentMultipleDdaRequest) {
      if(value!=null && value!=undefined){
        this.boletos = value;
      }
    }

    @Input() set alcadas (value: any) {
      if (value){
        this._alcadas = value;
      }
    }
  
    constructor(private modalService: NgbModal, private router: Router) {}
  
    ngOnInit() {
      this.openModal(this.content);
      this.setModalData();
    }
  
    setModalData(): void {
      this.title = "Em processamento.";
      this.message = `Enviado para aprovação do(s) master(s) da sua empresa.`;
      this.imgSrc = this.imgTypes.find((x) => x.type === "warning").src;
      this.typeMessage = "warn";
    }
  
    openModal(content): void {
      this.modalService.open(content, {
        centered: true,
        size: "lg",
        windowClass: "modal-content-pagamento-em-processamento",
        backdrop: "static",
      });
    }
  
    fechar(): void {
      this.modalService.dismissAll();
      this.goToView('/dda','boletos')
    }
  
    irParaHome(): void {
      this.modalService.dismissAll();
      this.goToView('/dashboard/');
    }
  
    public goToView = (route, aba?: string) => { 
      const userRoute = {
        params: { aba: aba }
      };
  
      this.router.navigate([route, userRoute.params], { skipLocationChange: true });
  
    }
  
    public closeModal(): void {
      this.modalService.dismissAll();
    }

    public pagamentoPendente(boleto: { codigo_barras: string }): boolean {
      return this._alcadas.some(alcada => alcada.codigo_barras === boleto.codigo_barras && alcada.regra_alcada == false);
    }

    public showApprovalMaster(boleto: { codigo_barras: string }) {
      var alcada = this._alcadas.find(alcada => alcada.codigo_barras === boleto.codigo_barras);

      return alcada.pessoas_regra.some(p => p.master === true);
    }
  
    public showApprovalOperator(boleto: { codigo_barras: string }) {
      var alcada = this._alcadas.find(alcada => alcada.codigo_barras === boleto.codigo_barras);

      return alcada.pessoas_regra.some(p => p.master === false);
    }

    public getAprovadores(boleto: { codigo_barras: string }): any[] {
      var alcada = this._alcadas.find(alcada => alcada.codigo_barras === boleto.codigo_barras);

      return alcada.pessoas_regra;
    }
  }
