import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { ReceiptPdfModel } from '../../domain/receipt/receipt.model';
import { ReceiptPdfRepository } from '@src/core/repositories/receipt/receipt-pdf.repository';

@Injectable({
  providedIn: 'root',
})
export class GetReceiptExtractPdfUsecase implements UseCase<any, ReceiptPdfModel> {

  constructor(private receiptRepository: ReceiptPdfRepository) {
    
  }

  execute(id: number): Observable<ReceiptPdfModel> {
    return this.receiptRepository.getPdfExtract(id);
  }
}
