<!-- <div class="fb-box">
  <div class="fb-box-title">
    <span>{{ label }}</span>
  </div>
  <div class="fb-box-options">
    <div class="fb-selector" *ngFor="let item of option">
      <input
        type="radio"
        [id]="item.option"
        [name]="item.option"
        [value]="item.value"
        [(ngModel)]="check"
        (change)="valueTest(item.value)"
      />
      <label class="fibra-radio-group fibra-radio" [for]="item.option"></label>
      <span class="fb-label">{{ item.option }}</span>
    </div>
  </div>
</div> -->

<div class="fb-box2">
  <div class="fb-box-title">
    <span>{{ label }}</span>
  </div>
  <div class="fb-box-options">
    <div class="fb-selector" *ngFor="let item of option">
      <div class="fb-selector-input aqua">
        <input
          type="radio"
          [id]="item.option"
          [name]="item.option"
          [value]="item.value"
          [(ngModel)]="check"
          (change)="valueTest(item.value)"
        />
        <label
          class="fibra-radio-group fibra-radio"
          [for]="item.option"
        ></label>
      </div>
      <div class="fb-label">
        <span>{{ item.option }}</span>
      </div>
    </div>
  </div>
</div>
