<div *ngIf="_open" class="blackout"></div>
<div *ngIf="_open && data.title" class="alert-container" [ngClass]="alertClass">
  <div *ngIf="data.iconPNG && showIcon" class="icon">
    <img [src]="'/assets/png/'+data.iconPNG+'.png'" alt="Alert icon" />
  </div>
  <div class="title" [innerHTML]="data.title"></div>
  <div class="body">
    <ng-content></ng-content>
  </div>
  <div class="buttons">
    <button *ngIf="data.cancelButtonTitle" (click)="cancelAction()" type="button" class="secondary-button btn-outline-primary" [disabled]="busy">
      {{data.cancelButtonTitle}}
    </button>
    <button *ngIf="data.okButtonTitle" (click)="confirmAction()" type="button" class="secondary-button" [disabled]="busy">
      {{ !busy ? data.okButtonTitle : ''}}
      <div *ngIf="busy" class="spinner-border" role="status"></div>
    </button>
  </div>
</div>