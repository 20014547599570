import { Component, OnInit, Input, TemplateRef } from '@angular/core';

// model
import { StepperModel } from '@src/core/domain/business-models';
@Component({
  selector: 'fibra-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
  @Input() stepObj: StepperModel[];
  @Input() showHeader = true;
  @Input() firstStep: TemplateRef<any>;
  @Input() secondStep: TemplateRef<any>;
  @Input() thirdStep: TemplateRef<any>;
  @Input() currentStep = 1;
  @Input() titleSans: boolean = false;

  constructor() {}
  ngOnInit() {}
}
