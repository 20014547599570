import { Component, OnInit } from "@angular/core";
import { StatusApprovalEnum } from "@src/core/domain/dda/dda-type-approval.enum";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";

@Component({
  selector: "fibra-termos-dados",
  templateUrl: "./termos-dados.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
  providers: [CpfCnpjPipe],
})
export class DdaTermosDadosComponent implements OnInit {
  waitApproval: boolean = false;
  showUndoCancel: boolean = false;
  cancelInProgress: boolean = false;
  waitApprovalCancel: boolean = false;
  showMainData: boolean = true;

  termoAceiteId: number = 0;
  termoAceiteIdCancelamento: number = 0;

  constructor(public ddaTermDataShare: DdaTermDataShare) {
  }

  obterDadosTermo(): void {
    this.ddaTermDataShare.getStatusTerm().subscribe((result) => {
      if(result != null){
        this.termoAceiteId = result.id;
        
        this.waitApproval = result.status === StatusApprovalEnum.Pendant;
      }
    });
  }

  obterDadosTermoCancel(): void {
   this.ddaTermDataShare.getStatusTermCancel().subscribe((result) => {
      if (result !== null && result!=undefined) {

        this.termoAceiteIdCancelamento = result.id;

        this.waitApprovalCancel = result.status === StatusApprovalEnum.Pendant;

        this.cancelInProgress =
          result.aprovacoes.find(
            (x) =>
              x.pessoa[0].email === localStorage.getItem("useremail") &&
              x.status === StatusApprovalEnum.Pendant
          ) !== undefined;

        this.showUndoCancel =
          result.aprovacoes.find(
            (x) =>
              x.pessoa[0].email === localStorage.getItem("useremail") &&
              x.status === StatusApprovalEnum.Approved
          ) !== undefined;
      }
    });
  }

  ngOnInit() {
    this.obterDadosTermo();
    this.obterDadosTermoCancel();
  }

  getShowMainData(event: boolean): void {
    this.showMainData = event;
  }
}
