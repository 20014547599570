import { Injectable } from '@angular/core';
import { DocumentCheckModelRepository } from '@src/core/repositories/charge/document-check-type.repository';
import { UseCase } from '@src/core/base/use-case';
import { DocumentCheckModel, DocumentRequest } from '@src/core/domain/charge/document-check.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetDocumentCheckUsecase implements UseCase<DocumentRequest, DocumentCheckModel> {

  constructor(private documentCheckRepository: DocumentCheckModelRepository) { }

  execute(params: DocumentRequest): Observable<DocumentCheckModel> {
    return this.documentCheckRepository.getDocumentCheck(params);
  }
}
