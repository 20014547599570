import { ReceiptandRefuseRequest, ReceiptandRefuseModel } from '@src/core/domain/transfer-receipt/receipt.model';
import { ReceiptandRefuseEntity, TransferReceiptEntity } from './receipt.entity';
import { TransferReceiptModel, ReceiptDetailRequest, ReceiptRequest } from '@src/core/domain/transfer-receipt/receipt.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';
import { TransferReceiptRepositoryMapper, ReceiptAndRefusedRepositoryMapper } from './receipt-repository.mapper';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TransferReceiptDataRepository {
  private transderMapper = new TransferReceiptRepositoryMapper();
  private ReceiptMapper = new ReceiptAndRefusedRepositoryMapper();

  constructor(private http: HttpClient) {}
  getList(obj: ReceiptRequest): Observable<TransferReceiptModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
      .post<TransferReceiptEntity>(
        `${environment.URLBase}v1/transacoes/comprovante/lista`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.transderMapper.mapFrom), catchError(handleError));
  }

  getDetail(obj: ReceiptDetailRequest): Observable<TransferReceiptModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
      .get<TransferReceiptEntity>(
        `${environment.URLBase}v1/Transacoes/comprovante/${params.id}/detalhes`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.transderMapper.mapFrom), catchError(handleError));
  }


  getPdf(id: number): Observable<TransferReceiptModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<TransferReceiptEntity>(
        `${environment.URLBase}v1/Transacoes/comprovante/${id}/comprovante/web`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.transderMapper.mapFrom), catchError(handleError));
  }

  getPdfPixReceipt(id: number): Observable<TransferReceiptModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<TransferReceiptEntity>(
        `${environment.URLBase}v1/Transacoes/Comprovante-recebimento/${id}/comprovante/web/pix`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.transderMapper.mapFrom), catchError(handleError));
  }

  getPdfExtract(nroMovimento: number): Observable<TransferReceiptModel> {
    return this.http
      .get<TransferReceiptEntity>(`${environment.URLBase}v1/Transacoes/comprovante/extrato/${nroMovimento}`, { headers: getHeader() })
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.transderMapper.mapFrom), catchError(handleError));
  }

  getPdfList(ids: number[], receipt: boolean): Observable<TransferReceiptModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ids: ids, formato: "web", recebimento: receipt, tipo: 'pix' };
    return this.http
      .post<TransferReceiptEntity>(
        `${environment.URLBase}v1/Transacoes/ComprovantesPDFLista`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.transderMapper.mapFrom), catchError(handleError));
  }

  
}
