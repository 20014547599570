<div class="approval-list" id="body-content">
  <header class="approval-list__header">
    <img class="logo" src="/assets/svg/logo_fibra.svg" alt="Logo Fibra" />
    <p class="tagline">
      Operações de crédito - Internet Banking - Banco Fibra -
      {{ this.date }} - Auto atendimento IB - {{ this.hour }}.
    </p>
    <h3 class="headline">Posição Diária</h3>
  </header>
  <main class="approval-list__content">
    <!-- <ul class="approval-list__content__tabs">
      <li class="item">{{ tabsType | titlecase }}</li>
      <li class="item">
        Conta
        <span *ngIf="accountType === ''" class="item__selected">{{
          accountType || 'Todas'
        }}</span>
        <span *ngIf="accountType !== ''" class="item__selected">{{
          accountType | account
        }}</span>
      </li>
      <li class="item">Tipo <span class="item__selected">Todos</span></li>
      <li class="item">
        Período <span class="item__selected">{{ diffDateDays }} dias</span>
      </li>
    </ul> -->

    <div class="approval-list__content__table">
      <table class="approval-table">
        <tr>
          <th class="tbl-col-1">Produto</th>
          <th class="tbl-col-2">Contrato</th>
          <th class="tbl-col-3">N° da Parcela</th>
          <th class="tbl-col-4">Data do Vencimento</th>
          <th class="tbl-col-5">Principal</th>
          <th class="tbl-col-6">Valor à pagar</th>
          <th class="tbl-col-6">Situação</th>
        </tr>

        <!-- <div class="pt-15">
          <span class="dateRef">Data de referência: {{ dateReferenceToShow | date: 'dd/MM/yyyy' }}</span>
        </div>  -->

        <tr *ngFor="let item of dailyPositionList">
          <td>{{ item.nome_produto }}</td>
          <td>
            <p class="tipo-transacao">{{ item.num_contrato }}</p>
          </td>
          <td> {{ item.num_parcela }} </td>
          <td>{{ item.dt_vencto | date: 'dd/MM/yyyy' }}</td>
          <td>{{ item.valor_principal | currency:'BRL' }}</td>
          <td>{{ item.valor_a_pagar | currency:'BRL' }}</td>
          <td>
            <!-- <span class="margin-right"><img src="/assets/svg/status-{{status}}.svg" /> </span> -->
            {{ item.cod_situacao }}
          </td>
        </tr>
      </table>
    </div>
  </main>
</div>