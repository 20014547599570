import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

import { CpfValidator } from '@src/shared/validators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[cpf]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CpfValidatorDirective,
      multi: true,
    },
  ],
})
export class CpfValidatorDirective implements Validator {
  /**
   * Valida os dados.
   *
   * @param AbstractControl control
   * @return object ou null caso válido
   */
  validate(control: AbstractControl): { [key: string]: any } {
    return CpfValidator.validate(control);
  }
}
