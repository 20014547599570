import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fibra-pix-limite-cadastro',
    templateUrl: './pix-limite-cadastro.component.html',
    styleUrls: ['./pix-limite-cadastro.component.scss']
})
export class PixLimiteCadastroComponent implements OnInit{
    public codValidacao = '';
    public cadastro = false;
    public enable = false;
    public loading = false;
    public finalizado = false;
    public cadastroEmail = false;
    public enableCel = false;
    public numCelular = '';
    public email = '';
    public timeoutToken = false;
    public document = '';
    public tokenMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    public validEmail = false;
    public token = '';
    public docType = '';
    public keyType = '';
    public cadastroCelular = false;
    public cadastroDocumento = false;
    public validDocument = false;
    public warnType = '';
    public minutes = 1;
    public interval;
    public counter = 0;
    public cadastroAleatorio = false;
    public arrayMessages = {
      chaveJaVinculada: {
        title: 'Chave vinculada a uma conta em outra instituição',
        subtitle: 'Solicite a portabilidade desta chave. Após solicitar a portabilidade, você terá 7 dias para confirmar a alteração na instituição em que ela está cadastrada.',
        button1: 'Cadastrar outra chave',
        button2: 'Solicitar Portabilidade',
        type: 'warn',
        key: this.numCelular
      },
      portabilidadePendente: {
        title: 'Portabilidade de chave pendente',
        subtitle: 'Solicitação enviada para aprovação do(s) master(s) da sua empresa.',
        button1: 'Cadastrar outra chave',
        button2: 'Ver status da solicitação',
        type: 'warn',
        key: this.numCelular
      },
      chaveJaCadastrada: {
        title: 'Esta chave foi cadastrada por outro usuário.',
        subtitle: 'Solicite a reivindicação da chave. Após solicitação de reivindicação, o usuário que está de posse da chave terá o prazo de 7 dias para resposta.',
        button1: 'Cadastrar outra chave',
        button2: 'Solicitar reivindicação',
        type: 'warn',
        key: this.numCelular
      },
      reivindicaçãoPendente: {
        title: 'Esta chave foi cadastrada por outro usuário.',
        subtitle: 'Solicite a reivindicação da chave. Após solicitação de reivindicação, o usuário que está de posse da chave terá o prazo de 7 dias para resposta.',
        button1: 'Cadastrar outra chave',
        button2: 'Solicitar reivindicação',
        type: 'warn',
        key: this.numCelular
      },
      cadastroChavePendente: {
        title: 'Cadastro de chave pendente',
        subtitle: 'Solicitação enviada para aprovação do(s) master(s) da sua empresa.',
        button1: 'Cadastrar outra chave',
        button2: 'Ver status da solicitação',
        type: 'warn',
        key: this.numCelular
      },
      aceiteTermosPixPendente: {
        title: 'Aceite ao Termo do Pix pendente',
        subtitle: 'Para cadastrar uma chave, o(s) master(s) da sua empresa precisa(m) aceitar o Termo de Adesão do Pix.',
        button1: 'Fechar',
        button2: 'Acompanhar status',
        type: 'warn',
        key: this.numCelular
      },
      chavePixCadastrada: {
        title: 'Chave Pix Cadastrada',
        subtitle: `Compartilhe a chave minhachave sempre que quiser receber uma transferência.`,
        button1: 'Fazer uma transferência',
        button2: 'Voltar',
        type: 'success',
        key: this.numCelular
      }, userOtherKey: {
        title: 'Esta chave pertence a outro usuário',
        subtitle: "Solicite a reivindicação desta chave. Após solicitar a reivindicação, o usuário que está de posse da chave terá 7 dias para responder a solicitação.",
        button1: 'Cadastrar outra chave',
        button2: 'Solicitar reivindicação',
        type: 'warn',
        key: this.numCelular
      }
    };
    public warnTitle = '';
    public warnSubtitle = '';
    public button1 = '';
    public button2 = '';
    public btnFunction1 = "";
    public btnFunction2 = "";
    public apiCallback = 88;
    public payload = 159;
    public showModalValidation = false;
    public getAccountInformationSession: any;
    public msgError = "Ocorreu um erro no envio";
    public subTextError = "Tente novamente!";
    public limitsNumberKeys;
    constructor(public modalService: NgbModal) { }
    ngOnInit() {
      //this.loading = true;
      // this.getKeys.getKeysIb().subscribe((data: any) => {
      //   this.getLimitsKeys(data['data'].keys);
      // }, error => {
      //   this.getLimitsKeys([]);
      // })
      this.getAccountInformationSession = sessionStorage.getItem('limite-pix-conta-selected');
      // if (localStorage.getItem('cadastro-atual') !== 'false') {
      //   if (localStorage.getItem('cadastro-atual') === 'celular') {
      //     this.cadastro = true;
      //     this.cadastroCelular = true;
      //     this.keyType = 'celular';
      //   } else if (localStorage.getItem('cadastro-atual') === 'email') {
      //     this.cadastro = true;
      //     this.cadastroEmail = true;
      //     this.keyType = 'email';
      //   } else if (localStorage.getItem('cadastro-atual') === 'document') {
      //     this.cadastro = true;
      //     this.cadastroDocumento = true;
      //     this.document = this.getAccountInformationSession[0].cpfCnpj;
      //     this.keyType = 'document';
      //   } else if (localStorage.getItem('cadastro-atual') === 'aleatorio') {
      //     this.cadastro = true;
      //     this.cadastroAleatorio = true;
      //     this.keyType = 'aleatorio';
      //   }
      // }
    }
    public chooseKey(key: string) {
      if (key === 'celular') {
        this.cadastroCelular = true;
        this.cadastroEmail = false;
        this.cadastroDocumento = false;
        this.cadastroAleatorio = false;
        this.keyType = 'celular';
        this.cadastro = !this.cadastro;
      }
      else if (key === 'email') {
        this.cadastroCelular = false;
        this.cadastroEmail = true;
        this.cadastroDocumento = false;
        this.cadastroAleatorio = false;
        this.keyType = 'email';
        this.cadastro = !this.cadastro;
      }
      else if (key === 'documento') {
        this.cadastroCelular = false;
        this.cadastroEmail = false;
        this.cadastroDocumento = true;
        this.cadastroAleatorio = false;
        this.keyType = 'documento';
        this.cadastro = !this.cadastro;
      }
      else if (key === 'aleatorio') {
        this.cadastroCelular = false;
        this.cadastroEmail = false;
        this.cadastroDocumento = false;
        this.cadastroAleatorio = true;
        this.keyType = 'aleatorio';
        this.cadastro = !this.cadastro;
      }
    }
    public goBackToKeys() {
      this.cadastro = !this.cadastro;
    }
    public goBack() {
      // this.cadastroEmail = false;
      // this.cadastroAleatorio = false;
      // this.cadastroEmail = false;
      // this.cadastroCelular = false;
      localStorage.setItem('view-selecionada', 'limite-home');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }
    public goToTranfer() {
      localStorage.setItem('view-selecionada', 'transferir-beneficiarios');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }
    mphone(v) {
      let r = v.replace(/\D/g, '');
      r = r.replace(/^0/, '');
      if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
      }
      else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
      }
      else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
      }
      else {
        r = r.replace(/^(\d*)/, '($1');
      }
      this.numCelular = r;
      if (this.numCelular.length < 15) {
        this.enableCel = false;
      }
      else {
        this.enableCel = true;
      }
    }
    public cadastrarChave() {
      this.subTextError = "Tente novamente!";
      this.msgError = "Ocorreu um erro no envio";
      this.loading = true;
      this.modalService.dismissAll();
      localStorage.setItem('cadastro-atual', 'false');
      localStorage.setItem('chaves', 'tem-chave');
    }
    public sendToken(type: string) {
      this.modalService.dismissAll();
      this.loading = true;
      // if (type === 'email') {
      //   this.createKeyService.sendEmailToken(this.email).subscribe((res) => {
      //     this.loading = false;
      //     this.openModal(this.contentEmail);
      //     this.codValidacao = res.data;
      //     this.minutes = 5;
      //     this.startTimer();
      //   });
      // } else if (type === 'phone') {
      //   this.createKeyService.sendSmsToken(this.numCelular.replace(/[^0-9\/]/g, '')).subscribe((res) => {
      //     this.loading = false;
      //     this.openModal(this.contentSms);
      //     this.codValidacao = res.data;
      //     this.minutes = 5;
      //     this.startTimer();
      //   });
      // }
    }
    public closeError() {
      this.msgError = "Ocorreu um erro no envio";
      const message = document.getElementById('message');
      message.classList.remove('show');
    }
    public openModal(content): void {
      this.modalService.open(content, {
        centered: true,
        size: 'lg',
        windowClass: 'modal-xxl',
        backdrop: 'static',
      });
    }
    startTimer() {
      clearInterval(this.interval);
      this.timeoutToken = false;
      this.counter = 59;
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        }
        else {
          clearInterval(this.interval);
          if (this.minutes > 0) {
            this.minutes--;
            this.callTimer();
          }
          else {
            this.timeoutToken = true;
            clearInterval(this.interval);
            this.minutes = 0;
          }
        }
      }, 1000);
    }
    callTimer() {
      this.startTimer();
    }
    closeModal() {
      this.modalService.dismissAll();
    }
    responseApiModal($event) {
      if ($event.code === 200) {
        this.showModalValidation = false;
        this.warnType = this.arrayMessages.chavePixCadastrada.type;
        this.warnTitle = this.arrayMessages.chavePixCadastrada.title;
        this.warnSubtitle = this.arrayMessages.chavePixCadastrada.subtitle.replace('minhachave', this.numCelular);
        this.button1 = this.arrayMessages.chavePixCadastrada.button1;
        this.button2 = this.arrayMessages.chavePixCadastrada.button2;
        this.finalizado = true;
        this.loading = false;
        this.btnFunction1 = 'goTransf';
        this.btnFunction2 = 'goback';
      }
      else {
        this.loading = false;
        this.msgError = "Ocorreu um erro no envio";
        const message = document.getElementById('message');
        message.classList.add('show');
      }
    }
    closeModalApi($event) {
      this.showModalValidation = false;
    }
    goStatus() {
      console.log("status");
    }
    solicitaionResquest() {
      console.log("solitação de revindicação");
    }
    btnSeletedCArd(params) {
      console.log(params);
      switch (params) {
        case 'goback':
          this.cadastroCelular = false;
          this.cadastroEmail = false;
          this.cadastroDocumento = false;
          this.cadastroAleatorio = false;
          this.finalizado = false;
          this.keyType = '';
          this.cadastro = !this.cadastro;
          break;
        case 'goTransf':
          this.goToTranfer();
          break;
        case 'solicitanionRequest':
          this.solicitaionResquest();
          break;
        case 'goStatus':
          this.goStatus();
          break;
        default:
          break;
      }
    }
    public getKeyInit() {
      this.loading = true;
      // this.getKeys.getKeysIb().subscribe((data: any) => {
      //   this.getLimitsKeys(data['data'].keys);
      // }, error => {
      //   this.getLimitsKeys([]);
      // })
    }
    public getLimitsKeys(keys) {
      this.loading = true;
      let kyesIb = keys;
      // this.getKeys.getKeys().subscribe((keys: any) => {
      //   if (keys.data.keys.length > 0) {
      //     const data = keys.data;
      //     let object = data['keys'].concat(kyesIb);
      //     this.limitsNumberKeys = object.length;
      //     this.loading = false;
      //   } else {
      //     this.limitsNumberKeys = keys.length;
      //     this.loading = false;
      //   }
      // }, (err) => {
      //   if (err['status'] === 404) {
      //     let object = kyesIb;
      //     this.loading = false;
      //   } else {
      //     this.loading = false;
      //   }
      // })
    }
    handleLimitKey() {
      this.loading = false;
      this.subTextError = "Você atingiu o limite máximo de chaves ativas permitidas para vínculo a esta conta.";
      this.msgError = "Ocorreu um erro no envio";
      const message = document.getElementById('message');
      message.classList.add('show');
    }
    isLimitKey() {
      if (this.getAccountInformationSession[0].tipo_pessoa === 'PF' && this.limitsNumberKeys === 5) {
        return true;
      }
      else if (this.getAccountInformationSession[0].tipo_pessoa === 'PJ' && this.limitsNumberKeys === 20) {
        return true;
      }
      else {
        false;
      }
    }

}