import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApprovalReceiptDataShare {
  public data: any;

  public dataToShare = new BehaviorSubject(this.data);
  public dataShared = this.dataToShare.asObservable();

  constructor() {}

  setValue(data: any) {
    this.dataToShare.next(data);
  }

  clear() {
    this.dataToShare = new BehaviorSubject([]);
    this.dataShared = this.dataToShare.asObservable();
  }
}
