<div *ngIf="waitApproval; else branches">
  <fibra-aprovacao-termo
  [showMainData]="showMainData"
  [waitApproval]="waitApproval"
  [waitApprovalCancel]="waitApprovalCancel"
  [cancelInProgress]="cancelInProgress"
  [showUndoCancel]="showUndoCancel"
  ></fibra-aprovacao-termo>
</div>
  
<ng-template #branches>
  <fibra-aprovacao-termo-cancelamento
  (showMainData)="getShowMainData($event)"
  [termoAceiteId]="termoAceiteId"
  *ngIf="cancelInProgress">
</fibra-aprovacao-termo-cancelamento>

  <fibra-dda-filiais
  [showMainData]="showMainData"
  [waitApproval]="waitApproval"
  [waitApprovalCancel]="waitApprovalCancel"
  [cancelInProgress]="cancelInProgress"
  [showUndoCancel]="showUndoCancel"
  ></fibra-dda-filiais>
</ng-template>
