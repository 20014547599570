import { DisclaimerModel } from '@src/core/domain/disclaimer/disclaimer.model';
import { Mapper } from '@src/core/base/mapper';
import { DisclaimerEntity } from './disclaimer.entity';

export class DisclaimerRepositoryMapper extends Mapper<
  DisclaimerEntity,
  DisclaimerModel
> {
  mapFrom(param: DisclaimerEntity): DisclaimerModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: DisclaimerModel): DisclaimerEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
