import { Mapper } from "@src/core/base/mapper";
import { DdaAccountModel } from "@src/core/domain/dda/account.model";
import { map } from "rxjs/operators";
import { DdaAccountEntity } from "../entity/term/dda-account.entity";

export class DdaAccountMapper extends Mapper<
  DdaAccountEntity,
  Array<DdaAccountModel>
> {
  mapFrom(param: DdaAccountEntity): Array<DdaAccountModel> {
    let data: DdaAccountModel[] = param.data.map((data) => {
      return {
        num_indice: data.num_indice,
        num_conta: data.num_conta,
        cod_agencia: data.cod_agencia,
        desc_conta: data.desc_conta,
        cod_modalidade: data.cod_modalidade,
        desc_modalidade: data.desc_modalidade,
        selecionado: data.selecionado,
      };
    });

    return data;
  }
  mapTo(param: Array<DdaAccountModel>): DdaAccountEntity {
    throw new Error("Method not implemented.");
  }
}
