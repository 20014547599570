import { Component } from '@angular/core';
import { IAcceptDoc } from '@src/data/repository/accept-doc/accept-doc.entity';

@Component({
  selector: 'fibra-page-term-user',
  templateUrl: './terms-user.component.html',
  styleUrls: ['./terms-user.component.scss'],
})

export class TermUserPageComponent {
  document: IAcceptDoc = {
    docTitle: 'Termo de uso',
    docUrl: 'term-user-openbanking',
    buttonTittle: 'Aderir',
    checkTitle: 'Li e concordo com o Termo de Uso.',
    readOnly: true
  };
}

