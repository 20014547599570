import { DocumentCheckEntity } from '@src/data/repository/charge/document-check/document-check.entity';
import { DocumentCheckModel } from '@src/core/domain/charge/document-check.model';
import { Mapper } from '@src/core/base/mapper';

export class DocumentCheckRepositoryMapper extends Mapper <DocumentCheckEntity, DocumentCheckModel> {
  mapFrom(param: DocumentCheckEntity): DocumentCheckModel {
    return {
      code: param.code,
      status: param.status,
      data: {
        cpfCnpj: param.data.num_cpf_cnpj,
        draweeName: param.data.nome_sacado,
      },
    };
  }

  mapTo(param: DocumentCheckModel): DocumentCheckEntity {
    return {
      code: param.code,
      status: param.status,
      data: {
        num_cpf_cnpj: param.data.cpfCnpj,
        nome_sacado: param.data.draweeName,
      }
    };
  }
}
