import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fibra-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnChanges {

  @Input() showSelectAll = false;
  @Input() collapseID: string;
  @Output() emitChange: EventEmitter<any>;
  @Output() emitAllChange: EventEmitter<any>;
  @Input() listAccount: [{
    acesso_padrao: boolean,
    numero_conta: number,
    permissoes: [
      {
        cod_funcionalidade: number;
        operacoes: [{ cod_operacao: number }]
      }
    ]
  }];

  @Input() listNotAccount: [{
    cod_funcionalidade: number;
    operacoes: [{ cod_operacao: number }]
  }];


  @Input() listPermissions: [{
    cod_funcionalidade: number,
    desc_funcionalidade: string,
    operacoes: [
      { cod_operacao: number, desc_operacao: string, status: boolean }
    ]
  }];
  public listPermissionsTemp: any;
  public checkAll = false;
  constructor() {
    this.emitChange = new EventEmitter();
    this.emitAllChange = new EventEmitter();
  }

  ngOnChanges() {
    this.listPermissionsTemp = this.listPermissions;
  }

  public changeRule(event, collapseId?, funcionalidadeId?, operacaoId?) {
    const checked = event;
    const arrReturn = {
      checked,
      operacao_id: operacaoId,
      collapse_id: collapseId,
      funcionalidade_id: funcionalidadeId
    };
    this.emitChange.emit(arrReturn);
  }

  public changeAllRules(event, collapseId) {
    const checked = event;
    const arrReturn = {
      checked,
      collapse_id: collapseId
    };
    this.emitAllChange.emit(arrReturn);
  }

  isEnable(rule) {
    return rule.status;
  }

  
  public getQtdActive(numero_conta, cod_funcionalidade): string {
    let qtd = 0;
    let msg = '';
    
    if (numero_conta !== 0) 
    {
      
      if (numero_conta && cod_funcionalidade && this.listAccount.length) 
      {
        
        this.listAccount.forEach(account => {
          if (numero_conta === account.numero_conta) {
            account.permissoes.forEach(permissoes => {
              if (permissoes.cod_funcionalidade === cod_funcionalidade) {
                permissoes.operacoes.forEach(operacao => {
                  qtd++;
                });
              }
            });
          }
        });

      }

    } 
    else 
    {

      this.listNotAccount.forEach(account => {
        if (account.cod_funcionalidade === cod_funcionalidade) {
          account.operacoes.forEach(operacao => {
            qtd++;
          });
        }
      });

    }

    if (qtd === 1) {
      msg = qtd + ' permissão ativa';
    } else if (qtd > 1) {
      msg = qtd + ' permissões ativas';
    }
    return msg;
  }

  public enableOperator(numero_conta, cod_funcionalidade, cod_operacao) {
    let qtd = 0;

    if (numero_conta !== 0) 
    {
      if (numero_conta && cod_funcionalidade && cod_operacao && this.listAccount.length) {
        this.listAccount.forEach(account => {
          if (numero_conta === account.numero_conta) {
            account.permissoes.forEach(permissoes => {
              if (permissoes.cod_funcionalidade === cod_funcionalidade) {
                permissoes.operacoes.forEach(operacao => {
                  if (operacao.cod_operacao === cod_operacao) {
                    qtd++;
                  }
                });
              }
            });
          }
        });
      }
    } 
    else 
    {

      if(numero_conta === 0){

        this.listNotAccount.forEach(account => {

          if (account.cod_funcionalidade === cod_funcionalidade) {
            account.operacoes.forEach(operacao => {
              if (operacao.cod_operacao === cod_operacao) {
                qtd++;
              }
            });

          }

        });
      }
    }
    return !!qtd;
  }
  
}
