import { Observable } from 'rxjs';
import { PredictedModel, PredictedRequest, PredictedTotalizadorModel } from '@src/core/domain/predicted/predicted.model';

export abstract class PredictedRepository {
  abstract getPredictedMovements(obj: PredictedRequest): Observable<PredictedModel>;
}

export abstract class PredictedTotalizadoresRepository {
  abstract getPredictedMovementsTotalizadores(): Observable<PredictedTotalizadorModel>;
}
