import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fibra-card-balance',
  templateUrl: './card-balance.component.html',
  styleUrls: ['./card-balance.component.scss'],
})
export class CardBalanceComponent implements OnInit {
  @Input() titleCard;
  @Input() valueType;
  @Input() value;
  @Input() showData;
  @Input() accountNumber;
  @Input() accountName;
  
  constructor() {}

  ngOnInit() {}
}
