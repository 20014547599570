import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
} from '@angular/forms';
import { cnpjMask, cpfMask } from '@src/shared/util-common';
import { productBannerList } from '@src/data/repository/data-share-repository';
import { BannerEmailDataRepository } from '@src/data/repository/dashboard/banner-email.repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Router } from '@angular/router';

@Component({
  selector: 'fibra-modal-dda-join',
  templateUrl: './modal-dda-join.component.html',
  styleUrls: ['./modal-dda-join.component.scss']
})
export class ModalDdaJoinComponent implements OnInit {

  public formEmail: FormGroup;
  public verificaPessoa: string;
  public verificaContactType: string;
  public cpfMask = cpfMask;
  public cnpjMask = cnpjMask;
  public docValid: boolean;
  public productBannerList = productBannerList;

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', { static: true }) content: HTMLElement;
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  public typToastColor;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';

  public get emailForm() {
    return this.formEmail;
  }
  
  constructor(
    private modalService: NgbModal,
    public bannerEmailDataRepository: BannerEmailDataRepository,
    private router: Router,
    public analytics: AngularFireAnalytics
  ) {
    
  }

  ngOnInit(): void {
    this.openModal(this.content);
  }

  goToDda() {
    this.router.navigate(["dda-transacional"]);
    this.closeModal();
  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-dda',
      backdrop: 'static',
    });
  }

  public closeModal(): void {
    this.closeEvent.emit(false);
    this.modalService.dismissAll();
  }

}
