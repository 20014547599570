import { BankDataShare } from '@src/core/usecases/data-share/bank-data-share.service';
import { DropdownList } from '@src/core/domain/business-models/dropdown.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TransferReceiptDataShare } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-new-ownership',
  templateUrl: './new-ownership.component.html',
  styleUrls: ['./new-ownership.component.scss'],
})
export class NewOwnershipPageComponent implements OnInit {
  accountData;
  companyData;
  beneficiaryData = [];
  purposesData: DropdownList;
  valueTransfer;
  typeTransfer;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  constructor(
    private router: Router,
    private shareTransferReceipt: TransferReceiptDataShare,
    private shareBank: BankDataShare
  ) {
    this.valueTransfer = 0;
  }

  ngOnInit() {
    this.typeTransfer = 'new';
  }

  setAmmount(value) {
    this.valueTransfer = value;
  }

  navigateBack() {
    this.router.navigate(['/transfer']);
  }

  goNext() {
    this.shareTransferReceipt.setValue({
      accountData: this.accountData,
      beneficiaryData: this.beneficiaryData,
    });
    this.router.navigate(['/transfer-confirm']);
  }
}
