export interface DdaBoletoModel {
  id: string;
  vencimento: string;
  beneficiario: string;
  cpf_cnpj: string;
  empresa: string;
  valor_a_ser_pagar: number;
  emitido_por: string;
  codigo_barras: string;
  nosso_numero: string;
  banco: string;
  valor: number;
  valor_pago: number | null;
  desconto: number | null;
  abatimento: number | null;
  juros: number | null;
  multa: number | null;
  mora: number | null;
  data_pagamento: string;
  data_agendamento: string;
  numero_documento_origem: string;
  aceita_pagamento_parcial: string;
  processando_aprovacao: boolean | null;
  status: string;
  status_id: DdaBoletoStatusEnum | null;
  aprovadores?: string[];
  sacado: string;
  cnpj_cpf_sacado: string;
  pagamento_id?: number;
  transacao_id?: number;
  erros_lambda?: {
    data_hora: string,
    cdg_erro: string,
    msg_erro: string,
  }

  selecionado?: boolean;
  toggled?: boolean;
}
export type DdaBoletoDetalheModel = {
  situacao: DdaBoletoStatusEnum,
  emitidoPor: string,
  codigoBarras: string,
  beneficiario: Beneficiario,
  vencimento: string,
  valor: number,
  desconto: number,
  abatimento: number,
  juros: number,
  multa: number,
  mora: number,
  valorPago: number,
  formaPagamento: string,
  canal: string,
  dataAgendamento: string,
  contaDebitada: ContaDebitada,
  dataPagamento: string,
  selecionado?: boolean,
  toggled?: boolean,
};

type Beneficiario = {
  nome: string,
  cpfCnpj: string,
  descBanco: string,
  numBanco: number,
}

type ContaDebitada = {
  nome: string,
  cpfCnpj: string,
  agencia: string,
  conta: string,
}

export enum DdaBoletoStatusEnum {
  Pendente = 0,
  Aprovado = 1,
  Reprovado = 2,
  Cancelado = 3,
  AguardandoAprovacaoMaster = 4,
  AguardandoSaldoDisponivel = 5,
  CanceladoSaldoInsuficiente = 6,
  Agendado = 7,
  AgendadoProximoDiaUtil = 8,
  PendenteBancoFibra = 9,
  AguardandoBaixaCIP = 10,
  EmProcessamento = 11,
  Pago = 12,
}

export enum DdaBoletoSituacaoEnum {
  AVencer = 0,
  Vencido = 1,
  PendenteAprovacao = 2,
  Agendado = 3,
}