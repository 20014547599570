import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { RefunResponse } from './pix-refund-entity';
import { RefundRepository } from '@src/core/repositories/pix/refund.repository';
import { RefundModel } from '@src/core/domain/pix/refund.model';

@Injectable({
  providedIn: 'root',
})
export class PixGetRefundListRepository extends RefundRepository {

  constructor(private http: HttpClient) {
    super();
  }

  public getRefund(obj): Observable<RefunResponse> {

    const httpOptions = {
      headers: getHeader(),
    };

    return this.http.post<RefunResponse>(`${environment.URLBase}v1/pix/listarrecebidos`, obj, httpOptions);
  }

  getHistoric(id: number): Observable<RefunResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<RefunResponse>(`${environment.URLBase}v1/pix/pix-recebido-detalhe/${id}`, httpOptions);
  }

    
}
