<div>
    <div class="contract-cont">

        <div class="filter-content">
            <div class="form-check mr-15">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (click)="getAllContracts()" [checked]="radioPeriod">
                <label class="form-check-label mt-5" for="flexRadioDefault1">
                      Posição atual
                    </label>
            </div>
            <div class="form-check  mr-15">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (click)="changePeriod()" [checked]="!radioPeriod">
                <label class="form-check-label mt-5" for="flexRadioDefault2">
                      Período
                    </label>
            </div>
            <div class="filter">
            </div>
            <fibra-form-control-group>
                <fibra-form-control [style.width]="'auto'">
                    <fibra-dropdown (change)="changeSelectMonth($event)" [options]="meses" class="dropdown-mes-list" customClass="select-option mes" placeholder="Mês" removeShadown="true">
                    </fibra-dropdown>
                </fibra-form-control>
                <fibra-form-control [style.width]="'auto'">
                    <fibra-dropdown id="ano" class="dropdown-ano-list" (change)="changeSelectYear($event)" [options]="ano" customClass="select-option ano" placeholder="Ano" removeShadown="true">
                    </fibra-dropdown>
                </fibra-form-control>
            </fibra-form-control-group>
        </div>






        <!-- <div *ngIf="dateReferenceToShow!=null">
            <span class="dateRef">Data de referência: {{ dateReferenceToShow }}</span>
        </div> -->
        <div class="row">
            <div class="col-md-9">
                <div class="contrant-content">

                    <div *ngIf="loading" class="loading-content">
                        <div class="text-center loading">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Carregando dados...</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="hasData" class="contract-header">
                        <div class="col-header"><span>Contrato</span></div>
                        <div class="col-header">Produto</div>
                        <div class="col-header">
                            <div class="col-children">Valor do empréstimo</div>
                        </div>
                        <div class="col-header">Prazo</div>
                        <div class="col-header">
                            <div class="col-children">Vencimento do contrato</div>
                        </div>
                        <div class="col-header">Situação</div>
                        <div class="col-header">
                            <div class="col-children">Informações do contrato</div>
                        </div>
                    </div>
                    <div *ngIf="!loading && !hasData && !hasError" class="no-show-information">
                        <!-- <p class="no-info-to-show">Não há informações para serem exibidas</p> -->
                        <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Não há informações para serem exibidas">
                        </fibra-generic-info>
                    </div>
                    <div class="empty-info no-show-information" *ngIf="hasError">
                        <fibra-generic-info type="error" title="Ocorreu um erro" message="Não foi possível carregar suas operações. Tente novamente mais tarde.">
                        </fibra-generic-info>
                    </div>



                    <div class="contract-body" *ngIf="hasData">

                        <div>
                            <span class="dateRef">Data de referência: {{ dateReferenceToShow | date: 'dd/MM/yyyy' }}</span>
                        </div>

                        <table *ngIf="hasData" class="table table-contract">
                            <tbody>


                                <tr *ngFor="let item of loansList">


                                    <td colspan="2" class="col-value">
                                        <div>

                                            {{ item.num_contrato }}
                                            <span *ngIf="item.qt_parcela_vencida > 0" class="d-inline-block" data-toggle="tooltip" data-placement="top" data-toggle="tooltip" title="Existem parcelas vencidas e não liquidadas neste contrato">
                                                <img style="width: 12px;" src="/assets/png/iconWarningOrange.png" />
                                            </span>
                                        </div>
                                        <p class="num-contract">
                                            Data do contrato: {{item.dt_contratacao | date: 'dd/MM/yyyy'}}
                                        </p>
                                    </td>
                                    <td class="col-value">{{ item.nome_produto }}</td>
                                    <td class="col-value">{{ item.valor_principal | currency: 'BRL' }}</td>
                                    <td class="col-value"><span>{{ item.num_parcelas }} meses</span></td>
                                    <td class="col-value">{{ item.dt_fim | date: 'dd/MM/yyyy'}}</td>
                                    <td class="col-value">
                                        <div class="progress">
                                            <div class="progress-bar custom-progress-bar" role="progressbar" [ngStyle]="{'width': item.progress +'%'}" aria-valuenow="5" aria-valuemin="0" aria-valuemax="{{item.num_parcelas}}">
                                            </div>
                                        </div>
                                        <p class="custom-td-info">{{ item.qt_parcela_paga }} / {{ item.num_parcelas }}</p>
                                    </td>
                                    <td class="col-value contract-info">
                                        <img src="/assets/png/icon_doc.png" (click)="contractInfo(item.num_contrato)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br />
                <div *ngIf="hasData" class="texto-informativo">
                    *Valores sujeitos a confirmação no momento da liquidação.
                </div>
            </div>
            <div id="accordion" class="col-md-3 card-hide accordion-card-content">
                <div class="card card-head-warranty" *fibraHasRole="'OP_CRED.MAP_GAR'">
                    <div class="card-header card-header-warranty-service" id="headingOne">
                        <h5 (click)="rotate1()" class="mb-0 card-title-warranty">
                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="table-contract card-header-button-generic">
                                <div class="title-card-content">
                                    <span class="card-title-header">Mapa
                                        de
                                        garantia</span>
                                    <img [ngStyle]="{ 'transform': rotateArrow1 ?  'rotate(180deg)' : 'rotate(0deg)'}"
                                        alt="image-seta-baixo" src="/assets/svg/arrow_down_blue.svg">
                                </div>
                            </button>
                        </h5>
                    </div>
                    <div aria-labelledby="headingOne" class="collapse scrolly show" data-parent="#accordion" id="collapseOne">
                        <div class="card-body">
                            <fibra-warranty-map [showMoreInfo]="showMoreInfo"></fibra-warranty-map>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    &nbsp;
                </div>
                <div class="card total-opration-content">
                    <div class="card-header card-header-total-operation" id="headingOne">
                        <h5 (click)="rotate2()" class="mb-0 card-header-total-operation-title-group">
                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" class="card-button card-header-button-generic">

                                <div class="card-content-title-total-operation">
                                    <span class="total-operation-title-card">Total
                                        de
                                        operações</span>
                                    <img [ngStyle]="{ 'transform': rotateArrow2 ?  'rotate(180deg)' : 'rotate(0deg)'}"
                                        alt="image-seta" src="/assets/svg/arrow_down_blue.svg">
                                </div>
                            </button>
                        </h5>
                    </div>
                    <div aria-labelledby="headingOne" class="collapse" data-parent="#accordion" id="collapseTwo">

                        <fibra-total-operation></fibra-total-operation>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!loadingWarranty && !errWarranty" class="row card-show table-contract">
            <div class="col-md-9 card mt-5 warranty-map-bellow-content">
                <p class="warranty-map-bellow-content-title">Mapa de garantia</p>
                <p class="card-text" class="garantia-map-title">Garantia de duplicadas
                </p>
                <div class="row warrantyMap-bellow-for">
                    <span class="warrantyMap-supervenience-bellow">
                        <span>*Superveniência</span>
                    <br />
                    <br />
                    <span class="font-size-16">{{ warrantyMap.valor_superveniencia | currency : 'BRL'}}</span>
                    </span>
                    <span class="elegible-total-warranty-bellow">Total
                        Exígivel
                        <br />
                        <br /> <span class="font-size-16">{{ warrantyMap.total_exigivel_duplicatas | currency : 'BRL'
                            }}</span> </span>
                    <span class="total-garant">Total de garantias <br /><br /> <span class="font-size-16">
                            {{ warrantyMap.total_garantias | currency : 'BRL' }}
                        </span> </span>
                </div>
                <p class="warranty-map-info margin-top-25">*Valor sujeito a alteração no decorrer do dia de acordo com a apuração diária das garantias e análise de crédito.Disponibilidade sujeita às obrigações contratuais.
                </p>
                <p class="warranty-map-info margin-top-10">*Saldo composto pela garantia + Conveniência aprovada.
                </p>
            </div>
        </div>
        <div *ngFor="let operation of totalOperations" class="row card-show total-op-bellow-content-for">
            <div class="col-md-9 card mt-5 total-op-bellow-content">
                <p class="total-operation-title-card">
                    Total de Operações</p>
                <div class="row total-operation-row">
                    <div class="total-operation-row-item">
                        <img class="total-operation-item-line" alt="image-credito-contratado" src="/assets/svg/contratado.svg">
                        <div>
                            <span class="font-size-10">Contratado<br /> </span>
                            <p class="total-operation-item-line-value">
                                {{ operation.valor_contratado | currency: 'BRL' }}
                            </p>
                        </div>
                    </div>
                    <div class="total-operation-row-item">
                        <img class="total-operation-item-line" alt="image-parcelas-vence-hoje" src="/assets/svg/parcelas-vence-hoje.svg">
                        <div>
                            <span class="font-size-10">Parcelas vencendo hoje<br /> </span>
                            <p class="total-operation-item-line-value">
                                {{ operation.valor_vencendo | currency: 'BRL' }}
                            </p>
                        </div>
                    </div>
                    <div class="total-operation-row-item">
                        <img class="total-operation-item-line" alt="image-parcelas-vencidas" src="/assets/svg/parcelas-vencidas.svg">
                        <div>
                            <span class="font-size-10">Parcelas vencidas<br /> </span>
                            <p class="total-operation-item-line-value">
                                {{ operation.valor_vencido | currency: 'BRL' }}
                            </p>
                        </div>
                    </div>
                    <div class="total-operation-row-item">
                        <img class="total-operation-item-line" alt="image-proximos-vencimentos" src="/assets/svg/proximos-vencimentos.svg">
                        <div>
                            <span class="font-size-10">Próximos vencimentos<br /> </span>
                            <p class="total-operation-item-line-value">
                                {{ operation.valor_a_vencer | currency: 'BRL' }}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>