import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { getHeader } from "@src/core/base/header";
import { DdaHistoryModel } from "@src/core/domain/dda/history.model";
import { DdaHistoryRepository } from "@src/core/repositories/dda/dda-history.repository";
import { environment } from "@src/environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DdaHistoryEntity } from "../entity/history/dda-history.entity";
import { DdaHistoryMapper } from "./dda-history.mapper";

@Injectable({
  providedIn: "root",
})
export class DdaHistoryImplementationRepository extends DdaHistoryRepository {
  mapperHistory = new DdaHistoryMapper();

  private httpOptions = {
    headers: getHeader(),
  };
  constructor(private http: HttpClient) {
    super();
  }


  
  get(param: {
    nu_cnpj: string;
    data_inicial: string;
    data_final: string;
    pagina_atual: number;
    quantidade_registros: number;
  }): Observable<DdaHistoryModel[]> {
    return this.http
      .post<DdaHistoryEntity>(
        `${environment.URLBase}v1/Dda/listar-historico`,
        param,
        this.httpOptions
      )
      .pipe(map(this.mapperHistory.mapTo));
  }
}
