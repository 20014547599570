import { DdaBoletoModel } from "@src/core/domain/dda/dda-boleto.model";
import { DdaBoletoEntity, ResultSetEntity } from "./dda-boleto-entity";
import { Mapper } from "@src/core/base/mapper";

export class DdaBoletoMappper extends Mapper<
  DdaBoletoEntity,
  Array<DdaBoletoModel>
> {
    mapFrom(param: DdaBoletoEntity): any {
      return param.data.map((item: ResultSetEntity) => {
        return {
          id: item.id,
          vencimento: item.vencimento,
          beneficiario: item.beneficiario,
          cpfCnpj: item.cpfCnpj,
          empresa: item.empresa,
          valorPagar: item.valorPagar,
          situacao: item.situacao,
          emitidoPor: item.emitidoPor,
          codigoBarras: item.codigoBarras,
          banco: item.banco,
          valor: item.valor,
          desconto: item.desconto,
          abatimento: item.abatimento,
          tipoJuros: item.tipoJuros,
          juros: item.juros,
          tiposJurosMora: item.tiposJurosMora,
          jurosMulta: item.jurosMulta,
          dataPagamento: item.dataPagamento,
        };
      });
    }

    mapTo(param: Array<DdaBoletoModel>): DdaBoletoEntity {
      return null;
    }
}
