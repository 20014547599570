import { Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import * as Util from '@src/shared/util-common';
import * as $ from 'jquery';
import { ContratacaoEnum, ParcelasEnum} from '@src/core/domain/product/product.model';
import { GetSolicitacaoUseCase } from '@src/core/usecases/product/get-solicitacao.usecase';

@Component({
  selector: 'fibra-capital-agil',
  templateUrl: './capital-agil.component.html',
  styleUrls: ['./capital-agil.component.scss'],
})
export class CapitalAgilComponent implements OnInit {
  public list: Array<ContratacaoEnum> = [];
  public ids = ['produtos-simulacao','acompanhar-solicitacao']
  public produtos = true;
  public solicitacao = false;
  public temSolicitacao = false;

  constructor(
    private getSolicitacaoUseCase?: GetSolicitacaoUseCase
    ) {}

  ngOnInit() {
    this.changeNavbarClass('produtos-simulacao');
    this.requestSolicitacaoList();
  }

   //REQUEST GET SOLICITACAO
   private requestSolicitacaoList(): void {
    this.getSolicitacaoUseCase
      .execute()
      .subscribe(this.requestSolicitacaoSuccess, this.requestSolicitacaoError);
  }

  private requestSolicitacaoError = (err) => {
    return console.log(err);
  };

  private requestSolicitacaoSuccess = (value: any) => {
    this.list = Util.verifyArray(value.data);
    if(this.list && this.list.length > 0){
      this.temSolicitacao = true;
    }
  };

  public updateAcompanhar(){
    this.requestSolicitacaoList();
  }

  public changeNavbarClass(id: string) {
    if (id === 'produtos-simulacao') {
      $('#produtos-simulacao-item').removeClass('item-unselected');
        $('#produtos-simulacao-item').addClass('item-selected');
        $('#produtos-simulacao-font').removeClass('item-font-unselected');
        $('#produtos-simulacao-font').addClass('item-font-selected');
        this.ids.forEach((elem) => {
          if(elem !== 'produtos-simulacao') {
            $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
          }
        })
        this.produtos = true;
        this.solicitacao = false;
    }
    if(id == 'acompanhar-solicitacao'){
      $('#acompanhar-solicitacao-item').removeClass('item-unselected');
      $('#acompanhar-solicitacao-item').addClass('item-selected');
      $('#acompanhar-solicitacao-font').removeClass('item-font-unselected');
      $('#acompanhar-solicitacao-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if(elem !== 'acompanhar-solicitacao') {
          $(`#${elem}-item`).removeClass('item-selected');
        $(`#${elem}-font`).removeClass('item-font-selected');
        $(`#${elem}-item`).addClass('item-unselected');
        $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.produtos = false;
      this.solicitacao = true;
    }
   
  }
  
}
