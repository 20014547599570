import {
  AddAccountRequest,
  AddBeneficiariesRequest,
  BusinessDayRequest,
  RemoveAndAddRequest,
  RequestAccountFibra,
} from './../../domain/transfer/transfer.model';
import {
  BeneficiariesModel,
  BeneficiariesRequest,
} from '../../domain/transfer/transfer.model';
import { Observable } from 'rxjs';
import { BankTypeEnum } from '@src/data/repository/transfer/beneficiaries/beneficiaries.entity';

export abstract class FavoritesRepository {
  abstract getFavorites(
    params: BeneficiariesRequest
  ): Observable<BeneficiariesModel>;

  abstract deleteFavorites(
    params: RemoveAndAddRequest
  ): Observable<BeneficiariesModel>;

  abstract deleteAccountFavorites(
    params: RemoveAndAddRequest
  ): Observable<BeneficiariesModel>;

  abstract addFavorites(
    params: AddBeneficiariesRequest
  ): Observable<BeneficiariesModel>;

  abstract addAccountBeneficiary(
    params: AddAccountRequest
  ): Observable<BeneficiariesModel>;

  abstract addBankFavorites(
    params: BankTypeEnum
  ): Observable<BeneficiariesModel>;

  abstract getBusinessDay(
    params: BusinessDayRequest
  ): Observable<BeneficiariesModel>;

  abstract getAccountFibra(
    params: RequestAccountFibra
  ): Observable<BeneficiariesModel>;
}
