import { ProductListModel, ProductListRequest, ContractRequest, ComprovanteModel } from '@src/core/domain/product/product.model';
import { Observable } from 'rxjs';

export abstract class ProductRepository {
  abstract getAllProduct(): Observable<ProductListModel>;
  abstract postSimulation(params: ProductListRequest): Observable<ProductListModel>;
  abstract getParcelas(cod_solicitacao: number): Observable<ProductListModel>;
  abstract putContratacao(params: ContractRequest): Observable<ProductListModel>;
  abstract getSolicitation(): Observable<ProductListModel>;
  abstract getComprovante(cod_solicitacao: number): Observable<ComprovanteModel>;
}
