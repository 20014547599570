import { Injectable } from '@angular/core';
import { CompanyTypeRepository } from '@src/core/repositories/company/company-type.repository';
import { UseCase } from '@src/core/base/use-case';
import { CompanyTypeModel, CompanyRequest } from '@src/core/domain/company/companyType.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class setCompanyIndexUsecases implements UseCase<CompanyRequest, CompanyTypeModel> {

  constructor(private companyTypeRepository: CompanyTypeRepository) { }

  execute(params: CompanyRequest): Observable<CompanyTypeModel> {
    return this.companyTypeRepository.setCompanyIndex(params);
  }
}
