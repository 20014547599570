import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { PendencyReduceLimitPix } from '@src/core/domain/dashboard/dashboard.model';
import { PendencyLimitReducePixRepository } from '@src/core/repositories/dashboard/pendency-limit-reduce-pix.repository';

@Injectable({
  providedIn: 'root',
})
export class GetPendencyLimitReducePixUsecase implements UseCase<void, PendencyReduceLimitPix> {
  constructor(private pendencyLimitReducePixRepository: PendencyLimitReducePixRepository) {}

  execute(): Observable<PendencyReduceLimitPix> {
    return this.pendencyLimitReducePixRepository.getPendencyReducePix();
  }
}
