import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { PendencyReduceLimitPix } from '@src/core/domain/dashboard/dashboard.model';
import { PendencyLimitReducePixRepository } from '@src/core/repositories/dashboard/pendency-limit-reduce-pix.repository';

@Injectable({
  providedIn: 'root',
})
export class GetPendencyLimitReducePixRepository extends PendencyLimitReducePixRepository {

  constructor(private http: HttpClient) {
    super();
  }

  public getPendencyReducePix(): Observable<PendencyReduceLimitPix> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<PendencyReduceLimitPix>(`${environment.URLBase}v1/limitetransacional/dashboard-alteracao-limite-empresa`, httpOptions);
  }

}
