import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';

import { handleError } from '@src/shared/util-common';
import { ChatTokenResponse } from './chat.entity';

@Injectable({
  providedIn: 'root',
})
export class ChatRepository {

  constructor(private http: HttpClient) {}

  getToken(): Observable<ChatTokenResponse> {
        const httpOptions = {
          headers: getHeader(),
        };
          try {
            return this.http
            .get<ChatTokenResponse>(
              `${environment.URLBase}v1/chat/token`,
              httpOptions
            )
         }
         catch(error) {
             handleError(error);
         }

  }

}
