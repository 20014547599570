import {
  ApprovalMatrixModel,
  PeopleRequest,
  RuleDeleteRequest,
  PeopleMatrixRequest,
  RuleEditRequest,
  ApproveRuleRequest,
  NewRuleMatrixRequest
} from '@src/core/domain/approval-matrix/approval-matrix.model';
import { Observable } from 'rxjs';

export abstract class RulesRepository {
  abstract getAllRules(): Observable<ApprovalMatrixModel>;
  abstract getRulesId(params): Observable<ApprovalMatrixModel>;
  abstract setNewRuleMatrix(obj: NewRuleMatrixRequest): Observable<ApprovalMatrixModel>;
  abstract getAllPeople(obj: PeopleRequest): Observable<ApprovalMatrixModel>;
  abstract getAllPeopleApproval(obj: PeopleRequest): Observable<ApprovalMatrixModel>;
  abstract deleteRule(obj: RuleDeleteRequest): Observable<ApprovalMatrixModel>;
  abstract editRule(obj: RuleEditRequest): Observable<ApprovalMatrixModel>;
  abstract getPeople(obj: PeopleMatrixRequest): Observable<ApprovalMatrixModel>;
  abstract postApproveRule(obj: ApproveRuleRequest): Observable<ApprovalMatrixModel>;
}
