import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getHeader } from "@src/core/base/header";
import { DebugDdaModel, DebugDdaResponse } from "./debug-dda.model";
import { environment } from "@src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DebugDdaRepository {

  debugDdaModel : DebugDdaModel;

  private httpOptions = {
    headers: getHeader(),
  };
  constructor(private http: HttpClient) {

  }

  get(): Observable<DebugDdaModel> {
    return this.http
      .get<DebugDdaResponse>(
        `${environment.URLBase}v1/MatrizAcessos/pessoas/perfil-master/empresa-logada`,
        this.httpOptions
      )
      .pipe(
        map(result=>{

          if(result.code == 200){
            return {
              cnpj_logado: result.data.cnpj_logado,
              nu_conta: result.data.nu_conta,
              total_masters: result.data.total_masters,
              masters: result.data.masters
            }
          }
        }),
        catchError((a) =>{
          if(!environment.production){
            console.log('Erro registros para debug');
            console.error(a);
          }
          return of(null);
        })
        );
  }
}
