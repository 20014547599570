import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fibra-admin-user-page',
  templateUrl: './admin-user-page.component.html',
  styleUrls: ['./admin-user-page.component.scss'],
})
export class AdminUserPageComponent implements OnInit {
  params = [];
  constructor() {}

  ngOnInit() {}
}
