import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { OpenBankingPendency } from '@src/core/domain/dashboard/dashboard.model';
import { PendencyRepository } from '@src/core/repositories/dashboard/pendency.repository';

@Injectable({
  providedIn: 'root',
})
export class GetOpenBankingPendenciesUsecase implements UseCase<void, OpenBankingPendency[]> {
  constructor(private pendencyRepository: PendencyRepository) {}

  execute(): Observable<OpenBankingPendency[]> {
    return this.pendencyRepository.getOpenBankingPendencies();
  }
}
