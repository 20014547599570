import { TokenEntity } from '@src/data/repository/token-repository/token.entity';
import { TokenTypeModel } from '@src/core/domain/token/tokenType.model';
import { Mapper } from '@src/core/base/mapper';

export class TokenRepositoryMapper extends Mapper <TokenEntity, TokenTypeModel> {
  mapFrom(param: TokenEntity): TokenTypeModel {
    return {
      code: param.code,
      status: param.status,
      data: {
        sessionToken: param.data.token_sessao,
      }
    };
  }

  mapTo(param: TokenTypeModel): TokenEntity {
    return {
      code: param.code,
      status: param.status,
      data: {
        token_sessao: param.data.sessionToken,
      }
    };
  }
}
