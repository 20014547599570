import {
  BeneficiariesModel,
  BeneficiariesRequest,
} from './../../../domain/transfer/transfer.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { FavoritesRepository } from '@src/core/repositories/transfer/favorites.repository';

@Injectable({
  providedIn: 'root',
})
export class GetFavoritesUsecase implements UseCase<any, BeneficiariesModel> {
  constructor(private favoritesRepository: FavoritesRepository) {}

  execute(params: BeneficiariesRequest): Observable<BeneficiariesModel> {
    return this.favoritesRepository.getFavorites(params);
  }
}
