<div class="row row-cols-2 align-items-center" *fibraHasRole="'MASTER,DDA.DDA_CONS_HIST'">
  <div class="col">
    <fibra-dda-filter-date (change)="setFilter($event)" [eventTabs]="eventTabs"></fibra-dda-filter-date>
  </div>
</div>
<div class="wraper-list-value">
  <header class="wraper-list-header">
    <div class="items">

      <div class="col-item col-1">
        <span>Hora</span>
        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('data_hora', historicos)" />
      </div>
      <div class="col-item ml-2 col-2">
        <span>CNPJ</span>
        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('cnpj', historicos)" />
      </div>
      <div class="col-item col-3">
        <span>Usuário</span>
        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('nome_pessoa', historicos)" />
      </div>
      <div class="col-item col-2">
        <span>Ação realizada</span>
        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('status_dda', historicos)" />
      </div>
      <div class="col-item col-3">
        <span>Descrição</span>
        <img src="/assets/svg/duo_arrow.svg" alt="" (click)="util.sortList('descricao', historicos)" />
      </div>
    </div>
  </header>
  <div class="list-value">
    <div class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)" *ngIf="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="empty-info" *ngIf="historicos.length === 0 && !isLoading">
      <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
        message="Tente fazer uma nova pesquisa inserindo novos parâmetros.">
      </fibra-generic-info>
    </div>

    <div *ngIf="!isLoading">
      <div class="panel-group" id="accordion">
        <div class="list-content" *ngFor="let dt of getDates(); index as y">

          <div class="mb-2 datas">
            <div colspan="11">
              {{dt | date:'d'}} de {{dt | date:'MMMM':'UTC':'pt-br'}} de {{dt | date:'y'}}
            </div>
          </div>

          <div class="panel-list" *ngFor="let item of getTransactionByDate(dt); index as i" [ngClass]="{'azul': verificaSelected(i)}">
            <div class="col-panel col-1 pl-0">
              <span class="title-desc">{{
                item.data_hora | date:'HH:mm'}}</span>
            </div>
            <div class="col-panel col-2">
              <span class="title-desc">{{ item.cnpj | cpfcnpj}}</span>

            </div>
            <div class="col-panel col-3">
              <span class="title-desc">
                {{ item.nome_pessoa }}
              </span>
            </div>
            <div class="col-panel col-2">
              <span class="title-desc">
                {{ item.tipo_validacao }}
              </span>
            </div>
            <div class="col-panel col-3">
              <span class="title-desc">
                {{ item?.descricao }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>