import {
  ApprovalRequest,
  TransferApprovalModel,
} from './../../domain/transfer-approval/approval.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';

import { Observable } from 'rxjs';
import { TransferApprovalRepository } from '@src/core/repositories/transfer-approval/approval.repository';

@Injectable({
  providedIn: 'root',
})
export class GetApprovalListUsecase
  implements UseCase<any, TransferApprovalModel> {
  constructor(private approvaRepository: TransferApprovalRepository) {}

  execute(params?: ApprovalRequest): Observable<TransferApprovalModel> {
    return this.approvaRepository.getList(params);
  }
}
