import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const isAllow: string = localStorage.getItem('loggedUser');
      if (isAllow === 'true') {
            return true;
        }
      this.router.navigate(['/']);
      return false;
    }
}
