import { Component, EventEmitter, OnInit, OnDestroy, Output, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterComponent } from '@src/presentation/web/components/filter/filter.component';
import * as Util from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

import { PixRefundDataShare } from '@src/core/usecases/data-share/pix-refund-data-share.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { GetRefundList } from '@src/core/usecases/pix/refund.usecase';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';


@Component({
  selector: 'fibra-pix-refund',
  templateUrl: './pix-refund.component.html',
  styleUrls: ['./pix-refund.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)', opacity: 0 }),
            animate('400ms', style({ transform: 'translateX(0)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [

            style({ transform: 'translateX(0)', opacity: 1 }),
            animate('400ms', style({ transform: 'translateX(100%)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class PixRefundComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() controlShowHideComponent: EventEmitter<boolean> = new EventEmitter();
  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(FilterComponent, null) filterComp: FilterComponent;
  @ViewChild('anchor', { static: true }) anchor: ElementRef<HTMLElement>;

  private observer: IntersectionObserver;
  private _subUnsub: Subscription;
  private _subUnsubNinityDay: Subscription;
  public listPixRefund = [];

  util = Util;
  typToastColor;
  approvalTransaction = true
  isSchedules = true;
  verifySchedule = [];
  count;
  loading: boolean = false;
  hide: boolean = false;

  listArray: any = [];
  sum = 10;
  arrayWidth: number;
  loadDataGrid: boolean = false;
  noDataShow: boolean = false;
  accountRefundPixSelected: any = [];
  useFilter: boolean = false;
  messageNinetyDay: boolean = false;

  constructor(
    private pixRefundData: PixRefundDataShare,
    private host: ElementRef,
    private listRefund: GetRefundList,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.requestAccount();
    this.controlScroll();
  }

  ngAfterViewInit() {
    this.filterComp.initAll();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('tab');

    if (this._subUnsub) {
      this._subUnsub.unsubscribe();
    }

    if (this._subUnsubNinityDay) {
      this._subUnsubNinityDay.unsubscribe();
    }

    this.observer.disconnect();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  verifyNinityDays(){
    let date = new Date();
  
    const initialParam = {
      dt_inicial: new Date(date.setDate(date.getDate() - 90)).toISOString(),
      dt_final: new Date().toISOString(),
      num_conta: Number(localStorage.getItem('refund-account-selected'))
    };

    if(this._subUnsubNinityDay){
      this._subUnsubNinityDay.unsubscribe();
    }

    this._subUnsubNinityDay = this.listRefund.execute(initialParam).subscribe(async rs => {
      console.log(rs)
    }, err => {
      if(err && err.error.data.length === 0){
        this.messageNinetyDay = true;
      }
    })
  }

  nextScreenRefund(val) {
    const dataPixRefund = {
      dataRefund: new Array(val),
      accountRefund: this.accountRefundPixSelected
    }
    this.pixRefundData.setValue(dataPixRefund);
    this.controlShowHideComponent.emit(false);
  }

  htmlShowCpf(val) {
    let format = Util.formatCpfCnpj(val);
    return Util.protectShowCpf(format);
  }

  element() {
    return this.host.nativeElement;
  }

  isHostScrollable() {
    const styles = window.getComputedStyle(this.element());
    return styles.getPropertyValue('overflow') === 'auto' || styles.getPropertyValue('overflow-y') === 'scroll';
  }

  controlScroll() {
    // USADO PARA CONTROLAR O ELEMENTO ANCONRA DO INFINITY SCROLL
    const options = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      let sd = 1;
      sd += entry.intersectionRatio > 0 ? entry.intersectionRatio : 0;
      if (sd > 1) {
        this.onScrollDown();
      }
    }, options);
    this.observer.observe(this.anchor.nativeElement);
  }

  setFilter(event) {
       
    const useCalendar = event.periodCalendar ? true : false;
    const useFilter = typeof event.typeEvent !== 'undefined' && event.typeEvent.type === 'click' ? true : false; 

    if(useCalendar || useFilter) this.useFilter = true
    
    this.listArray = [];
    this.listPixRefund = [];
    this.hide = false;
    this.loading = true;
    const accountSelected = Number(localStorage.getItem('refund-account-selected'));

    const param = {
      dt_inicial: new Date(event.dt_Inicio).toISOString(),
      dt_final: new Date(event.dt_Final).toISOString(),
      num_conta: accountSelected
    };

    if (this._subUnsub) {
      this._subUnsub.unsubscribe();
    }

    this.verifyNinityDays();

    this._subUnsub = this.listRefund.execute(param).subscribe(async rs => {
      this.listPixRefund = await rs.data.sort(function (a, b) {
        return Number(new Date(b.dataPagamento)) - Number(new Date(a.dataPagamento));
      });

      this.loading = false;
      this.hide = false
      this.setDataOnfilter();
    }, err => {
      this.listPixRefund = [];
      this.loading = false;
      this.hide = true
    })
  }

  // Seta dados nas variavéis quando for feito o scroll
  onScrollDown() {

    // Não exibir menssagem se array de dados menor que 8 lenght
    if (this.listArray.length < 8) {
      this.noDataShow = false;
    }

    if (this.listPixRefund.length === 0) {
      this.loadDataGrid = false;
      this.noDataShow = false;
      this.loading = false;
      return false;
    }

    this.loadDataGrid = true;
    if (this.listArray.length >= this.listPixRefund.length) {
      this.loadDataGrid = false;
      this.noDataShow = true;
      this.loading = false;
      return false;
    }

    setTimeout(() => {
      this.sum += 10;
      this.addItems();
    }, 1000);

  }

  // Seta dados nas variavéis quando for clicado nos filtros
  setDataOnfilter() {
    // Não exibir menssagem se array de dados menor que 8 lenght
    if (this.listArray.length < 8) {
      this.noDataShow = false;
    }

    if (this.listPixRefund.length === 0) {
      this.loadDataGrid = false;
      this.noDataShow = false;
      this.loading = false;
      return false;
    }

    this.loadDataGrid = true;
    if (this.listArray.length >= this.listPixRefund.length) {
      this.loadDataGrid = false;
      this.noDataShow = true;
      this.loading = false;
      return false;
    }

    setTimeout(() => {
      this.sum = 10;
      this.addItems();
    }, 1000);
  }

  addItems() {
    this.listArray = []
    const newListItens = [];

    for (let i = 0; i < this.sum; ++i) {
      newListItens.push(this.listPixRefund[i]);
    }

    this.listArray = [...this.listArray, ...newListItens];

    if (this.listArray.length > this.listPixRefund.length) {
      this.listArray.length = this.listPixRefund.length;
    }

    this.loadDataGrid = false;
    this.loading = false;
  }

  verifyCalendar(event) {
    if (event === 'isGreaterThan730Days') {
      this.typToastColor = '#FF0000';
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 730 dias.',
        null,
        null
      );
      return;
    }

    if (event === 'outOfReach') {
      this.typToastColor = '#FF0000';
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 180 dias.',
        null,
        null
      );
    }
  }

  requestAccount(): void {
    this.loading = true;

    this.getAllAccountTypes
      .execute(null)
      .subscribe(this.requestSuccess, this.requestError);
  }

  requestSuccess = (value) => {
    const accountSelected = localStorage.getItem('refund-account-selected');
    this.accountRefundPixSelected = value.data.filter(r => r.num_conta === accountSelected) || [];
  }

  requestError = (err) => {
    console.log(err)
  };

  returnAccountFormat(account) {
    return this.util.formatValueAccount(account);
  }

  returnAgencyFormat(agency) {
    return this.util.returnAgency(agency);
  }

}
