import { Mapper } from "@src/core/base/mapper";
import { DddaApproversMastersModel } from "@src/core/domain/dda/dda-approvers.model";
import { DdaApproversMasterEntity } from "../entity/term/dda-approvers.entity";

export class DdaApproversMasterMapper
  implements Mapper<Array<DddaApproversMastersModel>, DdaApproversMasterEntity>
{
  mapFrom(param: Array<DddaApproversMastersModel>): DdaApproversMasterEntity {
    return {
      code: null,
      status: null,
      data: null,
    };
  }
  mapTo(param: DdaApproversMasterEntity): Array<DddaApproversMastersModel> {
    return param.data.map((item) => {
      return {
        dt_criacao: item.dt_criacao,
        dt_aprovacao: item.dt_aprovacao,
        termo_aceite_id: item.termo_aceite_id,
        pessoa_id: item.pessoa_id,
        status: item.status,
      };
    });
  }
}
