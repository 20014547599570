import { Component, OnInit } from '@angular/core';
import * as Util from '@src/shared/util-common';


@Component({
  selector: 'fibra-download-check-company',
  templateUrl: './download-check-company.component.html',
  styleUrls: ['./download-check-company.component.scss'],
})
export class DownloadCheckCompanyComponent implements OnInit {
  removeMask = (value) => Util.removeMask(value);
  public generatePDF = (value?) => Util.generatePDF(value);
  public data;
  public approvalList;
  public accountType;
  public tabsType;
  public date;
  public hour;
  public diffDateDays;
  public loansList =[];
  public dailyPosition = false;
  public contracts = false;
  public companyCheck = false;

  constructor() {}

  ngOnInit() {
    
    this.data = JSON.parse(localStorage.getItem('check-company-export-data'));
    this.loansList = this.data.data;        
  }
  
  ngAfterViewInit() {
    this.generatePDF('body-content');
    localStorage.removeItem('check-company-export-data');
  }  
}
