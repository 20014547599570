import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fibra-pix-favorite-beneficiaries',
  templateUrl: './pix-favorite-beneficiaries.component.html',
  styleUrls: ['./pix-favorite-beneficiaries.component.scss']
})
export class PixFavoriteBeneficiariesComponent implements OnInit {
  @Output() changeComponent = new EventEmitter()
  public search;

  constructor() { }

  ngOnInit() {
  }

  public goToNewTransfer() {
    //this.changeComponent.emit('new-transfer')
    localStorage.setItem('view-selecionada', 'transferir-newtransfer');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public goBack() {
    localStorage.setItem('view-selecionada', 'transferir-transferir');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

}
