import { Observable } from 'rxjs';
import {
  ArchiveModel,
  ArchiveRequest,
  ListArchivesModel,
  ListArchivesRequest,
  ArchiveListDownloadRequest,
} from '@src/core/domain/charge/archive.model';

export abstract class ArchiveModelRepository {
  abstract getDownloadArchiveList(
    obj: ArchiveListDownloadRequest
  ): Observable<ArchiveModel>;

  abstract getUrlAssigned(obj: ArchiveRequest): Observable<ArchiveModel>;

  abstract getAmazonArchiveDownload(
    obj: ArchiveRequest
  ): Observable<ArchiveModel>;

  abstract getDownloadArchive(obj: ArchiveRequest): Observable<ArchiveModel>;

  abstract getArchiveList(
    obj: ListArchivesRequest
  ): Observable<ListArchivesModel>;

  abstract getArchiveListReturn(
    obj: ListArchivesRequest
  ): Observable<ListArchivesModel>;

  abstract getArchiveReturnDownload(obj: any): Observable<ListArchivesModel>;

  abstract putArchiveUpload(obj: ArchiveRequest): Observable<ArchiveModel>;
}
