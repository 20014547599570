<div *fibraHasRole="'MASTER'" class="card mt-3 noborder">
    <div class="card-body">
      <fibra-disclaimer-cancel></fibra-disclaimer-cancel>
  
      <div class="row mt-5 justify-content-between">
        <div class="col-8">
          <div class="check-div">
            <input class="checkbox" id="agree" type="checkbox" 
              (click)="enable()"
            /><label
              class="check-font"
              for="agree"
              >Li e concordo com o termo de cancelameto do débito autorizado.
            </label>
          </div>
        </div>
        <div class="col-2 text-center">
          <button class="leaked-button" (click)="redirect()" type="button">Voltar</button>
        </div>
        <div class="col-2 text-center">
          <button class="button-blue" type="button" (click)="goStep()" [disabled]="!enableAction">Cancelar Adesão</button>
        </div>
      </div>
    </div>
  </div>
  
  <fibra-modal-validation
    *ngIf="exibirAutorizacao"
    [apiCallback]="apiCallback"
    (closeEvent)="fecharModal()"
    [apiPayload]="apiPayload"
    (responseValidation)="respostaApi($event)"
  >
  </fibra-modal-validation>
  <fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
  <fibra-modal-conclusao-cancelamento
    *ngIf="exibirConclusao"
    [recusar]="false"
    [totalMasters]="totalMasters"
    [totalFiliais]="0"
    (closeEvent)="fecharModalConclusao()"
  >
  </fibra-modal-conclusao-cancelamento>
  
