import { Mapper } from '@src/core/base/mapper';
import { ApprovalMatrixModel } from '@src/core/domain/approval-matrix/approval-matrix.model';
import { ApprovalMatrixEntity } from './approval-matrix.entity';

export class ApprovalMatrixRepositoryMapper extends Mapper<
ApprovalMatrixEntity,
ApprovalMatrixModel
> {
  mapFrom(param: ApprovalMatrixEntity): ApprovalMatrixModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: ApprovalMatrixModel): ApprovalMatrixEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
