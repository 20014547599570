import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { flatMap, map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';
import {AccessRepository} from '@src/core/repositories/access/access.repository';
import {
  AccessRepositoryMapper,
  FuncionalitiesAccessRepositoryMapper
} from '@src/data/repository/access-repository/access-repository.mapper';
import { getHeader } from '@src/core/base/header';
import {AccessTypeModel, FuncionalitiesUserAccessTypeModel} from '@src/core/domain/access/accessType.model';
import {AccessEntity, FuncionalitiesUserAccessEntity} from '@src/data/repository/access-repository/access.entity';
import {AccountTypeEntity} from '@src/data/repository/account/accountType.entity';

@Injectable({
  providedIn: 'root',
})
export class AccessDataRepository extends AccessRepository {
  mapper = new AccessRepositoryMapper();
  funcionalitiesMapper = new FuncionalitiesAccessRepositoryMapper()

  constructor(private http: HttpClient) {
    super();
  }

  public getAccess(): Observable<AccessTypeModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<AccessEntity>(
            `${environment.URLBase}v1/usuarios/acessomaster`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.mapper.mapFrom));
  }

  public getFuncionalitiesAccess(): Observable<FuncionalitiesUserAccessTypeModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
        .get<FuncionalitiesUserAccessEntity>(
            `${environment.URLBase}v1/usuarios/acessos`,
            httpOptions
        )
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.funcionalitiesMapper.mapFrom));
  }
}
