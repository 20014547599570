import { Injectable } from '@angular/core';
import { PaymentRepository } from '@src/core/repositories/payment/payment.repository';
import { UseCase } from '@src/core/base/use-case';
import { PaymentModel, PaymentDeleteRequest } from '@src/core/domain/payment/payment.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteBoletosUsecases implements UseCase<PaymentDeleteRequest, PaymentModel> {

  constructor(private paymentRepository: PaymentRepository) { }

  execute(params: PaymentDeleteRequest): Observable<PaymentModel> {
    return this.paymentRepository.deleteBoletos(params);
  }  
}
