import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { RolesService } from '../services/roles.service';

export enum PermissionValidationType {
  hasAny,
  hasAll
}

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private router: Router, private rolesService: RolesService) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const roles = this.rolesService.get();

    if (roles.indexOf('SEM_CONTA') > -1 && next.data.permissions && next.data.permissions.indexOf('!SEM_CONTA') > -1) {
      this.router.navigate(['/access-denied-no-account']);
      return false;
    }

    let allow: boolean;

    if (next.data.advancedPermissions) {
      allow = this.validateAdvancedPermissions(next.data.advancedPermissions);
    } else {
      let permissions = next.data.permissions;

      if(!Array.isArray(permissions)){
        permissions = [permissions];
      }

      allow = this.hasAnyPermission(permissions);
    }

    if (!allow) {
      this.router.navigate(['/access-denied']);
    }

    return allow;
  }

  private validateAdvancedPermissions(permissions: any[]): boolean {
    return permissions.every(p => {
      if (p.type == PermissionValidationType.hasAny) {
        return this.hasAnyPermission(p.permissions);
      } else {
        return this.hasAllPermissions(p.permissions);
      }
    });
  }

  private hasAnyPermission(permissions: string[]): boolean {
    const roles = this.rolesService.get();
    const allowed = permissions.filter(x => !x.startsWith('!'));
    const prohibited = permissions.filter(x => x.startsWith('!')).map(x => x.slice(1));

    if ((this.containsAny(roles, allowed) || allowed.length === 0 ) && (!this.containsAny(roles, prohibited) || prohibited.length === 0)) {
      return true;
    }

    return false;
  }

  private hasAllPermissions(permissions: any[]): boolean {
    const roles = this.rolesService.get();
    const allowed = permissions.filter(x => !x.startsWith('!'));
    const prohibited = permissions.filter(x => x.startsWith('!')).map(x => x.slice(1));

    if ((!allowed.length || this.containsAll(roles, allowed)) && (!prohibited.length || !this.containsAny(roles, prohibited))) {
      return true;
    }

    return false;
  }

  private containsAny(a: string[], b: string[]): boolean {

    if (!Array.isArray(b)) {
      b = [b];
    }

    return b.some(x => a.includes(x))
  }

  private containsAll(a: string[], b: string[]): boolean {
    return b.every(x => a.includes(x))
  }
}
