import { AfterContentChecked, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ComexReportDataShare } from '@src/core/usecases/data-share/comex-report-data-share.service';
import { AccAceEntity } from '@src/data/repository/export-repository/export.entity';
import { CobrancaEntity, CreditoEntity, FinimpEntity } from '@src/data/repository/import-repository/import.entity';
import { ChangeEntity } from '@src/data/repository/ready-change-repository/ready-change.entity';
import { ReceivedPaymentEntity } from '@src/data/repository/receveid-payment/received-payment.entity';
import { IssuedWarrantyEntity, ReceivedWarrantyEntity } from '@src/data/repository/warranties/warranties.entity';
import html2canvas from 'html2canvas';
import * as $ from 'jquery';
import jsPDF from 'jspdf';
import * as Util from '@src/shared/util-common';
import { ExportDataRepository } from '@src/data/repository/export-repository/export.repository';
import { ImportDataRepository } from '@src/data/repository/import-repository/import.repository';
import { WarrantiesDataRepository } from '@src/data/repository/warranties/warranties.repository';
import { ReadyChangeDataRepository } from '@src/data/repository/ready-change-repository/ready-change.repository';
import { ReceveidPaymentDataRepository } from '@src/data/repository/receveid-payment/received-payment.repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-foreign-trade',
  templateUrl: './foreign-trade.component.html',
  styleUrls: ['./foreign-trade.component.scss'],
})
export class ForeignTradeComponent implements OnInit, AfterContentChecked {

  public ids = ['cambio', 'importacao', 'exportacao', 'op', 'garantias'];
  public cambio = true;
  public importacao = false;
  public exportacao = false;
  public ordemPagamentos = false;
  public report = false;
  public garantias = false;
  public lastFragment = "cambio"
  @Input() public changeArray: ChangeEntity[];
  @Input() public finimpArray: FinimpEntity[];
  @Input() public cobrancaArrayImport: CobrancaEntity[];
  @Input() public creditArrayImport: CreditoEntity[];
  @Input() public accAceArray: AccAceEntity[];
  @Input() public cobrancaArrayExport: CobrancaEntity[];
  @Input() public creditArrayExport: CreditoEntity[];
  @Input() public paymentArray: ReceivedPaymentEntity[];
  @Input() public receivedArray: ReceivedWarrantyEntity[];
  @Input() public issuedArray: IssuedWarrantyEntity[]
  public show = false;
  public typToastColor;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  constructor(
    public comexReportShare?: ComexReportDataShare,
    public comexImportRepository?: ImportDataRepository,
    public comexExportRepository?: ExportDataRepository,
    public comexGarantiaRepository?: WarrantiesDataRepository,
    public comexOrdemPagamentoRepository?: ReceveidPaymentDataRepository,
    public comexCambioProntoRepository?: ReadyChangeDataRepository) {

  }

  ngOnInit() {
    this.changeClasses();
    this.resetArrays();
  }

  ngAfterContentChecked() {
    this.changeClasses();
  }

  public changeNavbarClass(id: string) {
    if (id === 'cambio') {
      $('#cambio-item').removeClass('item-unselected');
      $('#cambio-item').addClass('item-selected');
      $('#cambio-font').removeClass('item-font-unselected');
      $('#cambio-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'cambio') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      });
      this.cambio = true;
      this.lastFragment = "cambio"
      this.importacao = false;
      this.exportacao = false;
      this.ordemPagamentos = false;
      this.garantias = false;
    }
    if (id === 'importacao') {
      $('#importacao-item').removeClass('item-unselected');
      $('#importacao-item').addClass('item-selected');
      $('#importacao-font').removeClass('item-font-unselected');
      $('#importacao-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'importacao') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      });
      this.lastFragment = "importacao"
      this.cambio = false;
      this.importacao = true;
      this.exportacao = false;
      this.ordemPagamentos = false;
      this.garantias = false;
    }
    if (id === 'exportacao') {
      $('#exportacao-item').removeClass('item-unselected');
      $('#exportacao-item').addClass('item-selected');
      $('#exportacao-font').removeClass('item-font-unselected');
      $('#exportacao-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'exportacao') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      });
      this.lastFragment = "exportacao"
      this.cambio = false;
      this.importacao = false;
      this.exportacao = true;
      this.ordemPagamentos = false;
      this.garantias = false;
    }
    if (id === 'op') {
      $('#op-item').removeClass('item-unselected');
      $('#op-item').addClass('item-selected');
      $('#op-font').removeClass('item-font-unselected');
      $('#op-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'op') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      });
      this.cambio = false;
      this.importacao = false;
      this.exportacao = false;
      this.ordemPagamentos = true;
      this.garantias = false;
    }
    if (id === 'garantias') {
      $('#garantias-item').removeClass('item-unselected');
      $('#garantias-item').addClass('item-selected');
      $('#garantias-font').removeClass('item-font-unselected');
      $('#garantias-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'garantias') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      });
      this.lastFragment = "garantias"
      this.cambio = false;
      this.importacao = false;
      this.exportacao = false;
      this.ordemPagamentos = false;
      this.garantias = true;
    }
  }
  
  public setReportData(event) {
    let errorMessage = "Erro ao fazer download.Favor tentar mais tarde."
    if (event == 2) {
      
      switch (localStorage.getItem('view-atual')) {
        case 'exportacao-accace':
          this.comexExportRepository.postExportExcelAccAce().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `exportação-accace`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;
        case 'exportacao-cobranca':
          this.comexExportRepository.postExportExcelCobranca().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `exportação-cobranca`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;
        case 'exportacao-cartacredito':
          this.comexExportRepository.postExportExcelCartaCredito().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `exportação-carta-credito`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;
        //////
        case 'importacao-finimp':
          this.comexImportRepository.postExportExcelImportFinmp().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `importacao-finimp`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'importacao-cartacredito':
          this.comexImportRepository.postExportExcelImportCartaCredito().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `importação-carta-credito`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'importacao-cobranca':
          this.comexImportRepository.postExportExcelImportCobranca().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `importação-cobrança`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        //garantia
        case 'garantias-recebidas':
          this.comexGarantiaRepository.postExportExcelGarantiaRecebida().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `garantias-recebidas`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'garantias-emitidas':
          this.comexGarantiaRepository.postExportExcelGarantiaEmitida().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `garantias-emitidas`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        //cambio pronto

        case 'cambio-pronto':
          this.comexCambioProntoRepository.postExportExcelCambioPronto().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `cambio-pronto`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        //ordem de pgto 
        case 'ordem-pgto':
          this.comexOrdemPagamentoRepository.postExportExcelOrdemRecebida().subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `ordem-pagamento-recebida`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        // case 'ordem-emitida':
        //   this.comexOrdemPagamentoRepository.postExportExcelOrdemEmitida().subscribe((res) => {
        //     Util.consoleDev('res', res)
        //     Util.handleFile(res, `ordem-pagamento-emitida`);
        //   }, error => {
        //     alert(errorMessage)
        //     Util.consoleDev('Erro:', error)
        //   });
        //   break;
      }

      return;
    }

    if (event === 1) {
      switch (localStorage.getItem('view-atual')) {
        case 'cambio-pronto':
          this.changeArray = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'importacao-finimp':
          this.finimpArray = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'importacao-cobranca':
          this.cobrancaArrayImport = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'importacao-cartacredito':
          this.creditArrayImport = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'exportacao-accace':
          this.accAceArray = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'exportacao-cobranca':
          this.cobrancaArrayExport = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'exportacao-cartacredito':
          this.creditArrayExport = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'ordem-pgto':
          this.paymentArray = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'garantias-emitidas':
          this.issuedArray = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'garantias-recebidas':
          this.receivedArray = this.comexReportShare.getValue();
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
          this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
      }
    }
  }

  public changeReportView(event) {
    this.report = !this.report;
    this.resetArrays();
  }

  public changeClasses() {
    if (this.cambio) {
      this.changeNavbarClass('cambio');
    } else if (this.importacao) {
      this.changeNavbarClass('importacao');
    } else if (this.exportacao) {
      this.changeNavbarClass('exportacao');
    } else if (this.ordemPagamentos) {
      this.changeNavbarClass('op');
    } else if (this.garantias) {
      this.changeNavbarClass('garantias');
    }
  }

  public resetArrays() {
    this.changeArray = null;
    this.finimpArray = null;
    this.cobrancaArrayImport = null;
    this.creditArrayImport = null;
    this.accAceArray = null;
    this.cobrancaArrayExport = null;
    this.creditArrayExport = null;
    this.paymentArray = null;
    this.receivedArray = null;
    this.issuedArray = null;

  }
}
