<div class="main-selector">
    <fibra-company-list></fibra-company-list>
    <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>
<div class="content-page">
    <div class="div">
        <fibra-breadcrumb bottom="0" infoAccount="" roule="Registrato"></fibra-breadcrumb>
        <div *ngIf="!pj" class="navbar-teste">
            <div (click)="changeNavbarClass('register')" class="item-selected" id="register-item">
                <span class="item-font-selected" id="register-font"> Registro de frase de segurança </span>
            </div>
            <div (click)="changeNavbarClass('read')" class="item-unselected" id="read-item">
                <span class="item-font-unselected" id="read-font"> Consulta de frase de segurança </span>
            </div>
        </div>
    </div>
    <section class="body-content" id="body">
        <div *ngIf="!pj; else errorPJ">
            <div *ngIf="register">
                <div class="subtitle-registrato">
                    <h3>BACEN - Auto credenciamento registrato - validar
                        <span
                                [appTooltip]="tooltipAll"
                        >
                        <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg"/>
                        <div #tooltipAll class="tooltip">
                        <p style="width:446px">
                            <strong>Importante:</strong> para disponibilizar dados do seu relacionamento com o banco para a CSS - Cadastro de Clientes do Sistema Financeiro Nacional,
                            digite a frase de segurança o campo solicitado. Para gerar a frase de segurança, primeiro, você precisará acessar o site http://www.bcb.gov.br,
                            informar seu CPF e em "Instituição Financeira", digite Banco Fibra.<strong> A frase gerada será válida por 48h.</strong>
                        </p>
                        <div class="arrow"></div>
                        </div>
                    </span>
                    </h3>
                </div>
                <ng-template #firstStep>
                    <div class="subtitle-form">
                        <h1>Validar frase de segurança</h1>
                    </div>
                    <div class="form-row personal-information">
                        <form (ngSubmit)="submitFormStep1()" [formGroup]="formStep1" novalidate>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="">Banco</label>
                                    <input class="form-control form-control-plaintext" readonly type="text"
                                           value="Banco Fibra SA">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Nome</label>
                                    <input class="form-control form-control-plaintext" readonly type="text"
                                           value="{{pessoa.name}}">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">CPF</label>
                                    <input class="form-control form-control-plaintext" readonly type="text"
                                           value="{{pessoa.cpfCnpj | cpfcnpj}}">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-8">
                                    <label for="">Frase de segurança</label>
                                    <div>
                                        <input [class.is-invalid]="!campoFraseDeSeguranca.valid && campoFraseDeSeguranca.touched"
                                               class="form-control col-md-8 frase" formControlName="fraseDeSeguranca"
                                               type="text">

                                        <span
                                                [appTooltip]="tooltipFrase"
                                        >
                                        <img alt="logo" src="/assets/svg/tooltip-alert-orange.svg"/>
                                        <div #tooltipFrase class="tooltip">
                                        <p>
                                            Copie a frase da página do Banco Central e cole no local reservado para evitar erros na digitação.
                                        </p>
                                        <div class="arrow"></div>
                                        </div>
                                    </span>
                                        <div *ngIf="!campoFraseDeSeguranca.valid && campoFraseDeSeguranca.touched"
                                             class="invalid-feedback">
                                            Por favor, preencha o campo frase de segurança.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>Suas informações ficarão sob tutela do Bacen.</p>
                            <div class="col-12 buttons" toastContainer>
                                <div class="step-buttons-container offset-10">
                                    <button [disabled]="formStep1.invalid" class="btn btn-primary secondary-button next btn-registrato"
                                            type="submit">
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
                <ng-template #secondStep>
                    <div class="subtitle-form">
                        <h1>Validar frase de segurança</h1>
                    </div>
                    <div class="form-row personal-information">
                        <form novalidate>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="">Banco</label>
                                    <input class="form-control form-control-plaintext" readonly type="text"
                                           value="Banco Fibra SA">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Nome</label>
                                    <input class="form-control form-control-plaintext" readonly type="text"
                                           value="{{pessoa.name}}">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">CPF</label>
                                    <input class="form-control form-control-plaintext" readonly type="text"
                                           value="{{pessoa.cpfCnpj | cpfcnpj}}">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-8">
                                    <label for="">Frase de segurança</label>
                                    <div>
                                        <input class="form-control form-control-plaintext" readonly type="text"
                                               value="{{campoFraseDeSeguranca.value}}">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 buttons">
                                <div class="step-buttons-container offset-10">
                                    <button class="btn btn-primary secondary-button btn-registrato" type="submit">
                                        <img src="/assets/svg/download-accent-white.svg">
                                        Fazer download
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
                <fibra-steps
                        [currentStep]="currentStep"
                        [firstStep]="firstStep"
                        [secondStep]="secondStep"
                        [stepObj]="step">
                </fibra-steps>
                <fibra-modal-registrato-error
                    #content
                    (closeEvent)="closeModalRegistratoError($event)"
                    *ngIf="showModalRegistratoError"
                >
                </fibra-modal-registrato-error>
                <fibra-modal-validation
                    #content
                    (responseValidation)="responseApi($event)"
                    *ngIf="showModalValidation"
                    [apiCallback]="apiCallback"
                    [apiPayload]="campoFraseDeSeguranca.value"
                    (closeEvent)="closeModal($event)"
                >
                </fibra-modal-validation>
            </div>
            <div *ngIf="read">
                <div class="subtitle-registrato">
                    <h3>BACEN - Auto credenciamento registrato - consulta</h3>
                </div>
                <div class="fibra-card-registrato">
                    <div class="fibra-content-registrato">
                        <div class="subtitle-form">
                            <h1>Dados do Usuário</h1>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4">
                                <label for="">Banco</label>
                                <input class="form-control form-control-plaintext" readonly type="text"
                                       value="Banco Fibra SA">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="">Nome</label>
                                <input class="form-control form-control-plaintext" readonly type="text"
                                       value="{{pessoa.name}}">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="">CPF</label>
                                <input class="form-control form-control-plaintext" readonly type="text"
                                       value="{{pessoa.cpfCnpj | cpfcnpj}}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-8">
                                <label for="">Frase de segurança</label>
                                <div>
                                    <input class="form-control form-control-plaintext" readonly type="text"
                                           value="{{registrato.frase}}">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-8">
                                <label for="">Status</label>
                                <div>
                                    <input class="form-control form-control-plaintext" [ngClass]="getColor(registrato.cod_status)" readonly type="text"

                                           value="{{getStatus(registrato.cod_status)}}">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 buttons">
                            <div class="step-buttons-container offset-10">
                                <button class="btn btn-primary secondary-button btn-registrato" type="submit">
                                    <img src="/assets/svg/download-accent-white.svg">
                                    Fazer download
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #errorPJ>
            <div class="fibra-card-registrato error-pj">
                <div class="fibra-content-registrato">
                    <div class="msg-error">
                    <span class="item-icon warning-icon">
                      <img alt="download-accent"
                           height="80"
                           src="/assets/svg/warning-yellow.svg"
                           style="padding-right: 10px"
                      />
                    </span>
                        <p>Funcionalidade disponível somente para Pessoa Física</p>
                    </div>
                </div>
            </div>
        </ng-template>
    </section>
</div>
