import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fibra-form-control-group',
  templateUrl: './form-control-group.component.html',
  styleUrls: ['./form-control-group.component.scss']
})
export class FormControlGroupComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}


