import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { PixLimitesResponse } from './pix-limites.entity';

@Injectable({
  providedIn: 'root',
})
export class PixGetLimitesDataRepository {

  constructor(private http: HttpClient) { }

  public getPixLimites(): Observable<PixLimitesResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<PixLimitesResponse>(`${environment.URLBase}v1/pix/limites/contas/`, httpOptions);
  }

  public getPixLimitesPendenteAprovacao(): Observable<PixLimitesResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<PixLimitesResponse>(`${environment.URLBase}v1/pix/limites/pendenteaprovacao/`, httpOptions);
  }

  public cancelLimite(id:any): Observable<PixLimitesResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.post<PixLimitesResponse>(`${environment.URLBase}v1/pix/limite/cancelar/${id}`, {}, httpOptions);
  }  
}
