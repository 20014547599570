<div class="arrow" (click)="navigateBack()">
  <img src="/assets/svg/seta-left.svg" alt="" />
  <label>Voltar</label>
</div>

<div class="title mb-3">
  <label>Informações do título</label>
</div>

<fibra-tabs-list [options]="optionsTab" (itemTab)="selectTab($event.idOption)"></fibra-tabs-list>
<div class="list-value">
  <!-- ----------1.0  Dados sacado ------------>
  <div class="dados_sacado container-categoria" *ngIf="info === 'sacado' || info === 'tudo'">
    <div class="titulo">
      <div>Dados do pagador</div>
    </div>
    <div class="dados-item">
      <section class="nome-dados">
        <label>Nome do pagador</label>
        <span class="data-hj-suppress">{{titleInfoList.nm_sacado || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Tipo de pessoa</label>
        <span>{{titleInfoList.tp_pessoa_sacado === 'J' ? 'Jurídica' : 'Física'}}</span>
      </section>

      <section class="nome-dados">
        <label>CNPJ / CPF</label>
        <span>{{cpfCnpjMask(titleInfoList.nr_cpf_cnpj_sacado) || ''}}</span>
      </section>
    </div>

    <div class="divisor"></div>
    <!-- ---------- 1.1 endereço ------------>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Endereço</label>
        <span>{{titleInfoList.ds_endereco_sacado || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Cidade</label>
        <span>{{titleInfoList.ds_cidade_sacado || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>UF</label>
        <span>{{titleInfoList.cs_estado_sacado || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>CEP</label>
        <span>{{cepMask(titleInfoList.ds_cep_sacado) || ''}}</span>
      </section>
    </div>
  </div>

  <!-- ---------- 2.0 Dados do titulo ------------>

  <div class="dados_titulo container-categoria" *ngIf="info === 'titulo' || info === 'tudo'">
    <div class="titulo">
      <div>Dados do título</div>
    </div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Seu Número</label>
        <span>{{titleInfoList.nr_seu_numero || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Vencimento</label>
        <span>{{titleInfoList.dt_vencimento | date: 'dd/MM/yyyy' || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Espécie</label>
        <span>{{titleInfoList.cd_especia_produto || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Banco cobrança</label>
        <span>{{titleInfoList.codigo_banco || ''}}</span>
      </section>
    </div>

    <!-- <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Nosso Número</label>
        <span>{{titleInfoList.nossoNumero || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Moeda</label>
        <span>{{titleInfoList.moeda || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Emissão</label>
        <span>{{titleInfoList.dataEmissao || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Aceite</label>
        <span>{{titleInfoList.aceite || ''}}</span>
      </section>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Tipo de cobrança</label>
        <span>{{'??'}}</span>
      </section>

      <section class="nome-dados">
        <label>Data liq/Baixa</label>
        <span>{{titleInfoList.dataLiquidacaoBaixa || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Situação</label>
        <span>{{titleInfoList.descricaoSituacao || ''}}</span>
        
      </section>

      <section class="nome-dados">
        <label>Valor do titulo</label>
        <span>{{titleInfoList.valor || ''}}</span>
      </section>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Valor creditado</label>
        <span>{{titleInfoList.valorCreditado || ''}}</span>
        
      </section>

      <section class="nome-dados">
        <label>Creditado em juros dia</label>
        <span>{{titleInfoList.valorJurosPorDia || ''}}</span>
        
      </section>

      <section class="nome-dados">
        <label>Valor de abatimento do título</label>
        <span>{{titleInfoList.valorAbatimento || ''}}</span>
         
      </section>

      <section class="nome-dados">
        <label>Desconto</label>
        <span>{{titleInfoList.valorDesconto || ''}}</span>
        
      </section>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Juos/multa</label>
        <span>{{titleInfoList.valorJuros || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Tarifas</label>
        <span>{{titleInfoList.tarifa || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Agendamento de cartório para protesto</label>
        <span>{{titleInfoList.qtdeDiasAgendamentoCartorioParaProtesto || ''}}</span>
      </section>
    </div> -->
  </div>
</div>

<div class="d-flex justify-content-center position" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>