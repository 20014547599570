import { BeneficiariesModel } from '@src/core/domain/transfer/transfer.model';
import { Mapper } from '@src/core/base/mapper';
import { PurposesEntity } from './purposes.entity';

export class PurposesRepositoryMapper extends Mapper<
  BeneficiariesModel,
  PurposesEntity
> {
  mapFrom(param: PurposesEntity): BeneficiariesModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: BeneficiariesModel): PurposesEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
