<div class="content-page">
  <fibra-breadcrumb bottom="0" infoAccount="" roule="Simulação" arrow="false" downloadDoc="">
  </fibra-breadcrumb>

  <div class="subtitle-impersonate">Dados para simulação de perfil</div>

  <div class="search-bar">
    <label class="label-title">Empresa</label>
    <input type="text" placeholder="Digite ID da empresa ou CNPJ/CPF" class="input" name="companyControl"
      id="companyControl" maxlength="19" mask="000.000.000-00 || 00.000.000/0000-00 || 0000000000" #companyInput (keyup)="validValue($event)">
    <div class="button-receipt ml40">
      <button type="button" class="mr24 btn btn-outline-primary" (click)="clear()">
        Limpar
      </button>
      <button type="button" [disabled]="!novaluecompany" class="button-primary" (click)="searchCompany(companyInput.value)">
        Buscar
      </button>
    </div>
  </div>


  <div class="page-content">

    <!--LOADING-->
    <div class="datatable warning-display" *ngIf="loadingCompany">
      <div class="d-flex justify-content-center position loading-container">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>



    <div *ngIf="!loadingCompany && hasCompany" class="company-detail">
      <div class="row">
        <span class="page-subtitle">Dados da empresa</span>
      </div>
      <div class="row">
        <div class="col">
          <p class="item-label">CPF/CNPJ</p>
          <p class="item-value">{{company.num_cnpj_cpf_cli | cpfcnpj}}</p>
        </div>
        <div class="col">
          <p class="item-label">Razão Social</p>
          <span class="item-value">{{company.nome_cli_cad_int}}</span>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="item-label">Endereço</p>
          <p class="item-value">{{address}}</p>
        </div>
        <div class="col">
          <p class="item-label">Telefone</p>
          <span class="item-value">{{phone}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="item-label">E-mail</p>
          <p class="item-value">{{company.email}}</p>
        </div>
      </div>
      <!--<div class="row">
        <div class="col">
          <div class="custom-control custom-switch">
            <input class="custom-control-input" type="checkbox" id="flexSwitchCheckCheckedDisabled"
              [checked]="company.ind_restricao_credito" disabled>
            <label class="custom-control-label item-value" for="flexSwitchCheckCheckedDisabled">Restrição de
              crédito</label>
          </div>
        </div>
      </div>-->
    </div>

    <!--ERRO DA EMPRESA-->
    <div class="datatable warning-display" *ngIf="!loadingCompany && !hasCompany && !isClearCompany && !isCPF && !isID">
      <div class="empty-info">
        <fibra-generic-info type="error" title="CNPJ não cadastrado!"
          message="Verifique os dados inseridos e tente novamente.">
        </fibra-generic-info>
      </div>
    </div>

    <div class="datatable warning-display" *ngIf="!loadingCompany && !hasCompany && !isClearCompany && isCPF && !isID">
      <div class="empty-info">
        <fibra-generic-info type="error" title="CPF não cadastrado!"
          message="Verifique os dados inseridos e tente novamente.">
        </fibra-generic-info>
      </div>
    </div>

    <div class="datatable warning-display" *ngIf="!loadingCompany && !hasCompany && !isClearCompany && !isCPF && isID">
      <div class="empty-info">
        <fibra-generic-info type="error" title="ID não cadastrado!"
          message="Verifique os dados inseridos e tente novamente.">
        </fibra-generic-info>
      </div>
    </div>

    <div *ngIf="!loadingUsers && hasUser" class="users-result">

      <!--<select [(ngModel)]="selectedUser" class="custom-select" size="5">
      <option *ngFor="let user of users" [value]="user.cod_pessoa">
        <div>
          <div class="row">{{user.nome_pessoa}}</div>
          <div class="row">{{user.email}}</div>
          <div class="row">{{user.celular}}</div>
        </div>
      </option>
    </select>-->
      <div class="row">
        <span class="page-subtitle">Selecione um usuário:</span>
      </div>

      <div class="list-group">
        <button *ngFor="let user of users" type="button" class="list-group-item list-group-item-action"
          (click)="selectUser(user.cod_pessoa)" [ngClass]="{'active': user.cod_pessoa == selectedUser}">
          <div class="item-title">{{user.tipo_acesso}} - {{user.nome_pessoa}}</div>
          <div class="item-label">E-mail</div>
          <div class="item-value">{{user.email}}</div>
          <div class="item-label">Telefone</div>
          <div class="item-value">{{user.celular}}</div>
        </button>

      </div>

      <div class="dropdown-menu">
        <a *ngFor="let user of users" class="dropdown-item" href="#">
          {{user.nome_pessoa}}
          <!--<div class="">{{user.nome_pessoa}}</div>
        <div class="">{{user.email}}</div>
        <div class="">{{user.celular}}</div>-->
        </a>
      </div>

      <div class="button-users row">
        <button type="button" class="button-primary" (click)="clear()">
          Limpar
        </button>

        <button type="button" class="button-primary" (click)="impersonate()" [disabled]="!noselecteduser">
          Simular
        </button>

      </div>

      

    </div>

    <div class="datatable warning-display" *ngIf="startingImpersonate">
      <div class="d-flex justify-content-center position loading-container">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    
    <div class="datatable warning-display" *ngIf="!loadingUsers && !hasUser && !isClearUser">
      <div class="empty-info">
        <fibra-generic-info type="error" title="Empresa não possui usuários ativos!"
          message="Verifique os dados inseridos e tente novamente.">
        </fibra-generic-info>
      </div>
    </div>

    <div class="datatable warning-display" *ngIf="loadingUsers">
      <div class="d-flex justify-content-center position loading-container">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

  </div>

</div>