import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fibra-summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss'],
})
export class SummaryItemComponent implements OnInit {
  @Input() public label: string;
  @Input() public value: string;
  @Input() public fullValue?: boolean;
  constructor() { }

  ngOnInit() { }

  checkValue(value: any) {
    let newValue = value;
    if (value.length > 15 && !this.fullValue) {
      newValue = value.slice(0, 15) + '...';
    }
    return newValue;
  }
}


