import { Injectable } from '@angular/core';
import { CompanyTypeRepository } from '@src/core/repositories/company/company-type.repository';
import {
  CompanyTypeModel,
  CompanyRequest,
  CompanyDefaultModel,
} from '@src/core/domain/company/companyType.model';
import { Observable } from 'rxjs';
import { CompanyTypeRepositoryMapper } from '@src/data/repository/company/companyType-repository.mapper';
import { CompanyDefaultRepositoryMapper } from '@src/data/repository/company/companyDefault-repository.mapper';
import { HttpClient } from '@angular/common/http';
import {
  CompanyTypeEntity,
  CompanyDefaultType,
} from '@src/data/repository/company/companyType.entity';
import { flatMap, map } from 'rxjs/operators';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { RolesService } from '@src/shared/services/roles.service';


@Injectable({
  providedIn: 'root',
})
export class CompanyTypeDataRepository extends CompanyTypeRepository {
  private mapper = new CompanyTypeRepositoryMapper();
  private mapperDefault = new CompanyDefaultRepositoryMapper();

  constructor(private http: HttpClient, private rolesService: RolesService) {
    super();
  }

  getCompanyTypes(): Observable<CompanyTypeModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<CompanyTypeEntity>(
        `${environment.URLBase}v1/usuarios/empresas`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  setCompanyIndex(params: CompanyRequest): Observable<CompanyTypeModel> {
    let url: string;
    const httpOptions = {
      headers: getHeader(),
    };

    if (!params.persistir) {
      url = `${environment.URLBase}v1/usuarios/empresas/${params.index}/true`;
    } else {
      url = `${environment.URLBase}v1/matrizacessos/empresas/${params.index}/true`;
    }
    return this.http
      .get<CompanyTypeEntity>(url, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom))
      .pipe(map((val) => {
        this.rolesService.setSession(val.data.token_sessao);
        return val;
      }));
  }

  getCompanyDefault(): Observable<CompanyDefaultModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<CompanyDefaultType>(
        `${environment.URLBase}v1/matrizacessos/obterempresapadrao`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapperDefault.mapFrom));
  }

  getAskCompanyDefault(): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<any>(
        `${environment.URLBase}v1/matrizacessos/pergunta-empresa_padrao`,
        httpOptions
      );
  }
  
}
