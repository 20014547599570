
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core'; import { GetAllLoansUsecase } from '@src/core/usecases/loans/get-all-loans';
import { LoansRequest, LoansRequestContractInfo } from '@src/core/domain/loans/loan-model';
import { GetContractInformationUsecase } from '@src/core/usecases/loans/get-contract-information';
import { LoansDateParameterDataShare } from '@src/core/usecases/data-share/loans-date-param';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fibra-contract-information',
  templateUrl: './contract-information.component.html',
  styleUrls: ['./contract-information.component.scss'],
})
export class ContractInformationComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public numContrato: string;
  public loansList;
  public dateReferenceToShow = null
  public getContractInfoSub: Subscription;

  constructor(
    private getContractInfo: GetContractInformationUsecase,
    private dateShare: LoansDateParameterDataShare,
  ) { }



  @Input() numContract: string;
  @Output() showPageContractInformation = new EventEmitter<Boolean>();
  @Output() showPageParcela = new EventEmitter<Boolean>();




  ngOnInit(): void {


    this.dateShare.data
    let dateParam = null
    let date = null

    this.dateShare.dataShared.subscribe((res) => (dateParam = res))

    this.dateReferenceToShow = dateParam

    //Seta data de referencia, se houver
    this.dateReferenceToShow = dateParam.dat_ref

    if (dateParam.userPassValue)
      date = dateParam.dat_ref

    const params: LoansRequestContractInfo = {
      numContrato: this.numContract,
      dt_ref: date
    }

    //Export Excel
    sessionStorage.setItem('excelFilterCodContract', this.numContract);



    this.loading = true
    this.getContractInfoSub = this.getContractInfo.execute(params).subscribe((data) => {
      this.loansList = data.data

      //PDF
      sessionStorage.removeItem('contracts_list_pdf');
      sessionStorage.setItem('info_contrato_pdf', JSON.stringify(this.loansList));
      sessionStorage.setItem('informations_pdf', '{"title":"Capital de  giro", "filters":false, "report_name":"InfoContrato", "name":"info-contrato","num_contract":"'+this.numContract+'","dt_ref":"'+this.dateReferenceToShow+'"}')

      this.loading = false
    })
  }




  back() {
    this.showPageContractInformation.emit(false)
    this.showPageParcela.emit(false)
  }

  ngOnDestroy() {
    this.getContractInfoSub.unsubscribe();
    sessionStorage.removeItem('info_contrato_pdf')
    sessionStorage.removeItem('informations_pdf')
    sessionStorage.removeItem('excelFilterCodContract')
  }

}
