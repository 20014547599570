import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { componentDeclarations } from '@src/shared/components/cards/cards.common';
import { TooltipModule } from '@src/shared/directives/tooltip';
import { CardValueChequeComponent } from './card-value-cheque/card-value.component';
import { CardValueTwoRowsComponent } from './card-value-two-rows/card-value.component';
import { CardValueInvestmentsComponent } from './card-value-investments/card-value-investments.component'

@NgModule({
  declarations: [...componentDeclarations, CardValueChequeComponent, CardValueTwoRowsComponent, CardValueInvestmentsComponent],
  exports: [...componentDeclarations, CardValueChequeComponent, CardValueTwoRowsComponent, CardValueInvestmentsComponent],
  imports: [CommonModule, TooltipModule],
})
export class CardsModule {}
