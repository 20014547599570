import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PixComprovanteDataShare {
  public data: any;
  public currentAccount = undefined;

  constructor() {}

  setValue(data: any) {
   this.data = data;
  }

  getValue() {
      return this.data;
  }
}
