import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getHeader } from "@src/core/base/header";
import { NotificationModel } from "@src/core/domain/notification/notification.model";
import { environment } from '@src/environments/environment';
import { Observable } from "rxjs";
@Injectable({
    providedIn: 'root'
})

export class NotificationRepository {
    constructor(private http: HttpClient) {}

    public getNotification(param): Observable<NotificationModel> {
        const httpOptions = {
            headers: getHeader()
        };

        return this.http.get<NotificationModel>(`${environment.URLBase}v1/notificacoes/${param.token_id}/${param.companyId}`, httpOptions);
    }

    public postNotification(param: any): Observable<NotificationModel> {
        const httpOptions = {
            headers: getHeader(),
        };
        
        return this.http.post<NotificationModel>(`${environment.URLBase}v1/notificacoes/token`, param, httpOptions);
    }

    putNotification(param) {
        const httpOptions = {
            headers: getHeader()
        };

        return this.http.put<NotificationModel>(`${environment.URLBase}v1/notificacoes`,param, httpOptions);
    }

    deleteNotification(param) {
        const httpOptions = {
            headers: getHeader(),
            body: param
        };
        
        return this.http.delete<NotificationModel>(`${environment.URLBase}v1/notificacoes`, httpOptions);
    }


}