import { Mapper } from '@src/core/base/mapper';

export class TitleInformationRepositoryMapper extends Mapper<any, any> {
  mapFrom(param: any): any {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: any): any {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
