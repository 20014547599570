export interface Functionalities {
    access?: boolean;
    id: number;
    desc: string;
    permissions?: Permissions[];
}

export interface Permissions {
    access: boolean;
    id: number;
    desc: string;
}

export enum FunctionalitiesId {
    Transfer = 1,
    Payment_Of_Tickets = 2,
    Extract = 3,
}

export enum PermissionId {
    Between_Accounts_Fibers_of_Other_Ownership = 1,
    Between_Accounts_Fibers_of_Same_Ownership = 3,
    Other_Banks_of_Same_Ownership = 4,
    Bill_Payment = 7,
    View_Search_And_Download_Extracts = 6,
    Other_Banks_of_Other_Ownership = 5,
    View_Beneficiary_List = 31,
    Schedule_Transfer = 32,
}
