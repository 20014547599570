import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'account',
})
export class AccountPipe implements PipeTransform {
  transform(account: any): any {
    if (account) {
      const len = String(account).length - 1;
      return account.substring(0, len) + '-' + account.substring(len);
    }
    return account;
  }
}
