import { Injectable } from '@angular/core';
import { LoginRepository } from '@src/core/repositories/login/login.repository';
import { UseCase } from '@src/core/base/use-case';
import { LoginMobileTypeModel, LoginMobileRequest } from '@src/core/domain/login/loginMobileType.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SendLoginUsecase implements UseCase<any, LoginMobileTypeModel> {
  constructor(private loginRepository: LoginRepository) { }
  execute(params: LoginMobileRequest): Observable<LoginMobileTypeModel> {
    return this.loginRepository.sendLogin(params);
  }
}

