<ng-template #content let-modal>
    <div class="dda-container">
      <div class="modal-header">
        <div class="generic-info">
          <img alt="" [src]="imgSrc" class="img-medium mb-3" />
      <div class="row">
        <div class="col-12 text-center">
          <h5
          class="font-weight-bold"
          [ngClass]="{'fg-yellow': typeMessage === 'warning', 'fg-green': typeMessage === 'success', 'fg-red': typeMessage === 'error'}"
        > {{ title }}</h5>
        </div>
        <div class="col-12 mt-2 text-left">
          <h5  class="font-weight-bold">{{ message }}</h5>
        </div>
      </div>    
        </div>
      </div>
      <div class="mt-2 row align-items-center text-center  mb-5">
      <div class="ml-3 col mr-3">
        <button
            type="button"
            class="secondary-button mt-2"
            (click)="close()"
          >
            Fechar
          </button>
      </div>

    </div>
  </div>
  </ng-template>