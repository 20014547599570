import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { AlteracoesLimitesRepository } from '@src/core/repositories/alteracoeslimites/alteracoeslimites.repository';
import { Response } from '@src/core/domain/response/response';

@Injectable({
  providedIn: 'root'
})
export class MarkModalAsReadUsecase implements UseCase<any, Response<any>> {
  constructor(private _repository: AlteracoesLimitesRepository) { 

  }

  execute(idAlteracao: number): Observable<Response<any>> {
    return this._repository.markModalAsRead(idAlteracao);
  }
}
