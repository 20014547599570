export const middleChequeEmpresaNaoContratado = ['09664683000156',
'03306578001211',
'03306578000169',
'03306578001300',
'03306578001645',
'03306578002102',
'03306578002617',
'03306578002374',
'03306578002889',
'03306578002536',
'03306578002960',
'03306578002293',
'03306578002021',
'03306578003346',
'03306578003265',
'03306578003770',
'03306578003427',
'03306578000673',
'03306578001050',
'03306578001726',
'03306578003184',
'03306578002706',
'03306578003931',
'03306578001998',
'03306578000592',
'03306578000754',
'03306578000240',
'02505168000184',
'04654727000143',
'61098869000105',
'61098869000458',
'14587041000130',
'05437703000103',
'23122434000160',
'02450411000104',
'45294055000190',
'03548504000139',
'02999999000150',
'11354634000169',
'14716931000103',
'22172430000123',
'27105334000186',
'14381634000146',
'07164263000185',
'07464123000122',
'09014205000109',
'92660893000110',
'93612059000111',
'17882286000189',
'04193383000112',
'94328580000194',
'30983544000181',
'03857539000150',
'01238035000126',
'18319940000103',
'26661766000100',
'28822870000165',
'61064598000177',
'47464524000105',
'47854831000194',
'72907595000417',
'53137527000129',
'55183248000127',
'61529616000149',
'57040081000170',
'72907595000174',
'02971159000189',
'00600758000160',
'61529616000220',
'01148171000125',
'02092233000197',
'33047655000174',
'04064707000112',
'82437211000150',
'46201869000103',
'61071148000101',
'62407564000109',
'03594431000111',
'08829223000178',
'02434768000107',
'04918848000155',
'61425849000100',
'61244166000148',
'47854831002057',
'10276041000169',
'09471917000149',
'46201869000600',
'47176755000105',
'47176755000296',
'47176755000539',
'04220031000109',
'88610191000154',
'12744404000179',
'07094272000147',
'07671092000180',
'07603376000130',
'05435483000170',
'05435483000413',
'45361425000164',
'45361425000598',
'66617747000363',
'66617747000100',
'27177096000114',
'10616554000171',
'13146331000186',
'24892056000166',
'25213835000150',
'13146331000348',
'33233778000108',
'26143486000100',
'26093950000100',
'11111545000191',
'10855315000174',
'10650880000103',
'32746647000153',
'32768367000146',
'33311394000158',
'33309685000101',
'33233778000361',
'18351514000157',
'09373468000287',
'09373468000104',
'46025722000100',
'05757520000167',
'66201278000135',
'10894925000187',
'04125952000192',
'01126123000136',
'57046955000105',
'06975242000187',
'02060862000135',
'13328577000179',
'13328665000170',
'02060862001107',
'06956096000142',
'33018974000151',
'14569963000115',
'30821677000151',
'13410173000120',
'08301904000169',
'12764368000105',
'82956889000140',
'78269628000184',
'04908757000139',
'07033311000104',
'16930087000136',
'52780376000321',
'52780376000160',
'15689185000160',
'15689185000241',
'09487999000110',
'02933416000198',
'89373245000178',
'92980333000142',
'60894482000100',
'00804937000110',
'61532198000149',
'03856102000100',
'43782788000148',
'60894482000445',
'61532198000653',
'61532198000300',
'78143146000183',
'34781808000175',
'60851615000153',
'55156574000145',
'60851599000107',
'43667427000150',
'18787482000137',
'04904042000108',
'01129983000123',
'12846474000138',
'14548476000176',
'14880035000177',
'53705646000130',
'53705646001960',
'53705646002265',
'53705646002850',
'53705646002770',
'05861238000125',
'20278271000110',
'96195615000190'];

export const middleChequeEmpresaNaoUtilizado = ['06940439000180',
'44242287000131',
'46686465000220',
'57940546000220',
'60569845000124',
'60860681000190',
'75284224000171',
'04691623000109',
'13294850000191',
'31112243000145',
'61142089001510',
'74461005000158',
'90102609000164',
'01144673000188',
'02886413000140',
'03331941000104',
'05742615000107',
'07206858000156',
'07270366000120',
'13246724000161',
'00556594000111',
'02496442000104',
'08826385000152',
'10847647000107',
'14807945000124',
'52645009000153',
'57424244000119',
'61144150000163',
'61167060000198',
'61216867000173',
'71923304000179',
'81315426000136',
'01025974000192',
'12255403000160',
'20382746000114',
'68093095000179',
'14880035000177',
'00408307000126',
'10767247000191'];

export const middleFibraFacilNaoUtilizado = ['10793008000106',
'00729422000100',
'15660513000104',
'18351514000157',
'24662298000163',
'32274158000146',
'61150447000131',
'00119633000113',
'02045487000154',
'03549807000176',
'04746729000162',
'04894085000150',
'06227913000121',
'13440797000190',
'15806916000100',
'18741907000177',
'21558246000153',
'21812204000105',
'34787966000132',
'35764708000101',
'38874848000112',
'47939855000146',
'49034275000135',
'57146607000100',
'61106043000140',
'61727285000151',
'64799539000135',
'05283183000113',
'10421584000122',
'12047030000132',
'15634502000141',
'19869582000175',
'23826083000178',
'35251088000107',
'35659497000139',
'38334252000120',
'43942598000140',
'51128999000190',
'60497708000121',
'02101894000131',
'03831403000170',
'04201168000116',
'05389334000112',
'07750075000139',
'07956015000177',
'08637580000134',
'08773135000100',
'10520136000186',
'11873448000136',
'16952307000122',
'19526748000150',
'39622406000141',
'52781317000106',
'60446929000170',
'61322558000188',
'68291921000194',
'75222901000127',
'83495085000153',
'89373245000178',
'94854908000106',
'03524760000196',
'04625459000131',
'08617578000101',
'11190870000197',
'12776168000172',
'14730298000108',
'17659781000123',
'30710499000191',
'32248649000112',
'62291380000118',
'21628516000155',
'44944668000162',
'45365541000151',
'21619080000138',
'26589893000146'];