<div class="list-value">
  <ul class="col-lg-12 list-group list-group-flush p-0">
    <li class="row-list" *ngFor="let item of balanceData.contas; index as i">
      <div class="panel-group">
        <div class="panel-list">
          <table>
            <tr>
              <td class="td-1">
                <div class="account-numbering">
                  <span>{{ i + 1 }}</span>
                </div>
              </td>
              <td class="td-2">
                <div class="col-bank-number">
                  <small>Número da instituição</small>
                  <span> {{ pad(item.cod_banco,3) }} </span>
                </div>
              </td>
              <td class="td-3">
                <div class="col-fibra-bank">
                  <div class="ico-fibra" *ngIf="item.cod_banco === 224">
                    <img
                      src="assets/svg/logo-blue-small.svg"
                      alt="Conta do Banco Fibra"
                    />
                  </div>
                  <div class="col-bank-name">
                    <small>Nome do banco</small>
                    <span> {{ item.nome_banco | titlecase }} </span>
                  </div>
                </div>
              </td>
              <td class="td-4">
                <div class="col-agency">
                  <small> Agência</small>
                  <span> {{ item.num_agencia }} </span>
                </div>
              </td>
              <td class="td-5">
                <div class="col-account">
                  <small>Conta</small>
                  <span> {{ item.num_conta | account }} </span>
                </div>
              </td>
              <td class="td-6">
                <div class="item-icon" id="rotate">
                  <button
                    type="button"
                    class="secondary-button button"
                    (click)="
                      selectBeneficiary(balanceData, balanceData.contas[i])
                    "
                    *ngIf="item"
                  >
                    Selecionar
                  </button>
                  <img
                    src="/assets/svg/trash.svg"
                    *fibraHasRole="'TRANSF.EXC_BEN'"
                    alt="Excluir Favorito"
                    (click)="
                      deleteBeneficiary(
                        balanceData.contas[i].cod_conta_beneficiario
                      )
                    "
                  />
                </div>
              </td>
            </tr>
          </table>

          <!-- <div class="account-numbering">
            <span>{{ i + 1 }}</span>
          </div>
          <div class="col-bank-number">
            <small>Número do banco</small>
            <span> {{ item.cod_banco }} </span>
          </div>
          <div class="col-fibra-bank">
            <div class="ico-fibra" *ngIf="item.cod_banco === 224">
              <img
                src="assets/svg/logo-blue-small.svg"
                alt="Conta do Banco Fibra"
              />
            </div>
            <div class="col-bank-name">
              <small>Nome do banco</small>
              <span> {{ item.nome_banco | titlecase }} </span>
            </div>
          </div>
          <div class="col-agency">
            <small> Agência</small>
            <span> {{ item.num_agencia }} </span>
          </div>
          <div class="col-account">
            <small>Conta</small>
            <span> {{ item.num_conta | account }} </span>
          </div>
          <div class="item-icon" id="rotate">
            <button
              type="button"
              class="secondary-button button"
              (click)="selectBeneficiary(balanceData, balanceData.contas[i])"
              *ngIf="item"
            >
              Selecionar
            </button>
            <img
              src="/assets/svg/trash.svg"
              alt="Excluir Favorito"
              (click)="
                deleteBeneficiary(balanceData.contas[i].cod_conta_beneficiario)
              "
            />
          </div> -->
        </div>
      </div>
    </li>
  </ul>
</div>

<!-- <fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message> -->