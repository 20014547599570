import {
  ApprovalandRefuseRequest,
  ApprovalandRefuseModel,
} from '@src/core/domain/transfer-approval/approval.model';
import {
  ApprovalandRefuseEntity,
  TransferApprovalEntity,
} from './approval.entity';
import {
  TransferApprovalModel,
  ApprovalDetailRequest,
  ApprovalRequest,
} from '@src/core/domain/transfer-approval/approval.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';
import { TransferApprovalRepository } from '@src/core/repositories/transfer-approval/approval.repository';
import {
  TransferApprovalRepositoryMapper,
  ApprovalAndRefusedRepositoryMapper,
} from './approval-repository.mapper';
import {  ReceiptRequest } from '@src/core/domain/transfer-receipt/receipt.model';
// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { ResponseTypes } from 'msal/lib-commonjs/utils/Constants';

@Injectable({
  providedIn: 'root',
})
export class TransferApprovalDataRepository extends TransferApprovalRepository {
  private transderMapper = new TransferApprovalRepositoryMapper();
  private approvalMapper = new ApprovalAndRefusedRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }
  getList(obj: ApprovalRequest): Observable<TransferApprovalModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
      .post<TransferApprovalEntity>(
        `${environment.URLBase}v1/transacoes/lista`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.transderMapper.mapFrom), catchError(handleError));
  }

  getDetail(obj: ApprovalDetailRequest): Observable<TransferApprovalModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
      .get<TransferApprovalEntity>(
        `${environment.URLBase}v1/Transacoes/${params.id}/detalhes`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.transderMapper.mapFrom), catchError(handleError));
  }

  postApproval(
    obj: ApprovalandRefuseRequest
  ): Observable<ApprovalandRefuseModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
      .post<ApprovalandRefuseEntity>(
        `${environment.URLBase}v1/transacoes/aprovar`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.approvalMapper.mapFrom), catchError(handleError));
  }

  postRefused(
    obj: ApprovalandRefuseRequest
  ): Observable<ApprovalandRefuseModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };
    return this.http
      .post<ApprovalandRefuseEntity>(
        `${environment.URLBase}v1/transacoes/reprovar`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.approvalMapper.mapFrom), catchError(handleError));
  }

  postExportExcel(obj: ApprovalRequest): Observable<TransferApprovalModel> {
    const params = { ...obj };
    return this.http
      .post<TransferApprovalEntity>(
        `${environment.URLBase}v1/transacoes/excel`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }

  postReceiptExportExcel(obj: ReceiptRequest): Observable<TransferApprovalModel> {
    const params = { ...obj };
    return this.http
      .post<TransferApprovalEntity>(
        `${environment.URLBase}v1/transacoes/Comprovante/excel`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }
}
