import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { yourText, fiscalKey } from "@src/shared/util-common";
import { isArray } from "util";
import { ToastErrorMessageComponent } from "@src/presentation/web/components/toast-error-message/toast-error-message.component";
import { WalletTypeDataRepository } from "@src/data/repository/wallet/walletType.repository";
import { DateValidator } from "@src/shared";
import * as moment from "moment";
import { RolesService } from "@src/shared/services/roles.service";

import { EspecieModel } from "@src/core/domain/charge/especie.model";
import { EspecieGetDataRepository } from "@src/data/repository/charge/especie/especie.repository";
import { DropdownList } from "@src/core/domain/business-models";

interface OptionsRadio {
  option: string;
  value: any;
}

@Component({
  selector: "fibra-charge-second-step",
  templateUrl: "./charge-second-step.component.html",
  styleUrls: ["./charge-second-step.component.scss"],
})
export class ChargeSecondStepComponent implements OnInit {
  createDefaultDate: NgbDate = new NgbDate(2000, 1, 1);
  createDefaultDueDate: NgbDate = new NgbDate(2000, 1, 1);
  maxIssueDate: NgbDate;
  taxOption: OptionsRadio[] = [];
  protestOption: OptionsRadio[] = [];
  currencyOption = [];
  multaOption: OptionsRadio[] = [];
  public checkRadio;
  public valueCheckProtest = false;
  checkTax: string;
  taxCheck = undefined;
  protest: Date;
  verifyValue = false;
  hasDateDiscount = false;
  date: Date;
  inputDate: string;
  dueDate: NgbDate;
  createDate: NgbDate;
  limitDiscountDate: NgbDate;
  inputValue: number;
  hasErrorPercent: boolean;
  dateProtest;
  step2Edit: any;
  today = new Date();

  @Input() submit: boolean;
  @Output() isInfoTitleValid = new EventEmitter();
  @Output() isDiscountValid = new EventEmitter();
  public formStep2: FormGroup;
  public isFirstTax = false;
  public fiscalKey = fiscalKey;
  public yourNumber = yourText;
  public optionWallet: OptionWalletCombo[] = [];
  roleObject: any;

  especiesOptions: DropdownList = [];

  hasError = false;

  hasEdit: boolean = false;

  @Output() dateSelected: EventEmitter<NgbDate>;

  @ViewChild("myInput", { static: true }) myInput: ElementRef;

  @ViewChild("createAt", { static: true }) createAt: ElementRef;

  isTitleValueZero = false;
  public get titleInformationForm() {
    return this.formStep2.get("titleInformation");
  }

  ProtestDate(date: NgbDate) {
    this.protest = new Date(date.year, date.month - 1, date.day);
  }

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ShowToast(value: any): void {
    const isMessageServer: boolean = !!(
      value &&
      value.code &&
      value.message &&
      value.message.length > 0
    );
    isMessageServer &&
      this.toast &&
      this.toast.callModalMessage(null, value.message[0], null, null, null);
  }

  constructor(
    private formBuild: FormBuilder,
    private route: Router,
    private walletTypeDataRepository: WalletTypeDataRepository,
    private rolesService: RolesService,
    private especieRepository: EspecieGetDataRepository
  ) {
    this.hasEdit = localStorage.getItem("second_step_edit") ? true : false;

    if (localStorage.getItem("especies"))
      this.especiesOptions = JSON.parse(
        localStorage.getItem("especies")
      ) as DropdownList;

    this.formStep2 = this.formBuild.group({
      titleInformation: this.formBuild.group(
        {
          isFirstTax: [""],
          firstTax: [""],
          secondTax: [""],
          yourNumber: [
            "",
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(10),
            ],
          ],
          titleValue: ["", [Validators.required]],
          fiscalKey: [
            "",
            [
              Validators.required,
              Validators.minLength(54),
              Validators.maxLength(54),
            ],
          ],
          dueDate: [
            "",
            [
              Validators.required,
              //DateValidator.onlyDateEqualOrGreaterThanFiveDays,
            ],
          ],
          message1: ['', Validators.maxLength(69)],
        message2: ['', Validators.maxLength(69)],
        message3: ['', Validators.maxLength(69)],
        message4: ['', Validators.maxLength(69)],
        message5: ['', Validators.maxLength(69)],
          createAt: [
            "",
            [Validators.required, DateValidator.onlyDateEqualOrLessThanToday],
          ],
          coin: [1],
          especie: ["", Validators.required],
          discount: [""],
          mora: [0],
          tax: [""],
          banco: [""],
          conta: ["", Validators.required],
          conta_header: ["", Validators.required],
          carteira: ["", Validators.required],
          protest: [false, Validators.required],
          limitDiscountDate: [""],
          protestDate: [""],
          valueDays: [""],
          typeTax: [""],
        },
        {
          validator: DateValidator.onlyDateLessOtherDate(
            "limitDiscountDate",
            "dueDate"
          ),
        }
      ),
    });
  }
  requestAccount(): void {
    this.walletTypeDataRepository
      .getWalletTypes()
      .subscribe(this.requestSuccess, this.requestError);
  }

  ConsultaObrigatoriedadeChaveAcesso(): void {
    this.walletTypeDataRepository
      .ConsultaObrigatoriedadeChaveAcessoNfAutbank()
      .subscribe((value) => {
        if (value.data && !value.data.obriga_Nfe) {
          this.titleInformationForm.get("fiscalKey").setValidators([]);
          this.titleInformationForm.get("fiscalKey").setErrors(null);
          this.titleInformationForm.get("fiscalKey").updateValueAndValidity();
        }
      });
  }

  formatFiscalKey(event: string) {
    return event.replace(/\s/g, "");
  }

  requestSuccess = (value) => {
    if (isArray(value.data)) {
      value.data.map((e, i) => {
        if (
          e.ctaHeader.trim() !== "" &&
          e.ctaHeader !== null &&
          !e.descricaoProduto.toLowerCase().includes("cessão") &&
          !e.descricaoProduto.toLowerCase().includes("cessao")
        ) {
          this.roleObject.forEach((r) => {
            if (
              r.Name == "COB.CAD_TIT" &&
              (r.NumeroConta == e.numeroConta || r.NumeroConta == null)
            ) {
              this.optionWallet.push({
                indice: e.numOperacao,
                name: e.descricaoProduto,
                value: e.codProduto,
                modality_account: e.codigoModalidadeConta,
                selected: false,
                numeroConta: e.ctaHeader,
                codBancoPreferencial: e.codBancoPreferencial,
              });
            }
          });
        }
      });
    }

    this.setValues();
  };

  requestError = (err) => {
    this.setValues();
    this.ShowToast(err);
  };

  ngOnInit(): void {
    this.sumDate();
    this.setOptions();
    this.requestAccount();
    this.ConsultaObrigatoriedadeChaveAcesso();
    this.setInitialDateValues();
    this.roleObject = this.rolesService.getRoles();
  }

  setInitialDateValues(): void {
    this.maxIssueDate = new NgbDate(
      this.today.getFullYear(),
      this.today.getMonth() + 1,
      this.today.getDate()
    );
    this.date = new Date();
    this.inputDate = Intl.DateTimeFormat("pt-BR").format(this.date);
    this.createDate = null;
    this.limitDiscountDate = null;
    this.dueDate = null;
  }

  setOptions(): void {
    this.protestOption = [
      { option: "Sim", value: true },
      { option: "Não", value: false },
    ];

    this.currencyOption = [
      { name: "Real", value: 1 },
      // { name: 'Dólar', value: 2 }
    ];

    this.multaOption = [
      { option: "Percentual", value: true },
      { option: "Valor nominal", value: false },
      { option: "Sem multa", value: null },
    ];
  }

  setValues() {
    setTimeout(() => {
      if (this.hasEdit) {
        this.formStep2.setValue(
          JSON.parse(localStorage.getItem("second_step_edit"))
        );

        this.setDatesValue();

        this.titleInformationForm.valueChanges.subscribe(
          (res) => {
            localStorage.setItem(
              "second_step_edit",
              JSON.stringify({ titleInformation: res })
            );
          },
          (error) => this.ShowToast(error)
        );

        if (
          this.hasEdit &&
          this.titleInformationForm.get("protestDate").value !== ""
        ) {
          this.dateProtest = this.titleInformationForm.get("protestDate").value;
          this.inputValue = this.titleInformationForm.get("valueDays").value;
          this.showProtest(true);
        }
      }

      if (this.titleInformationControl("tax").value) {
        this.showTax(
          this.titleInformationForm.get("typeTax").value === "P",
          true
        );
      }

      if (this.titleInformationControl("tax").value === "") {
        this.showTax(null);
      }
    }, 300);
  }

  setDatesValue(): void {
    this.selectDate("dueDate", this.titleInformationForm.get("dueDate").value);
    this.selectDate(
      "createAt",
      this.titleInformationForm.get("createAt").value
    );
    this.selectDate(
      "limitDiscountDate",
      this.titleInformationForm.get("limitDiscountDate").value
    );
  }

  goNext() {

    if (this.titleInformationForm.valid) {
      localStorage.setItem("second_step", JSON.stringify(this.formStep2.value));
      this.route.navigate(["/summary"]);
    }
  }

  changeEspecie(event: any): void {
    this.titleInformationForm.get("especie").setValue(event);
  }

  alterAccount(event) {
    let selectedItem = this.optionWallet.find((item) => item.value === event);
    this.titleInformationForm.get("conta").setValue(selectedItem.numeroConta);
    this.titleInformationForm
      .get("conta_header")
      .setValue(selectedItem.numeroConta);
    this.titleInformationForm
      .get("banco")
      .setValue(selectedItem.codBancoPreferencial);

    this.titleInformationForm.patchValue({
      dueDate: "",
    });

    this.dueDate = null;
    
    if (this.titleInformationForm.get("carteira").value !== "CBONLINE") {
      this.titleInformationForm.get("dueDate").clearValidators();
      this.titleInformationForm.get("dueDate").setValidators([Validators.required, DateValidator.onlyDateEqualOrGreaterThanFiveDays]);
    } else {
      this.titleInformationForm.get("dueDate").clearValidators();
      this.titleInformationForm
      .get("dueDate")
      .setValidators([Validators.required, DateValidator.dateEqualOrGreaterThanToday]
      );
    }

    this.setEspeciesOption(event);

    this.isCobrancaOnline(selectedItem.value);
  }

  setEspeciesOption(event: any): void {
    this.especieRepository.getEspecies().subscribe((response: EspecieModel) => {
      this.especiesOptions = response.data.map((d: any) => {
        let data = event === "CBONLINE" ? d.codigoCip : d.codigoFebraban;
        return {
          value: data,
          name: d.nome,
        };
      }) as DropdownList;

      localStorage.removeItem("especies");
      localStorage.setItem("especies", JSON.stringify(this.especiesOptions));
    });
  }

  //#region Manage personal methods forms
  titleInformationControl(name: string) {
    this.isInfoTitleValid.emit(this.titleInformationForm.valid);
    return this.titleInformationForm.get(name);
  }

  titleInformationControlIsInvalid(name: string) {

    let control = this.titleInformationControl(name);

    let controlTitle = this.titleInformationControl("titleValue");
    let dataLimiteDesconto = this.titleInformationControl("limitDiscountDate");

    let valueTitle = Number(controlTitle.value);

    if (name === "discount") {
      this.applyDiscount(control, dataLimiteDesconto, valueTitle);
    }
    if (name === "titleValue") {
      this.applyTitleValue(valueTitle);
    }

    if (name === "fiscalKey") {
      this.titleInformationForm.get("fiscalKey").setValue(control.value.trim());
    }

    return name === "discount"
      ? (this.verifyValue || control.invalid) &&
          (control.touched || control.dirty)
      : control.invalid && (control.touched || control.dirty);
  }

  applyTitleValue(valueTitle: number) {
    if (!valueTitle) {
      this.titleInformationForm
        .get("titleValue")
        .setValidators([Validators.required, Validators.pattern(/^[1-9]+$/)]);
      this.titleInformationForm.get("titleValue").setErrors({ required: true });
      this.titleInformationForm.get("titleValue").updateValueAndValidity();
    } else {
      this.titleInformationForm.get("titleValue").setValidators(null);
      this.titleInformationForm
        .get("titleValue")
        .setErrors({ required: false });
      this.titleInformationForm.get("titleValue").updateValueAndValidity();
    }
  }

  applyDiscount(
    control: AbstractControl,
    dataLimiteDesconto: AbstractControl,
    valueTitle: number
  ) {
    let valueDiscount = Number(control.value);
    let valueLimiteDesconto = String(dataLimiteDesconto.value);

    this.callEmitDiscount(valueDiscount, valueTitle, valueLimiteDesconto);
    this.setDiscountValidations(valueLimiteDesconto, valueDiscount);
    this.setLimiteValidations(valueDiscount);
  }

  setLimiteValidations(valueDiscount: number) {
    if (valueDiscount > 0) {
      this.titleInformationForm
        .get("limitDiscountDate")
        .setValidators(
          valueDiscount
            ? [Validators.required, DateValidator.dateEqualOrGreaterThanToday]
            : [DateValidator.dateEqualOrGreaterThanToday]
        );
      this.titleInformationForm
        .get("limitDiscountDate")
        .setErrors(valueDiscount ? { required: true } : null);
      this.titleInformationForm
        .get("limitDiscountDate")
        .updateValueAndValidity();
    } else {
      this.titleInformationForm.get("limitDiscountDate").setValidators(null);
      this.titleInformationForm
        .get("limitDiscountDate")
        .setErrors(valueDiscount ? { required: false } : null);
      this.titleInformationForm
        .get("limitDiscountDate")
        .updateValueAndValidity();
    }
  }

  setDiscountValidations(
    valueLimiteDesconto: string,
    valueDiscount: number
  ): void {
    if (valueLimiteDesconto !== "" && !valueDiscount) {
      this.titleInformationForm
        .get("discount")
        .setValidators([Validators.required, Validators.pattern(/^[1-9]+$/)]);
      this.titleInformationForm.get("discount").setErrors({ required: true });
      this.titleInformationForm.get("discount").updateValueAndValidity();
    } else {
      this.titleInformationForm.get("discount").setValidators(null);
      this.titleInformationForm
        .get("discount")
        .setErrors(valueDiscount ? { required: false } : null);
      this.titleInformationForm.get("discount").updateValueAndValidity();
    }
  }

  callEmitDiscount(
    valueDiscount: number,
    valueTitle: number,
    valueLimiteDesconto: string
  ) {
    if (
      valueDiscount >= valueTitle ||
      (valueLimiteDesconto !== "" && !valueDiscount)
    ) {
      this.verifyValue = true;
      this.isDiscountValid.emit(false);
    } else {
      this.verifyValue = false;
      this.isDiscountValid.emit(true);
    }
  }

  titleInformationControlValid(name: string) {
    const control = this.titleInformationControl(name);
    return control && !control.invalid;
  }

  titleInformationControlErrors(name: string): any {
    return this.getErrorsControl(this.titleInformationControl(name));
  }

  dateControlFirstError(name: string): any {
    let control = this.titleInformationControl(name);

    if (control.invalid && (control.touched || control.dirty)) {
      return this.getErrorsControl(control);
    }
  }

  showTax(show: boolean, isEdit?: boolean) {
    this.taxCheck = show;

    if (show !== null) this.checkTax = show ? "percent" : "value";
    else this.checkTax = null;

    this.hasErrorPercent = false;

    if (!isEdit && show !== null)
      this.formStep2.patchValue({
        titleInformation: {
          tax: "",
          typeTax: show ? "P" : "V",
        },
      });

    this.titleInformationForm.get("tax").setValidators([Validators.required]);
    this.titleInformationForm.get("tax").setErrors({ required: true });
    this.titleInformationForm.get("tax").updateValueAndValidity();

    if (this.checkTax === null) {
      this.formStep2.patchValue({
        titleInformation: {
          tax: "",
          typeTax: "",
        },
      });

      this.titleInformationForm.get("tax").setValidators(null);
      this.titleInformationForm.get("tax").setErrors({ required: false });
      this.titleInformationForm.get("tax").updateValueAndValidity();
    }

    if (show !== this.isFirstTax) {
      this.isFirstTax = show;
      this.formStep2.patchValue({
        titleInformation: {
          firstTax: this.isFirstTax,
          secondTax: !this.isFirstTax,
        },
      });
    }
  }

  checkValueTax(event) {
    this.hasErrorPercent = false;
    if (this.checkTax === "percent") {
      let newValue = event.target.value;
      if (newValue.includes(",")) newValue = newValue.replace(",", ".");
      if (
        Number(newValue.replace("%", "")) > 2 ||
        Number(newValue.replace("%", "")) <= 0
      ) {
        this.hasErrorPercent = true;
      }
    }
    return event;
  }

  getErrorsControl(control: AbstractControl) {
    const list = control.errors
      ? Object.getOwnPropertyNames(control.errors)
      : [];
    if (list.length > 0) {
      const error = {};
      error[list[0]] = control.errors[list[0]];
      return error;
    }
    console.log(control.errors);
    return control.errors;
  }
  //#region

  showProtest(show: boolean) {
    this.checkRadio = show !== undefined ? (show ? true : false) : null;
    //this.checkRadio = show ? true : false ;
    this.formStep2.patchValue({
      titleInformation: {
        protest: this.checkRadio,
      },
    });

    var checkProtest = this.titleInformationControl("protest").value;
    if (checkProtest === true) {
      this.valueCheckProtest = true;
      this.verifyValue = true;
    } else {
      this.valueCheckProtest = false;
    }

    if (this.checkRadio === true) {
      this.titleInformationForm
        .get("valueDays")
        .setValidators([Validators.required, Validators.pattern(/^[0-9]+$/)]);
      this.titleInformationForm.get("valueDays").setErrors({ required: true });
      this.titleInformationForm.get("valueDays").updateValueAndValidity();
    } else {
      this.titleInformationForm.get("valueDays").setValidators(null);
      this.titleInformationForm.get("valueDays").setErrors({ required: false });
      this.titleInformationForm.get("valueDays").reset();
      this.titleInformationForm.get("protestDate").reset();
      this.titleInformationForm.get("valueDays").updateValueAndValidity();
    }
  }

  Protest(event) {
    this.titleInformationForm.patchValue({
      protestDate: event.dateProtest,
      valueDays: event.valueDays,
    });

    if (event.valueDays === "" || event.valueDays === "0") {
      this.titleInformationForm.get("valueDays").reset();
      this.titleInformationForm.get("protestDate").reset();
    } else {
      this.titleInformationForm.get("valueDays").setValidators(null);
      this.titleInformationForm.get("valueDays").setErrors({ required: false });
      this.titleInformationForm.get("valueDays").updateValueAndValidity();
    }
  }

  verifyDataIsValid() {
    if (this.titleInformationForm.valid) {
      this.dateSelected.emit(this.titleInformationForm.value);
    }
  }

  verifyChanges() {
    this.titleInformationForm.get("createAt").valueChanges.subscribe(() => {
      this.verifyDataIsValid();
    });
  }

  selectDate(type: string, obj: any) {
    if (obj && !obj.year) {
      let newObj = obj.split("/");

      if (newObj[2] == "    " || newObj[2].trim() == "" || newObj[1] > 12) {
        this.titleInformationForm.patchValue({
          dueDate: "",
        });
        return
      }

      obj = {
        year: Number(newObj[2]),
        month: Number(newObj[1]),
        day: Number(newObj[0]),
      };
    }

    if (type === "dueDate") {
      this.titleInformationForm.patchValue({
        dueDate: obj,
      });
      this.dueDate = obj;
      this.ProtestDate(obj);
    } else if (type === "limitDiscountDate") {
      this.titleInformationForm.patchValue({ limitDiscountDate: obj });
      this.limitDiscountDate = obj;
      this.hasDateDiscount = true;
    } else if (type === "createAt") {
      this.titleInformationForm.patchValue({
        createAt: obj,
      });
      this.createDate = obj;
    }
  }

  sumDate() {
    let date = new Date();
    let newDate = moment(date).add(5, "days").format("DD/MM/YYYY");
    let dateSplit = newDate.split("/");

    this.createDefaultDueDate = new NgbDate(
      Number(dateSplit[2]),
      Number(dateSplit[1]),
      Number(dateSplit[0])
    );
  }

  clearDateCreateAt() {
    this.titleInformationForm.patchValue({
      createAt: "",
    });
  }

  clearDateLimitDiscountDate() {
    this.titleInformationForm.patchValue({
      limitDiscountDate: "",
    });
  }

  clearDateDueDate() {
    this.titleInformationForm.patchValue({
      dueDate: "",
    });
  }

  isCobrancaOnline(value) {
    this.destroySessionDispatchDropDownCarteira();

    let date = new Date();
    let newDate;


    if(value === 'CBONLINE') {
      newDate = moment(date).format("DD/MM/YYYY");
      sessionStorage.setItem('DISPATCH_DROPDOWN_CARTEIRA', value);
    }

    if(value !== 'CBONLINE') {
      newDate = moment(date).add(5, "days").format("DD/MM/YYYY");
    }

    let dateSplit = newDate.split("/");

    this.createDefaultDueDate = new NgbDate(
      Number(dateSplit[2]),
      Number(dateSplit[1]),
      Number(dateSplit[0])
    );

    console.log(this.createDefaultDueDate);
  }

  destroySessionDispatchDropDownCarteira() {
    const isCobrancaOnline = sessionStorage.getItem('DISPATCH_DROPDOWN_CARTEIRA');
    isCobrancaOnline ? sessionStorage.removeItem('DISPATCH_DROPDOWN_CARTEIRA') : '';
  }
}

interface OptionWalletCombo {
  indice: string;
  name: string;
  value: string;
  modality_account: string;
  selected: boolean;
  numeroConta: string;
  codBancoPreferencial: string;
}
