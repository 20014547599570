import { Injectable } from '@angular/core';
import { AccountTypeRepository } from '@src/core/repositories/account/account-type.repository';
import { AccountTypeModel } from '@src/core/domain/account/accountType.model';
import { Observable, of } from 'rxjs';
import { AccountTypeRepositoryMapper } from '@src/data/repository/account/accountType-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { AccountTypeEntity } from '@src/data/repository/account/accountType.entity';
import { flatMap, map } from 'rxjs/operators';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeDataRepository extends AccountTypeRepository {
  mapper = new AccountTypeRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllaccountTypes(): Observable<AccountTypeModel> {
    return this.http
      .get<AccountTypeEntity>(`${environment.URLBase}v1/contas`, { headers: getHeader() })
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  getAllAccountsByFuncionality(functionality: string, filter: boolean): Observable<AccountTypeModel> {
    return this.http
      .get<AccountTypeEntity>(`${environment.URLBase}v1/contas/funcionalidade/${functionality}/${filter}`, { headers: getHeader() })
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}

@Injectable({
  providedIn: 'root'
})
export class AccountTypeTestRepository extends AccountTypeRepository {
  mapper = new AccountTypeRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllaccountTypes(): Observable<AccountTypeModel> {
    return of({
      code: 200,
      status: 'sucess',
      data: {}
    });
  }

  getAllAccountsByFuncionality(_functionality: string, _filter: boolean): Observable<AccountTypeModel> {
    return of({
      code: 200,
      status: 'sucess',
      data: {}
    });
  }
}
