import { Component, Input, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";
import { GetReceiptPdfUsecase } from "@src/core/usecases/receipt/get-receipt-pdf-usecases";
import { Router } from "@angular/router";
import { PagamentoStatus } from "@src/core/domain/dda/payment-status.enum";
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { AccountDataShare } from '@src/data/repository/data-share-repository';

export type Comprovante = {
  valorPago: number,
  formaPagamento: string,
  canal: string,
  dataVencimento: string,
  dataAgendada: string,
  dataAgendamentoPagamento: string,
  beneficiario: {
    nome: string,
    cpfCnpj: string,
    banco: string,
  },
  valorBoleto: number,
  desconto: number,
  abatimento: number,
  encargos: {
    juros: number,
    multas: number,
    mora: number,
  },
  contaDebitada: {
    nome: string,
    cpfCnpj: string,
    conta: {
      descricao: string,
      agencia: string,
      numero: string,
    }
  },
  numeroCompromisso: number,
  codigoBarras: string,
  codStatusPagamento: number
}

@Component({
  selector: "fibra-comprovante",
  templateUrl: "./comprovante.component.html",
  styleUrls: ["./comprovante.component.scss"],
})
export class DdaComprovanteComponent {  
  public model!: Comprovante;
  titulo!: string;
  mensagemTituloBoleto: string;

  @ViewChild('content', { static: true })
  public content: HTMLElement;

  @ViewChild(ToastErrorMessageComponent, null)
  public toast: ToastErrorMessageComponent;
  statusPagamento: string;
  idTransacao: number;
  codStatusPagamento: number;
  companyName: string;
  companyCnpj: string;
  paymentContaExibicao: string;
  paymentContaCod: string;
  paymentContaAg: string;

  verificarStatusAgendamentoPagamento(): boolean {
    return this.codStatusPagamento == (PagamentoStatus.Agendado || PagamentoStatus.AgendadoProximoDiaUtil);
  }

  verificarStatusPagamentoRealizado(): boolean {
    return this.codStatusPagamento == (PagamentoStatus.Aprovado || PagamentoStatus.Pago || PagamentoStatus.AguardandoBaixaCip);
  }

  getCompanyDetails() {
    this.companyDataShare.dataShared.subscribe((res) => {
      if (res !== undefined) {
        res.companyList.map((e) => {
          if (e.selected) {
            this.companyName = e.name;
            this.companyCnpj = e.modality_account;
          }
        });        
      }
    });
  }

getAccountDetails() {
    this.shareAccount.dataShared.subscribe((res) => {
      if (res !== undefined) {
        const data = res.filter((e) => e.selecionado)[0];
        this.paymentContaExibicao = data.exibicao;
        this.paymentContaCod = data.num_conta;
        this.paymentContaAg = data.cod_agencia;
      }
    });
  }

  @Input() set value (value: Comprovante) {
    if(value !== undefined){
      this.getCompanyDetails();
      this.getAccountDetails();

      value.contaDebitada.nome = this.companyName;
      value.contaDebitada.cpfCnpj = this.companyCnpj;

      value.contaDebitada.conta.descricao = this.paymentContaExibicao;
      value.contaDebitada.conta.agencia = this.paymentContaAg;
      value.contaDebitada.conta.numero = this.paymentContaCod;

      this.codStatusPagamento = value.codStatusPagamento;
      this.idTransacao = value.numeroCompromisso;


      switch(this.codStatusPagamento){
        case PagamentoStatus.Agendado:
        case PagamentoStatus.AgendadoProximoDiaUtil:
          this.titulo = "Pagamento agendado";
          this.statusPagamento = "agendamento"
          this.mensagemTituloBoleto = "Comprovante de pagamento agendado";
          break; 

        case PagamentoStatus.Aprovado:
        case PagamentoStatus.Pago:
        case PagamentoStatus.AguardandoBaixaCip:
          this.titulo = "Pagamento realizado";
          this.statusPagamento = "pagamento"
          this.mensagemTituloBoleto = "Comprovante de pagamento";
          break;
        
        default:
          this.statusPagamento = "processanto"
          this.titulo = "Em processamento";
          this.mensagemTituloBoleto = "Em processamento";
          break;
      }

      this.model = value;
      this.openModal(this.content);
    }
  }

  public constructor(
    private router: Router,
    private getPdf: GetReceiptPdfUsecase,
    private readonly modalService: NgbModal,
    private readonly companyDataShare: CompanyDataShare,
    private shareAccount: AccountDataShare,
  ) {
    
  }

  public goToView = (route, aba?: string) => { 
    const userRoute = {
      params: { aba: aba }
    };

    this.router.navigate([route, userRoute.params], { skipLocationChange: true });

  }

  public baixarPDF() {
    this.getPdf
    .execute(this.idTransacao)
    .subscribe(this.successPdf, this.errorPdf);    
  }

  private successPdf = (value) => {
    fetch(value.data.url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "comprovante-boleto-"+ crypto['randomUUID']()+"-"+value.data.nome_arquivo;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  };

  private errorPdf = (err) => {
    this.toast.callModalMessage(null, "Erro", "Ocorreu um erro ao gerar PDF. Por favor tente novamente mais tarde.");
  };

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'xl',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal(): void {
    this.modalService.dismissAll();
  }
}
