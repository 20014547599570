<ng-template #content let-modal>
  <div class="company-change-container">
    <div class="modal-header">
      <h4 class="modal-title">Escolher empresa padrão ?</h4>
      <button
        type="button" 
        class="close"
        aria-label="Close"
        (click)="closeModal()"
        id="closeModalEmpresaPadrao"
      >
        <span aria-hidden="true">
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 20.0583L1.00091 1M21 1.00173L1 20.0583L21 1.00173Z"
              stroke="#4D86BF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="txt-alert">
          <p>
            Você pode definir a empresa padrão para que em todas as vezes que
            entrar no Internet Banking você já acesse as informações da empresa
            escolhida.
          </p>
        </div>

        <div class="row box-alert">
          <span class="col-md-2 ico-info"></span>
          <p class="col-md-10 txt-sub-alert">
            A padronização de empresas pode ser alterada a qualquer momento em
            “empresa padrão” no menu de usuário.
          </p>
        </div>
        <ng-container
          class="company-list company-list-modal"
          *ngTemplateOutlet="companySelector"
        ></ng-container>
      </form>
    </div>
    <div class="modal-footer-company">
      <div class="modal-footer-dialog txt-sub-alert">
        <input type="checkbox" (change)="askAgain($event)" />
        Não perguntar novamente
      </div>
      <button
        class="btn btn-primary btn-confirm"
        [disabled]="isDisabled()"
        (click)="actionModal()"
      >
        {{ TitleButton }}
      </button>
    </div>
  </div>
</ng-template>
