import { Component, OnInit, Input } from '@angular/core';
import {TotaisEntity } from '@src/data/repository/ready-change-repository/ready-change.entity';

@Component({
  selector: 'fibra-side-card-ready-change',
  templateUrl: './side-card-ready-change.component.html',
  styleUrls: ['./side-card-ready-change.component.scss'],
})
export class SideCardReadyChangeComponent implements OnInit {
  @Input() loading = true;
  @Input() hide = false;
  @Input() options: TotaisEntity[];

  constructor() {}

  /**
   * @description
   * Add a Title to componente on view
   */


  ngOnInit() {
    var teste = this.options;

  }

  
}


