import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BalancesRepository } from '@src/core/repositories/balance/balances.repository';
import { BalancesModel, IChequeEmpresa } from '@src/core/domain/balance/balances.model';
import { BalancesRepositoryMapper } from '@src/data/repository/balances-repository/balances-repository.mapper';
import { BalancesEntity } from '@src/data/repository/balances-repository/balances-entity';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BalancesDataRepository extends BalancesRepository {
  mapper = new BalancesRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllBalances(): Observable<BalancesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<BalancesEntity>(`${environment.URLBase}v1/saldos`, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}

@Injectable({
  providedIn: 'root',
})
export class BalancesService {
  constructor(private http: HttpClient) { }
  getChequeEmpresa(): Observable<IChequeEmpresa> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<IChequeEmpresa>(`${environment.URLBase}v1/saldos/chequeempresa`, httpOptions);
  }
}
