import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fibra-service-channels-access',
  templateUrl: './service-channels.component.html',
  styleUrls: ['./service-channels.component.scss']
})
export class ServiceChannelsComponent {
  
  public getBacen: boolean = false;

  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {

  }

  public closeModal(): void {
    this.modalService.dismissAll();
  }

  openChat() {
    document.getElementsByTagName('iframe')[1].contentWindow.document.getElementsByTagName('button')[0].click();
  }

  goToSiteBacen() {
    window.open('https://www.bcb.gov.br/acessoinformacao/registrar_reclamacao');
  }

  callBacen() {
    this.getBacen === false ? this.getBacen = true : this.getBacen = false;
  }


}
