import { Component, OnInit, PipeTransform } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'fibra-page-payment-pending',
  templateUrl: './payment-pending.component.html',
  styleUrls: ['./payment-pending.component.scss'],
})
export class PaymentPendingComponent implements OnInit {
  constructor(
    private router: Router,
  ) {}

  ngOnInit() {}

  public goToView = route => this.router.navigateByUrl(route);
}
