<!-- <section class="header-component-initial">
  <img class="logo-header-initial" src="/assets/svg/logoFibraBlue.svg" alt="logo">
  <button type="button" class="btn btn-default"  >Atendimento</button>
</section>
<section class='login-page'>
  <div class="login-container">
    <article class="login-box">
      <div class="login-box-header">
        <p>Internet Banking</p>
      </div>
      <div class="login-box-body">
        <p>Lorem ipsum dolor sit amet, 
          consectetur adipiscing elit. 
          Nam porta mi nulla, vel vestibulum mi 
          dapibus sed. Sed tempor risus at scelerisque 
          hendrerit. Quisque risus sapien, 
          iaculis vel urna sed, ornare mattis sapien.</p>
          <button type="button" class="secondary-button" (click)="login()" >Acessar</button>
      </div>

    </article>
  </div>
</section> -->
<div class="loading-initial d-flex justify-content-center position">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>