import { BankModel } from './../../domain/transfer/transfer.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { BankRepository } from '@src/core/repositories/transfer/bank.repository';

@Injectable({
  providedIn: 'root',
})
export class GetBankUsecase implements UseCase<any, BankModel> {
  constructor(private bankRepository: BankRepository) {}

  execute(): Observable<BankModel> {
    return this.bankRepository.getAllBank();
  }
}
