<section class="sideCard">
  <h1 *ngIf="!loading" class="title">Análise diária dos derivativos</h1>
  <div class="side-card-content" *ngIf="!loading">
    <div class="list" id="sideCardFirstRow">
      <div class="circle-progress">
        <circle-progress [percent]="50" [outerStrokeColor]="'#72b699'"
          [imageSrc]="'/assets/svg/arrow_green.svg'"></circle-progress>
      </div>

      <div class="item">
        <h1>NET</h1>
        <p *ngIf="options.net > 0 || options.net < 0">
          <span>R$</span>{{ (options.net | number: '1.2-2' ) }}
        </p>
        <p *ngIf="options.net == 0">
          <span>R$</span>---
        </p>
      </div>
    </div>
    <div class="list" id="sideCardSecondRow">
      <div class="circle-progress">
        <circle-progress [percent]="50" [outerStrokeColor]="'#d69e15'" [imageSrc]="'/assets/svg/arrow_yellow.svg'">
        </circle-progress>
      </div>

      <div class="item">
        <h1>Derivativos recebidos</h1>
        <p *ngIf="options.credito > 0 || options.credito < 0">
          <span>R$</span>{{ (options.credito | number: '1.2-2' ) }}
        </p>
        <p *ngIf="options.credito == 0">
          <span>R$</span>---
        </p>
      </div>
    </div>
    <div class="list" id="sideCardThirdRow">
      <div class="circle-progress">
        <circle-progress [percent]="50" [outerStrokeColor]="'#ed6b6c'"
          [imageSrc]="'/assets/svg/arrow_graph_red.svg'" [imageHeight]="18" [imageWidth]="28"></circle-progress>
      </div>
      <div class="item">
        <h1>Derivativos pagos</h1>
        <p *ngIf="options.debito > 0 || options.debito < 0">
          <span>R$</span>{{ (options.debito | number: '1.2-2' ) }}
        </p>
        <p *ngIf="options.debito == 0">
          <span>R$</span>---
        </p>
      </div>
    </div>
  </div>
  <div class="loading-container d-flex justify-content-center align-items-center" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</section>