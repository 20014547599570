<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div class="content-page">
  <div class="section">
    <div  class="margin-40-right">
        <img src="/assets/opportunity/svg/icon-bank.svg" alt="icon-bank">
        <h1>Fiança Bancária</h1>
        <p>Garantia para operações tais como licitações, fornecimento de produtos, B3, dentre outras.</p>
        <button (click)="isInterested()">Tenho interesse</button>
    </div>
    <div>
      <img src="/assets/opportunity/img/img-fianca-bancaria-man.jpg" alt="img-header-fianca-bancaria-man">
    </div>
  </div> 
  <div class="section margin-top-80">
    <div>
      <img src="/assets/opportunity/img/img-hand-fianca.jpg" alt="img-hand">
    </div>
    <div class="padding-40-left">
      <h3>Projetado para oferecer garantia local a beneficiários nacionais!</h3>
      <p class="font-16px">Explore a tranquilidade nos seus negócios com a Fiança Bancária do Banco Fibra, um produto bancário projetado para elevar o nível de confiança nas suas transações comerciais. Somos seu parceiro estratégico, agindo como Fiador para garantir o cumprimento das suas obrigações financeiras (Afiançado) junto aos terceiros (Beneficiários).</p>
      <button (click)="isInterested()">Saiba mais</button>
    </div>
  </div>  
  <div class="section-benefities margin-top-80">
      <h3>Vantagens do Fiança Bancária</h3>
    <div class="warpper">
      <div *ngFor="let item of benefities" class="warpper-single">
        <img [src]="item.icon" [alt]="item.alt">
        <p class="font-16px">{{ item.description }}</p>
      </div>
    </div>
  </div> 
  <div class="section-modalities margin-top-80">
    <h3>Conheça as modalidades de fiança do Banco Fibra</h3>
  <div class="warpper">
    <div *ngFor="let modality of modalities" class="warpper-single">
      <h4>{{ modality.title }}</h4>
      <p class="font-16px">{{ modality.description }}</p>
    </div>
  </div>
</div> 
  <div #interested class="section-benefities align-center margin-top-80">
    <div class="square">
      <img src="/assets/opportunity/svg/icon-money-recive.svg" alt="icon-money-recive">
      <div class="letters">
        <span>Fiança BNDES ou Finep</span>
        <p>A fiança BNDES ou Finep é concedida para garantir o pagamento de valores oriundos de obrigações eventualmente não cumpridas pelo afiançado, nosso cliente, perante o BNDES ou Finep, nos termos dos respectivos contratos de financiamento firmado entre as partes.</p>
      </div>
    </div>
  </div>
  <fibra-opportunity-form></fibra-opportunity-form>
  <div class="line margin-top-80"></div> 
  <fibra-opportunity-products-list></fibra-opportunity-products-list>
</div>
