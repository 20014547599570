/**
 * Diretiva de máscara para campos monetários.
 *
 */

import {
  Directive,
  HostListener,
  Input,
  OnInit,
  ElementRef
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR, ControlValueAccessor
} from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[MaskCurrency]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaskCurrencyDirective,
    multi: true
  }]
})
export class MaskCurrencyDirective implements ControlValueAccessor, OnInit {

  onTouched: any;
  onChange: any;

  delimiterDecimal: string;
  delimiterThousand: string;
  prefix: string;

  @Input('MaskCurrency') mask: any;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.delimiterDecimal = this.mask.decimal || ',';
    this.delimiterThousand = this.mask.thousan || '.';
    this.prefix = this.mask.prefix || '';
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngDoCheck() {
    if (this.mask.decimal !== this.delimiterDecimal || this.delimiterThousand !== this.mask.thousan || this.prefix !== this.mask.prefix) {
      this.delimiterDecimal = this.mask.decimal || ',';
      this.delimiterThousand = this.mask.thousan || '.';
      this.prefix = this.mask.prefix || '';

      this.el.nativeElement.value = this.applyMask(String(this.el.nativeElement.value));
    }
  }
  writeValue(value: any): void {
    if (value) {
      if (!isNaN(value)) {
        value = value.toFixed(2);
      }
      this.el.nativeElement.value = this.applyMask(String(value));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: any) {

    const valor: string = this.applyMask($event.target.value);

    if (valor === '') {
      this.onChange('');
      $event.target.value = '';
      return;
    }

    if (this.delimiterDecimal === ',') {
      this.onChange(valor.replace(/\./g, '').replace(',', '.'));
    } else {
      this.onChange(valor.replace(/\,/g, ''));
    }

    $event.target.value = valor;
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    const valor: string = this.applyMask($event.target.value);
    $event.target.value = valor;
  }

  /**
   * Aplica a máscara a determinado valor.
   *
   * @param string valorConverter
   * @return string
   */
  applyMask(valueConverte: string): string {
    const valueInteger = parseInt(valueConverte.replace(/\D/g, ''), 10);
    let valueMask = '';
    let value: string;

    if (isNaN(valueInteger)) {
      return '';
    }

    value = valueInteger.toString();
    switch (value.length) {
      case 1:
        valueMask = '00' + this.delimiterDecimal +
          '0' + value;
        break;
      case 2:
        valueMask = '0' + this.delimiterDecimal + value;
        break;
      case 3:
        valueMask = value.substr(0, 1) + this.delimiterDecimal +
          value.substr(1, 2);
        break;
      default:
        break;
    }

    if (valueMask === '') {
      let delimiterThousand = 0;
      for (let i = (value.length - 3); i >= 0; i--) {
        if (delimiterThousand === 3) {
          valueMask = this.delimiterThousand + valueMask;
          delimiterThousand = 0;
        }
        valueMask = value.charAt(i) + valueMask;
        delimiterThousand++;
      }
      valueMask = valueMask + this.delimiterDecimal +
        value.substr(value.length - 2, 2);
    }

    if (this.prefix !== '') {
      valueMask = this.prefix + ' ' + valueMask;
    }

    return valueMask;
  }

}
