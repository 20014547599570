import { DdaAccountModel } from "@src/core/domain/dda/account.model";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";
import { DdaApprovalsModel } from "@src/core/domain/dda/dda-approvals.model.";
import { DddaApproversMastersModel } from "@src/core/domain/dda/dda-approvers.model";
import { DdaBatchCompanyApprovalModel } from "@src/core/domain/dda/dda-batch-company-approval.model";
import { DdaBatchCompanyModel } from "@src/core/domain/dda/dda-batch-company.model";
import { EmpresaFilialModel } from "@src/core/domain/dda/empresa-filial.model";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { TermTypeEnum } from "@src/core/domain/dda/term-types-enum";
import { DdaLegadoModel, DdaTermModel } from "@src/core/domain/dda/term.model";
import { Observable } from "rxjs";

export abstract class DdaTermRepository {
  abstract get(termType: TermTypeEnum): Observable<DdaTermCompanyApprovalModel>;
  abstract create(): Observable<DdaTermModel>;
  abstract getAccount(): Observable<DdaAccountModel[]>;
  abstract getBranch(id: number): Observable<Array<DdaBranchModel>>;
  abstract getApprovers(
    termId: number
  ): Observable<Array<DddaApproversMastersModel>>;
  abstract getApprovals(termId: number): Observable<Array<DdaApprovalsModel>>;
  abstract addCompanyBatch(payload: DdaBatchCompanyModel): Observable<number>;
  abstract approvalCompanyBatch(
    payload: DdaBatchCompanyApprovalModel
  ): Observable<number>;
  abstract undoCancellation(empresaFilial: EmpresaFilialModel) : Observable<DdaTermModel>;
  abstract getLegacyMembership(filiais: boolean) : Observable<DdaLegadoModel>;
}
