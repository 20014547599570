import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { RefundModel } from '@src/core/domain/pix/refund.model';
import { RefundRepository } from '@src/core/repositories/pix/refund.repository';

@Injectable({
  providedIn: 'root'
})
export class GetRefundHistoric implements UseCase<any, RefundModel> {

  constructor(private refundRepository: RefundRepository) { }

  execute(params: any): Observable<RefundModel> {
    return this.refundRepository.getHistoric(params)
  }  
}
