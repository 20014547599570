import { SchedulingDataShare } from '@src/core/usecases/data-share/scheduling-data-share.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { ExtractModel } from '@src/core/domain/extract/extract.model';
import { GetSchedulingExtractsUsecase } from '@src/core/usecases/extract/get-scheduling-extracts.usecase';
import * as Util from '@src/shared/util-common';
import * as moment from 'moment';

@Component({
  selector: 'fibra-scheduling-list',
  templateUrl: './scheduling-list.component.html',
  styleUrls: ['./scheduling-list.component.scss'],
})
export class SchedulingListComponent implements OnInit, OnDestroy {
  schedulings: Array<ExtractModel> = [];
  @Output() extractBlock: EventEmitter<ExtractModel>;
  @Input() balance: any;
  @Input() params;
  public isGreaterThan180Days;
  public loading: boolean;
  public hide: boolean;
  public iconPath = (cod: string, desc: string) => Util.iconPath(cod, desc);
  public conta = ''
  public agencia = ''
  public cpfCnpj = ''
  public nomeRemet = ''

  constructor(
    private getSchedulingExtracts: GetSchedulingExtractsUsecase,
    private shareIsGreater: SchedulingDataShare
  ) {
    this.extractBlock = new EventEmitter();
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('extrato_all_pdf')
    sessionStorage.removeItem('informations_pdf')
  }

  /**
   * @description
   * Add a Title to componente on view
   */

  ngOnInit() {
    this.requestSchedule();
  }


  private requestSchedule = () => {
    this.loading = true;
    this.isGreater(this.params);

    this.getSchedulingExtracts
      .execute(JSON.parse(this.params))
      .subscribe(this.requestSuccess, this.requestError, this.requestComplete);
  };
  private requestSuccess = (value: ExtractModel) => {
    this.schedulings = Util.verifyArray(value.data);
    this.extractBlock.emit(value.data);
    this.hide = Util.isEmpty(this.schedulings);
    console.log(this.schedulings);

    //PDF
    //this.data = value.data
    sessionStorage.setItem('extrato_all_pdf', JSON.stringify(value.data));
    sessionStorage.setItem('informations_pdf', '{"exibir":"Agendados", "periodo": "' + Util.calcDiffInDays(this.params) + ' dia(s)", "report_name":"Extrato","name":"extrato-all"}')

    //Excel
    let paramsFilter = JSON.parse(this.params)
    let dataInicio = paramsFilter.dataInicio;
    let dataFinal = paramsFilter.dataFinal;

    dataFinal = moment(dataFinal, "YYYY-MM-DD").format("YYYY-MM-DD");
    dataInicio = moment(dataInicio, "YYYY-MM-DD").format("YYYY-MM-DD");
    sessionStorage.setItem('extract_excel', '{"tipo":"agendadas", "dataInicio":"' + dataInicio + '", "dataFinal":"' + dataFinal + '"}')

  };

  private requestError = (err) => {
    // PDF
    const data = [];
    sessionStorage.setItem('extrato_all_pdf', JSON.stringify(data));
    sessionStorage.setItem('informations_pdf', '{"exibir":"Agendados", "periodo": "' + Util.calcDiffInDays(this.params) + ' dia(s)", "report_name":"Extrato","name":"extrato-all"}');

    //Excel
    let paramsFilter = JSON.parse(this.params)
    let dataInicio = paramsFilter.dataInicio;
    let dataFinal = paramsFilter.dataFinal;

    dataFinal = moment(dataFinal, "YYYY-MM-DD").format("YYYY-MM-DD");
    dataInicio = moment(dataInicio, "YYYY-MM-DD").format("YYYY-MM-DD");
    sessionStorage.setItem('extract_excel', '{"tipo":"agendadas", "dataInicio":"' + dataInicio + '", "dataFinal":"' + dataFinal + '"}');
    
    this.hide = Util.isEmpty(this.schedulings);
    console.warn(err);
    this.loading = false;
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.schedulings);
    this.loading = false;
  };

  isNull = (value) => {
    return Util.isNull(value);
  };

  formatDate(date: string) {
    moment.locale('pt-br');
    const today = moment().startOf('day');
    const patternDate = moment(date.split('T')[0]);
    const diffDays = today.diff(patternDate, 'days');

    return diffDays === 0
      ? 'Hoje, ' + patternDate.format('LL')
      : diffDays === 1
        ? 'Ontem, ' + patternDate.format('LL')
        : diffDays > 1 && diffDays <= 7
          ? patternDate.format('dddd').charAt(0).toUpperCase() +
          patternDate.format('dddd').slice(1) +
          ', ' +
          patternDate.format('LL')
          : patternDate.format('DD') +
          ' de ' +
          patternDate.format('MMMM') +
          ' de ' +
          patternDate.format('YYYY');
  }

  isGreater(obj) {
    let date;

    if (obj) {
      date = JSON.parse(obj).dataFinal;
    }
    moment.locale('pt-br');
    const today = moment().startOf('day');
    const patternDate = moment(date);
    const diffDays = today.diff(patternDate, 'days');

    this.isGreaterThan180Days = diffDays <= -180 && true;
    if (this.isGreaterThan180Days) {
      this.shareIsGreater.setValue(this.isGreaterThan180Days);
      return;
    }
  }

  public isPix(item): boolean {
    return (<string>item.desc_historico).toUpperCase().indexOf("PIX") > -1;
  }

  public HasRemetInfo(txt: string, nomeRemet: string, cnpj: string, agencia: string, conta: string) {

    txt = txt || '';
    nomeRemet = nomeRemet || '';
    cnpj = cnpj || '';

    if (txt.trim() !== "" && nomeRemet.trim() !== "" && cnpj.trim() !== "") {
      const splits = txt.split(' ')
      // this.agencia = "AG:"+splits[1] 
      // this.conta = "CC:"+txt// "CC:"+splits[0]
      this.agencia = "AG:" + agencia.padStart(4, '0')
      this.conta = "CC:" + conta// "CC:"+splits[0]
      this.cpfCnpj = this.FormatcpfCnpj(cnpj) // cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      this.nomeRemet = nomeRemet

      // if(splits[0].length > 1 &&
      //    splits[1].length > 2)
      //      return true
      // else
      //   return false

      return true
    }
    else {
      return false
    }
  }

  public formatCpfCnpj = (value: string) => Util.formatCpfCnpj(value);

  public FormatcpfCnpj(v: string) {

    v = v.replace(/\D/g, "")
    let v2 = +(v)
    v = v2.toString();

    //Remove tudo o que não é dígito


    if (v.length <= 11) { //CPF



      v = v.padStart(11, '0')

      //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2")

      //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d)/, "$1.$2")

      //Coloca um hífen entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

    } else { //CNPJ

      v = v.padStart(14, '0')


      //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})(\d)/, "$1.$2")

      //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")

      //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")

      //Coloca um hífen depois do bloco de quatro dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }
    return v
  }

  public temDescricao(item): boolean {
    return item.compl_det && item.compl_det.desc_nome && item.compl_det.desc_nome.trim() != '';
  }

  public temInstituicao(item): boolean {
    return item.compl_det && item.compl_det.dados_conta && item.compl_det.dados_conta.nome_banco && item.compl_det.dados_conta.nome_banco != '0' && item.compl_det.dados_conta.nome_banco != '00';
  }

  public formatAccountNumber(accNumber: string): string {
    return `${accNumber.substring(0, accNumber.length - 1)}-${accNumber[accNumber.length - 1]}`;
  }

  public temChave(item): boolean {
    return item.compl_det && item.compl_det.dados_conta && item.compl_det.dados_conta.chave;
  }
}
