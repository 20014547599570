import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { SelectBeneficiaryDataShare } from '@src/data/repository/data-share-repository';
import { RolesService } from '@src/shared/services/roles.service';
import { ToastErrorMessageComponent } from '../toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-details-list-beneficiaries',
  templateUrl: './details-list-beneficiaries.component.html',
  styleUrls: ['./details-list-beneficiaries.component.scss'],
})
export class ListBeneficiariesDetailsComponent implements OnInit {
  @Input() balanceData;
  @Input() indice;
  @Output() emitBeneficiary: EventEmitter<any> = new EventEmitter();
  @Output() emitDelete: EventEmitter<any> = new EventEmitter();
  @Output() emitToast: EventEmitter<any> = new EventEmitter();
  
  public typeColorToast;
  public REDCOLOR = '#ED6C6C';

  constructor(
    private router: Router,
    private shareBeneficiary: SelectBeneficiaryDataShare,
    private roles: RolesService
  ) {}

  ngOnInit() {}

  selectBeneficiary(item, item2) {
    this.typeColorToast = this.REDCOLOR;
    var permissoes = this.roles.get();
    if(permissoes.includes('TRANSF.TRANSF_OTR_TIT_BC') && item2.cod_banco != 224 && item.flag_titularidade == 1){  
    this.emitBeneficiary.emit({ beneficiary: item, quote: item2 });
    this.shareBeneficiary.setValue({ beneficiary: item, quote: item2 });

    }else if(permissoes.includes('TRANSF.TRANSF_MSM_TIT_OTR_BC') && item2.cod_banco != 224 && item.flag_titularidade == 0){
      this.emitBeneficiary.emit({ beneficiary: item, quote: item2 });
      this.shareBeneficiary.setValue({ beneficiary: item, quote: item2 });

    }else if(permissoes.includes('TRANSF.TRANSF_OTR_TITU') && item2.cod_banco == 224 && item.flag_titularidade == 1){
      this.emitBeneficiary.emit({ beneficiary: item, quote: item2 });
      this.shareBeneficiary.setValue({ beneficiary: item, quote: item2 });
    
    }else if(permissoes.includes('TRANSF.TRANSF_MSM_TITU') && item2.cod_banco == 224 && item.flag_titularidade == 0){
      this.emitBeneficiary.emit({ beneficiary: item, quote: item2 });
      this.shareBeneficiary.setValue({ beneficiary: item, quote: item2 });

    }else{
      this.emitToast.emit("Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.");
    }
    // this.emitBeneficiary.emit({ beneficiary: item, quote: item2 });
    // this.shareBeneficiary.setValue({ beneficiary: item, quote: item2 });
  }

  deleteBeneficiary(item) {
    console.log(item);
    this.emitDelete.emit(item);
  }

  pad(num:number, size:number): string {
      let s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
  }  
}
