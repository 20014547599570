<div class="main-selector">
  <fibra-company-list ></fibra-company-list>
</div>
<div class="content-page" 
  *fibraHasRole="'COB.CAD_TIT,QUALQUER_CONTA.COB.CAD_TIT'"
>
  <fibra-breadcrumb
    bottom="-20"
    infoAccount="Cobrança / Nova cobrança"
    roule="Cadastrar título"
    arrow="false"
    infoText='Preencha os campos abaixo com as informações do pagador. Depois clique em "Avançar" para continuar o processo.'
  ></fibra-breadcrumb>

  <ng-template #firstStep>
    <fibra-charge-first-step submit="true"></fibra-charge-first-step>
  </ng-template>
  <ng-template #secondStep>
    <fibra-charge-second-step submit="true"></fibra-charge-second-step>
  </ng-template>

  <fibra-stepper
    [stepObj]="step"
    [firstStep]="firstStep"
    [secondStep]="secondStep"
  ></fibra-stepper>
</div>
