import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fibra-openbanking-onboarding',
  templateUrl: './openbanking-onboarding.component.html',
  styleUrls: ['./openbanking-onboarding.component.scss']
})
export class OpenbankingOnboardingComponent {
  public pagina: number = 0;
  public busy: boolean = false;

  @Input() readonly: boolean = false;
  @Input() canCancel: boolean = true;
  
  @Output() onOk: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  
  public prior(): void {
    if (this.pagina > 0) {
      this.pagina--;
    }    
  }

  public next(): void {
    if (this.pagina < 1) {
      this.pagina++;
    }
  }

  public ok(): void {
    if (!this.busy) {
      this.onOk.emit();
    }    
  }

  public cancel(): void {
    if (!this.busy) {
      this.onCancel.emit();
    }
  }
}
