import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getInitialHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { CompanyResponse, SessaoResponse, UserResponse } from './impersonate.entity';
import { handleError } from '@src/shared/util-common';

@Injectable({
  providedIn: 'root',
})
export class ImpersonateRepository {

  impersonating: BehaviorSubject<boolean> = new BehaviorSubject(sessionStorage.getItem("simulacao") === "true");

  constructor(private http: HttpClient) { }

  searchCompany(company: string): Observable<CompanyResponse> {
    const httpOptions = {
      headers: getInitialHeader(),
    };
    try {
      return this.http
        .get<CompanyResponse>(
          `${environment.URLBase}v1/simulacao/empresa/${company}`,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }

  }


  searchUsers(company: number): Observable<UserResponse> {
    const httpOptions = {
      headers: getInitialHeader(),
    };
    try {
      return this.http
        .get<UserResponse>(
          `${environment.URLBase}v1/simulacao/empresa/${company}/usuarios`,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }

  }



  getSession(company: number, email: string, name: string): Observable<SessaoResponse> {
    const httpOptions = {
      headers: getInitialHeader(),
    };
    try {
      const params = { 
        nome: name,
        email,
        cod_empresa: company
       };
      return this.http
        .post<SessaoResponse>(
          `${environment.URLBase}v1/simulacao/sessao`,
          params,
          httpOptions
        )
    }
    catch (error) {
      handleError(error);
    }
  }


  broadcastImpersonate(b: boolean){
    this.impersonating.next(b);
  }


}
