<div class="content-transferir">
    <div class="has-content" id="nova-chave">
        <div class="arrow">
            <img class="arrow-img" (click)="goBack()" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar">
                Voltar</span>
        </div>

        <div class="data-refuse-header" *ngFor="let dt of dataBeneficiario$ | async">
            <div>
                <span class="title-refuse-data"> É possível devolver até</span>
                <span class="value-refuse-data">{{(dt.valorDisponivelDevolucao === 0 ? dt.valorTransferencia : dt.valorDisponivelDevolucao) | currency: 'BRL'}}</span>
            </div>

            <div class="contentRow">
                <div class="row1">
                    <span class="title-refuse-data"> Data e Horas</span>
                    <span class="value-refuse-data-2">{{dt.dataPagamento | date: 'dd/MM/yyyy'}} - {{dt.dataPagamento | date: 'H:mm:ss'}}</span>
                </div>
                <div class="row2">
                    <span class="title-refuse-data"> Nome</span>
                    <span class="value-refuse-data-2">{{dt.nomePagador}}</span>
                </div>
                <div class="row2">
                    <span class="data-title"> CPF/CNPJ</span>
                    <span class="value-refuse-data-2">{{ dt.cpfcnpjPagadorMascarado }}</span>
                </div>

            </div>

            <div class="contentRow">
                <div class="row1">
                    <span class="title-refuse-data"> Instituição</span>
                    <span class="value-refuse-data-2">{{dt.bancoPagador || 'Banco Fibra'}}</span>
                </div>
                <div *ngIf="false" class="row2">
                    <span class="title-refuse-data"> Dados da conta </span>
                    <span class="value-refuse-data-2">AG: {{ dt.numeroAgenciaPagadorMascarado }} | CC: {{ dt.numeroContaPagadorMascarado | account }}</span>
                </div>
            </div>
        </div>

        <!-- HISTORICO -->
        <div class="historic">
            <div class="titleHistoric" *ngIf="loadingHistoric || historic.length > 0">
                <span>
                    Histórico de devoluções 
                    <div class="d-flex positionLoadingHistoric" *ngIf="loadingHistoric">
                        <div class="spinner-border text-primary customSpinner" role="status">
                        </div>
                    </div>
                </span> 
            </div>

            
            <div *ngIf="historic.length > 0">
                <div class="blockData" *ngFor="let hist of historic.slice(0).reverse()">
                    <div class="dateTitleHistoric"><span>{{hist.date | date: 'dd/MM/yyyy'}}</span></div>
                    <div class="data-historic" *ngFor="let val of hist.lancamentos">
                        <div class="dateHistoric"><span>Pix devolvido:</span> <span class="money">- {{val.valor | currency: 'BRL'}}</span></div>
                    </div>
                </div>
            </div>


            <div class="maxPrazo">
                <span>Prazo máximo para realizar devolução</span>
                <span>{{this.dataLimite | date: 'dd/MM/yyyy'}}</span>
            </div>
        </div>

        <div class="transferir-formulario">
            <div class="steps">

                <div class="step">
                    <div class="title">
                        <div class="number">
                            <img src="/assets/svg/step_1.svg" alt="step1">
                            <span class="number-title"> Valor de devolução </span>
                        </div>
                    </div>
                    <div class="data-div">
                        <div class="data">
                            <span class="data-title" style="margin-left: 16px;
                            margin-bottom: 12px;"> Valor a ser devolvido</span>
                            <input type="text" 
                                maxlength="18" 
                                currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',  align: 'left', allowNegative: false, allowZero: false }"
                                placeholder="R$ 0,00" 
                                [(ngModel)]="transferValue" 
                                class="line-code-bar"
                                style="width: 200px;" 
                                name="data" 
                                (blur)="changeAmmount()"
                                [disabled]="this.payerData.dataRefund[0].bloqueioCautelar"
                                >
                            <span *ngIf="warningValue" class="warningValue"><img src="assets/svg/ico_alert_red.svg"
                                    alt="ico_alert_red"> O valor deve ser menor ou igual a {{valueRefund | currency: 'BRL'}}.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="step">
                    <div class="title">
                        <div class="number">
                            <img src="/assets/svg/step_2.svg" alt="step2">
                            <span class="number-title"> Motivo da devolução </span>
                        </div>
                    </div>
                    <div class="descricao-div">
                        <div class="data-div" style="flex-direction: column; padding-left: 40px;">
                            <div class="title-div">
                                <span class="descricao-title"> Descrição</span>
                                <span class="descricao-length" [ngClass]="{ redCounter: char == 0 }"> {{ char }}
                                    caracteres</span>
                            </div>
                            <textarea class="text-area" 
                                [(ngModel)]="textArea" 
                                id="w3review" 
                                name="w3review"
                                maxlength="30" 
                                rows="6" 
                                cols="50" 
                                (keyup)="limitTextarea($event.target.value)"
                                (paste)="util.clearSpecialChar($event)"
                                (input)="util.clearSpecialChar($event)"
                                ></textarea>
                        </div>
                    </div>
                </div>
                <div class="step">
                    <div class="title">
                        <div class="number">
                            <img src="/assets/svg/step_3.svg" alt="step3">
                            <span class="number-title"> Conta para débito</span>
                        </div>
                    </div>
                    <div class="account-info">
                        <div class="accounts-div">
                            <fibra-slide-account-selector-pix [active]="contaSelecionada"
                                [label]="'Selecione a conta a ser debitada'" [data]="accountData"
                                (emitAccount)="setAccountDebit($event)">
                            </fibra-slide-account-selector-pix>
                        </div>
                        <div class="button">
                            <button type="button" class="button-continuar" (click)="setTransferInfos()"
                                [disabled]="validationInfo()">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
</div>

<fibra-modal-cheque-empresa-pix *ngIf="chequeEmpresaPix" (closeEvent)="closeModalPix($event)"
[conta]="contaToDebit.desc_modalidade"
    [valor]="valorChequeEmpresa"></fibra-modal-cheque-empresa-pix>