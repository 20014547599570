import {
  Component,
  OnInit,
  Input,
  Output,
  forwardRef,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDate,
  NgbCalendar,
} from '@ng-bootstrap/ng-bootstrap';
import { MomentDateFormatter } from '@src/shared/formatDate';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { dateMask, rneMask, cpfMask } from '@src/shared/util-common';
import * as moment from 'moment';

const noop = () => {
};

@Component({
  selector: 'fibra-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useValue: new MomentDateFormatter() },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent implements OnInit {
  @Input() public set value(value) {
    if (value !== undefined && this.valueControl !== value) {
      this.valueControl = value;
      this.onChange(value);
    }
  }

  constructor(calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.date = calendar.getToday();
    this.minDate = calendar.getToday();
    this.dateSelected = new EventEmitter();
    this.dateInvalid = new EventEmitter();
  }
  readonly DT_FORMAT = 'DD/MM/YYYY';
  @Input() public placeholder: string;
  @Input() placement: string;
  @Input() label: string;
  @Input() inputHidden: boolean;
  @Input() minDate: NgbDate;
  @Input() maxDate: NgbDate;
  @Input() date: NgbDate;
  @Input() disabled: boolean;
  @Output() dateSelected: EventEmitter<NgbDate>;
  @Output() dateInvalid: EventEmitter<string>;
  public valueControl = '';
  public hoveredDate: null;
  public dateMask = dateMask;
  public cpfMask = cpfMask;
  public rneMask = rneMask;
  public _readonlyInput: boolean;
  public d;

  // verificar se foi Adicionado, assim deixar input como readonly
  get readonlyInput(): boolean {
    return this._readonlyInput;
  }

  @Input() set readonlyInput(value: boolean) {
    this._readonlyInput = value;
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  innerValue: any;

  private static isOverflowing(selector) {
    const overflowingElement = document.querySelector(selector);
    return overflowingElement.scrollWidth - overflowingElement.clientWidth;
  }

  ngOnInit(): void {
    let resizeTimer;
    window.onresize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        this.positionDatePopup();
      }, 150);
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // console.log(this.date)
  }

  public isPastDate(date) {
    if (this.minDate.after(date)) {
      return true;
    }
  }

  isDateInit(date: NgbDate) {
    return (
        date.equals(this.minDate)
    );
  }

  isDateEnd(date: NgbDate) {
    return (
        date.equals(this.maxDate)
    );
  }

  public positionDatePopup() {
    const datePicker = Array.from(
      document.getElementsByClassName(
        'dropdown-menu show'
      ) as HTMLCollectionOf<HTMLElement>
    )[0];
    if (datePicker) {
      datePicker.style.marginLeft = '0px';
      const amountOverflowed = DatepickerComponent.isOverflowing('.content-page');

      if (amountOverflowed > 0) {
        datePicker.style.marginLeft = '-' + (amountOverflowed + 15) + 'px';

        if (DatepickerComponent.isOverflowing('.content-page') === amountOverflowed) {
          datePicker.style.marginLeft = '0';
          datePicker.style.marginRight = '-' + (amountOverflowed + 15) + 'px';
        }
      }
    }
  }

  public onDateSelection(date: NgbDate) {
    this.date = date;
    var tipo = typeof this.date;
    this.dateSelected.emit(date);
  }
  /*
    FormControl methods
  */
  public writeValue(value: any): void {
    this.value = value;
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void { }

  private onChange = (value: any) => { };

  /*   registerOnChange(fn: any): void {
      this.onChangeCallback = fn;
  } 
  
  registerOnTouched(fn: any): void {
      this.onTouchedCallback = fn;
  }
  
  onChange(event) {
      this.value = event;
      this.onBlur(event);
  } */

  public addMask = (value: any) => {
    return {
      mask: value,
      placeholderChar: ' ',
      guide: true,
      modelClean: true,
    };
  }

  dataChanged(value: Event) {
    var isDate = value.target['value'].replaceAll(" ", "");
    if (isDate && isDate.length == 10) {
      var teste = value.target['value']
      const mdt = moment(teste, this.DT_FORMAT, true);
      if (!mdt.isValid()) {
        this.dateInvalid.emit("invalid");
      }
      else {
        let date = value.target['value'].split("/");
        var data = {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
        this.date = new NgbDate(data.year, data.month, data.day)
        this.dateSelected.emit(this.date);
      }
    }
  }

  onBlur(value) {
    this.innerValue = value;
    this.onChangeCallback(this.innerValue);
  }

  todate(value) {
    this.value = value;
    this.dateSelected.emit(value);
  }
}
