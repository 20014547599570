<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <div class="main-divisor"></div>
  <fibra-profile-list [contasPermitidas]="contasPermitidas"></fibra-profile-list>
  <fibra-debug-dda></fibra-debug-dda>
</div>
<div
  class="content-page">
  <fibra-breadcrumb
    bottom="0"
    infoAccount="Transações"
    roule="Débito Direto Autorizado (DDA)"
    arrow="false"
    infoText=""></fibra-breadcrumb>
  <div
    class="mb-0 fibra-navbar"
    [ngStyle]="{ 'justify-content': isMaster ? 'space-between' : 'left' }">
    <div
      [hidden]="esconderDadosAdesaoPendente || esconderDadosCancelamentoPendente"
        *fibraHasRole="'MASTER,DDA.DDA_CONS_BOL,DDA.DDA_REALIZA_PAG_BOL,DDA.DDA_GER_ARQ_CNAB'"
        [ngClass]="selectedNavBar === 'boletos' ? 'item-selected' : 'item-unselected'"
        (click)="changeNavbarClass('boletos')">

        <span
          [ngClass]="
        selectedNavBar === 'boletos'
          ? 'item-font-selected'
          : 'item-font-unselected'
        ">
          Boletos
      </span>
    </div>
    <div
      [hidden]="acessoMasterBackup"
      *fibraHasRole="'MASTER'"
      [ngClass]="
        selectedNavBar === 'termos-dados'
          ? 'item-selected'
          : 'item-unselected'
      "
      id="termos-dados"
      (click)="changeNavbarClass('termos-dados')">
      <span
        [ngClass]="
      selectedNavBar === 'termos-dados'
        ? 'item-font-selected'
        : 'item-font-unselected'
    ">
        Termos e dados
      </span>
    </div>
    <div
    [hidden]="esconderDadosAdesaoPendente || esconderDadosCancelamentoPendente || acessoMasterBackup" 
      *fibraHasRole="'MASTER'"
      [ngClass]="
    selectedNavBar === 'aprovacoes'
      ? 'item-selected'
      : 'item-unselected'
  "
      id="aprovacoes"
      (click)="changeNavbarClass('aprovacoes')">
      <span
        [ngClass]="
      selectedNavBar === 'aprovacoes'
        ? 'item-font-selected'
        : 'item-font-unselected'
    "
        class="item-font-unselected">
        Aprovações</span>
    </div>
    <div
      *fibraHasRole="'MASTER,DDA.DDA_CONS_HIST'"
      [ngClass]="
    selectedNavBar === 'historico' ? 'item-selected' : 'item-unselected'
  "
      id="historico"
      (click)="changeNavbarClass('historico')">
      <span
        [ngClass]="
      selectedNavBar === 'historico'
        ? 'item-font-selected'
        : 'item-font-unselected'
    ">Histórico</span>
    </div>
  </div>


  <div class="row">

    <div class="col-12 mt-2">
      <ng-container
        [ngSwitch]="selectedNavBar">
        <fibra-termos-dados *ngSwitchCase="'termos-dados'"></fibra-termos-dados>
        <fibra-aprovacao-masters *ngSwitchCase="'aprovacoes'"></fibra-aprovacao-masters>
        <fibra-historico *ngSwitchCase="'historico'"></fibra-historico>
        <fibra-boletos *ngSwitchDefault></fibra-boletos>
      </ng-container>
    </div>
  </div>
</div>