import { Component, EventEmitter, Injectable, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fibra-pix-limit-transactional',
  templateUrl: './pix-limit-transactional.component.html',
  styleUrls: ['./pix-limit-transactional.component.scss']
})

export class PixLimitTransactionalComponent implements OnInit, OnDestroy, OnChanges {

  @Input() title?: string;
  @Input() transNocturnal: boolean = true; // Exibir/ocultar limites por transacoes noturnas
  @Input() transacDayTime: boolean = true; // Exibir/ocultar limites por transacoes diurnas
  @Input() dataLimits: Observable<{}> = new Observable<{}>(); // Array com dados dos limites
  @Input() set editar(val: boolean) {
    this.editForm.next(val);
  }
  @Input() saveData: boolean;
  @Input() titleObject?: string;
  @Output() flagFormChange: EventEmitter<any> = new EventEmitter();
  @Output() dataFormChange: EventEmitter<any> = new EventEmitter();
  @Output() observeFormStatus: EventEmitter<{}> = new EventEmitter();
  @Output() observeFormStatusMin: EventEmitter<{}> = new EventEmitter();

  limitList: FormGroup;
  editForm: Subject<any>;
  // forValidatorMaxLimit: Subject<any>;
  _controlEditForm: boolean = false;
  limiteData: {};
  maxLimiteAllow: Observable<number> = new Observable<number>();
  controlExecuteMethod: boolean = true;
  controlValues;
  arrAllInputErrorMin = [{
    diurnoMesma: false,
    diurnoOutraPJ: false,
    diurnoOutraPF: false,
    noturnoMesma: false,
    noturnoOutraPJ: false,
    noturnoOutraPF: false,
    transacaoDiurnaMesma: false,
    transacaoDiurnaOutraPJ: false,
    transacaoDiurnaOutraPF: false,
    transacaoNoturnaMesma: false,
    transacaoNoturnaOutraPJ: false,
    transacaoNoturnaOutraPF: false,
  }];

  constructor(private formBuild: FormBuilder) {
    this.editForm = new Subject();
    // this.forValidatorMaxLimit = new Subject();

    this.limitList = this.formBuild.group({
      diurnoMesma: [Number(''), Validators.required],
      diurnoOutraPJ: [Number(''), Validators.required],
      diurnoOutraPF: [Number(''), Validators.required],
      noturnoMesma: [Number(''), Validators.required],
      noturnoOutraPJ: [Number(''), Validators.required],
      noturnoOutraPF: [Number(''), Validators.required],
      transacaoDiurnaMesma: [Number(''), [Validators.required]],
      transacaoDiurnaOutraPJ: [Number(''), Validators.required],
      transacaoDiurnaOutraPF: [Number(''), Validators.required],
      transacaoNoturnaMesma: [Number(''), Validators.required],
      transacaoNoturnaOutraPJ: [Number(''), Validators.required],
      transacaoNoturnaOutraPF: [Number(''), Validators.required]
    });
  }

  ngOnInit() { 
    //this.setValueLimit();
    this.verifyIfValueChangedInForm();
    //this.formStatusCheck();

    this.editForm.subscribe(r => {
      this.limitList.controls['transacaoDiurnaMesma'].setErrors(null);
      this.limitList.controls['noturnoMesma'].setErrors(null);
      this.limitList.controls['transacaoNoturnaMesma'].setErrors(null)
      if (!r) this.setValueLimit();
      if (r) this.varifydispatchInitialMaxValue(this.limitList.value);
      this._controlEditForm = r;
    });


  }

  ngOnDestroy(): void {
    //Contente here
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['dataLimits'] !== 'undefined' && changes['dataLimits'].currentValue) {
      this.dataLimits.subscribe((r) => {

        //Reseta valor da variavel de controle
        this.controlExecuteMethod = true;

        // Atribui à variavel, os dados de limites vindos do componente pai
        this.limiteData = r;

        // Atribui à variavel, o valor do limite máximo vindo do componente pai
        this.maxLimiteAllow = new Observable<number>((obs) => obs.next(this.limiteData[0].maxLimiteValue));

        this.formStatusCheck();
        //this.forValidatorMaxLimit.next({})
        this.setValueLimit();
      })
    }

    if (typeof changes['saveData'] !== 'undefined' && changes['saveData'].currentValue) {
      this.limitList.setValue(this.limitList.value);
      this._controlEditForm = false;
      this.dataFormChange.emit(this.titleObject ? { title: this.titleObject, ...this.limitList.value } : this.limitList.value);
    }
  }

  verifyIfValueChangedInForm() {

    this.limitList.valueChanges.subscribe(val => {

      if(this.controlExecuteMethod) this.controlValues = val;
      this.controlExecuteMethod = false;
      
      let control = Object.keys(this.controlValues).some(key => this.controlValues[key] !== val[key]);
      if(control){
        this.flagFormChange.emit({flg: control, value: val});
        return
      }
      this.flagFormChange.emit({flg: control, value: this.controlValues});      
    })
  }

  // Seta valores nos inputs
  setValueLimit() {
    let values = {
      diurnoMesma: this.limiteData[0].diurnoMesma,
      diurnoOutraPJ: this.limiteData[0].diurnoOutraPJ,
      diurnoOutraPF: this.limiteData[0].diurnoOutraPF,
      noturnoMesma: this.limiteData[0].noturnoMesma,
      noturnoOutraPJ: this.limiteData[0].noturnoOutraPJ,
      noturnoOutraPF: this.limiteData[0].noturnoOutraPF,
      transacaoDiurnaMesma: this.limiteData[0].transacaoDiurnaMesma,
      transacaoDiurnaOutraPJ: this.limiteData[0].transacaoDiurnaOutraPJ,
      transacaoDiurnaOutraPF: this.limiteData[0].transacaoDiurnaOutraPF,
      transacaoNoturnaMesma: this.limiteData[0].transacaoNoturnaMesma,
      transacaoNoturnaOutraPJ: this.limiteData[0].transacaoNoturnaOutraPJ,
      transacaoNoturnaOutraPF: this.limiteData[0].transacaoNoturnaOutraPF,
    }

    this.limitList.setValue(values);
  }

  // Dispara um valor inicial ao componente pai do tipo 'max' para controlar o estado do botão 'Alterar e Salvar'
  varifydispatchInitialMaxValue(formValue) {
    this.maxLimiteAllow.subscribe(r => {
      let value = Object.keys(formValue).some(s => formValue[s] > r)
      if (value) this.observeFormStatus.emit({ max: true })
    });
  }

  // Dispara um valor ao componente pai, se houver erro do tipo 'max' em algum input, para controlar o estado do botão 'Alterar e Salvar'
  formStatusCheck() {

    this.limitList.get('diurnoMesma').valueChanges.subscribe(f => {

      //Perido VS Transacao
      this.periodVsTransaction(f, 'transacaoDiurnaMesma');

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('diurnoMesma', 'min');
    });

    this.limitList.get('diurnoOutraPJ').valueChanges.subscribe(f => {

      //Perido VS Transacao
      this.periodVsTransaction(f, 'transacaoDiurnaOutraPJ');

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('diurnoOutraPJ', 'min');
    });

    this.limitList.get('diurnoOutraPF').valueChanges.subscribe(f => {

      //Perido VS Transacao
      this.periodVsTransaction(f, 'transacaoDiurnaOutraPF');

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('diurnoOutraPF', 'min');
    });

    this.limitList.get('noturnoMesma').valueChanges.subscribe(f => {

      //Perido VS Transacao
      this.periodVsTransaction(f, 'transacaoNoturnaMesma');

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('noturnoMesma', 'min');
    });

    this.limitList.get('noturnoOutraPJ').valueChanges.subscribe(f => {

      //Perido VS Transacao
      this.periodVsTransaction(f, 'transacaoNoturnaOutraPJ');

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('noturnoOutraPJ', 'min');
    });

    this.limitList.get('noturnoOutraPF').valueChanges.subscribe(f => {

      //Perido VS Transacao
      this.periodVsTransaction(f, 'transacaoNoturnaOutraPF');

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('noturnoOutraPF', 'min');

    });

    // Transações diurnas
    this.limitList.get('transacaoDiurnaMesma').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('transacaoDiurnaMesma', 'min');

      // Transacao VS Periodo
      this.transactionVsPeriod(f, 'diurnoMesma', 'transacaoDiurnaMesma');
    });

    this.limitList.get('transacaoDiurnaOutraPJ').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('transacaoDiurnaOutraPJ', 'min');

      // Transacao VS Periodo
      this.transactionVsPeriod(f, 'diurnoOutraPJ', 'transacaoDiurnaOutraPJ');
    });

    this.limitList.get('transacaoDiurnaOutraPF').valueChanges.subscribe(f => {
      
      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('transacaoDiurnaOutraPF', 'min');

      // Transacao VS Periodo
      this.transactionVsPeriod(f, 'diurnoOutraPF', 'transacaoDiurnaOutraPF');
    });

    // Transações noturnas
    this.limitList.get('transacaoNoturnaMesma').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('transacaoNoturnaMesma', 'min');

      // Transacao VS Periodo
      this.transactionVsPeriod(f, 'noturnoMesma', 'transacaoNoturnaMesma');
    });

    this.limitList.get('transacaoNoturnaOutraPJ').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('transacaoNoturnaOutraPJ', 'min');

      // Transacao VS Periodo
      this.transactionVsPeriod(f, 'noturnoOutraPJ', 'transacaoNoturnaOutraPJ');
    });

    this.limitList.get('transacaoNoturnaOutraPF').valueChanges.subscribe(f => {

      this.checkInputsErrorMax('max', { max: true });
      this.checkOnlyInputErrorMin('transacaoNoturnaOutraPF', 'min');

      // Transacao VS Periodo
      this.transactionVsPeriod(f, 'noturnoOutraPF', 'transacaoNoturnaOutraPF');
    });
  }

  checkInputsErrorMax(error: string, value: {}) {
    let arr = [];

    Object.keys(this.limitList.controls).forEach(key => {
      arr.push(this.limitList.get(key).hasError(error));
    });

    if (arr.includes(true)) {
      this.observeFormStatus.emit(value);
      return;
    }
    this.observeFormStatus.emit({ max: false });
  }

  checkOnlyInputErrorMin(key: string, error: string) {
    //console.log(this.limitList.get(key).hasError(error))
    if(this.limitList.get(key).hasError(error)){
      this.arrAllInputErrorMin[0][key] = true;
      this.observeFormStatusMin.emit({anyErrorMin: this.arrAllInputErrorMin, min: true });
      return
    }
    this.arrAllInputErrorMin[0][key] = false;
    this.observeFormStatusMin.emit({anyErrorMin: this.arrAllInputErrorMin, min: true });
  }

  periodVsTransaction(clBk, field: string): void{
    if(clBk < this.limitList.get(field).value){
      this.limitList.get(field).setErrors({max: true})
      this.observeFormStatus.emit({ max: true });
    }

    if(clBk >= this.limitList.get(field).value){
      this.limitList.get(field).setErrors(null)
    }
  }

  transactionVsPeriod(clbk, fieldPeriod, fieldTransaction): void{
    if(clbk > this.limitList.get(fieldPeriod).value){
      this.limitList.get(fieldTransaction).setErrors({max: true})
      this.observeFormStatus.emit({ max: true, tr: true });
    }

    if(clbk <= this.limitList.get(fieldPeriod).value){
      //this.limitList.get(fieldTransaction).setErrors(null)
    }
  }
}
