import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[fibraNotifyControl]'
})
export class NotifyControlDirective {

  @Output() notifyEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private el: ElementRef) { }

  @HostListener('mouseenter') onMouseEnter() {
    const childNtfy = this.el.nativeElement.lastChild;
    childNtfy.classList.add('showNotify')
    childNtfy.classList.add('animate__animated');
    childNtfy.classList.add('animate__fadeIn');
    childNtfy.style.setProperty('--animate-duration', '300ms');
  }

  @HostListener('mouseleave') onMouseLeave() {
    const childNtfy = this.el.nativeElement.lastChild;
    childNtfy.classList.remove('showNotify')
  }

}
