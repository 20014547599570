import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  HostBinding,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'fibra-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['Mask', 'MaskCurrency', 'MaskPercentage'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() value: string;
  @Input() public mask: string;
  @Input() public prefixIcon: string;
  @Input() public sufixIcon: string;
  @Input() public placeholder = '';
  @Input() public disabled = false;
  @Input() public maxLength: number;
  @Input() public minLength: number;
  @Output() public clickPrefix: EventEmitter<void>;
  @Input() public currency: boolean;
  @Input() public percentage: boolean;
  @Input() public typeCurrency: string;
  @Input() public readon: boolean;
  @Input() public type: string;

  @Output() retornoMask = new EventEmitter();

  private masked: boolean;

  @HostBinding('style.opacity')
  get opacity() {
    return this.disabled ? 0.25 : 1;
  }

  public clickPrefixIcon() {
    this.clickPrefix.emit();
  }

  onBlur($event: any) {
    // codigo
    if ($event.target.value.length === this.mask.length) {
      return;
    }
    this.onChange('');
    $event.target.value = '';
    this.retornoMask.emit($event.target.value);
  }

  writeValue(value: any): void {
    this.value = value;
    this.onChange(this.value);
  }

  public verifyCurrency() {
    return this.typeCurrency ? this.typeCurrency : 1;
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  public onChange = (value: string) => {};
  public onTouched = () => {};
}
