import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ListQueryRequest } from '@src/core/domain/query/query.model';
import { posicaoCarteiraListEntity } from './posicao-carteira.entity';
import { posicaoCarteiraListRepositoryMapper } from './posicao-carteira-repository-mapper';
import { PosicaoCarteiraRepository } from '@src/core/repositories/query/posicao-carteira.repository';

@Injectable({
  providedIn: 'root',
})
export class PosicaoCarteiraDataRepository extends PosicaoCarteiraRepository {
  mapper = new posicaoCarteiraListRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getPosicaoCarteira(params: ListQueryRequest): Observable<any> {

    return this.http
      .post<posicaoCarteiraListEntity>(`${environment.URLBase}v1/PosicaoCarteira/listar`, params, { headers: getHeader() })
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
