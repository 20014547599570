import { Injectable } from '@angular/core';
import { flatMap, map, catchError } from 'rxjs/operators';
import { DocumentCheckModelRepository } from '@src/core/repositories/charge/document-check-type.repository';
import { DocumentCheckModel } from '@src/core/domain/charge/document-check.model';
import { Observable } from 'rxjs';
import { DocumentCheckRepositoryMapper } from '@src/data/repository/charge/document-check/document-check-repository-mapper';
import { HttpClient } from '@angular/common/http';
import { DocumentCheckEntity } from '@src/data/repository/charge/document-check/document-check.entity';
import { DocumentRequest } from '@src/core/domain/charge/document-check.model';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocuemntCheckRepository extends DocumentCheckModelRepository {
  mapper = new DocumentCheckRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getDocumentCheck(obj: DocumentRequest): Observable<DocumentCheckModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<DocumentCheckEntity>(
      `${environment.URLBase}v1/sacados/documento/${obj.documento}`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
