import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { InstructionRequest, InstructionResponse } from '@src/core/domain/query/query.model';
import { InstructionRepository } from '@src/core/repositories/query/instruction.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetInstructionUsecase implements UseCase<InstructionRequest, InstructionResponse> {

  constructor(private instructionRepository: InstructionRepository) {

  }

  execute(params: InstructionRequest): Observable<InstructionResponse> {
    return this.instructionRepository.getInstruction(params);
  }
}