import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TransferRequest } from '@src/core/domain/transfer/transfer.model';
import { TransferUsecases } from '@src/core/usecases/transfer/transfer.usecases';
import {
  DebitAccountDataShare,
  TransferConfirmDataShare,
  TransferPendingDataShare,
  TransferReceiptDataShare,
} from '@src/data/repository/data-share-repository';
import * as Util from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'fibra-page-transfer-confirm',
  templateUrl: './transfer-confirm.component.html',
  styleUrls: ['./transfer-confirm.component.scss'],
})
export class TransferConfirmComponent implements OnInit {
  constructor(
    private route: Router,
    private shareTransferReceipt: TransferReceiptDataShare,
    private shareDebit: DebitAccountDataShare,
    private addTransfer: TransferUsecases,
    private shareTransferConfirm: TransferConfirmDataShare,
    private shareTransferReponse: TransferPendingDataShare,
    private shareCompany: CompanyDataShare
  ) {
    this.textBtn = 'Transferir';
    this.textDate = 'Data';
  }
  public beneficiaryData;
  public bank;
  public requestDataApi;
  public showModalValidation = false;
  public isPending;
  public dateCompare = false;
  public companyData;
  public textBtn;
  public textDate;
  public typeCcountVerification;
  public typeCcount;

  public params: TransferRequest;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit(): void {
    this.shareTransferReceipt.dataShared.subscribe((res) => {
      if (res && res.transferInformation) {
        res.transferInformation
          .get('dateTrasfer')
          .setValue(
            this.resolveDate(res.transferInformation.get('dateTrasfer').value)
          );
        
        this.beneficiaryData = res;
        console.log(this.beneficiaryData);
      }
    });
    this.shareDebit.dataShared.subscribe((res) => {
      if (res) {
        this.bank = res;
      }
    });
    this.shareCompany.dataShared.subscribe((res) => {
      if (res) {
        this.companyData = res.companyList.filter((e) => e.selected);
      }
    });
    if (!this.beneficiaryData) {
      this.route.navigate(['/transfer']);
    }
  }

  private changeDateCompare(compare?) {
    if (compare) {
      this.textBtn = 'Confirmar agendamento';
      this.textDate = 'Agendada para';
    }
  }

  private verifyTypeAccount(value) {
    switch (value) {
      case '8':
        value = 'CC';
        break;
      case '9':
        value = 'PP';
        break;
      case '4':
        value = 'CV';
        break;
      default:
        value = 'CC';
        break;
    }
    return value;
  }

  public DescTypeAccount(value)
  {
    switch(value)
    {
      case 'PP':
        return value = 'Conta Poupança';
      case 'CC':
        return value = 'Conta Corrente';
      default:
        return value;
    }
  }

  private dateFormat(date) {
    const jsdate = new Date(
      date.split('/')[2],
      date.split('/')[1] - 1,
      date.split('/')[0]
    );
    const format = jsdate.toISOString().slice(0, 19);

    return format;
  }

  private successTransfer = (value) => {
    switch (value.data.etapaTransferencia) {
      case 0:
        this.shareTransferReponse.setValue(value.data);
        this.shareTransferConfirm.setValue(value.data);
        this.route.navigate(['/transfer-receipt']);
        break;
      case 1:
        this.shareTransferReponse.setValue(value.data);
        this.shareTransferConfirm.setValue(value.data);
        this.route.navigate(['/transfer-receipt']);
        break;
      case 2:
        this.shareTransferReponse.setValue(value.data);
        this.shareTransferConfirm.setValue(value.data);
        this.route.navigate(['/transfer-pending']);
        break;
      case 3:
        this.shareTransferConfirm.setValue(value.data);
        this.shareTransferReponse.setValue(value.data);
        this.route.navigate(['/transfer-pending']);
        break;
      case 4:
        this.shareTransferConfirm.setValue(value.data);
        this.shareTransferReponse.setValue(value.data);
        this.route.navigate(['/transfer-pending']);
        break;
      case 5:
        this.shareTransferConfirm.setValue(value.data);
        this.shareTransferReponse.setValue(value.data);
        this.route.navigate(['/transfer-receipt']);
        break;
      case 6:
        this.shareTransferConfirm.setValue(value.data);
        this.shareTransferReponse.setValue(value.data);
        this.route.navigate(['/transfer-pending']);
        break;
      case 7:
        this.shareTransferConfirm.setValue(value.data);
        this.shareTransferReponse.setValue(value.data);
        this.route.navigate(['/transfer-pending']);
        break;
      case 8:
        this.shareTransferConfirm.setValue(value.data);
        this.shareTransferReponse.setValue(value.data);
        this.route.navigate(['/transfer-pending']);
        break;
      case 9:
        this.shareTransferConfirm.setValue(value.data);
        this.shareTransferReponse.setValue(value.data);
        this.route.navigate(['/transfer-pending']);
        break;
      case 16:
        sessionStorage.setItem('erroAprovacao', 'Transação em processamento. Entre em contato com a nossa área de atendimento.');
        this.route.navigate(['transaction-approval']);
        break;
    }
  }

  private errorTransfer = (err) => {
    this.showModalValidation = false;
    if (err.status == 500 || err.status == 504) {
      const msg = err.status == 500 ? 'Serviço indisponível. Atenção! Antes de realizar novamente a transferência, confira se ela não foi concluída.' : 'Transação em processamento. Entre em contato com a nossa área de atendimento.';
      sessionStorage.setItem('erroAprovacao', msg);
      this.route.navigate(['transaction-approval']);
    }
    else if (err.error && err.error.message && err.error.message.length > 0) {
      this.toast.callModalMessage(null, `${err.error.message[0]}`);
    } else {
      this.toast.callModalMessage(null, 'Ocorreu um erro realizar a operação.');
    }
  }

  public removeMask = (value) => value && Util.removeMask(value);

  public resolveDate(date: NgbDate) {
    const dateAux = new Date(date.year, date.month - 1, date.day);
    const format = new Intl.DateTimeFormat('pt-BR').format(dateAux);
    this.dateCompare = Util.compareDate(format);
    this.changeDateCompare(this.dateCompare);
    return format;
  }

  public securityResponse = value => {
    if (value.status === 'success') {
      this.successTransfer(value);
    } else {
      this.errorTransfer(value);
    }
  }

  public closeModal = () => (this.showModalValidation = false);

  public goNext()
  {
    let purposeData;
    if(this.beneficiaryData.transferInformation.get('purpose').value === null){
      purposeData = 1;
    }
    else{
      purposeData = this.beneficiaryData.transferInformation.get('purpose').value.value;
    }

    this.typeCcountVerification = this.bank.desc_modalidade;
    this.typeCcount = this.typeCcountVerification.toUpperCase().indexOf("VINC") !== -1 ? "CV" : "CC";

    this.params = {
      num_canal_pagamento: 3, // TODO: 3 internet banking / 9 mobile
      tipo_operacao: Util.verifyTransferType(
        this.beneficiaryData.transferInformation.get('optionTransfer').value
      ),
      num_finalidade: purposeData,
      vlr_transferencia: this.beneficiaryData.transferInformation.get('ammount')
        .value,
      dt_transferencia: this.dateFormat(
        this.beneficiaryData.transferInformation.get('dateTrasfer').value
      ),
      desc_transferencia: this.beneficiaryData.transferInformation.get(
        'description'
      ).value,
      contas: {
        conta_origem: {
          cod_banco: 224,
          tipo_conta: this.typeCcount,
          num_agencia: this.bank.cod_agencia,
          num_conta: this.bank.num_conta,
          num_cpfcnpj: this.companyData[0].modality_account,
          nome_beneficiario: this.companyData[0].name,
        },
        conta_destino: {
          cod_banco: this.beneficiaryData.beneficiaryData.quote.banco.cod_banco,
          tipo_conta: this.verifyTypeAccount(
            this.beneficiaryData.beneficiaryData.quote.tipo_conta
          ),
          num_agencia: this.beneficiaryData.beneficiaryData.quote.num_agencia,
          num_conta: this.beneficiaryData.beneficiaryData.quote.num_conta,
          num_cpfcnpj: this.beneficiaryData.beneficiaryData.beneficiary.num_cpf_cnpj.replace(
            /[^\d]+/g,
            ''
          ),
          nome_beneficiario: this.beneficiaryData.beneficiaryData.beneficiary
            .nome_beneficiario,
        },
      },
    };
    console.log(this.bank);
    this.showModalValidation = true;
  }
  public securityRequest = () => {
    return this.params;
  }
  navigateBack() {
    this.route.navigate(['/transfer']);
  }
}
