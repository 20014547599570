import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { PendencyApprovalPix } from '@src/core/domain/dashboard/dashboard.model';
import { PendencyRepository } from '@src/core/repositories/dashboard/pendency.repository';
import { PendencyLimitsApprovalResository } from '@src/core/repositories/dashboard/pendency-limits-approval.repository';

@Injectable({
  providedIn: 'root',
})
export class GetPendencyLimitsApprovalUsecase implements UseCase<void, PendencyApprovalPix> {
  constructor(private pendencyLimitsApprovalResository: PendencyLimitsApprovalResository) {}

  execute(): Observable<PendencyApprovalPix> {
    return this.pendencyLimitsApprovalResository.getPendencyApproval();
  }
}
