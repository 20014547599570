import { Mapper } from '@src/core/base/mapper';
import { PendencyModel } from '@src/core/domain/dashboard/dashboard.model';
import { PendencyEntity } from '@src/data/repository/dashboard/pendency/pendency.entity';

export class PendencyRepositoryMapper extends Mapper<
  PendencyEntity,
  PendencyModel
> {
  mapFrom(param: PendencyEntity): PendencyModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: PendencyModel): PendencyEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
