import { Observable } from 'rxjs';
import {
  LoginMobileTypeModel,
  LoginMobileRequest,
} from '@src/core/domain/login/loginMobileType.model';
import { AccessTypeModel} from '@src/core/domain/access/accessType.model';

export abstract class LoginRepository {
  abstract sendLogin(params: LoginMobileRequest): Observable<LoginMobileTypeModel>;
}
