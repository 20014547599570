import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PixComprovanteDataShare } from '@src/core/usecases/data-share/pix-comprovante-data-share.service';
import { TransferReceiptDataRepository } from '@src/data/repository/receipt-repository/receipt.repository';
import * as Util from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';


@Component({
  selector: 'fibra-pix-receipt-devolution',
  templateUrl: './pix-receipt-devolution.component.html',
  styleUrls: ['./pix-receipt-devolution.component.scss']
})

export class PixReceiptDevolutionComponent implements OnInit {

  public transacaoPendente: boolean = false;
  public transacaoRealizada: boolean = false;
  public transacaoAgendada: boolean = false;
  public infoComprovantes;
  public typToastColor;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';

  constructor(
    public transferComprovante: PixComprovanteDataShare,
    private router: Router,
    private receiptService: TransferReceiptDataRepository,
  ) { }
 
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  ngOnInit() {
    this.infoComprovantes = this.transferComprovante.getValue();

    if (this.infoComprovantes.data.obj_transf_pix.idStatusTransferenciaPix === 'AguardandoAprovacaoMaster' || this.infoComprovantes.data.obj_transf_pix.idStatusTransferenciaPix === 'Inconsistente'){
      this.transacaoPendente = true;
    }

    if(this.infoComprovantes.data.obj_transf_pix.idStatusTransferenciaPix === 'Aprovado'){
      this.transacaoRealizada = true;
    }
  }

  public downloadComprovante(){
    this.receiptService.getPdf(this.infoComprovantes.data.num_compromisso).subscribe((data:any) => {
      this.typToastColor = this.GREENCOLOR;
      this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
      Util.downloadPdf(data);
    });
  }

  public goBack(){
    localStorage.setItem('view-selecionada', 'refund-transfer');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public goToView = (route) => this.router.navigateByUrl(route);

  htmlShowCpf(val) {
    let format = Util.formatCpfCnpj(val);
    return Util.protectShowCpf(format);
  }
  
  agencyFormat(account) {
    return Util.returnAgency(account);
  }

  accountFormat(account) {
    return Util.formatValueAccount(account);
  }
}
