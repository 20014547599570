import { Mapper } from "@src/core/base/mapper";
import { DdaHistoryModel } from "@src/core/domain/dda/history.model";
import { DdaHistoryEntity } from "../entity/history/dda-history.entity";

export class DdaHistoryMapper extends Mapper<
  Array<DdaHistoryModel>,
  DdaHistoryEntity
> {
  mapFrom(param: Array<DdaHistoryModel>): DdaHistoryEntity {
    return null;
  }
  mapTo(param: DdaHistoryEntity): Array<DdaHistoryModel> {
    return param.data.map((item: any) => {
      return {
        id: item.id,
        data_hora: item.data_hora,
        numero_protocolo: item.numero_protocolo,
        descricao: item.descricao,
        status_dda: item.status_dda,
        tipo_validacao: item.tipo_validacao,
        nome_empresa: item.nome_empresa,
        cnpj: item.cnpj,
        nome_pessoa: item.nome_pessoa,
      };
    });
  }
}
