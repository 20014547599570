import { HttpClient, } from '@angular/common/http';
import { Inject, Injectable,LOCALE_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { handleError } from '@src/shared/util-common';
import { CobrancaEntity, CobrancaResponse, CreditoEntity, CreditoResponse, FinimpEntity, FinimpiResponse } from './import.entity';
import { formatDate } from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class ImportDataRepository {

  constructor(private http: HttpClient,@Inject(LOCALE_ID) private locale?: string) {}

  getFinimpEmSer(endPeriod:string): Observable<FinimpiResponse> {
      const httpOptions = {
        headers: getHeader(),
      };
        try {
          return this.http
          .get<FinimpiResponse>(
            `${environment.URLBase}v1/comercioexterior/importacao/finimp?dt_ini=null&dt_fim=${endPeriod}`,
            httpOptions
          )
       }
       catch(error) {
           handleError(error);
       }
  }

  getPDF(contrato:string,tipo:string): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
      try {
        return this.http
        .get<any>(
          `${environment.URLBase}v1/comercioexterior/ordempagto/emitida/${tipo}/ref/${contrato}`,
          httpOptions
        )
     }
     catch(error) {
         handleError(error);
     }
}

  getCobrancaEmSer(endPeriod:string): Observable<CobrancaResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      try {
        return this.http
        .get<CobrancaResponse>(
          `${environment.URLBase}v1/comercioexterior/importacao/cobranca?dt_ini=2013-11-12&dt_fim=${endPeriod}`,
          httpOptions
        )
     }
     catch(error) {
         handleError(error);
     }
}

getCreditEmSer(endPeriod:string): Observable<CreditoResponse> {
  const httpOptions = {
    headers: getHeader(),
  };
    try {
      return this.http
      .get<CreditoResponse>(
        `${environment.URLBase}v1/comercioexterior/importacao/cartacredito?dt_ini=null&dt_fim=${endPeriod}`,
        httpOptions
      )
   }
   catch(error) {
       handleError(error);
   }
}

  GetFinimpContract(contractNum:string): Observable<FinimpiResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      try {
        return this.http
        .get<FinimpiResponse>(
          `${environment.URLBase}v1/comercioexterior/importacao/finimp/contrato/${contractNum}`,
          httpOptions
        )
     }
     catch(error) {
         handleError(error);
     }
     
  }

  getCobrancaContract(contractNum:string): Observable<CobrancaResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      try {
        return this.http
        .get<CobrancaResponse>(
          `${environment.URLBase}v1/comercioexterior/importacao/cobranca/ref/${contractNum}`,
          httpOptions
        )
     }
     catch(error) {
         handleError(error);
     }
     
  }

  PostExcel(obj: any, url: string): Observable<any> {
    return this.http
      .post<any>(
        `${url}`,
        obj,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }
  

  postExportExcelImportFinmp(): Observable<any> {
    let json = JSON.parse(sessionStorage.getItem('excel_info'))

    let referencia = json.referencia || null;
    let dataFinal = null;
    if(json.dt_fim!==undefined){
      dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) 
    }

    let obj = {
      dataFinal : dataFinal,
      referencia : referencia
    }

    return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/importacao/finimp/excel`);
  }
  postExportExcelImportCartaCredito(): Observable<any> {
    let json = JSON.parse(sessionStorage.getItem('excel_info'))
    let referencia = json.referencia || null;
    let dataFinal = null;
    if(json.dt_fim!==undefined){
      dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) 
    }

    let obj = {
      dataFinal : dataFinal,
      referencia : referencia
    }
    
    return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/importacao/cartacredito/excel`);
  }
  
 
  postExportExcelImportCobranca(): Observable<any> {
    let json = JSON.parse(sessionStorage.getItem('excel_info'))
    let referencia = json.referencia || null;
    let dataFinal = null;
    if(json.dt_fim!==undefined){
      dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) 
    }

    let obj = {
      dataFinal : dataFinal,
      referencia : referencia
    }
    
    return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/importacao/cobranca/excel`);
  }

  getCreditContract(contractNum:string): Observable<CreditoResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      try {
        return this.http
        .get<CreditoResponse>(
          `${environment.URLBase}v1/comercioexterior/importacao/cartacredito/ref/${contractNum}`,
          httpOptions
        )
     }
     catch(error) {
         handleError(error);
     }
     
  }
}
