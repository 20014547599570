
<div *fibraHasRole="'MASTER,DDA.DDA_CONS_BOL,DDA.DDA_REALIZA_PAG_BOL'">
<div class="container-boleto" *ngIf="situacao !== DdaBoletoSituacaoEnum.Agendado">
  <div *ngIf="account && situacao !== DdaBoletoSituacaoEnum.PendenteAprovacao">
    <div class="margin">
      <strong class="color-text margin">Conta para débito</strong>
      <br />
      <span>{{ account.num_conta }} - {{ account.exibicao }}</span>
    </div>
    <div class="margin">
      <div class="template-saldo-conta">
        <div *ngIf="account.saldo.vlr_saldo_cheque && account.saldo.vlr_limite">
            <span>Sem {{ toTitleCase(account.exibicao)}}: <strong>{{ account.saldo.vlr_saldo | currency:"BRL" }}</strong></span>
            <span><br>Com {{ toTitleCase(account.exibicao)}}: <strong>{{ account.saldo.vlr_saldo_cheque | currency:"BRL" }}</strong></span>
        </div>
        <div *ngIf="!account.saldo.vlr_saldo_cheque || !account.saldo.vlr_limite">
          <span><strong>Saldo Dispon&iacute;vel: {{ account.saldo.vlr_saldo | currency:"BRL" }}</strong></span>
        </div>        
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading; else loading">
    <div>
      <div class="border-dashed"></div>
      <div *ngIf="situacao == DdaBoletoSituacaoEnum.PendenteAprovacao">
        <strong class="color-text">Dados do boleto</strong>
        <div class="margin">
          <div class="template-boleto">
            <span>Boleto emitido por</span>
            <span>Código de barras</span>
          </div>
          <div class="template-boleto">
            <strong>{{ detalhes?.num_banco }} - {{ detalhes?.desc_banco }}</strong>
            <strong>{{ boleto.codigo_barras }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-nome-do-beneficiario">
            <span>Nome do beneficíario</span>
            <span>CNPJ / CPF</span>
            <span>Banco</span>
          </div>
          <div class="template-nome-do-beneficiario">
            <strong>{{ detalhes?.nome_beneficiario }}</strong>
            <strong>{{ detalhes?.num_cpf_cnpj }}</strong>
            <strong>{{ detalhes?.num_banco }} - {{ detalhes?.desc_banco }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-data-vencimento">
            <span>Data de vencimento</span>
            <span>Valor do boleto</span>
          </div>
          <div class="template-data-vencimento">
            <strong>{{ detalhes?.dt_vencimento | date : "dd/MM/yyyy" }}</strong>
            <strong>{{ detalhes?.vlr_titulo | currency : "BRL" }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-desconto">
            <span>Desconto</span>
            <span>Abatimento</span>
          </div>
          <div class="template-desconto">
            <strong>{{ detalhes?.mem_calculo.vlr_desconto | currency : "BRL" }}</strong>
            <strong>{{ detalhes?.mem_calculo.vlr_abatimento | currency : "BRL" }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-juros">
            <span>Juros</span>
            <span>Multa</span>
            <span>Mora</span>
          </div>
          <div class="template-juros">
            <strong>{{ detalhes?.mem_calculo.vlr_juros | currency : "BRL" }}</strong>
            <strong>{{ detalhes?.mem_calculo.vlr_multa | currency : "BRL" }}</strong>
            <strong>{{ detalhes?.mem_calculo.vlr_mora | currency : "BRL" }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-valor-ser-pago">
            <span>Valor a ser pago</span>
            <span>Data de pagamento</span>
          </div>
          <div class="template-valor-ser-pago">
            <strong>{{ detalhes?.mem_calculo.vlr_total | currency : "BRL" }}</strong>
            <strong>{{ dataPagamentoAsDate | date : "dd/MM/yyyy" }}</strong>
          </div>
        </div>
      </div>
      <div *ngIf="situacao != DdaBoletoSituacaoEnum.PendenteAprovacao">
        <strong class="color-text">Dados do boleto</strong>
        <div class="margin">
          <div class="template-boleto">
            <span>Boleto emitido por</span>
            <span>Código de barras</span>
          </div>
          <div class="template-boleto">
            <strong>{{ detalhes?.num_banco }} - {{ detalhes?.desc_banco }}</strong>
            <strong>{{ boleto.codigo_barras }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-nome-do-beneficiario">
            <span>Nome do beneficíario</span>
            <span>CNPJ / CPF</span>
            <span>Banco</span>
          </div>
          <div class="template-nome-do-beneficiario">
            <strong>{{ detalhes?.nome_beneficiario }}</strong>
            <strong>{{ detalhes?.num_cpf_cnpj }}</strong>
            <strong>{{ detalhes?.num_banco }} - {{ detalhes?.desc_banco }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-data-vencimento">
            <span>Data de vencimento</span>
            <span>Valor do boleto</span>
          </div>
          <div class="template-data-vencimento">
            <strong>{{ detalhes?.dt_vencimento | date : "dd/MM/yyyy" }}</strong>
            <strong>{{ detalhes?.vlr_titulo | currency : "BRL" }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-desconto">
            <span>Desconto</span>
            <span>Abatimento</span>
          </div>
          <div class="template-desconto">
            <strong>{{ detalhes?.mem_calculo.vlr_desconto | currency : "BRL" }}</strong>
            <strong>{{ detalhes?.mem_calculo.vlr_abatimento | currency : "BRL" }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-juros">
            <span>Juros</span>
            <span>Multa</span>
            <span>Mora</span>
          </div>
          <div class="template-juros">
            <strong>{{ detalhes?.mem_calculo.vlr_juros | currency : "BRL" }}</strong>
            <strong>{{ detalhes?.mem_calculo.vlr_multa | currency : "BRL" }}</strong>
            <strong>{{ detalhes?.mem_calculo.vlr_mora | currency : "BRL" }}</strong>
          </div>
        </div>

        <div class="margin">
          <div class="template-valor-ser-pago">
            <span>Valor a ser pago</span>
            <span>Data de pagamento</span>
          </div>
          <div class="template-valor-ser-pago">
            <strong>{{ detalhes?.mem_calculo.vlr_total | currency : "BRL" }}</strong>
            <strong>{{ dataPagamentoAsDate | date : "dd/MM/yyyy" }}</strong>
          </div>
        </div>
      </div>      
    </div>
  </div>
  <div class="border-dashed"></div>
  <div *ngIf="situacao !== DdaBoletoSituacaoEnum.PendenteAprovacao">
    <div *ngIf="permitePagamento; else pagamento_nao_permitido">
      <div class="data-pagamento">
        <span>Definir data para pagamento</span>
        <div class="showToolTip">
          <div class="tooltipView" *ngIf="exibirPagamentoNoProximoDiaUtil || exibirPagamentoAposVencimento || showStatusHourPaymentClosed">
            <div class="tooltipContent">
              <div class="tooltipTexts">

                <span class="tooltipTitle" *ngIf="exibirPagamentoNoProximoDiaUtil && !showStatusHourPaymentClosed">
                  O pagamento será realizado no próximo dia útil.
                </span>

                <span class="tooltipTitle" *ngIf="exibirPagamentoAposVencimento && !showStatusHourPaymentClosed">
                  Atenção! O pagamento será feito após a data de
                  vencimento. Serão aplicados encargos.
                </span>

                <span class="tooltipTitle" *ngIf="showStatusHourPaymentClosed">
                  Horário limite excedido.<br />
                  O pagamento será agendado para o próximo dia útil.
                  Após a data de vencimento serão aplicados encargos.
                </span>

              </div>
              <button class="btn-close" (click)="onCloseToolTip()">
                <img src="../../../../assets/img/close.svg" alt="" />
              </button>
            </div>
            <div class="arrowView">
              <div class="arrowDown"></div>
            </div>
          </div>
        </div>
        <fibra-datepicker
          [(date)]="dataPagamento"
          [minDate]="createDefaultDueDate"
          [disabled]="agendarPagamentoParaDataVencimento || isLoading || !permitePagamento"
          placement="top"
          (dateSelected)="onDataPagamentoSelected($event)">
        </fibra-datepicker>
        <span style="display: flex;">ou
          <div class="radio-class" style="display: flex; align-items: center; padding: 0 32px;">
            <input
              type="checkbox"
              class="form-check-input"
              [(ngModel)]="agendarPagamentoParaDataVencimento"
              [disabled]="isLoading || !permitePagamento"
              (click)="agendarPagamentoParaDataVencimentoClick()"
              name="Agendar" />
            <div>
              <label value="false" name="approval" class="form-check-label" for="digitableLine">Agendar pagamento para a data de vencimento</label>
            </div>
          </div>
        </span>
      </div>
      <div class="horario-limite">
        <span>Horário e limites:</span>
        <span><strong>Pagamento de boleto e compensação</strong> somente das 8:30h ás 20:00 e até R$ 7.000.000,00</span>
        <div *ngIf="!isLoading && showLimit">          
          <p *ngFor="let limit of limits">
            <label class="payment-txt" *ngIf="limit.tipoTitularidade === 'MESMA_TITULARIDADE'">Mesma titularidade das {{ formatHour(limit.horarioInicio) }}h às {{ formatHour(limit.horarioTermino) }}h: até {{ limit.valorMaximoTotal | currency:'BRL' }}</label>
            <label class="payment-txt" *ngIf="limit.tipoTitularidade === 'OUTRA_TITULARIDADE_PF'">Outra titularidade PF das {{ formatHour(limit.horarioInicio) }}h às {{ formatHour(limit.horarioTermino) }}h: até {{ limit.valorMaximoTotal | currency:'BRL' }}</label>
            <label class="payment-txt" *ngIf="limit.tipoTitularidade === 'OUTRA_TITULARIDADE_PJ'">Outra titularidade PJ das {{ formatHour(limit.horarioInicio) }}h às {{ formatHour(limit.horarioTermino) }}h: até {{ limit.valorMaximoTotal | currency:'BRL' }}</label>
          </p>
        </div>
      </div>
      <div class="container-pagar" *fibraHasRole="'MASTER,DDA.DDA_REALIZA_PAG_BOL'">
        <button type="button" class="text-center button-blue mr-2" [disabled]="!dataPagamento || isLoading || !permitePagamento" (click)="pagarClick()">Pagar</button>
      </div>
    </div>
    <ng-template #pagamento_nao_permitido>
      <span *ngIf="mensagem" class="warning">{{mensagem}}</span>
    </ng-template>
  </div>
</div>
<div class="container-agendado" *ngIf="situacao == DdaBoletoSituacaoEnum.Agendado">
  <div *ngIf="!isLoading; else loading">
    <div class="margin row">
      <div class="template-agendamento-valorPago col-sm">
        <span>Valor pago</span>
        <span>Forma de pagamento</span>
        <span>Canal</span>
        <span>Data de Vencimento</span>
        <span>Data do agendamento</span>
      </div>
    </div>
    <div class="margin row">
      <div class="template-agendamento-valorPago col-3">
        <strong>{{ detalhes?.mem_calculo.vlr_total | currency : "BRL" }}</strong>
        <strong>D&eacute;bito em conta</strong>
        <strong>Internet Banking</strong>
        <strong>{{ detalhes?.dt_vencimento | date : "dd/MM/yyyy" }}</strong>
        <strong>{{ boleto.data_agendamento | date : "dd/MM/yyyy" }}</strong>
      </div>
    </div>
    <div class="margin row">
      <strong class="fontSize">Beneficiário</strong>
    </div>

    <div class="margin row">
      <div class="template-agendamento-valorPago col-sm">
        <span>Nome</span>
        <span>CPF/CNPJ</span>
        <span>Banco</span>
      </div>
    </div>
    <div class="margin row">
      <div class="template-agendamento-valorPago col-sm">
        <strong>{{ detalhes?.nome_beneficiario }}</strong>
        <strong>{{ detalhes?.num_cpf_cnpj }}</strong>
        <strong>{{ detalhes?.num_banco }} - {{ detalhes?.desc_banco }}</strong>
      </div>
    </div>

    <div class="margin">
      <div class="template-agendamento-valorBoleto">
        <strong class="fontSize">Valor do boleto</strong>
        <strong class="fontSize">Desconto</strong>
        <strong class="fontSize">Abatimento</strong>
      </div>
      <div class="template-agendamento-valorBoleto">
        <strong>{{ detalhes?.mem_calculo.vlr_total | currency : "BRL" }}</strong>
        <strong>{{ detalhes?.mem_calculo.vlr_desconto | currency : "BRL" }}</strong>
        <strong>{{ detalhes?.mem_calculo.vlr_abatimento | currency : "BRL" }}</strong>
      </div>
    </div>
    <div class="margin">
      <strong class="fontSize">Encargos</strong>
    </div>
    <div class="margin">
      <div class="template-agendamento-encargos">
        <span>Juros</span>
        <span>Multas</span>
        <span>Mora</span>
      </div>
      <div class="template-agendamento-encargos">
        <strong>{{ detalhes?.mem_calculo.vlr_juros | currency : "BRL" }}</strong>
        <strong>{{ detalhes?.mem_calculo.vlr_multa | currency : "BRL" }}</strong>
        <strong>{{ detalhes?.mem_calculo.vlr_mora | currency : "BRL" }}</strong>
      </div>
    </div>
    <div class="margin">
      <strong class="fontSize">Conta debitada</strong>
    </div>
    <div class="margin">
      <div class="template-agendamento-contaDebitada">
        <span>Nome</span>
        <span>CPF/CNPJ</span>
      </div>
      <div class="template-agendamento-contaDebitada">
        <strong>{{ boleto.sacado }}</strong>
        <strong>{{ detalhes?.num_cpf_cnpj_pagador }}</strong>
      </div>
    </div>

    <div class="margin">
      <div>
        <span>Dados da conta</span>
      </div>
      <div>
        <strong>{{ account.exibicao }}</strong><br/>
          <span>AG: {{ account.cod_agencia}} - CC: {{ account.num_conta }}</span> 
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)" *ngIf="isLoading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
</div>
<fibra-modal-validation
  *ngIf="exibirAutorizacao"
  [apiCallback]="otpOptions.apiCallback"
  (closeEvent)="otpOptions.closeEvent()"
  [apiPayload]="otpOptions.apiPayload"
  (responseValidation)="otpOptions.responseValidation($event)"></fibra-modal-validation>

<fibra-comprovante *ngIf="!exibirPagamentoAguardandoApMaster && !exibirPagamentoPendente" [value]="comprovante"></fibra-comprovante>

<fibra-modal-pagamento-pendente *ngIf="exibirPagamentoAguardandoApMaster" [title]="'Pagamento Pendente.'" [message]="'Enviado para aprovação do(s) master(s) da sua empresa.'"></fibra-modal-pagamento-pendente>

<fibra-modal-pagamento-pendente *ngIf="exibirPagamentoPendente" [message]="mensagemPagamentoPendente" [typeMessage]="typeMessagePagamentoPendente"></fibra-modal-pagamento-pendente>

<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>