import {GetFunctionalitiesAccountUseCase} from '@src/core/usecases/admin-user/get-functionalities-account.usecase';
import {GetAccessUsecase} from '@src/core/usecases/access/get-access.usecase';
import {Injectable} from '@angular/core';
import {Functionalities, Permissions} from '@src/data/repository/perfilamento/profile.entity';
import {consoleDev} from '@src/shared/util-common';
import {isArray} from 'rxjs/internal-compatibility';
import {GetAccessFuncionalitiesUsecase} from '@src/core/usecases/access/get-access-funcionalities.usecase';

@Injectable({
    providedIn: 'root'
})
export class ProfileAccessProvider {
    public functionalities: any;
    constructor(
        private getAccess: GetAccessUsecase,
        private getFunctionalitiesAccess: GetAccessFuncionalitiesUsecase,
    ) {}

    private getFunctionalities = () => {
        const success = res => {
            this.functionalities = this.formatArrayFunctionalities(res.data);
            consoleDev('Funcionalidades :: ', this.functionalities);
        };
        const error = err => {
            consoleDev('Error getFunctionalities:: ', err);
            this.functionalities = [];
        };
        this.getFunctionalitiesAccess.execute().subscribe(success, error);
    }

    private getMasterAccess = ()  => {
        localStorage.removeItem('acessoMaster');
        const success = res => {
            let access: boolean;
            if (res.code === 200) {
                consoleDev('isMaster', res.data.acessoMaster);
                access = res.data.acessoMaster;
            }
            localStorage.setItem('acessoMaster', String(access));
            this.verifyAccess(access);
        };
        const error = err => {
            consoleDev('Error getMasterAccess:: ', err);
            localStorage.setItem('acessoMaster', String(false));
        };
        this.getAccess.execute().subscribe(success, error);
    }

    private formatArrayFunctionalities = (value) => {
        const newArray: Functionalities[] = [];
        const permissions: Permissions[] = [];
        for (const f of value) {
            for (const p of f.operacoes) {
                permissions.push({
                    access: true,
                    id: p.cod_operacao,
                    desc: p.desc_operacao
                });
            }
            newArray.push({
                access: true,
                id: f.cod_funcionalidade,
                desc: f.desc_funcionalidade,
                permissions
            });
        }
        consoleDev('Success permissions:: ', newArray);
        localStorage.setItem('permissions', JSON.stringify(newArray));
    }

    private verifyAccess = bool => {
        this.functionalities = [];
        if (!bool) {
            this.getFunctionalities();
        }
    }

    public getAccessUser = () => this.getMasterAccess();

    public isMaster = () => Boolean(localStorage.getItem('acessoMaster'));

    public getFunctionalityAllowed = async (id?) => {
        const functionality = await JSON.parse(localStorage.getItem('permissions')) || [];
        return functionality.some(e => e.id === id);
    }

    public getPermissionAllowed = async (functionalityId, permissionID?) => {
        const functionality = await JSON.parse(localStorage.getItem('permissions'));
        let functionalitySelected = [];
        let selected = false;
        if (isArray(functionality)) {
            functionalitySelected = functionality.filter(e => e.id === functionalityId);
            if (permissionID) {
                selected = functionalitySelected.some(s => s.id === permissionID);
            }
        }
        consoleDev('Pemission ID:: ', selected);
        return selected;
    }

    public getAllPermissions = async () => {
        const permission = await JSON.parse(localStorage.getItem('permissions'));
        consoleDev('Permissões:: ', permission);
        if (isArray(permission)) {
            return permission;
        }
        return [];
    }

    public getFunctionalitiesListId = async (id?) => {
        const permission = await JSON.parse(localStorage.getItem('permissions'));
        let selected = [];
        if (isArray(permission)) {
            selected = permission.filter(e => e.id === id);
        }
        consoleDev('Pemission ID:: ', selected);
        return selected;
    }

    public getPermissionsListId = async (functionalityId, permissionID?) => {
        const functionality = await JSON.parse(localStorage.getItem('permissions'));
        let functionalitySelected = [];
        let selected = [];
        if (isArray(functionality)) {
            functionalitySelected = functionality.filter(e => e.id === functionalityId);
            if (permissionID) {
                selected = functionalitySelected.filter(s => s.id === permissionID);
            }
        }
        consoleDev('Pemission ID:: ', selected);
        return selected;
    }
}
