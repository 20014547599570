import { AcceptTermModel } from './../../../core/domain/accept-term/accept-term.model';
import { AcceptTermRepositories } from '@src/core/repositories/accept-term/accept-term.repository';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';

@Injectable({
  providedIn: 'root',
})
export class GetAcceptTermRepository extends AcceptTermRepositories {

  constructor(private http: HttpClient) {
    super();
  }

  public getAcceptTerm(params): Observable<AcceptTermModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<AcceptTermModel>(`${environment.URLBase}v1/termopix/${params.idTermo}`, httpOptions);
  }

  public postAcceptTerm(params): Observable<AcceptTermModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.post<AcceptTermModel>(`${environment.URLBase}v1/termopix`, params, httpOptions);
  }

}
