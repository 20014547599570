import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';

@Injectable({
  providedIn: 'root',
})
export class PixCreateKeyDataRepository {
  public body;

  constructor(private http: HttpClient) { }

  public createKey(type: string, cod?: string, token?: string, key?: string): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    if (key !== '') {
      this.body = {
        chave_tipo: type,
        portabilidade: false,
        chave: key,
        cod_validacao: cod,
        token_validacao: token,
        tp_operacao_chave: "CRIAR",
      }
    } else {
      this.body = {
        chave_tipo: type,
        portabilidade: false,
        cod_validacao: cod,
        token_validacao: token,
        tp_operacao_chave: "CRIAR",
      }
    }


    return this.http.post<any>(`${environment.URLBase}v1/pix/operacaochave`, this.body, httpOptions);
  }

  public sendSmsToken(phoneNumber: string): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };

    const body = {
      phone: phoneNumber
    }

    return this.http.post<any>(`${environment.URLBase}v1/validacaopositiva/sms/envio`, body, httpOptions);
  }

  public sendEmailToken(emailData: string): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    const body = {
      email: emailData
    }
    return this.http.post<any>(`${environment.URLBase}v1/validacaopositiva/email/envio`, body, httpOptions);
  }
}
