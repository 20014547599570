import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { ReadyChangeContractResponse, ReadyChangeEntity } from './ready-change.entity';
import { ReadyChangeResponse } from './ready-change.entity';
import { handleError } from '@src/shared/util-common';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ReadyChangeDataRepository {

  constructor(private http: HttpClient,@Inject(LOCALE_ID) private locale?: string) {}

  PostExcel(obj: any, url: string): Observable<any> {
    return this.http
      .post<any>(
        `${url}`,
        obj,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }


  postExportExcelCambioPronto(): Observable<any> {
    let json = JSON.parse(sessionStorage.getItem('excel_info'))

    let referencia = json.referencia || null;
    let dataInicio = null;
    let dataFinal = null;

    if(json.dt_fim!==undefined){
      dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
    }
    if(json.dt_ini!==undefined){
      dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
    }
    

    let obj = {
      dataInicial:dataInicio,
      dataFInal: dataFinal,
      referencia : referencia
    }
    //let obj = { "dataInicial":json.dt_ini, "dataFinal": json.dt_fim }
    return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/cambiopronto/excel`);
  }

  getPeriodData(startPeriod?:string, endPeriod?:string): Observable<ReadyChangeResponse> {
        const httpOptions = {
          headers: getHeader(),
        };
          try {
            return this.http
            .get<ReadyChangeResponse>(
              `${environment.URLBase}v1/comercioexterior/cambiopronto?dt_ini=${startPeriod}&dt_fim=${endPeriod}`,
              httpOptions
            )
         }
         catch(error) {
             handleError(error);
         }

  }

  getContractData(contractNum:string): Observable<ReadyChangeContractResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    try {
      return this.http
      .get<ReadyChangeContractResponse>(
        `${environment.URLBase}v1/comercioexterior/cambiopronto/contrato/${contractNum}`,
        httpOptions
      )
   }
   catch(error) {
       handleError(error);
   }
  }

}
