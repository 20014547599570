<div class="useapp-container">
  <img class="logo" src="/assets/img/logofibra-dark.png" alt="Banco Fibra" />
  <img class="pending-info" src="/assets/png/icon-pending.png" alt="Aviso" />
  <h1>Oi! Temos um recado para você</h1>
  <p class="message">
    Para uma melhor experiência, recomendamos que <br />
    o acesso seja por meio do nosso aplicativo.
  </p>
  <a href="https://play.google.com/store/apps/details?id=com.bancofibra.app" target="_blank" >
    <img class="icone" src="/assets/jpg/android.png" />
  </a>
  <a href="https://apps.apple.com/br/app/banco-fibra/id1570515082" target="_blank" >
    <img class="icone" src="/assets/jpg/apple.jpg"/>
  </a>
  <p class="help-message">
    Se precisar de ajuda, entre em contato <br />
    através dos canais abaixo:
  </p>
  <div id="accordion">
    <button 
      class="help-card"
      data-toggle="collapse"
      data-parent="#accordion"
      [attr.href]="'#collapse' + 'canal'"
    >
      <img class="apoio" src="/assets/png/apoio.png" alt="Apoio" />
      <div class="help-card-content">
        <h2 class="label">Canais exclusivos</h2>
        <p class="tag">Dúvidas e apoio</p>
      </div>
      <img
        class="arrow-down"
        src="/assets/png/icon_arrow_down_black.png"
        alt="Apoio"
      />
    </button>
    <div
      [id]="'collapse'+'canal'"
      class="panel-collapse collapse in"
    >
      <div class="info-card">
        <p class="titulo">Corporate, Agro e PMEs</p>
        <p class="contato">(11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.</p>
        <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
        <p class="segundo-titulo">Confirme Fibra</p>
        <p class="contato">(11) 4130-7449, opção 1, das 9h às 18h, de segunda a sexta-feira, exceto feriados.</p>
        <a href="confirme.fibra@bancofibra.com.br">confirme.fibra@bancofibra.com.br</a>
      </div>
    </div>
  </div>
  <div id="accordion">
    <button 
      class="help-card"
      data-toggle="collapse"
      data-parent="#accordion"
      [attr.href]="'#collapse' + 'sac'"
    >
      <img class="apoio" src="/assets/png/telefone.png" alt="SAC" />
      <div class="help-card-content">
        <h2 class="label">SAC</h2>
        <p class="tag">Sugestões, reclamações e elogios</p>
      </div>
      <img
        class="arrow-down"
        src="/assets/png/icon_arrow_down_black.png"
        alt="Apoio"
      />
    </button>
    <div
      [id]="'collapse'+'sac'"
      class="panel-collapse collapse in"
    >
      <div class="info-sac">
        <p class="canal-atendimento">Canal de Atendimento ao Cliente</p> 
        <p>0800 727 0132</p>

        <p style="margin-top: 20px;" class="canal-atendimento">Canal exclusivo para deficientes auditivos ou de fala</p> 
        <p>0800 200 6921</p>
      </div>
    </div>
  </div>
  
  <div id="accordion">
    <button 
      class="help-card"
      data-toggle="collapse"
      data-parent="#accordion"
      [attr.href]="'#collapse' + 'ouvidoria'"  
    >
      <img class="apoio" src="/assets/png/alto-falante.png" alt="Ouvidoria" />
      <div class="help-card-content">
        <h2 class="label">Ouvidoria</h2>
        <p class="tag">Demandas não solucionadas</p>
      </div>
      <img
        class="arrow-down"
        src="/assets/png/icon_arrow_down_black.png"
        alt="Apoio"
      />
    </button>
    <div
      [id]="'collapse'+'ouvidoria'"
      class="panel-collapse collapse in"
    >
      <div class="info-ouvidoria">
        A Ouvidoria é um canal de comunicação direto entre o cliente e o Banco, o qual atua com total imparcialidade e transparência. Tal canal é exclusivo para o atendimento às demandas dos clientes e usuários de produtos e serviços, que não tenham sido solucionadas de maneira satisfatória nos canais de atendimento primário da instituição. Ressalta-se que para ser atendido pela Ouvidoria, é necessário que o cliente já tenha recorrido ao atendimento primário da instituição. Fale com a Ouvidoria pelo telefone 0800 727 0132, das 10h às 16h, de segunda a sexta-feira feira, exceto feriados.
      </div>
    </div>
  </div>
</div>
