<div
  class="{{ customClass }} content-drop dropdown"
  [style.color]="textColor"
  [style.backgroundColor]="backgroundColor"
  [ngClass]="{ disabled: isDisabled, shadown: !removeShadown }"
  (click)="toggle()"
  [style.display]="numCpfCnpj ? 'block' : 'flex'"
  [style.padding]="numCpfCnpj ? '0px 0 10px 10px' : '10px 0 10px 10px'"
>
  <ng-container *ngIf="prefix" [ngTemplateOutlet]="prefix"> </ng-container>
  <div
    class="inside-content dropdown"
    [ngClass]="{ placeholder: placeholder && !label }"
  >
    <span 
      [style.paddingRight.px]="prefix ? 10 : 0" 
      class="dropdown data-hj-suppress">
      {{ prefixText ? prefixText + "" : "" }}{{ label ? label : placeholder }} 
      <span *ngIf="numCpfCnpj && numCpfCnpj.length > 11" style="font-size: 10px; margin-bottom: 10px;">
        <br/>
        CNPJ: {{numCpfCnpj  | cpfcnpj}}
      </span>
      <span *ngIf="numCpfCnpj && numCpfCnpj.length <= 11" style="font-size: 10px;">
        <br/>
        CPF: {{numCpfCnpj | cpfcnpj}}
      </span>
    </span>
    <img
      [ngStyle]="
        hasOpened 
          ? { transform: 'rotate(180deg)' }
          : { transform: 'rotate(0deg)' }
      "
      style="margin-left: 5px"
      src="/assets/svg/{{ iconName || 'arrow_down_blue' }}.svg"
      alt="image-seta-baixo"
      class="dropdown"
    />
  </div>
</div>

<div
  *ngIf="hasOpened"
  class="{{ customClass }} items dropdown"
  [style.color]="textColor"
>
  <div
    *ngFor="let item of options"
    class="item dropdown"
    (click)="select(item); this.toggle()"
  >
    <span class="dropdown data-hj-suppress">
      {{ prefixText ? prefixText + " " : "" }}{{ item.name }} 
      <span *ngIf="item.cpfCnpj && item.cpfCnpj.length > 11" style="font-size: 10px;">
        <br/>
        CNPJ: {{item.cpfCnpj | cpfcnpj}}
      </span>
      <span *ngIf="item.cpfCnpj && item.cpfCnpj.length <= 11" style="font-size: 10px;">
        <br/>
        CPF: {{item.cpfCnpj | cpfcnpj}}
      </span>
    </span>
  </div>
</div>
