import { AfterViewInit, Component, Input } from '@angular/core';
import { IOpenBankingConsent } from '@src/core/domain/openbanking/openbanking.consent.model';
import { timer } from 'rxjs';

@Component({
    selector: 'fibra-openbanking-paymentrequest-redirect',
    templateUrl: './openbanking-paymentrequest-redirect.component.html',
    styleUrls: ['./openbanking-paymentrequest-redirect.component.scss']
})
export class OpenbankingPaymentRequestRedirectComponent implements AfterViewInit {    

    @Input() consent: any;
    @Input() public transactionWasSuccessful: boolean;
   
    
    public get title(): string {

        let result = 'Você está sendo redirecionado para a instituição iniciadora de transferências inteligentes.'
        
        if (!this.transactionWasSuccessful) {
            if(sessionStorage.getItem('response-limite') || sessionStorage.getItem('response-saldo') ) {
                result = `Saldo insuficiente. Sua transação não foi realizada. ${result}`;

            }

            if(!sessionStorage.getItem('response-limite') && !sessionStorage.getItem('response-saldo') ) {
                result = `Sua transação não foi realizada. ${result}`;

            }

            if(!sessionStorage.getItem('origem-destino-iguais') && !sessionStorage.getItem('response-saldo') ) {
                result = `Contas de origem e destino iguais. ${result}`;

            }
        } 
        return result;
    }

    ngAfterViewInit(): void {
        var url = decodeURIComponent(this.consent.client_uri);
        url = url.replace(/u0026/g, '&');
        timer(2000).subscribe(() => window.location.href = url);
    }
}