import { PaymentModel } from '@src/core/domain/payment/payment.model';
import { Mapper } from '@src/core/base/mapper';
import { PaymentEntity } from '@src/data/repository/payment/payment.entity';

export class PaymentRepositoryMapper extends Mapper<
PaymentEntity,
  PaymentModel
> {
  mapFrom(param: PaymentEntity): PaymentModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }

  mapTo(param: PaymentModel): PaymentEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
