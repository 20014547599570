import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterArchiveDataShare, SelectorDataShare } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-page-charge-archive',
  templateUrl: './charge-archive.component.html',
  styleUrls: ['./charge-archive.component.scss'],
})
export class ChargeArchiveComponent implements OnInit {
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public AMBARCOLOR = '#D69D10';
  public typToastColor;
  public currentTab: string;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  
  constructor(
    private shareSelector: SelectorDataShare,
    private shareFilter: FilterArchiveDataShare
  ) {}

  ngOnInit() {
    this.shareSelector.setValue('charge-archive');
  }

  public setFilter(value) {
    this.shareFilter.setValue(value);
  }

  getInfoExport(event) {
    // if (event === 1) this.exportPdf()
    console.log(event)
    if (event === 2) this.exportExcel()
  }

  changeTab(event) {
    this.currentTab = event;
  }
  
  exportExcel() {
    // let params = this.currentFilter;
    // params = { ...params, status: this.typeList }
    // switch (this.currentView.value) {
    //   case 1:
    //     params = { ...params, view: 'arquivostransito/exporta/excel' }
    //     break;
    //   case 2:
    //     params = { ...params, view: 'PosicaoCarteira/exporta/excel' }
    //     break;
    //   case 3:
    //     params = { ...params, view: 'instrucoes/excel' }
    //     break;
    //   case 4:
    //     delete params['status'];
    //     params = { ...params, view: 'titulos/Francesinha/excel' }
    //     break;
    // }

    // this.requestExportExcel(params)
  }

  public validaArquivo(event : string) {
    if (event === 'aquivo-cnab-invalido') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage('arquivo-cnab-invalido');
      return;
    }else if (event === 'codigo-empresa-invalido') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Arquivo com código da empresa inválido',
        'Selecione um arquivo com o código da empresa correto.',
        null,
        null
      );
      return;
    }else if(event === 'arquivo-invalido') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        '',
        'Arquivo inválido!',
        null,
        null
      );
      return;
    } 
  }

  public verifyCalendar(event : string) {
    if (event === 'isGreaterThan730Days') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 730 dias.',
        null,
        null
      );
      return;
    }

    if (event === 'outOfReach') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 180 dias.',
        null,
        null
      );
      return;
    }

    if (event.includes('isGreaterThanDayConfig_')) {
      this.typToastColor = this.REDCOLOR;
      let days = event.substring(event.indexOf('_')+1, event.length);
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de '+days+' dias.',
        null,
        null
      );
      return;
    }
    
    if (event === 'isGreaterThan90Days') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 90 dias.',
        null,
        null
      );
      return;
    }
  }
}
