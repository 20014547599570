import { ExtractEntity, ExtractDateEnum } from '@src/data/repository/extract-repository/extract.entity';
import { ExtractModel } from '@src/core/domain/extract/extract.model';
import { Mapper } from '@src/core/base/mapper';

export class ExtractWebRepositoryMapper extends Mapper <ExtractEntity, ExtractModel> {
  mapFrom(param: ExtractEntity): ExtractModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }
  mapTo(param: ExtractModel): ExtractEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }
}
