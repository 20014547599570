import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';
import * as $ from 'jquery';
import { PostExportExcelUsecase } from '@src/core/usecases/transfer-approval/post-export-approval-usecase';
import { FilterComponent } from '@src/presentation/web/components/filter/filter.component';
import { TransactionDetailRequest } from '@src/core/domain/transaction/transaction.model';
import { GetTransactionDetailUsecase } from '@src/core/usecases/transaction/get-transaction-detail-usecases';
import { GetReceiptPdfUsecase } from '@src/core/usecases/receipt/get-receipt-pdf-usecases';
import { ApprovalandRefuseRequest, ApprovalList, TransferApprovalModel } from '@src/core/domain/transfer-approval/approval.model';
import * as Util from '@src/shared/util-common';
import { GetApprovalListUsecase } from '@src/core/usecases/transfer-approval/get-approval-list-usecases';
import { TransactionsDataShare } from '@src/core/usecases/data-share/transactions-data-share.service';
import { TabsDataShare } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-transaction-approval-batch',
  templateUrl: './transaction-approval-batch.component.html',
  styleUrls: ['./transaction-approval-batch.component.scss']
})
export class TransactionApprovalBatchComponent implements OnInit {
  constructor(
    private getApprovalList: GetApprovalListUsecase,
    private getDetail: GetTransactionDetailUsecase,
    private transactionsDataShare: TransactionsDataShare,
    private tabsDataShared: TabsDataShare,
    private postExportExcel: PostExportExcelUsecase,
    private getPdf: GetReceiptPdfUsecase,
    private route: ActivatedRoute

  ) { }
  public data;
  public filter = new FormControl('');
  public loading: boolean;
  public hide: boolean;
  public shown = [];
  public shownAll: boolean;
  public dataAll: ApprovalList[];
  public actionConfirmed: boolean;
  public showConfirmation: boolean;
  public showConfirmationRadio: boolean;
  public checkedArrayIsNotEmpty: boolean;
  public checkedArrayIsExpiredTicket: boolean;
  public oneOrMorePendingChecked: boolean;
  public oneOrMorePendingExpiredChecked: boolean;
  public isPoolPenddingselected: boolean;
  public isExpiredTicketSelected: boolean;
  public showRefusedOrApproveAction: boolean;
  public showIndividualConfirmation: boolean;
  public showIndividualRecusa: boolean;
  public approvalList = [];
  public checkedList = [];
  public action: string;
  public total;
  public params;
  public type;
  public count = 0;
  public dataDetail;
  public loadingDetail: boolean;
  public util = Util;
  public selectedTab: string;
  public status: string;
  public options = [];
  public actionAproveOrRefuse = false;
  public channelEnum;
  public objRequest: ApprovalandRefuseRequest;
  public isSchedules;
  public typToastColor;
  public indexList: boolean;
  public isPendent: boolean;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public AMBARCOLOR = '#D69D10';
  public paramsExport;
  public showCheckbox: boolean;
  public selected = [];
  public initAllFlag = true;
  public varSubscriptTabs: Subscription;
  public varSubscriptTransaction: Subscription;
  public vencidoJuros;
  public vencidoMultas;
  public vencidoMora;
  public totalEncargos;
  public dataDetailEncargos;
  public transacaoSelecionada;
  public loadingEncargos: boolean = true;
  public cnab240_arquivo_id: number;
  public batchDetails = {
    cnab240_arquivo_nome: "-",
    valorTotal: 0,
    quantidadeTotalPagamento: 0,
    nomeEmpresa: "",
    contaParaDebito: null
  };
  verifySchedule = [];

  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(FilterComponent, null) filterComp: FilterComponent;

  public generatePDF = (value?) => Util.generatePDF(value);
  public removeMask = (value?) => Util.removeMask(value);
  public userEmail;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const { id } = params

      this.cnab240_arquivo_id = Number(id)
    })

    this.init();
    this.userEmail = localStorage.getItem('useremail')
  }

  ngOnDestroy() {
    sessionStorage.removeItem('tab');

    if (this.varSubscriptTabs) {
      this.varSubscriptTabs.unsubscribe();
    }

    if (this.varSubscriptTransaction) {
      this.varSubscriptTransaction.unsubscribe();
    }
  }

  private init = () => {
    this.typToastColor = this.REDCOLOR;
    this.options = [
      { idOption: 'Tudo', option: 'Tudo' },
      { idOption: 'PENDENTE', option: 'Pendentes' },
      { idOption: 'LIQUIDADO', option: 'Liquidados' },
      { idOption: 'RECUSADO', option: 'Recusados' },
      { idOption: 'AGENDADO', option: 'Agendados' },
      { idOption: 'VENCIDO', option: 'Vencidos' },
    ];
  };

  private requestDetail(id) {
    this.loadingDetail = true;
    const params: TransactionDetailRequest = {
      idTransacao: id,
    };
    this.getDetail
      .execute(params)
      .subscribe(this.successDetail, this.errorDetail);
  }

  private successDetail = (value) => {
   
    this.dataDetail = value.data;
    this.loadingDetail = false;
    localStorage.setItem('approval-receipt', JSON.stringify(this.dataDetail));
  };

  private errorDetail = (err) => {
    this.loadingDetail = false;
  };

  private requestDetailEncargos(id) {
    this.loadingEncargos = true;
    const params: TransactionDetailRequest = {
      idTransacao: id,
    };
    this.getDetail
      .execute(params)
      .subscribe(this.successDetailEncargos, this.errorDetailEncargos);
  }

  private successDetailEncargos = (value) => {
    this.loadingEncargos = false;

    this.dataDetailEncargos = value.data;
    if (this.dataDetailEncargos && this.dataDetailEncargos.info_encargo) {
      this.vencidoJuros = +this.dataDetailEncargos.info_encargo.vlr_juros;
      this.vencidoMora = +this.dataDetailEncargos.info_encargo.vlr_mora;
      this.vencidoMultas = +this.dataDetailEncargos.info_encargo.vlr_multa;
      this.totalEncargos = +this.dataDetailEncargos.vlr_pago_transacao

    }
  };

  private errorDetailEncargos = (err) => {
    this.loadingEncargos = false;
  };
  private resolveStatus(status?) {
    switch (status) {
      case 'AGENDADO':
        return 1;
      case 'PENDENTE':
        return 2;
      case 'LIQUIDADO':
        return 5;
      case 'RECUSADO':
        return 6;
      case 'VENCIDO':
        return 7;

      default:
        return '';
    }
  }

  public loadBreadcumbBatchData(): void {

    this.batchDetails.valorTotal = this.dataAll.reduce((acc, { vlr_transacao }) => acc + vlr_transacao, 0)
    this.batchDetails.quantidadeTotalPagamento = this.dataAll.length
    this.batchDetails.contaParaDebito = this.dataAll[0].num_conta
    this.batchDetails.nomeEmpresa = JSON.parse(localStorage.getItem("batch-company-name")).name

  }

  private requestSearch(filter?, type?, tabs?) {
    startWith('');
    this.loading = true;
    this.resetList();
    this.hide = false;
    const params = {
      dt_inicial: this.params.dt_Inicio,
      dt_final: this.params.dt_Final,
      num_conta: filter.account === 'Todas' ? '' : filter.account,
      status: tabs === 'Tudo' ? '' : tabs,
      tipo_transacao: type === 'Todas' ? '' : type,
      num_cpf_cnpj: '',
      cod_empresa: 0,
      cnab240_arquivo_id: this.cnab240_arquivo_id
    };
    this.paramsExport = params;
    this.getApprovalList
      .execute(params)
      .subscribe(
        this.requestSuccess,
        this.requestError,
        () => {
          (this.loading = false)
          this.loadBreadcumbBatchData()
        }
      );
  }

  private verifySuccess(value) {
    const data = value.data;
    if (value.data === '') {
      this.data = [];
    } else {
      this.data = Util.verifyArray(data);

      this.data.sort((a, b) =>
        a.dt_transacao > b.dt_transacao
          ? -1
          : b.dt_transacao > a.dt_transacao
            ? 1
            : 0
      );
      this.batchDetails.cnab240_arquivo_nome = this.data[0].Cnab240_Arquivo_Nome;
    }
  }

  private requestSuccess = (value: TransferApprovalModel) => {
    this.verifySuccess(value);
    this.dataAll = this.data;
   
    localStorage.setItem(
      'approval-export-data',
      JSON.stringify({
        data: this.data,
        type: this.type,
        date: this.params,
        tabs: this.selectedTab,
      })
    );
    this.hide = Util.isEmpty(this.data);
    this.checkStatus(this.data);
    this.loading = false;
  };

  private requestError = (err) => {
    this.batchDetails.valorTotal = 0;
    this.batchDetails.quantidadeTotalPagamento = 0;
    this.loading = false;
    this.data = [];
    this.hide = Util.isEmpty(this.data);
  };

  private verifyChecked(data, checked) {
    let aux = [];
    if (this.approvalList.some((e) => e.desc_status)) {
      aux = this.approvalList.filter((e) => e.desc_status);
      for (const element of aux) {
        if (element.desc_status === 'VENCIDO') {
          if (aux.length > 1) {
            this.oneOrMorePendingExpiredChecked = true;
            this.isExpiredTicketSelected = false;
            this.showRefusedOrApproveAction = false;
            this.showConfirmationRadio = true;
          } else {
            this.isExpiredTicketSelected = true;
            this.showRefusedOrApproveAction = true;
            this.isPoolPenddingselected = false;
            this.showConfirmationRadio = true;
            this.requestDetailEncargos(element.cod_transacao);
            return;
          }
        }
      }
    }

    if (data.desc_status === 'PENDENTE' && checked) {
      this.isPoolPenddingselected = true;
      this.showConfirmationRadio = true;
      this.oneOrMorePendingExpiredChecked = this.approvalList.map(x => x.desc_status == 'VENCIDO').includes(true);
    }

    if (data.desc_status === 'AGENDADO' && checked) {
      this.isPoolPenddingselected = true;
      this.showConfirmationRadio = true;
      this.oneOrMorePendingExpiredChecked = this.approvalList.map(x => x.desc_status == 'VENCIDO').includes(true);
    } else {
      this.isExpiredTicketSelected = false;
      this.isPoolPenddingselected = true;

      this.showConfirmationRadio = this.showRefusedOrApproveAction ? false : true;
      this.oneOrMorePendingExpiredChecked = this.approvalList.map(x => x.desc_status == 'VENCIDO').includes(true);
    }
  }

  private totalValue() {
    this.total = 0;

    for (const item of this.approvalList) {

      const element = item.vlr_transacao;
      this.total += element;

    }
  }

  private resetAction() {
    this.checkedArrayIsNotEmpty = false;
    this.checkedArrayIsExpiredTicket = false;
    this.oneOrMorePendingChecked = false;
    this.isPoolPenddingselected = false;
    this.showConfirmationRadio = false;
    this.isExpiredTicketSelected = false;
    this.showRefusedOrApproveAction = false;
    this.oneOrMorePendingExpiredChecked = false;
    this.showConfirmation = false;
    this.showIndividualConfirmation = false;
    this.showIndividualRecusa = false;
  }

  private resetList() {
    this.data = [];
    this.approvalList = [];
    this.checkedList = [];
  }

  private resetCheckBox() {
    this.checkedList = [];
    this.approvalList = [];
    this.total = 0;
    this.shownAll = true;
    this.checked('all');
  }

  private navigateToResource(router?: string): void {
    window.open(router);
  }

  public async tabsSelect(tabs) {
    this.selectedTab = tabs;
    this.isSchedules = tabs === 'AGENDADO' || tabs === 'PENDENTE';
    this.filterComp.formatDate(this.isSchedules);
    this.filterComp.sendOptions();
  }

  public checkStatus = (data) => {
    const numArrayToAllow = [];
    if (data !== undefined) {
      if (isArray(data)) {
        data.forEach((e) => {
          if (e.desc_status === 'LIQUIDADO' || e.desc_status === 'RECUSADO' || e.desc_status === 'CANCELADO BANCO FIBRA') {
            numArrayToAllow.push(e);
          }
        });
      }
      this.showCheckbox = data.length !== numArrayToAllow.length;
      return;
    }
    this.showCheckbox = true;
  };
  private addChecListAll(item, idx, index) {
    // Trava temporaria para cancelar agendado
    this.verifySchedule.push(item);

    this.checkedList.push(item.cod_transacao);
    this.approvalList.push(item);
    this.selected.push(index);
    this.verifyChecked(item, this.shown[idx]);
  }
  public checked(idx, data?: ApprovalList) {
    // Verificação para trava temporaria: adiciona dados no array verifySchedule
    // Remover após correção
    if(idx !== "all"){
      this.verifySchedule.push({ desc_status: data.desc_status});      
    }

    this.showIndividualConfirmation = false;
    this.showIndividualRecusa = false;
    if (this.selected.find(x => x == idx) != null) {
      let index = this.selected.findIndex(x => x == idx);
      this.selected.splice(index, 1);
    } else {
      this.selected.push(idx);
    }

    this.showRefusedOrApproveAction = false;
    if (idx !== 'all') {
      this.shown[idx] = !this.shown[idx];
      if (this.shown[idx] !== false) {
        this.checkedList.push(data.cod_transacao);
        this.approvalList.push(data);
        this.verifyChecked(data, this.shown[idx]);
      } else {
        this.approvalList.splice(this.approvalList.indexOf(data), 1);
        this.checkedList.splice(
          this.checkedList.indexOf(data.cod_transacao),
          1
        );
        if (this.checkedList.length === 0) {
          this.checkedArrayIsNotEmpty = false;
          this.checkedArrayIsExpiredTicket = false;
          this.oneOrMorePendingChecked = false;
          this.isPoolPenddingselected = false;
          this.showConfirmationRadio = false;
          this.isExpiredTicketSelected = false;
          this.showRefusedOrApproveAction = false;
        } else {
          this.verifyChecked(data, this.shown[idx]);
        }

      }
    } else {
      this.shownAll = !this.shownAll;
      this.approvalList = [];
      this.checkedList = [];
      this.selected = [];
      this.data.map((item, index) => {
        const elem = document.getElementById(
          index.toString()
        ) as HTMLInputElement;
        elem.checked = this.shownAll;
        this.shown[index] = this.shownAll;

        if (
          this.shownAll !== false &&
          !this.emProcessamento(item.aprovadores)
          && this.isMaster(item.aprovadores) 
          && item.desc_status == 'PENDENTE' ||
          item.desc_status == 'AGENDADO') {
          this.addChecListAll(item, idx, index);
        } else if (this.shownAll !== false && item.desc_status == 'VENCIDO' && item.tipo_transacao == 'PAGAMENTO') {
          this.addChecListAll(item, idx, index);
        }
        else {
          this.totalValue();
        }
      });
    }
    this.objRequest = {
      lista_transacoes: this.checkedList,
    };
    if (this.objRequest && this.objRequest.lista_transacoes.length == 0) {
      this.resetAction();
    }
    this.totalValue();
  }

  public closeModal = () => {
    this.actionConfirmed = false;
    this.cancelConfirmation();
  };

  public changeTypeApprove(value) {
    this.actionAproveOrRefuse = value;
    this.channelEnum = value;
  }

  public approveOrRefuse(action, index, item?) {
    // Trava temporaria para status agendado
    if(item.desc_status === "AGENDADO"){
      this.toast.callModalMessage(null, "Por favor entrar em contato com o Banco Fibra.", '',  false, true);
      return false;
    }

    this.showConfirmationRadio = false;
    this.cancelConfirmation();
    this.action = action;
    if (action === 'aprovar') {
      this.channelEnum = 9;
      this.showIndividualConfirmation = true;
      this.showIndividualRecusa = false;
      this.requestDetailEncargos(item.cod_transacao);
    }
    if (action === 'recusar') {
      this.channelEnum = 10;
      this.showIndividualConfirmation = false;
      this.showIndividualRecusa = true;
    }
    if (this.showRefusedOrApproveAction || this.showConfirmationRadio) {
      return this.cancelConfirmation();
    }
    if (item.desc_status === 'PENDENTE') {
      this.showConfirmation = true;
      this.checkedList.push(item.cod_transacao);
      this.objRequest = {
        lista_transacoes: this.checkedList,
      };
      this.showRefusedOrApproveAction = true;
      return;
    }
    if (item.desc_status === 'VENCIDO') {
      this.isExpiredTicketSelected = true;
      this.showRefusedOrApproveAction = true;
      this.checkedList.push(item.cod_transacao);
      this.objRequest = {
        lista_transacoes: this.checkedList,
      };
      this.showRefusedOrApproveAction = true;
      return;
    }

    if (item.desc_status === 'AGENDADO') {
      this.showConfirmation = true;
      this.checkedList.push(item.cod_transacao);
      this.objRequest = {
        lista_transacoes: this.checkedList,
      };
      this.showRefusedOrApproveAction = true;
      return;
    }
    this.requestSearch(
      this.params,
      this.type,
      this.resolveStatus(this.selectedTab)
    );
  }

  public cancelConfirmation() {
    // Parte da trava temporaria: zerando valor do array
    // Remover após correção
    this.verifySchedule = [];
    this.showRefusedOrApproveAction = false;
    this.actionAproveOrRefuse = false;
    this.resetAction();
    this.resetCheckBox();
  }

  public confirmAction() {
    // Trava temporaria para cancelar agendado
    let trava = this.verifySchedule.find(e => e.desc_status === 'AGENDADO');
    if(trava){
      this.toast.callModalMessage(null, "Por favor entrar em contato com o Banco Fibra.", '',  false, true);
      return false;
    }
    this.verifySchedule = [];
    this.actionConfirmed = true;
    this.resetAction();
  }

  public aprovarAction() {
    this.actionConfirmed = true;
    this.channelEnum = 9;
    this.resetAction();
  }

  public recusarAction() {
    this.actionConfirmed = true;
    this.channelEnum = 10;
    this.resetAction();
  }
  public sendReceipt(item) {
    this.getPdf
      .execute(item.cod_transacao)
      .subscribe(this.successPdf, this.errorPdf);
    // this.requestDetail(item.cod_transacao);
    // setTimeout(() => {
    //   this.navigateToResource('/approval-receipt');
    // }, 300);
  }

  private successPdf = (value) => {
    fetch(value.data.url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Comprovante_transação.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
  };

  private errorPdf = (err) => {
    this.toast.callModalMessage(null, "Falha no download do comprovante tente novamente");
  };

  public setFilter(event?) {
    this.count = 0;
    this.varSubscriptTransaction = this.transactionsDataShare.dataShared.subscribe((res) => {
      startWith('');
      this.type = res.type;
      this.params = {
        account: "",
        dt_Final: event.dt_Final,
        dt_Inicio: event.dt_Inicio,
        periodCalendar: "",
      };
      this.requestSearch(
        this.params,
        this.type,
        this.resolveStatus(this.selectedTab)
      );
    });
  }

  public toogleDetail = (index, id) => {
    this.requestDetail(id);
    if (this.indexList !== index) {
      this.indexList = index;
      $('.collapse').removeClass('show');
      // $(`.item-icon img`).removeClass('img-arrow');
    }
  };

  public exportScreen(value) {
    if (value === 1) {
      this.navigateToResource('/approval-export');
      return;
    }

    this.postExportExcel.execute(this.paramsExport).subscribe((res) => {
      //this.successDownload(res);
      var currentdate = new Date();
      Util.handleFile(res, 'aprovacao_de_transacoes_' + currentdate.getDate().toString() + '/' + (currentdate.getMonth() + 1).toString() + '/' + currentdate.getFullYear().toString());
    }, error => {
      var currentdate = new Date();
      Util.handleFile(error.error.text, 'aprovacao_de_transacoes_' + currentdate.getDate().toString() + '/' + (currentdate.getMonth() + 1).toString() + '/' + currentdate.getFullYear().toString());
    });
  }
  private successDownload = (value) => {
    if (value.status === 'success') {
      // const blob = new Blob([value.data], { type: 'application/octet-stream' });
      Util.handleFile(value.data, 'arquivo');
    }
  };

  public verifyCalendar(event) {
    if (event === 'isGreaterThan730Days') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 730 dias.',
        null,
        null
      );
      return;
    }

    if (event === 'outOfReach') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 180 dias.',
        null,
        null
      );
      return;
    }
  }

  public securityResponse = (value?) => {
    this.approvalList = [];
    this.checkedList = [];
    this.resetAction();

    const success = (res: any) => {
      this.requestSearch(
        this.params,
        this.type,
        this.resolveStatus(this.selectedTab)
      );
      this.resetCheckBox();
      this.actionConfirmed = false;
      let returnMessage: string;

      if (this.action === 'aprovar' || (
        res && res.data && res.data[0] && res.data[0].desc_etapa_execucao &&
        res.data[0].desc_etapa_execucao === "AprovadoSucesso"
      )) {
        this.typToastColor = this.GREENCOLOR;
        returnMessage = 'Transação aprovada';
      }
      if (this.action === 'recusar') {
        this.typToastColor = this.REDCOLOR;
        returnMessage = 'Transação reprovada';
      }
      if (this.isPendent) {
        this.typToastColor = this.AMBARCOLOR;
        this.toast.callModalMessage(
          null,
          'Transações pendentes',
          'Necessário aprovação dos demais masters da sua empresa.',
          null,
          true
        );
        return;
      }
      let message = returnMessage;

      if (res.data[0].desc_mensagem != null && res.data[0].desc_mensagem != undefined) {
        message = res.data[0].desc_mensagem
        if (message === 'Será realizada na data agendada, mediante disponibilidade de saldo em conta.') {
          message = 'Transferência agendada. Será realizada na data agendada, mediante disponibilidade de saldo em conta.'
        }
      }
      this.toast.callModalMessage(null, `${message}`, '', null, true);
    };
    const error = (err) => {
      this.checkedList = [];
      this.approvalList = [];
      this.actionConfirmed = false;
      this.cancelConfirmation();
      if (err.error.message && err.error.message.length > 0) {
        this.toast.callModalMessage(
          null,
          `${err.error.message[0]}`,
          '',
          null,
          true
        );
      } else {
        this.toast.callModalMessage(
          null,
          'Ocorreu um erro ao aprovar transação. Tente novamente mais tarde.'
        );
      }
    };
    if (value.code === 200) {
      success(value);
      return;
    }
    error(value);
    this.resetAction();
  };

  public securityRequest = () => {
    return this.objRequest;
  };

  public showApprovalMaster(obj: any) {
    this.isPendent = obj.every((e) => e.tipo_acesso === 'Master').length > 1;
    return obj.some((e) => e.tipo_acesso === 'Master');
  }

  public showApprovalOperator(obj: any) {
    return obj.some((e) => e.tipo_acesso === 'Operador');
  }
  public checkOperator(obj: any) {
    return obj.find((x: { tipo_acesso: string; }) => x.tipo_acesso === 'Operador') != null;
  }
  verificaSelected(i: any) {
    return this.selected.find(x => x == i) != null;
  }

  isPedding(approveds): boolean {
    let isOtherMaster = false;
    approveds.map(data => {
      if (data.desc_status_aprovador.indexOf('Aguardando Aprova') !== -1 && this.userEmail === data.desc_email_aprovador) {
        isOtherMaster = true
      }
    })
    return isOtherMaster
  }

  isMaster(approveds): boolean {
    let isOtherMaster = false;
    approveds.map(data => {
      if (this.userEmail === data.desc_email_aprovador) {
        isOtherMaster = true
      }
    })
    return isOtherMaster
  }

  emProcessamento(aprovadores): boolean {
    return aprovadores && aprovadores.some(a => a.desc_email_aprovador == this.userEmail && a.flag_robot_aprovacao == true);
  }

  handleTypeConfirm() {
    let justRefuse = false
    this.approvalList.map(data => {
      for (const item of data.aprovadores) {
        if (item.desc_status_aprovador === "Aprovado" && this.userEmail === item.desc_email_aprovador) {
          justRefuse = true;
        }
      }
    })
    return justRefuse;
  }

}
