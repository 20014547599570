import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoansRequestDailyPosition } from '@src/core/domain/loans/loan-model';
import { GetDailyPositionAVencerUsecase } from '@src/core/usecases/loans/get-daily-position';
import { GetDailyPositionVencidaUsecase } from '@src/core/usecases/loans/get-daily-position-vencida';
import { GetDailyPositionVencendoUsecase } from '@src/core/usecases/loans/get-daily-position-vencendo';
import * as moment from 'moment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'fibra-daily-position',
  templateUrl: './daily-position.component.html',
  styleUrls: ['./daily-position.component.scss'],
})
export class DailyPositionComponent implements OnInit,OnDestroy {

  public data;
  public filter = new FormControl('');
  public loading: boolean;
  public hide: boolean;
  public shown = [];
  public dataAll;
  public showAndHideDisplay = 0;

  public getDailyPositionAVencerSub:Subscription;
  public getDailyPositionVencendoSub:Subscription;
  public getDailyPositionVencidaSub:Subscription;


  constructor(
    private getDailyPositionAVencer: GetDailyPositionAVencerUsecase,
    private getDailyPositionVencendo: GetDailyPositionVencendoUsecase,
    private getDailyPositionVencida: GetDailyPositionVencidaUsecase
  ) { }

  public diaryPositionList = [];
  public hasData = false;
  public status = 'avencer';
  public totalPrincipal: number;
  public totalPrincipalCorrigido: number;
  public totalJuros: number;
  public totalJurosDeMora: number;
  public totalMultaMoratoria: number;
  public totalIof: number;
  public totalAPagar: number;
  public hasError = false;
  public showDtVencimento = true;

  ngOnInit(): void {
    this.tabsSelect('vencendo');
  }


  private diaryListFillData() {
    this.totalPrincipal = 0;
    this.totalPrincipalCorrigido = 0;
    this.totalJuros = 0;
    this.totalJurosDeMora = 0;
    this.totalMultaMoratoria = 0;
    this.totalIof = 0;
    this.totalAPagar = 0;

    this.diaryPositionList.forEach(element => {
      this.totalPrincipal += element.valor_principal;
      this.totalPrincipalCorrigido += element.valor_principal_cm;
      this.totalJuros += element.valor_juros;
      this.totalJurosDeMora += element.valor_mora;
      this.totalMultaMoratoria += element.valor_multa;
      this.totalIof += element.valor_iof;
      this.totalAPagar += element.valor_a_pagar;
    });

    sessionStorage.setItem('daily-position-total-values', `
      {"totalPrincipal":${this.totalPrincipal},
     "totalPrincipalCorrigido":${this.totalPrincipalCorrigido},
     "totalJuros":${this.totalJuros},
     "totalJurosDeMora":${this.totalJurosDeMora},
     "totalMultaMoratoria":${this.totalMultaMoratoria},
     "totalIof":${this.totalIof},
     "totalAPagar":${this.totalAPagar}}`)
  }


  checkHasData() {
    if (this.diaryPositionList.length > 0) {
      this.hasData = true;
    } else {
      this.hasData = false;
    }
  }


  private capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private requestSuccess = (value: any, tab?: any) => {
    localStorage.setItem(
      'daily-export-data',
      JSON.stringify({
        data: value,
        type: null,
        date: null,
        tabs: tab,
      })
    );
  };

  public tabsSelect(tabs) {
    this.diaryPositionList = [];
    this.hasData = false;
    this.hide = false;
    this.loading = true;
    setTimeout(() => {
      if (tabs === 'vencendo') {
        this.diaryPositionList = []
        const params: LoansRequestDailyPosition = {};
        this.showDtVencimento = false;
        this.getDailyPositionVencendoSub = this.getDailyPositionVencendo.execute(params).subscribe((data) => {

          data.data.forEach(element => {
            if (element.cod_situacao === "A vencer") {
              element.cod_situacao = "À vencer"
              this.diaryPositionList.push(element)
            }
            else {
              this.diaryPositionList.push(element)
            }
          });
          //this.diaryPositionList = data.data;
          this.status = 'avencer';
          this.checkHasData();
          this.diaryListFillData();
          this.requestSuccess(this.diaryPositionList, 'vencendo')


          //Export Excel
          let excelFilterParam = 'vencendo';
          sessionStorage.setItem('excelFilterParam', excelFilterParam);

          //PDF
          sessionStorage.setItem('posicao_diaria_pdf', JSON.stringify(this.diaryPositionList) );
          sessionStorage.setItem('informations_pdf', '{"title":"Posição diária", "filter":"Vencendo hoje", "report_name":"PosicaoDiaria", "name":"daily-position"}')
          

          this.loading = false;
        }, (err) => {
          this.hasError = true
          this.loading = false
          this.hasData = false
        });
      } else if (tabs === 'avencer') {
        this.diaryPositionList = []
        const params: LoansRequestDailyPosition = {};
        this.showDtVencimento = true;
        this.getDailyPositionAVencerSub = this.getDailyPositionAVencer.execute(params).subscribe((data) => {
          
          //Export Excel
          let excelFilterParam = 'A vencer';
          sessionStorage.setItem('excelFilterParam', excelFilterParam);

          data.data.forEach(element => {
            if (element.cod_situacao === "A vencer") {
              element.cod_situacao = "À vencer"
              this.diaryPositionList.push(element)
            }
            else {
              this.diaryPositionList.push(element)
            }
          });


          this.status = 'avencer';
          this.checkHasData();
          this.diaryListFillData();
          const diaryPositionListCopy = this.diaryPositionList;
          this.diaryPositionList = [];
          let lastDateVerified = null;
          diaryPositionListCopy.forEach(element => {
            const item = element;

            const dt = moment(element.dt_vencto.substring(0, 10), "yyyy-MM-dd");
            const lastDate = `${dt.month()}-${dt.year()}`;
            if (lastDate !== lastDateVerified) {
              const index = dt.locale('pt').month();
              moment.locale('pt');
              item.infoMonthYear = `${this.capitalizeFirstLetter(moment.months(index))} de ${dt.year()}`;
              lastDateVerified = lastDate;
            } else {
              item.infoMonthYear = null;
            }
            this.diaryPositionList.push(item);

            //PDF
            sessionStorage.setItem('posicao_diaria_pdf', JSON.stringify(this.diaryPositionList) );
            sessionStorage.setItem('informations_pdf', '{"title":"Posição diária", "filter":"À vencer", "report_name":"PosicaoDiaria", "name":"daily-position"}')
            
          }, (err) => {
            this.hasError = true
            this.loading = false
            this.hasData = false
          });

          this.requestSuccess(this.diaryPositionList, 'avencer');
          this.loading = false;
        });
      } else {
        this.diaryPositionList = []
        const params: LoansRequestDailyPosition = {};
        this.showDtVencimento = true;
        this.getDailyPositionVencidaSub = this.getDailyPositionVencida.execute(params).subscribe((data) => {
          this.diaryPositionList = data.data;
          this.checkHasData();
          this.diaryListFillData();
          this.status = 'vencido';

          //Export Excel
          let excelFilterParam = 'Vencida';
          sessionStorage.setItem('excelFilterParam', excelFilterParam);

          const diaryPositionListCopy = this.diaryPositionList;
          this.diaryPositionList = [];
          let lastDateVerified = null;
          diaryPositionListCopy.forEach(element => {
            const item = element;

            const dt = moment(element.dt_vencto.substring(0, 10), "yyyy-MM-dd");
            const lastDate = `${dt.month()}-${dt.year()}`;
            if (lastDate !== lastDateVerified) {
              const index = dt.locale('pt').month();
              moment.locale('pt');
              item.infoMonthYear = `${this.capitalizeFirstLetter(moment.months(index))} de ${dt.year()}`;
              lastDateVerified = lastDate;
            } else {
              item.infoMonthYear = null;
            }
            this.diaryPositionList.push(item);

            //PDF
            sessionStorage.setItem('posicao_diaria_pdf', JSON.stringify(this.diaryPositionList) );
            sessionStorage.setItem('informations_pdf', '{"title":"Posição diária", "filter":"Vencidas", "report_name":"PosicaoDiaria", "name":"daily-position"}') 
            
          }, (err) => {
            this.hasError = true;
          });
          this.requestSuccess(this.diaryPositionList, 'vencido')
          this.loading = false;
        }, (err) => {
          this.loading = false;
          this.hasData = false;
          this.hasError = true;
        });
      }
    }, 600);
  }

  ngOnDestroy()
  {
    //this.getDailyPositionVencidaSub.unsubscribe();
    //this.getDailyPositionVencendoSub.unsubscribe();
    //this.getDailyPositionAVencerSub.unsubscribe();
    sessionStorage.removeItem('excelFilterParam');
    sessionStorage.removeItem('daily-position-total-values')
    sessionStorage.removeItem('informations_pdf')
    sessionStorage.removeItem('posicao_diaria_pdf')
  }
}
