import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';

@Injectable({
    providedIn: 'root',
})
export class BannerEmailDataRepository {

    constructor(private http: HttpClient) {}

    public sendBannerEmail(parametros) :Observable<any> {
        const params = {
            "name": parametros.name,
            "type": parametros.type,
            "num_cpf_cnpj": parametros.num_cpf_cnpj,
            "product_name": parametros.product_name,
            "phone": parametros.phone,
            "email": parametros.email,
            "contact_type": parametros.contact_type
        };
        const httpOptions = {
          headers: getHeader(),
        };
          return this.http.post<any>(`${environment.URLBase}v1/emailcomercial`, params, httpOptions);
    }

    public getCampaign(companyId) :Observable<any> {
        const httpOptions = {
          headers: getHeader(),
        };
          return this.http.get<any>(`${environment.URLBase}v1/Campanha/Empresa/${companyId}`, httpOptions);
    }

}