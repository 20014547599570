<div #rootdiv class="message-transaction" id="message" [style.backgroundColor]="color">
  <img src="/assets/svg/warning.svg" />
  <div class="text">
    <h4 class="title">{{ dataMsgTransaction.title }}</h4>
    <div class="msg">{{ dataMsgTransaction.msg }}</div>
  </div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="closeModal()"
    *ngIf="dataMsgTransaction.showButtonClose"
  >
    <img src="/assets/svg/icon_close_white.svg" />
  </button>
  <div class="buttons" *ngIf="dataMsgTransaction.showButtonOptions">
    <!-- <button class="button" (click)="returnScreen()"> Sim </button> -->
    <button class="button" (click)="closeModal(); clickNo()">Não</button>
    <button class="button" (click)="clickYes()">Sim</button>
  </div>
</div>
