<div
  class="box-breadcrumb"
  [style.height.px]="height"
  [style.margin-bottom.px]="bottom"
>
  <div class="info">
    <p>
      {{ infoAccount }}
    </p>
  </div>
  <div class="roule">
   <div class="roule-wrapper">
      <h1>
        <img
          (click)="goBack()"
          *ngIf="arrow === 'true'"
          src="/assets/svg/arrow_page.svg"
          alt="arrow-page"
        />
        {{ roule }}
      </h1>
    <span class="cnab_code" *ngIf="batchDetails">{{ batchDetails?.cnab240_arquivo_nome }}</span>
   </div>
   
  

    <div *ngIf="downloadDoc === true" class="dropdown-roule">
      <fibra-dropdown
        textColor="#082a4d"
        disabledChange="true"
        placeholder="{{exportButtonPlaceholder}}"
        [prefix]="dowloadIcon"
        (change)="sendInfo($event)"
        [options]=options
      ></fibra-dropdown>
      <ng-template #dowloadIcon>
        <img
          src="/assets/svg/download-accent.svg"
          alt="download-accent"
          style="padding-right: 10px"
        />
      </ng-template>
    </div>

    <div *ngIf="showBtnLimitPix === true" class="dropdown-roule">
      <button (click)="redirectPixLimits()" class="secondary">Alterar meus limites PIX</button>
    </div>
  
    <a *ngIf="linkExternoUrl != null" class="link-externo" [href]="linkExternoUrl" target="_blank" >
      <span>{{linkExternoTitulo}}</span>
    </a>  
  </div>

  <div class="info-text">
    <span>{{ infoText }}</span>
  </div>
  <div class="batch-details" *ngIf="batchDetails">
    <p>Conta para Débito:  {{ batchDetails.contaParaDebito }} - {{ batchDetails.nomeEmpresa }}</p>
    <p>Quantidade de pagamento/transações : {{ batchDetails.quantidadeTotalPagamento }} | Valor total {{ batchDetails.valorTotal | currency: 'BRL' }}</p>
  </div>
</div>
