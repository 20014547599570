import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { PixTermResponse } from './pix-terms.entity';

@Injectable({
  providedIn: 'root',
})
export class PixCreateTermDataRepository {

  constructor(private http: HttpClient) {}

  public createPixTerm() :Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.post<any>(`${environment.URLBase}v1/termoaceite`,{}, httpOptions);
  }
}
