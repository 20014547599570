<div class="box-drop">
  <ng-container *ngIf="optionsPurposes.length > 1">
    <fibra-dropdown
      [style.width.px]="400"
      placeholder="Selecionar finalidade"
      [options]="optionsPurposes"
      removeShadown="true"
      (emitChange)="selectPurpose($event)"
      textColor="#8F969D"
      [typeDrop]="'profile'"
    ></fibra-dropdown>
  </ng-container>
</div>
