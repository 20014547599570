<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>
<div class="content-page" *fibraHasRole="'INF_REND.CONS_INF_REND'">
  <div class="div">
    <fibra-breadcrumb
    bottom="0"
    infoAccount="Informe de rendimentos"
    roule="Informe de rendimentos"
    arrow="false"
    downloadDoc=""
  ></fibra-breadcrumb>
  <div class="content">
    <div class="group-items" 
      [ngClass]="{'warning-filter' : !loading && incomeForm.invalid && (incomeForm.dirty || incomeForm.touched) && yearInput.value.length === 4}" 
      [formGroup]="incomeReportForm" 
      #incomeForm="ngForm"
    >
      <div class="period">
        <div class="label-period">Ano</div>
        <div class="group-period">
          <div *ngFor="let item of optionPeriod" class="options-period-container">
            <div
              class="options-period"
              (click)="changePeriodItem(item)"
              [ngClass]="{ activeCalendar: selectedYear == item && incomeForm.valid && !(incomeForm.dirty || incomeForm.touched)}"
            >
              {{ item }}
            </div>
          </div>
          <input
          type="text"
          maxlength="4"
          placeholder="Digite outro ano"
          class="line-other-period"
          name="yearNum"
          id="yearNum"
          formControlName="yearNum"
          maxlength="4"
          #yearInput
          >
          <div class="button-receipt">
            <button
              type="button"
              class="button-continue"
              (click)="changePeriodButton(yearInput.value)"
              [disabled]="incomeForm.invalid ||  yearInput.value.length !== 4"
            >
              Consultar
            </button>
          </div>
        </div>
        
      </div>
      <div class="period-warning" *ngIf="!loading && incomeForm.invalid && (incomeForm.dirty || incomeForm.touched) && yearInput.value.length === 4">
        <span class="item-icon warning-icon">
          <img
            src="/assets/svg/warning-red.svg"
            alt="download-accent"
            style="padding-right: 10px"
          />
        </span>
        <div class="warning-text">
          Digite um ano entre {{startYear}} e {{endYear}}.
        </div>
      </div>
    </div>
    <div class="datatable" *ngIf="!loading && !noData && !hasError">
      <div class="table-title">
        <div class="table-item">
          <span class="title-font">{{selectedYear}}</span>
        </div>
      </div>
      <div class="table-content" *ngFor="let item of filteredIncomeReport; let i = index">
        <div class="title">
          <span class="subtitle-font">{{getPeriodDescription(item)}} </span>
        </div>
        <div class="table-title">
          <div class="table-item">
            <div class="table-content-inline">
              <div>
                <img
                  src="/assets/svg/informe-rendimentos.svg"
                  alt="download-accent"
                  style="padding-right: 10px"
                />
              </div>
              <div>
                <div>
                  <span class="item-font">{{item.nome_arquivo}}</span>
                </div>
                <div>
                  <span class="item-subfont">{{getFileSize(item)}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="table-item">
            <span class="item-icon">
                <img
                  src="/assets/svg/download-accent-dark.svg"
                  alt="download-accent"
                  style="padding-right: 10px"
                  class="cursor-pointer"
                  (click)="downloadFile(item)"
                />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="datatable warning-display" *ngIf="loading">
      <div class="d-flex justify-content-center position loading-container">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div class="datatable warning-display"  *ngIf="noData && !loading">
      <div class="empty-info">
        <div 
          class="generic-info"
          *ngIf="noData && !loading"
        >
          <img src="/assets/png/icon-pending.png" />
          <h1 [style.color]="'#D69D10'">Não possui informe de rendimentos para esse período.</h1>
          <div class="generic-info-message">Tente fazer uma nova pesquisa inserindo outro período.</div>
        </div>
        <div 
          class="generic-info" 
          *ngIf="hasError && !loading"
        >
          <img src="/assets/png/icon-error.png" />
          <h1 [style.color]="'#ED6C6C'">Ocorreu um erro inesperado.</h1>
          <div class="generic-info-message">Não foi possível encontrar dados de informes de rendimento!</div>
        </div>
        <!-- <fibra-generic-info
          type="warn"
          title="Não possui informe de rendimentos para esse período."
          message="Tente fazer uma nova pesquisa inserindo outro período."
          style="max-height: 420px;"
        >
        </fibra-generic-info>
    
        <fibra-generic-info
        type="error"
        title="Ocorreu um erro inesperado"
        message="Não foi possível encontrar dados de informes de rendimento!"
        *ngIf="hasError && !loading"
        >
        </fibra-generic-info> -->
      </div>
    </div>
    <div class="datatable warning-display" *ngIf="hasError && !loading">
      <div class="empty-info" >
        <fibra-generic-info
        type="error"
        title="Ocorreu um erro inesperado"
        message="Não foi possível encontrar dados de movimentação!"
      >
      </fibra-generic-info>
        </div>
    </div>
  </div>
</div>
<fibra-toast-error-message
    [color]="toastColor"
  ></fibra-toast-error-message>
