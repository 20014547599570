import { MsalService } from '@azure/msal-angular';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { OpenBankingTransactionStatusEnum } from '@src/core/domain/openbanking/openbanking.transaction.status.enum';
import { IAcceptDoc } from '@src/data/repository/accept-doc/accept-doc.entity';
import { OpenBankingRepository } from '@src/data/repository/openbanking/openbanking.repository';
import { AcceptDocPageComponent } from '../../components/accept-doc/accept-doc.component';
import { AlertComponent } from '../../components/alert/alert.component';
import { PixAccountEntity } from '@src/data/repository/pix/accounts/pix-accounts-entity';
import { IOpenBankingTransaction } from '@src/core/domain/openbanking/openbanking.transaction.model';
import { RolesService } from '@src/shared/services/roles.service';
import { OpenbankingPaymentRequestNewTransactionComponent } from './newtransaction/openbanking-paymentrequest-newtransaction.component';
import { OpenbankingPaymentRequestConfirmTransactionComponent } from './confirmtransaction/openbanking-paymentrequest-confirmtransaction.component';
import { OpenbankingOnboardingComponent } from '../../components/openbanking-onboarding/openbanking-onboarding.component';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';

@Component({
    selector: 'fibra-openbanking-paymentrequest',
    templateUrl: './openbanking-paymentrequest.component.html',
    styleUrls: ['./openbanking-paymentrequest.component.scss']
})
export class OpenbankingPaymentRequestComponent implements OnInit, OnDestroy {

    private _sub: Subscription;
    private _subCancel: Subscription;

    public status: OpenBankingTransactionStatusEnum = OpenBankingTransactionStatusEnum.InProgress;
    public session: { data: any };
    public transaction: any;
    public transactionWasSuccessful: boolean;
    public accounts: { items: PixAccountEntity[] } = { items: [] };
    public today = new Date();
    public isScheduling: boolean = false;
    private validationRole = false;
    private selectedAccount: any;

    public apiCallback = 119;

    public params;
    public apiCallBack;
    public showModalValidation = false;

    public readonly ONBOARDING = 0;
    public readonly TERMSOFUSE = 1;
    public readonly NEWTRANSACTION = 2;
    public readonly CONFIRMTRANSACTION = 3;
    public readonly REDIRECT = 4;

    public currentPresentation: number;

    public get title(): string | null {
        switch (this.currentPresentation) {
            case this.ONBOARDING: return "Onboarding";
            case this.TERMSOFUSE: return "Termo de Uso"
            case this.NEWTRANSACTION: return "Autorizar Transferência Inteligente";
            case this.CONFIRMTRANSACTION: return this.isScheduling ? "Confirmação de solicitação de agendamento" : "Confirmação de solicitação de pagamento";
            default: return "Nova transação";
        }
    }

    public termsOfUse: IAcceptDoc = {
        docTitle: 'Termo de uso',
        docUrl: 'term-user-openbanking',
        buttonTittle: 'Aderir',
        checkTitle: 'Li e concordo com o Termo de Uso.',
        readOnly: false
    };

    public get self() {
        return this;
    }

    getDataTransferencia(){
        if(!this.isScheduling){
            return this.transaction.consentimento.payment.date;
        } else {
            if("custom" in this.transaction.consentimento.payment.schedule){
                return this.transaction.consentimento.payment.schedule.custom.dates[0];
            } else if ("daily" in this.transaction.consentimento.payment.schedule){
                return this.transaction.consentimento.payment.schedule.daily.startDate;
            } else if ("weekly" in this.transaction.consentimento.payment.schedule){
                return this.transaction.consentimento.payment.schedule.weekly.startDate;
            } else if ("monthly" in this.transaction.consentimento.payment.schedule){
                return this.transaction.consentimento.payment.schedule.monthly.startDate;
            } else if ("single"in this.transaction.consentimento.payment.schedule){
                return this.transaction.consentimento.payment.schedule.single.date;
            }
        }
    }

    public securityValidationDialogVisible: boolean = false;

    public get apiPayload(): any {
        let payload = {};
        if (this.transaction.consentimento.creditors && Array.isArray(this.transaction.consentimento.creditors)) {
            this.apiCallback = 119;
            payload = {
                totalAmount: this.transaction.consentimento.recurringConfiguration.sweeping.totalAllowedAmount,
                data_transferencia: moment().toISOString(),
                descricao: 'Transferência inteligente',
                recurringInteractionId: this.transaction.interactionId,
                recurringConsentId: this.transaction.consentimento.recurringConsentId,
            }
        } else {
            this.apiCallback = 120;
            payload = {
                num_canal_pagamento: 3,
                valor: this.transaction.consentimento.payment.amount,
                data_transferencia: this.getDataTransferencia(),
                descricao: `Transação Openbaking`,
                conta_origem: {
                    agencia_conta: this.transaction.consentimento.selectedAccount.cod_agencia,
                    num_conta: this.transaction.consentimento.selectedAccount.num_conta,
                },
                conta_destino: {
                    cod_banco: this.transaction.consentimento.payment.details.creditorAccount.ispb,
                    agencia_conta: this.transaction.consentimento.payment.details.creditorAccount.issuer,
                    num_conta: this.transaction.consentimento.payment.details.creditorAccount.number,
                    cpf_cnpj: this.transaction.consentimento.creditor.cpfCnpj.replace(/[^\d]+/g, ''),
                    nome: this.transaction.consentimento.creditor.name,
                },
                interactionId: this.transaction.interactionId,
                consent_id: this.transaction.consentimento.consentId,
                localInstrument: this.transaction.consentimento.payment.details.localInstrument,
                proxy: this.transaction.consentimento.payment.details.proxy,
                isSchedule: this.isScheduling,
                schedule: this.isScheduling ? this.transaction.consentimento.payment.schedule : null
            };
        }
        return payload;
    }


    //#region alert

    public alertVisible: boolean = false;
    public alertShowIcon: boolean = true;
    public alertPrimaryMsg: string;
    public alertSecondaryMsg: string;
    public alertPrimaryAction: { caption: string, action: () => void };
    public alertSecondaryAction: { caption: string, action: () => void };

    //#endregion

    //#region error

    public errorVisible: boolean = false;
    public errorShowIcon: boolean = true;
    public errorPrimaryMsg: string;
    public errorSecondaryMsg: string;
    public errorAction: { caption: string, action: () => void };
    public errorIcon: string;

    //#endregion

    //#region cancelation dialog

    public cancelationDialogVisible: boolean = false;

    //#endregion

    private _toast: ToastErrorMessageComponent;
    @ViewChild(ToastErrorMessageComponent, null) public set toast(value: ToastErrorMessageComponent) {
        this._toast = value;
    }

    private _onBoardingComponent: OpenbankingOnboardingComponent;
    @ViewChild(OpenbankingOnboardingComponent, null) public set onBoardingComponent(value: OpenbankingOnboardingComponent) {
        this._onBoardingComponent = value;
    }

    private _acceptDocPageComponent: AcceptDocPageComponent;
    @ViewChild(AcceptDocPageComponent, null) public set acceptDocPageComponent(value: AcceptDocPageComponent) {
        this._acceptDocPageComponent = value;
    }

    private _newTransPageComponent: OpenbankingPaymentRequestNewTransactionComponent;
    @ViewChild(OpenbankingPaymentRequestNewTransactionComponent, null) public set newTransPageComponent(value: OpenbankingPaymentRequestNewTransactionComponent) {
        this._newTransPageComponent = value;
    }

    private _confirmPaymentPageComponent: OpenbankingPaymentRequestConfirmTransactionComponent;
    @ViewChild(OpenbankingPaymentRequestConfirmTransactionComponent, null) public set confirmPaymentPageComponent(value: OpenbankingPaymentRequestConfirmTransactionComponent) {
        this._confirmPaymentPageComponent = value;
    }

    private _alertDialog: AlertComponent;
    @ViewChild('alert', null) public set alertDialog(value: AlertComponent) {
        this._alertDialog = value;
    }

    private _errorDialog: AlertComponent;
    @ViewChild('error', null) public set errorDialog(value: AlertComponent) {
        this._errorDialog = value;
    }

    private _cancelOperationDialog: AlertComponent;
    @ViewChild('cancel', null) public set cancelOperationDialog(value: AlertComponent) {
        this._cancelOperationDialog = value;
    }

    constructor(
        private _repository: OpenBankingRepository,
        private _router: Router,
        private _rolesService: RolesService,
        private authService: MsalService
    ) {

    }

    private gotoNewTransactionPage(): void {
        let _checkRoles = this._rolesService.get();
        this.checkRoles(_checkRoles);

        if (this.validationRole) {
            return;
        }

        this.currentPresentation = this.NEWTRANSACTION;
        this.showWarning('Tempo limite para a transação',
            '<span>Esta operação tem um limite de até <strong>5 minutos</strong> para ser confirmada. Caso contrário, a operação será cancelada</span>',
            {
                caption: 'Continuar com o acesso', action: () => {
                    this.alertVisible = false
                }
            },
            { caption: 'Cancelar operação', action: () => this.showCancelationDialog() });
    }

    public okOnboarding(): void {
        if (!this.transaction.termodeuso) {
            this.currentPresentation = this.TERMSOFUSE;
        } else {
            const done: Subject<boolean> = new Subject<boolean>();

            this._repository.setUserDoneOnboarding()
                .pipe(finalize(() => {
                    this._onBoardingComponent.busy = false;
                    done.next();
                    done.complete();
                }))
                .subscribe(() => this.gotoNewTransactionPage(), err => done.error(err));

            timer(100).pipe(takeUntil(done)).subscribe(() => {
                this._onBoardingComponent.busy = true;
            });
        }
    }

    public acceptTermsOfUse(): void {
        const done: Subject<boolean> = new Subject<boolean>();

        this._repository.setUserDoneOnboardingAndTermsOfUse()
            .pipe(finalize(() => {
                this._acceptDocPageComponent.showSpinner = false;
                done.next();
                done.complete();
            }))
            .subscribe(() => this.gotoNewTransactionPage(), err => done.error(err));

        timer(100).pipe(takeUntil(done)).subscribe(() => {
            this._acceptDocPageComponent.showSpinner = true;
        });
    }

    public backToOnboarding(): void {
        this.currentPresentation = this.ONBOARDING;
    }

    public okNewTransaction(selectedAccount: PixAccountEntity): void {

        if (!this.transaction.consentimento.creditors && !Array.isArray(this.transaction.consentimento.creditors)) {
            this.transaction.consentimento.selectedAccount = selectedAccount;
        }

        this.selectedAccount = selectedAccount;
        if (this.transaction.consentimento.debtorAccount != null) {
            this.confirmPayment();
        } else {
            this._newTransPageComponent.busy = true;
            const amount = this.transaction.consentimento.creditors && Array.isArray(this.transaction.consentimento.creditors) ? this.transaction.consentimento.recurringConfiguration.sweeping.totalAllowedAmount : this.transaction.consentimento.payment.amount;
            this._repository.validateAccount(selectedAccount.num_indice, amount)
                .pipe(finalize(() => this._newTransPageComponent.busy = false))
                .subscribe(result => {
                    if (!result.pode_aprovar) {
                        this.showWarning('Você não está autorizado a fazer essa operação',
                            '<span>Para ter acesso a essa funcionalidade, solicite a liberação ao(s) usuário(s) master(s) da sua empresa.</span>',
                            { caption: 'Voltar', action: () => this.alertVisible = false });
                    } else {
                        this.transaction.unico_aprovador = result.unico_aprovador;
                        this.confirmPayment();
                    }
                });
        }
    }

    public backToNewTransaction(): void {
        this.currentPresentation = this.NEWTRANSACTION;
        this.goToTop();
    }

    //#region Cancel Operation Dialog

    public doCancelTransaction = (dialog: AlertComponent) => {
        if (this._subCancel) {
            this._subCancel.unsubscribe();
        }

        if (dialog) {
            dialog.busy = true;
        }

        //this._subCancel = this._repository.cancelTransaction(this.transaction.consentimento.consentId, this.session.data)
        this._subCancel = this._repository.cancelTransaction(this.transaction.interactionId, this.transaction.consentimento.consentId)
            .pipe(finalize(() => {
                if (dialog) {
                    dialog.busy = false;
                }
            }))
            .subscribe(result => {
                if (result.errors && result.errors[0].code === 'CONSENTIMENTO_EXPIRADO') {
                    this.alertVisible = false;
                    this.cancelationDialogVisible = false;
                    this.showError(
                        'Tempo limite para transação expirado',
                        this.isScheduling ? '<span>Sua transação não foi realizada.<br>O tempo para realizar a solicitação do agendamento expirou.</span>' : '<span>Sua transação não foi realizada.<br>O tempo para realizar a solicitação do pagamento expirou.</span>',
                        { caption: 'Sair', action: () => this.redirectToDashboard() },
                        'alert');
                } else if (result.errors && result.errors[0].code !== 'CONSENTIMENTO_EXPIRADO') {
                    this.alertVisible = false;
                    this.cancelationDialogVisible = false;
                    this.showError(
                        result.errors[0].title,
                        'Ocorreu um erro inesperado tente novamente mais tarde.',
                        { caption: 'Sair', action: () => this.redirectToDashboard() },
                        'alert');
                }
                else {
                    this.status = OpenBankingTransactionStatusEnum.Canceled;
                    this.transaction.consentimento.client_uri = result.url;
                    this.redirect(false);
                }
            });
    }

    private logout(success: boolean) {
        this.status = OpenBankingTransactionStatusEnum.Canceled;
        this._rolesService.clearOpenFinanceSession();
        this.cancelationDialogVisible = false;
        this.alertVisible = false;
        this.errorVisible = false;
        this.transactionWasSuccessful = success;
        this.authService.logout();
    }

    private redirect(success: boolean) {
        this._rolesService.clearOpenFinanceSession();
        this.cancelationDialogVisible = false;
        this.alertVisible = false;
        this.errorVisible = false;
        this.transactionWasSuccessful = success;
        this.currentPresentation = this.REDIRECT;
    }

    private redirectToDashboard() {
        this._rolesService.clearOpenFinanceSession();
        this.cancelationDialogVisible = false;
        this.alertVisible = false;
        this.status = OpenBankingTransactionStatusEnum.Canceled;
        this._router.navigate(['dashboard']);
    }

    public showCancelationDialog(): void {
        this.cancelationDialogVisible = true;
    }

    public cancelationConfirmed(): void {
        this.doCancelTransaction(this._cancelOperationDialog);
    }

    public cancelationCanceled(): void {
        this.cancelationDialogVisible = false;
    }

    //#endregion

    public confirmPayment(): void {
        let primaryMsg = `<span class="header">Deseja confirmar a autorização de transferências inteligentes?</span>`;
        let secondaryMsg = '<span>A solicitação de transferências inteligentes será efetivada após autorização e você será redirecionado a instituição iniciadora de transferências inteligentes.</span>';
        let captionButton = 'Confirmar';

        const m = moment(this.transaction.consentimento.creationDateTime);

        if (!this.transaction.unico_aprovador) {
            primaryMsg += `<br><br><br>Os usuários masters têm até <strong>${m.format("DD/MM/YYYY")} às 23:59</strong> para autorizar a transação. Após essa data, deverá ser feita uma nova autorização de transferências inteligentes.`;
            secondaryMsg = '<span>A autorização de transferências inteligentes será concluída após a aprovação de todos os usuários masters.</span>';
        }

        this.showWarning(primaryMsg, secondaryMsg,
            {
                caption: captionButton,
                action: () => {
                    this._alertDialog.busy = true;

                    this.alertVisible = false;
                    this.securityValidationDialogVisible = true;
                }
            },
            {
                caption: 'Voltar',
                action: () => { this.alertVisible = false; }
            },
            false);
    }

    private goToTop(): void {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    //#region alert

    private showWarning(primaryMsg: string, secondaryMsg: string, primaryAction: { caption: string, action: () => void }, secondaryAction?: { caption: string, action: () => void }, showIcon?: boolean) {
        this.alertPrimaryMsg = primaryMsg;
        this.alertSecondaryMsg = secondaryMsg;
        this.alertPrimaryAction = primaryAction;
        this.alertSecondaryAction = secondaryAction;
        this.alertShowIcon = showIcon != null ? showIcon : true;
        this.alertVisible = true;
    }

    public confirmAlert() {
        this.alertPrimaryAction.action();
    }

    public cancelAlert() {
        this.alertSecondaryAction.action();
    }

    //#endregion

    //#region error

    private showError(primaryMsg: string, secondaryMsg: string, action: { caption: string, action: () => void }, icon: string = 'frown') {
        this.errorPrimaryMsg = primaryMsg;
        this.errorSecondaryMsg = secondaryMsg;
        this.errorAction = action;
        this.errorVisible = true;
        this.errorIcon = icon;
    }

    public confirmError() {
        this.errorAction.action();
    }

    //#endregion

    //#region Security Validation

    public responseSecurityValidation(response) {
        console.log("Response final", response);
        if (response.status === 'success' && response.code !== 500) {
            if (response.data.errors && response.data.errors.length > 0) {
                this.removeSessionStorageResponse();
                response.data.errors.find((r) => {
                    if (r.code === 'ESTADO_CONSENTIMENTO_INVALIDO' || r.code === 'CONSENTIMENTO_EXPIRADO') {
                        let msg = '<span>O tempo para realizar a autorização de transferências inteligentes expirou. Sua transação não foi realizada. <br><br>Acesse a iniciadora de transferências inteligentes para solicitar uma nova autorização.</span>';
                        this.showError(
                            'Tempo limite para transação expirado',
                            msg,
                            { caption: 'Sair', action: () => this.doCancelTransaction(this._errorDialog) },
                            'alert');
                    } else if (r.code === 'SALDO_INSUFICIENTE') {
                        sessionStorage.setItem('response-saldo', 'SALDO_INSUFICIENTE');
                        this.transaction.consentimento.client_uri = response.data.url;
                        this.status = OpenBankingTransactionStatusEnum.Confirmed;
                        this.redirect(false);
                    } else if (r.code === 'VALIDACAO_LIMITE') {
                        sessionStorage.setItem('response-limite', 'VALIDACAO_LIMITE');
                        sessionStorage.setItem('response-limite-data', JSON.stringify(r));

                        this.transaction.consentimento.client_uri = response.data.url;
                        this.status = OpenBankingTransactionStatusEnum.Confirmed;
                        this.redirect(false);
                    } else if (r.code === 'CONTAS_ORIGEM_DESTINO_IGUAIS') {

                        sessionStorage.setItem('origem-destino-iguais', 'CONTAS_ORIGEM_DESTINO_IGUAIS');

                        this.transaction.consentimento.client_uri = response.data.url;
                        this.status = OpenBankingTransactionStatusEnum.Confirmed;
                        this.redirect(false);
                    } else if (r.code === 'INTERACAO_NAO_ENCONTRADA') {
                        this.alertVisible = false;
                        this.cancelationDialogVisible = false;
                        this.showError(
                            r.title,
                            'Ocorreu um erro inesperado tente novamente mais tarde.',
                            { caption: 'Sair', action: () => this.redirectToDashboard() },
                            'alert');
                    }
                });
            } else {
                this.transaction.consentimento.client_uri = response.data.url;
                this.status = OpenBankingTransactionStatusEnum.Confirmed;
                this.redirect(true);
            }
        } else if (response.code === 500) {
            sessionStorage.setItem('response-error', 'ERROR_500');

            this.transaction.consentimento.client_uri = response.data.url;
            this.status = OpenBankingTransactionStatusEnum.Confirmed;
            this.redirect(false);
        } else {
            if (response.data.errors && response.data.errors[0].detail && response.data.errors[0].code !== 'VALIDACAO_LIMITE') {
                this.showError(
                    `${response.data.errors[0].title}`,
                    `<span>${response.data.errors[0].detail}</span>`,
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else if (response.data.errors && response.data.errors[0].code === 'VALIDACAO_LIMITE') {
                this.showError(
                    'Limite',
                    `<span>${response.data.errors[0].detail}</span>`,
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else {
                this._toast.callModalMessage(null, 'Ocorreu um erro ao realizar a operação.');
            }
        }
    }

    public closeSecurityValidationDialog($event) {
        this.securityValidationDialogVisible = false;
        this._alertDialog.busy = false;
    }

    //#endregion

    ngOnInit(): void {
        this.session = this._rolesService.getOpenFinanceSession();

        if (this.session) {
            this.transaction = JSON.parse(this.session.data);

            if (this.transaction.documento_divergente || typeof this.transaction.consentimento === 'undefined') {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showError(
                    'Não foi possível prosseguir com a sua transação.',
                    '<span>Você não possui vínculo com a conta escolhida para realizar esta operação.</span>',
                    { caption: 'Sair', action: () => this.logout(false) });
            }

            if (this.transaction.erroescalonamento) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showError(this.isScheduling ? 'Não foi possível prosseguir com a solicitação do agendamento.' : 'Não foi possível prosseguir com a solicitação do pagamento.', `<span>${this.transaction.erroescalonamento}</span>`, { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else if (this.transaction.erroescalonamento) { // <<<< MUDAR O VALOR PARA CONTA ATIVA - FALTA VOLTAR NO OBJETO
                this.currentPresentation = this.NEWTRANSACTION;
                this.showError(
                    this.isScheduling ? 'Não foi possível prosseguir com a solicitação do agendamento.' : 'Não foi possível prosseguir com a solicitação do pagamento.',
                    this.isScheduling ? '<span>Para prosseguir, é necessário que a conta escolhida para o agendamento esteja ativa.</span>' : '<span>Para prosseguir, é necessário que a conta escolhida para o pagamento esteja ativa.</span>',
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else if (this.transaction.contanaotemvinculocomusuario) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showError(
                    this.isScheduling ? 'Não foi possível prosseguir com a solicitação do agendamento.' : 'Não foi possível prosseguir com a solicitação do pagamento.',
                    this.isScheduling ? '<span>Você não possui vínculo com a conta escolhida para realizar o agendamento.</span>' : '<span>Você não possui vínculo com a conta escolhida para realizar o pagamento.</span>',
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else if (this.transaction.semPermissaoPix) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showWarning('Você não está autorizado a fazer essa operação',
                    '<span>Para ter acesso a essa funcionalidade, solicite a liberação ao(s) usuário(s) master(s) da sua empresa.</span>',
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._alertDialog) });
            } else if (this.transaction.contasemtermoaceitepix) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showError(
                    this.isScheduling ? 'Não foi possível prosseguir com a solicitação do agendamento.' : 'Não foi possível prosseguir com a solicitação do pagamento.',
                    this.isScheduling ? '<span>Para prosseguir, é necessário que a conta escolhida para o agendamento tenha aderido ao Pix.</span>' : '<span>Para prosseguir, é necessário que a conta escolhida para o pagamento tenha aderido ao Pix.</span>',
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._errorDialog) });
            } else if (this.transaction.masterfaltaaceitartermo) {
                this.currentPresentation = this.NEWTRANSACTION;
                this.showWarning('Aceite ao Termo de Uso pendente',
                    '<span>Para realizar uma transação, o(s) usuário(s) master(s) da sua empresa precisa(m) aceitar o Termo de Uso. Foi enviada uma notificação para o(s) usuário(s) master(s). Quando o termo de uso for aceito pelo(s) usuário(s) master(s), você receberá um aviso e poderá fazer a transação normalmente.</span>',
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._alertDialog) });
            } else if (!this.transaction.onboarding) {
                this.currentPresentation = this.ONBOARDING;
            } else if (!this.transaction.termodeuso) {
                this.currentPresentation = this.TERMSOFUSE;
            } else {

                let _checkRoles = this._rolesService.get();
                this.checkRoles(_checkRoles);

                if (!this.validationRole) {
                    this.currentPresentation = this.NEWTRANSACTION;
                    this.showWarning('Tempo limite para a transação',
                        '<span>Esta operação tem um limite de até <strong>5 minutos</strong> para ser confirmada. Caso contrário, a operação será cancelada</span>',
                        {
                            caption: 'Continuar com o acesso', action: () => {
                                this.alertVisible = false;
                            }
                        },
                        { caption: 'Cancelar operação', action: () => this.showCancelationDialog() });
                }
            }

        } else {
            this.redirectToDashboard();
        }
    }

    ngOnDestroy(): void {
        if (this._sub) {
            this._sub.unsubscribe();
        }

        if (this._subCancel) {
            this._subCancel.unsubscribe();
        }
    }

    isMoreOneYears(date) {
        const isMoreOneYearsDate = moment(date).diff(moment(), 'year');
        if (isMoreOneYearsDate > 0) {
            this.showError(
                'Data inválida',
                '<span>O limite de agendamento é de <b>365 dias</b> a partir<br> da data de solicitação de pagamento. Você será redirecionado para a iniciadora onde poderá selecionar uma data válida</span>',
                { caption: 'Ir para a inicidadora', action: () => this.doCancelTransaction(this._alertDialog) },
                'alert');
        }
    }

    checkRoles(roles: any) {
        for (let r of roles) {
            if (r === 'PROBLEMA_CREDITO') {
                this.validationRole = true;
                this.currentPresentation = this.NEWTRANSACTION;
                this.showWarning(
                    'Você não está autorizado a fazer essa operação',
                    '<span> Para ter acesso a essa funcionalidade entre em contato com seu gerente de relacionamento.</span>',
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._alertDialog) });
                break;
            }

            if (r === 'SAIDA_RISCO') {
                this.validationRole = true;
                this.currentPresentation = this.NEWTRANSACTION;
                this.showWarning(
                    'Você não está autorizado a fazer essa operação',
                    '<span> Para ter acesso a essa funcionalidade entre em contato com seu gerente de relacionamento.</span>',
                    { caption: 'Cancelar operação', action: () => this.doCancelTransaction(this._alertDialog) });
                break;
            }
        }
    }

    removeSessionStorageResponse() {
        sessionStorage.getItem('response-limite') ? sessionStorage.removeItem('response-limite') : '';
        sessionStorage.getItem('response-saldo') ? sessionStorage.removeItem('response-saldo') : '';
    }

    openFinanceAction() {
        moment.locale('pt-br');
        const sessionData = JSON.parse(this.session.data);

        this.apiCallBack = 119;  
        this.params = {
            totalAmount: sessionData.consentimento.recurringConfiguration.sweeping.totalAllowedAmount,
            data_transferencia: moment().toISOString(),
            descricao: 'teste',
            conta_origem: {
                agencia_conta: sessionData.consentimento.debtorAccount.issuer,
                num_conta: sessionData.consentimento.debtorAccount.number,
            },
            recurringInteractionId: sessionData.interactionId,
            recurringConsentId: sessionData.consentimento.recurringConsentId,
        }

        this.showModalValidation = true;
        console.log("Entrou aqui ", this.params, this.apiCallBack)
    }

    public securityRequest = () => {
        return this.params;
    }

    public closeModal = () => (this.showModalValidation = false);

    public securityResponse = value => {
        if (value.status === 'success') {
            window.open(value.data.url, '_blank');
        }
    }
}

