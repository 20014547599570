import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyEntity, UserEntity, EnderecoEntity, ContatoEntity } from '@src/data/repository/impersonate-repository/impersonate.entity';
import { ImpersonateRepository } from '@src/data/repository/impersonate-repository/impersonate.repository';


@Component({
  selector: 'fibra-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
})

export class ImpersonateComponent implements OnInit {
  public impersonateFormGroup: FormGroup;

  @ViewChild('companyInput', {static: false})
  InputVar: ElementRef;

  startingImpersonate: boolean = false;
  loadingCompany: boolean = false;
  novaluecompany: boolean = false;
  hasCompany: boolean = false;
  company: CompanyEntity = null;
  address: string = "";
  phone: string = "";
  isCPF: boolean = false;
  isID: boolean = false;
  noselecteduser: boolean = false;

  loadingUsers: boolean = false;
  hasUser: boolean = false;
  users: UserEntity[] = null;

  selectedUser: number = 0;

  isClearCompany: boolean = true;
  isClearUser: boolean = true;

  constructor(
    private router: Router,
    private impersonateService: ImpersonateRepository
  ) { }
  

  ngOnInit() {
    if(sessionStorage.getItem('accessToken') == null){
      this.router.navigate(['/login']);
    }
    console.log("IMPERSONATE");
    sessionStorage.removeItem('fibra-session');
    sessionStorage.removeItem('simulacao');
    sessionStorage.removeItem('defaultCompany');
    sessionStorage.removeItem('showAskCompanyDefault');
    // localStorage.setItem('loggedUser', 'false');
  }


  searchCompany(company: string) {
    this.impersonateService.broadcastImpersonate(false);
    this.noselecteduser = false;
    this.loadingCompany = true;
    this.company = null;
    this.address = "";
    this.phone = "";
    this.users = null;
    this.hasUser = false;
    this.isClearCompany = false;
    this.isClearUser = true;
    this.isCPF = false;
    this.isID = false;

    let company_limpo = company.replace(/[^0-9]+/g,'');
  

    if(company_limpo.length == 11){
      this.isCPF = true;
    }

    if(company_limpo.length <= 10){
      this.isID = true;
    }
    
    this.impersonateService.searchCompany(company_limpo).subscribe((data) => {
      this.company = data.data;
      console.log("empresa:", this.company);
      this.loadingCompany = false;
      this.hasCompany = true;


      if (data.data.enderecos !== null && data.data.enderecos.length > 0) {
        let a = data.data.enderecos.find(e => e.tipo_end === "1");
        if (a !== null) {
          this.address = `${a.nome_end.trim()}, ${a.nome_bairro.trim()} - ${a.nome_cid.trim()}/${a.nome_uf.trim()}`;
        }
      }

      if (data.data.contatos !== null && data.data.contatos.length > 0) {
        let c = data.data.contatos[0];
        this.phone = c.num_fone;
      }

      this.searchUsers(data.data.cod_cli_cad_int);

    }, err => {
      this.loadingCompany = false;
      this.hasCompany = false;
    });
  }


  searchUsers(company: number) {
    this.loadingUsers = true;
    this.users = null;
    this.isClearUser = false;
    this.impersonateService.searchUsers(company).subscribe((data) => {
      this.users = data.data;
      this.hasUser = true;
      this.loadingUsers = false;
      sessionStorage.setItem('simulacao.users', JSON.stringify(this.users));
    }, err => {
      this.loadingUsers = false;
      this.hasUser = false;
    });
  }


  selectUser(codPessoa: number) {
    this.selectedUser = codPessoa;
    this.noselecteduser = true;
  }


  impersonate() {
    this.noselecteduser = false;
    this.startingImpersonate = true;
    let u = this.users.find(e => e.cod_pessoa == this.selectedUser);
    sessionStorage.setItem('simulacao.company', JSON.stringify(this.company));
    sessionStorage.setItem('simulacao.user', JSON.stringify(u));

    this.impersonateService.getSession(this.company.cod_cli_cad_int, u.email, u.nome_pessoa).subscribe(data => {
      let token = data.data.token_sessao;
      sessionStorage.setItem("fibra-session", token);
      sessionStorage.setItem('simulacao', 'true');
      localStorage.setItem('loggedUser', 'true');
      this.impersonateService.broadcastImpersonate(true);
      this.router.navigate(['/dashboard']).then(a => {
        location.reload();
      });
    }, err => {
      console.error("Erro ao recuperar sessao", err);
    });
  }


  clear(){
    this.isClearCompany = true;
    this.isClearUser = true;
    this.selectedUser = null;
    this.company = null;
    this.hasCompany = false;
    this.hasUser = false;
    this.users = null;
    this.InputVar.nativeElement.value = "";
    this.novaluecompany = false;
  }


  validValue(event: any){
    this.novaluecompany = false;
    if(event.target.value.length>=2){
      this.novaluecompany = true;
    }
  }

}
