import { ApprovalLimitsRepository } from '@src/core/repositories/approval-limits/approval-limits.repository';
import { ReturnTypeModelLimits } from '@src/core/domain/approval-limits/return-approval-limits.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';

@Injectable({
  providedIn: 'root',
})
export class GetApprovalLimitsRepository extends ApprovalLimitsRepository {

  constructor(private http: HttpClient) {
    super();
  }

  public getApprovalAllLimites(type: string): Observable<ReturnTypeModelLimits> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<ReturnTypeModelLimits>(`${environment.URLBase}v1/limitetransacional/consultar/${type}`, httpOptions);
  }

  getApprovalStatusLimites(params): Observable<ReturnTypeModelLimits> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<ReturnTypeModelLimits>(`${environment.URLBase}v1/limitetransacional/limites-status/${params.status}/${params.type}`, httpOptions);
  }


}
