import { Injectable } from '@angular/core';
import { AccountTypeRepository } from '@src/core/repositories/account/account-type.repository';
import { AccountTypeModel } from '@src/core/domain/account/accountType.model';
import { Observable } from 'rxjs';
import { AccountTypeRepositoryMapper } from '@src/data/repository/account/accountType-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { flatMap, map } from 'rxjs/operators';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { DisclaimerRepositoryMapper } from './disclaimer-repository.mapper';
import { DisclaimerRepository } from '@src/core/repositories/disclaimer/disclaimer.repository';
import { DisclaimerModel } from '@src/core/domain/disclaimer/disclaimer.model';
import { DisclaimerEntity } from './disclaimer.entity';

@Injectable({
  providedIn: 'root',
})
export class DisclaimerDataRepository extends DisclaimerRepository {
  mapper = new DisclaimerRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getDisclaimer(params: string): Observable<DisclaimerModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<DisclaimerEntity>(
        `${environment.URLBase}v1/transacoes/limites/${params}`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }
}
