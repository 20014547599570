import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  ParcelasEnum } from '@src/core/domain/product/product.model';

@Component({
  selector: 'fibra-modal-confirmation-contract',
  templateUrl: './modal-confirmation-contract.component.html',
  styleUrls: ['./modal-confirmation-contract.component.scss'],
})
export class ModalConfirmationContractComponent implements OnInit {
  @Input() retornoParc: Array<ParcelasEnum> = [];
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', { static: true }) content: HTMLElement;

  message="A Solicitação de Contratação está sujeita a análise de crédito e demais condições do produto. Somente após aprovação interna e assinatura do contrato por seus representantes legais, o valor líquido da operação será creditado em sua conta corrente."
  message2="Confirma a Solicitação da Contratação com base nas informações acima apresentadas?"
  
  constructor(
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.openModal(this.content);
  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal(value: any): void {
    this.closeEvent.emit(value);
    this.modalService.dismissAll();
  }
}
