import { Mapper } from "@src/core/base/mapper";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import {
  DdaCompanyApprovalEntity,
  TermApprovalEntity,
} from "../entity/term/dda-company-approval.entity";

export class DdaCompanyApprovalMapper extends Mapper<
  DdaCompanyApprovalEntity,
  DdaTermCompanyApprovalModel
> {
  mapFrom(param: DdaCompanyApprovalEntity): DdaTermCompanyApprovalModel {
    if(param!=null && param!=undefined && param.data.length!=0){
      return {
        id: param.data[0].id,
        status: param.data[0].status,
        status_id: param.data[0].status_id,
        conta_id: param.data[0].conta_id,
        dt_criacao: param.data[0].dt_criacao,
        empresa_id: param.data[0].empresa_id,
        status_adesao_cip: param.data[0].status_adesao_cip,
        aprovacao_concluida: param.data[0].aprovacao_concluida,
        aprovacoes: param.data[0].aprovacoes,
      };
    }
  }
  mapTo(param: DdaTermCompanyApprovalModel): DdaCompanyApprovalEntity {
    return {
      status: null,
      code: null,
      data: [],
    };
  }
}

export class TermApprovalMapper extends Mapper<
  DdaTermCompanyApprovalModel,
  TermApprovalEntity
> {
  mapFrom(param: DdaTermCompanyApprovalModel): TermApprovalEntity {
    return {
      id: param.id,
      status: param.status,
      status_id: param.status_id,
      conta_id: param.conta_id,
      dt_criacao: param.dt_criacao,
      empresa_id: param.empresa_id,
      status_adesao_cip: param.status_adesao_cip,
      aprovacao_concluida: param.aprovacao_concluida,
      aprovacoes: param.aprovacoes,
    };
  }
  mapTo(param: TermApprovalEntity): DdaTermCompanyApprovalModel {
    return {
      id: param.id,
      status: param.status,
      status_id: param.status_id,
      conta_id: param.conta_id,
      dt_criacao: param.dt_criacao,
      empresa_id: param.empresa_id,
      status_adesao_cip: param.status_adesao_cip,
      aprovacao_concluida: param.aprovacao_concluida,
      aprovacoes: param.aprovacoes,
    };
  }
}
