<div class="filter">
    <div class="filter-input" *ngIf="inputFilter">
        <fibra-form-control-group [formGroup]="filterInput">
            <div class="filter-position">
                <fibra-form-control [style.width.px]="270" label="Nosso número">
                    <div class="control-data">
                        <input id="ourNumber" formControlName="ourNumber" [style.width.%]="100" placeholder="0000000000000 0" (change)="setOurNumber($event.target.value)"
                        />
                    </div>
                </fibra-form-control>
                <fibra-form-control class="your-number" [style.width.px]="270" label="Seu número">
                    <div class="control-data">
                        <input id="yourNumber" formControlName="yourNumber" [style.width.%]="100" placeholder="0000000000000 0" (change)="setYourNumber($event.target.value)"
                        />
                    </div>
                </fibra-form-control>

                <fibra-form-control [style.width]="'44%'" label="Sacado" class="mr-0">
                    <div class="control-data">
                        <input id="draweeName" formControlName="draweeName" [style.width.%]="100" placeholder="Nome do sacado ou CPF/CNPJ" (change)="setDraweeName($event.target.value)"
                        />
                    </div>
                </fibra-form-control>
            </div>
        </fibra-form-control-group>
    </div>

    <div class="filter-default">
        <fibra-form-control-group [formGroup]="filter" *ngIf="default">
            <fibra-form-control label="Carteira" [style.width.px]="215">
                <fibra-dropdown removeShadown="true" formGroupName="wallet" customClass="select-option" [options]="optionWallet" (change)="changeWallet($event)"></fibra-dropdown>
            </fibra-form-control>

            <fibra-form-control label="Conta" [style.width.px]="215">
                <fibra-dropdown removeShadown="true" formGroupName="account" customClass="select-option" placeholder="Todas" [options]="optionsProfile"
                    (change)="changeAccount($event)"></fibra-dropdown>
            </fibra-form-control>
        </fibra-form-control-group>

        <ng-container *ngIf="approval">
            <fibra-form-control-group [formGroup]="filter">
                <fibra-form-control [style.width.px]="333">
                    <div class="dropdown-input">
                        <span>Conta</span>
                        <fibra-dropdown [style.width.px]="333" removeShadown="true" formGroupName="account" customClass="select-option" placeholder="{{ selected }}"
                            [options]="optionsProfile" (change)="changeAccount($event)"></fibra-dropdown>
                    </div>
                </fibra-form-control>
                <fibra-form-control [style.width.px]="250">
                    <div class="dropdown-input">
                        <span>Tipo</span>
                        <fibra-dropdown [style.width.px]="250" removeShadown="true" formGroupName="type" customClass="select-option" [options]= "optionTypeTransfer"
                            (change)="changeType($event)"></fibra-dropdown>
                    </div>
                </fibra-form-control>
            </fibra-form-control-group>
        </ng-container>
        <ng-container *ngIf="receipt">
                <fibra-form-control-group [formGroup]="filter">
                    <fibra-form-control [style.width.px]="333">
                        <div class="dropdown-input">
                            <span>Conta</span>
                            <fibra-dropdown [style.width.px]="333" removeShadown="true" formGroupName="account" customClass="select-option" placeholder="{{ selected }}"
                                [options]="optionsProfile" (change)="changeAccount($event)"></fibra-dropdown>
                        </div>
                    </fibra-form-control>
                    <fibra-form-control *ngIf="!isReceiptPix" [style.width.px]="250">
                        <div class="dropdown-input">
                            <span>Tipo</span>
                            <fibra-dropdown  [style.width.px]="250" removeShadown="true" formGroupName="type" customClass="select-option" [options]= "optionTypeReceipt"
                            (change)="changeType($event)"></fibra-dropdown>
                        </div>
                    </fibra-form-control>
                </fibra-form-control-group>
            </ng-container>
        <div class="dropdown-periodic">
            <div class="group-items">
                <div class="period">
                    <div class="label-period">Período</div>
                    <div class="group-period">
                        <div *ngFor="let item of optionPeriod">
                            <div class="options-period" (click)="changePeriod(item, $event)" [ngClass]="{ activeCalendar: dataRequest === item }">
                                {{ item }} dias
                            </div>
                        </div>
                        <div class="icon">
                                <button (click)="periodModal()" class="btn btn-outline calendar" type="button"><img id="calendar" src="/assets/svg/icon_calendar_gray.svg" alt="icon" ></button>
                                <fibra-modal-period-datepicker #content *ngIf="!isSchedules && showModalPeriodDatepicker"
                                                               (change)="changePeriodDate($event)"
                                                               (closeEvent)="closeEvent($event)"
                                                               [minDate]="minDay"
                                                               [maxDate]="approvalTransaction ? maxDay : today"
                                                               [isSchedules]="isSchedules"
                                                               [abaPosicaoCarteira]="'transaction-approval'"
                                ></fibra-modal-period-datepicker>

                                <fibra-modal-period-datepicker #content *ngIf="isSchedules && showModalPeriodDatepicker"
                                                               (change)="changePeriodDate($event)"
                                                               (closeEvent)="closeEvent($event)"
                                                               [minDate]="today"
                                                               [maxDate]="maxDay"
                                                               [isSchedules]="isSchedules"
                                                               [abaPosicaoCarteira]="'transaction-approval'"
                                ></fibra-modal-period-datepicker>
                       </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <section *ngIf="periodCalendar != '' && periodCalendar != undefined">
        <span class="txt-interval">Período: {{ dataIn }} à {{ dataFinal }}</span>
    </section>
</div>
<ng-content></ng-content>