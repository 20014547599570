import { Injectable } from '@angular/core';
import { WalletTypeRepository } from '@src/core/repositories/wallet/wallet-type.repository';
import { WalletTypeModel, WalletRequest } from '@src/core/domain/wallet/walletType.model';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { WalletTypeEntity } from '@src/data/repository/wallet/walletType.entity';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { RolesService } from '@src/shared/services/roles.service';

@Injectable({
  providedIn: 'root',
})
export class WalletTypeDataRepository extends WalletTypeRepository {

  constructor(private http: HttpClient, private rolesService: RolesService) {
    super();
  }

  getWalletTypes(): Observable<WalletTypeModel[]> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<WalletTypeEntity[]>(
      `${environment.URLBase}v1/titulos/ConsultaHeaderConta`,
      httpOptions
    )
  }

  ConsultaObrigatoriedadeChaveAcessoNfAutbank(): Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<any>(
      `${environment.URLBase}v1/titulos/ConsultaObrigatoriedadeChaveAcessoNfAutbank`,
      httpOptions
    )
  } 
}

@Injectable({
  providedIn: 'root',
})
export class WalletTypeTestRepository extends WalletTypeRepository {

  constructor(private http: HttpClient, private rolesService: RolesService) {
    super();
  }

  getWalletTypes(): Observable<WalletTypeModel[]> {
    return of<WalletTypeModel[]>([]);
  }

  ConsultaObrigatoriedadeChaveAcessoNfAutbank(): Observable<any> {
    return of({});
  } 
}