import { Injectable } from '@angular/core';
import { InstructionsTypeRepository } from '@src/core/repositories/instructions/instructions-type.repository';
import { UseCase } from '@src/core/base/use-case';
import { InstructionsTypeModel, InstructionsRequest } from '@src/core/domain/instructions/instructionsType.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class setInstructionsIndexUsecases implements UseCase<InstructionsRequest, InstructionsTypeModel> {

  constructor(private companyTypeRepository: InstructionsTypeRepository) { }

  execute(params: InstructionsRequest): Observable<InstructionsTypeModel> {
    return this.companyTypeRepository.setInstructionsIndex(params);
  }
}
