import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { AccompanimentModel } from '@src/core/domain/dashboard/dashboard.model';
import { AccompanimentRepository } from '@src/core/repositories/dashboard/accompaniment.repository';

@Injectable({
  providedIn: 'root',
})
export class GetAccompanimentUsecases
  implements UseCase<void, AccompanimentModel> {
  constructor(private accompanimentRepository: AccompanimentRepository) {}

  execute(): Observable<AccompanimentModel> {
    return this.accompanimentRepository.getAccompaniment();
  }
}
