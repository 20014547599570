import { Observable } from 'rxjs';
import { LoansModel, LoansRequest, LoansRequestByContractAndDate, LoansRequestByDate, LoansRequestCompanyCheck, LoansRequestContractInfo, LoansRequestDailyPosition, CETFibraFacil } from '@src/core/domain/loans/loan-model';
import { LoansExportExcelRequest } from '@src/data/repository/loans-repository/loans.entity';

export abstract class LoansRepository {
  abstract getAllLoans(obj: LoansRequest): Observable<LoansModel>;
  abstract getTotalOperations(obj: LoansRequest): Observable<LoansModel>;
  abstract getWarrantyMap(obj: LoansRequest): Observable<LoansModel>;
  abstract getContractByDate(obj: LoansRequestByDate): Observable<LoansModel>;
  abstract getCompanyCheck(obj: LoansRequestCompanyCheck): Observable<LoansModel>;
  abstract getCETFibraFacil(numeroConta: string): Observable<CETFibraFacil>;
  abstract getDailyPositionAVencer(obj: LoansRequestDailyPosition): Observable<LoansModel>;
  abstract getDailyPositionVencendo(obj: LoansRequestDailyPosition): Observable<LoansModel>;
  abstract getDailyPositionVencida(obj: LoansRequestDailyPosition): Observable<LoansModel>;
  abstract getContractInfo(obj:LoansRequestContractInfo):Observable<LoansModel>;
  abstract getParcela(obj:LoansRequestContractInfo):Observable<LoansModel>;
  abstract getParcelaByDate(obj:LoansRequestByContractAndDate):Observable<LoansModel>;
  abstract postExportExcelContracts(obj: LoansExportExcelRequest): Observable<any>;
  abstract postExportExcelDailyPosition(obj: LoansExportExcelRequest): Observable<any>;
  abstract postExportExcelCheckCompany(obj: LoansExportExcelRequest): Observable<any>;
  abstract postExportExcelInfoContract(obj: LoansExportExcelRequest): Observable<any>;
  abstract postExportExcelParcela(obj: LoansExportExcelRequest): Observable<any>;
}


