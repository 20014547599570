<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>

<div class="content-page">
  <fibra-breadcrumb
    bottom="-120px"
    infoAccount="Transações"
    roule="Transferências"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb>
  <div class="body-content">
    <div id="transfer-confirm">
      <div id="transfer-confirm-content">
        <h1>Dados do beneficiário</h1>

        <p class="title">Nome do beneficiário:</p>
        <p data-hj-suppress>
          {{
            beneficiaryData?.beneficiaryData.beneficiary.nome_beneficiario
              | titlecase
          }}
        </p>

        <p class="title">CNPJ / CPF</p>
        <p>
          {{
            removeMask(beneficiaryData?.beneficiaryData.beneficiary.num_cpf_cnpj)
              | cpfcnpj
          }}
        </p>

        <p *ngIf="beneficiaryData?.beneficiaryData.quote.num_agencia === '00001'" class="title">Conta</p>
        <p *ngIf="beneficiaryData?.beneficiaryData.quote.num_agencia === '00001'">
          {{ DescTypeAccount(beneficiaryData?.beneficiaryData.quote.tipo_conta) }}
        </p>

        <p class="title">Banco</p>
        <p>
          {{ beneficiaryData?.beneficiaryData.quote.banco.cod_banco }} -
          {{ beneficiaryData?.beneficiaryData.quote.banco.nome_banco }}
        </p>

        <p class="title">Dados da conta</p>
        <p>
          AG: {{ beneficiaryData?.beneficiaryData.quote.num_agencia }} | CC:
          {{ beneficiaryData?.beneficiaryData.quote.num_conta | account }}
        </p>

        <h1>Valor e data</h1>

        <p class="title">Valor da transferência</p>
        <p>
          {{
            beneficiaryData?.transferInformation.get('ammount').value
              | currency: 'BRL'
          }}
        </p>

        <p class="title">{{ textDate }}</p>
        <p>
          {{ beneficiaryData?.transferInformation.get('dateTrasfer').value }}
        </p>

        <h1>Tipo de transferência</h1>

        <p style="margin-top: 16px">
          {{ beneficiaryData?.transferInformation.get('tipo_operacao').value }}
        </p>

        <h1>Finalidade e descrição</h1>

        <p class="title">Finalidade</p>
        <p>
          {{ beneficiaryData?.transferInformation.get('purpose')?.value?.name || ''}}
        </p>

        <div class="desc">
          <p class="title">Descrição</p>
          <p>
            {{ beneficiaryData?.transferInformation.get('description')?.value || '' }}
          </p>
        </div>

        <h1>Conta para débito</h1>

        <p class="title">Conta</p>
        <p>{{ bank?.exibicao | titlecase }}</p>

        <p class="title">Dados da conta</p>
        <p>AG:{{ bank?.cod_agencia }} | CC: {{ bank?.num_conta | account }}</p>
      </div>

      <div class="transfer-confirm-footer">
        <button
          type="button"
          class="leaked-button preview"
          (click)="toast.callModalMessage('retorno')"
        >
          Voltar
        </button>

        <button type="button" class="secondary-button next" (click)="goNext()">
          {{ textBtn }}
        </button>
      </div>
    </div>
  </div>
  <fibra-toast-error-message [color]="'#ED6C6C'" (optionYes)="navigateBack()"></fibra-toast-error-message>
  <fibra-modal-validation
    *ngIf="showModalValidation"
    [apiCallback]="3"
    (closeEvent)="closeModal()"
    [requestData]="requestDataApi"
    [apiPayload]="securityRequest()"
    [handleInternalError]="false"
    (responseValidation)="securityResponse($event)">
  </fibra-modal-validation>
</div>

<ng-content></ng-content>
