import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import * as Util from '@src/shared/util-common';
import { LoansRepository } from '@src/core/repositories/loans/loans-repository';
import { LoansExportExcelRequest } from '@src/data/repository/loans-repository/loans.entity';
import { AccountTypeDataRepository } from '@src/data/repository/account/accountType.repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss'],
})
export class LoansComponent {  

  public ids = ['contratos', 'posicaoDiaria', 'chequeEmpresa', 'parcelas', 'informacaoContrato']
  public contratos = true;
  public posicaoDiaria = false;
  public chequeEmpresa = false;
  public informacaoContrato = false;
  public parcelas = false;
  public showComponents = true;
  public numContrato: string;
  public lastFragment: string = 'contracts';
  public hasCheckCompanyAccount = false;
  public generatePDF = (value?) => Util.generatePDF(value);

  public report =false;
  public contractsArray = [];
  public contractsCheckArray = []; 
  public contractInfoArray = [];
  public parcelasArray = [];
  public dailyPositionArray = []; 
  public typToastColor;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  constructor(
    private loansRepository: LoansRepository,
    private accountType: AccountTypeDataRepository,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe(params => {
      if (params.chequeempresa) {
        this.hasCheckCompanyAccount = true;
        this.contratos = false;
        this.chequeEmpresa = true;
      } else {
        this.accountType.getAllaccountTypes().subscribe((data) =>{

          data.data.forEach(element => {
            if (element.desc_conta == "CHEQUE EMPRESA")
              this.hasCheckCompanyAccount = true
          });    
        });
      }
    });    
  }

  public exportScreen(value) {
    debugger
    if (value === 2) {

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }
      const locale = 'pt-br'
      let dateReport = new Date().toLocaleDateString(locale, options)
      
      let dt_ref = sessionStorage.getItem('dt_ref')
      let numContract = sessionStorage.getItem('excelFilterCodContract')
      let filter = sessionStorage.getItem('excelFilterParam')
      const param: LoansExportExcelRequest = {
        dt_ref: dt_ref === "null" ? null : dt_ref,
        cod_contrato: numContract,
        param: filter
      }

      let errorMessage='Erro ao tentar fazer download do arquivo, tente mais tarde.'
      switch (this.lastFragment) {
        case 'contracts':
          this.loansRepository.postExportExcelContracts(param).subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `contratos-${dateReport}`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'daily-position':
          this.loansRepository.postExportExcelDailyPosition(param).subscribe((res) => {
            Util.handleFile(res, `posicao-diaria-${dateReport}`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;
        case 'contract-info':
          this.loansRepository.postExportExcelInfoContract(param).subscribe((res) => {
            Util.handleFile(res, `informacao-contrato-${dateReport}`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'installments':
          this.loansRepository.postExportExcelParcela(param).subscribe((res) => {
            Util.handleFile(res, `parcelas-${dateReport}`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'check-company':
          this.loansRepository.postExportExcelCheckCompany(param).subscribe((res) => {
            Util.handleFile(res, `cheque-empresa-${dateReport}`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        default:
          this.loansRepository.postExportExcelContracts(param).subscribe((res) => {
            Util.handleFile(res, res.NomeArquivo);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;
      }

    }
  }

  private successDownload = (value) => {
    if (value.status === 'success') {
      // const blob = new Blob([value.data], { type: 'application/octet-stream' });
      Util.handleFile(value.data, 'arquivo');
    }
  };

  callNavbar(event) {

    if (event == false) {
      this.showComponents = true
      this.changeNavbarClass('contratos')
    }
    else {
      this.showComponents = false
      if (event.page == "informacaoContrato") {
        //this.numContract = event.numContrato
        this.numContrato = event.numContrato
        this.changeNavbarClass('informacaoContrato')
      }
      else {
        this.numContrato = event.numContrato
        this.changeNavbarClass('parcelas')
      }

    }


  }

  public setReportData(event) {

    Util.consoleDev('setReportData:', event)
    if (event==1) {
      switch (this.lastFragment) {
        case 'contracts':
          this.contractsArray = JSON.parse(sessionStorage.getItem('contracts_list_pdf'))
          Util.consoleDev('contractsArray:',this.contractsArray)
          this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          this.report = true;
          break;
        case 'check-company':
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'contract-info':
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'installments':
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
        case 'daily-position':
          this.report = true;
          this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          break;
      }
    }
    else
    {
      this.exportScreen(2)
    }

    
  }

  public changeReportView(event) {
    this.report = !this.report;
    this.resetArrays();
  }

  public resetArrays() {
    this.contractsArray = null;
    this.dailyPositionArray = null;
    this.contractInfoArray = null;
    this.contractsCheckArray = null;
  }

  public changeNavbarClass(id: string) {
    if (id === 'contratos') {
      this.showComponents = true
      $('#contratos-item').removeClass('item-unselected');
      $('#contratos-item').addClass('item-selected');
      $('#contratos-font').removeClass('item-font-unselected');
      $('#contratos-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'contratos') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.contratos = true;
      this.posicaoDiaria = false;
      this.chequeEmpresa = false;
      this.informacaoContrato = false;
      this.parcelas = false;
      this.lastFragment = 'contracts';
    }
    if (id === 'posicaoDiaria') {
      $('#posicaoDiaria-item').removeClass('item-unselected');
      $('#posicaoDiaria-item').addClass('item-selected');
      $('#posicaoDiaria-font').removeClass('item-font-unselected');
      $('#posicaoDiaria-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'posicaoDiaria') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.contratos = false;
      this.posicaoDiaria = true;
      this.chequeEmpresa = false;
      this.informacaoContrato = false;
      this.parcelas = false;
      this.lastFragment = 'daily-position';
    }
    if (id === 'chequeEmpresa') {
      $('#chequeEmpresa-item').removeClass('item-unselected');
      $('#chequeEmpresa-item').addClass('item-selected');
      $('#chequeEmpresa-font').removeClass('item-font-unselected');
      $('#chequeEmpresa-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'chequeEmpresa') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.contratos = false;
      this.posicaoDiaria = false;
      this.chequeEmpresa = true;
      this.informacaoContrato = false;
      this.parcelas = false;
      this.lastFragment = 'check-company';
    }

    if (id === 'informacaoContrato') {
      this.showComponents = false
      $('#informacaoContrato-item').removeClass('item-unselected');
      $('#informacaoContrato-item').addClass('item-selected');
      $('#informacaoContrato-font').removeClass('item-font-unselected');
      $('#informacaoContrato-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'informacaoContrato') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.contratos = false;
      this.posicaoDiaria = false;
      this.chequeEmpresa = false;
      this.informacaoContrato = true;
      this.parcelas = false;
      this.lastFragment = 'contract-info';
    }

    if (id === 'parcelas') {
      $('#parcelas-item').removeClass('item-unselected');
      $('#parcelas-item').addClass('item-selected');
      $('#parcelas-font').removeClass('item-font-unselected');
      $('#parcelas-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'parcelas') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.contratos = false;
      this.posicaoDiaria = false;
      this.chequeEmpresa = false;
      this.informacaoContrato = false;
      this.parcelas = true;
      this.lastFragment = 'installments';
    }
  }
}
