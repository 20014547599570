import { PostAcceptTermUsecase } from '@src/core/usecases/accept-term/accept-term.usecase';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StepperModel } from '@src/core/domain/business-models';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fibra-term-contract',
  templateUrl: './term-contract.component.html',
  styleUrls: ['./term-contract.component.scss']
})
export class TermContractComponent implements OnInit {

  @ViewChild('content', {static: false}) content: ElementRef;
  @Input() public accountData;
  @Output() public acceptPixTerms = new EventEmitter();
  @Input() idTermo: string;

  public checked = false;
  public showModalValidation = false;
  public showModalRegistratoError = false;
  public varSubscriptTerm: Subscription;
 
  constructor(
    private modalService: NgbModal,
    private postAcceptTermUsecase: PostAcceptTermUsecase,
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.openModal(this.content);
    this.controlerHeightModal();
    
  }

  public goBack() {
  }

  public openModal(content): void {
    this.showModalValidation = false;
    this.modalService.open(content, {
      centered: true,
      size: 'xl',
      windowClass: 'modal-xxl',
      backdrop: 'static'
    });
  }

  public closeModalTerm(): void {
    this.postTerm();
    this.modalService.dismissAll();
  }

  public controlerHeightModal() {
    const modalContent = Array.from(document.getElementsByClassName("modal-content") as HTMLCollectionOf<HTMLElement>);
    modalContent.forEach(res => {
      res.style.setProperty('height', '600px');
    })
  }
  
  unsubscribeVariable(variable) {
    if(variable) {
      variable.unsubscribe();
    }
    return;
  }

  postTerm() {
    this.unsubscribeVariable(this.varSubscriptTerm);
    let params = {
        idTermo: this.idTermo
    };

    this.varSubscriptTerm = this.postAcceptTermUsecase.execute(params)
      .subscribe(
          this.sucessTerm,
          this.errorTerm
      );
  }

  sucessTerm = (value) => {
    console.log('success');
  }

  errorTerm = (err) => {
    console.log(err);
  }

}
