<div class="arrow" (click)="navigateBack()">
  <img src="/assets/svg/seta-left.svg" alt="" />
  <label>Voltar</label>
</div>

<div class="title mb-3">
  <label>Informações do título</label>
</div>

<fibra-tabs-list [options]="optionsTab" (itemTab)="selectTab($event.idOption)"></fibra-tabs-list>
<div class="list-value">
  <!-- ----------1.0  Dados sacado ------------>
  <div class="dados_sacado container-categoria" *ngIf="info === 'sacado' || info === 'tudo'">
    <div class="titulo">
      <div>Dados do pagador</div>
    </div>
    <div class="dados-item">
      <section class="nome-dados">
        <label>Nome do pagador</label>
        <span class="data-hj-suppress">{{titleInfoList?.nomeSacado || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Tipo de pessoa</label>
        <span>{{titleInfoList?.tipoPessoa === 'J' ? 'Jurídica' : 'Física'}}</span>
      </section>

      <section class="nome-dados">
        <label>CNPJ / CPF</label>
        <span>{{cpfCnpjMask(titleInfoList.cpfCnpjSacado) || ''}}</span>
      </section>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Endereço</label>
        <span>{{titleInfoList.endereco || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Cidade</label>
        <span>{{titleInfoList.cidade || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>UF</label>
        <span>{{titleInfoList.estado || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>CEP</label>
        <span>{{cepMask(titleInfoList.cep) || ''}}</span>
      </section>
    </div>
  </div>

  <!-- ---------- 2.0 Dados do titulo ------------>

  <div class="dados_titulo container-categoria" *ngIf="info === 'titulo' || info === 'tudo'">
    <div class="titulo">
      <div>Dados do título</div>
    </div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Seu Número</label>
        <span>{{titleInfoList.seuNumero || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Vencimento</label>
        <span>{{titleInfoList.dataVencimento | date: 'dd/MM/yyyy' || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Espécie</label>
        <span>{{titleInfoList.especieDocumento || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Banco cobrança</label>
        <span>{{titleInfoList.codBancoCorrespondente || ''}}</span>
      </section>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Nosso Número</label>
        <span>{{titleInfoList.nossoNumero || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Moeda</label>
        <span>{{titleInfoList.moeda || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Emissão</label>
        <span>{{titleInfoList.dataEmissao | date: 'dd/MM/yyyy' || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Aceite</label>
        <span>{{titleInfoList.aceite || ''}}</span>
      </section>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Tipo de cobrança</label>
        <span>{{titleInfoList.descricaoProduto || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Data liq/Baixa</label>
        <span>{{validateDate(titleInfoList.dataLiquidacaoBaixa)}}</span>
      </section>

      <section class="nome-dados">
        <label>Situação</label>
        <span>{{titleInfoList.descricaoSituacao || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Valor do titulo</label>
        <span> {{isCurrencyBRL}} {{ titleInfoList.valor.toLocaleString('pt-br', {minimumFractionDigits: 2}) ||
          ''}}</span>
      </section>

      <!-- <section class="nome-dados" *ngIf="titleInfoList.moeda === 2">
      <label>Valor do titulo</label>
      <span> {{isCurrencyUSD}} {{ titleInfoList.valor.toLocaleString('pt-br', {minimumFractionDigits: 2}) || ''}}</span>
    </section> -->

    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Valor creditado</label>
        <span>{{titleInfoList.valorCreditado ? isCurrencyBRL: ''}} {{titleInfoList.valorCreditado ?
          titleInfoList.valorCreditado.toLocaleString('pt-br', {minimumFractionDigits: 2}) : ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Creditado em juros dia</label>
        <span>{{titleInfoList.valorJurosPorDia || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Valor de abatimento do título</label>
        <span> {{titleInfoList.valorAbatimento ? isCurrencyBRL: ''}} {{titleInfoList.valorAbatimento ?
          titleInfoList.valorAbatimento.toLocaleString('pt-br', {minimumFractionDigits: 2}) : ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Desconto</label>
        <span> {{titleInfoList.valorDesconto ? isCurrencyBRL : ''}} {{titleInfoList.valorDesconto ?
          titleInfoList.valorDesconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) : ''}}</span>
      </section>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Juros</label>
        <span> {{titleInfoList.valorJuros ? isCurrencyBRL: ''}} {{titleInfoList.valorJuros ?
          titleInfoList.valorJuros.toLocaleString('pt-br', {minimumFractionDigits: 2}) : ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Multa</label>
        <span></span>
        <span> {{titleInfoList.taxaMulta ? titleInfoList.taxaMulta.toLocaleString('pt-br', {minimumFractionDigits: 2}) + "%" : ''}} 
               {{titleInfoList.valorMulta ? isCurrencyBRL : ''}} {{titleInfoList.valorMulta ?
              titleInfoList.valorMulta.toLocaleString('pt-br', {minimumFractionDigits: 2}) : ''}}
        </span>
      </section>

      <section class="nome-dados">
        <label>Agendamento de cartório para protesto</label>
        <span>{{titleInfoList.qtdeDiasAgendamentoCartorioParaProtesto || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Tarifas</label>
        <span>{{titleInfoList.tarifa || ''}}</span>
      </section>
    </div>
  </div>

  <!-- ---------- 3.0 sacador avalista ------------>
  <div class="sacador_avalista container-categoria" *ngIf="info === 'sacador' || info === 'tudo'">
    <div class="titulo">
      <div>Sacador avalista</div>
    </div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>CNPJ/CPF</label>
        <span>{{cpfCnpjMaskAvalista(titleInfoList.sacadorAvalistaCpfCnpj) || ''}}</span>
      </section>

      <section class="nome-dados">
        <label>Endereço</label>
        <span>{{titleInfoList.sacadorAvalistaEndereco || ''}}</span>

      </section>

      <section class="nome-dados">
        <label>CEP</label>
        <span>{{cepMask(titleInfoList.sacadorAvalistaCep) || ''}}</span>

      </section>

      <section class="nome-dados">
        <label>Cidade</label>
        <span>{{titleInfoList.sacadorAvalistaCidade || ''}}</span>

      </section>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>UF</label>
        <span>{{titleInfoList.sacadorAvalistaEstado || ''}}</span>

      </section>
    </div>
  </div>

  <!-- ---------- 4.0 Histórico do titulo ------------>
  <div class="instrucoes container-categoria" *ngIf="info === 'historico' || info === 'tudo'">
    <div class="titulo">
      <div>Histórico do título</div>
    </div>

    <div class="titulo">
      <div>Instruções</div>
    </div>

    <div class="divisor"></div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Instruções de cobrança</label>
      </section>

      <section class="nome-dados">
        <label>Inclusão</label>
      </section>

      <section class="nome-dados">
        <label>Situação</label>
      </section>
    </div>
    <div class="dados-item" *ngFor="let item of instrucaoLista">
      <section class="nome-dados">
        <span>{{item.instrucao_instrucao || ''}}</span>
      </section>

      <section class="nome-dados">
        <span>{{item.instrucao_data | date: 'dd/MM/yyyy' || ''}}</span>
      </section>

      <section class="nome-dados">
        <span>{{item.instrucao_situacao || ''}}</span>
      </section>
    </div>
  </div>

  <!-- ---------- 5.0 sacador avalista ------------>
  <div class="ocorrencia_ container-categoria" *ngIf="info === 'historico' || info === 'tudo'">
    <div class="titulo">
      <div>Ocorrências</div>
    </div>

    <div class="dados-item">
      <section class="nome-dados">
        <label>Modalidade</label>
      </section>

      <section class="nome-dados">
        <label>Típo</label>
      </section>

      <section class="nome-dados">
        <label>Data ocorrência</label>
      </section>

      <section class="nome-dados">
        <label>Data vencimento</label>
      </section>
    </div>

    <div *ngFor="let item of ocorrenciaLista">
      <div class="dados-item">
        <section class="nome-dados">
          <span>{{item.ocorrencia_modalidade || ''}}</span>
        </section>

        <section class="nome-dados">
          <span>{{item.ocorrencia_tipo || ''}}</span>
        </section>

        <section class="nome-dados">
          <span>{{item.ocorrencia_data | date: 'dd/MM/yyyy' || ''}}</span>
        </section>

        <section class="nome-dados">
          <span>{{item.ocorrencia_dataVencimento | date: 'dd/MM/yyyy' || ''}}</span>
        </section>
      </div>

      <div class="divisor"></div>

    </div>

    <div class="d-flex justify-content-center position" *ngIf="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>