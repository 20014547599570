import { DisclaimerEnum } from '@src/core/domain/disclaimer/disclaimer.model';
import { DisclaimerModel } from '@src/core/domain/disclaimer/disclaimer.model';
import { GetDisclaimerUsecase } from '@src/core/usecases/disclaimer/disclaimer.usecase';
import { GetBusinessDayUsecase } from '@src/core/usecases/transfer/beneficiaries/get-businessDay-usecases';
import {
  AddAccountRequest,
  BeneficiariesModel,
  RequestAccountFibra,
} from '@src/core/domain/transfer/transfer.model';
import { AddFavoritesUsecase } from '@src/core/usecases/transfer/beneficiaries/add-favorites-usecases';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import {
  AccountDataShare,
  BankDataShare,
  DebitAccountDataShare,
  SelectBeneficiaryDataShare,
  TransferReceiptDataShare,
} from '@src/data/repository/data-share-repository';
import * as Util from '@src/shared/util-common';
import { GetAccountFibraUsecase } from '@src/core/usecases/transfer/beneficiaries/get-account-fibra-usecases';
import { AddAccountUsecase } from '@src/core/usecases/transfer/beneficiaries/add-account-usecases';
import { CpfCnpjValidator } from '@src/shared';
import { RolesService } from '@src/shared/services/roles.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { LimitsDisclaimerUsecase } from '@src/core/usecases/limits-disclaimer/limits-disclaimer.usecase';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastErrorMessageComponent } from '../toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-transaction-information',
  templateUrl: './transaction-information.component.html',
  styleUrls: ['./transaction-information.component.scss'],
})
export class TransactionInformationComponent implements OnInit, OnDestroy {

  @Input() typeTransfer;
  @Output() emitValue: EventEmitter<any> = new EventEmitter();
  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();
  @Output() errorCpfCnpj: EventEmitter<any> = new EventEmitter();
  @Output() errorValue: EventEmitter<any> = new EventEmitter();
  @Output() errorAccountFibra: EventEmitter<any> = new EventEmitter();

  public chequeEmpresa: boolean = false;
  public saldoDisponivel: boolean = true;
  public valorChequeEmpresa;
  errDateType: string;
  errDates: boolean = false;

  constructor(
    private formBuild: FormBuilder,
    private shareAccount: AccountDataShare,
    private shareCompany: CompanyDataShare,
    private shareBeneficiary: SelectBeneficiaryDataShare,
    private shareTransferReceipt: TransferReceiptDataShare,
    private shareBank: BankDataShare,
    private addBeneficiary: AddFavoritesUsecase,
    private getBusinessDay: GetBusinessDayUsecase,
    private addAccount: AddAccountUsecase,
    private getAccountFibra: GetAccountFibraUsecase,
    private getDisclaimer: GetDisclaimerUsecase,
    private shareDebit: DebitAccountDataShare,
    private router: Router,
    private route: ActivatedRoute,
    private calendar: NgbCalendar,
    private role: RolesService,
    private limitDisclaimer: LimitsDisclaimerUsecase
  ) {
    this.otherTitle = this.formBuild.group({
      agency: ['', [Validators.required, Validators.min(0), Validators.max(4)]],
      typeAccount: ['', [Validators.required]],
      account: [
        '',
        [Validators.required, Validators.min(0), Validators.max(7)],
      ],
      name: ['', [Validators.required]],
      cpfCnpj: ['', [Validators.required, CpfCnpjValidator]],
    });
    this.transferData = this.formBuild.group({
      ammount: [0.0, [Validators.required]],
      dateTrasfer: ['', [Validators.required]],
      optionTransfer: ['', [Validators.required]],
      description: ['', [Validators.maxLength(100)]],
      purpose: [null],
      banco: {
        cod_banco: ['', [Validators.required]],
        nome_banco: ['', [Validators.required]],
      },
      nome_beneficiario: ['', [Validators.required]],
      num_agencia: ['', [Validators.required]],
      num_conta: ['', [Validators.required]],
      num_cpfcnpj: ['', [Validators.required]],
      tipo_conta: ['', [Validators.required]],
      tipo_operacao: ['', [Validators.required]],
    });
    this.ownership = '';
    this.loading = false;
    this.minDate = this.calendar.getToday();

    // Verificar a titularidade
    const beneficiary = this.shareBeneficiary.dataToShare;
    const company = this.shareCompany.dataToShare;

    combineLatest([beneficiary, company]).subscribe((rs) => {
      console.log(rs)
      if (rs[0].length === 0) {
        let changeKey = rs[1].companyList[0];
        this.titularidade = this.verifyOwnershipSame(changeKey);
        return
      }
      //this.titularidade = this.verifyOwnership(rs[0]);
    });
    // --

    this.requestAccountInput();
  }

  public minDate;
  public transferData: FormGroup;
  public char = 100;
  public redBorder;
  public beneficiaryData;
  public companyData;
  public ownership;
  public data;
  public transferValue = 0;
  public transferDateTrasfer: any = '';
  public transferOptionTransfer = '';
  public trasnferDescription = '';
  public trasnferPurpose = '';
  public accountData;
  public bank;
  public otherTitle: FormGroup;
  public date;
  public selectedDate: any;
  public loading;
  public saveFavorite;
  public accountFibra;
  public showDateTooltip: boolean;
  public accountOrigin;
  public indexAccount;
  public errorAccoutInput = false;
  public codBeneficiary;
  public disclamer: DisclaimerEnum;
  public disclamerMessages = [];
  public beneficiaryName;
  public beneficiaryCpfCnpj;
  public beneficiaryAccount;
  public accountDebit;
  public beneficiaryDataName;
  public beneficiaryDataCpfCnpj;
  public debitAccount;
  public isEqualCnpj = true;

  public personType: Observable<any> = new Observable<any>();
  public outraTitularidade;
  public typeTransaction: string;
  public showAllLimits: boolean = true;
  titularidade: string;
  showDisclaimer: boolean = false;
  disclaimerSpinner: boolean = false;
  disclaimerData = []

  requestAccount = new Subject<string>();

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  cpfcnpjMask = (value) => Util.cpfcnpjmask(value);

  removeMask = (value) => Util.removeMask(value);

  ngOnInit(): void {
    var main = document.querySelector('.main');
    
    if (main) {
      main.scrollIntoView();
    }
    this.errDateType = '';
    this.errDates = false;

    this.errDateType = '';
    this.errDates = false;
    
    this.initPage();
    this.shareAccount.dataShared.subscribe((res) => (this.accountData = res));
    setTimeout(() => {
      this.setBackgroundColor();
    }, 50);
    this.verifyBank();
    this.requestDisclaimer();
    if (!this.accountData) {
      this.router.navigate(['/transfer']);
    }
    console.log(this.typeTransaction, this.determinePeriod('07:25', '16:50')) //💡💡💡💡💡💡💡💡💡
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  initPage() {

    this.shareCompany.dataShared.subscribe((res) => {
      console.log(res)
      if (res) {
        this.companyData = res.companyList.filter((e) => e.selected)[0];
        console.log('this.companyData:', this.companyData)
        this.verifyOwnership(res)
      }
    });

    this.shareBeneficiary.dataShared.subscribe((res) => {
      console.log(res)
      if (res && res['beneficiary']) {
        this.verifyOwnership(res)
        this.beneficiaryData = res;
        this.beneficiaryDataName = this.beneficiaryData['beneficiary']['nome_beneficiario'];
        this.beneficiaryDataCpfCnpj = this.beneficiaryData['beneficiary']['num_cpf_cnpj'];
        this.codBeneficiary =
          !res ? 0 : res.beneficiary.cod_beneficiario;
        this.ownership = res.quote.cod_banco == '224' ? 'other' : 'same';

        this.debitAccount = res.quote.num_conta;


        let beneficiaryDataCpfCnpj = this.removeMask(this.beneficiaryDataCpfCnpj)
        this.isEqualCnpj = beneficiaryDataCpfCnpj === this.companyData.modality_account
      }
      if (this.typeTransfer === 'favorite') {
        this.setDebitTransferData(this.beneficiaryData.quote);
      }

      console.log(this.ownership)
    });

    this.shareBank.dataShared.subscribe((res) => {
      if (res) {
        this.bank = res;
      }
    });

    this.route.queryParams.subscribe((parametros) => {
      if (parametros.cnpjCpf) {
        this.beneficiaryCpfCnpj = parametros.cnpjCpf;
        this.beneficiaryName = parametros.beneficiary;
        this.beneficiaryAccount = parametros.counter;
        this.otherTitle.patchValue({
          name: parametros.beneficiary,
          cpfCnpj: parametros.cnpjCpf,
          account: parametros.counter,
        });
        this.otherTitleControlIsInvalid('cpfCnpj');
        this.changeOwnership('other');
        this.getAccount(this.beneficiaryAccount);
      }
    });
  }

  verifyBank() {
    var permissoes = this.role.get();
    if (this.bank && this.bank.cod_banco === 224) {
      this.transferData.get('optionTransfer').setValue('TEF');
      this.typeTransaction = 'TEF';
      if (permissoes.includes('TRANSF.TRANSF_OTR_TITU')) {
        this.ownership = 'other'
      }
      if (permissoes.includes('TRANSF.TRANSF_MSM_TITU')) {
        this.ownership = 'same'
      }
    } else {
      if (permissoes.includes('TRANSF.TRANSF_OTR_TIT_BC')) {
        this.ownership = 'other'
      }
      if (permissoes.includes('TRANSF.TRANSF_MSM_TIT_OTR_BC')) {
        this.ownership = 'same'
      }
      this.transferData.get('optionTransfer').setValue('TED');
      this.typeTransaction = 'TED';
    }
    if (
      this.typeTransfer !== 'favorite' &&
      Util.isEmpty(this.beneficiaryData)
    ) {
      this.beneficiaryData = {
        quote: {
          cod_banco: this.bank.cod_banco,
        },
      };
    }
    if (this.beneficiaryData && this.beneficiaryData.quote.cod_banco === 224) {
      this.transferData.get('optionTransfer').setValue('TEF');
      this.typeTransaction = 'TEF';
    } else {
      this.transferData.get('optionTransfer').setValue('TED');
      this.typeTransaction = 'TED';
    }
  }

  changeDescription(value) {
    this.transferData.patchValue({
      description: this.trasnferDescription,
    });
    this.redBorder = value.length;
    this.char = this.char - value.length;
    this.emitTransferData.emit(this.transferData);
  }

  limitTextarea(valor) {
    const quant = 100;
    const total = valor.length;

    if (total <= quant) {
      this.char = quant - total;
    } else {
      this.char = valor.substr(0, quant);
    }
  }

  changeCpfCnpj(value) {
    console.log(value)
    this.outraTitularidade = {
      num_cpfcnpj: value
    }
    const cpfcnpj = value.replace(/\D/gim, '');
    this.otherTitle.patchValue({
      cpfCnpj: cpfcnpj,
    });
    this.otherTitleControlIsInvalid('cpfCnpj');
  }

  changeAgency(value) {
    this.otherTitle.patchValue({
      agency: value,
    });
  }

  changeAccount(value) {
    this.otherTitle.patchValue({
      account: value,
    });
  }

  changeBeneficiaryName(value) {
    this.otherTitle.patchValue({
      name: value,
    });
  }

  changeTypeAccount(value, tipo) {
    if (tipo === 'tipo_conta') {
      this.otherTitle.patchValue({
        typeAccount: value,
      });
    } else {
      this.changeOptionTransfer(value);
    }
  }

  changeAmmount() {
    console.log(this.transferValue)
    if (this.transferValue > 0) {
      this.transferData.patchValue({
        ammount: this.transferValue,
      });

      this.emitValue.emit(this.transferValue);
      this.emitTransferData.emit(this.transferData);
    }
    if (this.trasnferControlIsInvalid('ammount')) {
      this.errorValue.emit(true);
    }
  }

  checkPastDate(ev) {
    moment.locale('pt-br');
    var valueDate: any;
    if (typeof ev === 'object') {
      valueDate = ev.day + '/' + ev.month + '/' + ev.year;
    } else {
      valueDate = ev;
    }

    let dateFormat = moment(valueDate, 'D/M/YYYY', true).format('DD/MM/YYYY');
    let validDate = moment(dateFormat, 'DD/MM/YYYY', true).isValid();
    let conv = moment(dateFormat, 'DD/MM/YYYY').format('YYYY-MM-DD');
    let bfDay = moment(conv).isBefore(moment(), 'day');
    let isBigger = moment(conv).diff(moment(), 'year');
    
    if (!validDate || bfDay) {
      this.errDates = true;
      this.errDateType = 'Data inválida. Informe uma data igual ou maior que a data atual.';
      return false;
    }

    if (validDate && isBigger > 0) {
      this.errDates = true;
      this.errDateType = 'Data inválida.';
      return false;
    }
    this.errDates = false;
    return true;
  }

  changeDate(value) {
    if(!this.checkPastDate(value)) {
      return;
    }
    if (value.toString().indexOf('/') > -1) {
      let date = value.split("/");
      value = new NgbDate(parseInt(date[2], 10), parseInt(date[1], 10), parseInt(date[0], 10))
    }

    this.transferDateTrasfer = value;
    this.transferData.patchValue({
      dateTrasfer: this.transferDateTrasfer,
    });
    this.emitTransferData.emit(this.transferData);
    this.getDayBusiness();
  }

  changeOptionTransfer(value) {
    this.transferOptionTransfer = value;
    this.transferData.patchValue({
      optionTransfer: this.transferOptionTransfer,
    });
    this.emitTransferData.emit(this.transferData);
  }

  changePurposes(value) {
    this.trasnferPurpose = value;
    this.transferData.patchValue({
      purpose: this.trasnferPurpose,
    });
    this.emitTransferData.emit(this.transferData);
  }

  changeOwnership(value) {
    this.ownership = value;
    this.personType = new Observable<string>((r) => r.next(value));
    if (value == "same") {
      this.isEqualCnpj = true
    } else {
      this.isEqualCnpj = false
    }
  }

  verifyTypeTransfer() {
    console.log('this.typeTransfer: ', this.typeTransfer + '- this.bank.cod_banco: ' + this.bank.cod_banco + '- this.ownership: ' + this.ownership)
    if (this.typeTransfer === 'favorite') {
      this.beneficiaryData = {
        beneficiary: {
          contas: [
            {
              banco: {
                cod_banco: this.beneficiaryData.beneficiary.contas[0].cod_banco,
                nome_banco: this.beneficiaryData.beneficiary.contas[0]
                  .nome_banco,
              },
              nome_beneficiario: this.beneficiaryData.beneficiary
                .nome_beneficiario,
              num_agencia: this.beneficiaryData.beneficiary.contas[0]
                .num_agencia,
              num_conta: this.beneficiaryData.beneficiary.contas[0].num_conta,
              num_cpfcnpj: this.beneficiaryData.beneficiary.num_cpf_cnpj,
              tipo_conta: this.otherTitle.get('typeAccount').value,
              tipo_operacao: Util.verifyTransferType(
                this.transferData.get('optionTransfer').value
              ),
            },
          ],
          nome_beneficiario: this.beneficiaryData.beneficiary.nome_beneficiario,
          num_conta: this.beneficiaryData.beneficiary.contas[0].num_conta,
          num_cpf_cnpj: this.beneficiaryData.beneficiary.num_cpf_cnpj,
        },
        quote: {
          banco: {
            cod_banco: this.transferData.get('banco').value.cod_banco,
            nome_banco: this.transferData.get('banco').value.nome_banco,
          },
          nome_beneficiario: this.transferData.get('nome_beneficiario').value,
          num_agencia: this.transferData.get('num_agencia').value,
          num_conta: this.transferData.get('num_conta').value,
          num_cpfcnpj: this.transferData.get('num_cpfcnpj').value,
          tipo_conta: this.transferData.get('tipo_conta').value,
          tipo_operacao: Util.verifyTransferType(
            this.transferData.get('optionTransfer').value
          ),
        },
      };
      return;
    }
    if (
      this.typeTransfer !== 'favorite' &&
      this.typeTransfer !== 'new' &&
      this.ownership === 'other'
    ) {
      this.beneficiaryData = {
        beneficiary: {
          contas: [
            {
              banco: {
                cod_banco: this.bank.cod_banco,
                nome_banco: this.bank.nome_banco,
              },
              nome_beneficiario: this.otherTitle.get('name').value,
              num_agencia: this.otherTitle.get('agency').value,
              num_conta: this.otherTitle.get('account').value,
              num_cpfcnpj: this.otherTitle.get('cpfCnpj').value,
              tipo_conta: this.otherTitle.get('typeAccount').value,
              tipo_operacao: Util.verifyTransferType(
                this.transferData.get('optionTransfer').value
              ),
            },
          ],
          nome_beneficiario: this.otherTitle.get('name').value,
          num_conta: this.otherTitle.get('account').value,
          num_cpf_cnpj: this.otherTitle.get('cpfCnpj').value,
        },
        quote: {
          banco: {
            cod_banco: this.bank.cod_banco,
            nome_banco: this.bank.nome_banco,
          },
          nome_beneficiario: this.otherTitle.get('name').value,
          num_agencia: this.otherTitle.get('agency').value,
          num_conta: this.otherTitle.get('account').value,
          num_cpfcnpj: this.otherTitle.get('cpfCnpj').value,
          tipo_conta: this.otherTitle.get('typeAccount').value,
          tipo_operacao: Util.verifyTransferType(
            this.transferData.get('optionTransfer').value
          ),
        },
      };
    }
    if (
      this.typeTransfer === 'same' &&
      this.ownership === 'other' &&
      this.bank &&
      this.bank.cod_banco === 224
    ) {
      this.beneficiaryData = {
        beneficiary: {
          contas: [
            {
              banco: {
                cod_banco: this.accountFibra.banco.cod_banco,
                nome_banco: this.accountFibra.banco.nome_banco,
              },
              nome_beneficiario: this.accountFibra.nome_beneficiario,
              num_agencia: this.accountFibra.num_agencia,
              num_conta: this.accountFibra.num_conta,
              num_cpfcnpj: this.accountFibra.num_cpfcnpj,
              tipo_conta: this.accountFibra.tipo_conta,
              tipo_operacao: 3,
            },
          ],
          nome_beneficiario: this.accountFibra.nome_beneficiario,
          num_conta: this.accountFibra.num_conta,
          num_cpf_cnpj: this.accountFibra.num_cpfcnpj,
        },
        quote: {
          banco: {
            cod_banco: this.accountFibra.banco.cod_banco,
            nome_banco: this.accountFibra.banco.nome_banco,
          },
          nome_beneficiario: this.accountFibra.nome_beneficiario,
          num_agencia: this.accountFibra.num_agencia,
          num_conta: this.accountFibra.num_conta,
          num_cpfcnpj: this.accountFibra.num_cpfcnpj,
          tipo_conta: this.accountFibra.desc_conta,
          tipo_operacao: 3,
        },
      };
    }
    if (this.typeTransfer === 'new' && this.bank.cod_banco !== 224) {
      this.beneficiaryData = {
        //beneficiary: this.beneficiaryData.beneficiary,
        beneficiary: {
          contas: [
            {
              banco: {
                cod_banco: this.bank.cod_banco,
                nome_banco: this.bank.nome_banco,
              },
              nome_beneficiario: this.otherTitle.get('name').value,
              num_agencia: this.otherTitle.get('agency').value,
              num_conta: this.otherTitle.get('account').value,
              num_cpfcnpj: this.otherTitle.get('cpfCnpj').value,
              tipo_conta: this.otherTitle.get('typeAccount').value,
              tipo_operacao: Util.verifyTransferType(
                this.transferData.get('optionTransfer').value
              ),
            },
          ],
          nome_beneficiario: this.beneficiaryDataName,
          num_conta: this.otherTitle.get('account').value,
          num_cpf_cnpj: this.beneficiaryDataCpfCnpj,
        },
        quote: {
          banco: this.bank,
          nome_beneficiario: this.otherTitle.get('name').value,
          num_agencia: this.otherTitle.get('agency').value,
          num_conta: this.otherTitle.get('account').value,
          num_cpfcnpj: this.otherTitle.get('cpfCnpj').value,
          tipo_conta: this.otherTitle.get('typeAccount').value,
          tipo_operacao: Util.verifyTransferType(
            this.transferData.get('optionTransfer').value
          ),
        },
      };
    }
    if (
      this.typeTransfer === 'new' &&
      this.bank.cod_banco === 224 &&
      this.ownership === 'same'
    ) {
      this.beneficiaryData = {
        beneficiary: {
          contas: [
            {
              banco: {
                cod_banco: this.bank.cod_banco,
                nome_banco: this.bank.nome_banco,
              },
              nome_beneficiario: this.companyData.name,
              num_agencia: this.accountOrigin.cod_agencia,
              num_conta: this.accountOrigin.num_conta,
              num_cpfcnpj: this.companyData.modality_account,
              tipo_conta: this.accountOrigin.desc_conta,
              tipo_operacao: Util.verifyTransferType(
                this.transferData.get('optionTransfer').value
              ),
            },
          ],
          nome_beneficiario: this.companyData.name,
          num_conta: this.otherTitle.get('account').value,
          num_cpf_cnpj: this.companyData.modality_account,
        },
        quote: {
          banco: this.bank,
          nome_beneficiario: this.companyData.name,
          num_agencia: this.accountOrigin.cod_agencia,
          num_conta: this.accountOrigin.num_conta,
          num_cpfcnpj: this.companyData.modality_account,
          tipo_conta: this.accountOrigin.desc_conta,
          tipo_operacao: Util.verifyTransferType(
            this.transferData.get('optionTransfer').value
          ),
        },
      };
    }

    if (
      this.typeTransfer === 'new' &&
      this.bank.cod_banco === 224 &&
      this.ownership === 'other'
    ) {

      this.beneficiaryData = {
        beneficiary: {
          contas: [
            {
              banco: {
                cod_banco: this.accountFibra.banco.cod_banco,
                nome_banco: this.accountFibra.banco.nome_banco,
              },
              nome_beneficiario: this.accountFibra.nome_beneficiario,
              num_agencia: this.accountFibra.num_agencia,
              num_conta: this.accountFibra.num_conta,
              num_cpfcnpj: this.accountFibra.num_cpfcnpj,
              tipo_conta: 'cc',
              tipo_operacao: Util.verifyTransferType(
                this.transferData.get('optionTransfer').value
              ),
            },
          ],
          nome_beneficiario: this.accountFibra.nome_beneficiario,
          num_conta: this.accountFibra.num_conta,
          num_cpf_cnpj: this.accountFibra.num_cpfcnpj,
        },
        quote: {
          banco: {
            cod_banco: this.accountFibra.banco.cod_banco,
            nome_banco: this.accountFibra.banco.nome_banco,
          },
          nome_beneficiario: this.accountFibra.nome_beneficiario,
          num_agencia: this.accountFibra.num_agencia,
          num_conta: this.accountFibra.num_conta,
          num_cpfcnpj: this.accountFibra.num_cpfcnpj,
          tipo_conta: 'cc',
          tipo_operacao: Util.verifyTransferType(
            this.transferData.get('optionTransfer').value
          ),
        },
      };
    }

    if (
      this.ownership === 'same' &&
      this.typeTransfer === 'same' &&
      this.bank.cod_banco !== 224
    ) {
      this.beneficiaryData = {
        beneficiary: {
          contas: [
            {
              banco: {
                cod_banco: this.bank.cod_banco,
                nome_banco: this.bank.nome_banco,
              },
              nome_beneficiario: this.otherTitle.get('name').value,
              num_agencia: this.otherTitle.get('agency').value,
              num_conta: this.otherTitle.get('account').value,
              num_cpfcnpj: this.otherTitle.get('cpfCnpj').value,
              tipo_conta: this.otherTitle.get('typeAccount').value,
              tipo_operacao: Util.verifyTransferType(
                this.transferData.get('optionTransfer').value
              ),
            },
          ],
          nome_beneficiario: this.companyData.name,
          num_conta: this.otherTitle.get('account').value,
          num_cpf_cnpj: this.companyData.modality_account,
        },
        quote: {
          banco: {
            cod_banco: this.bank.cod_banco,
            nome_banco: this.bank.nome_banco,
          },
          nome_beneficiario: this.otherTitle.get('name').value,
          num_agencia: this.otherTitle.get('agency').value,
          num_conta: this.otherTitle.get('account').value,
          num_cpfcnpj: this.otherTitle.get('cpfCnpj').value,
          tipo_conta: this.otherTitle.get('typeAccount').value,
          tipo_operacao: Util.verifyTransferType(
            this.transferData.get('optionTransfer').value
          ),
        },
      };
    }

    if (
      this.typeTransfer === 'same' &&
      this.ownership === 'same' &&
      this.bank.cod_banco === 224
    ) {
      this.beneficiaryData = {
        beneficiary: {
          contas: [
            {
              banco: {
                cod_banco: this.bank.cod_banco,
                nome_banco: this.bank.nome_banco,
              },
              nome_beneficiario: this.companyData.name,
              num_agencia: this.accountOrigin.cod_agencia,
              num_conta: this.accountOrigin.num_conta,
              num_cpfcnpj: this.companyData.modality_account,
              tipo_conta: this.accountOrigin.desc_conta,
              tipo_operacao: Util.verifyTransferType(
                this.transferData.get('optionTransfer').value
              ),
            },
          ],
          nome_beneficiario: this.companyData.name,
          num_conta: this.otherTitle.get('account').value,
          num_cpf_cnpj: this.companyData.modality_account,
        },
        quote: {
          banco: {
            cod_banco: this.bank.cod_banco,
            nome_banco: this.bank.nome_banco,
          },
          nome_beneficiario: this.companyData.name,
          num_agencia: this.accountOrigin.cod_agencia,
          num_conta: this.accountOrigin.num_conta,
          num_cpfcnpj: this.companyData.modality_account,
          tipo_conta: this.accountOrigin.desc_conta,
          tipo_operacao: Util.verifyTransferType(
            this.transferData.get('optionTransfer').value
          ),
        },
      };
    }
  }

  verifyCheckSave() {
    if (
      this.typeTransfer === 'new' &&
      this.saveFavorite &&
      this.bank &&
      this.bank.cod_banco !== 224
    ) {
      this.addAccountFavorite();
      this.router.navigate(['/transfer-confirm']);
      return;
    }

    if (
      this.typeTransfer === 'new' &&
      this.saveFavorite &&
      this.bank &&
      this.bank.cod_banco === 224
    ) {
      this.addAccountFibra();
      this.router.navigate(['/transfer-confirm']);
      return;
    }

    if (
      this.typeTransfer !== 'new' &&
      this.saveFavorite &&
      this.bank.cod_banco === 224
    ) {
      this.addFavorite();
      this.router.navigate(['/transfer-confirm']);
      return;
    }

    if (
      this.saveFavorite &&
      this.typeTransfer !== 'new' &&
      this.bank.cod_banco !== 224
    ) {
      this.addFavorite();
      this.router.navigate(['/transfer-confirm']);
      return;
    } else {
      this.router.navigate(['/transfer-confirm']);
    }
  }

  initialLetters(name) {
    name = name.replace(/\s(de|da|dos|das)\s/g, ' ');
    const initials = name.match(/\b(\w)/gi);
    return (
      initials[0] + (initials[1] !== undefined ? initials[1] : initials[0])
    ).toUpperCase();
  }

  setBackgroundColor() {
    const arrayColor = [
      '#CC092F',
      '#082A4D',
      '#FEF200',
      '#0070AF',
      '#003399',
      '#FF984E',
      '#CE4343',
      '#8A05BE',
      '#F00003',
      '#C4C4C4',
    ];
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        const color = arrayColor[Math.floor(Math.random() * arrayColor.length)];
        const elem = document.getElementById(`background${i}`);
        elem.setAttribute('style', `background: ${color}`);
      }
    }
  }

  public trasnferControl(name: string) {
    return this.transferData.get(name);
  }

  public trasnferControlIsInvalid(name: string) {
    const control = this.trasnferControl(name);
    return control.invalid && control.touched;
  }

  getDayBusiness() {
    const error = (err) => {
      console.warn('Erro ao salvar Beneficiário como favorito');
    };
    const date: NgbDate = this.transferData.get('dateTrasfer').value;
    const params = {
      diautil: date.year + '-' + date.month + '-' + date.day,
    };
    this.getBusinessDay
      .execute(params)
      .subscribe(this.daySuccessRequest, error);

    this.selectedDate = {
      year: date.year,
      month: date.month,
      day: date.day,
    };
  }

  daySuccessRequest = (value: BeneficiariesModel) => {
    const aux: string = value.data.diautil.split('T');
    const date = aux[0].split('-');

    this.date = {
      year: parseInt(date[0]),
      month: parseInt(date[1]),
      day: parseInt(date[2]),
    };
    console.log(this.date)
    this.showDateTooltip = !(
      this.selectedDate.year === this.date.year &&
      this.selectedDate.month === this.date.month &&
      this.selectedDate.day === this.date.day
    );
    this.transferDateTrasfer = this.date;
    this.transferData.patchValue({
      dateTrasfer: this.transferDateTrasfer,
    });
  };

  setFavorite() {
    this.saveFavorite = true;
  }

  addFavorite() {
    const success = (value: BeneficiariesModel) => {
      console.log('Beneficiário salvo como favorito com sucesso!');
    };

    const error = (err) => {
      console.warn('Erro ao salvar Beneficiário como favorito');
    };

    if (this.ownership === 'same') {
      let tipoCoonta = 'cc';

      if (this.bank.cod_banco === 224) {
        tipoCoonta = 'cc';
      } else {
        tipoCoonta = this.otherTitle.get('typeAccount').value;
      }

      const params: any = {
        nome_beneficiario: this.companyData.name,
        num_cpf_cnpj: this.companyData.modality_account,
        contas: [
          {
            cod_banco: this.bank.cod_banco,
            nomeBanco: this.bank.nome_banco,
            num_agencia: this.beneficiaryData.quote.num_agencia,
            nome_beneficiario: this.companyData.name,
            num_conta: this.beneficiaryData.quote.num_conta,
            tipo_operacao: Util.verifyTransferType(
              this.transferData.get('optionTransfer').value
            ),
            num_cpfcnpj: this.companyData.modality_account,
            tipo_conta: tipoCoonta,
          },
        ],
        flag_titularidade: this.ownership === 'same' ? 0 : 1
      };

      this.addBeneficiary.execute(params).subscribe(success, error);
      return;
    }
    if (this.ownership === 'other' && this.bank.cod_banco === 224) {
      const params: any = {
        nome_beneficiario: this.accountFibra.nome_beneficiario,
        num_cpf_cnpj: this.accountFibra.num_cpfcnpj,
        contas: [
          {
            cod_banco: this.accountFibra.banco.cod_banco,
            nomeBanco: this.accountFibra.banco.nome_banco,
            num_agencia: this.accountFibra.num_agencia,
            nome_beneficiario: this.accountFibra.nome_beneficiario,
            num_conta: this.accountFibra.num_conta,
            tipo_operacao: Util.verifyTransferType(
              this.transferData.get('optionTransfer').value
            ),
            num_cpfcnpj: this.accountFibra.num_cpfcnpj,
            tipo_conta: 'cc',
          },
        ],
        flag_titularidade: this.ownership === 'same' ? 0 : 1
      };

      this.addBeneficiary.execute(params).subscribe(success, error);
      return;
    } else {
      const params: any = {
        nome_beneficiario: this.otherTitle.get('name').value,
        num_cpf_cnpj: this.otherTitle.get('cpfCnpj').value,
        contas: [
          {
            cod_banco: this.bank.cod_banco,
            nomeBanco: this.bank.nome_banco,
            num_agencia: this.otherTitle.get('agency').value,
            nome_beneficiario: this.otherTitle.get('name').value,
            num_conta: this.otherTitle.get('account').value,
            tipo_operacao: Util.verifyTransferType(
              this.transferData.get('optionTransfer').value
            ),
            num_cpfcnpj: this.otherTitle.get('cpfCnpj').value,
            tipo_conta: this.otherTitle.get('typeAccount').value,
          },
        ],
        flag_titularidade: this.ownership === 'same' ? 0 : 1
      };

      this.addBeneficiary.execute(params).subscribe(success, error);
    }
  }

  private addAccountFibra() {
    if (this.ownership === 'same' && this.bank.cod_banco === 224) {
      const params: AddAccountRequest = {
        cod_beneficiario: this.codBeneficiary,
        cod_banco: this.bank.cod_banco,
        num_agencia: this.accountOrigin.cod_agencia,
        nome_beneficiario: this.beneficiaryData.beneficiary.nome_beneficiario,
        num_conta: this.accountOrigin.num_conta,
        tipo_operacao: Util.verifyTransferType(
          this.transferData.get('optionTransfer').value
        ),
        num_cpfcnpj: this.companyData.modality_account,
        tipo_conta: this.accountOrigin.desc_conta,
      };
      const success = (value: BeneficiariesModel) => {
        console.log(value);
      };

      const error = (err) => {
        console.warn(err);
      };
      this.addAccount.execute(params).subscribe(success, error);
    }

    if (this.ownership === 'other' && this.bank.cod_banco === 224) {
      const params: AddAccountRequest = {
        cod_beneficiario: this.codBeneficiary,
        cod_banco: this.accountFibra.banco.cod_banco,
        num_agencia: this.accountFibra.num_agencia,
        nome_beneficiario: this.accountFibra.nome_beneficiario,
        num_conta: this.accountFibra.num_conta,
        tipo_operacao: Util.verifyTransferType(
          this.transferData.get('optionTransfer').value
        ),
        num_cpfcnpj: this.accountFibra.num_cpfcnpj,
        tipo_conta: 'cc',
      };
      const success = (value: BeneficiariesModel) => {
        console.log(value);
      };

      const error = (err) => {
        console.warn(err);
      };

      this.addAccount.execute(params).subscribe(success, error);
    }
  }

  addAccountFavorite() {

    const params: AddAccountRequest = {
      cod_beneficiario: this.codBeneficiary,
      cod_banco: this.bank.cod_banco,
      num_agencia: this.otherTitle.get('agency').value,
      nome_beneficiario: this.beneficiaryData.beneficiary.nome_beneficiario,
      num_conta: this.otherTitle.get('account').value,
      tipo_operacao: Util.verifyTransferType(
        this.transferData.get('optionTransfer').value
      ),
      num_cpfcnpj: this.companyData.modality_account,
      tipo_conta: this.otherTitle.get('typeAccount').value
    };
    const success = (value: BeneficiariesModel) => {
      console.log(value);
    };

    const error = (err) => {
      console.warn(err);
    };

    this.addAccount.execute(params).subscribe(success, error);
  }

  getAccount(account: string) {
    const success = (value) => {
      console.log(value)
      this.accountFibra = value.data;

      // Quando for outra titularidade a variavel 'outraTitularidade' receberá valor 
      this.outraTitularidade = value.data;

      Object.keys(value.data).length === 0
        ? (this.errorAccoutInput = true)
        : (this.errorAccoutInput = false);
      this.loading = false;
    };

    const error = (err) => {
      this.loading = false;
      this.accountFibra = undefined;
      this.errorAccountFibra.emit(true);
    };

    this.accountFibra = undefined;
    this.errorAccoutInput = false;

    this.loading = true;
    const params: RequestAccountFibra = {
       num_conta: account,
    };
    this.getAccountFibra.execute(params).subscribe(success, error);
    
  }

  onClose() {
    this.showDateTooltip = false;
  }

  setAccountOrigin(value) {
    this.accountOrigin = value;
  }

  setBlockAccountFibra(index) {
    this.indexAccount = this.accountOrigin.num_indice;
  }

  setAccountDebit(value) {
    this.setLimit(value);

    if (this.typeTransfer !== 'favorite') {
      this.setDebitTransferData(value);
    }
    this.setDebit(value);
  }

  private setDebitTransferData(value) {
    let cd_banco = this.bank ? this.bank.cod_banco : value.cod_banco
    this.transferData.patchValue({
      banco: {
        cod_banco: value.cod_banco,
        nome_banco: value.nome_banco,
      },
      nome_beneficiario: this.companyData.name,
      num_agencia: value.cod_agencia ? value.cod_agencia : value.num_agencia,
      num_conta: value.num_conta,
      num_cpfcnpj: this.companyData.modality_account,
      tipo_conta: value.desc_conta ? value.desc_conta : value.desc_tipo_conta,
      tipo_operacao: cd_banco === 224 ? 'TEF' : 'TED',
      saldo: value.saldo,
      exibicao: value.exibicao
    });


  }

  private setDebit(value) {
    this.shareDebit.setValue({
      banco: {
        cod_banco: 224,
        nome_banco: 'Banco Fibra',
      },
      nome_beneficiario: this.companyData.name,
      cod_agencia: value.cod_agencia,
      num_conta: value.num_conta,
      num_cpfcnpj: this.companyData.modality_account,
      desc_conta: value.desc_conta,
      tipo_operacao: this.transferData.get('optionTransfer').value,
    });

    this.accountDebit = {
      banco: {
        cod_banco: 224,
        nome_banco: 'Banco Fibra',
      },
      nome_beneficiario: this.companyData.name,
      cod_agencia: value.cod_agencia,
      num_conta: value.num_conta,
      num_cpfcnpj: this.companyData.modality_account,
      desc_conta: value.desc_conta,
      tipo_operacao: this.transferData.get('optionTransfer').value,
      saldo: value.saldo,
      exibicao: value.exibicao
    };
  }

  validationInfo() {
    if (
      this.typeTransfer === 'same' &&
      this.ownership === 'other' &&
      this.accountFibra &&
      this.accountFibra.banco &&
      this.accountFibra.banco.cod_banco === 224
    ) {
      return !(
        this.transferData.get('purpose').value &&
        this.transferData.get('dateTrasfer').value &&
        this.transferData.get('optionTransfer').value &&
        this.transferValue > 0 &&
        this.accountFibra &&
        this.accountDebit
      );
    }
    if (this.typeTransfer === 'favorite') {
      return !(
        this.transferData.valid &&
        this.transferValue > 0 &&
        this.accountDebit
      );
    }

    if (this.typeTransfer === 'new' && this.bank.cod_banco !== 224) {
      return !(
        this.otherTitle.get('agency').value &&
        this.otherTitle.get('account').value &&
        this.otherTitle.get('typeAccount').value &&
        this.transferValue > 0 &&
        this.accountDebit
      );
    }

    if (
      this.typeTransfer === 'new' &&
      this.bank.cod_banco === 224 &&
      this.ownership === 'other' &&
      this.accountFibra
    ) {
      return !(
        this.transferData.valid &&
        true &&
        this.transferValue > 0 &&
        this.accountDebit
      );
    }

    if (
      (this.typeTransfer === 'new' &&
        this.bank.cod_banco === 224 &&
        this.ownership === 'same') ||
      (this.typeTransfer === 'same' && this.bank.cod_banco === 224)
    ) {
      return !(
        this.transferData.get('purpose').value &&
        this.transferData.get('dateTrasfer').value &&
        this.transferData.get('optionTransfer').value &&
        this.accountOrigin !== undefined &&
        this.transferValue > 0 &&
        this.accountDebit &&
        this.accountDebit.num_conta != this.accountOrigin.num_conta
      );
    }

    if (
      this.typeTransfer === 'same' &&
      this.bank.cod_banco !== 224 &&
      this.ownership === 'same'
    ) {
      return !(
        this.otherTitle.get('agency').value &&
        this.otherTitle.get('account').value &&
        this.otherTitle.get('typeAccount').value &&
        this.transferData.get('purpose').value &&
        this.transferData.get('dateTrasfer').value &&
        this.transferData.get('optionTransfer').value &&
        this.transferValue > 0 &&
        this.accountDebit
      );
    }

    if (
      this.typeTransfer === 'same' &&
      this.bank.cod_banco !== 224 &&
      this.ownership === 'other'
    ) {
      return !(
        this.transferData.get('purpose').value &&
        this.transferData.get('dateTrasfer').value &&
        this.transferValue > 0 &&
        this.transferData.get('optionTransfer').value &&
        this.otherTitle.get('typeAccount').value &&
        this.otherTitle.get('name').value &&
        this.otherTitle.get('cpfCnpj').value &&
        this.otherTitle.get('agency').value &&
        this.otherTitle.get('account').value &&
        this.accountDebit
      );
    }
  }

  public otherTitleControl(name: string) {
    return this.otherTitle.get(name);
  }

  public otherTitleControlIsInvalid(name: string) {
    const control = this.otherTitleControl(name);
    if (
      (control.value.toString().length === 11 && control.errors) ||
      (control.value.toString().length === 14 && control.errors)
    ) {
      this.errorCpfCnpj.emit(control.value.toString().length);
    }
  }

  goNext() {

    // Verifica se é agendamento
    let dateToday = new Date();
    let selectedDate = new Date(this.transferDateTrasfer.year, this.transferDateTrasfer.month - 1, this.transferDateTrasfer.day);
    let isSchedule = moment(selectedDate).isAfter(dateToday.toJSON());


    if(this.errDates) {
      return;
    }
    if (this.accountDebit.desc_conta == 'CHEQUE EMPRESA' && !isSchedule) {
      this.chequeEmpresa = this.UsaChequeEmpresa()
    }

    if (!this.chequeEmpresa && this.saldoDisponivel) {
      this.verifyTypeTransfer();
      this.shareTransferReceipt.setValue({
        accountData: this.accountData,
        beneficiaryData: this.beneficiaryData,
        transferInformation: this.transferData,
        same: this.ownership === 'same' || this.typeTransfer === 'favorite'
      });
      this.verifyCheckSave();
    }
  }

  public UsaChequeEmpresa() {
    let vlr_cheque_disponivel = this.accountDebit.saldo.vlr_cheque_disponivel;
    const saldoMenosValorBloqueado = Math.round((this.accountDebit.saldo.vlr_saldo_calculado - this.accountDebit.saldo.vlr_bloqueado) * 1e2) / 1e2;

    if (this.accountDebit.saldo.vlr_limite == 0) {
      vlr_cheque_disponivel = 0;
    }
    if (this.transferData.value.ammount > saldoMenosValorBloqueado) {
      var valorMenosSaldo = 0;
      if (saldoMenosValorBloqueado < 0) {
        valorMenosSaldo = this.transferData.value.ammount
      } else {
        valorMenosSaldo = Math.round((this.transferData.value.ammount - saldoMenosValorBloqueado) * 1e2) / 1e2;
      }
      if (valorMenosSaldo > 0 && vlr_cheque_disponivel < valorMenosSaldo) {
        this.saldoDisponivel = true;
        return false;
      } else if (valorMenosSaldo == 0) {
        this.saldoDisponivel = true;
        return false;
      } else {
        this.valorChequeEmpresa = valorMenosSaldo;
        this.saldoDisponivel = true;
        return true;
      }
    }
  }

  public closeModalPix(value) {
    if (value == 'sim') {
      this.verifyTypeTransfer();
      this.shareTransferReceipt.setValue({
        accountData: this.accountData,
        beneficiaryData: this.beneficiaryData,
        transferInformation: this.transferData,
        same: this.ownership === 'same' || this.typeTransfer === 'favorite'
      });
      this.verifyCheckSave();
    } else if (value == 'cancelar') {
      this.chequeEmpresa = false;
    }
  }

  public requestDisclaimer() {
    const success = (value: DisclaimerModel) => {
      this.disclamer = value.data;
      this.resolveDisclaimer(this.disclamer);
    };
    const error = (err: any) => {
      console.log(err);
    };
    let params = '';
    if (this.ownership === 'same') {
      params = '1/3/1';
    }
    if (this.ownership === 'other') {
      params = '1/3/0';
    }
    this.getDisclaimer.execute(params).subscribe(success, error);
  }

  public resolveDisclaimer = (value: DisclaimerEnum) => {
    for (const i in value) {
      this.disclamerMessages.push({
        label: value[i].desc_disclaimer,
      });
    }
  };

  originAccount(val) {
    return val.filter(rs => rs.desc_conta !== 'CONTA GARANTIDA');
  }

  originAccountBeneficiary(val) {
    return val.filter(rs => rs.desc_conta !== 'CONTA GARANTIDA' && rs.cod_modalidade !== '9' && rs.cod_modalidade !== '53' && rs.cod_modalidade !== '61');
  }

  ngOnDestroy(): void {
    this.personType;
  }

  mountArray(titularidade, value, data) {
    if (titularidade === 'same' && value === 'PF') {
      this.personType = new Observable<any>(val => val.next('Mesma titularidade PF'));
      return data[0].mesmaTitularidadePF
    }

    if (titularidade === 'same' && value === 'PJ') {
      this.personType = new Observable<any>(val => val.next('Mesma titularidade PJ'));
      return data[0].mesmaTitularidadePJ
    }

    if (titularidade === 'other' && value === 'PF') {
      this.personType = new Observable<any>(val => val.next('Outra titularidade PF'));
      return data[0].outraTitularidadePF
    }

    if (titularidade === 'other' && value === 'PJ') {
      this.personType = new Observable<any>(val => val.next('Outra titularidade PJ'));
      return data[0].outraTitularidadePJ
    }
  }

  setLimit(value) {
    this.showDisclaimer = false;
    this.disclaimerSpinner = true;
    

    const numberAccount = {
      NumeroConta: value.num_conta,
      NumeroEmpresa: "",
      IdEmpresa: 0,
      Pix: false
    }

    this.limitDisclaimer.execute(numberAccount).subscribe(r => {

      let arrDisclaimer;

      // setar limite quando Beneficiário favorito
      if (this.typeTransfer === 'favorite') {
        const typePerson = Util.removeMask(this.beneficiaryData.beneficiary.num_cpf_cnpj).length > 11 ? 'PJ' : 'PF';

        if (this.companyData.cpfCnpj === Util.removeMask(this.beneficiaryData.beneficiary.num_cpf_cnpj)) {
          arrDisclaimer = r.data.find((r: any) => r.tipoTitularidade === 'MESMA_TITULARIDADE' && r.tipoTransacao === this.typeTransaction);
          this.disclaimerData = Array(arrDisclaimer);
          this.disclaimerSpinner = false;
          this.showDisclaimer = true;
        }

        if (this.companyData.cpfCnpj !== Util.removeMask(this.beneficiaryData.beneficiary.num_cpf_cnpj) && typePerson === 'PJ') {
          arrDisclaimer = r.data.find((r: any) => r.tipoTitularidade === 'OUTRA_TITULARIDADE_PJ' && r.tipoTransacao === this.typeTransaction);
          this.disclaimerData = Array(arrDisclaimer);
          this.disclaimerSpinner = false;
          this.showDisclaimer = true;
        }

        if (this.companyData.cpfCnpj !== Util.removeMask(this.beneficiaryData.beneficiary.num_cpf_cnpj) && typePerson === 'PF') {
          arrDisclaimer = r.data.find((r: any) => r.tipoTitularidade === 'OUTRA_TITULARIDADE_PF' && r.tipoTransacao === this.typeTransaction);
          this.disclaimerData = Array(arrDisclaimer);
          this.disclaimerSpinner = false;
          this.showDisclaimer = true;
        }
      }

      // setar limite novo Beneficiário
      if (this.typeTransfer !== 'favorite') {

        if (this.ownership === 'same') {
          // SETA LIMITE DE ACORDO COM A PESSOA DA MESMA TITULARIDADE
          if (this.companyData.tipo_pessoa === 'PJ') {
            arrDisclaimer = r.data.find((r: any) => r.tipoTitularidade === 'MESMA_TITULARIDADE' && r.tipoTransacao === this.typeTransaction);
            this.disclaimerSpinner = false;
            this.disclaimerData = Array(arrDisclaimer);
            this.showDisclaimer = true;
          }

          if (this.companyData.tipo_pessoa === 'PF') {
            arrDisclaimer = r.data.find((r: any) => r.tipoTitularidade === 'MESMA_TITULARIDADE' && r.tipoTransacao === this.typeTransaction);
            this.disclaimerSpinner = false;
            this.disclaimerData = Array(arrDisclaimer);
            this.showDisclaimer = true;
          }
        }

        if (this.ownership === 'other') {
          
          const typePerson = Util.removeMask(this.outraTitularidade.num_cpfcnpj).length > 11 ? 'PJ' : 'PF';

          if (this.companyData.cpfCnpj !== this.outraTitularidade.num_cpfcnpj && typePerson === 'PJ') {
            arrDisclaimer = r.data.find((r: any) => r.tipoTitularidade === 'OUTRA_TITULARIDADE_PJ' && r.tipoTransacao === this.typeTransaction);
            this.disclaimerData = Array(arrDisclaimer);
            this.disclaimerSpinner = false;
            this.showDisclaimer = true;
          }

          if (this.companyData.cpfCnpj !== this.outraTitularidade.num_cpfcnpj && typePerson === 'PF') {
            arrDisclaimer = r.data.find((r: any) => r.tipoTitularidade === 'OUTRA_TITULARIDADE_PF' && r.tipoTransacao === this.typeTransaction);
            this.disclaimerData = Array(arrDisclaimer);
            this.disclaimerSpinner = false;
            this.showDisclaimer = true;
          }
        }
      }
    });



  }

  determinePeriod(start, end): boolean {
    let hourStart = Number(start.split(':')[0]) * 60 + Number(start.split(':')[1]);
    let hourEnd = Number(end.split(':')[0]) * 60 + Number(end.split(':')[1]);

    let nowHour = new Date().toLocaleTimeString("pt-BR", { timeZone: "America/Sao_Paulo" });

    let time = Number(nowHour.split(':')[0]) * 60 + Number(nowHour.split(':')[1]);

    return time >= hourStart && time < hourEnd;
  }

  verifyOwnership(val) {

    let cnpjCpf = Util
  
    if (val[0]) {
      return
    }

    if (val.beneficiary) {
      return
    }
  }

  verifyOwnershipSame(val) {
    let pfPj = val.cpfCnpj.length <= 11 ? 'PF' : 'PJ';
    if (pfPj === 'PF') {
      return 'mesmaTitularidadePF';
    }
    if (pfPj === 'PJ') {
      return 'mesmaTitularidadePJ';
    }
  }

  formatHour(hr){
    let combineHour = hr;
    combineHour = `${combineHour.split(':')[0]}:${combineHour.split(':')[1]}`;
    return combineHour;
  }

  requestAccountInput(){
    this.requestAccount.pipe(
      debounceTime(1500),
      distinctUntilChanged())
      .subscribe(value => {
        if(value === ''){
          this.accountFibra = undefined;
          return
        }
        this.getAccount(value)
      });
  }
}
