import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import {stringify} from 'querystring';

const cripto = CryptoJS;

@Injectable({
  providedIn: 'root',
})

export class SessionObjectDataShare {
  public sessionObject: any;

  public dataToShare = new BehaviorSubject(this.sessionObject);
  dataShared = this.dataToShare.asObservable();

  constructor() {}

  setSessionobject(data: any) {
    this.dataToShare.next(data);
    const newData = cripto.enc.Base64.parse(data);
    sessionStorage.setItem('session', stringify(newData));
  }
  getSessionobject() {
    let data = [];
    this.dataShared.subscribe(
        sessionObject => data = sessionObject,
        error => data = null);
    return data;
  }
}
