import { Injectable } from '@angular/core';
import { TokenRepository } from '@src/core/repositories/token/token.repository';
import { UseCase } from '@src/core/base/use-case';
import { TokenTypeModel } from '@src/core/domain/token/tokenType.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetTokenUsecase implements UseCase<any, TokenTypeModel> {

  constructor(private tokenRepository: TokenRepository) { }

  execute(): Observable<TokenTypeModel> {
    return this.tokenRepository.getToken();
  }
}

