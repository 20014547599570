import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'fibra-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
})
export class OpportunityComponent implements OnInit {
  public params: any = {};

  constructor(public analytics: AngularFireAnalytics) {}

  ngOnInit() {
    this.getInformationAccount();
  }


  async getInformationAccount() {
    this.params = {
      id: await this.getCompanyLocalStorage().value,
      name: await this.getCompanyLocalStorage().name,
      type: await this.getCompanyLocalStorage().tipo_pessoa,
      num_cpf_cnpj: await this.getCompanyLocalStorage().cpfCnpj,
    }
    this.analytics.logEvent('click_oportunidade', this.params);
  }

  getCompanyLocalStorage() {
    return JSON.parse(localStorage.getItem('batch-company-name'));
  }
}
