import { AccompanimentEntity } from '@src/data/repository/dashboard/accompaniment.entity';
import { Mapper } from '@src/core/base/mapper';
import { AccompanimentModel } from '@src/core/domain/dashboard/dashboard.model';

export class AccompanimentRepositoryMapper extends Mapper<
  AccompanimentEntity,
  AccompanimentModel
> {
  mapFrom(param: AccompanimentEntity): AccompanimentModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: AccompanimentModel): AccompanimentEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
