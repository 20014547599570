<div class="content-page">
  <div
    class="d-flex justify-content-center align-items-center position loading-container loadingView"
    *ngIf="!loading"
  >
      <div class="spinner-border text-primary" role="status">
    </div>
  </div>
  <div class="body-content" *ngIf="loading">
      <div class="wraper-list-value col-md-12 background-color-white" style=" height: 515px;" >
        <table class="table">
          <thead>
            <tr>
              <th>
                <div class="text">
                  Produto
                </div>
              </th>
              <th>
                <div class="text" (click)="sortList('valor_solicitado')">
                  Valor Solicitado
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" style="cursor: pointer;"/>
                  </span>
                </div>
              </th>
              <th>
                <div class="text" (click)="sortList('dt_credito')" >
                  Data de Solicitação
                  <span class="icon" style="cursor: pointer;">
                    <img src="/assets/svg/sort-list.svg" />
                  </span>
                </div>
              </th>
              <th>
                <div class="text" (click)="sortList('situacao')">
                  Status
                  <span class="icon">
                    <img src="/assets/svg/sort-list.svg" style="cursor: pointer;"/>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody *ngFor="let item of list; index as i">
            <tr data-toggle="collapse" attr.data-target="#collapse_{{i}}" class="accordion-toggle">
              <td>
                <label> {{item.produto_nome}} </label>
              </td>
              <td>
                <label>  R${{ item.valor_solicitado || 0 | number: "1.2-2" }}</label>
              </td>
              <td>
                <label> {{ item.dt_credito | date: 'dd/MM/yyyy' }} </label>
              </td>
              <td>
                <div 
                *ngIf="item.situacao == 1 ||
                          item.situacao == 2 ||
                          item.situacao == 3 ||
                          item.situacao == 4" 
                >
                  <img src="/assets/svg/yellow-dot.svg"/>
                  <span>
                    Em análise
                  </span>
                </div>
                <div
                  *ngIf="item.situacao == 5 ||
                  item.situacao == 8" 
                >
                  <img src="/assets/svg/red-dot.svg"/>
                  <span>
                    Não aprovado
                  </span>
                </div>
                <div
                  *ngIf="item.situacao == 7" 
                >
                  <img src="/assets/svg/green-dot.svg"/>
                  <span>
                    Aprovado
                  </span>
                </div>
                <div
                  *ngIf="item.situacao == 6" 
                >
                  <img src="/assets/svg/yellow-dot.svg"/>
                  <span>
                    Em formalização
                  </span>
                </div>
              </td>
              <td >
                <span 
                  class="item-icon" 
                  (click)="toogleIconArrow(i)" 
                  attr.href="{{ '#collapse' + i }}" 
                  data-toggle="collapse" 
                  data-target="#collapseOne" 
                  aria-expanded="true" 
                  aria-controls="collapseOne">
                  <img 
                    src="/assets/svg/arrow_down.svg" 
                    id="{{ i }}"
                    /> 
                </span>
              </td>
            </tr>
            <tr id="collapse_{{i}}" class="accordian-body collapse" style="background-color: #F8F9FA; ">
              <td colspan="7" style=" height: 101px;">
                <div class="row" style="justify-content: center; align-items: center; border: none;">
                  <div class="body-resumo">
                    <div id="product-resumo-content">
                      <div class="header-body-resumo">
                        <!-- TODO: ADICIONAR ICONE DO BANCO-->
                        <div class="header-title-resumo">
                          <span>
                            Resumo
                          </span>
                          <button
                            type="button"
                            class="button-pdf"
                            (click)="generatePDF(item.cod_simulacao)"
                          >
                            Baixar PDF
                          </button>
                        </div>
                        <img [src]="'/assets/png/logo-receipt.png'" />
                      </div>

                      <div>
                        <label class="label-td" style="padding-left: 16px; padding-bottom: 24px;"> Conta selecionada para receber o crédito </label>
                        <br/>
                        <label class="label-td-value" style="padding-left: 16px; padding-bottom: 40px;"> {{item.conta.num_conta}} - {{item.conta.desc_conta}} </label>
                      </div>
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>
                              <label class="label-td"> Valor Solicitado </label>
                            </td>
                            <td>
                              <label class="label-td">Prazo Total</label>
                            </td>
                            <td>
                              <label class="label-td">
                                Carência do principal</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label class="label-td-value"> R${{ item.valor_solicitado || 0 | number: "1.2-2" }} </label>
                            </td>
                            <td>
                              <label class="label-td-value" *ngIf="item.num_parcelas > 1">{{item.num_parcelas}} meses</label>
                              <label class="label-td-value" *ngIf="item.num_parcelas == 1">{{item.num_parcelas}} mês</label>
                            </td>
                            <td>
                              <label class="label-td-value" *ngIf="item.num_carencia == 0">Sem Carência</label>
                              <label class="label-td-value" *ngIf="item.num_carencia > 1">{{item.num_carencia}} dias</label>
                              <label class="label-td-value" *ngIf="item.num_carencia == 1">{{item.num_carencia}} dia</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label class="label-td" style="margin-top: 40px;"> Indexador </label>
                            </td>
                            <td>
                              <label class="label-td" style="margin-top: 40px;">Taxa Anual</label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label class="label-td-value" *ngIf="item.indexador_id == 1"> Pré-Fixado </label>
                              <label class="label-td-value" *ngIf="item.indexador_id == 2"> Pós-Fixado: 100% CDI </label>
                            </td>
                            <td>
                              <label class="label-td-value">{{item.valor_taxa_mensal | number: "1.3-3"}} %</label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="body-details">
                        <p class="product-details-simulation">
                          <span class="bold-simulation">
                            Detalhes da simulação
                          </span>
                        </p>
                        <p class="product-details-txt">
                          <span class="bold">
                            Valor Liquido
                          </span>
                          <span class="bold-value">
                            R${{ item.valor_liquido || 0 | number: "1.2-2" }}
                          </span>
                        </p>
                        <a class="product-title-parcelas" (click) = "parcelas(item.cod_simulacao)" *ngIf="item.num_parcelas > 1">
                          Fluxo de parcelas   ({{ item.num_parcelas }} parcelas{{item.indexador_id == 2 ? '*' : ''}})
                        </a>
                        <a class="product-title-parcelas" (click) = "parcelas(item.cod_simulacao)" *ngIf="item.num_parcelas == 1">
                          Fluxo de parcelas   ({{ item.num_parcelas }} parcela{{item.indexador_id == 2 ? '*' : ''}})
                        </a>
                        <p class="product-details-txt" style="padding-top: 40px;">
                          <span class="bold">
                            IOF
                          </span>
                          <span class="bold-value">
                            R${{ item.valor_iof || 0 | number: "1.2-2" }}
                          </span>
                        </p>
                        
                        <p class="product-details-txt">
                          <span class="bold">
                            CET
                          </span>
                          <span class="bold-value">
                            {{ item.valor_cet | number: "1.3-3"}}%
                          </span>
                        </p>
                        <p class="product-details-txt">
                          <span class="bold">
                            Data prevista do Crédito
                          </span>
                          <span class="bold-value">
                            {{ item.dt_credito | date: 'dd/MM/yyyy' }}
                          </span>
                        </p>
                        <p class="product-details-txt">
                          <span class="bold">
                            Primeiro vencimento
                          </span>
                          <span class="bold-value">
                            {{ item.dt_primeiro_vencimento | date: 'dd/MM/yyyy' }}
                          </span>
                        </p>
                        <p class="product-details-txt">
                          <span class="bold">
                            Ultimo vencimento
                          </span>
                          <span class="bold-value">
                            {{ item.dt_ultimo_vencimento | date: 'dd/MM/yyyy' }}
                          </span>
                        </p>
                        <!-- <p class="product-details-txt" *ngIf = "item.num_carencia > 0">
                          <span class="bold">
                            Periodo de Carência de Pagamento do Principal
                          </span>
                          <span class="bold-value">
                            sem valor ainda
                          </span>
                        </p> -->
                        <p class="product-cdi-txt" *ngIf="item.indexador_id == 2">
                          <span class="bold">
                            * A ser acrescido do Índice Pós-Fixado estipulado no campo Taxa Anual. 
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <!-- <div class="border-table"></div> -->
        </table>
      </div>
  </div>
  <div class="footer-table-solicitacao">
    <img src="/assets/png/infogray-icon-info.png" style="padding-right: 8px;"/>
    Exibindo somente as solicitações de contratação realizadas nos últimos 7 dias.
  </div> 
</div>
<fibra-modal-parcelas-produtos
  #content
  (closeEvent)="closeModalParc($event)"
  [retornoParc]="parcelasList"
  [indexParc]="parcelasIndexador"
  *ngIf="showModalParcelas"
>
</fibra-modal-parcelas-produtos>
