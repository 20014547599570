<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div class="content-page">
  <div class="section">
    <div  class="margin-40-right">
        <img src="/assets/opportunity/svg/icon-seguros.svg" alt="icon-seguros">
        <h1>Fibra Corretora 
          de Seguros</h1>
        <p>Soluções em seguros com atendimento ágil, humanizado e transparente para proteger o seu negócio!</p>
        <button (click)="isProduct()">Conhecer produtos</button>
    </div>
    <div>
      <img src="/assets/opportunity/img/img-header-seguros.jpg" alt="img-header-seguros">
    </div>
  </div>  
  <div class="section margin-top-80">
    <div>
      <img src="/assets/opportunity/img/img-woman-seguros.jpg" alt="img-woman">
    </div>
    <div class="padding-40-left">
      <h3>Quem somos</h3>
      <p class="font-16px">Atuamos em parceria com outras empresas do segmento oferecendo uma efetiva consultoria em gestão de seguros e riscos, e as melhores soluções de proteção com precisão, velocidade e em sintonia com suas necessidades.</p>
      <p class="font-16px">Pertencemos ao Grupo Vicunha, um dos maiores grupos industriais do pais, do qual são integrantes a Vicunha Têxtil e a Companhia Siderúrgica Nacional (CSN), dentre outras empresas.</p>
      <button (click)="isInterested()">Saiba mais</button>
    </div>
  </div>  
  <div class="section-benefities align-center margin-top-80">
    <div class="square">
      <img src="/assets/opportunity/svg/opportunity-icon-people.svg" alt="opportunity-icon-people">
      <span #product>Nosso propósito é solucionar <a>o agora</a> para as pessoas que fazem o <a>amanhã.</a></span>
    </div>
  </div>
  <div class="section-benefities margin-top-80">
      <h2>Conheça nossas soluções</h2>
      <div class="content-component"> 
        <div *ngFor="let solution of soluctions" class="warpper-single">
          <img [src]="solution.img" alt="seguro">
          <h1>{{ solution.title }}</h1>
          <div class="description">
            <p *ngFor="let item of solution.description; let i = index">
              {{ item }}
            </p>
          </div>
        </div>
      </div>
      <div class="anchor" #interested></div>
  </div> 
  <fibra-opportunity-form></fibra-opportunity-form>
  <div class="line margin-top-80"></div> 
  <fibra-opportunity-products-list></fibra-opportunity-products-list>
</div>
