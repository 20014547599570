<div class="content-approval" *ngIf="!showTerm">
    <div class="content-options">
        <div class="rounded-circle"></div>
        <div class="options-div">
            <span class="option-selected" style="margin-right: 17px; margin-left: 16px;" (click)="filterTerms('SEM-TERMO');changeFilterClass('adesao')"
                id="adesao"> Contas sem adesão</span>
            <div class="separator"></div>
            <span class="option-unselected" style="margin-right: 17px; margin-left: 16px;" (click)="filterTerms('PENDENTE');changeFilterClass('pendente')"
                id="pendente"> Pendentes <span class="notif" *ngIf="notif"></span></span>
            <div class="separator"></div>
            <span class="option-unselected" style="margin-left: 16px;" (click)="filterTerms('APROVADO');changeFilterClass('aprovado')"
                id="aprovado"> Aceitos</span>
            <div class="separator"></div>
            <span class="option-unselected" style="margin-left: 16px;" (click)="filterTerms('REPROVADO');changeFilterClass('reprovado')"
                id="reprovado"> Recusados</span>
        </div>
    </div>

    <div class="" style="margin-top: 29px;" *ngIf="!loading && !hasError && filterByAcc && !showContent">
        <span *ngIf="showlabel" class="title-account" style="margin-left:29px;">Selecione uma conta para continuar
        </span>
        <div class="row accounts" *ngIf="accountsWithoutMembership">
            <div class="card-account mx-3 col-3" (click)="verTermo(item, item.num_conta, item.desc_conta, true, 0)" *ngFor="let item of accountsWithoutMembership">
                <span>{{item.num_conta}} - {{item.exibicao}}</span>
            </div>

        </div>
        <div class="row d-flex justify-content-center alert-terms " *ngIf="showCardSucess">
            <div class="col-4">
                <img src="assets/png/iconConfirm.png" alt="" class="img-fluid">
                <p class="alert-title" style="color: #73B599;"> Adesão ao Pix aprovada</p>
                <p class="alert-text">Agora você e os outros operadores já podem fazer tranferências ou cadastrar uma chave
                    para a conta {{selectedAccount?.num_conta}} - {{selectedAccount?.desc_conta}}.</p>
            </div>

            <div class="col-12 ">
                <button class="btn-secondary" (click)="goTransf()">Fazer uma transferência
                </button>
            </div>
            <div class="col-12 ">
                <button class="btn-secondary" (click)="goKeys()">Cadastrar uma chave</button>
            </div>
            <div class="col-12">
                <button class="btn-primary" (click)="goBack();getAccountsWithoutMembership();">Voltar</button>

            </div>
        </div>
        <div class="row d-flex justify-content-center alert-terms " *ngIf="AlertCard">
            <div class="col-4">
                <img src="assets/png/icon-pending.png" alt="" class="img-fluid">
                <p class="alert-title"> {{alertTitle}}</p>
                <p class="alert-text">{{alertText}}</p>
            </div>

            <div class="col-12 " *ngIf="AlertCard">
                <button class="btn-secondary" (click)="goBack();getAccountsWithoutMembership()">Voltar</button>
            </div>
            <div class="col-12" *ngIf="AlertCard">
                <button class=" btn-primary" (click)="filterTerms('PENDENTE');changeFilterClass('pendente')">Acompanhar status
                </button>
            </div>
        </div>
    </div>
    <div class="keys-content" *ngIf="!loading">
        <div class="cards" *ngIf="termsDataFiltered && !filterByAcc && showCards">
            <div class="card-item" *ngFor="let key of termsDataFiltered">
                <div class="status">
                    <span class="status-font"> Status</span>
                    <div *ngIf="key.status === 'PENDENTE'">
                        <div *ngIf="isPeddingotherMaster(key.aprovacoes) else othersMasters">
                            <div class="{{key.circleClass}}"> </div>
                        </div>
                    </div>
                    <div *ngIf="key.status !== 'PENDENTE'">
                        <div class="{{key.circleClass}}"> </div>
                    </div>
                    <div>
                        <ng-template #othersMasters>
                            <span class="icon-alert">
                                <span class="icon-info notification" [appTooltip]="tooltipAll">
                                    <img src="/assets/svg/tooltip-alert-orange.svg" style="margin-right: 4px !important;" alt="logo" />
                                    <div #tooltipAll class="tooltip-custom">
                                        <p style="font-size: 14px; margin-left: 15px">Aguardando aprovação</p>
                                        <div class="masters">
                                            <div class="masters-title">
                                                <img src="/assets/svg/marcador-icon.svg" style="margin-right: 4px !important;">
                                                <span class="master-font"> Masters </span>
                                            </div>

                                            <span class="master-sub" *ngFor="let mast of key.aprovacoes">
                                                <span *ngIf="mast.status === 'PENDENTE' && mast.obrigatoria" style="margin-left: 16px;">{{mast.pessoa.nome_pessoa}}</span>
                                            </span>
                                        </div>
                                        <div class="arrow"></div>
                                    </div>
                                </span>
                            </span>
                        </ng-template>


                    </div>
                    <span class="{{key.statusClass}}"> {{key.statusMessage}}</span>
                </div>
                <div class="card-content">
                    <div class="account" *ngIf="key.conta">
                        <span class="acc-title"> Conta</span>
                        <span class="acc-value"> {{key.conta.numero}} - {{key.conta.exibicao}}</span>
                    </div>
                    <div class="account" *ngIf="key.status === 'REPROVADO'">
                        <span class="acc-title"> Recusado pelo(s) master(s)</span>
                        <span class="acc-value" *ngFor="let item of key.aprovacoes"><span *ngIf="item.status === 'REPROVADO'">{{item.pessoa.nome_pessoa}}</span></span>
                    </div>
                    <div *ngIf="key.status === 'PENDENTE'">
                        <div class="card-options">
                            <div *ngFor="let item of key.aprovacoes">
                                <div *ngIf="item.status === 'PENDENTE' && userNanme === item.pessoa.email && item.pode_aprovar">
                                    <img src="/assets/svg/fechar.svg" alt="fechar" style="margin-right: 32px; cursor: pointer;" (click)="openModal(contentReprove);getTermoId(key, true)">
                                    <img src="/assets/svg/aprovar.svg" alt="aprovar" style="cursor: pointer" (click)="openModal(content);getTermoId(key, true)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center alert-refused" *ngIf="cardRefused ">
            <div class="col-4">
                <img src="assets/png/icon-error.png" alt="" class="img-fluid">
                <p class="alert-title"> Adesão ao Pix recusada</p>
                <p class="alert-text">Você recusou a adesão ao Termo para a conta {{selectedAccount.numero}} - {{selectedAccount.desc_conta}}.
                </p>
            </div>

            <div class="col-12 ">
                <button class="btn-primary" (click)="goBack();filterTerms('PENDENTE');changeFilterClass('pendente');getTermsData()">Voltar</button>
            </div>
            <!-- <div class="col-12" *ngIf="AlertCard">
                <button class=" btn-primary">Acompanhar status</button>
            </div> -->
        </div>
        <div class="row d-flex justify-content-center alert-terms" *ngIf="cardPedding">
            <div class="col-4">
                <img src="assets/png/icon-pending.png" alt="" class="img-fluid">
                <p class="alert-title">Adesão ao Pix pendente</p>
                <p class="alert-text"> A adesão ao Termo para a conta {{selectedAccount.numero}} - {{selectedAccount.desc_conta}}
                    está pendente de aprovaçao dos outros masters.</p>
            </div>

            <div class="col-12 ">
                <button class="btn-secondary" (click)="goBack();filterTerms('PENDENTE');changeFilterClass('pendente')">Voltar</button>
            </div>
            <div class="col-12">
                <button class=" btn-primary" (click)="goBack();filterTerms('APROVADO');changeFilterClass('aprovado')">Acompanhar
                    status
                </button>
            </div>
        </div>
        <div class="row d-flex justify-content-center alert-terms " *ngIf="showCardSucess && !isConfirmad ">
            <div class="col-4">
                <img src="assets/png/iconConfirm.png" alt="" class="img-fluid">
                <p class="alert-title" style="color: #73B599;"> Adesão ao Pix aprovada</p>
                <p class="alert-text">Agora você e os outros operadores já podem fazer tranferências ou cadastrar uma chave
                    para a conta {{selectedAccount?.num_conta}} - {{selectedAccount?.desc_conta}}.</p>
            </div>
            <div class="col-12 ">
                <button class="btn-secondary" (click)="goTransf()">Fazer uma transferência
                </button>
            </div>
            <div class="col-12 ">
                <button class="btn-secondary" (click)="goKeys()">Cadastrar uma chave</button>
            </div>
            <div class="col-12">
                <button class="btn-primary" (click)="goBack();filterTerms('PENDENTE');changeFilterClass('pendente')">Voltar</button>

            </div>
        </div>
        <div class="nodata-content" *ngIf="!termsDataFiltered.length && !showContent">
            <span class="info-data"> {{noDataMessage}}</span>
        </div>
    </div>

    <div class="keys-content" *ngIf="loading">
        <div class="d-flex justify-content-center position loading-container">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="has-content" *ngIf="hasError && !loading" style="margin-top: 100px;">

        <fibra-generic-info type="error" title="Ocorreu um erro inesperado" message="{{errorMessage}}">
        </fibra-generic-info>
    </div>

</div>
<div class="content-terms" *ngIf="showTerm">
    <fibra-pix-term [firstTime]="firstTime" (acceptPixTerms)="acceptTermByComponent($event)" (closeEvent)="closeModal($event)"
        (response)="responseApi($event)" [accountData]="selectedAccount" (setTerm)="changeTerm($event)">
    </fibra-pix-term>
</div>
<div class="modal">
    <ng-template #content let-modal>
        <div class="conteudo-modal">
            <div class="generic-info">
                <img src="/assets/png/icon-pending.png" style="width: 29px;height: 29px;" />
                <span class="info-title">Aceite de termos de adesão ao Pix</span>
                <span class="generic-info-message">Para aprovar o cadastro da chave, você precisa aceitar o Termo de Adesão
                    ao Pix.</span>
            </div>
            <div class="buttons">
                <button type="button" class="button-secondary" (click)="verTermo(selectedAccount, selectedAccount.numero, selectedAccount.desc_conta, false, idTerm)">
                    Ver Termo
                </button>
                <button type="button" class="button-primary" (click)="approverdPeddingTerms()"> Aceitar Termo </button>
            </div>
            <div class="info-div">
                <img class="info-img" src="/assets/svg/icon-info-blue.svg"> <span class="info-font"> Ao tocar no botão Aceitar
                    Termo, você concorda com o Termo de Adesão ao Pix do Banco Fibra.</span>
            </div>
        </div>

    </ng-template>
</div>

<div class="modal">
    <ng-template #contentReprove let-modal>
        <div class="conteudo-modal-reprovar">
            <div class="generic-info">
                <span class="info-title">Recusar termo de adesão Pix</span>
                <span class="generic-info-message">Você gostaria mesmo de recusar o termo de adesão para a conta: <bold>
                        {{selectedAccount.numero}} - {{selectedAccount.desc_conta}}</bold></span>
            </div>
            <div class="buttons">
                <button type="button" class="button-secondary" (click)="closeModalApi($event)">
                    Não
                </button>
                <button type="button" class="button-primary" (click)="refusedTerm()"> Sim </button>
            </div>
        </div>

    </ng-template>
</div>

<div class="row d-flex justify-content-center alert-terms " *ngIf="showContent">
  <div class="col-5">
    <img src="assets/png/icon-pending.png" alt="" class="img-fluid">
    <p class="alert-title"> Aceite ao Termo do Pix pendente</p>
    <p class="alert-text" style="border-bottom: 1px solid #D9DBE3; padding-bottom: 16px">Enviada para aprovação do(s) outro(s) master(s) da sua empresa.</p> 
    <img src="/assets/svg/info.svg" style="width: 16px;
      height: 16px; margin-top: 16px;">
    <p class="warnings"> Já enviamos uma notificação para ele. Assim que ele aceitar o termo, você receberá um aviso e poderá
        fazer a transação normalmente.</p>
  </div>
  <div class="col-12">
    <button class="btn-secondary" (click)="returnContentFalse()">Voltar</button>
  </div>
</div>

<fibra-modal-validation #content (responseValidation)="responseApiModal($event)" *ngIf="showModalValidation" [apiCallback]="apiCallback"
    [apiPayload]="payload" (closeEvent)="closeModalApi($event)">
</fibra-modal-validation>

<fibra-toast-error-message color="#ED6C6C"></fibra-toast-error-message>