import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private pf: boolean = false;
  private pj: boolean = false;
  constructor() { }

  public isPf(){
    return this.pf;
  }

  public isPj(){
    return this.pj;
  }

  public setType(tipo: string){
    if (tipo === 'PF'){
      this.pf = true;
      this.pj = false;
    }
    else if (tipo === 'PJ') {
      this.pf = false;
      this.pj = true;
    }
  }

}
