import { Component, OnInit, ViewChild, EventEmitter  } from '@angular/core';
import {
  DebitAccountDataShare,
  TransferConfirmDataShare,
  TransferReceiptDataShare,
} from '@src/data/repository/data-share-repository';
import * as Util from '@src/shared/util-common';
import { TransferReceiptDataRepository } from '@src/data/repository/receipt-repository/receipt.repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-page-transfer-receipt',
  templateUrl: './transfer-receipt.component.html',
  styleUrls: ['./transfer-receipt.component.scss'],
})
export class TransferReceiptComponent implements OnInit {
  public btnLabel = 'Transferir'; // Transferir || Confirmar agendamento
  public beneficiaryData;
  public dataConfirm;
  public bank;
  public dateCompare = false;
  public textReceipt;
  public textDate;
  public typeMessage;
  public currentDate;
  public agend;
  public typToastColor;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  removeMask = (value) => Util.removeMask(value);
  public generatePDF = (value?) => Util.generatePDF(value);

  constructor(
    private shareTransferReceipt: TransferReceiptDataShare,
    private shareDebit: DebitAccountDataShare,
    private shareTransferConfirm: TransferConfirmDataShare,
    private receiptService: TransferReceiptDataRepository,
  ) {
    this.textReceipt = 'Comprovante de transferência';
    this.textDate = 'Data da transferência';
  }

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  ngOnInit() {
    this.shareTransferReceipt.dataShared.subscribe((res) => {
      res.transferInformation
        .get('dateTrasfer')
        .setValue(
          this.resolveDate(res.transferInformation.get('dateTrasfer').value)
        );
      this.beneficiaryData = res;
    });
    this.shareTransferConfirm.dataShared.subscribe((res) => {
      this.dataConfirm = res;
      this.resolveMessageConfirm(res.etapaTransferencia);
    });
    this.shareDebit.dataShared.subscribe((res) => {
      this.bank = res;
    });
    this.currentDate = new Date();
  }

  ngOnDestroy() {
    this.shareDebit.clear();
    this.shareTransferConfirm.clear();
    this.shareTransferReceipt.clear();
  }
  public resolveDate(date) {
    this.dateCompare = Util.compareDate(date);
    this.changeDateCompare(this.dateCompare);
    return date;
  }

  changeDateCompare(compare?) {
    if (compare) {
      this.textReceipt = 'Comprovante de transferência agendada';
      this.textDate = 'Data agendada';
    }
  }

  public resolveMessageConfirm(value) {
    switch (value) {
      case 0:
        this.typeMessage = 'Transferência agendada';
        this.agend = true;
        break;
      case 1:
        this.typeMessage = 'Transferência agendada';
        this.agend = true;
        break;
      case 5:
        this.typeMessage = 'Transferência realizada';
        this.agend = false;
        break;
    }
  }

  public dowloadFile(id) {

    this.receiptService.getPdf(id).subscribe((data: any) => {
      var oReq = new XMLHttpRequest();
      oReq.open("GET", data['data'].url, true);
      oReq.responseType = 'blob';
      oReq.onload = function (event) {
        let blob = new Blob([oReq.response], { type: 'application/pdf' });
        var file = URL.createObjectURL(blob);
        // window.open(file);
        // window.URL.createObjectURL(blob)
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = data['data'].nome_arquivo;
        // start download
        a.click();
      }
      this.typToastColor = this.GREENCOLOR;
      this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
      oReq.send();
    })
  }
}
