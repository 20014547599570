import { Injectable } from '@angular/core';
import { DuplicateBillModelRepository } from '@src/core/repositories/charge/duplicate-bill.repository';
import { UseCase } from '@src/core/base/use-case';
import { DuplicateBillModel, DuplicateBillRequest } from '@src/core/domain/charge/duplicate-bill.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetDuplicateBillUsecase implements UseCase<DuplicateBillRequest, DuplicateBillModel> {

  constructor(private duplicateBillRepository: DuplicateBillModelRepository) { }

  execute(params: DuplicateBillRequest): Observable<DuplicateBillModel> {
    return this.duplicateBillRepository.getDuplicateBill(params);
  }
}
