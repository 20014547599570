import {LoginEntity, VerifyMasterEntity} from '@src/data/repository/login-repository/login.entity';
import { LoginMobileTypeModel } from '@src/core/domain/login/loginMobileType.model';
import { Mapper } from '@src/core/base/mapper';

export class LoginRepositoryMapper extends Mapper<LoginEntity, LoginMobileTypeModel> {
  mapFrom(param: LoginEntity): LoginMobileTypeModel {
    return param;
  }

  mapTo(param: LoginMobileTypeModel): LoginEntity {
    return param;
  }
}
