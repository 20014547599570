<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <div class="main-divisor"></div>

</div>

<div class="content-page" id="tela">
  <fibra-breadcrumb height="280" [infoAccount]="routAccont" roule="Pagamento em lote" [downloadDoc]="false"
    (infoReport)="exportScreen($event)"></fibra-breadcrumb>
  <div class="title_back">
    <a class="back" routerLink="/payment-batch">
      <svg width="36" height="12" viewBox="0 0 36 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23743 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46446 6.59619 0.98959 6.3033 0.696697C6.01041 0.403803 5.53553 0.403803 5.24264 0.696696L0.469669 5.46967ZM36 5.25L1 5.25L1 6.75L36 6.75L36 5.25Z"
          fill="black" />
      </svg>
      Voltar
    </a>
    <h2 class="title-Name">{{nomeArquivo}}</h2>
  </div>

  <div class="detalheArquivo">
    <table class="table tabela-responsiva">
      <thead class="table-header">
        <tr>
          <th scope="col" *ngFor="let titleheader of option">
            {{ titleheader.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <div *ngFor="let item of detailsListLiquidado; let i = index" style="display: table-row" display="relative">
          <td scope="row" class='table-aline'>{{ formatDate(item.dt_transacao) }}</td>
          <td class='table-aline'>
            <ngb-highlight [result]="item.tipo_transacao" [term]="filter.value"></ngb-highlight>
            <p>{{item.NomeBeneficiario}}</p>
          </td>
          <td class='table-aline'>
            <ngb-highlight [result]="item.num_conta" [term]="filter.value"></ngb-highlight>
          </td>
          <td class='table-aline'>
            <ngb-highlight [result]="item.num_cpf_cnpj" [term]="filter.value"></ngb-highlight>
          </td>
          <td class='table-aline'>
            <ngb-highlight [result]="item.vlr_transacao | currency: 'BRL'" [term]="filter.value"></ngb-highlight>
          </td>
          <td class="util table-aline col-status" (click)="toggleStatus(item)">
            <div class="status-col">
              <div class="circle green" *ngIf="item.desc_status === 'LIQUIDADO'"></div>
              <div class="circle red" *ngIf="item.desc_status !== 'LIQUIDADO'"></div>
              <ngb-highlight [result]="item.desc_status === 'LIQUIDADO' ? 'Liquidado' : 'Recusado'" [term]="filter.value"></ngb-highlight>
              <img src="/assets/svg/dropdown.svg" class="right-arrow" alt="Seta para dropdown" 
                *ngIf="item.desc_status !== 'LIQUIDADO' && !item.isToggled"/>
              <img src="/assets/svg/dropdown.svg" class="right-arrow rotate" alt="Seta para dropdown"
                *ngIf="item.desc_status !== 'LIQUIDADO' && item.isToggled"/>
                <div class="desc-status-recusado" *ngIf="item.desc_status !== 'LIQUIDADO' && item.isToggled">{{item.desc_status}}</div>
            </div>
          </td>
        </div>
      </tbody>
    </table>
  </div>


</div>