import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComexReportDataShare } from '@src/core/usecases/data-share/comex-report-data-share.service';
import { CobrancaEntity, CreditoEntity, FinimpEntity } from '@src/data/repository/import-repository/import.entity';
import { ImportDataRepository } from '@src/data/repository/import-repository/import.repository';

@Component({
  selector: 'fibra-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit, OnDestroy {
  public loading = false;
  public noData = false;
  public contractNum = '';
  public filterByContract = false;
  public filterBySer = true;
  public isFin = true;
  public isCob = false;
  public isCar = false;
  public hasError = false;
  public fieldRequired = false;
  public selectedFilter: string;
  public radioSelected = '';
  public filterForm: FormGroup;
  public serDate = new Date();
  public finimpArray: FinimpEntity[];
  public cobrancaArray: CobrancaEntity[];
  public creditArray: CreditoEntity[];
  public periods = [
    { name: 'FINIMP', value: 'fin' },
    { name: 'Cobrança', value: 'cob' },
    { name: 'Carta de crédito', value: 'carta' },
  ];

  constructor(private formBuilder: FormBuilder,
    private importService: ImportDataRepository,
    public comexReport: ComexReportDataShare) {
    this.filterBySer = true;
    this.filterForm = this.formBuilder.group({
      filter: ['ser'],
      serDate: [this.serDate.toLocaleDateString()],
      contractNum: ['']
    });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('excel_info')
  }

  ngOnInit() {
    this.selectedFilter = 'fin';
    this.radioSelected = 'ser';
    this.sendImportData();
  }

  public getPDFNotes(contrato: string, tipo: string) {
    this.importService.getPDF(contrato, tipo).subscribe((data) => {

      data.data.forEach(element => {
        window.open(element.url);
      });
     
    })
  }




  public changeFilterType(type: string) {
    if (type === 'ser') {
      this.filterBySer = true;
      this.filterByContract = false;
      this.fieldRequired = false;
      this.radioSelected = 'ser';
    } else if (type === 'contract') {
      this.filterBySer = false;
      this.filterByContract = true;
      this.radioSelected = 'contract';
    }
  }

  public sendImportData() {
    this.loading = true;
    this.fieldRequired = false;
    if (this.radioSelected === 'ser') {
      if (this.selectedFilter === 'fin') {
        sessionStorage.setItem('excel_info', '{"selectedFilter":"' + this.selectedFilter + '","dt_fim":"' + this.serDate + '"  }')
        localStorage.setItem('view-atual', 'importacao-finimp');
        this.finimpArray = new Array();
        this.importService.getFinimpEmSer(this.serDate.toJSON().slice(0, 10)).subscribe((finData) => {
          if (finData.data.length === 0) {
            this.loading = false;
            this.noData = true;
            this.hasError = false;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.finimpArray = finData.data;
            this.finimpArray.forEach((elem) => {
              elem.usdFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
              elem.taxa_en = parseFloat(elem.taxa_en).toFixed(3).replace('.', ',');
              this.isFin = true;
              this.isCob = false;
              this.isCar = false;
              this.loading = false;
              this.noData = false;
              this.comexReport.setValue(this.finimpArray);
              this.comexReport.setReportInfo({
                title: 'Importação',
                subtitle1: 'Operação',
                subTitle1Value: 'FINIMP',
                subtitle2: 'Operação em ser',
                subtitle2Value: this.serDate.toLocaleDateString()
              });
              localStorage.setItem('view-atual', 'importacao-finimp');
              localStorage.setItem('canLoadReport', 'true');
            });
          }
        }, () => {
          this.loading = false;
          this.noData = false;
          this.hasError = true;
          localStorage.setItem('canLoadReport', 'false');
        });
      } else if (this.selectedFilter === 'cob') {
        sessionStorage.setItem('excel_info', '{"selectedFilter":"' + this.selectedFilter + '","dt_fim":"' + this.serDate + '"  }')
        localStorage.setItem('view-atual', 'importacao-cobranca');
        this.cobrancaArray = [];
        this.importService.getCobrancaEmSer(this.serDate.toJSON().slice(0, 10)).subscribe((cobData) => {
          if (cobData.data.length === 0) {
            this.loading = false;
            this.noData = true;
            this.hasError = false;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.cobrancaArray = cobData.data;
            this.cobrancaArray.forEach((elem) => {
              elem.usdFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.dt_reg = this.formatDate(elem.dt_reg);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
              elem.saldoFormatado = this.formatUsd(elem.valor_moeda_estrangeira_or);
            });
            this.isFin = false;
            this.isCob = true;
            this.isCar = false;
            this.loading = false;
            this.noData = false;
            this.comexReport.setValue(this.cobrancaArray);
            this.comexReport.setReportInfo({
              title: 'Importação',
              subtitle1: 'Operação',
              subTitle1Value: 'Cobrança',
              subtitle2: 'Operação em ser',
              subtitle2Value: this.serDate.toLocaleDateString()
            });
            localStorage.setItem('view-atual', 'importacao-cobranca');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, (err) => {
          this.loading = false;
          this.noData = false;
          this.hasError = true;
          localStorage.setItem('canLoadReport', 'false');
        });

      } else {
        sessionStorage.setItem('excel_info', '{"selectedFilter":"' + this.selectedFilter + '","dt_fim":"' + this.serDate + '"  }')
        this.importService.getCreditEmSer(this.serDate.toJSON().slice(0, 10)).subscribe((creditData) => {
          if (creditData.data.length === 0) {
            this.loading = false;
            this.noData = true;
            this.hasError = false;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.creditArray = creditData.data;
            this.creditArray.forEach((elem) => {
              elem.saldoAutilizarForm = this.formatUsd(elem.saldo_a_utilizar);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_liq_or = this.formatDate(elem.dt_liq_or);
              elem.saldoUtilizadoForm = this.formatUsd(elem.saldo_utilizado);
            });
            this.isFin = false;
            this.isCob = false;
            this.isCar = true;
            this.loading = false;
            this.noData = false;
            this.comexReport.setValue(this.creditArray);
            this.comexReport.setReportInfo({
              title: 'Importação',
              subtitle1: 'Operação',
              subTitle1Value: 'Carta de crédito',
              subtitle2: 'Operação em ser',
              subtitle2Value: this.serDate.toLocaleDateString()
            });
            localStorage.setItem('view-atual', 'importacao-cartacredito');
            localStorage.setItem('canLoadReport', 'true');
          }

        }, () => {
          this.loading = false;
          this.noData = false;
          this.hasError = true;
          localStorage.setItem('canLoadReport', 'false');
        });
      }

    } else {
      this.contractNum = this.filterForm.value.contractNum;

      if (this.contractNum === '' || this.contractNum === '0000000000') {
        this.loading = false;
        this.fieldRequired = true;
        //alert('campo obrigatório!');
        return;
      }

      if (this.selectedFilter === 'fin') {
        sessionStorage.setItem('excel_info', '{"selectedFilter":"' + this.selectedFilter + '", "referencia":"'+this.contractNum+'","dt_ini":null,"dt_fim":"' + this.serDate + '"  }')
        localStorage.setItem('view-atual', 'importacao-finimp');


        this.finimpArray = [];
        this.importService.GetFinimpContract(this.contractNum).subscribe((finData) => {
          if (finData.data.length === 0) {
            this.loading = false;
            this.noData = true;
            this.hasError = false;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.finimpArray = finData.data;
            this.finimpArray.forEach((elem) => {
              elem.usdFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
              elem.taxa_en = parseFloat(elem.taxa_en).toFixed(2).replace('.', ',');
              this.isFin = true;
              this.isCob = false;
              this.isCar = false;
              this.loading = false;
              this.noData = false;
              this.comexReport.setValue(this.finimpArray);
              this.comexReport.setReportInfo({
                title: 'Importação',
                subtitle1: 'Operação',
                subTitle1Value: 'FINIMP',
                subtitle2: 'Número do contrato',
                subtitle2Value: this.contractNum
              });
              localStorage.setItem('view-atual', 'importacao-finimp');
              localStorage.setItem('canLoadReport', 'true');
            });
          }
        }, () => {
          this.loading = false;
          this.noData = false;
          this.hasError = true;
          localStorage.setItem('canLoadReport', 'false');
        });
      } else if (this.selectedFilter === 'cob') {


        this.cobrancaArray = new Array();

        sessionStorage.setItem('excel_info', '{"selectedFilter":"' + this.selectedFilter + '", "referencia":"'+this.contractNum+'","dt_fim":"' + this.serDate + '"  }')
        this.importService.getCobrancaContract(this.contractNum).subscribe((cobData) => {
          if (cobData.data.length === 0) {
            this.loading = false;
            this.noData = true;
            this.hasError = false;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.cobrancaArray = cobData.data;
            this.cobrancaArray.forEach((elem) => {
              elem.usdFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.dt_reg = this.formatDate(elem.dt_reg);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
              elem.saldoFormatado = this.formatUsd(elem.valor_moeda_estrangeira_or);
            });
            this.isFin = false;
            this.isCob = true;
            this.isCar = false;
            this.loading = false;
            this.noData = false;
            this.comexReport.setValue(this.cobrancaArray);
            this.comexReport.setReportInfo({
              title: 'Importação',
              subTitle1Value: 'Cobrança',
              subtitle1: 'Operação',
              subtitle2: 'Referencia banco',
              subtitle2Value: this.contractNum
            });
            localStorage.setItem('view-atual', 'importacao-cobranca');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, (err) => {
          this.loading = false;
          this.noData = false;
          this.hasError = true;
          localStorage.setItem('canLoadReport', 'false');
        });
      } else {
        this.creditArray = new Array();

        sessionStorage.setItem('excel_info', '{"selectedFilter":"' + this.selectedFilter + '", "referencia":"'+this.contractNum+'","dt_fim":"' + this.serDate + '"  }')
        this.importService.getCreditContract(this.contractNum).subscribe((creditData) => {
          if (creditData.data.length === 0) {
            this.loading = false;
            this.noData = true;
            this.hasError = false;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.creditArray = creditData.data;
            this.creditArray.forEach((elem) => {
              elem.saldoAutilizarForm = this.formatUsd(elem.saldo_a_utilizar);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_liq_or = this.formatDate(elem.dt_liq_or);
              elem.saldoUtilizadoForm = this.formatUsd(elem.saldo_utilizado);
            });
            this.isFin = false;
            this.isCob = false;
            this.isCar = true;
            this.loading = false;
            this.noData = false;
            this.comexReport.setValue(this.creditArray);
            this.comexReport.setReportInfo({
              title: 'Importação',
              subtitle1: 'Operação',
              subTitle1Value: 'Carta de crédito',
              subtitle2: 'Referencia banco',
              subtitle2Value: this.contractNum
            });
            localStorage.setItem('view-atual', 'importacao-cartacredito');
            localStorage.setItem('canLoadReport', 'true');
          }

        }, () => {
          this.loading = false;
          this.noData = false;
          this.hasError = true;
          localStorage.setItem('canLoadReport', 'false');
        });
      }
    }
  }

  public switchFilter(event) {
    this.fieldRequired = false;
    this.selectedFilter = event;
  }

  public formatDate(data: string) {
    return `${data.slice(8, 10)}/${data.slice(5, 7)}/${data.slice(0, 4)}`;
  }

  public formatUsd(value: number) {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2 });
  }

  public formatBrl(value: number) {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

}
