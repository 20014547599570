import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "fibra-modal-arquivo-erro",
  templateUrl: "./modal-arquivo-erro.component.html",
  styleUrls: ["./modal-arquivo-erro.component.scss"],
})
export class ModalArquivoErroComponent implements OnInit {
  @ViewChild("content", { static: true }) content: HTMLElement;
  @Output() closeEvent: EventEmitter<any>;
  @Input() filename:  string = null;
  @Input() messageError: string = null;
  @Input() size: string = null;

  constructor(
    private modalService: NgbModal
  ) {
    this.closeEvent = new EventEmitter();
  }

  ngOnInit() {
    this.openModal(this.content);
  }

  openModal(content): void {
    this.modalService.open(content, {
      beforeDismiss: () => {
        this.closeEvent.emit({close: true})
         return true;
       }, 
      centered: true,
      size: "lg",
      windowClass: "modal-xxl",
      backdrop: "static",
    });
  }

  close(): void {
    this.modalService.dismissAll();
  }

}
