<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <div class="main-divisor"></div>

</div>

<div class="content-page" id="tela">
  <fibra-breadcrumb height="280" [infoAccount]="routAccont" roule="Pagamento em lote" [downloadDoc]="false"
    (infoReport)="exportScreen($event)"></fibra-breadcrumb>



  <!-- Tabs navs -->

  <div class="card-information">
    <a (click)="backLista()" class="back" *ngIf="back">Voltar</a>
    <h3 *ngIf="headerData">Histórico dos arquivos</h3>
    <div class="paginacao-arquivos" *ngIf="headerData">
      <div class="dropdown-periodic">
        <div class="group-items">
          <!-- <div class="period">
            <div class="label-period">Período</div>
            <div class="group-period">
              <div *ngFor="let item of optionPeriod">
                <div class="options-period" (click)="changePeriod(item)"
                  [ngClass]="{ activeCalendar: dataRequest === item }">
                  {{ item }} dias
                </div>
              </div>
              <div class="icon">
                <fibra-range-datepicker *ngIf="!isSchedules" (change)="changePeriodDate($event)" [maxDate]="today"
                  [minDate]="minDay" [isSchedules]="isSchedules"></fibra-range-datepicker>
                <fibra-range-datepicker *ngIf="isSchedules" (change)="changePeriodDate($event)" [minDate]="today"
                  [maxDate]="maxDay" [isSchedules]="isSchedules"></fibra-range-datepicker>
              </div>
            </div>
          </div> -->
          <div class="filter-list">
            <!-- <div class="tabs-list">
              <fibra-tabs-list [options]="options" (itemTab)="tabsSelect($event.idOption)"></fibra-tabs-list>
            </div> -->
            <fibra-filter  (emitFilter)="setFilter($event)" (emitFilterToast)="verifyCalendar($event)"
              [isSchedules]="isSchedules"></fibra-filter>
          </div>
      
        </div>
      </div>
    </div>
  </div>

  <div 
    class="list"
   
  >
    <div class="historic" *ngIf="contentHistorico">
      <div class="box-header">
        
        <div class="first-stage" 
          *fibraHasRole="'PAG_LOTE.CONS_ARQ_REM'"
          [ngClass]="{ active: active == 'remessa' }" 
          (click)="handleTabs('remessa')"
         >
          <label class="stage-label">Remessa</label>
        </div>
        <div class="second-stage" 
          *fibraHasRole="'PAG_LOTE.CONS_ARQ_RET'" 
          [ngClass]="{ active: active == 'retorno' }" 
          (click)="handleTabs('retorno')"
        >
          <label class="stage-label">Retorno</label>
        </div>
        <div class="second-stage"
          *fibraHasRole="'PAG_LOTE.CONS_AR_LIQ'"
          [ngClass]="{ active: active == 'liquidado' }" 
          (click)="handleTabs('liquidado')"
        >
          <label class="stage-label">Liquidação</label>
        </div>

      </div>

      <div class="tab-content">
        <div class="empty-info" *ngIf="invalidDate">
          <fibra-generic-info
            type="warn"
            title="Nenhum resultado encontrado"
            message="Tente uma nova pesquisa inserindo novos parâmetros."
          >
          </fibra-generic-info>
        </div>

        <!-- TAB REMESSA -->
        <div *fibraHasRole="'PAG_LOTE.CONS_ARQ_REM'">
          <div *ngIf="remessa && !invalidDate" class="tabela">
            <div class="empty-info" *ngIf="listRemessas?.length <= 0 && loading === false">
              <fibra-generic-info
                type="warn"
                title="Nenhum resultado encontrado"
                message="Tente uma nova pesquisa inserindo novos parâmetros."
              >
              </fibra-generic-info>
            </div>

            <div class="loadinOuter" *ngIf="loading">
              <div class="spinner-border loadinInto" role="status"></div>
            </div>
                
            <table class="table table-borderless" *ngIf="listRemessas?.length > 0">
              <tbody *ngFor="let list of listRemessas">
                <tr>
                  <td class="date">{{list.data | date:'d'}} de {{list.data | date:'MMMM':'UTC':'pt-br'}} de {{list.data | date:'y'}}</td>
                  <td class="value">Valor total: {{list.valorTotal | currency:'BRL'}}</td>
                </tr>
                <hr class="line-bottom">
                <tr *ngFor="let arquivo of list?.arquivos">
                  <td>
                    <div class="grid-layout">
                      <div class="layer">
                        <img class="icones-menu" src="/assets/svg/layer1.svg" alt="imagem-upload" />
                        <img class="icones-menu" src="/assets/svg/layer2.svg" alt="imagem-upload" />
                      </div>
                      <div class="title">
                        <a class="description file-name" (click)="downloadfile(arquivo.nomeArquivo)">{{arquivo.nomeArquivo}}</a>
                        <span class="sub-description">{{formatBytes(arquivo.tamanhoArquivo, 2)}}</span>
                      </div>
                    </div>
                  </td>
                  <td class="description-value">
                    <p>
                      Este arquivo contém {{arquivo.quantidadeTransacoes}} pagamentos no valor total de
                      <small>
                        {{arquivo.valorTotalTransacoes | currency:'BRL'}}
                      </small>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- TAB RETORNO -->
        <div *fibraHasRole="'PAG_LOTE.CONS_ARQ_RET'">
          <div *ngIf="retorno && !invalidDate" class="tabela">
            <div class="empty-info" *ngIf="listRetorno?.length <= 0 && loading === false">
              <fibra-generic-info
                type="warn"
                title="Nenhum resultado encontrado"
                message="Tente uma nova pesquisa inserindo novos parâmetros."
              >
              </fibra-generic-info>
            </div>

            <div class="loadinOuter" *ngIf="loading">
              <div class="spinner-border loadinInto" role="status"></div>
            </div>
            
            <table class="table table-borderless" *ngIf="listRetorno?.length > 0">
              <tbody *ngFor="let list of listRetorno">
                <tr>
                  <td class="date">{{list.data | date:'d'}} de {{list.data | date:'MMMM':'UTC':'pt-br'}} de {{list.data | date:'y'}}</td>
                </tr>
                <hr class="line-bottom">
                <tr *ngFor="let  arquivo of list.arquivos">
                  <td>
                    <div class="grid-layout">
                      <div class="layer">
                        <img class="icones-menu" src="/assets/svg/layer1.svg" alt="imagem-upload" />
                        <img class="icones-menu" src="/assets/svg/layer2.svg" alt="imagem-upload" />
                      </div>
                      <div class="title">
                        <a class="description file-name" routerLink="/detalsRetorno/{{arquivo.arquivoId}}/{{arquivo.nomeArquivo}}">{{arquivo.nomeArquivo}}</a>
                        <span class="sub-description">{{formatBytes(arquivo.tamanhoArquivo, 2)}}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="grid-layout">
                      <div class="layer" style="align-items: center;">
                        <a (click)='downloadfile(arquivo.nomeArquivo)' style="cursor: pointer"> 
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4629 10.0377L10.6839 15.5254L3.905 10.3605M10.6839 0.999075L10.6839 15.5254L10.6839 0.999075Z" stroke="#495057" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 14.2637V21.0005H20.3684V14.2637" stroke="#495057" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </a>                    
                      </div> 
                      <div class="details">
                        
                        <a routerLink="/detalsRetorno/{{arquivo.arquivoId}}/{{arquivo.nomeArquivo}}">ver detalhes</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- TAB LIQUIDACAO -->
        <div *fibraHasRole="'PAG_LOTE.CONS_AR_LIQ'">
          <div *ngIf="liquidado && !invalidDate" class="tabela">
            <div class="empty-info" *ngIf="listLiquidacao?.length <= 0 && loading === false">
              <fibra-generic-info
                type="warn"
                title="Nenhum resultado encontrado"
                message="Tente uma nova pesquisa inserindo novos parâmetros."
              >
              </fibra-generic-info>
            </div>

            <div class="loadinOuter" *ngIf="loading">
              <div class="spinner-border loadinInto" role="status"></div>
            </div>

            <table class="table table-borderless" *ngIf="listLiquidacao?.length > 0">
              <tbody *ngFor="let list of listLiquidacao | sortBy: 'desc':'data'; let i = index">
                <tr>
                  <td class="date">{{list.data | date:'d'}} de {{list.data | date:'MMMM':'UTC':'pt-br'}} de {{list.data | date:'y'}}</td>
                </tr>
                <hr class="line-bottom">
                <tr *ngFor="let  arquivo of list.arquivos">
                  <td>
                    <div class="grid-layout">
                      <div class="layer">
                        <img class="icones-menu" src="/assets/svg/layer1.svg" alt="imagem-upload" />
                        <img class="icones-menu" src="/assets/svg/layer2.svg" alt="imagem-upload" />
                      </div>
                      <div class="title">
                        <span class="description file-name">{{arquivo.nomeArquivo}}</span>
                        <span class="sub-description">{{formatBytes(arquivo.tamanhoArquivo, 2)}}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="grid-layout">
                      <a (click)='downloadfile(arquivo.nomeArquivo)' style="cursor: pointer">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.4629 10.0377L10.6839 15.5254L3.905 10.3605M10.6839 0.999075L10.6839 15.5254L10.6839 0.999075Z" stroke="#495057" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M1 14.2637V21.0005H20.3684V14.2637" stroke="#495057" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </a>
                      <div class="details">
                        <a class="ver-detalhes" routerLink="/detailsLiquidado/{{arquivo.arquivoId}}/{{arquivo.nomeArquivo}}">ver detalhes</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>

    <div class="transmit" *ngIf="headerHistorico">
      <aside class="archive-history-sidebar" *fibraHasRole="'PAG_LOTE.TRANS_ARQ'">
        <div class="sidebar-inner">
          <fibra-upload-payment (refreshAPIData)="postRemessa()" [activeTab]="activeTab"></fibra-upload-payment>
        </div>
      </aside>
    </div>
  </div>
  <fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
</div>