import {
  AdminUserModel,
  AdminUserRequest,
} from '@src/core/domain/admin-user/admin-user.model';
import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { AdminUserRepository } from '@src/core/repositories/admin-user/admin-user.repository';

@Injectable({
  providedIn: 'root',
})
export class GetFunctionalitiesAccountUseCase
    implements UseCase<AdminUserRequest, AdminUserModel> {
  constructor(private adminUserRepository: AdminUserRepository) {}

  execute(): Observable<AdminUserModel> {
    return this.adminUserRepository.getFunctionalitiesAccount();
  }
}
