<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div class="content-page">
  <fibra-breadcrumb
    bottom="-120px"
    infoAccount="Transações"
    roule="Transferências"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb>
  <div class="body-content">
    <div class="payment-pending">
      <header class="payment-pending-header">
        <img src="/assets/png/iconWarningOrange.png" />
        <h1>Transferência pendente</h1>
      </header>
      <div class="payment-pending-content">
        <p class="tag-msg">{{ data.mensagem }}</p>
      </div>
    </div>
    <div class="payment-pending-actions">
      <button
        type="button"
        (click)="goToView('/transfer')"
        class="btn btn btn-outline-primary"
      >
        Fechar
      </button>
      <button
        type="button"
        (click)="goToView('/transfer')"
        class="btn btn btn-outline-primary"
      >
        Nova transferência
      </button>
      <button
        type="button"
        (click)="goToView('/transaction-approval')"
        class="btn btn btn-primary"
      >
        Acompanhar transação
      </button>
    </div>
  </div>
</div>
