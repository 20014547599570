import { OtpModel } from '@src/core/domain/otp/otp.model';
import { Mapper } from '@src/core/base/mapper';
import { OtpEntity } from '@src/data/repository/otp/otp.entity';

export class OtpRepositoryMapper extends Mapper<
  OtpEntity,
  OtpModel
  > {
  mapFrom(param: OtpEntity): OtpModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }

  mapTo(param: OtpModel): OtpEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
