<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div class="content-page" 
  *fibraHasRole="'
  TRANSF.TRANSF_MSM_TITU,
  TRANSF.TRANSF_OTR_TITU,
  TRANSF.EXC_BEN,
  TRANSF.TRANSF_MSM_TIT_OTR_BC,
  TRANSF.TRANSF_OTR_TIT_BC'"
>
  <fibra-breadcrumb
    bottom="0"
    infoAccount="Transações"
    roule="Transferências"
    arrow="false"
    downloadDoc="false"
  ></fibra-breadcrumb>
  <section class="body-content">
    <header class="row-title">
      <h1 class="title">Beneficiários favoritos</h1>

      <div class="search-beneficiary input-group mb-3">
        <input
          type="text"
          placeholder="Pesquisar pelo nome do beneficiário ou CNPJ/CPF"
          [(ngModel)]="search"
        />

        <div class="input-group-append">
          <img
            (click)="searchBeneficiary(search)"
            src="assets/svg/icon_search.svg"
            width="18"
          />
        </div>

        <button
          type="button"
          class="secondary-button button"
          (click)="newBeneficiary()"
        >
          Novo Beneficiário
        </button>
      </div>
    </header>
    <fibra-list-beneficities-transfer
    #filho
      (emitDeleteAccount)="deleteAccountToast($event)"
      (emitDeleteBeneficiary)="deleteBeneficiaryToast($event)"
      (emitToast)="callToast($event)"
    ></fibra-list-beneficities-transfer>
    <fibra-toast-error-message
      (optionYes)="
        paramDeleteBeneficiary ? deleteBeneficiary() : deleteAccount()
      "
    ></fibra-toast-error-message>
  </section>
</div>
