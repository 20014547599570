import { EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Component } from "@angular/core";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DdaBoletoModel, DdaBoletoSituacaoEnum, DdaBoletoStatusEnum } from "@src/core/domain/dda/dda-boleto.model";
import
{
  OtpOptions,
  PagamentoAprovarReprovarResponseViewResponse,
  PaymentBoletoRequest,
  PaymentGetBoletoResponse,
  PaymentGetResponse,
  PaymentModel,
  PaymentMultipleDdaRequest,
  PaymentResponse
} from "@src/core/domain/payment/payment.model";
import { AccountsEntity } from "@src/data/repository/pix/accounts/pix-accounts-entity";
import { AccountDataShare } from "@src/data/repository/data-share-repository";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";
import { dateToNgbDate, ngbDateToDate } from "../boletos/boletos.component";
import { PaymentRepository } from "@src/core/repositories/payment/payment.repository";
import { LimitsDisclaimerRepositories } from "@src/core/repositories/limits-disclaimer/limits-disclaimer.repository";
import { FavoritesRepository } from "@src/core/repositories/transfer/favorites.repository";
import * as moment from "moment";
import { Observable } from "rxjs";
import { Comprovante } from "../comprovante/comprovante.component";
import { PagamentoStatus } from "@src/core/domain/dda/payment-status.enum";
import { CompanyDataShare } from "@src/core/usecases/data-share/company-data-share.service";
import { toTitleCase } from 'codelyzer/util/utils';

const REDCOLOR   = '#ED6C6C';
const GREENCOLOR = '#73B599';

type AprovarOuReprovar = "aprovar" | "reprovar";

@Component({
  selector: "fibra-boleto-detalhe",
  templateUrl: "./boleto-detalhe.component.html",
  styleUrls: ["../../../pages/dda/dda.scss", "./boleto-detalhe.component.scss"],
})
export class BoletoDetalheComponent implements OnInit {
  private _account?: AccountsEntity;
  private _detalhes!: PaymentGetBoletoResponse;
  private _pagamento!: PaymentGetResponse;
  private _boleto: DdaBoletoModel;

  public pagamentoStatus: PagamentoStatus;

  otpOptions: OtpOptions;

  public toTitleCase = toTitleCase;

  createDefaultDueDate: NgbDate = dateToNgbDate(new Date());
  dataPagamento?: NgbDate = undefined;  
  DdaBoletoSituacaoEnum = DdaBoletoSituacaoEnum;  
  exibirAutorizacao: boolean = false;
  agendarPagamentoParaDataVencimento: boolean = false;
  
  public typeColorToast = REDCOLOR;
  statusPaymentHour: any;
  showStatusHourPaymentClosed: boolean;
  limits: any;
  showLimit: boolean;
  paymentDateFormat: string;
  proximoDiaUtil: NgbDate;
  businessDayDue: any;
  podeAprovar: boolean = false;

  mockedNow?: string;
  permitePagamento: boolean;
  mensagem: string = "";

  exibirPagamentoAguardandoApMaster: boolean = false;
  exibirPagamentoPendente: boolean = false;
  mensagemPagamentoPendente: string = "";
  typeMessagePagamentoPendente: string = "";

  isClienteSaidaRisco: boolean = false;
  isPautaVencida: boolean = false;
  isPossuiConta: boolean = false;
  IsProblemaCredito: boolean = false;
  IsRestricaoCredito: boolean = false;
  exibirToolDataPagamento: boolean = false;
  exibirPagamentoNoProximoDiaUtil: boolean = false;
  exibirPagamentoAposVencimento: boolean = false;

  public get now(): Date {
    return this.mockedNow ? new Date(this.mockedNow) : new Date();
  }

  public get account(): AccountsEntity {
    return this._account;
  }

  public set account(value: AccountsEntity) {
    this._account = value;
  }

  private pendingRequests: number = 0;

  public get isLoading(): boolean {
    return this.pendingRequests > 0;
  }
  
  public get detalhes(): PaymentGetBoletoResponse {
    return this._detalhes;
  }
  public set detalhes(value: PaymentGetBoletoResponse) {
    this._detalhes = value;
  }
  
  public get pagamento(): PaymentGetResponse {
    return this._pagamento;
  }
  public set pagamento(value: PaymentGetResponse) {
    this._pagamento = value;
  }

  public comprovante: Comprovante

  public get dataPagamentoAsDate(): Date | undefined {
    return ngbDateToDate(this.dataPagamento);
  }

  public get proximoDiaUtilAsDate(): Date | undefined {
    return ngbDateToDate(this.proximoDiaUtil);
  }

  get boleto(): DdaBoletoModel {
    return this._boleto;
  }

  @Input()
  set boleto(valor: DdaBoletoModel) {
    this._boleto = valor;
  }

  @Input()
  tab: string;

  @Input()
  situacao: DdaBoletoSituacaoEnum

  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() crash: EventEmitter<unknown> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  private startRequest = () => { 
    this.pendingRequests++;
  }

  private endRequest = () => { 
    this.pendingRequests--;
  }

  isStatusAgendamentoPagamento(): boolean {
    return this.pagamento.cod_status_pagamento == (DdaBoletoStatusEnum.Agendado || DdaBoletoStatusEnum.AgendadoProximoDiaUtil);
  }

  isStatusPagamentoRealizado(): boolean {
    return this.pagamento.cod_status_pagamento == (DdaBoletoStatusEnum.Aprovado || DdaBoletoStatusEnum.Pago || DdaBoletoStatusEnum.AguardandoBaixaCIP);
  }

  boletosMultiplos: PaymentMultipleDdaRequest = {lista_pagamentos_efetivar: []};

  constructor(
    private readonly paymentRepository: PaymentRepository,
    private readonly shareAccount: AccountDataShare,
    private readonly companyDataShare: CompanyDataShare,
    private readonly limitDisclaimer: LimitsDisclaimerRepositories,
    private readonly favoritesRepository: FavoritesRepository,
  ){ }

  private showError(message: string): void {
    this.typeColorToast = REDCOLOR;
    this.toast.callModalMessage(null, null, message);
  }
  

  private showWarning(message: string): void {
    this.typeColorToast = REDCOLOR;
    this.toast.callModalMessage(null, null, message)
  }

  private showInfo(message: string): void {
    this.typeColorToast = GREENCOLOR;
    this.toast.callModalMessage(null, null, message)
  }

  agendarPagamentoParaDataVencimentoClick(): void {
    if (!this.agendarPagamentoParaDataVencimento) {
      this.onDataPagamentoSelected(dateToNgbDate(new Date(this.boleto.vencimento)));
    }
  }

  getCompanyDetails() {
    this.companyDataShare.dataShared.subscribe((res) => {
      if (res !== undefined) {
        res.companyList.map((e) => {
          if (e.selected) {
            this.isClienteSaidaRisco = e.ind_cliente_saida_risco;
            this.isPautaVencida = e.ind_pauta_vencida;
            this.isPossuiConta = e.ind_possui_conta;
            this.IsProblemaCredito = e.ind_problema_credito;
            this.IsRestricaoCredito = e.ind_restricao_cred;
          }
        });        
      }
    });
  }

  async buscarConta(): Promise<void> {
    this.account = await new Promise(
      (resolve, reject) => this.shareAccount.dataShared
      .subscribe(
        (response: AccountsEntity[]) => resolve(response.filter(x => x.selecionado)[0]),
        reject
      )
    )
  }

  public async call<T>(observable: Observable<T>): Promise<T | null> {
    this.startRequest();

    try {
      return await observable.toPromise();
    } catch (error) {
      this.showError(error.error && error.error.message ? error.error.message[0] : Array.isArray(error.message) ? error.message[0] : error.message);

      throw error;
    }
    finally {
      this.endRequest();
    }
  }

  getDataConsultaDetalhe(): any{

    switch(this.tab){

      case 'AVencer': return this.stringToNgbDate(this.boleto.vencimento);
      case 'Vencido': return this.stringToNgbDate(this.boleto.vencimento);
      case 'PendenteAprovacao': this.stringToNgbDate((this.boleto.data_agendamento !='' && this.boleto.data_agendamento) ? this.boleto.data_pagamento : this.boleto.data_pagamento);
      case 'Agendado': this.stringToNgbDate((this.boleto.data_agendamento !='' && this.boleto.data_agendamento) ? this.boleto.data_pagamento : this.boleto.data_pagamento);
    }
  }

  stringToNgbDate(dateString: string): NgbDate {
  const momentDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss');

  const dateStruct: NgbDateStruct = {
    year: momentDate.year(),
    month: momentDate.month() + 1,
    day: momentDate.date()
  };

  return NgbDate.from(dateStruct);
}

  async buscarDetalhes(): Promise<void> {
    const response = await this.call(this.paymentRepository.getBoletoDetalhado({ codigoBoleto: this.boleto.codigo_barras, dt_pagamento: this.dataPagamentoAsDate.toISOString(), isDda: true}))
    
    if (response.code == 200) {

      this.buscarConta();
      this.detalhes = response.data.boleto;
      this.permitePagamento = response.data.permite_pagamento;

      if (this.permitePagamento) {
        this.podeAprovar = !(response.data.pagamento && response.data.pagamento.aprovadores || []).some(x => x.email == localStorage.getItem('useremail'))
    
       if (this.situacao == DdaBoletoSituacaoEnum.Agendado) {
          await this.buscarPagamento(response.data.pagamento.numero_operacao);
        }
        else {
          await this.validaHorario(this.detalhes)
       }
      } else {
        this.mensagem = response.data.pagamento.mensagem
      }
    }else{
      this.errorHandler(response);
    }
  }

  formatHour(hora: string) {
    return `${hora.split(':')[0]}:${hora.split(':')[1]}`;
  }

  async validaHorario(boleto: PaymentGetBoletoResponse): Promise<void> {
    await this.getDayBusiness();

    const customer = JSON.parse(localStorage.getItem('batch-company-name'));
    const titularity = boleto.num_cpf_cnpj.replace(/[^0-9]/g, "");

    let tipo;

    if (boleto.tipo_pessoa === 'PJ' && customer.cpfCnpj !== titularity) {
      tipo = 'OUTRA_TITULARIDADE_PJ';
    }

    if (boleto.tipo_pessoa === 'PF' && customer.cpfCnpj !== titularity) {
      tipo = 'OUTRA_TITULARIDADE_PF';
    }

    if ((boleto.tipo_pessoa === 'PJ' || boleto.tipo_pessoa === 'PF') && customer.cpfCnpj === titularity) {
      tipo = 'MESMA_TITULARIDADE';
    }

    const payload = {
      NumeroConta: this.account.num_conta,
      NumeroEmpresa: "",
      IdEmpresa: 0,
      Pix: false
    }

    const disclaimers = (await this.call(this.limitDisclaimer.getDisclaimers(payload))).data;

    const limitReady = disclaimers.filter(e => e.tipoTransacao === 'BOLETO' && e.tipoTitularidade === tipo);

    this.limits = limitReady;
    this.showLimit = true;

    const statusHourPayment = (await this.call(this.paymentRepository.getStatusHourPayment(this.detalhes.mem_calculo.vlr_total))).data

    this.statusPaymentHour = statusHourPayment.status;
    this.showStatusHourPaymentClosed = moment().startOf('day').diff(this.dataPagamentoAsDate, 'day') == 0 && this.statusPaymentHour == 'FECHADO';

    if (this.showStatusHourPaymentClosed) {
      this.getDayBusiness(true);
    }
  }

  private calcularBoleto(response:any){
    this.boleto.desconto = response.data.boleto.mem_calculo.vlr_desconto;
    this.boleto.abatimento = response.data.boleto.mem_calculo.vlr_abatimento;
    this.boleto.juros = response.data.boleto.mem_calculo.vlr_juros;
    this.boleto.multa = response.data.boleto.mem_calculo.vlr_multa;
    this.boleto.mora = response.data.boleto.mem_calculo.vlr_mora;

    this.detalhes.mem_calculo.vlr_desconto = response.data.boleto.mem_calculo.vlr_desconto;
    this.detalhes.mem_calculo.vlr_abatimento = response.data.boleto.mem_calculo.vlr_abatimento;
    this.detalhes.mem_calculo.vlr_juros = response.data.boleto.mem_calculo.vlr_juros;
    this.detalhes.mem_calculo.vlr_multa = response.data.boleto.mem_calculo.vlr_multa;
    this.detalhes.mem_calculo.vlr_mora = response.data.boleto.mem_calculo.vlr_mora;
  }

  private async getBoletoEncargos(date: string): Promise<void> {
      const params: PaymentBoletoRequest = {
        codigoBoleto: this.boleto.codigo_barras,
        dt_pagamento: date.split('T')[0],
        isDda: true
      };

      const response = await this.call(this.paymentRepository.getBoletoDetalhado(params))

      if (response.code == 200 && response.status == "success") {
        this.calcularBoleto(response);
        const dtVencimento = moment(this.boleto.vencimento);
        const diff = dtVencimento.diff(moment(response.data.boleto.mem_calculo.dt_validade, 'day'));
        this.exibirPagamentoAposVencimento = diff < 0;
        
        const businessDay = (await this.call(this.favoritesRepository.getBusinessDay({ diautil: dtVencimento.format('YYYY-MM-DD') }))).data

        this.businessDayDue = businessDay.diautil
      }
  }

  async getDayBusiness(limite: boolean = false) {
    if (limite && !this.agendarPagamentoParaDataVencimento) {
      this.dataPagamento.day = this.dataPagamento.day + 1
    }

    const businessDay = (await this.call(this.favoritesRepository.getBusinessDay({ diautil: this.dataPagamentoAsDate.toISOString() }))).data

    this.proximoDiaUtil = dateToNgbDate(new Date(businessDay.diautil))
    const dataAtual = moment(moment(this.now).format('YYYY-MM-DDT00:00:00'));
    const outraData = moment.utc(businessDay.diautil);
    const diferenca = outraData.diff(dataAtual, 'days');

    const dtPagamentoDiaUtil = moment(`${this.proximoDiaUtil.year}-${this.proximoDiaUtil.month}-${this.proximoDiaUtil.day}`, 'YYYY-MM-DDT00:00:00');
    const dtPagamentoFromDatePicker = moment(`${this.dataPagamento.year}-${this.dataPagamento.month}-${this.dataPagamento.day}`, 'YYYY-MM-DDT00:00:00');

    if(diferenca>0){
      await this.getBoletoEncargos(businessDay.diautil);
    }
  
    const dtPagamento  = diferenca > 0 ? dtPagamentoDiaUtil : moment(this.dataPagamentoAsDate);
    const dtVencimento = moment(this.boleto.vencimento);
    
    const diff = moment(dtPagamento).diff(moment(dtVencimento), 'days');
    const pagamentoNoProximoDiaUtil = dtPagamentoDiaUtil.diff(dtPagamentoFromDatePicker, 'days') > 0;

    if (diff > 0 || pagamentoNoProximoDiaUtil) {
      this.dataPagamento = this.stringToNgbDate(dtPagamentoDiaUtil.format("YYYY-MM-DDT00:00:00"));
      this.exibirToolDataPagamento = true;  
    }

    this.exibirPagamentoNoProximoDiaUtil = pagamentoNoProximoDiaUtil;
    this.exibirPagamentoAposVencimento   = diff > 0;
  }

  async buscarPagamento(codPagamento: number): Promise<void> {
    this.pagamento = (await this.call(this.paymentRepository.getDetalhes(codPagamento))).data;

    this.exibirPagamentoAguardandoApMaster = this.pagamento.cod_status_pagamento == PagamentoStatus.AguardandoApMaster;
    this.exibirPagamentoPendente = this.pagamento.cod_status_pagamento == PagamentoStatus.Pendente || this.pagamento.cod_status_pagamento == PagamentoStatus.AguardandoSaldo;
    this.mensagemPagamentoPendente = (this.pagamento.cod_status_pagamento == PagamentoStatus.AguardandoSaldo ? "Aguardando saldo disponível" : "");
    this.typeMessagePagamentoPendente = (this.pagamento.cod_status_pagamento == PagamentoStatus.AguardandoSaldo ? "warn" : "");

    this.comprovante = {
      abatimento: this.pagamento.vlr_abatimento,
      beneficiario: {
        banco: this.pagamento.desc_banco_beneficiario,
        cpfCnpj: this.pagamento.num_cpfcnpj_beneficiario,
        nome: this.pagamento.desc_razao_social_beneficiario
      },
      canal: this.pagamento.canal_pagamento,
      codigoBarras: this.pagamento.num_cod_barras,
      contaDebitada: { //processados no componente do comprovante
        conta: {
          agencia: "",
          descricao: "",
          numero: "",
        },
        cpfCnpj: "",
        nome: "",
      },
      dataAgendada: this.pagamento.dt_agendamento,
      dataAgendamentoPagamento: this.pagamento.dt_execucao, //pagamento ou agendamento
      dataVencimento: this.pagamento.dt_vencimento,
      desconto: this.pagamento.vlr_desconto,
      encargos: {
        juros: this.pagamento.vlr_juros,
        mora: this.pagamento.vlr_mora,
        multas: this.pagamento.vlr_multa,
      },
      formaPagamento: this.pagamento.forma_pagamento,
      numeroCompromisso: this.pagamento.num_compromisso,
      valorBoleto: this.pagamento.vlr_original,
      valorPago: this.pagamento.vlr_pago,   
      codStatusPagamento: this.pagamento.cod_status_pagamento   
    }
  }

  async ngOnInit(): Promise<void> {
    this.getCompanyDetails();
    await this.buscarConta();

    switch(this.situacao){
      case DdaBoletoSituacaoEnum.Agendado:
      case DdaBoletoSituacaoEnum.PendenteAprovacao:
        this.dataPagamento = dateToNgbDate(new Date(this.boleto.data_agendamento));
        break;
      default:
        this.dataPagamento = dateToNgbDate(new Date());
        break;  
    }

    await this.buscarDetalhes();    
  }

  async onDataPagamentoSelected(value: NgbDate): Promise<void> {
    this.onCloseToolTip();
    if (value.before(dateToNgbDate(new Date()))) {
      this.showWarning("Data inválida. Favor informar uma data maior ou igual a data atual.")
    }
    else {
      this.dataPagamento = value;
      await this.buscarDetalhes();
    }
  }

  pagarClick() {
    const dataPagamento = this.dataPagamento.before(this.proximoDiaUtil)
      ? this.proximoDiaUtil
      : this.dataPagamento;

    this.otpOptions = {
      apiCallback: 1,
      closeEvent: this.fecharModal.bind(this),
      responseValidation: this.respostaPagamentoApi.bind(this),
      apiPayload: {
        desc_pagamento: "DDA",
        dt_pagamento: ngbDateToDate(dataPagamento).toISOString(),
        num_cod_barras: this.detalhes.codigo_barras,
        num_conta_debito: this.account.num_conta,
        cnpj_cpf_sacado: this.boleto.cnpj_cpf_sacado,
        num_canal_pagamento: 3, // internet banking
        tipo_pagamento: 6, //DDA
        num_forma_pagamento: 1, // conta corrente
        vlr_pagamento: this.detalhes.mem_calculo.vlr_total,
        email_operador: localStorage.getItem('useremail'),
      }
    }

    this.exibirAutorizacao = true;
  }

  aprovarOuReprovar(acao: AprovarOuReprovar) {
    this.otpOptions = {
      apiCallback: acao == "aprovar" ? 134 : 135,
      closeEvent: this.fecharModal.bind(this),
      responseValidation: response => this.respostaAprovarOuReprovarApi(response, acao),
      apiPayload: {
        pagamento_id: this.pagamento.cod_pagamento,
        transacao_id: this.pagamento.num_compromisso,
      }
    }

    this.exibirAutorizacao = true;
  }

  fecharModal(): void {
    this.exibirAutorizacao = false;
  }

  private errorHandler(response){
    this.showError(response.error && response.error.message ? response.error.message[0] : Array.isArray(response.message) ? response.message[0] : response.message);
  }

  respostaPagamentoApi(response: PaymentModel<PaymentResponse> | any): void {
    if (response.code == 200) {
      if(response.data.mensagem && !response.data.num_operacao)
        this.showWarning(response.data.mensagem);
      else
        this.buscarPagamento(response.data.num_operacao);
    }
    else {
      this.errorHandler(response);
    }
  }

  respostaAprovarOuReprovarApi(response: PaymentModel<PagamentoAprovarReprovarResponseViewResponse>, acao: AprovarOuReprovar): void {    
    if (response.code == 200) {
      const map: Record<AprovarOuReprovar, string> = {
        "aprovar": "aprovado",
        "reprovar": "reprovado",
      }
      this.showInfo(`Pagamento ${map[acao]} com sucesso.`);
      this.closed.emit();
    }
    else {
      const map: Record<AprovarOuReprovar, string> = {
        "aprovar": "Aprovação",
        "reprovar": "Reprovação",
      }
      this.showWarning(`${map[acao]} de pagamento indisponível. Tente novamente mais tarde.`);
    }
  }

  onCloseToolTip(): void {
    this.exibirPagamentoNoProximoDiaUtil = false;
    this.exibirPagamentoAposVencimento = false;
    this.showStatusHourPaymentClosed = false;
  }

  showEncargosError = () => {
    const payment = moment(ngbDateToDate(this.proximoDiaUtil));
    const due = moment(this.businessDayDue);

    return payment.diff(due) !== 0;
  }
}
