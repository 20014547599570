import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fibra-access-denied-no-account-page',
  templateUrl: './access-denied-no-account-page.component.html',
  styleUrls: ['./access-denied-no-account-page.component.scss'],
})
export class AccessDeniedNoAccountPageComponent {

}
