import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { DropdownItem } from '@src/core/domain/business-models';
import { isArray } from 'util';
import { empresasCapitalGiro, empresasChequeEmpresa, empresasFibraFacil } from './banner.empresas';
import { pequenasEmpresasCapitalGiro, pequenasEmpresasChequeEmpresa, pequenasEmpresasChequeEmpresaNaoContratado } from './banner.pe';
import { middleChequeEmpresaNaoContratado, middleChequeEmpresaNaoUtilizado, middleFibraFacilNaoUtilizado } from './banner.middle';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { UserService } from '@src/shared/services/user.service';
import { BannerEmailDataRepository } from '@src/data/repository/dashboard/banner-email.repository';

@Component({
  selector: 'fibra-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  providers: [NgbCarouselConfig]
})
export class BannerComponent implements OnInit {

  private _company: DropdownItem;
  @Input() public fibraFacil: boolean = false;
  bannerType: number = 1;

  showModalBannerEmail: boolean = false;
  campaign: any;

  public get empresaPEBoletoOnline(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return ['18911839000147', '07713492000101', '05468779000198', '07020193000191', '60975562000182', '31742121000132', '10341087000114', '12999705000143', '03405559000190', '19370003000145', '09094440000120', '13625920000147', '05015496000190', '23481608000180', '10437100000133', '15156237000133', '20520367000143', '03122300000131', '32631676000170'].indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerCapitalGiroEmpresa(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return empresasCapitalGiro.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerCapitalGiroPE(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return pequenasEmpresasCapitalGiro.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerChequeEmpresa(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return empresasChequeEmpresa.indexOf(this._company.cpfCnpj) > -1 || pequenasEmpresasChequeEmpresa.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerFibraFacil(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return empresasFibraFacil.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerChequeEmpresaNaoContratadoPE(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return pequenasEmpresasChequeEmpresaNaoContratado.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerChequeEmpresaNaoContratadoMiddle(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return middleChequeEmpresaNaoContratado.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerChequeEmpresaNaoUtilizado(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return middleChequeEmpresaNaoUtilizado.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  public get showBannerFibraFacilNaoUtilizado(): boolean {
    if (this._company && this._company.cpfCnpj) {
      return middleFibraFacilNaoUtilizado.indexOf(this._company.cpfCnpj) > -1;
    }

    return false;
  }

  constructor(
    config: NgbCarouselConfig,
    private _router: Router,
    private _sharedCompany: CompanyDataShare,
    public analytics: AngularFireAnalytics,
    private userService: UserService,
    public bannerEmailDataRepository: BannerEmailDataRepository,
  ) {
    config.interval = 10000;
    config.keyboard = false;
    config.showNavigationArrows = false;
    config.pauseOnHover = true;
  }

  redirecionaParaChequeEmpresa(e) {
    this._router.navigate(['/loans'], { queryParams: { chequeempresa: true }});
  }

  ngOnInit(): void {
    this._sharedCompany.dataShared.subscribe((res) => {
      if (res !== undefined && isArray(res.companyList)) {
        this._company = res.companyList.filter((e) => e.selected)[0];

        this.getCampaignByCompany();
      }
    });
  }

  async openModal(bannerType) {
    this.bannerType = bannerType;
    await this.analytics.logEvent('custom_event', {});
    if (bannerType == 1) {
      this.analytics.logEvent('banner_clicado_credito', this._company);
    } else {
      this.analytics.logEvent('banner_clicado_cambio', this._company);
    }
    
    this.showModalBannerEmail = true;
  }

  public closeModalPix(value) {
    this.showModalBannerEmail = false;
  }

  isPj() {
    return this.userService.isPj();
  }

  getCampaignByCompany() {
    this.bannerEmailDataRepository.getCampaign(this._company.value)
    .pipe()
    .subscribe((result) => {
      this.campaign = result.data;
    });
  }

  goToExtract(): void {
    this._router.navigate(["/extract/"]);
  }
}

