<div class="box-detail">
  <div class="info-cod-boleto">
    <div class="info-bill">
      <span class="text">Código de barras</span>
      <span class="valueBoleto">{{ data.num_cod_barras}}</span>
    </div>
  </div>
  
  <div class="info-beneficiary">
    <div class="info-bill">
      <span class="text">Nome do beneficiário:</span>
      <span class="value"> {{ data.nome_beneficiario | titlecase }} </span>
    </div>
    <div class="info-bill">
      <span class="text">CNPJ / CPF</span>
      <span class="value">{{ data.num_cpf_cnpj | cpfcnpj }} </span>
    </div>
    <div class="info-bill">
      <span class="text">Banco</span>
      <span class="value">
        {{ data.num_banco }} - {{ data.desc_banco }}
      </span>
    </div>
  </div>

  <div class="info-value-date">
    <div class="info-bill">
      <span class="text">Data de vencimento </span>
      <span class="valueTop">{{ data.dt_vencimento }}</span>
    </div>
    <div class="info-bill">
      <span class="text">Valor do boleto</span>
      <span class="valueTop">R$ {{ data.vlr_titulo | number: '1.2-2' }}</span>
    </div>
    <div class="info-bill">
      <span class="text">Data de pagamento</span>
      <span class="valueTop">{{ formatDate(data.dt_pagamento) }}</span>
    </div>
  </div>

  

  <div class="info-desc-abatimento">
    <div class="info-bill">
      <span class="text">Desconto</span>
      <span class="valueBottom">
        R$ {{ data.vlr_desconto | number: '1.2-2' }}
      </span>
    </div>
    <div class="info-bill">
      <span class="text">Abatimento</span>
      <span class="valueBottom">
        R$ {{ data.vlr_abatimento | number: '1.2-2' }}
      </span>
    </div>
  </div>

  <div class="info-mora">
    <div class="info-bill">
      <span class="text">Juros</span>
      <span class="valueBottom">
        R$ {{ data.vlr_juros | number: '1.2-2' }}
      </span>
    </div>
    <div class="info-bill">
      <span class="text">Multas</span>
      <span class="valueBottom">
        R$ {{ data.vlr_multa | number: '1.2-2' }}
      </span>
    </div>
    <div class="info-bill">
      <span class="text">Mora</span>
      <span class="valueBottom">R$ 
        {{ data.vlr_mora | number: '1.2-2' }} 
      </span>
    </div>
  </div>

  <div class="info-value-date">
    <div class="info-bill">
      <span class="text">Valor pago </span>
      <span class="valueTop">R$ {{ data.vlr_pagamento | number: '1.2-2' }}</span>
    </div>
    <div class="info-bill">
      <span class="text">Data de pagamento</span>
      <span class="valueTop">{{ data.dt_pagamento }}</span>
    </div>
  </div>

  <div class="info-descricao">
    <div class="info-bill">
      <span class="text">Descrição</span>
      <span class="valueDesc">
        {{ data.desc_pagamento || ' '}}
      </span>
    </div>
  </div>
</div>
