import { Injectable } from '@angular/core';
import { PredictedTotalizadoresRepository } from '@src/core/repositories/predicted/predicted.repository';
import { UseCase } from '@src/core/base/use-case';
import { PredictedTotalizadorModel } from '@src/core/domain/predicted/predicted.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetPredictedTotalizadoresUsecase {

  constructor(private predictedRepository: PredictedTotalizadoresRepository) { }

  execute(): Observable<PredictedTotalizadorModel> {
    return this.predictedRepository.getPredictedMovementsTotalizadores();
  }
}
