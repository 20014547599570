<div class="container fibra-accordion">
  <div class="fibra-accordion-select-all" *ngIf="showSelectAll">
    <div class="row">
      <div class="col">
        Ative as permissões que este usuário terá na conta:
      </div>
      <div class="col-auto" *ngIf="!checkAll">
        Ativar todas as permissões
      </div>
      <div class="col-auto" *ngIf="checkAll">
        Desativar todas as permissões
      </div>
      <div class="col-auto">
        <label class="switch switch-dark">
          <input type="checkbox" [checked]="checkAll" (change)="changeAllRules($event.target.checked, collapseID)">
          <span class="slider"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="fibra-accordion-group" *ngFor="let permission of listPermissions">
    <a class="fibra-accordion-link" data-toggle="collapse"
      href="#collapseRule{{collapseID}}{{permission.cod_funcionalidade}}" role="button" aria-expanded="false"
      aria-controls="collapseRule{{collapseID}}{{permission.cod_funcionalidade}}">
      <div class="row fibra-accordion-header">
        <div class="col fibra-accordion-header-title">
          {{permission.desc_funcionalidade}}
        </div>
        <div class="col-auto fibra-accordion-header-permissions">
          {{getQtdActive( collapseID, permission.cod_funcionalidade)}}
        </div>
        <div class="col-auto fibra-accordion-header-icon">
        </div>
      </div>
    </a>
    <div *ngFor="let rule of permission.operacoes" class="row collapse fibra-accordion-rules"
      id="collapseRule{{collapseID}}{{permission.cod_funcionalidade}}">
      <div class="col fibra-accordion-rule"
        [ngClass]="{'fibra-accordion-rule-active': enableOperator(collapseID, permission.cod_funcionalidade, rule.cod_operacao)}">
        {{rule.desc_operacao}}
      </div>
      <div class="col-auto">
        <label class="switch fibra-accordion-switch">
          <input type="checkbox" [checked]="enableOperator(
                      collapseID,
                      permission.cod_funcionalidade,
                      rule.cod_operacao)" (change)="changeRule(
                      $event.target.checked,
                      collapseID,
                      permission.cod_funcionalidade,
                      rule.cod_operacao)">
          <span class="slider"></span>
        </label>
      </div>
    </div>
  </div>
</div>