import { Injectable } from '@angular/core';
import { TitleInformationModelRepository } from '@src/core/repositories/charge/title-information.repository';
import { UseCase } from '@src/core/base/use-case';
import {
  TitleInformationRequest,
  TitleInformationModel,
} from '@src/core/domain/charge/titleinformation.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostTitleInformationUsecase
  implements UseCase<TitleInformationRequest, TitleInformationModel> {
  constructor(
    private titleInformationRepository: TitleInformationModelRepository
  ) {}

  execute(params: TitleInformationRequest): Observable<TitleInformationModel> {
    return this.titleInformationRepository.postTitleInformation(params);
  }
}
