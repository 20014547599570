<div class="content">
  <div class="datatable warning-display">
    <h1>{{doc.docTitle}}</h1>
    <div [ngClass]="doc.readOnly  ? 'text read-only' : 'text'" [innerHTML]="text | safeHtml"></div>
    <div class="bottom" *ngIf="!doc.readOnly">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="confirmation" />
        <label class="form-check-label" for="exampleCheck1">{{doc.checkTitle}}</label>
      </div>
      <div class="agree">
        <button (click)="clicked()" type="button" class="secondary-button" [disabled]="!confirmation">{{showSpinner ? ''
          : doc.buttonTittle}}
          <div *ngIf="showSpinner" class="spinner-border" role="status"></div>
        </button>
      </div>
    </div>
  </div>
</div>