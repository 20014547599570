import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'fibra-opportunity-fianca-bancaria',
  templateUrl: './opportunity-fianca-bancaria.component.html',
  styleUrls: ['./opportunity-fianca-bancaria.component.scss'],
})
export class OpportunityFiancaBancariaComponent implements OnInit {
  
  @ViewChild('interested', {static: false}) interested: ElementRef; 
  benefities = [
    {
      icon: '/assets/opportunity/svg/opportunity-benefits-wallet-chart.svg',
      description: 'Flexibilidade no pagamento das comissões, com prazos sob medida para os seus negócios.',
      alt: 'icon-wallet'
    },
    {
      icon: '/assets/opportunity/svg/opportunity-benefits-coin.svg',
      description: 'Credibilidade e tranquilidade para suas negociações.',
      alt: 'icon-coin'
    },
    {
      icon: '/assets/opportunity/svg/icon-phone-money.svg',
      description: 'Conte com limite de Finep e BNDES.',
      alt: 'icon-money'
    },
    {
      icon: '/assets/opportunity/svg/icon-menu-board.svg',
      description: 'Tenha acesso a diversas modalidades de fiança.',
      alt: 'icon-board'
    }
  ]

  modalities = [
    {
      title: 'Pagamento',
      description: 'Flexibilidade no pagamento das comissões, com prazos sob medida para os seus negócios.'
    },
    {
      title: 'B3',
      description: 'Garantir operações de Clientes (em geral Instituições Financeiras) realizadas junto à B3.'
    },
    {
      title: 'Fiscal',
      description: 'Garantir processos judiciais ou administrativos que tenham por objeto cobrança de tributos.'
    },
    {
      title: 'Performance',
      description: 'Garantir o cumprimento de obrigações realizadas à prestação de serviços e/ou fornecimentos estabelecidos em contratos, pedidos de compras, etc.'
    },
    {
      title: 'Concorrência / Licitação (Bid Bond)',
      description: 'Garantir o cumprimento de obrigações assumidas no âmbito de processo de concorrência ou licitação.'
    },
    {
      title: 'Aduaneiras',
      description: 'Garantir o recolhimento dos tributos decorrentes da entrada de mercadorias no país.'
    },
    {
      title: 'Fiança direta ao beneficiário',
      description: 'Contratada pelo próprio beneficiário ao invés do afiançado.'
    },
    {
      title: 'Judicial',
      description: 'Garantir processos judiciais.'
    },
    {
      title: 'Finep',
      description: 'Garantir a honra do contrato estabelecido entre cliente (afiançado) e FINEP (beneficiário).'
    },
    {
      title: 'BNDES',
      description: 'Garantir a honra do contrato estabelecido entre cliente (afiançado) e BNDES (beneficiário).'
    }
  ]
  constructor() {}

  ngOnInit() {}

  isInterested() {
    const targetElement = this.interested.nativeElement
    targetElement.scrollIntoView({behavior: "smooth"})
  }
     
}
