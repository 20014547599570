import { InstructionEntity } from "@src/data/repository/query/query-instruction-list/instruction.entity";
import { Response, Paginated } from "../response/response";

export interface ListQueryRequest {
  dt_inicial: string;
  dt_final: string;
  num_carteira?: string;
  num_conta?: string;
  param_carteira?: any;
}

export interface ListQueryRequestExcel {
  dt_inicial: string;
  dt_final: string;
  num_carteira?: string;
  num_conta?: string;
  status?: string;
  view?: string;
}


export interface ListQueryInfoTitleRequest {
  numero_carteira: string,
  codigo_agencia: number,
  nosso_numero: number,
  seu_numero: string,
  nosso_numero_correspondente: string,
  codigo_banco_correspondente: string,
  codigo_cliente: string
}

export interface ListQueryModel {
  code: number;
  status: string;
  data?: ListQueryResult;
  numberItem?: number;
  originalValue?: number;
}

export interface ListQueryResult {
  rows: ListQueryTypeEnum[],
  rowcount: number,
  titulospagos: number,
  titulosvencidos: number,
  titulosbaixados: number,
  titulosavencer: number,
  titulosemcartorio: number
}

export interface ListQueryTypeEnum {
  dt_envio: string;
  nome_arquivo: string;
  nome_pagador?: string;
  num_agencia: string;
  num_conta: string;
  desc_chave: string;
  qtd_boletos: number;
  vlr_total_boletos: number;
  tam_arquivo_kb: number;
  desc_msg_erro: string;
  cod_status: string;
  desc_status: string;
  cod_especia: string;
  dt_emissao: Date;
  dt_vencimento: Date;
  seu_numero: string;
  nosso_numero: string;
  status: string,
  vlr_titulo: number
}

export interface instructionListModel {
  code: number;
  status: string;
  data?: instructionListTypeEnum;
}

export interface instructionListTypeEnum {
  nome_pagador?: string;
  cod_status?: string;
  desc_status?: string;
  data_emissao?: string;
  data_vencimento?: string;
  pagador?: string;
  status?: string;
  vlr_titulo?: string;
  seu_numero?: string;
  instrucoes?: string;
}

export interface francesinhaListModel {
  code: number;
  status: string;
  data?: francesinhaListTypeEnum;
}

export interface francesinhaListTypeEnum {
  francesinhas: francesinhaEnum;
  vlr_abatimento: number;
  vlr_iof: number;
  vlr_tarifa: number;
  vlr_titulo: number;
}

export interface francesinhaEnum {
  num_titulo: string;
  vlr_abatimento: number;
  vlr_iof: number;
  vlr_tarifa: number;
  vlr_liquido: number;
  dt_emissao: string;
  dt_vencimento: string;
  nome_pagador: string;
  desc_status: string;
  vlr_titulo: number;
  seu_numero: string;
}
export interface posicaoCarteiraListQueryModel {
  code: number;
  status: string;
  data?: posicaoCarteiraListQueryResult;
  numberItem?: number;
  originalValue?: number;
}

export interface posicaoCarteiraListQueryResult {
  rows: posicaoCarteiraListQueryTypeEnum[],
  rowcount: number
}

export interface posicaoCarteiraListQueryTypeEnum {
  dt_envio: string;
  nome_arquivo: string;
  nome_pagador?: string;
  num_agencia: string;
  num_conta: string;
  desc_chave: string;
  qtd_boletos: number;
  vlr_total_boletos: number;
  tam_arquivo_kb: number;
  desc_msg_erro: string;
  cod_status: string;
  desc_status: string;
  cod_especia: string;
  dt_emissao: Date;
  dt_vencimento: Date;
  seu_numero: string;
  nosso_numero: string;
  status: string,
  vlr_titulo: number
}


export interface ExcelExportListQueryModel {
  dt_inicial: string;
  dt_final: string;
  num_carteira?: string;
  num_conta?: string;
}

export interface PdfExportListQueryModel {
  dt_inicial: string;
  dt_final: string;
  num_carteira?: string;
  num_conta?: string;
  data?: pdfResponseQuery;
}

export interface pdfResponseQuery {
  url: string;
  nome_arquivo: string;
}

export interface InstructionRequest {
  status: string;
  num_carteira: string;
  desc_carteira: string;
  num_conta: string;
  tipo_data: string;
  valor: number | null;
  nossonumero: string;
  seunumero: string;
  pagador: string;
  tipo_instrucao: number | null;
  dt_inicial: Date;
  dt_final: Date;
  paginar: boolean;
  offset: InstructionEntity;
  campo_ordenacao: string;
  sentido_ordenacao: string;
}

export interface InstructionResponse extends Response<Paginated<InstructionEntity>> {

}

export enum InstructionTypeEnum {
  Abatimento = 0,
  AlteracaoDeVencimento = 1,
  PedidoDeBaixa = 2,
  Protesto = 3,
  SustacaoDeProtesto = 4,
}

export interface InstructionFilter {
  carteira: string; 
  descCarteira: string;
  account: string; 
  dt_Inicio: Date; 
  dt_Final: Date; 
  yourNumber: string; 
  ourNumber: string; 
  draweeName: string; 
  valorTitulo: number; 
  tipo_data_instrucoes: string;
  instructionValue: number
}