import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'fibra-opportunity-capital-giro',
  templateUrl: './opportunity-capital-giro.component.html',
  styleUrls: ['./opportunity-capital-giro.component.scss'],
})
export class OpportunityCapitalGiroComponent implements OnInit {
  
  @ViewChild('interested', {static: false}) interested: ElementRef; 
  benefities = [
    {
      icon: '/assets/opportunity/svg/opportunity-benefits-wallet.svg',
      description: 'Mais facilidade com débitos direto na sua conta corrente do Fibra',
      alt: 'icon-wallet'
    },
    {
      icon: '/assets/opportunity/svg/opportunity-benefits-wallet-chart.svg',
      description: 'Pagamento com prazos ideais para o seu planejamento',
      alt: 'icon-chart'
    },
    {
      icon: '/assets/opportunity/svg/opportunity-benefits-coin.svg',
      description: 'Recursos disponíveis com a agilidade que você precisa',
      alt: 'icon-coin'
    },
    {
      icon: '/assets/opportunity/svg/opportunity-presention-chart.svg',
      description: 'Tenha o controle: Internet banking ágil para gerenciar seus recursos',
      alt: 'icon-chart'
    }
  ]
  constructor() {}

  ngOnInit() {}

  isInterested() {
    const targetElement = this.interested.nativeElement
    targetElement.scrollIntoView({behavior: "smooth"})
  }
     
}
