import {
    ListQueryRequest,
    PdfExportListQueryModel,
  } from '@src/core/domain/query/query.model';
  import { Injectable } from '@angular/core';
  import { UseCase } from '@src/core/base/use-case';
  import { Observable } from 'rxjs';
  import { QueryRepository } from '@src/core/repositories/query/query.repository';
  
  @Injectable({
    providedIn: 'root',
  })
  export class GetAllQueryPdfExportUsecase
    implements UseCase<any, PdfExportListQueryModel> {
    constructor(private queryRepository: QueryRepository) {}
  
    execute(
      id: number
    ): Observable<PdfExportListQueryModel> {
      return ;
    }
  }