<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page">
  <fibra-breadcrumb infoAccount="Cobrança / Nova cobrança" roule="Revisão de título" infoText='Revise os campos abaixo com as informações do pagador e do título. Clique em "Editar" para corrigir ou em "Enviar" para finalizar o processo.'>
  </fibra-breadcrumb>

  <div class="col-sm-11 my-5 summary">
    <div class="card p-4">
      <p class="summary-subtitle">Dados do pagador</p>
      <div class="summary-row">
        <fibra-summary-item label="Tipo de pessoa" [fullValue]="true" value="{{
            step1 && step1.personal && step1.personal.personType === 'F'
              ? 'Pessoa física'
              : 'Pessoa jurídica'
          }}">
        </fibra-summary-item>
        <fibra-summary-item label="CPF/CNPJ" [fullValue]="true" value="{{ step1 && step1.personal ? step1.personal.cpfCnpj : '' }}">
        </fibra-summary-item>
        <fibra-summary-item label="Nome do pagador" [fullValue]="true" value="{{ step1 && step1.personal ? step1.personal.draweeName : '' }}"></fibra-summary-item>
      </div>
      <div class="summary-row">
        <fibra-summary-item label="CEP" [fullValue]="true" value="{{ step1 && step1.address ? step1.address.cep : '' }}">
        </fibra-summary-item>
        <fibra-summary-item label="Nome da rua" [fullValue]="true" value="{{ step1 && step1.address ? step1.address.street : '' }}"></fibra-summary-item>
        <fibra-summary-item label="Número" [fullValue]="true" value="{{ step1 && step1.address ? step1.address.number : '' }}">
        </fibra-summary-item>
        <fibra-summary-item label="Complemento" [fullValue]="true" value="{{ step1 && step1.address ? step1.address.complement : '' }}"></fibra-summary-item>
        <fibra-summary-item label="Bairro" [fullValue]="true" value="{{ step1 && step1.address ? step1.address.neighborhood : '' }}"></fibra-summary-item>
      </div>
      <div class="summary-row">
        <fibra-summary-item label="Cidade" [fullValue]="true" value="{{ step1 && step1.address ? step1.address.city : '' }}">
        </fibra-summary-item>
        <fibra-summary-item label="Estado" value="{{ step1 && step1.address ? step1.address.state : '' }}"></fibra-summary-item>
      </div>
      <div class="summary-divider"></div>
      <div *ngIf="drawer">
        <p class="summary-subtitle" *ngIf="drawer.drawer !== undefined">Dados do sacador avalista</p>
      </div>
      <div *ngIf="drawer">
        <div class="summary-row" *ngIf="drawer.drawer !== undefined">

          <fibra-summary-item label="Tipo de pessoa" [fullValue]="true" value="{{
              drawer && drawer.drawer && drawer.drawer.personType === 'F'
                ? 'Pessoa física'
                : 'Pessoa jurídica'
            }}">
          </fibra-summary-item>
          <fibra-summary-item label="CPF/CNPJ" [fullValue]="true" value="{{ drawer && drawer.drawer ? drawer.drawer.cpfCnpj : '' }}">
          </fibra-summary-item>
          <fibra-summary-item label="Nome do sacador" [fullValue]="true" value="{{ drawer && drawer.drawer ? drawer.drawer.draweeName : '' }}"></fibra-summary-item>
        </div>
      </div>
      <div *ngIf="drawer">
        <div class="summary-row" *ngIf="drawer.drawerAddress !== undefined">

          <fibra-summary-item label="CEP" [fullValue]="true" value="{{
              drawer && drawer.drawerAddress ? drawer.drawerAddress.cep : ''
            }}">
          </fibra-summary-item>
          <fibra-summary-item label="Nome da rua" [fullValue]="true" value="{{
              drawer && drawer.drawerAddress ? drawer.drawerAddress.street : ''
            }}"></fibra-summary-item>
          <fibra-summary-item label="Número" [fullValue]="true" value="{{
              drawer && drawer.drawerAddress ? drawer.drawerAddress.number : ''
            }}">
          </fibra-summary-item>
          <fibra-summary-item label="Complemento" [fullValue]="true" value="{{
              drawer && drawer.drawerAddress
                ? drawer.drawerAddress.complement
                : ''
            }}"></fibra-summary-item>
          <fibra-summary-item label="Bairro" [fullValue]="true" value="{{
              drawer && drawer.drawerAddress
                ? drawer.drawerAddress.neighborhood
                : ''
            }}"></fibra-summary-item>
        </div>
        <div class="summary-row" *ngIf="drawer.drawerAddress !== undefined">
          <fibra-summary-item [fullValue]="true" label="Cidade" value="{{
              drawer && drawer.drawerAddress ? drawer.drawerAddress.city : ''
            }}">
          </fibra-summary-item>
          <fibra-summary-item label="Estado" value="SP"></fibra-summary-item>
        </div>
        <div class="summary-divider"></div>
        </div>

      <p class="summary-subtitle">Dados do título</p>
      <div class="summary-row">
        <fibra-summary-item [fullValue]="true" label="Carteira" value="{{
            step2 && step2.titleInformation
              ? step2.titleInformation.carteira
              : ''
          }}">
        </fibra-summary-item>

        <fibra-summary-item label="Conta" [fullValue]="true" value="{{
            step2 && step2.titleInformation
              ? step2.titleInformation.conta
              : ''
          }}">
        </fibra-summary-item>

        <fibra-summary-item label="Seu número" [fullValue]="true" value="{{
            step2 && step2.titleInformation
              ? step2.titleInformation.yourNumber
              : ''
          }}">
        </fibra-summary-item>

        <fibra-summary-item *ngIf="step2.titleInformation.coin === 1" label="Valor do título" [fullValue]="true" value="{{
            (step2 && step2.titleInformation
              ? step2.titleInformation.titleValue
              : '-'
            ) |  currency: 'BRL'
          }}">
        </fibra-summary-item>

        <fibra-summary-item *ngIf="step2.titleInformation.coin !== 1" label="Valor do título" [fullValue]="true" value="{{
            (step2 && step2.titleInformation
              ? step2.titleInformation.titleValue
              : '-'
            ) | currency: 'USD':'symbol-narrow'}}">
        </fibra-summary-item>


      </div>
      <div class="summary-row">

        <fibra-summary-item label="Data de emissão" [fullValue]="true" value="{{ step2 && step2.titleInformation ? createDate : '' }}">
        </fibra-summary-item>

        <fibra-summary-item label="Chave de acesso nota fiscal" [fullValue]="true" value="{{
            step2 && step2.titleInformation
              ? step2.titleInformation.fiscalKey
              : ''
          }}">
        </fibra-summary-item>

        <fibra-summary-item label="Moeda" [fullValue]="true" value="{{
            step2 && step2.titleInformation
              ? step2.titleInformation.coin === 1
                ? 'Real'
                : 'Dólar'
              : ''
          }}">
        </fibra-summary-item>
        <fibra-summary-item *ngIf="step2.titleInformation.coin === 1" label="Valor de desconto" [fullValue]="true" value="{{
            (step2 && step2.titleInformation && step2.titleInformation.discount
              ? step2.titleInformation.discount
              : '-'
            ) | currency: 'BRL'
          }}">
        </fibra-summary-item>

        <fibra-summary-item *ngIf="step2.titleInformation.coin !== 1" label="Valor de desconto" [fullValue]="true" value="{{
            (step2 && step2.titleInformation && step2.titleInformation.discount
              ? step2.titleInformation.discount
              : '-'
            ) | currency: 'USD':'symbol-narrow'
          }}">
        </fibra-summary-item>

      </div>
      <div class="summary-row">
        <fibra-summary-item *ngIf="step2.titleInformation.coin === 1" label="Mora (aplicação por dia)" [fullValue]="true" value="{{
            (step2 && step2.titleInformation && step2.titleInformation.mora
              ? step2.titleInformation.mora
              : '-') | currency: 'BRL'
          }}">
        </fibra-summary-item>

        <fibra-summary-item *ngIf="step2.titleInformation.coin !== 1" label="Mora (aplicação por dia)" [fullValue]="true" value="{{
            (step2 && step2.titleInformation && step2.titleInformation.mora
            ? step2.titleInformation.mora
            : '-'
            ) | currency: 'USD':'symbol-narrow'}}">
        </fibra-summary-item>

        <fibra-summary-item label="Data de vencimento" [fullValue]="true" value="{{ step2 && step2.titleInformation ? dueDate : '-' }}">
        </fibra-summary-item>

        <fibra-summary-item *ngIf="step2.titleInformation.coin === 1" label="Multa" [fullValue]="true" value="{{
            (step2 && step2.titleInformation && step2.titleInformation.tax
              ? step2.titleInformation.tax
              : '-')
          }}">
        </fibra-summary-item>
        <fibra-summary-item *ngIf="step2.titleInformation.coin !== 1" label="Multa" [fullValue]="true" value="{{
          (step2 && step2.titleInformation && step2.titleInformation.tax
            ? step2.titleInformation.tax.replace('R$ ', '$ ') 
            : '-') 
        }}">
        </fibra-summary-item>

        <fibra-summary-item label="Data limite para desconto" [fullValue]="true" value="{{
            step2 &&
            step2.titleInformation &&
            step2.titleInformation.limitDiscountDate
              ? limitDiscountDate
              : '-'
          }}">
        </fibra-summary-item>
      </div>
      <div class="summary-row">
        <fibra-summary-item [fullValue]="true" label="Espécie" value="{{
            step2 && step2.titleInformation && step2.titleInformation.especie
              ? especie
              : '-'
          }}">
        </fibra-summary-item>

        <fibra-summary-item label="Mensagens" [fullValue]="true" value="{{
          message
              ? message
              : '-'
          }}">
        </fibra-summary-item>
      </div>
      <div class="summary-row">
        <fibra-summary-item label="Protestar" [fullValue]="true" value="{{
            step2 &&
            step2.titleInformation &&
            step2.titleInformation.protestDate
              ? 'Sim'
              : 'Não'
          }}">
        </fibra-summary-item>
        <fibra-summary-item label="Dias para protestar" [fullValue]="true" value="{{
            step2 &&
            step2.titleInformation &&
            step2.titleInformation.protestDate
              ? step2.titleInformation.valueDays
                ? step2.titleInformation.valueDays + ' dias'
                : '30 dias'
              : '-'
          }}">
        </fibra-summary-item>
        <fibra-summary-item label="Data de início do protesto" [fullValue]="true" value="{{
            step2 &&
            step2.titleInformation &&
            step2.titleInformation.protestDate
              ? step2.titleInformation.protestDate
              : '-'
          }}">
        </fibra-summary-item>
      </div>
      <div class="buttons">
        <button type="button" class="leaked-button" (click)="editData()">
          Editar
        </button>
        <button type="button" class="secondary-button" (click)="sendInfo()">
          Enviar
        </button>
      </div>
    </div>
  </div>
  <fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>

</div>

<fibra-modal-validation  
  *ngIf="showModalValidation"
  [apiCallback]="apiCallback"
  (closeEvent)="closeModal()"  
  [apiPayload]="requestDataApi"
  (responseValidation)="responseApi($event)">
</fibra-modal-validation>
<fibra-modal-conclusao-titulo *ngIf="showModalConclusao" (closeEvent)="closeModalConclusao()"
[showDownload]="showDownload" [base64Pdf]="base64Pdf" (showMsgError)="showMsgErrorDownload()">
</fibra-modal-conclusao-titulo>