import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fibra-side-card-derivative',
  templateUrl: './side-card-derivative.component.html',
  styleUrls: ['./side-card-derivative.component.scss'],
})
export class SideCardDerivativeComponent implements OnInit {
  @Input() loading = true;
  @Input() hide = false;
  @Input() options: {
    net: number,
    credito: number,
    debito: number
  };

  constructor() {}

  /**
   * @description
   * Add a Title to componente on view
   */


  ngOnInit() {
  }

  
}


