import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeysEntity, KeysResponse, KeysSearchResponse, TemPendenciaResponse } from './pix-key-entity';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { flatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PixGetKeysDataRepository {

  constructor(private http: HttpClient) { }

  public getKeys(): Observable<KeysResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysResponse>(`${environment.URLBase}v1/pix/chave/listar`, httpOptions);
  }

  public verifyKey(key: string): Observable<KeysSearchResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/chave?chave=${key}`, httpOptions);
  }

  public getKeysIb() {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/operacaochave/conta-selecionada/status/PENDENTE`, httpOptions);

  }

  public getKeysApproved() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/operacaochave/empresa-selecionada`, httpOptions);
  }

  public getLimitKeys() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/chave/validarlimitechaves`, httpOptions);
  }

  public getSolicitationSendPending() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/operacaochave/reivindicacao/portabilidadeexterna-posse/status/pendente`, httpOptions);
  }
  public getSolicitationSendRefused() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/operacaochave/reivindicacao/portabilidadeexterna-posse/status/recusado`, httpOptions);
  }

  public getSolicitationSendApproved() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/operacaochave/reivindicacao/portabilidadeexterna-posse/status/aprovado`, httpOptions);
  }
  public getRequestReceivedRefused() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/operacaochave/doacoes-por-empresa/recusado`, httpOptions);
  }

  public getRequestReceivedApproved() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/operacaochave/doacoes-por-empresa/aprovado`, httpOptions);
  }

  public getRequestReceivedPending() {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<KeysSearchResponse>(`${environment.URLBase}v1/pix/operacaochave/doacoes-por-empresa/pendente`, httpOptions);
  }

  public temPendencias(): Observable<TemPendenciaResponse> {
    return this.http.get<TemPendenciaResponse>(`${environment.URLBase}v1/pix/operacaochave/tempendencias`, { headers: getHeader() });
  }

}
