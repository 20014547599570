import { AbstractControl } from '@angular/forms';
import { CpfValidator } from '@src/shared/validators/cpf.validator';
import { CnpjValidator } from '@src/shared/validators/cnpj.validator';

export class CpfCnpjValidator {
  /**
   *
   * @param AbstractControl control
   * @return object ou null caso válido
   */
  static validate(control: AbstractControl): { [key: string]: boolean } {
    if (this.validCpfCnpj(control.value)) {
      return null;
    }
    // tslint:disable-next-line: object-literal-key-quotes
    return { cpfCnpj: true };
  }

  /**
   * Valida um CPF/CNPJ.
   *
   * @param cpfCnpj valor do cpf/cnpj a ser validado.
   * @return boolean informando se o cpf/cnpj é válido ou não.
   */
  static validCpfCnpj(cpfCnpj: any): boolean {
    if (!cpfCnpj) {
      return false;
    }

    const ehCNPJ = !!~cpfCnpj.indexOf('/')
    const valorSemMascara = cpfCnpj.replace(/\D/g, '');
    const temMascara = valorSemMascara != cpfCnpj;

    if ((!temMascara || !ehCNPJ) && valorSemMascara.length === 11) {
      return CpfValidator.validCpf(valorSemMascara);
    } else if ((!temMascara || ehCNPJ) && valorSemMascara.length === 14) {
      return CnpjValidator.validCnpj(valorSemMascara);
    }

    return false;
  }
}
