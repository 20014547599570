<ng-template #content let-modal>
  <div class="dda-container">
    <div class="modal-header">
      <div class="generic-info">
        <img alt="" [src]="imgSrc" class="img-medium mb-3" />
        <h5 class="font-weight-bold" [ngClass]="{'fg-yellow': typeMessage === 'warn', 'fg-green': typeMessage === 'success', 'fg-red': typeMessage === 'error'}" >
          {{ title }}
        </h5>
      </div>
    </div>
    <p class="p-4"><span class="label-medium">{{ message }}</span></p>
    <div class="button-container">
        <button type="button" class="btn btn-block btn-outline-primary" (click)="closeModal();goToView('/dda')">Voltar</button>
        <button type="button" class="btn btn-block btn-outline-primary" (click)="closeModal();goToView('/dda','boletos')">Novo pagamento de boleto</button>
        <button type="button" class="btn btn-block secondary-button-dda w-100" (click)="closeModal();goToView('/transaction-approval')">Acompanhar transação</button>
    </div>
  </div>
</ng-template>