import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'fibra-transfer-approval-detail',
  templateUrl: './transfer-approval-detail.component.html',
  styleUrls: ['./transfer-approval-detail.component.scss'],
})
export class TransferApprovalDetailComponent implements OnChanges, OnInit {
  private _data: any;

  public get data() {
    return this._data;
  }

  @Input() public set data(value: any) {
    this._data = value;

    if (this._data && this._data.info_fluxo_transacao && this._data.info_fluxo_transacao.usuarios_aprovacao) {
      this._data.info_fluxo_transacao.aprovadores = this._data.info_fluxo_transacao.usuarios_aprovacao.filter(a => (a.obrigatoria || a.desc_acao === 'Aprovou' || a.reprovou) && !a.substituida);

      this._data.info_fluxo_transacao.aprovadores.forEach(a => {
        if (a.desc_acao == 'Recusou' && !a.reprovou) {
          a.desc_acao = 'Pendente';
        }
      });

      this._data.info_fluxo_transacao.aprovadores = this._data.info_fluxo_transacao.aprovadores.filter(a => a.desc_acao != 'Pendente');
    }
  }

  @Input() index;
  @Input() loading;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  public refusedOrApproved = (flag): boolean => {
    return flag !== 'RECUSADO' && flag !== 'APROVADO';
  }

  public benefitType = (benefit, account) => {
    if (!benefit || !account) {
      return;
    }

    if (benefit.num_cpf_cnpj === account.num_cpf_cnpj) {
      return 'Mesma titularidade';
    }
    return 'Outra titularidade';
  }

  public showPaymentDate = (status) => {
    return (status !== 'AGENDADO' && status !== 'PENDENTE');
  }

}
