<div class="approval-list" id="body-content" style="width: 1250px;">
  <header class="approval-list__header">
    <img class="logo" src="/assets/svg/logo_fibra.svg" alt="Logo Fibra" />
    <p class="tagline">
      Internet Banking - Banco Fibra - Empresa: {{company[0].name}} - CNPJ {{company[0].modality_account}} - {{ this.date }}  {{ this.hour }}.
    </p>
    <h3 class="headline">Comprovantes</h3>
  </header>
  <main class="approval-list__content">
    <ul class="approval-list__content__tabs">
      <li class="item">{{tabsType === 'PIX_RECEBIDOS' ? 'PIX Recebido' : tabsType | titlecase }}</li>
      <li class="item">
        Conta
        <span *ngIf="accountType === ''" class="item__selected">{{
          accountType || 'Todas'
        }}</span>
        <span *ngIf="accountType !== ''" class="item__selected">{{
          accountType | account
        }}</span>
      </li>
      <li class="item">Tipo <span class="item__selected">Todos</span></li>
      <li class="item">
        Período
        <!-- <span class="item__selected">{{ diffDateDays }} dias</span> -->
        <span class="item__selected">{{date1 | date:'d'}} {{date1 | date:'MMM':'UTC':'pt-br'}}/{{date1 | date:'y'}}</span>
        à
        <span class="item__selected">{{date2 | date:'d'}} {{date2 | date:'MMM':'UTC':'pt-br'}}/{{date2 | date:'y'}}</span>
      </li>
    </ul>
    <div class="empty-info" *ngIf="hide">
      <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
        message="Tente uma nova pesquisa inserindo novos parâmetros.">
      </fibra-generic-info>
    </div>
    <div *ngIf="!hide" class="approval-list__content__table">
      <table class="approval-table">
        <tr style="background-color: #efefef; border: 1px solid #ddd; width: 100%;">
          <th style="background-color: #efefef;" class="tbl-col-1">Tipo de transação</th>
          <th *ngIf="tabsType !== 'PIX_RECEBIDOS'" style="background-color: #efefef;" class="tbl-col-2">Nome do Beneficiário</th>
          <th *ngIf="tabsType === 'PIX_RECEBIDOS'" style="background-color: #efefef;" class="tbl-col-2">Nome Pagador</th>
          <th style="background-color: #efefef;" class="tbl-col-3">Banco</th>
          <th style="background-color: #efefef;" class="tbl-col-4">Valor</th>
          <th *ngIf="tabsType !== 'PIX_RECEBIDOS'" style="background-color: #efefef;" class="tbl-col-5">Conta Débito</th>
          <th *ngIf="tabsType === 'PIX_RECEBIDOS'" style="background-color: #efefef;" class="tbl-col-5">Conta Crédito</th>
          <th style="background-color: #efefef;" class="tbl-col-6">Nro Compromisso</th>
          <th style="background-color: #efefef;" class="tbl-col-7">Ref Lote</th>
          <th style="background-color: #efefef;" class="tbl-col-8">Status</th>
        </tr>
        <tr *ngFor="let item of dataGeral; index as i"
            style="border: 1px solid transparent; background-color: white;"
            [ngStyle]="{'height': i > 12 ? '104px' : '105.15px'}">


          <!-- Data -->
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" colspan="11"  *ngIf="item?.cod_transacao === undefined" style="border: 0px solid yellow;">
            <span class="datas"> {{item | date:'d'}} de {{item | date:'MMMM':'UTC':'pt-br'}} de {{item | date:'y'}}</span>
          </td>

          <span style="display: none">{{addQtd()}}</span>

          <!-- Comprovante -->
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" *ngIf="item?.cod_transacao !== undefined" class="tbl-col-1" style="padding-left: 40px">
            <span *ngIf="tabsType !== 'PIX_RECEBIDOS'">{{item?.tipo_transacao === 'PAGAMENTO'
              ? 'Pgto. de boleto'
              : tratarTipoTransacao(item?.tipo_transacao) }}
            </span>
            <span *ngIf="tabsType === 'PIX_RECEBIDOS'">Pix Recebido
            </span>
          </td>
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" *ngIf="item?.cod_transacao !== undefined" class="tbl-col-2">
            <p class="tipo-transacao">{{ item?.NomeBeneficiario ? item?.NomeBeneficiario : item?.NomePagador }}</p>
            <p class="nome_favorecido">CNPJ/CPF: {{ item?.num_cpf_cnpj.trim()}}</p>
          </td>
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" *ngIf="item?.cod_transacao !== undefined" class="tbl-col-3">{{ 
            item.BancoPagador !== ' - ' ? item.BancoPagador : tratarBanco(item?.Banco) }}</td>
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" *ngIf="item?.cod_transacao !== undefined" class="tbl-col-4">{{ item?.vlr_transacao | currency: 'BRL' }}</td>
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" *ngIf="item?.cod_transacao !== undefined" class="tbl-col-5">{{ item?.ContaDebito }}</td>
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" *ngIf="item?.cod_transacao !== undefined">{{ item?.nr_Compromisso }}</td>
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" *ngIf="item?.cod_transacao !== undefined">{{ item?.RefLote == '' || item.RefLote == null ? '-      ' : item.RefLote }}</td>
          <td  [ngStyle]="{'padding-bottom': i === 11 ? '263px' : getMarginLastNumber(i)}" *ngIf="item?.cod_transacao !== undefined">
            <p *ngIf="tabsType !== 'PIX_RECEBIDOS'" class="desc_status">
              {{ item?.desc_status }}
            </p>
            <p *ngIf="tabsType === 'PIX_RECEBIDOS'" class="desc_status">
              RECEBIDO
            </p>
          </td>
        </tr>
      </table>
    </div>
  </main>
  <div class="card-content">
    <div class="card">
      <div class="html2pdf__page-break"></div>
      <br />
      <div style="display: table; padding: 25px 0px 10px 20px; width:100%;">
          <p>Se precisar de ajuda, entre em contato com a gente.</p>
      </div> 
      <br />
      <div class="card-body" #atendimento>
        <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
        <div class="text">
          <div class="title">Canais exclusivos</div>
          <div class="desc">Dúvidas e apoio</div>
        </div>
      </div>
      <div class="card-bottom">
        <p>
          <b>Corporate, Agro e PMEs</b>
          (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
        </p>
        <p>
          <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
        </p>
      </div>
    </div>
    <div class="card">
      <div class="card-body" #sac>
        <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
        <div class="text">
          <div class="title">SAC</div>
          <div class="desc">Sugestões, reclamações e elogios</div>
        </div>
      </div>
      <div class="card-bottom">
        <p>
          <b>Canal de Atendimento ao Cliente</b>
        </p>
        <p>
          0800 727 0132
        </p>
        <p>
          <b>Canal exclusivo para deficientes auditivos ou de fala</b>
        </p>
        <p>
          0800 200 6921
        </p>
      </div>
    </div>
    <div class="card">
      <div class="card-body" #ouvidoria>
        <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
        <div class="text">
          <div class="title">Ouvidoria</div>
          <div class="desc">Demandas não solucionadas</div>
        </div>
      </div>
      <div class="card-bottom">
        <p>
          0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
        </p>
        <p>
          <b>&nbsp;</b>
        </p>
      </div>
    </div>
  </div>
</div>
