import { Component, OnChanges, Input } from '@angular/core';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'fibra-payment-revision-detail',
  templateUrl: './payment-revision-detail.component.html',
  styleUrls: ['./payment-revision-detail.component.scss'],
})
export class PaymentRevisionDetail implements OnChanges {
  @Input() item: any;

  constructor() {}

  ngOnChanges() {
    console.log('dataDetail', this.item)
  }

  removeString = (wordToRemove, phrase) => {
    if(phrase.split(wordToRemove)[0] === ""){
        return phrase.split(wordToRemove)[1];
    } else {
        return phrase;
    }
  }

  formatDate = (date) => {
    const dt = date.split('-');
    return dt[2] + '/' + dt[1] + '/' + dt[0];
  }

  formatTime = (date) => {
    return date.split('T')[1];
  }

  public generatePDF() {

    const data = document.getElementById('payment-receipt-content');
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
    {
      html2canvas(data,{
        windowWidth: document.body.scrollWidth,
        windowHeight: document.body.scrollHeight,
        x: 0,
        y: window.pageYOffset,
      }).then((canvas) => {

        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/jpeg');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight);
        pdf.save('BancoFibraPagamento.pdf');
      });
    }
    else
    {
      html2canvas(data,{
        windowWidth: document.body.scrollWidth,
        windowHeight: document.body.scrollHeight,
        x: 0,
        y: window.pageYOffset,
      }).then((canvas) => {

        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/jpg');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        pdf.addImage(contentDataURL, 'JPG', 0, position, imgWidth, imgHeight);
        pdf.save('BancoFibraPagamento.pdf');
      });
    }
  }
}

