<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div
  class="content-page"
  *fibraHasRole="
    '
      TRANSF.TRANSF_MSM_TITU,
      TRANSF.TRANSF_OTR_TITU,
      TRANSF.EXC_BEN,
      TRANSF.TRANSF_MSM_TIT_OTR_BC,
      TRANSF.TRANSF_OTR_TIT_BC'
  "
>
  <fibra-breadcrumb
    bottom="0"
    infoAccount="Transações"
    roule="Alterar limites transacionais"
    arrow="false"
    downloadDoc="false"
    [showBtnLimitPix]="true"
  ></fibra-breadcrumb>

  <section class="body-content content-beneficiary" [ngClass]="setTab === 'limits' ? 'putOverflow-y put-with-section' : ''">
    <!-- TABS -->
    <div class="tabs-beneficiary">
      <div
        class="tab-1"
        [ngClass]="setTab === 'times' ? 'tab-selected' : ''"
        (click)="toggleTab('times')"
      >
        <span>Horário</span>
      </div>

      <div
        class="tab-1"
        [ngClass]="setTab === 'limits' ? 'tab-selected' : ''"
        (click)="toggleTab('limits')"
      >
        <span>Meus Limites</span>
      </div>

      <div
        class="tab-2"
        [ngClass]="setTab === 'approval' ? 'tab-selected' : ''"
        (click)="toggleTab('approval')"
      >
        <span>Aprovações</span><span class="notif" *ngIf="notif"></span>
      </div>
    </div>
    <!-- FIM DAS TABS -->

    <!-- HORARIOS -->
    <div *ngIf="setTab === 'times'" class="tab-1-show">
      <fibra-limit-change-time
        [changeTime]="editTimeCall"
        [timeNight]="timeNight"
        [timeDay]="timeDay"
        (exitComponent)="readExitComponent($event)"
      ></fibra-limit-change-time>

      <div>
        <!-- Buttons -->
        <div class="row row-top-horario">
          <div class="col-1" *ngIf="editTimeCall">
            <button class="btnCancelar" (click)="cancelarTime()">Cancelar</button>
          </div>
          <!-- !editTimeCall ? editTimeLimit() : saveTimeLimit() -->
          <div class="col-6">
            <button
              class="btn-edit-save-time"
              [ngClass]="!editTimeCall ? '' : 'margin-save'"
              (click)="gotoLimitPix()"
            >
              {{ editTimeCall ? "Alterar e Salvar" : "Editar" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- LIMITES -->
    <div *ngIf="setTab === 'limits'" class="tab-1-show">
      <div class="layer_1 flex">
        <div class="flex-item-1 padding-right-150 title_1">
          Alterando limite das transações da conta abaixo.
        </div>
        <div class="flex-item-1 title_1">Limite máximo diário*</div>
      </div>

      <div class="layer_2 flex">
        <div class="flex-item-1 padding-right-150">
          <fibra-dropdown
            [textColor]="editCall ? '#495057' : '#4E85BE'"
            [options]="accounts"
            (change)="accountSelected($event)"
          ></fibra-dropdown>
          <span class="maxLimitAlert" *ngIf="maxLimiteAlert">Seu limite máximo diário foi alterado. Atualize os seus limites.</span>
        </div>
        <div
          *ngIf="loadingLimit"
          class="d-flex justify-content-center loading-container spinner-border-sm"
        >
          <div
            class="spinner-border spinner-border-custom text-primary spinner-border-sm"
            role="status"
          ></div>
        </div>
        <div class="flex-item-1 limite-maximo">
          <span *ngIf="!loadingLimit">{{ allowLimit | currency: "BRL" }}</span>
        </div>
      </div>

      <div class="layer_4">
        <div>
          <fibra-transactional-limits
            [transNocturnal]="false"
            [transacDayTime]="false"
            [editar]="editCall"
            [saveData]="onSaveData"
            [dataLimits]="dataLimits"
            (flagFormChange)="callBackIfChangeForm($event)"
            (dataFormChange)="getValuesFormOnChange($event)"
            (observeFormStatus)="forStatusObserveMax($event)"
            (observeFormStatusMin)="forStatusObserveMin($event)"
          ></fibra-transactional-limits>
        </div>

        

        
      </div>
    </div>

    <!-- APROVACOES -->
    <div *ngIf="setTab === 'approval'" class="tab-2-show">
      <fibra-limit-approval
        [identificationPage]="'CHANGE-TRANSACTION'"
      ></fibra-limit-approval>
    </div>

    <div *ngIf="setTab === 'limits'" class="footerBtn">
      <div class="row">
        <div class="col-1" *ngIf="editCall">
          <button class="btnCancelar" (click)="cancelEdit()">Cancelar</button>
        </div>

        <div class="col-6">
          <button
            *ngIf="!editCall"
            class="btnEditarSalvar"
            [ngClass]="editCall ? 'margin-left' : ''"
            (click)="editar()"
          >
            Editar
          </button>

          <button
           *ngIf="editCall"
            class="btnEditarSalvar"
            [ngClass]="editCall ? 'margin-left' : ''"
            [disabled]="!controlToastBack || receivErrorMin"
            (click)="saveChangeLimit()"
          >
            Alterar e Salvar
          </button>
        </div>
      </div>
      <div class="disclaimer-fixo">
        <span>
          *O limite máximo diário é consumido por todas as transações realizadas no dia.
        </span>
      </div>
    </div>
  </section>
</div>

<fibra-toast-error-message
  [color]="typeColorToast"
  (optionYes)="confirmeCancelChange()"
></fibra-toast-error-message>

<fibra-modal-validation
  #content
  (responseValidation)="responseApiModal($event)"
  *ngIf="showModalValidation"
  [apiCallback]="apiCallback"
  [apiPayload]="securityRequest()"
  (closeEvent)="closeModalApi($event)"
  (responseValidation)="securityResponse($event)"
>
</fibra-modal-validation>

<ng-container *ngIf="showModalAlertLimitChange">
  <div #modalAlert class="modal-alert-limit animate__animated animate__fadeIn animate__faster">
    <img class="img-alert" alt="Icone de Alerta" src="./assets/svg/alert-modal-limit.svg" />
    <span class="alert-text">
      Seu limite máximo diário foi alterado para {{valueChanged | currency:'BRL'}}. Atualize os seus limites.
    </span>
    <button class="btn-alert-modal" (click)="closeAlertModal(modalAlert)">Fechar</button>
  </div>

  <div class="modal-alert-limit-overlay"></div>
</ng-container>