import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'fibra-generic-info',
  templateUrl: './generic-info.component.html',
  styleUrls: ['./generic-info.component.scss'],
})
export class GenericInfoComponent implements OnChanges {
  public imageChange;
  public dataMessage;
  @Input() title?: string;
  @Input() title2?: string;
  @Input() message?: string;
  @Input() message2?: string;
  @Input() type?;
  @Input() fechar?:boolean;

  constructor(
    private router: Router,) {
    this.imageChange = [
      {
        image: '/assets/png/icon-error.png',
      },
      {
        image: '/assets/png/icon-pending.png',
      },
      {
        image: '/assets/png/icon-accreddited.png',
      },
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.dataMessage = this.onStatusMessage(this.type);
  }

  titleColor() {
    switch (this.type) {
      case 'error':
        return '#ED6C6C';
        break;
      case 'warn':
        return '#D69D10';
        break;
      case 'success':
        return '#73B599';
        break;
      default:
        return '#ED6C6C';
        break;
    }
  }

  onStatusMessage(type) {
    switch (type) {
      case 'error':
        return this.imageChange[0];
      case 'warn':
        return this.imageChange[1];
      case 'success':
        return this.imageChange[2];
      default:
        return this.imageChange[0];
    }
  }
  redirect(){
    this.router.navigate(["/dashboard"]);
  }
}


