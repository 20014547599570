<div class="main-selector">
  <fibra-company-list ></fibra-company-list>
</div>
<div class="content-page">
  <fibra-breadcrumb
    infoAccount="Cobrança / Nova cobrança"
    roule="Cadastrar título"
    arrow="true"
    infoText='Edite as informações que deseja corrigir. Depois clique em " Salvar e Avançar” para continuar o processo.'
  ></fibra-breadcrumb>
  <div class="charge-edit col-sm-11 card p-0">
    <div div class="row p-0 m-0">
      <div class="col-6 step mt-3">
        <div class="d-inline-flex number">1</div>
        <p class="d-inline">Dados do pagador</p>
      </div>
      <div class="divisor ml-5 mr-5"></div>
      <div class="w-100 p-0 ml-5 mr-5 mt-3">
        <fibra-charge-first-step 
          [guarantor]="guarantor"
          (isPersonalValid)="getPersonal($event)"
          (isAddressalValid)="getAddress($event)"
          (isDrawerValid)="getDrawer($event)"
          (isDrawerAddressValid)="getDrawerAddress($event)"
          (alterarGuarantor)="verifyGuarantor($event)"
        ></fibra-charge-first-step>
      </div>
    </div>
    <div class="top-divisor w-100"></div>
    <div class="row p-0 m-0">
      <div class="col-6 step mt-3">
        <div class="d-inline-flex number">2</div>
        <p class="d-inline">Dados do Título</p>
      </div>
      <div class="divisor ml-5 mr-5"></div>
      <div class="w-100 p-0 ml-5 mr-5 mt-3">
        <fibra-charge-second-step
          (isInfoTitleValid)="getInfoTitle($event)"
          (isDiscountValid)="verifyDiscountTitle($event)"
        >
        </fibra-charge-second-step>
      </div>
    </div>
    <div class="buttons">
        <button [hidden]="btnGuarantor"
        type="button" 
        class="secondary-button" 
        (click)="save($event)"
        [disabled]="!this.isValidPersonal || !this.isValidAddress || !this.isValidInfoTitle || discountInvalid"
      >
        Salvar e Avançar
      </button>
      <button [hidden]="!btnGuarantor"
        type="button" 
        class="secondary-button" 
        (click)="save($event)"
        [disabled]="!this.isValidPersonal || !this.isValidAddress || !this.isValidInfoTitle || !this.isValidDrawer || !this.isValidAddressDrawer || discountInvalid" 
      >
        Salvar e Avançar 
      </button>
    </div>
  </div>
</div>
