<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="container-fluid">
    <div class="row d-flex justify-content-center" *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 5">
        <div class="col-7 header py-4">
            <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                    src="/assets/svg/icon-ok.svg" alt="arrow">
                <span *ngIf="!transacaoAgendada">A Transação Pix foi realizada</span>
                <span *ngIf="transacaoAgendada">A Transação Pix foi agendada</span>
            </p>
            <p *ngIf="transacaoAgendada" class="my-3 sub-text-header">Será realizada na data agendada, mediante disponibilidade de saldo
                em conta.</p>
        </div>
        <div class="col-7 header py-4 mt-4 px-0">
            <p class="d-flex justify-content-between align-items-center my-4 px-4">
                <img class=" " src="/assets/svg/logo-pix.svg" alt="arrow">
                <span class="text-bold" *ngIf="transacaoAgendada">Comprovante de Agendamento Pix</span>
                <span class="text-bold" *ngIf="!transacaoAgendada">Comprovante de transferência Pix</span>
            </p>
            <div class="separator">

            </div>
            <div class="row mt-5 px-4">
                <div class="col-3">
                    <div>
                        <label for="">Valor</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.valorTransferencia | currency: 'BRL'}}</p>
                    </div>
                </div>
                <div class="col-3" *ngIf="transacaoAgendada">
                    <div>
                        <label for="">Data agendada</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.dataPagamento}}</p>
                    </div>
                </div>
                <div class="col-4" *ngIf="!transacaoAgendada">
                    <div>
                        <label for="">Data e hora de transferência</label>
                        <p class="mt-2 value"><span class="mr-2">{{infoComprovantes.data.obj_transf_pix.dataProcessamento | date: 'dd/MM/yyyy HH:mm'  }}</span></p>
                    </div>
                </div>
            </div>
            <div class="px-4 mt-4">
                <label for="">Descrição</label>
                <p>
                    {{infoComprovantes.data.obj_transf_pix.descricao}}
                </p>
            </div>

            <p class="px-4 my-4 text-bold">Beneficiário</p>
            <div class="row mt-4 px-4 ">
                <div class="col-6 mb-4">
                    <div>
                        <label for="">Nome</label>
                        <p class="mt-2 value" data-hj-suppress>{{infoComprovantes.data.obj_transf_pix.destino.nome}}</p>
                    </div>
                </div>
                <div class="col-4 mb-4">
                    <div>
                        <label for="">CPF/CNPJ</label>
                        <p class="mt-2 value">{{infoComprovantes.destinoBanco.cpf_cnpj_mascara}}</p>
                    </div>
                </div>
                <div class="col-12 my-4" *ngIf="infoComprovantes.data.obj_transf_pix.destino.chave != ''">
                    <div>
                        <label for="">Chave</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.destino.chave_mascara}}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <label for="">Instituição</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.destino.cod_compe_banco}} - {{infoComprovantes.data.obj_transf_pix.destino.desc_banco}} </p>
                    </div>
                </div>
                <div *ngIf="false" class="col-3">
                    <div>
                        <label for="">Agência</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.destino.agencia_conta_mascara}}</p>
                    </div>
                </div>
                <div *ngIf="false" class="col-3">
                    <div> 
                        <label for="">Conta</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.destino.num_conta_mascara}}</p>
                    </div>
                </div>
            </div>
            <p class="px-4 my-4 text-bold">Debitado de</p>
            <div class="row mt-4 px-4">
                <div class="col-6">
                    <div>
                        <label for="">Nome</label>
                        <p class="mt-2 value" data-hj-suppress>{{infoComprovantes.data.obj_transf_pix.origem.nome}}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <label for="">CPF/CNPJ</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.origem.cpf_cnpj | cpfcnpj}}</p>
                    </div>
                </div>
            </div>
            <div class="row px-4 my-4">
                <div class="col-4">
                    <div>
                        <label for="">Instituição</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.origem.cod_banco}} - Banco Fibra S/A </p>
                    </div>
                </div>
                <div class="col-3">
                    <div>
                        <label for="">Agência</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.origem.agencia_conta}}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div>
                        <label for="">Conta</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.origem.num_conta}}</p>
                    </div>
                </div>
            </div>
            <div class="separator">

            </div>
            <div class="my-4 px-4">
                <label for="">ID da transação</label>
                <p class="mt-2 value">{{infoComprovantes.data.num_compromisso}}</p>
            </div>
            <div class="separator">

            </div>

            <div class="my-4 px-4">
                <p class="sub-text" style="line-height: 18px">
                  Se precisar de ajuda, entre em contato com a gente.
                </p>
            </div>

            <div class="my-4 px-4">
                <p class="d-flex align-items-center text-apoio"><img class="mr-2" src="/assets/svg/apoio.svg" alt="arrow">
                    Canais exclusivos
                </p>
                <p class="text-apoio" style="margin-left: 31px">Dúvidas e apoio</p>
            </div>
            <div class="my-4 px-4">
                <p class="value" style="font-size: 12px">Corporate, Agro e PMEs</p>
                <p class="mt-2">(11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados</p>
                <p class="mt-4 email">relacionamento@bancofibra.com.br</p>
            </div>

            <div class="my-4 px-4">
                <p class="d-flex align-items-center text-apoio"><img class="mr-2" src="/assets/png/telefone.png" alt="arrow">
                    SAC
                </p>
                <p class="text-apoio" style="margin-left: 31px">Sugestões, reclamações e elogios</p>
            </div>
            <div class="my-4 px-4">
                <p class="value" style="font-size: 12px">Canal de Atendimento ao Cliente</p>
                <p class="mt-2">0800 727 0132</p>
                <br>
                <p class="value" style="font-size: 12px">Canal exclusivo para deficientes auditivos ou de fala</p>
                <p class="mt-2">0800 200 6921</p>
            </div>

            <div class="my-4 px-4">
                <p class="d-flex align-items-center text-apoio"><img class="mr-2" src="/assets/png/alto-falante.png" alt="arrow">
                    Ouvidoria
                </p>
                <p class="text-apoio" style="margin-left: 31px">Demandas não solucionadas</p>
            </div>
            <div class="my-4 px-4">
                <p class="mt-2">0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.</p>
            </div>


            <div class="separator">

            </div>
            <div class="d-flex justify-content-between mt-4 px-4">
                <button class="primary px-4" (click)="downloadComprovante()">Baixar comprovante</button>
                <button class="secondary px-4" (click)="goBack()">Fechar</button>
            </div>
        </div>
    </div>
    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 4">
        <div class="row d-flex justify-content-center">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação Pix pendente </span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">Enviada para aprovação do(s) master(s) da sua empresa.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0">
                        <button class="primary px-4 w-100" (click)="goBack()">Voltar</button>
                    </div>
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goToView('/transaction-approval')">Acompanhar transação</button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 1">
        <div class="row d-flex justify-content-center">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">O envio do Pix está em processamento. </span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">Favor confirmar em seu extrato a realização da transação.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goBack()">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 8">
        <div class="row d-flex justify-content-center">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação não concluida.</span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">O envio do Pix foi reijeitado pelo Banco Central.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goBack()">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 10">
        <div class="row d-flex justify-content-center">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação não concluida.</span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">Ocorreu um erro no envio do Pix.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goBack()">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 9">
        <div class="row d-flex justify-content-center">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação não concluida.</span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">O envio do Pix foi rejeitado.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goBack()">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="transacaoPendente || (infoComprovantes.data && (infoComprovantes.data.status == 0 || infoComprovantes.data.status == 14))">
        <div class="row d-flex justify-content-center">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação em processamento.</span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">Entre em contato com a nossa área de atendimento.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goToView('/transaction-approval')">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>