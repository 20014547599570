import {Component, Input, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'fibra-limit-detail',
  templateUrl: './limit-detail.component.html',
  styleUrls: ['./limit-detail.component.scss']
})
export class LimitDetailComponent implements OnInit {

  public aprovadores = [];

  private _data: any;
  public get data(): any {
    return this._data;
  }

  @Input() public set data(value: any) {
    this._data = value;

    if (this._data) {
      this.aprovadores = this._data.aprovadores.filter(a => (a.aprovacaoObrigatoria || a.status === 'Aprovado' || a.status === 'Recusado') && !a.substituida);

      if (this._data.status === 'Recusado') {
        this.aprovadores = this.aprovadores.filter(a => a.status != 'Pendente');
      }
    }
  }
  
  @Input() index;
  @Input() loading;
  @Input() identificationPage: string;

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onClick() {
    
  }
}
