<div class="box-header">
  <div
    class="stage"
    [value]="item.value"
    [ngClass]="{ active: toggle == item.value }"
    *ngFor="let item of optionList"
  >
    <label class="stage-label" (click)="tabSelected(item)">{{
      item.option
    }}</label>
  </div>
</div>
