<ng-template #content let-modal>
    <div class="modal-banner-container">

        <img src="../../../../assets/png/dda-adesao.png" class="ddaImage" />
        
        <div class="form-row personal-information" style="margin: 0 !important; width: 450px;">
                <div style="margin-top: 54px !important;
                font-size: 24px;
                margin-right: 20px;
                margin-left: 20px;">

                    <div class="title">
                            Débito Direto Autorizado (DDA)
                    </div>

                    <div class="infoView">
                        <span class="infoSubtitle">
                            O Débito Direto Autorizado (DDA) já está disponível. Faça sua adesão e facilite a gestão dos pagamentos dos seus boletos.
                        </span>
                    </div>

                    <h3 style="margin-top:20px; font-size: 16px; color: #082a4d;">Como funciona</h3>

                    <div class="infoView">
                        <span class="infoHow">
                            O DDA permite que sua empresa receba e visualize digitalmente os boletos de cobrança registrados em que consta como devedora.
                        </span>
                    </div>

                    <h3 style="margin-top:20px; font-size: 16px; color: #082a4d;">Vantagens</h3>
                    <div class="infoView">
                        <img src="../../../../assets/png/dda-vantagens-1.png" class="infoIcon" />
                        <span class="infoVant">
                            <b>Sem custo</b> para adesão e uso
                        </span>
                    </div>
                    <div class="infoView">
                        <img src="../../../../assets/png/dda-vantagens-2.png" class="infoIcon" />
                        <span class="infoVant">
                            <b>Segurança e controle</b> nos pagamentos
                        </span>
                    </div>
                    <div class="infoView">
                        <img src="../../../../assets/png/dda-vantagens-3.png" class="infoIcon" />
                        <span class="infoVant">
                            <b>Liberdade</b> para fazer o pagamento <b>onde você quiser</b> - o DDA pode estar ativo em mais de um banco
                        </span>
                    </div>
                    <div class="infoView">
                        <img src="../../../../assets/png/dda-vantagens-4.png" class="infoIcon" />
                        <span class="infoVant">
                            <b>Facilidade</b> na gestão e pagamento aqui no Internet Banking 
                        </span>
                    </div>
                    <div class="infoView">
                        <img src="../../../../assets/png/dda-vantagens-5.png" class="infoIcon" />
                        <span class="infoVant">Recebimento dos boletos <b>eletronicamente</b>, inibindo o boleto impresso</span>
                    </div>
            
                </div>

                <div class="col-12">
                    <div class="modal-email-buttons">
                        <div class="cancel" (click)="closeModal()">
                            Cancelar
                        </div>
                        <button class="confirm" (click)="goToDda()">
                          Quero aderir!
                        </button>
                    </div>
                </div>
        </div>
    </div>
</ng-template>
  
