import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Util from '@src/shared/util-common';

@Component({
  selector: 'fibra-download-daily-position',
  templateUrl: './download-daily-position.component.html',
  styleUrls: ['./download-daily-position.component.scss'],
})
export class DownloadDailyPositionComponent implements OnInit {
  removeMask = (value) => Util.removeMask(value);
  public generatePDF = (value?) => Util.generatePDF(value);
  public data;
  public approvalList;
  public accountType;
  public tabsType;
  public date;
  public hour;
  public diffDateDays;
  public dailyPositionList =[];
  public dailyPosition = false;
  public contracts = false;
  public companyCheck = false;
  public dateReferenceToShow;

  constructor() {}

  ngOnInit() {
    
    this.data = JSON.parse(localStorage.getItem('daily-export-data'));
    this.dailyPositionList = this.data.data;
    this.dailyPosition=true;     
    
  }
  
  ngAfterViewInit() {
    this.generatePDF('body-content');
    localStorage.removeItem('daily-export-data');
  }

  getDate() {

   
    const auxDate = new Date();
    this.date = Intl.DateTimeFormat('pt-BR').format(auxDate);
    this.hour = auxDate.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
    this.diffDays();
  }

  diffDays() {
    const date1 = new Date(this.data.date.dt_Inicio);
    const date2 = new Date(this.data.date.dt_Final);

    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    this.diffDateDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  }
}
