<form [formGroup]="limitList">
  <div class="container-main">
    <div class="title-header">TED</div>

    <div class="container-list">
      
        <div class="title flex">
          <div class="flex-item-1 title-cols">Limite</div>
          <div class="flex-item-1 title-cols">Mesma Titularidade</div>
          <div class="flex-item-1 title-cols">Outra Titularidade PJ</div>
          <div class="flex-item-1 title-cols">Outra Titularidade PF</div>
        </div>

        <div class="content-list">
          <div class="items flex">
            <div class="flex-item-1 title-row">Diurno</div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tedDiurnoMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tedDiurnoMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tedDiurnoMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tedDiurnoMesma" type="text" formControlName="tedDiurnoMesma" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tedDiurnoOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tedDiurnoOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tedDiurnoOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tedDiurnoOutraPJ" type="text" formControlName="tedDiurnoOutraPJ" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tedDiurnoOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tedDiurnoOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tedDiurnoOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tedDiurnoOutraPF" type="text" formControlName="tedDiurnoOutraPF" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
          </div>

          <div class="items flex">
            <div class="flex-item-1 title-row">Noturno</div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tedNoturnoMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tedNoturnoMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tedNoturnoMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tedNoturnoMesma" type="text" formControlName="tedNoturnoMesma" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tedNoturnoOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tedNoturnoOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tedNoturnoOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tedNoturnoOutraPJ" type="text" formControlName="tedNoturnoOutraPJ" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tedNoturnoOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tedNoturnoOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tedNoturnoOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tedNoturnoOutraPF" type="text" formControlName="tedNoturnoOutraPF" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
          </div>


        </div>
      
      <div *ngIf="_controlEditForm">
        <span class="notice">*No caso de aumento do limite será necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para utilizá-lo.</span><br>
        <span class="notice">**Serão considerados os limites vigentes no momento da liquidação das transações.</span>
      </div>
    </div>
  </div>

  <div class="container-main">
    <div class="title-header">Transferência (TEF)</div>

    <div class="container-list">
      
        <div class="title flex">
          <div class="flex-item-1 title-cols">Limite</div>
          <div class="flex-item-1 title-cols">Mesma Titularidade</div>
          <div class="flex-item-1 title-cols">Outra Titularidade PJ</div>
          <div class="flex-item-1 title-cols">Outra Titularidade PF</div>
        </div>

        <div class="content-list">
          <div class="items flex">
            <div class="flex-item-1 title-row">Diurno</div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tefDiurnoMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tefDiurnoMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tefDiurnoMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tefDiurnoMesma" type="text" formControlName="tefDiurnoMesma" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tefDiurnoOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tefDiurnoOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tefDiurnoOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tefDiurnoOutraPJ" type="text" formControlName="tefDiurnoOutraPJ" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tefDiurnoOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tefDiurnoOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tefDiurnoOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tefDiurnoOutraPF" type="text" formControlName="tefDiurnoOutraPF" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
          </div>

          <div class="items flex">
            <div class="flex-item-1 title-row">Noturno</div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tefNoturnoMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tefNoturnoMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tefNoturnoMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tefNoturnoMesma" type="text" formControlName="tefNoturnoMesma" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tefNoturnoOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tefNoturnoOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tefNoturnoOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tefNoturnoOutraPJ" type="text" formControlName="tefNoturnoOutraPJ" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('tefNoturnoOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('tefNoturnoOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('tefNoturnoOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="tefNoturnoOutraPF" type="text" formControlName="tefNoturnoOutraPF" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
          </div>
        </div>
      
      <div *ngIf="_controlEditForm">
        <span class="notice">*No caso de aumento do limite será necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para utilizá-lo.</span><br>
      <span class="notice">**Serão considerados os limites vigentes no momento da liquidação das transações.</span>
      </div>
    </div>
  </div>

  <div class="container-main">
    <div class="title-header">Pagamento de Boleto</div>

    <div class="container-list">
      
        <div class="title flex">
          <div class="flex-item-1 title-cols">Limite</div>
          <div class="flex-item-1 title-cols">Mesma Titularidade</div>
          <div class="flex-item-1 title-cols">Outra Titularidade PJ</div>
          <div class="flex-item-1 title-cols">Outra Titularidade PF</div>
        </div>

        <div class="content-list">
          <div class="items flex">
            <div class="flex-item-1 title-row">Diurno</div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('boletoDiurnoMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('boletoDiurnoMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('boletoDiurnoMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="boletoDiurnoMesma" type="text" formControlName="boletoDiurnoMesma" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('boletoDiurnoOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('boletoDiurnoOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('boletoDiurnoOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="boletoDiurnoOutraPJ" type="text" formControlName="boletoDiurnoOutraPJ" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('boletoDiurnoOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('boletoDiurnoOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('boletoDiurnoOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="boletoDiurnoOutraPF" type="text" formControlName="boletoDiurnoOutraPF" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
          </div>

          <div class="items flex">
            <div class="flex-item-1 title-row">Noturno</div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('boletoNoturnoMesma').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('boletoNoturnoMesma').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('boletoNoturnoMesma').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="boletoNoturnoMesma" type="text" formControlName="boletoNoturnoMesma" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('boletoNoturnoOutraPJ').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('boletoNoturnoOutraPJ').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('boletoNoturnoOutraPJ').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="boletoNoturnoOutraPJ" type="text" formControlName="boletoNoturnoOutraPJ" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
            <div class="flex-item-1 valuesLimit"><span [ngClass]="limitList.get('boletoNoturnoOutraPF').value > (maxLimiteAllow | async) ? 'exceededLimit' : ''" *ngIf="!_controlEditForm">{{limitList.get('boletoNoturnoOutraPF').value | currency:'BRL'}}</span> <input [ngClass]="limitList.get('boletoNoturnoOutraPF').hasError('max') ? 'redBorderInput' : ''" *ngIf="_controlEditForm" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="boletoNoturnoOutraPF" type="text" formControlName="boletoNoturnoOutraPF" min="0.01" max="{{limiteData[0][0].maxLimiteValue}}"></div>
          </div>
        </div>
      
      <div *ngIf="_controlEditForm">
        <span class="notice">*No caso de aumento do limite será necessário aguardar de 24 a 48 horas, após a aprovação de todos os masters, para utilizá-lo.</span><br>
      <span class="notice">**Serão considerados os limites vigentes no momento da liquidação das transações.</span>  
      </div>
    </div>
  </div>
</form>