<h2 *ngIf="!pageProduct" class="margin-top-80">Conheça todos os produtos do Banco Fibra</h2>
<div [ngClass]="pageProduct ? 'content-component margin-top-80' : 'content-component'"> 
  <div *ngFor="let item of product" class="warpper-single">
    <img [src]="item.img" alt="capital-giro">
    <h1>{{ item.title }}</h1>
    <p>
      {{ item.description }}
    </p>
    <button [routerLink]="item.link">Saiba mais</button>
  </div>
</div>