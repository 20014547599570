<section class="sideCard" [ngClass]="disabledDiv">
  <h1 class="title">Transmitir arquivo</h1>

  <div class="upload">
    <div class="upload-inner">
      <!-- Step 1 -->
      <div
        class="upload-inner-content"
        appDnd
        (fileDropped)="onFileDropped($event)"
        *ngIf="step1"
      >
        <input
          type="file"
          #fileDropRef
          id="fileDropRef"
          (change)="verifyFile($event.target.files)"
        />

        <div class="content-upload" *ngIf="success">
          <div class="info" *ngIf="!step4">
            <svg
              width="40"
              height="51"
              viewBox="0 0 40 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.2073 0.594079C26.9758 0.349781 26.6584 0.203125 26.3295 0.203125H6.63418C2.99988 0.203125 0 3.19684 0 6.83776V44.1536C0 47.7949 2.99988 50.7886 6.63418 50.7886H33.3658C37.0001 50.7886 40 47.7949 40 44.1536V14.5233C40 14.2057 39.8536 13.9004 39.6464 13.6681L27.2073 0.594079ZM27.561 4.51635L35.8904 13.2771H30.4757C28.866 13.2771 27.561 11.9819 27.561 10.3691V4.51635ZM33.3658 48.3449H6.63418C4.35384 48.3449 2.43904 46.4511 2.43904 44.1536V6.83776C2.43904 4.55302 4.34151 2.64688 6.63418 2.64688H25.1219V10.3691C25.1219 13.3381 27.5124 15.7209 30.4757 15.7209H37.561V44.1536C37.561 46.4511 35.6585 48.3449 33.3658 48.3449Z"
                fill="#8F969D"
              />
              <path
                d="M30.0368 39.9141H9.96366C9.29304 39.9141 8.74414 40.4636 8.74414 41.1359C8.74414 41.8079 9.29304 42.3578 9.96366 42.3578H30.0492C30.7198 42.3578 31.2687 41.8079 31.2687 41.1359C31.2687 40.4636 30.7198 39.9141 30.0368 39.9141Z"
                fill="#8F969D"
              />
              <path
                d="M13.7568 26.7667L18.7812 21.3535V34.6965C18.7812 35.3684 19.3301 35.9184 20.0008 35.9184C20.6718 35.9184 21.2203 35.3684 21.2203 34.6965V21.3535L26.2447 26.7667C26.4886 27.0229 26.806 27.1576 27.1349 27.1576C27.4277 27.1576 27.7327 27.0476 27.9642 26.8276C28.4523 26.3634 28.4889 25.5934 28.0255 25.1048L20.879 17.4193C20.6471 17.175 20.3301 17.0283 19.9888 17.0283C19.6471 17.0283 19.3301 17.175 19.0986 17.4193L11.9521 25.1048C11.4888 25.5934 11.5254 26.3753 12.013 26.8276C12.5253 27.2919 13.2934 27.2553 13.7568 26.7667Z"
                fill="#8F969D"
              />
            </svg>
            <br />
            <span class="label">Arraste e solte seu arquivo aqui</span>

            <br /><br />
            <span class="or">OU</span>
          </div>

          <div *ngIf="step4">
            <svg
              width="65"
              height="65"
              viewBox="0 0 65 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 35.6923L27.8947 44L45 26"
                stroke="#73B599"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="32.5"
                cy="32.5"
                r="31.5"
                stroke="#73B599"
                stroke-width="2"
              />
            </svg>

            <span class="green break">Envio bem sucedido</span>
          </div>

          <label *ngIf="!step4" class="secondary-button" for="fileDropRef"
            >Procurar arquivo</label
          >
          <label *ngIf="step4" class="secondary-button" for="fileDropRef"
            >Enviar novo arquivo</label
          >
        </div>
        <span class="label" *ngIf="!success">
          <input
            type="file"
            #fileDropRef
            id="fileDropRef"
            (change)="nextStep(2, $event.target.files)"
          />

          <label class="secondary-button" for="fileDropRef">
            Procurar novo arquivo</label
          >
        </span>
      </div>

      <!-- Arquivo + carteira -->
      <!-- Step 2 -->
      <div class="upload-inner-content" *ngIf="step2">
        <p class="tagline">
          Selecione a carteira para iniciar o envio do arquivo:
        </p>

        <div class="attachment" *ngFor="let file of files; let i = index">
          <!-- Details File -->
          <div class="single-file">
            <h4 class="file-name">
              {{ file?.name }}
            </h4>
            <img
              src="../../../../assets/img/icon-file.svg"
              width="14"
              height="20"
              alt="Nome do arquivo"
            />
          </div>
        </div>

        <fibra-dropdown
          *ngIf="!isCobranca"
          removeShadown="true"
          class="break select-option"
          [options]="optionWallet"
          (change)="mudarCarteira($event)"
          [isClear]="true"
        ></fibra-dropdown>
        <fibra-dropdown-cobranca
          *ngIf="isCobranca"
          removeShadown="true"
          class="break select-option"
          [options]="optionWallet"
          (change)="mudarCarteira($event)"
          [isClear]="true"
          [isCobranca]="isCobranca"
        ></fibra-dropdown-cobranca>
      </div>

      <!-- List of Files -->
      <!-- Step 3 -->
      <!-- Carregando arquivo -->
      <div class="upload-inner-content" *ngIf="step3">
        <p class="tagline">Fazendo envio do arquivo</p>

        <div class="attachment" *ngFor="let file of currentFile; let i = index">
          <!-- Details File -->
          <div class="single-file">
            <h4 class="file-name">
              {{ file?.name }}
            </h4>
            <img
              src="../../../../assets/img/icon-file.svg"
              width="14"
              height="20"
              alt="Nome do arquivo"
            />
          </div>

          <!-- Progress -->
          <fibra-progress [progress]="file?.progress"></fibra-progress>

          <!-- Cancelar Button -->
          <div class="col-12">
            <div class="step-buttons-container">
              <button
                type="button"
                style="
                  color: red !important;
                  width: 100% !important;
                  border: 1px solid #ed6c6c;
                  box-sizing: border-box;
                "
                class="leaked-button preview CancelDiv"
                (click)="deleteFile(i)"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="manual">
      <div class="manual-title">Manual:</div>
      <div class="manual-itens">
        <div class="manual-item" (click)="downloadItem('240', 'CNAB240')">
          CNAB 240
          <svg
            width="12"
            height="12"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4629 10.0377L10.6839 15.5254L3.905 10.3605M10.6839 0.999075L10.6839 15.5254L10.6839 0.999075Z"
              stroke="#4D86BF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 14.2637V21.0005H20.3684V14.2637"
              stroke="#4D86BF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="manual-item" (click)="downloadItem('400', 'CNAB400')">
          CNAB 400
          <svg
            width="12"
            height="12"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4629 10.0377L10.6839 15.5254L3.905 10.3605M10.6839 0.999075L10.6839 15.5254L10.6839 0.999075Z"
              stroke="#4D86BF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 14.2637V21.0005H20.3684V14.2637"
              stroke="#4D86BF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="manual-item" (click)="downloadItem('excel', 'Excel')">
          Excel
          <svg
            width="12"
            height="12"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4629 10.0377L10.6839 15.5254L3.905 10.3605M10.6839 0.999075L10.6839 15.5254L10.6839 0.999075Z"
              stroke="#4D86BF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 14.2637V21.0005H20.3684V14.2637"
              stroke="#4D86BF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <fibra-modal-validation
    #content
    *ngIf="showModalValidation"
    [apiCallback]="apiCallback"
    (closeEvent)="closeModal()"  
    [apiPayload]="requestDataApi"
    (responseValidation)="responseApi($event)">
  </fibra-modal-validation>
</section>
