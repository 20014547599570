import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ExportDataRepository } from '@src/data/repository/export-repository/export.repository';
import { AccAceEntity, CobrancaEntity, CreditoEntity } from '@src/data/repository/export-repository/export.entity';
import { ComexReportDataShare } from '@src/core/usecases/data-share/comex-report-data-share.service';

@Component({
  selector: 'fibra-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit, OnDestroy {

  public loading = false;
  public noData = false;
  public contractNum = ''
  public filterByContract = false;
  public filterBySer = true;
  public accAceArray: AccAceEntity[];
  public cobrancaArray: CobrancaEntity[];
  public creditArray: CreditoEntity[];
  public filterForm: FormGroup;
  public selectedFilter: string;
  public isAcc = true;
  public isCob = false;
  public isCar = false;
  public hasError = false;
  public fieldRequired = false;
  public serDate  = new Date();
  public radioSelected: string;
  public periods = [
    { name: 'ACC/ACE', value: 'accace' },
    { name: 'Cobrança', value: 'cob' },
    { name: 'Carta de crédito', value: 'carta' },
  ];

  constructor(private formBuilder: FormBuilder,
              private exportService: ExportDataRepository,
              public comexReport: ComexReportDataShare) {
    this.filterBySer = true;
    this.filterForm = this.formBuilder.group({
      filter: ['ser'],
      serDate: [this.serDate.toLocaleDateString()],
      contractNum: ['']
      
  });
   }

   ngOnDestroy(): void {
    sessionStorage.removeItem("excel_info")
  }


  ngOnInit() {
   
  this.radioSelected = 'ser';
  this.selectedFilter = 'accace';
  this.sendExportaData();
  }

  public changeFilterType(type: string) {
    this.fieldRequired = false;
    if( type === 'ser') {
      this.filterBySer = true;
      this.filterByContract = false;
      this.radioSelected = 'ser';
    } else if (type === 'contract') {
      this.filterBySer = false;
      this.filterByContract = true;
      this.radioSelected = 'contract';
    }
  }

  public sendExportaData() {
    this.loading = true;
    this.fieldRequired = false;
    if (this.radioSelected === 'ser') {
      if (this.selectedFilter === 'accace') {
        sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","endPeriod":"'+this.serDate+'"  }')
        localStorage.setItem('view-atual', 'exportacao-accace');
        this.accAceArray = new Array();
        this.exportService.getAccAceEmSer(this.serDate.toJSON().slice(0,10)).subscribe((accData: any) => {
          if (accData.data.length === 0) {
            this.loading = false;
            this.hasError = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.accAceArray = accData.data;
            this.accAceArray.forEach ((elem) => {
              elem.valorContratoUsd = this.formatUsd(elem.valor_moeda_estrangeira_or);
              elem.valorContratoBrl = this.formatBrl(elem.valor_moeda_nacional_or);
              elem.saldoLaeUsd = this.formatUsd(elem.saldo_acc_moeda_estrangeira);
              elem.saldoLaeBrl = this.formatBrl(elem.saldo_acc_moeda_nacional);
              elem.saldoLeBrl = this.formatBrl(elem.saldo_ace_moeda_nacional);
              elem.saldoLeUsd = this.formatUsd(elem.saldo_ace_moeda_estrangeira);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
              elem.dt_vencto2 = this.formatDate(elem.dt_vencto2);
              elem.liquidadoUsd = this.formatUsd(elem.valor_liquidado_moeda_estrangeira);
              elem.liquidadoBrl = this.formatBrl(elem.valor_liquidado_moeda_nacional);
            })
            this.isAcc = true;
            this.isCob = false;
            this.isCar = false;
            this.noData = false;
            this.hasError = false
            this.loading = false
            this.comexReport.setValue(this.accAceArray);
            this.comexReport.setReportInfo({
              title: 'Exportação',
              subtitle1: 'Operação',
              subTitle1Value: 'ACC/ACE',
              subtitle2: 'Operações em ser',
              subtitle2Value: this.serDate.toLocaleDateString()
            })
            localStorage.setItem('view-atual', 'exportacao-accace');
            localStorage.setItem('canLoadReport', 'true');
          }
          
        }, (err) => {
            this.hasError = true;
            this.noData = false;
            this.loading = false;
            localStorage.setItem('canLoadReport', 'false');
        })
      } else if (this.selectedFilter === 'cob') {
        sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","endPeriod":"'+this.serDate+'"  }')
        localStorage.setItem('view-atual', 'exportacao-cobranca');
        this.cobrancaArray = new Array();
        this.exportService.getCobrancaEmSer(this.serDate.toJSON().slice(0,10)).subscribe((cobData: any) => {
          if (cobData.data.length === 0) {
            this.loading = false;
            this.hasError = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.cobrancaArray = cobData.data;
            this.cobrancaArray.forEach ((elem) => {
              elem.saldoFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
            })
            this.isAcc = false;
            this.isCob = true;
            this.isCar = false;
            this.noData = false;
            this.hasError = false
            this.loading = false
            this.comexReport.setValue(this.cobrancaArray);
            this.comexReport.setReportInfo({
              title: 'Exportação',
              subtitle1: 'Operação',
              subTitle1Value: 'Cobrança',
              subtitle2: 'Operações em ser',
              subtitle2Value: this.serDate.toLocaleDateString()
            })
            localStorage.setItem('view-atual', 'exportacao-cobranca');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, () => {
          this.hasError = true;
          this.noData = false;
          this.loading = false;
          localStorage.setItem('canLoadReport', 'false');
        })

      } else {
        sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","endPeriod":"'+this.serDate+'"  }')
        localStorage.setItem('view-atual', 'exportacao-cartacredito');
        this.exportService.getCreditEmSer(this.serDate.toJSON().slice(0,10)).subscribe((creditData: any) => {
          if (creditData.data.length === 0) {
            this.loading = false;
            this.hasError = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.creditArray = creditData.data;
            this.creditArray.forEach ((elem) => {
              elem.saldoTotal = this.formatUsd(elem.saldo_a_vincular);
              elem.saldoUtilizado= this.formatUsd(elem.saldo_le);
              elem.dt_me = this.formatDate(elem.dt_me);
              elem.dt_vencto =  this.formatDate(elem.dt_vencto);
              elem.saldoDisponivel = this.formatUsd(elem.saldo_total);
            })
            this.isAcc = false;
            this.isCob = false;
            this.isCar = true;
            this.noData = false;
            this.hasError = false
            this.loading = false
            this.comexReport.setValue(this.creditArray);
            this.comexReport.setReportInfo({
              title: 'Exportação',
              subtitle1: 'Operação',
              subTitle1Value: 'Carta de Crédito',
              subtitle2: 'Operações em ser',
              subtitle2Value: this.serDate.toLocaleDateString()
            })
            localStorage.setItem('view-atual', 'exportacao-cartacredito');
            localStorage.setItem('canLoadReport', 'true');
          }

        }, () => {
          this.hasError = true;
          this.noData = false;
          this.loading = false;
          localStorage.setItem('canLoadReport', 'false');
        })
      }

    } else {
      this.contractNum =  this.filterForm.value['contractNum'];
      if (this.contractNum === '' || this.contractNum === '0000000000') {
        this.loading = false;
        this.fieldRequired = true;
        //alert('campo obrigatório!');
        return;
      }

      sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","referencia":"'+this.contractNum+'"  }')
      if (this.selectedFilter === 'accace') {
        this.exportService.getAccAceContract(this.contractNum).subscribe((accData: any) => {
          if (accData.data.length === 0) {
            this.loading = false;
            this.hasError = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.accAceArray = accData.data;
            this.accAceArray.forEach ((elem) => {
              elem.valorContratoUsd = this.formatUsd(elem.valor_moeda_estrangeira_or);
              elem.valorContratoBrl = this.formatBrl(elem.valor_moeda_nacional_or);
              elem.saldoLaeUsd = this.formatUsd(elem.saldo_acc_moeda_estrangeira);
              elem.saldoLaeBrl = this.formatBrl(elem.saldo_acc_moeda_nacional);
              elem.saldoLeBrl = this.formatBrl(elem.saldo_ace_moeda_nacional);
              elem.saldoLeUsd = this.formatUsd(elem.saldo_ace_moeda_estrangeira);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
              elem.dt_vencto2 = this.formatDate(elem.dt_vencto2);
              elem.liquidadoUsd = this.formatUsd(elem.valor_liquidado_moeda_estrangeira);
              elem.liquidadoBrl = this.formatBrl(elem.valor_liquidado_moeda_nacional);
            })
            this.isAcc = true;
            this.isCob = false;
            this.isCar = false;
            this.noData = false;
            this.hasError = false
            this.loading = false
            this.comexReport.setValue(this.accAceArray);
            this.comexReport.setReportInfo({
              title: 'Exportação',
              subtitle1: 'Operação',
              subTitle1Value: 'ACC/ACE',
              subtitle2: 'Número do contrato',
              subtitle2Value: this.contractNum
            })
            localStorage.setItem('view-atual', 'exportacao-accace');
            localStorage.setItem('canLoadReport', 'true');
          }
          
        }, (err) => {
            this.hasError = true;
            this.noData = false;
            this.loading = false;
            localStorage.setItem('canLoadReport', 'false');
        })
    
      } else if (this.selectedFilter === 'cob') {
        this.cobrancaArray = new Array();
        this.exportService.getCobrancaContract(this.contractNum).subscribe((cobData: any) => {
          if (cobData.data.length === 0) {
            this.loading = false;
            this.hasError = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.cobrancaArray = cobData.data;
            this.cobrancaArray.forEach ((elem) => {
              elem.saldoFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
            })
            this.isAcc = false;
            this.isCob = true;
            this.isCar = false;
            this.noData = false;
            this.hasError = false
            this.loading = false
            this.comexReport.setValue(this.cobrancaArray);
            this.comexReport.setReportInfo({
              title: 'Exportação',
              subtitle1: 'Operação',
              subTitle1Value: 'Cobrança',
              subtitle2: 'Referência banco',
              subtitle2Value: this.contractNum
            })
            localStorage.setItem('view-atual', 'exportacao-cobranca');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, () => {
          this.hasError = true;
            this.noData = false;
            this.loading = false;
            localStorage.setItem('canLoadReport', 'false');
        })
      } else {
        this.creditArray = new Array();
        this.exportService.getCreditContract(this.contractNum).subscribe((creditData: any) => {
          if (creditData.data.length === 0) {
            this.loading = false;
            this.hasError = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.creditArray = creditData.data;
            this.creditArray.forEach ((elem) => {
              elem.saldoTotal = this.formatUsd(elem.saldo_a_vincular);
              elem.saldoUtilizado= this.formatUsd(elem.saldo_le);
              elem.dt_me = this.formatDate(elem.dt_me);
              elem.dt_vencto =  this.formatDate(elem.dt_vencto);
              elem.saldoDisponivel = this.formatUsd(elem.saldo_total);
            })
            this.isAcc = false;
            this.isCob = false;
            this.isCar = true;
            this.noData = false;
            this.hasError = false
            this.loading = false
            this.comexReport.setValue(this.creditArray);
            this.comexReport.setReportInfo({
              title: 'Exportação',
              subtitle1: 'Operação',
              subTitle1Value: 'Carta de crédito',
              subtitle2: 'Referência banco',
              subtitle2Value: this.contractNum
            })
            localStorage.setItem('view-atual', 'exportacao-cartacredito');
            localStorage.setItem('canLoadReport', 'true');
          }

        }, () => {
          this.hasError = true;
          this.noData = false;
          this.loading = false;
          localStorage.setItem('canLoadReport', 'false');
        })
      }
    }
  }

  public switchFilter(evento) {
    this.fieldRequired = false;
    this.selectedFilter = evento;
  }

  public formatDate(data:string) {
    const date = `${data.slice(8,10)}/${data.slice(5,7)}/${data.slice(0,4)}`;
    return date;
  }

  public formatUsd(value: number) {
    const val = value.toLocaleString('en-US', {minimumFractionDigits: 2});
    return val;
  }

  public formatBrl(value: number) {
    const val = value.toLocaleString('pt-br', {minimumFractionDigits: 2});
    return val;
  }

}
