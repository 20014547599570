import {
  Component,
  OnInit,
  Output,
  Input,
  forwardRef,
  EventEmitter,
  ViewChild,
  Injectable
} from '@angular/core';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbInputDatepicker,
  NgbDatepickerI18n,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { MomentDateFormatter } from '@src/shared/formatDate';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from 'jquery';
import { ToastErrorMessageComponent } from '../toast-error-message/toast-error-message.component';

const I18N_VALUES = {
  // tslint:disable-next-line
  'pt': {
    weekdays: ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
    months: ['Janeiro / ', 'Fevereiro / ', 'Março / ', 'Abril / ', 'Maio / ', 'Junho / ',
    'Julho / ', 'Agosto / ', 'Setembro / ', 'Outubro / ', 'Novembro / ', 'Dezembro / '],
  }
};

@Injectable()
export class I18n {
  /*eslint quote-props: ["error", "always"]*/
  /*eslint-env es6*/
  language = 'pt';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
 // tslint:disable-next-line
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
@Component({
  selector: 'fibra-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  providers: [ I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    { provide: NgbDateParserFormatter, useValue: new MomentDateFormatter() },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeDatepickerComponent),
      multi: true,
    },
  ],
})
export class RangeDatepickerComponent implements OnInit {
  public hoveredDate: NgbDate | null = null;
  public placement = 'bottom';
  public today: NgbDate;
  public dataSelect: NgbDate;
  @Input() isSchedules?;
  @Input() minDate: NgbDate;
  @Input() maxDate: NgbDate;
  @Input() abaSelected:string;
  @Input() abaPosicaoCarteira = '';
  @Output() fromDate: NgbDate;
  @Output() toDate: NgbDate | null = null;
  // tslint:disable-next-line:no-output-native
  @Output() change: EventEmitter<{ from: NgbDate; to: NgbDate }>;
  @ViewChild('datepicker', { static: true }) datepickerRef: NgbInputDatepicker;
  @Input() public set value(value) {
    if (value !== undefined && this.valueControl !== value) {
      this.valueControl = value;
      this.onChange(value);
    }
  }
  @Input() public diasPermitidos: number = 365;
  public valueControl = '';

  toast = new ToastErrorMessageComponent();

  constructor(private calendar: NgbCalendar) {
    this.fromDate = calendar.getToday();
    // console.log(this.fromDate)
    this.toDate = calendar.getToday();
    this.change = new EventEmitter();
    this.today = calendar.getToday();
  }

  ngOnInit() {
    // console.log(this.abaSelected)
    let resizeTimer;
    window.onresize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        this.positionDatePopup();
      }, 150);
    };
  }

  positionDatePopup() {
    this.fromDate = null
    this.toDate = null;
    const datePicker = Array.from(document.getElementsByClassName('dropdown-menu show') as HTMLCollectionOf<HTMLElement>)[0];
    if (datePicker) {
      datePicker.style.marginLeft = '0px';
      const amountOverflowed = this.isOverflowing('.content-page');

      if (amountOverflowed > 0) {
        datePicker.style.marginLeft = '-' + (amountOverflowed + 15) + 'px';

        if (this.isOverflowing('.content-page') === amountOverflowed) {
          datePicker.style.marginLeft = '0';
          datePicker.style.marginRight = '-' + (amountOverflowed + 15) + 'px';
        }
      }
    }
    this.dataSelect = null;
  }

  isOverflowing(selector) {
    const overflowingElement = document.querySelector(selector);
    return overflowingElement.scrollWidth - overflowingElement.clientWidth;
  }

  isDateInit(date: NgbDate) {
    return (
      date.equals(this.fromDate)
    );
  }

  isDateEnd(date: NgbDate) {
    return (
      date.equals(this.toDate)
    );
  }

  onDateSelection(date: NgbDate) {
    this.dataSelect = date
    if (!this.fromDate && !this.toDate) {
      //this.fromDate = date;
      if(this.isSchedules != null && this.isSchedules != undefined && this.isSchedules == false){
        this.toDate = date;
        this.fromDate = null;
      }else{
        this.toDate = null;
        this.fromDate = date;
      }
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else if(!this.fromDate && this.toDate && date.before(this.toDate)){
      this.fromDate = date;
    }else {
      if(this.isSchedules != null && this.isSchedules != undefined && this.isSchedules == false){
        this.toDate = date;
        this.fromDate = null;
      }else{
        this.toDate = null;
        this.fromDate = date;
      }
    }

    if (this.fromDate && this.toDate) {   
        this.change.emit({ from: this.fromDate, to: this.toDate });
        this.datepickerRef.close();
        this.changeIconCalendar();
    }
  }

  isHovered(date: NgbDate) {

    if(this.abaSelected) {
      if(this.hoveredDate != this.dataSelect && this.hoveredDate != null && this.dataSelect != undefined) {
        if(this.hoveredDate.after(this.dataSelect)) {
          this.isSchedules = undefined
          this.onDateSelection(this.dataSelect)
        } else if (this.hoveredDate.before(this.dataSelect)) {
          this.isSchedules = false
          this.onDateSelection(this.dataSelect)
        }
        } 
      }

    if(this.isSchedules != null && this.isSchedules != undefined && this.isSchedules == false){
      return (
        !this.fromDate &&
        this.toDate &&
        this.hoveredDate &&
        date.after(this.hoveredDate) &&
        date.before(this.toDate)
      );
    }else{
      return (
        this.fromDate &&
        !this.toDate &&
        this.hoveredDate &&
        date.after(this.fromDate) &&
        date.before(this.hoveredDate)
      );
    }
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    var teste = this.isHovered(date)
    var teste2 = this.isInside(date)
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  changeIconCalendar() {
    if (this.fromDate && this.toDate) {
      return $('#calendar').attr('src', '/assets/svg/icon_calendar.svg');
    } else {
      return $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg');
    }
  }

  isPastDate(date) {
    if (this.isSchedules) {
      if (this.today.after(date)) {
        return true;
      }
    } else {
      if (this.today.before(date)) {
        return true;
      }
    }
  }

  isPastDatePosicaoCarteira(date) {

    if (this.abaSelected) {
      if (this.maxDate != undefined) {

      if (this.maxDate.before(date)) {
        return true;
      }
    }
    if (this.minDate != undefined) {
    if (this.minDate.after(date)) {
      return true;
    }
  }
    } 
  }
  
  /*
    FormControl methods
  */
  public writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {}
  private onChange = (value: any) => {};


  diffDays(minDate: NgbDate, maxDate: NgbDate, diasPermitidos: number) {
    let minDateFormatToDate = new Date(minDate.year, minDate.month - 1, minDate.day);
    let maxDateFormatToDate = new Date(maxDate.year, maxDate.month - 1, maxDate.day);
    let days = Math.floor((maxDateFormatToDate.getTime() - minDateFormatToDate.getTime()) / 1000 / 60 / 60 / 24);

    return (days > diasPermitidos) ?  true : false
  }

  // isDisabled = (date: NgbDateStruct, current: {month: number}) => {
  //   // console.log('Allow Weekend', this.allowWeekend);
  //   return date.day === 2;
  // }

}


