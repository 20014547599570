import { Mapper } from '@src/core/base/mapper';
import {AccessEntity, FuncionalitiesUserAccessEntity} from '@src/data/repository/access-repository/access.entity';
import {AccessTypeModel, FuncionalitiesUserAccessTypeModel} from '@src/core/domain/access/accessType.model';

export class AccessRepositoryMapper extends Mapper<AccessEntity, AccessTypeModel> {
  mapFrom(param: AccessEntity): AccessTypeModel {
    return param;
  }
  mapTo(param: AccessTypeModel): AccessEntity {
    return param;
  }
}

export class FuncionalitiesAccessRepositoryMapper extends Mapper<FuncionalitiesUserAccessEntity, FuncionalitiesUserAccessTypeModel> {
  mapFrom(param: FuncionalitiesUserAccessEntity): FuncionalitiesUserAccessTypeModel {
    return param;
  }
  mapTo(param: FuncionalitiesUserAccessTypeModel): FuncionalitiesUserAccessEntity {
    return param;
  }
}
