<div *fibraHasRole="'MASTER'" class="container-flex">
  <div class="row">
    <div class="col-12">
      <h1>Dados da Empresa</h1>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-3">
      <span class="table-header">CNPJ</span>
    </div>
    <div class="col-9">
      <span class="table-header">Razão Social</span>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-3">
      <span class="label">{{ empresa.cpfCnpj | cpfcnpj }}</span>
    </div>
    <div class="col-9">
      <span class="label">{{ empresa.name }}</span>
    </div>
  </div>
  <hr />
  <div class="row mt-4">
    <div class="col-12">
      <h1>Dados da Filial</h1>
    </div>
    <div class="col-12">
      <p class="mt-2 label-medium">
        A filial precisa ter o CNPJ raiz igual da matriz.
      </p>
    </div>
  </div>
  <hr />
  <div class="row mt-2 align-items-center">
    <div class="col">
      <span *ngIf="filiais.length > 0" class="table-header">CNPJ</span>
    </div>
  </div>
  <div class="row mt-4 align-items-center" *ngFor="let filial of filiais; index as i">
    <div class="col-1">
      <span class="label-medium">{{ filial.cnpjInscricao }}</span>
    </div>
    <div class="col-2 ml-2">
      <input
        type="text"
        class="form-control text-left label-medium"
        maxlength="7"
        #ctrl="ngModel"
        [(ngModel)]="filial.identificacaoFilial"
        required
        value="{{ filial.identificacaoFilial }}"
        [textMask]="{
          mask: cnpjMask,
          placeholderChar: '_',
          guide: false,
          modelClean: true
        }"
        (blur)="validarCnpj(filial);processarValidacoes()"
      />
    </div>
    <div class="col-1">
      <img
        src="/assets/svg/trash.svg"
        alt="Excluir Filial"
        (click)="excluirFilial(i)"
      />
    </div>
    <div class="col-8"></div>
    <div class="col-12">
      <div class="row align-items-center ml-0 mt-2" *ngIf="!filial.validado">
        <img alt="" src="assets/svg/info-error.svg" />&nbsp;&nbsp;
        <small class="label-small text-danger">
          {{ filial.msgValidacao }}
        </small>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <button
      type="button"
      class="col-2 leaked-button ml-2"
      (click)="adicionarFilial()"
    >
      Adicionar Filial
    </button>
  </div>
  <hr />
  <div class="row mt-2 justify-content-end">
    <button
    [disabled]="desativarBotaoSalvarAlteracoes"
      type="button"
      class="text-center button-blue mr-2"
      (click)="processar()"
    >
      Aderir
    </button>
  </div>
</div>
<fibra-modal-validation
  *ngIf="exibirAutorizacao"
  [apiCallback]="apiCallback"
  (closeEvent)="fecharModal()"
  [apiPayload]="apiPayload"
  (responseValidation)="respostaApi($event)"
>
</fibra-modal-validation>
<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
<fibra-modal-conclusao-adesao
  *ngIf="exibirConclusao"
  [totalMasters]="totalMasters"
  [recusar]="false"
  [totalFiliais]="filiais.length"
  (closeEvent)="fecharModalConclusao()"
>
</fibra-modal-conclusao-adesao>
