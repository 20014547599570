<div class="ob-alert-content">
  <div class="header"><img src="/assets/png/logo-receipt.png" alt="Logo"></div>
  <div class="body">
    <div class="warning-text">
      <img src="/assets/png/icon-pending.png" alt="Icone de alerta" />
      <h1>Usuário sem acesso.</h1>
      <h4>Usuário não tem acesso ao Internet Banking.</h4>
      <button type="button" class="btn btn-outline-primary" (click)="redirect()">
        Voltar
      </button>
    </div>
    <div class="card-content">
      <div class="card">
        <div class="card-body" #atendimento>
          <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
          <div class="text">
            <div class="title">Canais exclusivos</div>
            <div class="desc">Dúvidas e apoio</div>
          </div>
          <div class="arrow" (click)="arrowControl(atendimento); arrow1 = !arrow1">
            <img class="icon" [src]="'/assets/png/'+ (arrow1 ? 'arrowuppayment' : 'icon_arrow_down_black')+'.png'"
              alt="arrow down" />
          </div>
        </div>
        <div class="card-bottom" open="true" style="display: none;">
          <p>
            <b>Corporate, Agro e PMEs</b>
            (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
          </p>
          <p>
            <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
          </p>
          <p>
            <b>Confirme Fibra</b>
            (11) 4130-7449, opção 1, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
          </p>
          <p>
            <a href="mailto:confirme.fibra@bancofibra.com.br">confirme.fibra@bancofibra.com.br</a>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="card-body" #sac>
          <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
          <div class="text">
            <div class="title">SAC</div>
            <div class="desc">Sugestões, reclamações e elogios</div>
          </div>
          <div class="arrow" (click)="arrowControl(sac); arrow2 = !arrow2">
            <img class="icon" [src]="'/assets/png/'+ (arrow2 ? 'arrowuppayment' : 'icon_arrow_down_black')+'.png'"
              alt="arrow down" />
          </div>
        </div>
        <div class="card-bottom" open="true" style="display: none;">
          
          <p style="font-weight: bold; font-family: 'DM Sans', sans-serif;">Canal de Atendimento ao Cliente</p> 
          <p>0800 727 0132</p>
  
          <p style="margin-top: 20px; font-weight: bold; font-family: 'DM Sans', sans-serif;">Canal exclusivo para deficientes auditivos ou de fala</p> 
          <p>0800 200 6921</p>

        </div>
      </div>
      <div class="card">
        <div class="card-body" #ouvidoria>
          <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
          <div class="text">
            <div class="title">Ouvidoria</div>
            <div class="desc">Demandas não solucionadas</div>
          </div>
          <div class="arrow" (click)="arrowControl(ouvidoria); arrow3 = !arrow3">
            <img class="icon" [src]="'/assets/png/'+ (arrow3 ? 'arrowuppayment' : 'icon_arrow_down_black')+'.png'"
              alt="arrow down" />
          </div>
        </div>
        <div class="card-bottom" open="true" style="display: none;">
          <p>
            A Ouvidoria é um canal de comunicação direto entre o cliente e o Banco, o qual atua com total imparcialidade
            e transparência.<br />
            Tal canal é exclusivo para o atendimento às demandas dos clientes e usuários de produtos e serviços, que não
            tenham sido solucionadas de maneira satisfatória nos canais de atendimento primário da instituição. <br />
            Ressalta-se que para ser atendido pela Ouvidoria, é necessário que o cliente já tenha recorrido ao
            atendimento primário da instituição. <br />
            Fale com a Ouvidoria pelo telefone 0800 727 0132, das 10h às 16h, de segunda a sexta-feira feira, exceto
            feriados.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>