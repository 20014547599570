import { Injectable } from '@angular/core';
import { AddressTypeModelRepository } from '@src/core/repositories/charge/address-type.repository';
import { UseCase } from '@src/core/base/use-case';
import { AddressTypeModel, ChargeRequest } from '@src/core/domain/charge/address.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetAddressTypeUsecase implements UseCase<ChargeRequest, AddressTypeModel> {

  constructor(private addressTypeRepository: AddressTypeModelRepository) { }

  execute(params: ChargeRequest): Observable<AddressTypeModel> {
    return this.addressTypeRepository.getAddress(params);
  }
}
