import { AccompanimentEntity } from '@src/data/repository/dashboard/accompaniment.entity';
import { AccompanimentModel } from '@src/core/domain/dashboard/dashboard.model';
import { AccompanimentRepositoryMapper } from '@src/data/repository/dashboard/accompaniment-repository-mapper';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { AccompanimentRepository } from '@src/core/repositories/dashboard/accompaniment.repository';

@Injectable({
  providedIn: 'root',
})
export class AccompanimentDataRepository extends AccompanimentRepository {
  mapper = new AccompanimentRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAccompaniment(): Observable<AccompanimentModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    let params;

    return this.http
      .post<AccompanimentEntity>(
        `${environment.URLBase}v1/dashboard/acompanhamentos`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }
}
