import { PredictedEntity, PredictedTotalizadorEntity } from '@src/data/repository/predicted-repository/predicted.entity';
import { PredictedModel, PredictedTotalizadorModel } from '@src/core/domain/predicted/predicted.model';
import { Mapper } from '@src/core/base/mapper';

export class PredictedRepositoryMapper extends Mapper <PredictedEntity, PredictedModel> {
  mapFrom(param: PredictedEntity): PredictedModel {
    return {
      code: param.code,
      status: param.status,
      data: {
        expected_exits: param.data.vlr_total_saidas,
        expected_entries: param.data.vlr_total_entradas,
        expected_balance: param.data.vlr_saldo,
        expected_real_balance: param.data.vlr_saldo_sem_cheque_empresa
      },
    };
  }

  mapTo(param: PredictedModel): PredictedEntity {
    return {
      code: param.code,
      status: param.status,
      data: {
        vlr_total_entradas: param.data.expected_entries,
        vlr_total_saidas: param.data.expected_exits,
        vlr_saldo: param.data.expected_balance,
        vlr_saldo_sem_cheque_empresa: param.data.expected_real_balance
      },
    };
  }
}

export class PredictedTotalizadorRepositoryMapper extends Mapper <PredictedTotalizadorEntity, PredictedTotalizadorModel> {
  mapFrom(param: PredictedTotalizadorEntity): PredictedTotalizadorModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: PredictedTotalizadorModel): PredictedTotalizadorEntity {
    return {
      code: param.code,
      status: param.status,
      data: {
        totalAprovados: param.data.totalAprovados,
        totalEmAnalise: param.data.totalEmAnalise,
        totalRecusados: param.data.totalRecusados,
      }
    };
  }
}
