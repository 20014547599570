import {
  Component,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { codeBarMask } from '@src/shared/util-common';
import { Router } from '@angular/router';
import * as boleto from '@mrmgomes/boleto-utils';
import {Observable} from 'rxjs/internal/Observable';
import {getBindingElementVariableDeclaration} from 'tslint';
import { GetAccountFibraUsecase } from '@src/core/usecases/transfer/beneficiaries/get-account-fibra-usecases';
import { RequestAccountFibra } from '@src/core/domain/transfer/transfer.model';
import { RolesService } from '@src/shared/services/roles.service';

@Component({
  selector: 'fibra-quick-access-transactions',
  templateUrl: './quick-access-transactions.component.html',
  styleUrls: ['./quick-access-transactions.component.scss'],
})
export class QuickAccessTransactionsComponent {
  public active = 'first';
  public sameHolder = true;
  public digitableLine = true;
  public disabledPaymentContinue = true;
  public disabledTransferContinue = true;
  public account: string;
  public beneficiary: string;
  public doc: string;
  public radioTransferSame = false;
  public radioTransferOther = false;

  public errorAccoutInput = false;
  public loadingAccountData;

  public codeBarMask = codeBarMask;
  @Input() data;
  requestDataPayment = {
    codeBar: null,
  };

  @Output() errorAccountFibra: EventEmitter<any> = new EventEmitter();

  requestDataSameTransfer = {
    beneficiary: 'Empresa XPTO',
    cnpjCpf: '000.000.000/0001-00',
  };

  requestDataOtherTransfer = {
    beneficiary: null,
    cnpjCpf: null,
    counter: null,
  };

  public codeBarDigitavel = true;

  reactiveForm: FormGroup = new FormGroup({
    reactiveRadio: new FormControl(true),
    reactiveRadioCodeBar: new FormControl(true),
  });

  constructor(private router: Router,
    private getAccountFibra: GetAccountFibraUsecase,
    private role: RolesService) {
    this.reactiveForm.controls.reactiveRadioCodeBar.valueChanges.subscribe(
      (state: any) => {
        console.log(state);
        this.digitableLine = state;
      }
    );
    var permissoes = role.get();
    if(permissoes.includes('TRANSF.TRANSF_OTR_TITU') || permissoes.includes('TRANSF.TRANSF_MSM_TITU')){
      this.active = 'second';

      this.sameHolder = true;
      this.radioTransferSame = true;
      this.radioTransferOther = false;
      this.verifyTransferContinue();

    }else if(permissoes.includes('PAG_BOL.REALIZAR_PAG_BOL')){
      this.active = 'first';
    }
  }

  handleTabs(stage) {
    this.verifyTransferContinue();
    this.active = stage;
    if (stage === 'first') {
      return;
    }
    if (stage === 'second') {
      return;
    }
  }

  changeCodeBar(event) {
    this.requestDataPayment.codeBar = event.target.value;
  }

  getAccount(account: string) {

    this.requestDataOtherTransfer.beneficiary = "";
    this.beneficiary = "";

    this.requestDataOtherTransfer.cnpjCpf = "";
    this.doc = "";

    const success = (value) => {
      
      this.requestDataOtherTransfer.counter = value.data.num_conta;
      this.account = value.data.num_conta;

      this.requestDataOtherTransfer.beneficiary = value.data.nome_beneficiario;
      this.beneficiary = value.data.nome_beneficiario;

      this.requestDataOtherTransfer.cnpjCpf = value.data.num_cpfcnpj;
      this.doc = value.data.num_cpfcnpj;

      this.loadingAccountData = false;
      this.verifyTransferContinue()
    };

    const error = (err) => {
    };

    this.errorAccoutInput = false;
    //if (account.length === 10) {
      this.loadingAccountData = true;
      const params: RequestAccountFibra = {
        num_conta: account,
      };
      this.getAccountFibra.execute(params).subscribe(success, error);
    //}
  }

  getNumberAccount(event) {
    this.requestDataOtherTransfer.counter = event.target.value;
    this.account = event.target.value;
  }

  getNameBeneficiary(event) {
    this.requestDataOtherTransfer.beneficiary = event.target.value;
    this.beneficiary = event.target.value;
  }

  getNumberCnpjCpf(event) {
    this.requestDataOtherTransfer.cnpjCpf = event.target.value;
    this.doc = event.target.value;
  }

  sendData(typeOfData) {
    if (typeOfData === 'codeBar') {
      console.log('requestDataPayment = ', this.requestDataPayment);
      this.router.navigateByUrl(
        '/payment?boleto=' + this.requestDataPayment.codeBar + '&option=' + this.digitableLine
      );
    }
    if (typeOfData === 'holder') {
      if (this.sameHolder) {
        console.log('requestDataSameTransfer = ', this.requestDataSameTransfer);
        this.router.navigateByUrl('/bank=' + this.requestDataSameTransfer );
      }
      if (!this.sameHolder) {
        console.log(
          'requestDataOtherTransfer = ',
          this.requestDataOtherTransfer
        );
        const urlTree = this.router.parseUrl('/bank');
        urlTree.queryParams = this.requestDataOtherTransfer;

        this.router.navigateByUrl(urlTree);
      }
    }
  }

  cpfcnpjmask = (rawValue) => {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;

    if (numbers) {
      numberLength = numbers.join('').length;
    }

    if (numberLength <= 11) {
      return [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
      ];
    } else {
      return [
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '/',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
      ];
    }
  }

  validateBarcode() {
    if (this.requestDataPayment.codeBar) {
      const barcode = boleto.validarBoleto(this.requestDataPayment.codeBar);
      this.codeBarDigitavel = barcode.sucesso;
      if (this.codeBarDigitavel) {
        this.sendData('codeBar');
      }
    } else {
      this.codeBarDigitavel = false;
    }
  }

  onlyNumbers(event) {
    this.verifyPaymentContinue(event);
    return (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.keyCode === 45 ||
      event.charCode === 46
    );
  }

  onlyString(event) {
    return (
      (event.charCode > 64 && event.charCode < 91) ||
      (event.charCode > 96 && event.charCode < 123) ||
      (event.charCode > 191 && event.charCode <= 255) ||
      event.charCode === 32
    );
  }

  public verifyPaymentContinue(event) {
    this.disabledPaymentContinue = !(event.target.value.length == 44 || event.target.value.length == 47);
  }

  changeOptionTransfer(event) {
    if (event.target.form[0].checked === true) {
      this.sameHolder = true;
      this.radioTransferSame = true;
      this.radioTransferOther = false;
    } else {
      this.sameHolder = false;
      this.radioTransferSame = false;
      this.radioTransferOther = true;
    }
    this.verifyTransferContinue();
  }

  public verifyTransferContinue() {
    let account: boolean;
    let benefitiary: boolean;
    let doc: boolean;
    account = this.account && this.account.length > 0;
    benefitiary = this.beneficiary && this.beneficiary.length > 0;
    doc = this.doc && this.doc.length > 0;
    if (this.sameHolder) {
      this.disabledTransferContinue = false;
      return;
    }
    this.disabledTransferContinue = !(account && benefitiary && doc);
  }
}
