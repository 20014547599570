<div class="main-selector">
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" id="body-scroll-matrix">
  <fibra-breadcrumb
    height="16"
    bottom="-20"
    infoAccount=""
    roule="Matriz de aprovação"
  ></fibra-breadcrumb>

  <div class="matrix-content">
    <div class="rules-list">
      <fibra-list-matrix></fibra-list-matrix>
    </div>
  </div>
</div>
