import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PixTransferDataShare } from '@src/core/usecases/data-share/pix-transfer-data-share.service';
import { PixAccountEntity } from '@src/data/repository/pix/accounts/pix-accounts-entity';
import { PixGetAccountsDataRepository } from '@src/data/repository/pix/accounts/pix-get-accounts-repository';
import { PixGetKeysDataRepository } from '@src/data/repository/pix/keys/pix-get-keys-repository';
import { KeysEntity } from '@src/data/repository/pix/keys/pix-key-entity';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import { PixBeneficiarioFavoritoDataShare } from '@src/core/usecases/data-share/pix-beneficiario-favorito-data-share.service';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { AccountPixDataShare } from '@src/core/usecases/data-share/account-pix-data-share.service';
import { RolesService } from '@src/shared/services/roles.service';
import { trigger, style, animate, transition } from '@angular/animations';
import * as Util from '@src/shared/util-common';
import { PixRefundDataShare } from '@src/core/usecases/data-share/pix-refund-data-share.service';
import { GetRefundHistoric } from '@src/core/usecases/pix/refund-historic.usecase';
import { DropdownItem } from '@src/core/domain/business-models/dropdown.model';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { isArray } from 'util';

@Component({
  selector: 'fibra-pix-new-refund',
  templateUrl: './pix-new-refund.component.html',
  styleUrls: ['./pix-new-refund.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)', opacity: 0 }),
            animate('400ms', style({ transform: 'translateX(0)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [

            style({ transform: 'translateX(0)', opacity: 1 }),
            animate('400ms', style({ transform: 'translateX(100%)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class PixNewRefundComponent implements OnInit, OnDestroy {

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @Output() controlShowHideNewRefund: EventEmitter<boolean> = new EventEmitter();

  util = Util;
  devolucoes = [];
  private _company: DropdownItem;
  private _sub: Subscription;
  public array = [1, 2, 3]
  public cadastro = true;
  public selected = false;
  public loading = false;
  public showMiniLoad = false;
  public showNoKeyMsg = false;
  public keyToSearch;
  public key: KeysEntity;
  public contaToDebit;
  public cpfCnpjMask;
  public keyType = '';
  public keyValue = ''
  public validCel = false;
  public keyFound = false;
  public loadingKey = false;
  public validEmail = false;
  public validDocument = false;
  public validAleatorio = false;
  public transferValue;
  public celMask = ['+', '55', '', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public accountData: PixAccountEntity[];
  public textArea: string;
  public errorSearchKey = false;
  public bodyTransfer;
  public dataSelected: string;
  public saveFavorite: boolean = false;
  public verificaChave: boolean = false;
  public tipoChave: string;
  public bancoInfo;
  public favorito: boolean = false;
  public novaChave: boolean = false;
  public chequeEmpresaPix: boolean = false;
  public saldoDisponivel: boolean = true;
  public valorChequeEmpresa;
  public char = 30;
  public redBorder;
  public beneficiarioFavorito;
  public contaSelecionada: any;
  public valorLimiteConta = 0;
  public msgCharging = false;
  public permissoes;
  public vlLimAccount = 0;
  public loadingHistoric = true;

  warningValue: boolean = false;
  public valueRefund: number;
  dataBeneficiario$!: Observable<any>;
  dataAccountSelected: [] = [];
  payerData: any = [];
  historic: any = [];
  dataLimite: string;

  constructor(
    public getAccService: PixGetAccountsDataRepository,
    public transferShare: PixTransferDataShare,
    public verifyKeyService: PixGetKeysDataRepository,
    public beneficiarioShare: PixBeneficiarioFavoritoDataShare,
    public getBeneficiariesDataRepository: PixGetBeneficiariesDataRepository,
    private sharePixAccount: AccountPixDataShare,
    private roles: RolesService,
    private pixRefundData: PixRefundDataShare,
    private getHistoric: GetRefundHistoric, 
    private _sharedCompany: CompanyDataShare
  ) {

  }

  async ngOnInit() {
    this._sharedCompany.dataShared.subscribe((res) => {
      if (res !== undefined && isArray(res.companyList)) {
        this._company = res.companyList.filter((e) => e.selected)[0];
      }
    });

    this.dataBeneficiario$ = this.pixRefundData.dataShared.pipe(map((item: any) => item.dataRefund));

    this.dataBeneficiario$.subscribe(r => {
      this.dataLimite = r[0].dataLimiteDevolucao;
    })

    this.pixRefundData.dataShared.subscribe(async r => {
      this.dataAccountSelected = await r.accountRefund[0];

      this.getHistoric.execute(r.dataRefund[0].id).subscribe(rs => {
        this.loadingHistoric = false;
        this.historic = this.orderHistoric(rs.data.devolucoes);
        this.devolucoes = rs.data.devolucoes;
      }, _err => {
        this.loadingHistoric = false;
      })
    })

    this.pixRefundData.dataShared.subscribe(rs => {
      this.valueRefund = this.updateValueTransfer(rs.dataRefund[0].valorTransferencia, rs.dataRefund[0].valorDisponivelDevolucao);
      this.payerData = rs;
      this.payerData.dataRefund[0].bloqueioCautelar ? this.transferValue = rs.dataRefund[0].valorTransferencia : '';
    })

    // Filtra e passa para o componente aconta selecionada 
    // na tela anterior
    this.filterAccountSelected();

    this.permissoes = this.roles.get();
  }

  ngOnDestroy(): void {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  htmlShowCpf(val) {
    let format = Util.formatCpfCnpj(val);
    return Util.protectShowCpf(format);
  }

  filterAccountSelected() {
    let dtaAccSeleted, dtaAccData

    const dependentPromisses = [
      new Promise((resolve, _reject) => {
        this.pixRefundData.dataShared.subscribe(r => {
          dtaAccSeleted = r.accountRefund[0];
          resolve('atribuida conta selecionada');
        })
      }),
      new Promise((resolve, _reject) => {
        this.sharePixAccount.dataShared.subscribe((res) => {
          dtaAccData = res.filter(r => r.num_conta === dtaAccSeleted.num_conta);
          this.verifyQuantityAccount(dtaAccData);
          resolve('resolve a conta selecionada');
        })
      })
    ];

    Promise.all(dependentPromisses).then(() => {
      this.accountData = dtaAccData;

      if (this._company.ind_cliente_saida_risco || this._company.ind_problema_credito || this._company.ind_pauta_vencida) {
        dtaAccData.forEach(c => {
          c.saldo.vlr_limite = 0;
        }) 
      }
    })
  }

  orderHistoric(arr) {
    const groups = arr.reduce((group, lanc) => {
      const date = lanc.data.split('T')[0];
      if (!group[date]) {
        group[date] = [];
      }
      group[date].push(lanc);
      return group;
    }, {});

    return Object.keys(groups).map((date) => {
      return {
        date,
        lancamentos: groups[date]
      };
    });
  }

  updateValueTransfer(val1, val2) {
    return val2 === 0 ? val1 : val2;
  }

  public setAccountDebit(event: any) {
    if (event && this.key && event.num_conta === this.key.informacao_adicional_conta.conta) {
      this.toast.callModalMessage(null, 'A conta de débito deve ser diferente da conta selecionada para realizar o crédito.', null);
      this.contaSelecionada = (Math.random() * 1000) * -1;
    } else {
      this.contaToDebit = event;
    }
  }

  public setTransferInfos() {
    console.log(this.transferValue)
    this.bodyTransfer = {
      pagador: this.payerData,
      data: this.payerData.dataRefund[0].dataPagamento,
      valor: this.transferValue,
      descricao: this.textArea,
      contaDebito: {
        nome: this.contaToDebit.desc_conta,
        conta: this.contaToDebit.num_conta,
        agencia: this.contaToDebit.cod_agencia
      },
      callback: 25,
      pagRetorno: 'transferir-newtransfer',
      confirme_linha_credito: typeof this.chequeEmpresaPix === 'undefined' ? false : this.chequeEmpresaPix 
    }

    // Verificar saldo e cheque empresa
    /* if (this.bodyTransfer.contaDebito.nome === 'CHEQUE EMPRESA' && this.verifySaldoAndChequeEmpresa()){
      this.toast.callModalMessage(null, 'Saldo Indisponivel!', null);
      return
    } */

    if (this.bodyTransfer.contaDebito.nome === 'CHEQUE EMPRESA') {
      this.chequeEmpresaPix = this.UsaChequeEmpresa();
      this.bodyTransfer.confirme_linha_credito = this.chequeEmpresaPix;
    }

    const bdy = this.setBody(this.dataSelected, this.textArea, this.transferValue, this.payerData, this.devolucoes, this.chequeEmpresaPix);
    this.transferShare.setValue({ payload: bdy, showInScreen: this.bodyTransfer,  conta_debito: this.contaToDebit});

    if ((typeof this.chequeEmpresaPix === 'undefined' || !this.chequeEmpresaPix) && this.saldoDisponivel) {
      this.controlShowHideNewRefund.emit(false);
    }
  }

  verifySaldoAndChequeEmpresa(){
    // Verificar saldo cheque empresa
    let vlr_cheque_disponivel = this.contaToDebit.saldo.vlr_cheque_disponivel;
    let verify = false;

    if (this.contaToDebit.saldo.vlr_limite === 0) {
      vlr_cheque_disponivel = 0;
    }

    if(vlr_cheque_disponivel === 0){
      verify = true;
    }
    return verify;
  }

  public setBody(_dtaSelected, textArea, transValue, payerData, valueDevolucoes, cheEmpresa) {

    return {
      num_canal_pagamento: 3,
      data_pagamento: payerData.dataRefund[0].dataPagamento,
      valor_transacao: payerData.dataRefund[0].valorTransferencia,
      valor_devolucao: transValue, //this.transferValue,
      valor_total_devolvido: this.somarValorTotal(valueDevolucoes), //this.somarValorTotal(this.devolucoes),
      descricao: textArea, //this.textArea,
      bloqueio_cautelar: payerData.dataRefund[0].bloqueioCautelar,
      conta_origem: {
        empresa_id: null,
        cod_corporativo: null,
        cpf_cnpj: payerData.dataRefund[0].cpfcnpjRecebedor,
        num_conta: payerData.accountRefund[0].num_conta,
        agencia_conta: payerData.accountRefund[0].cod_agencia,
        cod_banco: payerData.dataRefund[0].ispbRecebedor,
        nome: JSON.parse(localStorage.getItem('batch-company-name')).name,
        nome_banco: payerData.dataRefund[0].bancoRecebedor,
        desc_banco: payerData.dataRefund[0].bancoRecebedor
      },
      conta_destino: {
        empresa_id: null,
        cod_corporativo: null,
        cpf_cnpj: payerData.dataRefund[0].cpfcnpjPagador,
        num_conta: payerData.dataRefund[0].numeroContaPagador,
        agencia_conta: payerData.dataRefund[0].numeroAgenciaPagador,
        cod_banco: payerData.dataRefund[0].ispbPagador,
        nome: payerData.dataRefund[0].nomePagador,
        nome_banco: payerData.dataRefund[0].bancoPagador,
        desc_banco: payerData.dataRefund[0].bancoPagador
      },
      email_operador: localStorage.getItem('useremail'),
      nome_operador: localStorage.getItem('username'),
      id_transferencia_pix_recebida: payerData.dataRefund[0].id,
      confirme_linha_credito: typeof cheEmpresa === 'undefined' ? false : cheEmpresa,
      instant_payment_id: payerData.dataRefund[0].instantPaymentId,
      end_to_end_id: payerData.dataRefund[0].endToEndId,
    }
  }

  public goBack() {
    this.cadastro = false;
    this.controlShowHideNewRefund.emit(false);
    this.resetValores()
    if (!this.selected) {
      localStorage.setItem('view-selecionada', 'refund-transfer');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    } else {
      this.selected = false;
    }
  }

  public resetValores() {
    this.verificaChave = false;
    this.transferValue = null;
    this.contaToDebit = null;
    this.key = null;
    this.textArea = null;
  }

  validationInfo() {
    return this.warningValue || (typeof this.transferValue === 'undefined' || this.transferValue <= 0) || typeof this.contaToDebit === 'undefined' ? true : false;
  }

  public closeModalPix(value) {
    if (value == 'sim') {
      this.controlShowHideNewRefund.emit(false);
    } else if (value == 'cancelar') {
      this.chequeEmpresaPix = false;
    }
  }

  public UsaChequeEmpresa() {
    this.saldoDisponivel = true;
    let vlr_cheque_disponivel = this.contaToDebit.saldo.vlr_cheque_disponivel;
    const saldoMenosValorBloqueado = Math.round((this.contaToDebit.saldo.vlr_saldo_calculado - this.contaToDebit.saldo.vlr_bloqueado) * 1e2) / 1e2;

    if (this.contaToDebit.saldo.vlr_limite == 0) {
      vlr_cheque_disponivel = 0;
    }

    if (this.bodyTransfer.valor > saldoMenosValorBloqueado) {
      let valorMenosSaldo = 0;

      if (saldoMenosValorBloqueado < 0) {
        valorMenosSaldo = this.bodyTransfer.valor
      } else {
        valorMenosSaldo = Math.round((this.bodyTransfer.valor - saldoMenosValorBloqueado) * 1e2) / 1e2;
      }
      
      if (valorMenosSaldo > 0 && vlr_cheque_disponivel < valorMenosSaldo) {
        this.toast.callModalMessage(null, 'Saldo Indisponível!', null);
        this.saldoDisponivel = false;
        return false;
      } else if (valorMenosSaldo == 0) {
        this.saldoDisponivel = true;
        return false;
      } else {
        this.valorChequeEmpresa = valorMenosSaldo;
        this.saldoDisponivel = true;
        return true;
      }
    } else {
      return false;
    }
  }

  limitTextarea(valor) {
    const quant = 30;
    const total = valor.length;
    this.redBorder = valor.length;

    if (total <= quant) {
      this.char = quant - total;
    } else {
      this.char = valor.substr(0, quant);
    }
  }

  changeAmmount() {
    this.warningValue = this.transferValue > this.valueRefund ? true : false;
    if (this.transferValue <= 0) {
      this.warningValue = true;
      this.toast.callModalMessage(null, null, 'Valor inválido.');
    }
  }

  public somarValorTotal(arr) {
    return arr.reduce((a, b) => a + b.valor, 0);
  }

  verifyQuantityAccount(account) {
    if(account.length == 1) {
      this.accountData = account;
    }
  }

  agencyFormat(account) {
    return this.util.returnAgency(account);
  }

  accountFormat(account) {
    return this.util.formatValueAccount(account);
  }
}
