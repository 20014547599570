<div *fibraHasRole="'MASTER'" class="display-flex-card">
    <div class="fibra-card"  style="z-index: 999" *ngIf="toApprovalTerm">
        <div class="fibra-card-content">
            <div class="fibra-card-icon">
                <img alt src="/assets/svg/adhesion.svg"  />
            </div>
            <div class="fibra-card-title">
            Adesão ao DDA pendente de aprovação
            </div>
        </div>
        <div class="fibra-card-info" routerLink="/dda">
            <label>Mais informações</label>
        </div>
    </div>
    <div class="fibra-card" style="z-index: 999" *ngIf="toCancelTerm">
        <div class="fibra-card-content">
            <div class="fibra-card-icon">
                <img alt src="/assets/svg/adhesion.svg"  />
            </div>
            <div class="fibra-card-title">
            Cancelamento ao DDA pendente de aprovação
            </div>
        </div>
        <div class="fibra-card-info" routerLink="/dda">
            <label>Mais informações</label>
        </div>
    </div>

    <div class="fibra-card" style="z-index: 999" *ngIf="toApprovalExclude">
        <div class="fibra-card-content">
            <div class="fibra-card-icon">
                <img alt src="/assets/svg/adhesion.svg"  />
            </div>
            <div class="fibra-card-title">
            Exclusão de filial do DDA pendente de aprovação
            </div>
        </div>
        <div class="fibra-card-info" routerLink="/dda">
            <label>Mais informações</label>
        </div>
    </div>
    <div class="fibra-card" style="z-index: 999" *ngIf="toApprovalInclude">
        <div class="fibra-card-content">
            <div class="fibra-card-icon">
                <img alt src="/assets/svg/adhesion.svg"  />
            </div>
            <div class="fibra-card-title">
            Inclusão de filial no DDA pendente de aprovação
            </div>
        </div>
        <div class="fibra-card-info" routerLink="/dda">
            <label>Mais informações</label>
        </div>
    </div>
</div>