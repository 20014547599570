<div *ngIf="!loading" class="card-body body-accordion">
  <div class="resume">
    <span>Resumo da transação</span>
  </div>
  <div class="logo_fibra">
    <img alt="Logo-fibra" src="/assets/svg/logo_fibra.svg" />
  </div>
  <div *ngIf="data?.tipo_transacao === 'PAGAMENTO'; else TRANSFERENCIA">
    <section *ngIf="refusedOrApproved(data?.desc_status); else RECUSADO">
      <div class="recipient-dice-account content-space-between paymentView">
        <div>
          <div class="title">
            <span>Beneficiário </span>
          </div>
          <div class="resume_dices data-hj-suppress">
            <div class="recipient-dice">
              <div class="recipient-dice-item">
                <label>Nome</label>
                <span>{{
                  (data?.info_beneficiario?.nome_beneficiario | titlecase) ||
                    '-'
                }}</span>
              </div>
            </div>
            <div class="recipient-dice">
              <div class="recipient-dice-item">
                <label>CNPJ/CPF</label>
                <span>{{ data?.info_beneficiario?.num_cpf_cnpj || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="account_debit">
          <div class="title">
            <span>Conta para débito</span>
          </div>
          <div class="account_debit-item">
            <label>Banco</label>
            <span
              >{{ data?.info_conta?.cod_banco || '-' }} -
              {{ data?.info_conta?.info_banco || '-' | titlecase }}</span
            >
          </div>
          <div class="account_debit-item">
            <label>Conta</label>
            <span>{{ data?.info_conta?.num_conta || '-' | account }}</span>
          </div>
        </div>
        <div class="recipient">
          <div class="title">
            <span>Valor e Data</span>
          </div>
          <div class="resume_dices">
            <div class="recipient-dice">
              <div class="recipient-dice-item dataValueView">
                <label>Valor do boleto</label>
                <span>R$ {{ data?.vlr_transacao | number: '1.2-2' }}</span>
              </div>
              <div class="recipient-dice-item dataValueView">
                <label>Valor a ser pago</label>
                <span>R$ {{ data?.vlr_pago_transacao | number: '1.2-2' }}</span>
              </div>
              <div class="recipient-dice-item">
                <label>Desconto</label>
                <span
                  >R$
                  {{ data?.info_encargo?.vlr_desconto | number: '1.2-2' }}</span
                >
              </div>
            </div>
            <div class="recipient-dice-account">
              <div class="recipient-dice-item dataValueView">
                <label>Data de vencimento</label>
                <span>{{
                  data?.dt_vencimento | date: 'dd/MM/yyyy' || '-'
                }}</span>
              </div>
              <div
                class="recipient-dice-item dataValueView"
                *ngIf="data && data.desc_status === 'AGENDADO'"
              >
                <label>Data agendada</label>
                <span>{{ data?.dt_agendada | date: 'dd/MM/yyyy' || '-' }}</span>
              </div>
              <div
                class="recipient-dice-item dataValueView"
                *ngIf="data && showPaymentDate(data.desc_status)"
              >
                <label>Data de pagamento</label>
                <span>{{ data?.dt_execucao | date: 'dd/MM/yyyy' || '-' }}</span>
              </div>
              <div class="recipient-dice-item">
                <label>Abatimento</label>
                <span
                  >R$
                  {{
                    data?.info_encargo?.vlr_abatimento | number: '1.2-2'
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="marginBetweenRows"></div>
      <div class="recipient-dice-account content-space-between paymentView">
        <div class="recipient">
          <div class="title">
            <span>Encargos</span>
          </div>
          <div class="resume_dices">
            <div class="recipient-dice">
              <div class="value_trasnf-item encargosView">
                <label>Juros</label>
                <span
                  >R$
                  {{
                    data?.info_encargo?.vlr_juros || '-' | number: '1.2-2'
                  }}</span
                >
              </div>
              <div class="value_trasnf-item encargosView">
                <label>Multa</label>
                <span
                  >R$
                  {{
                    data?.info_encargo?.vlr_multa || '-' | number: '1.2-2'
                  }}</span
                >
              </div>
              <div class="value_trasnf-item">
                <label>Mora</label>
                <span
                  >R$
                  {{
                    data?.info_encargo?.vlr_mora || '-' | number: '1.2-2'
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="recipient">
          <div class="description">
            <div class="title"><span>Descrição</span></div>
            <span class="description-span">{{
              data?.desc_transacao || '-'
            }}</span>
          </div>
        </div>
        <div *ngIf="data?.desc_status !== 'LIQUIDADO'" class="recipient">
          <div class="margin-top-26"></div>
          <div class="operator">
            <label>Operador responsável</label>
            <span data-hj-suppress>{{ data?.nome_operador_responsavel || '-' }}</span>
          </div>
        </div>
      </div>
      <div class="transactionLine"></div>
      <div class="footer-info">
        <label>Código de barras</label>
        <span>{{ data?.num_codigo_barra || '-' }}</span>
      </div>
    </section>
    <ng-template #RECUSADO>
      <div class="recipient-dice-account content-space-between paymentView">
        <div class="recipient">
          <div class="margin-top-26"></div>
          <div class="operator">
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #TRANSFERENCIA>
    <div class="same_ownership">
      <span>{{ benefitType(data?.info_beneficiario, data?.info_conta) }}</span>
    </div>
    <div class="resume_dice_description">
      <div class="loyalty">
        <div class="title">
          <span>Tipo de Transferência</span>
        </div>
        <span class="loyalty-span">{{ data?.tipo_transacao || '-' }}</span>
      </div>
    </div>
    <div class="resume_dice">
      <div class="recipient">
        <div class="title">
          <span>Beneficiário </span>
        </div>
        <div class="resume_dices">
          <div class="recipient-dice">
            <div class="recipient-dice-item recipient-item">
              <label>Nome do beneficiário</label>
              <span data-hj-suppress>{{
                (data?.info_beneficiario?.nome_beneficiario | titlecase) || '-'
              }}</span>
            </div>
            <div class="recipient-dice-item">
              <label>CNPJ / CPF</label>
              <span>{{ data?.info_beneficiario?.num_cpf_cnpj || '-' }}</span>
            </div>
          </div>
          <div class="recipient-dice-account">
            <div class="account-bank">
              <label>Banco</label>
              <span>
                {{ data?.info_beneficiario?.cod_banco || '-' }} -
                {{ data?.info_beneficiario?.nome_banco || '-' }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="value_date">
        <div class="title">
          <span>Valor e data </span>
        </div>
        <div class="value_transf">
          <div class="value_trasnf-item">
            <label>Valor da transferência </label>
            <span>{{ data?.vlr_transacao | currency: 'BRL' || '-' }}</span>
          </div>
          <div
            class="value_trasnf-item"
            *ngIf="data && data.desc_status === 'AGENDADO'"
          >
            <label>Data agendada</label>
            <span>{{ data?.dt_agendada | date: 'dd/MM/yyyy' || '-' }}</span>
          </div>
          <div
            class="value_trasnf-item"
            *ngIf="data && data.desc_status !== 'AGENDADO'"
          >
            <label>Data da transferência</label>
            <span>{{ data?.dt_agendada | date: 'dd/MM/yyyy' || '-' }}</span>
          </div>
        </div>
      </div>
      <div class="account_debit">
        <div class="title">
          <span>Conta para débito</span>
        </div>
        <div class="account_debit-item">
          <label>Conta</label>
          <span data-hj-suppress>{{ data?.info_conta?.nome_conta || '-' | titlecase }} </span>
        </div>
        <div class="account_debit-item">
          <label>Dados da conta</label>
          <span>{{ data?.info_conta?.num_agencia_conta || '-' }} </span>
        </div>
      </div>
    </div>

    <div *ngIf="data?.isOpenBank" class="resume_dice_description">
      <div class="loyalty">
        <div class="title">
          <span>Instituição Iniciadora de Pagamento</span>
        </div>
        <span class="loyalty-span">{{ data?.iniciadora || '-' }}</span>
      </div>
    </div>

    <div class="resume_dice_description">
      <div class="loyalty" *ngIf = "data?.tipo_transacao != 'PIX' && data?.tipo_transacao != 'Pix - Open Finance' && data?.tipo_transacao != 'Devolução PIX'">
        <div class="title">
          <span>Finalidade</span>
        </div>
        <span class="loyalty-span">{{ data?.desc_finalidade || '-' }}</span>
      </div>
      <div class="description">
        <div class="title"><span>Descrição</span></div>
        <span class="description-span">{{ data?.desc_transacao || '-' }}</span>
      </div>
    </div>


  </ng-template>
    <div class="approvers">
      <div class="recipient-dice-account content-space-between paymentView">
        <div class="recipient">
          <div class="margin-top-26"></div>
          <div class="operator">
            <label>Operador responsável</label>
            <span data-hj-suppress>{{ data?.nome_operador_responsavel || '-' }}</span>
          </div>
        </div>
      </div>
      <div class="recipient-dice-account content-space-between paymentView">
        <div class="recipient">
          <div class="margin-top-26"></div>
          <div class="operator" *ngIf="data?.info_fluxo_transacao">
            <label>Fluxo de autorização</label>
            <div class="permission-transaction-box">
              <div class="transaction-info">
                <span>{{ data?.info_fluxo_transacao?.nome_grupo }}</span>
              </div>
              <div class="transaction-info grupo">
                <img src="assets/svg/tooltip.svg" alt="icon" />
                <span>{{ data?.info_fluxo_transacao?.nome_regra }}</span>
              </div>
              <div class="transaction-info status">
                <img src="assets/svg/tooltip.svg" alt="icon" />
                <span
                  >Status do grupo:
                  {{ data?.info_fluxo_transacao?.desc_status }}</span
                >
              </div>
              <div class="transaction-info usuarios">
                <img src="assets/svg/tooltip.svg" alt="icon" />
                <span>Usuários</span>
              </div>
              <div class="transaction-column">
                <ng-template #masters>
                  <div class="transaction-item" *ngFor="let approvers of data?.info_fluxo_transacao?.aprovadores">
                    <p data-hj-suppress>{{ approvers?.desc_tipo_usuario }}</p>
                    <p
                      [ngClass]="{
                        red: approvers?.desc_acao == 'Recusou',
                        green: approvers?.desc_acao === 'Aprovou',
                        yellow: approvers?.desc_acao === 'Pendente' || approvers?.desc_acao === 'Aguardando Aprovação'
                      }"
                    >
                      <img [src]="approvers?.desc_acao === 'Recusou' ? 'assets/png/refusedIcon.png' : approvers?.desc_acao === 'Aprovou' ? 'assets/png/approvedIcon.png' : 'assets/svg/tooltip-alert-orange.svg'"/>
                      {{ approvers?.desc_acao }}
                    </p>
                    <p
                      [ngClass]="{
                        red: approvers?.desc_acao == 'Recusou',
                        green: approvers?.desc_acao === 'Aprovou',
                        yellow: approvers?.desc_acao === 'Pendente' || approvers?.desc_acao === 'Aguardando Aprovação'
                      }"
                    >
                        {{ approvers?.nome_usuario }}
                    </p>
                  </div>
                </ng-template>

                <div class="transaction-item" *ngIf="data?.aprovacao_automatica; else masters">
                  <p class="green">  
                  <img src="assets/png/approvedIcon.png"/>
                    <span class="green">APROVAÇÃO AUTOMÁTICA</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<div
  *ngIf="loading"
  class="d-flex justify-content-center position loading-container"
>
  <div class="spinner-border text-primary" role="status"></div>
</div>
