import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "fibra-aceite-termo",
  templateUrl: "./aceite-termo.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
})
export class DdaAceiteTermoComponent implements OnInit {
  @Output() goNextStep: EventEmitter<number> = new EventEmitter();

  constructor() {}

  showDisclaimer: boolean = false;
  enableNext: boolean = false;

  ngOnInit() {}

  enable(): void {
    this.enableNext = !this.enableNext;
  }

  goStep(): void {
    document.documentElement.scrollTop = 0;
    this.goNextStep.emit(2);
  }
}
