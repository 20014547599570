import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'cpfcnpj',
})
export class CpfCnpjPipe implements PipeTransform {
  transform(value: any): any {
    const newValue: any = String(value);
    if (newValue && newValue.length >= 14) {
      return `${newValue.substr(0, 2)}.${newValue.substr(
        2,
        3
      )}.${newValue.substr(5, 3)}/${newValue.substr(8, 4)}-${newValue.substr(
        12,
        2
      )}`;
    }
    if (newValue && newValue.length <= 11) {
      return `${newValue.substr(0, 3)}.${newValue.substr(
        3,
        3
      )}.${newValue.substr(6, 3)}-${newValue.substr(9, 2)}`;
    }

    return newValue;
  }
}
