import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fibra-payment-multiple-detail',
  templateUrl: './payment-multiple-detail.component.html',
  styleUrls: ['./payment-multiple-detail.component.scss'],
})
export class PaymentMultipleDetailComponent implements OnInit {
  @Input() data;
  constructor() {}

  ngOnInit() {}

  formatDate = (date) => {
    let dt = date.split('-');
    return dt[2] + '/' + dt[1] + '/' + dt[0];
  }
}
