<div class="container-input">
  <input
    *ngIf="!currency && !percentage"
    [style.paddingLeft.px]="prefixIcon ? 40 : ''"
    type="{{ type }}"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    maxlength="26"
    [minlength]="minLength"
    (change)="onChange($event.target.value)"
    [readonly]="readon"
  />
  <input
    *ngIf="percentage"
    [style.paddingLeft.px]="prefixIcon ? 48 : ''"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [maxlength]="maxLength"
    [minlength]="minLength"
    (keyup)="writeValue($event.target.value)"
    (change)="onChange($event.target.value)"
    MaskPercentage
  />
  <input
    *ngIf="currency"
    [style.paddingLeft.px]="prefixIcon ? 48 : ''"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [maxlength]="maxLength"
    [minlength]="minLength"
    (keyup)="writeValue($event.target.value)"
    (change)="onChange($event.target.value)"
    [MaskCurrency]="
      verifyCurrency() === 1
        ? { prefix: 'R$' }
        : { prefix: '$', decimal: '.', milhar: ',' }
    "
  />

  <img
    *ngIf="prefixIcon"
    (click)="clickPrefixIcon()"
    class="input-prefix"
    src="/assets/svg/{{ prefixIcon }}.svg"
    alt=""
  />
  <img
    *ngIf="sufixIcon"
    class="input-sufix"
    src="/assets/svg/{{ sufixIcon }}.svg"
    alt=""
  />
</div>
