import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fibra-section-content',
  templateUrl: './section-content.component.html',
  styleUrls: ['./section-content.component.scss'],
})
export class SectionContentComponent implements OnInit {
  @Input() public description: string;
  constructor() {}

  ngOnInit() {}
}


