import { DisclaimerModel } from '@src/core/domain/disclaimer/disclaimer.model';
import { Injectable } from '@angular/core';
import { DisclaimerRepository } from '@src/core/repositories/disclaimer/disclaimer.repository';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetDisclaimerUsecase implements UseCase<string, DisclaimerModel> {
  constructor(private disclaimerRepository: DisclaimerRepository) { }

  execute(params: string): Observable<DisclaimerModel> {
    return this.disclaimerRepository.getDisclaimer(params);
  }
}
