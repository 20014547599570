import { Mapper } from '@src/core/base/mapper';
import { TransferReceiptEntity } from './receipt.entity';
import { ReceiptandRefuseEntity } from './receipt.entity';
import { TransferReceiptModel } from '@src/core/domain/transfer-receipt/receipt.model';
import { ReceiptandRefuseModel } from '@src/core/domain/transfer-receipt/receipt.model';


export class TransferReceiptRepositoryMapper extends Mapper<
  TransferReceiptEntity,
  TransferReceiptModel
> {
  mapFrom(param: TransferReceiptEntity): TransferReceiptModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }

  mapTo(param: TransferReceiptModel): TransferReceiptEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}

export class ReceiptAndRefusedRepositoryMapper extends Mapper<
    ReceiptandRefuseModel,
    ReceiptandRefuseEntity
    > {
  mapFrom(param: ReceiptandRefuseEntity): ReceiptandRefuseModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: ReceiptandRefuseModel): ReceiptandRefuseEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
