<section class="sideCard"  (fileDropped)="handleDragFile($event)" Dnd [ngClass]="disabledDiv" style="background: red;">
  <h1 class="title">Transmitir arquivo </h1>

  <div class="upload" appDnd (fileDropped)="onFileDropped($event)" >
    <div class="upload-inner">
      <div class="d-flex justify-content-center align-items-center loadingView" *ngIf="loading">
        <div class="spinner-border text-primary" role="status"></div>
      </div>
      <!-- Step 1 -->
      <div class="upload-inner-content"  (fileDropped)="onFileDropped($event)" *ngIf="step1" >
        <input type="file" #fileDropRef id="fileDropRef" (change)="verifyFile($event.target.files); false" />

        <div class="content-upload" *ngIf="success">
          <div class="info" *ngIf="!step4 && step6 === false">
            <svg width="40" height="51" viewBox="0 0 40 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M27.2073 0.594079C26.9758 0.349781 26.6584 0.203125 26.3295 0.203125H6.63418C2.99988 0.203125 0 3.19684 0 6.83776V44.1536C0 47.7949 2.99988 50.7886 6.63418 50.7886H33.3658C37.0001 50.7886 40 47.7949 40 44.1536V14.5233C40 14.2057 39.8536 13.9004 39.6464 13.6681L27.2073 0.594079ZM27.561 4.51635L35.8904 13.2771H30.4757C28.866 13.2771 27.561 11.9819 27.561 10.3691V4.51635ZM33.3658 48.3449H6.63418C4.35384 48.3449 2.43904 46.4511 2.43904 44.1536V6.83776C2.43904 4.55302 4.34151 2.64688 6.63418 2.64688H25.1219V10.3691C25.1219 13.3381 27.5124 15.7209 30.4757 15.7209H37.561V44.1536C37.561 46.4511 35.6585 48.3449 33.3658 48.3449Z"
                fill="#8F969D" />
              <path
                d="M30.0368 39.9141H9.96366C9.29304 39.9141 8.74414 40.4636 8.74414 41.1359C8.74414 41.8079 9.29304 42.3578 9.96366 42.3578H30.0492C30.7198 42.3578 31.2687 41.8079 31.2687 41.1359C31.2687 40.4636 30.7198 39.9141 30.0368 39.9141Z"
                fill="#8F969D" />
              <path
                d="M13.7568 26.7667L18.7812 21.3535V34.6965C18.7812 35.3684 19.3301 35.9184 20.0008 35.9184C20.6718 35.9184 21.2203 35.3684 21.2203 34.6965V21.3535L26.2447 26.7667C26.4886 27.0229 26.806 27.1576 27.1349 27.1576C27.4277 27.1576 27.7327 27.0476 27.9642 26.8276C28.4523 26.3634 28.4889 25.5934 28.0255 25.1048L20.879 17.4193C20.6471 17.175 20.3301 17.0283 19.9888 17.0283C19.6471 17.0283 19.3301 17.175 19.0986 17.4193L11.9521 25.1048C11.4888 25.5934 11.5254 26.3753 12.013 26.8276C12.5253 27.2919 13.2934 27.2553 13.7568 26.7667Z"
                fill="#8F969D" />
            </svg>
            <br />
            <span class="label">Arraste e solte seu arquivo aqui</span>

            <br /><br />
            <span class="or">OU</span>
          </div>
          <div *ngIf="step4">
            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 35.6923L27.8947 44L45 26" stroke="#73B599" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <circle cx="32.5" cy="32.5" r="31.5" stroke="#73B599" stroke-width="2" />
            </svg>

            <span class="green break" *ngIf="isUploadSucessV2 === true">{{ uploadMessage }}</span>
            <span class="green break" *ngIf="isUploadSucessV2 === false">Arquivo Carregado</span>
            <div class="filename-wrapper" *ngIf="isUploadSucessV2 === false">
              <p class="filename">{{ fileToUploadV2.name }}
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" fill="none" style="transform: translateY(8px)">
                <path d="M14.1154 18.4627C14.1154 19.0349 13.6508 19.5 13.0789 19.5H1.53648C0.961918 19.5 0.5 19.0365 0.5 18.4638V1.53616C0.5 0.965004 0.964307 0.5 1.53799 0.5H9.65682C9.78167 0.5 9.9676 0.539297 10.1653 0.6212C10.3629 0.703046 10.5233 0.807158 10.6135 0.897301L13.7181 4.00192C13.8085 4.09238 13.9126 4.25232 13.9942 4.44921C14.0759 4.64614 14.1154 4.83249 14.1154 4.9599V18.4627Z" fill="white" stroke="#082A4D"/>
                <path d="M11.1575 4.61538C10.5193 4.61538 9.99994 4.09649 9.99994 3.46155V1.92307C9.99994 1.71065 9.82774 1.53845 9.61533 1.53845C9.40291 1.53845 9.23071 1.71065 9.23071 1.92307V3.46155C9.23071 4.52148 10.0946 5.38461 11.1575 5.38461H12.6923C12.9047 5.38461 13.0769 5.21241 13.0769 4.99999C13.0769 4.78757 12.9047 4.61538 12.6923 4.61538H11.1575Z" fill="#082A4D"/>
                </svg></p>
            </div>
            <span class="label">
              <div *ngIf="!loadingView">
                <button type="button" class="secondary-button" [style.pointer-events]="isUploadInProgress ? 'none' : '' "  *ngIf="isUploadSucessV2 === false; else uploadNewFile" (click)="onUpload(accountsWithPix)"> <!-- onUpload(this.accountsWithPix) -->
                  {{ this.uploadAutomaticoAtivado ? 'Enviar' : 'Revisar e enviar' }}
                </button>
              </div>
              <button *ngIf="loadingView" type="button" class="secondary-button" [disabled]="loadingView">
                Enviando...
              </button>
              <ng-template #uploadNewFile>
                <label class="secondary-button" for="fileDropRef" (click)="getUrlUpload()"
                  [ngClass]="activeTab !== 'remessa' ? 'disabled-btn' : ''">Enviar novo arquivo</label>
              </ng-template>

            </span>
          </div>

          <div *ngIf="step6">

            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
              <circle cx="33" cy="33" r="32.25" fill="white" stroke="#D69D10" stroke-width="1.5" />
              <path
                d="M32.695 40.0711L32.696 40.1201H32.745H35.8795H35.9285L35.9294 40.0711L36.3709 18.2079L36.3719 18.1569H36.3209H32.3035H32.2525L32.2535 18.2079L32.695 40.0711ZM32.5324 48.0118L32.5323 48.0118L32.5345 48.0139C33.0449 48.484 33.6754 48.7184 34.4226 48.7184C35.141 48.7184 35.7425 48.4836 36.2235 48.0129C36.7333 47.5139 36.989 46.9106 36.989 46.2056C36.989 45.5011 36.7335 44.9119 36.2235 44.4415C35.7429 43.9423 35.1414 43.6928 34.4226 43.6928C33.6745 43.6928 33.0436 43.9422 32.5334 44.4416C32.0524 44.9123 31.8121 45.5016 31.8121 46.2056C31.8121 46.9096 32.0523 47.5126 32.5324 48.0118Z"
                fill="#D69D10" stroke="#D69D10" stroke-width="0.1" />
            </svg>

            <span class="green break" style="margin-bottom: 10px;">Em Processamento</span>

            <span style="
              white-space: normal;
              font-size: 16px;
              display: block;
              margin-bottom: 8px;
              width: 197px;
              color: #8f969d;
              ">Após o processamento as transações serão enviadas para a aprovação do(s) master(s) da sua empresa</span>
            <span class="label">
              <label class="secondary-button" for="fileDropRef1" (click)="uploadNewFile(); openModalSystemFileUpload()"
                [ngClass]="activeTab !== 'remessa' ? 'disabled-btn' : ''">Enviar novo arquivo</label>
            </span>
          </div>
          <label 
            *ngIf="!step4 && step6 === false" 
            class="secondary-button" 
            for="fileDropRef1" 
            (click)="getUrlUpload(); openModalSystemFileUpload()"
            [ngClass]="activeTab !== 'remessa' ? 'disabled-btn' : ''"
          >
            Procure seu arquivo
          </label>
        </div>

      </div>

      <div *ngIf="step5">
        <svg class="icon-close" width="65" height="65" viewBox="0 0 65 65" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M45 20.0022L21 44M45 44L21.0011 20" stroke="#ED6C6C" stroke-width="2" stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round" />
          <circle cx="32.5" cy="32.5" r="31.5" stroke="#ED6C6C" stroke-width="2" />
        </svg>
        <span class="red break"> Ocorreu um erro no envio</span>
        <span class="label sub-title">
          <span class="error-message" *ngFor="let errorMessage of errorMessages"
            style="white-space: normal; font-size: 14px; margin: 4px 0;">{{errorMessage}}</span>
        </span>

        <span class="label">
          <button type="button" class="reload-button" (click)="reload()">
            Tentar novamente
          </button>
        </span>
      </div>

      <!-- Arquivo + carteira -->
      <!-- Step 2 -->
      <div class="upload-inner-content" *ngIf="step2">
        <p class="tagline">
          Selecione a carteira para iniciar o envio do arquivo:
        </p>

        <div class="attachment" *ngFor="let file of files; let i = index">
          <!-- Details File -->
          <div class="single-file">
            <h4 class="file-name">
              {{ file?.name }}
            </h4>
            <img src="../../../../assets/img/icon-file.svg" width="14" height="20" alt="Nome do arquivo" />
          </div>
        </div>

        <fibra-dropdown removeShadown="true" class="break select-option" [options]="optionWallet"
          (change)="mudarCarteira($event)"></fibra-dropdown>
      </div>

    </div>

    <div class="manual">
      <div class="manual-title">Manual:</div>
      <div class="manual-itens">
        <div class="manual-item" (click)="downloadItem('240', 'CNAB240')">
          CNAB 240
          <svg width="12" height="12" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4629 10.0377L10.6839 15.5254L3.905 10.3605M10.6839 0.999075L10.6839 15.5254L10.6839 0.999075Z"
              stroke="#4D86BF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M1 14.2637V21.0005H20.3684V14.2637" stroke="#4D86BF" stroke-width="1.5" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="manual-item" (click)="downloadItem('lamina', 'LaminaPagamentoEmLote')">
          Lâmina de Pagamento
          <svg width="12" height="12" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4629 10.0377L10.6839 15.5254L3.905 10.3605M10.6839 0.999075L10.6839 15.5254L10.6839 0.999075Z"
              stroke="#4D86BF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M1 14.2637V21.0005H20.3684V14.2637" stroke="#4D86BF" stroke-width="1.5" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
    <span style="    white-space: normal;
      font-size: 12px;
      text-align: justify;
      display: block;">Arquivos enviados <b>após as 16:00,</b> serão processados no próximo dia útil. </span>
  </div>
</section>

<fibra-modal-validation 
*ngIf="showSecurityModal" 
(closeEvent)="closeSecurityModal()"
[apiCallback]=121 
[apiPayload]="filename" 
(responseValidation)="finishUpload()">
</fibra-modal-validation>