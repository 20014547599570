<div class="approval-list" id="body-content">
  <header class="approval-list__header">
    <img class="logo" src="/assets/svg/logo_fibra.svg" alt="Logo Fibra" />
    <p class="tagline">
      Operações de crédito - Internet Banking - Banco Fibra -
      {{ this.date }} - Auto atendimento IB - {{ this.hour }}.
    </p>
    <h3 class="headline">Parcelas</h3>
  </header>
  <main class="approval-list__content">

    <div class="approval-list__content__table">
      <table class="approval-table">
        <tr>
          <th class="tbl-col-1">N° da Parcela</th>
          <th class="tbl-col-2">Data do Vencimento</th>
          <th class="tbl-col-3">Principal</th>
          <th class="tbl-col-4">Valor à pagar</th>
          <th class="tbl-col-5">Valor Pago</th>
          <th class="tbl-col-6">Data do pagamento</th>
          <th class="tbl-col-6">Situação</th>
        </tr>


        <div class="pt-15">
          <span class="dateRef">Data de referência: {{ dateReferenceToShow | date: 'dd/MM/yyyy' }}</span>
        </div> 

        <tr *ngFor="let item of parcelaList">
          <td>{{ item.num_parcela }}</td>
          <td>
            <p class="tipo-transacao"> {{ item.dt_vencto | date : 'dd/MM/yyyy' }}</p>
          </td>

          <td>{{ item.valor_principal | currency: 'BRL'}}</td>
          <td>>{{ item.valor_a_pagar | currency: 'BRL' }}</td>
          <td>{{ item.valor_pago | currency: 'BRL' }}</td>
          <td>{{ item.dt_pagto | date : 'dd/MM/yyyy' }}</td>
          <td class="col-value">
            <span class="margin-right">
              <img src="/assets/svg/status-{{ item.status }}.svg" />
            </span>
            {{ item.cod_situacao }}
           
          </td>

        </tr>
      </table>
    </div>
  </main>
</div>