import { GetPendencyLimitReducePixUsecase } from './../../../../core/usecases/dashboard/get-pendency-limit-reduce-pix.usecase';
import { GetPendencyLimitsApprovalUsecase } from './../../../../core/usecases/dashboard/get-pendency-limits-approval.usecase';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { ShowValuesDashboardDataShare } from '@src/core/usecases/data-share/show-values-dashboard-data-share.service';
import { SelectorDataShare } from '@src/core/usecases/data-share/selector-data-share.service';
import { OpenBankingPendency, PendencyModel, WarrantyModel } from '@src/core/domain/dashboard/dashboard.model';
import { GetAccompanimentUsecases } from '@src/core/usecases/dashboard/get-accompaniment.usecase';
import { GetPredictedTotalizadoresUsecase } from '@src/core/usecases/predicted/get-predicted-movements-totalizadores.usecase';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GetWarrantyUsecases } from '@src/core/usecases/dashboard/get-warranty.usecase';
import { GetPendencyUsecases } from '@src/core/usecases/dashboard/get-pendency.usecase';
import { InvestimentsDataRepository } from '@src/data/repository/investiments/investiments.repository';
import { BalancesEnum, IChequeEmpresa } from '@src/core/domain/balance/balances.model';
import * as Util from '@src/shared/util-common';
import { isArray } from 'util';
import { ProfileAccessProvider } from '@src/data/repository/perfilamento/profile.repository';
import { DerivativeDataRepository } from '@src/data/repository/derivative-repository/derivative.repository';
import { LoansRepository } from '@src/core/repositories/loans/loans-repository';
import { RolesService } from '@src/shared/services/roles.service';
import { PendencyDataRepository } from '@src/data/repository/dashboard/pendency/pendency.repository';
import { GetOpenBankingPendenciesUsecase } from '@src/core/usecases/dashboard/get-openbanking-pendencies.usecase';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';
import { BalancesService } from '@src/data/repository/balances-repository/balances.repository';
import { OpenBankingRepository } from '@src/data/repository/openbanking/openbanking.repository';
import { AccountsEntity } from '@src/data/repository/pix/accounts/pix-accounts-entity';
import { toTitleCase } from 'codelyzer/util/utils';
import { GetTemFibraFacilUseCase } from '@src/core/usecases/dashboard/get-temfibrafacil.usecase';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TermImplementationRepository } from '@src/data/repository/dda/term/term-implementation.repository';
import { UserService } from '@src/shared/services/user.service';
import { PaymentRepository } from '@src/core/repositories/payment/payment.repository';
import { GetPendencyDdaUseCase } from '@src/core/usecases/dashboard/get-pendency-dda.usecase';

@Component({
  selector: 'fibra-page-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  @ViewChild('cardpendency', null) cardpendency: ElementRef;
  @ViewChild('referenceWidth', null) referenceWidth: ElementRef;

  public token = undefined;
  public dataAccompaniment;
  public dataWarranty;
  public dataPendency;
  public dataOpenBankingPendencies: OpenBankingPendency[];
  public showData: boolean;
  public hidePendency = true;
  public hideDdaPendency = true;
  public hideOpenBankingPendencies = true;
  public dataAccount;
  public balance: BalancesEnum;
  public investimentsBalance: number;
  public derivativePendency = false;
  public opCreditoList;
  public hasNotificationPix = false;
  public showButton: boolean = false
  public totalPendencias = 0
  public chequeEmpresa: IChequeEmpresa = {} as IChequeEmpresa;
  public chequeEmpresaList: AccountsEntity[] = [] as AccountsEntity[];
  public chequeEmpresaListCount: number = 0;
  public toTitleCase = toTitleCase;
  public temFibraFacil: boolean = false;
  public loadingBannerInfo: boolean = true;
  public varSubscriptTransaction: Subscription;
  public pendencyLimitMaxSubscription: Subscription;
  public hasPixLimitPendency: boolean = false;
  public hasTransacionalLimitPendency: boolean = false;
  public hasHoursPendency: boolean = false;
  public hasLimitsMax: boolean = false;
  public hasLimitsMaxPix: boolean = false;
  public hasOverdraftChange : boolean = false;
  public showDdaPopup: boolean = false;
  public ddaPendencies: number = 0;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  constructor(
    private DerivativeService: DerivativeDataRepository,
    private shareSelector: SelectorDataShare,
    private getAcompaniment: GetAccompanimentUsecases,
    private getPredictedTotalizadoresUsecase: GetPredictedTotalizadoresUsecase,
    private balancesService: BalancesService,
    private getWarranty: GetWarrantyUsecases,
    private getPendency: GetPendencyUsecases,
    private getOpenBankingPendencies: GetOpenBankingPendenciesUsecase,
    private shareShowValues: ShowValuesDashboardDataShare,
    private shareCompany: CompanyDataShare,
    private accessProvider: ProfileAccessProvider,
    private InvestimentsService: InvestimentsDataRepository,
    private loansRepository: LoansRepository,
    private rolesService: RolesService,
    private pendencyServices: PendencyDataRepository,
    private openBankingRepository: OpenBankingRepository,
    private getTemFibraFacil: GetTemFibraFacilUseCase,
    private getPendencyLimitsApprovalUsecase: GetPendencyLimitsApprovalUsecase,
    private getPendencyLimitReducePixUsecase: GetPendencyLimitReducePixUsecase,
    private termRepository: TermImplementationRepository,
    private userService: UserService,
    private getPendencyDdaUseCase: GetPendencyDdaUseCase
  ) { }

  ngOnInit() {
    this.validateOverdraftLimitChanged();
    this.getPendecyApprovalLimits();
    this.getPendecyLimitMax();
    this.getDdaPendency();
    
    this.openBankingRepository.getAccounts().subscribe((list: AccountsEntity[]) => {
      this.chequeEmpresaList = list.map((item) => {
        const isPjType = this.isPj();
        if (isPjType) {
          this.checkDdaTerm();
        }

        if (item.desc_conta === 'CHEQUE EMPRESA') {
          const saldo = item.saldo as any;
          const vlr_limite = saldo.vlr_limite || 0;
          this.chequeEmpresaListCount = this.chequeEmpresaListCount + 1;
          
          item.vlr_dashboard_cheque_empresa = saldo.vlr_cheque_disponivel || 0;
          
          if (vlr_limite == 0) {
            item.vlr_dashboard_cheque_empresa = 0;
          }
        }
        return item;
      });
      
      this.showScrollButton();
    })
    
    this.init();

    this.getHasNotificationPix();
  }

  private init = () => {

    // get Roles
    let roles = this.rolesService.get();

    roles.forEach(role => {

      if (role == "OP_CRED.CONS_OP_CRED") {
        this.getAllContractsOp();
      }

      if (role == "DER.CONS_DER") {
        this.getDerivative(new Date());
      }

      if (role == "INVEST.CONS_INV") {
        this.getInvestimentsBalance();
      }

    });


    //////////
    this.shareSelector.setValue('dashboard');
    this.requestAccompaniment();
    // this.requestWarranty();
    this.requestPendency();
    this.requestOpenBankingPendencies();
    this.showValuesShare();
    this.requestBalances();
    this.verificaSeTemFibraFacil();

    this.shareCompany.dataShared.subscribe((res) => {
      if (res !== undefined && isArray(res.companyList)) {
        this.dataAccount = res.companyList.filter((e) => e.selected);
      }
    });

    //

    var erroDashboard = sessionStorage.getItem('erroDashboard');

    if (erroDashboard) {
      this.toast.callModalMessage(null, erroDashboard);
      sessionStorage.removeItem('erroDashboard');
    }
  }

  getHasNotificationPix() {
    this.pendencyServices.getPendencyPix().subscribe(data => {
      this.hasNotificationPix = true;
      this.totalPendencias = this.totalPendencias + 1
      this.carregaBotao();
    }, error => {
      this.hasNotificationPix = false
    })
  }
  getInvestimentsBalance() {
    var dt = new Date();
    this.InvestimentsService.getInvestiments(dt, null, true).subscribe((data) => {
      this.investimentsBalance = data.data['tot_liquido']
    }, () => {
      this.investimentsBalance = 0;
    });
  }

  showValuesShare() {
    this.shareShowValues.dataShared.subscribe((res) => {
      this.showData = res && res.iconEyesOpen;
    });
  }

  public isHideAcompaniment = () => {
    return !this.dataAccompaniment || !this.dataWarranty;
  }

  requestBalances = () => {
    this.balancesService.getChequeEmpresa().subscribe(({ data }: any) => {
      if (data && data.vlr_limite) {
        this.chequeEmpresa = data;
      }
    });
  }

  // tslint:disable-next-line:align
  public requestAccompaniment = () => {
    this.getPredictedTotalizadoresUsecase
      .execute()
      .subscribe(this.requestSuccess, this.requestError);
  }

  private requestSuccess = (value) => {
    if (value.status === 'success') {
      this.dataAccompaniment = value.data;
    }
  }

  private requestError = (err) => {
    return console.log(err);
  }

  private requestWarranty = () => {
    this.getWarranty
      .execute()
      .subscribe(this.requestSuccessWarranty, this.requestError);
  }

  private requestSuccessWarranty = (value: WarrantyModel) => {
    if (value.status === 'success') {
      this.dataWarranty = value.data;
    }
  }

  private requestPendency = () => {
    this.getPendency
      .execute()
      .subscribe(
        this.requestSuccessPendency,
        this.requestError,
        this.requestPendencyComplete
      );
  }

  private requestOpenBankingPendencies = () => {
    this.getOpenBankingPendencies
      .execute()
      .subscribe(
        this.requestSuccessOpenBankingPendencies,
        this.requestError,
        this.requestOpenBankingPendenciesComplete
      );
  }

  private requestSuccessPendency = (value: PendencyModel) => {
    if (value.status === 'success') {
      this.dataPendency = Util.verifyArray(value.data);
      this.hidePendency = Util.isEmpty(this.dataPendency);
      var dataPendencyQtd = this.dataPendency ? this.dataPendency.length : 0
      this.totalPendencias = this.totalPendencias + dataPendencyQtd;
      this.carregaBotao();
    }
  }

  private requestSuccessOpenBankingPendencies = (value: OpenBankingPendency[]) => {
    console.log(value);
    this.dataOpenBankingPendencies = Util.verifyArray(value);
    this.hideOpenBankingPendencies = Util.isEmpty(this.dataOpenBankingPendencies);
    var dataPendenciesOpenBankingQtd = this.dataOpenBankingPendencies ? this.dataOpenBankingPendencies.length : 0
    this.totalPendencias = this.totalPendencias + dataPendenciesOpenBankingQtd
    this.carregaBotao();
  }

  private requestPendencyComplete = () => {
    this.hidePendency = Util.isEmpty(this.dataPendency);
  }

  private requestOpenBankingPendenciesComplete = () => {
    this.hideOpenBankingPendencies = Util.isEmpty(this.dataOpenBankingPendencies);
  }

  getDerivative(dt: Date) {
    this.DerivativeService.getDerivative(dt).subscribe((data) => {
      if (data.data && data.data.length > 0) {
        this.derivativePendency = true;
        this.totalPendencias = this.totalPendencias + 1
      } else {
        this.derivativePendency = false
      }
      this.carregaBotao();
    }, () => {
      this.derivativePendency = false;
    });
  }

  public parcelaVencendo = false;
  public parcelaVencida = false;
  public parcelaAvencer = false;
  getAllContractsOp() {

    const params = {}

    this.loansRepository.getAllLoans(params).subscribe((contratos) => {
      this.opCreditoList = contratos.data

      this.opCreditoList.forEach(element => {
        if (element.qt_parcela_vencida > 0) {
          this.parcelaVencida = true
          this.totalPendencias = this.totalPendencias + 1
        }
        else if (element.qt_parcela_vencendo > 0) {
          this.parcelaVencendo = true
          this.totalPendencias = this.totalPendencias + 1
        }
        else if (element.qt_parcela_a_vencer > 0) {
          this.parcelaAvencer = true
          this.totalPendencias = this.totalPendencias + 1
        }
      });
      this.carregaBotao();
    }, (err) => {
    })
  }
  scrollBy(side) {
    if (side === 'right') {
      document.querySelector('#cardpendency').scrollBy({
        left: 410,
        behavior: 'smooth',
      });
    }
    if (side === 'left') {
      document.querySelector('#cardpendency').scrollBy({
        left: -410,
        behavior: 'smooth',
      });
    }
  }

  carregaBotao() {
    if (window.innerWidth <= 890) {
      this.showButton = this.totalPendencias > 1 ? true : false;
    } else if (window.innerWidth > 890 && window.innerWidth < 1366) {
      this.showButton = this.totalPendencias > 2 ? true : false;
    } else if (window.innerWidth >= 1366 && window.innerWidth <= 1850) {
      this.showButton = this.totalPendencias > 3 ? true : false;
    } else if (window.innerWidth > 1850 && window.innerWidth < 2300) {
      this.showButton = this.totalPendencias > 4 ? true : false;
    } else {
      this.showButton = this.totalPendencias > 5 ? true : false;
    }
  }

  private verificaSeTemFibraFacil() {
    this.getTemFibraFacil.execute().pipe(finalize(() => this.loadingBannerInfo = false)).subscribe(result => this.temFibraFacil = result);
  }

  public getPendecyApprovalLimits() {
    this.unsubscribeVariable(this.varSubscriptTransaction);
    this.varSubscriptTransaction = this.getPendencyLimitsApprovalUsecase.execute()
      .subscribe(this.successPendencyLimits,
      error => {
        console.log(error);
       }
    );
  }
  public validateOverdraftLimitChanged() {
    const roles = this.rolesService.get();
    this.hasOverdraftChange = (roles.indexOf('ALTERACAO_LIMITE_CARD') > -1 &&  sessionStorage.getItem('cardOverdraftLimitChange') != 'true');
    if(this.hasOverdraftChange ){
      this.totalPendencias++;  
      this.carregaBotao(); 
    }
    
  }

  unsubscribeVariable(variable) {
    if(variable) {
      variable.unsubscribe();
    }
  }

  private successPendencyLimits = (value) => {
    this.hasPixLimitPendency = value.data.pendenciaLimitePix;
    this.hasTransacionalLimitPendency = value.data.pendenciaLimite;
    this.hasHoursPendency = value.data.pendenciaHorario;
  }

  public getPendecyLimitMax() {
    this.unsubscribeVariable(this.pendencyLimitMaxSubscription);
    this.pendencyLimitMaxSubscription = this.getPendencyLimitReducePixUsecase.execute()
      .subscribe(this.successPendencyLimitMax,
      error => {
        console.log(error);
       }
    );
  }

  private successPendencyLimitMax = (value) => {
    this.hasLimitsMax = value.data.pendenciaOutros;
    this.hasLimitsMaxPix = value.data.pendenciaPix;
  }

  showScrollButton(): void{
    setTimeout(() => {
      const cardPendencyWithScroll = (this.cardpendency.nativeElement as HTMLElement).offsetWidth;
      const cardReferenceWithScroll = (this.referenceWidth.nativeElement as HTMLElement).offsetWidth;

      if(cardReferenceWithScroll > cardPendencyWithScroll){
        this.showButton = true;
      }
    }, 800);
    
  }

  public closeModalDda(value) {
    this.showDdaPopup = false;
  }

  checkDdaTerm() {
    this.termRepository
    .getHaveTerm().subscribe(
      (result: any) => {
        this.showDdaPopup = false;
      },
      (error: any) => {
        this.showDdaPopup = true;
        console.log(error);
      }
    );
  }

  isPj() {
    return this.userService.isPj();
  }

  public dateToString = (date, future) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}${future?'T23:59:59':'T00:00:00'}`
  }

  getDdaPendency() {
    const todayDate = new Date(Date.now());
    const futureDate = new Date(Date.now() + (8.64e+7 * 15));

    const payloadDdaPendency = {
      data_inicial: this.dateToString(todayDate, false),
      data_final: this.dateToString(futureDate, true),
      status_pagamento: 'AVencer'
    };

    this.getPendencyDdaUseCase.execute(payloadDdaPendency).subscribe(
      (response) => {
        this.ddaPendencies = response.data.num_boletos;
        this.hideDdaPendency = response.data.num_boletos === 0;
      },
      (error: any) => console.log(error));
  }
}
