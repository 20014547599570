<ng-template #t let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.init]="isDateInit(date)"
    [class.end]="isDateEnd(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
    [class.past-date]="isPastDate(date)"
  >
    {{ date.day }} 
  </span>
</ng-template>
<form class="form-inline">
  <div class="form-group">
    <label>{{ label }}</label>
    <div class="input-group">
      <input *ngIf="!_readonlyInput"
        class="form-control"
        name="dp"
        ngbDatepicker
        #d="ngbDatepicker"
        [dayTemplate]="t"
        navigation="1"
        [textMask]="addMask(dateMask)"
        (dateSelect)="onDateSelection($event)"
        (input)="dataChanged($event)"
        (change)='todate($event.target.value)'
        (blur)="onBlur($event.target.value)"
        [startDate]="date"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [placement]="placement"
        [ngClass]="inputHidden ? 'inputHidden' : '' && { placeholder: placeholder} "
        (click)="d.toggle()"
        focused
        onkeypress="return true"
        [disabled]="disabled"
        [value]="formatter.format(date)"
        placeholder="{{placeholder}}"
      /> 
      <input *ngIf="_readonlyInput"
        class="form-control"
        name="dp"
        ngbDatepicker
        #d="ngbDatepicker"
        [dayTemplate]="t"
        navigation="1"
        [textMask]="addMask(dateMask)"
        (dateSelect)="onDateSelection($event)"
        (input)="dataChanged($event)"
        (change)='todate($event.target.value)'
        (blur)="onBlur($event.target.value)"
        [startDate]="date"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [placement]="placement"
        [ngClass]="inputHidden ? 'inputHidden' : '' && { placeholder: placeholder} "
        focused
        onkeypress="return true"
        [value]="formatter.format(date)"
        placeholder="{{placeholder}}"
        [readonly]="_readonlyInput"
        [disabled]="disabled"
      />
      <div class="input-group-append" *ngIf="inputHidden">
        <button
          class="btn btn-outline calendar"
          (click)="d.toggle(); positionDatePopup()"
          type="button"
        >
          <img src="/assets/svg/icon_calendar.svg" alt="icon-calendar" />
        </button>
      </div>
    </div>
  </div>
</form>
