<div class="term-content" *fibraHasRole="'MASTER'">
<div class="row mt-0">
  <div class="col-12 term-title">
    <span class="term-font">Termo de adesão</span>
  </div>
  <div class="col-12 mt-3">
    <p class="mt-3 term-text font">
      1.&nbsp;Pelo presente Termo de Adesão ao Débito Direto Autorizado
      – DDA, o CLIENTE:
    </p>
    <div class="term-text mt-3">
      <span class="font">
      (i) Conforme opção de contratação assinalada na FICHA CADASTRAL E PROPOSTA
      - PESSOA JURÍDICA, expressamente adere aos termos e condições do Débito
      Direto Autorizado – DDA (“DDA”) e à prestação de serviços, pelo BANCO
      FIBRA S.A. (“BANCO”), de recebimento de boletos de cobrança registrados
      contra o próprio CLIENTE pelas instituições financeiras participantes do
      DDA, através do INTERNET BANKING DO BANCO FIBRA ou de outros canais que
      vierem a ser disponibilizados e informados pelo BANCO.</span>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="term-text mt-3">
      <span class="font">
      (ii) Está ciente que poderá cadastrar Pagadores Agregados para recebimento
      de boletos de cobrança em meio eletrônico, através da celebração de Termo
      de Inclusão de Pagadores Agregados ao Sistema DDA, que conterá todas as
      disposições aplicáveis a essa modalidade</span>
    </div>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      (iii) Reconhece que, a partir da adesão ao DDA, não mais receberá boletos
      de cobrança registrada sacados contra o próprio CLIENTE e Pagadores
      Agregados, quando houver, passando a ter ciência desses boletos
      exclusivamente de forma eletrônica.</span>
    </div>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      (iv) Reconhece que, caso receba o mesmo boleto de cobrança em papel e meio
      eletrônico, deverá preferencialmente pagar o boleto recebido por meio
      eletrônico.</span>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <p class="mt-3 term-text font">
      2. Adicionalmente, o CLIENTE reconhece que, em razão de sua adesão ao DDA:
    </p>
    <div class="mt-3">
      <div class="term-text mt-3">
        <span class="font">
      (i) deverá acessar diariamente o serviço de DDA disponibilizado no
      INTERNET BANKING DO BANCO FIBRA, para obter os boletos de cobrança
      emitidos contra ele pelas instituições financeiras participantes do DDA;</span>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      (ii) tem conhecimento que o DDA não é uma solução de pagamento automático
      de boletos bancários, ficando sob responsabilidade exclusiva do CLIENTE o
      pagamento dos referidos boletos;</span>
    </div>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      (iii) deverá liquidar os boletos até a sua data de vencimento, sob pena de
      permanecerem em aberto perante o respectivo Sacador/Beneficiário;</span>
    </div>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      (iv) tem conhecimento que a disponibilidade do boleto bancário para
      consulta por meio eletrônico, independentemente do acesso, representará o
      seu recebimento pelo CLIENTE;</span>
    </div>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      (v) tem conhecimento que o não recebimento dos boletos de cobrança
      registrada em papel não encerra a obrigação do CLIENTE com o emitente
      desses boletos, razão pela qual permanece responsável pelo pagamento da
      dívida representada pelos boletos apresentados eletronicamente por meio do
      DDA;</span>
    </div>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      (vi) verificará se os boletos recebidos em meio eletrônico por meio do DDA
      e papel foram pagos, evitando o inadimplemento ou o pagamento em
      duplicidade dos boletos.</span>
    </div>
  </div>
</div>
<div class="row">
  <div class="term-text mt-3">
    <span class="font">
    2.1.&nbsp;Caso o CLIENTE não reconheça a regularidade de qualquer boleto
    sacado contra ele, deverá manifestar sua discordância diretamente ao
    Sacador/Beneficiário do título.</span>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      2.2.&nbsp;Em caso de boletos vencidos, o CLIENTE poderá solicitar a
      segunda via do boleto para pagamento, ou tomar todas as providências
      necessárias para tanto junto ao Cedente/Beneficiário.</span>
    </div>
  </div>
  <div class="col-12">
    <p class="mt-3">
      3.&nbsp;O CLIENTE está ciente que poderá solicitar a exclusão do DDA,
      mediante prévio aviso por escrito ao BANCO, com 30 (trinta) dias de
      antecedência.
    </p>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      3.1. Na hipótese prevista nesta cláusula, o BANCO fornecerá ao CLIENTE, no
      dia útil seguinte ao da solicitação, relatório com informações sobre os
      boletos disponíveis no DDA até a data do encerramento dos serviços.
      </span>
    </div>
  </div>
  <div class="col-12">
    <div class="term-text mt-3">
      <span class="font">
      3.2.&nbsp;O CLIENTE reconhece que a exclusão definitiva do DDA somente
      ocorrerá após o CLIENTE requerer essa exclusão junto a todos os Bancos em
      que tenha aderido ao DDA, ou quando encerrar a totalidade das contas de
      depósito elegíveis a participação do DDA mantidas nesses bancos.</span>
    </div>
  </div>
  <div class="col-12">
    <p class="mt-3">
      4.&nbsp; O CLIENTE declara, por fim, que leu, compreendeu e ratifica todas
      as cláusulas, obrigações e condições aplicáveis à prestação de serviços de
      Débito Direto Autorizado -DDA previstas nas CONDIÇÕES GERAIS À FICHA
      CADASTRAL E PROPOSTA PESSOA JURÍDICA, da qual o presente TERMO DE ADESÃO é
      parte integrante e complementar.
    </p>
  </div>
</div>
</div>
