<div class="card mt-3 noborder" *ngIf="exibirDadosAprovacao && !showDisclaimer">
  <div class="card-body" *fibraHasRole="'MASTER'">
    <div class="row align-items-center">
      <div class="col-8 text-wrap">
        <p class="label-medium text-justify">
          O usuário
          <span class="font-weight-bold">{{ criadoPor }}</span>
          fez a requisição da <span class="fg-green">adesão</span> ao DDA no dia
          <span class="font-weight-bold">{{ dataCriacao | date : "dd/MM/yyyy" }}
          </span>
          às <span class="font-weight-bold">{{ horaCriacao }}h</span> e depende da aprovação dos masters. Você deseja:
        </p>
      </div>
      <div class="col-2">
        <button type="button" class="text-center button-outlined mr-2" (click)="reprovarTermo()">
          Recusar adesão
        </button>
      </div>
      <div class="col-2">
        <button type="button" class="text-center button-blue mr-2" (click)="showDisclaimer = true">
          Aprovar adesão
        </button>
      </div>
    </div>
  </div>
</div>
<div class="card mt-3 noborder" *fibraHasRole="'MASTER'">
  <div class="card-body">
    <div class="row" *ngIf="!isDebug">
          <h1><img id="pendentes" alt="logo" src="assets/svg/help-circle.svg" style="margin-right: 12px !important;" />Status Processo - Aprova&ccedil;&atilde;o termo</h1>
          <table class="table">
              <thead>
                  <tr>
                      <th scope="col">Aderido <img id="pendentes" alt="logo" src="assets/svg/bullet_green.svg" style="margin-right: 12px !important;" /></th>
                      <th scope="col">Ades&atilde;o pendente <img id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" /></th>
                      <th scope="col">Ades&atilde;o em processo <img id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" /></th>
                      <th scope="col">Cancelamento pendente <img id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" /></th>
                      <th scope="col">Cancelamento em processo <img id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" /></th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                    <!--ADESAO-->
                      <td [ngClass]="adesaoConcluida ? 'table-cell': ''">
                        <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'green'} : {'color': 'red'}">{{adhesionInLegado.toString().toUpperCase()}}</b> , esperado <b style="color: green;">TRUE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'red'} : {'color': 'green'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ?  {'color': 'red'} : {'color': 'green'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ?  {'color': 'red'} : {'color': 'green'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      </td>
                      <!--ADESAO PENDENTE-->
                      <td [ngClass]="adesaoPendente ? 'table-cell': ''">
                        <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'red'} : {'color': 'green'}">{{adhesionInLegado.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'green'} : {'color': 'red'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ? {'color': 'red'} : {'color': 'green'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ? {'color': 'red'} : {'color': 'green'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      </td>
                      <!--ADESAO EM PROCESSO-->
                      <td [ngClass]="adesaoEmProcesso ? 'table-cell': ''">
                        <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'red'} : {'color': 'green'}">{{adhesionInLegado.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'red'} : {'color': 'green'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ? {'color': 'red'} : {'color': 'green'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ? {'color': 'red'} : {'color': 'green'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      </td>                      
                      <!--CANCELAMENTO PENDENTE-->
                      <td [ngClass]="cancelamentoPendente ? 'table-cell': ''">
                        <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'green'} : {'color': 'red'}">{{adhesionInLegado.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'red'} : {'color': 'green'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ? {'color': 'red'} : {'color': 'green'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ? {'color': 'green'} : {'color': 'red'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                      </td>
                      <!--CANCELAMENTO EM PROCESSO-->
                      <td [ngClass]="cancelamentEmProcesso ? 'table-cell': ''">
                        <b>Ades&atilde;o Legado</b>: <b [ngStyle]="adhesionInLegado ? {'color': 'green'} : {'color': 'red'}">{{adhesionInLegado.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o</b>: <b [ngStyle]="waitApproval ? {'color': 'red'} : {'color': 'green'}">{{waitApproval.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o Cancelamento CIP</b>: <b [ngStyle]="waitCancelInCipProgress ? {'color': 'green'} : {'color': 'red'}">{{waitCancelInCipProgress.toString().toUpperCase()}}</b>, esperado <b style="color: green;">TRUE</b><br>
                        <b>Aguardando aprova&ccedil;&atilde;o de cancelamento</b>: <b [ngStyle]="waitApprovalCancel ? {'color': 'red'} : {'color': 'green'}">{{waitApprovalCancel.toString().toUpperCase()}}</b>, esperado <b style="color: green;">FALSE</b><br>
                      </td>                      
                  </tr>
              </tbody>
          </table>
    </div>
    <div *ngIf="!showDisclaimer; else disclaimer">
      <div class="row">
        <div class="col-12">
          <h1>Dados da Empresa</h1>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-3">
          <span class="table-header">CNPJ</span>
        </div>
        <div class="col-3">
          <span class="table-header">Razão Social</span>
        </div>
        <div class="col-6">
          <span class="table-header">Status</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <span class="label-medium">{{ empresa.cpfCnpj | cpfcnpj }}</span>
        </div>
        <div class="col-3">
          <span class="label-medium">{{ empresa.name }}</span>
        </div>
        <div class="col-6">
          <div [ngSwitch]="statusAdesao" class="label-medium">
            <div *ngSwitchCase="'ADERIDO'">
              <span class="label-medium">
                <img alt="" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
              </span>
            </div>
            <div *ngSwitchCase="'ADESAO_PENDENTE'">
              <span class="label-medium">
                <span [appTooltip]="tooltipAll" class="icon-info notification">
                  <img id="pendentes" alt="logo" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                  <div #tooltipAll class="tooltip-custom">
                    <p style="font-size: 14px;">Aguardando aprovação</p>
                    <div class="masters" style="text-align: left;">
                      <div class="masters-title">
                        <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                        <span class="master-font"> Masters </span>
                      </div>
                      <span *ngFor="let pessoa of aprovadores">
                        <span class="master-sub">{{pessoa.nome_pessoa}}</span>
                      </span>
                    </div>
                    <div class="arrow"></div>
                  </div>
                </span>
              </span>                            
            </div>
            <div *ngSwitchCase="'ADESAO_EM_PROCESSAMENTO'">
              <span class="label-medium">
                <span [appTooltip]="tooltipAll" class="icon-info notification">
                  <img id="pendentes" alt="logo" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                  <div #tooltipAll class="tooltip-validacao-em-processo">
                    <div class="masters" style="text-align: left">
                      <div class="masters-title">
                        <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                        <span class="master-font">Em processamento de adesão</span>
                      </div>
                    </div>
                    <div class="arrow"></div>
                  </div>
                </span>
              </span>                            
            </div>            
            <div *ngSwitchCase="'CANCELAMENTO_PENDENTE'">
              <span class="label-medium">
                <span [appTooltip]="tooltipAll" class="icon-info notification">
                  <img id="pendentes" alt="logo" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                  <div #tooltipAll class="tooltip-custom">
                    <p style="font-size: 14px;">Aguardando aprovação</p>
                    <div class="masters" style="text-align: left;">
                      <div class="masters-title">
                        <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                        <span class="master-font"> Masters </span>
                      </div>
                      <span *ngFor="let pessoa of aprovadoresCancelamento">
                        <span class="master-sub">{{pessoa.nome_pessoa}}</span>
                      </span>
                    </div>
                    <div class="arrow"></div>
                  </div>
                </span>
              </span>              
            </div>
            <div *ngSwitchCase="'CANCELAMENTO_EM_PROCESSAMENTO'">
              <span class="label-medium">
                <span [appTooltip]="tooltipAll" class="icon-info notification">
                  <img id="pendentes" alt="logo" src="assets/svg/{{imgStatus}}.svg" style="margin-right: 12px !important;" />&nbsp;{{descStatus}}
                  <div #tooltipAll class="tooltip-validacao-em-processo">
                    <div class="masters" style="text-align: left">
                      <div class="masters-title">
                        <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                        <span class="master-font">Em processo de cancelamento</span>
                      </div>
                    </div>
                    <div class="arrow"></div>
                  </div>
                </span>
              </span>              
            </div>
            <!--div *ngSwitchDefault>Não aderido</div-->
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-4">
        <div class="col-12">
          <h1>Dados da Filial</h1>
        </div>
        <div class="col-12">
          <p class="mt-2 label-medium">
            A filial precisa ter o CNPJ raiz igual da matriz.
          </p>
        </div>
      </div>
      <div *ngIf="filiais.length > 0; else notfound">
        <div class="row mt-4 align-items-center">
          <div class="col-3">
            <span class="table-header">CNPJ</span>
          </div>
          <div class="col-6">
            <span class="table-header">Razão Social</span>
          </div>
          <div class="col-3"></div>
        </div>
        <div class="row mt-2 align-items-center" *ngFor="let filial of filiais">
          <div class="col-3">
            <span class="label-medium">{{ getInscricao(filial) | cpfcnpj }}
            </span>
          </div>
          <div class="col-3">
            <span class="label-medium">{{ filial.razaoSocial }}</span>
          </div>
          <div class="col-3">

            <span *ngIf="filial.statusId===2 && !(!adhesionInLegado || cancelInProgress || waitApprovalCancel || waitCancelInCipProgress)" class="label-medium">
              <img alt="" src="assets/svg/bullet_green.svg" />&nbsp;&nbsp;</span>

            <span *ngIf="filial.statusId!==2 || !adhesionInLegado || cancelInProgress || waitApprovalCancel || waitCancelInCipProgress">
              <span [appTooltip]="tooltipAll" class="icon-info notification">
                <img *ngIf="filial.statusId!==10" id="pendentes" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;"
                />              
                <div #tooltipAll [ngClass]="filial.aprovadores && filial.aprovadores.length > 0 ? 'tooltip-custom' : 'tooltip-validacao'">
                  <div *ngIf="(filial.statusId==9 || filial.statusId==10) && filial.aprovadores.length == 0">
                    <div class="masters" style="text-align: left">
                      <div class="masters-title">
                        <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                        <span class="master-font">Em processamento</span>
                      </div>
                    </div>
                    <div class="arrow"></div>
                  </div>
                  <div *ngIf="filial.statusId==5">
                    <div class="masters" style="text-align: left">
                      <div class="masters-title">
                        <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                        <span class="master-font"> CNPJ em valida&ccedil;&atilde;o </span>
                      </div>
                    </div>
                    <div class="arrow"></div>
                  </div>
                  <div *ngIf="filial.statusId !=5 && filial.statusId != 13 && filial !=null && filial.aprovadores !=null && filial.aprovadores.length > 0">
                    <p style="font-size: 14px;">Aguardando aprovação</p>
                    <div class="masters" style="text-align: left;">
                      <div class="masters-title">
                        <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                        <span class="master-font"> Masters </span>
                      </div>
                      <span *ngFor="let item of filial.aprovadores">
                        <span class="master-sub" *ngIf="item.status_id === 9 || item.status_id === 10">{{item.nome}}</span>                        
                      </span>
                    </div>
                    <div class="arrow"></div>
                  </div>
                  <div *ngIf="filial.statusId==13">
                    <div class="masters" style="text-align: left;">
                      <div class="masters-title">
                        <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                        <span class="master-font"> Em processamento </span>
                      </div>
                    </div>
                    <div class="arrow"></div>
                  </div>
                </div>
              </span>
            </span>
            <span *ngIf="!(!adhesionInLegado || cancelInProgress || waitApprovalCancel || waitCancelInCipProgress)" class="label-medium"> {{filial.statusId | ddaStatus }}
            </span>
            <span *ngIf="!adhesionInLegado || cancelInProgress || waitApprovalCancel || waitCancelInCipProgress" class="label-medium">&nbsp;{{descStatus}}</span>
          </div>
        </div>
      </div>
      <ng-template #notfound>
        <div class="row">
          <div class="col">
            <span class="label-xmedium info-warning">Não existem filiais cadastradas para essa para adesão.</span>
          </div>
        </div>
      </ng-template>
    </div>
    <hr />

    <ng-template #disclaimer>
      <div class="row">
        <div class="col">
          <fibra-disclaimer></fibra-disclaimer>
        </div>
      </div>
      <div class="row mt-5 justify-content-between">
        <div class="col-10 check-div">
          <input id="agree" type="checkbox" class="checkbox" (click)="enable()" />
          <label for="agree" class="check-font">Li e concordo com o termo de adesão do débito autorizado.
          </label>
        </div>

        <div class="col-1">
          <button type="button" class="text-center button-outlined mr-2" (click)="showDisclaimer = false">
            Voltar
          </button>
        </div>
        <div class="col-1">
          <button type="button" class="text-center button-blue mr-2" [disabled]="!enableAction" (click)="exibirAutorizacao = true">
            Aderir
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<fibra-modal-validation *ngIf="exibirAutorizacao" [apiCallback]="apiCallback" (closeEvent)="fecharModal()" [apiPayload]="apiPayload"
  (responseValidation)="respostaApi($event)">
</fibra-modal-validation>
<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
<fibra-modal-conclusao-aprovacao *ngIf="exibirConclusao" [recusar]="this.apiPayload.ds_status === statusApproval.Reproved"
  [totalMasters]="totalMasters" [totalFiliais]="filiais.length" (closeEvent)="fecharModalConclusao()">
</fibra-modal-conclusao-aprovacao>