<div class="box-drop">
    <label>Selecione a empresa</label>
    <fibra-dropdown *ngIf="!loading" class="dropdown-company-list" iconName="dropdown_pj" placeholder="{{ selected }}" numCpfCnpj="{{selectedCpfCnpj}}" [options]="optionsCompany" (callback)="reloadSession($event)" removeShadown="true" customClass="dropdown-company-list"
        textColor="#4D86BF" id="selecioneEmpresa" [isDisabled]="readonly"></fibra-dropdown>
    <!-- Loading -->
    <div class="d-flex justify-content-center position" *ngIf="loading">
        <div class="spinner-border text-primary" role="status">
        </div>
    </div>
</div>


<ng-template #companySelector>
    <div class="box-drop-default">
        <fibra-dropdown *ngIf="!loading" style=" border-radius: 5px;
              box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);" iconName="dropdown_pj" placeholder="Selecione a empresa" [options]="optionsCompany" (callback)="getCompany($event)" removeShadown="true" customClass="dropdown-company-list" textColor="#4D86BF"></fibra-dropdown>
        <!-- Loading -->
        <div class="d-flex justify-content-center position" *ngIf="loading">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-template>
<p id="open" (click)="companyModal()"></p>

<div class="content-terms" *fibraHasRole="'MASTER'">
    <fibra-term-contract *ngIf="showTerm" [idTermo]="idTermo" ></fibra-term-contract>
</div>
<div>
    <fibra-modal-share-data *ngIf="noAgreed" [noAgreed]="noAgreed"></fibra-modal-share-data>
</div>

<fibra-modal-overdraft-limit-change #content (closeEvent)="closeModalOverdraft($event)" *ngIf="showOverdraftLimitModal"></fibra-modal-overdraft-limit-change>

<fibra-modal-default-company #content (closeEvent)="closeEvent($event)" [deactivate]="(index !== null)" [companySelector]="companySelector" *ngIf="showModalDefaultCompany && !readonly"></fibra-modal-default-company>
