import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { CpfCnpjValidator } from '@src/shared';
import { cnpjMask, cpfMask } from '@src/shared/util-common';
import { productBannerList } from '@src/data/repository/data-share-repository';
import { BannerEmailDataRepository } from '@src/data/repository/dashboard/banner-email.repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAnalytics } from '@angular/fire/analytics';

interface OptionsRadio {
  option: string;
  value: any;
}

@Component({
  selector: 'fibra-modal-banner-email',
  templateUrl: './modal-banner-email.component.html',
  styleUrls: ['./modal-banner-email.component.scss']
})
export class ModalBannerEmailComponent implements OnInit {

  public formEmail: FormGroup;
  public optionPerson: OptionsRadio[] = [];
  public optionContactType: OptionsRadio[] = [];
  public verificaPessoa: string;
  public verificaContactType: string;
  public cpfMask = cpfMask;
  public cnpjMask = cnpjMask;
  public docValid: boolean;
  public productBannerList = productBannerList;

  @Input() bannerType: number;

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', { static: true }) content: HTMLElement;
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  public typToastColor;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';

  public get emailForm() {
    return this.formEmail;
  }
  
  constructor(
    private modalService: NgbModal,
    private formBuild: FormBuilder,
    public bannerEmailDataRepository: BannerEmailDataRepository,
    private toastr: ToastrService,
    public analytics: AngularFireAnalytics
    
  ) {
    this.formEmail = this.formBuild.group({
      name: ['', [Validators.required]],
      personType: ['', [Validators.required]],
      cpfCnpj: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(20),
          CpfCnpjValidator
        ],
      ],
      product: [''],
      email: ['', Validators.required],
      cell: ['', Validators.required],
      contactType: ['', Validators.required]
    });
  }

  ngOnInit(): void {

    this.openModal(this.content);

    this.optionPerson = [
      { option: 'Pessoa Física', value: 'F' },
      { option: 'Pessoa Jurídica', value: 'J' },
    ];

    this.optionContactType = [
      { option: 'Celular', value: 'Celular' },
      { option: 'Email', value: 'Email' },
    ];

    this.setPersonPhysical('J');
    this.setContactType('Celular');
  }

  //#region Manage personal methods forms
  public personalControl(name: string) {
    //this.isPersonalValid.emit(this.formEmail.valid)
    return this.emailForm.get(name);
  }

  public personControlIsInvalid(name: string) {
    const control = this.personalControl(name);
    return control.invalid && control.touched;
  }

  public setIsCpfCnpjValid(bool: boolean) {
    this.docValid = bool;
  }

  public setPersonPhysical(typePerson) {
    let bool;
    this.verificaPessoa = typePerson;

    if (typePerson === 'F') {
      bool = true;
      this.formEmail.patchValue({
        personType: typePerson,
      });
    } else {
      bool = false;
      this.formEmail.patchValue({
        personType: typePerson,
      });
    }

    //this.btnAvancar = false;
  }

  public setContactType(contactType) {
    let bool;
    this.verificaContactType = contactType;

    if (contactType === 'Celular') {
      bool = true;
      this.formEmail.patchValue({
        contactType: contactType,
      });
    } else {
      bool = false;
      this.formEmail.patchValue({
        contactType: contactType,
      });
    }

    //this.btnAvancar = false;
  }

  public personalControlFirstError(name: string): any {
    const control = this.personalControl(name);

    if (control.invalid && (control.touched || control.dirty)) {
      return this.getErrorsControl(control);
    }
  }

  private getErrorsControl(control: AbstractControl) {
    const list =
      control.errors !== null ? Object.getOwnPropertyNames(control.errors) : [];
    if (list.length > 1) {
      const error = {};
      error[list[0]] = control.errors[list[0]];
      return error;
    }

    return control.errors || false;
  }

  sendEmail() {
    const params = {
      name: this.formEmail.value.name,
      type: this.formEmail.value.personType == 'F' ? 'cpf' : 'cnpj',
      num_cpf_cnpj: this.formEmail.value.cpfCnpj,
      product_name: this.bannerType == 1 ? this.formEmail.value.product : "Câmbio",
      phone: this.formEmail.value.cell,
      email: this.formEmail.value.email,
      contact_type: this.formEmail.value.contactType
    }

    if (this.bannerType === 1) {
      this.analytics.logEvent('banner_email_credito', params);
    } else {
      this.analytics.logEvent('banner_email_cambio', params);
    }

    this.bannerEmailDataRepository.sendBannerEmail(params).subscribe((_res) => {
      this.typToastColor = this.GREENCOLOR;
      //this.toast.callModalMessage(null, 'Dados enviados. Em breve você receberá um contato.', null, null, null);
      this.toastr.success('Dados enviados. Em breve você receberá um contato.', null, { closeButton: true });
      this.closeModal();
    }, (_err) => {
      this.typToastColor = this.REDCOLOR;
      //this.toast.callModalMessage(null, "Ocorreu um erro no envio.", "Favor tentar novamente.", null, null);
      this.toastr.error('Ocorreu um erro no envio.', null, { closeButton: true });
      this.closeModal();
    })

  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal(): void {
    this.closeEvent.emit(false);
    this.modalService.dismissAll();
  }

}
