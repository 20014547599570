import { InstructionsTypeModel } from '@src/core/domain/instructions/instructionsType.model';
import { Mapper } from '@src/core/base/mapper';
import { InstructionsTypeEntity } from '@src/data/repository/instructions/instructionsType.entity';

export class InstructionsTypeRepositoryMapper extends Mapper<
  InstructionsTypeEntity,
  InstructionsTypeModel
> {
  mapFrom(param: InstructionsTypeEntity): InstructionsTypeModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
      }
    };

  mapTo(param: InstructionsTypeModel): InstructionsTypeEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data
      }
    };
  }
