import { Mapper } from '@src/core/base/mapper';
import { LoansModel, CETFibraFacil } from '@src/core/domain/loans/loan-model';
import { LoansEntity } from './loans.entity';

export class LoansRepositoryMapper extends Mapper <LoansEntity, LoansModel> {
  mapFrom(param: LoansEntity): LoansModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }
  mapTo(param: LoansModel): LoansEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }
}
