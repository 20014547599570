import { PaymentBatchRepository } from './../../../../data/repository/payment-batch/payment-batch-repository';
import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'fibra-details-liquidado',
  templateUrl: './details-liquidado.component.html',
  styleUrls: ['./details-liquidado.component.scss']
})
export class DetailsLiquidadoComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private termsService: PaymentBatchRepository
    ) { 
      this.option = [
        { title: 'Data do pagamento', value: 1 },
        { title: 'Tipo de transação', value: 2 },
        { title: 'Conta', value: 3 },
        { title: 'CNPJ / CPF', value: 4 },
        { title: 'Valor', value: 5 },
        { title: 'Status', value: 6 },
      ];
    }

  public routAccont: string;
  detailsListLiquidado: any[];
  public filter = new FormControl('');
  public option;
  public nomeArquivo: string;

  @Output() emitItem: EventEmitter<any>;


  ngOnInit() {
  this.detalhesId();
   this.nomeArquivo = this.activatedRoute.snapshot.paramMap.get('nomeArquivo');
  }

  public exportScreen(_value) {}

  detalhesId(){
    const Id = this.activatedRoute.snapshot.paramMap.get('id');
    this.termsService.detalheHistoricoLiquidado(Id).subscribe(data => {
      this.detailsListLiquidado = data['data']
    })
  }

  sendItemList(item) {
    this.emitItem.emit(item);
  }

  public formatDate(date: string) {
    return date.split("T")[0].split("-").reverse().join("/")
  } 

  public toggleStatus(item) {
    if(item.desc_status !== 'LIQUIDADO') {
      item.isToggled = !item.isToggled;
    }
  }

}
