import { Directive } from '@angular/core';
import {
  AbstractControl, NG_VALIDATORS, Validator
} from '@angular/forms';

import { CpfCnpjValidator } from '@src/shared/validators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[cpf-cnpj]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CpfCnpjValidatorDirective,
    multi: true
  }]
})
export class CpfCnpjValidatorDirective implements Validator {

  /**
   * Valida os dados.
   *
   * @param AbstractControl control
   * @return object ou null caso válido
   */
  validate(control: AbstractControl): {[key: string]: any} {
    return CpfCnpjValidator.validate(control);
  }
}
