<table class="table">
  <thead class="table-header">
    <tr>
      <th style="width: 90px; min-width: 90px" class="snug" scope="col" (click)="sort('emissao')">Data da emissão<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 90px; min-width: 90px" class="snug" scope="col" (click)="sort('envio')">Data de envio<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 105px; min-width: 105px" class="snug" scope="col" (click)="sort('vencimento')">Data de vencimento<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 105px; min-width: 105px" class="snug" scope="col" (click)="sort('seu_numero')">Seu número<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 118px; min-width: 118px" class="snug" scope="col" (click)="sort('nosso_numero')">Nosso Número<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 255px; min-width: 255px" class="snug" scope="col" (click)="sort('pagador')">Pagador<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 110px; min-width: 110px" class="snug" scope="col" (click)="sort('valortitulo')">Valor título<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 140px; min-width: 140px" class="snug" scope="col" (click)="sort('instrucao')">Instruções<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 110px; min-width: 110px" class="snug" scope="col" (click)="sort('complemento')">Compl.<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
      <th style="width: 100px; min-width: 100px" class="snugr" scope="col" (click)="sort('situacao')">Status<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
    </tr>
  </thead>
  <div #tablebody class="bodyTable" *ngIf="!hide && !loading">
    <tbody *ngFor="let item of instructions">
      <tr>
        <td style="width: 90px; min-width: 90px" class="snug" scope="row">{{ item.dt_emissao | date: 'dd/MM/yyyy' }}</td>
        <td style="width: 90px; min-width: 90px" class="snug" scope="row">{{ item.dt_envio | date: 'dd/MM/yyyy' }}</td>
        <td style="width: 105px; min-width: 105px" class="snug" scope="row">{{ item.dt_vencimento | date: 'dd/MM/yyyy' }}</td>
        <td style="width: 105px; min-width: 105px" class="snug" scope="row">{{ item.seu_numero }}</td>
        <td style="width: 118px; min-width: 118px" class="snug" scope="row">{{ item.nosso_numero }}</td>
        <td style="width: 255px; min-width: 255px" class="snug" scope="row">{{ item.pagador }}</td>        
        <td style="width: 110px; min-width: 110px" class="snug" scope="row">{{ item.vl_titulo | currency: 'BRL' }}</td>
        <td style="width: 140px; min-width: 140px" class="snug" scope="row">{{ item.ds_instrucao }}</td>
        <td style="width: 110px; min-width: 110px" class="snug" scope="row">{{ item.ds_complemento }}</td>
        <td style="width: 100px; min-width: 100px" class="snug util" scope="col">
          <div [ngClass]="{ pending: item.ds_situacao == 'Em análise', authorized: item.ds_situacao == 'Aprovada', refused: item.ds_situacao == 'Recusada' }"></div>{{ item.ds_situacao }}
        </td>
      </tr>
    </tbody>
    <div class="d-flex justify-content-center" *ngIf="loadingMore">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Carregando...</span>
      </div>
    </div>
  </div>
</table>
<div class="d-flex justify-content-center position" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="empty-info" *ngIf="hide">
  <fibra-generic-info
      type="warn"
      title="Nenhum resultado encontrado"
      message="Tente uma nova pesquisa inserindo novos parâmetros."
    >
    </fibra-generic-info>
</div>

<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
