import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@src/environments/environment";
import { EspecieEntity } from "./especie.entity";
import { getHeader } from "@src/core/base/header";
import { EspecieRepositoryMapper } from "./especie-repository.mapper";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EspecieGetDataRepository {
  especieMapper = new EspecieRepositoryMapper();

  constructor(private http: HttpClient) {}

  public getEspecies(): Observable<EspecieEntity> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<EspecieEntity>(`${environment.URLBase}v1/especies`, httpOptions)
      .pipe(map(this.especieMapper.mapFrom));
  }
}
