import {
    AdminUserValidaDadosModel,
    AdminUserValidaDadosRequest
  } from '@src/core/domain/admin-user/admin-user.model';
  import { Injectable } from '@angular/core';
  import { UseCase } from '@src/core/base/use-case';
  import { Observable } from 'rxjs';
  import { AdminUserRepository } from '@src/core/repositories/admin-user/admin-user.repository';
  
  @Injectable({
    providedIn: 'root',
  })
  export class PostValidaDadosUseCase
    implements UseCase<AdminUserValidaDadosRequest, AdminUserValidaDadosModel> {
    constructor(private adminUserRepository: AdminUserRepository) {}
  
    execute(params: AdminUserValidaDadosRequest): Observable<AdminUserValidaDadosModel> {
      return this.adminUserRepository.postValidaDados(params);
    }
  }
  