<ng-template #content let-modal>
  <div class="parcelas-change-container">
    <div class="modal-header">
      <h4 class="modal-title">Fluxo de Parcelas 
        <span class="span-parcela"> ({{retornoParc.length}} parcelas)</span>
      </h4>
      <button
        type="button" 
        class="close"
        aria-label="Close"
        (click)="closeModal()"  
      >
        <span aria-hidden="true">
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 20.0583L1.00091 1M21 1.00173L1 20.0583L21 1.00173Z"
              stroke="#4D86BF"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
    </div>
    
    <div class="wraper-list-value col-md-12 background-color-white">
      <table class="table">
        <thead>
          <tr>
            <th class="col-wid">
              <div class="text">
                PARCELA
              </div>
            </th>
            <th class="col-wid">
              <div class="text">
                VENCIMENTO
              </div>
            </th>
            <th class="col-wid">
              <div class="text" >
                VALOR*
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of retornoParc; index as i">
            <td>
              <label class="label-td"> {{i + 1}} </label>
            </td>
            <td>
              <label class="label-td">{{item.dt_vencimento | date: 'dd/MM/yyyy'}}</label>
            </td>
            <td>
              <label class="label-td">
                R${{ item.valor_parcela || 0 | number: "1.2-2" }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <footer *ngIf="indexParc">
    <label>
      * A ser acrescido do Índice Pós-Fixado estipulado no campo Taxa Mensal.
    </label>
  </footer>
</ng-template>