import { Injectable } from "@angular/core";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DdaTermDataShare {
  constructor() {}

  private statusTermo = new BehaviorSubject<DdaTermCompanyApprovalModel>(null);

  private statusTermoCancel = new BehaviorSubject<DdaTermCompanyApprovalModel>(
    null
  );

  setStatusTerm(item: DdaTermCompanyApprovalModel): void {
    this.statusTermo.next(item);
  }

  getStatusTerm(): any {
    return this.statusTermo.asObservable();
  }

  setStatusTermCancel(item: DdaTermCompanyApprovalModel): void {
    this.statusTermoCancel.next(item);
  }

  getStatusTermCancel(): any {
    return this.statusTermoCancel.asObservable();
  }
}
