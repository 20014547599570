import { Component, OnInit, ViewChild,LOCALE_ID, Inject } from '@angular/core';
import { TabsDataShare } from '@src/data/repository/data-share-repository';
import { FilterComponent } from '../../components/filter/filter.component';
import * as models from '@src/data/repository/data-share-repository';
import { FormControl, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import * as Util from '@src/shared/util-common';
import { InvestimentsDataRepository } from '@src/data/repository/investiments/investiments.repository';
import { InvestimentsExcelRequest } from '@src/data/repository/investiments/investiments.entity';
import { formatDate } from '@angular/common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})
export class InvestmentsComponent implements OnInit {

  public ids = ['posicao', 'extrato'];
  public posicao;
  public extrato;

  public myGroup: FormGroup;
  public typToastColor;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  constructor( private InvestimentsService: InvestimentsDataRepository, @Inject(LOCALE_ID) private locale: string) { }

  public loading: boolean;
  public hide: boolean;
  public params;
  public hideCard;
  public typeList;
  public lastFragment = "posicaoConsolidada"
  public report = false



  @ViewChild(FilterComponent, null)
  filterComp: FilterComponent;

  ngOnInit() {
    this.changeNavbar('posicao');
    this.changeTable();

    // this.requestSearch();
  }




  public changeNavbar(id: string) {
    if (id === 'posicao') {
      $('#posicao-item').removeClass('item-unselected');
      $('#posicao-item').addClass('item-selected');
      $('#posicao-font').removeClass('item-font-unselected');
      $('#posicao-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'posicao') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.posicao = true;
      this.extrato = false;
      this.lastFragment = "posicaoConsolidada"
    }
    if (id === 'extrato') {
      $('#extrato-item').removeClass('item-unselected');
      $('#extrato-item').addClass('item-selected');
      $('#extrato-font').removeClass('item-font-unselected');
      $('#extrato-font').addClass('item-font-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'extrato') {
          $(`#${elem}-item`).removeClass('item-selected');
          $(`#${elem}-font`).removeClass('item-font-selected');
          $(`#${elem}-item`).addClass('item-unselected');
          $(`#${elem}-font`).addClass('item-font-unselected');
        }
      })
      this.posicao = false;
      this.extrato = true;
      this.lastFragment = "extrato"
    }

  }


  public changeTable() {
    if (this.posicao) {
      this.changeNavbar('posicao');
    } else if (this.extrato) {
      this.changeNavbar('extrato');
    }
  }

  public changeReportView(event) {
    this.report = !this.report;
    this.resetArrays();
  }

  public resetArrays() {
  }

  public exportScreen(value) {
    if (value === 1) {
      this.typToastColor = this.GREENCOLOR;
      this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
      if(this.lastFragment=='posicao')
      {
        this.report=true
      }
      else
      {
        this.report=true
      }
      
      return;
    }

    if (value === 2) {

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      const locale = 'pt-br'
      let dateReport = new Date().toLocaleDateString(locale, options)

      let dt_op = sessionStorage.getItem('dt_op')
      let dt_vencto = sessionStorage.getItem('dt_vencto')
      let produto = sessionStorage.getItem('produtoInvestimentoExcel')=="undefined" ? null :  sessionStorage.getItem('produtoInvestimentoExcel')
      let tipoOp= sessionStorage.getItem('tipoOp') 
      const param: InvestimentsExcelRequest = {
        dataOperacao : formatDate(dt_op, 'yyyy-MM-dd', this.locale),
        dataVencimento : formatDate(dt_vencto, 'yyyy-MM-dd', this.locale),
        produto: produto,
        tipoOperacao:tipoOp
      }
      let errorMessage='Erro ao tentar fazer download do arquivo, tente mais tarde.'
      switch (this.lastFragment) {
        case 'extrato':
          this.InvestimentsService.postExportExcelExtrato(param).subscribe((res) => {
            Util.consoleDev('res', res)
            Util.handleFile(res, `extrato-${dateReport}`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            //alert(errorMessage)
            Util.consoleDev('Erro:', error)
          });
          break;

        case 'posicaoConsolidada':
          this.InvestimentsService.postExportExcelPosicaoConsolidada(param).subscribe((res) => {
            Util.handleFile(res, `posicao-consolidada-${dateReport}`);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            Util.consoleDev('Erro:', error)
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
          });
          break;


        default:
          this.InvestimentsService.postExportExcelPosicaoConsolidada(param).subscribe((res) => {
            Util.handleFile(res, res);
            this.typToastColor = this.GREENCOLOR;
            this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
          }, error => {
            this.typToastColor = this.REDCOLOR;
            this.toast.callModalMessage(null, 'Ocorreu um erro ao tentar baixar o arquivo.');
            Util.consoleDev('Erro:', error)
          });
          break;
      }
    }

  }
}
