import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { PendencyModel } from '@src/core/domain/dashboard/dashboard.model';
import { PendencyRepository } from '@src/core/repositories/dashboard/pendency.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetPendencyUsecases implements UseCase<void, PendencyModel> {
  constructor(private pendencyRepository: PendencyRepository) {}

  execute(): Observable<PendencyModel> {
    return this.pendencyRepository.getPendency();
  }
}
