import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import {AccessTypeModel} from '@src/core/domain/access/accessType.model';
import {AccessRepository} from '@src/core/repositories/access/access.repository';

@Injectable({
  providedIn: 'root'
})
export class GetAccessUsecase implements UseCase<any, AccessTypeModel> {
  constructor(private accessRepository: AccessRepository) { }
  execute(): Observable<AccessTypeModel> {
    return this.accessRepository.getAccess();
  }
}
