import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PixGetTermsDataRepository } from '@src/data/repository/pix/terms/pix-get-terms-repository';
import { map, catchError } from 'rxjs/operators';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ResolveService implements Resolve<any> {

  constructor(
    private serviceTerms: PixGetTermsDataRepository,
    private serviceAllAccounts: GetAllAccountTypeUsecase
  ) { }
  
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot ): Observable<any> {
    switch(route.url[0].path) {
      case 'pix':
        return this.serviceTerms.getPixTerms()
        .pipe(
          map((result) => result.data.status === 'PROVADO'),
          catchError(error => {
            return of({error: error});
          })
        );
      break;
      case 'change-transactional-limits':
        return this.serviceAllAccounts.execute()
        .pipe(map((result) => result));
      return;
    }
  }
}

