import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { optionTypeTransfer, } from '@src/data/repository/data-share-repository';
import { TransactionsDataShare } from '@src/core/usecases/data-share/transactions-data-share.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as Util from '@src/shared/util-common';
import { GetListArchiveUsecase } from '@src/core/usecases/charge/get-archivesListTypes.usecase';
import { GetListArchiveReturnUsecase } from '@src/core/usecases/charge/get-archiveListReturn.usecase';
import { ArchiveModel } from '@src/core/domain/charge/archive.model';
import { PaymentBatchRepository } from '@src/data/repository/payment-batch/payment-batch-repository';
import { ParamsPayment } from '@src/data/repository/payment-batch/payment-batch-entity';
import { error } from '@angular/compiler/src/util';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-payment-batch',
  templateUrl: './payment-batch.component.html',
  styleUrls: ['./payment-batch.component.scss']
})
export class PaymentBatchComponent implements OnInit {

  public loading: boolean;
  public optionTypeTransfer = optionTypeTransfer;
  @Input() isSchedules;
  valor: string = "R$202.110,00";
  remessa: boolean = true;
  retorno: boolean = false;
  liquidado: boolean = false;
  public type;
  public filter: FormGroup;
  public optionPeriod = [7, 15, 30, 90];
  public dataRequest = 7;
  public periodDays;
  public periodCalendar: any;
  period: any;
  dataInicio: any;
  date: any;
  downloadArquivo: any;
  periodDate: any;
  dataFim: any;
  ourNumber: any;
  yourNumber: any;
  draweeName: any;
  emitFilter: any;
  account: any;
  tab: any;
  public today: NgbDate;
  public maxDay: NgbDate;
  public minDay: NgbDate;
  public isGreaterThan730Days: boolean;
  @Output() emitFilterToast: EventEmitter<any> = new EventEmitter();
  @Output() listInput: EventEmitter<ArchiveModel>;
  dataIn: string;
  dataFinal: string;
  public routAccont: string;
  active: any;
  list: any;
  params;
  hide: any;
  public accountsWithPix: any;
  listRemessas: any[];
  listRetorno: any[];
  listLiquidacao: any[];

  inform_remessa: any;
  inform_retorno: any;
  inform_liquidacao: any;
  TesteId: boolean = false;
  headerHistorico: boolean = true;
  contentHistorico: boolean = true;
  headerArquivos: boolean = false;
  headerData: boolean = true;
  detalheArquivoId: any[];
  back: boolean;
  arquivoName: any[];
  text: any[];

  activeTab: string;
  invalidDate:boolean = false;
  
  public dt_Inicio;
  public dt_Final

  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public AMBARCOLOR = '#D69D10';
  public typToastColor = this.REDCOLOR;

  private navigationSub;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  constructor(
    private transactionsDataShare: TransactionsDataShare,
    private formBuild: FormBuilder,
    private getArchiveList: GetListArchiveUsecase,
    private getArchiveReturnList: GetListArchiveReturnUsecase,
    private termsService: PaymentBatchRepository,
    private router:Router
  ) {

    
  }

  ngOnInit() {
    this.handleTabs('remessa');
    this.navigationSub = this.router.events.subscribe((event) => {
      localStorage.removeItem("payment-batch-pending-approval");
    });
  }

  ngOnDestroy() {
    this.navigationSub.unsubscribe();
  }

  public changePeriod(days: number) {
    this.periodDays = days;
    this.dataRequest = days;

    this.periodCalendar = undefined;
    if (this.isSchedules) {
      this.formatDate(this.isSchedules);
      this.sendOptions();
      return;
    }
    this.formatDate();

  }

  public formatDate(isSchedules?) {
    if (isSchedules) {
      let initialDate;
      let finalDate;
      this.period = this.periodDays;
      this.dataInicio = this.date.toISOString().slice(0, 10);
      initialDate = new Date(this.date);
      this.periodDate = initialDate.setDate(this.date.getDate() + this.period);
      finalDate = new Date(this.periodDate);
      this.dataFim = finalDate.toISOString().slice(0, 10);
      this.sendOptions();
      this.sendOptionsExtract();
      return;
    }

    this.period = this.periodDays;
    this.dataFim = this.date.toISOString().slice(0, 10);
    const auxDate = new Date(this.date);
    this.periodDate = auxDate.setDate(this.date.getDate() - this.period);
    const endDate = new Date(this.periodDate);
    this.dataInicio = endDate.toISOString().slice(0, 10);

  }

  public async sendOptions() {
    if (this.ourNumber || this.yourNumber || this.draweeName) {
      this.emitFilter.emit({
        account: await this.account,
        dt_Inicio: await this.dataInicio,
        dt_Final: await this.dataFim,
        draweeName: (await this.draweeName) || '',
        yourNumber: (await this.yourNumber) || '',
        ourNumber: (await this.ourNumber) || '',
        type: (await this.type) || '',
        periodCalendar: this.periodCalendar || '',
      });
      return;
    }
    if (this.account !== undefined) {
      this.emitFilter.emit({
        account: await this.account,
        dt_Inicio: await this.dataInicio,
        dt_Final: await this.dataFim,
        periodCalendar: this.periodCalendar || ''
      });
    }
  }

  public sendOptionsExtract() {
    this.tab.setValue({
      periodDays: this.periodDays,
      periodCalendar: this.periodCalendar,
    });
  }


  public setFilter({ dt_Inicio, dt_Final }) {
    this.dt_Inicio = dt_Inicio
    this.dt_Final = dt_Final
    this.invalidDate = false;
    const date1 = new Date(dt_Inicio);
    const date2 = new Date(dt_Final);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays <= 90) {
      this.postRemessa();
      this.postRetorno("", "")
      this.postLiquidacao("", "")
    } else {
      this.invalidDate = true;
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 90 dias.',
        null,
        null
      );
    }
  }

  public changePeriodDate(period: { to: NgbDate; from: NgbDate }) {
    this.dataInicio = `${period.from.year}-${this.adjustDate(
      period.from.month
    )}-${this.adjustDate(period.from.day)}`;

    this.dataFim = `${period.to.year}-${this.adjustDate(
      period.to.month
    )}-${this.adjustDate(period.to.day)}`;

    this.postRemessa();
    this.postRetorno(this.dataInicio, this.dataFim);
    this.postLiquidacao(this.dataInicio, this.dataFim);

    this.periodCalendar = this.convertPeriodCalendar(period);
    const dateTo = moment(this.dataFim);

    const dateFrom = moment(this.dataInicio);

    const today = moment(moment().format('YYYY-MM-DD'));

    const diffDays = dateTo.diff(dateFrom, 'days');

    this.isGreaterThan730Days = diffDays >= 730 && true;

    if (this.isGreaterThan730Days) {
      this.emitFilterToast.emit('isGreaterThan730Days');
      return;
    } else if (
      today.diff(dateFrom, 'days') <= 0 &&
      today.diff(dateTo, 'days') < -180
    ) {
      this.emitFilterToast.emit('outOfReach');
      return;
    }
    this.dataIn = moment(this.periodCalendar.dataInicio)
      .locale('pt-br')
      .format('DD MMM/yyyy');
    this.dataFinal = moment(this.periodCalendar.dataFinal)
      .locale('pt-br')
      .format('DD MMM/yyyy');
    this.sendOptions();
    this.sendOptionsExtract();



  }

  private adjustDate(date) {
    if (Number(date) < 10) {
      return `0${date}`;
    } else {
      return date;
    }
  }

  private convertPeriodCalendar(period: any) {
    return {
      dataInicio: `${period.from.year}-${this.adjustDate(
        period.from.month
      )}-${this.adjustDate(period.from.day)}`,

      dataFinal: `${period.to.year}-${this.adjustDate(
        period.to.month
      )}-${this.adjustDate(period.to.day)}`,
    };

  }

  postRemessa() {
    if (this.dt_Inicio && this.dt_Final) {
      this.loading = true;

      this.termsService.listarHistoricoRemessa({ dt_inicial: this.dt_Inicio, dt_final: this.dt_Final }).subscribe(
        (data) => this.loadSuccessRemessas(data),
        () => this.loadFailedRemessas()  
      )
    }    
  }

  public loadSuccessRemessas({ data }) {
    this.loading = false;
    this.listRemessas = data;
    this.inform_remessa = data;
  }

  public loadFailedRemessas() {
    this.loading = false;
    this.listRemessas = [];
    this.inform_remessa = [];
  }


  downloadfile(list) {
    this.termsService.pagamentoLoteDownload(list).subscribe(data => {
    },
      error => {
        this.downloadArquivo = error.error.text
        const data = this.downloadArquivo
        const file = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${list}`;
        link.click();
      })
  }


  postRetorno(dataInicio, dataFim) {
    this.loading = true;
    let body = new ParamsPayment
    body.dt_inicial = this.dt_Inicio,
      body.dt_final = this.dt_Final,

      this.termsService.listarHistoricoRetorno(body)
        .subscribe(
          (data) => this.loadSuccessRetorno(data),
          () => this.loadFailedRetorno()
        )
  }

  public loadSuccessRetorno({ data }) {
    this.loading = false;
    this.listRetorno = data;
    this.inform_retorno = data;
  }

  public loadFailedRetorno() {
    this.loading = false;
    this.listRetorno = [];
    this.inform_retorno = [];
  }

  postLiquidacao(dataInicio, dataFim) {
    this.loading = true;
    let body = new ParamsPayment
    body.dt_inicial = this.dt_Inicio,
      body.dt_final = this.dt_Final,

      this.termsService.listarHistoricoLiquidacao(body)
        .subscribe(
          (data) => this.loadSuccessLiquidacao(data),
          () => this.loadFailedLiquidacao()
        )
  }

  public loadSuccessLiquidacao({ data }) {
    this.loading = false;
    this.listLiquidacao = data;
    this.inform_liquidacao = data;
  }

  public loadFailedLiquidacao() {
    this.loading = false;
    this.listLiquidacao = [];
    this.inform_liquidacao = [];
  }

  detalheHistorico(arquivoId) {
    this.termsService.detalheHistorico(arquivoId).subscribe((data) => {
      this.detalheArquivoId = data['data']
      if (this.detalheArquivoId) {
        this.TesteId = true
        this.contentHistorico = false
        this.headerHistorico = false;
        this.headerData = false;
        this.back = true
      }

    })
  }

  backLista() {
    this.TesteId = false
    this.contentHistorico = true
    this.headerHistorico = true;
    this.headerData = true;
    this.back = false
  }

  handleTabs(stage) {
    this.active = stage;

    this.activeTab = stage

    if (stage === 'remessa') {
      this.list = [];
      this.remessa = true;
      this.retorno = false;
      this.liquidado = false;
      this.listLiquidacao = [];
      this.listRetorno = [];
      this.loading = true;
      setTimeout(() => {
        this.requestRemessa();
      }, 600);
      return;
    }

    if (stage === 'retorno') {
      this.list = [];
      this.listRemessas = [];
      this.listLiquidacao = [];
      this.loading = true;
      this.retorno = true;
      this.remessa = false;
      this.liquidado = false;
      setTimeout(() => {
        this.requestRetorno();
      }, 600);
      return;
    }

    if (stage === 'liquidado') {
      this.list = [];
      this.listRemessas = [];
      this.listRetorno = [];
      this.loading = true;
      this.liquidado = true;
      this.remessa = false;
      this.retorno = false;
      setTimeout(() => {
        this.requestLiquidado();
      }, 600);
      return;
    }
  }

  private requestArchiveList = () => {
    this.getArchiveList
      .execute(this.params)
      .subscribe(this.requestSuccess, this.requestError, this.requestComplete);
  };

  private requestArchiveReturnList = () => {
    this.getArchiveReturnList
      .execute(this.params)
      .subscribe(this.requestSuccess, this.requestError, this.requestComplete);
  };

  requestRemessa() {
    const newData = new Date()
    var dtInicial = String(newData.getFullYear()) + "-" + String(newData.getMonth() + 1).padStart(2, '0') + "-" + String(newData.getDate()).padStart(2, '0');
    const finalDay = new Date()
    var x = 14;
    finalDay.setDate(finalDay.getDate() - x);
    var dtFinal = String(finalDay.getFullYear()) + "-" + String(finalDay.getMonth() + 1).padStart(2, '0') + "-" + String(finalDay.getDate()).padStart(2, '0');
    const dataFim = dtInicial;
    const dataInicio = dtFinal;
    this.postRemessa()
  }

  requestRetorno() {
    const newData = new Date()
    var dtInicial = String(newData.getFullYear()) + "-" + String(newData.getMonth() + 1).padStart(2, '0') + "-" + String(newData.getDate()).padStart(2, '0');
    const finalDay = new Date()
    var x = 32;
    finalDay.setDate(finalDay.getDate() - x);
    var dtFinal = String(finalDay.getFullYear()) + "-" + String(finalDay.getMonth() + 1).padStart(2, '0') + "-" + String(finalDay.getDate()).padStart(2, '0');
    const dataFim = dtInicial;
    const dataInicio = dtFinal;
    this.postRetorno(dataInicio, dataFim)
  }

  requestLiquidado() {
    const newData = new Date()
    var dtInicial = String(newData.getFullYear()) + "-" + String(newData.getMonth() + 1).padStart(2, '0') + "-" + String(newData.getDate()).padStart(2, '0');
    const finalDay = new Date()
    var x = 32;
    finalDay.setDate(finalDay.getDate() - x);
    var dtFinal = String(finalDay.getFullYear()) + "-" + String(finalDay.getMonth() + 1).padStart(2, '0') + "-" + String(finalDay.getDate()).padStart(2, '0');
    const dataFim = dtInicial;
    const dataInicio = dtFinal;
    this.postLiquidacao(dataInicio, dataFim)
  }

  private requestSuccess = (value: ArchiveModel) => {
    this.list = Util.verifyArray(value.data);
    this.listInput.emit(value.data);
    this.hide = Util.isEmpty(this.list);
  }

  private requestError = (err) => {
    this.hide = Util.isEmpty(this.list);
    this.loading = false;
  }

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.list);
  }

  public exportScreen(_value) { }

  public verifyCalendar(value) {
    
  }
  
  public formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
