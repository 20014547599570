import {
  Directive,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit {
  @Input() appTooltip: HTMLElement;
  @Input(`appTooltipUseCustomStyle`) useCustomStyle: boolean;

  constructor(private element: ElementRef<HTMLElement>) {}
  ngOnInit(): void {
    this.element.nativeElement.appendChild(this.appTooltip);
    this.element.nativeElement.classList.add('app-tooltip');
    this.appTooltip.classList.add('app-tooltip-content');
  }
}


