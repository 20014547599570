import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FilterComponent } from "../../filter/filter.component";
import * as Util from "@src/shared/util-common";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";
import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";
import { DdaApprovalsModel } from "@src/core/domain/dda/dda-approvals.model.";
import { DdaCallBackEnum } from "@src/core/domain/dda/dda-callback.enum";
import { DdaBranchApprovalEntitty } from "@src/data/repository/dda/entity/term/dda-branch-approval.enity";
import { StatusApprovalChangeEnum, StatusApprovalEnum, StatusTipoValidacaoDdaEnum } from "@src/core/domain/dda/dda-type-approval.enum";


@Component({
  selector: "fibra-aprovacao-masters",
  templateUrl: "./aprovacao-masters.component.html",
  styleUrls: ["../../../pages/dda/dda.scss","./aprovacao-masters.component.scss"],
  providers: [TermImplementationRepository, CpfCnpjPipe],
})
export class DdaAprovacaoMastersComponent implements OnInit {
  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  @ViewChild(FilterComponent, null) filterComp: FilterComponent;

  empresa: any = null;
  filiais: Array<DdaApprovalsModel> = [];

  isLoading: boolean = true;
  isSchedules: any = null;

  util = Util;
  selected: [] = [];

  showBatch: boolean = false;
  toggleAll: boolean = false;
  approval: boolean = null;
  termoAceiteid: number = 0;
  enableAction: boolean = false;
  showDisclaimer: boolean = false;
  totalMasters: number = 0;
  totalAprovadores: number = 0;
  inclusaoFilial : boolean = false;
  contemFilial : boolean = false;

  apiCallback: number = DdaCallBackEnum.AprovacaoAlteracaoFiliais;
  exibirAutorizacao: boolean = false;
  exibirConclusao: boolean = false;
  apiPayload: DdaBranchApprovalEntitty = {
    aprovacoes: [],
    termo_aceite_id: 0,
    status_id: 0,
    filiais: [],
    ds_status: 0,
  };

  typeColorToast = "#ED6C6C";
  statusApproval = StatusApprovalChangeEnum;
  batch: boolean = false;

  constructor(
    private cpfCnpjPipe: CpfCnpjPipe,
    private termRepository: TermImplementationRepository,
    public ddaTermDataShare: DdaTermDataShare
  ) {
    this.getTermoAceite();
  }

  ngOnInit() {
    this.getBranches();
  }

  getTermoAceite() {
    this.ddaTermDataShare.getStatusTerm().subscribe((status) => {
      this.termoAceiteid = status.id;
    });
  }

  checkedAll(value?: boolean) {
    this.toggleAll = value != undefined ? value : !this.toggleAll;
    this.filiais.forEach((item: DdaApprovalsModel) => {
      if(this.enableRowAction(item)){
        item.selecionado = this.toggleAll;
      }
    });

    this.getChecked(true);
  }

  checked(item: DdaApprovalsModel) {
    item.selecionado = !item.selecionado;
    this.getChecked();
  }

  getChecked(checkedAll: boolean = false) {
    let checked = this.filiais.filter((x) => x.selecionado === true);
    this.showBatch = checked === undefined ? false : checked.length > (checkedAll ? 0 : 1);
  }

  getBranches(): void {
    this.isLoading = true;

    this.termRepository.getApprovals(this.termoAceiteid).subscribe(
      (data: any) => {
        this.fetchDataSource(data);
      },

      (error: any) => {
        this.fetchDataSourceError(error);
      }
    );
  }
  fetchDataSource(result: Array<DdaApprovalsModel>) {
    this.filiais = result;
    this.isLoading = false;
  }

  fetchDataSourceError(error: any): void {
    this.isLoading = false;
    this.filiais = [];
  }

  takeApproval(filial: DdaApprovalsModel): void {
    filial.selecionado = true;
    this.approval = true;
    this.process(false);
  }

  takeReproval(filial: DdaApprovalsModel): void {
    filial.selecionado = true;
    this.approval = false;
    this.process(false);
  }

  process(batch: boolean = true): void {
    this.batch = batch;
    let status = this.approval ? StatusApprovalChangeEnum.Approved : StatusApprovalChangeEnum.Reproved;

    let branches: Array<DdaApprovalsModel> = this.filiais.filter(
      (x) => x.selecionado === true
    );

    let approvers = branches.map(
      branches => {
        if (branches.aprovadores) {
          return branches.aprovadores.filter(
            aprovador => (aprovador.email == localStorage.getItem("useremail")) && (aprovador.status_id == StatusApprovalChangeEnum.PendantInclude || aprovador.status_id == StatusApprovalChangeEnum.PendantExclude)
          ).map(x => x.aprovacao_id.toString());
        } else {
          return [];
        }
      }
    ).reduce((a, b) => [...a, ...b]);

    let filiais_aprovadadas: string[] = [];
    branches.forEach(filial => {
      if (filial.aprovadores) {
        var result = filial.aprovadores.filter(aprovador => (aprovador.email === localStorage.getItem("useremail")) && (aprovador.status_id == StatusApprovalChangeEnum.PendantInclude || aprovador.status_id == StatusApprovalChangeEnum.PendantExclude));
        this.apiPayload.termo_aceite_id = filial.termo_id;
        if (result.length > 0) {
          filiais_aprovadadas.push(filial.cnpj + "|" + filial.status_id + "|" + status);
        }
      }
    });

    this.apiPayload.aprovacoes = approvers;
    this.apiPayload.filiais = filiais_aprovadadas;
    this.apiPayload.ds_status = status;
    this.exibirAutorizacao = true;

  }

  exibirMensagem(err: any): void {
    if (err.code !== 400) {
      this.toast.callModalMessage(null, err.message[0], null, null, null);
    } else {
      this.toast.callModalMessage(null, err, null, null, null);
    }
  }

  enableCheckAll(): boolean {
    if(this.filiais=null && this.filiais!=undefined){
      return this.filiais.filter(x=>x.aprovadores.filter(x => x.email !== localStorage.getItem("useremail") && (x.status_id === StatusApprovalChangeEnum.PendantInclude || x.status_id === StatusApprovalChangeEnum.PendantExclude))).length > 0;
    }
    else return false;
  }

  enableRowUser(filial: DdaApprovalsModel, status: number): boolean {
    if(filial!=null && filial!=undefined){
      if(filial.aprovadores!=null && filial.aprovadores!=undefined)
        return filial.aprovadores.find(x => x.status_id === status) !== undefined;
      else return false;
    }
    else return false;
  }

  enableRowAction(filial: DdaApprovalsModel): boolean {
    if(filial.aprovadores != null){
      this.enableAction = filial.aprovadores.some(x => x.email == localStorage.getItem("useremail") && (x.status_id === StatusApprovalChangeEnum.PendantInclude || x.status_id === StatusApprovalChangeEnum.PendantExclude));
    }
    else{
      this.enableAction = false;
    }
    

    return this.enableAction;
  }

  fecharModal(): void {
    this.checkedAll(false);
    this.exibirAutorizacao = false;
  }

  fecharModalConclusao(): void {
    this.exibirConclusao = false;
  }

  respostaApi(value: any): void {
    this.exibirAutorizacao = false;
    if (value.status === "success") {
      
      if(!Array.isArray(value.data)){
        value.data = [value.data];
      }
      
      //
      this.contemFilial     = this.inclusaoFilial = value.data.length > 0;
      this.totalMasters     = value.data[0].totalMasters;
      this.totalAprovadores = value.data[0].totalAprovadores;
      this.exibirConclusao  = true;
    } else {
      this.exibirConclusao = false;
      this.exibirMensagem(value.error);
    }
  }

  onApproval(approval: boolean): void{
    this.approval = approval;
  }
}
