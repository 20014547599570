import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { FuncionalitiesUserAccessTypeModel} from '@src/core/domain/access/accessType.model';
import {AccessRepository} from '@src/core/repositories/access/access.repository';

@Injectable({
    providedIn: 'root'
})
export class GetAccessFuncionalitiesUsecase implements UseCase<any, FuncionalitiesUserAccessTypeModel> {
    constructor(private accessRepository: AccessRepository) { }
    execute(): Observable<FuncionalitiesUserAccessTypeModel> {
        return this.accessRepository.getFuncionalitiesAccess();
    }
}
