import { HttpClient } from '@angular/common/http';
import { Inject, Injectable,LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { handleError } from '@src/shared/util-common';
import { formatDate } from '@angular/common';
import { AccAceResponse, CobrancaEntity, CobrancaResponse, CreditoEntity, CreditoResponse } from './export.entity';

@Injectable({
  providedIn: 'root',
})
export class ExportDataRepository {

  constructor(private http: HttpClient,@Inject(LOCALE_ID) private locale?: string) {}

  getAccAceEmSer(endPeriod:string): Observable<AccAceResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      try {
        return this.http
        .get<AccAceResponse>(
          `${environment.URLBase}v1/comercioexterior/exportacao/accace?dt_ini=null&dt_fim=${endPeriod}`,
          httpOptions
        )
     }
     catch(error) {
         handleError(error);
     }
}

PostExcel(obj: any, url: string): Observable<any> {
  return this.http
    .post<any>(
      `${url}`,
      obj,
      {
        headers: getHeader(),
        responseType: 'blob' as 'json'
      }
    )
}

postExportExcelAccAce(): Observable<any> {
  let json = JSON.parse(sessionStorage.getItem('excel_info'))
  let referencia = json.referencia || null;
  let dataInicio = null;
  let dataFinal = null;

  if(json.dt_fim!==undefined){
    dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
  }
  if(json.dt_ini!==undefined){
    dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
  }


  let obj = {
    dataInicial:dataInicio,
    dataFinal: dataFinal,
    referencia : referencia
  }
  return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/exportacao/accace/excel`);
}
postExportExcelCobranca(): Observable<any> {
  let json = JSON.parse(sessionStorage.getItem('excel_info'))
  let referencia = json.referencia || null;
  let dataInicio = null;
  let dataFinal = null;
  
  if(json.dt_fim!==undefined){
    dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
  }
  if(json.dt_ini!==undefined){
    dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
  }
  
  
  let obj = {
    dataInicial:dataInicio,
    dataFinal: dataFinal,
    referencia : referencia
  }
  return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/exportacao/cobranca/excel`);
}

postExportExcelCobrancaEmSer(): Observable<any> {
  let json = JSON.parse(sessionStorage.getItem('excel_info'))
  let referencia = json.referencia || null;
  let dataInicio = null;
  let dataFinal = null;

  if(json.dt_fim!==undefined){
    dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
  }
  if(json.dt_ini!==undefined){
    dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
  }


  let obj = {
    dataInicial:dataInicio,
    dataFinal: dataFinal,
    referencia : referencia
  }
  return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/exportacao/cobranca/excel`);
}
postExportExcelCartaCredito(): Observable<any> {
  let json = JSON.parse(sessionStorage.getItem('excel_info'))
  let referencia = json.referencia || null;
  let dataInicio = null;
  let dataFinal = null;

  if(json.dt_fim!==undefined){
    dataFinal = formatDate(json.dt_fim, "yyyy-MM-dd", this.locale) || null
  }
  if(json.dt_ini!==undefined){
    dataInicio = formatDate(json.dt_ini, "yyyy-MM-dd", this.locale) || null
  }


  let obj = {
    dataInicial:dataInicio,
    dataFinal: dataFinal,
    referencia : referencia
  }
  return this.PostExcel(obj, `${environment.URLBase}v1/comercioexterior/exportacao/cartacredito/excel`);
}

getCobrancaEmSer(endPeriod:string): Observable<CobrancaResponse> {
  const httpOptions = {
    headers: getHeader(),
  };
    try {
      return this.http
      .get<CobrancaResponse>(
        `${environment.URLBase}v1/comercioexterior/exportacao/cobranca?dt_ini=${endPeriod}&dt_fim=${endPeriod}`,
        httpOptions
      )
   }
   catch(error) {
       handleError(error);
   }
}

getCreditEmSer(endPeriod:string): Observable<CreditoResponse> {
const httpOptions = {
  headers: getHeader(),
};
  try {
    return this.http
    .get<CreditoResponse>(
      `${environment.URLBase}v1/comercioexterior/exportacao/cartacredito?dt_ini=null&dt_fim=${endPeriod}`,
      httpOptions
    )
 }
 catch(error) {
     handleError(error);
 }
}

getAccAceContract(contractNum:string): Observable<AccAceResponse> {
  const httpOptions = {
    headers: getHeader(),
  };
    try {
      return this.http
      .get<AccAceResponse>(
        `${environment.URLBase}v1/comercioexterior/exportacao/accace/contrato/${contractNum}`,
        httpOptions
      )
   }
   catch(error) {
       handleError(error);
   }
   
}

getCobrancaContract(contractNum:string): Observable<CobrancaResponse> {
  const httpOptions = {
    headers: getHeader(),
  };
    try {
      return this.http
      .get<CobrancaResponse>(
        `${environment.URLBase}v1/comercioexterior/exportacao/cobranca/ref/${contractNum}`,
        httpOptions
      )
   }
   catch(error) {
       handleError(error);
   }
   
}

getCreditContract(contractNum:string): Observable<CreditoResponse> {
  const httpOptions = {
    headers: getHeader(),
  };
    try {
      return this.http
      .get<CreditoResponse>(
        `${environment.URLBase}v1/comercioexterior/exportacao/cartacredito/ref/${contractNum}`,
        httpOptions
      )
   }
   catch(error) {
       handleError(error);
   }
   
}
}
