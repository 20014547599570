import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'fibra-page-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss'],
})

export class NoPermissionComponent {
  public arrow1: boolean;
  public arrow2: boolean; 
  public arrow3: boolean;
  constructor(private authService: MsalService) {

  }

  public redirect() {
    this.authService.logout();
  }

  public arrowControl(el: HTMLDivElement) {
    const next = el.nextElementSibling;
    const isHidden = next.getAttribute('open');

    if (isHidden === 'true') {
      next.setAttribute('open', 'false');
      next.setAttribute('style', 'display:block');
    }
    if (isHidden === 'false') {
      next.setAttribute('open', 'true');
      next.setAttribute('style', 'display:none');
    }
  }

}
