import { DropdownList } from '@src/core/domain/business-models/dropdown.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TransferReceiptDataShare } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-same-ownership',
  templateUrl: './same-ownership.component.html',
  styleUrls: ['./same-ownership.component.scss'],
})
export class SameOwnershipComponent implements OnInit {
  accountData;
  companyData;
  beneficiaryData = [];
  purposesData: DropdownList;
  valueTransfer;
  typeTransfer;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  constructor(
    private router: Router,
    private shareTransferReceipt: TransferReceiptDataShare
  ) {
    this.valueTransfer = 0;
  }

  ngOnInit() {
    this.typeTransfer = 'favorite';
  }

  setAmmount(value) {
    this.valueTransfer = value;
  }

  navigateBack() {
    this.router.navigate(['/transfer']);
  }
  errorToast(event) {
    if (event === 11) {
      this.toast.callModalMessage(null, 'CPF invalido', 'Digite novamente.');

      return;
    }
    this.toast.callModalMessage(null, 'CNPJ invalido', 'Digite novamente.');
  }

  errorToastValue(event) {
    if (event) {
      this.toast.callModalMessage(null, null, 'Digite um valor valido.');
    }
  }

  errorToastAccountFibra(event) {
    if (event) {
      this.toast.callModalMessage(
        null,
        'Nenhuma conta encontrada',
        'Digite novamente.'
      );
    }
  }

  goNext() {
    this.shareTransferReceipt.setValue({
      accountData: this.accountData,
      beneficiaryData: this.beneficiaryData,
    });
    this.router.navigate(['/transfer-confirm']);
  }
}
