import { Component, OnInit,OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComexReportDataShare } from '@src/core/usecases/data-share/comex-report-data-share.service';
import { ReceivedPaymentEntity } from '@src/data/repository/receveid-payment/received-payment.entity';
import { ReceveidPaymentDataRepository } from '@src/data/repository/receveid-payment/received-payment.repository';

@Component({
  selector: 'fibra-received-payment-orders',
  templateUrl: './received-payment-orders.component.html',
  styleUrls: ['./received-payment-orders.component.scss']
})
export class ReceivedPaymentOrdersComponent implements OnInit, OnDestroy {

  public loading = false;
  public noData = false;
  public contractNum = '';
  public filterByContract = false;
  public filterBySer = true;
  public radioSelected = '';
  public selectedFilter = '';
  public paymentArray: ReceivedPaymentEntity[];
  public hasError = false;
  public fieldRequired = false;
  public isFin = false;
  public isCob = false;
  public isCar = true;
  public filterForm: FormGroup;
  public serDate = new Date();

  constructor(private formBuilder?: FormBuilder,
              private paymentService?: ReceveidPaymentDataRepository,
              public comexReport?: ComexReportDataShare) {}



  ngOnDestroy(): void {
    sessionStorage.removeItem('excel_info')
  }

  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      filter: ['ser'],
      serDate: [this.serDate.toLocaleDateString()],
      contractNum: ['']
  });

 



  this.radioSelected = 'ser';
  this.sendPaymentData();
  }

  public changeFilterType(type: string) {
    this.fieldRequired = false;
    if( type === 'ser') {
      this.filterBySer = true;
      this.filterByContract = false;
      this.radioSelected = 'ser';
    } else if (type === 'contract') {
      this.filterBySer = false;
      this.filterByContract = true;
      this.radioSelected = 'contract';
    }
  }

  public sendPaymentData() {
    this.loading = true;
    this.fieldRequired = false;
      if (this.radioSelected === 'ser') {
        sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","dt_fim":"'+this.serDate+'"  }')
        localStorage.setItem('view-atual', 'ordem-pgto');
          this.paymentArray = new Array();
          this.paymentService.getReceivedPaymentEmSer(this.serDate.toJSON().slice(0,10)).subscribe((payData) =>{
            if (payData.data.length === 0) {
              this.hasError = false;
              this.noData = true;
              this.loading = false;
              localStorage.setItem('canLoadReport', 'false');
            } else {
              this.paymentArray = payData.data;
              this.paymentArray.forEach((elem) => {
                elem.valorFormatado = this.formatUsd(elem.valor_moeda_estrangeira_or);//this.formatUsd(elem.valor_moeda_estrangeira);
                elem.saldoFormatado = this.formatUsd(elem.valor_moeda_estrangeira)//this.formatUsd(elem.valor_moeda_estrangeira_or);
                elem.dt_op = this.formatDate(elem.dt_op);
              })
              this.loading = false;
              this.noData = false;
              this.hasError = false;
              this.comexReport.setValue(this.paymentArray);
            this.comexReport.setReportInfo({
              title: 'Ordem de pagamento recebida',
              subtitle1: 'Operações em ser',
              subTitle1Value: this.serDate.toLocaleDateString(),
              subtitle2: null,
              subtitle2Value: null
            })
            localStorage.setItem('view-atual', 'ordem-pgto');
            localStorage.setItem('canLoadReport', 'true');
            }
          }, () => {
            this.hasError = true;
            this.loading = false;
            this.noData = false;
            localStorage.setItem('canLoadReport', 'false');
          })
  
      } else {
        this.contractNum =  this.filterForm.value['contractNum'];
        if (this.contractNum === '' || this.contractNum === '0000000000') {
          this.loading = false;
          this.fieldRequired = true;
          //alert('campo obrigatório!');
          return;
        }

        sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","referencia":"'+this.contractNum+'"  }')
        this.paymentService.getReceivedPaymentContract(this.contractNum).subscribe((payData) => {
          if (payData.data.length === 0) {
            this.hasError = false;
            this.noData = true;
            this.loading = false;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.paymentArray = payData.data;
            this.paymentArray.forEach((elem) => {
              elem.valorFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.saldoFormatado = this.formatUsd(elem.valor_moeda_estrangeira_or);
              elem.dt_op = this.formatDate(elem.dt_op);
            })
            this.loading = false;
            this.noData = false;
            this.hasError = false;
            this.comexReport.setValue(this.paymentArray);
            this.comexReport.setReportInfo({
              title: 'Ordem de pagamento recebida',
              subtitle1: 'Referência banco',
              subTitle1Value: this.contractNum,
              subtitle2: null,
              subtitle2Value: null
            })
            localStorage.setItem('view-atual', 'ordem-pgto');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, () => {
          this.hasError = true;
          this.loading = false;
          this.noData = false;
          localStorage.setItem('canLoadReport', 'false');
        })

      }
    }
  
    public switchFilter(evento) {
      this.selectedFilter = evento;
    }
  
    public formatDate(data:string) {
      const date = `${data.slice(8,10)}/${data.slice(5,7)}/${data.slice(0,4)}`;
      return date;
    }
  
    public formatUsd(value: number) {
      const val = value.toLocaleString('en-US', {minimumFractionDigits: 2});
      return val;
    }
  
    public formatBrl(value: number) {
      const val = value.toLocaleString('pt-br', {minimumFractionDigits: 2});
      return val;
    }

}
