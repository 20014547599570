import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { MasterBackupRepository } from '@src/core/repositories/master-backup/master-backup.repository';
import { getHeader } from '@src/core/base/header';
import { Observable } from 'rxjs';
import { VerificarMasterBackup } from '@src/core/domain/master-backup/master-backup.model';
import { environment } from "@src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MasterBackupImplementationRepository extends MasterBackupRepository {
  private httpOptions = {
    headers: getHeader(),
  };

  constructor(private http: HttpClient) {
    super();
  }

  verificarMasterBackup(): Observable<VerificarMasterBackup> {
    return this.http.get<VerificarMasterBackup>(
      `${environment.URLBase}v1/MatrizAcessos/verificar-master-backup`,
      this.httpOptions
    );
  }
}