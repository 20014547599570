<section class="body-content-intro-key" [class.readonly]="readonly" [class.canCancel]="canCancel">
    <div class="pix-intro-div">
        <div class="arrow">
            <img src="/assets/svg/arrow-pix-left.svg" (click)="prior()">
        </div>
        <div [ngSwitch]="pagina">
            <div class="pix-image-content" *ngSwitchCase="0">
                <div class="pix-image-div">
                    <img class="image onboardingimg0" src="/assets/svg/openfinance.svg" alt="pix-image">
                </div>
                <div class="pix-image-div">
                    <img class="image handshake" src="/assets/svg/openbanking_onboarding_handshake.svg" alt="pix-image">
                </div>
                <div class="openbanking-header">
                    <span>O que é o Open Finance?</span>
                </div>
                <div class="pix-info-div">
                    <div class="pix-info-content">
                        <span class="pix-content-info"> Open Finance é um serviço financeiro 
                            que especifica um conjunto de regras 
                            sobre o uso e compartilhamento de 
                            dados e informações financeiras entre 
                            as instituições, proporcionando melhores 
                            produtos e serviços financeiros para você.</span>
                    </div>
                </div>
                <div class="pix-num-passo-div">
                    <div class="passo-actual" id="passo1"></div>
                    <div class="passo" id="passo2"></div>
                </div>
                <div *ngIf="!readonly && canCancel" class="pix-button-div">
                    <button type="button" class="button-cancel" [disabled]="busy" (click)="cancel()">
                        Cancelar operação
                    </button>
                </div>
                <div *ngIf="!readonly" class="pix-button-div" style="margin-top: 10px;">
                    <button type="button" class="button-continue" (click)="ok()">{{busy ? '' : 'Entendi'}}
                        <div *ngIf="busy" class="spinner-border" role="status"></div>                        
                    </button>
                </div>
            </div>
            <div class="pix-image-content" *ngSwitchCase="1">
                <div class="pix-image-div">
                    <img class="image onboardingimg1" src="/assets/svg/openfince.svg" alt="pix-image">
                </div>
                <div class="pix-image-div">
                    <img class="image citizen" src="/assets/svg/openbanking_onboarding_citizen.svg" alt="pix-image">
                </div>
                <div class="openbanking-header">
                    <span>Saiba mais</span>
                </div>
                <div class="pix-info-div">
                    <div class="pix-info-content">
                        <span class="pix-content-info">Se quiser saber mais sobre como o 
                            Open Finance funciona e como ele é 
                            capaz de mudar a vida de muitos 
                            brasileiros, acesse <a target="_blank" href="https://openbankingbrasil.org.br/quem-participa">Portal do Cidadão.</a>
                        </span>
                    </div>
                </div>
                <div class="pix-num-passo-div">
                    <div class="passo" id="passo1"></div>
                    <div class="passo-actual" id="passo2"></div>
                </div>
                <div *ngIf="!readonly && canCancel" class="pix-button-div">
                    <button type="button" class="button-cancel" [disabled]="busy" (click)="cancel()">
                        Cancelar operação
                    </button>
                </div>
                <div *ngIf="!readonly" class="pix-button-div" style="margin-top: 10px;">
                    <button type="button" class="button-continue" (click)="ok()">{{busy ? '' : 'Entendi'}}
                        <div *ngIf="busy" class="spinner-border" role="status"></div>                        
                    </button>
                </div>
            </div>
        </div>
        <div class="arrow">
            <img src="/assets/svg/arrow-pix-right.svg" (click)="next()">
        </div>
    </div>
</section>