<div class="card noborder" *fibraHasRole="'MASTER'">
  <div class="card-body noborder">

    <div class="list-value">
      <div class="d-flex justify-content-center align-items-center" style="height: calc(70vh - 130px)" *ngIf="isLoading">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
      </div>
      <div *ngIf="!isLoading && filiais.length > 0">   
        <table class="table table-fibra">
          <thead>
            <tr class="header-list">
              <th class="text-right" scope="col"><input type="checkbox" class="checkbox" [checked]="toggleAll" (change)="checkedAll()" /></th>
              <th  scope="col" >CNPJ</th>
              <th  scope="col" >Razão Social</th>
              <th  scope="col" >Status</th>
              <th  scope="col" >Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let filial of filiais">
              <th class="text-right" scope="row">
                <input
                [hidden]="!enableRowAction(filial)"
                [disabled]="!enableRowAction(filial) || showBatch"
                type="checkbox"
                class="checkbox"
                [checked]="filial.selecionado"
                (change)="checked(filial)"
              /></th>
              <td scope="col">{{ filial.cnpj | cpfcnpj }}</td>
              <td scope="col">{{ filial.nome }}</td>
              <td scope="col">
                <span [appTooltip]="tooltipAll" class="icon-info notification">
                  <img *ngIf="enableRowUser(filial,9)" alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" />
                  <img *ngIf="enableRowUser(filial,10)" alt="logo" src="assets/svg/bullet-red.svg" style="margin-right: 12px !important;" />
                  <div #tooltipAll class="tooltip-custom">
                      <p style="font-size: 14px;">Aguardando aprovação</p>
                      <div class="masters" style="text-align: left; overflow-y: auto;">
                          <div class="masters-title">
                              <img alt='' src="assets/svg/marcador-icon.svg" style="margin-right: 8px !important">
                              <span class="master-font"> Masters </span>
                          </div>
                          <span  *ngFor="let item of filial.aprovadores">
                              <span class="master-sub" *ngIf="item?.status_id === 9 || item?.status_id === 10">{{item.nome}}</span></span>
                      </div>
                      <div class="arrow"></div>
                  </div>
              </span>
              {{ filial.status_id | ddaStatus }}
              </td>
              <td scope="col">
                <button *ngIf="enableRowAction(filial)" type="button" class="btn btn-sm img-button" [disabled]="!enableRowAction(filial) || showBatch"   (click)="takeReproval(filial)">
                  <img 
                    class="mr-3"
                    src="/assets/svg/reproval.svg"
                    alt=""
                    
                  />
                </button>

                <button *ngIf="enableRowAction(filial)" type="button" class="btn btn-sm img-button" [disabled]="!enableRowAction(filial) ||showBatch" (click)="takeApproval(filial)">
                  <img 
                    src="/assets/svg/check.svg"
                    alt=""
                    
                  />
                </button>
              
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      <div class="empty-info" *ngIf="filiais.length === 0 && !isLoading">
            <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
              message="Tente fazer uma nova pesquisa inserindo novos parâmetros.">
            </fibra-generic-info>
      </div>




<div *ngIf="showBatch" class="row align-items-center justify-content-end label-medium">
  <div class="col-5 text-right">Qual a ação deseja realizar?</div>
  <div class="col-auto">
    <div class="radio-class" style="display: flex; align-items: center">
      <input
        type="radio"
        class="form-check-input"
        value="true"
        name="status"
        (click)="onApproval(true)"
      />
      <div>
        <label class="form-check-label" for="digitableLine">Aprovar</label>
      </div>
    </div>
  </div>
  <div class="col-auto">
    <div class="radio-class" style="display: flex; align-items: center">
      <input
      type="radio"
      class="form-check-input"
      value="true"
      name="status"
      (click)="onApproval(false)"
    />
      <div>
        <label class="form-check-label" for="digitableLine">Reprovar</label>
      </div>
    </div>
  </div>
  <div class="col-auto">
    <button [disabled]="approval===null" type="button" class="text-center button-blue mr-2" (click)="process()">
      Confirmar
    </button>
  </div>
  <div class="col-2">
    <button
      type="button"
      class="leaked-button"
      (click)="checkedAll(false)"
    >
      Remover seleções
    </button>
  </div>
</div>
</div>
</div>

<fibra-modal-validation
  *ngIf="exibirAutorizacao"
  [apiCallback]="apiCallback"
  (closeEvent)="fecharModal()"
  [apiPayload]="apiPayload"
  (responseValidation)="respostaApi($event)"
>
</fibra-modal-validation>
<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
<fibra-modal-alteracao-adesao
  *ngIf="exibirConclusao"
  [tela]="'aprovacao-masters'"
  [inclusaoFilial]="inclusaoFilial"
  [batch]="batch"
  [contemFilial]="contemFilial"
  [totalAprovadores]="totalAprovadores"
  [totalMasters]="totalMasters"
  [recusar]="!approval"
  (closeEvent)="fecharModalConclusao()"
>
</fibra-modal-alteracao-adesao>