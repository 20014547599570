<div class="content">
    <div class="div">
      <form class="flex" [formGroup]="filterForm">
        <div class="form-check form-check-inline" style="margin-right: 30px;justify-content: space-between;">
              <div class="div" style="display: flex;">
                <input
                type="radio"
                class="form-check-input"
                formControlName="filter"
                mdbInput
                (click) = "changeFilterType('ser')"
                value="ser"
              />
              <div>
                <label class="form-check-label" for="digitableLine"
                >Operações em ser</label
              >
              </div>
              </div>
        </div>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            class="form-check-input"
            formControlName="filter"
            mdbInput
            (click) = "changeFilterType('contract')"
            value="contract"
          />
          <label class="form-check-label" for="opticalReader"
            >Referência banco</label
          >
            <input
              type="text"
              maxlength="10"
              placeholder="0000000000"
              class="line-code-bar"
              name="dtEmSer"
              formControlName="serDate"
              disabled
              *ngIf="filterBySer"
            >
            <input
            type="text"
            maxlength="10"
            onlyNumber
            placeholder="000000"
            class="line-code-bar"
            name="contract"
            formControlName="contractNum"
            [(ngModel)]="contractNum"
            *ngIf="filterByContract"
          >
            <div class="button-receipt">
              <button
                type="button"
                class="button-continue"
                (click)="sendPaymentData()"
              >
                Consultar
              </button>
            </div>
            <div>
              <br><br><br><br>
              <span class="field-required" *ngIf="(contractNum === '' || !contractNum) && fieldRequired">Campo obrigatório</span>      
            </div>
        </div>
      </form>
    </div>
    <div class="datatable" *ngIf="!loading && !noData">
      <div class="table-title">
          <div class="table-item itemRefBanco">
            <span class="item-font">Ref.banco</span>
          </div>
          <div class="table-item itemDataRecepcao">
            <span class="item-font">Data de recepção</span>
          </div>
          <div class="table-item itemMoeda">
            <span class="item-font">Moeda</span>
          </div>
          <div class="table-item itemValor">
            <span class="item-font labelRight">Valor</span>
          </div>
          <div class="table-item itemSaldo">
            <span class="item-font labelRight">Saldo</span>
          </div>   
      </div>
      <div class="table-content" *ngFor= "let pay of paymentArray">
        <div class="type">
          <span class="type-font" data-hj-suppress> Ordenante: {{pay.nome_contraparte}} </span>
        </div>
        <div class="table-title">
          <div class="table-item itemRefBanco">
            <span class="item-font">{{pay.num_contrato}}</span>
          </div>
          <div class="table-item itemDataRecepcao">
            <span class="item-font">{{pay.dt_op}}</span>
          </div>
          <div class="table-item itemMoeda">
            <span class="item-font">{{pay.swift}}</span>
          </div>
          <div class="table-item itemValor">
            <span class="item-font labelRight">{{pay.valorFormatado}}</span>
          </div>
          <div class="table-item itemSaldo">
            <span class="item-font labelRight">{{pay.saldoFormatado}}</span>
          </div>
      </div>
      </div>
    </div>
    <div class="datatable warning-display"  *ngIf="loading">
      <div
      class="d-flex justify-content-center position loading-container"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    </div>
    <div class="datatable warning-display"  *ngIf="noData && !loading">
      <div class="empty-info">
        <fibra-generic-info
      type="warn"
      title="Nenhum resultado encontrado"
      message="Tente uma nova pesquisa inserindo novos parâmetros."
    >
    </fibra-generic-info>
      </div>
    </div>
    <div class="datatable warning-display" *ngIf="hasError && !loading">
      <div class="empty-info">

        <fibra-generic-info
        type="error"
        title="Ocorreu um erro inesperado"
        message="Não foi possível encontrar dados de movimentação!"
      >
      </fibra-generic-info>
        </div>
    </div>
  </div>
