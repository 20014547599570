import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fibra-generic-toaster',
  templateUrl: './generic-toaster.component.html',
  styleUrls: ['./generic-toaster.component.scss'],
})

export class GenericToasterComponent implements OnChanges {
  @Input() title?: string;
  @Input() text?: string;
  @Input() show?: boolean;
  @Output() action?: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnChanges() {

  }

  closeToaster = () => {
    this.action.emit();
  }
}
