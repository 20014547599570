import { CompanyTypeModel } from '@src/core/domain/company/companyType.model';
import { Mapper } from '@src/core/base/mapper';
import { CompanyTypeEntity } from '@src/data/repository/company/companyType.entity';

export class CompanyTypeRepositoryMapper extends Mapper<
  CompanyTypeEntity,
  CompanyTypeModel
> {
  mapFrom(param: CompanyTypeEntity): CompanyTypeModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data
    };
  }

  mapTo(param: CompanyTypeModel): CompanyTypeEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
