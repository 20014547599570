export enum PagamentoStatus {
    Pendente = 0,
    Aprovado = 1,
    Reprovado = 2,
    Cancelado = 3,
    AguardandoApMaster = 4,
    AguardandoSaldo = 5,
    CanceladoSaldoInsuficiente = 6,
    Agendado = 7,
    AgendadoProximoDiaUtil = 8,
    PendenteBancoFibra = 9,
    AguardandoBaixaCip = 10,
    EmProcessamento = 11,
    Pago = 12
  }
  