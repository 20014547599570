import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fibra-modal-registrato-error',
  templateUrl: './modal-registrato-error.component.html',
  styleUrls: ['./modal-registrato-error.component.scss'],
})
export class ModalRegistratoErrorComponent implements OnInit {

  @ViewChild('content', { static: true }) content: HTMLElement;
  @Output() closeEvent: EventEmitter<any>;


  constructor(
    private modalService: NgbModal,
  ) {
    this.closeEvent = new EventEmitter();
  }

  ngOnInit(): void {
    this.openModal(this.content);
  }

  public openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal(): void {
    this.closeEvent.emit(false);
    this.modalService.dismissAll();
  }

}
