import { BankDataShare } from '@src/core/usecases/data-share/bank-data-share.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  PipeTransform,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { BankEntity } from '@src/data/repository/transfer/bank/bank.entity';
import { GetBankUsecase } from '@src/core/usecases/transfer/get-all-bank-usecases';
import {ActivatedRoute, Router} from '@angular/router';
import * as Util from '@src/shared/util-common';
import { RolesService } from '@src/shared/services/roles.service';
import { ToastErrorMessageComponent } from '../toast-error-message/toast-error-message.component';

interface Banks {
  value: string;
  label: string;
  nome_banco: string;
  cod_banco: number;
  css: string;
}

@Component({
  selector: 'fibra-list-bank',
  templateUrl: './list-bank.component.html',
  styleUrls: ['./list-bank.component.scss'],
  providers: [DecimalPipe],
})
export class ListBankComponent implements OnInit {
  @Input() data;
  @Input() params;
  @Output() emitBank: EventEmitter<any> = new EventEmitter();
  public filter = new FormControl('');
  public getOrderedDate;
  public getOrderedPayer;
  public getOrderedValue;
  public hide = false;
  public loading;
  public option;
  public newAccount;
  public banks: Observable<Banks[]>;
  public fibraFirst: any;
  public noFibra = true;
  public urlTree;
  public typeColorToast;
  public REDCOLOR = '#ED6C6C';

  listDataTemp = [];
  listDataFirstTemp = [];

  constructor(
    public pipe: DecimalPipe,
    private getAllBank: GetBankUsecase,
    private shareBank: BankDataShare,
    private router: Router,
    private route: ActivatedRoute,
    private roles: RolesService
  ) {
    this.loading = true;

    this.banks = this.filter.valueChanges.pipe(
      startWith(''),
      map((text) => this.search(text, this.pipe))
    );
  }
  
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit() {
    this.typeColorToast = this.REDCOLOR;
    this.requestBank();
    this.configList();
    this.shareBank.dataShared.subscribe((res) => {
      if (res === 'new') {
        this.newAccount = true;
      }
    });

    this.route.queryParams.subscribe((parametros) => {
      this.urlTree = this.router.parseUrl('/ownership');
      this.urlTree.queryParams = parametros;
    });
  }

  public configList() {
    this.filter.valueChanges.subscribe((res) => {
      this.banks.subscribe((res) => (this.hide = Util.isEmpty(res)));
      return;
    });
  }

  search(text: string, pipe: PipeTransform): Banks[] {
    return this.data.filter((banks) => {
      const term = text.toLowerCase();

      let result =
        banks.nome_banco.toLowerCase().includes(term) ||
        this.mountCodBank(pipe.transform(banks.cod_banco)).includes(term);

      return result;
    });
  }

  initialLetters(name: string) {
    name = name.replace(/\s(de|da|dos|das)\s/g, ' ');
    const initials = name.match(/\b(\w)/gi);

    if (initials.length > 1) {
      return (initials[0] + initials[1]).toUpperCase();
    } else {
      return name.substr(0, 2);
    }
  }

  getRandColor() {
    let iconColor = (Math.random() * (10 - 1) + 1).toFixed(0);

    return 'bg-' + iconColor;
  }

  selectBank(bank) {
    var permissoes = this.roles.get();
    if((permissoes.includes('TRANSF.TRANSF_OTR_TIT_BC') || permissoes.includes('TRANSF.TRANSF_MSM_TIT_OTR_BC')) && bank.cod_banco != 224){
      this.emitBank.emit(bank);
      if (this.newAccount) {
        this.router.navigate(['/new-ownership']);
      } else {
        this.router.navigateByUrl(this.urlTree);
      }
    }else if((permissoes.includes('TRANSF.TRANSF_OTR_TITU') || permissoes.includes('TRANSF.TRANSF_MSM_TITU')) && bank.cod_banco == 224){
      this.emitBank.emit(bank);
      if (this.newAccount) {
        this.router.navigate(['/new-ownership']);
      } else {
        this.router.navigateByUrl(this.urlTree);
      }
    }else{
      this.toast.callModalMessage(null, "Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.");
    }
  }

  private requestBank = () => {
    this.getAllBank.execute().subscribe(this.requestSuccess, this.requestError);
  };

  private requestSuccess = (value: BankEntity) => {
    let bankList: any = [];

    if (value.code === 200) {
      bankList = value.data;

      bankList.forEach((item) => {
        if (
          item.cod_banco === 224 ||
          item.cod_banco === 1 ||
          item.cod_banco === 33 ||
          item.cod_banco === 104 ||
          item.cod_banco === 341 ||
          item.cod_banco === 237
        ) {
          let i = 0;
          switch (item.cod_banco) {
            case 224:
              i = 0;
              break;

            case 237:
              i = 1;
              break;

            case 1:
              i = 2;
              break;

            case 33:
              i = 3;
              break;

            case 104:
              i = 4;
              break;

            case 341:
              i = 5;
              break;

            default:
              i = 0;
              break;
          }

          this.listDataFirstTemp[i] = {
            cod_banco: item.cod_banco,
            nome_banco: item.nome_banco,
            css: this.getRandColor(),
          };
        } else {
          this.listDataTemp[item.cod_banco] = {
            cod_banco: item.cod_banco,
            nome_banco: item.nome_banco,
            css: this.getRandColor(),
          };
        }
      });
    }
    this.data = this.listDataFirstTemp.concat(this.listDataTemp);
    this.loading = false;
  };

  mountCodBank(cod: number) {
    return ('000' + cod.toString()).slice(-3);
  }

  public formatBankCode = (code) => {
    const newCode = String(code);
    if (newCode.length === 1) {
      return '00' + newCode;
    }
    if (newCode.length === 2) {
      return '0' + newCode;
    }
    return code;
  };

  private requestError = (err) => {
    this.loading = false;
    this.hide = Util.isEmpty(this.data);
    return console.log(err);
  };
}
