<ng-template #content let-modal>
        <div class="modal-arquivo-container">
          <div class="modal-header">
            <div class="generic-info">
              <h1>Erros do arquivo</h1>
              <br>
              <div class="archive-group">
                <div class="archive-group-icon">
                  <span style="width: 200px;font-size:12px">{{filename}}</span>&nbsp;&nbsp;
                  <span style="width: 100px;font-size:10px;color:gray">{{size}}</span>&nbsp;&nbsp;
                  <span style="width: 50px">
                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.2 11.0862V12.0172C14.2 12.2641 14.1052 12.501 13.9364 12.6756C13.7676 12.8502 13.5387 12.9482 13.3 12.9482H6.7C6.46131 12.9482 6.23239 12.8502 6.0636 12.6756C5.89482 12.501 5.8 12.2641 5.8 12.0172V11.0862H1V17.2931H19V11.0862H14.2Z"
                        stroke="#082A4D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M2.49994 1H17.4999M2.49994 8.75863H17.4999H2.49994ZM2.49994 6.17242H17.4999H2.49994ZM2.49994 3.58621H17.4999H2.49994Z"
                        stroke="#082A4D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </div>
              </div>
              <br>
              <textarea class="descricao_erro"  [(ngModel)]="messageError"
              maxlength="40" rows="6" cols="40" disabled></textarea>
            </div>
          </div>
          <br>
          <div class="text-center">
        
          <div class="col-12 buttons">
            <button
              type="button"
              class="button-yes"
              (click)="close()"
            >
              Voltar
            </button>
          </div>
          <br>
        </div>
    </div>
</ng-template>