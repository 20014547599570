import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';

@Injectable({
  providedIn: 'root',
})
export class PixDeleteKeysDataRepository {

  constructor(private http: HttpClient) {}

  public deleteKey(key:string) :Observable<any> {
    const httpOptions = {
      headers: getHeader(),
    };  

      return this.http.delete<any>(`${environment.URLBase}v1/pix/chave/${key}`, httpOptions);
  }
}
