import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageInterface } from "../modal-conclusao-adesao/interfaces/image-interfaces";

@Component({
  selector: "fibra-modal-confirmation",
  templateUrl: "./modal-confirmation.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
})
export class ModalConfirmationComponent implements OnInit {
  @ViewChild("content", { static: true }) content: HTMLElement;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() title: string = "";
  @Input() message: string = "";  
  @Input() recusar: boolean = false; 

  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit() {
    this.openModal(this.content);
  }  

  openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "modal-xxl",
      backdrop: "static",
    });
  }

  confirm(): void {
    this.closeEvent.emit(true);
    this.modalService.dismissAll();
  }

  cancel(): void {
    this.closeEvent.emit(false);
    this.modalService.dismissAll();
  }
}
