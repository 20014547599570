<ng-template #content let-modal>
    <div class="modal-body">
  
      <div class="text">
        <div class="elipse">
          <p>!</p>
        </div>
        <h4 class="title">Ocorreu um erro</h4>
      </div>
  
      <div class="msg">
        <p>Não foi possível concluir a validação.</p>
        <p>Tente novamente.</p>
      </div>
  
      <button type="button" class="btn btn-primary btn-block" (click)="closeModal()">Fechar</button>
  
    </div>
  </ng-template>