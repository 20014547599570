<div class="main-selector">
    <fibra-company-list></fibra-company-list>
    <div class="main-divisor"></div>
    <fibra-profile-list contasPermitidas="['CHEQUE EMPRESA', 'CONTA CORRENTE']"></fibra-profile-list>
</div>

<!-- Loading -->
<div class="d-flex justify-content-center align-items-center loadingView" *ngIf="loading">
    <div class="spinner-border text-primary" role="status"></div>
</div>


<div class="content-page" *fibraHasRole="'
  PAG_BOL.REALIZAR_PAG_BOL'">
    <fibra-breadcrumb height="280" infoAccount="" roule="Pagamento de boletos" arrow="false" downloadDoc="false">
    </fibra-breadcrumb>

    <div class="body-content">
        <!-- Header MSG -->
        <div class="payment-header-msg" *ngIf="paymentStep === 3">
            <div class="row">
                <div class="col-1">
                    <img src="/assets/png/iconConfirm.png" />
                </div>
                <div class="col-11 payment-header-content">
                    <label class="payment-header-status control-label">{{
            isPayment ? 'Pagamento realizado' : 'Pagamento agendado'
          }}</label>
                    <label class="payment-header-txt" *ngIf="isPayment === false">Será realizado na data agendada, mediante disponibilidade de saldo
            em conta.</label
          >
        </div>
      </div>
    </div>
    <!-- Header MSG / End -->

    <!-- Payment Content -->
    <div class="payment" *ngIf="paymentStep !== 3 && paymentStep !== 4">
      <!-- Header -->
      <header class="payment-header" *ngIf="paymentStep !== 3">
        <div class="row">
          <div
            class="col-5 payment-header-title"
            [ngClass]="{ disabled: paymentStep === 2 }"
          >
            <span class="number-circle">
              <label>1</label>
                    </span>
                    <h1>Dados do pagamento</h1>
                </div>
                <div class="col-7 payment-header-title disabled" [ngClass]="{ disabled: paymentStep === 1 }">
                    <span class="number-circle">
              <label>2</label>
            </span>
                    <h1>Revisão</h1>
                </div>
            </div>
            </header>
            <!-- Header / End -->

            <!-- Step One -->
            <div class="payment-step-one" *ngIf="paymentStep === 1">
                <!-- Details -->
                <div class="payment-details">
                    <div class="payment-details-content">
                        <div class="row" style="margin-bottom: 16px">
                            <div class="col-12">
                                <h1 class="payment-details-title">Conta para débito</h1>
                            </div>
                            <div class="col-12">
                                <p class="payment-details-txt">
                                    {{ paymentContaCod }} - {{ paymentContaExibicao | titlecase }}
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <h1 class="payment-details-title">
                                    Saldo disponível {{ paymentContaDesc !== 'CHEQUE EMPRESA' || limiteChequeEmpresa == 0 ? ': R$' + (paymentContaSemChequeEmpresa || 0 | number: '1.2-2') : null }}
                                </h1>
                            </div>
                            <div class="col-12" *ngIf="paymentContaDesc === 'CHEQUE EMPRESA' && limiteChequeEmpresa > 0">
                                <div class="col-4" style="float: left; padding-left: 0">
                                    <p class="payment-details-txt">
                                        Sem {{ paymentContaExibicao | titlecase }}:
                                        <span class="bold">R$ {{ paymentContaSemChequeEmpresa || 0 | number: '1.2-2' }}</span>
                                    </p>
                                </div>
                                <div class="col-8">
                                    <p class="payment-details-txt">
                                        Com {{ paymentContaExibicao | titlecase }}:
                                        <span class="bold">R$ {{ paymentContaComChequeEmpresa || 0 | number: '1.2-2'}}</span>
                                    </p>
                              </div>
                            </div>
                        </div>
            <!-- Details -->
          </div>
        </div>
        <!-- Details -->

        <!-- Content -->
        <main class="payment-body">
          <!-- Leitor de código -->
          <div class="payment-body-content">
            <div class="row">
              <div class="col-12">
                <h1 class="payment-title">Dados do pagamento</h1>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="payment-txt">Digite o código de barras:</label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-3 payment-radio-box col-digitavel">
                <input
                  class="payment-radio"
                  checked
                  type="radio"
                  name="payment-radio"
                  id="radio-digitavel"
                  (click)="changePaymentInput('digitavel')"
                  value="digitavel"
                />
                <label class="payment-txt-small">Linha digitável</label>
              </div>

              <div class="col-9 payment-radio-box">
                <input
                  class="payment-radio"
                  type="radio"
                  name="payment-radio"
                  id="radio-otico"
                  (click)="changePaymentInput('otico')"
                  value="otico"
                />
                <label class="payment-txt-small">
                  Leitor ótico <br />
                  <label class="payment-txt-small-sub"
                    >Posicione o leitor ótico sobre o código de barras e
                    aguarde.</label
                  >
                </label>
              </div>
            </div>
            <!-- Código de Barras / Digitável -->
            <div class="row">
              <div class="col-12 padding-bottom">
                <input
                  [(ngModel)]="inputKey"
                  type="text"
                  class="code-bar"
                  [placeholder]="
                    paymentInput === 'digitavel'
                      ? 'Código de barras'
                      : 'Aguardando leitura do código de barras'
                  "
                  (ngModelChange)="validateBarcodeBlock($event)"
                  [ngClass]="{
                    'code-bar':
                      !valuesBoleto && !errorBarCodeDigit && !errorBarCodeOtico,
                    'code-bar-sucess': valuesBoleto,
                    'code-bar-warning': errorBarCodeDigit || errorBarCodeOtico
                  }"
                  [textMask]="{
                    mask: boletoMask,
                    placeholderChar: '_',
                    guide: false,
                    modelClean: true
                  }"
                />
                <div
                  *ngIf="errorBarCodeDigit"
                  class="box-erro"
                >
                  <img src="/assets/png/iconErro.png" />
                  <label>Código de barras inválido.</label>
                </div>
                <div
                  *ngIf="errorBarCodeOtico"
                  class="box-erro"
                >
                  <img src="/assets/png/iconErro.png" />
                  <label>Não foi possível identificar um código de barras. Tente escanear novamente ou digite o código.</label>
                </div>
              </div>
            </div>
          </div>

          <!-- Retorno do Boleto -->
          <div *ngIf="valuesBoleto" class="payment-body-content">
            <div class="row">
              <div class="col-12">
                <label class="payment-title">Valor a ser pago</label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="payment-value"
                  >
                  R$
                    {{
                      mem_calculo.tipo_calculo.toUpperCase() ===
                        'QUALQUERVALOR' ||
                      mem_calculo.tipo_calculo.toUpperCase() ===
                        'ENTREMINIMOEMAXIMO'

                        ? paymentMinValue === 0 ||
                          paymentMinValue === undefined ||
                          paymentMinValue === null
                          ? (mem_calculo.vlr_total | number: '1.2-2')

                          : (paymentMinValue | number: '1.2-2')

                        : mem_calculo.tipo_calculo.toUpperCase() ===
                          'SOMENTEVALORMINIMO'
                        ? (mem_calculo.vlr_minimo | number: '1.2-2')
                        : (mem_calculo.vlr_total | number: '1.2-2')
                    }}
                  <!-- R$ {{ paymentValue || 0 | number: '1.2-2' }} -->
                  </label
                >
              </div>
            </div>

            <div
              class="row minValueMargin"
              *ngIf="
                mem_calculo.tipo_calculo.toUpperCase() === 'QUALQUERVALOR' ||
                mem_calculo.tipo_calculo.toUpperCase() === 'ENTREMINIMOEMAXIMO'
              "
            >
              <div class="col-12">
                <label class="payment-txt">Pagar valor mínimo</label>
              </div>
            </div>

            <div
              class="row"
              *ngIf="
                mem_calculo.tipo_calculo.toUpperCase() === 'QUALQUERVALOR' ||
                mem_calculo.tipo_calculo.toUpperCase() === 'ENTREMINIMOEMAXIMO'
              "
            >
              <div class="col-12 minValueInput">
                <input
                  class="form-control"
                  [ngClass]="
                    paymentMinValue > 0 &&
                    mem_calculo.vlr_minimo > paymentMinValue
                      ? 'payment-min-value-error'
                      : 'payment-min-value'
                  "
                  currencyMask
                  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                  [(ngModel)]="paymentMinValue"
                  placeholder="R$ 0,00"
                />
              </div>
            </div>

            <div
              class="row"
              *ngIf="
                paymentMinValue > 0 && mem_calculo.vlr_minimo > paymentMinValue
              "
            >
              <div class="col-12 minValueErrorView">
                <img
                  src="../../../../assets/png/ellipse.png"
                  class="minValueErrorIcon"
                />
                <label class="minValueErrorText"
                  >Valor abaixo do permitido</label
                >
              </div>
            </div>

            <div class="row padding-top">
              <div class="col-12">
                <label class="payment-title">Data de vencimento</label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="payment-value">{{ paymentDataVencimento }}</label>
              </div>
            </div>

            <div class="row padding-top">
              <div class="col-12">
                <label class="payment-txt">Data de pagamento</label>

                <div class="showToolTip">                
                  <div
                    class="tooltipView"
                    *ngIf="showDiaUtil || showDiaUtilTax || showStatusHourPaymentClosed"
                  >
                    <div class="tooltipContent">
                      <div class="tooltipTexts">
                        
                        <span class="tooltipTitle" *ngIf="showDiaUtil && !showStatusHourPaymentClosed">
                          O pagamento será realizado no próximo dia útil.
                        </span>


                       

                         <div class="tooltipTextsMargin" *ngIf="showDiaUtil && showDiaUtilTax && showEncargosError()"></div>
                            <span class="tooltipTitle" *ngIf="showDiaUtilTax && showEncargosError()">
                          Atenção! O pagamento será feito após a data de
                          vencimento. Serão aplicados encargos.
                        </span>

                        <span class="tooltipTitle"  *ngIf="showStatusHourPaymentClosed">
                          Horário limite excedido.<br/>O pagamento será agendado para o próximo dia útil.
                          Após a data de vencimento serão aplicados encargos.
                        </span>

                       
                                </div>
                                <button class="btn-close" (click)="onClose()">
                        <img src="../../../../assets/img/close.svg" alt="" />
                      </button>
                            </div>
                            <div class="arrowView">
                                <div class="arrowDown"></div>
                            </div>

                          
                        </div>
                    </div>

                    <fibra-datepicker #dp placement="top" (dateSelected)="changeDate($event)" [date]="date">
                    </fibra-datepicker>

                </div>



            </div>

            <div class="row padding-top">
                <div class="col-12">
                    <div class="box-description">
                        <label class="payment-txt">Descrição</label>
                        <span class="counter" [ngClass]="{ redCounter: char == 0 }">
                    {{ char }} caracteres</span>
                    </div>
                    <div class="box-description">
                        <textarea placeholder="" [maxLength]="100" class="txtDescription" (keyup)="changeDescription($event.target.value)" [(ngModel)]="paymentDescription"></textarea>
                    </div>
                </div>
            </div>

            <div class="row padding-top">
                <div class="col-12">
                    <label class="payment-title">Informações do boleto</label>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p>
                        <label class="payment-txt-sub-grey">Código de barras:</label
                  >
                </p>
                <p>
                  <label class="payment-txt">{{
                    paymentBarcode
                  }}</label>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p>
                        <label class="payment-txt-sub-grey">Nome do beneficiário:</label
                  >
                </p>
                <p>
                  <label class="payment-txt data-hj-suppress">{{
                    paymentNomeBeneficiario | titlecase
                  }}</label>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p><label class="payment-txt-sub-grey">CNPJ / CPF</label></p>
                    <p>
                        <label class="payment-txt">{{
                    paymentCnpjCpf | cpfcnpj
                  }}</label>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p><label class="payment-txt-sub-grey">Banco</label></p>
                    <p>
                        <label class="payment-txt">{{ paymentCodBank }} {{ paymentBank }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Data de vencimento</label></p>
                    <p>
                        <label class="payment-txt">{{ paymentDataVencimento }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Valor do boleto</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ valorBoleto || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Desconto</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentDesconto || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Abatimento</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentAbatimento || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Juros</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentJuros || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Multas</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentMultas || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Mora</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentMora || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Valor a ser pago</label></p>
                    <p>
                        <label class="payment-txt">R$
                    {{
                      mem_calculo.tipo_calculo.toUpperCase() ===
                        'QUALQUERVALOR' ||
                      mem_calculo.tipo_calculo.toUpperCase() ===
                        'ENTREMINIMOEMAXIMO'

                        ? paymentMinValue === 0 ||
                          paymentMinValue === undefined ||
                          paymentMinValue === null
                          ? (mem_calculo.vlr_total | number: '1.2-2')

                          : (paymentMinValue | number: '1.2-2')

                        : mem_calculo.tipo_calculo.toUpperCase() ===
                          'SOMENTEVALORMINIMO'
                        ? (mem_calculo.vlr_minimo | number: '1.2-2')
                        : (mem_calculo.vlr_total | number: '1.2-2')
                    }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p>
                  <label class="payment-txt-sub-grey">Data do pagamento</label>
                    </p>
                    <p>
                        <label class="payment-txt">{{ paymentDateFormat }}</label>
                    </p>
                </div>
            </div>


            <div class="row mb-3" *ngIf="showLimit">
              <div class="col-12">
                  <p>
                      <label class="payment-txt text-bold">Horário de liquidação</label>
                  </p>
                  <p>
                      <label class="payment-txt">Pagamento de boleto das 08:30h às 20:00h</label>
                  </p>

              <p>
                <label class="payment-txt text-bold">Limites</label>
              </p>
              <p *ngFor="let limit of limits">
                <label class="payment-txt" *ngIf="limit.tipoTitularidade === 'MESMA_TITULARIDADE'">Mesma titularidade das {{formatHour(limit.horarioInicio)}}h às {{formatHour(limit.horarioTermino)}}h: até {{limit.valorMaximoTotal | currency:'BRL'}}</label>
                <label class="payment-txt" *ngIf="limit.tipoTitularidade === 'OUTRA_TITULARIDADE_PF'">Outra titularidade PF das {{formatHour(limit.horarioInicio)}}h às {{formatHour(limit.horarioTermino)}}h: até {{limit.valorMaximoTotal | currency:'BRL'}}</label>
                <label class="payment-txt" *ngIf="limit.tipoTitularidade === 'OUTRA_TITULARIDADE_PJ'">Outra titularidade PJ das {{formatHour(limit.horarioInicio)}}h às {{formatHour(limit.horarioTermino)}}h: até {{limit.valorMaximoTotal | currency:'BRL'}}</label>
              </p>
            </div>
            </div>
          </div>
        </main>
        <!-- Content / End -->

        <!-- Footer -->
        <div class="payment-footer">
          <div class="payment-footer-content">
            <div class="row">
              <div class="col-12 payment-footer-buttons">
                <!-- Buttons -->
                <button
                  type="button"
                  class="btn btn btn-outline-primary"
                  (click)="navigateBack()"
                >
                  Cancelar
                </button>

                <button
                  type="button"
                  class="btn btn-space-left btn-primary"
                  *ngIf="validateForm()"
                  [disabled]="!validateForm()"
                  (click)="nextStep()"
                >
                  Salvar e Avançar
                </button>
                <!-- Buttons / End -->
              </div>
            </div>
          </div>
        </div>
        <!-- Footer / End -->
      </div>
      <!-- Step One / End -->

      <!-- Step Two -->
      <div class="payment-step-two" *ngIf="paymentStep === 2">
        <!-- Content -->
        <div class="payment-body">
          <div class="payment-body-content">
            <div class="row">
              <div class="col-12">
                <p class="payment-title">Informações do boleto</p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p>
                  <label class="payment-txt-sub-grey">Código de barras</label>
                    </p>
                    <p>
                        <label class="payment-txt" *ngIf="paymentInput === 'digitavel'">
                    {{ paymentBarcode | barcode }}
                  </label>
                        <label class="payment-txt" *ngIf="paymentInput === 'otico'">
                    {{ paymentBarcodeOptical | barcode }}
                  </label>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p>
                        <label class="payment-txt-sub-grey">Nome do beneficiário</label
                  >
                </p>
                <p>
                  <label class="payment-txt data-hj-suppress">{{
                    paymentNomeBeneficiario | titlecase
                  }}</label>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p><label class="payment-txt-sub-grey">CNPJ / CPF</label></p>
                    <p>
                        <label class="payment-txt">{{
                    paymentCnpjCpf | cpfcnpj
                  }}</label>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p><label class="payment-txt-sub-grey">Banco</label></p>
                    <p>
                        <label class="payment-txt">{{ paymentCodBank }} {{ paymentBank }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p>
                  <label class="payment-txt-sub-grey">Data de vencimento</label>
                    </p>
                    <p>
                        <label class="payment-txt">{{ paymentDataVencimento }}</label>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p>
                        <label class="payment-txt-sub-grey">Valor do boleto</label>
                    </p>
                    <p>
                        <label class="payment-txt">R$ {{ valorBoleto || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Desconto</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentDesconto || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Abatimento</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentAbatimento || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Juros</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentJuros || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Multas</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentMultas || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Mora</label></p>
                    <p>
                        <label class="payment-txt">R$ {{ paymentMora || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p><label class="payment-txt-sub-grey">Valor a ser pago</label></p>
                    <p>
                        <label class="payment-txt">R$
                    {{
                      mem_calculo.tipo_calculo.toUpperCase() ===
                        'QUALQUERVALOR' ||
                      mem_calculo.tipo_calculo.toUpperCase() ===
                        'ENTREMINIMOEMAXIMO'
                        ? paymentMinValue === 0 ||
                          paymentMinValue === undefined ||
                          paymentMinValue === null
                          ? mem_calculo.vlr_total
                          : paymentMinValue
                        : mem_calculo.tipo_calculo.toUpperCase() ===
                          'SOMENTEVALORMINIMO'
                        ? mem_calculo.vlr_minimo
                        : (mem_calculo.vlr_total | number: '1.2-2')
                    }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <p>
                  <label class="payment-txt-sub-grey">Data de pagamento</label>
                    </p>
                    <p>
                        <label class="payment-txt">{{ paymentDateFormat }}</label>
                    </p>
                </div>
            </div>

            <div class="row mb-3" *ngIf="paymentDescription !== ''">
                <div class="col-12">
                    <p>
                        <label class="payment-txt-sub-grey">Descrição</label>
                    </p>
                    <p>
                        <label class="payment-txt">{{ paymentDescription }}</label>
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <p class="payment-title padding-top-16">Conta para débito</p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p><label class="payment-txt-sub-grey">Conta</label></p>
                    <p>
                        <label class="payment-txt">{{
                    paymentContaCod + ' - ' + paymentContaExibicao | titlecase
                  }}</label>
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <p>
                        <label class="payment-txt-sub-grey">Dados da conta</label>
                    </p>
                    <p>
                        <label class="payment-txt">AG: {{ paymentContaAg }} | CC:
                    {{ paymentContaCod | account }}</label
                  >
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="payment-footer">
          <div class="payment-footer-content">
            <div class="row">
              <div class="col-12 payment-footer-buttons">
                <!-- Buttons -->
                <button
                  type="button"
                  class="btn btn btn-outline-primary"
                  (click)="back()"
                >
                  Voltar
                </button>

                <!-- TODO: Desabilitado - Habilitar qd for usar os pagamentos multiplos -->
                <button
                  type="button"
                  class="btn btn-space-left btn-primary"
                  (click)="addMultiplePayment()"
                >
                  Salvar e incluir nova transação
                </button>

                <button
                  type="button"
                  class="btn btn-right btn-primary"
                  (click)="securityValidation()"
                >
                  Pagar
                </button>
                <!-- Buttons / End -->
              </div>
            </div>
          </div>
        </div>
        <!-- Footer / End -->
      </div>
      <!-- Step Two / End -->
    </div>

    <!-- Payment Receipt -->
    <div class="payment-receipt" id="payment-receipt-content" *ngIf="paymentStep === 3">
      <!-- Step three -->
      <div class="payment-step-three" *ngIf="paymentStep === 3">
        <!-- Content -->
        <div  class="payment-body">
          <div class="payment-body-content">
            <!-- Header -->
            <div class="row row-line p-4">
              <div class="col-3">
                <img
                  src="/assets/png/logo-receipt.png"
                  style="margin-top: 5px"
                  id="logo-receipt"
                />
              </div>
              <div class="col-9 text-right">
                <h1 style="text-align: center;" class="payment-title-receipt">
                  {{ isPayment ? 'Comprovante de pagamento' : 'Comprovante de pagamento agendado' }}
                </h1>
              </div>
            </div>
            <!-- Header / End -->

            <div class="row p-4">
              <div class="col-4">
                <p><label class="payment-txt-receipt-title">Valor pago</label></p>
                    <p>
                        <label class="payment-txt-receipt">R$
                    {{
                      mem_calculo.tipo_calculo.toUpperCase() ===
                        'QUALQUERVALOR' ||
                      mem_calculo.tipo_calculo.toUpperCase() ===
                        'ENTREMINIMOEMAXIMO'
                        ? paymentMinValue === 0 ||
                          paymentMinValue === undefined ||
                          paymentMinValue === null
                          ? mem_calculo.vlr_total
                          : paymentMinValue
                        : mem_calculo.tipo_calculo.toUpperCase() ===
                          'SOMENTEVALORMINIMO'
                        ? mem_calculo.vlr_minimo
                        : (mem_calculo.vlr_total | number: '1.2-2')
                    }}</label
                  >
                </p>
              </div>

              <div class="col-4">
                <p>
                  <label class="payment-txt-receipt-title"
                    >Forma de pagamento</label
                  >
                </p>
                <p>
                  <label class="payment-txt-receipt">{{ paymentMethod }}</label>
                    </p>
                </div>

                <div class="col-4">
                    <p><label class="payment-txt-receipt-title">Canal</label></p>
                    <p>
                        <label class="payment-txt-receipt">{{ channel }}</label>
                    </p>
                </div>

                <div class="col-4 pt-4">
                    <p>
                        <label class="payment-txt-receipt-title">Data de vencimento</label
                  >
                </p>
                <p>
                  <label class="payment-txt-receipt">{{
                    paymentDataVencimento
                  }}</label>
                    </p>
                </div>

                <div class="col-3 pt-4" *ngIf="!isPayment">
                    <p>
                        <label class="payment-txt-receipt-title">Data agendada</label>
                    </p>
                    <p>
                        <label class="payment-txt-receipt">{{
                    paymentDateFormat
                  }}</label>
                    </p>
                </div>

                <div class="col-5 pt-4">
                    <p>
                        <label class="payment-txt-receipt-title">{{
                    isPayment
                      ? 'Data e hora do pagamento'
                      : 'Data e hora do agendamento'
                  }}</label>
                    </p>
                    <p>
                        <label class="payment-txt-receipt">{{ dt_transacao }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <p class="payment-title-dark">Beneficiário</p>
              </div>
            </div>

            <div class="row p-4">
              <div class="col-4">
                <p><label class="payment-txt-receipt-title">Nome</label></p>
                    <p>
                        <label class="payment-txt-receipt data-hj-suppress">{{
                    paymentNomeBeneficiario | titlecase
                  }}</label>
                    </p>
                </div>

                <div class="col-4">
                    <p><label class="payment-txt-receipt-title">CPF/CNPJ</label></p>
                    <p>
                        <label class="payment-txt-receipt">{{
                    paymentCnpjCpf | cpfcnpj
                  }}</label>
                    </p>
                </div>

                <div class="col-4">
                    <p><label class="payment-txt-receipt-title">Banco</label></p>
                    <p>
                        <label class="payment-txt-receipt">{{ paymentCodBank }} {{ paymentBank }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row p-4">
              <div class="col-4">
                <p class="payment-title-dark-middle">Valor do boleto</p>
                <p>
                  <label class="payment-txt-receipt">R$ {{ valorBoleto || 0 | number: '1.2-2' }}</label>
                    </p>
                </div>

                <div class="col-4">
                    <p class="payment-title-dark-middle">Desconto</p>
                    <p>
                        <label class="payment-txt-receipt">R$ {{ paymentDesconto || 0 | number: '1.2-2' }}</label>
                    </p>
                </div>

                <div class="col-4">
                    <p class="payment-title-dark-middle">Abatimento</p>
                    <p>
                        <label class="payment-txt-receipt">R$ {{ paymentAbatimento || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <p class="payment-title-dark">Encargos</p>
              </div>
            </div>

            <div class="row p-4">
              <div class="col-3">
                <p><label class="payment-txt-receipt-title">Juros</label></p>
                    <p>
                        <label class="payment-txt-receipt">R$ {{ paymentJuros || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>

              <div class="col-3">
                <p><label class="payment-txt-receipt-title">Multas</label></p>
                    <p>
                        <label class="payment-txt-receipt">R$ {{ paymentMultas || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>

              <div class="col-3">
                <p><label class="payment-txt-receipt-title">Mora</label></p>
                    <p>
                        <label class="payment-txt-receipt">R$ {{ paymentMora || 0 | number: '1.2-2' }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <p class="payment-title-dark">Conta debitada</p>
              </div>
            </div>

            <div class="row row-line p-4">
              <div class="col-6">
                <p><label class="payment-txt-receipt-title">Nome</label></p>
                    <p>
                        <label class="payment-txt-receipt data-hj-suppress">{{
                    companyName | titlecase
                  }}</label>
                    </p>
                </div>

                <div class="col-6">
                    <p><label class="payment-txt-receipt-title">CPF/CNPJ</label></p>
                    <p>
                        <label class="payment-txt-receipt">{{
                    companyCnpj | cpfcnpj
                  }}</label>
                    </p>
                </div>

                <div class="col-6 pt-4">
                    <p>
                        <label class="payment-txt-receipt-title">Dados da conta</label
                  >
                </p>
                <p>
                  <label class="payment-txt-receipt" style="line-height: 1.4"
                    >{{ paymentContaExibicao | titlecase }} <br />
                    AG: {{ paymentContaAg }} | CC:
                    {{ paymentContaCod | account }}</label
                  >
                </p>
              </div>
            </div>

            <div class="row row-line p-4">
              <div class="col-12">
                <p>
                  <label class="payment-txt-sub">Número do compromisso</label>
                    </p>
                    <p>
                        <label class="payment-txt">{{ num_compromisso }}</label>
                    </p>
                </div>

                <div class="col-12 pt-4">
                    <p><label class="payment-txt-sub">Código de barras</label></p>
                    <p>
                        <label class="payment-txt" *ngIf="paymentInput === 'digitavel'">{{ paymentBarcode | barcode }}</label
                  >
                  <label class="payment-txt" *ngIf="paymentInput === 'otico'">{{
                    paymentBarcodeOptical | barcode
                  }}</label>
                    </p>
                </div>

                <div class="col-12">
                    <p class="bottomMessage">
                        <label class="payment-txt-sub">O pagamento será efetivado mediante disponibilidade de
                    saldo em conta.</label
                  >
                  </p>
                </div>
            </div>


            <div class="my-4 px-4">
                <p class="sub-text" style="line-height: 18px">
                  Se precisar de ajuda, entre em contato com a gente.
                </p>
            </div>

            <div class="my-4 px-4">
                <p class="d-flex align-items-center text-apoio">
                   <img class="mr-2" src="/assets/svg/apoio.svg" alt="arrow" style="margin: 5px 0px -10px 0px;">
                    Canais exclusivos
                </p>
                <p class="text-apoio" style="margin-left: 31px">Dúvidas e apoio</p>
            </div>
            <div class="my-4 px-4">
                <p class="value" style="font-size: 12px">Corporate, Agro e PMEs</p>
                <p class="mt-2">(11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados</p>
                <p class="mt-4 email">relacionamento@bancofibra.com.br</p>
            </div>

            <div class="my-4 px-4">
                <p class="d-flex align-items-center text-apoio"><img class="mr-2" src="/assets/png/telefone.png" alt="arrow"  style="margin: 5px 0px -10px 0px;">
                    SAC
                </p>
                <p class="text-apoio" style="margin-left: 31px">Sugestões, reclamações e elogios</p>
            </div>
            <div class="my-4 px-4">
                <p class="value" style="font-size: 12px">Canal de Atendimento ao Cliente</p>
                <p class="mt-2">0800 727 0132</p>
                <br>
                <p class="value" style="font-size: 12px">Canal exclusivo para deficientes auditivos ou de fala</p>
                <p class="mt-2">0800 200 6921</p>
            </div>

            <div class="my-4 px-4">
                <p class="d-flex align-items-center text-apoio"><img class="mr-2" src="/assets/png/alto-falante.png" alt="arrow"  style="margin: 5px 0px -10px 0px;">
                    Ouvidoria
                </p>
                <p class="text-apoio" style="margin-left: 31px">Demandas não solucionadas</p>
            </div>
            <div class="my-4 px-4">
                <p class="mt-2">0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.</p>
            </div>
            


          </div>
        </div>
        <!-- Content / End -->

        <!-- Footer -->
        <div class="payment-footer">
          <div class="payment-footer-content">
            <div class="row">
              <div class="col-12 payment-footer-buttons">
                <!-- Buttons -->
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  (click)="newPayment()"
                >
                  Pagar outro boleto
                </button>

                <button
                  type="button"
                  class="btn btn-outline-primary btn-space-left"
                  (click)="generatePDF()"
                >
                  Baixar PDF
                </button>

                <button
                  type="button"
                  class="btn btn-right btn-primary"
                  (click)="navigateBack()"
                >
                  Fechar
                </button>
                <!-- Buttons / End -->
              </div>
            </div>
          </div>
        </div>
        <!-- Footer / End -->
      </div>
      <!-- Step three / End -->
    </div>

    <div class="payment-pending" *ngIf="paymentStep === 4">
      <div class="payment-pending-content">
        <div class="row">
          <div class="col-12">
            <img src="/assets/png/iconWarningOrange.png" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 padding-top">
            <label class="payment-title-warning">Pagamento pendente</label>
                </div>
            </div>

            <div class="row padding-top">
                <div class="col-12">
                    <p class="payment-desc-warning">
                        {{ desc_etapa_pagamento === 'EnviadoAprovacaoSemSaldo' ? 'Aguardando saldo disponível' : 'Enviado para aprovação do(s) master(s) da sua empresa.' }}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button type="button" (click)="navigateBack()" class="btn btn btn-outline-primary">
              Fechar
            </button>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button type="button" (click)="newPayment()" class="btn btn btn-outline-primary">
              Novo Pagamento
            </button>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button type="button" (click)="goToView('/transaction-approval')" class="btn btn btn-primary">
              Acompanhar transação
            </button>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>

<fibra-modal-validation 
  *ngIf="showModalValidation"
  [apiCallback]="apiCallback"
  [apiPayload]="params"
  [handleInternalError]="this.apiCallback !== 1"
  (closeEvent)="closeModal()"
  (responseValidation)="securityResponse($event)">
</fibra-modal-validation>