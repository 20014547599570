import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { LoansRequest, MapaGarantia } from '@src/core/domain/loans/loan-model';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { GetWarrantyMapUsecase } from '@src/core/usecases/loans/get-warranty-map';
import * as Util from '@src/shared/util-common';

@Component({
  selector: 'fibra-warranty-map',
  templateUrl: './warranty-map.component.html',
  styleUrls: ['./warranty-map.component.scss']
})
export class WarrantyMapComponent implements OnInit {


  public recebiveisSupervenienciaInsu: boolean;
  public recebiveisSupervenienciaInsuValue;
  public warrantyMap: MapaGarantia;
  public dataAccount;
  public rotateArrow1 = false;
  public rotateArrow2 = false;
  public loadingWarranty: boolean = true;
  public errWarranty: boolean = false;
  @Input() showMoreInfo:boolean;
  @Output() eventClick = new EventEmitter();

  constructor(
    private getWarrantyMap: GetWarrantyMapUsecase,
    private shareCompany: CompanyDataShare, ) {
  }

  // @Input() typeComponent: string




  ngOnInit(): void {

    const params: LoansRequest = {}


    //this.showMoreInfo = false
    this.shareCompany.dataShared.subscribe((res) => {
      if (res !== undefined && Array.isArray(res.companyList)) {
        this.dataAccount = res.companyList.filter((e) => e.selected)
        Util.consoleDev('this.dataAccount: ', this.dataAccount)
      }
    })

    //Mapa de Garantia    
    this.getWarrantyMap.execute(params).subscribe((warrantiesMap) => {
      this.warrantyMap = warrantiesMap.data
      this.recebiveisSupervenienciaInsu = this.warrantyMap.fluxo_mes_anterior >= this.warrantyMap.total_exigivel_recebiveis ? true : false
      this.recebiveisSupervenienciaInsuValue = this.warrantyMap.fluxo_mes_anterior - this.warrantyMap.total_exigivel_recebiveis
      Util.consoleDev('this.warrantyMap', this.warrantyMap)
      Util.consoleDev('warrantiesMap', warrantiesMap)
      this.loadingWarranty = false
    }, (err) => {
        this.loadingWarranty = false
        this.errWarranty = true
      })
  }


  onClick() {
    this.eventClick.emit('null');
  }





}