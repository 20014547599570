import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getHeader } from "@src/core/base/header";
import { DdaBoletoModel } from "@src/core/domain/dda/dda-boleto.model";
import { DdaBoletoConsultaModel } from "@src/core/domain/dda/dda-consulta-boleto.model";
import { DdaSinglePaymentModel } from "@src/core/domain/dda/dda-single-payment.model";
import { DdaBoletoRepository } from "@src/core/repositories/dda/dda-boleto.repository";
import { environment } from "@src/environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DdaBoletoMappper } from "../entity/boletos/dda-boleto-mapper";
import { PaymentModel } from '@src/core/domain/payment/payment.model';

@Injectable({
  providedIn: "root",
})
export class DdaBoletoImplementationRepository extends DdaBoletoRepository {
  mapperBoleto = new DdaBoletoMappper();

  private httpOptions = {
    headers: getHeader(),
  };

  constructor(private http: HttpClient) {
    super();
  }

  get(param: DdaBoletoConsultaModel): Observable<Array<DdaBoletoModel>> {
    return this.http
      .post(
        `${environment.URLBase}v1/Pagamentos/Listar/BoletosDda`,
        param,
        this.httpOptions
      )
      .pipe(map(this.mapperBoleto.mapFrom));
  }

  batchPayment(): void {
    throw new Error("Method not implemented.");
  }
  singlePayment(param: DdaSinglePaymentModel): void {
    throw new Error("Method not implemented.");
  }
  export(param: string[]): void {
    throw new Error("Method not implemented.");
  }

  exportarXlsx(request: string[]): Observable<PaymentModel<string>> {
    const httpOptions = { headers: getHeader() };

    return this.http.post<PaymentModel<string>>(`${environment.URLBase}v1/Dda/exportar-xlsx`, request, httpOptions);
  }

  exportarCNAB(request: string[]): Observable<PaymentModel<string>> {
    const httpOptions = { headers: getHeader() };

    return this.http.post<PaymentModel<string>>(`${environment.URLBase}v1/Dda/exportar-cnab`, request, httpOptions);
  }
}
