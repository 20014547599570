import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BankDataShare } from '@src/data/repository/data-share-repository';

@Component({
  selector: 'fibra-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
})
export class BankComponent implements OnInit {
  banks;
  constructor(private router: Router, private shareBank: BankDataShare) {}

  ngOnInit() {}

  routerBack() {
    this.router.navigate(['/transfer']);
  }

  selectBank(bank) {
    this.shareBank.setValue(bank);
  }
}
