import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as Util from '@src/shared/util-common';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { PixTransferDataShare } from '@src/core/usecases/data-share/pix-transfer-data-share.service';
import { PixComprovanteDataShare } from '@src/core/usecases/data-share/pix-comprovante-data-share.service';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';

@Component({
  selector: 'fibra-pix-confirm-transfer',
  templateUrl: './pix-confirm-transfer.component.html',
  styleUrls: ['./pix-confirm-transfer.component.scss'],
})
export class PixTransferConfirmComponent implements OnInit {
  @Output() loadConfirm: EventEmitter<any> = new EventEmitter();
  constructor(
    private route: Router,
    public transferShare: PixTransferDataShare,
    public transferComprovante: PixComprovanteDataShare,
    public getBeneficiariesDataRepository: PixGetBeneficiariesDataRepository
  ) {
    this.textBtn = 'Transferir';
    this.textDate = 'Data';
  }
  public beneficiaryData;
  public bank;
  public requestDataApi;
  public showModalValidation = false;
  public isPending;
  public dateCompare = false;
  public companyData;
  public textBtn;
  public textDate;
  public infoComprovantes;
  public loading: boolean = false;
  public dateLabelRepeat;

  public params;
  public apiCallBack;
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit(): void {
    this.beneficiaryData = this.transferShare.getValue();
    if(this.beneficiaryData.repetir.semanaOuMes) {
      this.getDateLabel(this.beneficiaryData.repetir.repetir);
    }

    if (this.beneficiaryData) {
      this.apiCallBack = this.beneficiaryData.callback;      
    }
  }

  private changeDateCompare(compare?) {
    if (compare) {
      this.textBtn = 'Confirmar agendamento';
      this.textDate = 'Agendada para';
    }
  }

  private verifyTypeAccount(value) {
    switch (value) {
      case '8':
        value = 'CC';
        break;
      case '9':
        value = 'PP';
        break;
      case '4':
        value = 'CV';
        break;
      default:
        value = 'CC';
        break;
    }

    return value;
  }

  public DescTypeAccount(value)
  {
    switch(value)
    {
      case 'PP':
        return value = 'Conta Poupança';
      case 'CC':
        return value = 'Conta Corrente';
      default:
        return value;
    }
  }

  private dateFormat(date) {
    const jsdate = new Date(
      date.split('/')[2],
      date.split('/')[1] - 1,
      date.split('/')[0]
    );
    const format = jsdate.toISOString().slice(0, 19);

    return format;
  }

  private successTransfer = (value) => {
    this.infoComprovantes = {
      data: value.data,
      destinoBanco: this.beneficiaryData.beneficiario,
      origemBanco: this.beneficiaryData.contaDebito,
      erroTransfer: false
    }
    this.transferComprovante.setValue(this.infoComprovantes);
    localStorage.setItem('view-selecionada', 'transferir-pixTransferComprovante');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  private errorTransfer = (err) => {
    this.showModalValidation = false;
    var erroTimeout = false;
    if (err.error && err.error.message && err.error.message instanceof Array && err.error.message.length > 0) {
      console.log(err.error.message);
      this.toast.callModalMessage(null, `${err.error.message[0]}`);
    } else if(err.error && err.error.message && err.error.message.length > 0){
      this.toast.callModalMessage(null, 'Transação indisponivel. Tente novamente mais tarde.');
      erroTimeout = true
      console.log(err.error.message);
    }else{
      this.toast.callModalMessage(null, 'Ocorreu um erro ao realizar a operação.');
      console.log("Ocorreu um erro interno ao realizar a operação.");
      erroTimeout = true
    }

    if(erroTimeout){
      this.infoComprovantes = {
        data: null,
        destinoBanco: this.beneficiaryData.beneficiario,
        origemBanco: this.beneficiaryData.contaDebito,
        erroTransfer: erroTimeout
      }
      this.transferComprovante.setValue(this.infoComprovantes)
      localStorage.setItem('view-selecionada', 'transferir-pixTransferComprovante');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    } 
  }

  public removeMask = (value) => value && Util.removeMask(value);

  public resolveDate(date: NgbDate) {
    const dateAux = new Date(date.year, date.month - 1, date.day);
    const format = new Intl.DateTimeFormat('pt-BR').format(dateAux);
    this.dateCompare = Util.compareDate(format);
    this.changeDateCompare(this.dateCompare);
    return format;
  }

  public securityResponse = value => {
    if (value.status === 'success') {
      this.successTransfer(value);
    } else {
      this.errorTransfer(value);
    }
  }

  public closeModal = () => (this.showModalValidation = false);

  public goNext(){
    if(this.apiCallBack == 26){
      this.params = {
        num_canal_pagamento: 3, // TODO: 3 internet banking / 9 mobile
        chave: "",
        valor: this.beneficiaryData.valor,
        data_transferencia: this.beneficiaryData.data,
        descricao: this.beneficiaryData.descricao,
        conta_origem: {
          cod_banco: 224,
          agencia_conta: this.beneficiaryData.contaDebito.agencia.length >= 5 ? this.beneficiaryData.contaDebito.agencia.substring(1,5) : this.beneficiaryData.contaDebito.agencia,
          num_conta: this.beneficiaryData.contaDebito.conta,
        },
        conta_destino: {
          cod_banco: this.beneficiaryData.beneficiario.cod_banco,
          agencia_conta: this.beneficiaryData.beneficiario.agencia.length >= 5 ? this.beneficiaryData.beneficiario.agencia.substring(1,5) : this.beneficiaryData.beneficiario.agencia,
          num_conta: this.beneficiaryData.beneficiario.conta,
          tipo_conta: this.beneficiaryData.beneficiario.tipo_conta === 'PP' ? 'SVGS' : 'CACC',
          cpf_cnpj: this.beneficiaryData.beneficiario.document ? this.beneficiaryData.beneficiario.document.replace(/[^\d]+/g, '') : null,
          nome: this.beneficiaryData.beneficiario.nome,
          desc_banco: this.beneficiaryData.beneficiario.banco
        },
        confirme_linha_credito: this.beneficiaryData.confirme_linha_credito
      };
    }else if(this.apiCallBack == 123){
      this.params = {
        num_canal_pagamento: 3,
        chave: this.beneficiaryData.chave ? this.beneficiaryData.chave : '',
        valor: this.beneficiaryData.valor,
        data_agendamento_transferencia: this.beneficiaryData.data,
        descricao: this.beneficiaryData.descricao,
        conta_origem: {
          cod_banco: 224,
          agencia_conta: this.beneficiaryData.contaDebito.agencia.length >= 5 ? this.beneficiaryData.contaDebito.agencia.substring(1,5) : this.beneficiaryData.contaDebito.agencia,
          num_conta: this.beneficiaryData.contaDebito.conta,
        },
        conta_destino: {
          cod_banco: this.beneficiaryData.beneficiario.cod_banco,
          agencia_conta: this.beneficiaryData.beneficiario.agencia.length >= 5 ? this.beneficiaryData.beneficiario.agencia.substring(1,5) : this.beneficiaryData.beneficiario.agencia,
          num_conta: this.beneficiaryData.beneficiario.conta,
          cpf_cnpj: this.beneficiaryData.beneficiario.document ? this.beneficiaryData.beneficiario.document.replace(/[^\d]+/g, '') : null,
          nome: this.beneficiaryData.beneficiario.nome,
          desc_banco: this.beneficiaryData.beneficiario.banco
        },
        confirme_linha_credito: this.beneficiaryData.confirme_linha_credito,
        repeticao: this.validRepeat()
      };
    }else{
      this.params = {
        num_canal_pagamento: 3,
        chave: this.beneficiaryData.chave,
        valor: this.beneficiaryData.valor,
        data_transferencia: this.beneficiaryData.data,
        descricao: this.beneficiaryData.descricao,
        conta_origem: {
          cod_banco: 224,
          agencia_conta: this.beneficiaryData.contaDebito.agencia.length >= 5 ? this.beneficiaryData.contaDebito.agencia.substring(1,5) : this.beneficiaryData.contaDebito.agencia,
          num_conta: this.beneficiaryData.contaDebito.conta,
        },
        conta_destino: {
          desc_banco: this.beneficiaryData.beneficiario.banco
        },
        confirme_linha_credito: this.beneficiaryData.confirme_linha_credito,
        repeticao: this.validRepeat()
      };
    }
    this.showModalValidation = true;
  }
  public securityRequest = () => {
    return this.params;
  }

  navigateBack() {
    localStorage.setItem('view-selecionada', this.beneficiaryData.pagRetorno);
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  validRepeat() {
    let repeat = null;
    if(this.beneficiaryData.repetir.semanaOuMes) {
      repeat = {
        frequencia: this.beneficiaryData.repetir.semanaOuMes,
        ate: this.beneficiaryData.repetir.repetir,
        semana: this.beneficiaryData.repetir.dias != '' ? this.beneficiaryData.repetir.dias : null
        }
        let labelButtonAcompanharAgendamento = 'true';
        localStorage.setItem('labelButtonAcompanharAgendamento',labelButtonAcompanharAgendamento);
    }
    return repeat;
  }

  getDateLabel(date) {
    if (typeof date === 'object') {
      date = date.toJSON();
    }
    let dateLabel = date.split("T");
      dateLabel = dateLabel[0].split("-");
      this.dateLabelRepeat = this.dateLabelRepeat = `${dateLabel[2]}/${dateLabel[1]}/${dateLabel[0]}`;
   }
}
