import { Injectable } from '@angular/core';
import { PaymentRepository } from '@src/core/repositories/payment/payment.repository';
import { UseCase } from '@src/core/base/use-case';
import { PaymentModel, PaymentRequest, PaymentBoletoRequest } from '@src/core/domain/payment/payment.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetStatusHourPaymentUsecases implements UseCase<any, PaymentModel> {

  constructor(private paymentRepository: PaymentRepository) { }

    execute(x: number): Observable<any> {
    return this.paymentRepository.getStatusHourPayment(x);
  }  
}
