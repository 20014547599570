<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div class="content-page">
  <div class="section">
    <div  class="margin-40-right">
        <img src="/assets/opportunity/svg/icon-money.svg" alt="icon-money">
        <h1>Câmbio</h1>
        <p>Para sua empresa que precisa pagar ou receber recursos de outros países, nossas taxas de câmbio estão entre as mais competitivas do mercado.</p>
        <button (click)="isInterested()">Tenho interesse</button>
    </div>
    <div>
      <img src="/assets/opportunity/img/img-header-cambio.jpg" alt="img-header-cambio">
    </div>
  </div>  
  <div class="section margin-top-80">
    <div>
      <img src="/assets/opportunity/img/img-notebook.jpg" alt="img-notebook">
    </div>
    <div class="padding-40-left">
      <h3>Sua melhor escolha em câmbio!</h3>
      <p class="font-16px">Somos ágeis na análise de documentação e na efetivação da sua operação. O Banco Fibra disponibiliza uma variedade de moedas para melhor atender o que você precisa. Nosso atendimento customizado garante que cada negociação seja feita de acordo com a sua conveniência.</p>
      <button (click)="isInterested()">Saiba mais</button>
    </div>
  </div>  
  <div class="section-benefities margin-top-80">
      <h3>Vantagens do Câmbio com o Banco Fibra</h3>
    <div class="warpper">
      <div *ngFor="let item of benefities" class="warpper-single">
        <img [src]="item.icon" [alt]="item.alt">
        <p class="font-16px">{{ item.description }}</p>
      </div>
    </div>
  </div> 
  <div class="section margin-top-80">
    <div  class="padding-40-right background-with-border-bottom">
        <h3>O que é e como funciona?</h3>
        <p class="font-16px">
          O Câmbio funciona como uma troca de moedas entre brasileiros e estrangeiros que precisam honrar seus compromissos entre si.
        ​</p>

        <p class="font-16px">
          Simplificadamente, a operação de Câmbio surge a partir da demanda de uma empresa brasileira em realizar a troca das moedas de um pagamento ou recebimento no exterior, para Reais, de forma a suprir sua necessidade de realizar o pagamento no exterior ou ingressar caixa no Brasil.​
        ​</p>

        <p #interested class="font-16px">
          Os principais cientes que utilizam do produto são empresas importadoras, exportadoras, contratantes de serviços ou com Matriz ou Filial no exterior, tendo em vista essa necessidade de troca de moeda.
        </p>

    </div>
    <div>
      <img src="/assets/opportunity/img/img-hand.jpg" alt="img-hand">
    </div>
  </div>  
  <fibra-opportunity-form></fibra-opportunity-form>
  <div class="line margin-top-80"></div> 
  <fibra-opportunity-products-list></fibra-opportunity-products-list>
</div>
