import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import { Observable } from 'rxjs';
import { LoansModel, LoansRequest, LoansRequestContractInfo } from '@src/core/domain/loans/loan-model';
import { LoansRepository } from '@src/core/repositories/loans/loans-repository';


@Injectable({
  providedIn: 'root'
})
export class GetParcelaUsecase implements UseCase<LoansRequest, LoansModel> {

  constructor(private loanRepository: LoansRepository) { }

  execute(params: LoansRequestContractInfo): Observable<LoansModel> {
    return this.loanRepository.getParcela(params);
  }
}
