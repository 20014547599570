import { Injectable } from '@angular/core';
import { ArchiveModelRepository } from '@src/core/repositories/charge/archive.repository';
import { UseCase } from '@src/core/base/use-case';
import {
  ArchiveModel,
  ArchiveRequest,
} from '@src/core/domain/charge/archive.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetArchiveDownloadUsecase
  implements UseCase<ArchiveRequest, ArchiveModel> {
  constructor(private archiveRepository: ArchiveModelRepository) {}

  execute(params: ArchiveRequest): Observable<ArchiveModel> {
    return this.archiveRepository.getDownloadArchive(params);
  }
}
