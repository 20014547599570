import { BeneficiariesModel } from './../../../../core/domain/transfer/transfer.model';
import { Mapper } from '@src/core/base/mapper';
import { BeneficiariesEntity } from './beneficiaries.entity';

export class BeneficiariesRepositoryMapper extends Mapper<
  BeneficiariesModel,
  BeneficiariesEntity
> {
  mapFrom(param: BeneficiariesEntity): BeneficiariesModel {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
  mapTo(param: BeneficiariesModel): BeneficiariesEntity {
    return {
      code: param.code,
      status: param.status,
      data: param.data,
    };
  }
}
