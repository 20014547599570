import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import * as Util from '@src/shared/util-common';


@Component({
  selector: 'fibra-download-approval-list',
  templateUrl: './download-approval-list.component.html',
  styleUrls: ['./download-approval-list.component.scss'],
})
export class DownloadApprovalListComponent implements OnInit {
  @Output() public closeReport = new EventEmitter();
  removeMask = (value) => Util.removeMask(value);
  public generatePDF = (value?) => Util.generatePDF(value);
  public data;
  public approvalList;
  public accountType;
  public tabsType;
  public date;
  public hour;
  public diffDateDays;
  public hide;

  constructor() {}

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('approval-export-data'));
    this.hide = false;
    if (this.data) {
      this.approvalList = this.data.data;
    } else {
      this.approvalList = this.data;
    }
    this.hide = Util.isEmpty(this.approvalList);
    this.tabsType = this.data.tabs;
    this.accountType = this.data.date.account;
    this.getDate();
  }

  ngAfterViewInit() {
    this.generatePDF('body-content');
    localStorage.removeItem('approval-export-data');
    this.closeReport.emit('true');
  }

  getDate() {
    const auxDate = new Date();
    this.date = Intl.DateTimeFormat('pt-BR').format(auxDate);
    this.hour = auxDate.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
    this.diffDays();
  }

  diffDays() {
    const date1 = new Date(this.data.date.dt_Inicio);
    const date2 = new Date(this.data.date.dt_Final);

    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    this.diffDateDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  }
}
