<div class="content-page">
  <fibra-breadcrumb
    height="16"
    bottom="-20"
    infoAccount=""
    roule=""
  ></fibra-breadcrumb>

  <div class="acess-denied-content">
    <div class="datatable warning-display">
      <div class="empty-info">
        <fibra-generic-info type="error" title="Acesso negado."
          message="Você não possui acesso a essa funcionalidade. Solicite acesso ao usuário master da sua empresa.">
        </fibra-generic-info>
      </div>
      <div>
        <button class="button-home" [routerLink]="['/dashboard']">Ir para Home</button>
      </div>
    </div>
  </div>
</div>
