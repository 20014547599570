import { 
  Component, 
  OnInit, 
  ViewChild 
} from '@angular/core';
import * as Util from '@src/shared/util-common';
import * as $ from 'jquery';
import { ContratacaoEnum, ParcelasEnum} from '@src/core/domain/product/product.model';
import { GetSolicitacaoUseCase } from '@src/core/usecases/product/get-solicitacao.usecase';
import { GetParcelasUseCase } from '@src/core/usecases/product/get-parcelas.usecase';
import { GetComprovanteUseCase } from '@src/core/usecases/product/get-comprovante.usecase';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';
import { DropdownList } from '@src/core/domain/business-models';

@Component({
  selector: 'fibra-acompanhar-solicitacao',
  templateUrl: './acompanhar-solicitacao.component.html',
  styleUrls: ['./acompanhar-solicitacao.component.scss'],
})
export class AcompanharSolicitacaoComponent implements OnInit {
  public list: Array<ContratacaoEnum> = [];
  public loading: boolean;
  public indexList;
  public sorted: boolean;
  public showModalParcelas = false;
  public parcelasList: Array<ParcelasEnum>;
  public indexParcelas = 0;
  public indexParc = 0;
  public optionsProfile:any [];
  public REDCOLOR = '#ED6C6C';
  public typeColorToast = this.REDCOLOR;
  public toasterTitle: string;
  public toasterMessage: string;
  public URLComprovante:string;
  public parcelasIndexador:boolean = false;
  
  firstVenc: Date;
  lastVenc: Date;
  
  constructor(
    private getSolicitacaoUseCase: GetSolicitacaoUseCase,
    private getParcelasUseCase: GetParcelasUseCase,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private getComprovanteUseCase: GetComprovanteUseCase,
  ) {
    this.toasterTitle = 'Ocorreu um erro no envio!';
    this.toasterMessage = 'Tente novamente.';
  }
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  ngOnInit() {
    this.loading = false;
    this.requestAccount();
  }

  //REQUEST GET COMPROVANTE
  private requestComprovante(cdSimulacao: number): void {
    this.getComprovanteUseCase
      .execute(cdSimulacao)
      .subscribe(this.requestComprovanteSuccess, this.requestComprovanteError);
  }

  private requestComprovanteError = (err) => {
    this.showToast(err);
    return console.log(err);
  };

  private requestComprovanteSuccess = (value: any) => {
    this.URLComprovante = value.data;
    this.downloadFile(this.URLComprovante);
  };

  //REQUEST CONTA
  public requestAccount(): void {
    this.getAllAccountTypes
      .execute(null)
      .subscribe(
        this.requestSuccess,
        this.requestError
        );
  }

  private requestError = (err) => {
    this.typeColorToast = this.REDCOLOR;
    this.showToast(err);
  }

  private requestSuccess = (value) => {
    this.optionsProfile = [];
    value.data.forEach(element => {
      if(element.desc_conta == 'CONTA CORRENTE' || element.desc_conta == 'CHEQUE EMPRESA'){
        this.optionsProfile.push(element)
      }
    });
    this.requestSolicitacaoList();
  }

  //REQUEST GET SOLICITACAO
  private requestSolicitacaoList(): void {
    this.getSolicitacaoUseCase
      .execute()
      .subscribe(this.requestSolicitacaoSuccess, this.requestSolicitacaoError);
  }

  private requestSolicitacaoError = (err) => {
    this.loading = true;
    this.showToast(err);
    return console.log(err);
  };

  private requestSolicitacaoSuccess = (value: any) => {
    this.loading = true;
    this.list = Util.verifyArray(value.data);
    this.list.forEach(element =>{
      var contaItem = this.optionsProfile.find(x => x != null && x.num_conta == element.num_conta);
      if(contaItem){
        element.conta = contaItem;
      }else{
        contaItem = {
          num_conta: "00000000",
          desc_conta:"teste"
        }
        element.conta = contaItem;
      }
    });
  };

  //REQUEST PARCELAS
  parcelas(value: number){
    this.getParcelasUseCase
    .execute(value)
    .subscribe(this.requestParcelasSuccess, this.requestParcelasError);
  }

  private requestParcelasError = (err) => {
    return console.log(err);
  };

  private requestParcelasSuccess = (value: any) => {
    var retornoParce = {
      id: this.indexParc,
      parcelas: value.data
    }
    this.parcelasList = value.data
    // if(this.list.indexador_id == 2){
    //   this.parcelasIndexador = true;
    // }
    this.showModalParcelas = true;
  };

  public closeModalParc = (event) => {
    this.showModalParcelas = false;
  }
  
  sortList(value) {
    this.sorted = !this.sorted;
    this.list.sort((a, b) => {
      if (a[value] < b[value]) {
        if (!this.sorted) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[value] > b[value]) {
        if (!this.sorted) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
  }

  //seta pra baixo quando clicado
  toogleIconArrow(index) {
    if (this.indexList !== index) {
      this.indexList = index;
      $('.collapse').removeClass('show');
      $(`.item-icon img`).removeClass('img-arrow');
    }

    document.getElementById(index).classList.toggle('img-arrow');
    $('input').val('');
    $('option').attr('selected', false);
  }

  //GERA PDF
  public generatePDF(cdSimula: number) {
    this.requestComprovante(cdSimula);
  }
  
  //CALL TOAST ERROR
  private showToast = (err) => {
    if (err.error.message && err.error.message.length > 0) {
      let message: string;
      switch (err.error.message[0]) {
        case 'Cliente não passou pela validação padrão do Internet Banking':
          message =
            'Ocorreu um erro no envio!';
          break;
        default:
          message = err.error.message[0];
          break;
      }
      this.toast.callModalMessage(null, message);
    } else {
      this.toast.callModalMessage(null, this.toasterTitle, this.toasterMessage);
    }
  }

  //DOWNLOAD PDF
  downloadFile(url: any){
    fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "ComprovanteContratacao.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    })
  }
}
