import { Injectable } from '@angular/core';
import { AccountTypeRepository } from '@src/core/repositories/account/account-type.repository';
import { UseCase } from '@src/core/base/use-case';
import { AccountTypeModel } from '@src/core/domain/account/accountType.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetAllAccountTypeUsecase implements UseCase<void, AccountTypeModel> {

  constructor(private accountTypeRepository: AccountTypeRepository) { }

  execute(params: void): Observable<AccountTypeModel> {
    return this.accountTypeRepository.getAllaccountTypes();
  }
}
