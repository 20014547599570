import { Injectable } from '@angular/core';
import { RulesRepository } from '@src/core/repositories/approval-matrix/rules.repository';
import { UseCase } from '@src/core/base/use-case';
import { ApprovalMatrixModel, PeopleRequest } from '@src/core/domain/approval-matrix/approval-matrix.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GetAllPeopleUsecase implements UseCase<PeopleRequest, ApprovalMatrixModel> {

  constructor(private ruleRepository: RulesRepository) { }

  execute(param?: PeopleRequest): Observable<ApprovalMatrixModel> {
    return this.ruleRepository.getAllPeople(param);
  }

  executeApproval(param?: PeopleRequest): Observable<ApprovalMatrixModel> {
    return this.ruleRepository.getAllPeopleApproval(param);
  }
}
