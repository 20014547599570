import { SubjectSubscription } from "@microsoft/signalr/dist/esm/Utils";
import { Mapper } from "@src/core/base/mapper";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";
import { DdaBranchEntity } from "../entity/term/dda-branch.entity";

export class DdaBranchMapper
  implements Mapper<Array<DdaBranchModel>, DdaBranchEntity>
{
  mapFrom(param: Array<DdaBranchModel>): DdaBranchEntity {
    return {
      code: null,
      status: null,
      data: null,
    };
  }
  mapTo(param: DdaBranchEntity): Array<DdaBranchModel> {
    return param.data.map((item) => {
      return {
        cnpjInscricao: item.cnpj.substring(0, 8),
        identificacaoFilial: item.cnpj.substring(8, 14),
        razaoSocial: item.nome,
        cnpjValidado: true,
        msgValidacao: null,
        validado: null,
        excluido: false,
        status: item.descricao_status,
        statusId: item.status_id,
        selecionado: false,
        aprovadores: item.aprovadores,
      };
    });
  }
}
