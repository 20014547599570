<div class="content">
    <div class="div">
        <form class="flex" [formGroup]="filterForm">
            <div class="form-check form-check-inline" style="justify-content: space-between;">
                <div class="dropdown-periodic">
                    <span class="span"> Operação </span>
                    <fibra-dropdown class="period-drop" [style.width.px]="156" customClass="period" [options]="periods" (change)="switchFilter($event)" textColor="#8f969d"></fibra-dropdown>
                </div>
                <div class="radio-class" style="display: flex; align-items: center;">
                    <input type="radio" class="form-check-input" formControlName="filter" mdbInput (click)="changeFilterType('ser')" value="ser" />
                    <div>
                        <label class="form-check-label" for="digitableLine">Operações em ser</label
              >
              </div>
              </div>
       
       
        </div>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            class="form-check-input"
            formControlName="filter"
            mdbInput
            (click) = "changeFilterType('contract')"
            value="contract"
          />
          <label class="form-check-label" for="opticalReader" *ngIf="selectedFilter === 'fin'">Número do contrato</label>
                        <label class="form-check-label" for="opticalReader" *ngIf="selectedFilter === 'cob' || selectedFilter === 'carta'">Referência banco</label>
                        <input type="text" maxlength="10" onlyNumber placeholder="000000000" class="line-code-bar" name="dtEmSer" formControlName="serDate" disabled *ngIf="filterBySer">
                        <input type="text" maxlength="10" onlyNumber placeholder="0000000000" class="line-code-bar" name="contract" formControlName="contractNum" [(ngModel)]="contractNum" *ngIf="filterByContract && selectedFilter === 'fin'">
                        <input type="text" maxlength="10" onlyNumber placeholder="0000000000" class="line-code-bar" name="contract" formControlName="contractNum" [(ngModel)]="contractNum" *ngIf="filterByContract && selectedFilter !== 'fin'">
                        <div class="button-receipt">
                            <button type="button" class="button-continue" (click)="sendImportData()">
              Consultar
              </button>
                        </div>
                        <div>
                            <br><br><br><br>
                            <span class="field-required" *ngIf="(contractNum === '' || !contractNum) && fieldRequired">Campo obrigatório</span>
                        </div>
                    </div>

        </form>
        </div>

        <div class="datatable" *ngIf="!loading && !noData && isFin && !hasError">
            <div class="table-title">
                <div class="table-item width-row-um">
                    <span class="item-font">Data de desembolso</span>
                </div>
                <div class="table-item width-row-um">
                    <span class="item-font">Data de vencimento</span>
                </div>
                <div class="table-item width-row-dois">
                    <span class="item-font">Juros %</span>
                </div>
                <div class="table-item width-row-dois">
                    <span class="item-font">Moeda</span>
                </div>
                <div class="table-item width-row-tres">
                    <span class="item-font">Valor de Juros</span>
                </div>
                <div class="table-item width-row-tres">
                    <span class="item-font">Valor</span>
                </div>
                <div class="table-item text-align" style="width: 10% !important;">
                    <span class="item-font">OP Emitida</span>
                </div>

            </div>
            <div class="table-content" *ngFor="let finimp of finimpArray">
                <div class="type">
                    <span class="type-font"> Número do contrato: {{finimp.num_contrato}} </span>
                </div>
                <div class="table-title">
                    <div class="table-item width-row-um">
                        <span class="item-font">{{finimp.dt_op}}</span>
                    </div>
                    <div class="table-item width-row-um">
                        <span class="item-font">{{finimp.dt_vencto}}</span>
                    </div>
                    <div class="table-item width-row-dois">
                        <span class="item-font">{{finimp.taxa_en}}</span>
                    </div>
                    <div class="table-item width-row-dois">
                        <span class="item-font"> {{finimp.swift}}</span>
                    </div>
                    <div class="table-item width-row-tres">
                        <span *ngIf="finimp.encargo!=='' || finimp.encargo!==undefined" class="item-font">{{ finimp.encargo  | number:'1.2-2' }}</span>
                        <span *ngIf="finimp.encargo==='' || finimp.encargo===undefined" class="item-font">0,00</span>
                    </div>




                    <div class="table-item width-row-tres">
                        <span class="item-font">{{finimp.usdFormatado}}</span>
                    </div>
                    <div class="table-item op-align" style="width: 10% !important;">
                        <span class="item-font" *ngIf="!finimp.ind_op_emitida"> - </span>
                        <img class="icon-download" src="/assets/svg/icon_download.svg" alt="download" (click)="getPDFNotes(finimp.num_contrato, 'FinImp')" *ngIf="finimp.ind_op_emitida" />
                    </div>

                </div>
            </div>
        </div>

        <div class="datatable" *ngIf="!loading && !noData && isCob && !hasError">
            <div class="table-title">
                <div class="table-item" style="width: 10% !important;">
                    <span class="item-font">Ref.banco</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Data de recepção</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Tipo de vencimento</span>
                </div>
                <div class="table-item" style="width: 25% !important;">
                    <span class="item-font">Banco emissor / Banco exportador</span>
                </div>
                <div class="table-item" style="width: 10% !important;">
                    <span class="item-font">Moeda</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Valor</span>
                </div>
                <div class="table-item">
                    <span class="item-font">Saldo</span>
                </div>

            </div>
            <div class="table-content" *ngFor="let cob of cobrancaArray">
                <div class="type">
                    <span class="type-font"> Ref.cliente: {{cob.num_contrato}} </span>
                </div>
                <div class="table-title">
                    <div class="table-item" style="width: 10% !important;">
                        <span class="item-font">{{cob.num_ref}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{cob.dt_reg}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{cob.dt_vencto}}</span>
                    </div>
                    <div class="table-item" style="width: 28% !important;">
                        <span class="item-font"> {{cob.nome_banco_emissor}}</span>
                        <span class="item-font" style="margin-top: 6px;"> {{cob.nome_exportador}}</span>
                    </div>
                    <div class="table-item" style="width: 10% !important;">
                        <span class="item-font">{{cob.swift}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{cob.usdFormatado}}</span>
                    </div>
                    <div class="table-item">
                        <span class="item-font">{{cob.saldoFormatado}}</span>
                    </div>

                </div>
            </div>
        </div>

        <div class="datatable" *ngIf="!loading && !noData && isCar && !hasError">
            <div class="table-title">
                <div class="table-item-carta" style="width: 8% !important;">
                    <span class="item-font">Ref.banco</span>
                </div>
                <div class="table-item-carta" style="width: 10% !important;margin-right: 6px;">
                    <span class="item-font">Data de abertura</span>
                </div>
                <div class="table-item-carta" style="width: 8% !important;margin-right: 10px;">
                    <span class="item-font">Data de embarque</span>
                </div>
                <div class="table-item-carta" style="width: 28% !important;">
                    <span class="item-font">Banco emissor / Banco avisador</span>
                </div>
                <div class="table-item-carta" style="width: 8% !important;">
                    <span class="item-font">Moeda</span>
                </div>
                <div class="table-item-carta">
                    <span class="item-font">Valor da carta de crédito</span>
                </div>
                <div class="table-item-carta">
                    <span class="item-font">Saldo utilizado</span>
                </div>
                <div class="table-item-carta">
                    <span class="item-font">Saldo disponível</span>
                </div>

            </div>
            <div class="table-content" *ngFor="let credit of creditArray">
                <div class="type">
                    <span class="type-font"> Exportador: {{credit.nome_contraparte}} </span>
                </div>
                <div class="table-title">
                    <div class="table-item-carta" style="width: 8% !important;">
                        <span class="item-font">{{credit.num_contrato}}</span>
                    </div>
                    <div class="table-item-carta" style="width: 10% !important;margin-right: 6px;word-break: break-all;">
                        <span class="item-font">{{credit.dt_op}}</span>
                    </div>
                    <div class="table-item-carta" style="width: 8% !important;margin-right: 10px;word-break: break-all;">
                        <span class="item-font">{{credit.dt_liq_or}}</span>
                    </div>
                    <div class="table-item-carta" style="width: 28% !important;">
                        <span class="item-font"> {{credit.nome_banco_avisador}}</span>
                    </div>
                    <div class="table-item-carta" style="width: 8% !important;">
                        <span class="item-font">{{credit.swift}}</span>
                    </div>
                    <div class="table-item-carta">
                        <span class="item-font">{{(credit.saldo_a_utilizar + credit.saldo_utilizado) | number:'1.2-2'}}</span>
                    </div>
                    <div class="table-item-carta">
                        <span class="item-font">{{credit.saldoUtilizadoForm}}</span>
                    </div>
                    <div class="table-item-carta">
                        <span class="item-font">{{((credit.saldo_a_utilizar + credit.saldo_utilizado)- credit.saldo_utilizado) | number:'1.2-2' }}</span>
                    </div>

                </div>
            </div>
        </div>

        <div class="datatable warning-display" *ngIf="loading">
            <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div class="datatable warning-display" *ngIf="noData && !loading">
            <div class="empty-info">
                <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Tente uma nova pesquisa inserindo novos parâmetros.">
                </fibra-generic-info>
            </div>
        </div>


        <div class="datatable warning-display" *ngIf="hasError && !loading">
            <div class="empty-info">

                <fibra-generic-info type="error" title="Ocorreu um erro inesperado" message="Não foi possível encontrar dados de movimentação!">
                </fibra-generic-info>
            </div>
        </div>

        </div>