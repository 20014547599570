<div class="list-value-admin-user">
  <article class="box-header">
    <div class="first-stage" [ngClass]="{ active: active == 'first' }" (click)="handleTabs('first')">
      <label class="stage-label">Usuários</label>
    </div>
    <div class="second-stage" [ngClass]="{ active: active == 'second' }" (click)="handleTabs('second')">
      <label class="stage-label">Grupos</label>
    </div>
  </article>
  <section class="filter" *ngIf="!showDeleteButton">
    <div class="btnAdd" (click)="goToView(active === 'first' ? '/register-admin-user' : '/register-group-admin-user')">
      <span class="text">
        {{ active === 'first' ? '+ Adicionar usuário' : '+ Adicionar grupo' }}
      </span>
    </div>
    <div class="search" data-hj-suppress>
      <img src="assets/svg/icon_search.svg" />
      <input type="text" (keyup)="filterList($event.target.value)" [(ngModel)]="inputValue"
        [placeholder]="active === 'first' ? 'Digite o nome ou e-mail do operador' : 'Digite o nome do grupo'" />
    </div>
  </section>
  <section class="filter filterSelected" *ngIf="showDeleteButton">
    <div class="filterSelectedStart">
      <div class="btnRemove" (click)="removeAllCheck()">
        <span class="text">Remover seleção</span>
      </div>
      <label class="approvalRadio">
        <input type="radio" [(ngModel)]="multiApproval" [value]="true">
        Aprovar
      </label>
      <label class="approvalRadio">
        <input type="radio" [(ngModel)]="multiApproval" [value]="false">
        Recusar
      </label>
      <div class="btnAdd" (click)="active === 'first'
        ? handleApproval('', 'APPROVALMULTIPLEUSER', resolveMessage('USER'))
        : handleApproval('', 'APPROVALMULTIPLEGROUP', resolveMessage('GROUP'))
      ">
        <span class="text">Confirmar</span>
      </div>
    </div>
    <div class="btnDelete" [ngStyle]="{ cursor: validatingDelete ? 'default' : 'pointer' }" (click)="active === 'first'
      ? handleDelete('', 'MULTIPLEUSER', 'Deseja realmente excluir os usuários selecionados?')
      : handleDelete('', 'MULTIPLEGROUP', 'Deseja realmente excluir os grupos selecionados?')
    ">
      <img [ngClass]="validatingDelete ? 'disabledTrashIcon' : 'trashIcon'" src="/assets/svg/trash.svg" />
      <span class="text" [ngStyle]="{ cursor: validatingDelete ? 'default' : 'pointer' }">{{
        active === 'first' ?
          countChecked > 1
            ? 'Excluir selecionados'
            : 'Excluir selecionado'
        :
          countCheckedGroup > 1
            ? 'Excluir selecionados'
            : 'Excluir selecionado'
      }}</span>
    </div>
  </section>
  <div class="d-flex justify-content-center position" *ngIf="loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="body-list-admin-user" *ngIf="active === 'first' && !loading">
    <table *ngIf="!hide && !loading; else ERROR_MESSAGE" class="table">
      <thead>
        <tr class="header-list">
          <th scope="col">
            <div class="checkbox">
              <input type="checkbox" class="checkbox" [(ngModel)]="checkAll" (change)="checkedAll()" />
            </div>
          </th>
          <th scope="col">
            <div class="text" (click)="sortList('nome_pessoa')">
              Nome
              <span class="icon">
                <img src="/assets/svg/sort-list.svg" />
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="text" (click)="sortList('email')">
              E-mail
              <span class="icon">
                <img src="/assets/svg/sort-list.svg" />
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="text" (click)="sortList('desc_status')">
              Status
              <span class="icon">
                <img src="/assets/svg/sort-list.svg" />
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="text text-right">Ações</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of showList; index as u" class="row">
          <div class="panel-group" id="accordion">
            <div class="panel-list">
              <td scope="row">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox" [(ngModel)]="item.checked" (change)="checkedList(u)" />
                </div>
              </td>
              <td>
                <label class="listLabelName data-hj-suppress"> {{ item.nome_pessoa | titlecase }}</label>
              </td>
              <td class="item-data">
                <label class="listLabelMail">{{ item.email }}</label>
              </td>
              <td>
                <span
                  [appTooltip]="tooltipAll"
                  *ngIf="item.status === 1"
                >
                  <img src="/assets/svg/tooltip-alert-orange.svg" alt="logo" />
                  <div class="tooltip" #tooltipAll>
                    <h1>Aguardando Aprovação</h1>
                    <ul>
                      <li>
                        <div class="title">
                          <div></div>
                          Masters
                        </div>
                        <div *ngFor="let approve of item.lista_aprovadores_pendentes">
                          <p data-hj-suppress>{{ approve.nome_pessoa }}</p>
                        </div>
                      </li>
                    </ul>
                    <div class="arrow"></div>
                  </div>
                </span>
                <span *ngIf="item.status === 1" class="m-pendent">
                  Pendente
                </span>
                <span class="d-flex align-items-center" *ngIf="item.status !== 1">
                  <div [ngClass]="item.status === 2 ? 'authorized' : 'refused'"></div>
                  {{ item.status === 2 ? 'Aprovado' : 'Recusado' }}
                </span>
              </td>
              <td>
                <div class="actions-list">
                  <div (click)="editAccount(item)" class="actEdit">
                    <span class="text"> Editar conta </span>
                  </div>
                  <span>
                    <a  (click)="handleApproval({id: item.cod_pessoa, status: 3}, 'APPROVALSINGLEUSER', 'Deseja reprovar o usuário selecionado?')" *ngIf="item.status === 1 && !item.isMasterApproved"><img class="actBtns"
                        src="/assets/png/icon_delete_rule.png" /></a>
                    <a  (click)="handleApproval({id: item.cod_pessoa, status: 2}, 'APPROVALSINGLEUSER', 'Deseja aprovar o usuário selecionado?')" *ngIf="item.status === 1 && !item.isMasterApproved"><img class="actBtns"
                        src="/assets/png/icon_approval_green.png" /></a>
                    <a (click)="handleDelete(item.cod_pessoa, 'SINGLEUSER', 'Deseja realmente excluir o usuário selecionado?')" id="delete_user_{{item.cod_pessoa}}">
                      <img class="actBtns" [ngStyle]="{ cursor: validatingDelete ? 'default' : 'pointer' }" src="/assets/svg/trash.svg" alt="Excluir Favorito" />
                    </a>
                  </span>
                </div>
              </td>
              <span class="item-icon" (click)="toogleIconArrow(u, item.cod_pessoa)" data-toggle="collapse"
                attr.href="{{ '#collapse' + u }}">
                <img id="{{ u }}" src="/assets/svg/arrow_down.svg" class="" alt="Seta para dropdown" />
              </span>
            </div>
            <div id="{{ 'collapse' + u }}" class="collapse" data-parent="#accordion">
              <fibra-admin-user-detail [data]="userDetails"></fibra-admin-user-detail>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
  <section class="body-list-admin-user" *ngIf="active === 'second' && !loading">
    <table *ngIf="!hide && !loading; else ERROR_MESSAGE" class="table">
      <thead>
        <tr class="header-list">
          <th scope="col">
            <div class="checkbox">
              <input type="checkbox" class="checkbox" [(ngModel)]="checkAll" (change)="checkedAll()"/>
            </div>
          </th>
          <th scope="col">
            <div class="text" (click)="sortListGroup('desc_grupo')">
              Nome
              <span class="icon">
                <img src="/assets/svg/sort-list.svg" />
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="text">
              Quantidade de usuários
            </div>
          </th>
          <th scope="col">
            <div class="text" (click)="sortListGroup('status')">
              Status
              <span class="icon">
                <img src="/assets/svg/sort-list.svg" />
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="text text-right">Ações</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of showListGroup; index as i" class="row">
          <div class="panel-group" id="accordionGroup">
            <div class="panel-list">
              <td scope="row">
                <div class="checkbox">
                  <input type="checkbox" class="checkbox" [(ngModel)]="item.checked" (change)="checkedListGroup(i)"/>
                </div>
              </td>
              <td>
                <label> {{ item.desc_grupo | titlecase }}</label>
              </td>
              <td class="item-data">
                <label>{{ item.qtd_usuarios }}</label>
              </td>
              <td>
                <span
                  [appTooltip]="tooltipAll"
                  *ngIf="item.status === 1"
                >
                  <img src="/assets/svg/tooltip-alert-orange.svg" alt="logo" />
                  <div class="tooltip" #tooltipAll>
                    <h1>Aguardando Aprovação</h1>
                    <ul>
                      <li>
                        <div class="title">
                          <div></div>
                          Masters
                        </div>
                        <div *ngFor="let approve of item.lista_aprovadores_pendentes">
                          <p>{{ approve.nome_pessoa }}</p>
                        </div>
                      </li>
                    </ul>
                    <div class="arrow"></div>
                  </div>
                </span>
                <span *ngIf="item.status === 1" class="m-pendent">
                  Pendente
                </span>
                <span class="d-flex align-items-center" *ngIf="item.status !== 1">
                  <div [ngClass]="item.status === 2 ? 'authorized' : 'refused'"></div>
                  {{ item.status === 2 ? 'Aprovado' : 'Recusado' }}
                </span>
              </td>
              <td>
                <div class="actions-list">
                  <div class="actEdit" (click)="editGroup(item)">
                    <span class="text"> Editar grupo </span>
                  </div>
                  <span >
                    <a (click)="handleApproval({id: item.cod_grupo, status: 3}, 'APPROVALSINGLEGROUP', 'Deseja reprovar o grupo selecionado?')" *ngIf="item.status === 1 && !item.isMasterApproved"><img class="actBtns"
                        src="/assets/png/icon_delete_rule.png" /></a>
                    <a (click)="handleApproval({id: item.cod_grupo, status: 2}, 'APPROVALSINGLEGROUP', 'Deseja aprovar o grupo selecionado?')" *ngIf="item.status === 1 && !item.isMasterApproved"><img class="actBtns"
                        src="/assets/png/icon_approval_green.png" /></a>
                    <a (click)="handleDelete(item.cod_grupo, 'SINGLEGROUP', 'Deseja realmente excluir o grupo selecionado?')">
                      <img class="actBtns" src="/assets/svg/trash.svg" alt="Excluir Favorito" />
                    </a>
                  </span>
                </div>
              </td>
              <span class="item-icon" (click)="toogleIconArrowGroup(i, item.cod_grupo)" data-toggle="collapse"
                attr.href="{{ '#collapse' + i }}">
                <img id="{{ i }}" src="/assets/svg/arrow_down.svg" class="" alt="Seta para dropdown" />
              </span>
            </div>
            <div id="{{ 'collapse' + i }}" class="collapse" data-parent="#accordionGroup">
              <fibra-admin-user-detail [data]="groupDetails"></fibra-admin-user-detail>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
  </section>
  <section class="box-options" *ngIf="showAction">
    <div class="info"></div>
    <div class="actions">
      <div class="text">{{ textAlertRule }}</div>
      <div class="buttons">
        <div class="cancel" (click)="showAction = false">Cancelar</div>
        <div class="approval" (click)="requestDeleteGroup(footerOption)">Sim</div>
      </div>
    </div>
  </section>
  <ng-template #ERROR_MESSAGE>
    <section *ngIf="hide && !loading">
      <div *ngIf="(active === 'second' && showListGroup.length === 0 && listGroup.length >= 1) || (active === 'first' && showList.length === 0 && list.length >= 1)">
        <fibra-generic-info
          type="warn"
          title="Nenhum resultado encontrado."
          message="Tente uma nova pesquisa inserindo novos parâmetros."
        >
        </fibra-generic-info>
      </div>
      <div *ngIf="(active === 'second' && listGroup.length === 0) || (active === 'first' && list.length === 0)">
        <fibra-generic-info
          type="warn"
          title="Nenhum resultado encontrado."
          [message]="active === 'second' ? 'Não foi possível encontrar dados de grupos!': 'Não foi possível encontrar dados de usuários!'"
        >
        </fibra-generic-info>
      </div>
    </section>
  </ng-template>
  <fibra-toast-error-message [color]="typeColorToast" (optionYes)="selectToasterFunction()" (optionNo)="optionNo()"></fibra-toast-error-message>
</div>


<fibra-modal-generic-confirmation
  #content
  [message] = "message"
  message2 = "Deseja prosseguir com a exclusão?"
  title = "Este grupo possui operadores cadastrados"
  (closeEvent)="closeModalConf($event)"
  *ngIf="showModalConfirmation"
>
</fibra-modal-generic-confirmation>

<fibra-modal-validation
  *ngIf="securityAction"
  [apiCallback]="apiCallback"
  (closeEvent)="closeModal()"
  [apiPayload]="securityRequest()"
  (responseValidation)="securityResponse($event)"
>

