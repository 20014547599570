import { GetAmazonArchiveDownloadUsecase } from '@src/core/usecases/charge/get-amazon-archiveDownload.usecase';
import { GetUrlAssignedUsecase } from '@src/core/usecases/charge/get-urlAssigned.usecase';
import { Component, OnInit, Output, EventEmitter, Input, AfterViewChecked, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DownloadArchiveDataShare } from '@src/data/repository/data-share-repository';
import { ErrorTypeModel } from '@src/core/domain/error/errorType.model';

import { GetArchiveDownloadUsecase } from '@src/core/usecases/charge/get-archiveDownload.usecase';
import { ArchiveEntity } from '@src/data/repository/charge/archive-repository/archive.entity';
import {
  ArchiveRequest,
  ArchiveModel,
} from '@src/core/domain/charge/archive.model';
import { PutArchiveUploadUsecase } from '@src/core/usecases/charge/put-archiveUpload.usecase';
import { UploadDataShare } from '@src/core/usecases/data-share/upload-data-share.service';
import { handleFile } from '@src/shared/util-common';

import { optionWallet } from '@src/data/repository/data-share-repository';
import { ReplaySubject } from 'rxjs';

import { WalletTypeDataRepository } from '@src/data/repository/wallet/walletType.repository';
import { isArray } from 'util';
import { RolesService } from '@src/shared/services/roles.service';
import {ToastErrorMessageComponent} from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-drop-archive',
  templateUrl: './download-archive.component.html',
  styleUrls: ['./download-archive.component.scss'],
})
export class DownloadArchiveComponent implements OnInit, AfterViewChecked {

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  fileToUpload: Set<File>;
  file: FormData;
  files: any[] = [];
  currentFile: any[] = [];
  loading: boolean;
  success: boolean;
  statusToken: any = null
  isCobranca: boolean = true
  // Steps
  step1: boolean;
  step2: boolean;
  step3: boolean;
  step4: boolean;

  optionWallet = [];
  currentOption

  @Output() uploadFiles: EventEmitter<any>;
  @Output() emitFilterToast: EventEmitter<any> = new EventEmitter();
  nameFile;
  @Input() disabledDiv;

  public showModalValidation: boolean;
  public apiCallback: Number = 0;

  public requestDataApi = {};

  public typToastColor = '#ED6C6C';

  constructor(
    private dndshare: DownloadArchiveDataShare,
    private getUrlAssigned: GetUrlAssignedUsecase,
    private putArchiveUpload: PutArchiveUploadUsecase,
    private getArchiveDocumentacao: GetArchiveDownloadUsecase,
    private getAmazonArchiveDownload: GetAmazonArchiveDownloadUsecase,
    private uploadDataShare: UploadDataShare,
    private WalletTypeDataRepository: WalletTypeDataRepository,
    private changeDetector: ChangeDetectorRef,
    private rolesService: RolesService
  ) {
    this.uploadFiles = new EventEmitter();
    this.loading = false;
    this.file = new FormData();
    this.success = true;

    // Steps
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;

  }

  ngOnInit() { }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges()
  }

  private successDownload = (value: ArchiveEntity) => {
    if (value.status === 'success') {

      if (["CNAB240", "CNAB400", "Excel"].includes(this.nameFile)) {
        let fileUrl = value.data.split("u0026").join("&");
        window.open(fileUrl, "_blank");
      }

      this.getAmazonArchiveDownload
        .execute(value.data)
        .subscribe((res: any) => {
          const name = this.nameFile;
          handleFile(res, name);
          this.nameFile = null;
        }, this.errorDownload);
    }
  }

  private errorUpload = (err: ErrorTypeModel) => {
    console.log(err);
    this.loading = false;
  }

  private requestGetUrlAssigned = (obj: File) => {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    // reader.readAsBinaryString(obj);
    reader.readAsDataURL(obj);
    reader.onload = (event: any) => {

      this.file.append(obj.name, obj, obj.name);

      const _banco = this.optionWallet.find(carteira => carteira.value === this.currentOption)

      let param: Object = {}

      if (_banco && this.step3) {
        param = {
          arquivo: event.target.result.split(",")[1],
          nome_arquivo: obj.name,
          carteira: this.currentOption,
          banco: _banco.banco
        };
      } else {
        param = {
          arquivo: event.target.result.split(",")[1],
          nome_arquivo: obj.name,
        };
      }

      this.apiCallback = 122
      this.requestDataApi = param

      this.showModalValidation = true;

      // this.getUrlAssigned
      //   .execute(param)
      //   .subscribe(this.successUrlAssigned, this.errorUpload);
    }
  }

  private verifysuccess = (boll: boolean) => {
    this.success = boll;
    this.uploadDataShare.setValue(boll);
  }

  public errorDownload(err: ErrorTypeModel) {
    console.log(err);
  }

  public downloadItem(doc, nameItem) {
    this.setName(nameItem);

    const param: ArchiveRequest = {
      documentacao: doc,
    };

    this.getArchiveDocumentacao
      .execute(param)
      .subscribe(this.successDownload, this.errorDownload);
  }

  private setName(nameItem) {
    this.nameFile = nameItem;
  }

  newList() {
    this.WalletTypeDataRepository.getWalletTypes().subscribe((res: any) => {

      if (isArray(res.data)) {
        // res.data = res.data.sort().filter(function (item, pos, ary) {
        //   return !pos || item.codEspecie != ary[pos - 1]['codEspecie'];
        // });

        res.data.map((e, i) => {
          if (e.ctaHeader.trim() !== "" && e.ctaHeader !== null &&
            !e.descricaoProduto.toLowerCase().includes("cessão") &&
            !e.descricaoProduto.toLowerCase().includes("cessao")) {
            this.CarregaCarteiraPorAcesso(e.numeroConta, e.descricaoProduto, e.ctaHeader, e.codBancoPreferencial);
            // this.optionWallet.push({
            //   name: e.descricaoProduto,
            //   value: e.ctaHeader,
            //   banco: e.codBancoPreferencial
            // });
          }
        });

      }
    }, () => {
      let res = { data: [] }

      if (isArray(res.data)) {
        res.data = res.data.sort().filter(function (item, pos, ary) {
          return !pos || item.codEspecie != ary[pos - 1]['codEspecie'];
        });
        res.data.map((e, i) => {
          console.log(e)
          this.optionWallet.push({
            name: e.descricaoEspecie,
            value: e.codEspecie,
            banco: e.codBancoPreferencial
          });
        });
      }
    });
  }

  public CarregaCarteiraPorAcesso(numeroConta: any, descricaoProduto: any, ctaHeader: any, codBancoPreferencial: any) {
    let roleObject = this.rolesService.getRoles();

    if (this.isCobranca) {
      roleObject.forEach(r => {
        if ((r.Name == "COB.TRANS_ARQ") &&
          (r.NumeroConta == numeroConta || r.NumeroConta == null)) {
          this.optionWallet.push({
            name: descricaoProduto,
            value: ctaHeader,
            banco: codBancoPreferencial
          });
          return;
        }
      });
    }
  }

  closeModal() {
    this.showModalValidation = false;
  }

  public responseApi = (value) => {
    if (value.status === 'success') {
      this.uploadFilesSimulator(0);
    } else if (value.status === 500 && value.error.message[0] === "codigo-empresa-invalido") { 
      this.nextStep(1);
      this.callToast("codigo-empresa-invalido"); 
    } else if (value.status === 500 && value.error.message[0] === "arquivo-invalido") { 
      this.nextStep(1);
      this.callToast("arquivo-invalido"); 
    }
    else {
      this.nextStep(1);
    }
  }

  nextStep(step: number, files?) {
    switch (step) {
      case 2:
        // Steps
        this.newList()

        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;

        // Array Files
        this.fileBrowseHandlerArray(files);

        // Clear Files
        this.currentFile = [];

        // Current File
        this.currentFile = files;

        break;
      case 3:
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;

        // Array Files
        this.fileBrowseHandler(this.currentFile);

        break;

      case 4:
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;
        this.uploadDataShare.setValue(true)
        break;

      default:
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        break;
    }
  }

  verifyFile(files) {
    this.currentFile = [];
    this.currentFile = files;
    // this.showModalValidation = true

    if (
      this.currentFile[0].type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.currentFile[0].type === 'application/vnd.ms-excel'
    ) {
      this.nextStep(2, this.currentFile);
    } else {
      this.nextStep(3, this.currentFile);
    }
  }

  mudarCarteira(carteira) {
    this.currentOption = carteira

    if (carteira !== 'todas') {

      this.nextStep(3);
    }
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  fileBrowseHandlerArray(files) {
    this.prepareFilesListArray(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.clearFiles();
    this.nextStep(1);
    this.optionWallet = [];
  }

  clearFiles() {
    this.files = [];
    this.currentFile = [];
  }

  private successUpload = (value: ArchiveModel) => {
    const params: ArchiveRequest = {
      data: value.data,
      arquivo: this.files,
    };

    this.putArchiveUpload.execute(params).subscribe(
      (archive) => {
        this.verifysuccess(true);
        this.loading = false;
        this.uploadFiles.emit(true);
      },
      (err) => {
        this.verifysuccess(false);
      }
    );
  }

  private successUrlAssigned = (value: ArchiveModel) => {
    if (value.status === 'success') {
      this.successUpload(value);
    }
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files.length) {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.success = true;
              this.uploadFilesSimulator(index + 1);
              this.clearFiles();
              this.nextStep(4);
            } else {
              this.files[index].progress += 5;
            }
          } else {
            this.clearFiles();

            clearInterval(progressInterval);
          }
        }, 200);
      }
    }, 1000);
  }

  prepareFilesList(files: Array<any>) {

    for (const item of files) {

      let formatoValido = item.name.toUpperCase().endsWith('TXT') || item.name.toUpperCase().endsWith('CNAB') || item.name.toUpperCase().endsWith('REM') || item.name.toUpperCase().endsWith('XLS') || item.name.toUpperCase().endsWith('XLSX');

      if (!formatoValido) {
        this.callToast("aquivo-cnab-invalido");
        return;
      }

      item.progress = 0;
      this.files.push(item);
      this.requestGetUrlAssigned(item);
    }

    // this.uploadFilesSimulator(0);
  }

  prepareFilesListArray(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  callToast(value) {

    this.emitFilterToast.emit(value);

    // this.toast.callModalMessage('arquivo-cnab-invalido', 'arquivo-cnab-invalido', null, null, null);
    // this.toast.callModalMessage('arquivo-cnab-invalido');

  }
}

