import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ExtractListEntity, ExtractProdutosListEntity, InvestimentsExcelRequest, InvestimentsListEntity, InvestimentsProdutosListEntity } from './investiments.entity';
import { formatDate } from '@angular/common';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvestimentsDataRepository{
  constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string) {}

  getExtract(dt_op: string, dt_vencto?: string, produto?: string, tipoOpera? :string): Observable<ExtractListEntity> {
    if(dt_vencto){
      const httpOptions = {
        headers: getHeader(),
        params: {
          dt_op: formatDate(dt_op, 'yyyy-MM-dd', this.locale),
          dt_vencto: formatDate(dt_vencto, 'yyyy-MM-dd', this.locale),
          produto: produto ? produto : "",
          tipoOperacao: tipoOpera ? tipoOpera : ""
        }
      };
      
      try {
        return this.http
        .get<ExtractListEntity>(
          `${environment.URLBase}v1/Investimentos/Extrato`,
          httpOptions
          )
        }
        catch (error) {
          handleError(error);
        }
    }else{
      const httpOptions = {
        headers: getHeader(),
        params: {
          dt_op: formatDate(dt_op, 'yyyy-MM-dd', this.locale),
          produto: produto ? produto : "",
          tipoOperacao: tipoOpera ? tipoOpera : ""
        }
      };
      try {
        return this.http
        .get<ExtractListEntity>(
          `${environment.URLBase}v1/Investimentos/Extrato`,
          httpOptions
          )
        }
        catch (error) {
          handleError(error);
        }
    }
  }

  getExtractProdutos(): Observable<ExtractProdutosListEntity> {
      const httpOptions = {
        headers: getHeader()
      };
      try {
        return this.http
        .get<ExtractProdutosListEntity>(
          `${environment.URLBase}v1/Investimentos/Extrato/Produtos`,
          httpOptions
          )
        }
        catch (error) {
          handleError(error);
        }
  }

  getInvestiments(dt_op: Date, produtos?: string, maxDate?: boolean): Observable<InvestimentsListEntity> {
    if(!produtos){
      const httpOptions = {
        headers: getHeader(),
        params: {
          dt_op: formatDate(dt_op, 'yyyy-MM-dd', this.locale),
          maxDate: maxDate.toString() ? maxDate.toString() : null
        }
      };
      try {
      return this.http
      .get<InvestimentsListEntity>(
        `${environment.URLBase}/v1/Investimentos/PosicaoConsolidada`,
        httpOptions
        )
      }
      catch (error) {
        handleError(error);
      }
    }else{
      const httpOptions = {
        headers: getHeader(),
        params: {
          dt_op: formatDate(dt_op, 'yyyy-MM-dd', this.locale),
          produtos: produtos ? produtos : null,
          maxDate: maxDate.toString() ? maxDate.toString() : null
        }
      };
      try {
      return this.http
      .get<InvestimentsListEntity>(
        `${environment.URLBase}/v1/Investimentos/PosicaoConsolidada`,
        httpOptions
        )
      }
      catch (error) {
        handleError(error);
      }
    }
  }

  getInvestimentsDetails(num_op: number, dt_op: Date, dt_vencto?: Date): Observable<InvestimentsListEntity> {
    const httpOptions = {
      headers: getHeader(),
      params: {
        dt_op: formatDate(dt_op, 'yyyy-MM-dd', this.locale)
      }
    };
    try {
    return this.http
    .get<InvestimentsListEntity>(
      `${environment.URLBase}/v1/Investimentos/PosicaoConsolidada/${num_op}/Detalhe`,
      httpOptions
      )
    }
    catch (error) {
      handleError(error);
    }
  }


  getInvestimentsDetailsList(dt_op: Date,produtos?:string): Observable<InvestimentsListEntity[]> {
    const httpOptions = {
      headers: getHeader(),
      params: {
        dt_op: formatDate(dt_op, 'yyyy-MM-dd', this.locale),
        produtos: produtos ? produtos : null
      }
    };
    try {
    return this.http
    .get<InvestimentsListEntity[]>(
      `${environment.URLBase}/v1/Investimentos/PosicaoConsolidada/detalhe/lista`,
      httpOptions
      )
    }
    catch (error) {
      handleError(error);
    }
  }

  getInvestimentsProdutos(): Observable<InvestimentsProdutosListEntity> {
    const httpOptions = {
      headers: getHeader()
    };
    try {
    return this.http
    .get<InvestimentsProdutosListEntity>(
      `${environment.URLBase}/v1/Investimentos/PosicaoConsolidada/Produtos`,
      httpOptions
      )
    }
    catch (error) {
      handleError(error);
    }
  }


  PostExcel(obj: any, url: string): Observable<any> {
    const params = { ...obj };
    return this.http
      .post<any>(
        `${environment.URLBase}${url}`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }

  postExportExcelExtrato(obj: InvestimentsExcelRequest): Observable<any> {
    return this.PostExcel(obj, 'v1/investimentos/extrato/excel');
  }
  postExportExcelPosicaoConsolidada(obj: InvestimentsExcelRequest): Observable<any> {
    return this.PostExcel(obj, 'v1/investimentos/posicaoConsolidada/excel');
  }
}
