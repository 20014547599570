<ng-template #content let-modal>
    <div class="modal-banner-container">
        <div class="form-row personal-information">
            <form [formGroup]="formEmail">
                <fibra-section-content style="margin-top: 20px !important" description="Tenho Interesse!">
                    <div class="divisor"></div>

                    <div class="infoView">
                        <img src="../../../../assets/png/icon-pending.png" class="infoIcon" />
                        <span class="infoSpan">
                            Este preenchimento é exclusivo para cotação de um ou mais produtos do Banco Fibra. 
                            Para outros assuntos, acione o chat, ícone localizado na parte inferior direita da página.
                        </span>
                    </div>

                    <h3 style="margin-top:20px;">Informações</h3>

                    <div class="mt-4">
                        <fibra-form-control [style.max-width.px]="400">
                            <label class="nome-label">Razão Social</label>
                            <div class="control-data">
                            <input
                                type='text'
                                id="name"
                                pattern='^[ 0-9a-zA-Zàèìòùáéíóúâêîôûãõç\b./\-&]+$'
                                formControlName="name"
                                [style.width.%]="100"
                                placeholder="Informar o nome"
                            />
                            <ng-container *ngIf="personControlIsInvalid('name')">
                                <small
                                class="text-danger"
                                *ngIf="personalControlFirstError('name').required"
                                >
                                Por favor, preencha a Razão Social.
                                </small>
                            </ng-container>
                            </div>
                        </fibra-form-control>
                    </div>
                    <div class="mt-4">
                        <fibra-form-control [style.width.px]="207" label="CNPJ">
                            <div class="control-data">
                            <input
                                formControlName="cpfCnpj"
                                type="text"
                                [style.width.px]="207"
                                placeholder="CNPJ"
                                [textMask]="verificaPessoa === 'F'
                                ? {
                                    mask: cpfMask,
                                    placeholderChar: '_',
                                    guide: false,
                                    modelClean: true
                                }
                                : {
                                    mask: cnpjMask,
                                    placeholderChar: '_',
                                    guide: false,
                                    modelClean: true
                                }
                                "
                            />
                            <ng-container
                                *ngIf="!docValid && personControlIsInvalid('cpfCnpj')"
                            >
                                <small class="text-danger">
                                Por favor, digite o CPF/CNPJ.
                                </small>
                            </ng-container>
                            </div>
                        </fibra-form-control>
                    </div>
                    <div class="mt-4" *ngIf="bannerType === 1">
                        <fibra-form-control label="Produto que tem interesse" [style.width]="'auto'">
                            <fibra-dropdown
                                id="product"
                                formControlName="product"
                                removeShadown="true"
                                customClass="select-option"
                                [options]="productBannerList"
                            ></fibra-dropdown>
                        </fibra-form-control>
                    </div>

                    <div class="mt-4">
                        <fibra-form-control-group [formGroup]="formEmail">
                            <fibra-form-control [style.max-width.px]="200">
                                <label class="nome-label">Celular</label>
                                <div class="control-data">
                                    <fibra-input
                                    id="cell"
                                    onkeypress="return (!(event.charCode >= 33 && event.charCode <= 44) && !(event.charCode >= 58 && event.charCode <= 64) && !(event.charCode >= 128 && event.charCode <= 187))"
                                    formControlName="cell"
                                    pattern='^[ 0-9SsNn\b/]+$'
                                    [style.width.px]="220"
                                    ></fibra-input>
                                    <ng-container *ngIf="personControlIsInvalid('cell')">
                                        <small
                                        class="text-danger"
                                        *ngIf="personalControlFirstError('cell').required"
                                        >
                                        Por favor, digite seu número.
                                        </small>
                                    </ng-container>
                                </div>
                            </fibra-form-control>

                            <fibra-form-control [style.max-width.px]="200">
                                <label class="nome-label">E-mail</label>
                                <div class="control-data">
                                    <input  type="text"  
                                    id="email"
                                    formControlName="email"
                                    [style.width.px]="220"
                                    maxlength="77">
                                    
                                    <ng-container *ngIf="personControlIsInvalid('email')">
                                        <small
                                        class="text-danger"
                                        *ngIf="personalControlFirstError('email').required"
                                        >
                                        Por favor, digite um e-mail.
                                        </small>
                                    </ng-container>
                                </div>
                            </fibra-form-control>
                        </fibra-form-control-group>
                    </div>
                    <div class="mt-4">
                        <fibra-form-control 
                            [style.width.px]="300"
                            label="">
                            <div class="fb-box2">
                            <div class="fb-box-title">
                                <span style="font-weight: bold;">Você prefere receber o contato do Banco Fibra através do:</span>
                            </div>
                            <div class="fb-box-options">
                                <div class="fb-selector" *ngFor="let item of optionContactType">
                                <div class="fb-selector-input aqua">
                                    <input  class="fibra-radio-custom"
                                    type="radio"
                                    [id]="'contactTypeRadion'"
                                    [name]="'contactTypeRadion'"
                                    [value]="item.value"
                                    (change)="setContactType(item.value)"
                                    [checked]="verificaContactType === item.value"
                                    />
                                    <label
                                    class="fibra-radio-group fibra-radio"
                                    [for]="item.option"
                                    ></label>
                                </div>
                                <div class="fb-label">
                                    <span>{{ item.option }} </span>
                                </div>
                                </div>
                            </div>
                            </div>
                        </fibra-form-control>
                    </div>
            
                </fibra-section-content>

                <div class="col-12">
                    <div class="modal-email-buttons">
                        <div class="cancel" (click)="closeModal()">
                          Cancelar
                        </div>
                        <button class="confirm" (click)="sendEmail()" [disabled]="!emailForm.valid">
                          Enviar
                        </button>
                      </div>

                    <!--<div class="description">
                        <div class="step-buttons-cancelar">
                            <button
                              type="button"
                              class="button-cancel"
                              (click)="closeModal('cancelar')"
                            >
                              Cancelar
                            </button>
                        </div>
                        <div class="step-buttons-container offset-9">
                            <button
                            type="button"
                            style="float: right;"
                            class="secondary-button next"
                            [disabled]="!emailForm.valid"
                            (click)="sendEmail()"
                            > Enviar
                            </button>
                        </div>
                    </div>-->
                </div>
            </form>
        </div>
    </div>
</ng-template>
  