<div class="div" style="background-color: #F8F9FA;">
  <span class="font" *ngIf = "keyData && keyData.length > 0"> Chaves</span>
<div class="list-value">
  <ul class="col-lg-12 list-group list-group-flush p-0">
    <li  class="row-list" *ngFor="let key of keyData; index as i">
      <div class="panel-group">
        <div class="panel-list">
          <div class="account-numbering">
            <span>{{i + 1}}</span>
          </div>
          <div class="col-cpf"  style="flex-grow: 1;">
            <small>Chave {{returnType(key.tipo_chave)}}</small>
            <span>{{ key.chave_mascara }}</span>
          </div>
          <div class="item-icon" id="rotate">
            <button
              type="button"
              class="secondary-button button"
              (click)="selectBeneficiary(beneficiarioData, key)"
            >
              Selecionar
            </button>
            <img
              src="/assets/svg/trash.svg"
              alt="Excluir Favorito"
              (click)="deleteBeneficiary(key)"
            />
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
<span class="font" *ngIf = "accountData && accountData.length > 0"> Contas</span>
<div class="list-value">
  <ul class="col-lg-12 list-group list-group-flush p-0">
    <li  class="row-list" *ngFor="let acc of accountData; index as i">
      <div class="panel-group">
        <div class="panel-list">
          <div class="account-numbering">
            <span>{{i + 1}}</span>
          </div>
          <div class="col-bank-number">
            <small >Número da instituição</small>
            <span> {{acc.cod_compe_beneficiario}}  </span>
          </div>
          <div class="col-fibra-bank" >
            <div  class="ico-fibra" *ngIf="acc.cod_banco === '224'">
              <img
                src="assets/svg/logo-blue-small.svg"
                alt="Conta do Banco Fibra"
              />
            </div>
            <div class="col-bank-name">
              <small >Nome do banco</small>
              <span> {{acc.nome_banco_beneficiario}} </span>
            </div>
          </div>
          <div class="col-agency">
          </div>
          <div class="col-account">
          </div>
          <div class="item-icon" id="rotate">
            <button
              type="button"
              class="secondary-button button"
              (click)="selectBeneficiary(beneficiarioData,acc)"
            >
              Selecionar
            </button>
            <img
              src="/assets/svg/trash.svg"
              alt="Excluir Favorito"
              (click)="deleteBeneficiary(acc)"
            />
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
</div>

