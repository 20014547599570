<div *ngIf="!isDebug" class="box fibra-card">
    <table class="tid-table">
        <ng-container *ngFor="let row of rows; let index=index; let even=even">
          <tr class="tid-data-row"
            [ngClass]="{'tid-even': even}">
          <td>
              <button (click)="row.showDetail = !row.showDetail">Debug DDA</button>
          </td>
        </tr>
        <tr class="tid-detail-row"
            [ngClass]="{'show': row.showDetail}">
          <td colspan="2">
    <div class="table-responsive-sm">
        <table class="accordion table table-bordered">
            <thead>
                <tr>
                    <th>CNPJ</th>
                    <th>Conta</th>
                    <th>Masters</th>
                    <th>Permiss&otilde;es</th>
                </tr>
            </thead>
            <tbody *ngIf="model!=undefined && model!=null">
                <tr>
                    <td>{{model.cnpj_logado}}</td>
                    <td>{{model.nu_conta}}</td>
                    <td>{{model.masters.length}}</td>
                    <td>{{roles.length}}</td>
                </tr>
                <tr>
                    <td colspan="3" *ngIf="model.masters.length > 0">
                        <ul style="border-bottom: 1px solid #ccc; padding-bottom: 10px;" *ngFor="let master of model.masters">
                            <li>PessoaId: {{master.pessoa_id}} </li>
                            <li>Nome: {{master.nome}}</li>
                            <li>email: {{master.email}}</li>
                            <li>Acesso: {{master.tipo_acesso}}</li>
                        </ul>

                    </td>
                    <td rowspan="2" *ngIf="roles.length > 0">
                        <ul style="border-bottom: 1px solid #ccc; padding-bottom: 10px;" *ngFor="let role of roles">
                            <li>{{role.Name}}</li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
          </td>
        </tr>
        </ng-container>
      </table>
</div>