import { PaymentBatchRepository } from './../../../../data/repository/payment-batch/payment-batch-repository';
import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-detals-retorno',
  templateUrl: './detals-retorno.component.html',
  styleUrls: ['./detals-retorno.component.scss']
})


export class DetalsRetornoComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private termsService: PaymentBatchRepository
    ) { 
      this.option = [
        { title: 'Data do pagamento', value: 1 },
        { title: 'Tipo de transação', value: 2 },
        { title: 'Conta', value: 3 },
        { title: 'CNPJ / CPF', value: 4 },
        { title: 'Valor', value: 5 },
        { title: 'Status', value: 6 },
      ];
    }

  public routAccont: string;
  detalsListRetono: any[];
  public filter = new FormControl('');
  public option;
  public nomeArquivo: string;
  public isToggled: boolean[];

  @Output() emitItem: EventEmitter<any>;


  ngOnInit() {
  this.detalhesId();
   this.nomeArquivo = this.activatedRoute.snapshot.paramMap.get('nomeArquivo');
  }

  public exportScreen(_value) {}

  detalhesId(){
    const Id = this.activatedRoute.snapshot.paramMap.get('id');
    this.termsService.detalheHistorico(Id).subscribe(data => {
      this.isToggled = Array(data['data'].length).fill(false)
      this.detalsListRetono = data['data'];
      this.detalsListRetono = this.detalsListRetono.map(item => ({
        ...item,
        valor: Number(item.valor.replaceAll('.', '').replaceAll(',', '.'))
      }))
    })
  }

  sendItemList(item) {
    this.emitItem.emit(item);
  }

  public shouldRenderStatusDetailed(status: string): boolean {
    return status === "Recusado"
  }

  public toggleStatus(index, item) {
    this.isToggled[index] = !this.isToggled[index];
  }
  

}