<div class="content-beneficiaries">
  <div class="beneficiaries">
    <div class="arrow" (click)="goBack()" style="cursor: pointer;">
      <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar">
        Voltar</span>
    </div>
    <div class="title-filter-div">
      <span class="title-font">Selecione uma instituição</span>
      <div class="search-beneficiary">
        <input type="text" placeholder="Pesquisar por nome ou número do banco" [formControl]="filter"/>
        <!-- <input
          type="text"
          placeholder="Pesquisar por nome ou número do banco"
          [(ngModel)]="buscaBanco"
          (keyup.enter)="getBancos($event.target.value)"
        /> -->
        <img src="assets/svg/icon_search.svg" (click)="getBancos()"/>
      </div>
    </div>
  </div>
</div>
<div class="wraper-list-value">
  <div class="list-value">
    <table class="table">
      <tbody class="p-15" *ngIf="!loading">
        <tr *ngFor="let item of banks | async; index as i">
          <td>
            <div class="items">
              <!-- Bank Fibra -->
              <div *ngIf="item.cod_banco === 224" class="icon-fibra"></div>

              <!-- Other Bank -->
              <div *ngIf="item.cod_banco !== 224" class="initial-letters" [ngClass]="item.css">
                {{ initialLetters(item.nome_banco) }}
              </div>

              <div>
                {{
                item.cod_banco + ' - ' + item.nome_banco
                | titlecase
                }}
              </div>
            </div>
            <div class="select-button" (click)="selectBank(item)">
              Selecionar
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="empty-info" *ngIf="hide && !loading">
      <fibra-generic-info type="warn" title="Nenhum registro encontrado.">
      </fibra-generic-info>
    </div>
    <div class="d-flex justify-content-center position" *ngIf="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>

<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>