import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TabsDataShare, TabsOnChange } from '@src/core/usecases/data-share/tabs-data-share.service';
import { setInstructionsIndexUsecases } from '@src/core/usecases/instructions/set-instructionsIndex.usecase';
import { Component, EventEmitter, OnInit, Output, ViewChild, SimpleChanges, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import * as models from '@src/data/repository/data-share-repository';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { InstructionDataShare, DetailsListShare, SelectorDataShare, FilterQueryDataShare, optionInstructionsWallet } from '@src/data/repository/data-share-repository';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { GetAllQueryExcelExportUsecase } from '@src/core/usecases/query/get-all-query-excel-export.usecase';
import * as Util from '@src/shared/util-common';
import { DateValidator } from '@src/shared';
import { RolesService } from '@src/shared/services/roles.service';
import { GetAllQueryPdfExportUsecase } from '@src/core/usecases/query/get-all-query-pdf-export.usecase';
import { InstructionRequest, posicaoCarteiraListQueryTypeEnum } from '@src/core/domain/query/query.model';
import { QueryPosicaoCarteiraComponent } from '../../components/query-posicao-carteira/query-posicao-carteira.component';
import { FilterCobrancaComponent } from '@src/presentation/web/components/filter-cobranca/filter-cobranca.component';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as $ from 'jquery';

interface OptionsRadio {
  option: string;
  value: any;
}

@Component({
  selector: 'fibra-page-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss'],
})
export class QueryComponent implements OnInit, AfterViewInit {
  @Output() valueChoice = new EventEmitter();
  public token = undefined;
  public valuesPeriod
  public expirationChange: NgbDate;
  public createDate;
  public valueBlocked: boolean;
  public balance;
  public optionsSideCard;
  public choice;
  public choiceValue;
  public identifier;
  // public hideList = 'query-transit';
  public hideList = '';
  public isBatch;
  public isBatchAbatimento;
  public isBatchAltVencimento;
  public isInfoInstruction: boolean
  public titleDetail = null;
  public queryDetail = null;
  public dataInvalidaLote: boolean = false
  public currentFilter = {}
  public myGroup: FormGroup;
  // public currentView = { option: 'Arquivos em trânsito', value: 1 };
  public currentView: any;
  public apiCallback = 0;
  public today = new Date();
  isChecked: boolean
  public disable: boolean = true;
  public disableLote: boolean = true;
  public isExpirationDateInvalid: boolean = false;
  public adcInstrucao: boolean = false;
  public cleanList: boolean = false;
  selectedCkeckTitle;
  valueMsgInBatch;
  minValueTitile: any
  createDefaultDateBatchVencimento: NgbDate = new NgbDate(2000, 1, 1);
  public readonlyInput: boolean = false;

  public invalidDateMessage = "Data inválida. Informe uma data superior a data atual.";
  public invalidExpirationDateMessage = "Data inválida. Informe uma data superior a data atual de vencimento";

  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public AMBARCOLOR = '#D69D10';
  public typToastColor = this.REDCOLOR;

  paramsDefault = {};

  private subscriptions: Subscription[] = [];

  public controlFormQuery = {
    instructionInBatch: null,
    serviceInstruction: null,
    stepScreen: 0,

    titleButtonComeBack: 'Voltar',
    titleButtonAdvance: 'Adicionar',
    hideButtonBottom: true,
    hideButtonTop: true,
    showButtonInstructions: false,
    showOptionsInstructions: false,
    radioInstructionInBatch: false,

    hideMsgLabel: true,
    hideMsgLink: true,
    hideMsgPostInput: true,
    hideMsgButtons: true,
    hideMsgInputButtons: true,

    inputServiceMoney: false,
    inputServiceNumber: false,
    calendarService: false,

    labelMsg: '',
    labelLink: 'Ver as instruções adicionadas',
    labelPostInput: '',
  };

  public requestDataApi = {
    num_conta: null,
    cod_carteira: null,
    lote: false,
    nome_operador: null,
    instrucoes: [
      {
        nosso_numero: null,
        seu_numero: null,
        dt_vencimento: null,
        vlr_abatimento: null,
        num_dias_protesto: null,
        num_conta: null,
        tipo_pessoa: null,
        num_cpf_cnpj: null,
        nome: null,
        codigo_cliente: null,
        vlr_titulo: null,
      },
    ],
  };

  filterCombination: any = {};

  public optionInstructionsWallet = models.optionInstructionsWallet;
  public showTooltipPortfolioPosition: boolean;
  public options;
  public typeList;
  public tabsOptions;
  public optionTransit;
  public optionPortfolio;
  public optionFranc;
  public optionInstruction;
  public hideCard;
  public showModalValidation: boolean;
  public filterOptions: any;
  public service: any;
  public valuesChoice: any = {};
  public reactiveForm: FormGroup = new FormGroup({
    reactiveRadio: new FormControl(true),
  });
  public dataVenc;
  public dataValida;
  abatimentoInstruction: string;
  protestoInstruction: string;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(QueryPosicaoCarteiraComponent, null) posicaoCarteira: QueryPosicaoCarteiraComponent;
  @ViewChild(FilterCobrancaComponent, null) filterCobranca : FilterCobrancaComponent;

  public generatePDF = (value?) => Util.generatePDF(value);

  private ShowToast(value: any): void {
    const isMessageServer: boolean = !!(value && value.code && value.message && value.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, value.message[0], null, null, null);
  }

  private _rowCount: number;
  private _posicaoCarteiraCheckedAll: boolean;
  private _posicaoCarteiraEOF: boolean;
  public _posicaoCarteiraLoadingRemainingRows: boolean;

  isPDPGenerate: boolean = false;
  public report = false;
  private _paramsInstrucoes: InstructionRequest;

  constructor(
    private authService: MsalService,
    private tab: TabsDataShare,
    private shareSelector: SelectorDataShare,
    private formBuild: FormBuilder,
    private shareInstruction: InstructionDataShare,
    private shareValueChoice: DetailsListShare,
    private _filter: FilterQueryDataShare,
    private setInstructions: setInstructionsIndexUsecases,
    private getAllQueryExportExcel: GetAllQueryExcelExportUsecase,
    private rolesService: RolesService,
    private cdRef: ChangeDetectorRef,
    private getPdf: GetAllQueryPdfExportUsecase,
    private _tabsOnChange: TabsOnChange

  ) {
   
    this.reactiveForm.controls.reactiveRadio.valueChanges.subscribe(
      (state: any) => {
        this.controlFormQuery.instructionInBatch = state;
        this.abatimentoInstruction = null;
        this.protestoInstruction = null;
        this.expirationChange = new NgbDate(0,0,0);
        this.posicaoCarteira.limparListaSelecionado(true);
        this.deleteAllValuesChoice();
        this.controlShowComponents();
      }
    ),
      (this.optionsSideCard = models.optionsSideCard);
    this.optionsConfigTabs();

    this.subscriptions.push(this._filter.paramsInstrucoes.subscribe((params: InstructionRequest) => this._paramsInstrucoes = params));
  }

  ngAfterViewInit(): void {
    if (this.rolesService.get().includes('COB.CONS_ARQ_TRANS') || this.rolesService.get().includes('QUALQUER_CONTA.COB.CONS_ARQ_TRANS'))
      this.listingSelect({ option: 'Arquivos em trânsito', value: 1 });

    else if (this.rolesService.get().includes('COB.CONS_POS_CART') || this.rolesService.get().includes('QUALQUER_CONTA.COB.CONS_POS_CART'))
      this.listingSelect({ option: 'Posição de carteira', value: 2 });

    else if (this.rolesService.get().includes('COB.CONS_INST_ADIC') || this.rolesService.get().includes('QUALQUER_CONTA.COB.CONS_INST_ADIC'))
      this.listingSelect({ option: 'Instruções adicionadas', value: 3 });

    else if (this.rolesService.get().includes('COB.CONS_FRANC') || this.rolesService.get().includes('QUALQUER_CONTA.COB.CONS_FRANC'))
      this.listingSelect({ option: 'Francesinha', value: 4 });

    this.cdRef.detectChanges();

  }


  ngOnInit() {
    this.typeList = "query-list-all";
    this.tab.setValue({
      typeList: "query-list-all",
      periodDays: null,
      periodCalendar: null,
    });
    this._tabsOnChange.changeTab('query-list');
    this.currentView = "";
    this.sumDate();
    this.myGroup = new FormGroup({
      expirationChange: new FormControl()
    });

    this._filter.dataShared.subscribe((res) => {
      this.currentFilter = res;
      this.requestUpdate(res, 'counter');
      // esse res do shareFilterTransit traz o numero da conta e o código da carteira
    });

    this.shareInstruction.dataShared.subscribe((res) => {
      if (res && res.dataQueryComponent && this.service && this.service.dataQueryComponent) {
        if (!res.dataQueryComponent.optionInBatch && this.service.dataQueryComponent.optionInBatch) {
          this.clearValuesChoice();
        }
      }

      this.service = res;
      this.requestUpdate(res, 'service');
      // esse res do shareInstruction traz o serviço selecionado
    });

    this.shareValueChoice.dataShared.subscribe((res) => {   
      if (res) {
        if (res.seu_numero) this.valuesChoice[res.seu_numero] = { ...(this.valuesChoice[res.seu_numero] || {}), ...res }; // valor vindo do detalhe de cada item
        else if (res.numberItem) { // evento dos checkboxs          
          res.numberItem.forEach((item: any, index: number) => {
            this.valuesChoice[item] =
            {
              ...(this.valuesChoice[item] || {}),
              seu_numero: item,
              vlr_titulo: res.originalValue ? res.originalValue[index] : '',
              item: res.item ? res.item[index] : null,
            };

          });

          Object.keys(this.valuesChoice).forEach(key => {
            if (!res.numberItem.includes(key))
              delete this.valuesChoice[key];
          });
        }

        else {  // valor vindo em lote
          if (this.controlFormQuery.instructionInBatch === true)
            Object.keys(this.valuesChoice).forEach(key => {
              this.valuesChoice[key].valueChoice = res.valueChoice;
            });

          this.currentFilter['valueChoice'] = res.valueChoice;
        }
      }

      this.requestUpdate(res, 'valuesChoice');
      // esse res do shareValueChoice traz (caso individual com detalhes) o seu_numero,
      // vlr_titulo e o valor digitado pelo usuario (dt_vencimento, vlr_abatimento ou num_dias_protesto)
      // Individual sem detalhes aguardar para verificar o que vem no payload de listagem (seu_numero e vlr_titulo )
    });

    this.valueBlocked = true;
    this.hideCard = false;
    this.options = this.optionsSideCard[1];
    this.tabsOptions = this.optionTransit;
    this.reviewToken();
    this.shareValueChoice.dataShared.subscribe((res) => {
      if (res) {
        this.choice = res.valueChoice;
      }

      this.identifier = res && res.identifier;
      // this.requestDataApi.value = res && res.valueChoice;
      // this.requestDataApi.identifier = res && res.identifier;
    });

    this.shareSelector.setValue('query');
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  clearValuesChoice(): void {
    Object.keys(this.valuesChoice).forEach(key => {
      delete this.valuesChoice[key].valueChoice;
    });
  }

  deleteAllValuesChoice(): void {
    this.choiceValue = null;
    this.valuesChoice = {};
  }

  verifyQtdChecked(value) {
    this.selectedCkeckTitle = value;
  }

  formatDataTotalValue(date) {
    var Newdate = moment(date).format("DD/MM/YYYY")
    var splitDate = Newdate.split('/');
    var dateFormated = Number(splitDate[2] + splitDate[1] + splitDate[0]);

    return dateFormated
  }

  totalValuesPeriod(totais: { rowcount: number, titulospagos: number, titulosvencidos: number, titulosbaixados: number, titulosavencer: number, titulosemcartorio: number }) {    

    if (totais) {
      this._rowCount = totais.rowcount;

      this.valuesPeriod = {
        expected_entries: totais.titulospagos.toFixed(2),
        expected_exits: totais.titulosvencidos.toFixed(2),
        expected_downloaded: totais.titulosbaixados.toFixed(2),
        expected_real_balance: totais.titulosavencer.toFixed(2),
        expected_the_registry_office: totais.titulosemcartorio.toFixed(2)
      }
    } else {
      this._rowCount = 0;

      this.valuesPeriod = {
        expected_entries: 0,
        expected_exits: 0,
        expected_downloaded: 0,
        expected_real_balance: 0,
        expected_the_registry_office:0
      }
    }  
  }

  requestUpdate(data, type) {

    /*this.requestDataApi = {
      num_conta: '0016103017',
      cod_carteira: '',
      instrucoes: [
        {
          nosso_numero: 123, // this.controlFormQuery.serviceInstruction,
          seu_numero: 132, // this.identifier,
          dt_vencimento: '2020-12-10T00:00:00', // this.choice
          vlr_abatimento: '10',
          num_dias_protesto: 12,
          num_conta: 123,
          tipo_pessoa: 'pj',
          num_cpf_cnpj: 123456,
          nome: 'Wesley',
          codigo_cliente: 123,
          vlr_titulo: 10,
        },
      ],
    };*/
  }

  sendInstructions() {
    let tipoInstrucao = this.service.dataQueryComponent.instruction;
    let codigoTipoIntrucao = optionInstructionsWallet
      .filter(instruction => instruction.value == tipoInstrucao)
      .map(intruction => intruction.codigo)
      .find(_ => true);

    this.requestDataApi.num_conta = this.filterCombination['account'];
    this.requestDataApi.cod_carteira = this.filterCombination['codigo_especie'];
    this.requestDataApi.lote = this.service.dataQueryComponent.optionInBatch;

    let nomeOperador = localStorage.getItem('username');

    this.requestDataApi.nome_operador = nomeOperador;

    if (this._posicaoCarteiraCheckedAll && !this._posicaoCarteiraEOF && Object.keys(this.valuesChoice).length < this._rowCount && (tipoInstrucao == 'sustar-protesto' || tipoInstrucao == 'pedido-baixa' || this.isBatch || this.isBatchAltVencimento || this.isBatchAbatimento)) {
      this.posicaoCarteira.loadRemainingRows(); // Ver método posicaoCarteiraLoadedRemainingRows para resultado dessa chamada
    } else {
      this._sendInstructions(codigoTipoIntrucao, tipoInstrucao, this.valuesChoice);
    }    
  }

  private _sendInstructions(codigoTipoIntrucao: string, tipoInstrucao: string, valuesChoice: Array<any>) {
    this.requestDataApi.instrucoes = Object.keys(this.valuesChoice).map(key => {
      let instrucao = this.valuesChoice[key];
      return {
        cod_tipo_instrucao: codigoTipoIntrucao,
        nosso_numero: instrucao.item.nosso_numero,
        seu_numero: instrucao.seu_numero.trim(),
        dt_vencimento: tipoInstrucao == 'alteracao-vencimento' ? this.dateFormat(instrucao.valueChoice ? instrucao.valueChoice : this.dataVenc) : null,
        vlr_abatimento: tipoInstrucao == 'abatimento' ? (instrucao.valueChoice ? instrucao.valueChoice.replace('R$ ', '').replace(',', '.') : this.choiceValue.replace('R$ ', '').replace(',', '.')) : null,
        num_dias_protesto: tipoInstrucao == 'protesto' ? (instrucao.valueChoice ? instrucao.valueChoice : this.choice) : null,
        num_conta: instrucao.item.num_conta,
        tipo_pessoa: instrucao.item.tipo_pessoa,
        num_cpf_cnpj: instrucao.item.num_cpf_cnpj,
        nome: instrucao.item.nome_pagador,
        codigo_cliente: null,
        vlr_titulo: instrucao.vlr_titulo,
      };

    });

    this.apiCallback = 66;
    this.securityValidation();
  }

  public securityValidation = () => {
    this.showModalValidation = !this.showModalValidation;
  };

  public filterConfig = (value) => {
    this.filterCombination = { ...this.filterCombination, ...value };
    this._filter.setValue(value);
  };

  responseApi(value: any) {
    if (value.status === 'success') {
      this.setInstructionsSuccess(value);
    } else {
      this.setInstructionsError(value);
    }
  }

  private dateFormat(date) {
    const jsdate = new Date(
      date.split('/')[2],
      date.split('/')[1] - 1,
      date.split('/')[0]
    );
    const format = jsdate.toISOString().slice(0, 19);
    return format;
  }

  setInstructionsSuccess = (res) => {
    if (res && res.data) {
      this.controlFormQuery.stepScreen = this.controlFormQuery.stepScreen + 1;
      this.controlShowComponents();
    }
  };

  setInstructionsError = (err) => {
    this.ShowToast(err);
  };

  instructionEvent($event) { }

  closeModal() {
    this.showModalValidation = false;
  }

  public myGroupControl(name: string) {
    return this.myGroup.get(name);
  }

  public myGroupControlIsInvalid(name: string) {
    const control = this.myGroupControl(name);
  }

  validateExpirationDate(obj) {
    let isDateInvalid = false;

    Object.keys(this.valuesChoice).forEach(key => {
      let originalExpirationDate = moment(this.valuesChoice[key].item.dt_vencimento).format('DD/MM/YYYY').split("/");

      let dataAnteriorVenc = {
        day: parseInt(originalExpirationDate[0], 10),
        month: parseInt(originalExpirationDate[1], 10),
        year: parseInt(originalExpirationDate[2], 10),
      };
      let dateNgbAn =  new NgbDate(dataAnteriorVenc.year, dataAnteriorVenc.month, dataAnteriorVenc.day);
      let dateNgbnew = obj;
      if (typeof(obj) === 'string') {
        let newExpirationDate = obj.split("/");
        let expirationDate = {
          day: parseInt(newExpirationDate[0], 10),
          month: parseInt(newExpirationDate[1], 10),
          year: parseInt(newExpirationDate[2], 10),
        };

        dateNgbnew =  new NgbDate(expirationDate.year, expirationDate.month, expirationDate.day);
      }
      isDateInvalid = dateNgbAn.after(dateNgbnew) || dateNgbAn.equals(dateNgbnew);
    })

    return isDateInvalid;
  }

  selectDate(obj: NgbDate) {
    const ajustDate = new Date(obj.year, obj.month - 1, obj.day);
    let objDate: NgbDate = obj;

    if (!obj) {
      this.getValueChoice('')
    } else {
      if (typeof(obj) === 'string') {
        const newDate = moment(obj, 'DD/MM/YYYY').format('DD/MM/YYYY');
        const dateSplit = newDate.split('/');
    
        objDate = new NgbDate(Number(dateSplit[2]), Number(dateSplit[1]), Number(dateSplit[0]));
        
        if(objDate.before(this.createDefaultDateBatchVencimento) && newDate !== "Invalid date") {
          this.disableLote = true;
          this.dataInvalidaLote = true;
          return;
        }
      }

     // this.getValueChoice(moment(obj).subtract(1, 'months').format('DD/MM/YYYY'))
     var dataCheck = moment(ajustDate).format('DD/MM/YYYY');
     if(dataCheck == 'Invalid date') {
        this.dataVenc = this.dataValida;
      } else {
        this.dataValida = dataCheck;
      }
      this.getValueChoice(this.dataValida)
      this.dataVenc = this.dataValida;
    }

    if (Object.keys(this.valuesChoice).length < 1) {
      this.disableLote = true;
      this.disable = true;
      return;
    }

    if (objDate.before(this.createDefaultDateBatchVencimento) || (Object.keys(this.valuesChoice).length > 0 && this.validateExpirationDate(obj))) {
      //data invalida
      this.disableLote = true;
      this.dataInvalidaLote = true;
    } else {
      this.dataInvalidaLote = false;
    }

  }

  dataChanged(value: Event) {
  

    var isDate = value.target['value'].replaceAll(" ", "");
    if (isDate && isDate.length == 10) {
      var teste = value.target['value']
      const mdt = moment(teste, 'DD/MM/YYYY', true);
      if (!mdt.isValid()) {
        this.disableLote = true;
        this.dataInvalidaLote = true
      }
      else {
        let date = value.target['value'].split("/");
        var data = {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
        var dateNgb = new NgbDate(data.year, data.month, data.day)
        const ajustDate = new Date(dateNgb.year, dateNgb.month - 1, dateNgb.day)
    
        if (dateNgb.before(this.createDefaultDateBatchVencimento) || (Object.keys(this.valuesChoice).length > 0 && this.validateExpirationDate(dateNgb))) {
          //data invalida
          this.disableLote = true;
          this.dataInvalidaLote = true
        } else {
          this.dataInvalidaLote = false
          var dataCheck = moment(ajustDate).format('DD/MM/YYYY');
          if(dataCheck == 'Invalid date') {
             this.dataVenc = this.dataValida;
           } else {
             this.dataValida = dataCheck;
           }
           this.getValueChoice(this.dataValida)
           this.dataVenc = this.dataValida;
        }
      }
      if (Object.keys(this.valuesChoice).length < 1) {
        this.disableLote = true;
        return
      }
    }
  }

  sumDate() {
    const date = new Date()
    const newDate = moment(date).add(1, 'days').format('DD/MM/YYYY');
    // const newDate = moment(date).format('DD/MM/YYYY');
    const dateSplit = newDate.split('/');

    this.createDefaultDateBatchVencimento = new NgbDate(Number(dateSplit[2]), Number(dateSplit[1]), Number(dateSplit[0]));
  }

  getValueTitle() {
    let valuesTitle = []

    Object.values(this.valuesChoice).map((key: any, value) => {
      valuesTitle.push(key.vlr_titulo)
    })

    const minValue = Math.min(...valuesTitle)

    if (minValue == Infinity) {
      this.minValueTitile = 0
    } else {
      this.minValueTitile = minValue
    }
  }

  getValueChoice(event) {

    const newArrValuesChoice: any = Object.values(this.valuesChoice)
    const isNotValueChoice = newArrValuesChoice.find(item => !item.valueChoice)

    this.valueChoice.emit(event.target ? event.target.value : event);
    this.choice = event.target ? event.target.value : event;
    this.choiceValue = this.choice;

    this.shareValueChoice.setValue({
      // originalValue: 
      valueChoice: event.target ? event.target.value : event, /////////////// Esse é "dt_vencimento, vlr_abatimento, num_dias_protesto"
      identifier: 'InBatch',
      // "identifier": this.data.numberItem[this.index], /////////////// Esse é o seu_numero no request?
      // vlr_titulo: , /////////////// Esse vlr_titulo é apresentado no arquivo seguinte
      // (query-transit.component) e pode ser pego pelo indice
    });

    this._filter.setValue({
      valueChoice: event.target ? event.target.value : event, /////////////// Esse é "dt_vencimento, vlr_abatimento, num_dias_protesto"
      identifier: 'InBatch',
      // "identifier": this.data.numberItem[this.index], /////////////// Esse é o seu_numero no request?
      // vlr_titulo: , /////////////// Esse vlr_titulo é apresentado no arquivo seguinte
      // (query-transit.component) e pode ser pego pelo indice
    });
    this.valueMsgInBatch = this.choice;

    switch (this.controlFormQuery.serviceInstruction) {     
      case 'protesto':
        if (event.target.value > 0 && event.target.value !== '' && this.isChecked || Object.keys(this.valuesChoice).length > 0) {
          this.disableLote = false;
          this.disable = false
        }
        else {
          this.disableLote = true;
          this.disable = true
        }
        break;
      case 'abatimento':
        this.getValueTitle();

        let newChoice = this.choice.split(' ')[1]
        newChoice = newChoice.replace('.', '').replace(',', '.')
        if (newChoice <= 0 || newChoice > this.minValueTitile || isNotValueChoice) {
          this.disable = true
          this.disableLote = true
        } else {
          this.disable = false
          this.disableLote = false
        }
        break;
      case 'alteracao-vencimento':
        if (event !== '') {
          this.disableLote = false;
          this.disable = false
        }
        else {
          this.disableLote = true;
          this.disable = true
        }
        break;
    }
  }

  deactivateAdvanceButton() {
    this.disable = true;
    this.disableLote = true;
    this.dataInvalidaLote = false;
    return;
  }

  selectTypeList(event) {
    this.typeList = event.idOption;
    this.tab.setValue({
      typeList: this.typeList,
      periodDays: null,
      periodCalendar: null,
    });

    this.changeAbs(this.typeList)
  }

  changeAbs(value) {

    this.filterCobranca.setPeriods();
    switch (value) {
      case 'posicao-carteira-list-paid':
        this.closeInstructions();
        break;
      case 'posicao-carteira-list-overdue':
        this.closeInstructions();
        break;
      case 'posicao-carteira-list-downloaded':
        this.closeInstructions();
        break;
      case 'posicao-carteira-list-expiring':
        this.closeInstructions();
        break;
        case 'posicao-carteira-list-in-notary':
        this.closeInstructions();
        break;
    }
  }

  resetHideMsg(type) {
    this.controlFormQuery.hideMsgLabel = true;
    this.controlFormQuery.hideMsgLink = true;
    this.controlFormQuery.hideMsgPostInput = true;
    this.controlFormQuery.hideMsgButtons = true;
    this.controlFormQuery.hideMsgInputButtons = true;

    if (type !== 'showMsg') {
      this.controlFormQuery.hideButtonBottom = true;
      this.controlFormQuery.instructionInBatch = null;
      this.controlFormQuery.serviceInstruction = null;
      this.controlFormQuery.stepScreen = 0;

      this.shareInstruction.setValue({
        dataQueryComponent: {
          optionInBatch: null,
          instruction: null,
          screen: 0,
          inputServiceMoney: false,
          calendarService: false,
          inputServiceNumber: false
        },
      });
    }
  }

  showMsg() {
    this.resetHideMsg('showMsg');

    if (this.controlFormQuery.serviceInstruction === 'abatimento') {
      if (
        this.controlFormQuery.instructionInBatch === false &&
        (this.controlFormQuery.stepScreen === 0 || this.controlFormQuery.stepScreen === 1)
      ) {
        this.controlFormQuery.labelMsg =
          'Selecione os títulos abaixo e informe o valor do abatimento nos respectivos campos de preenchimento';
        this.controlFormQuery.hideMsgLabel = false;
        return;
      }
      /*  if (
         this.controlFormQuery.instructionInBatch === false &&
         this.controlFormQuery.stepScreen === 2
       ) {
         this.controlFormQuery.labelMsg =
           `Solicitado abatimento de ${this.choice} em ${Object.keys(this.valuesChoice).length} títulos`;
         this.controlFormQuery.hideMsgLink = false;
         return;
       } */

      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 2 && this.selectedCkeckTitle < 2
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado abatimento em ${this.selectedCkeckTitle} título`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 2 && this.selectedCkeckTitle > 1
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado abatimento em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 0
      ) {
        this.controlFormQuery.labelMsg = 'Solicitar abatimento de ';
        this.controlFormQuery.titleButtonComeBack = 'Cancelar';
        this.controlFormQuery.titleButtonAdvance = 'Adicionar';
        this.controlFormQuery.hideMsgInputButtons = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 1
      ) {
        this.controlFormQuery.labelMsg = `Os ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos selecionados abaixo irão receber abatimento de ${this.valueMsgInBatch}`;
        this.controlFormQuery.hideMsgLabel = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 2
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado abatimento de ${this.choice} em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
      }
    }

    if (this.controlFormQuery.serviceInstruction === 'alteracao-vencimento') {
      if (
        this.controlFormQuery.instructionInBatch === false &&
        (this.controlFormQuery.stepScreen === 0 ||
          this.controlFormQuery.stepScreen === 1)
      ) {
        this.controlFormQuery.labelMsg =
          'Os títulos abaixo irão receber as alterações de datas de vencimento indicadas nos respectivos campos';
        this.controlFormQuery.hideMsgLabel = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false && this.selectedCkeckTitle > 1 &&
        this.controlFormQuery.stepScreen === 2
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado alteração da data de vencimento em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false && this.selectedCkeckTitle < 2 &&
        this.controlFormQuery.stepScreen === 2
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado alteração da data de vencimento em ${this.selectedCkeckTitle} título`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 0
      ) {
        this.controlFormQuery.labelMsg =
          'Conceder alteração da data de vencimento para';
        this.controlFormQuery.titleButtonComeBack = 'Cancelar';
        this.controlFormQuery.titleButtonAdvance = 'Adicionar';
        this.controlFormQuery.hideMsgInputButtons = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 1
      ) {
        this.controlFormQuery.labelMsg = `Os ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos selecionados abaixo irão alterar sua data de vencimento para ${this.valueMsgInBatch}`;
        this.controlFormQuery.hideMsgLabel = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 2
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado alteração da data de vencimento para ${this.valueMsgInBatch} em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
      }
    }

    if (this.controlFormQuery.serviceInstruction === 'sustar-protesto') {
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 0
      ) {
        this.controlFormQuery.labelMsg =
          'Selecione os títulos abaixo que deseja adicionar sustação de protesto';
        this.controlFormQuery.hideMsgLabel = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 1 && this.selectedCkeckTitle < 2
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado sustação de protesto em ${this.selectedCkeckTitle} título`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 1 && this.selectedCkeckTitle > 1
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado sustação de protesto em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 0
      ) {
        this.controlFormQuery.labelMsg =
          // `Todos os ${this.selectedCkeckTitle} títulos dessa página estão selecionados para receber sustação de protesto`;
          'Selecione os títulos abaixo que deseja adicionar sustação de protesto';
        this.controlFormQuery.titleButtonComeBack = 'Cancelar';
        this.controlFormQuery.titleButtonAdvance = 'Conceder';
        this.controlFormQuery.hideMsgButtons = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 1
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado sustação de protesto em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
    }

    if (this.controlFormQuery.serviceInstruction === 'protesto') {
      if (
        this.controlFormQuery.instructionInBatch === false &&
        (this.controlFormQuery.stepScreen === 0)
      ) {
        this.controlFormQuery.labelMsg =
          'Selecione os títulos abaixo e informe os dias de protesto nos respectivos campos de preenchimento';
        this.controlFormQuery.hideMsgLabel = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false &&
        (this.controlFormQuery.stepScreen === 1)
      ) {
        this.controlFormQuery.labelMsg =
          'Os títulos abaixo irão receber os dias de protesto indicado nos respectivos campos de preenchimento';
        this.controlFormQuery.hideMsgLabel = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 2 && this.selectedCkeckTitle < 2
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado protesto em ${this.selectedCkeckTitle} título`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 2 && this.selectedCkeckTitle > 1
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado protesto em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 0
      ) {
        this.controlFormQuery.labelMsg = 'Protestar em ';
        this.controlFormQuery.titleButtonComeBack = 'Cancelar';
        this.controlFormQuery.titleButtonAdvance = 'Adicionar';
        this.controlFormQuery.labelPostInput = ' dia(s) após o vencimento';
        this.controlFormQuery.hideMsgPostInput = false;
        this.controlFormQuery.hideMsgInputButtons = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 1
      ) {
        if (this.valueMsgInBatch > 1 && this.selectedCkeckTitle > 1) {
          this.controlFormQuery.labelMsg = `Os ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos selecionados abaixo receberão protesto de  ${this.valueMsgInBatch} dias`;
          this.controlFormQuery.hideMsgLabel = false;
          return;
        }
        if (this.valueMsgInBatch > 1 && this.selectedCkeckTitle == 1) {
          this.controlFormQuery.labelMsg =
            `O título selecionado abaixo receberá protesto de  ${this.valueMsgInBatch} dias`;
          this.controlFormQuery.hideMsgLabel = false;
          return;
        }
        if (this.valueMsgInBatch == 1 && this.selectedCkeckTitle > 1) {
          this.controlFormQuery.labelMsg =
            `Os ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos selecionados abaixo receberão protesto de  ${this.valueMsgInBatch} dia`;
          this.controlFormQuery.hideMsgLabel = false;
          return;
        }
        if (this.valueMsgInBatch == 1 && this.selectedCkeckTitle == 1) {
          this.controlFormQuery.labelMsg =
            `O título selecionado abaixo receberá protesto de  ${this.valueMsgInBatch} dia`;
          this.controlFormQuery.hideMsgLabel = false;
          return;
        }
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 2
      ) {
        if (this.valueMsgInBatch == 1 && this.selectedCkeckTitle == 1) {
          this.controlFormQuery.labelMsg =
            `Solicitado protesto de ${this.valueMsgInBatch} dia em ${this.selectedCkeckTitle} título`;
          this.controlFormQuery.hideMsgLink = false;
        }
        if (this.valueMsgInBatch == 1 && this.selectedCkeckTitle > 1) {
          this.controlFormQuery.labelMsg =
            `Solicitado protesto de ${this.valueMsgInBatch} dia em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
          this.controlFormQuery.hideMsgLink = false;
        }
        if (this.valueMsgInBatch > 1 && this.selectedCkeckTitle == 1) {
          this.controlFormQuery.labelMsg =
            `Solicitado protesto de ${this.valueMsgInBatch} dias em ${this.selectedCkeckTitle} título`;
          this.controlFormQuery.hideMsgLink = false;
        }
        if (this.valueMsgInBatch > 1 && this.selectedCkeckTitle > 1) {
          this.controlFormQuery.labelMsg =
            `Solicitado protesto de ${this.valueMsgInBatch} dias em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
          this.controlFormQuery.hideMsgLink = false;
        }
      }
    }

    if (this.controlFormQuery.serviceInstruction === 'pedido-baixa') {
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 0
      ) {
        this.controlFormQuery.labelMsg =
          'Selecione os títulos abaixo que deseja adicionar pedido de baixa';
        this.controlFormQuery.hideMsgLabel = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 1 && this.selectedCkeckTitle > 1
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado pedido de baixa em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch === false &&
        this.controlFormQuery.stepScreen === 1 && this.selectedCkeckTitle < 2
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado pedido de baixa em ${this.selectedCkeckTitle} título`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 0
      ) {
        this.controlFormQuery.labelMsg =
          // `Todos os ${this.selectedCkeckTitle} títulos dessa página estão selecionados para receber pedido de baixa`;
          'Selecione os títulos abaixo que deseja adicionar pedido de baixa';
        this.controlFormQuery.titleButtonComeBack = 'Cancelar';
        this.controlFormQuery.titleButtonAdvance = 'Conceder';
        this.controlFormQuery.hideMsgButtons = false;
        return;
      }
      if (
        this.controlFormQuery.instructionInBatch &&
        this.controlFormQuery.stepScreen === 1
      ) {
        this.controlFormQuery.labelMsg =
          `Solicitado pedido de baixa em ${this._posicaoCarteiraCheckedAll ? this._rowCount : this.selectedCkeckTitle} títulos`;
        this.controlFormQuery.hideMsgLink = false;
        return;
      }
    }
  }

  private hideSideCard = () => {
    return (
      (this.controlFormQuery.serviceInstruction === 'abatimento' &&
        this.controlFormQuery.instructionInBatch !== null &&
        (this.controlFormQuery.stepScreen === 1 ||
          this.controlFormQuery.stepScreen === 2)) ||
      (this.controlFormQuery.serviceInstruction === 'alteracao-vencimento' &&
        this.controlFormQuery.instructionInBatch !== null &&
        (this.controlFormQuery.stepScreen === 1 ||
          this.controlFormQuery.stepScreen === 2)) ||
      (this.controlFormQuery.serviceInstruction === 'sustar-protesto' &&
        this.controlFormQuery.instructionInBatch !== null &&
        this.controlFormQuery.stepScreen === 1) ||
      (this.controlFormQuery.serviceInstruction === 'protesto' &&
        this.controlFormQuery.instructionInBatch !== null &&
        (this.controlFormQuery.stepScreen === 1 ||
          this.controlFormQuery.stepScreen === 2)) ||
      (this.controlFormQuery.serviceInstruction === 'pedido-baixa' &&
        this.controlFormQuery.instructionInBatch !== null &&
        this.controlFormQuery.stepScreen === 1)
    );
  }


  showOptionsInstructions() {
    const hide = this.hideSideCard();
    this.controlFormQuery.showOptionsInstructions = !hide;
  }
  showOptionsInstructionsIndividual() {
    this.controlFormQuery.inputServiceMoney = false;
    this.controlFormQuery.inputServiceNumber = false;
    this.controlFormQuery.calendarService = false;
    if (!this.controlFormQuery.instructionInBatch) {
      if (this.controlFormQuery.serviceInstruction == "abatimento")
        this.controlFormQuery.inputServiceMoney = true;

      if (this.controlFormQuery.serviceInstruction == "protesto")
        this.controlFormQuery.inputServiceNumber = true;
      if (this.controlFormQuery.serviceInstruction == "alteracao-vencimento")
        this.controlFormQuery.calendarService = true;
    }
  }

  showButtonsBottom() {
    this.controlFormQuery.hideButtonBottom = false;

    const items = ['abatimento', 'alteracao-vencimento', 'protesto'];
    const includes = items.includes(this.controlFormQuery.serviceInstruction);

    const items2 = ['pedido-baixa', 'sustar-protesto'];
    const includes2 = items2.includes(this.controlFormQuery.serviceInstruction);

    if (
      this.controlFormQuery.instructionInBatch === false &&
      this.controlFormQuery.stepScreen === 0 &&
      includes
    ) {
      if (this.controlFormQuery.serviceInstruction == "alteracao-vencimento") {
        this.choice = this.dataVenc;
      }
      this.controlFormQuery.titleButtonAdvance = 'Adicionar';
      return;
    }

    const verification1 =
      this.controlFormQuery.instructionInBatch !== null &&
      this.controlFormQuery.stepScreen === 1 &&
      includes;
    const verification2 =
      this.controlFormQuery.instructionInBatch === false &&
      this.controlFormQuery.stepScreen === 0 &&
      includes2;

    if (verification1 || verification2) {
      this.controlFormQuery.titleButtonAdvance = 'Conceder';
      this.controlFormQuery.titleButtonComeBack = 'Voltar';
    } else {
      this.controlFormQuery.hideButtonBottom = true;
    }
  }

  advanceOption() {
    const items = ['abatimento', 'alteracao-vencimento', 'protesto'];
    const includes = items.includes(this.controlFormQuery.serviceInstruction);

    const items2 = ['pedido-baixa', 'sustar-protesto'];
    const includes2 = items2.includes(this.controlFormQuery.serviceInstruction);

    /* if (this.controlFormQuery.stepScreen === 0 && includes && !this.choice) {
      return alert('Necessário preencher um valor');
    } */
    if (
      (this.controlFormQuery.stepScreen === 0 && includes2) ||
      (this.controlFormQuery.stepScreen === 1 && includes)
    ) {
      // this.securityValidation();
      this.sendInstructions();
    } else {
      this.controlFormQuery.stepScreen = this.controlFormQuery.stepScreen + 1;
      this.controlShowComponents();
    }

    if ((this.controlFormQuery.stepScreen === 1 && includes)) {
      this.readonlyInput = true;
    } else {
      this.readonlyInput = false;
    }
  }

  returnOption() {
    if (this.controlFormQuery.stepScreen > 0) {
      this.controlFormQuery.stepScreen = this.controlFormQuery.stepScreen - 1;
      this.controlShowComponents();
    }
    this.resetHideMsg('closeInstructions');
    this.reactiveForm.controls.reactiveRadio.setValue(false)
    this.cleanList = true;
    this.controlFormQuery.showButtonInstructions = true;
    this.controlFormQuery.showOptionsInstructions = false;
    this.disableLote = true; //desabilitar botão de avançar
    this.disable = true
    this.adcInstrucao = false;
    this.readonlyInput = false;
  }

  btnCancel() {
    this.resetHideMsg('closeInstructions');
    this.reactiveForm.controls.reactiveRadio.setValue(false)
  }

  controlShowComponents() {
    this.showButtonsBottom();
    this.showOptionsInstructions();
    this.showMsg();
    this.showOptionsInstructionsIndividual();

    if (!this.controlFormQuery.instructionInBatch) {
      this.choice = '';
    }

    this.shareInstruction.setValue({
      dataQueryComponent: {
        optionInBatch: this.controlFormQuery.instructionInBatch,
        instruction: this.controlFormQuery.serviceInstruction,
        screen: this.controlFormQuery.stepScreen,
        inputServiceMoney: this.controlFormQuery.inputServiceMoney,
        calendarService: this.controlFormQuery.calendarService,
        inputServiceNumber: this.controlFormQuery.inputServiceNumber
      },
    });

    this.verifyInBacth();
  }

  reviewToken() {
    this.token = sessionStorage.getItem('accessToken');
    if (!this.token) {
      this.authService.logout();
    }
  }

  private optionsConfigTabs() {
    this.optionTransit = models.optionTransit;
    this.optionPortfolio = models.optionPosicaoCarteira;
    this.optionInstruction = models.optionInstruction;
    this.optionFranc = models.optionFranc;
  }

  addInstructions() {
    this.controlFormQuery.showButtonInstructions = false;
    this.controlFormQuery.showOptionsInstructions = true;
    this.cleanList = false;
    this.adcInstrucao = true;
    this.readonlyInput = false;
  }

  closeInstructions() {
    this.controlFormQuery.showButtonInstructions = true;
    this.controlFormQuery.showOptionsInstructions = false;
    this.resetHideMsg('closeInstructions');
    this.cleanList = true;
    this.adcInstrucao = false;
    this.readonlyInput = false;
  }

  public changeInstruction(option) {

    this.shareValueChoice.setValue({
      valueChoice: '',
      identifier: 'InBatch',
    });
    this.choice = ' '
    this.controlFormQuery.serviceInstruction = option;
    // this.requestDataApi.service = option;

    this.controlFormQuery.stepScreen = 0;
    this.controlShowComponents();
    this.clearValuesChoice()
    this.virifyDisabledLote(this.isChecked);
    this.verifyInBacth();

    const newArrValuesChoice: any = Object.values(this.valuesChoice)
    const isNotValueChoice = newArrValuesChoice.find(item => !item.valueChoice)
    if (Object.keys(this.valuesChoice).length < 1) {
      return this.disable = true
    } else if (isNotValueChoice && option !== 'pedido-baixa' && option !== 'sustar-protesto') {
      return this.disable = true
    } else {
      return this.disable = false
    }
  }

  public verifyInBacth() {

    if (this.controlFormQuery.serviceInstruction === 'protesto') {
      this.isBatch = this.controlFormQuery.instructionInBatch;
    } else {
      this.isBatch = false;
    }

    if (this.controlFormQuery.serviceInstruction === 'abatimento') {
      this.isBatchAbatimento = this.controlFormQuery.instructionInBatch;
    } else {
      this.isBatchAbatimento = false;
    }

    if (this.controlFormQuery.serviceInstruction === 'alteracao-vencimento') {
      this.isBatchAltVencimento = this.controlFormQuery.instructionInBatch;
    } else {
      this.isBatchAltVencimento = false;
    }
  }

  listingSelect(event) {
    this.currentView = event
    // const init = { option: 'Arquivos em trânsito', value: 1 };
    // this.switchLists(init);
    if (event.value !== 2) {
      this.showTooltipPortfolioPosition = false;
      this.controlFormQuery.showButtonInstructions = false;
      this.controlFormQuery.showOptionsInstructions = false;
    }
    this.switchLists(event);
  }

  private switchLists(event) {
    switch (event.value) {
      case 1:
        this.options = this.optionsSideCard[1];
        this.tabsOptions = this.optionTransit;
        this.typeList = 'query-list-all';
        this.tab.setValue({
          typeList: this.tabsOptions[0].idOption,
          periodDays: null,
          periodCalendar: null,
        });
        this._tabsOnChange.changeTab('query-transit');
        this.hideCard = false;
        this.resetHideMsg('transit');
        this.hideList = 'query-transit';
        this.adcInstrucao = false;
        return;

      case 2:
        this.options = this.optionsSideCard[3];
        this.tabsOptions = this.optionPortfolio;
        this.tab.setValue({
          typeList: this.tabsOptions[0].idOption,
          periodDays: null,
          periodCalendar: null,
        });
        this._tabsOnChange.changeTab('query-posicao-carteira');
        this.hideCard = false;
        this.controlFormQuery.showButtonInstructions = true;
        this.showTooltipPortfolioPosition = true;
        this.controlFormQuery.stepScreen = 0;
        this.hideList = 'query-posicao-carteira';
        this.adcInstrucao = false;
        return;

      case 3:
        this.tabsOptions = this.optionInstruction;
        this.tab.setValue({
          typeList: this.tabsOptions[0].idOption,
          periodDays: null,
          periodCalendar: null,
        });
        this._tabsOnChange.changeTab('query-instruction');
        this.controlFormQuery.showButtonInstructions = false;
        this.hideCard = true;
        this.resetHideMsg('addInstructions');
        this.hideList = 'query-instruction';
        this.adcInstrucao = false;
        return;

      case 4:
        this.tabsOptions = this.optionFranc;
        this.tab.setValue({
          typeList: this.tabsOptions[0].idOption,
          periodDays: null,
          periodCalendar: null,
        });
        this._tabsOnChange.changeTab('query-francesinha');
        this.controlFormQuery.showButtonInstructions = false;
        this.hideCard = true;
        this.resetHideMsg('francesinha');
        this.hideList = 'query-francesinha';
        this.adcInstrucao = false;
        return;
    }
  }

  backDetail() {
    this.titleDetail = null;
    this.queryDetail = null;
  }

  showDetail(event) {
    this.queryDetail = event;
  }

  showPortfolio(event) {
    this.titleDetail = event;
  }

  instruction() {

  }

  getInfoExport(event) {
    if (event === 1) {
      //this.navigateToResource('/query-export');
      this.report = true;
      return;
    }

    if (event === 2) this.exportExcel()
  }

  public generatePDFCustom() {
    this.isPDPGenerate = true;
    var currentdate = new Date();
    let reportName = 'consultas_' + currentdate.getDate().toString() + '/' + (currentdate.getMonth() + 1).toString() + '/' + currentdate.getFullYear().toString()
    let data = null;

    switch (this.currentView.value) {
      case 1:
        data = document.getElementById('body-transit');
        break;
      case 2:
        data = document.getElementById('body-posicao-carteira');
        break;
      case 3:
        break;
      case 4:
        data = document.getElementById('body-francesinha');
        break;
    }

    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
    {
      html2canvas(data,{
          windowWidth: document.body.scrollWidth,
          windowHeight: document.body.scrollHeight,
          x: window.pageXOffset,
          y: window.pageYOffset,
      }).then((canvas) => {

        var imgWidth = 210;
        var pageHeight = 600;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        
        var doc = new jsPDF('p', 'mm');
        var position = 0;

        const imgData = canvas.toDataURL("image/jpeg")


        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(reportName+'.pdf');
        this.typToastColor = this.GREENCOLOR;
        this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
      });
    } else {
      html2canvas(data).then((canvas) => {
        var imgWidth = 210;
        var pageHeight = 297;

        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        
        var doc = new jsPDF('p', 'mm');
        var position = 0;

        const imgData = canvas.toDataURL("image/jpg")

        doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(reportName+'.pdf');
        this.typToastColor = this.GREENCOLOR;
        this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
      });
    }

    $("#ico-fechar").css({ display: "block" });
    //this.goBack();

  }

  private navigateToResource(router?: string): void {
    window.open(router);
  }

  exportExcel() {
    
    let params: any = { ...this.currentFilter, status: this.typeList }

    params.pagador = params.draweeName;
    params.nossonumero = params.ourNumber;
    params.seunumero = params.yourNumber;
    params.valor = params.valorTitulo;
    delete params.draweeName;
    delete params.ourNumber;
    delete params.yourNumber;
    delete params.valorTitulo;

    switch (this.currentView.value) {
      case 1:
        this.paramArquivoTransitoExcel(params)
        params = { ...this.paramsDefault, view: 'arquivostransito/exporta/excel' }
        break;
      case 2:
        params = { ...params, view: 'PosicaoCarteira/exporta/excel' }
        break;
      case 3:
        params = { ...this._paramsInstrucoes, paginar: false, offset: null, view: 'instrucoes/excel' };
        break;
      case 4:
        delete params['status'];
        params = { ...params, view: 'francesinha/exportar/excel' }//caminho excel
        break;
    }

    if (this.currentView.value !== 3) {
      params.dt_inicial = params.dt_Inicio;
      delete params.dt_Inicio;
    }

    this.requestExportExcel(params)
  }

  paramArquivoTransitoExcel(param: any) {
    
    const getSituacao = (status) => {
      switch (status) {
        case 'query-list-all':
          return null;
        case 'query-list-authorized':
          return 1;
        case 'query-list-refused':
          return 3;
        case 'query-list-pending':
          return 2;
        default:
          break;
      }
    }

    const isTransitApproved: boolean = (this.typeList === 'query-list-authorized' && this.hideList === 'query-transit');

    this.paramsDefault = {
      conta: {
        numero_conta: param.account != undefined ? param.account : '',
        codigo_coligada: '001',
        codigo_agencia: param.cod_agencia,
      },
      param_carteira: param.param_carteira || [],
      data_arquivo_inicial: isTransitApproved ? param.dt_Final || '' : param.dt_Inicio || '',
      data_arquivo_final: param.dt_Final || '',
      tipo_data: isTransitApproved ? 'envio' : param.tipo_data || '',
      codigo_cliente: null,
      codigo_especie: param.codigo_especie || '',
      codigo_produto: null,
      valor_titulo_inicial: param.valor,
      valor_titulo_final: param.valor,
      seu_numero: param.seunumero,
      codigo_situacao: getSituacao(param.status),
      nome_pagador: param.pagador
    };
  }

  public verifyCalendar(event: string) {
    if (event === 'isGreaterThan730Days') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 730 dias.',
        null,
        null
      );
      return;
    }

    if (event === 'outOfReach') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 180 dias.',
        null,
        null
      );
      return;
    }

    if (event === 'isGreaterThan90Days') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 90 dias.',
        null,
        null
      );
      return;
    }

    if (event === 'isGreaterThan365Days') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 365 dias.',
        null,
        null
      );
      return;
    }
  }

  private requestExportExcel(param) {
    const getFilename = () => {
      const currentdate  = new Date();

      switch (this.currentView.value) {
        case 1:
          return "arquivos_em_transito_" + moment(currentdate).format("DD/MM/YYYY");
        case 2:
          return "posicao_de_carteira_" + moment(currentdate).format("DD/MM/YYYY");
        case 3:
          return "instrucoes_" + moment(currentdate).format("DD/MM/YYYY");
        case 4: 
          return "francesinha_" + moment(currentdate).format("DD/MM/YYYY");
        default:
          return "consultas_" + moment(currentdate).format("DD/MM/YYYY");
      }
    };

    let payload = { ...param };

    if (this.currentView.value ==  2) {
      var codigo_situacao = models.optionPosicaoCarteira
        .filter(situacao => situacao['idOption'] == param.status)
        .map(situacao => situacao['codigo_situacao'])
        .find(_ => true);
        
      const tipo_data_posicao = param.tipo_data_posicao || 'emissao';
      payload = { ...param, codigo_situacao, tipo_data_posicao};
    }    

    this.subscriptions.push(this.getAllQueryExportExcel
      .execute(payload)
      .subscribe((res) => {
        Util.handleFile(res, getFilename());
        this.typToastColor = this.GREENCOLOR;
        this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
      }));
  }

  verifyValueChoiceOut(value) {
      const newArrValuesChoice: any = Object.values(this.valuesChoice)
      const isNotValueChoice = newArrValuesChoice.find(item => !item.valueChoice)

      if (this.controlFormQuery.serviceInstruction === 'abatimento') {
        this.getValueTitle()

        let newChoice: any

        if (value) {
          newChoice = value.split(' ')[1]
          newChoice = newChoice.replace('.', '').replace(',', '.')
        }

        if (this._posicaoCarteiraCheckedAll && (newArrValuesChoice.length < this._rowCount)) {
          this.disable = true;
        } else if (!newChoice || newChoice <= 0 || newChoice > this.minValueTitile || isNotValueChoice) {
          this.disable = true
          this.disableLote = true
        } else {
          this.disable = false
        }
      }

      if (this.controlFormQuery.serviceInstruction === 'protesto') {
        if (this._posicaoCarteiraCheckedAll && (newArrValuesChoice.length < this._rowCount)) {
          this.disable = true;
        } else if (value > 0 && value !== '' && !isNotValueChoice) {
          this.disable = false;
        } else {
          this.disable = true;
        }
      }

      if (this.controlFormQuery.serviceInstruction === 'alteracao-vencimento') {
        if (this._posicaoCarteiraCheckedAll && (newArrValuesChoice.length < this._rowCount)) {
          this.disable = true;
        } else if (value !== '' && !isNotValueChoice) {
          this.disable = false
        } else {
          this.disable = true
        }
      }
  }

  virifyDisabledLote(value) {
    this.getValueTitle()

    setTimeout(() => {
      if (this.controlFormQuery.instructionInBatch) {
        let newChoice: any
        switch (this.controlFormQuery.serviceInstruction) {
          case 'abatimento':
            if (this.choiceValue) {
              this.shareValueChoice.setValue({
                valueChoice: this.choiceValue,
                identifier: 'InBatch',
              });
              newChoice = this.choiceValue.split(' ')[1]
              newChoice = newChoice.replace('.', '').replace(',', '.')
            }
            if (!value) {

              this.disable = true
              this.disableLote = true
            } else if (value && !newChoice || newChoice <= 0 || newChoice > this.minValueTitile) {
              this.disable = true
              this.disableLote = true
            } else if (value) {
              this.disable = false
              this.disableLote = false
            }
            // }, 1000)
            break;

          case 'alteracao-vencimento':
            if (this.choice) {
              this.shareValueChoice.setValue({
                valueChoice: this.choice,
                identifier: 'InBatch',
              });
            }
            // setTimeout(() => {
            if (!value && !this.choice) {
              this.disableLote = true
              this.disable = true
            } else {
              const dateSplit = this.choiceValue.split('/');
          
              let objDate = new NgbDate(Number(dateSplit[2]), Number(dateSplit[1]), Number(dateSplit[0]));
              if(objDate.before(this.createDefaultDateBatchVencimento) || (Object.keys(this.valuesChoice).length > 0 && this.validateExpirationDate(objDate))) {
                this.disableLote = true;
                this.disable = true;
              } else {
                this.disableLote = false
                this.disable = false
              }
            }
            // }, 1000)

            break

          case 'protesto':

            if (this.choiceValue) {
              this.shareValueChoice.setValue({
                valueChoice: this.choiceValue,
                identifier: 'InBatch',
              });
            }

            // setTimeout(() => {
            if (!value || !this.choiceValue) {

              this.disableLote = true
              this.disable = true
            } else {

              this.disableLote = false
              this.disable = false
            }
            // }, 1000)

            break

          case 'sustar-protesto':
            if (value) {
              this.disable = false
              this.disableLote = false
            } else {
              this.disable = true
              this.disableLote = true
            }
            break

          case 'pedido-baixa':
            if (value) {
              this.disable = false
              this.disableLote = false
            } else {
              this.disable = true
              this.disableLote = true
            }
            break

          default:
            break;
        }
      }
    }, 500)

  }

  verifyChecked(value) {
    setTimeout(() => {

      const newArrValuesChoice: any = Object.values(this.valuesChoice)
      const isNotValueChoice = newArrValuesChoice.find((item: any) => !item.valueChoice)

      if (!value || isNotValueChoice && this.controlFormQuery.serviceInstruction !== 'pedido-baixa' && this.controlFormQuery.serviceInstruction !== 'sustar-protesto') {
        this.disable = true
      } else if (value) {
        this.disable = false
      }

      this.virifyDisabledLote(value)
    }, 500)

    return this.isChecked = value
  }

  verifyDate(value) {
    const values: any = Object.values(this.valuesChoice)
    const selectedDate = values.find(item => !item.valueChoice)

    if (value === false || (!this.isBatchAltVencimento && selectedDate !== undefined && (selectedDate.valueChoice === "" || selectedDate.valueChoice === undefined))) {
      this.dataInvalidaLote = true;
      this.disable = true
    } else {
      this.disable = false
    }
  }
  
  verifyExpirationDate(value) {
    if (value === true) {
      this.isExpirationDateInvalid = true;
    } else {
      this.isExpirationDateInvalid = false;
    }
  }

  verifyChanges() {
    var checkBoxs = this.isChecked
    /* 
    if (!checkBoxs || this.disableLote) {
     this.disable = true
     this.disableLote = true
    } else if (checkBoxs && this.disableLote === false) {
     this.disable = false
     this.disableLote = false
    }  */

    if (this.controlFormQuery.serviceInstruction === 'sustar-protesto' ||
      this.controlFormQuery.serviceInstruction === 'pedido-baixa') {

      if (checkBoxs) {
        this.disableLote = false
      } else {
        this.disableLote = true
      }
    }
  }

  posicaoCarteiracheckedAll(value) {
    this._posicaoCarteiraCheckedAll = value;
  }

  posicaoCarteiraEOF(value) {
    this._posicaoCarteiraEOF = value;
  }

  posicaoCarteiraLoadingRemainingRows(value) {
    this._posicaoCarteiraLoadingRemainingRows = value;
  }

  posicaoCarteiraLoadedRemainingRows(rows: Array<posicaoCarteiraListQueryTypeEnum>) {
    if (this.controlFormQuery.serviceInstruction === 'protesto') {
      rows.forEach(t => this.valuesChoice[t.seu_numero] = { seu_numero: t.seu_numero, vlr_titulo: t.vlr_titulo, item: t, valueChoice: this.currentFilter['valueChoice'] } );
      this._sendInstructions('9', 'protesto', this.valuesChoice);
    } else if (this.controlFormQuery.serviceInstruction ===  'alteracao-vencimento') {
      rows.forEach(t => this.valuesChoice[t.seu_numero] = { seu_numero: t.seu_numero, vlr_titulo: t.vlr_titulo, item: t, valueChoice: this.currentFilter['valueChoice'] } );
      this._sendInstructions('6', 'alteracao-vencimento', this.valuesChoice);
    } else if (this.controlFormQuery.serviceInstruction === 'abatimento') {
      rows.forEach(t => this.valuesChoice[t.seu_numero] = { seu_numero: t.seu_numero, vlr_titulo: t.vlr_titulo, item: t, valueChoice: this.currentFilter['valueChoice'] } );
      this._sendInstructions('4', 'abatimento', this.valuesChoice);
    } else if (this.controlFormQuery.serviceInstruction === 'sustar-protesto') {
      rows.forEach(t => this.valuesChoice[t.seu_numero] = { seu_numero: t.seu_numero, vlr_titulo: t.vlr_titulo, item: t, valueChoice: '' } );
      this._sendInstructions('18', 'sustar-protesto', this.valuesChoice);
    } else if (this.controlFormQuery.serviceInstruction === 'pedido-baixa') {
      rows.forEach(t => this.valuesChoice[t.seu_numero] = { seu_numero: t.seu_numero, vlr_titulo: t.vlr_titulo, item: t } );
      this._sendInstructions('2', 'pedido-baixa', this.valuesChoice);
    }
  }

  prepareDataExport(value) {
    let params: any = { ...this.currentFilter, status: this.typeList }

    params.pagador = params.draweeName;
    params.nossonumero = params.ourNumber;
    params.seunumero = params.yourNumber;
    params.valor = params.valorTitulo;
    delete params.draweeName;
    delete params.ourNumber;
    delete params.yourNumber;
    delete params.valorTitulo;

    switch (this.currentView.value) {
      case 1:
        this.paramArquivoTransitoExcel(params)
        params = { ...this.paramsDefault, view: 'arquivostransito/exporta/excel' }
        break;
      case 2:
        params = { ...params, view: 'PosicaoCarteira/exporta/excel' }
        break;
      case 3:
        params = { ...this._paramsInstrucoes, view: 'instrucoes/excel' };
        break;
      case 4:
        delete params['status'];
        params = { ...params, view: 'francesinha/exportar/excel' }//caminho excel
        break;
    }

    if (this.currentView.value === 4) {
      localStorage.setItem(
        'query-export-data',
        JSON.stringify({
          data: value.tableData,
          resume: value.resume,
          type: this.typeList,
          date: params,
          tabs: this.hideList,
        })
      );
    } else {
      localStorage.setItem(
        'query-export-data',
        JSON.stringify({
          data: value,
          type: this.typeList,
          date: params,
          tabs: this.hideList,
        })
      );
    }
  }

  public changeReportView(event) {
    this.report = !this.report;
  }
}
