import { Component, OnInit, PipeTransform } from '@angular/core';

import { Router } from '@angular/router';
import { TransferPendingDataShare } from '@src/data/repository/data-share-repository';

@Component({
  selector: 'fibra-transfer-payment-pending',
  templateUrl: './transfer-pending.component.html',
  styleUrls: ['./transfer-pending.component.scss'],
})
export class TransferPendingComponent implements OnInit {
  data;
  constructor(
    private router: Router,
    private shareTransferReponse: TransferPendingDataShare
  ) {}

  ngOnInit() {
    this.shareTransferReponse.dataShared.subscribe((res) => (this.data = res));
  }

  public goToView = (route) => this.router.navigateByUrl(route);
}
