<!-- <div>
  <ul *ngFor="let dateFiles of listFilesGroup">
    {{dateFiles.date}}

    <li *ngFor="let fil of dateFiles.files ">
      sadasdas {{fil.agencia}}
    </li>

  </ul>



</div> -->
<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="list-value">
  <div class="box-header">
    <div *fibraHasRole="'COB.CONS_ARQ_REM, QUALQUER_CONTA.COB.CONS_ARQ_REM'" class="first-stage"
      [ngClass]="{ active: active == 'first' }" (click)="handleTabs('first')">
      <label class="stage-label">Remessa</label>
    </div>
    <div *fibraHasRole="'COB.CONS_ARQ_RET, QUALQUER_CONTA.COB.CONS_ARQ_RET'" class="second-stage"
      [ngClass]="{ active: active == 'second' }" (click)="handleTabs('second')">
      <label class="stage-label">Retorno</label>
    </div>
  </div>

  <ul class="col-lg-12 list-group list-group-flush p-0" *ngIf="!loading && !hide">
    <div class="archive-list-item">
      <li class="list-group-item archive" *ngFor="let element of list">
        <div class="dateFiles">
          <div class="dateFiles-date">{{element.date | date: 'd'}} de {{element.date | date: 'MMMM'}} de {{element.date | date: 'yyyy'}}</div>

          <div *ngFor="let files of element.files">
            <div *ngIf="files.mensagemErro">
              <div class="group-error">
                <div class="archive-group-icon">
                <span class="show-error">
                 Erro no arquivo
                </span>
              </div>
              </div>
            </div>
            <div class="archive-group">
              <div class="archive-group-icon">
                <span style="width: 50px">
                  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.2 11.0862V12.0172C14.2 12.2641 14.1052 12.501 13.9364 12.6756C13.7676 12.8502 13.5387 12.9482 13.3 12.9482H6.7C6.46131 12.9482 6.23239 12.8502 6.0636 12.6756C5.89482 12.501 5.8 12.2641 5.8 12.0172V11.0862H1V17.2931H19V11.0862H14.2Z"
                      stroke="#082A4D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M2.49994 1H17.4999M2.49994 8.75863H17.4999H2.49994ZM2.49994 6.17242H17.4999H2.49994ZM2.49994 3.58621H17.4999H2.49994Z"
                      stroke="#082A4D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </div>
              <div class="archive-file">
                <span title="{{element.nomeArquivo}}" class="archive-name">{{ files.nomeArquivo }}</span>
                <span class="archive-size">{{formatBytes(files.tamanhoArquivo)}}</span>
              </div>
            </div>
            <div class="archive-values">
              <div *ngIf="!files.mensagemErro">
                <span class="archive-quantity">Este arquivo contém {{ files.qtdBoletos }} boletos no valor total
                  de</span>
                <span class="archive-value">
                  R$ {{ !files.totalBoletos ? '0,00' : (files.totalBoletos | money) }}
                </span>
              </div>
              <div *ngIf="files.mensagemErro">
                  <button type="button" class="button-cancel" (click)="showModal(files)">
                      Ver erros
                    </button>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item archive" *ngFor="let element of listReturn">
        <div class="dateFiles">
          <div class="dateFiles-date">{{element.date | date: 'd'}} de {{element.date | date: 'MMMM'}} de {{element.date | date: 'yyyy'}}</div>

          <div *ngFor="let files of element.files">
            <div class="archive-group">
              <div class="archive-group-icon">
                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.2 11.0862V12.0172C14.2 12.2641 14.1052 12.501 13.9364 12.6756C13.7676 12.8502 13.5387 12.9482 13.3 12.9482H6.7C6.46131 12.9482 6.23239 12.8502 6.0636 12.6756C5.89482 12.501 5.8 12.2641 5.8 12.0172V11.0862H1V17.2931H19V11.0862H14.2Z"
                    stroke="#082A4D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M2.49994 1H17.4999M2.49994 8.75863H17.4999H2.49994ZM2.49994 6.17242H17.4999H2.49994ZM2.49994 3.58621H17.4999H2.49994Z"
                    stroke="#082A4D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div class="archive-file">
                <div class="archive-values-return">
                  <span style="cursor: pointer; padding-right: 20px" (click)="download(files)">
                    <svg width="25" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.4629 10.0377L10.6839 15.5254L3.905 10.3605M10.6839 0.999075L10.6839 15.5254L10.6839 0.999075Z"
                        stroke="#4D86BF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M1 14.2637V21.0005H20.3684V14.2637" stroke="#4D86BF" stroke-width="1.5" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </div>
                <span class="archive-name">{{ files.nomeArquivo }}</span>
                <span class="archive-size">{{formatBytes(files.tamanhoArquivo)}}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </div>
  </ul>

  <div class="empty-info" *ngIf="hide">
    <fibra-generic-info type="warn" title="Nenhum resultado encontrado"
      message="Tente uma nova pesquisa inserindo novos parâmetros.">
    </fibra-generic-info>
  </div>
  <!-- Loading -->
  <div class="d-flex justify-content-center position" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
<ng-content></ng-content>

<fibra-modal-arquivo-erro #content *ngIf="showModalErro" 
(closeEvent)="closeEvent($event)" 
[filename]="nomeArquivo"
[messageError]="mensagemErro"
[size]="tamanhoArquivo"
></fibra-modal-arquivo-erro>