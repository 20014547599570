import {
  AccountDataShare,
  DebitAccountDataShare,
} from '@src/data/repository/data-share-repository';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as Util from '@src/shared/util-common';
import { isNodeFlagSet } from 'tslint';


@Component({
  selector: 'fibra-slide-account-selector',
  templateUrl: './slide-account-selector.component.html',
  styleUrls: ['./slide-account-selector.component.scss'],
})
export class SlideAccountSelectorComponent implements OnChanges {
  @Input() isBeneficiary = false;
  @Input() isEqualCnpj = false;
  @Input() label: string;
  @Input() data;
  @Input() debitAccount = null;
  @Input() desable;
  @Input() bank: string;
  @Input() ownership: string;
  @Input() destiny: string;
  @Input() accountOrigin;
  @Input() active: number;
  @Input() showButton: boolean = true;
  
  @Output() emitAccount: EventEmitter<any> = new EventEmitter();
  @Output() emitIndex: EventEmitter<any> = new EventEmitter();
  hide;  ;
  isSameBank: boolean;
  isSelectedCount: boolean;
  accountSelected;

  constructor(
    public sharedAccount: AccountDataShare,
    private shareDebit: DebitAccountDataShare
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.hide = Util.isEmpty(this.data);
    this.isSelectedCount = this.shareDebit.dataToShare.value ? true : false
    this.accountSelected = this.shareDebit.dataToShare.value
  }

  ngOnInit(): void {
    this.isSameBank = String(this.bank).toString() === '224' ? true : false;
  }

  mapArrows() {
    let count = 0;
    const arrows = document.querySelectorAll('.hiddenArrows');
    arrows.forEach(
      (el) => {
        el.setAttribute('data-toggle', String(count));
        count++;
      });
    this.hideIfOverflowing('.overflowingElements', '.hiddenArrows');
    return true;
  }

  scrollBy(side, target) {
    const selector = target.closest('.hiddenArrows').getAttribute('data-toggle');
    if (side === 'right') {
      document.querySelectorAll('.overflowingElements')[selector].scrollBy({
        left: 350,
        behavior: 'smooth',
      });
    }
    if (side === 'left') {
      document.querySelectorAll('.overflowingElements')[selector].scrollBy({
        left: -350,
        behavior: 'smooth',
      });
    }
  }

  selectAccount(account, index) {
    this.active = account.num_indice;
    this.emitAccount.emit(account);
    this.emitIndex.emit(index);
    this.shareDebit.setValue(account);
  }

  isOverflowing(overflowingElements): boolean {
    return overflowingElements.clientWidth < overflowingElements.scrollWidth;
  }

  hideIfOverflowing(overflowingElements, hiddenArrows) {
    overflowingElements = document.querySelectorAll(overflowingElements);
    hiddenArrows = document.querySelectorAll(hiddenArrows);

    overflowingElements.forEach(
      (overflowingElement) => {
        this.isOverflowing(overflowingElement)
          ? hiddenArrows.forEach((hiddenArrow) => { hiddenArrow.style.visibility = 'visible'; })
          : hiddenArrows.forEach((hiddenArrow) => { hiddenArrow.style.visibility = 'hidden'; });
      },
    );
  }

  disableAccount(type: string, num_conta: string): boolean {
    //quando uma TED ela só pode ser feita a partir de uma conta corrente ou cheque empresa
    if (this.bank !== '224' && (type.toUpperCase() !== 'CHEQUE EMPRESA' && type.toUpperCase() !== 'CONTA CORRENTE')) {
      return true;
    } else if (this.bank === '224' && this.ownership === 'same') {
      //quando TEF é de mesma titularidade, uma das contas tem que ser do tipo conta corrente ou cheque empresa
      this.destiny;
      if (this.destiny == num_conta) {
        return true;
      }
      var typeAccountToTransfer = '';
      for (let entry of this.sharedAccount.dataToShare.value) {
        if (entry.num_conta == this.destiny.replace('-', '')) {
          typeAccountToTransfer = entry.desc_conta;
          break;
        }
      }
      if (typeAccountToTransfer.toUpperCase() == 'CHEQUE EMPRESA' || typeAccountToTransfer.toUpperCase() == 'CONTA CORRENTE') {
        return false;
      } else {
        if (type.toUpperCase() !== 'CHEQUE EMPRESA' && type.toUpperCase() !== 'CONTA CORRENTE') {
          return true;
        }
      }

    }
    return false;
  }

  showCard(type: string, num_conta: string): boolean {
    
    if(this.debitAccount==num_conta.replace('-',''))
    {
      return false;
    }
    
    //quando uma TED ela só pode ser feita a partir de uma conta corrente ou cheque empresa
    if (this.isSameBank === false && (type.toUpperCase() !== 'CHEQUE EMPRESA' && type.toUpperCase() !== 'CONTA CORRENTE')) {
      return false;
      //quando for TEF para outra titularidade  só pode ser feita a partir de uma conta corrente ou cheque empresa
    } else if (this.isSameBank === true && !this.isEqualCnpj && (type.toUpperCase() !== 'CHEQUE EMPRESA' && type.toUpperCase() !== 'CONTA CORRENTE')) {
      return false
      //quando for TEF para para favoritos não pode reliazar transação para a mesma conta
    } else if (this.isSameBank === true && !this.isEqualCnpj && (num_conta === this.destiny)) {
      return false
      //quando for TEF para mesma Segue as seguintes regras:
    } 
    //else if (this.isSameBank === true && this.ownership === 'same') {
    else if (this.isSameBank === true && this.isEqualCnpj) {

     
      //Não existe transação para beneficiários com conta vinculada
      if (type.toUpperCase() === 'CONTA VINCULADA' && this.isBeneficiary) {
        return false;
      }
      if (this.isSelectedCount === true) {
        if (this.accountSelected.num_conta === num_conta) {
          return true;
          //TEF pode ser Conta corrente para Conta garantida || conta corrente || Cheque empresa
        } else if ((this.accountSelected.desc_conta === 'CONTA CORRENTE')) {
          return true;
          //TEF pode ser Conta empresa p/ Conta garantida || conta corrente || Cheque empresa
        } else if ((this.accountSelected.desc_conta === 'CHEQUE EMPRESA')) {
          return true;
          //TEF pode ser do tipo C.G p/ C.C || Conta empresa
        } else if ((this.accountSelected.desc_conta === 'CONTA GARANTIDA') && (type.toUpperCase() === 'CONTA VINCULADA' || type.toUpperCase() === 'CONTA GARANTIDA')) {
          return false;
          //TEF pode ser do tipo C.V p/ C.C || Conta empresa
          // } else if (this.accountSelected.desc_conta === 'CONTA VINCULADA') {
          //   return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

}
