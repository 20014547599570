import { LimitsDisclaimerUsecase } from '@src/core/usecases/limits-disclaimer/limits-disclaimer.usecase';
import { DisclaimerEnum, DisclaimerModel } from '@src/core/domain/disclaimer/disclaimer.model';
import { GetDisclaimerUsecase } from '@src/core/usecases/disclaimer/disclaimer.usecase';
import { RequestAccountFibra } from '@src/core/domain/transfer/transfer.model';
import { Component, Input, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { AccountDataShare, DebitAccountDataShare } from '@src/data/repository/data-share-repository';
import * as Util from '@src/shared/util-common';
import { GetAccountFibraUsecase } from '@src/core/usecases/transfer/beneficiaries/get-account-fibra-usecases';
import { CpfCnpjValidator } from '@src/shared';
import { RolesService } from '@src/shared/services/roles.service';
import { PixBankDataShare } from '@src/core/usecases/data-share/pix-banco-data-share';
import { PixTransferDataShare } from '@src/core/usecases/data-share/pix-transfer-data-share.service';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { PixBeneficiarioFavoritoDataShare } from '@src/core/usecases/data-share/pix-beneficiario-favorito-data-share.service';
import { AccountPixDataShare } from '@src/core/usecases/data-share/account-pix-data-share.service';
import { mascaraCpfCnpj, mascaraAgenciaConta } from '@src/shared/util-common';
import { Subscription } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { PixGetLimitesDataRepository } from '@src/data/repository/pix/limites/pix-get-limites-repository';
import { PixGetAccountsDataRepository } from '@src/data/repository/pix/accounts/pix-get-accounts-repository';
@Component({
  selector: 'fibra-pix-agenciaconta-transfer-bancofibra',
  templateUrl: './pix-agenciaconta-transfer-bancofibra.component.html',
  styleUrls: ['./pix-agenciaconta-transfer-bancofibra.component.scss'],
})
export class PixAgenciaContaTransferenciaFibra implements OnInit, OnDestroy {
  
  private _subAccountsFibra: Subscription;

  constructor(
    private formBuild: FormBuilder,
    private shareAccount: AccountDataShare,
    private sharePixAccount: AccountPixDataShare,
    private shareCompany: CompanyDataShare,
    private getAccountFibra: GetAccountFibraUsecase,
    private getDisclaimer: GetDisclaimerUsecase,
    private shareDebit: DebitAccountDataShare,
    private calendar: NgbCalendar,
    private role: RolesService,
    private pixBankShare: PixBankDataShare,
    private transferShare: PixTransferDataShare,
    private getBeneficiariesDataRepository: PixGetBeneficiariesDataRepository,
    public beneficiarioShare: PixBeneficiarioFavoritoDataShare,
    private limitesService: PixGetLimitesDataRepository,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private limitDisclaimer: LimitsDisclaimerUsecase,
    public getAccService: PixGetAccountsDataRepository
  ) {
    this.maxDate = { year: new Date().getFullYear() + 1, month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.otherTitle = this.formBuild.group({
      agency: ['', [Validators.required, Validators.min(0), Validators.max(4)]],
      typeAccount: ['', [Validators.required]],
      account: [
        '',
        [Validators.required, Validators.min(0), Validators.max(7)],
      ],
      name: ['', [Validators.required]],
      cpfCnpj: ['', [Validators.required, CpfCnpjValidator]],
    });

    this.transferData = this.formBuild.group({
      ammount: [0.0, [Validators.required]],
      dateTrasfer: ['', [Validators.required]],
      checkRepeat: [''],
      dateRepetir: [''],
      optionTransfer: ['', [Validators.required]],
      description: ['', [Validators.maxLength(40)]],
      purpose: [null],
      banco: {
        cod_banco: ['', [Validators.required]],
        nome_banco: ['', [Validators.required]],
      },
      nome_beneficiario: ['', [Validators.required]],
      num_agencia: ['', [Validators.required]],
      num_conta: ['', [Validators.required]],
      num_cpfcnpj: ['', [Validators.required]],
      tipo_conta: ['', [Validators.required]],
      tipo_operacao: ['', [Validators.required]],
      exibicao: ['', [Validators.required]]
    });

    this.outraTitularidade = this.formBuild.group({
      conta: [null, [Validators.required]]
    });

    this.outraTitularidade.get('conta').valueChanges.pipe(debounceTime(300)).subscribe(this.getAccount);

    this.ownership = '';
    this.loading = false;
    this.minDate = this.calendar.getToday();
  }
  @Input() typeTransfer = 'new';

  public minDate;
  public transferData: FormGroup = new FormGroup({});
  public char = 40;
  public redBorder;
  public beneficiaryData;
  public companyData;
  public ownership;
  public data;
  public transferValue = 0;
  public transferDateTrasfer: any;
  public transferOptionTransfer = '';
  public trasnferDescription = '';
  public accountData;
  public accountDataDest;
  public bank;
  public otherTitle: FormGroup;
  public date: any = new Date();
  public selectedDate: any;
  public loading;
  public saveFavorite;
  public accountFibra;
  public showDateTooltip: boolean;
  public accountOrigin;
  public indexAccount;
  public errorAccoutInput = false;
  public codBeneficiary;
  public disclamer: DisclaimerEnum;
  public disclamerMessages = [];
  public beneficiaryName;
  public beneficiaryCpfCnpj;
  public get beneficiaryAccount(): string {
    let result = (<string>this.outraTitularidade.get('conta').value);

    if (result) {
      result = result.replace(/[^0-9]/g, '').substr(0, 10);
    }

    return result;
  }
  public accountDebitNumber: string;
  public accountDebit;
  public beneficiaryDataName;
  public beneficiaryDataCpfCnpj;
  public debitAccount;
  public isEqualCnpj = true;
  public bodyTransfer;
  public favorito: boolean = false;
  public semAgencia: boolean = false;
  public chequeEmpresaPix: boolean = false;
  public saldoDisponivel: boolean = true;
  public valorChequeEmpresa;
  public contaToDebit;
  public contaSelecionada:any;
  public valorLimiteConta = 0;
  public dataRepetirSelected;
  public radioWeek: boolean = false;
  public checkWeekDay: string;
  public resultWeekOrMonth: string;
  public resultDayofWeek: string;
  public agendamento;
  public showCheckRepeatTransaction: boolean = false;
  public stringDay: string = 'segunda-feira';
  public dateRepeat: any = '';
  public maxDate;
  public startDate: any;

  public errDates: boolean = false;
  public errDateType: string;
  public errDatesRepeat: boolean = false;
  public errDateTypeRepeat: string;
  public outraTitularidade: FormGroup;
  qtyVerify: boolean = false;
  public vlLimAccount = 0;
  public validthirtyDays;
  public labelDateRepeat;
  public validChequeEmpresaDateIsAfterToday: boolean = false;

  public getCpfOrCnpjInputKeyPix = '';
  public mesmaTitularidadeOutraTitularidade: string;
  public accountCPF: string = 'CPF';
  public accountCNPJ: string = 'CNPJ';
  public showDisclaimerWhenOneAccountDebit: boolean = false;
  public changeHolder: boolean = false;
  public showInfoDisclaimer = [];
  public validDisclaimer: boolean = false;
  public ocultDisclaimerInit:boolean = true;
  public typePerson;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  cpfcnpjMask = (value) => Util.cpfcnpjmask(value);
  removeMask = (value) => Util.removeMask(value);

  ngOnInit(): void {
    const main = document.querySelector('.main');

    if (main) {
      main.scrollIntoView();
    }

    this.bank = this.pixBankShare.getValue();
    this.initPage();
    this.shareAccount.dataShared.subscribe((res) => (this.accountData = res));
    this.sharePixAccount.dataShared.subscribe((res) => (this.accountDataDest = res));

    this.removeAccountsWithoutTems();
    
    this.removeAccountsWithoutTems();

    setTimeout(() => {
      this.setBackgroundColor();
    }, 50);
    this.verifyBank();
    this.requestDisclaimer();
  }

  ngOnDestroy(): void {
    if (this._subAccountsFibra) {
      this._subAccountsFibra.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    document.querySelector('.main').scrollIntoView();

  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  initPage() {
    this.shareCompany.dataShared.subscribe((res) => {
      if (res) {
        this.companyData = res.companyList.filter((e) => e.selected)[0];
      }
    });

    var beneficiario = this.beneficiarioShare.getValue();
    if (beneficiario && beneficiario.favorito) {
      this.ownership = beneficiario.outraTitularidade ? 'other' : 'same'
      const bank = {
        cod_banco: beneficiario.chaveInfo.cod_banco_beneficiario,
        nome_banco: beneficiario.chaveInfo.nome_banco_beneficiario
      }
      this.bank = bank;
      this.favorito = true;
      this.beneficiaryData = {
        beneficiario: beneficiario,
        quote: bank
      }
      this.otherTitle.patchValue({
        name: beneficiario.beneficiarioInfo.nome_beneficiario,
        cpfCnpj: beneficiario.beneficiarioInfo.num_cpf_cnpj,
        account: beneficiario.chaveInfo.num_conta_beneficiario,
        agency: beneficiario.chaveInfo.agencia_beneficiario,
        typeAccount: beneficiario.chaveInfo.tipo_conta_beneficiario
      });

      if(beneficiario.beneficiarioInfo.num_cpf_cnpj) {
        this.titularidadePJortitularidadePF(beneficiario.beneficiarioInfo.num_cpf_cnpj);
        beneficiario.beneficiarioInfo.num_cpf_cnpj === this.getPersonForPJ().cpfCnpj ? this.ownership = 'same' : this.ownership = 'other';
      }
    }
  }

  verifyBank() {
    var permissoes = this.role.get();
    if (permissoes.includes('PIX.TRANS_PIX_OTR_TIT')) {
      this.ownership = 'other'
    }
    if (permissoes.includes('PIX.TRANSF_PIX_MSM_TIT')) {
      this.ownership = 'same'
    }
    if (
      this.typeTransfer !== 'favorite' &&
      Util.isEmpty(this.beneficiaryData)
    ) {
      this.beneficiaryData = {
        quote: {
          cod_banco: this.bank.cod_banco,
        },
      };
    }
  }

  changeDescription(value) {
    this.transferData.patchValue({
      description: this.trasnferDescription,
    });
    this.redBorder = value.length;
    this.char = this.char - value.length;
  }

  limitTextarea(valor) {
    const quant = 40;
    const total = valor.length;

    if (total <= quant) {
      this.char = quant - total;
    } else {
      this.char = valor.substr(0, quant);
    }
  }

  changeCpfCnpj(value) {
    const cpfcnpj = value.replace(/\D/gim, '');
    this.otherTitle.patchValue({
      cpfCnpj: cpfcnpj,
    });
  }

  changeAgency(value) {
    this.otherTitle.patchValue({
      agency: value,
    });
  }

  changeAccount(value) {
    this.otherTitle.patchValue({
      account: value,
    });
  }

  changeBeneficiaryName(value) {
    this.otherTitle.patchValue({
      name: value,
    });
  }

  changeTypeAccount(value, tipo) {
    if (tipo === 'tipo_conta') {
      this.otherTitle.patchValue({
        typeAccount: value,
      });
    } else {
      this.changeOptionTransfer(value);
    }
  }

  changeAmmount() {
    if (this.transferValue > 0) {
      this.transferData.patchValue({
        ammount: this.transferValue,
      });
    } else {
      this.toast.callModalMessage(null, null, 'Valor inválido.');
    }
  }

  changeDate(value) {
    let dateToday = new Date();
     this.checkPastDate(value);
    if (value.toString().indexOf('/') > -1) { 
      let dateCorrection = value.split("/");
      value = new NgbDate(parseInt(dateCorrection[2], 10), parseInt(dateCorrection[1], 10), parseInt(dateCorrection[0], 10))
    }
    const data = `${value.year}-${value.month}-${value.day}`;
    this.transferDateTrasfer = data;
    this.date = data;
    this.transferData.patchValue({
      dateTrasfer: this.transferDateTrasfer,
    });

    if(moment(this.date).isAfter(dateToday.toJSON())) {
      this.validChequeEmpresaDateIsAfterToday = true;
    }

    if(!moment(this.date).isAfter(dateToday.toJSON())) {
      this.validChequeEmpresaDateIsAfterToday = false;
    }

    if(this.resultWeekOrMonth == 'mensal') {
      this.dateRepeat = moment(this.date).add(30,'days');
      this.validthirtyDays = this.dateRepeat;
      this.startDate = {year: new Date(this.dateRepeat).getFullYear(), month: new Date(this.dateRepeat).getMonth() + 1, day: new Date(this.dateRepeat).getDate()};
      this.getDateLabel(this.dateRepeat);
      this.errDatesRepeat = false;
      return;
    }
    
    if(this.resultWeekOrMonth == 'semanal') {
      this.dateRepeat = moment(this.date).add(7,'days');
      this.validthirtyDays = this.dateRepeat;
      this.startDate = {year: new Date(this.dateRepeat).getFullYear(), month: new Date(this.dateRepeat).getMonth() + 1, day: new Date(this.dateRepeat).getDate()};
      this.getDateLabel(this.dateRepeat);
      return;
    }
  }

  changeOptionTransfer(value) {
    this.transferOptionTransfer = value;
    this.transferData.patchValue({
      optionTransfer: this.transferOptionTransfer,
    });
  }

  changeOwnership(value) {
    this.ownership = value;
    this.accountOrigin = undefined;
    if (value == "same") {
      this.outraTitularidade.get('conta').reset();
      this.isEqualCnpj = true
    } else {
      this.isEqualCnpj = false
      if(!this.changeHolder) {
        this.accountCPF = 'CPF';
        this.accountCNPJ = 'CNPJ';
      }
    }

    if(this.changeHolder) {
      this.accountCPF = 'CPF';
      this.accountCNPJ = 'CNPJ';
    }
  }

  initialLetters(name) {
    name = name.replace(/\s(de|da|dos|das)\s/g, ' ');
    const initials = name.match(/\b(\w)/gi);
    return (
      initials[0] + (initials[1] !== undefined ? initials[1] : initials[0])
    ).toUpperCase();
  }

  setBackgroundColor() {
    const arrayColor = [
      '#CC092F',
      '#082A4D',
      '#FEF200',
      '#0070AF',
      '#003399',
      '#FF984E',
      '#CE4343',
      '#8A05BE',
      '#F00003',
      '#C4C4C4',
    ];
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        const color = arrayColor[Math.floor(Math.random() * arrayColor.length)];
        const elem = document.getElementById(`background${i}`);
        elem.setAttribute('style', `background: ${color}`);
      }
    }
  }

  public trasnferControl(name: string) {
    return this.transferData.get(name);
  }

  public trasnferControlIsInvalid(name: string) {
    const control = this.trasnferControl(name);
    return control.invalid && control.touched;
  }

  setFavorite() {
    var save = document.getElementById('exampleCheck1')
    if (save['checked'] == true) {
      this.saveFavorite = true;
    } else {
      this.saveFavorite = false;
    }
  }

  getAccount = (account: string) => {
    account = account ? account.replace(/[^0-9]/g, '').substr(0, 10) : account;
    this.indexAccount = -1;
    this.accountFibra = { ...this.accountFibra, num_cpfcnpj: '', nome_beneficiario: '' } as any;
 
    if (account && account === this.accountDebitNumber) {
      this.toast.callModalMessage(null, 'A conta de débito deve ser diferente da conta selecionada para realizar o crédito.', null);
      this.contaSelecionada = (Math.random() * 1000) * -1;
      this.accountDebitNumber = null
      return;
    }
    
    const success = (value) => {
      this.accountOrigin = value.data;
      this.accountFibra = value.data;
      this.titularidadePJortitularidadePF(value.data.num_cpfcnpj);
      this.changeHolder = true;
      Object.keys(value.data).length === 0
        ? (this.errorAccoutInput = true)
        : (this.errorAccoutInput = false);
      this.loading = false;
      this.checkQuantityAccount(this.accountFibra);
    };

    const error = (err) => {
      this.accountFibra = "";
      this.loading = false;

      if (err.status == 429) {
        this.toast.callModalMessage(null, `Serviço indisponível. Tente novamente em alguns minutos.`);
      } else {
        this.toast.callModalMessage(null, 'Conta inválida.');
      }
    };

    this.errorAccoutInput = false;

    if(account && account.length >= 7) {
      this.accountFibra = null;
      this.loading = true;

      if (this._subAccountsFibra) {
        this._subAccountsFibra.unsubscribe();
      }

      this._subAccountsFibra = this.getAccountFibra.execute({ num_conta: account }).pipe(finalize(() => this.loading = false)).subscribe(success, error);
    } else {
      this.accountFibra = null;
    }
  }

  onClose() {
    this.showDateTooltip = false;
  }

  setAccountOrigin(value) {
    this.accountOrigin = value;
  }

  setBlockAccountFibra(index) {
    this.indexAccount = index;
  }

  setAccountDebit(value) { 
    this.getLimitDiscalimer(value.num_conta);  
    this.accountDebitNumber = value.num_conta || '';
    this.titularidadePJortitularidadePF(this.companyData.modality_account);
    this.showDisclaimerWhenOneAccountDebit = true;
    if (value.num_conta === this.beneficiaryAccount) {
      this.toast.callModalMessage(null, 'A conta de débito deve ser diferente da conta selecionada para realizar o crédito.', null);
      this.contaSelecionada = (Math.random() * 1000) * -1;
      return;
    }

    if (value.num_conta !== this.beneficiaryAccount) {
      if (this.typeTransfer !== 'favorite') {
        this.setDebitTransferData(value);
      }
      this.setDebit(value);
    }
  }

  private setDebitTransferData(value) {
    this.contaToDebit = value;
    let cd_banco = this.bank ? this.bank.cod_banco : value.cod_banco
    this.transferData.patchValue({
      banco: {
        cod_banco: value.cod_banco,
        nome_banco: value.nome_banco,
      },
      nome_beneficiario: this.companyData.name,
      num_agencia: value.cod_agencia ? value.cod_agencia : value.num_agencia,
      num_conta: value.num_conta,
      num_cpfcnpj: this.companyData.modality_account,
      tipo_conta: value.desc_conta ? value.desc_conta : value.desc_tipo_conta,
      tipo_operacao: cd_banco === 58616418 ? 'TEF' : 'TED',
      exibicao: value.exibicao
    });

  }

  private setDebit(value) {
    this.shareDebit.setValue({
      banco: {
        cod_banco: 58616418,
        nome_banco: 'Banco Fibra',
      },
      nome_beneficiario: this.companyData.name,
      cod_agencia: value.cod_agencia,
      num_conta: value.num_conta,
      num_cpfcnpj: this.companyData.modality_account,
      desc_conta: value.desc_conta,
      tipo_operacao: this.transferData.get('optionTransfer').value,
    });

    this.accountDebit = {
      banco: {
        cod_banco: 58616418,
        nome_banco: 'Banco Fibra',
      },
      nome_beneficiario: this.companyData.name,
      cod_agencia: value.cod_agencia,
      num_conta: value.num_conta,
      num_cpfcnpj: this.companyData.modality_account,
      desc_conta: value.desc_conta,
      tipo_operacao: this.transferData.get('optionTransfer').value,
    };
  }

  validationInfo() {
    this.transferData.value.dateTrasfer = this.calendar.getToday();
    this.transferData.patchValue({
      dateTrasfer: this.calendar.getToday(),
    });
    if (this.ownership === 'other') {
      if (this.beneficiaryAccount === this.accountDebitNumber) {
        return true;
      }

      if(this.beneficiaryAccount !== this.accountDebitNumber){
        if(
          this.accountFibra &&
          this.accountFibra.banco &&
          this.accountFibra.banco.cod_banco === 224
        ){
          return !(
            this.transferData.get('dateTrasfer').value &&
            this.transferValue > 0 &&
            this.accountFibra &&
            this.accountDebit
          ) || (this.errDates || this.errDatesRepeat);
        }else{
          return true
        }
      }
    }else{
      return !(
        this.transferData.get('dateTrasfer').value &&
        this.accountOrigin !== undefined &&
        this.transferValue > 0 &&
        this.accountDebit
      ) || (this.errDates || this.errDatesRepeat) || this.qtyVerify;
    }
  }

  public otherTitleControl(name: string) {
    return this.otherTitle.get(name);
  }


  goNext() {
    const data = new Date(`${this.transferData.value.dateTrasfer.year}-${this.transferData.value.dateTrasfer.month}-${this.transferData.value.dateTrasfer.day}`);
    moment().locale('pt-br');
    if(typeof this.date == 'undefined') {
        this.date = data;
    }
    const getDateSelected = this.date;
    let checkIfSchedulle = moment(moment()).isBefore(getDateSelected, 'day');
    if(this.showCheckRepeatTransaction) {
      checkIfSchedulle = true;
    }

    this.bodyTransfer = {
      beneficiario: {
        nome: this.ownership == 'other' ? this.accountFibra.nome_beneficiario : this.companyData.name,
        document: this.ownership == 'other' ? this.accountFibra.num_cpfcnpj : this.companyData.modality_account,
        cpf_cnpj_mascara: mascaraCpfCnpj(this.ownership == 'other' ? this.accountFibra.num_cpfcnpj : this.companyData.modality_account),
        banco: this.bank.nome_banco,
        cod_banco: this.bank.cod_ispb,
        agencia: this.ownership == 'other' ? this.accountFibra.num_agencia : this.accountOrigin.cod_agencia,
        conta: this.ownership == 'other' ? this.accountFibra.num_conta : this.accountOrigin.num_conta,
        tipo_conta: this.ownership == 'other' ? this.accountFibra.desc_conta : this.accountOrigin.desc_conta,
        agencia_mascara:  this.beneficiaryData.beneficiario ?  this.beneficiaryData.beneficiario.chaveInfo.agencia_beneficiario_mascara : mascaraAgenciaConta(this.ownership == 'other' ? this.accountFibra.num_agencia : this.accountOrigin.cod_agencia) ,
        conta_mascara: this.beneficiaryData.beneficiario ? this.beneficiaryData.beneficiario.chaveInfo.num_conta_beneficiario_mascara : mascaraAgenciaConta(this.ownership == 'other' ? this.accountFibra.num_conta : this.accountOrigin.num_conta)
      },
      valor: this.transferData.value.ammount,
      data: this.date,
      descricao: this.transferData.value.description,
      favorito: false,
      contaDebito: {
        nome: this.transferData.value.tipo_conta,
        conta: this.transferData.value.num_conta,
        agencia: this.transferData.value.num_agencia,
        exibicao: this.transferData.value.exibicao
      },
      repetir: {
        semanaOuMes: '',
        dias: '',
        repetir:'',
      },
      flg_agendamento: checkIfSchedulle,
      callback: checkIfSchedulle ? 123 : 26,
      pagRetorno: 'transferir-transferFibra'
    }

    if(this.resultWeekOrMonth) {
      this.bodyTransfer.repetir = {
        semanaOuMes: this.resultWeekOrMonth,
        dias: this.resultDayofWeek,
        repetir: this.dateRepeat.toJSON()
      }
    }

    if (this.saveFavorite) {
      const params = {
        nome_beneficiario: this.bodyTransfer.beneficiario.nome,
        nr_cpf_cnpj: this.bodyTransfer.beneficiario.document,
        tipo_chave: "AGENCIACONTA",
        chave: "",
        agencia: this.bodyTransfer.beneficiario.agencia,
        cod_banco: this.bodyTransfer.beneficiario.cod_banco,
        num_conta: this.bodyTransfer.beneficiario.conta,
        nome_banco: this.bodyTransfer.beneficiario.banco,
        tipo_conta: this.bodyTransfer.beneficiario.tipo_conta,
        fl_outra_titularidade: this.ownership == 'same' ? 0 : 1
      }
      this.getBeneficiariesDataRepository.postPixBeneficiariosNovo(params).subscribe((res) => {
      }, (err) => {
      })
    }
    this.transferShare.setValue(this.bodyTransfer);
    if (this.transferData.value.tipo_conta == 'CHEQUE EMPRESA') {
      this.chequeEmpresaPix = this.UsaChequeEmpresa()
      this.bodyTransfer.confirme_linha_credito = this.chequeEmpresaPix;
    }
    if (!this.chequeEmpresaPix && this.saldoDisponivel) {
      localStorage.setItem('view-selecionada', 'transferir-confirmTransfer');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }
    
  }

  public requestDisclaimer() {
    const success = (value: DisclaimerModel) => {
      this.disclamer = value.data;
      this.resolveDisclaimer(this.disclamer);
    };
    const error = (_err: any) => {
      //tratativa de erro
    };
    let params = '';
    if (this.ownership === 'same') {
      params = '1/3/1';
    }
    if (this.ownership === 'other') {
      params = '1/3/0';
    }
    this.getDisclaimer.execute(params).subscribe(success, error);
  }

  public resolveDisclaimer = (value: DisclaimerEnum) => {
    for (const i in value) {
      this.disclamerMessages.push({
        label: value[i].desc_disclaimer,
      });
    }
  };

  public goBack() {
    this.resetValues();
    if (this.favorito) {
      localStorage.setItem('view-selecionada', 'transferir-beneficiario');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    } else {
      localStorage.setItem('view-selecionada', 'transferir-listBancos');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }
  }

  resetValues() {
    this.resultWeekOrMonth = '';
    this.resultDayofWeek = '';
    this.dateRepeat = '';
    this.errDatesRepeat = false;
    this.showCheckRepeatTransaction = false;
    this.validthirtyDays = '';
    this.validChequeEmpresaDateIsAfterToday = false;
    this.showDisclaimerWhenOneAccountDebit = false;
    this.getCpfOrCnpjInputKeyPix = '';
    this.mesmaTitularidadeOutraTitularidade = '';
    this.accountCPF = 'CPF';
    this.accountCNPJ = 'CNPJ';
    this.changeHolder = false;
    this.showInfoDisclaimer = [];
    this.validDisclaimer = false;
    this.ocultDisclaimerInit = true;
  }

  public trocaBanco() {
    localStorage.setItem('view-selecionada', 'transferir-listBancos');
    window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  public closeModalPix(value) {
    if (value == 'sim') {
      localStorage.setItem('view-selecionada', 'transferir-confirmTransfer');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    } else if (value == 'cancelar') {
      this.chequeEmpresaPix = false;
    }
  }

  public UsaChequeEmpresa() {
    this.saldoDisponivel = true;
    let vlr_cheque_disponivel = this.contaToDebit.saldo.vlr_cheque_disponivel;
    const saldoMenosValorBloqueado = Math.round((this.contaToDebit.saldo.vlr_saldo_calculado - this.contaToDebit.saldo.vlr_bloqueado) * 1e2) / 1e2;

    if (this.contaToDebit.saldo.vlr_limite == 0) {
      vlr_cheque_disponivel = 0;
    }

    if (this.bodyTransfer.valor > saldoMenosValorBloqueado && !this.validChequeEmpresaDateIsAfterToday) {
      var valorMenosSaldo = 0;

      if (saldoMenosValorBloqueado < 0) {
        valorMenosSaldo = this.bodyTransfer.valor
      } else {
        valorMenosSaldo = Math.round((this.bodyTransfer.valor - saldoMenosValorBloqueado) * 1e2) / 1e2;
      }

      if (valorMenosSaldo > 0 && vlr_cheque_disponivel < valorMenosSaldo) {
        this.toast.callModalMessage(null, 'Saldo Indisponível!', null);
        this.saldoDisponivel = false;
        return false;
      } else if (valorMenosSaldo == 0) {
        this.saldoDisponivel = true;
        return false;
      } else {
        this.valorChequeEmpresa = valorMenosSaldo;
        this.saldoDisponivel = true;
        return true;
      }
    } else {
      return false;
    }
  }

  erroLimite(value){
    if(value && value.code == 200) {
      this.valorLimiteConta = value.data.vlr_permitido;
      if(value.data.vlr_permitido == 0) {
        this.getLimitesData();
      }
    }else {
      this.toast.callModalMessage(null, 'Houve um erro no retorno do valor do seu limite. tente novamente');
    }
  }

  public getLimitesData() {
    this.limitesService.getPixLimites().subscribe((terms: any) => {
      terms.data.find((p) => {
        p.conta.numero === this.contaToDebit;
        this.vlLimAccount = p.conta.vl_permitido;
        this.valorLimiteConta = this.vlLimAccount;
        return this.valorLimiteConta;
      });
    });
  }

  checkPastDate(ev) {
    moment.locale('pt-br');
    var valueDate: any;
    if (typeof ev === 'object') {
      valueDate = ev.day + '/' + ev.month + '/' + ev.year;
    } else {
      valueDate = ev;
    }

    let dateFormat = moment(valueDate, 'D/M/YYYY', true).format('DD/MM/YYYY');
    let validDate = moment(dateFormat, 'DD/MM/YYYY', true).isValid();
    let conv = moment(dateFormat, 'DD/MM/YYYY').format('YYYY-MM-DD');
    let bfDay = moment(conv).isBefore(moment(), 'day');
    let isBigger = moment(conv).diff(moment(), 'year');

    if (!validDate || bfDay) {
      this.errDates = true;
      this.errDateType = 'Data inválida. Informe uma data igual ou maior que a data atual.';
      return;
    }

    if (!validDate || isBigger > 0) {
      this.errDates = true;
      this.errDateType = 'Data inválida.';
      return;
    }
    this.errDates = false;
  }

  
  repeatTransaction() {
    if(!this.transferData.value.checkRepeat) {
      this.radioWeek = false;
      this.resultWeekOrMonth = 'mensal';
      this.errDatesRepeat = false;
      this.showCheckRepeatTransaction = true;
      this.dateRepeat = moment(this.date).add(30,'days');
      this.startDate = {year: new Date(this.dateRepeat).getFullYear(), month: new Date(this.dateRepeat).getMonth() + 1, day: new Date(this.dateRepeat).getDate()};
      this.getDateLabel(this.dateRepeat);
      this.validthirtyDays = this.dateRepeat;
    }else{    
      this.resultWeekOrMonth = '';
      this.resultDayofWeek = '';
      this.dateRepeat = '';
      this.errDatesRepeat = false;
      this.showCheckRepeatTransaction = false;
      this.validthirtyDays = '';
    }
  }

  radioWeekOrMonth(value) {
    this.radioWeek = false;
    if(value == 'semanal') {
      this.radioWeek = true;
      this.resultWeekOrMonth = value;
      this.resultDayofWeek = 'segunda';
      this.errDatesRepeat = false;
      this.dateRepeat = moment(this.date).add(7,'days');
      this.startDate ={year: new Date(this.dateRepeat).getFullYear(), month: new Date(this.dateRepeat).getMonth() + 1, day: new Date(this.dateRepeat).getDate()};
      this.validthirtyDays = this.dateRepeat;
      this.getDateLabel(this.dateRepeat);
    }else {
      this.radioWeek = false;
      this.resultWeekOrMonth = value;
      this.errDatesRepeat = false;
      this.dateRepeat = moment(this.date).add(30,'days');
      this.startDate ={year: new Date(this.dateRepeat).getFullYear(), month: new Date(this.dateRepeat).getMonth() + 1, day: new Date(this.dateRepeat).getDate()};
      this.validthirtyDays = this.dateRepeat;
      this.getDateLabel(this.dateRepeat);
    }
   }

  radioWeekSelected(value) {
    this.resultDayofWeek = value;
    this.returnStringDays(value);
   }
  
   returnStringDays(day) {
     switch(day) {
      case 'segunda':
        this.stringDay = 'segunda-feira';
        break;
      case 'terca':
        this.stringDay = 'terça-feira';
        break;
      case 'quarta':
        this.stringDay = 'quarta-feira';
        break;
      case 'quinta':
        this.stringDay = 'quinta-feira';
        break;
      case 'sexta':
        this.stringDay = 'sexta-feira';
        break;
      case 'sabado':
        this.stringDay = 'sábado';
        break;
      case 'domingo':
        this.stringDay = 'domingo';
        break;
     }
   }

   public changeDateRepetir(evento) {
    if (evento.toString().indexOf('/') > -1) { 
      let dateCorrection = evento.split("/");
      evento = new NgbDate(parseInt(dateCorrection[2], 10), parseInt(dateCorrection[1], 10), parseInt(dateCorrection[0], 10))
    }
    const data = new Date(`${evento.year}-${evento.month}-${evento.day}`);
    this.dateRepeat = data;
    this.getDateLabel(this.dateRepeat);
    
    const checkIfSchedulle = moment(this.date).isBefore(this.dateRepeat, 'day');
    let isBigger = moment(this.dateRepeat).diff(moment(), 'year');
    let thirtyDay = moment(this.dateRepeat).diff(moment(this.validthirtyDays).format('YYYY-MM-DD'), 'days');

    if (!checkIfSchedulle) {
      this.errDatesRepeat = true;
      this.errDateTypeRepeat = 'Data inválida. Informe uma data maior que a data de transferência.';
      return;
    }

    if (isBigger > 0) {
      this.errDatesRepeat = true;
      this.errDateTypeRepeat = 'Data inválida.';
      return;
    }

    if (thirtyDay < 0 && this.resultWeekOrMonth == 'mensal') {
      this.errDatesRepeat = true;
     this.errDateTypeRepeat = 'Data inválida. Informe uma data maior que 30 dias da data de transferência';
     return;
   }
   
    this.errDatesRepeat = false;
  }

  checkQuantityAccount(evt){
    if(this.ownership === 'same' && evt){
      this.qtyVerify = evt;
      if(evt) this.toast.closeModal(); this.toast.callModalMessage(null, 'A conta de débito deve ser diferente da conta selecionada para realizar o crédito.', null);
    }

    if(this.ownership === 'other'){
      this.shareDebit.dataShared.subscribe(r => {
        if(r.num_conta === evt.num_conta){
          this.toast.callModalMessage(null, 'A conta de débito deve ser diferente da conta selecionada para realizar o crédito.', null);
        }
      })
    }
  }
  
  getDateLabel(date) {
    if (typeof date === 'object') {
      date = date.toJSON();
    }
    let dateLabel = date.split("T");
    dateLabel = dateLabel[0].split("-");
    this.labelDateRepeat = this.labelDateRepeat = `${dateLabel[2]}/${dateLabel[1]}/${dateLabel[0]}`;
  }

  public titularidadePJortitularidadePF(value) {
    console.log(value)
    this.accountCPF = Util.cpfOrcnpj(value);
    this.accountCNPJ = Util.cpfOrcnpj(value);
   
  }

  public getLimitDiscalimer(account) {
    const numberAccount = {
      NumeroConta: account,
      NumeroEmpresa: "",
      IdEmpresa: 0,
      Pix: true
    }
    this.limitDisclaimer.execute(numberAccount).subscribe((result: any) => {
        this.showDisclaimer(result.data);
    }, error => {
      this.erroDisclaimer();
    });
  }
  
    public showDisclaimer(data) {
      this.validDisclaimer = true;
      this.showInfoDisclaimer = data;
    }
  
    public erroDisclaimer() {
      this.validDisclaimer = false;
      this.showInfoDisclaimer = [];
      this.ocultDisclaimerInit = false;
      this.toast.callModalMessage(null, 'Não foi possível exibir os limites. Tente novamente!', '',null, true);
    }

    removeAccountsWithoutTems() {
      this.getAccService.getAccountsWithNoTerms().subscribe(rs => {
        if(rs && rs.data.length > 0) {
          for (const dataWithoutPix of rs.data) {
            const accountWhithoutTerm = this.accountDataDest.findIndex((key: any) => key.num_conta === dataWithoutPix.num_conta);
            if (accountWhithoutTerm !== -1) {
              this.accountDataDest.splice(accountWhithoutTerm, 1);
            }
          }
        }
      })
    }

    public getPersonForPJ() {
      this.typePerson = JSON.parse(sessionStorage.getItem('defaultCompany')); 
      return this.typePerson[0];
    }
}
