import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, SimpleChanges, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CpfCnpjValidator } from '@src/shared';
import { SelectorDataShare } from '@src/data/repository/data-share-repository';

@Component({
  selector: 'fibra-page-charge-edit',
  templateUrl: './charge-edit.component.html',
  styleUrls: ['./charge-edit.component.scss'],
})
export class ChargeEditComponent implements OnInit {
  step1: any;
  step2: any;
  isValidDrawer: boolean;
  isValidAddressDrawer: boolean;
  isValidPersonal: boolean
  isValidAddress: boolean
  isValidInfoTitle: boolean
  isGuarantorSelected: boolean
  stepEdit: any;
  drawerStep: any;
  guarantor: boolean;
  btnGuarantor: boolean;
  discountInvalid: boolean = false;

  constructor(
    public route: Router,
    private formBuild: FormBuilder,
    private shareSelector: SelectorDataShare
  ) {
    this.stepEdit = this.formBuild.group({
      drawer: this.formBuild.group({
        personType: ['F', [Validators.required]],
        cpfCnpj: [
          '',
          [Validators.required, Validators.minLength(14), CpfCnpjValidator],
        ],
        draweeName: ['', Validators.required],
      }),
      drawerAddress: this.formBuild.group({
        cep: ['', [Validators.required, Validators.minLength(9)]],
        street: ['', Validators.required],
        number: ['', Validators.required],
        complement: [''],
        neighborhood: ['', Validators.required],
        city: ['', Validators.required],
      }),
    });
  }

  ngOnInit() {
    this.step1 = JSON.parse(localStorage.getItem('first_step_edit'));
    localStorage.removeItem('first_step');
    if (this.step1.guarantor === 'Sim') {
      this.guarantor = true;
    } 
    else {
      this.guarantor = false
    }

    this.step2 = JSON.parse(localStorage.getItem('second_step_edit'));
    localStorage.removeItem('second_step');

    this.drawerStep = JSON.parse(localStorage.getItem('drawer_step_edit'));
    localStorage.removeItem('drawer_step');
    if (this.drawerStep === null) {
      localStorage.removeItem('drawer_step_edit');
    }
    this.shareSelector.setValue('summary');

    this.verifyGuarantor(this.step1.guarantor)
  }

  getDrawer(value) {
    return this.isValidDrawer = value
  }

  getDrawerAddress(value) {
    return this.isValidAddressDrawer = value
  }

  getPersonal(value) {
    return this.isValidPersonal = value
  }

  getAddress(value) {
    return this.isValidAddress = value
  }

  getInfoTitle(value) {
    return this.isValidInfoTitle = value
  }

  save(value?) {
    this.step1 = JSON.parse(localStorage.getItem('first_step_edit'));
    localStorage.removeItem('first_step_edit');

    this.step2 = JSON.parse(localStorage.getItem('second_step_edit'));
    localStorage.removeItem('second_step_edit');

    this.drawerStep = JSON.parse(localStorage.getItem('drawer_step_edit'));
    localStorage.removeItem('drawer_step_edit');

    if (!localStorage.getItem('drawer_step')) {
      localStorage.setItem('drawer_step', JSON.stringify(this.drawerStep));
    }
    if (!localStorage.getItem('first_step')) {
      localStorage.setItem('first_step', JSON.stringify(this.step1));
    }
    localStorage.setItem('second_step', JSON.stringify(this.step2));

    // tslint:disable-next-line: no-unused-expression
    this.route.navigate(['/summary']);
  }

  verifyGuarantor(value) {
   if (value === 'Sim') {
     this.btnGuarantor = true
   } else {
     this.btnGuarantor = false
     localStorage.removeItem('drawer_step');
     localStorage.removeItem('drawer_step_edit'); 
   }
    return this.isGuarantorSelected = value
  }

  verifyDiscountTitle(value) {
    if(!value){
      this.discountInvalid = true;
    } else {
    this.discountInvalid = false;
  } 
 }


}
