import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fibra-toast-functionality-access',
  templateUrl: './toast-functionality-access.component.html',
  styleUrls: ['./toast-functionality-access.component.scss']
})
export class ToastFunctionalityAccessComponent implements OnInit {
  @Input() color;
  public dataMsgTransaction: any;

  constructor(

  ) {
    this.dataMsgTransaction = {
      title: null,
      msg: null,
      showButtonClose: true,
      showButtonOptions: null,
    };
  }


  ngOnInit() {
    this.color = !this.color ? '#6CBED9' : this.color;
  }

  public callModalMessage(
    title?: string,
    msg?: string,
    buttonOption?: boolean,
    buttonClose?: boolean
  ) {
    this.dataMsgTransaction.msg = msg;
    const message = document.getElementById('message');
    message.classList.add('show');
    if (!this.dataMsgTransaction.showButtonOptions && !buttonClose) {
      setTimeout(() => {
        this.closeModal();
      }, 7000);
    }
  }
  public closeModal() {
    this.dataMsgTransaction.title = null;
    this.dataMsgTransaction.msg = null;
    this.dataMsgTransaction.showButtonClose = null;
    this.dataMsgTransaction.showButtonOptions = null;

    const message = document.getElementById('message');
    message.classList.remove('show');
  }

  public dismissToast = () => {
    const message = document.getElementById('message');
    message.classList.remove('show');
  }

}
