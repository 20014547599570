import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "fibra-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["../../../pages/dda/dda.scss","./disclaimer.component.scss"],
})
export class DisclaimerComponent implements OnInit {
  @Output() goStep: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  close() {
    this.goStep.emit();
  }
}
