import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GetPendingQueryUsecase } from '@src/core/usecases/query/get-pending-query.usecase';
import {
  getOrderedDate,
  getOrderedPayer,
  getOrderedValue,
} from '@src/shared/util-common';
import * as Util from '@src/shared/util-common';


interface Transit {
  dt_envio: number;
  nome_arquivo: string;
  num_agencia: string;
  num_conta: string;
  desc_chave: string;
  qtd_boletos: number;
  vlr_total_boletos: number;
  tam_arquivo_kb: number;
  desc_msg_erro: string;
  cod_status: string;
  desc_status: string;
}

@Component({
  selector: 'fibra-portfolio-pending-list',
  templateUrl: './query-transit.component.html',
  styleUrls: ['./query-transit.component.scss'],
})
export class PortfolioTransitPendingListComponent implements OnInit {
  transit: Transit[];
  filter = new FormControl('');
  getOrderedDate;
  getOrderedPayer;
  getOrderedValue;
  @Input() params;
  hide;

  constructor(private getPendingQueryList: GetPendingQueryUsecase) {
    this.transit = [];
    this.requestQueryList();
  }

  ngOnInit() {
    this.hide = Util.isEmpty(this.transit);
  }

  private requestQueryList() {
    this.getPendingQueryList
      .execute(this.params)
      .subscribe((res) => console.log(res));
  }

  ordered(value) {
    switch (value) {
      case 'date':
        return (this.getOrderedDate = getOrderedDate(this.transit));
      case 'payer':
        return (this.getOrderedPayer = getOrderedPayer(this.transit));
      case 'value':
        return (this.getOrderedValue = getOrderedValue(this.transit));
    }
  }
}

