import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fibra-limit-change-time',
  templateUrl: './limit-change-time.component.html',
  styleUrls: ['./limit-change-time.component.scss']
})
export class LimitChangeTimeComponent implements OnInit, OnChanges, OnDestroy {

  @Input() changeTime: boolean = false;
  @Input() timeNight: number;
  @Input() timeDay: number;
  @Output() exitComponent: EventEmitter<boolean> = new EventEmitter();
  @Output() timeNightDayExport: EventEmitter<{}> = new EventEmitter();
  
  previousTimeDay: number;

  timesList = [];

  constructor() {
    // Content code costructor
  }

  ngOnInit() {
    this.previousTimeDay = this.timeDay;

    this.timesList = [{
      indice: 1,
      name: '20:00',
      value: '2000',
      modality_account: 'noturno',
      selected: this.selectedTime('2000', String(this.timeNight)),
    }, {
      indice: 2,
      name: '22:00',
      value: '2200',
      modality_account: 'noturno',
      selected: this.selectedTime('2200', String(this.timeNight)),
    }];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['changeTime'] && !changes['changeTime'].currentValue && typeof this.previousTimeDay !== 'undefined'){
      this.timeDay = this.previousTimeDay
    }
  }

  ngOnDestroy(): void {
    this.exitComponent.emit(false);
  }

  timeSelected(evt){
    this.timeDay = this.adjustTime(evt);
    this.timeNightDayExport.emit({tDay: this.formatHour(this.timeDay), tNight: this.formatHour(evt)})
  }

  adjustTime(sTime: number){
    return Number(sTime) === 2000 ? 1959 : 2159;
  }

  formatHour(hr){
    return hr ? hr.toString().split("", 4)[0] +""+ hr.toString().split("", 4)[1] + ":" + hr.toString().split("", 4)[2] +""+ hr.toString().split("", 4)[3] : '';
  }

  selectedTime(time1, time2): boolean{
    if(time1 === time2){
      return (true);
    }
    return (false)
  }

}
