<div class="content-parcela">
    <div class="voltar-content">
        <span><img src="/assets/png/seta-left.png" (click)="back()" /></span>
        <span class="voltar" (click)="back()">Voltar</span>
        <span class="capital-texto">Capital de giro</span>
        <span class="contrato">
            {{numContract}}
        </span>
    </div>
    <div class="tabs-list fibra-div-tab-list">
        <fibra-tabs-list class="fibra-tab-list" [options]="[
         { idOption: 'tudo', option: 'Tudo' },
         { idOption: 'pagos', option: 'Pagos' },
         { idOption: 'avencer', option: 'À vencer' },
         { idOption: 'vencidas', option: 'Vencidas' }]" (itemTab)="tabsSelect($event.idOption)"></fibra-tabs-list>
    </div>
    <div class="row">
        <div class="col-md-11">
            <div class="parcela-content">
                <div *ngIf="loading" class="loading-content">
                    <div class="text-center loading">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Carregando dados...</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="hasData" class="parcela-header">
                    <div class="col-header">N° da Parcela</div>
                    <div class="col-header">
                        <div class="col-children">Data do Vencimento</div>
                    </div>
                    <div class="col-header">Principal</div>
                    <div class="col-header">Valor à pagar</div>
                    <div class="col-header">Valor Pago</div>
                    <div class="col-header">
                        <div class="col-children">Data do pagamento</div>
                    </div>
                    <div class="col-header">Situação</div>
                </div>

                <div *ngIf="!loading && !hasData && !hasError" class="no-info-to-show">
                    <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Não há informações para serem exibidas"></fibra-generic-info>
                </div>
                <div class="empty-info no-info-to-show" *ngIf="hasError">
                    <fibra-generic-info type="error" title="Ocorreu um erro" message="Não foi possível carregar suas operações. Tente novamente mais tarde."></fibra-generic-info>
                </div>


                <div class="parcela-content-body" *ngIf="hasData">


                    <div>
                        <span class="dateRef">Data de referência: {{ dateReferenceToShow | date: 'dd/MM/yyyy' }}</span>
                    </div>

                    <table *ngIf="hasData" class="table margin-bottom-75">
                        <tbody *ngFor="let item of parcelaList; let i = index">
                            <tr data-toggle="collapse" attr.data-target="#collapse_{{i}}" class="accordion-toggle">
                                <td class="col-value">{{ item.num_parcela }}</td>
                                <td class="col-value">
                                    {{ item.dt_vencto | date : 'dd/MM/yyyy' }}
                                </td>
                                <td class="col-value "><span class="padding-right-5">{{ item.valor_principal | currency: 'BRL'}} </span></td>
                                <td class="col-value">{{ item.valor_a_pagar | currency: 'BRL' }}</td>
                                <td *ngIf="actualTab!='vencido'" class="col-value">{{ item.valor_pago | currency: 'BRL' }}</td>
                                <td *ngIf="actualTab==='vencido'" class="col-value" style="text-align:justify;">-</td>

                                <td *ngIf="!item.dt_pagto" class="col-value" style="text-align:justify;">
                                    -
                                </td>
                                <td *ngIf="item.dt_pagto" class="col-value">
                                    {{ item.dt_pagto | date : 'dd/MM/yyyy' }}
                                </td>
                                <td class="col-value">
                                    <div style="display: flex; justify-content: space-around;">
                                        <span class="margin-right">
                                            <img src="/assets/svg/status-{{ item.status }}.svg" />
                                        </span> {{ item.cod_situacao }}
                                        <span data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><img class="margin-left-10"
                                                src="/assets/svg/arrow_down.svg" /> </span>
                                    </div>

                                </td>
                            </tr>
                            <tr id="collapse_{{i}}" class="accordian-body collapse background-color-2">
                                <td colspan="7" class="parcela-td">

                                    <div class="row">
                                        <div class="col-sm-2 margin-left-3p">
                                            <p class="parcela-item-title">Correção monetária</p>
                                            <p class="parcela-item-value">{{ item.valor_principal_cm | currency: 'BRL' }}</p>
                                        </div>
                                        <div class="col-sm-2">
                                            <p class="parcela-item-title">Juros</p>
                                            <p class="parcela-item-value">{{ item.valor_juros | currency: 'BRL' }}</p>
                                        </div>
                                        <div class="col-sm-2">
                                            <p class="parcela-item-title">Juros de mora</p>
                                            <p class="parcela-item-value">{{ item.valor_mora | currency: 'BRL'}}</p>
                                        </div>
                                        <div class="col-sm-2">
                                            <p class="parcela-item-title">Iof</p>
                                            <p class="parcela-item-value">{{ item.valor_iof | currency: 'BRL'}}</p>
                                        </div>
                                        <div class="col-sm-2">
                                            <p class="parcela-item-title">Multa moratória</p>
                                            <p class="parcela-item-value">{{ item.valor_multa | currency: 'BRL' }}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="hasData" class="barra-inferior">
                <span class="alinhamento-barra-inferior valores-totais">
                    Valores<br /> totais
                </span>
                <span class="alinhamento-barra-inferior">
                    <span class="image-rotate"></span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Principal<br />
                    <span class="valor">{{ totalPrincipal | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Correção monetária <br />
                    <span class="valor">{{ totalPrincipalCorrigido | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Juros <br />
                    <span class="valor">{{ totalJuros | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Juros de mora <br />
                    <span class="valor">{{ totalJurosDeMora | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    Multa moratória <br />
                    <span class="valor">{{ totalMultaMoratoria | currency:'BRL'}}</span>
                </span>
                <span class="item-component-1 alinhamento-barra-inferior">
                    IOF <br />
                    <span class="valor" *ngIf="actualTab=='vencido' || actualTab=='tudo'" >
                        {{ totalIof | currency:'BRL'}}
                    </span>
                <span class="valor" *ngIf="actualTab!=='vencido' && actualTab!=='tudo'">
                        -
                    </span>
                </span>
                <span *ngIf="actualTab==='vencido'" class="item-component-1 alinhamento-barra-inferior">
                    Total à pagar <br />
                    <span class="valor">{{ totalAPagarSeAbaVencida | currency:'BRL'}}</span>
                </span>
                <span *ngIf="actualTab!=='vencido'" class="item-component-1 alinhamento-barra-inferior">
                    Total à pagar <br />
                    <span class="valor">{{ totalAPagar | currency:'BRL'}}</span>
                </span>
            </div>
        </div>
        <div *ngIf="hasData" class="texto-informativo">
            *Valores sujeitos a confirmação no momento da liquidação.
        </div>
    </div>
</div>