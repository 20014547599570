<div
  class="{{ customClass }} content-drop dropdown"
  [style.color]="textColor"
  [style.backgroundColor]="backgroundColor"
  [ngClass]="{ disabled: isDisabled, shadown: !removeShadown }"
  (click)="toggle()"
>
  <ng-container *ngIf="prefix" [ngTemplateOutlet]="prefix"> </ng-container>
  <div
    class="inside-content dropdown"
    [ngClass]="{ placeholder: placeholder && !label }"
  >
    <span [style.paddingRight.px]="prefix ? 10 : 0" class="dropdown"
      >{{ prefixText ? prefixText + " " : "" }}{{ label || placeholder }}</span
    >
    <img
      [ngStyle]="
        hasOpened
          ? { transform: 'rotate(180deg)' }
          : { transform: 'rotate(0deg)' }
      "
      style="margin-left: 5px"
      src="/assets/svg/{{ iconName || 'arrow_down_blue' }}.svg"
      alt="image-seta-baixo"
      class="dropdown"
    />
  </div>
</div>

<div
  *ngIf="hasOpened"
  class="{{ customClass }} items dropdown"
  [style.color]="textColor"
>
  <div class="scroll-dropdown">
    <div
      *ngFor="let item of options"
      class="item dropdown"
      (click)="select(item); this.toggle()"
      [style.padding]=""
    >
      <span class="dropdown"
        >{{ prefixText ? prefixText + " " : ""
        }}{{ item.name | titlecase }}</span
      >
    </div>
  </div>
</div>
