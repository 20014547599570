import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'fibra-opportunity-seguros',
  templateUrl: './opportunity-seguros.component.html',
  styleUrls: ['./opportunity-seguros.component.scss'],
})
export class OpportunitySegurosComponent implements OnInit {
  
  @ViewChild('interested', {static: false}) interested: ElementRef; 
  @ViewChild('product', {static: false}) product: ElementRef; 
  public soluctions = [
    {
      title: 'Seguro Garantia',
      img: '/assets/opportunity/svg/icon-coin.svg',
      description: [
        '✓ Judicial trabalhista, cível e fiscal',
        '✓ Compra e Venda de Energia',
        '✓ Performance Bond',
        '✓ Adiantamento de Pagamento'
      ],
    },
    {
      title: 'Benefícios',
      img: '/assets/opportunity/svg/icon-user.svg',
      description: [
        '✓ Vida',
        '✓ Saúde/Odonto',
        '✓ Para Você'
      ],
    },
    {
      title: 'Patrimonial',
      img: '/assets/opportunity/svg/icon-house.svg',
      description: [
        '✓ Riscos de Engenharia',
        '✓ Riscos Nomeados / Operacionais'
      ],
    },
    {
      title: 'Seguro Cyber',
      img: '/assets/opportunity/svg/icon-monitor.svg',
      description: [
        '✓ Riscos Cibernéticos'
      ],
    },
    {
      title: 'Seguro Aluguel',
      img: '/assets/opportunity/svg/icon-courthouse.svg',
      description: [
        '✓ Fiança Locatícia'
      ]
    },
    {
      title: 'Seguro de crédito ',
      img: '/assets/opportunity/svg/icon-wallet-add.svg',
      description: [
        '✓ Crédito interno',
        '✓ Crédito à exportação'
      ]
    }
  ]
  constructor() {}

  ngOnInit() {}

  isInterested() {
    const targetElement = this.interested.nativeElement
    targetElement.scrollIntoView({behavior: "smooth"})
  }

  isProduct() {
    const targetElement = this.product.nativeElement
    targetElement.scrollIntoView({behavior: "smooth"})
  }
     
}
