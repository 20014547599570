import { ArchiveRequest } from '@src/core/domain/charge/archive.model';
import { UploadArchiveRepositoryMapper } from '@src/data/repository/charge/archive-repository/upload-archive-repository.mapper';
import { UrlAssignedRepositoryMapper } from '@src/data/repository/charge/archive-repository/urlassigned-repository.mapper';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  ArchiveModel,
  ListArchivesModel,
} from '@src/core/domain/charge/archive.model';

// entitys
import { ListArchivesEntity } from '@src/data/repository/charge/archive-repository/archive.entity';

import { ArchiveModelRepository } from '@src/core/repositories/charge/archive.repository';
import { catchError, flatMap, map, retry } from 'rxjs/operators';

// mappers
import { DownloadArchiveRepositoryMapper } from '@src/data/repository/charge/archive-repository/archive-download-repository.mapper';
import { ArchiveListRepositoryMapper } from '@src/data/repository/charge/archive-repository/archive-list-repository.mapper';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { handleError } from '@src/shared/util-common';

@Injectable({
  providedIn: 'root',
})
export class ArchiveRepository extends ArchiveModelRepository {
  
  
  DOWNLOAD = new DownloadArchiveRepositoryMapper();
  ARCHIVE_LIST = new ArchiveListRepositoryMapper();
  URL_ASSIGNED = new UrlAssignedRepositoryMapper();
  UPLOAD = new UploadArchiveRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getUrlAssigned(obj): Observable<ArchiveModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const endpoint = `${environment.URLBase}v1/rotaAssinada/remessa/Arquivos/upload`;
    return this.http
      .post<any>(endpoint,  obj,httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.URL_ASSIGNED.mapFrom), catchError(handleError));
  }

  getDownloadArchive(obj): Observable<ArchiveModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };

    if (obj.documentacao) {
      // tslint:disable-next-line: no-shadowed-variable
      const endpoint = `${environment.URLBase}v1/rotaassinada/downloaddocumentacao`;
      return this.http
        .get<any>(endpoint, httpOptions)
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.DOWNLOAD.mapFrom), catchError(handleError));
    }

    const endpoint = `${environment.URLBase}v1/transacoes/listarHistoricoRemessa/historico/download`;
    return this.http
      .get<any>(endpoint, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.DOWNLOAD.mapFrom), catchError(handleError));
  }

  getAmazonArchiveDownload(obj): Observable<ArchiveModel> {
    const endpoint: string = obj;
    return this.http
      .get<any>(endpoint, {
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(handleError));
  }

  getDownloadArchiveList(obj): Observable<ArchiveModel> {
    // confirmar parametros
    const httpOptions = {
      headers: getHeader(),
      params: {
        dataInicial: obj.initialDate,
        dataFinal: obj.finalDate,
      },
    };
    return this.http
      .put<any>(`${environment.URLBase}amazon/v1/urlassinada`, httpOptions)
      .pipe(flatMap((item) => item))
      .pipe(map(this.DOWNLOAD.mapFrom), catchError(handleError));
  }

  getArchiveList(obj): Observable<ListArchivesModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    const params = { ...obj };
    
    return this.http
      .post<ListArchivesEntity>(
        `${environment.URLBase}v1/Historico/arquivoRemessa`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.ARCHIVE_LIST.mapFrom));
  }

  getArchiveListReturn(obj): Observable<ListArchivesModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...obj };

    return this.http
      .post<ListArchivesEntity>(
        `${environment.URLBase}v1/Historico/arquivoRetorno`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.ARCHIVE_LIST.mapFrom));
  }

  downloadArchiveRetorno(request: any){
    const httpOptions = {
      headers: getHeader(),
    };

    const params = { ...request };

    return this.http
      .post<ListArchivesEntity>(
        `${environment.URLBase}v1/Historico/arquivoRetorno/download`,
        params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.ARCHIVE_LIST.mapFrom), catchError(handleError));
  }

  putArchiveUpload(obj: ArchiveRequest): Observable<any> {
    const path = obj.data;
    return this.http
      .put<any>(path, obj.arquivo, {
        responseType: 'blob' as 'json',
      })
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.UPLOAD.mapFrom), catchError(handleError));
  }

  getArchiveReturnDownload(arquivo: string): Observable<ListArchivesModel> {
    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<ListArchivesEntity>(`${environment.URLBase}v1/RotaAssinada/Retorno/Arquivos/Download?arquivo=${arquivo}`,httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.ARCHIVE_LIST.mapFrom), catchError(handleError));
  }
}
