<div id="transferInfo" class="box-content" [formGroup]="transferData">
  <div class="content-same">
    <div class="arrow" (click)="goBack()" style="cursor: pointer;">
      <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar">
        Voltar</span>
    </div>
    <ng-container *ngIf="favorito">
      <div class="titulo">
        <div class="div-redondo-1"><span>1</span></div>
        <span class="titulo-text">Dados do beneficiário </span>
      </div>

      <div class="divisor-2"></div>

      <div class="info-beneficiaries">
        <div class="info-box">
          <div class="info-person">
            <div class="initial-letters" [id]="'background'">
              {{ initialLetters(beneficiaryData?.beneficiario.beneficiarioInfo.nome_beneficiario) }}
            </div>
            <div class="text">
              <label data-hj-suppress>
                {{beneficiaryData?.beneficiario.beneficiarioInfo.nome_beneficiario | titlecase}} 
              </label>
              <span>CNPJ:
                {{beneficiaryData?.beneficiario.beneficiarioInfo.num_cpf_cnpj_mascarado}}
              </span>
            </div>
          </div>
          <div class="divisor-3"></div>
          <div class="info-bank">
            <div *ngIf="beneficiaryData.beneficiario.chaveInfo.cod_compe_beneficiario !== 224"  class="initial-letters" [id]="'background'">
              {{ initialLetters(beneficiaryData?.beneficiario.chaveInfo.nome_banco_beneficiario) }} 
            </div>

            <div *ngIf="beneficiaryData.beneficiario.chaveInfo.cod_compe_beneficiario === 224" class="initial-letters" [id]="'background'">
              <img class="fibra-owner" src="assets/png/icon-owner-fibra.png" alt="Logotipo Banco Fibra" />
            </div>
            <div class="text">
              <label>
                {{ beneficiaryData.beneficiario.chaveInfo.cod_compe_beneficiario }}
                {{ beneficiaryData?.quote.nome_banco }}
              </label>
              <span>
                AG: {{ beneficiaryData?.beneficiario.chaveInfo.agencia_beneficiario_mascara }} | CC:
                {{ beneficiaryData?.beneficiario.chaveInfo.num_conta_beneficiario_mascara | account }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!favorito">
      <div class="trocar_banco">
        <div class="bank-info">
          <div class="initial-letters" [id]="'background'" *ngIf="bank && bank?.cod_banco !== 58616418">
            {{ initialLetters(bank?.nome_banco) }}
          </div>
          <span class="nome-banco">
            {{ bank?.cod_banco }} {{ bank?.nome_banco }}
          </span>  
        </div>

        <button (click)="trocaBanco()" class="btn-troca">
          <img src="/assets/svg/transf.svg" alt="Ion" />
          Trocar instituição
        </button>
      </div>

      <div class="dados_banco">
        <div class="dados_banco-agencia">
          <label>Agência</label>
          <input type="text" class="form-control" placeholder="0000" (keyup)="changeAgency($event.target.value)"
          oninput="this.value=this.value.replace(/[^\d]/,'')" maxlength="4" [disabled]="semAgencia" id="inputAgencia" onlynumber/>
            
          <div class="check-agency">
            <input type="checkbox" class="check-agency-input" id="checkAgency" (click)="setSemAgencia()" />
            <label class="check-agency-label" for="checkAgency">Não possui agência</label>
          </div>
        </div>
        

        <div class="dados_banco-radio">
          <fibra-radiobutton [label]="'Tipo de conta'" [option]="[
              { option: 'Corrente', value: 'CC' },
              { option: 'Poupança', value: 'PP' }
            ]" (selected)="changeTypeAccount($event, 'tipo_conta')"></fibra-radiobutton>
        </div>

        <div class="dados_numero-banco">
          <label style="margin-bottom: 8px">Número da conta com o dígito sem hífen
          </label>
          <input type="text" class="form-control"  oninput="this.value=this.value.replace(/[^\d]/,'')"  placeholder="0000000000" (keyup)="changeAccount($event.target.value)"
             maxlength="10" />
        </div>
      </div>

      <div class="divisor-2"></div>

      <div class="dado-1">
        <div class="titulo">
          <div class="div-redondo-1"><span>1</span></div>
          <span class="titulo-text">Dados do beneficiário </span>
        </div>

        <div class="outra_titularidade">
          <div class="titularidade" [class.active]="ownership === 'same'" (click)="changeOwnership('same')"
            *fibraHasRole="'PIX.TRANSF_PIX_MSM_TIT_BC'">
            <span class="text_span-titularidade">Mesma titularidade</span>
          </div>
          <div class="titularidade" [class.active]="ownership === 'other'" (click)="changeOwnership('other')"
            *fibraHasRole="'PIX.TRANSF_PIX_OTR_TIT_BC'">
            <span class="text_span-titularidade">Outra titularidade</span>
          </div>
        </div>

        <div *ngIf="ownership === 'same'">
          <div class="dados_beneficiario">
            <div class="dados_beneficiario-nome">
              <label>Nome do Beneficiário</label>
              <span class="data-hj-suppress">
                {{ companyData?.name || ' ' }}
              </span> 
            </div>

            <div class="dados_beneficiario-nome">
              <label class="">CNPJ/CPF</label>
              <span class="data-hj-suppress">
                {{removeMask(companyData?.modality_account) | cpfcnpj}} 
              </span>
            </div>
          </div>
        </div>

        <div class="other" *ngIf="ownership === 'other'">
          <div class="dados_banco-beneficiario">
            <label>Nome do beneficiário</label>
            <input type="text" class="form-control" placeholder="Nome completo/Razão social" maxlength="50"
              [ngModel]="beneficiaryName" [ngModelOptions]="{ standalone: true }"
              (keyup)="changeBeneficiaryName($event.target.value)" />
          </div>
          <div class="dados_banco-cpf">
            <label>CNPJ/CPF</label>
            <input type="text" class="form-control" id="cpfCnpj" placeholder="000.000.000/0001-00"
              [ngModel]="beneficiaryCpfCnpj" [ngModelOptions]="{ standalone: true }"
              (keyup)="changeCpfCnpj($event.target.value)" [textMask]="{
                mask: cpfcnpjMask,
                placeholderChar: '_',
                guide: false,
                modelClean: true
              }" />
          </div>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" (click)="setFavorite()" />
          <label class="form-check-label" for="exampleCheck1">Salvar dados da conta</label>
        </div>
      </div>
    </ng-container>

    <div class="divisor-2"></div>

    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1"><span>2</span></div>
        <span class="titulo-text">Valor e data</span>
      </div>

      <div class="valor_data">
        <fibra-form-control label="Valor a ser transferido">
          <div class="control-data valor_data-valores">
            <input class="form-control" formControlName="ammount" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false, allowZero: false }" [(ngModel)]="transferValue"
              (keyup)="changeAmmount()" [ngClass]="{ 'valor-zero': !transferValue }" maxlength="18" min="0" />
          </div>
        </fibra-form-control>

        <fibra-form-control label="Data de transferência">

          <fibra-datepicker placement="top" formControlName="dateTrasfer"
             (dateSelected)="changeDate($event)">
          </fibra-datepicker>
        </fibra-form-control>
        <div class="warning-div-sched" *ngIf="errDates" style="margin-left: 8px;">
          <img class="info-img" src="/assets/svg/icon-info-red.svg" style="margin-right: 8px;" alt="icon info red">
        </div>
        <span *ngIf="errDates" class="warning-font">{{ errDateType }}</span>
      
      </div>
    </div>

    <div class="dado-1 marginTop30 ">
      <input type="checkbox" class="checkbox" name="checkbox" formControlName="checkRepeat" (click)="repeatTransaction()" />
      <span class="check-font"> Repetir transferência</span>
      <div *ngIf="transferData.value.checkRepeat"  class="row marginTop20">
        <div class="col-2 maxWidth11">
          <input
            class="pix-radio"
            checked
            type="radio"
            name="pix-radio"
            value="mensal"
            (click)="radioWeekOrMonth('mensal')"
          />
          <label class="payment-txt-small"> Mensal</label>
        </div>

        <div class="col-3">
          <input
            class="pix-radio"
            type="radio"
            name="pix-radio"
            value="semanal"
            (click)="radioWeekOrMonth('semanal')"
          />
          <label class="payment-txt-small"> Semanal<br/>
          </label>
        </div>
    </div>
    </div>

    <div *ngIf="radioWeek && transferData.value.checkRepeat" class="dado-1 displayFlex marginTop20">
          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            checked
            name="semana-radio"
            (click)="radioWeekSelected('segunda')"
          />
            <span class="payment-txt-small"> Segunda-feira</span>  
          </div>

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('terca')"
          />
            <span class="payment-txt-small"> Terça-feira</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('quarta')"
          />
            <span class="payment-txt-small"> Quarta-feira</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('quinta')"
          />
            <span class="payment-txt-small"> Quinta-feira</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('sexta')"
          />
            <span class="payment-txt-small"> Sexta-feira</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('sabado')"
          />
            <span class="payment-txt-small"> Sábado</span>
          </div> 

          <div class="col-2 margin-radio">
            <input
            class="pix-radio"
            type="radio"
            name="semana-radio"
            (click)="radioWeekSelected('domingo')"
          />
            <span class="payment-txt-small">Domingo</span>
          </div> 
    </div>

    <div *ngIf="transferData.value.checkRepeat && showCheckRepeatTransaction" class="dado-1 displayFlex marginTop30">
      <span class="titulo-text marginRight8" style="line-height: 35px;"> Repetir até</span>

      <fibra-datepicker [maxDate]="maxDate" placement="top" [minDate] = "minDate" [date]="startDate" (dateSelected)="changeDateRepetir($event)">
      </fibra-datepicker>

      <div *ngIf="this.errDatesRepeat" class="warning-div-sched" style="margin-left: 8px;">
        <img class="info-img" src="/assets/svg/icon-info-red.svg" style="margin-right: 8px;" alt="icon info red">
      </div>
      <span *ngIf="this.errDatesRepeat" class="warning-font">{{errDateTypeRepeat}}</span>
    </div>
    <div *ngIf="dateRepeat && !this.errDatesRepeat" class="row dado-1 marginTop20" style="width: 700px;">
      <div class="col-12">
           <div *ngIf="resultWeekOrMonth === 'semanal'" class="rest-cred">A transferência será realizada {{resultDayofWeek === 'sabado' || resultDayofWeek === 'domingo' ? 'todo ' : 'toda '}} {{ stringDay }} a partir do dia {{ date | date :'shortDate' }}
               até o dia {{ labelDateRepeat }}, mediante a disponibilidade de saldo na conta.
           </div>
           <div *ngIf="resultWeekOrMonth === 'mensal'" class="rest-cred"> A transferência será realizada a cada 30 dias a partir do dia  {{ date | date :'shortDate' }}
               até o dia {{ labelDateRepeat }}, mediante a disponibilidade de saldo na conta.
           </div>
      </div>
   </div>

    <div class="divisor-2"></div>

    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1"><span>3 </span></div>
        <span class="titulo-text">Descrição</span>
      </div>

      <div class="finalidade_descricao">
        <div class="finalidade_descricao-descricao">
          <div class="infos">
            <label>Descrição</label>
            <span class="counter" [ngClass]="{ redCounter: char == 0 }">{{ char }} caracteres</span>
          </div>
          <textarea placeholder="" formControlName="description" [ngClass]="" [(ngModel)]="trasnferDescription" (keyup)="
              changeDescription($event.target.value);
              limitTextarea($event.target.value)
            " [maxLength]="40"></textarea>
        </div>
      </div>
    </div>

    <div class="divisor-2"></div>

    <div class="dado-1">
      <div class="titulo">
        <div class="div-redondo-1">
          <span>4</span>
        </div>
        <span class="titulo-text">Conta para débito</span>
      </div>

      <div class="valor_transf">
        <label>Valor a ser transferido</label>
        <span>{{ transferValue | currency: 'BRL' }}</span>
      </div>
      <fibra-slide-account-selector-pix [label]="'Selecione a conta a ser debitada'" [data]="accountData"
        (emitAccount)="setAccountDebit($event)" [desable]="indexAccount" [bank]="this.beneficiaryData.quote.cod_banco"
        [ownership]="this.ownership" [destiny]="beneficiaryData?.quote.num_conta | account"
        [accountOrigin]="accountOrigin" [isBeneficiary]="false" [debitAccount]="debitAccount"
        [isEqualCnpj]="isEqualCnpj" (emitLimite)="erroLimite($event)"></fibra-slide-account-selector-pix>
    </div>
    <div class="limits-button-continue">
      <div class="text_btn">
        <div class="disclaimer-flex" *ngIf="validDisclaimer">
          <span class="limit-disclaimer" *ngIf="ownership === 'same' && showDisclaimerWhenOneAccountDebit">
              <p class="title_limits">Transação Pix para Mesma Titularidade</p>
              <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[0].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
              <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[0].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
              <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[0].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
              <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[0].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
          </span>
          <span class="limit-disclaimer" *ngIf="ownership === 'other' && accountCNPJ == 'CNPJ' && showDisclaimerWhenOneAccountDebit">
              <p class="title_limits">Transação Pix para Outra Titularidade PJ</p>
              <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[1].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
              <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[1].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
              <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[1].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
              <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[1].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
          </span>
  
          <span class="limit-disclaimer" *ngIf="ownership === 'other' && accountCPF == 'CPF' && showDisclaimerWhenOneAccountDebit">
            <p class="title_limits">Transação Pix para Outra Titularidade PF</p>
            <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[2].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[2].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[2].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
            <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[2].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
          </span>
        </div>
      </div>
      <div class="disclaimer-flex" *ngIf="!validDisclaimer && !ocultDisclaimerInit">
        <span class="limit-disclaimer">
         <p>Não foi possível exibir os limites. Tente novamente!</p>
        </span>
       </div>
      <div class="button">
        <button type="button" class="btn btn-primary btn-continuar" (click)="goNext()" [disabled]="validationInfo()">
          Continuar
        </button>
      </div>
    </div>
  </div>
  <fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>
</div>

<fibra-modal-cheque-empresa-pix *ngIf="chequeEmpresaPix" (closeEvent)="closeModalPix($event)" [valor]="valorChequeEmpresa" [conta]="this.contaToDebit?.exibicao"></fibra-modal-cheque-empresa-pix>