import {Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { GetCompanyDefaultUsecases } from '@src/core/usecases/company/get-companyDefault.usecase';
import { SessionObjectDataShare } from '@src/core/usecases/data-share/session-object-data-share.service';
import { TokenTypeModel } from '@src/core/domain/token/tokenType.model';
import { GetTokenUsecase } from '@src/core/usecases/token/get-token-usecases';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { environment } from '@src/environments/environment';
import {ProfileAccessProvider} from '@src/data/repository/perfilamento/profile.repository';
import { PostNotificationUseCases } from '@src/core/usecases/notification/post-notification.usecase';


@Component({
  selector: 'fibra-page-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit, OnDestroy {
  acquiredToken: boolean;
  signed: boolean;
  deviceType: number = 1;

  public sessionObject: Array<any> = [];

  private _subAcquireTokenSuccess: Subscription;

  constructor(
    private router: Router,
    private companyDataShare: CompanyDataShare,
    private broadcastService: BroadcastService,
    private getCompanyDefault: GetCompanyDefaultUsecases,
    private authService: MsalService,
    public getToken: GetTokenUsecase,
    public setSession: SessionObjectDataShare,
    public analytics: AngularFireAnalytics,
    private accessProvider: ProfileAccessProvider,
    private setTokenNotification: PostNotificationUseCases
  ) { }

  ngOnInit() {    
    this.showCompanyModal();
    this.msalLogin();
  }

  ngOnDestroy(): void {
    if (this._subAcquireTokenSuccess) {
      this._subAcquireTokenSuccess.unsubscribe();
    }
  }

  private msalLogin = () => {
    this.broadcastService.subscribe('msal:loginSuccess', (response) => {
      this.signed = !!this.authService.getAccount();
    });

    this._subAcquireTokenSuccess = this.broadcastService.subscribe('msal:acquireTokenSuccess', (response) => {
      const sessiontoken: any = response.idToken.rawIdToken || response.accessToken;
      sessionStorage.setItem('accessToken', sessiontoken);
      localStorage.setItem('username', response.idToken.name);
      localStorage.setItem('useremail', response.account.idToken.email);
      this.analytics.setUserId(response.account.idToken.email);
      this.analytics.setUserProperties({ env: environment.env, canal: 'web' });
      const userRoles = response.idToken.claims.userRoles || [];

      console.log('userRoles',userRoles);
      
      if(response.idToken.claims.userRoles.includes("Intbanking.Simulacao") && sessionStorage.getItem('simulacao') == null){
        sessionStorage.setItem('canImpersonate', 'true');
        this.router.navigate(['/impersonate']);
      } else {
        this.callGeToken(true);
      }
    });
  }

  private requestSuccess = (response: TokenTypeModel) => {
    //TODO: PALEATIVO, REMOVER DEPOIS
    var mobile = this.isResponsive();
    
    sessionStorage.setItem('x-ibpj-session', response.data.sessionToken);
    this.companyDataShare.setSelect(true);
    const profile = this.authService.getAccount();
    this.sessionObject.push(profile);
    this.setSession.setSessionobject(this.sessionObject);

    const interactionId = sessionStorage.getItem('fibra-openfinance-session-interactionId');

    if (interactionId) {
      this.router.navigate(['openbanking-paymentrequest']);
    } else {
      this.router.navigate(['/dashboard']);
    }

    this.setNotificationToken();
    this.accessProvider.getAccessUser();
  }

  private requestFail = (err) => {
    this.authService.logout();
  }

  private callGeToken = (boll: boolean) => {
    if (boll) {
      try {
        localStorage.setItem('loggedUser', 'true');
        this.getToken
          .execute()
          .subscribe(this.requestSuccess, this.requestFail);
      } catch {
        localStorage.setItem('loggedUser', 'false');
        this.authService.loginRedirect();
      }
    } else {
      localStorage.setItem('loggedUser', 'false');
      this.authService.loginRedirect();
    }
  }

  private showCompanyModal = () => {
    const showModal = sessionStorage.getItem('showAskCompanyDefault');
    if (!showModal) {
      this.configDefault();
    }
  }

  /**
   * @description
   * This call SSO after pres login button
   * @todo use (!) on signed to redirect to next view or login JWT.
   */
  public login = () => this.callGeToken(this.acquiredToken);

  /**
   * @description
   * This get default company to define modal default company on dashboard
   */
  private configDefault = () => {
    const success = (res) => {
      if (Object.keys(res.data).length !== 0) {
        this.sessionObject.unshift(res.data);
        sessionStorage.setItem('showAskCompanyDefault', JSON.stringify(true));
        return;
      }
      sessionStorage.setItem('showAskCompanyDefault', JSON.stringify(false));
    };
    const error = (err) => {
      sessionStorage.setItem('showAskCompanyDefault', JSON.stringify(false));
    };

    this.getCompanyDefault.execute().subscribe(success, error);
  }
  
  public isResponsive = () => {
    let width = window.innerWidth <= 768;
  
    if (width) {
      return true;
    }
    return false;
  };

  setNotificationToken() {
    this.setTokenNotification.execute(this.deviceType).subscribe(
      this.returnRequestSuccess,
      this.returnRequestError
      
    );
  }

  returnRequestSuccess = (value) => {
    sessionStorage.setItem('tokenNotification', JSON.stringify(value.data));
  }

  returnRequestError = (error) => {
    console.log(error);
  }
}
