import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GetInfoTitleQueryUsecase } from '@src/core/usecases/query/get-info-title-query.usecase';
import * as models from '@src/data/repository/data-share-repository';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fibra-query-transit-detail',
  templateUrl: './query-transit-detail.component.html',
  styleUrls: ['./query-transit-detail.component.scss'],
})
export class QueryTransitDetailComponent implements OnInit {
  @Input() titleData: any;

  loading;
  optionsTab;
  titleInfoList;
  info;
  @Output() emitBack: EventEmitter<any>;
  @Input() titleInformation: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private getInfoTitle: GetInfoTitleQueryUsecase,
  ) {
    this.emitBack = new EventEmitter();
    this.optionsTab = models.optionQueryDetail;

    this.info = 'sacado';
  }

  ngOnInit() {
    this.requestQueryInfoTitle(this.titleData);
  }

  private requestQueryInfoTitle(param) {
    this.subscriptions.push(this.getInfoTitle
      .executeTransitDetail(
        {
          numero_carteira: null,
          codigo_agencia: null,
          nosso_numero: null,
          seu_numero: param.seu_numero,
          nosso_numero_correspondente: null,
          codigo_banco_correspondente: null,
          codigo_cliente: null
        }
      )
      .subscribe(
        this.requestSuccess,
        this.requestError,
        this.requestComplete
      ));
  }

  private requestSuccess = (value: any) => {
    if (value && value.data)
      this.titleInfoList = value.data;

    this.loading = false;
  };

  private requestError = (err) => {
    this.loading = false;
    return console.log(err);
  };

  private requestComplete = () => {
    this.loading = false;
  };

  public navigateBack() {
    this.emitBack.emit(true);
  }

  public selectTab(event) {
    this.info = event;
  }

  public cepMask(value){
    if(value){
      return(
        value.replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d/, '$1')
      )
    }
  }

  public cpfCnpjMask(value){

    if (value && this.titleInfoList.tp_pessoa_sacado  === 'F') {
      return (
        value.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      )
    } else {
      return (
        // value.replace(/^0/, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      )
    }
  }
}
