import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageInterface } from "../modal-conclusao-adesao/interfaces/image-interfaces";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";
import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";
import { toNumber } from "@src/shared/util-common";

@Component({
  selector: "fibra-modal-alteracao-adesao",
  templateUrl: "./modal-alteracao-adesao.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
})
export class ModalAlteracaoAdesaoComponent implements OnInit {
  @ViewChild("content", { static: true }) content: HTMLElement;

  @Input() inclusaoFilial: boolean = false;
  @Input() contemFilial: boolean = false;
  @Input() totalAprovadores: number = 0;
  @Input() totalMasters: number = 0;
  @Input() recusar: boolean = false;
  @Input() exclusao: boolean = false;
  @Input() batch: boolean = false;
  @Input() tela: string = '';

  @Input() filiais: DdaBranchModel[] = [];
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  imgTypes = {
    error: {
      type: "error",
      src: "assets/png/icon-error.png",
      forecolor: "fg-red",
    },
    success: {
      type: "success",
      src: "assets/png/icon-accreddited.png",
      forecolor: "fg-green",
    },
    warning: {
      type: "warning",
      src: "assets/png/icon-pending.png",
      forecolor: "fg-warn",
    },
  }

  title: string = "";
  message: string = "";
  imgSrc: string = "";
  typeMessage: "warn" | "success" | "error" = "success";

  constructor(private modalService: NgbModal, private router: Router, private cpfCnpjPipe: CpfCnpjPipe,) { }

  ngOnInit() {
    this.openModal(this.content);
    this.setModalData();
  }

  setModalData(): void {
    switch (this.tela) {
      case 'aprovacao-masters':
        if (this.batch) {
          this.title = "Operação realizada com sucesso.";
          this.message = "";
          this.imgSrc = this.imgTypes.success.src;
          this.typeMessage = "success";
        }
        else {
          if (this.recusar || this.totalMasters == this.totalAprovadores) {
            this.title = "Operação realizada com sucesso.";
            this.message = "";
            this.imgSrc = this.imgTypes.success.src;
            this.typeMessage = "success";
          }
          else {
            this.title = "Operação realizada com sucesso.";
            this.message = "Enviando para aprovação dos masters da sua empresa.";
            this.imgSrc = this.imgTypes.warning.src;
            this.typeMessage = "warn";
          }
        }
        break;
      default:
        if (!this.recusar) {
          if (this.totalMasters === 1 && !this.exclusao) {
            this.title = "Adesão ao DDA aprovada.";
            this.message = `CNPJ da filial está sendo validado.
              Após a validação, todos os boletos de cobrança registrados serão
              disponibilizados em até 1 dia útil.`;
            this.imgSrc = this.imgTypes.success.src;
            this.typeMessage = "success";
          } else if (this.totalMasters === 1 && this.exclusao) {
            this.title = "Exclusão realizada com sucesso.";
            this.message = "Os pagamentos agendados não serão mais liquidados.";
            this.imgSrc = this.imgTypes.success.src;
            this.typeMessage = "success";
          }

          if (this.totalMasters > 1 && !this.exclusao && !this.inclusaoFilial && !this.contemFilial) {
            this.title = "Adesão ao DDA pendente.";
            this.message = `Adesão ao DDA pendente. Necessário a aprovação dos demais masters da sua empresa.`;
            this.imgSrc = this.imgTypes.warning.src;
            this.typeMessage = "warn";
          }

          if (this.totalMasters > 1 && this.inclusaoFilial && this.contemFilial) {
            this.title = "Alteração da adesão solicitada.";
            this.message = `Enviado para aprovação dos demais masters da sua empresa.`;
            this.imgSrc = this.imgTypes.warning.src;
            this.typeMessage = "warn";
          }

          if (this.totalMasters > 1 && this.exclusao) {
            this.title = "Exclusão pendente.";
            this.message = `Exclusão de filial pendente. Necessário a aprovação dos demais masters da sua empresa.`;
            this.imgSrc = this.imgTypes.warning.src;
            this.typeMessage = "warn";
          }
        } else {
          if (this.contemFilial) {
            this.title = "Operação realizada com sucesso.";
            this.message = "Enviando para aprovação dos masters da sua empresa.";
            this.imgSrc = this.imgTypes.warning.src;
            this.typeMessage = "warn";
          } else {
            this.title = "Adesão ao DDA recusada.";
            this.message = "";
            this.imgSrc = this.imgTypes.error.src;
            this.typeMessage = "error";
          }
        }
    }
  }

  obterInscricao(filial: DdaBranchModel): string {
    return this.cpfCnpjPipe.transform(toNumber(filial.cnpjInscricao + filial.identificacaoFilial));
  }

  openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "modal-xxl",
      backdrop: "static",
    });
  }

  fechar(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/dda"]);
  }

  irParaHome(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/dashboard/"]);
  }
}
