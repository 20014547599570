import {ActivatedRoute, Router} from '@angular/router';
import {DropdownList} from '@src/core/domain/business-models';
import * as UserModel from '@src/core/domain/admin-user/admin-user.model';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {GetGroupsListUseCase} from '@src/core/usecases/admin-user/get-groups.usecase';
import {GetUserOfficeUseCase} from '@src/core/usecases/admin-user/get-user-office.usecase';
import {GetDepartmentsUseCase} from '@src/core/usecases/admin-user/get-departments.usecase';
import {GetUserDetailsUseCase} from '@src/core/usecases/admin-user/get-user-details.usecase';
import {GetAllAccountTypeUsecase} from '@src/core/usecases/account/get-all-accountTypes.usecase';
import {AccordionComponent} from '@src/presentation/web/components/accordion/accordion.component';
import {GetFunctionalitiesUseCase} from '@src/core/usecases/admin-user/get-functionalities.usecase';
import {GetFunctionalitiesAccountUseCase} from '@src/core/usecases/admin-user/get-functionalities-account.usecase';
import {GetFunctionalitiesNotAccountUseCase} from '@src/core/usecases/admin-user/get-functionalities-not-account.usecase';
import {PostValidaDadosUseCase} from '@src/core/usecases/admin-user/post-valida-dados.usecase';
import {ToastErrorMessageComponent} from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import * as Utils from '@src/shared/util-common';
import {toTitleCase} from 'codelyzer/util/utils';
import * as moment from 'moment';
import * as $ from 'jquery';
import {consoleDev} from '@src/shared/util-common';
import { map, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'fibra-register-admin',
  templateUrl: './register-admin.component.html',
  styleUrls: ['./register-admin.component.scss'],
})
export class RegisterAdminPageComponent implements OnInit, AfterViewInit {
  constructor(
    private getDepartments: GetDepartmentsUseCase,
    private getFunctionalities: GetFunctionalitiesUseCase,
    private getUserDetails: GetUserDetailsUseCase,
    private getUserOffice: GetUserOfficeUseCase,
    private router: Router,
    private route: ActivatedRoute,
    private calendar: NgbCalendar,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private getFunctionalitiesAccount: GetFunctionalitiesAccountUseCase,
    private getFunctionalitiesNotAccount: GetFunctionalitiesNotAccountUseCase,
    private getAdminGroupList: GetGroupsListUseCase,
    private postValidaDados: PostValidaDadosUseCase
  ) {}
  public step;
  public stepEdit;
  public date;
  public stepFlow = 'Individual';
  public maxStep = 3;
  public currentStep = 1;
  public minDate: NgbDate = new NgbDate(1900, 1, 1);
  public maxDate: NgbDate;
  public newAccount: any;
  public listAccount: any;
  public arrayAccount: any;
  public typeColorToast: string;
  public typeAccount: number;
  public qtdEmpty = 0;
  public qtdError = 0;
  public boolValidateStep1 = false;
  public arrayAccountPayload: Array<UserModel.AdminUserAccount> = [];
  public arrayNotAccountPayload: Array<UserModel.AdminUserAccountPermissoes> = [];
  public arrayFunctionalities: any;
  public arrayFunctionalitiesAccount: any;
  public arrayFunctionalitiesNotAccount: any;
  public showModalValidation = false;
  public optionsAccount: DropdownList;
  public apiCallback = 11;
  public loadDepartment = false;
  public loadOffice = false;
  public isEditMode = false;
  public userDetails: any;
  public accountsCheckbox: Array<any> = [];
  // public arrTypeDocuments = [
  //   { name: 'RG', value: 1 },
  //   { name: 'CNH', value: 2 },
  //   { name: 'RNE', value: 3 },
  //   { name: 'Carteira de Identidade Funcional', value: 4 },
  //   { name: 'Carteira Profissional', value: 5 },
  // ];
  public arrDepartment = [];
  public arrCargo = [];
  public payload: UserModel.UserRequestModel;

  public dataPersonal: UserModel.PersonalUserRequestModel;

  public dataUser: any;
  public listGroup: Array<any> = [];
  public addedGroups: Array<any> = [];
  public countCheckedGroups = 0;
  public selectedGroup: any;
  public buttonEnabled = false;

  public emailDuplicado = false;
  public cpfDuplicado = false;
  public celularDuplicado = false;

  private _loadingFinished = new Subject<void>();

  private _loading: boolean = false;
  public set loading(value: boolean) {
    if (this._loading && !value) {
      this._loadingFinished.next();
      this._loadingFinished.complete();
    }

    this._loading = value;
  }

  public get loading(): boolean {
    return this._loading;
  }

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  @ViewChild(AccordionComponent, null) accordion: AccordionComponent;

  public static formatValue(value, desc): string {
    return `${value} - ${toTitleCase(desc)}`;
  }

  static showBtnImport(accountNumber) {
    $('#row-edit-' + accountNumber).addClass('row-hide');
    $('#btn-radio-' + accountNumber).addClass('row-hide');
    $('#row-import-' + accountNumber).removeClass('row-hide');
  }

  static enableBtnEditClose(accountNumber) {
    $('#row-edit' + accountNumber).addClass('row-hide');
    $('#row-edit-close' + accountNumber).removeClass('row-hide');
  }

  static disableBtnEditClose(accountNumber) {
    $('#row-edit' + accountNumber).removeClass('row-hide');
    $('#row-edit-close' + accountNumber).addClass('row-hide');
  }

  static showBtnEdit(accountNumber) {
    $('#row-import-' + accountNumber).addClass('row-hide');
    $('#row-edit-' + accountNumber).removeClass('row-hide');
    $('#btn-radio-' + accountNumber).removeClass('row-hide');
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    this._loadingFinished.subscribe(() => this.validateEmptyInputs());
  }

  private async init() {
    this.loading = true;

    await this.listFunctionalitiesAccount();
    this.listDepartments();
    this.listOffices();
    this.listAccount = [];
    this.arrayAccount = [];
    this.optionsAccount = [];
    this.clearDataPersonal();    
    this.listFunctionalitiesNotAccount();
    this.maxDate = this.calendar.getToday();
    this.requestAccount();
    this.requestGroupUserList();

    this.route.params.subscribe((params) => {
      this.userDetails = {
        edit: params.editar,
        details: params.details ? JSON.parse(params.details) : null,
      };
      this.isEditMode = params.editar;
      this.maxStep = params.editar ? 2 : 3;
      if (params.editar) {
        this.apiCallback = 12;
      }
    });

    if (this.loadDepartment && this.loadOffice && !this.isEditMode) {
      this.loading = false;
    }

    this.step = [
      {
        step: 1,
        name: 'Informações do usuário',
      },
      {
        step: 2,
        name: 'Tipo de permissionamento',
      },
      {
        step: 3,
        name: 'Contas e permissões ou grupos',
      },
    ];

    this.stepEdit = [
      {
        step: 1,
        name: 'Informações do usuário',
      },
      {
        step: 2,
        name: 'Contas e permissões',
      },
    ];
    this.initialDate();
  }

  public loadDetails() {
    if (this.loadDepartment && this.loadOffice && this.userDetails.details) {
      this.getDetails(this.userDetails.details.cod_pessoa);
    }
  }

  getDetails = (id) => {
    const params = {
      cod_pessoa: id,
    };

    this.getUserDetails.execute(params).subscribe(
      (res) => {
        if (res && res.data) {
          this.userDetails = res;
          this.mountUserDetails(res);
        }
      },
      (err) => {
        consoleDev('err', err);
      }
    );
  }

  private initialDate = () => {
    const today = new Date();
    const year = Number(today.getFullYear()) - 18;
    const month = Number(today.getMonth()) + 1;
    const day = Number(today.getDate());
    if (!this.isEditMode) {
      this.date = null
      this.maxDate = new NgbDate(year, month, day);
    }
  }

  private mountUserDetails(user) {
    this.dataUser = user;
    this.dataPersonal.cod_pessoa = user.data.pessoa.cod_pessoa;
    this.dataPersonal.nm_pessoa = user.data.pessoa.nome_pessoa;
    this.dataPersonal.cod_cargo = user.data.pessoa.cod_cargo;
    this.dataPersonal.cod_departamento = user.data.pessoa.cod_departamento;
    // this.dataPersonal.tipo_documento = user.data.pessoa.tipo_documento;
    //this.dataPersonal.desc_orgao_emissor = user.data.pessoa.desc_orgao_emissor;
    this.dataPersonal.desc_email = user.data.pessoa.email;
    this.dataPersonal.dt_nascimento = user.data.pessoa.dt_nascimento;
    let nascimento = (<string>user.data.pessoa.dt_nascimento).split(' ');
    nascimento = nascimento[0].split('-');

    let yearBorn = +(nascimento[0]);
    let monthBorn = +(nascimento[1]);
    let dayBorn = +(nascimento[2]);
    
    for (let i = 0; i < user.data.pessoa.celular.length; i++) {
      if (i === 0) { this.dataPersonal.num_celular += '+'; }
      if (i === 2) { this.dataPersonal.num_celular += ' ('; }
      if (i === 4) { this.dataPersonal.num_celular += ') '; }
      if (i === 4) { this.dataPersonal.num_celular += ') '; }
      if (i === 9) { this.dataPersonal.num_celular += '-'; }
      this.dataPersonal.num_celular += user.data.pessoa.celular.substr(i, 1);
    }

    for (let i = 0; i < user.data.pessoa.num_cpf_cnpj.length; i++) {
      this.dataPersonal.num_cpf += user.data.pessoa.num_cpf_cnpj.substr(i, 1);
    }

    this.mountListAccount();
    this.mountNotAccount();
    this.loading = false;

    if (this.isEditMode) {
      this.changeDate(new NgbDate(yearBorn, monthBorn, dayBorn))
    }
  }

  public mountCpf(cpf) {
    return (cpf.substring(0, 3) + '.' + cpf.substring(3, 3) + '.' + cpf.substring(6, 3) + '-' + cpf.substring(7, 2));
  }

  private listDepartments() {
    this.getDepartments
      .execute()
      .subscribe(this.requestDepartmentsSuccess, this.requestError);
  }

  private listOffices() {
    this.getUserOffice
      .execute()
      .subscribe(this.requestOfficesSuccess, this.requestError);
  }

  private listFunctionalitiesAccount(): Promise<boolean> {
    const obs = new Subject<boolean>();

    this.getFunctionalitiesAccount
      .execute()
      .pipe(finalize(() => {
        obs.next(true);
        obs.complete();
      }))
      .subscribe(this.requestFunctionalitiesAccountSuccess);

    return obs.toPromise();
  }

  private listFunctionalitiesNotAccount() {
    this.getFunctionalitiesNotAccount
      .execute()
      .subscribe(
        this.requestFunctionalitiesNotAccountSuccess,
        this.requestError
      );
  }

  public cpfMask = (value) => {
    return Utils.formatCPFMask(value);
  }

  public cpMask = (value) => {
    return Utils.formatCPMask(value);
  }

  public cnhMask = value => {
    return Utils.formatCnhMask(value);
  }

  public lettersNumbersMask = value => {
    return Utils.formatLettersNumbersMask(value);
  }

  public rneMask = value => {
    return Utils.formatRneMask(value);
  }

  public emitterMask = (value) => {
    return Utils.formatEmitterMask(value);
  }

  public idMask = (value) => {
    return Utils.formatIdMask(value);
  }

  public mobileMask = (value) => {
    return Utils.formatMobileInternacionalMask(value);
  }

  public onlyNumbers(event) {
    return (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.keyCode === 45 ||
      event.charCode === 46
    );
  }

  public removeMask(mask) {
    return (mask.replace(/\D/g, ''));
  }

  public nextStep() {
    if (this.currentStep < this.maxStep) {
      this.currentStep++;
      if (this.currentStep === this.maxStep) {
        this.requestAccount();
      }
      if (this.stepFlow === 'Grupo' && this.listGroup.length === 0) {
        this.toast.callModalMessage(
          null,
          'Não existe grupo cadastrado para essa empresa.',
          null,
          false,
          true
        );
      }
    }
  }

  public prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    } else {
      this.router.navigate(['/admin-user']);
    }
  }

  public requestAccount(): void {
    this.getAllAccountTypes
      .execute()
      .subscribe(this.requestSuccess, this.requestError);
  }

  private requestError = (err) => {
    if ((err && err.status === 401) || (err && err.status === 0)) {
      this.router.navigate(['/']);
    }
    this.loading = false;
  }

  private requestSuccess = (value) => {
    this.optionsAccount = [];
    this.arrayAccount = [];
    value.data.map((e, i) => {
      this.optionsAccount.push({
        name: RegisterAdminPageComponent.formatValue(e.num_conta, e.exibicao || e.desc_conta),
        value: e.num_conta,
        selected: e.selecionado,
      });
      if (e.selecionado) {
        this.typeAccount = e.num_conta;
      }
      this.arrayAccount.push({
        account: e,
        functionalities: this.arrayFunctionalitiesAccount,
      });
    });
    this.loading = false;
  }

  public mountListAccount() {
    if (this.isEditMode) {
      this.dataUser.data.contas.forEach((conta) => {
        this.listAccount.push({
          account: {
            num_conta: conta.conta,
            desc_conta: conta.desc_conta,
            exibicao: conta.exibicao
          },
          functionalities: this.getFunctionalitiesChecked(conta.conta, conta.desc_conta, this.arrayFunctionalitiesAccount, conta.funcionalidades),
        });
      });
    }
  }

  public mountNotAccount() {
    let checked = true;
    let functionalityId: number;
    let operationId: number;
    let params: UserModel.AdminUserNotAccount;
    this.dataUser.data.permissoes_nao_atreladas.forEach((notAccount) => {
      checked = true;
      functionalityId = notAccount.codFuncionalidade;
      notAccount.operacao.forEach((op) => {
        operationId = op.cod_operacao;
        params = { checked, operacao_id: operationId, funcionalidade_id: functionalityId };
        this.changeRuleNotAccount(params);
      });
    });
  }

  public getFunctionalitiesChecked(numConta, tipo_conta, arrayFunctionalities, arrayFunctionalitiesChecked) {
    const params: UserModel.AdminUserFunctionalitiesChecked = {};
    const newArrayFunctionalitiesChecked = [];

    arrayFunctionalities.forEach((functionality) => {
      if (functionality.cod_funcionalidade != 8 || tipo_conta == 'CHEQUE EMPRESA' || tipo_conta == 'CONTA CORRENTE') {
        newArrayFunctionalitiesChecked.push({
          cod_funcionalidade: functionality.cod_funcionalidade,
          desc_funcionalidade: functionality.desc_funcionalidade,
          operacoes: functionality.operacoes,
        });
      }
    });
    
    newArrayFunctionalitiesChecked.forEach((newFunc) => {
      newFunc.operacoes.forEach((newOp) => {
        arrayFunctionalitiesChecked.forEach((checkFunc) => {
          if (newFunc.cod_funcionalidade === checkFunc.cod_funcionalidade) {
            checkFunc.operacao.forEach((operacao) => {
              if (newOp.cod_operacao === operacao.cod_operacao) {
                newOp.status = true;
                params.checked = true;
                params.operacao_id = newOp.cod_operacao;
                params.collapse_id = numConta;
                params.acessoPadrao = false; // TODO: Esse valor deve vim do serviço!
                params.funcionalidade_id = newFunc.cod_funcionalidade;
                this.changeRule(params);
              }
            });
          }
        });
      });
    });
    return newArrayFunctionalitiesChecked;
  }

  private requestDepartmentsSuccess = (value) => {
    this.arrDepartment = [];
    value.data.map((e, i) => {
      this.arrDepartment.push({
        name: e.nm_departamento,
        value: e.cod_departamento,
      });
    });
    this.loadDepartment = true;
    this.loadDetails();
  }

  private requestOfficesSuccess = (value) => {
    this.arrCargo = [];
    value.data.map((e, i) => {
      this.arrCargo.push({
        name: e.nm_cargo,
        value: e.cod_cargo,
      });
    });
    this.loadOffice = true;
    this.loadDetails();
  }

  private requestFunctionalitiesAccountSuccess = (value) => {
    this.arrayFunctionalitiesAccount = [];

    value.data.map((e, i) => {
      this.arrayFunctionalitiesAccount.push({
        cod_funcionalidade: e.cod_funcionalidade,
        desc_funcionalidade: e.desc_funcionalidade,
        operacoes: this.mountOperations(e.operacoes),
      });
    });
  }

  private requestFunctionalitiesNotAccountSuccess = (value) => {
    this.arrayFunctionalitiesNotAccount = [];
    value.data.map((e, i) => {
      this.arrayFunctionalitiesNotAccount.push({
        cod_funcionalidade: e.cod_funcionalidade,
        desc_funcionalidade: e.desc_funcionalidade,
        operacoes: this.mountOperations(e.operacoes),
      });
    });
  }

  private mountOperations(operators) {
    const newOperacoes = [];
    operators.forEach((op) => {
      newOperacoes.push({
        cod_operacao: op.cod_operacao,
        desc_operacao: op.desc_operacao,
        status: false,
      });
    });
    return newOperacoes;
  }

  public addAccount() {
    let acessoPadrao = false;
    
    if (!this.findAccount()) {
      this.arrayAccount.forEach(account => {
        if (account.account.desc_conta != 'CONTA CORRENTE' && account.account.desc_conta != 'CHEQUE EMPRESA') {
          account.functionalities = account.functionalities.filter(f => f.cod_funcionalidade != 8);
        }

        if (account.account.num_conta === this.newAccount) {
          this.listAccount.push(account);
          if (account.account.num_conta === this.typeAccount) {
            acessoPadrao = true;
          }
          this.arrayAccountPayload.push({
            numero_conta: account.account.num_conta,
            acesso_padrao: acessoPadrao,
            permissoes: [],
          });
        }
      });
    }
  }

  private findAccount() {
    let qtdAccount = 0;
    this.listAccount.forEach((accounts) => {
      if (accounts.account.num_conta === this.newAccount) {
        qtdAccount++;
      }
    });
    return qtdAccount > 0;
  }

  public setDefaultAccount = (numConta, event) => {
    const checked = event.target.checked;
    if (checked) {
      this.arrayAccountPayload.forEach((account, a) => {
        account.acesso_padrao = account.numero_conta === numConta;
      });
    }
  }

  public setAccount(event) {
    const checked = event.target.checked;
    const numConta = event.target.value;

    if (checked) {
      this.accountsCheckbox.push({ num_conta: numConta });
    } else {
      this.accountsCheckbox.forEach((account, a) => {
        if (account.numConta === numConta) {
          this.accountsCheckbox.splice(a, 1);
        }
      });
    }
  }

  public delAccount(accountsCheckbox) {
    accountsCheckbox.forEach((item) => {
      this.listAccount.forEach((accounts, i) => {
        if (accounts.account.num_conta === item.num_conta) {
          this.listAccount.splice(i, 1);
        }
      });
      this.arrayAccountPayload.forEach((accounts, a) => {
        if (accounts.numero_conta === item.num_conta) {
          this.arrayAccountPayload.splice(a, 1);
        }
      });
    });
    this.accountsCheckbox = [];
  }

  public validateCPF(value) {
    if (this.boolValidateStep1 && !this.qtdEmpty) {
      const cpf = value.replace(/[^0-9\/]/g, '');
      if (cpf.length === 11) {
        let sum;
        let mod;
        sum = 0;
        if (cpf === '00000000000') {
          this.qtdError++;
          return false;
        }
        for (let i = 1; i <= 9; i++) { sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i); }
        mod = (sum * 10) % 11;
        if ((mod === 10) || (mod === 11)) {  mod = 0; }
        if (mod !== parseInt(cpf.substring(9, 10)) ) {
          this.qtdError++;
          return false;
        }

        sum = 0;
        for (let i = 1; i <= 10; i++) { sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i); }
        mod = (sum * 10) % 11;

        if ((mod === 10) || (mod === 11)) {  mod = 0; }
        if (mod !== parseInt(cpf.substring(10, 11) ) ) {
          this.qtdError++;
          return false;
        }
        return true;
      } else {
        this.qtdError++;
        return false;
      }
    } else {
      return true;
    }
  }

  public validEmail(email) {
    var validateFibra = email.split('@')
    if (this.boolValidateStep1 && !this.qtdEmpty) {
      const re = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
      if (re.test(email) === false) { 
        this.qtdError ++; 
      }
      if(validateFibra && validateFibra.length > 1){
        if(validateFibra[1] == 'bancofibra.com.br'){
          this.qtdError ++; 
          return false;
        }
      }
      return re.test(email);
    } else {
      if(validateFibra && validateFibra.length > 1){
        if(validateFibra[1] == 'bancofibra.com.br'){
          return false;
        }
      }
      return true;
    }
  }

  public validateBirthday(value) {
    const date = `${value.year}-${value.month < 10 ? '0' + value.month : value.month}-${value.day < 10 ? '0' + value.day : value.day}`;
    if (this.boolValidateStep1 && !this.qtdEmpty) {
      const bool = moment().diff(date, 'years', false) >= 18;
      if (!bool) { this.qtdError++; }
      return bool;
    } else {
      return true;
    }
  }

  public changeDate(value) {
    
    if (value.toString().indexOf('/') > -1) { 
      let date = value.split("/");
      value = new NgbDate(parseInt(date[2], 10), parseInt(date[1], 10), parseInt(date[0], 10))
    }
    
    this.dataPersonal.dt_nascimento = value;
    this.date = value;
    this.validateEmptyInputs();
  }

  public showPermissions(typeAccount, accountNumber) {
    if (typeAccount !== accountNumber) {
      RegisterAdminPageComponent.showBtnImport(accountNumber);
    } else {
      RegisterAdminPageComponent.enableBtnEditClose(accountNumber);
    }

    $('#btn-radio-' + accountNumber).addClass('row-hide');
  }

  public closeAccount(accountNumber) {
    RegisterAdminPageComponent.showBtnEdit(accountNumber);
    $('#btn-radio-' + accountNumber).removeClass('row-hide');
  }

  private clearDataPersonal() {
    this.dataPersonal = {
      nm_pessoa: '',
      num_cpf: '',
      dt_nascimento: '',
      tipo_documento: 0,
      desc_orgao_emissor: '',
      num_rg: '',
      cnh: '',
      rne: '',
      cif: '',
      cp: '',
      num_celular: '',
      desc_email: '',
      cod_departamento: 0,
      cod_cargo: 0,
    };
  }

  public mountArrayOtherAccess() {
    return {
      permissoes: [
        {
          cod_funcionalidade: 0,
          operacoes: [
            {
              cod_operacao: 0,
            },
          ],
        },
      ],
    };
  }

  public importDefaultAccount(numConta: number) {
    let arrPermissions: any;
    let accounSelected: any;
    let index: number;
    let event: any;
    const newItensOnList = this.listAccount;
    this.arrayAccountPayload.forEach((account) => {
      if (account.acesso_padrao) {
        arrPermissions = account.permissoes;
      }
    });

    newItensOnList.map((e, i) => {
      if (e.account.num_conta === numConta) {
        e.functionalities.forEach(f => {
          f.operacoes.forEach(op => {
            op.status = false;
            event = {};
            event.checked = op.status;
            event.operacao_id = op.cod_operacao;
            event.collapse_id = numConta;
            event.acessoPadrao = false;
            event.funcionalidade_id = f.cod_funcionalidade;
            this.changeRule(event);
          });
        });
        e.functionalities.forEach(f => {
          arrPermissions.forEach(p => {
            if (f.cod_funcionalidade === p.cod_funcionalidade) {
              f.operacoes.forEach((op) => {
                p.operacoes.forEach((arrOp) => {
                  if (op.cod_operacao === arrOp.cod_operacao) {
                    op.status = true;
                    event = {};
                    event.checked = op.status;
                    event.operacao_id = op.cod_operacao;
                    event.collapse_id = numConta;
                    event.acessoPadrao = false;
                    event.funcionalidade_id = f.cod_funcionalidade;
                    this.changeRule(event);
                  }
                });
              });
            }
          });
        });
        index = i;
        accounSelected = e;
      }
    });
    this.listAccount.splice(index, 1, accounSelected);
  }

  public newArrayToSelected(numConta, obj) {
    let index: number;
    let accounSelected;
    this.listAccount.map((e, i) => {
      if (e.account.num_conta === numConta) {
        e.functionalities.forEach((f) => {
          if (f.cod_funcionalidade === obj.funcionalidade_id) {
            f.operacoes.forEach((op) => {
              if (op.cod_operacao === obj.operacao_id) {
                op.status = obj.checked;
              }
            });
          }
        });
        index = i;
        accounSelected = e;
      }
    });
    this.listAccount.splice(index, 1, accounSelected);
  }

  public addUser() {
    const arrayOtherAccess = this.mountArrayOtherAccess();
    let descOrganEmissary = '';
    let numDocument = '';
    switch (this.dataPersonal.tipo_documento) {
      case 1:
        descOrganEmissary = this.dataPersonal.desc_orgao_emissor;
        numDocument = this.dataPersonal.num_rg;
        break;
      case 2:
        numDocument = this.dataPersonal.cnh;
        break;
      case 3:
        numDocument = this.dataPersonal.rne;
        break;
      case 4:
        numDocument = this.dataPersonal.cif;
        break;
      case 5:
        numDocument = this.dataPersonal.cp;
        break;
    }

    this.payload = {
      nm_pessoa: this.dataPersonal.nm_pessoa,
      num_documento: '',
      desc_orgao_emissor: '',
      dt_nascimento: this.formatDate(this.dataPersonal.dt_nascimento),
      num_celular: this.removeMask(this.dataPersonal.num_celular),
      desc_email: this.dataPersonal.desc_email.toLowerCase(),
      num_cpf: this.removeMask(this.dataPersonal.num_cpf),
      cod_departamento: this.dataPersonal.cod_departamento,
      tipo_documento: 0,
      cod_cargo: this.dataPersonal.cod_cargo,
      contas: this.arrayAccountPayload,
      outros_acessos: {
        permissoes: this.arrayNotAccountPayload,
      },
    };

    if (this.isEditMode) {
      this.payload = {
        cod_pessoa: this.dataPersonal.cod_pessoa,
        nm_pessoa: this.dataPersonal.nm_pessoa,
        num_documento: '',
        desc_orgao_emissor: '',
        dt_nascimento: this.formatDate(this.dataPersonal.dt_nascimento),
        num_celular: this.removeMask(this.dataPersonal.num_celular),
        desc_email: this.dataPersonal.desc_email,
        num_cpf: this.removeMask(this.dataPersonal.num_cpf),
        cod_departamento: this.dataPersonal.cod_departamento,
        tipo_documento: 0,
        cod_cargo: this.dataPersonal.cod_cargo,
        contas: this.arrayAccountPayload,
        outros_acessos: {
          permissoes: this.arrayNotAccountPayload,
        },
      };
    }

    this.showModalValidation = true;
  }

  public formatDate(date): any {
    const d: NgbDateStruct = date;
    let mes: any = d.month;
    if (mes < 10) {
      mes = '0' + d.month;
    }
    return date.year + '-' + mes + '-' + date.day;
  }

  public addMask = (value: any) => {
    return {
      mask: value,
      placeholderChar: '_',
      guide: false,
      modelClean: true,
    };
  }

  public closeModal = () => (this.showModalValidation = false);

  public securityRequest = () => {
    return this.payload;
  }

  public securityResponse = (value) => {
    if (value.status === 'success') {
      this.successUser(value);
    } else {
      this.errorUser(value);
    }
  }

  private successUser = (value) => {
    let msg = 'Usuário cadastrado com sucesso.';

    if (this.isEditMode) {
      msg = 'Usuário alterado com sucesso.';
    }

    const userRoute = {
      page: '/admin-user',
      params: { type: 'user', status: 2, msg }
    };
    this.router.navigate([userRoute.page, userRoute.params], { skipLocationChange: true, replaceUrl: false });
  }

  private errorUser = (err) => {
    this.showModalValidation = false;
    if (err.error && err.error.message && err.error.message.length > 0) {
      this.toast.callModalMessage(null, `${err.error.message[0]}`);
    } else {
      this.toast.callModalMessage(null, 'Ocorreu um erro realizar a operação.');
    }
  }

  // Todo: Resolver bug nessa solução!
  public changeRuleNew(event) {
    const checked = event.checked;
    const operationId = event.operacao_id;
    const numberAccount = event.collapse_id;
    const functionalityId = event.funcionalidade_id;
    this.listAccount.forEach((accounts, a) => {
      if (accounts.account.num_conta === numberAccount) {
        accounts.functionalities.forEach((funcionalidades, f) => {
          if (funcionalidades.cod_funcionalidade === functionalityId) {
            funcionalidades.operacoes.forEach((operacoes, o) => {
              if (operacoes.cod_operacao === operationId) {
                operacoes.status = checked;
              }
            });
          }
        });
      }
    });
  }

  public isEmpty(campo) {
    if (this.boolValidateStep1) {
      if (typeof campo !== 'object') {
        if (Number.isInteger(campo)) {
          if (campo === 0) {
            this.qtdEmpty++;
            return true;
          }
        } else if (campo.trim() === '') {
          this.qtdEmpty++;
          return true;
        }
      }
    } else {
      return false;
    }
  }

  public isLength(campo, size) {
    if (this.boolValidateStep1 && !this.qtdEmpty) {
      if (campo.length >= size) {
        return false;
      } else {
        this.qtdError++;
        return true;
      }
    } else {
      this.qtdError++;
      return false;
    }
  }

  public validateEmptyInputs = () => {
    let flagEmpty = true;
    if (this.dataPersonal.cod_cargo === 0) { flagEmpty = false; }
    if (this.dataPersonal.cod_departamento === 0) { flagEmpty = false; }
    if (this.dataPersonal.desc_email === '') { flagEmpty = false; }
    if (this.dataPersonal.dt_nascimento === '') { flagEmpty = false; }
    if (this.dataPersonal.nm_pessoa === '') { flagEmpty = false; }
    if (this.dataPersonal.num_celular === '') { flagEmpty = false; }
    if (this.dataPersonal.num_cpf === '') { flagEmpty = false; }
    // if (this.dataPersonal.tipo_documento === 0) { flagEmpty = false; }

    // if (this.dataPersonal.tipo_documento !== 0) {
    //   switch (this.dataPersonal.tipo_documento) {
    //     case 1:
    //         if (this.dataPersonal.desc_orgao_emissor === '') { flagEmpty = false; }
    //         if (this.dataPersonal.num_rg === '') { flagEmpty = false; }
    //         break;
    //     case 2:
    //         if (this.dataPersonal.cnh === '') { flagEmpty = false; }
    //         break;
    //     case 3:
    //         if (this.dataPersonal.rne === '') { flagEmpty = false; }
    //         break;
    //     case 4:
    //         if (this.dataPersonal.cif === '') { flagEmpty = false; }
    //         break;
    //     case 5:
    //         if (this.dataPersonal.cp === '') { flagEmpty = false; }
    //         break;
    //     default:
    //       break;
    //   }
    // }
    this.buttonEnabled = flagEmpty;
  }

  public validateStep1() {
    this.boolValidateStep1 = true;
    this.qtdEmpty = 0;
    this.qtdError = 0;
    this.isEmpty(this.dataPersonal.nm_pessoa);
    this.isEmpty(this.dataPersonal.dt_nascimento);
    this.isEmpty(this.dataPersonal.desc_email);
    this.isEmpty(this.dataPersonal.num_cpf);
    // if (!this.isEmpty(this.dataPersonal.tipo_documento)) {
    //   switch (this.dataPersonal.tipo_documento) {
    //     case 1:
    //       this.isEmpty(this.dataPersonal.desc_orgao_emissor);
    //       this.isEmpty(this.dataPersonal.num_rg);
    //       break;
    //     case 2:
    //       this.isEmpty(this.dataPersonal.cnh);
    //       break;
    //     case 3:
    //       this.isEmpty(this.dataPersonal.rne);
    //       break;
    //     case 4:
    //       this.isEmpty(this.dataPersonal.cif);
    //       break;
    //     case 5:
    //       this.isEmpty(this.dataPersonal.cp);
    //       break;
    //     default:
    //       break;
    //   }
    // }
    this.isLength(this.dataPersonal.nm_pessoa, 3);
    // if (!this.isEmpty(this.dataPersonal.tipo_documento)) {
    //   switch (this.dataPersonal.tipo_documento) {
    //     case 1:
    //       this.isLength(this.dataPersonal.desc_orgao_emissor, 5);
    //       this.isLength(this.dataPersonal.num_rg, 7);
    //       break;
    //     case 2:
    //       this.isLength(this.dataPersonal.cnh, 11);
    //       break;
    //     case 3:
    //       this.isLength(this.dataPersonal.rne, 9);
    //       break;
    //     case 4:
    //       break;
    //     case 5:
    //       this.isLength(this.dataPersonal.cp, 6);
    //       break;
    //     default:
    //       break;
    //   }
    // }
    this.isLength(this.dataPersonal.num_celular, 15);
    this.validEmail(this.dataPersonal.desc_email);
    this.validateCPF(this.dataPersonal.num_cpf);
    this.validateBirthday(this.dataPersonal.dt_nascimento);
    this.isEmpty(this.dataPersonal.cod_departamento);
    this.isEmpty(this.dataPersonal.cod_cargo);
    //this.checaDadosExistentes(this.removeMask(this.dataPersonal.num_cpf), this.dataPersonal.desc_email,this.removeMask(this.dataPersonal.num_celular));
    if (!this.qtdEmpty && !this.qtdError) {
      this.nextStep();
    }
  }

  public changeRule(event) {
    const checked = event.checked;
    const operacaoId = event.operacao_id;
    const numeroConta = event.collapse_id;
    let acessoPadrao = false;
    const funcionalidadeId = event.funcionalidade_id;
    // this.newArrayToSelected(numeroConta, event);
    debugger;
    if (numeroConta === this.typeAccount) {
      acessoPadrao = true;
    }
    let existAccounts = false;
    let existOperacoes = false;
    let qtdOperacoes = 0;
    let qtdFuncionalidades = 0;
    let existFuncionalidades = false;
    this.arrayAccountPayload.forEach((accounts, a) => {
      if (accounts.numero_conta === numeroConta) {
        existAccounts = true;
        accounts.permissoes.forEach((permissao, p) => {
          qtdFuncionalidades++;
          if (permissao.cod_funcionalidade === funcionalidadeId) {
            existFuncionalidades = true;
            permissao.operacoes.forEach((operacao, o) => {
              qtdOperacoes++;
              if (operacao.cod_operacao === operacaoId) {
                existOperacoes = true;
                if (!checked) {
                  qtdOperacoes--;
                  this.arrayAccountPayload[a].permissoes[p].operacoes.splice(
                    o,
                    1
                  );
                }
              }
            });
            if (checked && !existOperacoes) {
              this.arrayAccountPayload[a].permissoes[p].operacoes.push({
                cod_operacao: operacaoId,
              });
            }
          }
          if (!checked && existFuncionalidades && !qtdOperacoes) {
            qtdFuncionalidades--;
            existFuncionalidades = false;
            this.arrayAccountPayload[a].permissoes.splice(p, 1);
          }
        });
        if (checked && !existFuncionalidades) {
          this.arrayAccountPayload[a].permissoes.push({
            cod_funcionalidade: funcionalidadeId,
            operacoes: [
              {
                cod_operacao: operacaoId,
              },
            ],
          });
        }
      }
    });
    if (!existAccounts && !existOperacoes && !existFuncionalidades && checked) {
      this.arrayAccountPayload.push({
        numero_conta: numeroConta,
        acesso_padrao: acessoPadrao,
        permissoes: [
          {
            cod_funcionalidade: funcionalidadeId,
            operacoes: [
              {
                cod_operacao: operacaoId,
              },
            ],
          },
        ],
      });
    }
  }

  public changeRuleNotAccount(event) {
    const checked = event.checked;
    const funcionalidadeId = event.funcionalidade_id;
    const operacaoId = event.operacao_id;
    let existOperacao = false;
    let qtdOperacoes = 0;
    let qtdFuncionalidades = 0;
    let existFuncionalidade = false;
    this.arrayNotAccountPayload.forEach((permissao, p) => {
      qtdFuncionalidades++;
      if (permissao.cod_funcionalidade === funcionalidadeId) {
        existFuncionalidade = true;
        permissao.operacoes.forEach((operacao, o) => {
          qtdOperacoes++;
          if (operacao.cod_operacao === operacaoId) {
            existOperacao = true;
            if (!checked) {
              qtdOperacoes--;
              this.arrayNotAccountPayload[p].operacoes.splice(o, 1);
            }
          }
        });
        if (checked && !existOperacao) {
          this.arrayNotAccountPayload[p].operacoes.push({
            cod_operacao: operacaoId,
          });
        }
      }
      if (!checked && existFuncionalidade && !qtdOperacoes) {
        qtdFuncionalidades--;
        existFuncionalidade = false;
        this.arrayNotAccountPayload.splice(p, 1);
      }
    });
    if (checked && !existFuncionalidade) {
      this.arrayNotAccountPayload.push({
        cod_funcionalidade: funcionalidadeId,
        operacoes: [
          {
            cod_operacao: operacaoId,
          },
        ],
      });
    }
  }

  public changeRuleNotAccountAll(event){

    const checked = event.checked;
    let arrOperacoes: any = [];
    this.arrayNotAccountPayload=[];
    
    if (checked) 
    {
      this.arrayFunctionalitiesNotAccount.forEach((funcionalidade) => {
        arrOperacoes = [];

        funcionalidade.operacoes.forEach((operacao) => {
          arrOperacoes.push({ cod_operacao: operacao.cod_operacao });
        });

        this.arrayNotAccountPayload.push({
          cod_funcionalidade: funcionalidade.cod_funcionalidade,
          operacoes: arrOperacoes
        });

      });
    }

  }

  public changeAllRule(event) {
    
    const acessoPadrao = true;
    const checked = event.checked;
    const numeroConta = event.collapse_id.toString();
    const arrayFuncionalidade: Array<UserModel.AdminUserFunctionalities> = [];
    let arrOperacoes: any = [];

      //ContaArray EditarPermissoes
      this.arrayAccountPayload.forEach((accounts, a) => {
        if (accounts.numero_conta === numeroConta) {
          this.arrayAccountPayload.splice(a, 1);
        }
      });
      
      if (checked) 
      {
        
        this.listAccount.forEach((account) => {
          
          if (account.account.num_conta === numeroConta) 
          {
            
            account.functionalities.forEach((funcionalidade) => {
              arrOperacoes = [];
              
              funcionalidade.operacoes.forEach((operacao) => {
                arrOperacoes.push({ cod_operacao: operacao.cod_operacao });
              });

              if (arrOperacoes.length) {
                arrayFuncionalidade.push({
                  cod_funcionalidade: funcionalidade.cod_funcionalidade,
                  operacoes: arrOperacoes,
                });
              }

            });

            this.arrayAccountPayload.push({
              numero_conta: numeroConta,
              acesso_padrao: acessoPadrao,
              permissoes: arrayFuncionalidade,
            });

          }

        });
       
      }

  }


  private requestGroupUserList(): void {
    this.getAdminGroupList
      .execute()
      .subscribe(this.requestGroupSuccess, this.requestGroupError);
  }

  private requestGroupSuccess = (value) => {
    const data = Utils.verifyArray(value.data);
    data.map((e) => {
      e.checked = false;
      this.listGroup.push({
        name: e.desc_grupo,
        value: e,
      });
    });
  }

  private requestGroupError = (err) => {
    return consoleDev('Error:: ', err);
  }

  public addGroup = () => {
    if (this.selectedGroup) {
      let flag = false;
      this.addedGroups.map((e) => {
        if (this.selectedGroup.cod_grupo === e.cod_grupo) {
          flag = true;
        }
      });
      if (!flag) {
        this.addedGroups.push(this.selectedGroup);
      }
      this.selectedGroup = null;
    }
  }

  public deleteGroups = () => {
    for (let i = 0; i <= this.addedGroups.length - 1; i++) {
      if (this.addedGroups[i].checked) {
        this.addedGroups[i].checked = false;
        this.addedGroups.splice(i, 1);
        this.deleteGroups();
        break;
      }
    }
    this.countCheckedGroups = 0;
  }

  public deleteGroup = (cod_grupo) => {
    for (let i = 0; i <= this.addedGroups.length - 1; i++) {
      if (this.addedGroups[i].cod_grupo === cod_grupo) {
        this.addedGroups[i].checked = false;
        this.addedGroups.splice(i, 1);
        break;
      }
    }
  }

  public setGroup(event) {
    const checked = event.target.checked;
    const codGroup = Number(event.target.value);
    const { addedGroups } = this;

    for (let i = 0; i <= addedGroups.length - 1; i++) {
      if (addedGroups[i].cod_grupo === codGroup) {
        addedGroups[i].checked = checked;
        this.countCheckedGroups += checked ? 1 : -1;
        break;
      }
    }
    this.addedGroups = addedGroups;
  }

  public register = () => {
    if (this.stepFlow === 'Individual' || this.isEditMode) {
      this.addUser();
    } else {
      if (this.addedGroups.length > 0) {
        this.registerUserByGroups();
      }
    }
  }

  public registerUserByGroups = () => {
    this.apiCallback = 23;
    let descOrgaoEmissor = '';
    let numDocumento = '';
    const listaIdsGrupos = [];

    this.addedGroups.map((e) => {
      listaIdsGrupos.push(e.cod_grupo);
    });

    // switch (this.dataPersonal.tipo_documento) {
    //   case 1:
    //     descOrgaoEmissor = this.dataPersonal.desc_orgao_emissor;
    //     numDocumento = this.dataPersonal.num_rg;
    //     break;
    //   case 2:
    //     numDocumento = this.dataPersonal.cnh;
    //     break;
    //   case 3:
    //     numDocumento = this.dataPersonal.rne;
    //     break;
    //   case 4:
    //     numDocumento = this.dataPersonal.cif;
    //     break;
    //   case 5:
    //     numDocumento = this.dataPersonal.cp;
    //     break;
    // }

    this.payload = {
      nm_pessoa: this.dataPersonal.nm_pessoa,
      num_documento: '',
      desc_orgao_emissor: '',
      dt_nascimento: this.formatDate(this.dataPersonal.dt_nascimento),
      num_celular: this.dataPersonal.num_celular,
      desc_email: this.dataPersonal.desc_email,
      num_cpf: this.dataPersonal.num_cpf,
      cod_departamento: this.dataPersonal.cod_departamento,
      tipo_documento: this.dataPersonal.tipo_documento,
      cod_cargo: this.dataPersonal.cod_cargo,
      lista_ids_grupos: listaIdsGrupos,
    };
    this.showModalValidation = true;
  }

  public checaDadosExistentes(cpf: string, email: string, celular: string){
    const params = {
      cpf: cpf,
      email: email,
      celular: celular
    };
    this.postValidaDados
      .execute(params)
      .subscribe(this.requestUserDadosSuccess, this.requestUserDadosError);
  }

  private requestUserDadosSuccess = (value) => {
    var mensagem = value.data;
    switch(mensagem){
      case "email ja cadastrado":
        this.emailDuplicado = true;
      case "celular ja cadastrado":
        this.celularDuplicado = true;
      case "cpf ja cadastrado":
        this.celularDuplicado = true;
      default:
        this.celularDuplicado = false;
        this.emailDuplicado = false;
        this.celularDuplicado = false;
    }
  }

  private requestUserDadosError = (err) => {
    return consoleDev('Error:: ', err);
  }
}
