<div class="card mt-3 noborder" *fibraHasRole="'MASTER'">
    <div class="card-body"  *ngIf="exibirDadosAprovacao && !showDisclaimer">
      <div class="row align-items-center">
        <div class="col-8 text-wrap">
          <p class="label-medium text-justify">
            O usuário
            <span class="font-weight-bold">{{ criadoPor }}</span>
            fez a requisição de <span class="fg-red">cancelamento</span> ao DDA no
            dia
            <span class="font-weight-bold">{{
              dataCriacao | date : "dd/MM/yyyy"
            }}</span>
            às <span class="font-weight-bold">{{ horaCriacao }}h</span> e depende
            da aprovação dos masters. Você deseja:
          </p>
        </div>
        <div class="col-2">
          <button
            type="button"
            class="text-center button-outlined mr-2"
            (click)="reprovarTermo()"
          >
            Manter adesão
          </button>
        </div>
        <div class="col-2">
          <button
            type="button"
            class="text-center button-blue mr-2"
            (click)="exibirTermo(true)"
          >
            Cancelar adesão
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card mt-3 noborder" *ngIf="showDisclaimer">
  <div class="card-body" *fibraHasRole="'MASTER'">
    <div class="row">
      <div class="col">
        <fibra-disclaimer-cancel></fibra-disclaimer-cancel>
      </div>
    </div>
    <div class="row mt-5 justify-content-between">
      <div class="col-9 check-div">
        <input id="agree" type="checkbox" class="checkbox" (click)="enable()" />
        <label for="agree" class="check-font"
          >Li e concordo com o termo de adesão do débito autorizado.
        </label>
      </div>

      <div class="col-1">
        <button
          type="button"
          class="text-center button-outlined mr-2"
          (click)="exibirTermo(false)"
        >
          Voltar
        </button>
      </div>
      <div class="col-2">
        <button
          type="button"
          class="text-center button-blue mr-2"
          [disabled]="!enableAction"
          (click)="exibirAutorizacao = true"
        >
            Cancelar Adesão
        </button>
      </div>
    </div>
  </div>
</div>
<fibra-modal-validation
  *ngIf="exibirAutorizacao"
  [apiCallback]="apiCallback"
  (closeEvent)="fecharModal()"
  [apiPayload]="apiPayload"
  (responseValidation)="respostaApi($event)"
>
</fibra-modal-validation>
<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
<fibra-modal-conclusao-cancelamento
  *ngIf="exibirConclusao"
  [recusar]="apiPayload.ds_status === StatusApprovalEnum.Reproved"
  [totalMasters]="totalMasters"
  [totalFiliais]="filiais.length"
  (closeEvent)="fecharModalConclusao()"
>
</fibra-modal-conclusao-cancelamento>
