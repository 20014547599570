<div class="content-beneficiaries">
  <div class="beneficiaries">
    <div class="arrow" (click)="goBack()" style="cursor: pointer;" >
        <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar"> Voltar</span>
  </div>
  <div class="title-filter-div">
      <span class="title-font">Beneficiários favoritos</span>
      <div class="filter-div">
          <div class="filter">
              <div class="input-filter">
                <!-- <input
                  type="text"
                  class="line-code-bar"
                  placeholder="Nome do beneficiario ou chave"
                  [(ngModel)]="search"
                /> -->
                <input
                  type="text"
                  class="line-code-bar"
                  placeholder="Nome do beneficiario ou chave"
                  [(ngModel)]="search"
                />
              <img class="search-img" src="assets/svg/icon_search.svg" />
              </div>
              <span class="filter-subtitle"> As chaves podem ser: CNPJ/CPF, celular, e-mail ou chave aleatória.</span>
          </div>
        <button
        type="button"
        class="button-continue"
        (click)="goToNewTransfer()"
      >
        Novo beneficiário
      </button>
      </div>
  </div>
  <div class="list-div mt-4">
      <fibra-pix-beneficiaries-list [filter]="search"> </fibra-pix-beneficiaries-list>
  </div>


</div>
</div>

  
 