<div class="body-content" >
    <div class="product">
      <!-- Header -->
      <header class="product-header" *ngIf="productStep != 3">
        <div class="row">
          <div
            class="col-5 product-header-title"
            [ngClass]="{ disabled: productStep === 2 }"
          >
            <span class="number-circle">
              <label>1</label>
            </span>
            <h1>Produtos</h1>
          </div>
          <div
            class="col-7 product-header-title disabled"
            [ngClass]="{ disabled: productStep === 1 }"
          >
            <span class="number-circle">
              <label>2</label>
            </span>
            <h1>Simulação</h1>
          </div>
        </div>
      </header>
      <!-- Header / End -->
      
      <!--  PRODUTOS -->
      <!-- <div class = "table">
        
      </div> -->
      
      
      <div class="list-value-admin-user" *ngIf="productStep == 1">
        <div
          class="d-flex justify-content-center align-items-center position loading-container loadingView"
          *ngIf="!loading"
        >
            <div class="spinner-border text-primary" role="status">
          </div>
        </div>
        <table class="table-product" *ngIf="loading">
          <tbody>
            <tr *ngFor="let item of list; index as i" class="row">
              <div class="panel-group" id="accordion">
                <div 
                  class="panel-list" 
                  (click)="toogleIconArrow(i)"
                  data-toggle="collapse"
                  attr.href="{{ '#collapse' + i }}"
                  style="cursor: pointer;"
                >
                  <td>
                    <h1 
                      class="product-title"
                    >
                    {{ item.desc_produto }} 
                    <label class="info-icon"> <img src="/assets/png/info.png"/> Este produto não requer garantia.</label>
                    </h1>
                    
                    <p class="product-value-pre">
                      <span class="bold-pre-text"
                        >Valor pré aprovado*
                      </span>
                      <span class="bold-pre-value"
                        >R$
                        {{ item.valor_disponivel || 0 | number: "1.2-2" }}
                      </span>
                    </p>
                  </td>
                  <span
                    class="item-icon"
                  >
                    <img
                      id="{{ i }}"
                      src="/assets/svg/arrow_down.svg"
                      class=""
                      alt="Seta para dropdown"
                      [ngStyle]="{'transform' : hasRotateOne ? 'rotate(180deg)' : ''}"
                    />
                  </span>
                </div>
                <div class="border-panel-list"></div>
                <div
                  id="{{ 'collapse' + i }}"
                  class="collapse"
                  [ngClass]="hasDtaOne == true ? 'collapse show' : 'collapse'"
                  data-parent="#accordion"
                  style="border-bottom: 0.7px solid #D9DBE3;"
                >
                  <form class="form-simulacao" [formGroup]="formStep1">
                    <!-- TODO: BOTAR PLACEHOLDER -->
                    <div *ngIf="optionsProfile && optionsProfile.length > 1">
                      <label class="name-label">Escolha a conta para receber o crédito</label>
                      <br/>
                      <!-- *ngIf="optionsProfile && optionsProfile.length > 1" -->
                      <form [formGroup]="accountModel" >
                        <select formControlName="account" class="form-control" id="selectedAccount" style="width:525px; margin-bottom:24px;" [(ngModel)]="selecionadoValor" (change)="accountSelected()">
                          <option hidden value="" disabled selected>Selecionar conta </option>
                          <option [ngValue]="option" *ngFor="let option of optionsProfile; let i = index">{{ option.num_conta }} - {{option.desc_conta}}
                          </option>
                        </select>
                      </form>
                    </div>
                    <form [formGroupName]="'simulacao'"  style="display: grid; grid-template-columns: 248px 197px 262px 200px; height: 130px;">
                      <div>
                        <label class="name-label">Meu valor</label>
                        <br/>
                        <input
                          id="{{'valor' + i}}"
                          formControlName="valor"
                          [style.width.px]="200"
                          currencyMask
                          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',  align: 'left' }"
                          placeholder="R$ 0.00"
                          (change)="setMeuValor($event.target.value)"
                        />
                        <br/>
                        <div *ngIf="validateMeuValor(item)">
                          <div
                            class="box-erro"
                            style="width: 200px;"
                          >
                            <img src="/assets/png/iconErro.png" style="margin-right: 8px;"/>
                            <span>Valor inválido. Informe um valor maior ou igual a <b>R${{item.limite_min_oferta || 0 | number: "1.2-2" }}</b> e menor ou igual ao <b>valor pré-aprovado</b></span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label class="name-label">Prazo total</label>
                        <br/>
                        <input
                          id="{{'prazo' + i}}"
                          formControlName="prazoTotal"
                          onlyNumber
                          [style.width.px]="66"
                          placeholder="000"
                          maxlength="3"
                          (change)="setPrazo($event.target.value)"
                        />
                        <br/>
                        <span class="text" *ngIf="item.parcelas > 1">limite máximo {{item.parcelas}} meses</span>
                        <span class="text" *ngIf="item.parcelas == 1">limite máximo {{item.parcelas}} mês</span>
                        <br/>
                        <div *ngIf="validatePrazo(item.parcelas)">
                          <div
                            class="box-erro"
                            style="width: 197px;"
                          >
                            <img src="/assets/png/iconErro.png" style="margin-right: 8px;"/>
                            <label>Valor inválido. Informe um valor maior do que zero e menor ou igual ao limite máximo de parcelas.</label>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="item.prazo_carencia > 0">
                        <!-- *ngIf="item.prazo_carencia > 0" -->
                        <div>
                          <label class="name-label">Carência do Principal</label>
                          <br/>
                          <div style="display: flex; align-items: center;">
                            <input
                              id="{{'carencia' + i}}"
                              formControlName="carencia"
                              onlyNumber
                              placeholder="000"
                              [style.width.px]="66"
                              maxlength="3"
                              (change)="setCarencia($event.target.value)"
                              [attr.disabled]="disableCarencia ? 'true' : null"
                            />
                            <input type="checkbox" class="checkbox" id="carenciaCheckBox" (change)="carenciaRules()" [attr.checked]="disableCarencia ? true : null" />
                            <span>
                              Sem carência
                            </span>
                          </div>
                          
                          <span class="text" *ngIf="item.prazo_carencia > 1">limite máximo {{item.prazo_carencia}} dias</span>
                          <span class="text" *ngIf="item.prazo_carencia == 1">limite máximo {{item.prazo_carencia}} dia</span>
                          <br/>
                          <div *ngIf="!disableCarencia && validateCarencia(item.prazo_carencia)">
                            <div
                              class="box-erro"
                              style="width: 200px;"
                            >
                              <img src="/assets/png/iconErro.png" style="margin-right: 8px;"/>
                              <label>Valor inválido. Informe um valor maior do que zero e menor ou igual ao limite máximo de parcelas da carência do principal.</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label class="name-label">Indexador</label>
                        <br/>
                        <select class="form-control" id="selectIndexador">
                          <option *ngFor="let option of indexadorList">
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </form>
                  </form>
                  <div class="button-simulation">
                    <button
                      type="button"
                      class="button-simulador"
                      (click)="simulation(item)"
                      [attr.disabled]="disableSimulation ? 'true' : null"
                    >
                      Simular Empréstimo
                    </button>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <!--  FIM PRODUTOS   -->

      <!-- SIMULAÇÃO  -->
      <div class="list-value-admin-user" *ngIf="productStep == 2">
        <div
            class="d-flex justify-content-center align-items-center position loading-container loadingView"
            *ngIf="!loading"
          >
              <div class="spinner-border text-primary" role="status">
            </div>
          </div>
        <table class="table" *ngIf="loading">
          <tbody>
            <tr>
              <h1 class="product-details-title">{{ nomeSimulation }}
                <label class="info-icon"> <img src="/assets/png/info.png"/> Este produto não requer garantia.</label>
              </h1>
            </tr>
            <div class="border-panel-list" style="margin-bottom: 24px;"></div>
            <tr *ngIf="optionsProfile && optionsProfile.length > 1">
              <span class="conta-text">Conta que será creditada</span>
              <h1 class="conta-details-title"> {{selectedAccount.num_conta}} - {{selectedAccount.desc_conta}}</h1>
              <!-- padding-left: 32px; -->
            </tr>
            <tr>
              <th>
                <div class="panel-list-resumo">
                  <tr>
                    <td>
                      <p class="product-details-txt">
                        <span class="bold">
                          Valor solicitado
                        </span>
                      </p>
                      <p class="product-details-txt-value">
                        R${{ simulacaoRetorno.valor_solicitado || 0 | number: "1.2-2" }}
                      </p>
                    </td>
                    <td>
                      <p class="product-details-txt">
                        <span class="bold">
                          Prazo total
                        </span>
                      </p>
                      <p class="product-details-txt-value" *ngIf="simulacaoRetorno.num_parcelas > 1">
                        {{simulacaoRetorno.num_parcelas}} meses
                      </p>
                      <p class="product-details-txt-value" *ngIf="simulacaoRetorno.num_parcelas == 1">
                        {{simulacaoRetorno.num_parcelas}} mês
                      </p>
                    </td>
                    <td>
                      <p class="product-details-txt">
                        <span class="bold">
                          Carência
                        </span>
                      </p>
                      
                      <p class="product-details-txt-value" *ngIf="disableCarencia">
                        Sem Carência
                      </p>
                      <p class="product-details-txt-value" *ngIf="!disableCarencia">
                        {{simulacaoRetorno.num_carencia}} dias
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="product-details-txt">
                        <span class="bold">
                          Indexador
                        </span>
                      </p>
                      <p class="product-details-txt-value" *ngIf = "indexador == 1">
                        Pré-Fixado
                      </p>
                      <p class="product-details-txt-value" *ngIf = "indexador == 2">
                        Pós-Fixado: 100% CDI
                      </p>
                    </td>
                    <td>
                      <p class="product-details-txt">
                        <span class="bold">
                          Taxa Anual
                        </span>
                      </p>
                      <p class="product-details-txt-value">
                        {{ simulacaoRetorno.valor_taxa_mensal | number: 
                          "1.3-3"}}%
                      </p>
                    </td>
                  </tr>
                </div>
              </th>
              <th>
                <div style="margin-top: 12px;">
                  <p class="product-details-simulation">
                    <span class="bold-simulation">
                      Detalhes da simulação
                    </span>
                  </p>
                  <p class="product-simulation-txt">
                    <span class="bold">
                      Valor Liquido
                    </span>
                    <span class="bold-value">
                      R${{ simulacaoRetorno.valor_liquido || 0 | number: "1.2-2" }}
                    </span>
                  </p>
                  <a class="product-title-parcelas" (click) = "parcelas()" *ngIf="simulacaoRetorno.num_parcelas > 1">
                    Fluxo de parcelas   ({{ simulacaoRetorno.num_parcelas }} parcelas)
                  </a>
                  <a class="product-title-parcelas" (click) = "parcelas()" *ngIf="simulacaoRetorno.num_parcelas == 1">
                    Fluxo de parcelas   ({{ simulacaoRetorno.num_parcelas }} parcela)
                  </a>
                  <p class="product-simulation-txt" style="padding-top: 40px;">
                    <span class="bold">
                      IOF
                    </span>
                    <span class="bold-value">
                      R${{ simulacaoRetorno.valor_iof || 0 | number: "1.2-2" }}
                    </span>
                  </p>
                  
                  <p class="product-simulation-txt">
                    <span class="bold">
                      CET
                    </span>
                    <span class="bold-value">
                      {{ simulacaoRetorno.valor_cet | number: "1.3-3" }}%
                    </span>
                  </p>
                  <p class="product-simulation-txt">
                    <span class="bold">
                      Data prevista do Crédito
                    </span>
                    <span class="bold-value">
                      {{ simulacaoRetorno.dt_credito | date: 'dd/MM/yyyy' }}
                    </span>
                  </p>
                  <p class="product-simulation-txt">
                    <span class="bold">
                      Primeiro vencimento
                    </span>
                    <span class="bold-value">
                      {{ simulacaoRetorno.dt_primeiro_vencimento | date: 'dd/MM/yyyy' }}
                    </span>
                  </p>
                  <p class="product-simulation-txt">
                    <span class="bold">
                      Ultimo vencimento
                    </span>
                    <span class="bold-value">
                      {{ simulacaoRetorno.dt_ultimo_vencimento | date: 'dd/MM/yyyy' }}
                    </span>
                  </p>
                  <!-- <p class="product-simulation-txt" *ngIf="simulacaoRetorno.num_carencia > 0">
                    <span class="bold">
                      Periodo de Carência de Pagamento do Principal
                    </span>
                    <span class="bold-value">
                      sem valor ainda
                    </span>
                  </p> -->
                  <p class="product-cdi-txt" *ngIf="indexador == 2">
                    <span class="bold">
                      * A ser acrescido do Índice Pós-Fixado estipulado no campo Taxa Anual. 
                    </span>
                  </p>
                  <div class="button-contract-back">
                    <button
                      type="button"
                      class="button-back"
                      (click)="voltar()"
                    >
                      Voltar
                    </button>
                    <button
                      type="button"
                      class="button-contract"
                      (click)="contract()"
                    >
                      Solicitar Contratação
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- FIM SIMULAÇÃO  -->
      
      

      <!--MENSAGEM DE ERRO-->
      <fibra-generic-info
        type="warn"
        title="Não há produtos disponíveis para simulação no momento."
        message="Entre em contato com a gente e conte com um atendimento personalizado."
        fechar="true"
        *ngIf="productStep == 0"
      >
      </fibra-generic-info>

      <fibra-generic-info
        type="warn"
        [title]="messageHorarioLimiteProduct"
        fechar="true"
        *ngIf="productStep == 4"
      >
      </fibra-generic-info>

      <fibra-generic-info
        type="warn"
        title="Horário limite excedido."
        [message]="messageHorarioLimiteSimulation"
        fechar="true"
        *ngIf="productStep == 5"
      >
      </fibra-generic-info>
      <!-- FIM MENSAGEM DE ERRO-->


    </div>
    <div class="footer-table-products-stepone" *ngIf="productStep == 1 && loading">
      * Sujeito a análise de crédito e demais condições do produto.
    </div> 


    <!-- RESUMO INICIO-->
    <div class="product-resumo" *ngIf="productStep == 3" style="display:flex; flex-direction: column; justify-content: center; align-items: center;">
      <div class="header-resumo">
        <p class="header-title"> 
          <img [src]="'/assets/png/iconConfirm.png'" />
          Solicitação de Contratação de operação realizada com sucesso! 
        </p>
        <p class="header-text">
          Aguarde, por favor. Nosso time está providenciando as aprovações e formalizações necessárias para que sua solicitação seja atendida
        </p>
      </div>
      <div class="body-resumo">
        <div id="product-resumo-content">
          <div class="header-body-resumo">
            <img [src]="'/assets/png/logo-receipt.png'" />
            <span class="header-title-resumo">
              Resumo
            </span>
          </div>
          <div *ngIf="optionsProfile && optionsProfile.length > 1">
            <label class="label-conta"> Conta selecionada para receber o crédito </label>
            <br/>
            <label class="label-conta-value"> {{selectedAccount.num_conta}} - {{selectedAccount.desc_conta}} </label>  
          </div>
          <table class="table">
            <tbody>
              <tr class="noborder">
                <td>
                  <label class="label-td"> Valor Solicitado </label>
                </td>
                <td>
                  <label class="label-td">Prazo Total</label>
                </td>
                <td>
                  <label class="label-td">
                    Carência</label>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="label-td-value"> R${{ contratacaoRetorno.valor_solicitado || 0 | number: "1.2-2" }} </label>
                </td>
                <td>
                  <label class="label-td-value" *ngIf="contratacaoRetorno.num_parcelas > 1">{{contratacaoRetorno.num_parcelas}} meses</label>
                  <label class="label-td-value" *ngIf="contratacaoRetorno.num_parcelas == 1">{{contratacaoRetorno.num_parcelas}} mês</label>
                </td>
                <td>
                  <label class="label-td-value" *ngIf="contratacaoRetorno.num_carencia == 0">Sem Carência</label>
                  <label class="label-td-value" *ngIf="contratacaoRetorno.num_carencia > 0">{{contratacaoRetorno.num_carencia}} dias</label>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="label-td" style="margin-top: 40px;"> Indexador </label>
                </td>
                <td>
                  <label class="label-td" style="margin-top: 40px;">Taxa Anual</label>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="label-td-value" *ngIf="contratacaoRetorno.indexador_id == 1"> Pré-Fixado </label>
                  <label class="label-td-value" *ngIf="contratacaoRetorno.indexador_id == 2"> Pós-Fixado: 100% CDI </label>
                </td>
                <td>
                  <label class="label-td-value">{{contratacaoRetorno.valor_taxa_mensal | number: "1.3-3"}}%</label>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="body-details">
            <p class="product-details-simulation">
              <span class="bold-simulation">
                Detalhes da simulação
              </span>
            </p>
            <p class="product-details-txt">
              <span class="bold">
                Valor Liquido
              </span>
              <span class="bold-value">
                R${{ contratacaoRetorno.valor_liquido || 0 | number: "1.2-2" }}
              </span>
            </p>
            <a class="product-title-parcelas" (click) = "parcelas()" *ngIf="contratacaoRetorno.num_parcelas > 1">
              Fluxo de parcelas   ({{ contratacaoRetorno.num_parcelas }} parcelas{{contratacaoRetorno.indexador_id == 2 ? '*' : null}})
            </a>
            <a class="product-title-parcelas" (click) = "parcelas()" *ngIf="contratacaoRetorno.num_parcelas == 1">
              Fluxo de parcelas   ({{ contratacaoRetorno.num_parcelas }} parcela{{contratacaoRetorno.indexador_id == 2 ? '*' : null}})
            </a>
            <p class="product-details-txt" style="padding-top: 40px;">
              <span class="bold">
                IOF
              </span>
              <span class="bold-value">
                R${{ contratacaoRetorno.valor_iof || 0 | number: "1.2-2" }}
              </span>
            </p>
            
            <p class="product-details-txt">
              <span class="bold">
                CET
              </span>
              <span class="bold-value">
                {{ contratacaoRetorno.valor_cet | number: "1.3-3"}}%
              </span>
            </p>
            <p class="product-details-txt">
              <span class="bold">
                Data prevista do Crédito
              </span>
              <span class="bold-value">
                {{ contratacaoRetorno.dt_credito | date: 'dd/MM/yyyy' }}
              </span>
            </p>
            <p class="product-details-txt">
              <span class="bold">
                Primeiro vencimento
              </span>
              <span class="bold-value">
                {{ contratacaoRetorno.dt_primeiro_vencimento | date: 'dd/MM/yyyy' }}
              </span>
            </p>
            <p class="product-details-txt">
              <span class="bold">
                Ultimo vencimento
              </span>
              <span class="bold-value">
                {{ contratacaoRetorno.dt_ultimo_vencimento | date: 'dd/MM/yyyy' }}
              </span>
            </p>
            <!-- <p class="product-details-txt" *ngIf="contratacaoRetorno.num_carencia > 0">
              <span class="bold">
                Periodo de Carência de Pagamento do Principal
              </span>
              <span class="bold-value">
                sem valor ainda
              </span>
            </p> -->
            <p class="product-cdi-txt" *ngIf="contratacaoRetorno.indexador_id == 2">
              <span class="bold">
                * A ser acrescido do Índice Pós-Fixado estipulado no campo Taxa Anual. 
              </span>
            </p>
          </div>
        </div>
        <div class="button-resumo">
          <button
            type="button"
            class="button-pdf"
            (click)="generatePDF(contratacaoRetorno.cod_simulacao)"
          >
            Baixar PDF
          </button>
          <button
            type="button"
            class="button-close"
            (click)="closeResumo()"
          >
            Fechar
          </button>
        </div>
      </div>
      <div class="footer-table-products">
        <div>
          <img src="/assets/png/infogray-icon-info.png" style="padding-right: 8px;"/>
          Exibindo somente as solicitações de contratação realizadas nos últimos 7 dias.
        </div>
      </div> 
    </div>
    <!-- RESUMO FIM -->
  </div>
  <fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
<fibra-modal-parcelas-produtos
  #content
  (closeEvent)="closeModalParc($event)"
  [retornoParc]="parcelasList"
  [indexParc]="parcelasIndexador"
  *ngIf="showModalParcelas"
>
</fibra-modal-parcelas-produtos>
<fibra-modal-confirmation-contract
  #content
  (closeEvent)="closeModalConf($event)"
  *ngIf="showModalConfirmation"
>
</fibra-modal-confirmation-contract>
<fibra-modal-validation
*ngIf="showModalValidation"
[apiCallback]="apiCallback"
[apiPayload]="securityRequest()"
(closeEvent)="closeModal()"
(responseValidation)="securityResponse($event)">
</fibra-modal-validation>

