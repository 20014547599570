import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DdaBranchModel } from "@src/core/domain/dda/branch.model";
import { DdaCallBackEnum } from "@src/core/domain/dda/dda-callback.enum";
import { StatusApprovalEnum } from "@src/core/domain/dda/dda-type-approval.enum";
import { DdaTermCompanyApprovalModel } from "@src/core/domain/dda/term-company-approval.model";
import { DdaTermDataShare } from "@src/data/repository/data-share-repository";
import { DdaTermApprovalEntity } from "@src/data/repository/dda/entity/term/dda-term-approval.entity";
import { TermImplementationRepository } from "@src/data/repository/dda/term/term-implementation.repository";
import { CpfCnpjPipe } from "@src/shared/pipes/cpfcnpj";
import * as moment from "moment";
import { finalize } from "rxjs/operators";
import { ToastErrorMessageComponent } from "../../toast-error-message/toast-error-message.component";

@Component({
  selector: "fibra-aprovacao-termo-cancelamento",
  templateUrl: "./aprovacao-termo-cancelamento.component.html",
  styleUrls: ["../../../pages/dda/dda.scss"],
  providers: [CpfCnpjPipe, TermImplementationRepository],
})
export class AprovacaoTermoCancelamentoComponent implements OnInit {
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  @Output() showMainData: EventEmitter<boolean> = new EventEmitter();
  @Input() termoAceiteId: number = 0;

  callBackType = DdaCallBackEnum;
  StatusApprovalEnum = StatusApprovalEnum;

  empresa: any;
  filiais: DdaBranchModel[] = [];
  enableAction: boolean = false;
  showDisclaimer: boolean = false;
  totalMasters: number = 0;

  apiCallback: number = DdaCallBackEnum.AprovarTermo;
  exibirAutorizacao: boolean = false;
  exibirConclusao: boolean = false;
  apiPayload: DdaTermApprovalEntity = {
    id: 0,
    termo_aceite_id: 0,
    ds_status: StatusApprovalEnum.Approved,
    dt_aprovacao: "",
    aprovacao_substituida: "",
    filiais: []
  };
  typeColorToast = "#ED6C6C";

  statusAprovacao: string = "";
  exibirDadosAprovacao: boolean = false;

  descricaoAprovacao: string = "";

  criadoPor: string = "";
  dataCriacao: string = "";
  horaCriacao: string = "";

  constructor(
    private termRepository: TermImplementationRepository,
    public ddaTermDataShare: DdaTermDataShare
  ) {}

  ngOnInit() {
    this.obterDadosEmpresa();
    this.prepararPayload();
    this.obterDadosFiliais();
  }

  prepararPayload() {
    this.ddaTermDataShare.getStatusTermCancel()
      .pipe(
        finalize(() => {
          if (this.apiPayload.id > 0) this.obterDadosFiliais();
        })
      )
      .subscribe((result: DdaTermCompanyApprovalModel) => {
        if (result !== null && result != undefined) {
          this.apiPayload.id = result.aprovacoes.find(
            (x) =>
              x.pessoa[0].email === localStorage.getItem("useremail") &&
              x.status === StatusApprovalEnum.Pendant
          ).id;
          this.apiPayload.termo_aceite_id = result.id;
          this.apiPayload.dt_aprovacao = moment().format("YYYY-MM-DDTHH:mm:ss");
          this.apiPayload.ds_status = StatusApprovalEnum.Approved;
          this.statusAprovacao = result.status;

          this.criadoPor = result.aprovacoes.find(
            (x) =>
              moment(x.dt_criacao).format("YYYY-MM-DDTHH:mm") ===
                moment(result.dt_criacao).format("YYYY-MM-DDTHH:mm") &&
              x.status === StatusApprovalEnum.Approved
          ).pessoa[0].nome_pessoa;

          let notApproved = result.aprovacoes.filter(
            (x) =>
              moment(x.dt_criacao).format("YYYY-MM-DDTHH:mm") ===
                moment(result.dt_criacao).format("YYYY-MM-DDTHH:mm") &&
              x.status === StatusApprovalEnum.Pendant
          );

          this.totalMasters =
            notApproved === undefined ? 0 : notApproved.length;

          this.exibirDadosAprovacao =
            result.aprovacoes.find(
              (x) =>
                x.pessoa[0].email === localStorage.getItem("useremail") &&
                x.status === StatusApprovalEnum.Pendant
            ) !== undefined;

          this.dataCriacao = result.dt_criacao;
          this.horaCriacao = moment(result.dt_criacao).format("HH:mm");
        }
      });
  }

  obterDadosFiliais(): void {
    this.termRepository
      .getBranch(this.termoAceiteId)
      .subscribe((result: any) => {
        this.filiais = result;
        this.apiPayload.filiais = this.filiais.map(f=>`${f.cnpjInscricao}${f.identificacaoFilial}`);
      });
  }

  exibirTermo(ocultar: boolean): void {
    this.showDisclaimer = ocultar;
    this.showMainData.emit(!ocultar);
  }

  obterDadosEmpresa(): void {
    this.empresa = JSON.parse(sessionStorage.getItem("defaultCompany"))[0];
  }

  reprovarTermo(): void {
    this.apiPayload.ds_status = StatusApprovalEnum.Reproved;
    this.exibirAutorizacao = true;
  }

  getInscricao(filial: DdaBranchModel): string {
    return `${filial.cnpjInscricao}${filial.identificacaoFilial}`;
  }

  enable(): void {
    this.enableAction = !this.enableAction;
  }

  exibirMensagem(err: any): void {
    if (err.code !== 400) {
      this.toast.callModalMessage(null, err.message[0], null, null, null);
    } else {
      this.toast.callModalMessage(null, err, null, null, null);
    }
  }

  fecharModal(): void {
    this.exibirAutorizacao = false;
    this.showMainData.emit(true);
  }

  fecharModalConclusao(): void {
    this.exibirConclusao = false;
    this.showMainData.emit(true);
  }

  respostaApi(value: any): void {
    this.exibirAutorizacao = false;
    if (value.status === "success") {
      this.exibirConclusao = true;
      this.showMainData.emit(true);
    } else {
      this.exibirConclusao = false;
      this.exibirMensagem(value.error);
    }
  }
}
