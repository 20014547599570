import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { PixBeneficiariesResponse, PixInsituticoesResponse, PixBeneficiarioNovoResponse, PixPrePagamentoResponse, PixConsultaContaResponse, PixTermoOperadorResponse} from './pix-beneficiaries.entity';

@Injectable({
  providedIn: 'root',
})
export class PixGetBeneficiariesDataRepository {

  constructor(private http: HttpClient) {}

  public getPixBeneficiaries() :Observable<PixBeneficiariesResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.post<PixBeneficiariesResponse>(`${environment.URLBase}v2/beneficiarios/filtro`, {}, httpOptions);
  }

  public getPixInsituticoes(nome?: any) :Observable<PixInsituticoesResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
    if(nome && isNaN(nome)){
      return this.http.get<PixInsituticoesResponse>(`${environment.URLBase}v1/pix/transacoes/listar-instituicoes-pagamento?nome=${nome}`, httpOptions);
    }else if(nome && !isNaN(nome)){
      return this.http.get<PixInsituticoesResponse>(`${environment.URLBase}v1/pix/transacoes/listar-instituicoes-pagamento?identificador=${nome}`, httpOptions);
    }else{
      return this.http.get<PixInsituticoesResponse>(`${environment.URLBase}v1/pix/transacoes/listar-instituicoes-pagamento`, httpOptions);
    }
  }

  public getPixBeneficiariosTodos() :Observable<PixBeneficiariesResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.get<PixBeneficiariesResponse>(`${environment.URLBase}v1/pix/beneficiarios/favoritos/todos`, httpOptions);
  }

  public postPixBeneficiariosNovo(body) :Observable<PixBeneficiarioNovoResponse> {
    const params = {
      "nome_beneficiario": body.nome_beneficiario,
      "nr_cpf_cnpj": body.nr_cpf_cnpj,
      "tipo_chave":body.tipo_chave,
      "chave": body.chave,
      "agencia": body.agencia,
      "cod_banco": body.cod_banco,
      "num_conta": body.num_conta,
      "nome_banco": body.nome_banco,
      "tipo_conta": body.tipo_conta,
      "fl_outra_titularidade": body.fl_outra_titularidade
    };
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.post<PixBeneficiarioNovoResponse>(`${environment.URLBase}v1/pix/beneficiarios/Novo`, params, httpOptions);
  }

  public deletePixBeneficiarios(idbeneficiario: number) :Observable<any>{
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.delete<any>(`${environment.URLBase}v1/pix/beneficiarios/Excluir/${idbeneficiario}`, httpOptions);
  }
  
  public deletePixBeneficiariosChave(idbeneficiarioChave: number, idbeneficiario: number) :Observable<any>{
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.delete<any>(`${environment.URLBase}v1/pix/ExcluirChave/${idbeneficiarioChave}/${idbeneficiario}`, httpOptions);
  }

  public getPixBancoChave(ispb) :Observable<PixBeneficiariesResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.get<PixBeneficiariesResponse>(`${environment.URLBase}v1/Pix/transacoes/listar-instituicoes-pagamento?identificador=${ispb}`, httpOptions);
  }
  
  public postPixPrePagamento(body) :Observable<PixPrePagamentoResponse> {
    const params = {
        "num_canal_pagamento": 3, // TODO: 3 internet banking / 9 mobile
        "chave": body.chave,
        "valor": body.valor,
        "data_transferencia": body.data_transferencia,
        "descricao": body.descricao,
        "conta_origem": body.conta_origem,
        "conta_destino": body.conta_destino,
        "confirme_linha_credito": body.confirme_linha_credito
    };
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.post<PixPrePagamentoResponse>(`${environment.URLBase}v1/Pix/transacoes/realizar-validacao-pre-pagamento`, params, httpOptions);
  }

  public getConsultarConta(numeroConta: string) :Observable<PixConsultaContaResponse> {
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.get<PixConsultaContaResponse>(`${environment.URLBase}v1/Pix/limite/consultaLimiteConta/${numeroConta}`,httpOptions);
  }

  //INICIA FLUXO DE ACEITE DE TERMO PARA QUE FIQUE PENDENTE DOS MASTERS APROVAR
  public postPixTermoOperador(parametros) :Observable<PixTermoOperadorResponse> {
    const params = {
        "tipo_adesao": parametros.tipo_adesao,
        "num_conta": parametros.num_conta
    };
    const httpOptions = {
      headers: getHeader(),
    };
      return this.http.post<PixTermoOperadorResponse>(`${environment.URLBase}v1/Pix/termoAdesao/operador`, params, httpOptions);
  }
}
