import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StepperModel } from '@src/core/domain/business-models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fibra-pix-term',
  templateUrl: './pix-term.component.html',
  styleUrls: ['./pix-term.component.scss']
})
export class PixTermComponent implements OnInit {

  @Input() public accountData;
  @Output() public acceptPixTerms = new EventEmitter();
  @Output() setTerm = new EventEmitter();
  @Output() response = new EventEmitter();
  @Input() firstTime = false;
  public checked = false;
  public showModalValidation = false;
  public showModalRegistratoError = false;
  public formStep1: FormGroup;
  public apiCallback = 85;
  public currentStep = 1;
  public errorSend = false;

  public payload;

  public step: StepperModel[] = [
    {
      step: 1,
      name: 'Preenchimento',
    },
    {
      step: 2,
      name: 'Comprovante',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.apiCallback = this.accountData.isApprovals ? 85 : 86;
    this.payload = this.accountData.isApprovals ? {
      tipo_adesao: 1, num_conta: this.accountData.num_conta
    } : this.accountData.id;
    this.formStep1 = this.formBuilder.group({
      fraseDeSeguranca: [null, [Validators.required]]
    });

    if(this.accountData.isApprovals === 'refusedKEy') {
      this.payload = this.accountData.operation;
      this.apiCallback = 89;
    }
  }

  get campoFraseDeSeguranca() { return this.formStep1.get('fraseDeSeguranca'); }
  public accept() {
    this.acceptPixTerms.emit('true');
    this.response.emit('mundo');
  }

  public setCheckbox() {
    this.checked = !this.checked
  }

  public goBack() {
    // localStorage.setItem('view-selecionada', 'termo-aprovacoes');
    this.setTerm.emit('true');
    // window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
  }

  closeModal($event) {
    this.showModalValidation = false;
  }

  openModal() {
    this.showModalValidation = true;
  }

  responseApi(validation) {
    this.response.emit(validation);
    if (validation) {
      if (this.errorSend) {
        this.showModalRegistratoError = true;
      } else {
        this.currentStep = 2;
      }
    }
    this.goBack();
    this.closeModal(false);

  }




}
