<ng-template #content let-modal>
    <div class="dda-container">
      <div class="modal-header">
        <div class="generic-info">
          <img alt="" [src]="imgSrc" class="img-medium mb-3" />
      <h5
        class="font-weight-bold"
        [ngClass]="{'fg-yellow': typeMessage === 'warn', 'fg-green': typeMessage === 'success', 'fg-red': typeMessage === 'error'}"
      >
        {{ title }}
      </h5>
      <span class="label-medium mt-2">{{ message }}</span>
        </div>
      </div>
      <div class="text-center">
      <div class="col-12">
        <button
            type="button"
            [ngClass]="{ 'mb-3': recusar }"
            class="leaked-button mt-2"
            (click)="irParaHome()"
          >
            Voltar para a home
          </button>
      </div> 
      <div *ngIf="!recusar" class="col-12">
        <button
            type="button"
            (click)="fechar()"
            class="secondary-button mt-2 mb-3"
          >
            Fechar
          </button>
      </div> 
    </div>
  </div>
  </ng-template>
  
