import { GetAllAccountTypeUsecase } from './../../../../core/usecases/account/get-all-accountTypes.usecase';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DuplicateBillEntity } from '@src/data/repository/charge/duplicate-bill/duplicate-bill.entity';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  optionWallet,
  titleValues,
  periods,
  SelectorDataShare,
  FilterBillDataShare,
} from '@src/data/repository/data-share-repository/index';

// model
import { DuplicateBillDataShare } from '@src/data/repository/data-share-repository';
import { DropdownList } from '@src/core/domain/business-models';
import { isArray } from 'util';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-page-duplicate-bill',
  templateUrl: './duplicate-bill.component.html',
  styleUrls: ['./duplicate-bill.component.scss'],
})
export class DuplicateBillComponent implements OnInit {
  public duplicateFilter: FormGroup;
  public periods: DropdownList;

  public params: any;
  duplicates: Array<DuplicateBillEntity> = [];
  date: Date;
  dateInitial: string;
  periodDate: number;
  periodDays: number;
  periodCalendar: any = {};
  dateEnd: string;
  period: number;

  dataInicio: string;
  dataFim: string;
  optionsProfile;

  public optionWallet = optionWallet;
  public titleValues = titleValues;

  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;


  private ShowToast(err: any): void {    
    const isMessageServer: boolean = !!(err && err.error && err.error.message && err.error.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, err.error.message[0], null, null, null);
  }

  constructor(
    private formBuild: FormBuilder,
    private duplicateShare: DuplicateBillDataShare,
    private shareSelector: SelectorDataShare,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private shareFilterBill: FilterBillDataShare
  ) {
    this.periods = periods;
    this.optionsProfile = [];

    this.duplicateFilter = this.formBuild.group({
      ourNumber: [''],
      yourNumber: [''],
      draweeName: [''],
      codigo_especie: [''],
      account: [''],
      dateBill: [''],
      titleValue: [''],
    });

    this.date = new Date();
    this.period = null;
  }

  ngOnInit() {
    this.shareSelector.setValue('duplicate-bill');
  }

  search() {
    const {
      codigo_especie,
      account,
      ourNumber,
      yourNumber,
      draweeName,
      titleValue,
    } = this.duplicateFilter.value;

    const params: any = {
      num_carteira: codigo_especie,
      num_conta: account,
      num_nosso_numero: ourNumber,
      num_seu_numero: yourNumber,
      nome_sacado: draweeName,
      dt_inicio: this.dataInicio,
      dt_final: this.dataFim,
      vlr_titulo: titleValue ? titleValue : '0',
    };

    this.duplicateShare.setValue(params);
  }

  sendFilter(value) {
    this.shareFilterBill.setValue(value);
  }

  public formatDate() {
    this.period = this.periodDays;
    this.dataInicio = this.date.toISOString().slice(0, 10);
    const auxDate = new Date(this.date);
    this.periodDate = auxDate.setDate(this.date.getDate() - this.period);
    const endDate = new Date(this.periodDate);
    this.dataFim = endDate.toISOString().slice(0, 10);
  }

  resolveDate(value) {
    this.params = JSON.stringify(value);
  }

  public changePeriod(days: number) {
    this.periodDays = days;
    this.periodCalendar = undefined;

    this.formatDate();
  }

  public changePeriodDate(period: { to: NgbDate; from: NgbDate }) {
    this.dataInicio = `${period.from.year}-${this.adjustDate(
      period.from.month
    )}-${this.adjustDate(period.from.day)}`;
    this.dataFim = `${period.to.year}-${this.adjustDate(
      period.to.month
    )}-${this.adjustDate(period.to.day)}`;
  }

  private adjustDate(date) {
    if (Number(date) < 10) {
      return `0${date}`;
    } else {
      return date;
    }
  }
  private requestAccount(): void {
    this.getAllAccountTypes.execute().subscribe(this.requestSuccess, (error) => this.ShowToast(error));
  }

  private requestSuccess = (value) => {
    if (isArray(value.data)) {
      value.data.map((e, i) => {
        this.optionsProfile.push({
          indice: e.num_indice,
          name: `${e.num_conta}`,
          value: e.num_conta,
          modality_account: e.cod_modalidade,
          selected: false,
        });
      });
    } else {
      this.optionsProfile.push({
        indice: value.data.num_indice,
        name: `${value.data.num_conta}`,
        value: value.data.num_conta,
        modality_account: value.data.cod_modalidade,
        selected: false,
      });
    }
  };
}
