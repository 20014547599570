import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { getHeader } from '@src/core/base/header';
import { PendencyLimitsApprovalResository } from '@src/core/repositories/dashboard/pendency-limits-approval.repository';
import { PendencyApprovalPix } from '@src/core/domain/dashboard/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class GetPendencyLimitsApprovalRepository extends PendencyLimitsApprovalResository {

  constructor(private http: HttpClient) {
    super();
  }

  public getPendencyApproval(): Observable<PendencyApprovalPix> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http.get<PendencyApprovalPix>(`${environment.URLBase}v1/limitetransacional/dashboard-aprovacao-pendente`, httpOptions);
  }

}
