<div *ngIf="duplicates != undefined && !loading">
  <div class="duplicate-group" *ngIf="duplicates.length > 0">
    <button
    type="button"
    (click)="requestDownloadBills()"
    class="secondary-button next mt-3" style="width:15%;">Gerar boletos selecionados</button>
  </div>
  
  <br/>

  <table style="
  width: 100%;
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  box-sizing: border-box;
  ">
      <thead style=" 
          vertical-align: middle;color: #4d86bf;
          height: 76px !important;
          border-collapse: collapse;
          background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 10%, #ffffff 47%, #E0E0E0 316%);
          line-height: 21px;">
        <tr>
          <td style="text-align:center;width:81px;"><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></td>
          <td>Nome do <br/> pagador</td>
          <td>Carteira</td>
          <td>Seu <br/> número</td>
          <td>Nosso <br/> número</td>
          <td>Data de <br/> vencimento</td>
          <td>Valor</td>
          <!-- <td>Nosso número <br/> correspondente</td> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let duplicate of duplicates; let i=index" style="line-height:86px; vertical-align:middle;color:#082a4d;border-bottom: 1px solid #CCCCCC;
        background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 10%, #ffffff 47%, #E0E0E0 316%);font-size: 13px;">
          <td style="text-align:center">
            <input type="checkbox"  value="{{duplicate.code}}" [(ngModel)]="duplicate.selected" (change)="onChecked(duplicate)" />
          </td>
          <td>
              <span class="duplicate-name" data-hj-suppress>{{ duplicate.nome_sacado }}</span>
          </td>
          <td>
              <span class="duplicate-month">
                  {{ duplicate.num_carteira }}
                </span>
          </td>
          <td>
              <span class="duplicate-your-number">
                  {{ duplicate.num_seu_numero }}
              </span>
          </td>
          <td>
              <span class="duplicate-our-number">
                  {{ duplicate.num_nosso_numero }}
              </span>
          </td>
          <td>
              <span class="duplicate-date-final">
                  {{ duplicate.dt_vencimento | date: 'd/M/yyyy' }}
              </span>
          </td>
          <td>
              <span class="duplicate-total-money"
              >R$
              {{
                isNull(duplicate.vlr_titulo)
                  ? '0,0'
                  : (duplicate.vlr_titulo | money)
              }}
            </span>
          </td>
          <!-- <td>
              <span class="duplicate-our-number-corresp">
                  {{ duplicate.num_nosso_numero_corresp }}
              </span>
          </td> -->
        </tr>
      </tbody>
    </table>
  

  

</div>

<div class="empty-info" *ngIf="hide">
  <fibra-generic-info
    type="warn"
    title="Nenhum resultado encontrado"
    message="Tente uma nova pesquisa inserindo novos parâmetros."
  >
  </fibra-generic-info>
</div>

<div class="d-flex justify-content-center position loading" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>