<fibra-toast-error-message [color]="typToastColor"></fibra-toast-error-message>

  <div class="group-items" [formGroup]="investimentFormGroup" #investmentsForm="ngForm">
    <div class="period" *ngIf="!noDataProdutos">
      <div class="label-period"> 
        <span class="icon-alert">
          <span
            class="icon-info investimentsNotification"
            [appTooltip]="tooltipAll"
          >
            <img class="info-filter"src="/assets/png/info.png"/> 
            <div #tooltipAll class="tooltip-custom">
              <p>Exibe o valor da posição consolidada
                conforme data informada.</p>
              <div></div>
            </div>
          </span>
        </span>
        Data da Posição
      </div>
      <fibra-datepicker 
        formControlName="investimentDate" 
        [minDate]="minDate" 
        [maxDate]="maxDate"
        (dateSelected)="selectDate($event)" 
        (dateInvalid)="dateInvalid($event)" 
        placement="bottom"
      ></fibra-datepicker>
      <div class="label-period">Produtos</div>
      <fibra-form-control-group>
          <fibra-form-control [style.width]="'auto'">
            <fibra-dropdown
              id="posicaoConsolidada"
              class="dropdown-product-list"
              (change)="filterProduct($event)" 
              [options]="produtos" 
              customClass="select-option posicaoConsolidada" 
              placeholder="Selecione" 
              removeShadown="true">
            </fibra-dropdown>
          </fibra-form-control>
      </fibra-form-control-group>
    </div>
  </div>
  
  <div class="icon-invalid-date" *ngIf="dataMaior">
    <img class="info-invalid-date"src="/assets/png/iconErro.png"/>
    <span class="invalid-date-text">
      A data deve ser menor ou igual a data atual.
    </span>
  </div>

  <div class="icon-invalid-date" *ngIf="dataInvalida">
    <img class="info-invalid-date"src="/assets/png/iconErro.png"/>
    <span class="invalid-date-text">
      Data inválida.
    </span>
  </div>
  
  <div class="lado-a-lado">
    <div class="datatable" *ngIf="loading && !hasError">
      <div class="table-title">
        <div class="table-item">
          <span class="item-font">Produto</span>
        </div>
        <div class="table-item-emissao">
          <span class="item-font">Data de emissão</span>
        </div>
        <div class="table-item">
          <span class="item-font">Data de Vencimento</span>
        </div>
        <div class="table-item">
          <span class="item-font" style="float: right;">Valor aplicado</span>
        </div>
        <div class="table-item">
          <span class="item-font" style="float: right;">Rendimento bruto</span>
        </div>
        <div class="table-item">
          <span class="item-font" style="float: right; padding-right: 4vw;">Valor líquido</span>
        </div>
        <div class="table-item">

        </div>


      </div>
      <div class="table-body" *ngIf="!noData">
        <div class="table-content" *ngFor="let item of investiments['itens']; let i = index" >
          <div class="table-row">
            <div class="table-item">
              <span class="item-font">{{item.cod_prod }}</span>
            </div>
            <div class="table-item">
              <span class="item-font">{{item.dat_emissao | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div class="table-item">
              <span class="item-font">{{item.dat_vencto | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div class="table-item">
              <span class="item-font" style="float: right;">R$ {{ item.val_aplic | number: '1.2-2' }}</span>
            </div>
            <div class="table-item">
              <span class="item-font" style="float: right;">R$ {{item.rend_bruto | number: '1.2-2' }}</span>
            </div>
            <div class="table-item">
                <span class="item-font" style="float: right; padding-right: 4vw;">R$ {{item.val_liquido | number: '1.2-2' }}</span>
              </div>
            <div class="table-item" data-toggle="collapse" attr.data-target="#collapse_{{i}}" class="accordion-toggle">
              <span 
                class="item-icon" 
                (click)="toogleIconArrow(i, item.num_operacao)" 
                data-toggle="collapse" 
                data-target="#collapseOne" 
                aria-expanded="true" 
                aria-controls="collapseOne">
                <img 
                  src="/assets/svg/arrow_down.svg" 
                  id="{{ i }}"
                  /> 
              </span>
            </div>
          </div>
          <div class="table-row-operacao" id="collapse_{{i}}" class="accordian-body collapse background-color-1">
            <div class="datatable warning-details" *ngIf="!loadingDetails">
              <div class="d-flex justify-content-center position loading-container">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div *ngIf="loadingDetails">
              <h1 class="operacao-title">Características da operação</h1>
              <div class="body-operacao">
                <div class="item-name">
                  Produto
                  <br>
                  <span class="item-value">
                    {{investimentsDetails.cod_prod }}
                  </span>
                </div>
                <div class="item-name">
                  Emissor
                  <br>
                  <span class="item-value">
                    {{investimentsDetails.emissor }}
                  </span>
                </div>
                <div class="item-name">
                  Quantidade
                  <br>
                  <span class="item-value">
                    {{investimentsDetails.quantidade }}
                  </span>
                </div>
                <div class="item-name">
                  Data de emissão
                  <br>
                  <span class="item-value">
                    {{investimentsDetails.dat_emissao | date: 'dd/MM/yyyy'}}
                  </span>
                </div>
                <div class="item-name">
                  Data de vencimento
                  <br>
                  <span class="item-value">
                    {{investimentsDetails.dat_vencto | date: 'dd/MM/yyyy'}}
                  </span>
                </div>
                <div class="item-name">
                  Taxa de operação
                  <br>
                  <span class="item-value">
                    {{investimentsDetails.tax_oper | number: '1.2-2'}}
                  </span>
                </div>
                <div class="item-name">
                  Percentual
                  <br>
                  <span class="item-value">
                    {{investimentsDetails.porc_indx_oper }}%
                  </span>
                </div>
                <div class="item-name">
                  Indexador
                  <br>
                  <span class="item-value">
                    {{investimentsDetails.indexador }}
                  </span>
                </div>

                <div class="item-name">
                  Valor aplicado
                  <br>
                  <span class="item-value">
                    R${{investimentsDetails.val_aplic | number: '1.2-2'}}
                  </span>
                </div>
                <div class="item-name">
                  Rendimento bruto
                  <br>
                  <span class="item-value">
                    R${{investimentsDetails.rend_bruto | number: '1.2-2'}}
                  </span>
                </div>
                <div class="item-name">
                  IR
                  <br>
                  <span class="item-value">
                    R${{investimentsDetails.impostos | number: '1.2-2'}}
                  </span>
                </div>
                <div class="item-name">
                  IOF
                  <br>
                  <span class="item-value">
                    R${{investimentsDetails.iof | number: '1.2-2'}}
                  </span>
                </div>
                <div class="item-name">
                  Valor bruto
                  <br>
                  <span class="item-value">
                    R${{investimentsDetails.val_bruto | number: '1.2-2'}}
                  </span>
                </div>
                <div class="item-name">
                  Valor líquido
                  <br>
                  <span class="item-value">
                    R${{investimentsDetails.val_liquido | number: '1.2-2'}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-body" *ngIf="noData && !noDataProdutos">
          <fibra-generic-info type="warn" title="Nenhum resultado encontrado."
            message="Não há informações para serem apresentadas.">
          </fibra-generic-info>
      </div>
      
      <div class="table-body" *ngIf="noDataProdutos">
        <fibra-generic-info type="warn" title="Não há informações para serem apresentadas.">
        </fibra-generic-info>
      </div>
    </div>
  </div>


  <div class="datatable warning-display" *ngIf="!loading">
    <div class="d-flex justify-content-center position loading-container">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <div class="datatable warning-display" *ngIf="hasError && loading">
    <div class="empty-info">
      <fibra-generic-info type="error" title="Ocorreu um erro."
        message="No momento não foi possível concluir sua pesquisa. Tente novamente.">
      </fibra-generic-info>
    </div>
  </div>


<fibra-card-value-investments
  *ngIf="!noData"
  [valorTotal]="total"
></fibra-card-value-investments>