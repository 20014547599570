import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IAcceptDoc } from '@src/data/repository/accept-doc/accept-doc.entity';
import { OpenBankingRepository } from '@src/data/repository/openbanking/openbanking.repository';
import { ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AcceptDocPageComponent } from '../../components/accept-doc/accept-doc.component';
import { OpenbankingOnboardingComponent } from '../../components/openbanking-onboarding/openbanking-onboarding.component';

@Component({
    selector: 'fibra-openbanking',
    templateUrl: './openbanking.component.html',
    styleUrls: ['./openbanking.component.scss']
})
export class OpenbankingComponent implements OnInit, OnDestroy {
   
    private _subFaltaAceitarTermoDeUso: Subscription;

    public readonly HOME = 0;
    public readonly ONBOARDING = 1;
    public readonly TERMSOFUSE = 2;

    public currentPresentation: number = this.HOME;

    public onBoardingDone = true;
    public loading = false;

    public get termsOfUseAccepted(): boolean {
        return this.termsOfUse.readOnly;
    }

    public get title(): string | null {
        switch (this.currentPresentation) {
            case this.ONBOARDING: return "Onboarding";
            case this.TERMSOFUSE: return "Termo de Uso"
            default: return "Open Finance";
        }
    }

    public termsOfUse: IAcceptDoc = {
        docTitle: 'Termo de uso',
        docUrl: 'term-user-openbanking',
        buttonTittle: 'Aderir',
        checkTitle: 'Li e concordo com o Termo de Uso.',
        readOnly: true
    };

    public get self() {
        return this;
    }

    private _onBoardingComponent: OpenbankingOnboardingComponent;
    
    @ViewChild(OpenbankingOnboardingComponent, null) public set onBoardingComponent(value: OpenbankingOnboardingComponent) {
        this._onBoardingComponent = value;
    }

    private _acceptDocPageComponent: AcceptDocPageComponent;
    
    @ViewChild(AcceptDocPageComponent, null) public set acceptDocPageComponent(value: AcceptDocPageComponent) {
        this._acceptDocPageComponent = value;
    }

    constructor(private _openBankingRepository: OpenBankingRepository) {
        
    }

    private load(): void {
        this.loading = true;

        this._subFaltaAceitarTermoDeUso = this._openBankingRepository.getUsuarioFezOnboardingTermoDeUso()
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                result => {
                    this.onBoardingDone = result.fezonboarding;
                    this.termsOfUse.readOnly = result.aceitoutermodeuso;
                }
            );
    }

    public okOnboarding(): void {
        const done: Subject<boolean> = new Subject<boolean>();

        this._openBankingRepository.setUserDoneOnboarding()
            .pipe(finalize(() => {
                this._onBoardingComponent.busy = false;
                this.onBoardingDone = true;
                done.next();
                done.complete();
            }))
            .subscribe(() => {
                this.currentPresentation = this.HOME;
            });

        timer(100).pipe(takeUntil(done)).subscribe(() => {
            this._onBoardingComponent.busy = true;
        });
    }

    public onReverOnboarding(): void {
        this.currentPresentation = this.ONBOARDING;
    }

    public acceptTermsOfUse(): void {
        const done: Subject<boolean> = new Subject<boolean>();

        this._openBankingRepository.setUserAcceptedTermsOfUse()
            .pipe(finalize(() => {
                this._acceptDocPageComponent.showSpinner = false;
                this.termsOfUse.readOnly = true;
                done.next();
                done.complete();
            }))
            .subscribe(() => {
                this.currentPresentation = this.HOME;
            });

        timer(100).pipe(takeUntil(done)).subscribe(() => {
            this._acceptDocPageComponent.showSpinner = true;
        });
    }

    public onReverTermoDeUso(): void {
        this.currentPresentation = this.TERMSOFUSE;
    }

    public backToHome(): void {
        this.currentPresentation = this.HOME;
        this.goToTop();
    }

    private goToTop(): void {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    ngOnInit(): void {
        this.load();
    }

    ngOnDestroy(): void {
        if (this._subFaltaAceitarTermoDeUso) {
            this._subFaltaAceitarTermoDeUso.unsubscribe();
        }
    }
}
