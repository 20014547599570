<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Informe senha e token</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 12.6076L1.00073 1M17 1.00106L1 12.6076L17 1.00106Z"
            stroke="#082A4D"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <p>Senha</p>
      <div class="iconPassword" (click)="showPassword()">
        <img
          src="/assets/svg/icon_show_gray.svg"
          class="eye"
          alt="View password"
          *ngIf="iconPassword"
        />
        <img
          src="/assets/svg/icon_hide_gray.svg"
          class="eye"
          alt="View password"
          *ngIf="!iconPassword"
        />
      </div>
      <input
        type="password"
        [(ngModel)]="password"
        name="inputPassword"
        class="passwordModal"
        (keyup)="onChangePassword($event.target.value)"
        id="password"
      />
      <p>Token</p>
      <input
        type="text"
        [(ngModel)]="tokenConfirmation"
        name="inputToken"
        onlyNumber
        maxlength="6"
        (keyup)="onChangeToken($event.target.value)"
      />
    </form>

    <div *ngIf="msgPasswordError" class="msg-password-error">
      <div class="row msg-password-error-content">
        <div class="col-2">
          <img class="icon-warning" src="/assets/svg/iconWarningWhite.svg" />
        </div>
        <div class="col-8">
          <span class="msg-error-title">Senha ou token incorretos.</span>
          <span class="msg-error-txt"
            >Após {{ countPass }}
            {{ countPass !== 1 ? "tentativas" : "tentativa" }} seu acesso será
            bloqueado</span
          >
        </div>
        <div class="col-2">
          <a (click)="closeError()"
            ><img class="icon-close" src="/assets/svg/iconCloseWhite.svg"
          /></a>
        </div>
      </div>
    </div>

    <div *ngIf="msgPasswordBlocked" class="msg-password-error">
      <div class="row msg-password-error-content">
        <div class="col-2">
          <img class="icon-warning" src="/assets/svg/iconWarningWhite.svg" />
        </div>
        <div class="col-8">
          <span class="msg-error-title">Senha bloqueada</span>
          <span class="msg-error-txt"
            >Clique em
            <a class="forgot-password" (click)="logout()"
              >"Esqueceu sua senha"</a
            >
            para gerar uma nova.</span
          >
        </div>
        <div class="col-2">
          <a (click)="closeError()"
            ><img class="icon-close" src="/assets/svg/iconCloseWhite.svg"
          /></a>
        </div>
      </div>
    </div>
    <div *ngIf="msgApiError" class="msg-password-error">
      <div class="row msg-password-error-content">
        <div class="col-2">
          <img class="icon-warning" src="/assets/svg/iconWarningWhite.svg" />
        </div>
        <div class="col-8">
          <span class="msg-error-title">Ocorreu um erro no envio!</span>
          <span class="msg-error-txt"
            >Tente novamente.</span
          >
        </div>
        <div class="col-2">
          <a (click)="closeError()"
            ><img class="icon-close" src="/assets/svg/iconCloseWhite.svg"
          /></a>
        </div>
      </div>
    </div>
  </div>

  <button
    class="btn btn-primary btn-confirm"
    [disabled]="deactivate || sending"
    (click)="verifyPassword()"
  >
    <div class="d-flex justify-content-center position" *ngIf="loading">
      <div class="spinner-border text-primary" role="status"></div>
    </div>
    {{ TitleButton }}
  </button>
</ng-template>