import { Injectable } from '@angular/core';
import { UseCase } from '@src/core/base/use-case';
import {
  ListQueryRequest,
  ExcelExportListQueryModel,
} from '@src/core/domain/query/query.model';
import { QueryRepository } from '@src/core/repositories/query/query.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetAllQueryExcelExportUsecase
  implements UseCase<ListQueryRequest, ExcelExportListQueryModel> {
  constructor(private repository: QueryRepository) {}

  execute(params: ListQueryRequest): Observable<ExcelExportListQueryModel> {
    return this.repository.getAllQueryExcelExport(params);
  }
}
