import { Component, OnInit,OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComexReportDataShare } from '@src/core/usecases/data-share/comex-report-data-share.service';
import { IssuedWarrantyEntity, ReceivedWarrantyEntity } from '@src/data/repository/warranties/warranties.entity';
import { WarrantiesDataRepository } from '@src/data/repository/warranties/warranties.repository';

@Component({
  selector: 'fibra-warranties',
  templateUrl: './warranties.component.html',
  styleUrls: ['./warranties.component.scss']
})
export class WarrantiesComponent implements OnInit, OnDestroy {

  public loading = false;
  public noData = false;
  public contractNum = '';
  public filterByContract = false;
  public filterBySer = true;
  public hasError = false;
  public fieldRequired = false;
  public filterForm: FormGroup;
  public isEmi = false;
  public isRec = true;
  public receivedArray: ReceivedWarrantyEntity[];
  public issuedArray: any;
  public selectedFilter = '';
  public radioSelected = '';
  public serDate = new Date();
  public periods = [
    { name: 'Recebidas', value: 'rec' },
    { name: 'Emitidas', value: 'emi' }
  ];

  constructor(private formBuilder: FormBuilder,
    private warrantyService: WarrantiesDataRepository,
    public comexReport: ComexReportDataShare) {
    this.filterForm = this.formBuilder.group({
      filter: ['ser'],
      serDate: [this.serDate.toLocaleDateString()],
      contractNum: ['']

    });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem("excel_info")
  }

  ngOnInit() {

    this.selectedFilter = 'rec';
    this.radioSelected = 'ser';
    this.sendWarrantyData();
  }

  public changeFilterType(type: string) {
    this.fieldRequired = false;
    if (type === 'ser') {
      this.filterBySer = true;
      this.filterByContract = false;
      this.radioSelected = 'ser';
    } else if (type === 'contract') {
      this.filterBySer = false;
      this.filterByContract = true;
      this.radioSelected = 'contract';
    }
  }

  public sendWarrantyData() {
    this.loading = true;
    this.fieldRequired = false;
    if (this.radioSelected === 'ser') {
      if (this.selectedFilter === 'rec') {
        sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","dt_fim":"'+this.serDate+'"  }')
        localStorage.setItem('view-atual', 'garantias-recebidas');
        this.receivedArray = new Array();
        this.warrantyService.getReceivedWarratyEmSer(this.serDate.toJSON().slice(0, 10)).subscribe((warrData) => {
          if (warrData.data.length === 0) {
            this.hasError = false;
            this.loading = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.receivedArray = warrData.data;
            this.receivedArray.forEach((elem) => {
              elem.valorFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
            })
            this.hasError = false;
            this.loading = false;
            this.noData = false;
            this.isRec = true;
            this.isEmi = false;
            this.comexReport.setValue(this.receivedArray);
            this.comexReport.setReportInfo({
              title: 'Garantias',
              subtitle1: 'Operação',
              subTitle1Value: 'Recebidas',
              subtitle2: 'Operações em ser',
              subtitle2Value: this.serDate.toLocaleDateString()
            })
            localStorage.setItem('view-atual', 'garantias-recebidas');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, () => {
          this.hasError = true;
          this.loading = false;
          this.noData = false;
          localStorage.setItem('canLoadReport', 'false');
        })
      } else {
        sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","dt_fim":"'+this.serDate+'"  }')
        localStorage.setItem('view-atual', 'garantias-emitidas');
        this.issuedArray = new Array();
        this.warrantyService.getIssuedWarratyEmSer(this.serDate.toJSON().slice(0, 10)).subscribe((warrData: any) => {
          if (warrData.data.length === 0) {
            this.hasError = false;
            this.loading = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.issuedArray = warrData.data;
            this.issuedArray.forEach((elem) => {
              elem.valorFormatado = this.formatUsd(elem.valor_moeda_estrangeira);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
            })
            this.hasError = false;
            this.loading = false;
            this.noData = false;
            this.isRec = false;
            this.isEmi = true;
            this.comexReport.setValue(this.issuedArray);
            this.comexReport.setReportInfo({
              title: 'Garantias',
              subtitle1: 'Operação',
              subTitle1Value: 'Emitidas',
              subtitle2: 'Operações em ser',
              subtitle2Value: this.serDate.toLocaleDateString()
            })
            localStorage.setItem('view-atual', 'garantias-emitidas');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, () => {
          this.hasError = true;
          this.loading = false;
          this.noData = false;
          localStorage.setItem('canLoadReport', 'false');
        })
      }

    } else {
      this.contractNum = this.filterForm.value['contractNum'];
      if (this.contractNum === '' || this.contractNum === '0000000000') {
        this.loading = false;
        this.fieldRequired = true;
        //alert('campo obrigatório!');
        return;
      }

      sessionStorage.setItem('excel_info','{"selectedFilter":"'+this.selectedFilter+'","referencia":"'+this.contractNum+'"  }')
      if (this.selectedFilter === 'rec') {
        this.receivedArray = new Array();
        this.warrantyService.getReceivedWarrantyContract(this.contractNum).subscribe((warrData) => {
          if (warrData.data.length === 0) {
            this.hasError = false;
            this.loading = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.receivedArray = warrData.data;
            this.receivedArray.forEach((elem) => {
              elem.valorFormatado = this.formatUsd(elem.valor_moeda_estrangeira_or);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
            })
            this.hasError = false;
            this.loading = false;
            this.noData = false;
            this.isRec = true;
            this.isEmi = false;
            this.comexReport.setValue(this.receivedArray);
            this.comexReport.setReportInfo({
              title: 'Garantias',
              subtitle1: 'Operação',
              subTitle1Value: 'Recebidas',
              subtitle2: 'Referência banco',
              subtitle2Value: this.contractNum
            })
            localStorage.setItem('view-atual', 'garantias-recebidas');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, () => {
          this.hasError = true;
          this.loading = false;
          this.noData = false;
          localStorage.setItem('canLoadReport', 'false');
        })
      } else {
        this.issuedArray = new Array();
        this.warrantyService.getIssuedWarrantyContract(this.contractNum).subscribe((warrData: any) => {
          if (warrData.data.length === 0) {
            this.hasError = false;
            this.loading = false;
            this.noData = true;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.issuedArray = warrData.data;
            this.issuedArray.forEach((elem) => {
              elem.valorFormatado = this.formatUsd(elem.valor_moeda_estrangeira_or);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_vencto = this.formatDate(elem.dt_vencto);
            })
            this.hasError = false;
            this.loading = false;
            this.noData = false;
            this.isRec = false;
            this.isEmi = true;
            this.comexReport.setValue(this.issuedArray);
            this.comexReport.setReportInfo({
              title: 'Garantias',
              subtitle1: 'Operação',
              subTitle1Value: 'Emitidas',
              subtitle2: 'Referência banco',
              subtitle2Value: this.contractNum
            })
            localStorage.setItem('view-atual', 'garantias-emitidas');
            localStorage.setItem('canLoadReport', 'true');
          }
        }, () => {
          this.hasError = true;
          this.loading = false;
          this.noData = false;
          localStorage.setItem('canLoadReport', 'false');
        })
      }
    }
  }

  public switchFilter(evento) {
    this.fieldRequired = false;
    this.selectedFilter = evento;
  }

  public formatDate(data: string) {
    const date = `${data.slice(8, 10)}/${data.slice(5, 7)}/${data.slice(0, 4)}`;
    return date;
  }

  public formatUsd(value: number) {
    const val = value.toLocaleString('en-US', { minimumFractionDigits: 2 });
    return val;
  }

  public formatBrl(value: number) {
    const val = value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    return val;
  }

}
