import { Component, OnInit } from '@angular/core';
import { DebugDdaModel } from '@src/data/repository/dda/debug/debug-dda.model';
import { DebugDdaRepository } from '@src/data/repository/dda/debug/debug-dda.repository';
import { environment } from '@src/environments/environment';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'fibra-debug-dda',
  templateUrl: './debug-dda.component.html',
  styleUrls: ["../dda.scss",'./debug-dda.component.scss']
})
export class DebugDdaComponent implements OnInit {

  name = 'Angular';

  isDebug : boolean = environment.production;

  model: DebugDdaModel;

  roles: UserRole[];

  rows = [
    {id: 1, desc: "foo", showDetail: false}
  ]
  constructor(private debugDdaRespository: DebugDdaRepository) { }
  

  ngOnInit() {
    this.debugDdaRespository.get().subscribe(r=>{
      this.model = r;
    })

    this.roles = this.verificarRoles();
    this.roles.forEach(f=>{
      if(f.Name === "DDA.DDA_CONS_BOL") f.Name = "Consultar boletos registrados no canal DDA"
      if(f.Name === "DDA.DDA_REALIZA_PAG_BOL") f.Name = "Realizar pagamentos de boletos vencidos e a vencer"
      if(f.Name === "DDA.DDA_GER_ARQ_CNAB") f.Name = "Gerar arquivo CNAB"
      if(f.Name === "DDA.DDA_CONS_HIST") f.Name = "Histórico de ações dentro do DDA"

    });
  }

  verificarRoles(): Array<UserRole> {
    const token = sessionStorage.getItem('fibra-session');
    const decodedRoleDDA: any = jwt_decode(token);
    return decodedRoleDDA.UserRoles.filter(r => r.Name.startsWith('DDA.'));
  }

}

class UserRole {
  Name: string;
  Descricao: string;
  NumeroConta: string[];
}