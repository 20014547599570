<div class="fibra-card" [ngClass]="'bk-' + backgroundColor" name="fibra-card">
  <div class="fibra-card-title">
    {{ titleCard }}
    <span *ngIf="tooltipTitle" class="icon-info" [appTooltip]="tooltipTitle">
      <img src="/assets/svg/tooltip-alerta.svg" alt="logo"/>
    </span>
  </div>
  <div class="fibra-card-value">
    <ng-container [ngSwitch]="valueType" *ngIf="isVisibleValue">
      <ng-container *ngSwitchCase="'number'">
        <p>
          <small>R$</small>
          <span name="valor-card" class="valor-card" *ngIf="showData">
            {{ value | number:'1.2-2' }}
          </span>
          <span name="valor-card" *ngIf="!showData">
            *****
          </span>
        </p>
      </ng-container>

      <ng-container *ngSwitchDefault>
        {{ value }}
      </ng-container>
    </ng-container>
    <div class="hide-value" *ngIf="!isVisibleValue">
      <div></div> <div></div> <div></div>
    </div>
  </div>
  <div *ngIf="warn" class="fibra-card-msn">
    {{ warn }}
  </div>
</div>
