<fibra-toast-error-message [color]="typToastColor"></fibra-toast-error-message>
<div class="main-selector">
    <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" id="content-body body-content" id="body-scroll" *fibraHasRole="'INVEST.CONS_INV'">
    <div class="div">
        <fibra-breadcrumb bottom="0" infoAccount="Investimentos" roule="Investimentos" arrow="false" [downloadDoc]="true" (infoReport)="exportScreen($event)" (click)="changeTable()"></fibra-breadcrumb>
        <div class="navbar-investimento">
            <div class="item-selected" (click)="changeNavbar('posicao')" id="posicao-item">
                <span class="item-font-selected" id="posicao-font"> Posição Consolidada </span>
            </div>
            <div class="item-unselected" (click)="changeNavbar('extrato')" id="extrato-item">
                <span class="item-font-unselected" id="extrato-font"> Extrato </span>
            </div>
        </div>
    </div>
    <section class="body-content">
        <fibra-investments-table *ngIf="posicao"></fibra-investments-table>
        <fibra-investments-extrato-table *ngIf="extrato"></fibra-investments-extrato-table>
    </section>
    <div *ngIf="posicao" class="footer">
        <p>*Alíquota de IR</p>
        <div class="info col-12">
            <div class="col-3">
                de 1 a 180 dias <span>22,5%</span>
            </div>
            <div class="col-3">
                de 181 a 360 dias <span>20%</span>
            </div>
            <div class="col-3">
                de 361 a 720 dias <span>17,5%</span>
            </div>
            <div class="col-3">
                Acima de 720 <span>15%</span>
            </div>
        </div>
    </div>
    <p *ngIf="posicao" class="footer-explain">**Incidência sobre aplicações com prazo inferior à 30 dias. IOF regressivo conforme portaria n° 264 da Receita Federal.</p>






</div>


<div>
    <section class="body-content report" id="body-report" *ngIf="report" style="position: absolute;">
        <investiments-report (closeReport)="changeReportView($event)" *fibraHasRole="'INVEST.CONS_INV'"></investiments-report>
    </section>
</div>