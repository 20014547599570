import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IAcceptDoc } from '@src/data/repository/accept-doc/accept-doc.entity';
import { AcceptDocRepository } from '@src/data/repository/accept-doc/accept-doc.repository';

@Component({
  selector: 'fibra-accept-doc',
  templateUrl: './accept-doc.component.html',
  styleUrls: ['./accept-doc.component.scss'],
})

export class AcceptDocPageComponent implements OnInit {

  public showSpinner: boolean = false;

  text = '';
  confirmation = false;

  @Input() doc: IAcceptDoc;
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  constructor(private termUserRepository: AcceptDocRepository) { }

  clicked() {
    this.confirm.emit();
    this.confirm.complete();
  }

  async ngOnInit() {
    if (this.doc.docUrl) {
      this.text = await this.termUserRepository.getDoc(this.doc.docUrl).toPromise();
    }

    if (!this.doc.docUrl) {
      throw new Error('O objeto IAcceptDoc contém todos os atributos como aobrigatórios');
    }
  }
}

