import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'barcode'
})

export class BarcodePipe implements PipeTransform {
  transform(value: any): any {
    const vl: any = String(value).replace(/[^0-9]/g, '');
    const calc = -1;

    if (vl && vl.length === 33) {
      return `${vl.substr( 1 + calc, 5)}.${vl.substr( 6 + calc, 5)} `
      +      `${vl.substr(11 + calc, 5)}.${vl.substr(16 + calc, 6)} `
      +      `${vl.substr(22 + calc, 5)}.${vl.substr(27 + calc, 6)} `
      +      `${vl.substr(33 + calc, 1)}`;
    } else if (vl && vl.length > 33 && vl.length < 48) {
      return `${vl.substr( 1 + calc, 5)}.${vl.substr( 6 + calc, 5)} `
      +      `${vl.substr(11 + calc, 5)}.${vl.substr(16 + calc, 6)} `
      +      `${vl.substr(22 + calc, 5)}.${vl.substr(27 + calc, 6)} `
      +      `${vl.substr(33 + calc, 1)} `
      +      `${vl.substr(34 + calc, vl.length - 33)}`;
    } else if (vl && vl.length === 48) {
      return `${vl.substr( 1 + calc, 11)} ${vl.substr(12 + calc, 1)} `
      +      `${vl.substr(13 + calc, 11)} ${vl.substr(24 + calc, 1)} `
      +      `${vl.substr(25 + calc, 11)} ${vl.substr(36 + calc, 1)} `
      +      `${vl.substr(37 + calc, 11)} ${vl.substr(48 + calc, 1)}`;
    } else {
      return vl;
    }
  }
}
