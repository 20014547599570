import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoansDateParameterDataShare } from '@src/core/usecases/data-share/loans-date-param';
import * as Util from '@src/shared/util-common';

@Component({
  selector: 'fibra-download-contracts',
  templateUrl: './download-contracts.component.html',
  styleUrls: ['./download-contracts.component.scss'],
})
export class DownloadContractsComponent implements OnInit {
  removeMask = (value) => Util.removeMask(value);
  public generatePDF = (value?) => Util.generatePDF(value);
  public data;
  public approvalList;
  public accountType;
  public tabsType;
  public date;
  public hour;
  public diffDateDays;
  public loansList = [];
  public dailyPosition = false;
  public contracts = false;
  public companyCheck = false;
  public dateReferenceToShow;

  constructor(private router: ActivatedRoute, private dateShare: LoansDateParameterDataShare) { }

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('contracts-export-data'));
    this.loansList = this.data.data;


    
    this.dateShare.data
    let dateParam = null
    let date = null
    this.dateShare.dataShared.subscribe((res) => (dateParam = res))

    let downDtRef = sessionStorage.getItem('dt_ref_download')
    let dt_Ref = sessionStorage.getItem('dt_ref')

    if(dt_Ref=='null')
    {
      this.dateReferenceToShow = downDtRef
    }else
    {
      this.dateReferenceToShow = dt_Ref
    }
    
    //Seta data de referencia, se houver
    
  }



  ngAfterViewInit() {
    this.generatePDF('body-content');
    localStorage.removeItem('contracts-export-data');
  }

  getDate() {


    const auxDate = new Date();
    this.date = Intl.DateTimeFormat('pt-BR').format(auxDate);
    this.hour = auxDate.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
    this.diffDays();
  }

  diffDays() {
    const date1 = new Date(this.data.date.dt_Inicio);
    const date2 = new Date(this.data.date.dt_Final);

    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    this.diffDateDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  }
}
