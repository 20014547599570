import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

interface OptionsRadio {
  option: string;
  value: any;
}

@Component({
  selector: 'fibra-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements OnInit {
  @Input() check: string = 'TED';
  @Input() name: string = 'TED';
  @Input() label: string;
  @Output() selected: EventEmitter<any>;
  @Input() option: OptionsRadio[] = [];

  @Input() public set value(value) {
    if (value !== undefined && this.valueControl !== value) {
      this.valueControl = value;
      this.onChange(value);
    }
  }
  public valueControl = '';
  constructor() {
    this.selected = new EventEmitter();
  }

  ngOnInit() { }

  valueTest(event) {
    this.selected.emit(event);
  }

  /*
    FormControl methods
  */
  public writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void { }
  private onChange = (value: any) => { };
}

