/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'sortByNotification' })
export class SortByNotificationPipe implements PipeTransform {

  transform(value: any[]): any[] {
    return value.sort((n1,n2) => 
    n2.id - n1.id );
  }
}
