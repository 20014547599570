import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import * as $ from 'jquery';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fibra-protest',
  templateUrl: './protest.component.html',
  styleUrls: ['./protest.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProtestComponent),
      multi: true,
    },
  ],
})
export class ProtestComponent implements OnInit {
  @Input() formControlName: string;
  @Input() inputValue: number;
  @Input() dateProtest;
  @Input() date;

  @Output() protest: EventEmitter<any>;

  @Input() public set value(value) {
    if (value !== undefined && this.valueControl !== value) {
      this.valueControl = value;
      this.onChange(value);
    }
  }
  public valueControl = '';
  constructor() {
    this.protest = new EventEmitter();
  }

  ngOnInit() {
    $(document).ready(() => {
      $('#inputDay').ready(() => {
        $('#inputDay:text').val('');
      });
    });

    $('#inputDay').blur((param1, param2) => {
      param1 = String(this.inputValue).replace(/[^0-9]/g, '');
      param2 = ' Dias';

      if (param1 === "0") {
        $('#inputDay:text').val('');
      } else {
        param1
          ? $('#inputDay:text').val(param1 + param2)
          : $('#inputDay:text').val('');
      }
    });

    $('#inputDay').click((e) => {
      $('#inputDay:text').val('');
    });
  }

  resolveProtest(valueDays) {

    let verificaPosicao = valueDays.split("");
   // let primeiraPosicao = verificaPosicao[0] === "0" ? true : false;

    let primeiraPosicao = verificaPosicao[0] === "0" || verificaPosicao.length === 0 ? true : false;

    if (primeiraPosicao) {
      this.dateProtest = '';
      this.protest.emit({ dateProtest: this.dateProtest, valueDays });

      return;
    }
  //  if (primeiraPosicao) return;

    const days = Number(valueDays);
    const protestDate = new Date(this.date);
    const updateDate = protestDate.setDate(protestDate.getDate() + days);
    const format = new Intl.DateTimeFormat('pt-BR').format(updateDate);

    this.dateProtest = format;
    this.protest.emit({ dateProtest: this.dateProtest, valueDays });

  }

  /*
    FormControl methods
  */
  public writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void { }
  private onChange = (value: any) => { };
}


