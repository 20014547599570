import { CommonModule } from '@angular/common';
import { SharedModule } from '@src/shared';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';

// pages
import { LoginComponent } from '@src/presentation/web/pages/login/login.component';
import { DownloadAppComponent } from './web/pages/download-app/download-app.component';
import { DetalsRetornoComponent } from './web/pages/detals-retorno/detals-retorno.component';
import { DashboardComponent } from '@src/presentation/web/pages/dashboard/dashboard.component';
import { ExtractComponent } from '@src/presentation/web/pages/extract/extract.component';
import { DemoComponent } from '@src/presentation/web/pages/demo/demo.component';
import { ChargeComponent } from '@src/presentation/web/pages/charge/charge.component';
import { ChargeArchiveComponent } from '@src/presentation/web/pages/charge-archive/charge-archive.component';
import { SummaryComponent } from '@src/presentation/web/pages/summary/summary.component';
import { ChargeEditComponent } from '@src/presentation/web/pages/charge-edit/charge-edit.component';
import { DuplicateBillComponent } from '@src/presentation/web/pages//duplicate-bill/duplicate-bill.component';
import { QueryComponent } from '@src/presentation/web/pages/query/query.component';
import { TransferComponent } from '@src/presentation/web/pages/transfer/transfer.component';
import { PaymentComponent } from '@src/presentation/web/pages/payment/payment.component';
import { PaymentRevisionComponent } from '@src/presentation/web/pages/payment-revision/payment-revision.component';
import { TransferConfirmComponent } from '@src/presentation/web/pages/transfer-confirm/transfer-confirm.component';
import { TransferReceiptComponent } from '@src/presentation/web/pages/transfer-receipt/transfer-receipt.component';
import { BankComponent } from '@src/presentation/web/pages/bank/bank.component';
import { SameOwnershipComponent } from '@src/presentation/web/pages/same-ownership/same-ownership.component';
import { OwnershipPageComponent } from '@src/presentation/web/pages/ownership/ownership.component';
import { ApprovalMatrixComponent } from '@src/presentation/web/pages/approval-matrix/approval-matrix.component';
import { TransactionApprovalComponent } from '@src/presentation/web/pages/transaction-approval/transaction-approval.component';
import { NewOwnershipPageComponent } from '@src/presentation/web/pages/new-ownership/new-ownership.component';
import { PaymentMultipleComponent } from '@src/presentation/web/pages/payment-multiple/payment-multiple.component';
import { PaymentPendingComponent } from '@src/presentation/web/pages/payment-pending/payment-pending.component';
import { TransferPendingComponent } from '@src/presentation/web/pages/transfer-pending/transfer-pending.component';
import { AdminUserPageComponent } from '@src/presentation/web/pages/admin-user-page/admin-user-page.component';
import { RegisterAdminPageComponent } from '@src/presentation/web/pages/register-admin/register-admin.component';
import { IncomeReportPageComponent } from '@src/presentation/web/pages/income-report/income-report.component';
import { CapitalAgilComponent } from '@src/presentation/web/pages/capital-agil/capital-agil.component';
import { ProdutoSimulacaoComponent } from '@src/presentation/web/pages/produtos-simulacao/produtos-simulacao.component';
import { AcompanharSolicitacaoComponent } from '@src/presentation/web/pages/acompanhar-solicitacao/acompanhar-solicitacao.component';
import { DerivativePageComponent } from '@src/presentation/web/pages/derivative/derivative.component';
import { RegisterGroupAdminPageComponent } from '@src/presentation/web/pages/register-group-admin/register-group-admin.component';

import { LoansComponent } from '@src/presentation/web/pages/loans/loans.component';
import { ContractsComponent } from '@src/presentation/web/pages/contracts/contracts.component';
import { CompanyCheckComponent } from '@src/presentation/web/pages/company-check/company-check.component';
import { DailyPositionComponent } from '@src/presentation/web/pages/daily-position/daily-position.component';
import { ContractInformationComponent } from '@src/presentation/web/pages/contract-information/contract-information.component';
import { ParcelasComponent } from '@src/presentation/web/pages/parcelas/parcelas.component';
import { PixConfirmDevolutionComponent } from './web/pages/pix-confirm-devolution/pix-confirm-devolution.component';
// components
import { CustomComponentsModule } from '@src/presentation/web/components/custom-components.module';


// providers
import { HttpClientModule } from '@angular/common/http';
import {
  NgModule,
  LOCALE_ID,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// enviroment
import { environment } from '@src/environments/environment';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { ToastrModule } from 'ngx-toastr';
import { ToastsContainer } from './web/pages/demo/toast-container';
import { ForeignTradeComponent } from './web/pages/foreign-trade/foreign-trade.component';
import { ImportComponent } from './web/pages/import/import.component';
import { ExportComponent } from './web/pages/export/export.component';
import { ReceivedPaymentOrdersComponent } from './web/pages/received-payment-orders/received-payment-orders.component';
import { WarrantiesComponent } from './web/pages/warranties/warranties.component';
import { PixComponent } from './web/pages/pix/pix.component';
import { PixTransferirComponent } from './web/pages/pix-transferir/pix-transferir.component';
import { PixChavesComponent } from './web/pages/pix-chaves/pix-chaves.component';
import { PixChavesCadastroComponent } from './web/pages/pix-chaves-cadastro/pix-chaves-cadastro.component';
import { PixFavoriteBeneficiariesComponent } from './web/pages/pix-favorite-beneficiaries/pix-favorite-beneficiaries.component';
import { PixBeneficiariesListComponent } from './web/components/pix-beneficiaries-list/pix-beneficiaries-list.component';
import { PixListBeneficiariesDetailsComponent } from './web/components/pix-details-list-beneficiaries/pix-details-list-beneficiaries.component';
import { PixNewTransferComponent } from './web/pages/pix-new-transfer/pix-new-transfer.component';
import { PixNewRefundComponent } from './web/pages/pix-new-refund/pix-new-refund.component';
import { PixTransferCheckComponent } from './web/pages/pix-transfer-check/pix-transfer-check.component';
import { PixTransferProofComponent } from './web/pages/pix-transfer-proof/pix-transfer-proof.component';
import { PixKeysApprovalsComponent } from './web/pages/pix-keys-approvals/pix-keys-approvals.component';
import { PixTermsApprovalsComponent } from './web/pages/pix-terms-approvals/pix-terms-approvals.component';
import { PixLimitesComponent} from './web/pages/pix-limites/pix-limites.component';
import { PixLimiteCadastroComponent } from './web/pages/pix-limite-cadastro/pix-limite-cadastro.component';
import { PixTransferConfirmComponent } from './web/pages/pix-confirm-transfer/pix-confirm-transfer.component';
import { PixAgenciaContaTransferenciaOutroBanco } from './web/pages/pix-agenciaconta-transfer-outrobanco/pix-agenciaconta-transfer-outrobanco.component';
import { PixAgenciaContaTransferenciaFibra } from './web/pages/pix-agenciaconta-transfer-bancofibra/pix-agenciaconta-transfer-bancofibra.component';
import { PixListBankComponent } from './web/pages/pix-agenciaconta-bancos-list/pix-agenciaconta-bancos-list.component';
import { PixTermComponent } from './web/components/pix-term/pix-term.component';
import { ReadyChangeComponent } from './web/pages/ready-change/ready-change.component';
import { ForeignTradeReportComponent } from './web/components/foreign-trade-report/foreign-trade-report.component';
//LoansReportComponent
import { LoansReportComponent } from './web/components/loans-report/loans-report.component';
import { ExtractReportComponent } from './web/components/extract-report/extract-report.component';
import { DerivativeReportComponent } from './web/components/derivative-report/derivative-report.component';





import { MascaraCPFCNPJDirective } from '@src/shared/directives/mascaraCpfCnpjDirective';
import { ApprovalReceiptComponent } from './web/components/approval-receipt/approval-receipt.component';
import { DownloadApprovalListComponent } from './web/components/download-approval-list/download-approval-list.component';
import { DownloadReceiptComponent } from './web/components/download-receipt/download-receipt.component';
import { DownloadInstallmentsComponent } from './web/components/download-installments/download-installments.component';
import { DownloadContractInfoComponent } from './web/components/download-contract-info/download-contract-info.component';

import { DownloadContractsComponent } from './web/components/download-contracts/download-contracts.component';
import { DownloadDailyPositionComponent } from './web/components/download-daily-position/download-daily-position.component';
import { DownloadCheckCompanyComponent } from './web/components/download-check-company/download-check-company.component';

import { DownloadDerivativeListComponent } from './web/components/download-derivative-list/download-derivative-list.component';
import { RegistratoComponent } from './web/pages/registrato/registrato.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TotalOperationComponent } from './web/components/total-operation/total-operation.component';
import { UserService } from '@src/shared/services/user.service';
import { WarrantyMapComponent } from './web/components/warranty-map/warranty-map.component';
import { QuickAccessGoAlongComponent } from './web/components/quick-access-go-along/quick-access-go-along.component';
import { ChatComponent } from './web/components/chat/chat.component';
import { ScriptService } from '@src/core/base/script.service';
import { AccessDeniedPageComponent } from './web/pages/access-denied-page/access-denied-page.component';
import { PixSolicitationComponent } from '@src/presentation/web/pages/pix-solicitation/pix-solicitation.component';
import { ImpersonateComponent } from './web/pages/impersonate/impersonate.component';
import { PaymentBatchComponent } from './web/pages/payment-batch/payment-batch.component';
import { ReceiptComponent } from './web/pages/receipt/receipt.component';
import { PixRefundComponent } from '@src/presentation/web/pages/pix-refund/pix-refund.component';
import { OpenbankingOnboardingComponent } from './web/components/openbanking-onboarding/openbanking-onboarding.component';
import { TermUserPageComponent } from './web/pages/terms-user/terms-user.component';
import { OpenbankingPaymentRequestComponent } from './web/pages/openbanking-paymentrequest/openbanking-paymentrequest.component';
import { OpenbankingPaymentRequestNewTransactionComponent } from './web/pages/openbanking-paymentrequest/newtransaction/openbanking-paymentrequest-newtransaction.component';
import { OpenbankingPaymentRequestConfirmTransactionComponent } from './web/pages/openbanking-paymentrequest/confirmtransaction/openbanking-paymentrequest-confirmtransaction.component';
import { OpenbankingPaymentRequestCanDeactivate } from './web/pages/openbanking-paymentrequest/openbanking-paymentrequest.candeactivate';
import { OpenbankingPaymentRequestBeforeUnloadComponent } from './web/pages/openbanking-paymentrequest/openbanking-paymentrequest.beforeunload';
import { OpenbankingPaymentRequestRedirectComponent } from './web/pages/openbanking-paymentrequest/redirect/openbanking-paymentrequest-redirect.component';
import { OpenbankingPaymentRequestCanActivate } from './web/pages/openbanking-paymentrequest/openbanking-paymentrequest.canactivate';
import { OpenbankingComponent } from './web/pages/openbanking/openbanking.component';
import { OpenbankingHomeComponent } from './web/pages/openbanking/home/openbanking-home.component';
//import { ImpersonateBarComponent } from './web/components/impersonate-bar/impersonate-bar.component';
import { ChangeTransactionalLimitComponent } from './web/pages/change-transactional-limit/change-transactional-limit.component';

const env = environment;
//#region Configurations


const MsalConfigurationModule = MsalModule.forRoot(
  {
    auth: {
      clientId: env.clientId,
      redirectUri: env.redirectUri,
      authority: `https://${env.tenantUrl}/tfp/${env.tenantId}/B2C_1A_basic_signin_with_ad`,
      validateAuthority: false,
      postLogoutRedirectUri: env.redirectUri,
    },
  },
  {
    protectedResourceMap: [],
  }
);
//#endregion

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AccessDeniedNoAccountPageComponent } from '@src/presentation/web/pages/access-denied-no-account-page/access-denied-no-account-page.component';
import { OpportunityComponent } from './web/pages/opportunity/opportunity.component';
import { OpportunityCapitalGiroComponent } from './web/pages/opportunity-capital-giro/opportunity-capital-giro.component';
import { OpportunityCambioComponent } from './web/pages/opportunity-cambio/opportunity-cambio.component';
import { OpportunitySegurosComponent } from './web/pages/opportunity-seguros/opportunity-seguros.component';
import { OpportunityFiancaBancariaComponent } from './web/pages/opportunity-fianca-bancaria/opportunity-fianca-bancaria.component';
@NgModule({
  declarations: [
    // pages
    LoginComponent,
    DownloadAppComponent,
    DashboardComponent,
    ExtractComponent,
    DemoComponent,
    ChargeComponent,
    ChargeArchiveComponent,
    SummaryComponent,
    ChargeEditComponent,
    DuplicateBillComponent,
    QueryComponent,
    TransferComponent,
    MascaraCPFCNPJDirective,
    PaymentComponent,
    PaymentRevisionComponent,
    TransferConfirmComponent,
    TransferReceiptComponent,
    BankComponent,
    DetalsRetornoComponent,
    SameOwnershipComponent,
    OwnershipPageComponent,
    ApprovalMatrixComponent,
    TransactionApprovalComponent,
    ToastsContainer,
    NewOwnershipPageComponent,
    PaymentMultipleComponent,
    PaymentPendingComponent,
    TransferPendingComponent,
    AdminUserPageComponent,
    ForeignTradeComponent,
    IncomeReportPageComponent,
    CapitalAgilComponent,
    ProdutoSimulacaoComponent,
    AcompanharSolicitacaoComponent,
    DerivativePageComponent,
    ReadyChangeComponent,
    ImportComponent,
    RegisterAdminPageComponent,
    RegisterGroupAdminPageComponent,
    ExportComponent,
    ReceivedPaymentOrdersComponent,
    WarrantiesComponent,
    PixComponent,
    PixTransferirComponent,
    PixChavesComponent,
    PixChavesCadastroComponent,
    PixFavoriteBeneficiariesComponent,
    PixBeneficiariesListComponent,
    PixListBeneficiariesDetailsComponent,
    PixNewTransferComponent,
    PixNewRefundComponent,
    PixTransferConfirmComponent,
    PixTransferCheckComponent,
    PixTransferProofComponent,
    PixKeysApprovalsComponent,
    PixTermsApprovalsComponent,
    PixAgenciaContaTransferenciaOutroBanco,
    PixAgenciaContaTransferenciaFibra,
    PixLimitesComponent,
    PixLimiteCadastroComponent,
    PixTermComponent,
    PixSolicitationComponent,
    PixRefundComponent,
    PixListBankComponent,
    ForeignTradeReportComponent,
    ApprovalReceiptComponent,
    DownloadApprovalListComponent,
    DownloadReceiptComponent,
    DownloadContractsComponent,
    DownloadDailyPositionComponent,
    DownloadContractInfoComponent,
    DownloadInstallmentsComponent,
    DownloadCheckCompanyComponent,
    RegistratoComponent,
    LoansComponent,
    LoansReportComponent,
    ExtractReportComponent,
    DerivativeReportComponent,
    ContractsComponent,
    CompanyCheckComponent,
    DailyPositionComponent,
    ContractInformationComponent,
    ParcelasComponent,
    DownloadDerivativeListComponent,
    TotalOperationComponent,
    WarrantyMapComponent,
    QuickAccessGoAlongComponent,
    ChatComponent,
    AccessDeniedPageComponent,
    AccessDeniedNoAccountPageComponent,
    ImpersonateComponent,
    PaymentBatchComponent,
    ReceiptComponent,
    OpenbankingOnboardingComponent,
    TermUserPageComponent,
    OpenbankingPaymentRequestComponent,
    OpenbankingPaymentRequestBeforeUnloadComponent,
    OpenbankingPaymentRequestNewTransactionComponent,
    OpenbankingPaymentRequestConfirmTransactionComponent,
    OpenbankingPaymentRequestRedirectComponent,
    OpenbankingComponent,
    OpenbankingHomeComponent,
    PixConfirmDevolutionComponent,
    ChangeTransactionalLimitComponent,
    OpportunityComponent,
    OpportunityCapitalGiroComponent,
    OpportunityCambioComponent,
    OpportunitySegurosComponent,
    OpportunityFiancaBancariaComponent
  ],
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    SharedModule,
    HttpClientModule,
    CustomComponentsModule,
    MsalConfigurationModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-left',
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, 
    AngularFireAuthModule, 
    AngularFireMessagingModule, 
    AngularFireAnalyticsModule,  
      
    NgbModule
  ],
  exports: [
    CustomComponentsModule,
    LoginComponent,
    DownloadAppComponent,
    ExtractComponent,
    DemoComponent,
    ChargeEditComponent,
    BankComponent,
    SameOwnershipComponent,
    OwnershipPageComponent,
    TransactionApprovalComponent,
    NewOwnershipPageComponent,
    PaymentMultipleComponent,
    PaymentPendingComponent,
    TransferPendingComponent,
    AdminUserPageComponent,
    RegisterAdminPageComponent,
    RegisterGroupAdminPageComponent,
    ApprovalReceiptComponent,
    DownloadApprovalListComponent,
    DownloadReceiptComponent,
    DownloadDerivativeListComponent,
    AccessDeniedPageComponent,
    AccessDeniedNoAccountPageComponent,
    ChatComponent,
    ImpersonateComponent,

    
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: UserService,
      useClass: UserService
    },
    {
      provide: ScriptService,
      useClass: ScriptService
    },
    ScreenTrackingService,
    OpenbankingPaymentRequestCanActivate,
    OpenbankingPaymentRequestCanDeactivate
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PresentationModule {}
