import { Injectable } from '@angular/core';
import { InstructionRequest, InstructionResponse } from '@src/core/domain/query/query.model';
import { Observable } from 'rxjs';
import { getHeader } from '@src/core/base/header';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { InstructionRepository } from '@src/core/repositories/query/instruction.repository';

@Injectable({
  providedIn: 'root',
})
export class InstructionDataRepository extends InstructionRepository {

  constructor(private http: HttpClient) {
    super();
  }

  getInstruction(params: InstructionRequest): Observable<InstructionResponse> {
    return this.http
      .post<InstructionResponse>(`${environment.URLBase}v1/instrucoes/listar`, params, { headers: getHeader() })
  }
}
