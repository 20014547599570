import {
  DuplicateBillModel,
  DuplicateBillRequest,
} from '@src/core/domain/charge/duplicate-bill.model';
import { Observable } from 'rxjs';

export abstract class DuplicateBillModelRepository {
  abstract getDuplicateBill(
    obj: DuplicateBillRequest
  ): Observable<DuplicateBillModel>;
}

export abstract class DownloadBillModelRepository {
  abstract getDownloadBill(
    obj: DuplicateBillRequest
  ): Observable<DuplicateBillModel>;

  abstract getDownloadBills(
    obj: DuplicateBillRequest[]
  ): Observable<DuplicateBillModel[]>;

}
