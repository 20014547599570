<div class="main-selector">
  <fibra-company-list></fibra-company-list>
  <fibra-profile-list [hidden]="true"></fibra-profile-list>
</div>

<div class="content-page">
  <div class="section-header">
    <div  class="margin-40-right">
      <img src="/assets/opportunity/svg/tag-orange.svg" alt="img-header-opportunity">
      <h1>Produtos Fibra</h1>
      <p>Uma linha de produtos para atender você e seu negócio. São serviços e soluções para administrar e gerenciar com mais eficiência o seu fluxo financeiro.</p>
    </div>
    <div><img src="/assets/opportunity/img/img-header-opportunity.jpg" alt="img-header-opportunity"></div>
  </div>  
  <fibra-opportunity-products-list></fibra-opportunity-products-list>
</div>
