import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FavoritesEnum } from '@src/data/repository/transfer/beneficiaries/beneficiaries.entity';
import { NavigationExtras, Router } from '@angular/router';
import { BeneficitiesTransferListComponent } from '../../components/beneficities-list/beneficities-list.component';
import { ProfileListComponent } from '../../components/profile-list/profile-list.component';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';

// Datashare
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import {
  AccountDataShare,
  BankDataShare,
  SelectBeneficiaryDataShare,
  TransferReceiptDataShare,
} from '@src/data/repository/data-share-repository';
@Component({
  selector: 'fibra-page-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
})
export class TransferComponent implements OnInit {
  beneficiaryData: FavoritesEnum[];
  hide;
  loading;
  search;
  paramDeleteAccount;
  paramDeleteBeneficiary;
  @ViewChild(BeneficitiesTransferListComponent, null)
  list: BeneficitiesTransferListComponent;
  @ViewChild(ProfileListComponent, null) profile: ProfileListComponent;
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  constructor(
    private router: Router,
    private shareBank: BankDataShare,
    private shareAccount: AccountDataShare,
    private shareCompany: CompanyDataShare,
    private shareBeneficiary: SelectBeneficiaryDataShare,
    private shareTransferReceipt: TransferReceiptDataShare
  ) {}

  ngOnInit() {

     
    
  
    // tslint:disable-next-line:no-unused-expression
    this.profile.requestAccount;
    this.clearDatashare();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.list.requestBeneficiaries();
  }

  clearDatashare() {
    this.shareBank.clear();
    this.shareAccount.clear();
    this.shareCompany.clear();
    this.shareBeneficiary.clear();
    this.shareTransferReceipt.clear();
  }

  searchBeneficiary(event) {
    this.list.requestSearch(event);
  }

  newBeneficiary() {
    const navigationExtras: NavigationExtras = {
      state: {
        value: 'new'
      }
    };
    this.router.navigate(['/bank'], navigationExtras);
  }

  deleteBeneficiaryToast(event) {
    this.paramDeleteBeneficiary = null;
    this.toast.callModalMessage(
      null,
      'Deseja mesmo excluir esse beneficiário?',
      null,
      true
    );
    this.paramDeleteBeneficiary = event;
  }

  deleteBeneficiary() {
    this.list.deleteBeneficiaryRequest(this.paramDeleteBeneficiary);
  }

  deleteAccountToast(event) {
    this.paramDeleteAccount = null;
    this.toast.callModalMessage(
      null,
      'Deseja mesmo excluir essa conta?',
      null,
      true,
      false
    );
    this.paramDeleteAccount = event;
  }

  deleteAccount() {
    this.list.deleteAccountBeneficiaryRequest(this.paramDeleteAccount);
  }

  callToast(text){
    this.toast.callModalMessage(
      null,
      text,
      null,
      false
    );
  }
}
